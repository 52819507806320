import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { blue, green } from '@mui/material/colors';
import { Save as SaveIcon } from '@mui/icons-material';
import clsx from 'clsx';

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  buttonDefaul: {},
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  button_transform: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: blue[700],
    '&:hover': {
      backgroundColor: blue[500],
    },
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 1000,
  },
});

class ButtonInputCircular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, loading, accept, isValid, small } = this.props;
    let { icon, text } = this.props;
    let running = false;
    if (typeof loading !== 'undefined' && typeof isValid !== 'undefined') {
      running = isValid ? loading : !isValid;
    } else {
      running = loading;
    }
    if (typeof icon === 'undefined') {
      icon = <SaveIcon />;
    }
    if (typeof text === 'undefined') {
      text = '';
    }
    return (
      <div className={classes.wrapper}>
        <input
          accept={accept}
          hidden
          id="select-circular-button"
          multiple={this.props.multiple}
          onChange={this.props.handleSelectFile}
          style={{ display: 'none' }}
          type="file"
        />
        <label htmlFor="select-circular-button">
          <IconButton
            aria-label="upload picture"
            className={classes.button_transform}
            color="primary"
            component="span"
            disabled={running || this.props.disabled}
            size="large">
            {icon}
          </IconButton>
        </label>
        {running && (
          <CircularProgress
            className={clsx({
              [classes.fabProgress]: !small,
              // [classes.fabProgressSmall]: small,
            })}
            size={small ? 40 : 50}
          />
        )}
      </div>
    );
  }
}

ButtonInputCircular.propTypes = {
  classes: PropTypes.object,
  handleSelectFile: PropTypes.func,
  isValid: PropTypes.bool,
  multiple: PropTypes.bool.isRequired,
};
ButtonInputCircular.defaultProps = {
  text: '',
  handleSelectFile: () => '',
  color: 'primary',
  disabled: false,
  loading: false,
  transform: false,
  accept: '.csv',
  multiple: false,
  small: false,
};
export default withStyles(ButtonInputCircular, useStyles);
