import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid, Tooltip } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import Zoom from '@mui/material/Zoom';
import SaveIcon from '@mui/icons-material/Save';
import {
  MutationAddClinicalPatients,
  MutationRemoveClinicalPatients,
  MutationUploadClinicalPatients,
} from 'graphql/DataClinics';

import { isEmpty, getColumnList } from './Styles';
import {
  ServerErrorsString,
  GetData,
  IsInvalid,
  IsAdministrator,
} from 'helpers';
import { labels } from '../../../components/utils_text';
import DialogViewClinicalPatient from 'components/Dialogs/DialogViewClinicalPatient';

class ClinicalPatientsToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      uploading: false,
      nowrap: false,
      open_delete: false,
      open_view: false,
    };
  }
  handleSaveChanges = () => {
    let { new_rows, rows_edited, patients } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      rows_edited = [];
    }
    if (IsInvalid(new_rows) || !new_rows.length) {
      new_rows = [];
    }
    new_rows = new_rows.concat(rows_edited);
    if (!new_rows.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.props;
    if (saving) return;
    this.props.setState({ saving: true });
    const inputs = new_rows
      .map(x => {
        const {
          // eslint-disable-next-line no-unused-vars
          address,
          // eslint-disable-next-line no-unused-vars
          user,
          // eslint-disable-next-line no-unused-vars
          edit,
          // eslint-disable-next-line no-unused-vars
          updatedAt,
          // eslint-disable-next-line no-unused-vars
          UniqueKeyIdentifier,
          // eslint-disable-next-line no-unused-vars
          createdAt,
          ...res
        } = x;
        if (isEmpty(res)) return null;
        return {
          ...res,
        };
      })
      .filter(x => x);
    (async () => {
      MutationAddClinicalPatients(inputs, 'patient')
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients: rows, total } = data.addPatientsClinics;
          if (ok) {
            this.props.setState({ saving: false });
            for (let i = 0; i < rows.length; i++) {
              const { id } = rows[i];
              const index = patients.map(x => x.id).indexOf(id);
              if (index === -1) {
                patients.unshift(rows[i]);
              } else {
                patients[index] = {
                  ...rows[i],
                };
              }
            }
            this.props.setState({
              new_rows: [],
              rows_edited: [],
              patients,
              total,
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    let { selected, new_rows, total, patients } = this.props;
    const new_selected = selected.filter(x => x.id < 0);
    if (new_selected.length) {
      for (let i = 0; i < new_selected.length; i++) {
        const { id } = new_selected[i];
        const index = new_rows.map(x => x.id).indexOf(id);
        if (index === -1) continue;
        new_rows.splice(index, 1);
      }
      this.props.setState({ new_rows });
    }
    selected = selected.filter(x => x.id >= 0);
    const { deleting } = this.state;
    if (deleting || !selected.length) {
      this.setState({ open_delete: false });
      this.props.setState({ selected });
      return;
    }
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    (async () => {
      MutationRemoveClinicalPatients(ids, 'patient')
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deletePatientsClinics;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              patients: patients.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { patients } = this.props;
    const { uploading } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadClinicalPatients(files[0], 'patient', this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            errors,
            patients: rows,
            total,
          } = data.uploadPatientsClinics;
          if (ok) {
            this.setState({
              uploading: false,
            });
            for (let i = 0; i < rows.length; i++) {
              const { id } = rows[i];
              const index = patients.map(x => x.id).indexOf(id);
              if (index === -1) {
                patients.unshift(rows[i]);
              } else {
                patients[index] = {
                  ...rows[i],
                };
              }
            }
            this.props.setState({ patients, total, loading: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getDeleteText = (table, isShortVersion) => {
    const splitted = table.split('_');
    const newArray = splitted.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    if (isShortVersion) {
      return `${newArray.join(' ')} (${this.props.selected.length})`;
    } else {
      return `${newArray.join(' ')} (${this.props.selected.length} records)`;
    }
  };
  render() {
    const {
      rows_edited,
      selected,
      // new_rows,
      row_clicked,
      anonymize,
    } = this.props;
    // let numSelected = rows_edited.length + new_rows.length;
    const table = 'patients';
    const confirmationTitle = `${
      labels.delete.confirmation.title
    } ${this.getDeleteText(table, false)}`;
    const confirmationText1 = `${
      labels.delete.confirmation.text1
    } ${this.getDeleteText(table, false)}`;
    const confirmationText2 = `${labels.delete.confirmation.text2}`;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={0.5}
          pt={0.5}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="save">
              {IsAdministrator && (
                <Tooltip
                  placement="top"
                  title={anonymize ? 'De-anonymize' : 'Anonymize'}
                  TransitionComponent={Zoom}>
                  <Button onClick={this.props.handleToggleAnonymize}>
                    {anonymize ? (
                      <LockIcon title="Anonymize" />
                    ) : (
                      <NoEncryptionIcon />
                    )}
                  </Button>
                </Tooltip>
              )}
              <Button
                disabled={IsInvalid(row_clicked)}
                onClick={() => this.setState({ open_view: true })}>
                <VisibilityIcon />
              </Button>
              <Button
                disabled={!rows_edited.length}
                onClick={this.handleSaveChanges}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </Button>
              <ButtonDownloadType
                columns={getColumnList()}
                inputs={{ ids: selected.map(x => x.id) }}
                key="clinical_patient"
                setState={state => this.setState(state)}
                table="patient"
                type="clinical_patient"
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`${confirmationText1} ${confirmationText2}`}
          title_text={confirmationTitle}
        />
        <DialogViewClinicalPatient
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_view: false })}
          history={this.props.history}
          open={this.state.open_view}
          patient={row_clicked}
        />
      </React.Fragment>
    );
  }
}

ClinicalPatientsToolbar.propTypes = {
  classes: PropTypes.object,
};
ClinicalPatientsToolbar.defaultProps = {
  rows_edited: [],
  selected: [],
};
export default ClinicalPatientsToolbar;
