import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

class TabContainerVolumes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container } = this.props;
    if (!container || container === undefined) return null;
    const { Mounts } = container;
    // Type: String!
    // Name: String
    // Source: String!
    // Destination: String!
    return (
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>A basic table example with a caption</caption>
          <TableBody>
            {Mounts.map((row, index) => (
              <TableRow key={`row-mount-${index}`}>
                <TableCell component="th" scope="row">
                  {row.Type}
                </TableCell>
                <TableCell align="left">{row.Name}</TableCell>
                <TableCell align="left">{row.Source}</TableCell>
                <TableCell align="left">{row.Destination}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabContainerVolumes.propTypes = {
  classes: PropTypes.object,
};

export default TabContainerVolumes;
