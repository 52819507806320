import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_INIT_RESET_PASSWORD = gql`
  query($email: String!) {
    InitResetPassword(email: $email) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const InitResetPassword = email => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_INIT_RESET_PASSWORD),
    variables: {
      email,
    },
  });
};

const QUERY_CHECK_CODE_RESET_PASSWORD = gql`
  query($email: String!, $code: String!) {
    CheckCodeResetPassword(email: $email, code: $code) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const CheckCodeResetPassword = (email, code) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CHECK_CODE_RESET_PASSWORD),
    variables: {
      email,
      code,
    },
  });
};

const QUERY_FINISH_RESET_PASSWORD = gql`
  query($email: String!, $password: String!, $code: String!) {
    FinishResetPassword(email: $email, password: $password, code: $code) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const FinishResetPassword = (email, password, code) => {
  console.log('FinishResetPassword: ', { email, code });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FINISH_RESET_PASSWORD),
    variables: {
      email,
      code,
      password,
    },
  });
};

const MUTATION_IS_SAME_PASSWORD = gql`
  mutation($password: String!) {
    IsSamePassword(password: $password) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationIsSamePassword = password => {
  console.log('MutationIsSamePassword: ');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_IS_SAME_PASSWORD),
    variables: {
      password,
    },
  });
};
const MUTATION_UPDATE_PASSWORD = gql`
  mutation($id: LongLong!, $current_password: String!, $password: String!) {
    updatePassword(
      id: $id
      current_password: $current_password
      password: $password
    ) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdatePassword = (id, current, password) => {
  console.log('MutationUpdatePassword: ');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PASSWORD),
    variables: {
      id,
      current_password: current,
      password,
    },
  });
};
