import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { QueryPathologies } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';
import { DetailsAutocomplete } from '../index';

const default_pathology = {
  idPathology: -1,
  name: '',
  updatedAt: '',
};

class Pathology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pathology: { ...default_pathology },
      pathologies: [],
      open: false,
      error: '',
      empty: false,
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.getPathology = () => {
        return this.state.pathology;
      };
      props.holder.resetPathology = () => {
        this.state.pathology = { ...default_pathology };
      };
    }
  }

  componentDidMount() {
    this.runSearch();
  }

  componentDidUpdate() {
    const { pathology } = this.state;
    if (
      pathology.idPathology === -1 &&
      this.props.pathology.idPathology !== -1
    ) {
      this.setState({ pathology: this.props.pathology });
    }
  }

  setOpen = open => {
    this.setState({ open });
  };
  setPathology = pathology => {
    this.setState({ pathology });
  };
  handleTyping = event => {
    const { loading } = this.state;
    let focus = event.target.value;
    if (loading || !focus || focus === '') {
      return;
    }
    this.runSearch(focus);
  };
  runSearch = pathology => {
    const { loading } = this.state;
    if (loading || this.state.empty) {
      return;
    }
    const { pathologies } = this.state;
    if (pathologies.length) {
      pathology = pathology.toLowerCase();
      const here = pathologies.filter(x =>
        x.name.toLowerCase().startsWith(pathology)
      );
      if (here.length) {
        return;
      }
    }
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryPathologies(pathology)
        .then(res => {
          const data = GetData(res);
          let pathologies = [];
          let empty = true;
          if (typeof data !== 'undefined' && data.Pathologies) {
            empty = data.Pathologies.length ? false : true;
            pathologies = data.Pathologies;
          }
          this.setState({ loading: false, empty, pathologies });
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChanged = (label, newValue) => {
    if (!newValue) {
      newValue = { ...default_pathology };
    }
    this.setPathology(newValue);
    this.props.handleSelectedChanged(label, newValue);
  };

  render() {
    const { minWidth } = this.props;
    const { loading, pathologies, open } = this.state;
    let pathology = this.state.pathology;
    if (pathology.idPathology === -1) {
      pathology = undefined;
    }
    return (
      <DetailsAutocomplete
        detail={pathology}
        details={pathologies}
        handleSelectedChanged={this.handleSelectedChanged}
        handleTyping={this.handleTyping}
        label="pathology"
        loading={loading}
        minWidth={minWidth}
        open={open}
        setOpen={this.setOpen}
        title="Pathology"
      />
    );
  }
}

Pathology.propTypes = {
  classes: PropTypes.object,
  minWidth: PropTypes.bool.isRequired,
};

Pathology.defaultProps = {
  className: '',
  pathology: default_pathology,
  minWidth: false,
};
export default Pathology;
