import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import { ButtonLoad, TextFieldCustom } from 'components';
import { IsInvalid } from 'helpers';
import { MutationAddAnonymizationRules } from 'graphql/Anonymization';
import { GetData, ServerErrorsString } from 'helpers';

class DialogCreateRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null,
      creating: false,
    };
  }
  handleTextChanged = event => {
    let { tag } = this.state;
    const name = event.target.name;
    if (IsInvalid(tag)) {
      tag = {
        [name]: event.target.value,
      };
    } else {
      tag = {
        ...tag,
        [name]: event.target.value,
      };
    }
    this.setState({ tag });
  };
  handleAddAnonymizationRule = () => {
    const { creating, tag } = this.state;
    if (creating || IsInvalid(tag)) return;
    this.setState({ creating: false });
    (async () => {
      MutationAddAnonymizationRules([{ ...tag }])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.addAnonymizationRules;
          if (ok) {
            this.setState({ creating: false });
            this.props.handleCreatedTagRule(rules);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { tag } = this.state;
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={this.props.open}
          PaperComponent={PaperComponent}>
          <DialogTitle>Create DICOM Rule</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter all the necessary information of the rule.
            </DialogContentText>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              pt={1}
              spacing={1}>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Tag"
                  name="tag"
                  onChange={this.handleTextChanged}
                  placeholder="Tag hexadecima (GGGG,EEEE)..."
                  value={tag ? tag.tag_rule : undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Tag Name"
                  name="tag_name"
                  onChange={this.handleTextChanged}
                  placeholder="Description of the Tag"
                  value={tag ? tag.tag_name : undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Tag Rule"
                  name="tag_rule"
                  onChange={this.handleTextChanged}
                  placeholder="Select one value of the options..."
                  value={tag ? tag.tag_rule : undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldCustom
                  label="Tag Options"
                  name="tag_options"
                  onChange={this.handleTextChanged}
                  placeholder="Tag options like X/D/Z/U..."
                  value={tag ? tag.tag_options : undefined}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <ButtonLoad
              color="primary"
              disable_button={this.state.tag ? false : true}
              handleClick={this.handleAddAnonymizationRule}
              loading={this.state.creating}
              name="Create Rule"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogCreateRule.propTypes = {
  size: PropTypes.string,
};

export default DialogCreateRule;
