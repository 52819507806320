import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import SaveIcon from '@mui/icons-material/Save';
import { MutationDeleteLogAdminUsers } from 'graphql/Logs';
import { ServerErrorsString, GetData, IsInvalid } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { useStylesToolBars } from 'common';
import { withRouter } from 'react-router-dom';

class ToolBarLogAdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      deleting: false,
      uploading: false,
    };
  }
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { logs_admin_users, selected } = this.props.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteLogAdminUsers(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteLogsAdminUsers;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              selected: [],
              logs_admin_users: logs_admin_users.filter(
                x => ids.indexOf(x.id) === -1
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveRules = () => {
    console.log('handleSaveRules');
  };
  handleSelectFile = () => {
    console.log('handleSelectFile');
  };
  handleOpenSeries = () => {
    const { selected, row_highlight } = this.props.state;
    const { idSeries } = row_highlight;
    if (idSeries && !selected.length) {
      this.props.history.push(`/image_analysis/series/${idSeries}/view`);
    } else if (selected.length) {
      // const ids_patients = selected.map(x => x.idPatient).filter(x => x);
      // this.props.setPatientsIds(ids_patients);
      // this.props.history.push('/image_analysis/patients/group');
    }
  };
  handleOpenExplorerSeries = () => {
    const { row_highlight } = this.props.state;
    const { series } = row_highlight;
    console.log({ row_highlight });
    if (IsInvalid(series)) return;
    const { locations } = series;
    if (locations && locations.length) {
      this.props.history.push(`/explorer/folder/${locations[0].idFolder}`);
    }
  };
  render() {
    const { classes } = this.props;
    const { rows_edited, selected } = this.props.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          pb={1}
          pt={1}
          xs={12}>
          <Grid item md={3.5} sm={5} xs={12}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSaveRules}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'blue' : 'gray' }}
                />
              </Button>
              {/* <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'green' }} />
              </Button> */}
              <ButtonDownloadType
                key="log_admin_users"
                type="log_admin_users"
              />
              {/* <Button disabled={!row_highlight} onClick={this.handleOpenSeries}>
                <LaunchIcon
                  style={{ color: row_highlight ? 'green' : 'gray' }}
                />
              </Button>
              <Button
                disabled={!row_highlight}
                onClick={this.handleOpenExplorerSeries}>
                <ExploreIcon
                  style={{ color: row_highlight ? 'blue' : 'gray' }}
                />
              </Button> */}
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={text => this.props.handleReload(text)}
              id="id-search-log-units"
              loading={this.props.loading}
              placeholder="Search log users..."
              size="small"
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            mr={1}
            xs={2}>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={() => this.props.setState({ selected: [] })}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete these user logs, this action cannot be undone"
          title_text="Delete Admin User Logs"
        />
      </React.Fragment>
    );
  }
}

ToolBarLogAdminUsers.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};

ToolBarLogAdminUsers.defaultProps = {
  selected: [],
  rows_edited: [],
  handleDeleteSelected: () => '',
};

export default withStyles(withRouter(ToolBarLogAdminUsers), useStylesToolBars);
