import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, TextField } from '@mui/material';

import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { options_access } from 'helpers/Docker';
import { GreenCheckbox } from 'components';
import { IsChanged, TabWidgetDockerInputs } from './components';

class DockerInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      access_type_object: null,
      settings: {
        id: null,
        volume: null,
        ports: null,
        execute: false,
        access: null,
        dockerfile: null,
        operation_name: '',
        inputs_outputs: [],
        input_commands: '',
        docker_variables: '',
      },
      inputs_fields: [],
      outputs_fields: [],
      counter: -1,
    };
    const { holder } = props;
    if (holder) {
      holder.setSetting = settings => {
        this.setSetting(settings);
      };
      holder.getSetting = () => {
        let value = {
          ...this.state.settings,
        };
        delete value.id;
        return value;
      };
      holder.IsChange = () => {
        const { settings } = this.state;
        const changed = IsChanged(settings, this);
        return changed;
      };
    }
    this.holder = {
      setOuputVolume: null,
      setInputVolume: null,
    };
  }
  setSetting = settings => {
    if (!settings || settings === undefined) return;
    if (settings.id === this.state.settings.id) return;
    if (!settings.inputs_outputs || settings.inputs_outputs === undefined)
      return;
    let state = {
      settings: {
        id: settings.id,
        operation_name: settings.operation_name,
        inputs_outputs: settings.inputs_outputs,
        volume: settings.volume,
        ports: settings.ports,
        execute: settings.execute,
        access: settings.access,
        input_commands: settings.input_commands,
        docker_variables: settings.docker_variables,
      },
    };
    let index = options_access.map(x => x.name).indexOf(settings.access);
    if (index !== -1) state.access_type_object = options_access[index];
    this.setState(state);
  };
  componentDidMount() {
    const { defaultSetting } = this.props;
    this.setSetting(defaultSetting);
  }
  componentDidUpdate() {
    const { defaultSetting } = this.props;
    this.setSetting(defaultSetting);
  }
  handleSelectedAccessType = access_type_object => {
    const { settings } = this.state;
    let access = '';
    if (access_type_object) access = access_type_object.name;
    const changed = IsChanged({ ...settings, access }, this);
    this.props.handleChanged(changed);
    this.setState({ access_type_object });
  };
  handleChangeExecute = event => {
    let { settings } = this.state;
    const execute = event.currentTarget.checked;
    settings = { ...settings, execute };
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
    this.setState({ settings });
  };
  handleDockerCommand = event => {
    let { settings } = this.state;
    const input_commands = event.target.value;
    console.log({ input_commands });
    settings = { ...settings, input_commands };
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
    this.setState({ settings });
  };
  handleDockerVariable = event => {
    let { settings } = this.state;
    const docker_variables = event.target.value;
    console.log({ docker_variables });
    settings = { ...settings, docker_variables };
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
    this.setState({ settings });
  };
  handleAddInput = () => {
    let { settings, counter } = this.state;
    counter--;
    settings.inputs_outputs.push({
      id: counter,
      idDockerSetting: settings.id,
      in_out_type: 'input',
      type: '',
      volume: '',
    });
    this.setState({ settings, counter });
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
  };
  handleRemoveInput = id => {
    console.log('Handle remove input', id);
    let { settings } = this.state;
    if (settings.inputs_outputs.length > 0) {
      settings.inputs_outputs = settings.inputs_outputs.filter(
        item => item.id !== id
      );
    }
    this.setSetting(settings);
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
  };
  handleAddOutput = () => {
    let { settings, counter } = this.state;
    counter--;
    settings.inputs_outputs.push({
      id: counter,
      idDockerSetting: settings.id,
      in_out_type: 'output',
      type: '',
      volume: '',
    });
    this.setState({ settings, counter });
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
  };
  handleRemoveOutput = id => {
    console.log('Handle remove output', id);
    let { settings } = this.state;
    if (settings.inputs_outputs.length > 0) {
      settings.inputs_outputs = settings.inputs_outputs.filter(
        item => item.id !== id
      );
    }
    this.setSetting(settings);
    const changed = IsChanged(settings, this);
    this.props.handleChanged(changed);
  };
  render() {
    const { disabled } = this.props;
    let { access_type_object, settings } = this.state;
    const { execute, input_commands, docker_variables } = settings;
    let access = '';
    if (access_type_object) {
      access = access_type_object.name;
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start"
        spacing={2}>
        <Grid container item spacing={2} style={{ width: '100%' }} xs={12}>
          <Grid item xs={12}>
            <TabWidgetDockerInputs
              defaultSetting={this.props.defaultSetting}
              disabled={disabled}
              handleAddInput={this.handleAddInput}
              handleAddOutput={this.handleAddOutput}
              handleChanged={this.props.handleChanged}
              handleRemoveInput={id => this.handleRemoveInput(id)}
              handleRemoveOutput={id => this.handleRemoveOutput(id)}
              handleUpdateSetting={settings => this.setState({ settings })}
              holder={this.holder}
              settings={settings}
              uploading={this.state.uploading}
            />
          </Grid>
          <Grid item style={{ width: '100%' }} xs={12}>
            <TextField
              disabled={disabled}
              fullWidth
              id="docker-command"
              InputLabelProps={{
                shrink: true,
              }}
              label="Input arguments for docker command"
              onChange={this.handleDockerCommand}
              placeholder="Type the input arguments for docker command execution  (e.g. --force variable1)..."
              value={input_commands}
              variant="filled"
            />
          </Grid>
          <Grid item style={{ width: '100%' }} xs={12}>
            <TextField
              disabled={disabled}
              fullWidth
              id="docker-variable"
              InputLabelProps={{
                shrink: true,
              }}
              label="Input variables for docker"
              onChange={this.handleDockerVariable}
              placeholder="Type the input variables for docker  (e.g. --gpus all)..."
              value={docker_variables}
              variant="filled"
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={execute}
                    onChange={this.handleChangeExecute}
                  />
                }
                label="Execute after a file is uploaded"
                style={{ margin: 0, padding: 0 }}
              />
            </Grid>
            <Grid item style={{ width: '100%' }} xs={6}>
              <SearchAutocomplete
                disabled={this.state.loading}
                handleAddResultObject={this.handleSelectedAccessType}
                label="Access Type"
                name={access}
                no_icon
                options={options_access}
                placeholder="Select who can run this docker..."
                selected={access_type_object}
                setObject={this.handleSelectedAccessType}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

DockerInputs.propTypes = {
  disabled: PropTypes.bool,
};
DockerInputs.defaultProps = {
  handleChanged: () => '',
};
export default DockerInputs;
