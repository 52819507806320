export const useStyles = theme => ({
  main_container: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: '99.7%',
    height: '100%',
  },
  root_channels: {
    paddingTop: theme.spacing(3),
    height: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'auto',
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
});
