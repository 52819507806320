import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clean_manager_error,
  copy,
  cut,
  paste,
} from 'redux-store/actions/file_folder_manager';

class ExplorerMenuPaste extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.holder) {
      props.holder.openPaste = () => this.handlePaste();
    }
  }
  handlePaste = () => {
    const {
      itemOver,
      CurrentFolder,
      cut_objects,
      copy_objects,
      copy_series,
      copy_files,
      size,
    } = this.props;
    console.log({ size, cut_objects, copy_objects, copy_series, copy_files });
    if (itemOver || size) {
      this.props.paste(
        CurrentFolder,
        cut_objects,
        copy_objects,
        copy_series,
        copy_files
      );
    } else {
      this.props.handleAddError(
        'To Paste you need to select (Folder/Object) first.'
      );
    }
  };
  render() {
    return null;
  }
}

ExplorerMenuPaste.propTypes = {
  itemOver: PropTypes.object,
};
const mapStateToProps = state => {
  const {
    cut,
    copy,
    copy_series,
    copy_files,
    loading_paste,
    error_manager,
    warning_manager,
  } = state.manager;
  const objects = cut
    .concat(copy)
    .concat(copy_series)
    .concat(copy_files);

  return {
    cut_objects: cut,
    copy_objects: copy,
    copy_series,
    copy_files,
    loading_paste,
    error_manager,
    warning_manager,
    size: objects.length,
  };
};
export default connect(mapStateToProps, {
  cut,
  copy,
  paste,
  clean_manager_error,
})(ExplorerMenuPaste);
