import {
  MutationDeleteFileMapTypes,
  MutationDeleteSeriesTypes,
  QueryTypesAtFile,
  QueryTypesAtSeries,
} from 'graphql/Mapping';

export const QueryVariableType = (idSeries, idFileObject, page, limit) => {
  if (idSeries > 0) return QueryTypesAtSeries(idSeries, page, limit);
  else {
    return QueryTypesAtFile(idFileObject, page, limit);
  }
};
export const GetDataTypes = data => {
  if (data.typesAtSeries) return data.typesAtSeries;
  else return data.typesAtFile;
};

export const MutationDeleteVariableTypes = (isSeries, ids) => {
  if (isSeries) return MutationDeleteSeriesTypes(ids);
  else return MutationDeleteFileMapTypes(ids);
};
export const GetDeleteDataTypes = data => {
  if (data.deleteSeriesTypes) return data.deleteSeriesTypes;
  else return data.deleteFileObjectTypes;
};
