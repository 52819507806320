import {
  CREATE_PROJECT_CREATED,
  CREATE_PROJECT_SETTINGS,
  CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE,
  CREATE_PROJECT_SETTINGS_IMAGE_TYPES,
  CREATE_PROJECT_SETTINGS_NOTIFICATIONS,
  CREATE_PROJECT_SETTINGS_SITES,
  CREATE_PROJECT_TEAMS,
  CREATE_PROJECT_TYPE,
  CREATE_PROJECT_USERS,
} from './types';

export const projectCreated = status => dispatch => {
  dispatch({
    type: CREATE_PROJECT_CREATED,
    status,
  });
};

export const projectCreateUsers = users => dispatch => {
  dispatch({
    type: CREATE_PROJECT_USERS,
    users: users,
  });
};
export const projectCreateTeams = teams => dispatch => {
  //
  dispatch({
    type: CREATE_PROJECT_TEAMS,
    teams: teams,
  });
};

export const projectCreateSettingsCenters = centers => dispatch => {
  dispatch({
    type: CREATE_PROJECT_SETTINGS_SITES,
    centers,
  });
};
export const projectCreateSettingsNotification = notifications => dispatch => {
  dispatch({
    type: CREATE_PROJECT_SETTINGS_NOTIFICATIONS,
    notifications: notifications,
  });
};

export const projectCreateType = project_type => dispatch => {
  dispatch({
    type: CREATE_PROJECT_TYPE,
    project_type: project_type,
  });
};
export const projectCreateSettingsImageType = img_type => dispatch => {
  dispatch({
    type: CREATE_PROJECT_SETTINGS_IMAGE_TYPES,
    img_type: img_type,
  });
};
export const projectCreateSettingsFolderStructure = folder_structure => dispatch => {
  dispatch({
    type: CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE,
    folder_structure: folder_structure,
  });
};
export const projectCreateSettings = project_types => dispatch => {
  dispatch({
    type: CREATE_PROJECT_SETTINGS,
    payload: project_types,
  });
};
