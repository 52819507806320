import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper } from '@mui/material';
import { rowsPerPageBig } from 'helpers';
import { BoxWaiting, TableGrid, TableRights, CardEditHeader } from 'components';
import { connect } from 'react-redux';
import {
  columns,
  columns_projects,
  columns_folders,
  ToolBarSummary,
  FileInfoDetails,
  queryFileInfos,
  DialogAddObjectToProject,
} from './components';
import { copySeries, copyFiles } from 'redux-store/actions/file_folder_manager';
class TabSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_infos: [],
      row_highlight: null,
      open_edit: false,
      projects: [],
      rights: [],
      selected: [],
      folder_contents: [],
      page: 0,
      limit: 50,
      open_project: false,
    };
  }
  componentDidMount() {
    queryFileInfos(this);
  }
  getProjectRightAtRow = row_highlight => {
    let rights = [];
    let list_projects = [];
    if (row_highlight) {
      const { projects, folder_contents } = row_highlight;
      for (let j = 0; j < folder_contents.length; j++) {
        const { Rights } = folder_contents[j];
        if (Rights === undefined) continue;
        for (let i = 0; i < Rights.length; i++) {
          const { idRight } = Rights[i];
          const index = rights.map(x => x.idRight).indexOf(idRight);
          if (index === -1) rights.push(Rights[i]);
        }
      }
      for (let j = 0; j < projects.length; j++) {
        const index = list_projects.map(x => x.id).indexOf(projects[j].id);
        if (index === -1) list_projects.push(projects[j]);
      }
    }
    return { rights, projects: list_projects };
  };
  handleReloadTable = () => {
    this.setState({
      projects: [],
      rights: [],
      selected: [],
      folder_contents: [],
      row_highlight: null,
    });
    queryFileInfos(this);
  };
  copySeriesToExplorer = () => {
    const { selected } = this.state;
    const { copy_series, copy_files } = this.props;

    const total = copy_series.length;
    const total_files = copy_files.length;
    for (let i = 0; i < selected.length; i++) {
      const { idObject, type, name, createdAt, updatedAt } = selected[i];
      if (type === 'Series') {
        const index = copy_series.map(x => x.idSeries).indexOf(idObject);
        if (index === -1) {
          copy_series.push({
            idSeries: idObject,
            SeriesDescription: name,
            createdAt,
            updatedAt,
          });
        }
      } else {
        const index = copy_files.map(x => x.idFileObject).indexOf(idObject);
        if (index === -1) {
          copy_files.push({
            idFileObject: idObject,
            original_name: name,
            createdAt,
            updatedAt,
          });
        }
      }
    }
    const success = total < copy_series.length;
    const success_files = total_files < copy_files.length;
    // console.log({ total, copy_series, l: copy_series.length });
    // console.log({ total_files, copy_files, l: copy_files.length });
    if (success) {
      this.props.copySeries(copy_series);
      this.props.handleAddSuccess(
        `${copy_series.length} series copied for explorer`
      );
    } else if (total !== copy_series.length) {
      this.props.handleAddError('The series are already copied');
    }
    if (success_files) {
      this.props.copyFiles(copy_files);
      this.props.handleAddSuccess(
        `${copy_files.length} files copied for explorer`
      );
    } else if (total_files !== copy_files.length) {
      this.props.handleAddError('The files are already copied');
    }
  };
  render() {
    let { height } = this.props;
    const { loading, selected, file_infos } = this.state;
    const row_styles = file_infos
      .filter(x => !x.healthy)
      .map(x => {
        const { idObject } = x;
        return {
          idObject,
          style: {
            backgroundColor: '#ff8a80',
          },
        };
      });
    if (this.state.open_edit) {
      return (
        <FileInfoDetails
          handleClose={() => this.setState({ open_edit: false })}
          selected={this.state.selected}
        />
      );
    }
    let { projects, rights, row_highlight } = this.state;
    let rows_folders = [];
    let rows_selected_contents = selected;
    if (row_highlight && !selected.length) {
      const reply = this.getProjectRightAtRow(row_highlight);
      projects = reply.projects;
      rights = reply.rights;
      rows_selected_contents = [row_highlight];
    }
    rows_selected_contents.forEach(x => {
      const contents = x.folder_contents;
      rows_folders = rows_folders.concat(
        x.folders.map(y => {
          const i = contents.map(x => x.idFolder).indexOf(y.idFolder);
          let idFolderContent = null;
          if (i !== -1) idFolderContent = contents[i].idFolderContent;
          return {
            ...y,
            element: x.name,
            idObject: x.idObject,
            idFolderContent,
          };
        })
      );
    });
    // console.log({ row_highlight, folder_contents });
    return (
      <Paper sx={{ margin: 0.5, height: height - 125 }}>
        <Grid container>
          <Grid item xs={12}>
            <CardEditHeader
              description="Click in the row to display element information (Rights or Projects)"
              handleClose={this.props.handleClose}
              handleReload={this.handleReloadTable}
              loading={this.state.loading}
              show_reload
              title="Edit selected elements"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <BoxWaiting />
            </Grid>
          ) : (
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <ToolBarSummary
                  folder_contents={this.state.folder_contents}
                  handleOpenCreate={() => this.setState({ open_project: true })}
                  handleOpenEdit={this.copySeriesToExplorer}
                  selected={this.state.selected}
                />
              </Grid>
              <Grid item xs={12}>
                <TableGrid
                  checkboxSelection
                  dense
                  disableSelectionOnClick
                  external_selected={this.state.selected}
                  headers={columns}
                  height={height - 600}
                  idRow="idObject"
                  loading={loading}
                  onHighlightChange={row_highlight =>
                    this.setState({ row_highlight })
                  }
                  onPageChange={page => this.setState({ page })}
                  onRowClicked={row_highlight =>
                    this.setState({ row_highlight })
                  }
                  onRowsPerPageChange={limit => this.setState({ limit })}
                  onSelectedChanged={selected => this.setState({ selected })}
                  page={this.state.page}
                  row_styles={row_styles}
                  rows={file_infos}
                  rowsPerPage={this.state.limit}
                  rowsPerPageOptions={rowsPerPageBig}
                />
                <Divider />
              </Grid>
              {/* <Grid item xs={12}></Grid> */}
              <Grid item xs={12}>
                <Paper sx={{ margin: 1 }}>
                  <TableGrid
                    checkboxSelection
                    dense
                    disableSelectionOnClick
                    external_selected={this.state.folder_contents}
                    headers={columns_folders}
                    height={height - 740}
                    idRow="idFolderContent"
                    loading={loading}
                    onPageChange={page => this.setState({ page })}
                    onRowClicked={this.handleRowClicked}
                    onRowsPerPageChange={limit => this.setState({ limit })}
                    onSelectedChanged={folder_contents =>
                      this.setState({ folder_contents })
                    }
                    page={this.state.page}
                    rows={rows_folders}
                    rowsPerPage={this.state.limit}
                    rowsPerPageOptions={rowsPerPageBig}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ margin: 1 }}>
                  <TableRights add_id rights={rights} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ margin: 1 }}>
                  <TableGrid
                    checkboxSelection
                    dense
                    disableSelectionOnClick
                    external_selected={this.state.selected}
                    headers={columns_projects}
                    // height={height - 70}
                    // idRow="idFolder"
                    // loading={loading}
                    onPageChange={page => this.setState({ page })}
                    onRowsPerPageChange={limit => this.setState({ limit })}
                    onSelectedChanged={selected => this.setState({ selected })}
                    page={this.state.page}
                    rows={projects}
                    rowsPerPage={this.state.limit}
                    // rowsPerPageOptions={rowsPerPageBig}
                  />
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
        <DialogAddObjectToProject
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_project: false })}
          open={this.state.open_project}
          selected={this.state.folder_contents}
        />
      </Paper>
    );
  }
}

TabSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let size = copy_series ? copy_series.length : 0;
  if (copy_files) size += copy_files.length;
  return {
    copy_series: copy_series ? copy_series : [],
    copy_files: copy_files ? copy_files : [],
    size,
  };
};

export default connect(mapStateToProps, { copySeries, copyFiles })(TabSummary);
