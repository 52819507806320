import {
  PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
  PAGE_PROJECTS_INIT,
  PAGE_PROJECTS_LOAD_PROJECTS,
  PAGE_PROJECTS_PROJECT_TEAMS,
  PAGE_PROJECTS_SAVING,
  PAGE_PROJECTS_SET_NOTIFICATIONS,
  PAGE_PROJECTS_SET_PROJECT,
  PAGE_PROJECTS_UPDATE_PROJECTS,
  PAGE_PROJECTS_WARNING,
} from './types';
import {
  GetData,
  loadProject,
  projectAddNewTeam,
  projectCreateNewTeam,
  projectNotifications,
  projectsAtName,
  teamsAtProject,
  updateNotifications,
  updateProjectDetails,
} from 'helpers';
import { logout } from 'redux-store/actions';

export const updatePageProjects = status => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_LOAD_PROJECTS,
    update: status,
  });
};

export const createNewTeamForProject = (projectId, name) => dispatch => {
  projectCreateNewTeam(projectId, name)
    .then(res => {
      const data = GetData(res);
      const { projectCreateNewTeam } = data;

      const { ok, errors } = projectCreateNewTeam;
      if (ok) {
        dispatch({
          type: PAGE_PROJECTS_LOAD_PROJECTS,
          update: true,
        });
      } else {
        dispatch({
          type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
          errors: errors,
        });
      }
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors: errors,
      });
      dispatch({
        type: PAGE_PROJECTS_LOAD_PROJECTS,
        update: false,
      });
    });
};
export const addNewTeamToProject = (projectId, name) => dispatch => {
  projectAddNewTeam(projectId, name)
    // eslint-disable-next-line no-unused-vars
    .then(res => {
      //const data = GetData(res);
      dispatch({
        type: PAGE_PROJECTS_LOAD_PROJECTS,
        update: true,
      });
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors: errors,
      });
      dispatch({
        type: PAGE_PROJECTS_LOAD_PROJECTS,
        update: false,
      });
    });
};

export const updateCurrentProjectTeams = (projectId, name) => dispatch => {
  teamsAtProject(projectId, name)
    .then(res => {
      const data = GetData(res);
      const { teamsAtProject } = data;

      dispatch({
        type: PAGE_PROJECTS_PROJECT_TEAMS,
        teams: teamsAtProject,
      });
      dispatch({
        type: PAGE_PROJECTS_LOAD_PROJECTS,
        update: false,
      });
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors: errors,
      });
      dispatch({
        type: PAGE_PROJECTS_LOAD_PROJECTS,
        update: false,
      });
    });
};

export const saveProjectNotifications = new_notifications => dispatch => {
  updateNotifications(new_notifications)
    .then(res => {
      const data = GetData(res);
      const { updateNotifications } = data;

      if (updateNotifications) {
        // const { projectId } = updateNotifications;
        // if (projectId) {
        //   dispatch({
        //     type: PAGE_PROJECTS_SET_NOTIFICATIONS,
        //     notifications: notifications
        //   });
        // } else {
        //   dispatch({
        //     type: PAGE_PROJECTS_SET_NOTIFICATIONS,
        //     notifications: {
        //       id: -1,
        //       projectId: id,
        //       email: false,
        //       upload: false,
        //       user_chat: false,
        //       team_chat: false
        //     }
        //   });
        // }
      }
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors,
      });
    });
};

export const setNotifications = id => dispatch => {
  projectNotifications(id)
    .then(res => {
      const data = GetData(res);

      const { notifications } = data;
      if (notifications) {
        const { projectId } = notifications;
        if (projectId === id) {
          dispatch({
            type: PAGE_PROJECTS_SET_NOTIFICATIONS,
            notifications: notifications,
          });
        } else {
          dispatch({
            type: PAGE_PROJECTS_INIT,
            projectId: id,
          });
        }
      } else {
        dispatch({
          type: PAGE_PROJECTS_INIT,
          projectId: id,
        });
      }
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors,
      });
    });
};

export const setProjectWarning = warning => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_WARNING,
    warning: warning,
  });
};

export const saveProjectDetails = new_details => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_SAVING,
    saving: true,
  });
  updateProjectDetails(new_details)
    .then(res => {
      const data = GetData(res);

      const { updateProjectDetails } = data;
      if (updateProjectDetails) {
        const { ok, project } = updateProjectDetails;
        if (ok) {
          const errorName = updateProjectDetails.errors;
          dispatch({
            type: PAGE_PROJECTS_SET_PROJECT,
            project: {
              ...new_details,
              name: project.name,
            },
          });
          dispatch({
            type: PAGE_PROJECTS_LOAD_PROJECTS,
            update: true,
          });
          if (errorName.length) {
            dispatch({
              type: PAGE_PROJECTS_WARNING,
              warning: errorName.map(e => e.message),
            });
          }
        }
      }
      dispatch({
        type: PAGE_PROJECTS_SAVING,
        saving: false,
      });
    })
    .catch(errors => {
      dispatch({
        type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
        errors,
      });
      dispatch({
        type: PAGE_PROJECTS_SAVING,
        saving: false,
      });
    });
};

export const selectCurrentProject = project => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_SET_PROJECT,
    project,
  });
};

export const loadProjects = update => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_UPDATE_PROJECTS,
    update,
  });
};
export const savingProjects = saving => dispatch => {
  dispatch({
    type: PAGE_PROJECTS_SAVING,
    saving,
  });
};

export const updateCurrentProjects = name => dispatch => {
  projectsAtName(name)
    .then(res => {
      const data = GetData(res);

      const { projectsAtName } = data;

      if (projectsAtName && projectsAtName.length) {
        dispatch({
          type: PAGE_PROJECTS_UPDATE_PROJECTS,
          projects: projectsAtName,
        });
        dispatch({
          type: PAGE_PROJECTS_LOAD_PROJECTS,
          update: false,
        });
      }
    })
    .catch(errors => {
      try {
        const { message } = errors[0];
        if (message === 'Not authenticated') {
          logout();
        } else {
          throw errors;
        }
      } catch (error) {
        dispatch({
          type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
          errors: errors,
        });
        dispatch({
          type: PAGE_PROJECTS_LOAD_PROJECTS,
          update: false,
        });
      }
    });
};
export const updatePageProjectErrors = errors => dispatch => {
  dispatch({
    type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
    errors: errors,
  });
};
export const loadCurrentProject = projectId => dispatch => {
  loadProject(projectId)
    .then(res => {
      const data = GetData(res);
      const { loadProject } = data;

      if (loadProject) {
        dispatch({
          type: PAGE_PROJECTS_SET_PROJECT,
          project: loadProject,
        });
      }
    })
    .catch(errors => {
      try {
        const { message } = errors[0];
        if (message === 'Not authenticated') {
          logout();
        } else {
          throw errors;
        }
      } catch (error) {
        dispatch({
          type: PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
          errors: errors,
        });
      }
    });
};
