import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetContentThumbnail } from 'helpers';
import { SharedGalleryItem } from './components';
import { isEqual } from '../../../utils_shared';

const useStyles = theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    width: '100%',
  },
  paper: {
    height: 110,
    width: 100,
  },
});

class ExplorerViewGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getThumbnail = row => {
    const { folder, content } = row;
    if (folder) return '';
    let Thumbnail = GetContentThumbnail(content);
    if (Thumbnail && !Thumbnail.startsWith('/images/')) {
      Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
    }
    return Thumbnail;
  };

  isSelected = row => {
    const { selected } = this.props;
    for (let i = 0; i < selected.length; i++) {
      if (isEqual(row, selected[i])) {
        console.log({ row, s: selected[i] });
        return true;
      }
    }
    return false;
  };

  render() {
    const { classes, contents, handleSelected, handleDoubleClick } = this.props;
    return (
      <Grid
        alignItems="flex-start"
        className={classes.root}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}>
        {contents.map((content, index) => {
          const isFolder = content.folder ? true : false;
          return (
            <Grid item key={`gallery-item-${index}`}>
              <SharedGalleryItem
                handleDoubleClick={handleDoubleClick}
                handleSelected={handleSelected}
                isFolder={isFolder}
                isSelected={this.isSelected(content)}
                item={content}
                name={content.name}
                Thumbnail={this.getThumbnail(content)}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

ExplorerViewGallery.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ExplorerViewGallery, useStyles);
