import React from 'react';
import PropTypes from 'prop-types';
import {
  QueryCorrectionFile,
  MutationSaveCorrectionFile,
} from 'graphql/Series/sequences/utils_sequence_classification';
import { GetData, ServerErrorsString } from 'helpers';
import ReactJson from 'react18-json-view';
import { Paper } from '@mui/material';
import { TabSequenceClassificationBar } from './components';
import { BoxWaiting } from 'components';
import { IsInvalid } from 'helpers';

class TabCentersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      loading: false,
      json_content: null,
      open: true,
      init: false,
    };
  }

  componentDidMount() {
    this.queryConfigurationFile();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryConfigurationFile();
  }
  queryConfigurationFile = () => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryCorrectionFile()
        .then(res => {
          const data = GetData(res);
          const { content, ok, errors } = data.getCorrectionFile;
          if (ok) {
            let json_content = JSON.parse(content);
            this.setState({
              loading: false,
              json_content,
              changed: false,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };
  mutationSaveConfigurationFile = () => {
    const { json_content } = this.state;
    if (this.state.loading) return;
    this.setState({ loading: true });
    const json = JSON.stringify(json_content);
    (async () => {
      MutationSaveCorrectionFile(json)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.saveConfigurationFile;
          if (ok) {
            console.log('Correctly saved!');
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = event => {
    event.preventDefault();
    this.queryConfigurationFile();
  };
  handleUpdateConfiguration = event => {
    const { updated_src } = event;
    console.log('handleUpdateConfiguration', { updated_src });
    this.setState({ json_content: updated_src, changed: true });
  };
  handleSaveConfig = () => {
    const { json_content } = this.state;
    console.log('saving', { json_content });
    this.mutationSaveConfigurationFile();
  };
  render() {
    const { loading, json_content } = this.state;
    let ComponentEditor = null;
    let height = this.props.height - 45;
    if (IsInvalid(height) || height < 250) {
      height = 250;
    }
    if (json_content) {
      ComponentEditor = (
        <ReactJson
          enableClipboard
          onAdd={this.handleUpdateConfiguration}
          onDelete={this.handleUpdateConfiguration}
          onEdit={this.handleUpdateConfiguration}
          src={json_content}
          style={{ fontSize: 12, lineHeight: 1.4 }}
        />
      );
    }
    if (loading) {
      ComponentEditor = <BoxWaiting />;
    }
    return (
      <div style={{ marginTop: '5px', paddingBottom: '20px', height: '100%' }}>
        <TabSequenceClassificationBar
          changed={this.state.changed}
          handleReload={this.queryConfigurationFile}
          handleSave={this.handleSaveConfig}
          loading={loading}
        />
        <Paper style={{ padding: '10px', paddingBottom: 0 }}>
          <div style={{ width: '100%', height }}>
            <div
              style={{
                height: '100%',
                overflowY: 'scroll',
                flexGrow: 1,
              }}>
              {ComponentEditor}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

TabCentersView.propTypes = {
  classes: PropTypes.object,
};

export default TabCentersView;
