const useStyles = theme => ({
  main_container: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: '99.7%',
    height: '100%',
  },
  content_overview: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'scroll',
  },
  root_content_overview: {
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(2),
    width: '100%',
    height: '70vh',
  },
  grow: {
    flexGrow: 1,
  },
  files: {
    paddingTop: 10,
    paddingLeft: 1,
    paddingRight: 1,
    height: '100%',
    // width: '100%',
    // display: 'flex'
  },
});

export default useStyles;
