import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageList, ImageListItem } from '@mui/material';
import { SeriesFilesQuery } from 'graphql/Series';
import { GetData, ServerErrors } from 'helpers';
import { connect } from 'react-redux';
import { SeriesFile, LoadMoreData } from './components';
import { IsInvalid } from 'helpers';

class SeriesFilesThumbnails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      files: [],
      selected: -1,
      error: '',
      idSeries: null,
      total: 0,
      page: 0,
      limit: props.limit ? props.limit : null,
      empty: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('keydown', this.keyDown);
    this.updateSeriesFiles();
  }

  componentDidUpdate() {
    this.updateSeriesFiles();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('keydown', this.keyDown);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  getSeriesTotal = Series => {
    if (IsInvalid(Series)) return 0;
    const { NumberOfSeriesRelatedInstances, NumberOfFrames } = Series;
    if (NumberOfFrames > 1) return NumberOfFrames;
    return NumberOfSeriesRelatedInstances;
  };
  getGridListCols = () => {
    let { width, isDesktop } = this.props;
    if (!width) {
      width = window.innerWidth;
    }
    width = width - 100;
    if (isDesktop) {
      width = width - 300;
    }
    const cols = parseInt(width / 90, 10);
    const { Series } = this.props;
    const total = this.getSeriesTotal(Series);
    if (total < cols) {
      return total;
    }
    if (cols) {
      return cols;
    }
    return 1;
  };
  getGridListEmpty = () => {
    const { Series } = this.props;
    if (!Series) return 0;
    const total_files = this.getSeriesTotal(Series);
    const rows = parseInt(total_files / this.getGridListCols(), 10);
    const total = Math.ceil(window.innerHeight / 170);
    const empty = total - rows;
    //
    if (empty <= 0) return 0;
    return empty;
  };
  getGridListRows = () => {
    const { Series } = this.props;
    if (!Series) return 0;
    const total_files = this.getSeriesTotal(Series);
    const rows = Math.ceil(total_files / this.getGridListCols(), 10);
    return rows;
  };
  getMatrixPosition = index => {
    const cols = this.getGridListCols();
    const y = Math.floor(index / cols);
    const x = index - y * cols;
    return { x, y };
  };
  keyDown = e => {
    // console.log({ e, repeat: e.repeat });
    let { selected, files } = this.state;
    let { Series } = this.props;
    const cols = this.getGridListCols();
    const rows = this.getGridListRows();
    if (selected < 0) return;
    //
    if (e.key === 'ArrowLeft') {
      selected--;
    } else if (e.key === 'ArrowRight') {
      selected++;
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let { x, y } = this.getMatrixPosition(selected);
      if (e.key === 'ArrowUp') y--;
      if (e.key === 'ArrowDown') y++;
      if (y < 0) {
        selected = (rows - 1) * cols + x;
      } else if (y >= rows) {
        selected = x;
      } else {
        selected = y * cols + x;
      }
    } else {
      return;
    }
    const total_files = this.getSeriesTotal(Series);
    if (selected < 0) {
      selected = total_files - 1;
    } else if (selected >= total_files) {
      selected = 0;
    }
    this.setState({ selected });
    this.props.handleOnClick(files[selected], selected);
  };
  updateSeriesFiles = () => {
    const { loading, error, idSeries, page, limit, empty } = this.state;

    if (loading || error.length || empty) {
      return;
    }
    const { Series } = this.props;
    if (Series === undefined || !Series) return;
    const idNewSeries = Series.idSeries;
    const { NumberOfFrames } = Series;
    let current_files = this.state.files;
    if (current_files.length && idSeries === idNewSeries) return;
    this.setState({ loading: true, error: '', empty: false });
    (async () => {
      SeriesFilesQuery(idNewSeries, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, files, total } = data.seriesFiles;
          if (ok) {
            if (idSeries) {
              current_files = [];
            }
            for (let i = 0; i < files.length; i++) {
              let to_find = files[i].idFile;
              if (NumberOfFrames > 1) {
                to_find = files[i].InstanceNumber;
              }
              const index = current_files
                .map(x => {
                  if (NumberOfFrames > 1) return x.InstanceNumber;
                  return x.idFile;
                })
                .indexOf(to_find);
              if (index === -1) {
                current_files.push(files[i]);
              }
            }
            const isEmpty = current_files.length || total ? false : true;
            this.setState({
              loading: false,
              files: current_files,
              idSeries: idNewSeries,
              total,
              empty: isEmpty,
            });
            if (isEmpty) {
              this.props.handleError(`Series (${idNewSeries}) is empty`);
            }
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const error = ServerErrors(errors);
          this.setState({
            loading: false,
            error: `- ${error.join('\n- ')}`,
          });
          this.props.handleError(error.join('\n'));
        });
    })();
  };
  handleClickFileItem = (index, file) => {
    this.setState({ selected: index });
    this.props.handleOnClick(file, index);
  };

  render() {
    const { Series, height } = this.props;
    if (Series === undefined || !Series) return null;
    const { loading, files, selected, page, limit } = this.state;
    const emptyRows = this.getGridListEmpty();
    let total_files = this.getSeriesTotal(Series);
    let load_more = false;
    let footer = 0;
    if (total_files > 100 && limit) {
      load_more = true;
      if (files.length > 0) {
        total_files = files.length;
      } else {
        total_files = 100;
      }
      footer = 50;
    }
    let list = Array.from(new Array(total_files));
    if (files.length) {
      list = files;
    }
    const { Modality } = Series;
    let style = {
      height: window.innerHeight - 250 - footer,
    };
    if (height) {
      style = {
        height: height - footer,
      };
    }
    // console.log({ current_size: files.length, a: list.length });
    return (
      <React.Fragment>
        <ImageList cols={this.getGridListCols()} rowHeight={135} style={style}>
          {list.map((item, index) => {
            return (
              <ImageListItem key={index} style={{ width: 'auto' }}>
                <SeriesFile
                  handleOnClick={this.handleClickFileItem}
                  index={index}
                  item={loading ? null : item}
                  Modality={Modality}
                  selected={selected}
                />
              </ImageListItem>
            );
          })}
          {emptyRows > 0 && (
            <ImageListItem style={{ height: 135 * emptyRows - 90 }} />
          )}
        </ImageList>
        {load_more && (
          <LoadMoreData
            limit={limit}
            onChangePage={page => this.setState({ page, idSeries: null })}
            page={page}
            total={Series.NumberOfSeriesRelatedInstances}
          />
        )}
      </React.Fragment>
    );
  }
}

SeriesFilesThumbnails.propTypes = {
  classes: PropTypes.object,
  handleError: PropTypes.func,
};
SeriesFilesThumbnails.defaultProps = {};

const mapStateToProps = state => ({
  user: state.info_user.user,
  isDesktop: state.layout.isDesktop,
});
export default connect(mapStateToProps)(SeriesFilesThumbnails);
