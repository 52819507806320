import { IsInvalid, IsInvalidParam } from 'helpers';
import { FormatTime } from 'helpers';

export const columns_account_clinics = [
  {
    field: 'id',
    headerName: 'ID',
    align: 'left',
  },
  {
    field: 'account_type',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'account_type');
      if (IsInvalid(value)) return '';
      return value.description;
    },
  },
  {
    field: 'clinical_type',
    headerName: 'Clinical type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'clinical_type');
      if (IsInvalid(value)) return '';
      return value.description;
    },
  },
  // {
  //   field: 'admin',
  //   headerName: 'Docker Admin',
  //   align: 'left',
  //   valueGetter: params => {
  //     if (IsInvalid(params)) return '';
  //     if (params.getValue === undefined) return '';
  //     const value = params.getValue(params.id, 'docker_image');
  //     if (IsInvalid(value)) return '';
  //     const { user } = value;
  //     if (IsInvalid(user)) return '';
  //     return getUserFullName(user);
  //   },
  // },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
