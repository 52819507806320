import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { QueryRunSummaryModalities } from 'graphql/Series';
import { ButtonReload } from 'components';
import { useStyles, getData, getEmpty, options } from './components';
import { ServerErrorsString, GetData, IsInvalid } from 'helpers';
import { getVariables } from 'common';
import { initialState } from 'redux-store/reducers/page_settings';

class LatestSeriesTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
    };
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const variables = {
      page: 0,
      limit: 1000,
      ...getVariables(this.props),
    };
    (async () => {
      QueryRunSummaryModalities(variables)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SummaryModalities;
          if (ok) {
            this.setState({
              data: getData(data.SummaryModalities),
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, className } = this.props;
    let { data, loading } = this.state;
    if (IsInvalid(data)) data = getEmpty();
    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="center"
              spacing={0}>
              <ButtonReload
                handleReload={() => this.handleLoad()}
                loading={loading}
                small
                tooltip="Series Modalities"
              />
            </Grid>
          }
          className={classes.header}
          title="Latest Series Modalities"
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainerSales}>
            <Bar data={data} options={options} redraw />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button color="primary" size="small" variant="text">
            Overview <ArrowRightIcon />
          </Button>
        </CardActions>
      </Card>
    );
  }
}

LatestSeriesTypes.propTypes = {
  className: PropTypes.string,
};
const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};
export default connect(mapStateToProps, { logout })(
  withStyles(LatestSeriesTypes, useStyles)
);
