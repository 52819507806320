import { grey } from '@mui/material/colors';

const useStyles = theme => ({
  root_table_container: {
    height: '100%',
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide_menu: {
    display: 'none',
  },
  table: {
    flexShrink: 0,
    position: 'relative',
    // tableLayout: 'fixed',
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
  tableRow: {
    height: 10,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'blue',
      },
    },
  },
  empty: {},
  download: {
    padding: 0,
    margin: 0,
  },
});

export default useStyles;
