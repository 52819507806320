import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SUMMARY_UNIT = gql`
  query($idUnit: LongLong!) {
    summaryUnit(idUnit: $idUnit) {
      ok
      summary {
        id
        idUnit
        setting {
          read
          receive
          find
          status
        }
        unit {
          idUnit
          Admin {
            id
            email
            FullName
          }
          name
          api
          ip
          setting {
            read
          }
        }
        users {
          value
          per
          up
        }
        patients {
          value
          per
          up
        }
        studies {
          value
          per
          up
        }
        series {
          value
          per
          up
        }
        files {
          value
          per
          up
        }
        dicoms {
          value
          per
          up
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QuerySummaryUnit = idUnit => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_UNIT),
    variables: {
      idUnit,
    },
  });
};
const MUTATION_UPDATE_SUMMARY = gql`
  mutation {
    updateSummary {
      ok
      summary {
        id
        idUnit
        unit {
          idUnit
          Admin {
            id
            email
            FullName
          }
          name
          api
          ip
          setting {
            read
          }
        }
        users {
          value
          per
          up
        }
        patients {
          value
          per
          up
        }
        studies {
          value
          per
          up
        }
        series {
          value
          per
          up
        }
        files {
          value
          per
          up
        }
        dicoms {
          value
          per
          up
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateSummary = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SUMMARY),
    //   variables: {
    //     idUnit,
    //   },
  });
};

const QUERY_SUMMARY_MY_UNITS = gql`
  query {
    summaryMyUnits {
      id
      idUnit
      unit {
        idUnit
        Admin {
          id
          email
          FullName
        }
        name
        api
        ip
        setting {
          read
        }
      }
      users {
        value
        per
        up
      }
      patients {
        value
        per
        up
      }
      studies {
        value
        per
        up
      }
      series {
        value
        per
        up
      }
      files {
        value
        per
        up
      }
      dicoms {
        value
        per
        up
      }
    }
  }
`;

export const QuerySummaryOfMyUnits = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_MY_UNITS),
    // variables: {
    //   idUnit,
    // },
  });
};
