import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_CLINICAL_ACCOUNTS = gql`
  query($idAccountType: LongLong!, $page: Int, $limit: Int) {
    accountClinicalData(
      idAccountType: $idAccountType
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total

      account_clinicals {
        id
        idAccountType
        idClinicalDataType
        account_type {
          id
          code
          description
        }
        clinical_type {
          id
          code
          description
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryAccountClinicalData = (idAccountType, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CLINICAL_ACCOUNTS),
    variables: {
      idAccountType,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_CLINICAL_ACCOUNTS = gql`
  mutation($inputs: [InputAccountClinicalData!]!) {
    addAccountClinicalData(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      account_clinicals {
        id
        idAccountType
        idClinicalDataType
        account_type {
          id
          code
          description
        }
        clinical_type {
          id
          code
          description
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationAddAccountClinicalData = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_CLINICAL_ACCOUNTS),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_CLINICAL_ACCOUNTS = gql`
  mutation($inputs: [InputAccountClinicalData!]!) {
    removeAccountClinicalData(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveAccountClinicalData = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_CLINICAL_ACCOUNTS),
    variables: {
      inputs,
    },
  });
};
