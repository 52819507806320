import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Grid, TextField } from '@mui/material';
import {
  runSearchSequence,
  runSearchTypes,
  runSearchTypeValues,
} from './components';

class SeriesMapFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length_type: 0,
      name_type: '',
      loading_types: false,
      types: [],
      selected_types: [],
      length_value: 0,
      name_value: '',
      loading_values: false,
      type_values: [],
      selected_values: [],
      length_sequence: 0,
      name_sequence: '',
      loading_sequences: false,
      sequences: [],
      selected_sequences: [],
    };
    const { holder } = props;
    if (holder) {
      holder.getFilterMap = () => {
        const {
          selected_types,
          selected_sequences,
          selected_values,
        } = this.state;
        let input = null;
        if (selected_types.length)
          input = { names: selected_types.map(x => x.name) };
        if (selected_sequences.length) {
          input = {
            ...input,
            ids_sequences: selected_sequences.map(x => x.idSequence),
          };
        }
        if (selected_values.length) {
          input = {
            ...input,
            values: selected_values.map(x => x.name),
          };
        }
        return input;
      };
    }
  }
  componentDidMount() {
    runSearchTypes(null, this);
    runSearchSequence(null, null, this);
    runSearchTypeValues(null, this);
  }
  componentDidUpdate() {
    const { length_sequence, name_sequence } = this.state;
    if (length_sequence !== name_sequence.length) {
      runSearchSequence(name_sequence, null, this);
    }
  }
  handleSequenceChanged = selected_sequences => {
    console.log({ selected_sequences });
    this.setState({ selected_sequences });
    this.handleFilterCount({ ...this.state, selected_sequences });
  };
  handleTypeChanged = selected_types => {
    console.log({ selected_types });
    this.setState({ selected_types });
    this.handleFilterCount({ ...this.state, selected_types });
  };
  handleSequencehanged = selected_values => {
    console.log({ selected_values });
    this.setState({ selected_values });
    this.handleFilterCount({ ...this.state, selected_values });
  };
  handleFilterCount = state => {
    let total = state.selected_values.length;
    total += state.selected_types.length;
    total += state.selected_sequences.length;
    this.props.onFilterChanged(total);
  };
  render() {
    const {
      types,
      type_values,
      sequences,
      selected_types,
      selected_sequences,
    } = this.state;
    selected_types.forEach(type => {
      const i = types.map(x => x.id).indexOf(type.id);
      if (i === -1) types.push(type);
    });
    selected_sequences.forEach(sequence => {
      const i = sequences.map(x => x.idSequence).indexOf(sequence.idSequence);
      if (i === -1) sequences.push(sequence);
    });
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        spacing={1}
        sx={{ width: '100%' }}>
        <Grid item sm={4} xs={12}>
          <Autocomplete
            // defaultValue={[top100Films[13]]}
            getOptionLabel={option => option.name}
            id="types-multi"
            multiple
            onChange={(event, values) => this.handleTypeChanged(values)}
            onInputChange={(event, name) => runSearchTypes(name, this)}
            options={types}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Types"
                placeholder="Type"
                variant="standard"
              />
            )}
            size="small"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Autocomplete
            // defaultValue={[top100Films[13]]}
            getOptionLabel={option => option.name}
            id="type-multi-value"
            multiple
            onChange={(event, selected_values) =>
              this.handleSequencehanged(selected_values)
            }
            onInputChange={(event, name) => runSearchTypeValues(name, this)}
            options={type_values}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Type Values"
                placeholder="Type value"
                variant="standard"
              />
            )}
            size="small"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Autocomplete
            // defaultValue={[top100Films[13]]}
            getOptionLabel={option => option.name}
            id="sequence-multi"
            multiple
            onChange={(event, values) => this.handleSequenceChanged(values)}
            onInputChange={(event, name) => runSearchSequence(name, null, this)}
            options={sequences}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Sequence"
                placeholder="Sequence"
                variant="standard"
              />
            )}
            size="small"
          />
        </Grid>
      </Grid>
    );
  }
}

SeriesMapFilters.propTypes = {
  classes: PropTypes.object,
};

export default SeriesMapFilters;
