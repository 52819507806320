import React from 'react';
const style = {
  textAlign: 'justify',
  fontSize: 20,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};
export const dcm_nifti = {
  image: '/images/dcm_nifti.png',
  title: 'DICOM to NIfTI transformation',
  description: (
    <p style={style}>
      &nbsp; &nbsp; &nbsp;The <strong>NIfTI</strong> image format standard was
      designed for scientific analysis of medical images. Most of the scanners
      store medical image using the industry format standard{' '}
      <strong>DICOM</strong> .
      <br />
      &nbsp; &nbsp; &nbsp;We use the software <strong>dcm2niix</strong> that was
      designed to convert neuroimaging data from the DICOM format to the{' '}
      <strong>NIfTI</strong> format. This software is one of the most used to
      transform to <strong>NIfTI</strong> images. A full description of the
      program is located in{' '}
      <a
        href="https://www.nitrc.org/plugins/mwiki/index.php/dcm2nii:MainPage#General_Usage"
        rel="noreferrer"
        target="_blank">
        https://www.nitrc.org/plugins/dcm2nii
      </a>
      , and the source code is located in the GitHub repository:{' '}
      <a
        href="https://github.com/rordenlab/dcm2niix"
        rel="noreferrer"
        target="_blank">
        https://github.com/rordenlab/dcm2niix
      </a>
    </p>
  ),
};
