import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { ProjectNotifications } from 'components';
import { ProjectCreateCenters } from './components';
import { StepContainer } from '../index';
import { projectCreateSettingsNotification } from 'redux-store/actions/projectActions';

const StepConfigureSettings = props => {
  const { title } = props;
  let { notifications } = props;
  const handleChangedChecked = (name, checked) => {
    notifications = {
      ...notifications,
      [name]: checked,
    };

    props.projectCreateSettingsNotification(notifications);
  };
  return (
    <StepContainer title={title}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <ProjectNotifications
            handleChangedChecked={handleChangedChecked}
            notifications={notifications}
            title="Project Notifications"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ProjectCreateCenters handleAddError={props.handleAddError} />
        </Grid>
      </Grid>
    </StepContainer>
  );
};

StepConfigureSettings.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = state => {
  const {
    project_settings: { notifications },
  } = state.projects;
  return { notifications };
};

export default connect(mapStateToProps, {
  projectCreateSettingsNotification,
})(StepConfigureSettings);
