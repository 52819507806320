import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio } from '@mui/material';
import { DateRangePicker } from 'components';

class DateFromTo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      disable_from,
      selectedFromDate,
      selectedToDate,
    } = this.props;
    const sylte_radio = {
      size: 'small',
      style: {
        margin: 0,
        padding: 0,
        paddingTop: 5,
        marginBottom: 5,
      },
    };
    // console.log({ selectedFromDate, selectedToDate });
    return (
      <Grid>
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="From - To"
          value="from"
        />
        <DateRangePicker
          dateend={selectedToDate}
          datestart={selectedFromDate}
          disabled={disable_from}
          handleDateChange={this.props.handleFromDateChange}
          label=""
          text_size="small"
        />
      </Grid>
    );
  }
}

DateFromTo.propTypes = {
  classes: PropTypes.object,
};

export default DateFromTo;
