import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_JOB_PROCESS = gql`
  mutation($inputs: [InputJobProcess!]!) {
    updateJobProcesses(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateJobs = inputs => {
  console.log('MutationUpdateJobs', {
    inputs,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_JOB_PROCESS),
    variables: {
      inputs,
    },
  });
};

const MUTATION_DELETE_JOB_PROCESS = gql`
  mutation($ids: [LongLong!]!) {
    deleteJobs(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteJobProcess = ids => {
  console.log('MutationDeleteJobProcess', {
    ids,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_JOB_PROCESS),
    variables: {
      ids,
    },
  });
};

const MUTATION_CREATE_JOB_PROCESS = gql`
  mutation($inputs: [InputCreateJobProcess!]!) {
    createJobs(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      jobs {
        id
        idUser
        idDockerImage
        idSetting
        idBatch
        idSeries
        idFileObject
        idFolder

        status

        docker_image {
          id
          operation
        }

        user {
          id
          FullName
        }
        setting {
          id
          idJob
          job_name
          own_folder
          on_content
          idFolder
          error
          folder_content {
            idFolderContent
            project {
              id
              name
            }
            Folder {
              idFolder
              name
              folders_paths {
                idFolder
                name
              }
            }
          }
          folder_project {
            idFolder
            name
          }
        }
        series {
          idSeries
          SeriesDescription
        }
        file_object {
          idFileObject
          original_name
        }
        folder {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
      total
      fails {
        idSeries
        idFileObject
        error
      }
    }
  }
`;
export const MutationCreateJobProcess = inputs => {
  console.log('MutationCreateJobProcess', {
    inputs,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_JOB_PROCESS),
    variables: {
      inputs,
    },
  });
};

const QUERY_JOB_PROCESS = gql`
  query($idBatch: LongLong, $page: Int, $limit: Int) {
    jobsAtBatch(idBatch: $idBatch, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      jobs {
        id
        idUser
        idDockerImage
        idSetting
        idBatch
        idSeries
        idFileObject
        idFolder

        status

        docker_image {
          id
          operation
        }

        user {
          id
          FullName
        }
        setting {
          id
          idJob
          job_name
          own_folder
          on_content
          idFolder
          error
          folder_content {
            idFolderContent
            project {
              id
              name
            }
            Folder {
              idFolder
              name
              folders_paths {
                idFolder
                name
              }
            }
          }
          folder_project {
            idFolder
            name
          }
        }
        series {
          idSeries
          SeriesDescription
        }
        file_object {
          idFileObject
          original_name
        }
        folder {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryJobProcess = (idBatch, page, limit) => {
  console.log('QueryJobProcess', {
    idBatch,
    page,
    limit,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_JOB_PROCESS),
    variables: {
      idBatch,
      page,
      limit,
    },
  });
};
const MUTATION_EXECUTE_JOB = gql`
  mutation($idJob: LongLong!) {
    executeJob(idJob: $idJob) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationExecuteJob = idJob => {
  console.log('MutationExecuteJob', { idJob });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_EXECUTE_JOB),
    variables: { idJob },
  });
};
