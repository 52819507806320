import React from 'react';
import PropTypes from 'prop-types';
import { ToolbarActivity } from './components';
import { QueryNotifications } from 'graphql/Communications';
import { GetData, rowsPerPageBig } from 'helpers';
import { TableGrid } from 'components';
import { headers_activity } from './components/Style';

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
      notifications: [],
      total: 0,
      page: 0,
      limit: 100,
      row_clicked: null,
    };
    this.holder = {
      getPageLimit: null,
    };
  }

  componentDidMount() {
    const { page, limit } = this.state;
    this.handleReload(page, limit);
  }

  handleReload = (page = this.state.page, limit = this.state.limit) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryNotifications(page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            notifications: { ok, errors, notifications, total },
          } = data;
          if (ok) {
            this.setState({
              loading: false,
              notifications,
              total,
              page,
              limit,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, page, limit });
        });
    })();
  };

  render() {
    if (!this.props.isActive) return null;
    const { notifications, total } = this.state;
    const { height } = this.props;
    let internal_height = height - 505;
    if (internal_height < 200) {
      internal_height = null;
    }
    return (
      <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
        <ToolbarActivity
          handleAddError={this.props.handleAddError}
          handleReload={() => this.handleReload()}
          notifications={notifications}
          row_clicked={this.state.row_clicked}
          selected={this.state.selected}
          setState={state => this.setState(state)}
        />
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          headers={headers_activity}
          height={this.props.height - 490}
          loading={this.state.loading}
          onPageChange={page => this.handleReload(page, this.state.limit)}
          onRowClicked={row_clicked => this.setState({ row_clicked })}
          onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
          onRowsPerPageChange={limit => this.handleReload(0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={total}
          rows={notifications}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

Activity.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};
Activity.defaultProps = {
  height: 0,
};
export default Activity;
