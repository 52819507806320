import React from 'react';
import PropTypes from 'prop-types';
import GroupBasics from '../GroupBasics';

class GroupProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      addMap: null,
      removeMaps: null,
    };
    if (props.holder) {
      props.holder.addProject = project => {
        if (this.holder.addMap) this.holder.addMap(project);
      };
      props.holder.removeProjects = () => {
        if (this.holder.removeMaps) this.holder.removeMaps();
      };
    }
  }

  render() {
    return (
      <GroupBasics
        handleOpenAddDialog={this.props.handleOpenAddDialog}
        handleOpenRemoveDialog={this.props.handleOpenRemoveDialog}
        holder={this.holder}
        map_name="Project Designer"
        title="Projekt"
      />
    );
  }
}

GroupProjects.propTypes = {
  classes: PropTypes.object,
};

export default GroupProjects;
