import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { QuerySignalPatients } from 'graphql/Signals';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
import { columns_signals } from './utils';

class TabPatientSignals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      empty: false,
      loading: false,
      patients: [],
      selected: [],
      total: 0,
      limit: 50,
    };
    const { holder } = props;
    if (holder) {
      holder.reloadSignals = name => this.loadSignalPatients(name);
      holder.reloadSignalsClean = () => this.setState({ name: '' });
    }
  }
  componentDidMount() {
    this.loadSignalPatients();
  }
  loadSignalPatients = (name = null, page = null, limit = null) => {
    const { loading } = this.state;
    if (loading) return;
    if (!limit) {
      page = this.state.page;
      limit = this.state.limit;
    }
    if (name === null) {
      name = this.state.name;
    }
    this.setState({ loading: true });
    QuerySignalPatients(name, page, limit)
      .then(res => {
        const data = GetData(res);
        const { ok, patients, total, errors } = data.signalPatients;
        if (ok) {
          this.setState({
            loading: false,
            empty: !patients.length,
            patients,
            total,
            page,
            limit,
            name,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ loading: false, empty: true });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  handlePageChange = page => {
    this.loadSignalPatients(null, page, this.state.limit);
  };
  handleRowsPerPageChange = limit => {
    this.loadSignalPatients(null, 0, limit);
  };
  render() {
    const { height } = this.props;
    return (
      <div>
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_signals}
          height={height - 260}
          loading={this.state.loading}
          onPageChange={newPage => this.handlePageChange(newPage)}
          onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.patients}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabPatientSignals.propTypes = {
  classes: PropTypes.object,
};

export default TabPatientSignals;
