import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import StudyCard from '../StudyCard';
import { IsInvalid } from 'helpers';

class StudyGridView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unhealthy: [],
      change: false,
    };
  }

  handleUpdateStudies = ListStudies => {
    for (let i = 0; i < ListStudies.length; i++) {
      const {
        idStudy,
        NumberOfStudyRelatedInstances,
        NumberOfStudyRelatedSeries,
      } = ListStudies[i];
      const index = this.props.Studies.map(x => x.idStudy).indexOf(idStudy);
      if (index !== -1) {
        this.props.Studies[
          index
        ].NumberOfStudyRelatedInstances = NumberOfStudyRelatedInstances;
        this.props.Studies[
          index
        ].NumberOfStudyRelatedSeries = NumberOfStudyRelatedSeries;
      }
    }
    this.setState({ change: !this.state.change });
  };
  CheckStudy = Study => {
    let warnings = [];
    const {
      Series,
      NumberOfStudyRelatedSeries,
      NumberOfStudyRelatedInstances,
    } = Study;
    if (Series === undefined) {
      return warnings;
    }
    const files = Series.map(x => x.NumberOfSeriesRelatedInstances).reduce(
      (a, b) => a + b,
      0
    );
    if (Series.length !== NumberOfStudyRelatedSeries) {
      warnings.push('Discrepancy in the number of series of study');
    }
    if (files !== NumberOfStudyRelatedInstances) {
      warnings.push('Discrepancy in the number of files of study');
    }
    return warnings;
  };
  checkNumberOfSeries = Study => {
    const { unhealthy } = this.state;
    const { idStudy } = Study;
    const { warning, stop_warning } = this.props;
    const warnings = this.CheckStudy(Study);
    if (warnings.length) {
      const index = unhealthy.map(x => x.idStudy).indexOf(idStudy);
      if (index === -1) {
        unhealthy.unshift({ idStudy, warnings });
        this.setState({ unhealthy });
      }
    }
    if (warning !== '' || stop_warning) {
      return warnings.length === 0;
    }
    if (warnings.length) {
      // const series_files = Study.Series.map(
      //   x => x.NumberOfSeriesRelatedInstances
      // ).reduce((a, b) => a + b, 0);

      this.props.handleWarning(warnings.join('\n'));
    }
    return warnings.length === 0;
  };

  render() {
    const { index_series, Studies, handleStudyDeleted } = this.props;

    return (
      <div style={{ padding: 30 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          spacing={5}>
          {Studies.map((Study, i) => {
            const { idStudy, Series } = Study;
            const healthy = this.checkNumberOfSeries(Study);
            let warnings = [];
            if (!healthy) {
              const index = this.state.unhealthy
                .map(x => x.idStudy)
                .indexOf(idStudy);
              if (index !== -1) {
                warnings = this.state.unhealthy[index].warnings;
              }
            }
            let loading = false;
            if (IsInvalid(Series) && index_series === i) {
              loading = true;
            }
            return (
              <Grid
                item
                key={`card-study-${idStudy}-${i}`}
                md={2}
                sm={4}
                style={{ minWidth: 290 }}
                xs={12}>
                <StudyCard
                  handleStudyDeleted={handleStudyDeleted}
                  handleUpdateStudies={this.handleUpdateStudies}
                  healthy={healthy}
                  loading={loading}
                  Study={Study}
                  user={this.props.user}
                  warnings={warnings}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

StudyGridView.propTypes = {
  classes: PropTypes.object,
};

export default StudyGridView;
