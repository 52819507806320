import React, { Component } from 'react';
import { ServerErrorsString, GetData } from 'helpers';
import { QueryRunSeriesDetails } from 'graphql/Series';

class QuerySeriesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Details: null,
      loading: false,
      name: null,
      page: 0,
      limit: 1000,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const { loading, name, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QueryRunSeriesDetails(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const rows = data.ImageFocus;
          if (rows) {
            this.props.setState({ loading: false });
            this.setState({
              rows,
              loading: false,
            });
          } else {
            throw Error('Invalid Rows');
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { loading, Details } = this.state;
    const details = { name: '', updatedAt: '' };
    let focus = { idFocus: -1, ...details };
    let region = { idRegion: -1, ...details };
    let pathology = { idPathology: -1, ...details };
    let sequence = { idSequence: -1, ...details };
    let side = { idSide: -1, ...details };
    if (Details) {
      if (Details.SeriesFocus) {
        focus = Details.SeriesFocus.ImageFocus;
        focus.updatedAt = Details.SeriesFocus.updatedAt;
      }
      if (Details.SeriesRegion) {
        region = Details.SeriesRegion.AnatomicalRegion;
        region.updatedAt = Details.SeriesRegion.updatedAt;
      }
      if (Details.SeriesPathology) {
        pathology = Details.SeriesPathology.Pathology;
        pathology.updatedAt = Details.SeriesPathology.updatedAt;
      }
      if (Details.SeriesSequence) {
        sequence = Details.SeriesSequence.Sequence;
        sequence.updatedAt = Details.SeriesSequence.updatedAt;
      }
      if (Details.SeriesSide) {
        side = Details.SeriesSide.InvestigatedSide;
        side.updatedAt = Details.SeriesSide.updatedAt;
      }
    }
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        focus,
        region,
        pathology,
        sequence,
        side,
        loading_query: loading,
      });
    });
  }
}

export default QuerySeriesDetails;
