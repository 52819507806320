import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadEpilepsyCSV } from './utils';

const QUERY_EPILEPSY_RESECTION = gql`
  query($name: String, $page: Int, $limit: Int) {
    epilepsyResections(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      resections {
        id
        idPatient
        idEpilepsyCase
        date
        patient_age
        op_side
        operation
        details
        complication
        histology
        histology_temporolpol
        histology_amygdala
        histology_hippocampus

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyResections = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_RESECTION),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_EPILEPSY_RESECTION = gql`
  mutation($ids: [LongLong!]!) {
    deleteEpilepsyResections(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveEpilepsyResections = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_EPILEPSY_RESECTION),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_EPILEPSY_RESECTION = gql`
  mutation($inputs: [InputEpilepsyResection!]!) {
    addEpilepsyResections(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      resections {
        id
        idPatient
        idEpilepsyCase
        date
        patient_age
        op_side
        operation
        details
        complication
        histology
        histology_temporolpol
        histology_amygdala
        histology_hippocampus

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddEpilepsyResections = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_EPILEPSY_RESECTION),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_EPILEPSY_RESECTION = gql`
  mutation($file: Upload!) {
    uploadEpilepsyResections(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      resections {
        id
        idPatient
        idEpilepsyCase
        date
        patient_age
        op_side
        operation
        details
        complication
        histology
        histology_temporolpol
        histology_amygdala
        histology_hippocampus

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEpilepsyResections = (
  file,
  handleProgress = null
) => {
  return MutationUploadEpilepsyCSV(
    file,
    MUTATION_UPLOAD_EPILEPSY_RESECTION,
    handleProgress
  );
};

const QUERY_SEARCH_EPILEPSY_RESECTION = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchEpilepsyResections(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      resections {
        id
        idPatient
        idEpilepsyCase
        date
        patient_age
        op_side
        operation
        details
        complication
        histology
        histology_temporolpol
        histology_amygdala
        histology_hippocampus
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySearchEpilepsyResections = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_EPILEPSY_RESECTION),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
