import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import {
  Badge,
  Button,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { StyledMenu, StyledMenuItem } from 'components/StyledMenu';
import { addNewMessage, readMessages } from 'redux-store/actions';
import { useStyles } from './Style';
import { FormatTime, getInitials } from 'helpers';
import { ButtonReload } from 'components';

class LatestMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selected: -1,
    };
  }

  getTotal = () => {
    const { chat_messages } = this.props;
    return chat_messages.filter(x => x.read === false).length;
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = select => {
    this.setState({ anchorEl: null, selected: select.id });
    if (this.getTotal() > 0) {
      this.props.readMessages();
    }
  };
  handleSelectMessage = message => {
    const { sender } = message;
    if (sender) {
      this.props.history.push(`/communications/chat/${sender.id}`);
    } else {
      this.props.history.push('/communications');
    }
    if (this.getTotal() > 0) {
      this.props.readMessages();
    }
    this.setState({ anchorEl: null, selected: -1 });
  };
  handleViewAll = () => {
    const { direct_to } = this.props;
    if (direct_to === '') {
      this.handleClose();
    } else {
      this.props.history.push(direct_to);
    }
    this.setState({ anchorEl: null, selected: -1 });
  };
  handleReload = () => {
    const { holder } = this.props;
    if (holder.handleReloadMessages) {
      holder.handleReloadMessages();
    }
  };
  render() {
    const { classes, icon, title } = this.props;
    const { anchorEl, selected } = this.state;
    let { chat_messages } = this.props;
    const total = this.getTotal();
    if (total > 5 || chat_messages.length) {
      chat_messages = chat_messages.slice(0, 5);
    }
    if (!chat_messages.length) {
      chat_messages = [
        {
          id: -1,
          text: 'There are no messages....',
          read: true,
          receiverId: 0,
          sender: {
            id: 0,
            FullName: '',
            username: 'John Doe',
            avatar: '',
          },
          createdAt: moment().valueOf(),
        },
      ];
    }
    return (
      <div>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={this.handleClick}
          size="large">
          <Badge badgeContent={total} color="error">
            {icon}
          </Badge>
        </IconButton>
        <StyledMenu
          anchorEl={anchorEl}
          id="customized-menu"
          keepMounted
          onClose={this.handleClose}
          open={Boolean(anchorEl)}>
          <div className={classes.menu}>
            <CardHeader
              action={
                <ButtonReload
                  handleReload={this.handleReload}
                  loading={this.props.loading}
                  small
                  tooltip="Reload Messages"
                />
              }
              style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
              subtitle={`${total} in total`}
              title={title}
            />
            <Divider />
            {chat_messages.map((message, i) => {
              const { sender } = message;
              let avatar = '/images/avatars/avatar_0.png';
              if (sender.avatar) {
                avatar = sender.avatar;
              }
              return (
                <StyledMenuItem
                  divider={i < total - 1}
                  key={message.id}
                  onClick={() => this.handleSelectMessage(message)}
                  selected={message.id === selected}>
                  <ListItemAvatar>
                    <img
                      alt={getInitials(sender.FullName)}
                      className={classes.image}
                      src={avatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid alignItems="center" container>
                        <Grid item xs>
                          {sender.FullName}
                        </Grid>
                        <Grid item style={{ width: '30px' }} />
                      </Grid>
                    }
                    secondary={message.text}
                  />
                  <ListItemText>
                    <Grid alignItems="center" container direction="column">
                      <Grid item>
                        <Typography variant="caption">
                          {`(${FormatTime(message.createdAt, 'FromNow')})`}
                        </Typography>
                      </Grid>
                      <Grid item style={{ width: '30px', height: '20px' }} />
                    </Grid>
                  </ListItemText>
                  <IconButton edge="end" size="small">
                    <MoreVertIcon />
                  </IconButton>
                </StyledMenuItem>
              );
            })}
            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                onClick={this.handleViewAll}
                size="small"
                variant="text">
                View all <ArrowRightIcon />
              </Button>
            </CardActions>
          </div>
        </StyledMenu>
      </div>
    );
  }
}

LatestMessages.defaultProps = {
  open: false,
  icon: <MailIcon />,
  title: 'Latest Messages',
  direct_to: '',
};
LatestMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  direct_to: PropTypes.string,
  history: PropTypes.object.isRequired,
  icon: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

const mapStateToProps = state => {
  const size = state.page_activity.chat_messages.filter(x => x.read === false)
    .length;
  return {
    user: state.info_user.user,
    chat_messages: state.page_activity.chat_messages,
    size,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addNewMessage,
    readMessages,
  })(withStyles(LatestMessages, useStyles))
);
