import { FormatBytes, ServerErrorsString } from 'helpers';

export const GetPatientSeriesInfo = item => {
  let info = {
    PatientName: '',
    SeriesDescription: '',
  };
  if (IsFile(item)) {
    return info;
  }
  const { content, file } = item;
  if (typeof file !== 'undefined' && file) {
    info.PatientName = `File Fail: ${file.name}`;
    info.SeriesDescription = 'Upload unsuccessful';
    return info;
  } else if (typeof content === 'undefined' || !content) {
    return info;
  }
  const { File, Series } = content;
  if (typeof File !== 'undefined' && File) {
    info.PatientName = `File Uploaded: ${File.original_name}`;
  } else if (typeof Series !== 'undefined' && Series) {
    info.PatientName = `Patient Name: ${Series.Patient.PatientName}`;
    info.SeriesDescription = `SeriesDescription: ${Series.SeriesDescription}`;
  }
  return info;
};
export const IsFile = content => {
  if (typeof content === 'undefined') return false;
  if (content instanceof File || content.constructor === File) return true;
  return false;
};
export const IsContentUploaded = item => {
  if (IsFile(item)) {
    return false;
  }
  return true;
};
export const GetContentSize = item => {
  if (IsFile(item)) {
    return FormatBytes(item.size);
  }
  const { content, file } = item;
  if (typeof file !== 'undefined' && file) {
    return FormatBytes(file.size);
  } else if (typeof content === 'undefined' || !content) {
    return 0;
  }
  const { File, Series } = content;
  if (typeof File !== 'undefined' && File) {
    return FormatBytes(File.Size);
  } else if (typeof Series !== 'undefined' && Series) {
    return FormatBytes(Series.Size);
  }
  return 0;
};
export const GetFileObjectName = item => {
  if (IsFile(item)) {
    return item.name;
  }
  const { filename } = item;
  if (typeof filename !== 'undefined' && filename) {
    return filename;
  }
  return '';
};
export const IsError = (item, check = false) => {
  if (IsFile(item)) {
    return false;
  }
  const { errors, finished_error } = item;
  if (check && finished_error) return false;
  if (errors !== undefined && errors && errors.length > 0) {
    return true;
  }
  return false;
};
export const GetError = item => {
  if (IsFile(item)) {
    return '';
  }
  const { errors } = item;
  if (errors !== undefined && errors && errors.length > 0) {
    return ServerErrorsString(errors);
  }
  return '';
};
export const IsWarning = item => {
  if (IsFile(item)) {
    return false;
  }
  const { warnings } = item;
  if (typeof warnings !== 'undefined' && warnings && warnings.length > 0) {
    return true;
  }
  return false;
};
export const GetWarning = item => {
  if (IsFile(item)) {
    return '';
  }
  const { warnings } = item;
  if (typeof warnings !== 'undefined' && warnings && warnings.length > 0) {
    return ServerErrorsString(warnings);
  }
  return '';
};
