import React, { Component } from 'react';
import { Subscription } from '@apollo/client/react/components';
import { SUBSCRIPTION_CHANNEL_MESSAGE } from 'graphql/Communications';
import { IsInvalid, GetData } from 'helpers';
import { QueryRunChannelMessages } from './utils_channel_messages';
class QueryChannelMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      idReceiver: -1,
      messages: [],
    };
  }
  componentDidMount() {
    const { channelId } = this.props;
    if (channelId && channelId !== -1) {
      this.handleLoadChannelMessages();
    }
  }
  componentDidUpdate() {
    const { channelId } = this.props;
    if (channelId && channelId !== -1) {
      this.handleLoadChannelMessages();
    }
  }
  handleLoadChannelMessages = () => {
    let { loading, idReceiver } = this.state;
    const { channelId } = this.props;
    if (loading || IsInvalid(channelId)) return;
    if (idReceiver === channelId) return;
    idReceiver = channelId;
    this.setState({ loading: true });
    (async () => {
      QueryRunChannelMessages(idReceiver)
        .then(res => {
          const data = GetData(res);
          this.setState({
            messages: data.messages,
            loading: false,
            idReceiver,
          });
        })
        .catch(error => {
          this.setState({ loading: false, idReceiver });
          this.props.handleError(error);
        });
    })();
  };
  onSubscriptionDataMessages = ondata => {
    console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { newChannelMessage } = data;
      if (newChannelMessage) {
        const { messages } = this.state;
        const index = messages.map(x => x.id).indexOf(newChannelMessage.id);
        if (index === -1) {
          messages.unshift(newChannelMessage);
          this.setState({ messages });
          // this.props.handleAddLastDirect(newDirectMessage);
        }
      }
    }
  };
  render() {
    const { children, channelId, user } = this.props;
    const variables = {
      channelId,
    };
    const { messages } = this.state;
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataMessages}
        shouldResubscribe
        subscription={SUBSCRIPTION_CHANNEL_MESSAGE}
        variables={variables}>
        {({ loading, error }) => {
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              loading,
              error,
              channelId,
              messages,
              user,
            });
          });
        }}
      </Subscription>
    );
  }
}

export default QueryChannelMessages;
