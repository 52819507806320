import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { RowText } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';
import RowTextMultiLine from 'components/Texts/RowTextMultiLine';

class SleepCaseViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { sleep_case } = this.props;
    console.log({ sleep_case });
    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          Sleep Case
        </Typography>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <RowText
              display="block ruby"
              first="Case record ID:"
              second={this.getValue(sleep_case.record_id)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Description:"
              second={this.getValue(sleep_case.description)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Anamnesy:"
              second={this.getValue(sleep_case.anamnesy)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Medication:"
              second={this.getValue(sleep_case.medication)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="REDcap event name:"
              second={this.getValue(sleep_case.redcap_event_name)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="ESS score:"
              second={this.getValue(sleep_case.ess_score)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="FSS score:"
              second={this.getValue(sleep_case.fss_score)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

SleepCaseViewer.propTypes = {
  classes: PropTypes.object,
};

export default SleepCaseViewer;
