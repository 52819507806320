import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ButtonLoad, IntpuTeams, SnackMessage } from 'components';
import { GetData, PaperComponent, ServerErrorsString } from 'helpers';
import { useStyles } from '../Styles';
import { AddTeamsToProject } from 'graphql/Teams';

class DialogAddTeamsToProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
    };
  }

  handleSelected = selected => {
    this.setState({ selected });
  };
  handleAddTeams = () => {
    const { loading, selected } = this.state;
    if (loading) return;
    const { project } = this.props;
    if (typeof project === 'undefined' || project.id < 1) {
      this.setState({ error: 'Project is missing' });
      return;
    }
    const ids = selected.map(x => x.id);
    (async () => {
      AddTeamsToProject(project.id, ids)
        .then(res => {
          const data = GetData(res);
          const { projectAddTeams } = data;

          if (typeof projectAddTeams !== 'undefined') {
            const { ok, errors, teams } = projectAddTeams;
            if (ok) {
              this.setState({ deleting: false, selected: [], error: '' });
              this.props.handleAddTeams(teams);
            } else {
              throw errors;
            }
          }
        })
        .catch(errors => {
          this.setState({
            deleting: false,
            error: ServerErrorsString(errors),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const { loading, error, selected } = this.state;
    const { classes, open, handleClose } = this.props;
    return (
      <Dialog
        aria-labelledby="draggable-team-title"
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-team-title" style={{ cursor: 'move' }}>
          Add Teams To Project
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new team to project, please type the name of the team.
          </DialogContentText>
          <IntpuTeams onChange={this.handleSelected} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            className={classes.button}
            disable_button={selected.length ? false : true}
            handleClick={this.handleAddTeams}
            loading={loading}
            name="Add"
            variant="text"
          />
        </DialogActions>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== ''}
          type="error"
        />
      </Dialog>
    );
  }
}

DialogAddTeamsToProject.propTypes = {
  classes: PropTypes.object,
  project: PropTypes.object.isRequired,
};
DialogAddTeamsToProject.defaultProps = {
  project: { id: -1 },
  open: false,
};
export default withStyles(DialogAddTeamsToProject, useStyles);
