import React from 'react';
import { Button } from '@mui/material';
import { DivGrow } from 'components';

class ChangeAvatarBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Button color="primary" onClick={this.props.handleUploadNew}>
          Upload New
        </Button>
        <DivGrow />
        <Button color="primary" onClick={this.props.handleSave}>
          Save
        </Button>
        <Button color="primary" onClick={this.props.handleCancel}>
          Cancel
        </Button>
      </React.Fragment>
    );
  }
}

ChangeAvatarBottom.propTypes = {};

export default ChangeAvatarBottom;
