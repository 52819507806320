/* eslint-disable react/no-direct-mutation-state */
import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import {
  setExplorerProgress,
  setExplorerTotalFiles,
} from 'redux-store/actions';
import { SnackMessage } from 'components';
import { GetData, ServerErrors } from 'helpers';
import { IsUserClinicalTrial, MutationUploadFile } from 'common';
const useStyles = () => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    // display: 'none',
  },
  input_props: {
    // style: { display: 'none' },
  },
});
const MyDropzone = ({ children, handleOnDrop, user }) => {
  if (IsUserClinicalTrial(user)) {
    return (
      <div
        style={{
          // display: 'none',
          width: '100%',
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
        {children}
      </div>
    );
  }
  const onDrop = useCallback(acceptedFiles => {
    handleOnDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps({
        style: {
          // display: 'none',
          width: '100%',
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        },
        onClick: e => e.stopPropagation(),
      })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

class ExplorerDropArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      all_uploaded: [],
      warning: '',
    };
  }

  componentDidUpdate() {
    const { files } = this.state;
    const { total_files } = this.props;
    if (files.length === 0 && total_files !== 0) {
      this.props.setExplorerTotalFiles(0);
    }
  }

  handleOnDrop = in_files => {
    let { files, uploading } = this.state;
    const { idFolder } = this.props;
    //
    const total = files.length;
    for (let x = 0; x < in_files.length; x++) {
      const file = in_files[x];
      let add = true;
      for (let y = 0; y < files.length; y++) {
        const { name, size, type, lastModified } = files[y].file;
        if (
          file.name === name &&
          file.size === size &&
          file.type === type &&
          file.lastModified === lastModified
        ) {
          add = false;
          this.setState({ warning: `File ${name} is here` });
        }
      }
      if (add) {
        files.push({ idFolder, file });
      }
    }
    if (total !== files.length) {
      // this.setState({ files });
      this.state.files = files;
      this.props.setExplorerTotalFiles(files.length);
      if (!total && !uploading) {
        this.handleUpload(files);
      }
    }
  };
  handleUplodedItem = item => {
    if (typeof item === 'undefined' || !item) {
      return;
    }
    let { files, all_uploaded } = this.state;
    files.splice(0, 1);
    all_uploaded.push(item);
    this.state.files = files;
    this.state.all_uploaded = all_uploaded;
    this.handleUpload(files);
    this.props.handleUploadFiles(item, all_uploaded.length);
  };
  handleUpload = files => {
    if (!files.length) {
      this.props.setExplorerProgress(0);
      this.setState({ uploading: false, all_uploaded: [] });
      return;
    }
    this.setState({ uploading: true });
    const { idFolder, file } = files[0];
    (async () => {
      MutationUploadFile(file, this.handleProgress, idFolder)
        .then(res => {
          const data = GetData(res);
          const {
            uploadFile: { ok, errors },
          } = data;
          if (ok) {
            const { contents, warnings } = data.uploadFile;
            const content = contents && contents.length ? contents[0] : null;
            const uploaded_content = {
              idFolderContent: content ? content.idFolderContent : null,
              filename: file.name,
              filesize: file.size,
              content,
              warnings: warnings && warnings.length ? warnings : [],
            };
            this.handleUplodedItem(uploaded_content);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          const idFolderContent = -1; //uniqueId('error_');
          const new_information = {
            idFolderContent,
            filename: file.name,
            filesize: file.size,
            file,
            errors: ServerErrors(error),
          };
          this.handleUplodedItem(new_information);
        });
    })();
  };
  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) total = loaded;
    const progress = Math.floor((loaded * 100) / total);
    this.props.setExplorerProgress(progress);
  };
  handleWarningClose = () => {
    this.setState({ warning: '' });
  };

  render() {
    const { children } = this.props;
    const { warning } = this.state;
    return (
      <React.Fragment>
        <MyDropzone handleOnDrop={this.handleOnDrop} user={this.props.user}>
          {children}
        </MyDropzone>
        <SnackMessage
          handleClose={this.handleWarningClose}
          horizontal="left"
          message_text={warning}
          open={warning !== '' ? true : false}
          type="warning"
        />
      </React.Fragment>
    );
  }
}

ExplorerDropArea.defaultProps = {
  total_files: 0,
};
ExplorerDropArea.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({
  total_files: state.page_explorer.total_files,
});

export default connect(mapStateToProps, {
  setExplorerProgress,
  setExplorerTotalFiles,
})(withStyles(ExplorerDropArea, useStyles));
