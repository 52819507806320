import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_FOLDER_CONTENTS = gql`
  query($idFolder: LongLong!, $page: Int, $limit: Int) {
    allFolderContent(idFolder: $idFolder, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      folder_contents {
        idFolderContent
        Series {
          idSeries
          idStudy
          SeriesDescription
          NumberOfSeriesRelatedInstances
          Size
          Thumbnail
        }
        File {
          idFileObject
          original_name
          mimetype
          Size
          Thumbnail
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryFolderContentAtFolder = (idFolder, page, limit) => {
  const query = { idFolder };
  if (page && limit) {
    query.page = page;
    query.limit = limit;
  }
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FOLDER_CONTENTS),
    variables: query,
  });
};
