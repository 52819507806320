import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SIGNAL_PATIENT = gql`
  query($signalEDFQuery: SignalEDFQuery, $page: Int, $limit: Int) {
    signalEDFs(signalEDFQuery: $signalEDFQuery, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      edfs {
        id
        file_object {
          Size
          original_name
        }
        equipment
        admincode
        file_duration
        file_type
        number_of_channels
        number_of_annotations
        number_of_datarecords
        start_date
        start_time
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySignalEDFs = (name, page, limit) => {
  const variables = {};
  if (name !== undefined) variables.name = name;
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SIGNAL_PATIENT),
    variables,
  });
};
