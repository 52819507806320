import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import BackupIcon from '@mui/icons-material/Backup';
import { green, blue } from '@mui/material/colors';
import { v1 as uuid } from 'uuid';

const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 0,
    left: 8,
    zIndex: 1,
  },
  wrapper: {
    padding: 0,
    margin: 0,
    // position: 'relative',
  },
});
class ButtonFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes, icon, uploading, accept, disabled } = this.props;
    if (!icon) {
      icon = (
        <BackupIcon
          style={{ color: !uploading && !disabled ? blue[500] : 'gray' }}
        />
      );
    }
    // let style = null;
    // if (height) {
    //   style = { height, maxHeight: height };
    // }
    const id_button = uuid();
    return (
      // <div className={classes.wrapper} style={style}>
      <Button disabled={disabled} style={{ margin: 0, padding: 0 }}>
        <input
          accept={accept}
          hidden
          id={id_button}
          onChange={this.props.handleSelectFile}
          style={{ display: 'none', border: 0 }}
          type="file"
        />
        <label
          htmlFor={id_button}
          style={{ margin: 0, borderRadius: 0, padding: 0, border: 0 }}>
          <Button
            component="span"
            style={{ margin: 0, borderRadius: 0, padding: 0, border: 0 }}>
            {icon}
            {uploading && (
              <CircularProgress className={classes.fabProgress} size={25} />
            )}
          </Button>
        </label>
      </Button>
    );
  }
}

ButtonFileUpload.propTypes = {
  classes: PropTypes.object,
};
ButtonFileUpload.defaultProps = {
  icon: null,
  uploading: false,
  disabled: false,
  accept: '.csv',
  handleSelectFile: () => '',
};
export default withStyles(ButtonFileUpload, useStyles);
