import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { ButtonReloadDelete, AutoComboBox, BadgeIcon } from 'components';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { styleToolbar } from 'common';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';
import { connect } from 'react-redux';

class ToolBarInputActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: 'all',
      images: [],
      total: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.handleUpdateImages();
  }
  handleUpdateImages = () => {
    console.log('ToolBarInputActions');
    const { loading } = this.state;
    const { user } = this.props;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySystemDockerImages(user.type)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images, total } = data.systemDockerImages;
          if (ok) {
            images.unshift({ operation: 'None', id: 0 });
            // images.push({ operation: 'Anonymization', id: 2 });
            this.setState({ loading: false, images, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAlignment = (event, alignment) => {
    this.setState({ alignment });
  };
  render() {
    const {
      classes,
      disabled,
      requests,
      tabValue,
      selected,
    } = this.props;
    let { process } = this.props;
    let { images } = this.state;
    if (tabValue > 1) {
      images = [{ operation: 'None', id: 0 }];
      process = images[0];
    }
    let { numSelected } = this.props;
    if (selected) numSelected = selected.length;
    if (images.length && (process === undefined || !process)) {
      process = images[0];
    }
    let disable_copy = false;
    let disable_run = false;
    let disable_add_job = true;
    if (!this.props.total_inputs) {
      disable_run = true;
    }
    if (!this.props.num_series) {
      disable_copy = true;
    }
    if (process && selected.length) {
      disable_add_job = process ? process.id === 0 : true;
    }
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.props.handleAction}
              size="small"
              value={this.props.view}>
              <ToggleButton
                aria-label="left aligned"
                disabled={disable_copy}
                sx={{ paddingTop: numSelected ? 0 : '7px' }}
                value="copy">
                <BadgeIcon
                  count={numSelected}
                  disabled={disable_copy}
                  Icon={PeopleOutlineIcon}
                  title="Copy series"
                />
              </ToggleButton>
              <ToggleButton
                aria-label="centered"
                disabled={disable_run}
                sx={{ paddingTop: requests ? 0 : '7px' }}
                value="run">
                <BadgeIcon
                  count={requests}
                  disabled={disable_add_job}
                  Icon={PlaylistAddIcon}
                  title="Add to job process"
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item sx={{ paddingLeft: 5 }} xs={6}>
            <AutoComboBox
              defaultValue={process}
              disabled={this.state.loading}
              getOptionLabel={option => {
                if (option === undefined || option.operation === undefined)
                  return '';
                return option.operation;
              }}
              label="Process"
              onObjectChanged={this.props.onObjectChanged}
              options={images}
              small
            />
          </Grid>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={numSelected}
            show_delete={this.props.show_delete}
            tooltip="Reload Inputs"
          />
        </Grid>
      </Paper>
    );
  }
}

ToolBarInputActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarInputActions.defautlProps = {
  name: '',
  show_delete: true,
  onObjectChanged: () => '',
  handleChangeName: () => '',
  handleOpenCreateUser: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleAction: () => '',
  loading: false,
  loading_table: false,
  numSelected: 0,
};
const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let num_series = copy_series ? copy_series.length : 0;
  let num_files = copy_files ? copy_files.length : 0;
  return {
    total_inputs: num_series + num_files,
    num_series,
  };
};
export default connect(mapStateToProps)(
  withStyles(ToolBarInputActions, styleToolbar)
);
