import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';

class EditSelectedType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to_edit: null,
    };
  }
  componentDidUpdate() {
    const { selected } = this.props;
    if (!selected || selected === undefined) return;
    const { to_edit } = this.state;
    if (!to_edit) {
      this.setState({ to_edit: selected });
      this.props.handleUpdateToEdit(selected);
    } else if (to_edit.id !== selected.id) {
      this.setState({ to_edit: selected });
      this.props.handleUpdateToEdit(selected);
    }
  }
  handleChange = event => {
    event.preventDefault();
    let { to_edit } = this.state;
    if (!to_edit) return;
    to_edit = {
      ...to_edit,
      [event.target.name]: event.target.value,
    };
    this.setState({ to_edit });
    this.props.handleUpdateToEdit(to_edit);
  };
  render() {
    const { to_edit } = this.state;
    let name = '';
    let description = '';
    let disable_all = true;
    if (to_edit) {
      disable_all = false;
      name = to_edit.name;
      description = to_edit.description;
    }
    return (
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            disabled={disable_all}
            fullWidth
            helperText="Please specify the name of the variable"
            InputLabelProps={{
              shrink: true,
            }}
            label="Variable name"
            margin="dense"
            name="name"
            onChange={this.handleChange}
            required
            value={name}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            disabled={disable_all}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Description"
            margin="dense"
            name="description"
            onChange={this.handleChange}
            placeholder="Please specify the description of this variable"
            value={description}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

EditSelectedType.propTypes = {
  classes: PropTypes.object,
};

export default EditSelectedType;
