import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';

import { updateAvatar } from 'redux-store/actions/userActions';
import { logout } from 'redux-store/actions/loginActions';

import { ObjectSize } from 'views/utils/utils';
import ChangeAvatar from '../ChangeAvatar';
import UpdatePassword from '../UpdatePassword';
import { FormatTime, getUserFullName } from 'helpers';

const useStyles = makeStyles()(theme => ({
  root: {},
  details: {
    display: 'flex',
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
}));

const AccountProfile = props => {
  // eslint-disable-next-line no-unused-vars
  const { className, user, updateAvatar, variant, logout, ...rest } = props;
  //
  const [open_modal, setOpenModal] = useState(false);
  const [open_password_modal, setOpenPasswordModal] = useState(false);

  const { classes } = useStyles();
  const handleCancel = () => {
    setOpenModal(false);
    setOpenPasswordModal(false);
  };
  const handleSave = avatar => {
    const currentUser = {
      ...user,
      avatar,
    };

    updateAvatar(currentUser);
    // setTouched(false);
    setOpenModal(false);
  };
  const openModal = () => {
    setOpenModal(true);
  };
  const openUpdatePasswordModal = () => {
    setOpenPasswordModal(true);
  };
  const getProfileCompleteness = () => {
    const size = ObjectSize(user) - 1;
    let count = 0;
    for (let key in user) {
      if (user[key]) {
        count++;
      }
    }
    return parseInt((count / size) * 100, 10);
  };
  //
  if (user === undefined || !user) {
    props.logout();
    return null;
  }
  let createdAt = 'Unknown';
  if (user.createdAt !== '') {
    createdAt = FormatTime(user.createdAt, 'MMM Do, YYYY (HH:mm a)');
  }
  let image_avatar = user.photo;
  if (image_avatar === undefined || !image_avatar) {
    image_avatar = '/images/avatars/avatar_0.png';
  }
  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <div className={classes.details}>
            <div>
              <Typography gutterBottom variant="h4">
                {getUserFullName(user)}
              </Typography>
              <Typography
                className={classes.locationText}
                color="textSecondary"
                variant="body1">
                {user.city ? user.city : 'City (Unknown)'},{' '}
                {user.country ? user.country : 'Country (Unknown)'}
              </Typography>
              <Typography
                className={classes.dateText}
                color="textSecondary"
                variant="body1">
                {`Registered: ${createdAt}`}
              </Typography>
            </div>
            <Avatar
              className={classes.avatar}
              src={image_avatar}
              style={{ width: 150, height: 'auto' }}
            />
          </div>
          <div className={classes.progress}>
            <Typography variant="body1">{`Profile Completeness: ${getProfileCompleteness()}%`}</Typography>
            <LinearProgress
              value={getProfileCompleteness()}
              variant="determinate"
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            onClick={openModal}
            variant="text">
            Change picture
          </Button>
          <Button onClick={openUpdatePasswordModal} variant="text">
            Update password
          </Button>
        </CardActions>
      </Card>
      <ChangeAvatar
        handleCancel={handleCancel}
        handleSave={handleSave}
        key="call-change-avatar-modal"
        open={open_modal}
      />
      <UpdatePassword
        handleCancel={handleCancel}
        key="call-update-password-modal"
        open={open_password_modal}
        user={user}
      />
    </React.Fragment>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
  updateAvatar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
export default connect(null, { updateAvatar, logout })(AccountProfile);
