export const useStyle = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  paper_item: {
    height: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
});
export { default as CardProjectCenters } from './CardProjectCenters';
export { default as CardProjectMembers } from './CardProjectMembers';
export { default as CardProjectNotifications } from './CardProjectNotifications';
