export const useStyles = () => ({
  root: {
    height: 110,
    width: 100,
    backgroundColor: 'white',
    color: 'blue',
  },
  checkbox: {
    width: '10px',
    height: '10px',
    marginTop: '-5px',
    marginLeft: '-10px',
    marginRight: 0,
  },
  box: {
    backgroundColor: 'white',
    width: '100px',
    padding: 0,
  },
  box_selected: {
    backgroundColor: 'white',
    width: '100px',
    padding: 0,
    marginTop: '0px',
    marginLeft: '-8px',
  },
  folder_name: {
    width: '100%',
    marginTop: '-15px',
  },
  icon: {
    width: 100,
    height: 100,
  },
});
