import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Divider, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

export default function StepContainer({ off_heander, title, children }) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.root}>
      {!off_heander && (
        <div>
          <Typography component="h3" variant="h5">
            {title}
          </Typography>
          <Divider style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
        </div>
      )}
      {children}
    </Paper>
  );
}
