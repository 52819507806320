import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { QueryInputsBySettingId } from 'graphql/Dockers/utils_docker_setting';
import { GetData, ServerErrorsString } from 'helpers';

class InputDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_type: null,
      selected_table: null,
      loading: false,
      input_types: [],
      set: false,
    };
  }
  componentDidMount() {
    const { input_definition } = this.props;
    this.queryInputs();
    this.setInputDefinition(input_definition);
  }
  componentDidUpdate() {
    const { input_definition } = this.props;
    this.setInputDefinition(input_definition);
  }
  queryInputs = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryInputsBySettingId(this.props.setting_id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, inputs } = data.getInputsBySettingId;
          if (ok) {
            let input_types = [];
            for (let i = 0; i < inputs.length; i++) {
              const element = inputs[i];
              input_types.push({ id: element.id, name: element.type });
            }
            this.setState({
              loading: false,
              input_types,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  setInputDefinition = input_definition => {
    if (this.state.set) return;
    if (input_definition === null || input_definition === undefined) return;
    const { selected_type, selected_table } = input_definition;
    const state = {
      selected_type,
      selected_table,
      set: true,
    };
    this.setState(state);
  };
  handleSelectedType = selected_type => {
    const { selected_table } = this.state;
    this.setState({ selected_type });
    this.props.handleUpdateInputDefinition({
      selected_type,
      selected_table,
    });
  };
  handleSelectedTable = selected_table => {
    const { selected_type } = this.state;
    this.setState({ selected_table });
    this.props.handleUpdateInputDefinition({
      selected_type,
      selected_table,
    });
  };

  render() {
    const { uploading, table_list } = this.props;
    const { selected_table, selected_type } = this.state;
    let input_type = '';
    if (selected_type) {
      input_type = selected_type.name;
    }
    let input_table = '';
    if (selected_table) {
      input_table = selected_table.name;
    }
    return (
      <Grid container item spacing={2} xs={12}>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedType}
            label="Input Data Type"
            name={input_type}
            no_icon
            options={this.state.input_types}
            placeholder="Select input type of your docker..."
            required
            selected={selected_type}
            setObject={this.handleSelectedType}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedTable}
            label="Input Reference Table"
            name={input_table}
            no_icon
            options={table_list}
            placeholder="Select reference table of your input type..."
            required
            selected={selected_table}
            setObject={this.handleSelectedTable}
          />
        </Grid>
      </Grid>
    );
  }
}

InputDefinition.propTypes = {
  handleUpdateInputDefinition: PropTypes.func,
  input_definition: PropTypes.object,
  table_list: PropTypes.array,
};
InputDefinition.defaultProps = {
  input_definition: null,
  table_list: null,
  handleUpdateInputDefinition: () => '',
};

export default InputDefinition;
