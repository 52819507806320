import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GroupAdd } from '@mui/icons-material';

import { DivGrow, SearchUsersInput, ButtonCircularIconLoad } from 'components';

const useStyles = theme => ({
  root: {
    margin: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    pading: 0,
    width: '98.5%',
  },
  searchInput: {},
});

class UsersToolbar extends Component {
  constructor(props) {
    super(props);
    this.holder = {
      clear: null,
    };
  }
  handleAddUser = user => {
    if (this.holder.clear) {
      this.holder.clear();
    }
    this.props.handleAddUser(user);
  };
  render() {
    const {
      classes,
      handleOpenInviteUser,
      save_changed,
      saving,
      admin,
      handleSaveClicked,
    } = this.props;
    let ComponentSave = null;
    if (admin && handleSaveClicked) {
      ComponentSave = (
        <Grid item xs={4}>
          <ButtonCircularIconLoad
            disabled={!save_changed}
            handleButtonClick={handleSaveClicked}
            loading={saving}
            small
          />
        </Grid>
      );
    }
    return (
      <div className={classes.root}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          item
          justifyContent="space-between"
          xs={12}>
          <Grid item xs={11}>
            <SearchUsersInput
              handleAddUser={this.handleAddUser}
              holder={this.holder}
            />
          </Grid>
          <DivGrow />
          <Grid item xs={1}>
            {handleOpenInviteUser && (
              <Button
                color="primary"
                endIcon={<GroupAdd />}
                onClick={() => handleOpenInviteUser(true)}
                variant="contained">
                Invite User
              </Button>
            )}
            {ComponentSave}
          </Grid>
          {/**/}
        </Grid>
      </div>
    );
  }
}

UsersToolbar.propTypes = {
  classes: PropTypes.object,
};
UsersToolbar.defaultProps = {
  admin: false,
  save_changed: false,
  saving: false,
  handleSaveClicked: () => '',
};

export default withStyles(UsersToolbar, useStyles);
