import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';

class OutputCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      column_name_csv: null,
      filename: null,
      loading: false,
      set: false,
    };
  }
  componentDidMount() {
    const { configuration } = this.props;
    this.setOutputCsv(configuration);
  }
  componentDidUpdate() {
    const { configuration } = this.props;
    this.setOutputCsv(configuration);
  }
  setOutputCsv = configuration => {
    if (this.state.set) return;
    if (configuration === null || configuration === undefined) return;
    const { column_name_csv, filename } = configuration;
    const state = {
      column_name_csv,
      filename,
      set: true,
    };
    this.setState(state);
  };
  handleUpdateColumnName = event => {
    let { column_name_csv, filename } = this.state;
    const value = event.target.value;
    if (column_name_csv === null || column_name_csv === undefined) {
      column_name_csv = { type: 'CSV', name: 'column_name_csv', value };
    } else {
      column_name_csv['value'] = value;
    }
    this.setState({ column_name_csv });
    this.props.handleOutputConfiguration({
      column_name_csv,
      filename,
    });
  };
  handleUpdateFilename = event => {
    let { filename, column_name_csv } = this.state;
    const value = event.target.value.toLowerCase();
    if (filename === null || filename === undefined) {
      filename = { type: 'CSV', name: 'filename', value };
    } else {
      filename['value'] = value;
    }
    this.setState({ filename });
    this.props.handleOutputConfiguration({
      filename,
      column_name_csv,
    });
  };
  render() {
    const { filename, column_name_csv } = this.state;
    let column_name_csv_text = '';
    if (column_name_csv) {
      column_name_csv_text = column_name_csv.value;
    }
    let filename_text = '';
    if (filename) {
      filename_text = filename.value;
    }
    return (
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item style={{ width: '100%' }} xs={6}>
          <TextField
            fullWidth
            id="output-filename-csv"
            InputLabelProps={{
              shrink: true,
            }}
            label="Output CSV filename"
            margin="dense"
            name={filename_text}
            onChange={event => this.handleUpdateFilename(event)}
            placeholder="Type filename of your CSV file (e.g. results.csv)..."
            required
            value={filename_text}
            variant="outlined"
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={6}>
          <TextField
            fullWidth
            id="output-column-name-csv"
            InputLabelProps={{
              shrink: true,
            }}
            label="Output Reference CSV column name"
            margin="dense"
            name={column_name_csv_text}
            onChange={event => this.handleUpdateColumnName(event)}
            placeholder="Type reference column name of your CSV file..."
            required
            value={column_name_csv_text}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

OutputCsv.propTypes = {
  configuration: PropTypes.object,
  handleOutputConfiguration: PropTypes.func,
  table_list: PropTypes.array,
};
OutputCsv.defaultProps = {
  configuration: null,
  table_list: null,
  handleOutputConfiguration: () => '',
};

export default OutputCsv;
