/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import { IsValid, IsInvalid, FormatTime } from 'helpers';
import { ClinicalSingleChildObjectViewer } from '..';

class ClinicalMultiChildObjectViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = (value, type) => {
    if (type === 'boolean') {
      /** empty */
    }
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { clinicalObject, columns } = this.props;
    if (!Array.isArray(clinicalObject)) return;

    return (
      <Box>
        {clinicalObject.length > 0 ? (
          <Box>
            {clinicalObject.map((obj, index) => (
              <Box key={index}>
                <ClinicalSingleChildObjectViewer
                  clinicalObject={obj}
                  columns={columns}
                />
                {index !== clinicalObject.length - 1 ? (
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                ) : null}
              </Box>
            ))}
          </Box>
        ) : (
          <Typography component="p" gutterBottom sx={{ p: 2 }} variant="p">
            No data to show
          </Typography>
        )}
      </Box>
    );
  }
}

ClinicalMultiChildObjectViewer.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalMultiChildObjectViewer;
