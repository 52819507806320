import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROJECT_CENTER_TEAMS = gql`
  query($idProject: LongLong!, $input_query: InputQueryCenterTeam!) {
    projectCenterTeams(idProject: $idProject, input_query: $input_query) {
      ok
      total
      project_center_teams {
        id

        idCenterTeam
        idProject
        folder_center {
          idFolder
          name
        }
        center_team {
          id
          idCenter
          idTeam
          center {
            id
            name
            admin {
              id
              FullName
            }
          }
          team {
            id
            name
            admin {
              id
              FullName
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const QueryProjectCenterTeams = (idProject, input_query) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_CENTER_TEAMS),
    variables: {
      idProject,
      input_query,
    },
  });
};
