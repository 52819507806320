import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USERS_PROJECTS = gql`
  query($name: String, $page: Int, $limit: Int) {
    SearchUsersAndProjects(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      objects {
        id
        name
        type
        avatar
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryUsersAndProjects = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined') variables.name = name;
  if (typeof page !== 'undefined') variables.page = page;
  if (typeof limit !== 'undefined') variables.limit = limit;
  if (typeof page !== 'undefined' || typeof limit !== 'undefined') {
    variables.page = 0;
    variables.limit = 10;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USERS_PROJECTS),
    variables,
  });
};
