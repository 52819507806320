import React, { Component } from 'react';
import { v1 as uuid } from 'uuid';

import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';

class MenuTopDown extends Component {
  render() {
    const { object_parent, open, handleClose, objects } = this.props;
    return (
      <Popper
        anchorEl={document.getElementById(object_parent)}
        disablePortal
        open={open}
        role={undefined}
        style={{ zIndex: '2000000', marginLeft: '-1rem' }}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'left top' : 'left bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose.bind(this)}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleClose.bind(this)}>
                  {objects.map((option, index) => (
                    <MenuItem
                      key={`title-registration-${uuid()}`}
                      onClick={handleClose.bind(this, { option, index })}
                      value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

export default MenuTopDown;
