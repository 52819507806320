import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Divider,
  Grid,
  Link,
  ListItemText,
  Typography,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { IOSSwitch } from 'components';
import {
  MutationCreateShareLink,
  MutationRemoveShareLink,
} from 'graphql/Syncs';
import { GetData, ServerErrorsString } from 'helpers';
import { IsUserSharedLink } from 'helpers';

class SharedLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switch_link: false,
      sharing: false,
      link: null,
    };
  }

  getLink = () => {
    const { folder_content, folder } = this.props;
    if (folder_content) {
      if (typeof folder_content.link === 'undefined') {
        return null;
      } else {
        return folder_content.link;
      }
    } else if (folder) {
      if (typeof folder.link === 'undefined') {
        return null;
      } else {
        return folder.link;
      }
    }
    return null;
  };
  getIDs = () => {
    const { folder_content, folder } = this.props;
    if (folder_content) {
      const { idFolderContent } = folder_content;
      return { idFolder: null, idFolderContent };
    } else if (folder) {
      const { idFolder } = folder;
      return { idFolder, idFolderContent: null };
    }
    return null;
  };

  componentDidMount() {
    const link = this.getLink();
    if (link) {
      this.setState({ switch_link: true, link });
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (!IsUserSharedLink(user)) {
      return;
    }
    const { switch_link, sharing } = this.state;
    if (sharing) return;
    const link = this.getLink();
    if (link && !switch_link) {
      this.setState({ switch_link: true });
    } else if (!link && switch_link) {
      this.setState({ switch_link: false });
    }
  }

  handleShareLink = () => {
    let switch_link = !this.state.switch_link;
    if (switch_link) {
      this.runCreateShareLink(switch_link);
    } else {
      this.runRemoveShareLink(switch_link);
    }
  };
  runCreateShareLink = switch_link => {
    const { sharing } = this.state;
    const ids = this.getIDs();
    if (sharing || !ids) return;
    this.setState({ sharing: true, switch_link });
    (async () => {
      MutationCreateShareLink(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, link } = data.createShareLink;
          if (ok) {
            this.props.handleSharedLinkChanged(ids, link);
            this.setState({ sharing: false, link });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ sharing: false, link: null });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };
  runRemoveShareLink = switch_link => {
    const { sharing } = this.state;
    const link = this.getLink();
    const ids = this.getIDs();
    if (sharing || !link || !ids) return;
    const { code } = link;
    this.setState({ sharing: true, switch_link });
    (async () => {
      MutationRemoveShareLink(code)
        .then(res => {
          //
          const data = GetData(res);
          const { ok, errors } = data.removeShareLink;
          if (ok) {
            this.props.handleSharedLinkChanged(ids, null);
            this.setState({ sharing: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ sharing: false });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { user } = this.props;
    if (!IsUserSharedLink(user)) {
      return null;
    }
    const link = this.getLink();
    const { switch_link, sharing } = this.state;
    let ShareLink = false;
    let code = null;
    if (link) {
      code = switch_link ? 'Creating...' : 'Removing...';
      if (!sharing) {
        code = link.code;
      }
      if (code) {
        ShareLink = true;
      }
    }
    return (
      <React.Fragment>
        <Divider />
        <ListItemText
          primary={
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between">
              {ShareLink ? (
                <Grid
                  container
                  style={{
                    padding: 0,
                    margin: 0,
                    maxWidth: 200,
                  }}>
                  <LinkIcon style={{ marginRight: 10 }} />
                  <Link
                    component="button"
                    onClick={() => {
                      this.props.handleGetSharedLink(link);
                    }}>
                    Get Shared Link
                  </Link>
                </Grid>
              ) : sharing ? (
                <CircularProgress size={25} style={{ marginRight: 10 }} />
              ) : (
                <Typography
                  gutterBottom
                  style={{ marginTop: 8, fontWeight: 1000 }}
                  variant="h6">
                  Shared Link
                </Typography>
              )}

              <IOSSwitch
                checked={switch_link}
                disabled={sharing}
                onChange={this.handleShareLink}
              />
            </Grid>
          }
        />

        <Divider />
      </React.Fragment>
    );
  }
}

SharedLink.propTypes = {
  folder: PropTypes.object,
  folder_content: PropTypes.object,
  handleGetSharedLink: PropTypes.func,
  handleSharedLinkChanged: PropTypes.func,
};
SharedLink.defaultProps = {
  handleGetSharedLink: () => '',
  handleSharedLinkChanged: () => '',
  folder: null,
  folder_content: null,
};
export default SharedLink;
