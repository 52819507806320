/* eslint-disable react/no-multi-comp */
import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { v1 as uuid } from 'uuid';
import { TableHeaders, TableToolbar } from 'components';
import {
  FindKey,
  getInitials,
  getNumber,
  getSorting,
  getUserFullName,
  IsUserObject,
  SelectedArray,
  stableSort,
  rowsPerPageSmall,
} from 'helpers';

// const ID = 'ID';
// const attributes = {
//   className: 'custom-root',
//   // disabledClassName: 'custom-disabled',
//   // dividerClassName: 'custom-divider',
//   // selectedClassName: 'custom-selected'
// };
const useStyles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  nameContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    // paddingBottom: -theme.spacing(1)
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
});

class TableBaseRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
    };
  }

  handleClickMouse = e => {
    // eslint-disable-next-line react/no-find-dom-node
    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      const { handleRowOver } = this.props;
      const { row } = this.state;
      if (handleRowOver) {
        handleRowOver(row);
      }
    }
  };

  componentDidUpdate() {
    const { row } = this.state;
    if (row !== this.props.row) {
      this.setState({ row: this.props.row });
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mouseover', this.handleClickMouse, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseover', this.handleClickMouse, false);
  }

  render() {
    const {
      classes,
      row,
      handleSelectedRow,
      handleButtonView,
      isItemSelected,
      handleClick,
      columns,
      index,
      dense,
      handleAcceptReject,
    } = this.props;
    const labelId = `enhanced-table-checkbox-${index}`;
    //
    return (
      <TableRow
        hover
        key={`table-row-${row.id}`}
        // onChange={console.log(index)}
        onClick={() => {
          if (handleSelectedRow) {
            handleSelectedRow(row);
          }
        }}
        role="checkbox"
        selected={isItemSelected}
        tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={event => {
              handleClick(event, index, row);
            }}
          />
        </TableCell>
        {columns.map((column, col_index) => {
          const index_column = col_index + index;
          let value = '';
          if (FindKey(row, column.id)) {
            value = row[column.id];
          } else if (column.id === 'name' && IsUserObject(row)) {
            value = getUserFullName(row);
          } else if (FindKey(row, 'total_projects')) {
            value = row['total_projects'];
          } else if (FindKey(row, 'total_members')) {
            value = row['total_members'];
          }
          if (column.id === 'projects') {
            if (FindKey(row, 'projects')) {
              value = row['projects'].length;
            } else if (FindKey(row, 'total_projects')) {
              value = row['total_projects'];
            }
          } else if (column.id === 'members') {
            if (FindKey(row, 'members')) {
              value = row['members'].length;
            } else if (FindKey(row, 'total_members')) {
              value = row['total_members'];
            }
          }

          if (column.type === 'WithIndex') {
            value = {
              value,
              admin: row.admin,
              i: index_column,
              handleAcceptReject: handleAcceptReject,
              row,
            };
          }
          let avatar = '';
          if (FindKey(row, 'avatarUrl')) {
            avatar = row.avatarUrl;
          } else if (FindKey(row, 'avatar')) {
            avatar = row.avatar;
          } else {
            avatar = '/images/avatars/avatar_0.png';
          }
          return (
            <TableCell
              align={column.align}
              key={`cell-key-${uuid()}-${column.id}`}>
              {column.id === 'name' ? (
                <div className={classes.nameContainer}>
                  <Avatar className={classes.avatar} src={avatar}>
                    {getInitials(value)}
                  </Avatar>
                  <Typography variant="body1">{value}</Typography>
                </div>
              ) : column.type === 'buttom' ? (
                <Button
                  className={clsx({
                    [classes.button_no_dense]: true,
                    [classes.button_dense]: dense,
                  })}
                  color="primary"
                  onClick={() => {
                    if (handleButtonView) {
                      handleButtonView(row);
                    }
                  }}
                  variant="outlined">
                  {row.kind === '--' ? 'Open' : 'View'}
                </Button>
              ) : column.type === 'count' ? (
                getNumber(value).toString()
              ) : column.format ? (
                column.format(value)
              ) : column.type === 'WithIndex' ? (
                column.format ? (
                  column.format(value)
                ) : (
                  value
                )
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

class TableBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'name',
      selected: [],
      rows: props.rows ? props.rows : [],
      dense: false,
      columns: props.columns,
      row_over: -1,
    };
  }

  handleRowOver = row => {
    const { row_over } = this.state;
    // const { name } = row;
    //
    if (row_over !== row) {
      //
      this.setState({ row_over: row });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      const newSelecteds = rows.map(row => this.getIndexRow(row));
      // const n = rows.map((n, index) => n);

      this.setState({ selected: newSelecteds });
    } else {
      this.setState({ selected: [] });
    }
  };
  getIndexRow = row => {
    const { rows } = this.props;
    if (!rows) {
      return -1;
    }
    for (let index in rows) {
      const to_check = rows[index];
      let count = 0;
      let total_keys = 0;
      for (let key in row) {
        total_keys++;
        if (row[key] === to_check[key]) {
          count++;
        }
      }
      if (total_keys === count) {
        return parseInt(index, 10);
      }
    }
    return -1;
  };
  handleClick = (event, index, row) => {
    event.preventDefault();
    //
    //
    index = this.getIndexRow(row);

    //
    const { selected } = this.state;
    const selectedIndex = selected ? selected.indexOf(index) : 0;
    const newSelected = SelectedArray(selected, index, selectedIndex);

    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc' });
    this.setState({ orderBy: property });
  };
  handleDeleteSelected = () => {
    const { selected } = this.state;

    const { externalDeleteSelected } = this.props;
    if (externalDeleteSelected) {
      externalDeleteSelected(selected);
      this.setState({ selected: [] });
    }
  };

  render() {
    const {
      classes,
      rows,
      title,
      handleSelectedRow,
      handleButtonView,
      handleAcceptReject,
    } = this.props;
    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      selected,
      dense,
      columns,
    } = this.state;
    const isSelected = row => selected.indexOf(this.getIndexRow(row)) !== -1;
    return (
      <Paper className={classes.root}>
        <TableToolbar
          handleDeleteSelected={this.handleDeleteSelected}
          numSelected={selected.length}
          title={title}
        />
        <div className={classes.tableWrapper}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHeaders
              classes={classes}
              headCells={columns}
              numSelected={selected.length}
              onRequestSort={this.handleRequestSort}
              onSelectAllClick={this.handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  return (
                    <TableBaseRow
                      classes={classes}
                      columns={columns}
                      dense={dense}
                      handleAcceptReject={handleAcceptReject}
                      handleButtonView={handleButtonView}
                      handleClick={this.handleClick}
                      handleRowOver={this.handleRowOver}
                      handleSelectedRow={handleSelectedRow}
                      index={index}
                      isItemSelected={isItemSelected}
                      key={`table-base-row-${index}`}
                      row={row}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={rows.length}
          onPageChange={this.handleChangePage.bind(this)}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageSmall}
        />
        {/* {menu_items && (
          <ContextMenu id={ID}>
            {menu_items.map((text, index_menu) => {
              return text === 'divider' ? (
                <MenuItem divider key={`menu-item-table-base-${index_menu}`} />
              ) : (
                <MenuItem
                  attributes={attributes}
                  data={{ action: text, row: row_over }}
                  key={`menu-item-table-base-${index_menu}`}
                  onClick={handleClickMenu}>
                  {text}
                </MenuItem>
              );
            })}
          </ContextMenu>
        )} */}
      </Paper>
    );
  }
}

TableBase.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};
export default withStyles(TableBase, useStyles);
