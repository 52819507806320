import React from 'react';
import PropTypes from 'prop-types';
import { EditMetadataToolBar, EditMetadataContent } from '../index';
import { Box } from '@mui/material';
import { MutationUpdateMultiFileMetadata } from 'graphql/Files';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { getInputs, updateMetadata } from './components';
import { udpateMutltiGroup, udpateSingleGroup } from '../utils';

class EditMedataMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_content: null,
      metadata: null,
      props_metadata: {},
    };
  }
  createMetaFromProps = () => {
    return {
      ...this.state.props_metadata,
      group_mappings: [...this.state.props_metadata.group_mappings],
    };
  };
  async componentDidMount() {
    const { selected_content } = this.props;
    const props_metadata = await updateMetadata(selected_content);
    this.setState({ props_metadata });
  }
  handleClose = () => {
    this.setState({
      folder_content: null,
      metadata: null,
      props_metadata: {},
    });
    this.props.handleClose();
  };
  handleGroupMappingSelected = (idGroup, group_name, group_map) => {
    let { changed, metadata } = this.state;
    console.log({ group_map });
    if (IsInvalid(metadata)) {
      metadata = this.createMetaFromProps();
    }
    const is_array = Array.isArray(group_map);
    if (metadata) {
      if (is_array) {
        metadata = udpateMutltiGroup(idGroup, group_map, metadata);
      } else {
        metadata = udpateSingleGroup(idGroup, group_map, metadata);
      }
    } else if (group_map) {
      metadata = this.getCloneProps();
      if (is_array) metadata.group_mappings = [...group_map];
      else metadata.group_mappings = [group_map];
    }
    console.log({
      others: metadata.group_mappings,
    });
    this.setState({
      metadata,
      changed: !changed,
    });
  };
  handleCommentsChanged = event => {
    let { metadata } = this.state;
    if (metadata) {
      metadata.comments = event.target.value;
    } else {
      metadata = this.createMetaFromProps();
      metadata.comments = event.target.value;
    }
    this.setState({ metadata });
  };
  handleNotesChanged = event => {
    let { metadata } = this.state;
    if (metadata) {
      metadata.notes = event.target.value;
    } else {
      metadata = this.createMetaFromProps();
      metadata.notes = event.target.value;
    }
    this.setState({ metadata });
  };
  handleUpdateKeywords = (idKeyword, keywords) => {
    let { metadata } = this.state;
    console.log({ keywords });
    if (IsInvalid(metadata)) {
      metadata = this.createMetaFromProps();
    }
    const { group_mappings } = metadata;
    if (group_mappings && group_mappings.length) {
      const not_keywords = group_mappings.filter(x => x.idGroup !== idKeyword);
      metadata.group_mappings = not_keywords.concat(keywords);
    } else {
      metadata.group_mappings = keywords;
    }
    console.log({ g: metadata.group_mappings });
    this.setState({ metadata });
  };
  handleUpdateMetadata = () => {
    const { updating } = this.state;
    const { metadata } = this.state;
    const { selected_content } = this.props;
    const inputs = getInputs(selected_content, metadata);
    if (updating) return;
    if (!inputs.length) {
      this.props.handleAddError('Empty inputs');
      return;
    }
    this.setState({ updating: true });
    (async () => {
      MutationUpdateMultiFileMetadata(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateMetadata;
          if (ok) {
            this.setState({
              updating: false,
              metadata: null,
              props_metadata: {
                ...metadata,
              },
            });
            this.props.handleUpdateMetadata(inputs);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsNotChanged = () => {
    const new_metadata = this.state.metadata;
    if (!new_metadata) return true;
    const { props_metadata } = this.state;
    if (!props_metadata) return false;
    let { group_mappings, notes, comments } = props_metadata;
    if (new_metadata.notes === '') new_metadata.notes = null;
    if (new_metadata.comments === '') new_metadata.comments = null;
    if (notes !== new_metadata.notes) {
      return false;
    }
    if (comments !== new_metadata.comments) {
      return false;
    }
    if (group_mappings.length !== new_metadata.group_mappings.length) {
      return false;
    }
    let count = 0;
    for (let i = 0; i < new_metadata.group_mappings.length; i++) {
      const { idGroup, name } = new_metadata.group_mappings[i];
      const key = `${idGroup}-${name}`;
      const index = group_mappings
        .map(x => `${x.idGroup}-${x.name}`)
        .indexOf(key);
      if (index !== -1) count++;
    }
    return count === group_mappings.length;
  };
  render() {
    const { selected_content } = this.props;
    let { folder_content, metadata, updating, props_metadata } = this.state;
    if (!metadata) metadata = props_metadata;
    return (
      <React.Fragment>
        <EditMetadataToolBar
          handleClose={this.handleClose}
          handleUpdateMetadata={this.handleUpdateMetadata}
          notchanged={this.IsNotChanged()}
          title={`Edit Multi-Image Metadata (${selected_content.length})`}
          updating={updating}
        />
        <Box sx={{ width: '100%', padding: 5 }}>
          <EditMetadataContent
            folder_content={folder_content}
            handleAddError={this.props.handleAddError}
            handleCommentsChanged={this.handleCommentsChanged}
            handleGroupMappingSelected={this.handleGroupMappingSelected}
            handleNotesChanged={this.handleNotesChanged}
            handleUpdateKeywords={this.handleUpdateKeywords}
            metadata={metadata}
          />
        </Box>
      </React.Fragment>
    );
  }
}

EditMedataMulti.propTypes = {
  handleUpdateMetadata: PropTypes.func,
};
EditMedataMulti.defaultProps = {
  handleUpdateMetadata: () => '',
};

export default EditMedataMulti;
