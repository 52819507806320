/* eslint-disable indent */
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { HTTP_ADDRESS_WEB } from 'config';
import { DivGrow, GreenCheckbox } from 'components';
import { MutationSharedLinkToPrivated } from 'graphql/Syncs';
import { GetData } from 'helpers';
import { getParamsUrl } from 'helpers';
import { IsInvalid } from 'helpers';
class DialogGetSharedLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      copied_link: false,
      copied_code: false,
    };
  }

  handleClickLink = () => {
    const { code, idFolder } = this.props.link;
    let type = 'folder';
    if (idFolder === undefined || !idFolder) {
      type = 'file';
    }
    this.props.history.push(`/shared/${type}/${code}`);
  };
  handleChange = event => {
    const private_access = event.target.checked;
    const { link } = this.props;
    const { code, idFolder, idFolderContent } = link;
    const { loading } = this.state;
    if (loading || !code) return;
    this.setState({ loading: true });
    (async () => {
      MutationSharedLinkToPrivated(private_access, code)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.sharedLinkToPrivated;
          if (ok) {
            const new_link = { ...link, private_access };
            this.props.handleSharedLinkChanged(
              { idFolder, idFolderContent },
              new_link
            );
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log({ errors });
          this.setState({ loading: false });
        });
    })();
  };
  handleClose = () => {
    this.setState({ copied_link: false, copied_code: false });
    this.props.handleClose();
  };

  render() {
    const { open, link } = this.props;
    if (IsInvalid(link)) return null;
    const { code, idFolder, private_access } = link;
    let type = 'folder';
    if (IsInvalid(idFolder)) {
      type = 'file';
    }
    const { loading, copied_link, copied_code } = this.state;
    let text_link = `${HTTP_ADDRESS_WEB}/shared/${type}/${code}`;
    let page = getParamsUrl(this.props);
    if (page) {
      let real_url = window.location.href;
      real_url = real_url.replace(page, '');
      if (HTTP_ADDRESS_WEB !== real_url) {
        text_link = `${real_url}/shared/${type}/${code}`;
      }
    }
    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={this.handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">Shared Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By default shared links are public anyone with the link can access
            the content. Clicking the checkbox you can change the default value.
          </DialogContentText>
          <Grid
            alignItems="stretch"
            container
            direction="column"
            justifyContent="flex-start"
            pt={2}>
            <Link
              // component="button"
              href={text_link}
              onClick={this.handleClose}
              rel="noopener"
              target="_blank"
              variant="body2">
              {text_link}
            </Link>
            <FormControlLabel
              control={
                loading ? (
                  <CircularProgress size={25} style={{ marginRight: 10 }} />
                ) : (
                  <GreenCheckbox
                    checked={private_access}
                    name="checkedG"
                    onChange={this.handleChange}
                  />
                )
              }
              label="Private access (Only members can access)"
              style={{ marginTop: 10 }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {copied_link || copied_code ? (
            <span style={{ color: 'green', fontWeight: 1000, marginLeft: 20 }}>
              {copied_link
                ? 'Link Copied to Clipboard...'
                : copied_code
                ? 'Code Copied to Clipboard...'
                : ''}
            </span>
          ) : null}
          <DivGrow />
          <CopyToClipboard
            onCopy={() =>
              this.setState({ copied_link: false, copied_code: true })
            }
            text={code}>
            <Button color="primary">Copy Code</Button>
          </CopyToClipboard>
          <CopyToClipboard
            onCopy={() =>
              this.setState({ copied_link: true, copied_code: false })
            }
            text={text_link}>
            <Button color="primary">Copy Link</Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogGetSharedLink.propTypes = {};

export default withRouter(DialogGetSharedLink);
