import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns } from './Styles';
class ViewUnitList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  render() {
    const { summaries } = this.props;
    // console.log({ summaries });
    return (
      <TableGrid
        checkboxSelection
        dense
        external_selected={this.state.selected}
        headers={columns}
        onSelectedChanged={selected => this.setState({ selected })}
        rows={summaries}
        show_pagination={false}
      />
    );
  }
}

ViewUnitList.propTypes = {
  classes: PropTypes.object,
};

export default ViewUnitList;
