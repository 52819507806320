import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { ComboBox, ButtonLoading } from 'components';
import { ListAccessLevel } from 'common';
class MemberSettingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      member_setting: null,
    };
  }
  componentDidUpdate() {
    const { member } = this.props;
    if (!member || member === undefined) {
      if (!member && this.state.member_setting) {
        this.setState({ member_setting: null });
      }
      return;
    }
    if (!this.state.member_setting && member) {
      this.setState({ member_setting: member });
    } else if (
      this.state.member_setting &&
      member &&
      this.state.member_setting.id !== member.id
    ) {
      this.setState({ member_setting: member });
    }
  }
  handleCheckChange = event => {
    let { member_setting } = this.state;
    if (!member_setting) return;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    member_setting = { ...member_setting, [name]: checked };
    this.setState({ member_setting });
    this.props.handleMemberSettingChanged(member_setting);
  };
  //   handleEmailChanged = event => {
  //     const { member_setting } = this.state;
  //     if (!member_setting) return;
  //     const new_setting = {
  //       ...member_setting,
  //       email_notification: event.target.value,
  //     };
  //     this.setState({
  //       member_setting: new_setting,
  //     });
  //   };
  handleAccessLevelChanged = access => {
    let { member_setting } = this.state;
    if (!member_setting) return;
    const access_level = ListAccessLevel.indexOf(access);
    if (access_level !== -1) {
      member_setting = { ...member_setting, access_level };
      this.setState({ member_setting });
      this.props.handleMemberSettingChanged(member_setting);
    }
  };
  //   IsValidEmail = email => {
  //     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //       return true;
  //     }
  //     return false;
  //   };
  render() {
    let access_level = 2;
    let email_notification = false;
    let upload_notification = false;
    if (this.state.member_setting) {
      access_level = this.state.member_setting.access_level;
      email_notification = this.state.member_setting.email_notification;
      upload_notification = this.state.member_setting.upload_notification;
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <ComboBox
            disabled={!this.state.member_setting}
            label="Access Level"
            onChange={this.handleAccessLevelChanged}
            placeholder="To select click member"
            size="small"
            titles={ListAccessLevel}
            value={ListAccessLevel[access_level]}
          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={email_notification}
                disabled={!this.state.member_setting}
                name="email_notification"
                onChange={this.handleCheckChange}
              />
            }
            label="Email Notification"
          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upload_notification}
                disabled={!this.state.member_setting}
                name="upload_notification"
                onChange={this.handleCheckChange}
              />
            }
            label="Upload Notification"
          />
        </Grid>
        <Grid item xs={2}>
          <ButtonLoading
            disable_button={!this.props.IsChanged()}
            handleClick={this.props.handleUpdateSettings}
            loading={this.props.updating}
            name="Save"
            transform={false}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

MemberSettingEditor.propTypes = {
  member: PropTypes.object,
};
MemberSettingEditor.defaultProps = {
  handleMemberSettingChanged: () => '',
};
export default MemberSettingEditor;
