import {
  RUNNING_PROCESSES
} from '../actions/types';

const initialState = {
  running_processes: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case RUNNING_PROCESSES:
      return {
        ...state,
        running_processes: action.running_processes,
      };
    default:
      return state;
  }
};

export default reducer;
