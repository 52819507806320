import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_unmapseries } from './utils';
import { rowsPerPageBig } from 'helpers';
import { QuerySeriesSequenceUnMapped } from 'graphql/Series/utils_series_sequences';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
import { Grid, Paper } from '@mui/material';
import { EditSeriesSequences, TabUnMappedHeader } from './components';

class UnMappedSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selected: [],
      unmapped: [],
      total: 0,
      loading: false,
      page: 0,
      limit: 100,
      open: false,
    };
  }
  componentDidMount() {
    this.runQuerySeriesSequenceUnMaps();
  }
  handleSeriesRowClicked = mapped => {
    console.log({ mapped });
    this.setState({ clicked: mapped });
  };
  handleClickSelected = selected => {
    if (selected === undefined || !selected) return;
    this.setState({ selected });
  };
  runQuerySeriesSequenceUnMaps = (
    newName = null,
    newPage = null,
    newLimit = null
  ) => {
    let { loading, page, limit, name } = this.state;
    if (loading) {
      return;
    }
    if (newPage) {
      page = newPage;
    }
    if (newLimit) {
      limit = newLimit;
    }
    if (newName !== null) {
      name = newName;
    }
    console.log({ name, newName });
    this.setState({ loading: true, name: newName });
    (async () => {
      QuerySeriesSequenceUnMapped(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, ListSeries, total, errors } = data.seriesSequenceUnMapped;
          if (ok) {
            this.setState({
              unmapped: ListSeries,
              total,
              loading: false,
              page,
              limit,
              name,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ unmapped: [], loading: false, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangeName = event => {
    event.preventDefault();
    this.runQuerySeriesSequenceUnMaps(event.target.value);
  };
  handleSeriesMapped = () => {
    let { selected, unmapped } = this.state;
    const ids = selected.map(x => x.idSeries);
    console.log({ ids });
    unmapped = unmapped.filter(x => {
      return ids.indexOf(x.idSeries) === -1;
    });
    this.setState({ selected: [], unmapped, open: false });
  };
  render() {
    const { unmapped, selected, total, loading } = this.state;
    const { height } = this.props;
    return (
      <Grid container spacing={1} sx={{ padding: 1 }}>
        <Grid item xs={12}>
          <TabUnMappedHeader
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleChangeName={this.handleChangeName}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleOpenEdit={() => this.setState({ open: true })}
            handleReloadTable={() => this.runQuerySeriesSequenceUnMaps()}
            loading={loading}
            name={this.state.name}
            selected={selected}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={selected}
              headers={columns_unmapseries}
              height={height}
              idRow="idSeries"
              loading={loading}
              onHighlightChange={this.handleSeriesRowClicked}
              onPageChange={page =>
                this.runQuerySeriesSequenceUnMaps(null, page)
              }
              onRowClicked={this.handleSeriesRowClicked}
              onRowsPerPageChange={limit =>
                this.runQuerySeriesSequenceUnMaps(null, null, limit)
              }
              onSelectedChanged={this.handleClickSelected}
              page={this.state.page}
              paginationMode="server"
              rowCount={total}
              rows={unmapped}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Paper>
        </Grid>
        <EditSeriesSequences
          handleClose={() => this.setState({ open: false })}
          handleSeriesMapped={this.handleSeriesMapped}
          open={this.state.open}
          selected={selected}
        />
      </Grid>
    );
  }
}

UnMappedSeries.propTypes = {
  classes: PropTypes,
};

export default UnMappedSeries;
