import React from 'react';
import PropTypes from 'prop-types';
import {
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Divider,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import PanToolIcon from '@mui/icons-material/PanTool';
import MouseIcon from '@mui/icons-material/Mouse';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ContrastIcon from '@mui/icons-material/Contrast';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import EditIcon from '@mui/icons-material/Edit';
// import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
// import Brightness2Icon from '@mui/icons-material/Brightness2';
// import FlipIcon from '@mui/icons-material/Flip';
// import OpenWithIcon from '@mui/icons-material/OpenWith';
// import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
// import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
// import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
// import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
class ToolBarViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formats: ['italic'],
      interactions: ['slicing'],
      anchorRef: null,
      menu: { name: 'None', IconMenu: DoNotDisturbAltIcon },
      menu_list: [
        { name: 'None', IconMenu: DoNotDisturbAltIcon },
        { name: 'Length', IconMenu: OpenInFullIcon },
        { name: 'Angle', IconMenu: ArchitectureIcon },
      ],
    };
  }
  handleClick = event => {
    event.preventDefault();
    let anchorRef = event.currentTarget;
    if (this.state.anchorRef) anchorRef = null;
    this.setState({ anchorRef });
  };
  handleClickMenu = menu => {
    this.setState({ menu, anchorRef: null });
    this.props.handleViewerAction(menu.name);
  };
  handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ anchorRef: null });
    } else if (event.key === 'Escape') {
      this.setState({ anchorRef: null });
    }
  };
  render() {
    const { formats, interactions, anchorRef, menu } = this.state;
    let open = Boolean(anchorRef);
    let MenuIconAction = FormatColorFillIcon;
    console.log({ menu });
    if (menu.name !== 'None') MenuIconAction = menu.IconMenu;
    return (
      <div>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            border: theme => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
          }}>
          <StyledToggleButtonGroup
            exclusive
            onChange={(event, interactions) => {
              this.setState({ interactions });
              this.props.handleImageAction(interactions);
            }}
            size="small"
            value={interactions}>
            <ToggleButton aria-label="bold" value="slicing">
              <MouseIcon />
            </ToggleButton>
            <ToggleButton aria-label="italic" value="contrast">
              <ContrastIcon />
            </ToggleButton>
            <ToggleButton aria-label="italic" value="zoom">
              <SearchIcon />
            </ToggleButton>
            <ToggleButton aria-label="underlined" value="pan">
              <PanToolIcon />
            </ToggleButton>
            <ToggleButton aria-label="underlined" value="rotate_left">
              <RotateLeftIcon />
            </ToggleButton>
            <ToggleButton aria-label="color" value="rotate_right">
              <RotateRightIcon />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
            aria-label="text formatting"
            onChange={(event, formats) => {
              this.setState({ formats });
            }}
            size="small"
            value={formats}>
            <ToggleButton aria-label="bold" value="bold">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton aria-label="italic" value="italic">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton aria-label="underlined" value="underlined">
              <FormatUnderlinedIcon />
            </ToggleButton>
            <ToggleButton
              aria-label="color"
              onClick={this.handleClick}
              value="color">
              <MenuIconAction />
              <ArrowDropDownIcon />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Popper
            anchorEl={anchorRef}
            disablePortal
            open={open}
            placement="bottom-start"
            role={undefined}
            sx={{ zIndex: 1000, display: open ? null : 'none' }}
            transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  zIndex: 1000,
                }}>
                <Paper sx={{ zIndex: 1000 }}>
                  <ClickAwayListener
                    onClickAway={() => this.setState({ anchorRef: null })}>
                    <MenuList
                      aria-labelledby="composition-button"
                      autoFocusItem={open}
                      id="composition-menu"
                      onKeyDown={this.handleListKeyDown}>
                      {this.state.menu_list.map(option => {
                        const { name, IconMenu } = option;
                        return (
                          <MenuItem
                            disableRipple
                            key={name}
                            onClick={() => this.handleClickMenu(option)}
                            selected={this.state.menu.name === name}>
                            <IconMenu />
                            <div style={{ paddingLeft: 10 }}>{name}</div>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Paper>
      </div>
    );
  }
}

ToolBarViewer.propTypes = {
  classes: PropTypes.object,
};
ToolBarViewer.defaultProps = {
  handleViewerAction: () => '',
  handleImageAction: () => '',
};

export default ToolBarViewer;
