const useStyles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(2),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    height: '42px',
    // marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  searchButton: {
    height: '42px',
    margin: theme.spacing(1),
  },
  textTop: {
    // marginBottom: theme.spacing(2),
  },
});

export default useStyles;
