import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import AddIcon from '@mui/icons-material/Add';
import { IsInvalid } from 'helpers';
import DialogCreateRuleTag from './DialogCreateRuleTag';
import SaveIcon from '@mui/icons-material/Save';
import {
  MutationAddAnonymizationTags,
  MutationRemoveAnonymizationTags,
  MutationUploadAnonymizationTagsCSV,
} from 'graphql/Anonymization';
import { ServerErrorsString, GetData } from 'helpers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoadingButton from '@mui/lab/LoadingButton';
class TabRulesTagsToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_info: false,
      open_delete: false,
      uploading: false,
      open_create: false,
      deleting: false,
    };
  }
  handleCreateDialog = () => {};
  handleSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadAnonymizationTagsCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.uploadAnonymizationTags;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleCreatedTagRule(rules);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationSaveChanges = () => {
    const { rows_edited } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited
      .map(x => {
        const { rule, idProject, tag_rule, tag_value } = x;
        if (IsInvalid(rule)) return null;
        const { tag } = rule;
        return {
          tag,
          idProject,
          tag_rule,
          tag_value,
        };
      })
      .filter(x => x);
    console.log({ inputs, rows_edited });
    this.setState({ saving: true });
    (async () => {
      MutationAddAnonymizationTags(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAnonymizationTags;
          if (ok) {
            this.props.handleCreatedTagRule(rows_edited, true);
            this.setState({ saving: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreatedTagRule = rules => {
    this.props.handleCreatedTagRule(rules);
    this.setState({ open_create: false });
  };
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { selected, rules } = this.props;
    const { deleting } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    (async () => {
      MutationRemoveAnonymizationTags(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeAnonymizationTags;
          if (ok) {
            this.props.setState({
              rules: rules.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
            });
            this.setState({ deleting: false, open_delete: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected, rows_edited } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pr={2}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.button_group}
              exclusive="false"
              size="small"
              value="save">
              <LoadingButton
                disabled={!rows_edited.length}
                loading={this.state.saving}
                onClick={this.mutationSaveChanges}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </LoadingButton>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <ButtonDownloadType
                idProject={this.props.idProject}
                key="anonymization"
                type="anonymization_tags"
              />
              <ButtonFileUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
              <Button onClick={this.props.handleOpenHelp}>
                <HelpOutlineIcon style={{ color: 'green' }} />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogCreateRuleTag
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreatedTagRule={this.handleCreatedTagRule}
          idProject={this.props.idProject}
          open={this.state.open_create}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the tag, this action cannot be undone"
          title_text="Delete Selected Tags"
        />
      </React.Fragment>
    );
  }
}

TabRulesTagsToolbar.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
TabRulesTagsToolbar.defaultProps = {
  selected: [],
  rows_edited: [],
};

export default TabRulesTagsToolbar;
