import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { connect } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { ExplorerFolderTreeItem } from './components';
import { setExplorerNodeID } from 'redux-store/actions';

const useStyles = () => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

class ExplorerViewListTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: ['3'],
      nodeIds: [],
    };
  }

  onNodeSelect = (event, nodeId) => {
    this.props.setExplorerNodeID(nodeId);
  };
  handleNodeToggle = (event, nodeIds) => {
    this.setState({ nodeIds });
  };
  render() {
    const { classes, SubFolders, base, selected, user } = this.props;
    return (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={this.onNodeSelect}
        onNodeToggle={this.handleNodeToggle}
        selected={selected}>
        {SubFolders.map(sub_folder => {
          const { idFolder } = sub_folder;
          return (
            <ExplorerFolderTreeItem
              base={base}
              color="#1a73e8"
              disable_click={this.props.disable_click}
              handleAddError={this.props.handleAddError}
              handleItemClick={this.props.handleItemClick}
              idHomeFolder={this.props.idHomeFolder}
              itemId={idFolder.toString()}
              key={`first-level-folder-item-${idFolder}`}
              nodeId={idFolder.toString()}
              nodeIds={this.state.nodeIds}
              selectedId={selected}
              sub_folder={sub_folder}
              user={user}
            />
          );
        })}
      </TreeView>
    );
  }
}

ExplorerViewListTree.propTypes = {
  classes: PropTypes.object,
};
ExplorerViewListTree.defaultProps = {
  SubFolders: [],
  disable_click: false,
  handleItemClick: () => '',
  handleAddError: () => '',
};

const mapStateToProps = state => ({
  view_type: state.page_explorer.view_type,
  selected: state.page_explorer.nodeId,
});

export default connect(mapStateToProps, {
  setExplorerNodeID,
})(withStyles(ExplorerViewListTree, useStyles));
