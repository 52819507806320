export const useStyles = () => ({
  p: {
    margin: '0cm',
    fontSize: '16px',
    fontFamily: '"Calibri",sans-serif;margin-left:35.45pt;text-indent:-35.45pt',
  },
  span_black_p: {
    margin: '0cm',
    fontSize: '16px',
    fontFamily: 'Menlo;margin-left:35.45pt;text-indent:-35.45pt',
  },
  span: {
    fontSize: '13px',
    fontWeight: 1000,
    fontFamily: 'Menlo',
    color: '#C41A16',
  },
  span_black: {
    fontSize: '12px',
    fontFamily: 'Menlo',
    color: 'black',
  },
});
