import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSleepCSV } from './utils';

const QUERY_SLEEP_VIGILANCES = gql`
  query($name: String, $page: Int, $limit: Int) {
    sleepVigilances(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      vigilances {
        id
        idPatient
        idSleepCase
        test_type
        pvt_mean
        pvt_lapses
        sart_total_errors
        stear_clear
        pupillography_var

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepVigilances = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_VIGILANCES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_SLEEP_VIGILANCES = gql`
  mutation($ids: [LongLong!]!) {
    deleteSleepVigilances(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveSleepVigilances = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_SLEEP_VIGILANCES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_SLEEP_VIGILANCES = gql`
  mutation($inputs: [InputSleepVigilance!]!) {
    addSleepVigilances(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      vigilances {
        id
        idPatient
        idSleepCase
        test_type
        pvt_mean
        pvt_lapses
        sart_total_errors
        stear_clear
        pupillography_var

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddSleepVigilances = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SLEEP_VIGILANCES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_SLEEP_VIGILANCES = gql`
  mutation($file: Upload!) {
    uploadSleepVigilances(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      vigilances {
        id
        idPatient
        idSleepCase
        test_type
        pvt_mean
        pvt_lapses
        sart_total_errors
        stear_clear
        pupillography_var

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadSleepVigilances = (file, handleProgress = null) => {
  return MutationUploadSleepCSV(
    file,
    MUTATION_UPLOAD_SLEEP_VIGILANCES,
    handleProgress
  );
};
