import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styleToolbar } from 'common';
import { ButtonReloadDelete, BadgeIcon } from 'components';
import HiveIcon from '@mui/icons-material/Hive';
import PanToolIcon from '@mui/icons-material/PanTool';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PauseIcon from '@mui/icons-material/Pause';
import {
  ButtonGroupBatchActions,
  addToBatchProcess,
  executeActionToBatchProcess,
} from './components';
class ToolBarJobsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: 'input_jobs',
    };
    this.holder = { getSetting: null };
    const { holder } = props;
    if (holder) {
      holder.setTabClicked = clicked => this.handleChange(null, clicked);
    }
  }
  componentDidMount() {
    const { clicked } = this.props;
    if (clicked !== this.state.clicked) {
      this.setState({ clicked });
    }
  }
  componentDidUpdate() {
    const { clicked } = this.props;
    if (clicked !== this.state.clicked) {
      this.setState({ clicked });
    }
  }
  handleChange = (event, clicked) => {
    this.setState({ clicked });
    this.props.handleViewChanged(clicked);
  };
  handleRunBatchProcess = () => {
    const { clicked } = this.props;
    if (clicked === 'input_jobs') {
      addToBatchProcess(this);
    } else if (clicked === 'batches') {
      executeActionToBatchProcess(this, 1);
    }
  };
  render() {
    const { classes, selected } = this.props;
    const { clicked } = this.state;
    const numSelected = selected.length;
    let numJobs = 0;
    let numBatch = 0;
    let numStop = 0;
    let numRun = 0;
    if (clicked === 'input_jobs') numJobs = numSelected;
    else if (clicked === 'batches') numBatch = numSelected;
    else if (clicked === 'stopped') numStop = numSelected;
    else if (clicked === 'running') numRun = numSelected;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid container item xs={6}>
            <Grid item>
              <ToggleButtonGroup
                aria-label="Small sizes"
                exclusive
                onChange={this.handleChange}
                size="small"
                value={clicked}>
                <ToggleButton
                  sx={{
                    paddingTop: numJobs ? 0 : '10px',
                    marginLeft: numJobs ? 0 : '10px',
                    paddingLeft: numJobs ? 0 : '10px',
                    minWidth: numJobs ? '50px' : 0,
                  }}
                  value="input_jobs">
                  <BadgeIcon
                    color={clicked === 'input_jobs' ? 'green' : 'grey'}
                    count={numJobs}
                    Icon={HourglassEmptyIcon}
                    max={999}
                    title={`Single Input Jobs ${numJobs ? `(${numJobs})` : ''}`}
                  />
                </ToggleButton>
                <ToggleButton value="batches">
                  <BadgeIcon
                    color={clicked === 'batches' ? 'green' : 'grey'}
                    count={numBatch}
                    Icon={HiveIcon}
                    max={999}
                    title={`Batches ${numBatch ? `(${numBatch})` : ''}`}
                  />
                </ToggleButton>
                <ToggleButton value="stopped">
                  <BadgeIcon
                    color={clicked === 'stopped' ? 'green' : 'grey'}
                    count={numStop}
                    Icon={PanToolIcon}
                    max={999}
                    title={`Stopped Job/Batches ${
                      numStop ? `(${numStop})` : ''
                    }`}
                  />
                </ToggleButton>
                <ToggleButton value="running">
                  <BadgeIcon
                    color={clicked === 'running' ? 'green' : 'grey'}
                    count={numRun}
                    Icon={PauseIcon}
                    max={999}
                    title={`Running Job/Batches ${numRun ? `(${numRun})` : ''}`}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <ButtonGroupBatchActions
                batch={this.props.batch}
                handleAddError={this.props.handleAddError}
                handleOpenBatchEdit={this.props.handleOpenBatchEdit}
                handleRunJob={() => this.handleRunBatchProcess()}
                handleUpdateBatches={this.props.handleUpdateBatches}
                handleUpdateJobProcess={this.props.handleUpdateJobProcess}
                holder={this.holder}
                job_clicked={this.props.job_clicked}
                selected={selected}
                tabValue={clicked}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              deleting={this.state.deleting}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReloadTable}
              // hide_reload={clicked === 'input_jobs' ? true : false}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              numSelected={numSelected}
              tooltip_reload={
                clicked === 'input_jobs' ? 'Reload Jobs' : 'Reload Batches'
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarJobsActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarJobsActions.defaultProps = {
  handleViewChanged: () => '',
  handleProcessFailed: () => '',
  handleReloadTable: () => '',
  handleProcessExecuted: () => '',
  selected: [],
};
export default withStyles(ToolBarJobsActions, styleToolbar);
