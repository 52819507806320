import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  TableSurveys,
  CreateNewSurvey,
  EditSurvey,
  ShowSurvey,
} from './components';
import TabToolBar from '../TabToolBar';
class TabGeneralSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_new_survey: false,
      open_edit_survey: false,
      open_show_survey: false,
      edit_survey: null,
    };
  }
  handleNewSurvey = () => {
    this.setState({ open_new_survey: true });
  };
  handleAddNewSurvey = new_survey => {
    this.props.handleAddNewSurvey(new_survey);
    this.setState({ open_new_survey: false });
  };
  handleUpdateSurvey = (new_survey, complete) => {
    this.props.handleUpdateSurvey(new_survey);
    if (complete) this.setState({ open_edit_survey: false });
  };
  handleEditSurvey = edit_survey => {
    console.log({ edit_survey });
    this.setState({ edit_survey, open_edit_survey: true });
  };
  handleShowSurvey = show_survey => {
    console.log({ show_survey });
    this.setState({ edit_survey: show_survey, open_show_survey: true });
  };
  render() {
    const { currentPatient, surveys, height } = this.props;
    const { open_new_survey, open_edit_survey, open_show_survey } = this.state;
    if (open_show_survey) {
      return (
        <ShowSurvey
          edit_survey={this.state.edit_survey}
          height={height}
          onClickBackToOverview={() =>
            this.setState({ open_show_survey: false, edit_survey: null })
          }
          surveyid={this.state.edit_survey.id}
        />
      );
    }
    if (open_edit_survey) {
      return (
        <EditSurvey
          currentPatient={currentPatient}
          edit_survey={this.state.edit_survey}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit_survey: false })}
          handleUpdateSurvey={this.handleUpdateSurvey}
          height={height}
        />
      );
    }
    if (open_new_survey) {
      return (
        <CreateNewSurvey
          currentPatient={currentPatient}
          handleAddError={this.props.handleAddError}
          handleAddNewSurvey={this.handleAddNewSurvey}
          handleClose={() =>
            this.setState({ open_new_survey: false, edit_survey: null })
          }
          height={height}
        />
      );
    }
    const neuroSurveys = surveys.filter(x => x.surveyType === 'surveyNeuro');
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TabToolBar
            add_survey
            handleCreateSurvey={() => this.handleNewSurvey('surveyNeuro')}
            handleReload={this.props.handleReload}
            loading={this.props.loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TableSurveys
            handleAddError={this.props.handleAddError}
            handleUpdateSurvey={this.handleUpdateSurvey}
            neuroSurveys={neuroSurveys}
            onClickContinueSurvey={this.handleEditSurvey}
            onClickShowSurvey={this.handleShowSurvey}
          />
        </Grid>
      </Grid>
    );
  }
}

TabGeneralSummary.propTypes = {
  classes: PropTypes.object,
};
TabGeneralSummary.defaultProps = {
  onClickBackToOverview: () => '',
};

export default TabGeneralSummary;
