import React from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const theme_box = createTheme({
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
});

function TabPanel(props) {
  // eslint-disable-next-line no-unused-vars
  const { children, value, index, handleAddError, ...other } = props;
  const tabValue = parseInt(value, 10);
  return (
    <Typography
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      component="div"
      hidden={tabValue !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_box}>
          <Box>{children}</Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
