import React from 'react';
import PropTypes from 'prop-types';
import { DialogDeleteWarning, TableLogErrors } from 'components';
import {
  MutationDeleteLogsSiteErrors,
  QueryLogsSiteErrors,
} from 'graphql/Logs';
import { GetData, ServerErrorsString } from 'helpers';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    margin: 0,
    height: 'calc(100% - 20)',
  },
  search: {
    height: '42px',
    margin: theme.spacing(1),
  },
});

class UserLogsErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      error_logs: [],
      deleting: false,
      loading: false,
      init: false,
      page: 0,
      limit: 100,
      type: 'Errors',
      open_delete: false,
    };
    this.holder = {
      getSelected: null,
      clearSelected: null,
      getSearchText: null,
    };
  }

  componentDidMount() {
    this.updateLogs();
  }

  componentDidUpdate() {
    if (!this.state.init) {
      this.updateLogs();
    }
  }
  handleSearchUser = username => {
    this.updateLogs(username);
  };
  updateLogs = (text = null) => {
    if (!this.props.isActive) return;
    const { loading, page, limit, type } = this.state;
    if (loading) return;

    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    let input = null;
    if (text && text !== '') {
      if (type === 'Users') input = { username: text };
      else input = { error: text };
    }
    this.setState({ loading: true });
    (async () => {
      QueryLogsSiteErrors(input, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, logs, total } = data.LogsSiteErrors;
          if (ok) {
            this.setState({
              loading: false,
              error_logs: logs,
              total,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage, init: false });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value, init: false });
  };
  handleDeleteSelected = () => {
    const { deleting, error_logs, total } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    const selected = this.holder.getSelected();
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteLogsSiteErrors(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.DeleteLogsSiteErrors;
          if (ok) {
            this.holder.clearSelected();
            this.setState({
              deleting: false,
              error_logs: error_logs.filter(x => ids.indexOf(x.id) === -1),
              open_delete: false,
              total: total - ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, open_delete: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { loading, type } = this.state;
    let placeholder = 'Search Users...';
    if (type === 'Errors') placeholder = 'Search Errors...';
    return (
      <React.Fragment>
        <div className={classes.root}>
          <TableLogErrors
            deleting={this.state.deleting}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleDeleteSelected={() => this.setState({ open_delete: true })}
            handleReloadTable={() => this.updateLogs()}
            handleSearchUser={this.handleSearchUser}
            handleTypeSearchChange={(e, type) => this.setState({ type })}
            height={this.props.height - 285}
            holder={this.holder}
            limit={this.state.limit}
            loading={loading}
            options_search={['Users', 'Errors']}
            page={this.state.page}
            placeholder_type={placeholder}
            rows={this.state.error_logs}
            title="Users Errors Logs"
            total={this.state.total}
            type_selected={type}
          />
        </div>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={() => this.handleDeleteSelected()}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the selected errors, this action cannot be undone."
          title_text="Delete Selected Error"
        />
      </React.Fragment>
    );
  }
}

UserLogsErrors.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(UserLogsErrors, useStyles);
