import React from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import {
  FilesViewTableHead,
  FolderContentTableRow,
  useStyles,
} from './components';
import { headCells } from './components/utils';
import { getRow, getRowProjectObjects } from '../utils_tag_projectfiles';
import { getSorting, SelectedArray, stableSort } from 'helpers';
import { withRouter } from 'react-router-dom';

class FilesViewTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 25,
      rows: props.rows,
      downloading: null,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.clearTable = () => {
        this.setSelected([]);
      };
      holder.getSelectedTable = () => {
        let selected = [];
        const { rows } = this.props;
        for (let i = 0; i < rows.length; i++) {
          if (this.isSelected(rows[i])) {
            selected.push(rows[i]);
          }
        }
        return selected;
      };
    }
  }

  setOrder = newOrder => {
    this.setState({ order: newOrder });
  };
  setOrderBy = newOrderBy => {
    this.setState({ orderBy: newOrderBy });
  };
  setSelected = newSelected => {
    this.setState({ selected: newSelected });
    this.props.handleSelected(newSelected.length);
  };
  setPage = newPage => {
    this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setOrder(isDesc ? 'asc' : 'desc');
    this.setOrderBy(property);
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = this.props.rows.map(x => getRow(x));
      this.setSelected(newSelecteds);
    } else {
      this.setSelected([]);
    }
  };

  handleSelected = row => {
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.name}`)
      .indexOf(`${row.id}_${row.name}`);
    const newSelected = SelectedArray(selected, row, index);
    this.setSelected(newSelected);
  };

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  handleChangeDense = event => {
    this.setDense(event.target.checked);
  };
  isSelected = value => {
    const row = getRowProjectObjects(value);
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.name}`)
      .indexOf(`${row.id}_${row.name}`);

    return index !== -1 ? true : false;
  };
  isDownloading = value => {
    const { downloading } = this.state;
    if (!downloading) return false;
    const { folder, folder_content, multi_object } = value;
    if (folder) return false;
    let filecontent = null;
    if (folder_content) filecontent = folder_content;
    else if (multi_object) filecontent = multi_object;
    else return;
    if (filecontent) {
      const { Series, File } = filecontent;
      if (Series) {
        return false;
      } else if (File && downloading) {
        return downloading.idFolderContent === filecontent.idFolderContent;
      }
      return false;
    }
  };
  handleContentView = content => {
    const { folder, folder_content, multi_object } = content;
    if (folder) {
      this.props.handleOpenSubFolder(folder);
    } else if (folder_content || multi_object) {
      let filecontent = null;
      if (folder_content) filecontent = folder_content;
      else if (multi_object) filecontent = multi_object;
      else return;
      const { Series, File } = filecontent;
      if (Series) {
        const { idSeries } = Series;
        this.props.history.push(`/image_analysis/series/${idSeries}/view`);
      } else if (File) {
        const { downloading } = this.state;
        if (downloading) {
          this.setState({ downloading: null });
        } else {
          this.setState({ downloading: File });
        }
      }
    }
  };

  render() {
    const { classes, projectId, rows, loading, admin } = this.props;
    let { height } = this.props;
    let { total } = this.props;
    if (total < rows) {
      total = rows.length;
    }
    const { dense, selected, order, orderBy, rowsPerPage, page } = this.state;
    let emptyRows = parseInt(height / 53, 10) - rows.length;
    if (emptyRows < 0) {
      emptyRows = 0;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <div className={classes.tableWrapper} style={style}>
        <Table
          aria-label="sticky table"
          aria-labelledby="files-view-table"
          size={dense ? 'small' : 'medium'}
          stickyHeader>
          <FilesViewTableHead
            classes={classes}
            disable_checkbox={!admin}
            disabled={this.state.downloading ? true : false}
            headCells={headCells}
            numSelected={selected.length}
            onRequestSort={this.handleRequestSort}
            onSelectAllClick={this.handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
          />
          <TableBody>
            {loading ? (
              <TableRow style={{ height: (dense ? 33 : 53) * 4 }}>
                <TableCell colSpan={6}>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    item
                    justifyContent="center"
                    style={{ height }}
                    xs={12}>
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = this.isSelected(row);
                  const downloading = this.isDownloading(row);
                  return (
                    <FolderContentTableRow
                      dense={dense}
                      disable_checkbox={!admin}
                      disabled={this.state.downloading ? true : false}
                      downloading={downloading}
                      handleContentView={this.handleContentView}
                      handleSelected={this.handleSelected}
                      history={this.props.history}
                      isItemSelected={isItemSelected}
                      key={`row-table-project-${index}`}
                      labelId={`table-checkbox-${index}`}
                      projectId={projectId}
                      row={row}
                    />
                  );
                })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (this.state.dense ? 33 : 53) * emptyRows,
                  border: 0,
                  width: '100%',
                  margin: 0,
                  padding: 0,
                }}>
                <TableCell colSpan={headCells.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

FilesViewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired
};
export default withStyles(withRouter(FilesViewTable), useStyles);
