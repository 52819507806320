import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { MenuItem } from '@mui/material';

const useStyles = theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
});

class StyledMenuItem extends Component {
  render() {
    // let { classNames } = this.props;
    // if (classNames === undefined) {
    //   classNames = null;
    // }
    return <MenuItem {...this.props} />;
  }
}

StyledMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
};
StyledMenuItem.defaultProps = {
  // classNames: null,
};
export default withStyles(StyledMenuItem, useStyles);
