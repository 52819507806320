import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  DialogEditJobProcess,
  DialogDeleteWarning,
  ButtonGroupLoading,
} from 'components';
import { IsInvalid } from 'helpers';
const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});
class ToolbarJobsAtBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      open_edit: false,
    };
  }
  handleOpenFolder = () => {
    const { job } = this.props;
    if (IsInvalid(job)) return;
    console.log({ job });
    const { setting } = job;
    if (IsInvalid(setting)) return;
    const { folder_content } = setting;
    if (IsInvalid(folder_content)) return;
    const { Folder } = folder_content;
    if (IsInvalid(Folder)) return;
    let location = '/explorer';
    if (Folder.name !== 'Home') {
      location = `/explorer/folder/${Folder.idFolder}`;
    }
    this.props.history.push(location);
  };
  render() {
    const { classes, selected, deleting, job, editing } = this.props;
    let ComponentDelete = null;
    let ComponentEditSelected = null;
    let ComponentOpenFolder = null;
    if (selected.length) {
      ComponentDelete = (
        <ButtonGroupLoading
          badge
          icon={<DeleteIcon style={{ color: deleting ? 'gray' : 'red' }} />}
          loading={deleting}
          onClick={() => this.setState({ open_delete: true })}
          total={selected.length}
        />
      );
      ComponentEditSelected = (
        <ButtonGroupLoading
          badge
          batch_style={{ batch_color: '#00e676' }}
          color_batch="primary"
          icon={
            <BorderColorIcon style={{ color: editing ? 'gray' : 'green' }} />
          }
          loading={editing}
          onClick={() => this.setState({ open_edit: true })}
          total={selected.length}
        />
      );
    } else if (job) {
      ComponentOpenFolder = (
        <Button disabled={!job} onClick={() => this.handleOpenFolder()}>
          <OpenInNewIcon
            style={{
              color: job ? 'green' : 'gray',
            }}
          />
        </Button>
      );
    }
    let text = `${selected.length} Job${selected.length > 0 ? 's' : ''}`;
    return (
      <React.Fragment>
        <ButtonGroup
          className={classes.root}
          exclusive="false"
          size="small"
          value="center">
          <Button
            disabled={!job}
            onClick={() => this.props.handleRunJobProcess(job)}>
            <PlayArrowIcon style={{ color: job ? 'blue' : 'gray' }} />
          </Button>
          <Button
            disabled={job ? false : true}
            onClick={this.props.handleOpenJobSetting}>
            <SettingsIcon
              style={{
                color: job ? 'orange' : 'gray',
              }}
            />
          </Button>
          {ComponentOpenFolder}
          {ComponentDelete}
          {ComponentEditSelected}
        </ButtonGroup>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={() => {
            this.setState({ open_delete: false });
            this.props.handleDeleteSelectedJobs();
          }}
          open={this.state.open_delete}
          question_text={`You are about to delete the selected ${text}, this action will delete the job from the batch it's settings`}
          title_text={'Delete Selected Jobs'}
        />
        <DialogEditJobProcess
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleUpdateJobProcess={this.props.handleUpdateJobProcess}
          jobs={this.props.selected}
          open={this.state.open_edit}
        />
      </React.Fragment>
    );
  }
}

ToolbarJobsAtBatch.propTypes = {
  classes: PropTypes.object,
};
ToolbarJobsAtBatch.defaultProps = {
  editing: false,
  deleting: false,
  handleRunJobProcess: () => '',
  handleUpdateJobProcess: () => '',
  selected: [],
};
export default withStyles(withRouter(ToolbarJobsAtBatch), useStyles);
