import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const TabsSmall = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

export default TabsSmall;
