export const useStyles = theme => ({
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
});

export const GetListFolders = (folders, Folder, idFolder) => {
  if (idFolder === undefined || !idFolder) {
    return null;
  }
  if (Folder.idFolder === idFolder) {
    folders.push(Folder);
    return folders;
  }
  const { SubFolders } = Folder;
  if (typeof SubFolders === 'undefined' || !SubFolders) {
    return null;
  }
  for (let i = 0; i < SubFolders.length; i++) {
    const results = GetListFolders(folders, SubFolders[i], idFolder);
    if (results) {
      if (Folder.name === 'Home' || Folder.name === 'Shared') {
        return results;
      }
      return [Folder, ...results];
    }
  }
  return null;
};
