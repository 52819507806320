import React from 'react';
// import { green } from '@mui/material/colors';
import {
  AirlineSeatFlatAngled,
  Assessment,
  ContactMail,
  Description,
  FileCopy,
  Image,
  InsertChartOutlined,
  Person as PersonIcon,
  Schedule as ClockIcon,
  SupervisorAccount,
} from '@mui/icons-material';

const Icon = ({ type, ...props }) => {
  let icon = <PersonIcon {...props} />;
  if (type === 'clock') icon = <ClockIcon {...props} />;
  else if (type === 'other') icon = <PersonIcon {...props} />;
  else if (type === 'filecopy') icon = <FileCopy {...props} />;
  else if (type === 'description') icon = <Description {...props} />;
  else if (type === 'image') icon = <Image {...props} />;
  else if (type === 'airlineseatflatangled')
    icon = <AirlineSeatFlatAngled {...props} />;
  else if (type === 'contactmail') icon = <ContactMail {...props} />;
  else if (type === 'insertchartoutlined')
    icon = <InsertChartOutlined {...props} />;
  else if (type === 'supervisoraccount')
    icon = <SupervisorAccount {...props} />;
  else if (type === 'assessment') icon = <Assessment {...props} />;
  return icon;
};

export default Icon;
