/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Table, TableBody } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTableUserHeaders, MainTableUserRow } from './components';
import { getSorting, stableSort } from 'helpers';
import { useStyles } from './components/MainTableUserRow/Styles';
import MainTableRowLoading from '../MainTableRowLoading';
import MainTableRowEmpty from '../MainTableRowEmpty';

class MainTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'name',
      columns: [],
      row_over: -1,
    };
  }

  componentDidMount() {
    const { columns } = this.props;
    if (typeof columns !== 'undefined' || columns.length) {
      this.setState({ columns });
    }
  }

  handleRowOver = row => {
    const { row_over } = this.state;
    if (row_over !== row) {
      this.setState({ row_over: row });
    }
  };
  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc' });
    this.setState({ orderBy: property });
  };

  render() {
    const {
      classes,
      selected,
      rows,
      limit,
      loading,
      dense,
      disable_splice,
      disable_checkbox,
      nowrap,
    } = this.props;
    const { columns, order, orderBy } = this.state;
    let { page } = this.props;
    let emptyRows = 3 - rows.length;
    if (emptyRows < 0) emptyRows = 0;
    if (disable_splice) {
      page = 0;
    }
    const total_limit = page * limit + limit;
    const start_limit = page * limit;
    let colSpan = columns.length + 1;
    if (!disable_checkbox) {
      colSpan++;
    }
    return (
      <Table
        aria-label="sticky table"
        className={clsx({
          [classes.table]: nowrap,
        })}
        size={dense ? 'small' : 'medium'}
        stickyHeader>
        <MainTableUserHeaders
          classes={classes}
          disable_checkbox={disable_checkbox}
          headCells={columns}
          loading={loading}
          numSelected={selected.length}
          onRequestSort={this.handleRequestSort}
          onSelectAllClick={this.props.handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={rows.length}
        />
        <TableBody>
          {loading ? (
            <MainTableRowLoading colSpan={colSpan} dense={dense} />
          ) : (
            <React.Fragment>
              {stableSort(rows, getSorting(order, orderBy, columns))
                .slice(start_limit, total_limit)
                .map((row, index) => {
                  const isItemSelected = this.props.isSelected(row);
                  const isHighLighted = this.props.isHighLighted(row);
                  return (
                    <MainTableUserRow
                      classes={classes}
                      columns={columns}
                      dense={dense}
                      disable_checkbox={disable_checkbox}
                      handleAcceptReject={this.props.handleAcceptReject}
                      handleButtonView={this.props.handleButtonView}
                      handleClick={this.props.handleSelectedClick}
                      handleClickLink={this.props.handleClickLink}
                      handleRowOver={this.handleRowOver}
                      handleSelectedRow={this.props.handleSelectedRow}
                      index={index}
                      isHighLighted={isHighLighted}
                      isItemSelected={isItemSelected}
                      key={`table-base-row-${index}`}
                      row={row}
                    />
                  );
                })}
              <MainTableRowEmpty
                colSpan={colSpan}
                dense={dense}
                emptyRows={emptyRows}
              />
            </React.Fragment>
          )}
        </TableBody>
      </Table>
    );
  }
}

MainTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  disable_splice: PropTypes.bool.isRequired,
  handleSelectedClick: PropTypes.func,
  isSelected: PropTypes.func,
  loading: PropTypes.bool,
  selected: PropTypes.array,
};
MainTable.defaultProps = {
  nowrap: false,
  dense: false,
  loading: false,
  selected: [],
  page: 0,
  limit: 5,
  disable_splice: false,
  disable_checkbox: false,
  isSelected: () => {
    return false;
  },
  handleSelectAllClick: () => '',
  handleSelectedRow: () => '', //console.log('handleSelectedRow: ', { item }),
  handleClickLink: () => '',
  isHighLighted: () => false,
};

export default withStyles(MainTable, useStyles);
