import validate from 'validate.js';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 20,
    },
    format: {
      pattern: '[a-z0-9\\s]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9',
    },
  },
  email: {
    presence: false, // { allowEmpty: true, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
};
export const ValidateEmail = email => {
  // return ValidatePassword(email, email);
  const validation = validate({ email }, schema);
  if (validation && validation.email) {
    return validation.email.join('\n');
  }
  return null;
};

const schema_password = {
  current_password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 20,
    },
    // format: {
    //   pattern: '([A-Za-z]+[0-9]|[0-9]+[A-Za-z])+',
    //   flags: 'i',
    //   message: 'can only contain letter and numbers',
    // },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      maximum: 20,
    },
    // format: {
    //   pattern: '([A-Za-z]+[0-9]|[0-9]+[A-Za-z])+',
    //   flags: 'i',
    //   message: 'can only contain letter and numbers',
    // },
    equality: {
      attribute: 'current_password',
      message: 'has to be different from current password',
      comparator: function (v1, v2) {
        return JSON.stringify(v1) !== JSON.stringify(v2);
      },
    },
  },
  confirm: {
    // You need to confirm your password
    presence: { allowEmpty: false, message: 'is required' },
    // and it needs to be equal to the other password
    // format: {
    //   pattern: '([A-Za-z]+[0-9]|[0-9]+[A-Za-z])+',
    //   flags: 'i',
    //   message: 'can only contain letter and numbers',
    // },
    equality: {
      attribute: 'password',
      message: 'does not match',
      comparator: function (v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      },
    },
  },
};
export const ValidatePassword = (password, confirm, current_password) => {
  if (typeof current_password === 'undefined') {
    current_password = `${password}other`;
  } else {
    //
  }
  const validation = validate(
    { password, confirm, current_password },
    schema_password
  );
  if (validation) {
    //
    if (typeof validation.password !== 'undefined') {
      return validation.password.join('\n');
    } else if (typeof validation.confirm !== 'undefined') {
      return validation.confirm.join('\n');
    }
  }
  return null;
};

export const FormatValidation = (password, confirm, current_password) => {
  if (typeof current_password === 'undefined') {
    current_password = `${password}other`;
  } else {
    //
  }
  // console.log({ password, confirm, current_password });
  const validation = validate(
    { password, confirm, current_password },
    schema_password
  );
  if (typeof validation === 'undefined' || !validation) return null;
  const keys = Object.keys(validation);
  let errors = {};
  keys.forEach(key => {
    errors = {
      ...errors,
      [key]: `- ${validation[key].join('\n- ')}`,
    };
  });
  if (Object.keys(errors).length) return errors;
  return null;
};
