import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import BadgeCustom from '../BadgeCustom';
import SaveIcon from '@mui/icons-material/Save';
class BadgeIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      Icon,
      disabled,
      count,
      color,
      batch_color,
      batch_color_text,
      max,
    } = this.props;
    return (
      <Tooltip title={this.props.title}>
        <BadgeCustom
          badgeContent={count}
          batch_color={batch_color}
          batch_color_text={batch_color_text}
          max={max}>
          <Icon
            style={{
              color: !disabled ? color : null,
            }}
          />
        </BadgeCustom>
      </Tooltip>
    );
  }
}

BadgeIcon.propTypes = {
  Icon: PropTypes.object,
  color: PropTypes.string,
  count: PropTypes.number,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};
BadgeIcon.defaultProps = {
  Icon: SaveIcon,
  count: 0,
  disabled: false,
  title: '',
  color: 'green',
  batch_color: '#e65100',
  batch_color_text: 'white',
  max: 99,
};
export default BadgeIcon;
