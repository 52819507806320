import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Button,
  Link,
  Grid,
  ListItem,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import {
  MutationCreatePatientSurveyLink,
  MutationRemovePatientSurveyLink,
} from 'graphql/Surveys';
import { GetData } from 'helpers';
import { HTTP_ADDRESS_WEB } from 'config';
import DeleteIcon from '@mui/icons-material/Delete';
import { ServerErrorsString } from 'helpers';
import { StatusBullet } from 'components';

class TableSurveysRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_link: null,
      loading: false,
      deleting: false,
    };
  }
  handleRemoveLink = () => {
    const { deleting, row } = this.props;
    if (deleting || row === undefined) return;
    this.setState({ deleting: true });
    (async () => {
      MutationRemovePatientSurveyLink(row.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeSurveyPatientLink;
          if (ok) {
            this.setState({ deleting: false, row_link: null });
            row.link = null;
            this.props.handleUpdateSurvey(row);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
        });
    })();
  };
  handleCreateLink = () => {
    const { loading, row } = this.props;
    if (loading || row === undefined) return;
    this.setState({ loading: true });
    (async () => {
      MutationCreatePatientSurveyLink(row.id)
        .then(res => {
          const data = GetData(res);
          const { ok, survey, errors } = data.createSurveyPatientLink;
          if (ok) {
            this.setState({ loading: false, row_link: survey });
            row.link = survey.link;
            this.props.handleUpdateSurvey(row);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false });
        });
    })();
  };
  render() {
    const { row, onClickShowSurvey, onClickContinueSurvey } = this.props;
    // console.log({ row });
    const { loading, row_link, deleting } = this.state;
    let link = null;
    let href = null;
    if (row.link) {
      link = row.link;
      href = `${HTTP_ADDRESS_WEB}/survey/${link}`;
    } else if (row_link) {
      link = row_link.link;
      href = `${HTTP_ADDRESS_WEB}/survey/${link}`;
    }
    let input_links = {};
    if (href) {
      input_links = {
        href,
        target: '_blank',
      };
    } else {
      input_links = {
        component: 'button',
        onClick: () => {
          if (link) this.handleOpenLink();
          else this.handleCreateLink();
        },
      };
    }
    const { completed } = row;
    let ComponentButtonShow = null;
    if (!completed) {
      ComponentButtonShow = (
        <Grid item>
          <Button
            onClick={() => onClickContinueSurvey(row)}
            size="small"
            variant="outlined">
            Show/Edit
          </Button>
        </Grid>
      );
    }
    return (
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>
          {moment(parseInt(row.createdAt)).format('MMMM Do YYYY')}
        </TableCell>
        <TableCell>
          {moment(parseInt(row.updatedAt)).format('MMMM Do YYYY, h:mm:ss a')}
        </TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StatusBullet color={completed ? 'success' : 'danger'} size="sm" />
            <div style={{ marginLeft: 2 }}>
              {completed ? 'complete' : 'open'}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            sx={{ width: completed ? 50 : 180 }}>
            <Grid item sx={{ paddingRight: 1 }}>
              <Button
                onClick={() => onClickShowSurvey(row)}
                size="small"
                sx={{ maringRight: 2 }}
                variant="outlined">
                Export
              </Button>
            </Grid>
            {ComponentButtonShow}
          </Grid>
        </TableCell>
        <TableCell>
          {loading ? (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="center"
              spacing={1}>
              <Grid item>
                <CircularProgress disableShrink size={20} />
              </Grid>
              <Grid item>
                <div style={{ fontColor: 'green', fontWeight: 800 }}>
                  Generating please wait
                </div>
              </Grid>
            </Grid>
          ) : (
            <ListItem
              secondaryAction={
                link ? (
                  <IconButton
                    aria-label="delete"
                    disabled={deleting}
                    edge="end"
                    onClick={this.handleRemoveLink}>
                    <DeleteIcon sx={{ color: deleting ? 'gray' : 'red' }} />
                  </IconButton>
                ) : (
                  undefined
                )
              }>
              {deleting ? (
                'Deleting....'
              ) : (
                <Link {...input_links} variant="body2">
                  {link ? link : 'Click to Generate Patient Link'}
                </Link>
              )}
            </ListItem>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

TableSurveysRow.propTypes = {
  handleUpdateSurvey: PropTypes.func,
};
TableSurveysRow.defaultProps = {
  handleUpdateSurvey: () => '',
};

export default TableSurveysRow;
