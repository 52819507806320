import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Divider } from '@mui/material';
import { InputDefinition, OutputDefinition } from './components';

class TablesDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_definition: null,
      output_definition: null,
      set: false,
    };
  }
  componentDidMount() {
    const { tables_definition } = this.props;
    this.setTableDefinition(tables_definition);
  }
  componentDidUpdate() {
    const { tables_definition } = this.props;
    this.setTableDefinition(tables_definition);
  }
  setTableDefinition = tables_definition => {
    if (this.state.set) return;
    if (tables_definition === null || tables_definition === undefined) return;
    const state = {
      input_definition: tables_definition.input_definition,
      output_definition: tables_definition.output_definition,
      set: true,
    };
    this.setState(state);
  };
  handleUpdateInputDefinition = input_definition => {
    const { output_definition } = this.state;
    this.setState({ input_definition });
    this.props.handleUpdateTablesDefinition({
      input_definition,
      output_definition,
    });
  };
  handleUpdateOutputDefinition = output_definition => {
    const { input_definition } = this.state;
    this.setState({ output_definition });
    this.props.handleUpdateTablesDefinition({
      output_definition,
      input_definition,
    });
  };
  render() {
    const { tables_definition } = this.props;
    return (
      <Box sx={{ width: '100%' }}>
        <h3>Tables Definition</h3>
        <Grid container item spacing={2} xs={12}>
          <InputDefinition
            handleUpdateInputDefinition={input_definition =>
              this.handleUpdateInputDefinition(input_definition)
            }
            input_definition={
              tables_definition !== null
                ? tables_definition.input_definition
                : null
            }
            setting_id={this.props.setting_id}
            table_list={this.props.table_list}
          />
          <Grid item xs={12}>
            <Divider
              flexItem
              orientation="horizontal"
              style={{ width: '100%' }}
            />
          </Grid>
          <OutputDefinition
            handleUpdateOutputDefinition={output_definition =>
              this.handleUpdateOutputDefinition(output_definition)
            }
            output_definition={
              tables_definition !== null
                ? tables_definition.output_definition
                : null
            }
            table_list={this.props.table_list}
          />
        </Grid>
      </Box>
    );
  }
}

TablesDefinition.propTypes = {
  handleClose: PropTypes.func,
  handleUpdateTablesDefinition: PropTypes.func,
  table_list: PropTypes.array,
  tables_definition: PropTypes.object,
};
TablesDefinition.defaultProps = {
  tables_definition: null,
  table_list: null,
  handleClose: () => '',
  handleUpdateTablesDefinition: () => '',
};

export default TablesDefinition;
