export const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};
export const useStyles = theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    background: 'white',
    '& h2': {
      color: 'black',
      fontSize: '20px',
      fontWeight: 1000,
    },
  },
});
