import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { pink } from '@mui/material/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { StudySeriesQuery } from 'graphql/Study';
import { CircularLoading, SnackMessage } from 'components';
import { GetData, ServerErrors } from 'helpers';
import { ViewStudyList, ViewStudyGrid } from './components';
import { IsInvalid } from 'helpers';
const useStyles = theme => ({
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
});

class ViewStudy extends Component {
  constructor(props) {
    super(props);
    const { Study, idStudy } = props;
    let loading = false;
    if (!Study && idStudy) {
      loading = true;
    }
    this.state = {
      loading,
      Study: null,
      error: '',
      empty: false,
    };
    //
    if (loading) {
      this.runStudy(idStudy);
    }
    const { holder } = props;
    if (typeof holder === 'undefined') {
      holder.getStudy = () => this.state.Study;
    }
  }

  componentDidMount() {
    //
    this.updateStudy();
  }

  componentDidUpdate() {
    //
    this.updateStudy();
  }

  updateStudy = () => {
    const { loading, error, empty } = this.state;
    if (loading || error.length || empty) return;
    const { idStudy, Study } = this.props;
    console.log({ idStudy, Study });
    if (Study && Study.Series) return;
    if (this.state.Study && this.state.Study.Series) return;
    this.setState({ loading: true, Study: null });
    this.runStudy(idStudy);
  };
  runStudy = idStudy => {
    (async () => {
      StudySeriesQuery(idStudy)
        .then(res => {
          const data = GetData(res);
          const { ok, total, studies, errors } = data.Studies;
          console.log({ total, studies });
          if (ok) {
            const Study = total ? studies[0] : null;
            this.setState({
              loading: false,
              Study,
              empty: studies.length ? false : true,
            });
            this.props.handleSetStudy(Study);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({
            loading: false,
            error: `- ${ServerErrors(errors).join('\n- ')}`,
          });
        });
    })();
  };
  handleSeriesDeleted = series => {
    console.log({
      series,
      SLocal: this.state.Study,
      SOutside: this.props.Study,
    });
    const { idSeries } = series;
    if (idSeries === undefined) {
      return;
    }
    let { Study } = this.props;
    if (!Study || Study === undefined) {
      Study = this.state.Study;
      if (!Study || Study === undefined) return;
    }
    const index = Study.Series.map(x => x.idSeries).indexOf(idSeries);
    if (index !== -1) {
      Study.Series.splice(index, 1);
      this.props.handleSeriesDeleted(Study);
      if (this.state.Study) {
        this.setState({ Study });
      }
    }
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };

  render() {
    const { loading, error } = this.state;
    let { classes, Study, idStudy, display_database, height } = this.props;
    if (loading) {
      return <CircularLoading />;
    }
    if (!Study && this.state.Study) {
      Study = this.state.Study;
    }
    if (IsInvalid(Study)) {
      const ErrorMessage = (
        <SnackMessage
          handleClose={this.handleCloseSnak}
          message_text={IsInvalid(error, true) ? 'Unknown error' : error}
          open={IsInvalid(error, true) ? true : false}
          type="error"
        />
      );
      return (
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          justifyContent="center"
          xs={12}>
          <Avatar className={classes.pink}>
            <ErrorOutlineIcon />
          </Avatar>
          {ErrorMessage}
        </Grid>
      );
    }
    if (display_database === 'list') {
      return (
        <ViewStudyList
          handleAddError={this.props.handleAddError}
          height={height}
          Study={Study}
          user={this.props.user}
        />
      );
    } else {
      return (
        <ViewStudyGrid
          error={error}
          handleCloseSnak={this.handleCloseSnak}
          handleSeriesDeleted={this.handleSeriesDeleted}
          idStudy={idStudy}
          Study={Study}
          user={this.props.user}
        />
      );
    }
  }
}

ViewStudy.propTypes = {
  classes: PropTypes.object,
};
ViewStudy.defaultProps = {};

export default withStyles(withRouter(ViewStudy), useStyles);
