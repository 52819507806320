import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';

class OutputDicom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tag_name_json: null,
      filename: null,
      loading: false,
      set: false,
    };
  }
  componentDidMount() {
    const { configuration } = this.props;
    this.setOutputDicom(configuration);
  }
  componentDidUpdate() {
    const { configuration } = this.props;
    this.setOutputDicom(configuration);
  }
  setOutputDicom = configuration => {
    if (this.state.set) return;
    if (configuration === null || configuration === undefined) return;
    const { filename } = configuration;
    const state = {
      // tag_name_json,
      filename,
      set: true,
    };
    this.setState(state);
  };
  // handleUpdateTagName = event => {
  //   let { tag_name_json, filename } = this.state;
  //   const value = event.target.value;
  //   if (tag_name_json === null || tag_name_json === undefined) {
  //     tag_name_json = { type: 'DICOM', name: 'tag_name_json', value };
  //   } else {
  //     tag_name_json['value'] = value;
  //   }
  //   this.setState({ tag_name_json });
  //   this.props.handleOutputConfiguration({
  //     tag_name_json,
  //     filename,
  //   });
  // };
  handleUpdateFilename = event => {
    let { filename /*tag_name_json*/ } = this.state;
    const value = event.target.value.toLowerCase();
    if (filename === null || filename === undefined) {
      filename = { type: 'DICOM', name: 'filename', value };
    } else {
      filename['value'] = value;
    }
    this.setState({ filename });
    this.props.handleOutputConfiguration({
      filename,
      // tag_name_json,
    });
  };
  render() {
    const { filename /*tag_name_json*/ } = this.state;
    // let tag_name_json_text = '';
    // if (tag_name_json) {
    //   tag_name_json_text = tag_name_json.value;
    // }
    let filename_text = '';
    if (filename) {
      filename_text = filename.value;
    }
    return (
      <Grid alignItems="center" container item spacing={2} xs={12}>
        <Grid item style={{ width: '100%' }} xs={6}>
          <TextField
            fullWidth
            id="output-filename-dicom"
            InputLabelProps={{
              shrink: true,
            }}
            label="Output DICOM filename"
            margin="dense"
            name={filename_text}
            onChange={event => this.handleUpdateFilename(event)}
            placeholder="Type filename of your DICOM file (e.g. results.dcm)..."
            required
            value={filename_text}
            variant="outlined"
          />
        </Grid>
        {/* <Grid item style={{ width: '100%' }} xs={6}>
          <TextField
            fullWidth
            id="output-tag-name-dicom"
            InputLabelProps={{
              shrink: true,
            }}
            label="Output Reference DICOM tag name"
            margin="dense"
            name={tag_name_json_text}
            onChange={event => this.handleUpdateTagName(event)}
            placeholder="Type reference tag name of your DICOM file..."
            required
            value={tag_name_json_text}
            variant="outlined"
          />
        </Grid> */}
      </Grid>
    );
  }
}

OutputDicom.propTypes = {
  configuration: PropTypes.object,
  handleOutputConfiguration: PropTypes.func,
  table_list: PropTypes.array,
};
OutputDicom.defaultProps = {
  configuration: null,
  table_list: null,
  handleOutputConfiguration: () => '',
};

export default OutputDicom;
