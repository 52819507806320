import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { SnackMessage } from 'components';
import { MutationSettings, QuerySettings } from 'graphql/Communications';
import { GetData, ServerErrorsString } from 'helpers';
import CardSetting from '../CardSetting';
const useStyles = makeStyles()(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const default_notifications = {
  emails: false,
  messages: false,
  uploads: false,
  chat: false,
  requests: false,
  shared: false,
};
const ManageNotifications = () => {
  const { classes } = useStyles();
  const [errors, setErrors] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [original, setOrginal] = React.useState({ ...default_notifications });
  const [notifications, setNotifications] = React.useState(null);

  React.useEffect(() => {
    handleLoad();
  }, []);
  const handleLoad = () => {
    if (loading) return;
    setLoading(true);
    (async () => {
      QuerySettings()
        .then(res => {
          const data = GetData(res);
          const { UserSettings } = data;
          if (UserSettings) {
            const { ok, setting } = UserSettings;
            if (ok) {
              setNotifications(setting);
              setLoading(false);
              setOrginal({ ...setting });
            } else {
              throw UserSettings.errors;
            }
          }
        })
        .catch(error => {
          setErrors(ServerErrorsString(error));
          setLoading(false);
        });
    })();
  };
  const handleCheckChange = event => {
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    setNotifications({ ...notifications, [name]: checked });
  };
  const handleUpdateNotifications = () => {
    if (loading) return;
    setLoading(true);
    (async () => {
      MutationSettings(notifications)
        .then(res => {
          const data = GetData(res);
          const { updateSettings } = data;
          if (updateSettings) {
            const { ok, setting } = updateSettings;
            if (ok) {
              setNotifications(setting);
              setLoading(false);
            } else {
              throw updateSettings.errors;
            }
          }
        })
        .catch(error => {
          setErrors(ServerErrorsString(error));
          setLoading(false);
        });
    })();
  };
  const handleCloseError = () => {
    setErrors('');
  };
  let emails = false,
    messages = false,
    uploads = false,
    requests = false,
    shared = false,
    chat = false;
  if (notifications) {
    emails = notifications.emails;
    messages = notifications.messages;
    uploads = notifications.uploads;
    chat = notifications.chat;
    requests = notifications.requests;
    shared = notifications.shared;
  }
  const IsChanged = () => {
    if (emails !== original.emails) return true;
    if (messages !== original.messages) return true;
    if (uploads !== original.uploads) return true;
    if (requests !== original.requests) return true;
    if (shared !== original.shared) return true;
    if (chat !== original.chat) return true;
    return false;
  };
  return (
    <React.Fragment>
      <CardSetting
        disabled={!IsChanged()}
        handleRealod={handleLoad}
        handleSave={handleUpdateNotifications}
        loading={loading}
        subheader="Manage the notifications"
        title="Notifications">
        <Grid container spacing={6} wrap="wrap">
          <Grid className={classes.item} item md={4} sm={6} xs={12}>
            <Typography gutterBottom variant="h6">
              Notifications
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emails}
                  disabled={loading}
                  name="emails"
                  onChange={handleCheckChange}
                />
              }
              label="Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={uploads}
                  disabled={loading}
                  name="uploads"
                  onChange={handleCheckChange}
                />
              }
              label="Upload Notifications"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shared}
                  disabled={loading}
                  name="shared"
                  onChange={handleCheckChange}
                />
              }
              label="Shared Objects"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={messages}
                  disabled={loading}
                  name="messages"
                  onChange={handleCheckChange}
                />
              }
              label="Private Messages"
            />
          </Grid>
          <Grid className={classes.item} item md={4} sm={6} xs={12}>
            <Typography gutterBottom variant="h6">
              Messages
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={requests}
                  disabled={loading}
                  name="requests"
                  onChange={handleCheckChange}
                />
              }
              label="Join Request"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={chat}
                  disabled={loading}
                  name="chat"
                  onChange={handleCheckChange}
                />
              }
              label="Team Chats"
            />
          </Grid>
        </Grid>
      </CardSetting>

      <SnackMessage
        handleClose={handleCloseError}
        message_text={errors !== '' ? errors : 'Unknown warning'}
        open={errors && errors !== '' ? true : false}
        type="error"
      />
    </React.Fragment>
  );
};

ManageNotifications.propTypes = {
  className: PropTypes.string,
};

export default ManageNotifications;
