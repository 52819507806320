import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Delete as DeleteIcon,
  Replay as ReloadIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { IconButtonLoad, CircularLoading } from 'components';
import { Grid } from '@mui/material';
class ButtonReloadDelete extends Component {
  render() {
    const {
      numSelected,
      handleDeleteSelected,
      handleReloadTable,
      handleClearSelected,
      loading_table,
      show_delete,
      hide_reload,
      hide_clean,
    } = this.props;
    if (!hide_reload && loading_table) {
      return <CircularLoading simple small />;
    }
    if (numSelected > 0) {
      let style = { padding: 0, margin: 0 };
      if (show_delete) {
        style.width = 110;
      }
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          style={style}>
          {show_delete && (
            <IconButtonLoad
              // disabled={!changed}
              bottom_top
              color="red"
              handleClick={handleDeleteSelected}
              icon={this.props.icon_delete}
              left_right
              length={numSelected}
              loading={this.props.deleting}
              small={this.props.small}
              tooltip={this.props.tooltip}
            />
          )}
          {!hide_clean && (
            <IconButtonLoad
              handleClick={handleClearSelected}
              icon={<CancelIcon />}
              small={this.props.small}
              tooltip={this.props.tooltip_clear}
            />
          )}
        </Grid>
      );
    } else if (!hide_reload) {
      return (
        <IconButtonLoad
          // disabled={!changed}
          handleClick={handleReloadTable}
          icon={<ReloadIcon />}
          loading={this.props.loading}
          small={this.props.small}
          tooltip={this.props.tooltip_reload}
        />
      );
    } else {
      return null;
    }
  }
}

ButtonReloadDelete.propTypes = {
  classes: PropTypes.object,
  handleClearSelected: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  hide_reload: PropTypes.bool,
  loading: PropTypes.bool,
  loading_table: PropTypes.bool,
  show_delete: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltip_clear: PropTypes.string,
};

ButtonReloadDelete.defaultProps = {
  tooltip: '',
  tooltip_clear: 'Clean selected',
  loading: false,
  deleting: false,
  loading_table: false,
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  icon_delete: <DeleteIcon sx={{ color: 'red' }} />,
  show_delete: true,
  small: false,
  hide_reload: false,
  hide_clean: false,
  tooltip_reload: 'Reload Table',
};

export default ButtonReloadDelete;
