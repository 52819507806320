import {
  LOGOUT_USER_PAGE_TEAMS,
  PAGE_TEAMS_LOAD_TEAMS,
  PAGE_TEAMS_SET_CURRENT_TEAM,
  PAGE_TEAMS_UPDATE_TEAM,
  PAGE_TEAMS_UPDATE_TEAM_AVATAR,
  PAGE_TEAMS_UPDATE_TEAM_ERRORS,
  PAGE_TEAMS_UPDATE_TEAM_MEMBERS,
  PAGE_TEAMS_UPDATE_TEAMS,
} from '../actions/types';

const initialState = {
  teams: [],
  team: {},
  errors: {},
  update: true,
};

export default function reducer_page_teams(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER_PAGE_TEAMS:
      return initialState;
    case PAGE_TEAMS_LOAD_TEAMS:
      return {
        ...state,
        update: action.update,
      };
    case PAGE_TEAMS_UPDATE_TEAMS:
      if (state.teams.length === 0 && action.teams === 0) {
        return state;
      }
      return {
        ...state,
        teams: action.teams,
      };
    case PAGE_TEAMS_SET_CURRENT_TEAM:
    case PAGE_TEAMS_UPDATE_TEAM:
      return {
        ...state,
        team: action.team,
      };
    case PAGE_TEAMS_UPDATE_TEAM_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case PAGE_TEAMS_UPDATE_TEAM_MEMBERS:
      return {
        ...state,
        team: {
          ...state.team,
          members: action.members,
        },
      };
    case PAGE_TEAMS_UPDATE_TEAM_AVATAR:
      return {
        ...state,
        team: {
          ...state.team,
          avatar: action.avatar,
        },
      };
    default:
      return state;
  }
}
