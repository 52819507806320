import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from '@mui/material';
import { v1 as uuid } from 'uuid';

import { GroupBoxEditSelectedRow } from './components';
import { MainTable } from 'components/Tables/components';
import { useStyles } from './components/utils';
import { columns } from '../TableUnsortedSequences/utils';
import { ServerErrorsString, GetData, rowsPerPageSmall } from 'helpers';
import { SaveMultiDetails } from 'graphql/Series';
import { CardEditHeader } from 'components';

class EditUnsortedSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      row_selected: null,
      // {
      //   idImageMr: -1,
      //   idSeries: -1,
      //   user: null,
      //   series: null,
      //   name: '',
      // },
    };
  }

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };

  handleSelectedRow = row_selected => {
    const here = this.isSelected(row_selected);
    if (here) row_selected = null;
    this.setState({ row_selected });
  };
  handleUpdateUnsortedSequences = mappings => {
    const idSelec = this.getFileID(this.state.row_selected);
    const index = mappings.map(x => this.getFileID(x)).indexOf(idSelec);
    if (index !== -1) {
      this.setState({ row_selected: mappings[index] });
    }
    this.props.handleUpdateUnsortedSequences(mappings);
  };
  handleSave = (ids, idSequence) => {
    if (ids.length === 0 || idSequence === undefined) {
      this.props.handleAddError('Invalid or missing Series');
      return;
    }

    const { saving } = this.state;
    if (saving) return;
    const sm = {
      idSeries: 0,
      idSequence,
    };

    this.setState({ saving: true });
    (async () => {
      SaveMultiDetails(ids, sm)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SaveMultiDetails;
          if (ok) {
            this.setState({ saving: false });
            this.props.handleUpdateUnsortedSequences(ids);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  isSelected = row => {
    const { row_selected } = this.state;
    if (row_selected === undefined || !row_selected) return false;
    return (
      `${row.idImageMr}-${row.idSeries}` ===
      `${row_selected.idImageMr}-${row_selected.idSeries}`
    );
  };
  render() {
    const { classes, selected } = this.props;
    const { row_selected } = this.state;
    // let createdAt = '';
    // if (row_selected) {
    //   createdAt = row_selected.createdAt;
    // }
    console.log('EditUnsortedSequences', { row_selected });
    return (
      <Card className={classes.card}>
        <CardEditHeader
          handleClose={this.props.handleOpenEditView}
          // header={'Created: ' + FormatTime(createdAt, 'MMM Do, YYYY')}
          title="Edit Selected Unsorted Sequences"
        />
        <Divider />
        <CardContent>
          <GroupBoxEditSelectedRow
            handleAddError={this.props.handleAddError}
            handleSave={this.handleSave}
            id={uuid()}
            selected={selected}
            show_mapping={false}
            single={false}
            title_box="Modify All Selected Unsorted Sequences"
          />
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <MainTable
              columns={columns}
              dense
              disable_checkbox
              handleSelectedRow={this.handleSelectedRow}
              isSelected={this.isSelected}
              limit={this.state.limit}
              page={this.state.page}
              rows={selected}
            />
            <TablePagination
              component="div"
              count={selected.length}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBoxEditSelectedRow
            handleAddError={this.props.handleAddError}
            handleSave={this.handleSave}
            id={uuid()}
            row_selected={row_selected}
            title_box="Selected Unsorted Sequence Row"
          />
        </CardContent>
        {/* <CardActions disableSpacing>
          <EditUnsortedSequencesBottom classes={classes} />
        </CardActions> */}
      </Card>
    );
  }
}

EditUnsortedSequences.propTypes = {
  classes: PropTypes.object,
  handleOpenEditView: PropTypes.func,
};
EditUnsortedSequences.defaultProps = {
  handleOpenEditView: () => '',
  handleUpdateSequenceMappings: () => '',
  handleAddError: () => '',
};
export default withStyles(EditUnsortedSequences, useStyles);
