import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from 'tss-react/mui';

import {
  Checkbox,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import { FindKey, getUserFullName, IsUserObject } from 'helpers';
import { useStyles } from './Styles';
import { CellElement } from 'components/Tables/components/MainTable/components/MainTableUserRow/components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MainTableRowLogErrorExand from '../MainTableRowLogErrorExand';
import { v1 as uuid } from 'uuid';
class MainTableRowLogError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      expand: false,
    };
  }

  handleClickMouse = e => {
    // eslint-disable-next-line react/no-find-dom-node
    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      this.props.handleRowOver(this.state.row);
    }
  };

  componentDidUpdate() {
    const { row } = this.state;
    if (row !== this.props.row) {
      this.setState({ row: this.props.row });
    }
  }

  componentDidMount() {
    document.addEventListener('mouseover', this.handleClickMouse, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseover', this.handleClickMouse, false);
  }

  handleExpand = () => {
    this.setState(x => ({ expand: !x.expand }));
  };

  render() {
    const {
      classes,
      row,
      handleSelectedRow,
      isItemSelected,
      handleClick,
      columns,
      index,
      dense,
      handleAcceptReject,
      expand_disable,
    } = this.props;
    const labelId = `enhanced-table-checkbox-${index}`;
    const { expand } = this.state;
    //onClick={handleDrawerClose}
    let ExpandComponent = null;
    let ExpandRowTable = null;
    if (!expand_disable) {
      ExpandComponent = (
        <TableCell padding="checkbox">
          <IconButton onClick={this.handleExpand} size="large">
            {expand ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </TableCell>
      );
      ExpandRowTable = (
        <TableRow key={`table-row-expand-${row.id}`}>
          <TableCell colSpan={10} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <MainTableRowLogErrorExand row={row} />
            </Collapse>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <React.Fragment>
        <TableRow
          hover
          key={`table-row-${row.id}`}
          onClick={() => handleSelectedRow(row)}
          role="checkbox"
          selected={isItemSelected}
          style={{
            paddingTop: dense ? 0 : '3px',
          }}
          tabIndex={-1}>
          {ExpandComponent}
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={event => handleClick(event, index, row)}
            />
          </TableCell>
          {columns.map((column, col_index) => {
            const index_column = col_index + index;
            //
            let value = '';
            if (column.id === 'name' && IsUserObject(row)) {
              value = getUserFullName(row);
            } else if (FindKey(row, column.id)) {
              value = row[column.id];
            }
            if (column.type === 'WithIndex') {
              value = {
                value,
                admin: row.admin,
                i: index_column,
                handleAcceptReject: handleAcceptReject,
                row,
              };
            }
            return (
              <TableCell
                align={column.align}
                key={`cell-key-${uuid()}-${column.id}`}
                style={{
                  paddingTop: dense ? 0 : '4px',
                  paddingBottom: dense ? 0 : '4px',
                }}>
                {/* <ContextMenuTrigger id={ID}> */}
                {CellElement(column, row, value, this.props, dense, classes)}
                {/* </ContextMenuTrigger> */}
              </TableCell>
            );
          })}
        </TableRow>
        {ExpandRowTable}
      </React.Fragment>
    );
  }
}

MainTableRowLogError.propTypes = {
  classes: PropTypes.object,
};
MainTableRowLogError.defaultProps = {
  dense: false,
  expand: false,
  handleRowOver: () => '',
  handleSelectedRow: () => '',
};
export default withStyles(MainTableRowLogError, useStyles);
