import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

// import isString from 'lodash/isString';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const loadProjectQuery = gql`
  query($projectId: Int!) {
    loadProject(projectId: $projectId) {
      id
      name
      type
      user {
        id
        firstName
        lastName
      }
      description
      avatar
      status
      createdAt
      updatedAt
    }
  }
`;

const projectCreateNewTeamMutation = gql`
  mutation($projectId: Int!, $name: String) {
    projectCreateNewTeam(projectId: $projectId, name: $name) {
      ok
      team {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`;

const projectAddNewTeamMutation = gql`
  mutation($projectId: Int!, $name: String) {
    projectAddNewTeam(projectId: $projectId, name: $name) {
      ok
      team {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`;

const QUERY_PROJECT_TEAMS = gql`
  query($projectId: Int!, $name: String) {
    teamsAtProject(projectId: $projectId, name: $name) {
      id
      name
      admin {
        id
        firstName
        lastName
        FullName
      }
      members {
        id
        firstName
        lastName
        FullName
        email
        avatar
        createdAt
        phone
        address {
          city
          country
        }
      }
      projects {
        id
        name
      }
      avatar
      createdAt
    }
  }
`;
const updateNotificationsMutation = gql`
  mutation(
    $projectId: Int!
    $email: Boolean!
    $upload: Boolean!
    $user_chat: Boolean!
    $team_chat: Boolean!
  ) {
    updateNotifications(
      projectId: $projectId
      email: $email
      upload: $upload
      user_chat: $user_chat
      team_chat: $team_chat
    ) {
      ok
      notifications {
        id
        projectId
        email
        upload
        user_chat
        team_chat
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

const projectNotificationsQuery = gql`
  query($id: LongLong!) {
    notifications(projectId: $id) {
      id
      projectId
      email
      upload
      user_chat
      team_chat
      createdAt
      updatedAt
    }
  }
`;

const updateProjectDetailsMutation = gql`
  mutation(
    $id: LongLong!
    $type: Int!
    $name: String!
    $description: String!
    $avatar: String!
  ) {
    updateProjectDetails(
      id: $id
      type: $type
      name: $name
      description: $description
      avatar: $avatar
    ) {
      ok
      project {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`;

const projectsAtNameQuery = gql`
  query($name: String!, $all: Boolean) {
    projectsAtName(name: $name, all: $all) {
      id
      name
      type
      admin {
        id
        firstName
        lastName
        FullName
      }
      description
      avatar
      status
      createdAt
      updatedAt
    }
  }
`;
const createFullProjectMutation = gql`
  mutation($config: InputBasicProject!, $settings: InputSettingProject) {
    createFullProject(config: $config, settings: $settings) {
      ok
      project {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`;
const createProjectMutation = gql`
  mutation($name: String!) {
    createProject(name: $name) {
      ok
      project {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;
export const projectCreateNewTeam = (projectId, name) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(projectCreateNewTeamMutation),
    variables: { projectId, name },
  });
};
export const projectAddNewTeam = (projectId, name) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(projectAddNewTeamMutation),
    variables: { projectId, name },
  });
};

export const teamsAtProject = (projectId, name) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_TEAMS),
    variables: { projectId, name },
  });
};

export const updateNotifications = notifications => {
  const { projectId, email, upload, user_chat, team_chat } = notifications;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(updateNotificationsMutation),
    variables: { projectId, email, upload, user_chat, team_chat },
  });
};

export const projectNotifications = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(projectNotificationsQuery),
    variables: {
      id,
    },
  });
};

export const updateProjectDetails = new_details => {
  const { id, type, name, description, avatar } = new_details;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(updateProjectDetailsMutation),
    variables: {
      id,
      type,
      name,
      description,
      avatar,
    },
  });
};

export const projectsAtName = (name, all) => {
  if (typeof all === 'undefined') {
    all = true;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(projectsAtNameQuery),
    variables: {
      name: name,
      all: all,
    },
  });
};

export const createProject = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(createProjectMutation),
    variables: {
      name: name,
    },
  });
};
export const createFullProject = ({ config, settings }) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(createFullProjectMutation),
    variables: {
      config,
      settings,
    },
  });
};

export const projectCreateSteps = (value, step) => {
  switch (step) {
    case 0:
    case 1:
    case 2:
    case 3:
      return projectsAtName(value, false);
    case 4:
      return createFullProject(value);
    // return createProject(value);
    default:
      return 'Unknown step';
  }
};
export const getStepPayload = (step, config) => {
  const {
    project_type: { name },
  } = config;
  switch (step) {
    case 0:
    case 1:
    case 2:
    case 3:
      return name;
    case 4:
      return getCreateProjectPayload(config);
    default:
      return 'Unknown step';
  }
};
const getCreateProjectPayload = config => {
  const {
    project_type,
    project_settings: {
      notifications, //: { emails, messages, uploads, chat },
      centers,
    },
    teams,
    users,
  } = config;
  const settings = {
    notifications, // { id: -1, emails, messages, uploads, chat }
    teams: teams.map(x => {
      const reply = { idTeam: x.id };
      const { idCenterTeam, center_team } = x;
      if (center_team && center_team.idCenter) {
        reply.idCenter = center_team.idCenter;
      }
      if (idCenterTeam) reply.idCenterTeam = idCenterTeam;
      return reply;
    }),
    members: users,
    centers: centers.map(x => ({ idCenter: x.id, code: x.code })),
  };
  return { config: project_type, settings };
};
const stepType = data => {
  const { projectsAtName } = data;

  const ok = projectsAtName.length === 0;
  let projectname = '';
  if (projectsAtName.length) {
    const { name } = projectsAtName[0];
    projectname = name;
  }
  return { ok, errors: ok ? [] : [`Project name (${projectname}) is used`] };
};
const stepCreateProject = data => {
  const { createFullProject } = data;

  const { ok, errors } = createFullProject;
  // let finalErrors = [];
  if (ok) {
    return { ok };
  } else {
    return { ok, errors: errors.map(x => x.message).filter(x => x) };
  }
};

export const getQueryResults = (activeStep, data) => {
  switch (activeStep) {
    case 0:
    case 1:
    case 2:
    case 3:
      return stepType(data);
    case 4:
      return stepCreateProject(data);
    default:
      return {
        ok: false,
        errors: ['Unknown error'],
      };
  }
};

export const loadProject = projectId => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(loadProjectQuery),
    variables: {
      projectId,
    },
  });
};

export const GetProjectTypes = props => {
  const { user } = props;
  if (user && user.idCategory === 2) {
    return ['Medical Trial', 'Sharing Images', 'Neurosurgery'];
  } else {
    return ['Medical Trial', 'Sharing Images'];
  }
};
