import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Divider,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ButtonLoading, AutoComboBox } from 'components';
import { MutationUpdateJobs } from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
class DialogJobProcessSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      setting: null,
      status: null,
    };
  }
  handleChange = event => {
    const setting = {
      ...this.state.setting,
      [event.target.name]: event.target.checked,
    };
    this.setState({ setting });
  };
  IsChanged = () => {
    const { job } = this.props;
    if (!job || job === undefined) return false;
    const { setting, status } = this.state;
    if (status !== undefined) {
      if (job.status !== status) return true;
    }
    if (!setting) return false;
    const { own_folder, on_content } = setting;
    if (job.setting) {
      if (on_content !== undefined) {
        if (job.setting.on_content !== on_content) return true;
      }
      if (own_folder !== undefined) {
        if (job.setting.own_folder !== own_folder) return true;
      }
    }
    return false;
  };
  handleSaveJobSetting = () => {
    const { job } = this.props;
    const { updating, status } = this.state;
    if (updating) return;
    this.setState({ updating: true });
    if (!status || status === undefined) {
      this.setState({ status: job.status });
    }
    const inputs = [
      {
        idJob: job.id,
        status: this.state.status,
        job_setting: {
          ...this.state.setting,
        },
      },
    ];
    (async () => {
      MutationUpdateJobs(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateJobProcesses;
          if (ok) {
            let new_job = {
              ...job,
            };
            if (this.state.status !== undefined) {
              new_job = {
                ...new_job,
                status: this.state.status,
                batch: {
                  ...new_job.batch,
                  status: this.state.status,
                },
              };
            }
            if (this.state.setting) {
              new_job.setting = {
                ...job.setting,
                ...this.state.setting,
              };
            }
            this.props.handleUpdateJobProcess(new_job);
            this.setState({
              updating: false,
              setting: null,
              status: undefined,
            });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false, setting: null, status: undefined });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { open, job } = this.props;
    if (IsInvalid(job)) {
      // this.props.handleAddError('You need to select a job first');
      return null;
    }
    // console.log({ job });
    let own_folder = false;
    let on_content = false;
    let job_name = null;
    let file_name = null;
    let idfile = null;
    let file_type = null;
    let folderpath = '';
    let job_status = null;
    let job_project = null;
    let default_status = null;
    let folder_content_name = null;
    let idFolderAtProject = null;
    let idFolderAtContent = null;
    let label_project_folder = 'Project Folder';
    if (job.folder) {
      folder_content_name = job.folder.name;
      idFolderAtContent = job.folder.idFolder;
    }
    if (job.setting) {
      const { folder_content } = job.setting;
      if (folder_content) {
        const { Folder } = folder_content;
        if (folder_content.project) job_project = folder_content.project.name;
        if (Folder) {
          folder_content_name = Folder.name;
          folderpath = Folder.folders_paths.map(x => x.name).join('/');
          // idFolderAtProject =
          //   Folder.folders_paths[Folder.folders_paths.length - 1].idFolder;
          const index = Folder.folders_paths
            .map(x => x.name)
            .indexOf(job_project);
          if (index !== -1)
            idFolderAtProject = Folder.folders_paths[index].idFolder;
          if (!folder_content) {
            folder_content_name = Folder.name;
            idFolderAtContent = Folder.idFolder;
            label_project_folder = `Project Folder at Content ${
              idFolderAtProject ? `(${idFolderAtProject})` : ''
            }`;
          }
        }
      }
      if (job.setting.job_name) job_name = job.setting.job_name;
      own_folder = job.setting.own_folder;
      on_content = job.setting.on_content;
    }
    if (IsInvalid(job_name) && job.process) {
      job_name = job.process.operation;
    }
    if (IsInvalid(job_name) && job.docker_image) {
      job_name = job.docker_image.operation;
    }
    if (job.idSeries) {
      file_type = 'Series';
      idfile = job.idSeries;
      if (job.series) file_name = job.series.SeriesDescription;
      else if (job.SeriesDescription) file_name = job.SeriesDescription;
    } else if (job.idFileObject) {
      file_type = 'File';
      idfile = job.idFileObject;
      if (job.file) file_name = job.file.original_name;
    }
    job_status = job.status;
    default_status = job.status;
    const { setting } = this.state;
    if (setting) {
      if (setting.own_folder !== undefined) own_folder = setting.own_folder;
      if (setting.on_content !== undefined) on_content = setting.on_content;
    }
    if (this.state.status !== undefined && this.state.status !== null) {
      job_status = this.state.status;
    }
    // console.log({ job, setting, updating: this.state.updating });
    let ComponentError = null;
    if (job.setting && (job.status === 'error' || job.setting.error)) {
      if (
        job.setting.error === undefined ||
        !job.setting.error ||
        job.setting.error === ''
      ) {
        job.setting.error = 'Unknown error';
      }
      ComponentError = (
        <Grid item xs={12}>
          <TextField
            defaultValue={job.setting.error}
            error
            fullWidth
            id="job-error"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            label="Job Error"
            multiline
            size="small"
            variant="filled"
          />
        </Grid>
      );
    }
    if (job_name === undefined || !job_name) job_name = job.job_name;
    if (job_project === undefined || !job_project || job_project === '') {
      if (job.setting && job.setting.folder_project) {
        job_project = job.setting.folder_project.name;
        idFolderAtProject = job.setting.folder_project.idFolder;
        label_project_folder = `Project Folder ${
          idFolderAtProject ? `(${idFolderAtProject})` : ''
        }`;
      }
    }
    // console.log({ job, job_setting: job.setting });
    return (
      <Dialog onClose={this.props.handleClose} open={open}>
        <DialogTitle>{`Job Process Setting ${
          job.id ? `( ID: ${job.id})` : ''
        }`}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                defaultValue={job_name}
                fullWidth
                id="job-name"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label="Job name"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <AutoComboBox
                defaultValue={job_status}
                getOptionLabel={option => {
                  if (option === '') option = 'waiting';
                  return option;
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === '') value = 'waiting';
                  return option === value;
                }}
                label="Job status"
                onObjectChanged={status => {
                  if (!status) status = default_status;
                  this.setState({ status });
                }}
                options={['waiting', 'error', 'finished']}
                small
                width={270}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                defaultValue={job_project}
                fullWidth
                id="project-folder"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label={label_project_folder}
                size="small"
                // variant="filled"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                defaultValue={folder_content_name}
                fullWidth
                id="folder-content"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label={`Content Folder Name ${
                  idFolderAtContent ? `(${idFolderAtContent})` : ''
                }`}
                size="small"
                // variant="filled"
              />
            </Grid>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={own_folder}
                    name="own_folder"
                    onChange={this.handleChange}
                  />
                }
                label="Own folder"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={on_content}
                    name="on_content"
                    onChange={this.handleChange}
                  />
                }
                label="On content"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={folderpath}
                fullWidth
                id="filled-size-small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label={`Path to the folder content ${
                  idFolderAtContent ? `(${idFolderAtContent})` : ''
                }`}
                size="small"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={file_name}
                fullWidth
                id="job-file-type"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label={`File type ${
                  file_type ? `(${file_type} ID: ${idfile})` : ''
                }`}
                size="small"
                variant="filled"
              />
            </Grid>
            {ComponentError}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <ButtonLoading
            disable_button={!this.IsChanged()}
            handleClick={this.handleSaveJobSetting}
            loading={this.state.updating}
            name="Save"
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogJobProcessSetting.propTypes = {
  classes: PropTypes.object,
  handleUpdateJobProcess: PropTypes.func,
};
DialogJobProcessSetting.defaultProps = {
  handleUpdateJobProcess: () => '',
};
export default DialogJobProcessSetting;
