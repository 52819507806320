import { alpha } from '@mui/material';
export { default as AutoCompleteCommunication } from './AutoCompleteCommunication';

export const useStyles = theme => ({
  upper_bar: {
    minWidht: 300,
    backgroundColor: 'white',
    display: 'flex',
    padding: '5px 10px 2px 10px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    minWidth: 200,
    width: '80%',
  },
  searchIcon: {
    width: theme.spacing(5),

    padding: '7px 0px 7px 7px',

    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    padding: '5px 10px 2px 10px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  menu_button: {
    marginRight: '0.5px',
    color: 'inherit',
  },
});