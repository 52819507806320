import {
  PAGE_UPLOAD_DOWNLOADS,
  PAGE_UPLOAD_DOWNLOADS_ADD,
} from '../actions/types';

export const addDownloadItem = item => dispatch => {
  dispatch({
    type: PAGE_UPLOAD_DOWNLOADS_ADD,
    item,
  });
};

export const addDownloads = downloads => dispatch => {
  dispatch({
    type: PAGE_UPLOAD_DOWNLOADS,
    downloads,
  });
};
