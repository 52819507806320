import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Divider, Grid } from '@mui/material';

import {
  TitleSort,
  Menu,
  ButtonVerticalDots,
  AscendingDescending,
  MenuFilterIItem,
} from './components';

class CellSortMenu extends React.Component {
  constructor(props) {
    super(props);
    const { header } = props;
    let filter_name = null;
    let filter_value = '';
    if (props.filter) {
      if (props.filter.header.headerName === header.headerName) {
        filter_name = props.filter.type;
        filter_value = props.filter.key;
      }
    }
    this.state = {
      anchorEl: null,
      filter_name,
      filter_value,
    };
  }
  updateInputs = () => {
    const { disabled_filter } = this.props;
    if (!disabled_filter) return;
    if (this.state.filter_value !== '') {
      this.setState({ filter_value: '' });
    }
  };
  componentDidUpdate() {
    const { isMouseOver } = this.props;
    if (!isMouseOver && this.state.anchorEl) {
      this.handleClose();
    } else {
      this.updateInputs();
    }
  }
  handleClick = event => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleHideColumn = () => {
    this.props.onClickHideColumn(this.props.header);
    this.setState({ anchorEl: null });
  };
  handleSearchFilterChanged = event => {
    event.preventDefault();
    event.stopPropagation();
    const { filter_name } = this.state;
    const { header } = this.props;
    let value = null;
    let filter_value = event.target.value;
    if (filter_name) {
      value = {
        key: filter_value,
        type: this.state.filter_name,
        header,
      };
    } else {
      filter_value = '';
    }
    // console.log('handleSearchFilterChanged', { key });
    this.props.onSearchFilterChanged(value);
    this.setState({ filter_value });
  };
  handleFilterSelected = filter_name => {
    if (!filter_name || filter_name === '') {
      // console.log('handleFilterSelected', { filter_name });
      this.props.onSearchFilterChanged(null);
      this.setState({ filter_name: 'Choose One', filter_value: '' });
    } else {
      const { header } = this.props;
      let value = null;
      if (filter_name) {
        value = {
          key: this.state.filter_value,
          type: this.state.filter_name,
          header,
        };
      }
      if (
        this.props.filter &&
        this.props.filter.type !== this.state.filter_name
      ) {
        this.props.onSearchFilterChanged(value);
      }
      if (this.state.filter_name !== filter_name) {
        this.setState({ filter_name });
      }
    }
  };
  render() {
    const {
      isMouseOver,
      orderBy,
      order,
      headerName,
      onSortHeader,
      header,
      disabled_filter,
    } = this.props;
    let open = Boolean(this.state.anchorEl);
    if (!isMouseOver) open = false;
    let IconFiltered = null;
    let width = '100%';
    let maxWidth = 20;
    // if (!disabled_filter) {
    //   width = '50%';
    //   maxWidth = 40;
    //   IconFiltered = (
    //     <Grid item style={{ width, padding: 0, margin: 0 }}>
    //       <FilterAltIcon style={{ paddingTop: 5 }} />
    //     </Grid>
    //   );
    // }
    let ComponentDots = null;
    if (isMouseOver || !disabled_filter) {
      ComponentDots = (
        <Grid item style={{ width, padding: 0, margin: 0 }}>
          <ButtonVerticalDots
            filter_active={!disabled_filter}
            handleClick={this.handleClick}
            headerName={headerName}
            isMouseOver={isMouseOver}
            open={open}
          />
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end"
          style={{ maxWidth, padding: 0, margin: 0 }}>
          {IconFiltered}
          {ComponentDots}
        </Grid>
        <Menu
          anchorEl={this.state.anchorEl}
          handleClose={this.handleClose}
          open={open}>
          <TitleSort title="Sort" />
          <AscendingDescending
            header={header}
            onSortHeader={onSortHeader}
            order={order}
            orderBy={orderBy}
          />
          <Divider />
          <TitleSort title="Filter" />
          <MenuFilterIItem
            disabled_filter={this.state.filter_name === 'Choose One'}
            filter={this.state.filter_name}
            handleSearchFilterChanged={this.handleSearchFilterChanged}
            onChange={this.handleFilterSelected}
            text_filter={this.state.filter_value}
          />
          <Divider style={{ paddingBottom: 5 }} />
          <Grid item style={{ paddingBottom: 10 }} />
          <MenuItem
            onClick={this.handleHideColumn}
            onKeyDown={e => e.stopPropagation()}>
            Hide
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={this.props.onClickShowColumn}
            onKeyDown={e => e.stopPropagation()}>
            Show columns
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

CellSortMenu.propTypes = {
  classes: PropTypes.object,
};
CellSortMenu.defaultProps = {
  disabled_filter: true,
  filter: null,
  onClick: () => '',
  onSortHeader: () => '',
  onClickHideColumn: () => '',
  onClickShowColumn: () => '',
  onSearchFilterChanged: () => '',
};
export default CellSortMenu;
