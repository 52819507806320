import { makeStyles } from 'tss-react/mui';

export const useStyles = theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
    height: '40px',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
  },
  notchedOutline: {
    borderWidth: '0',
    borderColor: 'white',
  },
  focused: {
    '& $notchedOutline': {
      borderColor: 'white',
    },
  },
});
export const useMakeStyles = makeStyles()(useStyles);
