import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SURVEYS_AT_PATIENTS_IDs = gql`
  query($idPatient: LongLong!) {
    allRecodedPatientSurveys(idPatient: $idPatient) {
      ok
      errors {
        message
      }
      surveys {
        id
        surveyType
        completed
        link
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryRecodedPatientSurveys = idPatient => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SURVEYS_AT_PATIENTS_IDs),
    variables: {
      idPatient,
    },
  });
};

const QUERY_SURVEY = gql`
  query($idSurvey: LongLong!) {
    surveyRecorded(idSurvey: $idSurvey) {
      ok
      errors {
        message
      }
      survey {
        id
        survey_raw
        survey
        surveyType
        completed
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySurvey = idSurvey => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SURVEY),
    variables: {
      idSurvey,
    },
  });
};

const MUTATION_SAVE_SURVEY = gql`
  mutation UpdateSurvey($input: InSurveyRecorded!, $link: String) {
    updateSurvey(input: $input, link: $link) {
      ok
      errors {
        message
      }
      survey {
        id
        survey_raw
        survey
        surveyType
        createdAt
        updatedAt
        completed
        patient {
          id
        }
      }
    }
  }
`;
export const MutationSaveSurvey = (survey, completeflag, link) => {
  const surveyData = JSON.stringify(survey.data);
  const plainData = JSON.stringify(
    survey.getPlainData({ includeEmpty: true, includeQuestionTypes: false })
  );

  const input = {
    idPatient: survey.idPatient,
    survey_raw: plainData,
    survey: surveyData,
    surveyType: survey.surveyType,
    completed: completeflag,
    id: survey.surveyId,
  };
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SAVE_SURVEY),
    variables: {
      input,
      link,
    },
  });
};

const MUTATION_CREATE_SURVEY = gql`
  mutation CreateSurvey($input: InSurveyRecorded!) {
    createSurvey(input: $input) {
      ok
      errors {
        message
      }
      survey {
        id
        survey_raw
        survey
        surveyType
        createdAt
        updatedAt
        completed
      }
    }
  }
`;
export const MutationCreateSurvey = (survey, completeflag) => {
  const surveyData = JSON.stringify(survey.data);
  const plainData = JSON.stringify(
    survey.getPlainData({ includeEmpty: true, includeQuestionTypes: false })
  );

  const input = {
    idPatient: survey.idPatient,
    survey_raw: plainData,
    survey: surveyData,
    surveyType: survey.surveyType,
    completed: completeflag,
  };
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_SURVEY),
    variables: {
      input,
    },
  });
};
const MUTATION_CREATE_LINK_SURVEY = gql`
  mutation($idSurvey: LongLong!) {
    createSurveyPatientLink(idSurvey: $idSurvey) {
      ok
      errors {
        message
      }
      survey {
        id
        createdAt
        updatedAt
        link
      }
    }
  }
`;
export const MutationCreatePatientSurveyLink = idSurvey => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_LINK_SURVEY),
    variables: {
      idSurvey,
    },
  });
};
const MUTATION_REMOVE_LINK_SURVEY = gql`
  mutation($idSurvey: LongLong!) {
    removeSurveyPatientLink(idSurvey: $idSurvey) {
      ok
      errors {
        message
      }
    }
  }
`;
export const MutationRemovePatientSurveyLink = idSurvey => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_LINK_SURVEY),
    variables: {
      idSurvey,
    },
  });
};
const QUERY_SURVEY_AT_LINK = gql`
  query($link: String!) {
    surveyPatientAtLink(link: $link) {
      ok
      errors {
        message
      }
      survey {
        id
        survey_raw
        survey
        surveyType
        createdAt
        updatedAt
        completed
        patient {
          id
          PatientName
          GivenName
          FamilyName
          PatientID
          PatientBirthDate
        }
      }
    }
  }
`;
export const QuerySurveyAtLink = link => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SURVEY_AT_LINK),
    variables: {
      link,
    },
  });
};
