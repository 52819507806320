import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  ClassificationUnsortedSequences,
  EditUnsortedSequences,
  TableUnsortedSequences,
  TabUnsortedSequencesHeader,
} from './components';
import { QueryUnsortedSequence } from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';

class TabUnsortedSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classification: false,
      loading: false,
      init: false,
      page: 0,
      limit: 100,
      total: 0,
      edit: false,
      selected: [],
      mappings: [],
      name: null,
      temp_name: null,
    };
    this.holder = {
      getSearchText: null,
      cleanSelected: null,
    };
  }
  componentDidMount() {
    const { temp_name } = this.state;
    if (temp_name && temp_name !== this.state.name) {
      this.queryMappings(temp_name);
    }
  }
  handleOpenEditView = () => {
    this.setState({ edit: !this.state.edit });
  };
  queryMappings = (newName = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName !== null) {
      name = newName;
    }
    if (!name && this.holder.getSearchText) {
      name = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QueryUnsortedSequence(name, page, limit)
        .then(res => {
          const data = GetData(res);
          let { mappings, ok, errors, total } = data.unsortedSequences;
          if (ok) {
            let { temp_name } = this.state;
            console.log({ temp_name, name });
            if (temp_name && temp_name == name) {
              temp_name = null;
            }
            mappings = mappings.filter(x => x.series);
            this.setState({
              loading: false,
              mappings,
              init: true,
              page,
              limit,
              total,
              name,
              temp_name,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            page,
            limit,
            temp_name: null,
            init: true,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.queryMappings(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.queryMappings(null, 0, +event.target.value);
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleUpdateUnsortedSequences = ids => {
    let { mappings, selected } = this.state;
    //
    for (let i = 0; i < ids.length; i++) {
      const idSeries = ids[i];
      let index = mappings.map(x => x.series.idSeries).indexOf(idSeries);
      if (index !== -1) {
        mappings.splice(index, 1);
      }
      index = selected.map(x => x.series.idSeries).indexOf(idSeries);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    }
    this.setState({ mappings, selected });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
    this.holder.cleanSelected();
  };
  render() {
    const { edit, loading, selected, temp_name, classification } = this.state;
    if (edit) {
      return (
        <EditUnsortedSequences
          handleError={this.props.handleAddError}
          handleOpenEditView={this.handleOpenEditView}
          handleUpdateUnsortedSequences={this.handleUpdateUnsortedSequences}
          selected={selected}
        />
      );
    }
    if (classification) {
      return (
        <ClassificationUnsortedSequences
          handleClose={() => this.setState({ classification: false })}
          handleError={this.props.handleAddError}
          handleUpdateUnsortedSequences={this.handleUpdateUnsortedSequences}
          selected={selected}
        />
      );
    }
    return (
      <Grid container>
        <TabUnsortedSequencesHeader
          handleClearSelected={this.handleClearSelected}
          handleOpenClassification={() =>
            this.setState({ classification: true })
          }
          handleOpenEdit={this.handleOpenEditView}
          handleReload={() => this.queryMappings()}
          handleSearchSequence={text => this.queryMappings(text)}
          loading={loading}
          name={temp_name ? temp_name : this.state.name}
          selected={selected}
        />
        <TableUnsortedSequences
          disable_splice
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSetSelected={this.handleSetSelected}
          height={this.props.height - 90}
          hide_toolbar
          holder={this.holder}
          limit={this.state.limit}
          loading={loading}
          page={this.state.page}
          rows={this.state.mappings}
          selected={selected}
          total={this.state.total}
        />
      </Grid>
    );
  }
}

TabUnsortedSequences.propTypes = {
  classes: PropTypes.object,
};

export default TabUnsortedSequences;
