import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import { withStyles } from 'tss-react/mui';
import { QuerySequences } from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';
import { DetailsAutocomplete } from '../index';
import { IsInvalid } from 'helpers';

const default_sequence = {
  idSequence: -1,
  name: '',
  updatedAt: '',
};

class Sequence extends Component {
  constructor(props) {
    super(props);
    const sequences = [];
    const { external } = props;
    if (external.length) {
      external.forEach(element => {
        sequences.unshift(element);
      });
    }
    this.state = {
      loading: false,
      sequence: { ...default_sequence },
      sequences,
      total: 0,
      open: false,
      error: '',
      empty: false,
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.getSequence = () => {
        return this.state.sequence;
      };
      props.holder.resetSequence = () => {
        this.state.sequence = { ...default_sequence };
        if (this.holder.reset) {
          this.holder.reset();
        }
      };
    }
    this.holder = {
      reset: null,
    };
  }

  componentDidMount() {
    this.runSearch();
  }

  componentDidUpdate() {
    const { sequence } = this.state;
    if (
      this.props.sequence &&
      sequence &&
      sequence.idSequence === -1 &&
      this.props.sequence.idSequence !== -1
    ) {
      this.setState({ sequence: this.props.sequence });
    }
  }

  setOpen = open => {
    this.setState({ open });
  };
  setSequence = sequence => {
    this.setState({ sequence });
  };
  handleTyping = event => {
    const { loading } = this.state;
    let focus = event.target.value;
    if (loading || !focus || focus === '') {
      return;
    }
    this.runSearch(focus, false);
  };
  runSearch = (sequence, empty) => {
    // console.log({ sequence });
    const { loading } = this.state;
    if (empty === undefined) empty = this.state.empty;
    if (loading || empty) {
      return;
    }
    const { sequences } = this.state;
    if (sequence && sequences.length) {
      sequence = sequence.toLowerCase();
      const here = sequences.filter(x =>
        x.name.toLowerCase().startsWith(sequence)
      );
      if (here.length) {
        return;
      }
    }
    const { external } = this.props;
    this.setState({ loading: true, empty: false });
    (async () => {
      QuerySequences(sequence)
        .then(res => {
          const data = GetData(res);
          const { ok, total, errors, sequences } = data.Sequences;

          if (ok) {
            if (external.length) {
              external.forEach(element => {
                sequences.unshift(element);
              });
            }
            this.setState({
              total,
              sequences,
              loading: false,
              empty: !sequences.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChanged = (label, newValue) => {
    if (!newValue) {
      newValue = { ...default_sequence };
    }
    this.setSequence(newValue);
    this.props.handleSelectedChanged(label, newValue);
    this.props.handleSetSequence(newValue);
  };

  render() {
    const { minWidth } = this.props;
    const { loading, sequences, open, sequence } = this.state;
    let final_sequence = sequence;
    let style = {};
    if (this.props.small) {
      style = { margin: 0 };
    }
    if (this.props.multiple) {
      if (IsInvalid(final_sequence)) final_sequence = [];
      else final_sequence = [final_sequence];
      if (
        final_sequence &&
        final_sequence.length === 1 &&
        final_sequence[0].idSequence === -1
      ) {
        final_sequence = [];
      }
    } else {
      if (final_sequence && final_sequence.idSequence === -1)
        final_sequence = undefined;
    }
    return (
      <DetailsAutocomplete
        detail={final_sequence}
        details={sequences}
        disabled={this.props.disabled}
        handleSelectedChanged={this.handleSelectedChanged}
        handleTyping={this.handleTyping}
        holder={this.holder}
        label="sequence"
        loading={loading}
        minWidth={minWidth}
        multiple={this.props.multiple}
        open={open}
        placeholder={this.props.placeholder}
        setOpen={this.setOpen}
        small={this.props.small}
        style={style}
        title="Sequences"
        variant={this.props.variant}
      />
    );
  }
}

Sequence.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  minWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  small: PropTypes.bool,
};

Sequence.defaultProps = {
  className: '',
  sequence: default_sequence,
  minWidth: false,
  disabled: false,
  small: false,
  handleSelectedChanged: () => '',
  handleSetSequence: () => '',
  external: [],
  multiple: false,
};
export default Sequence;
