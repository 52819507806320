import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  Typography,
} from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
class MappingSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relation_table: '',
      input_type: '',
      output_type: '',
      input_table_name: '',
      output_table_name: '',
    };
  }
  componentDidMount() {
    const { tables_definition, selected_join } = this.props;
    this.setFields(tables_definition, selected_join);
  }
  componentDidUpdate() {
    const { tables_definition, selected_join } = this.props;
    this.setFields(tables_definition, selected_join);
  }
  setFields = (tables_definition, selected_join) => {
    if (this.state.set) return;
    if (tables_definition === null || tables_definition === undefined) return;
    if (selected_join === null || selected_join === undefined) return;
    const state = {
      relation_table: selected_join.table_map_name,
      input_type: tables_definition.input_definition.selected_type.name,
      output_type: tables_definition.output_definition.selected_type.name,
      input_table_name: tables_definition.input_definition.selected_table.name,
      output_table_name:
        tables_definition.output_definition.selected_table.name,
      set: true,
    };
    this.setState(state);
  };
  render() {
    const {
      relation_table,
      input_type,
      output_type,
      input_table_name,
      output_table_name,
    } = this.state;
    return (
      <Box sx={{ width: '100%', maxWidth: '75%', bgcolor: 'background.paper' }}>
        <List component="nav">
          <ListItemButton key="1">
            <ListItemIcon>
              <LabelIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={relation_table}
              secondary={
                <Grid container style={{ width: '100%' }}>
                  <Grid item style={{ width: '100%' }} xs={6}>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Input Type:&nbsp;
                      </Typography>
                      {input_type}
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Output Type:&nbsp;
                      </Typography>
                      {output_type}
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: '100%' }} xs={6}>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Input Table:&nbsp;
                      </Typography>
                      {input_table_name}
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Output Table:&nbsp;
                      </Typography>
                      {output_table_name}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </ListItemButton>
        </List>
      </Box>
    );
  }
}

MappingSummary.propTypes = {
  handleClose: PropTypes.func,
};
MappingSummary.defaultProps = {
  handleClose: () => '',
};

export default MappingSummary;
