import React from 'react';
import PropTypes from 'prop-types';
import buttonStyle from './Style';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
  key: 'css',
  prepend: true,
});
const useStyles = makeStyles()(() => {
  return {
    ...buttonStyle,
  };
});

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    ...rest
  } = props;

  const { classes, cx } = useStyles();

  const btnClasses = cx({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });
  return (
    <CacheProvider value={cache}>
      <Button {...rest} className={btnClasses} ref={ref}>
        {children}
      </Button>
    </CacheProvider>
  );
});
RegularButton.displayName = 'RegularButton';
RegularButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'facebook',
    'twitter',
    'google',
    'github',
    'transparent',
  ]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  justIcon: PropTypes.bool,
  link: PropTypes.bool,
  round: PropTypes.bool,
  simple: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
};

export default RegularButton;
