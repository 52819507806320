// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ImageSizes } from 'graphql/Series';
import CustomPaper from '../Customizations/CustomPaper';
import { inputProps } from 'common';
const useStyles = makeStyles()(() => ({
  root: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      padding: 0,
      margin: 0,
    },
    '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
      padding: 0,
      margin: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 12,
      fontWeight: 500,
    },
  },
}));

export default function ImageSize({ holder }) {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [imagesize, setImageSize] = React.useState('');
  const loading = open && options.length === 0;

  if (holder && holder.getSize !== undefined) {
    holder.getSize = () => imagesize;
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const response = await ImageSizes();
        const imagesizes = response.data.data;
        const { ImageSize } = imagesizes.ImageSizes;
        //
        if (active) {
          setOptions(ImageSize);
        }
      } catch (error) {
        /* empty*/
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      className={classes.root}
      getOptionLabel={option => {
        if (option) return option.Size;
        return '';
      }}
      id="ImageSize-Component"
      isOptionEqualToValue={(option, value) => option.Size === value.Size}
      loading={loading}
      onChange={(event, value) => {
        if (value) {
          setImageSize(value.Size);
        } else {
          setImageSize('');
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={options}
      PaperComponent={CustomPaper}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            ...inputProps,
          }}
          label="ImageSize"
          variant="outlined"
        />
      )}
      size="small"
      style={{ width: 170 }}
    />
  );
}
