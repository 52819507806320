import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const READ_NOTIFICATIONS = gql`
  mutation {
    readNotifications
  }
`;

export const ReadNotifications = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(READ_NOTIFICATIONS),
  });
};

const QUERY_SETTINGS_NOTIFICATIONS = gql`
  query {
    UserSettings {
      ok
      setting {
        emails
        messages
        uploads
        chat
        requests
        shared
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QuerySettings = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SETTINGS_NOTIFICATIONS),
  });
};

const MUTATION_SETTINGS_NOTIFICATIONS = gql`
  mutation($Setting: InputNotificationSetting!) {
    updateSettings(Setting: $Setting) {
      ok
      setting {
        emails
        messages
        uploads
        chat
        requests
        shared
      }
      errors {
        path
        message
      }
    }
  }
`;

export const MutationSettings = Setting => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SETTINGS_NOTIFICATIONS),
    variables: {
      Setting,
    },
  });
};
const MUTATION_DELETE_NOTIFICATION = gql`
  mutation($ids: [LongLong!]!) {
    deleteNotifications(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteNotifications = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_NOTIFICATION),
    variables: {
      ids,
    },
  });
};
export const QUERY_NOTIFICATIONS = gql`
  query(
    $page: Int
    $limit: Int
    $mode: Int
    $idProject: Int
    $dateend: String
    $datestart: String
    $fullHistory: Boolean
  ) {
    notifications(
      page: $page
      limit: $limit
      mode: $mode
      idProject: $idProject
      dateend: $dateend
      datestart: $datestart
      fullHistory: $fullHistory
    ) {
      ok
      errors {
        path
        message
      }
      total
      notifications {
        id
        FolderContent {
          idFolder
          idFolderContent
          idSeries
          idFileObject
          Owner {
            id
            FullName
          }
        }
        From {
          id
          FullName
          avatar
        }
        To {
          id
          FullName
          avatar
        }
        Folder {
          idFolder
          name
          link {
            idFolder
            idFolderContent
            code
          }
        }
        Right {
          idRight
          idFolder
          idUser
          idCenterTeam
          User {
            id
            FullName
          }
          write
          download
          delete
          read
          full
          createdAt
          updatedAt
        }
        DirectMessage {
          id
          text
          sender {
            id
            FullName
          }
          read
          createdAt
        }

        name
        read

        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryNotifications = (
  page = null,
  limit = null,
  mode,
  idProject,
  dateend,
  datestart,
  fullHistory
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_NOTIFICATIONS),
    variables: {
      page,
      limit,
      mode,
      idProject,
      dateend,
      datestart,
      fullHistory,
    },
  });
};
export const SUBSCRIPTION_NEW_NOTIFICATION = gql`
  subscription {
    newNotification {
      id

      From {
        id
        FullName
        avatar
      }
      To {
        id
        FullName
        avatar
      }
      Folder {
        idFolder
        name
        link {
          idFolder
          idFolderContent
          code
        }
      }
      Right {
        idRight
        idFolder
        idUser
        idCenterTeam
        User {
          id
          FullName
        }
        write
        download
        delete
        read
        full
        createdAt
        updatedAt
      }
      DirectMessage {
        id
        text
        sender {
          id
          FullName
        }
        read
        createdAt
      }

      name
      read

      createdAt
      updatedAt
    }
  }
`;
