import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PhotoIcon from '@mui/icons-material/Photo';

class ToggleButtonSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { view, disabled } = this.props;
    return (
      <ToggleButtonGroup
        aria-label="text alignment"
        disabled={disabled}
        exclusive
        onChange={this.props.handleViews}
        size="small"
        value={view}>
        <ToggleButton aria-label="left aligned" value="series">
          <Tooltip title="Display Series">
            <PhotoIcon style={{ color: view === 'series' ? 'green' : null }} />
          </Tooltip>
        </ToggleButton>
        <ToggleButton aria-label="left aligned" value="files">
          <Tooltip title="Display Files">
            <InsertDriveFileIcon
              style={{ color: view === 'files' ? 'green' : null }}
            />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

ToggleButtonSelection.propTypes = {
  disabled: PropTypes.bool,
};

export default ToggleButtonSelection;
