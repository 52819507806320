import { FormatTime, IsInvalid } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    id: 'team_name',
    label: 'Name',
    minWidth: 170,
    type: 'full_access',
    format: value => {
      if (IsInvalid(value)) return 'Name Missing';
      const { row } = value;
      if (IsInvalid(row)) return 'Name Missing';
      const idCenterTeam = row['idCenterTeam'];
      const center_team = row['center_team'];
      let name = 'Name Missing';
      if (center_team) {
        const { team } = center_team;
        if (team) name = team.name;
      }
      if (idCenterTeam) return `${name} (${idCenterTeam})`;
      return name;
    },
  },
  {
    id: 'members',
    label: '#Members',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'center',
    label: 'Center',
    align: 'left',
    minWidth: 170,
    format: value => {
      let { name } = value;
      if (name === undefined) return 'Name Missing';
      return name;
    },
  },
  {
    id: 'updatedAt',
    label: 'Update At',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
