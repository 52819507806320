import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  CardActions,
  CardHeader,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';

import { getInitials, GetThumbnailFile } from 'helpers';
import { SeriesTagValue } from 'helpers/Series';
import { DivGrow } from 'components';

class TopMenu extends Component {
  render() {
    const { classes, Series, open, handleDrawerOpen, handleBack } = this.props;
    let Thumbnail = SeriesTagValue(Series, 'Thumbnail');
    const SeriesTime = SeriesTagValue(Series, 'SeriesTime');
    const SeriesDate = SeriesTagValue(Series, 'SeriesDate');
    const SeriesDescription = SeriesTagValue(Series, 'SeriesDescription');
    const PatientName = SeriesTagValue(Series, 'PatientName');
    const ImageSize = SeriesTagValue(Series, 'Size');
    const ImageSpacing = SeriesTagValue(Series, 'Spacing');
    if (Thumbnail && Thumbnail !== '') {
      Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
    } else if (Series.Modality === 'SR') {
      Thumbnail = '/images/icons/icon_sr_1.png';
    } else if (Series.Modality === 'PR') {
      Thumbnail = '/images/icons/icon_pr_1.png';
    } else {
      Thumbnail = GetThumbnailFile('file.dcm');
    }
    const sytle_title = { marginRight: '5px', textTransform: 'none' };
    return (
      <CardActions
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        disableSpacing>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <CardHeader
          avatar={
            <Avatar alt={getInitials(SeriesDescription)} src={Thumbnail} />
          }
          // subheader={`Series date: ${SeriesDate},  Series time: ${SeriesTime}`}
          subheader={
            <React.Fragment>
              <Typography
                display="inline"
                gutterBottom
                style={sytle_title}
                variant="h6">
                Series date:
              </Typography>
              <Typography
                display="inline"
                gutterBottom
                variant="caption"
                // style={{ marginRight: '5px' }}
              >
                {SeriesDate}
              </Typography>
              <Typography
                display="inline"
                gutterBottom
                style={sytle_title}
                variant="h6">
                {', Series time:'}
              </Typography>
              <Typography display="inline" gutterBottom variant="caption">
                {SeriesTime}
              </Typography>
            </React.Fragment>
          }
          title={
            <React.Fragment>
              <Typography
                display="inline"
                gutterBottom
                style={sytle_title}
                variant="h6">
                Patient Name:
              </Typography>
              <Typography display="inline" gutterBottom variant="body2">
                {PatientName}
              </Typography>
            </React.Fragment>
          }
        />
        <CardHeader
          subheader={
            <React.Fragment>
              <Typography
                display="inline"
                gutterBottom
                style={sytle_title}
                variant="h6">
                Image Size:
              </Typography>
              <Typography
                display="inline"
                gutterBottom
                variant="caption"
                // style={{ marginRight: '5px' }}
              >
                {ImageSize}
              </Typography>
              <Typography
                display="inline"
                gutterBottom
                style={{ marginRight: '5px' }}
                variant="h6">
                {',  Spacing:'}
              </Typography>
              <Typography display="inline" gutterBottom variant="caption">
                {ImageSpacing}
              </Typography>
            </React.Fragment>
          }
          title={
            <React.Fragment>
              <Typography
                display="inline"
                gutterBottom
                style={sytle_title}
                variant="h6">
                Series Description:
              </Typography>
              <Typography display="inline" gutterBottom variant="body2">
                {SeriesDescription}
              </Typography>
            </React.Fragment>
          }
        />
        <DivGrow />
        <IconButton
          aria-label="show more"
          className={clsx(classes.expand, {
            [classes.hide]: open,
          })}
          // aria-expanded={expanded}
          onClick={handleDrawerOpen}
          size="large">
          <MenuIcon />
        </IconButton>
      </CardActions>
    );
  }
}

TopMenu.propTypes = {
  classes: PropTypes.object,
};

export default TopMenu;
