import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';

const QUERY_ENCRYPTION_PATIENTS = gql`
  query($query_inputs: EncryptionPatientQuery, $page: Int, $limit: Int) {
    encryptionPatients(
      query_inputs: $query_inputs
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idAddress
        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID
        PatientSex

        PatientBirthDate
        PatientBirthTime

        PatientAge
        PatientSize
        PatientWeight

        Occupation
        OtherPatientNames
        EthnicGroup

        UniqueKeyIdentifier
        address {
          city
          country
        }
        patient_consent {
          id
          consent
          type
          information
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryEncryptionPatients = (query_inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ENCRYPTION_PATIENTS),
    variables: {
      query_inputs,
      page,
      limit,
    },
  });
};

const MUTATIOM_UPLOAD_ENCRYPTED_PATIENTS = gql`
  mutation($file: Upload!) {
    uploadEncryptionPatients(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idAddress
        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID
        PatientSex

        PatientBirthDate
        PatientBirthTime

        PatientAge
        PatientSize
        PatientWeight

        Occupation
        OtherPatientNames
        EthnicGroup

        UniqueKeyIdentifier
        address {
          city
          country
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEncryptionPatientsCSV = (
  file,
  handleProgress = null
) => {
  return MutationUploadSingleFile(
    file,
    MUTATIOM_UPLOAD_ENCRYPTED_PATIENTS,
    handleProgress
  );
};
const MUTATION_ADD_ENCRYPTION_PATIENTS = gql`
  mutation($inputs: [InputEncryptionPatient!]!) {
    addEncryptionPatients(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      patients {
        id
        idAddress
        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID
        PatientSex

        PatientBirthDate
        PatientBirthTime

        PatientAge
        PatientSize
        PatientWeight

        Occupation
        OtherPatientNames
        EthnicGroup

        UniqueKeyIdentifier
        address {
          city
          country
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationAddEncryptionPatients = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_ENCRYPTION_PATIENTS),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_ENCRYPTION_PATIENTS = gql`
  mutation($ids: [LongLong!]!) {
    removeEncryptionPatients(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveEncryptionPatients = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_ENCRYPTION_PATIENTS),
    variables: {
      ids,
    },
  });
};
