import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  TabContainerDetails,
  TabContainerNetworks,
  TabContainerStatus,
  TabContainerVolumes,
  useStyles,
  ViewDockerContainerBottom,
  ViewDockerContainerHeader,
} from './components';
import { TabPanel } from 'components';

const tabs = [
  { name: 'status', Component: TabContainerStatus, index: 0 },
  { name: 'details', Component: TabContainerDetails, index: 1 },
  { name: 'volumes', Component: TabContainerVolumes, index: 2 },
  { name: 'networks', Component: TabContainerNetworks, index: 3 },
];

class ViewDockerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(tabValue, 10) });
  };

  render() {
    const { classes, height, user } = this.props;
    const { tabValue } = this.state;
    return (
      <Card className={classes.card}>
        <ViewDockerContainerHeader
          classes={classes}
          container={this.props.container}
          handleClose={this.props.handleClose}
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <Tabs
              indicatorColor="primary"
              onChange={this.handleTabValue}
              textColor="primary"
              value={tabValue}
              variant="scrollable">
              <Tab label="Status" />
              <Tab label="Details" />
              <Tab label="Volumes" />
              <Tab label="Networks" />
            </Tabs>
            <Divider />
            {tabs.map(tab => {
              const { Component, index } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`tab-container-${index}`}
                  value={tabValue}>
                  <Component
                    container={this.props.container}
                    // handleSetSelectedContainers={
                    //   this.handleSetSelectedContainers
                    // }
                    handleAddError={this.props.handleAddError}
                    height={height}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <ViewDockerContainerBottom handleError={this.props.handleError} />
        </CardActions>
      </Card>
    );
  }
}

ViewDockerContainer.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
ViewDockerContainer.defaultProps = {
  handleClose: () => '',
  handleError: () => '',
};

export default withStyles(ViewDockerContainer, useStyles);
