import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { options_types, options_action } from 'helpers/Docker';
import {
  OutputCsv,
  OutputFolder,
  OutputDicom,
  OutputJson,
  OutputNifti,
} from './components';
import { QueryColumnNames } from 'graphql/utils_tables';
import { GetData, ServerErrorsString } from 'helpers';

class OutputDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_type: null,
      selected_action: null,
      selected_table: null,
      selected_column: null,
      configuration: null,
      loading: false,
      column_list: [],
      set: false,
    };
  }
  componentDidMount() {
    const { output_definition } = this.props;
    this.queryColumns();
    this.setOutputDefinition(output_definition);
  }
  componentDidUpdate() {
    const { output_definition } = this.props;
    this.setOutputDefinition(output_definition);
  }
  setOutputDefinition = output_definition => {
    if (this.state.set) return;
    if (output_definition === null || output_definition === undefined) return;
    const {
      selected_type,
      selected_action,
      configuration,
      selected_table,
      selected_column,
    } = output_definition;
    const state = {
      selected_type,
      selected_action,
      configuration,
      selected_table,
      selected_column,
      set: true,
    };
    this.setState(state);
  };
  queryColumns = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryColumnNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, columns } = data.columnNames;
          if (ok) {
            this.setState({
              loading: false,
              column_list: columns,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedType = selected_type => {
    const {
      configuration,
      selected_action,
      selected_table,
      selected_column,
    } = this.state;
    this.setState({ selected_type });
    this.props.handleUpdateOutputDefinition({
      selected_table,
      selected_column,
      selected_action,
      selected_type,
      configuration,
    });
    this.renderOutputConfiguration(selected_type);
  };
  handleSelectedAction = selected_action => {
    const {
      configuration,
      selected_table,
      selected_column,
      selected_type,
    } = this.state;
    this.setState({ selected_action: selected_action });
    this.props.handleUpdateOutputDefinition({
      selected_table,
      selected_column,
      selected_action,
      selected_type,
      configuration,
    });
  };
  handleSelectedTable = selected_table => {
    const {
      configuration,
      selected_action,
      selected_column,
      selected_type,
    } = this.state;
    this.setState({ selected_table });
    this.props.handleUpdateOutputDefinition({
      selected_table,
      selected_column,
      selected_action,
      selected_type,
      configuration,
    });
  };
  handleSelectedColumn = selected_column => {
    const {
      configuration,
      selected_action,
      selected_table,
      selected_type,
    } = this.state;
    this.setState({ selected_column });
    this.props.handleUpdateOutputDefinition({
      selected_table,
      selected_column,
      selected_action,
      selected_type,
      configuration,
    });
  };
  handleUpdateOutputConfiguration = configuration => {
    const {
      selected_action,
      selected_table,
      selected_column,
      selected_type,
    } = this.state;
    this.setState({ configuration });
    this.props.handleUpdateOutputDefinition({
      selected_table,
      selected_column,
      selected_action,
      selected_type,
      configuration,
    });
  };
  renderOutputConfiguration = () => {
    const { output_definition } = this.props;
    const { selected_type } = this.state;
    if (selected_type === null || selected_type === undefined) return;
    switch (selected_type.name) {
      case 'None':
        return <h3>None</h3>;
      case 'Folder':
        return (
          <OutputFolder
            configuration={
              output_definition !== null
                ? output_definition.configuration
                : null
            }
            handleOutputConfiguration={configuration =>
              this.handleUpdateOutputConfiguration(configuration)
            }
            table_list={this.props.table_list}
          />
        );
      case 'DICOM':
        return (
          <OutputDicom
            configuration={
              output_definition !== null
                ? output_definition.configuration
                : null
            }
            handleOutputConfiguration={configuration =>
              this.handleUpdateOutputConfiguration(configuration)
            }
            table_list={this.props.table_list}
          />
        );
      case 'NIfTI':
        return (
          <OutputNifti
            configuration={
              output_definition !== null
                ? output_definition.configuration
                : null
            }
            handleOutputConfiguration={configuration =>
              this.handleUpdateOutputConfiguration(configuration)
            }
            table_list={this.props.table_list}
          />
        );
      case 'CSV':
        return (
          <OutputCsv
            configuration={
              output_definition !== null
                ? output_definition.configuration
                : null
            }
            handleOutputConfiguration={configuration =>
              this.handleUpdateOutputConfiguration(configuration)
            }
            table_list={this.props.table_list}
          />
        );
      case 'JSON':
        return (
          <OutputJson
            configuration={
              output_definition !== null
                ? output_definition.configuration
                : null
            }
            handleOutputConfiguration={configuration =>
              this.handleUpdateOutputConfiguration(configuration)
            }
            table_list={this.props.table_list}
          />
        );
      case 'String':
        return <h3>String</h3>;
      case 'Number':
        return <h3>Number</h3>;
      default:
        return null;
    }
  };

  render() {
    const { uploading, table_list } = this.props;
    const {
      selected_type,
      selected_action,
      selected_table,
      selected_column,
      column_list,
    } = this.state;
    let output_type = '';
    if (selected_type) {
      output_type = selected_type.name;
    }
    let output_action = '';
    if (selected_action) {
      output_action = selected_action.name;
    }
    let output_table = '';
    if (selected_table) {
      output_table = selected_table.name;
    }
    let output_column = '';
    if (selected_column) {
      output_column = selected_column.name;
    }
    return (
      <Grid container item spacing={2} xs={12}>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled
            handleAddResultObject={this.handleSelectedType}
            label="Output Data Type"
            name={output_type}
            no_icon
            options={options_types}
            placeholder="Select output type of your docker..."
            required
            selected={selected_type}
            setObject={this.handleSelectedType}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={12}>
          {this.renderOutputConfiguration()}
        </Grid>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedTable}
            label="Output Reference Table"
            name={output_table}
            no_icon
            options={table_list}
            placeholder="Select reference table of your output type..."
            required
            selected={selected_table}
            setObject={this.handleSelectedTable}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedColumn}
            label="Output Reference Column"
            name={output_column}
            no_icon
            options={column_list}
            placeholder="Select reference column of your output type..."
            required
            selected={selected_column}
            setObject={this.handleSelectedColumn}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={6}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedAction}
            label="Output Data Action"
            name={output_action}
            no_icon
            options={options_action}
            placeholder="Select output action to perform..."
            required
            selected={selected_action}
            setObject={this.handleSelectedAction}
          />
        </Grid>
      </Grid>
    );
  }
}

OutputDefinition.propTypes = {
  handleUpdateOutputDefinition: PropTypes.func,
  output_definition: PropTypes.object,
  table_list: PropTypes.array,
};
OutputDefinition.defaultProps = {
  output_definition: null,
  table_list: null,
  handleUpdateOutputDefinition: () => '',
};

export default OutputDefinition;
