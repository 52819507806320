import React from 'react';
import PropTypes from 'prop-types';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { setExplorerView } from 'redux-store/actions';

const useStyles = theme => ({
  root: {
    marginRight: theme.spacing(1),
  },
});

class ToogleButtonExplorerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleViewType = (event, value) => {
    this.props.setExplorerView(value);
  };

  render() {
    const { classes, view_type } = this.props;
    return (
      <ToggleButtonGroup
        aria-label="toggle_buttons_view"
        className={classes.root}
        exclusive
        onChange={this.handleViewType}
        size="small"
        value={view_type}>
        <ToggleButton aria-label="left aligned" value={0}>
          <ViewModuleIcon />
        </ToggleButton>
        <ToggleButton aria-label="centered" value={1}>
          <ViewListIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

ToogleButtonExplorerView.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    view_type: state.page_explorer.view_type,
  };
};
export default connect(mapStateToProps, { setExplorerView })(
  withStyles(ToogleButtonExplorerView, useStyles)
);
