import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_MY_UNIT = gql`
  query($idUser: LongLong!) {
    MyUnit(idUser: $idUser) {
      ok
      unit {
        idUnit
        Admin {
          id
          email
          FullName
        }
        name
        code
        serial_number
        institution
        description
        api
        ip
        ip_private
        latitude
        longitude
        country
        country_code
        city
        setting {
          id
          read
          receive
          find
          status
        }
        status
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const MyUnit = idUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MY_UNIT),
    variables: { idUser },
  });
};
const QUERY_REQUEST_ACCESS = gql`
  query {
    RequestAccess {
      ok
      unit {
        idUnit
        Admin {
          id
          email
          FullName
        }
        name
        code
        serial_number
        institution
        description
        ip
        ip_private
        api
        latitude
        longitude
        country
        country_code
        city
        setting {
          id
          read
          receive
          find
          status
        }
        status
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryRequestAccess = ADDRESS_GRAPHQL => {
  // const ADDRESS_GRAPHQL = `${PROTOCOL}://${HOST}:${PORT}/graphql`;
  return axios.post(ADDRESS_GRAPHQL, {
    query: print(QUERY_REQUEST_ACCESS),
    // variables: { idUser },
  });
};

const MUTATION_ADD_UNITS = gql`
  mutation($units: [ExternalUnit!]!) {
    AddUnits(units: $units) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAddUnits = units => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_UNITS),
    variables: {
      units: units.map(x => {
        return {
          api: x.api,
          email: x.email,
          name: x.name,
          code: x.code,
          serial_number: x.serial_number,
          institution: x.institution,
          description: x.description,
          ip: x.ip,
          ip_private: x.ip_private,
          latitude: x.latitude,
          longitude: x.longitude,
          time_zone: x.time_zone,
          continent: x.continent,
          country: x.country,
          country_code: x.country_code,
          city: x.city,
        };
      }),
    },
  });
};

const MUTATION_REMOVE_UNITS = gql`
  mutation($ids: [Int!]!) {
    RemoveUnits(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveUnits = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_UNITS),
    variables: { ids },
  });
};
