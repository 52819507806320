import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Fab,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// import { tags } from 'data';
import { MultiAutoComplete, TreeSites } from 'components';

import {
  projectCreateSettingsFolderStructure,
  projectCreateSettingsImageType,
} from 'redux-store/actions/projectActions';

const tags = [];

const file_types = [
  { name: 'DICOM' },
  { name: 'PNG' },
  { name: 'JPEG' },
  { name: 'MHA' },
  { name: 'MHD' },
  { name: 'NIfTI' },
];

const useStyles = () => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function compare(arr1, arr2) {
  if (!arr1 || !arr2) return true;

  let result;

  arr1.forEach(e1 =>
    arr2.forEach(e2 => {
      if (e1.length > 1 && e2.length) {
        result = compare(e1, e2);
      } else if (e1 !== e2) {
        result = false;
      } else {
        result = true;
      }
    })
  );

  return result;
}

class ProjectStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: {},
      save_on: props.save_on ? props.save_on : false,
    };
    this.handleAddTagName = this.handleAddTagName.bind(this);
    this.handleSelectFileTypes = this.handleSelectFileTypes.bind(this);
  }

  handleSelectFileTypes = values => {
    const {
      project_settings: { img_type },
    } = this.props;
    if (compare(values, img_type)) {
      return;
    }

    this.props.projectCreateSettingsImageType(values);
  };
  handleClickTagName = (event, newValue) => {
    event.preventDefault();

    this.setState({ tag: newValue });
  };
  handleAddTagName = () => {
    const {
      tag: { tag_name },
    } = this.state;

    if (!tag_name) {
      return;
    }
    const {
      project_settings: { folder_structure },
    } = this.props;
    folder_structure.push(tag_name);
    this.props.projectCreateSettingsFolderStructure(folder_structure);
  };

  render() {
    const {
      className,
      classes,
      // title,
      // subtitle,
      // save,
      // projectCreateSettingsImageType,
      // projectCreateSettingsFolderStructure,
      project_settings: { img_type, folder_structure },
      ...rest
    } = this.props;
    const { save_on } = this.state;

    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader
            subheader="Sites Configurations"
            title="Project Structure"
          />
          <Divider />
          <CardContent>
            <Grid
              alignItems="center"
              container
              direction="column"
              justifyContent="space-evenly"
              spacing={1}>
              <Grid item style={{ width: '100%' }} xs={12} zeroMinWidth>
                <MultiAutoComplete
                  array={file_types}
                  handleSelectedValues={this.handleSelectFileTypes}
                  selected={img_type && img_type.length > 0 ? img_type : null}
                  sub_title="Type File Type"
                  title="File Types"
                />
              </Grid>
              <Grid item style={{ width: '100%' }} xs={12} zeroMinWidth>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="flex-start"
                  spacing={2}>
                  <Grid item>
                    <Autocomplete
                      getOptionLabel={option => {
                        if (option) return option.tag_name;
                        return '';
                      }}
                      id="combo-folder-tree"
                      onChange={this.handleClickTagName.bind(this)}
                      options={tags}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Folder Tag"
                            placeholder="Type DICOM Tag"
                            variant="outlined"
                          />
                        );
                      }}
                      style={{ width: 300 }}
                    />
                  </Grid>
                  <Grid item>
                    <Fab
                      aria-label="add"
                      color="primary"
                      onClick={this.handleAddTagName}>
                      <AddIcon />
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '100%' }} xs={12} zeroMinWidth>
                <TreeSites folders={folder_structure} />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          {save_on && (
            <CardActions>
              <Button color="primary" variant="outlined">
                Save
              </Button>
            </CardActions>
          )}
        </form>
      </Card>
    );
  }
}

ProjectStructure.propTypes = {
  className: PropTypes.string,
  projectCreateSettingsFolderStructure: PropTypes.func.isRequired,
  projectCreateSettingsImageType: PropTypes.func.isRequired,
  project_settings: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  project_settings: state.projects.project_settings,
});

export default connect(mapStateToProps, {
  projectCreateSettingsImageType,
  projectCreateSettingsFolderStructure,
})(withStyles(ProjectStructure, useStyles));
