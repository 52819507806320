import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Switch,
} from '@mui/material';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import { ButtonLoading } from 'components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
export const useStyles = theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    background: 'white',
    '& h2': {
      color: 'black',
      fontSize: '20px',
      fontWeight: 1000,
    },
  },
  summary: {
    maxHeight: 8,
    padding: '0px',
    paddingRight: '10px',
    margin: '0px',
  },
  details: {
    padding: '0px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    margin: '0px',
  },
});

class DialogSelectDownloadColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxes_data: [],
      loading: false,
    };
  }
  componentDidMount() {
    const { columns, table } = this.props;

    let checkboxes_data = columns.reduce((result, item) => {
      let { parent, ...rest } = item;
      if (!result[parent]) {
        result[parent] = [];
      }
      result[parent].push({ ...rest, checked: false });
      return result;
    }, {});

    checkboxes_data = Object.keys(checkboxes_data).map(key => {
      const texts = key.toLowerCase().split(' ');
      const regex = /ies$/;
      const table_name = table.replace(regex, 'y');
      const enabled = table_name.includes(texts[texts.length - 1]);
      const children = checkboxes_data[key];
      for (let i = 0; i < children.length; i++) {
        children[i].checked = enabled;
      }
      return {
        parent: key,
        children: children,
        enabled: enabled,
        expanded: enabled,
        all_selected: enabled,
      };
    });
    checkboxes_data = this.moveEnabledToFront(checkboxes_data);
    this.setState({ checkboxes_data });
  }
  moveEnabledToFront = array => {
    array.sort((a, b) => {
      if (a.enabled && !b.enabled) {
        return -1;
      } else if (!a.enabled && b.enabled) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  };
  getSelectedColumns = () => {
    const { checkboxes_data } = this.state;
    const selected_columns = [];
    checkboxes_data.forEach(parent => {
      if (parent.enabled) {
        parent.children.forEach(child => {
          if (child.checked) {
            selected_columns.push(child.key);
          }
        });
      }
    });
    this.props.handleDownload(selected_columns);
  };
  isDownloadEnabled = () => {
    const { checkboxes_data } = this.state;
    let count = 0;
    checkboxes_data.forEach(parent => {
      if (parent.enabled) {
        parent.children.forEach(child => {
          if (child.checked) {
            count += 1;
          }
        });
      }
    });
    return count === 0;
  };
  isIndetermined = index => {
    const { checkboxes_data } = this.state;
    let count = 0;
    checkboxes_data[index].children.forEach(child => {
      if (child.checked) {
        count += 1;
      }
    });
    return count > 0 && count < checkboxes_data[index].children.length;
  };
  handleSelectAllChangeTable = index => {
    let { checkboxes_data } = this.state;
    checkboxes_data[index].all_selected = !checkboxes_data[index].all_selected;
    for (let i = 0; i < checkboxes_data[index].children.length; i++) {
      checkboxes_data[index].children[i].checked =
        checkboxes_data[index].all_selected;
    }
    this.setState({ checkboxes_data });
  };
  handleAccordionChange = index => {
    let { checkboxes_data } = this.state;
    checkboxes_data[index].expanded = !checkboxes_data[index].expanded;
    this.setState({ checkboxes_data });
  };
  handleEnable = index => {
    let { checkboxes_data } = this.state;
    checkboxes_data[index].enabled = !checkboxes_data[index].enabled;
    checkboxes_data[index].expanded = checkboxes_data[index].enabled;
    checkboxes_data[index].all_selected = checkboxes_data[index].enabled;
    for (let i = 0; i < checkboxes_data[index].children.length; i++) {
      checkboxes_data[index].children[i].checked =
        checkboxes_data[index].enabled;
    }
    this.setState({ checkboxes_data });
  };
  handleSwitchChange = (index, item) => {
    let { checkboxes_data } = this.state;
    for (let i = 0; i < checkboxes_data[index].children.length; i++) {
      const child = checkboxes_data[index].children[i];
      if (child.key === item.key) {
        checkboxes_data[index].children[i].checked = !child.checked;
      }
    }
    const allChildrenChecked = checkboxes_data.every(item => {
      return item.children.every(child => child.checked);
    });
    checkboxes_data[index].all_selected = allChildrenChecked;
    this.setState({ checkboxes_data });
  };

  render() {
    const { open, handleClose, classes, studyName } = this.props;
    const { checkboxes_data } = this.state;
    if (checkboxes_data.length === 0) return null;
    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        onClose={() => handleClose()}
        open={open}>
        <DialogTitle id="form-dialog-title">Exportation Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By default all the columns of the tables used for the study{' '}
            {studyName} will be exported. If you do not want to include a
            column, use the column selection option below.
          </DialogContentText>
          <Box style={{ marginTop: '10px' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
              }}>
              {checkboxes_data.length &&
                checkboxes_data.map((table, index) => (
                  <Accordion
                    className={clsx({
                      ['Mui-disabled']: !table.enabled,
                    })}
                    disableGutters
                    expanded={table.expanded}
                    key={index}
                    onChange={() => {
                      console.log('');
                      if (table.enabled) this.handleAccordionChange(index);
                    }}>
                    <AccordionSummary
                      aria-controls={`panel${index + 1}a-content`}
                      className={clsx({
                        [classes.summary]: true,
                      })}
                      expandIcon={
                        table.enabled ? (
                          <ExpandLessIcon
                          // style={{ color: table.enabled ? 'blue' : 'gray' }}
                          />
                        ) : null
                      }
                      id={`panel${index + 1}a-header`}>
                      <Grid alignItems="center" container>
                        <Grid item>
                          <Checkbox
                            checked={table.enabled}
                            color="primary"
                            onChange={() => this.handleEnable(index)}
                          />{' '}
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ color: table.enabled ? 'black' : 'gray' }}>
                            Include table - {table.parent}
                          </Typography>{' '}
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              marginLeft: '5px',
                              color: 'grey',
                              fontSize: '14px',
                            }}>
                            {' '}
                            (switch on/off to include/exclude columns)
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      className={clsx({
                        [classes.details]: true,
                      })}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={table.all_selected}
                            color="primary"
                            disabled={!table.enabled}
                            indeterminate={this.isIndetermined(index)}
                            onChange={() =>
                              this.handleSelectAllChangeTable(index)
                            }
                          />
                        }
                        label={
                          table.all_selected ? 'Deselect All' : 'Select All'
                        }
                      />
                      <Grid container>
                        {table &&
                          table.children.map(item => (
                            <Grid item key={item.key} md={2} sm={4} xs={6}>
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                }}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={item.checked}
                                      disabled={!table.enabled}
                                      onChange={() =>
                                        this.handleSwitchChange(index, item)
                                      }
                                    />
                                  }
                                  label={item.value}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleClose()}
            size="medium"
            variant="text">
            Cancel
          </Button>
          <ButtonLoading
            color="primary"
            disable_button={this.isDownloadEnabled()}
            handleClick={() => this.getSelectedColumns()}
            loading={this.props.downloading}
            name="Download"
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogSelectDownloadColumns.propTypes = {
  columns: PropTypes.array,
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  handleDownload: PropTypes.func,
  open: PropTypes.bool,
};
DialogSelectDownloadColumns.defaultProps = {
  columns: [],
  handleAddError: () => '',
  handleClose: () => '',
  handleDownload: () => '',
};

export default withStyles(DialogSelectDownloadColumns, useStyles);
