import { IsInvalid } from 'helpers';
const GetDefault = id => {
  return {
    id: id * -1,
    consent: false,
    type: '',
    information: '',
  };
};
const GetConsent = params => {
  if (IsInvalid(params)) return null;
  if (IsInvalid(params.getValue)) return null;
  const patient_consent = params.getValue(params.id, 'patient_consent');
  if (IsInvalid(patient_consent)) return null;
  return patient_consent;
};
export const columns_consent = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'PatientName',
    headerName: 'PatientName',
    width: 75,
    align: 'left',
  },
  {
    field: 'consent',
    headerName: 'Consent',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const patient_consent = GetConsent(params);
      if (IsInvalid(patient_consent)) return 'No';
      const { consent } = patient_consent;
      if (IsInvalid(consent) || consent === '') return 'No';
      if (typeof consent === 'string') {
        if (consent.startsWith('y') || consent.startsWith('Y')) return 'Yes';
        return 'No';
      }
      return consent ? 'Yes' : 'No';
    },
    editerGetter: (row, value) => {
      let patient_consent = row['patient_consent'];
      if (IsInvalid(patient_consent)) {
        patient_consent = GetDefault(row['id']);
      }
      row = {
        ...row,
        patient_consent: {
          ...patient_consent,
          consent: value,
        },
      };
      return row;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const patient_consent = GetConsent(params);
      if (IsInvalid(patient_consent)) return '';
      return patient_consent.type;
    },
    editerGetter: (row, value) => {
      let patient_consent = row['patient_consent'];
      if (IsInvalid(patient_consent)) {
        patient_consent = GetDefault(row['id']);
      }
      row = {
        ...row,
        patient_consent: {
          ...patient_consent,
          type: value,
        },
      };
      return row;
    },
  },
  {
    field: 'information',
    headerName: 'Information',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const patient_consent = GetConsent(params);
      if (IsInvalid(patient_consent)) return '';
      return patient_consent.information;
    },
    editerGetter: (row, value) => {
      let patient_consent = row['patient_consent'];
      if (IsInvalid(patient_consent)) {
        patient_consent = GetDefault(row['id']);
      }
      row = {
        ...row,
        patient_consent: {
          ...patient_consent,
          information: value,
        },
      };
      return row;
    },
  },
  {
    field: 'file_path',
    headerName: 'FileName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      const patient_consent = GetConsent(params);
      if (IsInvalid(patient_consent)) return '';
      const { file_path } = patient_consent;
      if (IsInvalid(file_path) || file_path === '') return '';
      const filename = file_path.match(/[^/]+$/)[0];
      return filename;
    },
  },
];
export const fields = [
  { name: 'NamePrefix', type: null },
  { name: 'NameSuffix', type: null },
  { name: 'GivenName', type: null, required: true },
  { name: 'MiddleName', type: null },
  { name: 'FamilyName', type: null, required: true },

  { name: 'PatientID', type: null, required: true },

  {
    name: 'PatientBirthDate',
    type: null,
    placeholder: 'YYYMMDD',
    required: true,
  },
  { name: 'PatientBirthTime', type: null, placeholder: 'HHMMSS' },

  { name: 'PatientSex', type: null, placeholder: 'F/M/O' },
  { name: 'PatientAge', type: 'number', variant: 'filled' },
  { name: 'PatientSize', type: null, start: 'mt', variant: 'filled' },
  {
    name: 'PatientWeight',
    type: 'number',
    start: 'kg',
    variant: 'filled',
  },

  { name: 'Occupation', type: null },
  { name: 'OtherPatientNames', type: null },
  { name: 'EthnicGroup', type: null },
];
export const options_types = [
  { name: 'None', id: 0 },
  { name: 'Automatic', id: 1 },
  { name: 'Written', id: 2 },
  { name: 'Verbal', id: 2 },
];
