import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Card, Divider, Grid } from '@mui/material';
import { CardEditHeader, TableGrid } from 'components';
import { IsInvalid } from 'helpers';
import { QuerySeriesSequenceMappings } from 'graphql/Series/utils_series_sequences';
import { columns_sequence } from '../Styles';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import ShowSeriesToolbar from './ShowSeriesToolbar';
const useStyles = () => ({
  card: {
    margin: 4,
  },
});
class ShowSeriesSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      idSequence: null,
      series: [],
      total: 0,
      page: 0,
      limit: 100,
    };
  }
  componentDidMount() {
    this.reloadSequence();
  }
  componentDidUpdate() {
    this.reloadSequence();
  }
  reloadSequence = () => {
    const { idSequence: idInput } = this.props;
    if (IsInvalid(idInput)) return;
    const { idSequence } = this.state;
    if (idSequence && idInput === idSequence) return;
    this.handleLoadSequences();
  };
  handleOpenEditView = () => {
    this.setState({ idSequence: null });
    this.props.handleOpenEditView();
  };
  handleLoadSequences = (newPage = null, newLimit = null) => {
    const { idSequence } = this.props;
    let { loading, page, limit } = this.state;
    if (loading || IsInvalid(idSequence)) {
      return;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    const inputs = { ids_sequences: [idSequence] };
    (async () => {
      QuerySeriesSequenceMappings(inputs, page, limit, true)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, total, errors } = data.seriesSequenceMappings;
          if (ok) {
            this.setState({
              series: mappings.map(x => x.Series).filter(x => x),
              total,
              loading: false,
              idSequence,
              page,
              limit,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-UsersProjects', error);
          this.setState({ types: [], loading: false, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSeriesMapped = mapped => {
    const { mappings, selected } = this.state;
    console.log({ mapped });
    const ids = mapped.map(x => x.id);
    this.setState({
      mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
      selected: selected.map(x => ids.indexOf(x.id) === -1),
    });
  };
  render() {
    const { classes, row } = this.props;
    let title = 'Edit Selected Sequences';
    if (row) {
      const { Sequence } = row;
      title = `Edit Selected Sequences (${Sequence.name})`;
    }

    return (
      <Card className={classes.card}>
        <CardEditHeader handleClose={this.handleOpenEditView} title={title} />
        <Divider />
        <Grid container pt={1} spacing={1}>
          <Grid item xs={12}>
            <ShowSeriesToolbar
              handleClearSelected={() => this.setState({ selected: [] })}
              handleReloadTable={() => this.handleLoadSequences()}
              handleSeriesMapped={this.handleSeriesMapped}
              selected={this.state.selected}
            />
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={this.state.rows_edited}
              external_selected={this.state.selected}
              grid
              headers={columns_sequence}
              height={this.props.height - 50}
              idRow="idSeries"
              loading={this.state.loading}
              onPageChange={page => this.setState({ page })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              paginationMode="server"
              rows={this.state.series}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
        {/* <CardActions disableSpacing>
          <EditSequenceBottom classes={classes} />
        </CardActions> */}
      </Card>
    );
  }
}

ShowSeriesSequences.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ShowSeriesSequences, useStyles);
