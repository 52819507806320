import React from 'react';
import PropTypes from 'prop-types';
import {
  SearchText,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ServerErrorsString, GetData } from 'helpers';
import SaveIcon from '@mui/icons-material/Save';
import { withRouter } from 'react-router-dom';
import { MutationDeleteDownload } from 'graphql/Download';
import { IsInvalid, getParamsUrl } from 'helpers';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HTTP_ADDRESS_WEB } from 'config';

class ToolbarDownloads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      saving: false,
    };
  }
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { downloads, selected, total } = this.props;
    if (deleting) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.idDownload);
    (async () => {
      MutationDeleteDownload(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteDownloads;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              downloads: downloads.filter(
                x => ids.indexOf(x.idDownload) === -1
              ),
              total: total - ids.length,
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getLink = link => {
    if (IsInvalid(link)) return null;
    const { code, idFolder } = link;
    let type = 'folder';
    if (IsInvalid(idFolder)) {
      type = 'file';
    }
    return this.getAddress(`shared/${type}/${code}`);
  };
  getAddress = text => {
    let text_link = `${HTTP_ADDRESS_WEB}/${text}`;
    let page = getParamsUrl(this.props);
    if (page) {
      let real_url = window.location.href;
      real_url = real_url.replace(page, '');
      if (HTTP_ADDRESS_WEB !== real_url) {
        text_link = `${real_url}/${text}`;
      }
    }
    return text_link;
  };
  render() {
    const { rows_edited, selected, row_clicked } = this.props;
    let save = rows_edited.length || this.state.saving;
    let idFolder = null;
    let text_link = null;
    let IconLink = <LinkOffIcon style={{ color: 'gray' }} />;
    let IconOpen = <OpenInNewOffIcon style={{ color: 'gray' }} />;
    if (row_clicked) {
      let { folder_content, link } = row_clicked;
      if (folder_content) {
        if (folder_content.Folder) idFolder = folder_content.Folder.idFolder;
      } else if (row_clicked.idFolder) idFolder = row_clicked.idFolder;
      text_link = this.getLink(link);
      if (text_link) {
        IconLink = <LinkIcon style={{ color: 'green' }} />;
      }
    }
    if (idFolder) IconOpen = <OpenInNewIcon style={{ color: 'green' }} />;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSave}>
                <SaveIcon style={{ color: save ? 'green' : 'gray' }} />
              </Button>
              <Button
                disabled={!idFolder}
                onClick={() => {
                  if (idFolder)
                    window.open(
                      this.getAddress(`explorer/folder/${idFolder}`),
                      '_blank'
                    );
                }}>
                {IconOpen}
              </Button>
              <Button
                disabled={!text_link}
                onClick={() => {
                  if (text_link) window.open(text_link, '_blank');
                }}>
                {IconLink}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={code => this.props.handleReload(code)}
              id="id-search-download-key"
              loading={this.props.loading}
              placeholder="Search Download by key or content..."
            />
          </Grid>

          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Delete Shared Objects"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        {/* <DialogCreateRule
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreatedTagRule={rules => {
            this.setState({ open_create: false });
            this.props.handelAddRules(rules);
          }}
          open={this.state.open_create}
        /> */}
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the shared object, this action cannot be undone"
          title_text="Delete Selected Shared Objects"
        />
      </React.Fragment>
    );
  }
}

ToolbarDownloads.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ToolbarDownloads);
