import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import { TableSites } from 'components';
import { SearchSites, useStyles } from './components';
import { CardProjectActions, CardProjectHeader } from '../components';
import { unitsAtProject } from 'graphql/Projects/utils_project';
import { GetData, ServerErrorsString } from 'helpers';

class CardManagerSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sites: [],
      idProject: -1,
    };
  }

  componentDidMount() {
    this.runUpdateSites();
  }

  componentDidUpdate() {
    const { idProject } = this.state;
    const { project } = this.props;
    if (project.id !== idProject) {
      this.runUpdateSites(project.id);
    }
  }

  handleDeleteSites = () => {};
  runUpdateSites = id => {
    //
    const { loading } = this.state;
    if (loading) return;
    if (typeof id === 'undefined') {
      id = this.state.idProject;
    }
    this.setState({ loading: true });
    (async () => {
      unitsAtProject(id)
        .then(res => {
          const data = GetData(res);
          if (data.unitsAtProject) {
            this.setState({
              loading: false,
              idProject: id,
              sites: data.unitsAtProject,
            });
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject: id });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, maximize, show } = this.props;
    const { loading, sites } = this.state;
    if (!show && !maximize) return null;
    return (
      <Card className={classes.root}>
        <CardProjectHeader
          handleMaximize={() => this.props.handleMaximize('sites')}
          subtitle="List of project units"
          title="Units"
        />
        <Divider variant="middle" />
        <CardContent className={classes.content}>
          <Grid
            alignItems="center"
            className={classes.toolbar}
            container
            direction="column"
            justifyContent="space-evenly"
            spacing={1}>
            <SearchSites />
            <div className={classes.table}>
              <TableSites
                dense
                handleDeleteSites={this.handleDeleteSites}
                handleReloadTable={() => this.runUpdateSites()}
                loading={loading}
                rows={sites}
                title="Project units"
              />
            </div>
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <CardProjectActions loading={loading} />
      </Card>
    );
  }
}

CardManagerSites.propTypes = {
  classes: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
};
CardManagerSites.defaultProps = {
  sites: [],
  maximize: false,
  show: true,
};

export default withStyles(CardManagerSites, useStyles);
