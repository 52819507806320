import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { useStyles } from './Styles';
import { TableProjectTeams, TeamsProjectToolBar } from 'components';
import { CreateProjectTeam } from './components';
import { MutationDeleteProjectTeams } from 'graphql/Projects/utils_project';
import { GetData, ServerErrorsString } from 'helpers';

class ProjectTeamsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_create_team: false,
      deleting: false,
    };
    this.holder = {
      getSelected: () => this.props.selected,
    };
  }

  handleOpenCreate = () => {
    const { open_create_team } = this.state;
    this.setState({ open_create_team: !open_create_team });
  };
  handleAddNewProjectTeams = teams => {
    this.setState({ open_create_team: false });
    this.props.handleAddNewProjectTeams(teams);
  };
  handleDeletedTeams = () => {
    const { deleting } = this.state;
    if (deleting) return;
    const { project } = this.props;
    if (project.id <= 0) return;
    const ids = this.props.selected.map(x => x.idCenterTeam);
    if (ids.length <= 0) {
      return;
    }
    this.setState({ deleting: true });
    (async () => {
      MutationDeleteProjectTeams(project.id, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeTeamsFromProjects;
          if (ok) {
            this.props.handleDeletedTeams(this.props.selected);
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, project, loading, admin } = this.props;
    const { open_create_team } = this.state;
    if (open_create_team && admin) {
      return (
        <CreateProjectTeam
          handleAddError={this.props.handleAddError}
          handleAddNewProjectTeams={this.handleAddNewProjectTeams}
          handleClose={this.handleOpenCreate}
          project={project}
          project_teams={this.props.project_teams}
        />
      );
    }
    //
    return (
      <React.Fragment>
        <TeamsProjectToolBar
          admin={admin}
          handleOpenAddTeams={this.handleOpenAdd}
          handleOpenCreateTeam={this.handleOpenCreate}
          handleOpenEditSelected={this.props.handleOpenEditSelected}
          handleTeamSearch={this.props.handleTeamSearch}
          loading={loading}
          project_teams={this.props.project_teams}
          selected={this.props.selected}
        />
        <div className={classes.root_channels}>
          <div className={classes.paper}>
            <TableProjectTeams
              deleting={this.state.deleting}
              disable_checkbox={!admin}
              handleChangePage={this.props.handleChangePage}
              handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              handleDeleteSelected={this.handleDeletedTeams}
              handleReloadTable={() => this.props.updateTeams()}
              handleSetSelected={this.props.handleSetSelected}
              handleShowTeam={this.props.handleOpenTeamView}
              height={this.props.height - 215}
              holder={this.holder}
              idProject={project.id}
              limit={this.props.limit}
              loading={loading}
              nowrap
              page={this.props.page}
              project_teams={this.props.project_teams}
              selected={this.props.selected}
              total={this.props.total}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectTeamsView.propTypes = {
  classes: PropTypes.object,
  handleAddNewProjectTeams: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleTeamSearch: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
};
ProjectTeamsView.defaultProps = {
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  project: { id: -1 },
  handleAddError: () => '',
  selected: [],
};
export default withStyles(ProjectTeamsView, useStyles);
