import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SUMMARY_PATIENTS = gql`
  query(
    $mode: Int
    $idProject: Int
    $datestart: String
    $dateend: String
    $fullHistory: Boolean
  ) {
    SummaryPatients(
      mode: $mode
      idProject: $idProject
      datestart: $datestart
      dateend: $dateend
      fullHistory: $fullHistory
    ) {
      ok
      total
      percentage
      errors {
        path
        message
      }
    }
  }
`;

export const QuerySummaryPatients = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_PATIENTS),
    variables,
  });
};
