import React from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import {
  TableSiteUsersHeader,
  TableSiteUsersRow,
  TableSiteUsersToolbar,
} from './components';
import { headCells, useStyles } from './components/utils';
import { getSorting, SelectedArray, stableSort } from 'helpers';

const DivWrapper = ({ paper, classes, children }) => {
  if (paper) {
    return <Paper className={classes.root}>{children}</Paper>;
  } else {
    return <div>{children}</div>;
  }
};

class TableSiteUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 25,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.clearTable = () => {
        this.setSelected([]);
      };
      holder.getSelectedTable = () => {
        let selected = [];
        const { users } = this.props;
        for (let i = 0; i < users.length; i++) {
          if (this.isSelected(users[i])) {
            selected.push(users[i]);
          }
        }
        return selected;
      };
    }
  }

  setOrder = newOrder => {
    this.setState({ order: newOrder });
  };
  setOrderBy = newOrderBy => {
    this.setState({ orderBy: newOrderBy });
  };
  setSelected = newSelected => {
    this.setState({ selected: newSelected });
    this.props.handleSelected(newSelected);
  };
  setPage = newPage => {
    this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    const newOrderBy = isDesc ? 'asc' : 'desc';
    this.setOrder(newOrderBy);
    this.setOrderBy(property);
    console.log({ newOrderBy, property });
    if (property === 'account') return;
    if (property === 'address') return;
    if (property === 'FullName') property = 'firstName';
    this.props.onOrderByChanged(property, newOrderBy);
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      // const newSelecteds = this.props.users.map(x => getRow(x));
      this.setSelected(this.props.users);
    } else {
      this.setSelected([]);
    }
  };

  handleSelected = row => {
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.firstName}`)
      .indexOf(`${row.id}_${row.firstName}`);
    const newSelected = SelectedArray(selected, row, index);
    this.setSelected(newSelected);
  };

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  handleChangeDense = event => {
    this.setDense(event.target.checked);
  };
  isSelected = user => {
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.firstName}`)
      .indexOf(`${user.id}_${user.firstName}`);

    return index !== -1 ? true : false;
  };
  handleClearSelected = () => {
    this.setSelected([]);
  };
  render() {
    const {
      classes,
      height,
      users,
      loading,
      deleting,
      disable_splice,
      hide_toolbar,
    } = this.props;
    let { dense, selected, order, orderBy, rowsPerPage, page } = this.state;
    let emptyRows = 5 - users.length;
    if (emptyRows < 1) {
      emptyRows = 0;
    }
    if (this.props.page) {
      page = this.props.page;
    }
    if (this.props.limit) {
      rowsPerPage = this.props.limit;
    }
    // console.log({ p: this.props.page, l: this.props.limit, page, rowsPerPage });
    if (disable_splice) {
      page = 0;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    if (this.props.dense) {
      dense = true;
    }
    let ComponentToolBar = null;
    if (!hide_toolbar) {
      ComponentToolBar = (
        <TableSiteUsersToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={this.props.title}
        />
      );
    }
    return (
      <DivWrapper classes={classes} paper={this.props.paper}>
        {ComponentToolBar}
        <Divider />
        <div className={classes.tableWrapper} style={style}>
          <Table
            aria-label="sticky table"
            className={classes.table}
            size={dense ? 'small' : 'medium'}
            stickyHeader>
            <TableSiteUsersHeader
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              onRequestSort={this.handleRequestSort}
              onSelectAllClick={this.handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={users.length}
            />
            <TableBody>
              {loading ? (
                <TableRow style={{ height: (dense ? 33 : 53) * 4 }}>
                  <TableCell colSpan={headCells.length + 1}>
                    <Grid
                      alignItems="center"
                      container
                      direction="row"
                      item
                      justifyContent="center"
                      xs={12}>
                      <CircularProgress />
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(users, getSorting(order, orderBy, headCells))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(row);
                    return (
                      <TableSiteUsersRow
                        dense={dense}
                        handleEditUser={this.props.handleOpenEditUser}
                        handleSelected={this.handleSelected}
                        isItemSelected={isItemSelected}
                        key={`row-table-user-site-${index}`}
                        labelId={`table-checkbox-user-${index}`}
                        row={row}
                      />
                    );
                  })
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 70) * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </DivWrapper>
    );
  }
}

TableSiteUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  disable_splice: PropTypes.bool,
  paper: PropTypes.bool,
};
TableSiteUsers.defaultProps = {
  users: [],
  paper: true,
  disable_splice: false,
  onOrderByChanged: () => '',
  title: 'Site Users',
  dense: false,
  hide_toolbar: false,
};
export default withStyles(TableSiteUsers, useStyles);
