import gql from 'graphql-tag';

export const chunkSize = 500 * 1024 * 1024; // Define the chunk size (100MB in this example)
export const max_file_size = 1.4 * 1024 * 1024 * 1024; // max file size 1.5G
const upload_data = `
  ok
  errors {
    path
    message
  }
  warnings {
    path
    message
  }
  contents {
    idFolderContent
    idFolder
    Owner {
      id
      FullName
    }
    Rights {
      idRight
    }
    File {
      idFileObject
      original_name
      mimetype
      Size
      Thumbnail
      updatedAt
      createdAt
    }
    Series {
      idSeries
      idStudy
      Patient {
        PatientName
        PatientBirthDate
      }
      Study {
        StudyDescription
        StudyInstanceUID
        AccessionNumber
      }
      ImageSize {
        Size
      }
      ImageResolution {
        Spacing
      }
      Size
      SeriesDescription
      SeriesInstanceUID
      NumberOfSeriesRelatedInstances
      SeriesDate
      SeriesTime
      StationName
      Modality
      Thumbnail
      createdAt
      updatedAt
    }
    createdAt
  }
`;
export const MUTATION_FILE_UPLOAD = gql`
  mutation($file: Upload!, $settings: InputUploadSetting) {
    uploadFile(file: $file, settings: $settings) {
      ${upload_data}
    }
  }
`;

export const MUTATION_FILE_CHUNK_UPLOAD = gql`
  mutation($file: Upload!, $input: InputChunk) {
    uploadChunk(file: $file, input: $input) {
      ok
      errors {
        message
      }
      key
    }
  }
`;
export const MUTATION_CHUNK_FILE_UPLOAD_COMBINE = gql`
  mutation($key: String!, $settings: InputUploadSetting) {
    combineChunks(key: $key, settings: $settings) {
      ${upload_data}
    }
  }
`;
