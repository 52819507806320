import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_DELETE_SERIES_VAR_MAP = gql`
  mutation($ids: [LongLong!]!) {
    deleteSeriesTypes(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteSeriesTypes = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SERIES_VAR_MAP),
    variables: {
      ids,
    },
  });
};
const MUTATION_UPDATE_SERIES_VAR_MAP = gql`
  mutation($idSeriesMap: LongLong!, $idType: LongLong!) {
    updateSeriesTypes(idSeriesMap: $idSeriesMap, idType: $idType) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateSeriesTypes = (idSeriesMap, idType) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SERIES_VAR_MAP),
    variables: {
      idSeriesMap,
      idType,
    },
  });
};
const MUTATION_UPDATE_SERIES_VAR_MAPS = gql`
  mutation($maps: [InputSeriesType!]!) {
    mapSeriesTypes(maps: $maps) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSeries
        idType
        name
      }
    }
  }
`;
export const MutationUpdateSeriesMapTypes = maps => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SERIES_VAR_MAPS),
    variables: {
      maps,
    },
  });
};

const QUERY_TYPES_AT_SERIES = gql`
  query($idSeries: LongLong!, $page: Int, $limit: Int) {
    typesAtSeries(idSeries: $idSeries, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSeries
        idType
        name
        type {
          id
          name
          description
        }
        series {
          idSeries
          SeriesDescription
          ImageMr {
            SequenceName
            ProtocolName
            MRAcquisitionType
          }
          sequence {
            idSequence
            name
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryTypesAtSeries = (idSeries, page, limit) => {
  console.log('QueryTypesAtSeries', { idSeries, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_TYPES_AT_SERIES),
    variables: {
      idSeries,
      page,
      limit,
    },
  });
};

const QUERY_SERIES_AT_TYPES = gql`
  query($idType: LongLong!, $names: [String!], $page: Int, $limit: Int) {
    seriesAtType(idType: $idType, names: $names, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSeries
        idType
        name
        type {
          id
          name
        }
        series {
          idSeries
          SeriesDescription
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySeriesAtTypes = (idType, names, page, limit) => {
  console.log('QuerySeriesAtTypes', {
    idType,
    names,
    page,
    limit,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_AT_TYPES),
    variables: {
      idType,
      names,
      page,
      limit,
    },
  });
};
