import { IsInvalid } from './utils';
import { FormatSeriesDate, FormatSeriesTime } from './utils_dicom';
import { GetThumbnailFile } from 'helpers';

export const getStudyPatientName = Study => {
  if (IsInvalid(Study)) return '';
  const { Patient } = Study;
  return getPatientName(Patient);
};
export const getPatientName = (Patient, name_last = true) => {
  if (IsInvalid(Patient)) return '';
  let { PatientName, FamilyName, GivenName } = Patient;
  if (PatientName && PatientName !== '') return PatientName;
  PatientName = '';
  if (
    GivenName !== 'Empty' &&
    (FamilyName === 'Empty' ||
      FamilyName === undefined ||
      FamilyName === '' ||
      !FamilyName)
  )
    PatientName = GivenName;
  if (
    FamilyName !== 'Empty' &&
    (GivenName === 'Empty' ||
      GivenName === undefined ||
      GivenName === '' ||
      !GivenName)
  )
    PatientName = FamilyName;
  if (
    FamilyName &&
    FamilyName !== 'Empty' &&
    FamilyName !== '' &&
    GivenName &&
    GivenName !== 'Empty' &&
    GivenName !== ''
  ) {
    if (name_last) PatientName = `${GivenName} ${FamilyName}`;
    else PatientName = `${FamilyName}, ${GivenName}`;
  }
  return PatientName;
};
export const getStudyPatientID = Study => {
  if (IsInvalid(Study)) return '';
  const { Patient } = Study;
  if (IsInvalid(Patient)) return '';
  const { PatientID } = Patient;
  return PatientID;
};
export const getStudyDate = (Study, format) => {
  if (IsInvalid(Study)) return '';
  const { StudyDate } = Study;
  if (typeof format === 'undefined') {
    format = 'MMMM Do YYYY';
  }
  return FormatSeriesDate(StudyDate, format);
};
export const getStudyTime = Study => {
  if (IsInvalid(Study)) return '';
  const { StudyTime } = Study;
  return FormatSeriesTime(StudyTime, 'h:mm a');
};
export const getStudySeriesThumbnail = Series => {
  if (IsInvalid(Series)) return '/images/icons/dcm.png';
  let thumbnail = GetThumbnailFile('file.dcm');
  if (Series && Series.Modality === 'SR') {
    thumbnail = '/images/icons/icon_sr.png';
  } else if (Series && Series.Modality === 'PR') {
    thumbnail = '/images/icons/icon_pr.png';
  } else if (Series.Thumbnail && Series.Thumbnail !== '') {
    thumbnail = `data:image/jpeg;base64,${Series.Thumbnail}`;
  }
  return thumbnail;
};
