import { Model } from 'survey-core';

import { surveyNeuro, surveyIC, surveyPatInfo, surveyMC } from 'common/jsons';

const SurveyService = {
  getJsonStructure: survey => {
    let jsondata = undefined;
    switch (survey) {
      case 'surveyNeuro':
        jsondata = surveyNeuro;
        break;
      case 'surveyIC':
        jsondata = surveyIC;
        break;
      case 'surveyPatInfo':
        jsondata = surveyPatInfo;
        break;
      case 'surveyMC':
        jsondata = surveyMC;
        break;

      default:
        jsondata = '{}';
    }
    return jsondata;
  },
  getRawSurveyStructure: survey => {
    let jsondata = undefined;
    switch (survey) {
      case 'surveyNeuro':
        jsondata = surveyNeuro;
        break;
      case 'surveyIC':
        jsondata = surveyIC;
        break;
      case 'surveyPatInfo':
        jsondata = surveyPatInfo;
        break;
      case 'surveyMC':
        jsondata = surveyMC;
        break;

      default:
        jsondata = '{}';
    }
    return new Model(jsondata);
  },
};

export default SurveyService;
