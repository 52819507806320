import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, Icon } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Replay as ReloadIcon } from '@mui/icons-material';
import { IconButtonLoad } from 'components';
class CardHeaderPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { small, loading } = this.props;
    let style = null;
    if (small) {
      style = {
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
      };
    }
    return (
      <CardHeader
        action={
          <Grid container sx={{ paddingTop: small ? 1 : null }}>
            <IconButtonLoad
              disabled={loading}
              handleClick={() => this.props.handleReload()}
              icon={<ReloadIcon />}
              loading={loading}
              tooltip="Reload Survey"
            />
          </Grid>
        }
        avatar={
          <Icon style={{ fontSize: 55 }}>
            <ContactMailIcon style={{ fontSize: 55, color: '#14B495' }} />
          </Icon>
        }
        style={style}
        subheader={this.props.description}
        title={this.props.title}
      />
    );
  }
}

CardHeaderPatient.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
  show_reload: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip_clear: PropTypes.string,
};
CardHeaderPatient.defaultProps = {
  handleReload: () => '',
  handleClose: () => '',
  description: 'Modify all the necessary field and press save to update',
  loading: false,
  show_reload: false,
  tooltip_clear: 'Reload',
  small: true,
};

export default CardHeaderPatient;
