import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_FOLDER_CONTENT = gql`
  query($idFolderContent: LongLong!) {
    folderContent(idFolderContent: $idFolderContent) {
      ok
      folder_content {
        idFolderContent
        Owner {
          id
          FullName
        }
        Series {
          idSeries
          idStudy
          SeriesDescription
          Thumbnail
          Size
          createdAt
          updatedAt
        }
        File {
          idFileObject
          original_name
          mimetype
          Size
          Thumbnail
          updatedAt
          createdAt
        }
        Rights {
          idRight
          write
          download
          delete
          read
          full
          createdAt
          updatedAt
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryFolderContent = idFolderContent => {
  console.log('QueryFolderContent', { idFolderContent });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FOLDER_CONTENT),
    variables: { idFolderContent },
  });
};
const QUERY_FOLDER_CONTENT_METADATA = gql`
  query($idFolderContent: LongLong!) {
    folderContent(idFolderContent: $idFolderContent) {
      ok
      folder_content {
        idFolderContent
        rating
        Owner {
          id
          FullName
        }
        project {
          id
          name
        }
        Series {
          idSeries
          idStudy
          SeriesDescription
          Thumbnail
          Size
          createdAt
          updatedAt
        }
        File {
          idFileObject
          original_name
          mimetype
          Size
          Thumbnail
          updatedAt
          createdAt
          metadata {
            id
            idUser
            idFileObject

            notes
            comments
            information
            creationTime
            name

            group_mappings {
              id
              idGroup
              group {
                name
              }
              name
            }
            updatedAt
            createdAt
          }
        }
        Rights {
          idRight
          write
          download
          delete
          read
          full
          createdAt
          updatedAt
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryFolderContentWithMetadata = idFolderContent => {
  console.log('QueryFolderContentWithMetadata', { idFolderContent });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FOLDER_CONTENT_METADATA),
    variables: { idFolderContent },
  });
};

const QUERY_FOLDER_CONTENTS = gql`
  query($inputs: [InputTypeFolderContent!]!) {
    folderContents(inputs: $inputs) {
      ok
      folder_contents {
        idFolderContent
        Owner {
          id
          FullName
        }
        Series {
          idSeries
          Size
        }
        File {
          idFileObject
          Size
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryFolderContents = inputs => {
  console.log('QueryFolderContents', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FOLDER_CONTENTS),
    variables: { inputs },
  });
};
