import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton, Tooltip } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { v1 as uuid } from 'uuid';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = (theme, props) => ({
  horizontal_bottom: {
    '& .MuiBadge-badge': {
      left: -8,
      top: 13,
    },
  },
  color: {
    color: 'white',
    backgroundColor: props.color !== 'red' ? props.color : 'red',
  },
});
class IconButtonBadge extends Component {
  render() {
    const {
      classes,
      size,
      Icon,
      className,
      hide,
      color,
      horizontal,
    } = this.props;
    if (hide) return null;
    let classBadge = { color };
    if (color !== 'error') {
      classBadge = { classes: { badge: classes.color } };
    }
    let style = null;
    if (this.props.color_icon) {
      style = { color: this.props.color_icon };
    }
    const defaultProps = {
      anchorOrigin: {
        vertical: this.props.vertical,
        horizontal,
      },
      ...classBadge,
      children: <Icon style={style} />,
    };
    return (
      <Tooltip title={this.props.title}>
        <span style={{ margin: 0, padding: 0 }}>
          <IconButton
            aria-controls={`iconbutotn-series-${uuid()}`}
            className={className}
            onClick={this.props.onClick}
            size={this.props.size_buttom}>
            <Badge
              badgeContent={size}
              {...defaultProps}
              className={clsx({
                [classes.horizontal_bottom]: horizontal === 'bottom',
              })}
            />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
}

IconButtonBadge.propTypes = {
  Icon: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string,
  hide: PropTypes.bool,
  horizontal: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
  title: PropTypes.string,
  vertical: PropTypes.string,
};
IconButtonBadge.defaultProps = {
  color: 'error',
  color_icon: null,
  hide: false,
  className: null,
  size: 0,
  title: 'Unknown',
  onClick: () => '',
  Icon: BorderColorIcon,
  vertical: 'top',
  horizontal: 'left',
  size_buttom: 'large',
};

export default withStyles(IconButtonBadge, useStyles);
