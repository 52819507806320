import React from 'react';
import PropTypes from 'prop-types';
import { Survey } from 'survey-react-ui';
import { createSurvey, GetSteps } from 'helpers/Surveys';
import { CardHeaderCreate } from './components';
import { MutationCreateSurvey } from 'graphql/Surveys';
import moment from 'moment';
import { Divider, Paper } from '@mui/material';
import { SurveyStepper, defaultSurvey } from 'components';
import { GetData } from 'helpers';
import { FormatTime } from 'helpers';
class CreateNewSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultSurvey,
      saving: false,
    };
  }
  componentDidMount() {
    const { currentPatient } = this.props;
    const reply = createSurvey(
      { surveyType: 'surveyNeuro', survey: null },
      undefined,
      currentPatient.id,
      this.handleSave,
      this.handleBackToOverview
    );
    this.setState({
      survey_json: reply.survey_json,
      currentSurvey: reply.survey,
      buttonSave: reply.buttonSave,
      buttonCancel: reply.buttonCancel,
      value_changed: [],
    });
  }
  handleSave = (sender, complete) => {
    console.log({ sender, complete });
    const { saving } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      MutationCreateSurvey(sender, complete)
        .then(res => {
          const data = GetData(res);
          const { ok, survey, errors } = data.createSurvey;
          if (ok) {
            this.setState({ saving: false });
            this.props.handleAddNewSurvey(survey);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ saving: false });
        });
    })();
  };
  handleBackToOverview = () => {
    this.props.handleClose();
  };
  render() {
    const { currentSurvey, currentPageNo } = this.state;
    let { height } = this.props;
    const createdAt = moment().valueOf();
    let style = null;
    if (height) {
      height = height - 175;
      style = {
        height,
        maxHeight: height,
        overflow: 'auto',
      };
    }
    const json = GetSteps('surveyNeuro');
    let steps = [];
    let pages = [];
    if (json && json.pages) {
      pages = json.pages;
      steps = json.pages.map((x, index) => index);
    }
    if (currentSurvey) {
      currentSurvey.currentPageNo = currentPageNo;
    }
    return (
      <Paper>
        <CardHeaderCreate
          description={'Created: ' + FormatTime(createdAt, 'MMM Do, YYYY')}
          handleClose={this.props.handleClose}
          title="Create New Survey"
        />
        <Divider />
        <SurveyStepper
          activeStep={this.state.currentPageNo}
          buttonCancel={this.state.buttonCancel}
          buttonSave={this.state.buttonSave}
          currentSurvey={currentSurvey}
          handleBack={() => this.setState({ currentPageNo: currentPageNo - 1 })}
          handleNext={() => this.setState({ currentPageNo: currentPageNo + 1 })}
          handleSaved={() => this.setState({ value_changed: [] })}
          isChanged
          isFirstPage={this.state.isFirstPage}
          isLastPage={this.state.isLastPage}
          maxSteps={steps.length}
          pages={pages}>
          <div style={style}>
            {currentSurvey && <Survey model={currentSurvey} />}
          </div>
        </SurveyStepper>
      </Paper>
    );
  }
}

CreateNewSurvey.propTypes = {
  height: PropTypes.number,
};

export default CreateNewSurvey;
