import { grey } from '@mui/material/colors';

const useStyles = theme => ({
  table: {
    height: '100%',
    tableLayout: 'fixed',
    // width: '100%',
    padding: theme.spacing(2),
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
  tableCell: {
    maxHeight: 20,
    margin: 0,
    padding: 0,
  },
  margin_right: {
    paddingRight: 15,
  },
  tableRow: {
    height: 33,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'blue',
        fontWeight: 500,
      },
    },
  },
  empty: {},
  download: {
    padding: 0,
    margin: 0,
  },
});

export default useStyles;
