import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Table, TableBody } from '@mui/material';
import {
  MainTableRowEmpty,
  MainTableRowLoading,
} from 'components/Tables/components';
import { MainTableUserHeaders } from 'components/Tables/components/MainTable/components';
import MainTableRowLogError from '../MainTableRowLogError';
import { getSorting, stableSort } from 'helpers';

class MainTableLogErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'name',
      row_over: -1,
    };
  }

  handleRowOver = row => {
    const { row_over } = this.state;
    if (row_over !== row) {
      this.setState({ row_over: row });
    }
  };
  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({ order: isDesc ? 'asc' : 'desc' });
    this.setState({ orderBy: property });
  };

  render() {
    const {
      classes,
      selected,
      loading,
      columns,
      rows,
      dense,
      limit,
      disable_splice,
      expand_disable,
    } = this.props;
    const { order, orderBy } = this.state;
    let { page } = this.props;
    let emptyRows = 3 - rows.length;
    if (emptyRows < 0) emptyRows = 0;
    if (disable_splice) {
      page = 0;
    }
    const total_limit = page * limit + limit;
    const start_limit = page * limit;
    return (
      <Table
        aria-label="sticky table"
        className={clsx({
          [classes.nowrap]: this.props.nowrap,
        })}
        size={dense ? 'small' : 'medium'}
        stickyHeader>
        <MainTableUserHeaders
          add_space={!expand_disable}
          classes={classes}
          headCells={columns}
          loading={loading}
          numSelected={selected.length}
          onRequestSort={this.handleRequestSort}
          onSelectAllClick={this.props.handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={rows.length}
        />
        <TableBody>
          {loading ? (
            <MainTableRowLoading colSpan={columns.length + 2} dense={dense} />
          ) : (
            <React.Fragment>
              {stableSort(rows, getSorting(order, orderBy, columns))
                .slice(start_limit, total_limit)
                .map((row, index) => {
                  const isItemSelected = this.props.isSelected(row);
                  return (
                    <MainTableRowLogError
                      // classes={classes}
                      columns={columns}
                      dense={dense}
                      expand_disable={expand_disable}
                      handleClick={this.props.handleSelectedClick}
                      index={index}
                      isItemSelected={isItemSelected}
                      key={`table-base-row-${index}`}
                      row={row}
                    />
                  );
                })}
              <MainTableRowEmpty
                colSpan={columns.length + 2}
                dense={dense}
                emptyRows={emptyRows}
              />
            </React.Fragment>
          )}
        </TableBody>
      </Table>
    );
  }
}

MainTableLogErrors.propTypes = {
  classes: PropTypes.object,
};
MainTableLogErrors.defaultProps = {
  columns: [],
  isSelected: () => false,
  nowrap: false,
};
export default MainTableLogErrors;
