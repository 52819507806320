import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

import { TableGrid } from 'components';
import { ToolBarAnonySeries } from './components';
import {
  copySeries,
  copyJobSeries,
} from 'redux-store/actions/file_folder_manager';
import { rowsPerPageBig } from 'helpers';
import { columns_series } from 'common';

class TabSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      page: 0,
      limit: 50,
    };
  }
  handleDeleteSelected = () => {
    let { jobs_series } = this.props;
    const { selected } = this.state;
    const ids = selected.map(x => x.idSeries);
    jobs_series = jobs_series.filter(x => ids.indexOf(x.idSeries) === -1);
    this.setState({ selected: [] });
    this.props.copyJobSeries(jobs_series);
  };
  render() {
    const { height, jobs_series } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ToolBarAnonySeries
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={this.handleDeleteSelected}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item sx={{ paddingTop: 1 }} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_series}
            height={height - 240}
            idRow="idSeries"
            limit={this.state.limit}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            rows={jobs_series}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabSeries.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};
const mapStateToProps = state => {
  const { jobs_series } = state.manager;
  return {
    jobs_series: jobs_series ? jobs_series : [],
    size: jobs_series ? jobs_series.length : 0,
  };
};

export default connect(mapStateToProps, {
  copySeries,
  copyJobSeries,
})(TabSeries);
