import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_FILE_METADATA = gql`
  mutation($inputs: [InputFileMetadata!]!) {
    updateMetadata(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateFileMetadata = metadata => {
  const inputs = [];
  if (metadata) {
    inputs.push({ idFileObject: metadata.idFileObject });
    if (metadata.notes) inputs[0].notes = metadata.notes;
    if (metadata.comments) inputs[0].comments = metadata.comments;
    if (metadata.information) inputs[0].information = metadata.information;
    if (metadata.creationTime) inputs[0].creationTime = metadata.creationTime;
    if (metadata.group_mappings) {
      inputs[0].mappings = metadata.group_mappings.map(x => {
        const { id, idGroup, name } = x;
        return {
          id,
          idGroup,
          name,
        };
      });
    }
    if (metadata.name) inputs[0].name = metadata.name;
  }
  console.log('MutationUpdateFileMetadata', {
    metadata,
    input: inputs.length ? inputs[0] : null,
  });
  return MutationUpdateMultiFileMetadata(inputs);
};
export const MutationUpdateMultiFileMetadata = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_FILE_METADATA),
    variables: { inputs },
  });
};
const QUERY_FILE_METADATA = gql`
  query($idFileObject: LongLong!) {
    fileMetadata(idFileObject: $idFileObject) {
      ok
      errors {
        path
        message
      }
      metadata {
        id
        idUser
        idFileObject

        notes
        comments
        information
        creationTime
        name

        group_mappings {
          id
          idGroup
          group {
            name
          }
          name
        }
        project {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryFileMetadata = idFileObject => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_METADATA),
    variables: { idFileObject },
  });
};
