/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  CheckBoxText,
  DialogDeleteWarning,
  SearchText,
  DialogShowUploadInfo,
} from 'components';
import DialogViewClinicalDataCase from 'components/Dialogs/DialogViewClinicalDataCase';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import Zoom from '@mui/material/Zoom';

import {
  MutationAddStroke,
  GetAddResponse,
  MutationUploadStroke,
  GetUploadResponse,
  MutationRemoveStroke,
  GetRemoveResponse,
} from './utils_stroke';
import {
  IsAdministrator,
  IsInvalid,
  IsValid,
  ServerErrorsString,
  GetData,
  getParams,
} from 'helpers';
import {
  cases,
  encounter,
  followup,
  treatment,
  procedure,
} from './utils_columns';
import { getColumnList } from './utils_columns';

import { labels, titleCase } from '../../../components/utils_text';

import { searchStrokeTables } from '../components/utils_stroke';
import DialogViewClinicalPatient from 'components/Dialogs/DialogViewClinicalPatient';

const children = [
  { name: 'ssr_patient', label: 'PATIENTS' },
  { name: 'ssr_cases', label: 'CASES' },
  { name: 'ssr_followup', label: 'FOLLOWUP' },
  { name: 'ssr_treatment', label: 'TREATMENT' },
  { name: 'ssr_thrombo_procedure', label: 'THROMBOLYSIS' },
  { name: 'ssr_encounters', label: 'ENCOUNTER' },
];

class StrokeToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: 'ssr_patient',
      open_delete: false,
      uploading: false,
      open_create: false,
      nowrap: false,
      deleting: false,
      searching: false,
    };
    this.holder = {
      getSearchText: null,
      open_upload_info: false,
      cleanText: null,
    };
  }
  componentDidMount() {
    const { level_1, level_2 } = getParams(this.props);
    let flag = true;
    const index = children.map(x => x.name).indexOf(level_2);
    if (index !== -1) {
      this.props.setState({ table: index });
      this.setState({ table: level_2 });
      this.props.handleReload(children[index].name, null, null);
      flag = false;
    }
    if (flag && level_1 === labels.studies.stroke) {
      this.props.history.push(`/clinical_data/stroke/${children[0].name}`);
      this.props.setState({ table: 0 });
      this.setState({ table: children[0].name });
      this.props.handleReload(children[0].name, null, null);
    }
  }
  handleDeleteSelected = () => {
    let { selected, new_rows, total, index_table, rows } = this.props;
    const new_selected = selected.filter(x => x.id < 0);
    if (new_selected.length) {
      for (let i = 0; i < new_selected.length; i++) {
        const { id } = new_selected[i];
        const index = new_rows[index_table].map(x => x.id).indexOf(id);
        if (index === -1) continue;
        new_rows[index_table].splice(index, 1);
      }
      this.props.setState({ new_rows });
    }
    selected = selected.filter(x => x.id >= 0);
    const { deleting } = this.state;
    if (deleting || !selected.length) {
      this.setState({ open_delete: false });
      this.props.setState({ selected });
      return;
    }
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    const { table } = this.state;
    (async () => {
      MutationRemoveStroke(table, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = GetRemoveResponse(table, data);
          if (ok) {
            this.setState({ deleting: false, open_delete: false });

            this.props.setState({
              rows: rows.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveChanges = () => {
    let { add_rows, rows_edited, rows } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      rows_edited = [];
    }
    if (IsInvalid(add_rows) || !add_rows.length) {
      add_rows = [];
    }
    for (let i = 0; i < rows_edited.length; i++) {
      const element = rows_edited[i];
      add_rows = add_rows.filter(row => row.id !== element.id);
    }
    add_rows = add_rows.concat(rows_edited);
    if (!add_rows.length) {
      this.props.handleAddError(labels.errors.nothingToSave);
      return;
    }
    const { saving, table } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    const inputs = add_rows.map(x => {
      const {
        createdAt,
        user,
        UniqueKeyIdentifier,
        address,
        updatedAt,
        id,
        patient,
        ...res
      } = x;
      if (IsValid(patient)) {
        return {
          idPatient: patient.idPatient,
          PatientID: patient.PatientID,
          ...res,
        };
      } else {
        return {
          id,
          ...res,
        };
      }
    });
    (async () => {
      MutationAddStroke(table, inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rew_rows, total } = GetAddResponse(table, data);
          if (ok) {
            rows_edited = [];
            this.setState({ saving: false });
            for (let i = 0; i < rew_rows.length; i++) {
              const { id } = rew_rows[i];
              const index = rows.map(x => x.id).indexOf(id);

              if (index === -1) {
                rows.unshift(rew_rows[i]);
              } else {
                rows[index] = {
                  ...rew_rows[i],
                };
              }
            }
            const updatedRows = rows.map(row => {
              const edited = add_rows.find(edit => edit.id === row.id);
              return edited ? { ...row, ...edited } : row;
            });

            this.props.setState({
              add_rows: [],
              table_rows_edited: [],
              rows: updatedRows,
              total,
              selected: [],
            });

            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { uploading } = this.state;
    // const { setState, loading, page, limit, rows } = this.props;

    const { files } = event.target;
    if (uploading) return;
    if (!files.length) {
      this.props.handleAddError(labels.errors.fileNotSelected);
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadStroke('all', files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = GetUploadResponse('all', data);
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.history.push(
              `/clinical_data/stroke/${children[0].name}`
            );
            this.setState({ table: children[0].name });
            this.props.setState({ table: 0, loading: false });
            this.props.handleReload(children[0].name, null, null);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  extractResult = data => {
    let result = null;
    if (data.searchStoke && data.searchStoke.cases) {
      const { ok, errors, cases, total } = data.searchStoke;
      result = { ok, errors, results: cases, total };
    } else if (data.searchStoke && data.searchStoke.followups) {
      const { ok, errors, followups, total } = data.searchStoke;
      result = { ok, errors, results: followups, total };
    } else if (data.searchStoke && data.searchStoke.encounters) {
      const { ok, errors, encounters, total } = data.searchStoke;
      result = { ok, errors, results: encounters, total };
    } else if (data.searchStoke && data.searchStoke.treatments) {
      const { ok, errors, treatments, total } = data.searchStoke;
      result = { ok, errors, results: treatments, total };
    } else if (data.searchStoke && data.searchStoke.procedures) {
      const { ok, errors, procedures, total } = data.searchStoke;
      result = { ok, errors, results: procedures, total };
    }
    return result;
  };

  searchStroke = (text = null) => {
    const { page, limit } = this.props;
    const { searching, table } = this.state;
    if (searching) return;
    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    this.setState({ searching: true });
    (async () => {
      searchStrokeTables(text, table, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, results, total } = this.extractResult(data);
          if (ok) {
            this.props.setState({
              rows: results,
              total,
              page: page,
              limit: limit,
            });
            this.setState({ searching: false });
          } else throw errors;
        })
        .catch(error => {
          this.setState({ searching: false });
          throw error;
        });
    })();
  };
  handleEncryptedMapCreated = mappings => {
    this.setState({ open_create: false });
    this.props.handleCreatedMappings(mappings);
  };
  handleChange = (event, table) => {
    const { loading } = this.props;
    if (loading) return;
    if (IsInvalid(table)) return;
    this.setState({ table });
    this.props.setState({
      selected: [],
      table: children.map(x => x.name).indexOf(table),
    });
    if (this.holder.cleanText) {
      this.holder.cleanText();
    }
    this.props.history.push(`/clinical_data/stroke/${table}`);
    this.props.handleReload(table, null, null);
  };
  getSelectedIds = () => {
    const { selected } = this.props;
    const { table } = this.state;
    let ids = [];
    selected.forEach(element => {
      const { id, idCase } = element;
      if (IsValid(idCase) && table !== labels.tables.stroke.ssrCases) {
        ids.push(idCase);
      } else if (IsValid(id)) {
        ids.push(id);
      }
    });
    return ids;
  };

  getDeleteText = (table, isShortVersion) => {
    const record = this.props.selected.length > 1 ? 'records' : 'record';
    const tableName = children.map(child => {
      if (child.name === table) {
        return child.label.toUpperCase();
      }
    });
    if (isShortVersion) {
      return `${tableName.join(' ')} (${this.props.selected.length})`;
    } else {
      return `${tableName.join(' ')} (${this.props.selected.length} ${record})`;
    }
  };

  render() {
    const { selected, row_clicked, rows_edited, anonymize } = this.props;
    const { table } = this.state;
    const control = {
      value: table,
      onChange: this.handleChange,
      exclusive: true,
    };
    const { level_1 } = getParams(this.props);
    const confirmationTitle = `${labels.delete.confirmation.title} ${titleCase(
      level_1
    )} ${this.getDeleteText(table, false)}`;
    const confirmationText1 = `${labels.delete.confirmation.text1} ${titleCase(
      level_1
    )} ${this.getDeleteText(table, false)}`;
    const confirmationText2 = `${labels.delete.confirmation.text2}`;
    const columns = {
      cases,
      encounter,
      followup,
      treatment,
      procedure,
    };
    return (
      <React.Fragment>
        <Grid container direction="column">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            pb={0.5}
            pt={0.5}>
            <Grid item>
              <ToggleButtonGroup
                size="small"
                {...control}
                aria-label="Small sizes">
                {children.map(x => (
                  <ToggleButton key={x.name} value={x.name}>
                    {x.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <ButtonGroup exclusive="false" size="small" value="save">
                {IsAdministrator && (
                  <Tooltip
                    placement="top"
                    title={anonymize ? 'De-anonymize' : 'Anonymize'}
                    TransitionComponent={Zoom}>
                    <Button onClick={this.props.handleToggleAnonymize}>
                      {anonymize ? (
                        <LockIcon title="Anonymize" />
                      ) : (
                        <NoEncryptionIcon />
                      )}
                    </Button>
                  </Tooltip>
                )}
                {(table === labels.tables.stroke.ssrCases ||
                  table === labels.tables.stroke.SsrPatient) && (
                  <Button
                    disabled={IsInvalid(row_clicked)}
                    onClick={() => this.setState({ open_view: true })}>
                    <VisibilityIcon />
                  </Button>
                )}
                <Button
                  disabled={!rows_edited.length}
                  onClick={this.handleSaveChanges}>
                  <SaveIcon
                    style={{ color: rows_edited.length ? 'green' : 'gray' }}
                  />
                </Button>
                <ButtonDownloadType
                  columns={getColumnList()}
                  inputs={{ ids: this.getSelectedIds() }}
                  key="clinical_stroke"
                  setState={state => this.setState(state)}
                  studyName="Stroke"
                  table={table}
                  type={'clinical_stroke'}
                />
                <ButtonFileUpload
                  handleSelectFile={this.handleUploadSelectFile}
                  uploading={this.state.uploading}
                />
                <Tooltip placement="right" title="Upload file instructions">
                  <Button
                    onClick={() => this.setState({ open_upload_info: true })}>
                    <HelpOutlineIcon style={{ color: 'green' }} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
            {table !== 'ssr_patient' &&
              table !== 'ssr_treatment' &&
              table !== 'ssr_encounters' && (
                <Grid item xs={3}>
                  <CheckBoxText
                    checked={this.props.nowrap}
                    label="Wrap"
                    onChange={this.props.handleChangeCheck}
                    size="small"
                  />
                </Grid>
              )}
            <Grid item>
              <ButtonReloadDelete
                handleClearSelected={this.props.handleClearSelected}
                handleDeleteSelected={() =>
                  this.setState({ open_delete: table !== 'ssr_patient' })
                }
                handleReloadTable={this.handleReload}
                loading={this.state.deleting}
                loading_table={this.props.loading}
                numSelected={selected.length}
                show_delete={table !== 'ssr_patient'}
                small
                tooltip="Reload rules"
                wrapper={{ margin: 0 }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item style={{ marginBottom: '5px' }} xs={5}>
              {table !== 'ssr_patient' && (
                <SearchText
                  handleSearchText={this.searchStroke}
                  holder={this.holder}
                  loading={this.state.searching}
                  placeholder={'Search ' + table.slice(4, table.length)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`${confirmationText1} ${confirmationText2}`}
          title_text={confirmationTitle}
        />
        <DialogShowUploadInfo
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_upload_info: false })}
          open={this.state.open_upload_info}
          studyName={'Stroke'}
          type={'clinical_stroke'}
        />
        <DialogViewClinicalDataCase
          clinical_case={row_clicked}
          columns={columns}
          handleClose={() => this.setState({ open_view: false })}
          history={this.props.history}
          open={this.state.open_view && table === labels.tables.stroke.ssrCases}
          project={
            table !== labels.tables.stroke.SsrPatient
              ? labels.studies.stroke
              : 'patient'
          }
        />
        <DialogViewClinicalPatient
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_view: false })}
          history={this.props.history}
          open={this.state.open_view && table === 'ssr_patient'}
          patient={row_clicked}
        />
      </React.Fragment>
    );
  }
}

StrokeToolbar.propTypes = {
  classes: PropTypes.object,
};
StrokeToolbar.defaultProps = {
  rows_edited: [],
  selected: [],
};

export default StrokeToolbar;
