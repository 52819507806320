import React from 'react';
import {
  AccountBox as AccountBoxIcon,
  CalendarToday as CalendarTodayIcon,
  Description as DescriptionIcon,
  Storage as StorageIcon,
} from '@mui/icons-material';
import { green } from '@mui/material/colors';

export { default as ButtonViewType } from './ButtonViewType';
export { default as ButtonDownloadAll } from './ButtonDownloadAll';
export const useStyles = theme => ({
  root: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingRight: 0,
    marginRight: 0,
    height: 55,
  },
  sort: {
    height: '36px',
    margin: 0,
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  circle: {
    color: green[800],
    position: 'absolute',
    top: 3,
    left: 5,
    zIndex: 1,
  },
  progress: {
    width: '100%',
  },
});
export const menu = [
  { id: 'name', name: 'Name', icon: <DescriptionIcon fontSize="small" /> },
  { id: 'Owner', name: 'Owner', icon: <AccountBoxIcon fontSize="small" /> },
  { id: 'Size', name: 'Size', icon: <StorageIcon fontSize="small" /> },
  {
    id: 'createdAt',
    name: 'Last modified',
    icon: <CalendarTodayIcon fontSize="small" />,
  },
];
