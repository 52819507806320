import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonReload, CheckBoxText, SearchText } from 'components';
import DeleteIcon from '@mui/icons-material/Delete';
import { styleToolbar } from 'common';

class GridDockerToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nowrap: false,
    };
  }

  render() {
    const { classes, selected, className } = this.props;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <ButtonReload
          className={classes.margin}
          color="red"
          ComponentIcon={DeleteIcon}
          handleReload={this.props.handleDeleteSelected}
          loading={this.props.deleting}
          small
          tooltip="Delete details"
          wrapper={{ margin: 0 }}
        />
      );
    }
    return (
      <Paper
        className={clsx({
          [classes.paper]: true,
          [className]: className,
        })}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button onClick={this.props.handleOpenCreate}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
          </ButtonGroup>
          <Grid item>
            <SearchText
              handleSearchText={() => this.props.handleReload()}
              holder={this.props.holder}
              placeholder="Search docker name..."
            />
          </Grid>
          <Grid item xs={3}>
            <CheckBoxText
              checked={this.props.nowrap}
              label="Wrap"
              onChange={this.props.handleChangeCheck}
              size="small"
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={2}>
            {ComponentDelete}
            <ButtonReload
              className={classes.margin}
              handleReload={this.props.handleReload}
              loading={this.props.loading}
              small
              tooltip="Reload details"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

GridDockerToolBar.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
GridDockerToolBar.defaultProps = {
  loading: false,
  deleting: false,
  selected: [],
  handleOpenEdit: () => '',
  handleOpenCreate: () => '',
  handleReload: () => '',
  handleDeleteSelected: () => '',
};
export default withStyles(GridDockerToolBar, styleToolbar);
