import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns } from './Styles';
import { QueryBackendSummarySequences } from 'graphql/Series/sequences';
import { GetData } from 'helpers';
import { rowsPerPageBig } from 'helpers';
import TabSummaryToolbar from './TabSummaryToolbar';
import { Grid, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ShowSeriesSequences } from './components';
import { ServerErrorsString } from 'helpers';
const useStyles = () => ({
  root: {},
});

class TabSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_show: null,
      page: 0,
      limit: 100,
      sequences: [],
      new_accounts: [],
      saving: false,
      idUser: -1,
      open_dialog: false,
      selected: [],
      deleting: false,
    };
  }
  componentDidMount() {
    this.handleLoadSummary();
  }
  handleLoadSummary = (new_page, new_limit) => {
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryBackendSummarySequences(null, 5, null, null, null, true, page, limit)
        .then(res => {
          const data = GetData(res);
          const sequences = data.SummarySequences;
          this.setState({
            loading: false,
            sequences,
            page,
            limit,
            total: sequences.length,
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleClickOpen = row_show => {
    console.log({ row_show });
    this.setState({ row_show });
  };
  render() {
    const { loading } = this.state;
    const { classes } = this.props;
    if (this.state.row_show) {
      const { idSequence } = this.state.row_show;
      return (
        <ShowSeriesSequences
          handleAddError={this.props.handleAddError}
          handleOpenEditView={() => this.setState({ row_show: null })}
          height={this.props.height}
          idSequence={idSequence}
          row={this.state.row_show}
        />
      );
    }
    return (
      <Grid alignItems="center" container p={1} pb={0}>
        <Grid item pb={1} xs={12}>
          <Paper style={{ paddingTop: 4 }}>
            <TabSummaryToolbar
              classes={classes}
              handleReload={() => this.handleLoadSummary()}
              loading={loading}
            />
          </Paper>
        </Grid>
        <Grid item pb={0} xs={12}>
          <Paper>
            <TableGrid
              checkboxSelection
              dense
              external_selected={this.state.selected}
              headers={columns}
              height={this.props.height}
              idRow="idSequence"
              loading={loading}
              onButtonClick={this.handleClickOpen}
              onPageChange={page =>
                this.handleLoadSummary(page, this.state.limit)
              }
              onRowsPerPageChange={limit => this.handleLoadSummary(0, limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              paginationMode="server"
              rowCount={this.state.total}
              rows={this.state.sequences}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TabSummary.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabSummary, useStyles);
