import { IsInvalid } from 'helpers';

export const projectFilterOptions = [
  { value: 1, label: 'Global' },
  { value: 2, label: 'Collaborators' }, // Mine and my collaborator projects
  { value: 3, label: 'Project' }, // current project related
  { value: 4, label: 'Mine' }, // filter only my data
];

export const IsUserClinicalTrial = user => {
  return user.type === 5 || user.type === 12;
};
export const IsNormalUser = user => {
  if (IsInvalid(user)) return false;
  // const result = IsUserClinicalTrial(user);
  // console.log({ result, user });
  return !IsUserClinicalTrial(user);
};
export const IsUserClinicalTrialUpload = user => {
  if (IsInvalid(user)) return false;
  // const result = IsUserClinicalTrial(user);
  // console.log({ result, user });
  return user.type === 12;
};
