const useStyles = (theme, props) => ({
  main_container: {
    // paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    width: '99.7%',
    height: props ? props.height - 320 : '100%',
  },
  main_container_list: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  paper: {
    width: '100%',
    height: '100%',
    paddingBottom: 2,
    overflowY: 'scroll',
  },
  root_channels: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
    height: props ? props.height - 210 : '100%',
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(6),
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    marginLeft: '-23px',
    paddingLeft: 0,
    // marginRight: '-25px'
    // width: 'calc(100% - 41px)'
  },
  footer_list: {
    marginLeft: 0,
  },
  shiftContent: {
    paddingLeft: 300,
    paddingRight: 0,
    marginRight: 0,
  },
  pagination: {
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(3),
    // display: 'flex',
    // width: '82%',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    left: 0,
  },
  shiftpagination: {
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(3),
    // display: 'flex',
    width: '100%',
    // alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spacer: {
    flexGrow: 1,
  },
});

export default useStyles;
