import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { MenuTopDown } from 'components';

class ComboBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      open_titles: false,
    };
  }
  componentDidUpdate() {
    if (this.props.value && this.state.title !== this.props.value) {
      this.setState({ title: this.props.value });
    }
  }
  handleClickTitles = event => {
    event.preventDefault();
    this.setState({ open_titles: !this.state.open_titles });
  };
  handleCloseTitles = (title, event) => {
    console.log({ title, event });
    if (!event) {
      this.setState({ open_titles: false });
      return;
    }
    if (this.props.disabled) return;
    this.setState({ title: title.option, open_titles: false });
    this.props.onChange(title.option);
  };
  render() {
    return (
      <div style={{ width: '100%', padding: 0, margin: 0 }}>
        <TextField
          disabled={this.props.disabled}
          fullWidth
          id="title"
          InputLabelProps={{
            shrink: true,
            padding: 0,
            margin: 0,
          }}
          label={this.props.label}
          margin={this.props.margin}
          name={this.props.name}
          onClick={this.handleClickTitles}
          placeholder={this.props.placeholder}
          size={this.props.size}
          style={{ width: this.props.width }}
          value={this.state.title}
          variant={this.props.variant}
        />
        <MenuTopDown
          handleClose={this.handleCloseTitles}
          object_parent={'title'}
          objects={this.props.titles}
          open={this.state.open_titles}
        />
      </div>
    );
  }
}

ComboBox.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  titles: PropTypes.array,
  width: PropTypes.string,
};
ComboBox.defaultProps = {
  disabled: false,
  width: '230px',
  onChange: () => '',
  titles: [],
  label: 'Title',
  margin: 'dense',
  name: 'title',
  variant: 'outlined',
  placeholder: 'Select your titile',
  size: 'medium',
};
export default ComboBox;
