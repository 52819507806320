import { Vector3D } from 'helpers/Utils3D';
import * as m4 from './m4';
class MouseWebGL {
  constructor(gl) {
    this.gl = gl;
    this.x = 0;
    this.y = 0;
    this.prevX = 0;
    this.prevY = 0;
    this.holdLeft = false;
    this.holdRight = false;
    this.wheel = 0;
    this.clipX = 0;
    this.clipY = 0;
    this.type = 'perspective';
    gl.canvas.addEventListener('mousemove', this.mouseMove);
  }
  getClip = () => {
    return [this.clipX, this.clipY];
  };
  // eslint-disable-next-line no-unused-vars
  getWorldCordinates = (camera, image) => {
    const viewProjection = camera.update();
    // const invMat = m4.inverse(m4.multiply(viewProjection, world));
    // x: 19.04495600040034
    // y: 266.6921044534086
    // z: -96.43622811472974
    const invMat = m4.inverse(viewProjection);
    const start = m4.transformPoint(invMat, [this.clipX, this.clipY, -1]);
    const end = m4.transformPoint(invMat, [this.clipX, this.clipY, 0]);
    const P = new Vector3D(end[0], end[1], end[2]);
    const P0 = new Vector3D(start[0], start[1], start[2]);
    const ray = P.subtract(P0);
    ray.normalize();
    // const normal = image.getPlaneNormal();
    // const center = image.getPlaneCenter();
    // const A_P0 = center.subtract(P0).dot(normal);
    // const RAY_N = ray.dot(normal);
    // const t = A_P0 / RAY_N;
    // const target = P0.multiply(t);
    console.log('getWorldCordinates');
    return P;
    // console.log({ x: end[0], y: end[1] });
  };
  mouseMove = e => {
    const canvas = this.gl.canvas;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    this.clipX = (x / rect.width) * 2 - 1;
    this.clipY = (y / rect.height) * -2 + 1;
  };
  updateMouse(mouseEvent, event) {
    if (!mouseEvent) return;
    if (event === 'move') {
      this.x = mouseEvent.offsetX;
      this.y = mouseEvent.offsetY;
    } else if (event === 'wheel') {
      if (mouseEvent.detail) {
        this.wheel = Math.round(mouseEvent.detail);
      } else {
        const wheel = Math.round(mouseEvent.deltaY);
        this.wheel = wheel % 3;
      }
    } else if (event === 'down' || event === 'up') {
      console.log({ click: mouseEvent.which });
    }
    switch (mouseEvent.which) {
      case 1:
        this.holdLeft = event === 'up' ? false : true;
        // this.prevX = mouseEvent.layerX;
        // this.prevY = mouseEvent.layerY;
        break;
      case 2:
        break;
      case 3:
        this.holdRight = event === 'up' ? false : true;
        break;
      default:
        break;
    }
  }
  updateLast() {
    this.prevX = this.x;
    this.prevY = this.y;
  }
  fordward() {
    return this.y - this.prevY > 0;
  }
  backward() {
    return this.y - this.prevY < 0;
  }
  deltaY() {
    return this.y - this.prevY;
    // return this.prevY - this.y;
  }
  deltaX() {
    // return this.x - this.prevX;
    return this.prevX - this.x;
  }
  reset() {
    this.x = 0;
    this.y = 0;
    this.prevX = 0;
    this.prevY = 0;
    this.holdLeft = false;
    this.holdRight = false;
    this.wheel = 0;
  }
  Mouse3D() {
    const W = this.gl.canvas.width;
    const H = this.gl.canvas.height;
    const mouse3D = new Vector3D();
    mouse3D.x = (this.x / W) * 2 - 1;
    mouse3D.y = -(this.y / H) * 2 + 1;
    mouse3D.z = 0.5;
    return mouse3D;
  }
  rayCaster(camera) {
    const { type } = this.state;
    const dir = new Vector3D();
    if (type === 'orthographic') {
      const vector = this.Mouse3D(-1);
      vector.unproject(camera);
      dir.set(0, 0, -1).transformDirection(camera.cameraMatrix());
      // raycaster.set(vector, dir);
    } else if (type === 'perspective') {
      const vector = this.Mouse3D(0.5);
      vector.unproject(camera);

      // raycaster.set(
      //   camera.position(),
      //   vector.subtract(camera.position()).normalize()
      // );
    }
  }
}
export default MouseWebGL;
