import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@mui/material';
import { SearchProjectCenterTeams } from 'components';

class UploadStepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { project, center, patient_number } = this.props;
    let idProject = project ? project.id : null;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        p={2}
        spacing={2}>
        <Grid item>
          <Typography fontWeight={700}>Enter patient number</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            label="Project Name"
            value={project ? project.name : 'Missing project name'}
            variant="standard"
          />
        </Grid>
        <Grid item sm={5} xs={6}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            label="Center Name"
            value={center ? center.name : 'Missing center name'}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <SearchProjectCenterTeams
            handleAddCenterTeam={this.props.handleAddCenterTeam}
            holder={this.holder}
            idProject={idProject}
            no_icon
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Patient Number"
            onChange={this.props.handlePatientName}
            placeholder="Patient number should be 3 digits, e.g. 001 for the first patient"
            value={patient_number}
            variant="filled"
          />
        </Grid>
      </Grid>
    );
  }
}

UploadStepTwo.propTypes = {
  patient_number: PropTypes.string,
};

export default UploadStepTwo;
