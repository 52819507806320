import React from 'react';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { inputProps } from 'common';

const useStyles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: 9,
  },
  input: {
    // padding: '12.5px 14px',
  },
  margin: {
    padding: 0,
    margin: 0,
    // margin: theme.spacing(1),
    width: '100%',
  },
  textField: {
    flexGrow: 1,
    width: '100%',
  },
});

class TabQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    const { tab_holder, index, query } = props;
    if (tab_holder) {
      tab_holder.getValues[index] = () => ({
        type: query,
        value: this.state.value,
      });
    }
  }

  handleChange = event => {
    event.preventDefault();
    //
    this.setState({ value: event.target.value });
  };
  handleExecuteSearch = event => {
    if (!event) {
      return;
    }
    const { key } = event;
    if (key === 'Enter') {
      const { value } = this.state;

      this.props.executeSearch(value, this.props.query, event);
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, query } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        <TextField
          className={clsx(classes.margin, classes.textField)}
          id={`outlined-simple-start-${query}`}
          InputProps={{
            className: classes.input,
            ...inputProps,
          }}
          label={query}
          onChange={this.handleChange}
          onKeyPress={this.handleExecuteSearch}
          size="small"
          value={value}
          variant="outlined"
        />
      </div>
    );
  }
}

TabQuery.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabQuery, useStyles);
