import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
class GroupBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, style } = this.props;
    let { title_box } = this.props;
    if (title_box === undefined) {
      title_box = '';
    }
    const length = title_box.length;
    return (
      <div
        id={uuid()}
        style={{
          border: '1px solid #e0e0e0',
          padding: 4,
          paddingTop: 10,
          //   paddingLeft: 10,
          borderRadius: 10,
        }}>
        <div
          style={{
            marginTop: '-20px',
            marginLeft: 15,
            background: 'white',
            maxWidth: length * 8,
            fontSize: 'small',
          }}>
          <div
            style={{
              margin: 0,
              padding: 0,
              marginLeft: 10,
              color: '#9e9e9e',
            }}>
            {title_box}
          </div>
        </div>
        <div
          style={{ margin: 5, marginTop: title_box === '' ? 20 : 5, ...style }}>
          {children}
        </div>
      </div>
    );
  }
}

GroupBox.propTypes = {
  classes: PropTypes.object,
  title_box: PropTypes.string,
};
GroupBox.defaultProps = {
  title_box: 'Unknown',
  style: {},
};

export default GroupBox;
