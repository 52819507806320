/* eslint-disable no-unused-vars */
import {
  QueryStrokeFollowup,
  QueryStrokeCases,
  QueryStrokeTreatment,
  QueryStrokeThrombolysisProcedure,
  QueryStrokeEncounters,
  MutationAddClinicalPatients,
  MutationAddSsrCase,
  MutationAddSsrFollowup,
  MutationAddSsrTreatement,
  MutationAddSsrThromboProcedure,
  MutationAddSsrEncounter,
  MutationUploadStrokeAll,
  MutationRemoveStrokeCase,
  MutationRemoveStrokeFollowup,
  MutationRemoveStrokeEncounter,
  MutationRemoveStrokeTreatment,
  MutationRemoveStrokeThrombolysis,
  QueryStrokePatients,
  MutationUploadClinicalFileCatalogues,
} from 'graphql/DataClinics';
import { IsValid } from 'helpers';
import * as searches from 'graphql/DataClinics/stroke/utils_search';

export const QueryStroke = (table, name, page, limit, anonymize) => {
  console.log('***\n**\n* QS', { anonymize });
  if (table === 'ssr_cases') {
    const cases = QueryStrokeCases(name, page, limit);
    return cases;
  } else if (table === 'ssr_followup') {
    return QueryStrokeFollowup(name, page, limit);
  } else if (table === 'ssr_treatment') {
    return QueryStrokeTreatment(name, page, limit);
  } else if (table === 'ssr_thrombo_procedure') {
    return QueryStrokeThrombolysisProcedure(table, page, limit);
  } else if (table === 'ssr_encounters') {
    return QueryStrokeEncounters(name, page, limit);
  } else if (table === 'ssr_patient') {
    return QueryStrokePatients(page, limit, anonymize);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetResponse = (table, data) => {
  if (table === 'ssr_cases') {
    const { ok, errors, cases, total } = data.ssrCases;
    return { ok, errors, total, rows: cases };
  } else if (table === 'ssr_followup') {
    const { ok, errors, followups, total } = data.ssrFollowups;
    return { ok, errors, total, rows: followups };
  } else if (table === 'ssr_treatment') {
    const { ok, errors, treatments, total } = data.ssrTreatments;
    return { ok, errors, total, rows: treatments };
  } else if (table === 'ssr_thrombo_procedure') {
    const { ok, errors, procedures, total } = data.ssrThromboProcedures;
    return { ok, errors, total, rows: procedures };
  } else if (table === 'ssr_encounters') {
    const { ok, errors, encounters, total } = data.ssrEncounters;
    return { ok, errors, total, rows: encounters };
  } else if (table === 'ssr_patient') {
    const { ok, errors, patients, total } = data.ssrPatients;
    return { ok, errors, total, rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rows: [] };
  }
};

export const MutationRemoveStroke = (table, ids) => {
  if (table === 'ssr_cases') {
    return MutationRemoveStrokeCase(ids);
  } else if (table === 'ssr_followup') {
    return MutationRemoveStrokeFollowup(ids);
  } else if (table === 'ssr_encounters') {
    return MutationRemoveStrokeEncounter(ids);
  } else if (table === 'ssr_thrombo_procedure') {
    return MutationRemoveStrokeThrombolysis(ids);
  } else if (table === 'ssr_treatment') {
    return MutationRemoveStrokeTreatment(ids);
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};
export const GetRemoveResponse = (table, data) => {
  let reply = {
    ok: false,
    errors: [{ path: 'Invalid', message: 'Invalid table' }],
  };
  if (table === 'ssr_cases') {
    reply = data.deleteStrokeCase;
  } else if (table === 'ssr_followup') {
    reply = data.deleteStrokeFollowup;
  } else if (table === 'ssr_encounters') {
    reply = data.deleteStrokeEncounter;
  } else if (table === 'ssr_thrombo_procedure') {
    reply = data.deleteStrokeThromboProcedure;
  } else if (table === 'ssr_treatment') {
    reply = data.deleteStrokeTreatment;
  }
  return reply;
};

export const MutationAddStroke = (table, inputs) => {
  /**clean the input object to map it to the graphql schema */
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    if ('patient' in input) {
      const { patient } = input;
      if (IsValid(patient)) {
        const { id, PatientID } = patient;
        input.idPatient = id;
        input.PatientID = PatientID;
        delete input.edit;
      }
      delete input.patient;
    }
  }
  if (table === 'ssr_cases') {
    return MutationAddSsrCase(inputs);
  } else if (table === 'ssr_followup') {
    return MutationAddSsrFollowup(inputs);
  } else if (table === 'ssr_treatment') {
    return MutationAddSsrTreatement(inputs);
  } else if (table === 'ssr_thrombo_procedure') {
    return MutationAddSsrThromboProcedure(inputs);
  } else if (table === 'ssr_encounters') {
    return MutationAddSsrEncounter(inputs);
  } else if (table === 'ssr_patient') {
    return MutationAddClinicalPatients(inputs, 'stroke');
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetAddResponse = (table, data) => {
  if (table === 'ssr_cases') {
    const { ok, errors, cases, total } = data.addSsrCase;
    return { ok, errors, total, rew_rows: cases };
  } else if (table === 'ssr_patient') {
    const { ok, errors, patients, total } = data.addPatientsClinics;
    return { ok, errors, total, rew_rows: patients };
  } else {
    return { ok: true, errors: null, total: 0, rew_rows: [] };
  }
};

export const MutationUploadStroke = (table, file, handleProgress) => {
  if (table === 'all') {
    return MutationUploadStrokeAll(file, handleProgress);
  } else if (table === 'file') {
    return MutationUploadClinicalFileCatalogues(file, handleProgress);
  } else {
    return new Promise((resolve, reject) => {
      resolve({
        data: { data: 1 },
      });
    });
  }
};

export const GetUploadResponse = (table, data) => {
  if (table === 'all') {
    const { ok, errors, total, cases } = data.uploadStroke;
    return { ok, errors, total, new_rows: cases };
  } else if (table === 'file') {
    const { ok, errors, total, files } = data.uploadClinicalFileCatalogues;
    return { ok, errors, total, new_rows: files };
  } else {
    return {
      ok: true,
      errors: [{ path: 'Invalid', message: 'Invalid table' }],
      total: 0,
      new_rows: [],
    };
  }
};

export const searchStrokeTables = (text, table, page, limit) => {
  const functionName = `Search${table.charAt(0).toUpperCase() +
    table.slice(1)}`;
  const searchStrokeFunction = searches[functionName];

  // const functionsArray = Object.values(searches);
  // const searchStrokeFunction = functionsArray.find(
  //   func => func.name === functionName
  // );
  return searchStrokeFunction(text, page, limit, table);
};
