import React, { Component } from 'react';
import { ServerErrorsString, GetData } from 'helpers';
import { QuerySequences } from 'graphql/Series/sequences';

class QuerySeriesSequence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      loading: false,
      name: null,
      page: 0,
      limit: 1000,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const { loading, name, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QuerySequences(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, sequences } = data.Sequences;
          if (ok) {
            this.props.setState({ loading: false });
            this.setState({
              rows: sequences,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { loading, rows } = this.state;
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        rows,
        loading,
      });
    });
  }
}
QuerySeriesSequence.defaultProps = {
  holder: {
    reload: null,
  },
};
export default QuerySeriesSequence;
