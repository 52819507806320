import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { UnitSettings, UnitText } from './components';

const useStyles = theme => ({
  toolbar: {
    width: '100%',
  },
  description: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

class UnitDescription extends Component {
  render() {
    const {
      unit,
      admin,
      holder,
      description,
      institution,
      handelChange,
      handleAddError,
      handelChangeText,
    } = this.props;
    let { api } = this.props;
    if (!unit) return null;
    const { code, ip, country, country_code, city, setting } = unit;
    // console.log({ unit });
    const style_item = { paddingTop: 0 };
    if (api === undefined) api = unit.api;
    return (
      <Grid container direction="row">
        <Grid item sx={{ paddingTop: 3, paddingRight: 2 }} xs={6}>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={3}>
            <Grid container direction="row" item spacing={1} style={style_item}>
              <Grid item>
                <UnitText label="Code" readOnly text={code} />
              </Grid>
              <Grid item>
                <UnitText
                  label="Institution"
                  name="institution"
                  onChange={handelChangeText}
                  readOnly={!admin}
                  text={institution}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={1} style={style_item}>
              <Grid item>
                <UnitText label="IP" readOnly text={ip} />
              </Grid>
              <Grid item>
                <UnitText label="Private IP" readOnly text={unit.ip_private} />
              </Grid>
            </Grid>
            <Grid container direction="row" item spacing={1} style={style_item}>
              <Grid item>
                <UnitText label="City" readOnly text={city} />
              </Grid>
              <Grid item>
                <UnitText
                  label="Country"
                  readOnly
                  text={`${country} (${country_code})`}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item style={style_item}>
              <Grid item xs={12}>
                <UnitText
                  // className={classes.description}
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={handelChangeText}
                  readOnly={!admin}
                  text={description}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="flex-start">
            <Grid item xs={12}>
              <UnitText
                fullWidth
                label="API"
                name="api"
                onChange={handelChangeText}
                readOnly={!admin}
                text={api}
              />
            </Grid>
            <Grid item xs={12}>
              <UnitText
                label="Serial Number"
                readOnly
                text={unit.serial_number}
              />
            </Grid>
            <Grid item xs={12}>
              <UnitSettings
                admin={admin}
                handleAddError={handleAddError}
                holder={holder}
                onChange={handelChange}
                setting={setting}
                unit={unit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UnitDescription.propTypes = {
  classes: PropTypes.object,
};
UnitDescription.defaultProps = {
  unit: null,
  admin: false,
  holder: null,
  description: '',
  institution: '',
};

export default withStyles(UnitDescription, useStyles);
