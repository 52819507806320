import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
});

class Titles extends Component {
  render() {
    const { classes, titles } = this.props;
    return (
      <div className={classes.stats}>
        {titles.map(object => (
          <div className={classes.device} key={object.title}>
            {/* <span className={classes.deviceIcon}>{device.icon}</span> */}
            <Typography variant="body2">{object.title}</Typography>
            <Typography style={{ color: object.color }} variant="h6">
              {object.value}%
            </Typography>
          </div>
        ))}
      </div>
    );
  }
}

Titles.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Titles, useStyles);
