// export const desc = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };

// export const getSorting = (order, orderBy) => {
//   return order === 'desc'
//     ? (a, b) => desc(a, b, orderBy)
//     : (a, b) => -desc(a, b, orderBy);
// };

// export const stableSort = (array, cmp) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// };

export const rowsPerPageSmall = [5, 10, 25, 50, 100];
export const rowsPerPageMedium = [5, 10, 25, 50, 100, 150, 200];
export const rowsPerPageBig = [5, 10, 25, 50, 100, 200, 500];
