import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chip, TextField, Autocomplete } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { inputProps } from 'common';

const useStyles = theme => ({
  root: {
    paddingRight: 50,
    width: 400,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
});

class FolderFilesNames extends Component {
  getObjectName = object => {
    const { idSeries } = object;
    if (typeof idSeries !== 'undefined' && idSeries > 0) {
      return object.SeriesDescription;
    }
    const { idFolder, idFolderContent } = object;
    if (idFolderContent !== undefined && idFolderContent > 0) {
      const { File, Series } = object;
      if (File) {
        return File.original_name;
      } else {
        return Series.SeriesDescription;
      }
    }
    if (idFolder !== undefined && idFolder > 0) {
      return object.name;
    }
    return 'Name missing!';
  };

  render() {
    const { objects, classes, placeholder, title } = this.props;
    //
    let default_values = [];
    if (objects.lenght > 4) {
      default_values = objects.slice(0, 4);
    } else {
      default_values = objects;
    }
    return (
      <div className={classes.root}>
        <Autocomplete
          defaultValue={default_values}
          disabled
          getOptionLabel={option => {
            if (option) return option.name;
            return '';
          }}
          id="multiple-limit-tags"
          limitTags={5}
          multiple
          options={objects}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                ...inputProps,
              }}
              label={title}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const name = this.getObjectName(option);
              return (
                <Chip
                  key={`chip-tag-key-${index}`}
                  label={name}
                  size="small"
                  {...getTagProps({ index })}
                />
              );
            })
          }
          size="small"
        />
      </div>
    );
  }
}

FolderFilesNames.defaultProps = {
  objects: [],
  title: 'Folder to Share',
  placeholder: 'List of folders',
};
FolderFilesNames.propTypes = {
  objects: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default withStyles(FolderFilesNames, useStyles);
