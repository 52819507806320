import axios from 'axios';
import { print } from 'graphql';
import {
  MUTATION_CHUNK_FILE_UPLOAD_COMBINE,
  MUTATION_FILE_CHUNK_UPLOAD,
  chunkSize,
} from './utls_gql_upload';
import { GetData } from 'helpers';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const readFileChunk = (file, start, end) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.onerror = error => reject(error);

    const chunk = file.slice(start, end);
    reader.readAsArrayBuffer(chunk);
  });
};

const uploadChunk = async (file_input, input, totalChunks, handleProgress) => {
  const { chunkIndex } = input;
  const start = chunkIndex * chunkSize;
  const end = Math.min(start + chunkSize, file_input.size);
  const chunkData = await readFileChunk(file_input, start, end);
  const formData = new FormData();
  formData.append(
    'operations',
    JSON.stringify({
      query: print(MUTATION_FILE_CHUNK_UPLOAD),
      variables: {
        file: null,
        input,
      },
    })
  );
  formData.append(
    'map',
    JSON.stringify({
      '0': ['variables.file'],
    })
  );
  const file = new Blob([chunkData], {
    type: file_input.type,
    filename: file_input.filename,
    size: file_input.size,
  });
  formData.append('0', file);
  return new Promise((resolve, reject) => {
    axios
      .post(HTTP_ADDRESS_GRAPHQL, formData, {
        onUploadProgress: event => {
          const { loaded } = event;
          const accumulated = chunkSize * chunkIndex;
          handleProgress({ loaded: accumulated + loaded, total: file.size });
        },
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'apollo-require-preflight': true,
        },
        crossDomain: true,
      })
      .then(res => {
        console.log(`Uploaded chunk ${chunkIndex + 1} of ${totalChunks}`);
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};
const MutationCombineChunks = (key, settings) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CHUNK_FILE_UPLOAD_COMBINE),
    variables: {
      key,
      settings,
    },
  });
};
export const MutationBigFiles = (file, query, handleProgress) => {
  const totalChunks = Math.ceil(file.size / chunkSize);
  const { settings } = query;
  let projectIds = null;
  if (settings && settings.projectIds) {
    projectIds = settings.projectIds;
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const file_blob = new Blob([file], { type: file.type });
    let key_chunk = null;
    for (let i = 0; i < totalChunks; i++) {
      try {
        const input = {
          chunkIndex: i,
          total_size: file.size,
          key: key_chunk,
          total_chunks: totalChunks,
          filename: file.name,
          mimetype: file.type,
          projectIds: projectIds ? projectIds : null,
        };
        const res = await uploadChunk(
          file_blob,
          input,
          totalChunks,
          handleProgress
        );
        console.log({ res });
        const data = GetData(res);
        const { ok, errors, key } = data.uploadChunk;
        if (ok) {
          key_chunk = key;
        } else {
          throw errors;
        }
      } catch (error) {
        console.log({ error });
        reject(error);
        return;
      }
    }
    try {
      const { settings } = query;
      const res = await MutationCombineChunks(key_chunk, settings);
      const data = GetData(res);
      const { ok, errors } = data.combineChunks;
      if (ok) {
        const uploadFile = {
          ...data.combineChunks,
        };
        resolve({
          data: {
            data: {
              uploadFile,
            },
          },
        });
      } else {
        throw errors;
      }
    } catch (error) {
      reject(error);
    }
    console.log('File upload completed!');
  });
};
