import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { PublicSelectedSoftware, SlideSoftware } from './components';
import { getParams } from 'helpers';
import { HomeFooterSubscribe } from 'views/Home/components';
import { IsInvalid } from 'helpers';
class SoftwareMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      software: ['shoulder_measure'],
    };
  }
  IsHere = () => {
    const reply = getParams(this.props);
    if (IsInvalid(reply)) return false;
    const { level_2 } = reply;
    if (IsInvalid(level_2)) return false;
    const { software } = this.state;
    const index = software.indexOf(level_2);
    if (index !== -1) return true;
    this.props.history.push('/public_access/software');
    return false;
  };
  handleClose = () => {
    this.props.history.push('/public_access/software');
  };
  render() {
    if (this.IsHere()) {
      return <PublicSelectedSoftware handleClose={this.handleClose} />;
    }
    return (
      <Box py={6}>
        <Container>
          <Grid
            alignItems="center"
            container
            flexDirection="column"
            item
            mb={6}
            mx="auto"
            textAlign="center"
            xs={8}>
            <Typography mb={1} variant="h2">
              Explore algorithms and software
            </Typography>

            <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
              The algorithms and software required for data processing have been
              integrated into our platform. These algorithms can be employed at
              the level of individual patients or utilized to analyze a group
              within our pipeline system.
            </Typography>
          </Grid>

          <Grid container minHeight="40vh" spacing={3}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25, fontWeight: 1000 }} variant="h4">
                Algorithms:
              </Typography>
              <SlideSoftware />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25, fontWeight: 1000 }} variant="h4">
                Software:
              </Typography>
              <Typography
                color="text"
                my={2}
                sx={{ fontSize: 17 }}
                variant="body2">
                &nbsp; &nbsp; &nbsp;Coming soon...
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <HomeFooterSubscribe
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
        />
      </Box>
    );
  }
}

SoftwareMain.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(SoftwareMain);
