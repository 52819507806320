import React from 'react';
import PropTypes from 'prop-types';
import WebGLSlider from '../WebGLSlider';
class SliderTranslates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSliceValueMove = (event, { value }, index) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.translation[index] = value;
    renderer.drawScene();
  };
  render() {
    const { renderer } = this.props;
    if (renderer === undefined || !renderer) return null;
    return (
      <React.Fragment>
        <WebGLSlider
          options={{
            value: renderer.translation[0],
            max: renderer.gl.canvas.width,
            min: -renderer.gl.canvas.width,
            name: 'x',
          }}
          slide={(e, v) => this.handleSliceValueMove(e, v, 0)}
        />
        <WebGLSlider
          options={{
            value: renderer.translation[1],
            min: -renderer.gl.canvas.height,
            max: renderer.gl.canvas.height,
            name: 'y',
          }}
          slide={(e, v) => this.handleSliceValueMove(e, v, 1)}
        />
        <WebGLSlider
          options={{
            value: renderer.translation[2],
            min: -1000,
            max: 0,
            name: 'z',
          }}
          slide={(e, v) => this.handleSliceValueMove(e, v, 2)}
        />
      </React.Fragment>
    );
  }
}

SliderTranslates.propTypes = {
  classes: PropTypes.object,
};

export default SliderTranslates;
