// import { skullstripping } from './skullstripping';
// import { dcm_nifti } from './dcm_nifti';
// import { DL_DiReCT } from './DL_DiReCT';
export const pages = [
  {
    height: '40%',
    buttons: [{ name: 'SWEZ iEEG Dataset', route: 'ieeg' }],
    title: 'Brain',
    image: '/images/brain.jpg',
    description:
      'The brain is the most complex organ in the human body, and it controls all aspects of our thoughts, emotions, and actions',
  },
  {
    height: '70%',
    title: 'Heart',
    image: '/images/heart.jpg',
    description:
      'Heart research is critical for understanding how the heart works, how it can be damaged, and how it can be treated. ',
  },
  {
    height: '99%',
    title: 'Lungs',
    image: '/images/lungs.jpg',
    description:
      'Lung diseases, such as asthma, chronic obstructive pulmonary disease (COPD), and lung cancer, are significant causes of morbidity and mortality worldwide.',
  },
];
