import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { DialogAnonymizationKeys, TabPanel } from 'components';
import { TabRules, TabRulesTags } from './components';
import { withStyles } from 'tss-react/mui';
import { useStyles } from './components/Styles';
import { getLevels } from 'helpers';

class TabAnonymization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: 'Rules', url: 'rules', TabComponent: TabRules },
        {
          name: 'Rules User/Project',
          url: 'user_project',
          TabComponent: TabRulesTags,
        },
      ],
      tabValue: 0,
      open_info: false,
    };
  }
  componentDidMount() {
    const { level_3 } = getLevels(this.props);
    let tabValue = 0;
    const index = this.state.tabs.map(x => x.url).indexOf(level_3);
    if (index >= 0 && index < this.state.tabs.length) {
      tabValue = index;
    }
    this.setState({ tabValue });
  }
  handleTabChanged = (e, tabValue) => {
    this.setState({ tabValue });
    const url = this.state.tabs[tabValue].url;
    this.props.history.push(`/administrator/general/anonymization/${url}`);
  };
  render() {
    const { tabValue } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            onChange={this.handleTabChanged}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              let { name } = tab;
              return (
                <Tab
                  className={classes.tab}
                  key={`tab-ano-tab-${index}`}
                  label={name}
                />
              );
            })}
          </Tabs>
          <Divider />
          <div style={{ padding: 4 }}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  index={index}
                  key={`tab-ano-${index}`}
                  value={tabValue}>
                  <TabComponent
                    classes={this.props.classes}
                    handleAddError={this.props.handleAddError}
                    handleOpenHelp={() => this.setState({ open_info: true })}
                    height={this.props.height - 135}
                    isActive={this.props.isActive}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </Paper>
        <DialogAnonymizationKeys
          handleClose={() => this.setState({ open_info: false })}
          open={this.state.open_info}
        />
      </React.Fragment>
    );
  }
}

TabAnonymization.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabAnonymization, useStyles);
