import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  progress: {
    width: '100%',
  },
});
class ExplorerLinearProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, progress } = this.props;
    // console.log({ progress });
    if (progress > 0 && progress < 100) {
      return (
        <div className={classes.progress}>
          <LinearProgress value={progress} variant="determinate" />
        </div>
      );
    }
    return null;
  }
}

ExplorerLinearProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default withStyles(ExplorerLinearProgress, useStyles);
