import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setExplorerFolders } from 'redux-store/actions';

import { getLevels, GetThumbnailFile } from 'helpers';
import { IsProjectFolder, IsUploadFolder, useStyles } from './Style';

class ExplorerViewGalleryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
    };
    this.double = false;
  }

  handleMouseHover = () => {
    const { isMouseOver } = this.state;
    this.setState({ isMouseOver: !isMouseOver });
    if (isMouseOver) {
      this.props.handleMouseOverItem(null);
    } else {
      this.props.handleMouseOverItem(this.props.value);
    }
  };
  handleSelected = () => {
    const { value } = this.props;
    this.props.handleSelected(value);
  };
  handleOpenFolder = () => {
    console.log('handleOpenFolder');
    const { value } = this.props;
    if (!value) {
      return;
    }
    this.double = true;
    let { idFolder, folders_paths } = value;
    // console.log({ value, folders_paths });
    if (folders_paths && folders_paths.length) {
      if (folders_paths[0].name === 'Home') {
        folders_paths.splice(0, 1);
      }
      // console.log({ aftertotal: folders_paths.length });
      this.props.setExplorerFolders(folders_paths);
    }
    if (idFolder) {
      const { level_1 } = getLevels(this.props);
      this.props.handleCleanItemOver();
      this.setState({ isMouseOver: false });
      if (level_1 === 'shared') {
        this.props.history.push(`/explorer/shared/folder/${idFolder}`);
      } else {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      }
    }
  };
  handleDoubleClickEditName = () => {
    const { idFolder, name } = this.props.value;
    if (idFolder === undefined) {
      return;
    }
    this.props.handleSubFolderNameChanged(idFolder, name);
  };

  render() {
    const {
      classes,
      value,
      handleClickFolderItem,
      isSelected,
      user,
      idHomeFolder,
    } = this.props;
    const { isMouseOver } = this.state;
    let url = `/images/icons/${value.kind}.png`;
    if (value.kind === 'undefined') {
      url = '/images/icons/bin.png';
    }
    let is_folder = false;
    let title = 'Unknown';
    let project_folder = false;
    let upload_folder = false;
    const { idFolder, idFolderContent } = value;
    if (idFolder && idFolderContent === undefined && !idFolderContent) {
      is_folder = true;
      title = value.name;
      project_folder = IsProjectFolder(value, idHomeFolder);
      upload_folder = IsUploadFolder(value, idHomeFolder);
    } else if (idFolderContent) {
      const { Series, File } = value;
      if (Series) {
        title = Series.SeriesDescription;
        if (Series.Thumbnail && Series.Thumbnail !== '') {
          url = `data:image/jpeg;base64,${Series.Thumbnail}`;
        } else {
          url = GetThumbnailFile('file.dcm');
        }
      } else if (File) {
        title = File.original_name;
        if (File.Thumbnail) {
          url = `data:${File.mimetype};base64,${File.Thumbnail}`;
        } else {
          url = GetThumbnailFile(title);
        }
      } else {
        // IF ARRIVES HERE THE OBJECT WAS DELETED
        url = '/images/icons/notfound.png';
      }
    }
    if (typeof title === 'undefined') {
      title = 'undefined name';
    }
    let title_short = title;
    if (title.length > 11) {
      title_short = title.substring(0, 11) + '...';
    }
    const selected = isMouseOver || isSelected;
    let folder_ownership = true;
    if (is_folder) {
      const { Owner } = value;
      if (Owner && Owner.id !== user.id) {
        folder_ownership = false;
      }
    }
    return (
      <Grid
        className={clsx({
          [classes.root]: true,
          [classes.context_menu]: this.props.isContextMenu,
        })}
        container
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        wrap="nowrap">
        {selected ? (
          <Checkbox
            checked={isSelected}
            className={classes.checkbox}
            color="primary"
            onChange={this.handleSelected}
            style={{ backgroundColor: 'white' }}
          />
        ) : null}
        <Tooltip title={title.length > 10 ? title : ''}>
          <Box
            className={clsx({
              [classes.box]: !selected,
              [classes.box_selected]: selected,
            })}
            onClick={() => {
              if (this.double) {
                this.double = false;
                return;
              }
              handleClickFolderItem(value);
            }}>
            {is_folder ? (
              <FolderIcon
                className={clsx({
                  [classes.icon]: folder_ownership,
                  [classes.icon_folder_shared]: !folder_ownership,
                  [classes.icon_folder_project]: project_folder,
                  [classes.icon_folder_upload]: upload_folder,
                })}
                onDoubleClick={this.handleOpenFolder}
              />
            ) : (
              <div className={classes.icon}>
                <img
                  alt={title_short}
                  onDoubleClick={() =>
                    this.props.handleOpenAtDoubleClick(value)
                  }
                  src={url}
                  style={{ width: 100, height: 80 }}
                />
              </div>
            )}
            <Box className={classes.folder_name}>
              <Typography
                align="center"
                gutterBottom
                onDoubleClick={this.handleDoubleClickEditName}
                variant="body2">
                {title_short}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Grid>
    );
  }
}
ExplorerViewGalleryItem.propTypes = {
  handleCleanItemOver: PropTypes.func.isRequired,
};
ExplorerViewGalleryItem.defaultProps = {
  idHomeFolder: -1,
  isSelected: false,
  handleMouseOverItem: () => '',
  handleOpenAtDoubleClick: () => '',
  isContextMenu: false,
};

const mapStateToProps = state => {
  const { manager } = state;
  const objects = manager.cut.concat(manager.copy).concat(manager.copy_series);
  return {
    user: state.info_user.user,
    topaste: objects.length,
  };
};

export default connect(mapStateToProps, { setExplorerFolders })(
  withRouter(withStyles(ExplorerViewGalleryItem, useStyles))
);
