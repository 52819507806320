import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { TableGrid } from 'components';
import {
  EditProjectObjectTypes,
  ToolBarProjectObject,
  MappingProjectObjectTypes,
  EditProjectSeries,
} from './components';
import { columns, useStyles } from './components/useStyles';
import { GetData, ServerErrorsString, rowsPerPageBig } from 'helpers';
import { QueryAllProjectObject } from 'graphql/Projects/utils_project_objects';
import {
  GetObjectInputs,
  IsFileOrSeries,
  IsNameActive,
} from './components/ToolBarProjectObject/components';
import { IsInvalid } from 'helpers';
class TabProjectSettingObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_series: false,
      row_clicked: null,
      open_mapping: false,
      open_editor: false,
      idProject: -1,
      selected: [],
      project_objects: [],
      page: 0,
      limit: 100,
      total: 0,
      empty: false,
      type: 0,
      filter: null,
      headers_to_hide: [],
    };
    this.holder = {
      getName: null,
      getSecond: null,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    if (this.props.loading) return;
    this.runQueryProjectObject();
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (this.props.loading) return;
    const { project_objects, idProject, empty } = this.state;
    if (
      this.props.idProject !== idProject ||
      (!project_objects.length && !empty)
    ) {
      this.runQueryProjectObject();
    }
  }
  handleSelectionType = type => {
    if (typeof type !== 'number') return;
    if (type === this.state.type) return;
    this.runQueryProjectObject(type);
  };
  runQueryProjectObject = (
    new_type = null,
    new_page = null,
    new_limit = null
  ) => {
    const { idProject } = this.props;
    let { page, limit, loading, type } = this.state;
    console.log('runQueryProjectObject', { idProject });
    if (loading || IsInvalid(idProject)) return;
    if (new_type !== null && typeof new_type === 'number') {
      type = new_type;
    }
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    let name = undefined;
    let second_search = undefined;
    if (IsNameActive(type)) {
      name = this.holder.getName();
      if (IsFileOrSeries(type)) {
        second_search = this.holder.getSecond();
      }
    }
    const inputs = GetObjectInputs(type, name, second_search);
    this.setState({ loading: true });
    (async () => {
      QueryAllProjectObject(idProject, page, limit, inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, project_objects, total, errors } = data.allProjectObjects;
          if (ok) {
            this.setState({
              idProject,
              loading: false,
              project_objects,
              page,
              type,
              limit,
              total,
              empty: project_objects.length ? false : true,
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            page,
            idProject,
            limit,
            total: 0,
            empty: true,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleHeadersToHide = headers_to_hide => {
    this.setState({ headers_to_hide });
  };
  render() {
    if (!this.props.isActive) return null;
    const { selected } = this.state;
    let { height, isAdmin, access_level } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    if (this.state.open_series) {
      return (
        <EditProjectSeries
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_series: false })}
          height={height}
          idProject={this.props.idProject}
          selected={this.state.selected}
        />
      );
    }
    if (this.state.open_editor) {
      return (
        <EditProjectObjectTypes
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_editor: false })}
          height={height}
          idProject={this.props.idProject}
          selected={selected}
        />
      );
    }
    if (this.state.open_mapping) {
      return (
        <MappingProjectObjectTypes
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_mapping: false })}
          height={height}
          idProject={this.props.idProject}
          selected={selected}
        />
      );
    }
    let SelectedText = null;
    if (selected.length) {
      height = height - 20;
      SelectedText = `Selected: ${selected.length}`;
    }
    let height_table = height - 70;
    if (height_table < 100) height_table = null;
    return (
      <div style={style}>
        <ToolBarProjectObject
          access_level={access_level}
          handleClearSelected={() => this.setState({ selected: [] })}
          handleCloseEditSeries={() => this.setState({ open_series: true })}
          handleOpenEdit={() => this.setState({ open_editor: true })}
          handleOpenVarMappping={() => this.setState({ open_mapping: true })}
          handleReloadTable={() => this.runQueryProjectObject()}
          handleSelectionType={this.handleSelectionType}
          holder={this.holder}
          idProject={this.props.idProject}
          isAdmin={isAdmin}
          loading={this.state.loading}
          row_clicked={this.state.row_clicked}
          selected={selected}
          type={this.state.type}
        />
        {SelectedText}
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          filter={this.state.filter}
          headers={columns}
          headers_to_hide={this.state.headers_to_hide}
          height={height_table}
          onFilterChanded={filter => this.setState({ filter })}
          onHeadersToHideChanged={this.handleHeadersToHide}
          onPageChange={newPage =>
            this.runQueryProjectObject(null, newPage, this.state.limit)
          }
          onRowClicked={row_clicked => this.setState({ row_clicked })}
          onRowsPerPageChange={limit =>
            this.runQueryProjectObject(null, 0, limit)
          }
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.project_objects}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabProjectSettingObjects.propTypes = {
  classes: PropTypes.object,
};
TabProjectSettingObjects.defaultProps = {
  handleAddError: () => '',
  isAdmin: false,
  access_level: 0,
};
export default withStyles(TabProjectSettingObjects, useStyles);
