import React from 'react';
import PropTypes from 'prop-types';
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   TextField,
//   Button,
// } from '@mui/material';
// import SendIcon from '@mui/icons-material/Send';
// import DialogSubscribe from './DialogSubscribe';
class HomeFooterSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      email: '',
    };
  }
  handleSubscription = () => {
    if (this.state.email) this.setState({ open: true });
    else {
      this.props.handleAddError('Please enter an email');
    }
  };
  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };
  handleSubscribed = () => {
    this.setState({ email: '', open: false });
    this.props.handleAddSuccess(
      'Thanks very much for subscribing to our newsletter'
    );
  };
  render() {
    return 'hola subscribe';
    // return (
    //   <Box component="section" my={6} pt={6}>
    //     <Container>
    //       <Grid alignItems="center" container>
    //         <Grid item sx={{ ml: 'auto' }} />
    //         <Grid item md={4} sx={{ mr: 5 }} xs={12}>
    //           <Typography variant="h4">
    //             Be the first to see the news about our features and access
    //           </Typography>
    //           <Typography color="text" mb={3} variant="body2">
    //             Medical-Blocks is currently only being used within a limited
    //             number of clinical trials. If you&apos;re interested in learning
    //             more about Medical-Blocks and the possibility of using the
    //             system, please subscribe to receive information.
    //           </Typography>
    //         </Grid>
    //         <Grid
    //           alignItems="center"
    //           container
    //           item
    //           md={5}
    //           spacing={1}
    //           sx={{ mb: 10 }}
    //           xs={12}>
    //           <Grid item md={8} xs={12}>
    //             <TextField
    //               fullWidth
    //               label="Email Here"
    //               onChange={this.handleChangeEmail}
    //               size="small"
    //               type="email"
    //               value={this.state.email}
    //             />
    //           </Grid>
    //           <Grid item xs={4}>
    //             <Button
    //               onClick={this.handleSubscription}
    //               startIcon={<SendIcon />}
    //               sx={{ textTransform: 'none' }}
    //               variant="contained">
    //               Subscribe
    //             </Button>
    //           </Grid>
    //         </Grid>
    //         <Grid item sx={{ mr: 'auto', mt: 10 }} />
    //       </Grid>
    //     </Container>
    //     <DialogSubscribe
    //       email={this.state.email}
    //       handleAddError={this.props.handleAddError}
    //       handleChangeEmail={this.handleChangeEmail}
    //       handleClose={() => this.setState({ open: false })}
    //       handleSubscribed={this.handleSubscribed}
    //       open={this.state.open}
    //     />
    //   </Box>
    // );
  }
}

HomeFooterSubscribe.propTypes = {
  classes: PropTypes.object,
};

export default HomeFooterSubscribe;
