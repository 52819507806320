import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { RowText } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';
import RowTextMultiLine from 'components/Texts/RowTextMultiLine';
import Repetition from '../Repetition/Repetition';

class TestType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // expanded: false,
    };
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { test_type } = this.props;
    console.log({ test_type });
    if (IsInvalid(test_type)) return null;
    return (
      <Box>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="Test type:"
              second={this.getValue(test_type.type)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="SOREM N:"
              second={this.getValue(test_type.sorem_n)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Sleep latency:"
              second={this.getValue(test_type.sleep_latency)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="Amount repetitions:"
              second={this.getValue(test_type.nr_repetitions)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Instruction:"
              second={this.getValue(test_type.instruction)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
          <Grid item xs={12}>
            <RowTextMultiLine
              display="block ruby"
              first="Diagnose 1:"
              second={this.getValue(test_type.diagnose1)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Diagnose 2:"
              second={this.getValue(test_type.diagnose2)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Diagnose 3:"
              second={this.getValue(test_type.diagnose3)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
        </Grid>
        {/* {test_type.repetitions.length > 0 ? ( */}
        <Box sx={{ fontWeight: 1000, paddingLeft: 2 }}>Repetitions:</Box>
        {/* ) : (
          
        )} */}

        {test_type.repetitions.map((rep, index) => (
          <Box key={index}>
            <Repetition index={index + 1} repetition={rep} />
          </Box>
        ))}
        {test_type.repetitions.length === 0 ? (
          <Typography
            component="p"
            gutterBottom
            sx={{ paddingLeft: 3, paddingTop: 1 }}
            variant="p">
            No repetitions to show
          </Typography>
        ) : null}
      </Box>
    );
  }
}
TestType.propTypes = {
  classes: PropTypes.object,
};
export default TestType;
