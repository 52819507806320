import React, { Component } from 'react';
import path from 'common/path';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import DownloadIcon from '@mui/icons-material/GetApp';
import { green } from '@mui/material/colors';

import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';

import useStyles from './Styles';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import {
  ServerErrorsString,
  GetData,
  GetNameFromHeader,
  GetResponseError,
} from 'helpers';
import { SnackMessage } from 'components';
import clsx from 'clsx';
import { IsInvalid } from 'helpers';

const GetSize = item => {
  if (item === undefined || !item) return 0;
  const { Size, File, Series } = item;
  if (Size) return Size;
  if (Series && Series.Size) return Series.Size;
  if (File && File.Size) return File.Size;
  return 0;
};
class ButtonDownloadExplorerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      total_download: 0,
      selected: [],
    };
  }

  handleCloseSnak = () => {
    this.setState({ error: '' });
  };
  handleProgress = event => {
    const { loading, total_download, selected } = this.state;
    let { loaded, total } = event;
    if (isNaN(total) || total === 0 || total === undefined) {
      total = total_download;
    }
    if (this.props.handleProgress) {
      this.props.handleProgress({ loaded, total }, loading, selected);
    }
    if (!loading) {
      this.setState({ selected: [] });
    }
  };
  getSelected = () => {
    let total_download = 0;
    const { selected_content, selected_folder } = this.props;
    let selected = selected_content.map(x => ({
      idFolderContent: x.idFolderContent,
    }));
    selected = selected.concat(
      selected_folder.map(x => ({ idFolder: x.idFolder }))
    );
    if (selected_content.length) {
      total_download +=
        selected_content.map(x => GetSize(x)).reduce((a, b) => a + b, 0) * 0.3;
    }
    if (selected_folder.length) {
      total_download +=
        selected_folder.map(x => GetSize(x)).reduce((a, b) => a + b, 0) * 0.3;
    }
    return { selected, total_download };
  };
  handleDownload = () => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    const { selected, total_download } = this.getSelected();
    this.setState({ loading: true, total_download, selected });
    this.props.onDownloadClicked(selected, 'button');
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(selected);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            loading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          loading: false,
          error: ServerErrorsString(error),
        });
        this.props.onDownloadFinished(error);
        return;
      }
      console.log({ download_key });
      DownloadItemsAtKey(download_key, this.handleProgress)
        .then(async response => {
          let disposition = response.headers['Content-Disposition'];
          if (IsInvalid(disposition)) {
            disposition = response.headers['content-disposition'];
          }
          let name = 'download_all';
          let extension = 'zip';
          const { selected_content, selected_folder } = this.props;
          if (selected.length === 1) {
            if (selected_content.length) {
              const { Series, File } = selected_content[0];
              if (Series) {
                name = Series.SeriesDescription;
              } else if (File) {
                let ext = path.extname(File.original_name);
                name = path.basename(File.original_name, ext);
                if (ext) {
                  if (ext.startsWith('.')) {
                    ext = ext.replace('.', '');
                  }
                  extension = ext;
                }
              }
            } else if (selected_folder.length) {
              name = selected_folder[0].name;
            } else if (disposition && disposition.indexOf('filename=') !== -1) {
              name = GetNameFromHeader(response);
              extension = null;
            }
          }
          let file_name = `${name}.${extension}`;
          if (!extension) file_name = name;
          this.handleProgress({ loaded: 0 });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          this.setState({ loading: false });
          this.props.onDownloadFinished(null);
          await MutationDownloadFinished(download_key);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          this.setState({ loading: false });
          this.props.onDownloadFinished(error);
        });
    })();
  };

  render() {
    const {
      classes,
      selected_content,
      selected_folder,
      defaultProps,
      circle_size,
      circle_style,
      enable_badge,
      icon,
    } = this.props;
    const { error, loading } = this.state;
    const selected = selected_content.concat(selected_folder);
    if (selected.length === 0) {
      return null;
    }
    let selected_length = 0;
    if (enable_badge) {
      selected_length = selected.length;
    }
    if (icon) {
      defaultProps.children = icon;
    }
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <SnackMessage
            handleClose={this.handleCloseSnak}
            message_text={error !== '' ? error : 'Unknown warning'}
            open={error && error !== '' ? true : false}
            type="error"
          />
          <Tooltip title={this.props.tool_tip}>
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                {...this.props.iconProps}
                aria-controls="download-selected"
                onClick={this.handleDownload}
                size="large">
                {loading ? (
                  <DownloadIcon color="disabled" />
                ) : (
                  <Badge badgeContent={selected_length} {...defaultProps} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          {loading && (
            <CircularProgress
              className={clsx({
                [classes.fabProgress]: !circle_style,
                [circle_style]: circle_style,
              })}
              size={circle_size}
            />
          )}
        </div>
      </div>
    );
  }
}

ButtonDownloadExplorerContent.propTypes = {
  classes: PropTypes.object,
  defaultProps: PropTypes.object,
  enable_badge: PropTypes.bool,
  selected_content: PropTypes.array,
  selected_folder: PropTypes.array,
};

ButtonDownloadExplorerContent.defaultProps = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'secondary',
    children: <DownloadIcon style={{ color: green[800] }} />,
  },
  selected_content: [],
  selected_folder: [],
  iconProps: {},
  circle_style: null,
  circle_size: 35,
  handleProgress: null,
  enable_badge: true,
  tool_tip: 'Download Selected',
  onDownloadClicked: () => '',
  onDownloadFinished: () => '',
};
export default withStyles(ButtonDownloadExplorerContent, useStyles);
