import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { QueryProjectVariableTypes } from 'graphql/Projects/utils_project_types';
import { GetData } from 'helpers';
class ProjectVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      loading: false,
      idProject: null,
      name: null,
      page: 0,
      limit: 100,
      total: 0,
    };
  }
  componentDidMount() {
    this.updateTypes(this.props.idProject);
  }
  componentDidUpdate() {
    if (this.state.idProject !== this.props.idProject) {
      this.updateTypes(this.props.idProject);
    }
  }
  updateTypes = (idNewProject, newName, newPage, newLimit) => {
    let { loading, name, page, limit, idProject } = this.state;
    if (loading) return;
    if (idNewProject) idProject = idNewProject;
    if (idProject === undefined || !idProject) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectVariableTypes(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types, total } = data.projectVariableTypes;
          if (ok) {
            this.setState({
              idProject,
              loading: false,
              page,
              limit,
              types,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ idProject, loading: false });
        });
    })();
  };
  handleTypeNameChanged = event => {
    // setCount(event.target.value.length);
    // setUser({ ...user, FullName: event.target.value });
    this.updateTypes(null, event.target.value);
  };
  handleSelectionChanged = value => {
    this.props.onSelectionChanged(value);
  };
  render() {
    return (
      <Autocomplete
        getOptionLabel={option => option.type.name}
        id="tags-standard"
        multiple={this.props.multiple}
        onChange={(event, value) => this.handleSelectionChanged(value)}
        // defaultValue={[top100Films[13]]}
        options={this.state.types}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label="Project Variable Types"
            onChange={this.handleTypeNameChanged}
            placeholder="Select the variable type that you want to search"
            variant={this.props.variant}
          />
        )}
      />
    );
  }
}

ProjectVariableTypes.propTypes = {
  multiple: PropTypes.bool,
  variant: PropTypes.string,
};
ProjectVariableTypes.defaultProps = {
  onSelectionChanged: () => '',
  variant: 'standard',
  multiple: true,
};

export default ProjectVariableTypes;
