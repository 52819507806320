import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardActions } from '@mui/material';
import { ButtonCircularIconLoad } from 'components';

class CardButtonActions extends Component {
  render() {
    const { loading, changed } = this.props;
    return (
      <CardActions disableSpacing>
        {this.props.left && (
          <ButtonCircularIconLoad
            disabled={!this.props.changed_left}
            handleButtonClick={this.props.handleLeftClick}
            icon={this.props.icon_left}
            loading={this.props.loading_left}
            small
            tooltip={this.props.tooltip_left}
          />
        )}
        <span style={{ flexGrow: 1 }} />
        {this.props.right && (
          <ButtonCircularIconLoad
            disabled={!changed}
            handleButtonClick={this.props.handleSaveClicked}
            icon={this.props.icon_right}
            loading={loading}
            small
            tooltip={this.props.tooltip_right}
          />
        )}
      </CardActions>
    );
  }
}

CardButtonActions.propTypes = {
  classes: PropTypes.object,
  handleLeftClick: PropTypes.func,
  handleSaveClicked: PropTypes.func,
  icon_left: PropTypes.object,
  icon_right: PropTypes.object,
  left: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired,
  tooltip_left: PropTypes.string,
  tooltip_right: PropTypes.string,
};
CardButtonActions.defaultProps = {
  loading: false,
  loading_left: false,
  changed_left: false,
  left: false,
  right: true,
  icon_left: null,
  icon_right: null,
  tooltip_left: '',
  tooltip_right: '',
};

export default CardButtonActions;
