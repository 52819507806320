import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonLoad } from 'components';
import { Grid } from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { Replay as ReloadIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MutationAddAccountDockers,
  MutationRemoveAccountDockers,
} from 'graphql/Dockers/utils_docker_accounts';

class ToolBarAccountDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDelete = () => {
    let {
      account_dockers,
      new_accounts,
      selected,
      deleting,
    } = this.props.state;
    new_accounts = new_accounts.filter(x => {
      const i = selected.map(x => x.id).indexOf(x.id);
      if (i !== -1) selected.splice(x, 1);
      return i === -1;
    });
    console.log({ selected });
    if (!selected.length) {
      this.props.setState({ deleting: false, new_accounts });
      return;
    }
    if (deleting) return;
    this.props.setState({ deleting: true });
    (async () => {
      MutationRemoveAccountDockers(
        selected.map(x => {
          return {
            idAccountType: x.idAccountType,
            idDockerImage: x.idDockerImage,
          };
        })
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeAccountDockers;
          if (ok) {
            this.props.setState({
              deleting: false,
              new_accounts,
              account_dockers: account_dockers.filter(x => {
                const i = selected.map(x => x.id).indexOf(x.id);
                return i === -1;
              }),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  saveAccountDockers = () => {
    const { saving, state } = this.props;
    if (saving) return;
    let { account_dockers, new_accounts } = state;
    this.props.setState({ saving: true });
    const inputs = new_accounts.map(x => {
      return {
        idAccountType: x.idAccountType,
        idDockerImage: x.idDockerImage,
      };
    });
    (async () => {
      MutationAddAccountDockers(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAccountDockers;
          if (ok) {
            const new_account_dockers = data.addAccountDockers.account_dockers;
            new_accounts = new_accounts.filter(x => {
              const i = new_account_dockers
                .map(x => this.props.getId(x))
                .indexOf(this.props.getId(x));
              return i === -1;
            });
            this.props.setState({
              saving: false,
              new_accounts,
              account_dockers: new_account_dockers.concat(
                new_accounts.concat(account_dockers)
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, new_accounts, saving, state } = this.props;
    const disabled = new_accounts.length ? false : true;
    const { selected, deleting } = state;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <IconButtonLoad
          handleClick={this.handleDelete}
          icon={<DeleteIcon style={{ color: 'red' }} />}
          loading={deleting}
          small
          tooltip="Delete Selected"
        />
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        p={1}
        spacing={1}>
        <Grid container item xs={6}>
          <IconButtonLoad
            disabled={disabled || saving}
            handleClick={this.saveAccountDockers}
            icon={<SaveIcon style={{ color: disabled ? 'gray' : 'blue' }} />}
            loading={saving}
            small
            tooltip="Save Docker Image"
          />
          <IconButtonLoad
            handleClick={this.props.onOpenDialog}
            icon={<AddIcon style={{ color: 'green' }} />}
            small
            tooltip="Add Docker Image"
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={6}>
          {ComponentDelete}
          <IconButtonLoad
            handleClick={this.props.onReloadTable}
            icon={<ReloadIcon />}
            loading={loading}
            small
            tooltip="Reload Table"
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarAccountDocker.propTypes = {
  new_images: PropTypes.array,
};
ToolBarAccountDocker.defaultProps = {
  new_images: [],
};
export default ToolBarAccountDocker;
