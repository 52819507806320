export const useStyles = theme => ({
  root_gallery: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  control: {
    padding: theme.spacing(2),
  },
});
