import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import useStyles from 'components/Tables/components/Styles';
import { columns_errors, columns_logs } from './components/utils';
import { MainTableLogErrors, ToolbarLogsErrors } from './components';
import { rowsPerPageBig, SelectedArray } from 'helpers';

class TableLogErrors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
    const { holder } = props;
    if (holder) {
      holder.getSelected = () => {
        return this.state.selected;
      };
      holder.clearSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }

  isSelected = row => {
    const { selected } = this.state;
    const index = selected.map(x => x.id).indexOf(row.id);
    return index !== -1;
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.rows });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const {
      classes,
      rows,
      title,
      loading,
      deleting,
      expand_disable,
      height,
    } = this.props;
    const { page, limit, total } = this.props;
    const { selected } = this.state;
    let header_columns = [];
    if (this.props.columns) {
      header_columns = this.props.columns;
    } else if (expand_disable) {
      header_columns = columns_logs;
    } else {
      header_columns = columns_errors;
    }
    let style = {};
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    return (
      <Paper className={classes.root}>
        <ToolbarLogsErrors
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          handleSearchUser={this.props.handleSearchUser}
          handleTypeSearchChange={this.props.handleTypeSearchChange}
          holder={this.props.holder}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          options_search={this.props.options_search}
          placeholder_type={this.props.placeholder_type}
          title={title}
          type={this.props.type_selected}
        />
        <div className={classes.tableWrapper} style={{ ...style }}>
          <MainTableLogErrors
            classes={classes}
            columns={header_columns}
            dense={this.props.dense}
            disable_splice
            expand_disable={expand_disable}
            handleAcceptReject={this.props.handleAcceptReject}
            handleButtonView={this.props.handleButtonView}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.props.handleSelectedRow}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            nowrap={this.props.nowrap}
            page={page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={total > rows.length ? total : rows.length}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableLogErrors.propTypes = {
  classes: PropTypes.object,
};
TableLogErrors.defaultProps = {
  options_search: [],
  expand_disable: false,
  deleting: false,
  loading: false,
  dense: false,
  rows: [],
  selected: [],
  page: 0,
  limit: 5,
  columns: null,
  total: 0,
  nowrap: false,
};
export default withStyles(TableLogErrors, useStyles);
