import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: 0, 
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

const TableGridRowCellCheckbox = props => {
  const [checked, setChecked] = useState(props.value);

  const { onCheckboxChange } = props;

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const handleChange = event => {
    const value = event.target.checked;
    setChecked(value);
    onCheckboxChange(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Checkbox
        checked={checked}
        color="default"
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={handleChange}
        size="small"
      />
    </ThemeProvider>
  );
};

export default TableGridRowCellCheckbox;
