const useStyles = theme => ({
  list: {
    width: '100%',
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
  subheader: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  divider_item: {
    borderBottom: '1px solid #eaeaeb',
    paddingRight: '0',
  },
  divider_item_selected: {
    borderLeft: '3px solid red',
    borderBottom: '1px solid #eaeaeb',
    paddingRight: '0',
  },
  badge_index: {
    zIndex: '0',
  },
  header_black: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
});
export default useStyles;
