import { PAGE_DICOM_DISPLAY_SERIES, PAGE_DICOM_DISPLAY_STUDY } from './types';

export const setDisplayDicomSeries = series => {
  return {
    type: PAGE_DICOM_DISPLAY_SERIES,
    series,
  };
};
export const setDisplayDicomStudy = study => {
  return {
    type: PAGE_DICOM_DISPLAY_STUDY,
    study,
  };
};
