import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { MutationExecuteActionAtBatchProcess } from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';
import { ButtonGroupLoading } from 'components';
class ToolbarBatchProcesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
    };
  }
  handleRunJob = () => {
    const { running } = this.state;
    const { batch } = this.props;
    if (running) return;
    const { setting } = batch;
    this.setState({ running: true });
    const { project, tags, folder } = setting;
    let folder_structure = null;
    if (tags && tags.length) {
      folder_structure = tags
        .map(x => {
          if (x.tag === undefined) return x;
          return x.tag;
        })
        .join('/');
    }
    const inputs = [
      {
        idBatch: batch.id,
        setting: {
          folder_structure,
          own_folder: setting.own_folder,
          on_content: setting.on_content,
          idProject: project ? project.id : null,
          idFolder: folder ? folder.idFolder : null,
        },
        action: 1,
      },
    ];
    (async () => {
      MutationExecuteActionAtBatchProcess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.executeActionAtBatchProcess;
          if (ok) {
            this.setState({ running: false });
            this.props.handleAddSuccess(`Batch (${batch.id}) now is running`);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ running: false });
          const text = ServerErrorsString(error);
          console.log({ text, batch });
          if (batch) {
            this.props.handleUpdateBatches([{ ...batch, status: 'error' }]);
          }
          this.props.handleAddError(text);
        });
    })();
  };
  render() {
    const { classes, batch } = this.props;
    const { running } = this.state;
    return (
      <ButtonGroup
        className={classes.root}
        exclusive="false"
        size="small"
        value="center">
        <ButtonGroupLoading
          disabled={!batch}
          icon={
            <PlayArrowIcon
              style={{ color: batch && !running ? 'blue' : 'gray' }}
            />
          }
          loading={running}
          onClick={this.handleRunJob}
        />
        <Button disabled={!batch} onClick={this.props.handleOpenEdit}>
          <SettingsIcon
            style={{
              color: batch ? '#ffd600' : 'gray',
            }}
          />
        </Button>
      </ButtonGroup>
    );
  }
}

ToolbarBatchProcesses.propTypes = {
  classes: PropTypes.object,
};
ToolbarBatchProcesses.defaultProps = {
  handleRunBatchProcess: () => '',
};

export default ToolbarBatchProcesses;
