import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import {
  TabPatientClinics,
  TabPatientImages,
  TabPatientSignals,
  ToolBarPatientsActions,
  TabPeople,
} from './components';
import { TabPanel } from 'components';
import { IsInvalid } from 'helpers';
class Pipelines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      tab_value: 0,
      loading: false,
      view: 'people',
      tabs: [
        { name: 'People', TabComponent: TabPeople },
        { name: 'Images', TabComponent: TabPatientImages },
        { name: 'Signals', TabComponent: TabPatientSignals },
        { name: 'Clinical', TabComponent: TabPatientClinics },
      ],
    };
    this.holder = {
      reloadPeople: null,
      reloadDicoms: null,
      reloadSignals: null,
      reloadClinics: null,
      reloadPeopleClean: null,
      reloadSignalsClean: null,
      reloadDicomsClean: null,
      reloadClinicsClean: null,
    };
  }
  handleViews = (event, view) => {
    event.preventDefault();
    if (IsInvalid(view)) return;
    let tab_value = 0;
    if (view === 'images') tab_value = 1;
    else if (view === 'signals') tab_value = 2;
    else if (view === 'clinical') tab_value = 3;
    this.setState({ view, tab_value });
  };
  handleUpdate = () => {
    const { view } = this.state;
    if (view === 'images') {
      this.holder.reloadDicoms();
      this.holder.reloadDicomsClean();
    } else if (view === 'signals') {
      this.holder.reloadSignals();
      this.holder.reloadSignalsClean();
    } else if (view === 'clinical') {
      this.holder.reloadClinics();
      this.holder.reloadClinicsClean();
    } else {
      this.holder.reloadPeople();
      this.holder.reloadPeopleClean();
    }
  };
  handleChangeName = event => {
    const name = event.target.value;
    const { view } = this.state;
    if (view === 'images') this.holder.reloadDicoms(name);
    else if (view === 'signals') this.holder.reloadSignals(name);
    else if (view === 'clinical') this.holder.reloadClinics(name);
    else this.holder.reloadPeople(name);
  };
  render() {
    if (!this.props.isActive) return null;
    const { tabs, tab_value, view, selected } = this.state;
    return (
      <Grid
        container
        rowSpacing={1}
        style={{ padding: 4, paddingBottom: 0, paddingTop: 3 }}>
        <Grid item xs={12}>
          <ToolBarPatientsActions
            handleChangeName={this.handleChangeName}
            handleReloadTable={this.handleUpdate}
            handleViews={this.handleViews}
            loading={this.state.loading}
            selected={selected}
            view={view}
          />
        </Grid>
        <Grid item sx={{ padding: 1, minHeight: 400 }} xs={12}>
          <Paper>
            {tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  index={index}
                  key={`tab-patient-${index}`}
                  value={tab_value}>
                  <TabComponent {...this.props} holder={this.holder} />
                </TabPanel>
              );
            })}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

Pipelines.propTypes = {
  classes: PropTypes.object,
};

export default Pipelines;
