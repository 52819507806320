import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
class ButtonVerticalDots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      headerName,
      handleClick,
      open,
      isMouseOver,
      filter_active,
    } = this.props;
    return (
      <Box
        sx={{
          display: isMouseOver || filter_active ? 'flex' : 'none',
          alignItems: 'center',
          textAlign: 'center',
          padding: 0,
          margin: 0,
          width: 20,
        }}>
        <Tooltip title={`Filter header ${headerName}`}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, margin: 0, padding: 0 }}>
              {filter_active ? (
                <FilterAltIcon size="small" />
              ) : (
                <MoreVertIcon size="small" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  }
}

ButtonVerticalDots.propTypes = {
  filter_active: PropTypes.bool,
};
ButtonVerticalDots.defaultProps = {
  filter_active: false,
};

export default ButtonVerticalDots;
