import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_CREATE_BATCH_PROCESS = gql`
  mutation(
    $job_name: String
    $idDockerImage: LongLong
    $inputs: [InputBatch!]!
    $setting: InputBatchSetting!
  ) {
    createBatchProcess(
      job_name: $job_name
      idDockerImage: $idDockerImage
      inputs: $inputs
      setting: $setting
    ) {
      ok
      errors {
        path
        message
      }
      fails {
        idSeries
        idFileObject
        error
      }
    }
  }
`;

export const MutationCreateBatchProcess = (
  idDockerImage,
  inputs,
  in_setting
) => {
  let setting = {};
  if (in_setting) {
    // eslint-disable-next-line no-unused-vars
    const { count, project, selected_tags, ...res } = in_setting;
    setting = {
      ...res,
      idProject: project ? project.id : null,
      folder_structure:
        selected_tags && selected_tags.length
          ? selected_tags.map(x => x.tag).join('/')
          : null,
    };
  }
  console.log('MutationCreateBatchProcess', {
    idDockerImage,
    inputs,
    setting,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_BATCH_PROCESS),
    variables: { idDockerImage, inputs, setting },
  });
};
const QUERY_BATCH_PROCESS = gql`
  query($job_name: String, $page: Int, $limit: Int) {
    batchProcesses(job_name: $job_name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      batches {
        id
        user {
          id
          FullName
        }
        job_name
        total_jobs
        jobs_waiting
        jobs_running
        jobs_stopped
        jobs_finished
        jobs_error
        status
        setting {
          id
          idBatch
          folder_structure
          idProject
          idFolder
          tags
          folder {
            idFolder
            name
          }
          project {
            id
            name
          }
          own_folder
          on_content
          create_batch_log
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryBatchProcesses = job_name => {
  console.log('QueryBatchProcesses', { job_name });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_BATCH_PROCESS),
    variables: { job_name },
  });
};

const MUTATION_DELETE_BATCH_PROCESS = gql`
  mutation($ids: [LongLong!]!) {
    deleteBatchProcesses(ids: $ids) {
      ok
      errors {
        path
        message
      }
      fails {
        idSeries
        idFileObject
        error
      }
    }
  }
`;

export const MutationDeleteBatchProcesses = ids => {
  console.log('MutationDeleteBatchProcesses', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_BATCH_PROCESS),
    variables: { ids },
  });
};
const QUERY_JOBS_AT_BATCHES = gql`
  query($ids_batches: [LongLong!]!) {
    jobsAtBatches(ids_batches: $ids_batches) {
      ok
      errors {
        path
        message
      }
      total
      jobs {
        id
        idUser
        idSetting
        idDockerImage
        idBatch
        idSeries
        idFileObject
        idFolder

        status

        user {
          id
          FullName
        }

        docker_image {
          id
          operation
        }

        batch {
          id
          job_name
          status
          total_jobs
          jobs_waiting
          jobs_running
          jobs_stopped
          jobs_finished
          jobs_error
          setting {
            id
            idBatch
            folder_structure
            idProject
            idFolder
            idBatchLogFolder
            own_folder
            on_content
            create_batch_log
            tags
            folder {
              idFolder
              name
            }
            batch_log_folder {
              idFolder
              name
            }
            project {
              id
              name
            }
          }
        }
        setting {
          id
          idJob
          job_name
          own_folder
          on_content
          idFolder
          error
          folder_content {
            idFolderContent
            project {
              id
              name
            }
            Folder {
              idFolder
              name
              folders_paths {
                idFolder
                name
              }
            }
          }
          folder_project {
            idFolder
            name
          }
        }
        series {
          idSeries
          SeriesDescription
        }
        file_object {
          idFileObject
          original_name
        }
        folder {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryJobsAtBatches = ids_batches => {
  console.log('QueryJobsAtBatches', { ids_batches });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_JOBS_AT_BATCHES),
    variables: { ids_batches },
  });
};
const MUTATION_EXECUTE_ACTION_AT_BATCH_PROCESS = gql`
  mutation($inputs: [InputActionBatchProcess!]!) {
    executeActionAtBatchProcess(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationExecuteActionAtBatchProcess = inputs => {
  console.log('MutationExecuteActionAtBatchProcess', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_EXECUTE_ACTION_AT_BATCH_PROCESS),
    variables: { inputs },
  });
};
