import { grey } from '@mui/material/colors';

const useStyles = theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'black',
  },
  button: {
    margin: theme.spacing(1),
  },
  left: {
    marginLeft: 'auto',
  },
  button_grey: {
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[600],
    },
  },
  card_header: {
    width: '100%',
    padding: 0,
    margin: 0, //theme.spacing(0.5),
  },
});
export default useStyles;
