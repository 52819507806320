export const GetIdsFoldersContentToDelete = selected => {
  const folders_ids = selected.map(x => x.idFolder && x.Folder).filter(x => x);
  const content_ids = selected.map(x => x.idFolderContent).filter(x => x);
  return {
    folders_ids,
    content_ids,
  };
};
export const GetFoldersIds = folders => {
  const folders_ids = folders.map(x => {
    const { idFolder, idFolderContent } = x;
    if (idFolderContent) return null;
    return idFolder;
  })
    .filter(x => x);
  return folders_ids;
};
export const GetFolderContentIds = selected => {
  return selected.map(x => x.idFolderContent).filter(x => x);
};
