import React from 'react';
import { Grid } from '@mui/material';
import { StyleText } from 'components';

class StatusFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { status } = this.props;
    if (!status || !status.files) return null;
    return (
      <React.Fragment>
        <Grid item>
          <StyleText fontSize={18} fontWeight={1000} style text="Files:" />
        </Grid>
        <Grid item style={{ paddingRight: 10 }}>
          <StyleText
            fontSize={18}
            fontWeight={100}
            style
            text={`${status.files}`}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

StatusFiles.propTypes = {};

export default StatusFiles;
