import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadMsCSV } from './utils';

const QUERY_MS_MSFCS = gql`
  query($name: String, $page: Int, $limit: Int) {
    msMsfcs(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      msfcs {
        id
        idPatient
        sequence_number
        date
        walk_test_aid
        walk_test_time_1
        walk_test_time_2
        peg_test_handedness
        peg_test_right_time_1
        peg_test_right_time_2
        peg_test_left_time_1
        peg_test_left_time_2
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryMsMsfcs = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_MSFCS),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const MUTATION_REMOVE_MS_MSFCS = gql`
  mutation($ids: [LongLong!]!) {
    deleteMsMsfcs(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveMsMsfcs = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_MS_MSFCS),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_MS_MSFCS = gql`
  mutation($inputs: [InputMsMsfcs!]!) {
    addMsMsfcs(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      msfcs {
        id
        idPatient
        sequence_number
        date
        walk_test_aid
        walk_test_time_1
        walk_test_time_2
        peg_test_handedness
        peg_test_right_time_1
        peg_test_right_time_2
        peg_test_left_time_1
        peg_test_left_time_2
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddMsMsfcs = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_MS_MSFCS),
    variables: {
      inputs,
    },
  });
};

const MUTATION_UPLOAD_MS_MSFCS = gql`
  mutation($file: Upload!) {
    uploadMsMsfcs(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      msfcs {
        id
        idPatient
        sequence_number
        date
        walk_test_aid
        walk_test_time_1
        walk_test_time_2
        peg_test_handedness
        peg_test_right_time_1
        peg_test_right_time_2
        peg_test_left_time_1
        peg_test_left_time_2
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadMsMsfcs = (file, handleProgress = null) => {
  return MutationUploadMsCSV(file, MUTATION_UPLOAD_MS_MSFCS, handleProgress);
};

const QUERY_SEARCH_MS_MSFCS = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchMsMsfcs(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      msfcs {
        id
        idPatient
        sequence_number
        date
        walk_test_aid
        walk_test_time_1
        walk_test_time_2
        peg_test_handedness
        peg_test_right_time_1
        peg_test_right_time_2
        peg_test_left_time_1
        peg_test_left_time_2
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QuerySearchMsMsfcs = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_MS_MSFCS),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
