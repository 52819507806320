import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRunningProcesses } from 'redux-store/actions';
import { MutationExecuteProcess } from 'graphql/Dockers/utils_docker_process';
import { GetData, IsInvalid, IsValid, ServerErrorsString } from 'helpers';
import DialogJpgToDcmInput from 'components/Dialogs/DialogJpgToDcmInput';

class ExplorerMenuRunProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      open: false,
      id: '',
      itemOver: null,
    };
    if (props.holder) {
      props.holder.openRunProccess = id => {
        if (id === 'JPGs To DCM') {
          this.setState({ id, open: true, itemOver: this.props.itemOver });
        } else {
          this.executeProcess(id);
        }
      };
    }
  }
  getRandomInt = max => {
    const { running_processes } = this.props;
    let randomInt;
    let next = true;

    while (next) {
      randomInt = Math.floor(Math.random() * (max + 1));
      const found = running_processes.find(x => x.id === randomInt);
      if (!found) {
        next = false;
      }
    }

    return randomInt;
  };
  executeProcess = (docker_operation, params = null) => {
    const { running } = this.state;
    const {
      itemOver,
      selected_objects,
      CurrentFolder,
      running_processes
    } = this.props;
    let item_over = itemOver;
    console.log({
      CurrentFolder,
      docker_operation,
      selected_objects,
      params,
      itemOver,
    });
    if (IsInvalid(itemOver)) {
      item_over = this.state.itemOver;
    }
    if (running) return;
    this.setState({ running: false });
    const objects = [
      {
        // idSeries: itemOver.idSeries,
        idFolderContent: item_over.idFolderContent,
      },
    ];
    if (IsValid(params)) {
      objects[0].params = JSON.stringify(params);
    }
    if (selected_objects.length > 1) {
      let idFileObjects = [];
      let idSeries = [];
      for (let i = 0; i < selected_objects.length; i++) {
        const element = selected_objects[i];
        if (element.File) {
          idFileObjects.push(element.File.idFileObject);
        } else if (element.Series) {
          idSeries.push(element.Series.idSeries);
        }
      }
      if (idFileObjects.length > 0) {
        objects[0].idFileObjects = idFileObjects;
      }
      if (idSeries.length > 0) {
        objects[0].idSeries = idSeries[0];
      }
    } else {
      if (item_over.Series) {
        objects[0].idSeries = item_over.Series.idSeries;
      } else if (item_over.File) {
        objects[0].idFileObjects = [item_over.File.idFileObject];
      }
    }
    const input = {
      docker_operation,
      objects,
    };
    (async () => {
      MutationExecuteProcess(input)
        .then(res => {
          const data = GetData(res);
          console.log({ data });
          const { ok, errors, docker_process } = data.executeProcess;
          if (ok) {
            let newArray = JSON.parse(JSON.stringify(running_processes)); // deep copy
            this.setState({ running: false });
            for (let i = 0; i < docker_process.length; i++) {
              const element = docker_process[i];
              newArray.push(element);
            }
            this.props.setRunningProcesses(newArray);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('Error ExplorerMenuRunProcess', { error });
          this.setState({ running: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { id } = this.state;
    const { CurrentFolder } = this.props;
    if (id === 'JPGs To DCM') {
      return (
        <DialogJpgToDcmInput
          handleClose={() => this.setState({ open: false })}
          handleExecuteProcess={params => this.executeProcess(id, params)}
          idParent={CurrentFolder.idFolder}
          open={this.state.open}
        />
      );
    }
    return null;
  }
}

ExplorerMenuRunProcess.propTypes = {
  CurrentFolder: PropTypes.object,
  handleAddError: PropTypes.func,
  holder: PropTypes.object,
  isFolder: PropTypes.bool,
  itemOver: PropTypes.object,
  owner: PropTypes.bool,
};

const mapStateToProps = state => {
  const { running_processes } = state.running_processes;
  return {
    running_processes: running_processes ? running_processes : [],
  };
};
export default connect(mapStateToProps, { setRunningProcesses })(
  ExplorerMenuRunProcess
);
