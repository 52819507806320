import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
const useStyles = (theme, props) => ({
  root: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: props.size ? `${props.size / 5}px` : '10px',
    padding: '1em',
    paddingTop: props.height ? props.height / 2 - props.size / 2 : 200,
    paddingLeft: props.width ? props.width / 2 - props.size / 2 : 200,
    pointerEvents: 'none',
  },
});
class LoadingProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { value, classes, width, height, ...res } = this.props;
    if (value >= 100) return null;
    return (
      <div className={classes.root}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...res} value={value} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography
              color="text.secondary"
              component="div"
              sx={{ fontSize: '30px', fontWeight: 500 }}
              variant="caption">{`${Math.round(value)}%`}</Typography>
          </Box>
        </Box>
      </div>
    );
  }
}

LoadingProgress.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
};
LoadingProgress.defaultProps = {
  size: 200,
};

export default withStyles(LoadingProgress, useStyles);
