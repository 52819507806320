import React from 'react';
import PropTypes from 'prop-types';
import { webglDraw } from '../utils';
// import '../webgl-tutorials.css';
import { withStyles } from 'tss-react/mui';
import './components/webglslider.css';

const useStyles = () => ({
  uiContainer: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 3,
    fontFamily: 'monospace',
    pointerEvents: 'none',
    textShadow:
      '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
  },
});
import { WebGLSlider, SliderCameraPosition } from './components';
class WebGlSliders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // renderer: null,
    };
  }
  updateCameraAngle = (event, { value }) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.cameraAngleRadians = webglDraw.degToRad(value);
    renderer.drawScene();
  };
  updateFieldOfView = (event, { value }) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.camera.setFieldOfView(value);
    renderer.drawScene();
  };
  updateRadius = (event, { value }) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.radius = value;
    renderer.drawScene();
  };
  updateCameraPosition = (event, { value }) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.camZ = value;
    renderer.drawScene();
  };
  updateZoom = (event, { value }) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.camera.zoom = value;
    renderer.drawScene();
  };
  render() {
    const { classes, renderer } = this.props;
    if (renderer === undefined || !renderer) return null;
    return (
      <div className={classes.uiContainer}>
        <div id="ui_slider">
          {/* <WebGLSlider
            options={{
              value: webglDraw.radToDeg(renderer.cameraAngleRadians || 0),
              min: -360,
              max: 360,
              name: 'cameraAngle',
            }}
            slide={this.updateCameraAngle}
          /> */}
          {/* <WebGLSlider
            options={{
              value: renderer.camera.getFieldOfView(),
              min: 1,
              max: 179,
              name: 'fieldOfView',
            }}
            slide={this.updateFieldOfView}
          /> */}
          <WebGLSlider
            options={{
              value: renderer.zoom,
              min: 0.01,
              max: 100,
              step: 0.01,
              precision: 2,
              name: 'Zoom',
            }}
            slide={this.updateZoom}
          />
          <SliderCameraPosition renderer={renderer} />
          {/* <SliderTranslates renderer={renderer} />
          <SliderScales renderer={renderer} />
          <SliderAngles renderer={renderer} /> */}
        </div>
      </div>
    );
  }
}

WebGlSliders.propTypes = {
  renderer: PropTypes.object.isRequired,
};

export default withStyles(WebGlSliders, useStyles);
