import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { IsEditAllow } from 'helpers/Designers';
import {
  // Layouts
  Main as MainLayout,
  Minimal as MinimalLayout,
} from 'layouts';
import {
  Home as HomeView,
  HomePublicAccess as HomePublicAccessView,
  HomeAboutUs as HomeAboutUsView,
  Dashboard as DashboardView,
  Account as AccountView,
  Administrator as AdministratorView,
  DownloadUpload as DownloadUploadView,
  Explorer as ExplorerView,
  Projects as ProjectsView,
  Settings as SettingsView,
  Shared as SharedView,
  SignIn as SignInView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
} from 'views';
import RouteWithLayout from './RouteWithLayout';
export const GetDesignerRoutes = props => {
  const { user, ReactGA4 } = props;
  let RouteAdministrator = null;
  let RouteProject = null;
  let RouteExplorer = null;
  let RouteUploadDownload = null;
  if (IsEditAllow(user)) {
    RouteUploadDownload = (
      <RouteWithLayout
        component={DownloadUploadView}
        exact
        layout={MainLayout}
        path="/upload_download/:level_1?"
      />
    );
    RouteAdministrator = (
      <RouteWithLayout
        component={AdministratorView}
        exact
        layout={MainLayout}
        path="/administrator/:level_1?/:level_2?"
      />
    );
    RouteProject = (
      <RouteWithLayout
        component={ProjectsView}
        exact
        layout={MainLayout}
        path="/project_management/:item?/:level_1?/:level_2?/:level_3?/:level_4?"
      />
    );
    RouteExplorer = (
      <RouteWithLayout
        component={ExplorerView}
        exact
        layout={MainLayout}
        path="/explorer/:level_1?/:level_2?/:level_3?"
      />
    );
  }
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        path="/"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomePublicAccessView}
        exact
        path="/public_access/:level_1?/:level_2?/:level_3?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomeAboutUsView}
        exact
        path="/about-us"
        public_access
        ReactGA4={ReactGA4}
      />
      {RouteAdministrator}
      {RouteProject}
      {RouteExplorer}
      {RouteUploadDownload}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard/:level_1?/:level_2?"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account/:item?/:level_1?"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SharedView}
        exact
        layout={MinimalLayout}
        path="/shared/:type?/:id?/:idFolder?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        public_access
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};
{
  /* --------------------------------------------------- */
}
