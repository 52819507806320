import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { RowText } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';

class Repetition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { repetition, index } = this.props;
    console.log({ repetition });
    if (IsInvalid(repetition)) return null;
    return (
      <Box>
        <Box sx={{ fontWeight: 1000, paddingLeft: 3, paddingTop: 0.75 }}>
          Repetition {index}:
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ paddingLeft: 3, paddingBottom: 0.75 }}>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="Start date:"
              second={this.formatDate(repetition.start_datetime)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Sleep latency:"
              second={this.getValue(repetition.sleep_latency)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="REM latency:"
              second={this.getValue(repetition.rem_latency)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="KSS pre:"
              second={this.getValue(repetition.kss_pre)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="KSS post:"
              second={this.getValue(repetition.kss_post)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}
Repetition.propTypes = {
  classes: PropTypes.object,
};
export default Repetition;
