export const inputProps = {
  onKeyDown: event => {
    if (event.keyCode === 37) {
      // left arrow key
      // update the cursor position
      event.target.setSelectionRange(
        event.target.selectionStart - 1,
        event.target.selectionEnd - 1
      );
    } else if (event.keyCode === 39) {
      // right arrow key
      // update the cursor position
      event.target.setSelectionRange(
        event.target.selectionStart + 1,
        event.target.selectionEnd + 1
      );
    }
  },
};
