import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const SEARCH_MS_COMORBIDITIES = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchMs(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchComorbidities = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_MS_COMORBIDITIES),
    variables: { text, page, limit, table },
  });
};

const SEARCH_MS_MEDICATION = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchMs(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      medications {
        id
        idPatient
        date
        description
        active_ingredient
        active_ingredient_unknown
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchMedication = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_MS_MEDICATION),
    variables: { text, page, limit, table },
  });
};

const SEARCH_MS_EDSS = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchMs(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchEdss = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_MS_EDSS),
    variables: { text, page, limit, table },
  });
};

const SEARCH_MS_IMMUNOTHERAPY = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchMs(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      immunotherapies {
        id
        idPatient
        sequence_number
        name
        start_date
        stop_date
        stop_reason
        stop_reason_infection_susceptibility_to_infection
        stop_reason_insufficient_efficacy
        stop_reason_intolerance_side_effects
        stop_reason_patient_request_external_reasons
        stop_reason_safety
        stop_reason_therapy_change
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchImmunotherapy = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_MS_IMMUNOTHERAPY),
    variables: { text, page, limit, table },
  });
};

const SEARCH_MS_MSFC = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchMs(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      msfcs {
        id
        idPatient
        sequence_number
        date
        walk_test_aid
        walk_test_time_1
        walk_test_time_2
        peg_test_handedness
        peg_test_right_time_1
        peg_test_right_time_2
        peg_test_left_time_1
        peg_test_left_time_2
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchMsfc = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_MS_MSFC),
    variables: { text, page, limit, table },
  });
};
