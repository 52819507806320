import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { QueryVariableTypes } from 'graphql/Mapping/utils_mapping';
import { GetData, ServerErrorsString } from 'helpers';
import { useStyles } from '../Styles';
import { inputProps } from 'common';

class InputsVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    let defaultValue = null;
    if (props.value) {
      defaultValue = props.value;
    }
    this.state = {
      defaultValue,
      type: null,
      name: '',
      loading: false,
      types: [],
      open: false,
      length: 0,
      clean: false,
    };
    if (props.holder) {
      props.holder.cleanInput = () => {
        // console.log('cleanInput');
        this.setState({
          type: null,
          name: '',
          length: 0,
          open: false,
          clean: true,
        });
      };
      props.holder.addType = type => {
        const { types } = this.state;
        const index = types.map(x => x.id).indexOf(type.id);
        if (index === -1) {
          types.unshift(type);
          this.setState({ types });
        }
      };
    }
  }
  componentDidMount() {
    this.runSearchTypes();
  }
  handleTypeChanged = selected => {
    if (!selected || typeof selected === 'undefined') return;
    this.setState({ type: selected });
    this.props.onChange(selected);
  };
  handleTypeNameChanged = name => {
    if (name !== this.state.name) {
      // console.log('handleTypeNameChanged', { length, name });
      this.runSearchTypes(name);
      this.setState({ name });
    }
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddVariableType = () => {
    this.props.handleAddVariableType(this.state.type);
    this.setState({ name: '', type: null, length: 0, open: false });
  };
  runSearchTypes = new_name => {
    const { loading } = this.state;
    if (loading) return;
    (async () => {
      QueryVariableTypes(new_name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, types, total, errors } = data.variableTypes;
          if (ok) {
            if (!types.length) {
              types.push({ name: 'Not found' });
            }
            let length = 0;
            if (new_name) {
              length = new_name.length;
            }
            this.setState({
              types,
              total,
              loading: false,
              name: new_name ? new_name : '',
              length,
            });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('catch-UsersProjects', errors);
          this.setState({ types: [], loading: false });
          this.props.handleAddError(ServerErrorsString(errors));
        });
    })();
  };
  render() {
    const { types, open, loading, name } = this.state;
    const { textfield, enable_input_props } = this.props;
    const height = '40px';
    let style_input_props = null;
    if (enable_input_props) {
      style_input_props = {
        height,
        margin: 0,
        padding: 0,
        borderWidth: 0,
        border: 0,
      };
    }
    let inputValue = name;
    let others = {};
    if (this.state.defaultValue) {
      others = { defaultValue: this.state.defaultValue };
    }
    // console.log({ defaultValue: this.state.defaultValue, inputValue });
    return (
      <Autocomplete
        {...others}
        disabled={this.props.disabled}
        getOptionLabel={option => {
          if (option === undefined) return '';
          const { name } = option;
          if (name === undefined) return '';
          return name;
        }}
        id="search-variable-types-input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          this.handleTypeChanged(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleTypeNameChanged(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={types}
        renderInput={params => (
          <TextField
            {...params}
            {...textfield}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { style_input_props },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="type"
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.handleAddVariableType();
              }
            }}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
          />
        )}
        size={this.props.size}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
        value={this.state.type}
      />
    );
  }
}

InputsVariableTypes.propTypes = {
  classes: PropTypes.object.isRequired,
};
InputsVariableTypes.defaultProps = {
  size: 'small',
  textfield: {},
  placeholder: 'Search Variable types...',
  variant: 'standard',
  enable_input_props: true,
  multiple: true,
  value: null,
  filter: null,
  disabled: false,
  withname: true,
  handleAddVariableType: () => '',
  onChange: () => '',
  handleAddError: error => console.log({ error }),
};

export default withStyles(InputsVariableTypes, useStyles);
