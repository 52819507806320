export { default as Profile } from './Profile';
export { default as Cloud } from './Cloud';
export { default as Activity } from './Activity';
export { default as Logs } from './Logs';
export { default as RadiusProfileImage } from './RadiusProfileImage';

export const useStyles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  channels: {
    width: '100%',
    height: '100%',
    // overflowY: 'scroll',
    flexGrow: 1,
  },
  content: {
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  root_content_overview: {
    width: '100%',
    height: '70vh',
  },
  content_overview: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'scroll',
  },
  folder_container: {
    width: '100%',
    height: '100%',
    paddingBottom: 2,
    overflowY: 'scroll',
    minHeight: '25vh',
  },
  media: {
    height: 190,
  },
  rect: {
    height: 65,
    marginTop: -72,
    indexZ: -1,
  },
  avatar: {
    marginLeft: 20,
    marginTop: -75,
    // height: 110,
    // width: 100,
    flexShrink: 0,
    flexGrow: 0,
    indexZ: 2,
    borderWidth: 8,
    borderColor: theme.palette.white,
  },
  avatar_image: {
    height: 110,
    width: 100,
    // bgcolor: 'background.paper',
    borderColor: '#f4f6f8',
    m: 1,
    border: 6,
    style: { width: '6rem', height: '6rem' },
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto',
    indexZ: 1,
  },
  imgRounded: {
    borderRadius: '6px !important',
  },
  imgRoundedCircle: {
    borderRadius: '50% !important',
  },
  imgRaised: {
    boxShadow:
      '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
});
