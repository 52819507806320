import React from 'react';
import PropTypes from 'prop-types';
import { rowsPerPageBig } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import {
  copyFiles,
  copyJobFiles,
  setNewJobProcess,
} from 'redux-store/actions/file_folder_manager';
import { TableGrid } from 'components';
import { Box } from '@mui/material';
import { columns_files } from 'common';
import DialogConfigurationDockerInput from 'components/Dialogs/DialogConfigurationDockerInput';
import { GetData, ServerErrorsString, IsInvalid } from 'helpers';
import { QueryInputsBySettingId } from 'graphql/Dockers/utils_docker_setting';
import { MutationCreateJobProcess } from 'graphql/Process';

const useStyles = () => ({ root: {} });
class TabDataFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      page: 0,
      limit: 50,
      is_configure_input: false,
      process: null,
      new_jobs_file_objects: [],
    };
    const { holder } = props;
    if (holder) {
      holder.handleActionFiles = (action, process) =>
        this.handleAction(action, process);
      holder.handleDeleteFiles = () => this.handleDeleteSelected();
    }
  }
  handleDeleteSelected = () => {
    let { copy_files } = this.props;
    const { selected } = this.state;
    const ids = selected.map(x => x.idFileObject);
    copy_files = copy_files.filter(x => ids.indexOf(x.idFileObject) === -1);
    console.log({ selected, copy_files, ids });
    this.props.copyFiles(copy_files);
    this.props.cleanSelected();
    this.setState({ selected: [] });
  };
  handleCreateJobProcess = (
    new_jobs_file_objects,
    copy_files,
    docker_image
  ) => {
    const { creating } = this.state;
    if (creating) return;
    if (!new_jobs_file_objects.length) {
      this.props.handleAddError('You need to select a input data first');
      return;
    }
    console.log({ new_jobs_file_objects });
    this.setState({ creating: true });
    const inputs = new_jobs_file_objects.map(x => {
      let { job_input_objects, setting, volumes, idFileObject } = x;
      if (IsInvalid(job_input_objects)) {
        job_input_objects = [
          {
            idJob: x.idJob,
            idFileObject,
            volumes: volumes ? volumes : [],
          },
        ];
      }
      copy_files = copy_files.filter(c_f => {
        const i = job_input_objects
          .map(x => x.idFileObject)
          .indexOf(c_f.idFileObject);
        return i === -1;
      });
      return {
        idDockerImage: docker_image.id,
        idFileObject,
        job_setting: setting,
        job_input_objects,
      };
    });
    (async () => {
      MutationCreateJobProcess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, jobs, fails } = data.createJobs;
          if (ok) {
            console.log({ jobs, fails });
            this.props.copyFiles(copy_files);
            // this.props.handleTabChanged(1);
            this.setState({
              selected: [],
              process,
              creating: false,
            });
            this.props.setSelected([]);
            this.props.setNewJobProcess(jobs.length ? true : false);
            // this.props.setSelected(copy_files);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAction = async (action, process) => {
    let { selected } = this.state;
    const inputs = await this.getInputVolumes(process);
    if (inputs.length > 1) {
      this.setState({ is_configure_input: true });
    } else {
      this.setState({ is_configure_input: false });
    }
    if (process === undefined || process === null) {
      this.props.handleAddError('Invalid operation');
      return;
    }
    const check = process.settings.inputs_outputs.filter(
      x => x.type.toLowerCase() != 'dicom' && x.in_out_type === 'input'
    );
    if (!check.length) {
      this.props.handleAddError('Only accepting Files as input');
      return;
    }
    if (process.operation === 'None') {
      this.props.handleAddError('Invalid operation');
      return;
    }
    let { copy_files, jobs_files } = this.props;
    if (copy_files.length === 0) {
      this.props.handleAddError('No files to add to job');
      return;
    }
    if (jobs_files === undefined || !jobs_files) {
      jobs_files = [];
    }
    const ids = selected.map(x => x.idFileObject);
    if (action === 'run') {
      copy_files = copy_files.filter(x => ids.indexOf(x.idFileObject) === -1);
      let new_jobs_file_objects = [];
      for (let i = 0; i < selected.length; i++) {
        let volumes = [];
        let value = false;
        if (inputs.length === 1) value = true;
        inputs.forEach(input => {
          volumes.push({
            id: input.id,
            volume: input.volume,
            value,
          });
        });
        selected[i]['idJob'] = Math.floor(i / inputs.length) + 1;
        selected[i]['volumes'] = volumes;
        const index = new_jobs_file_objects
          .map(x => x.idFileObject)
          .indexOf(selected[i].idFileObject);
        if (index === -1) {
          new_jobs_file_objects.push({ ...selected[i] });
        }
      }
      new_jobs_file_objects = new_jobs_file_objects.map(x => ({
        ...x,
        process,
      }));
      console.log('new_jobs_file_objects', { new_jobs_file_objects });
      this.setState({ new_jobs_file_objects });
      this.handleCreateJobProcess(new_jobs_file_objects, copy_files, process);
    } else if (action === 'copy') {
      copy_files = copy_files.filter(x => ids.indexOf(x.idFileObject) === -1);
      for (let i = 0; i < selected.length; i++) {
        console.log({ jobs_files });
        let volumes = [];
        let value = false;
        if (inputs.length === 1) value = true;
        inputs.forEach(input => {
          volumes.push({
            id: input.id,
            volume: input.volume,
            value,
          });
        });
        selected[i]['idJob'] = Math.floor(i / inputs.length) + 1;
        selected[i]['volumes'] = volumes;
        const index = jobs_files
          .map(x => x.idFileObject)
          .indexOf(selected[i].idFileObject);
        console.log({ index });
        if (index === -1) {
          jobs_files.push(selected[i]);
        }
      }
      this.props.copyFiles(jobs_files);
      this.props.copyJobFiles(jobs_files);
      this.props.handleTabChanged(1);
      this.setState({ selected: [] });
      this.props.setSelected(jobs_files);
    }
  };
  handleSelected = selected => {
    this.setState({ selected });
    this.props.setSelected(selected);
  };
  groupBy = (xs, key) => {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  getInputVolumes = async process => {
    const inputs = await QueryInputsBySettingId(process.settings.id)
      .then(async res => {
        const data = GetData(res);
        const { ok, errors, inputs } = data.getInputsBySettingId;
        if (ok) {
          return inputs;
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.props.handleAddError(ServerErrorsString(error));
      });
    return inputs;
  };
  render() {
    const { height, copy_files } = this.props;
    const { is_configure_input, process, new_jobs_file_objects } = this.state;
    return (
      <Box>
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          headers={columns_files}
          height={height - 240}
          idRow="idFileObject"
          limit={this.state.limit}
          onSelectedChanged={this.handleSelected}
          page={this.state.page}
          rows={copy_files}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        {process && is_configure_input ? (
          <DialogConfigurationDockerInput
            handleAddError={this.props.handleAddError}
            handleClose={() => this.setState({ is_configure_input: false })}
            handleUpdateJobs={jobs => this.handleUpdateJobs(jobs)}
            open={is_configure_input}
            process={process}
            row_jobs={new_jobs_file_objects}
          />
        ) : null}
      </Box>
    );
  }
}

TabDataFiles.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_files, jobs_files } = state.manager;
  let size = copy_files ? copy_files.length : 0;
  if (jobs_files) size += jobs_files.length;
  return {
    copy_files: copy_files ? copy_files : [],
    jobs_files: jobs_files ? jobs_files : [],
    size,
  };
};

export default connect(mapStateToProps, {
  copyFiles,
  copyJobFiles,
  setNewJobProcess,
})(withStyles(TabDataFiles, useStyles));
