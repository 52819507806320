import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData } from 'helpers';
import { QueryAccountCategories } from 'graphql/Accounts';
import { useStyles } from '../Styles';
import { inputProps } from 'common';
class InputAccountCategories extends React.Component {
  constructor(props) {
    super(props);
    let defaultValue = null;
    if (props.defaultValue) {
      defaultValue = props.defaultValue;
    }
    this.state = {
      value: null,
      defaultValue,
      categories: [],
      name: '',
      loading: false,
      open: false,
      length: 0,
    };
  }
  componentDidMount() {
    this.runSearchCategory('');
  }

  componentDidUpdate() {
    const { length, name } = this.state;
    if (length !== name.length) {
      // console.log({ length, name });
      this.runSearchCategory(name);
    }
  }
  runSearchCategory = new_name => {
    let { loading, disabled, name } = this.state;
    if (loading || disabled) return;
    this.setState({
      loading: true,
      users: [],
      name: new_name,
    });
    if (new_name) {
      name = new_name;
    }
    (async () => {
      QueryAccountCategories(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, categories, total } = data.accountCategories;
          if (ok) {
            this.setState({
              loading: false,
              categories,
              total,
              name,
              length: name.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ categories: [], loading: false });
        });
    })();
  };
  handleCategoryChanged = selected => {
    if (!selected || typeof selected === 'undefined') return;
    this.props.onChange(selected);
    this.setState({ value: selected });
  };
  handleCategoryNameChanged = name => {
    if (name !== this.state.name) {
      // console.log('handleCategoryNameChanged', { name });
      this.runSearchCategory(name);
      this.setState({ name });
    }
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddCategories = () => {
    // const { user } = this.state;
    this.setState({ name: '', categories: [], length: 0, open: false });
    this.props.handleAddCategories();
  };
  render() {
    let { categories, open, loading, name, value } = this.state;
    const { textfield, enable_input_props } = this.props;
    const height = '40px';
    let style_input_props = null;
    if (enable_input_props) {
      style_input_props = {
        height,
        margin: 0,
        padding: 0,
        borderWidth: 0,
        border: 0,
      };
    }
    let inputValue = name;
    let others = {};
    if (this.state.defaultValue) {
      others = { defaultValue: this.state.defaultValue };
    }
    if (this.props.value) {
      value = this.props.value;
    }
    return (
      <Autocomplete
        {...others}
        disabled={this.props.disabled}
        getOptionLabel={option => {
          if (option === undefined) return '';
          const { name } = option;
          if (name === undefined) return '';
          return name;
        }}
        id="search-categories-input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          this.handleCategoryChanged(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleCategoryNameChanged(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={categories}
        renderInput={params => (
          <TextField
            {...params}
            {...textfield}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { style_input_props },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            label={this.props.label}
            name="category"
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.handleAddCategories();
              }
            }}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
        value={value}
      />
    );
  }
}

InputAccountCategories.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enable_input_props: PropTypes.bool,
  filter: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  textfield: PropTypes.object,
  value: PropTypes.object,
  variant: PropTypes.string,
};
InputAccountCategories.defaultProps = {
  textfield: {},
  placeholder: 'Search Category...',
  variant: 'standard',
  enable_input_props: true,
  multiple: false,
  value: null,
  filter: null,
  disabled: false,
  handleAddCategories: () => '',
  onChange: () => '',
  label: 'Category',
};
export default withStyles(InputAccountCategories, useStyles);
