import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTable, MainTableToolbar } from 'components/Tables/components';
import { columns } from './utils';
import { SelectedArray, rowsPerPageBig } from 'helpers';
const useStyles = () => ({
  root: {},
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
});

class TableProjectTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  setSelected = selected => {
    if (this.props.handleSetSelected) {
      this.props.handleSetSelected(selected);
    } else {
      this.setState({ selected });
    }
  };
  getSelected = () => {
    if (this.props.holder.getSelected) {
      return this.props.holder.getSelected();
    } else {
      return this.state.selected;
    }
  };
  isSelected = file => {
    const selected = this.getSelected();
    const index = selected.map(x => x.id).indexOf(file.id);
    return index !== -1;
  };
  handleSelectedClick = (event, index, file) => {
    event.preventDefault();
    const selected = this.getSelected();
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(file.id)
      : 0;
    const newSelected = SelectedArray(selected, file, selectedIndex);
    this.setSelected(newSelected);
    return newSelected.length;
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setSelected(this.props.project_teams);
    } else {
      this.setSelected([]);
    }
  };
  handleClearSelected = () => {
    this.setSelected([]);
  };

  render() {
    const { classes, height } = this.props;
    const selected = this.getSelected();
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={this.props.loading}
          numSelected={selected.length}
          title="Centers Teams"
          transform
        />
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            dense
            disable_checkbox={this.props.disable_checkbox}
            disable_splice={this.props.disable_splice}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={this.props.limit}
            loading={this.props.loading}
            nowrap={this.props.nowrap}
            page={this.props.page}
            rows={this.props.project_teams}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={this.props.total}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
          SelectProps={{
            disabled: this.props.loading,
          }}
        />
      </Paper>
    );
  }
}

TableProjectTeams.propTypes = {
  classes: PropTypes.object.isRequired,
  disable_splice: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  handleSetSelected: PropTypes.func.isRequired,
  holder: PropTypes.object.isRequired,
};
TableProjectTeams.defaultProps = {
  disable_splice: true,
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  project_teams: [],
  selected: [],
  total: 0,
  holder: { getSelected: null },
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: null,
  disable_checkbox: false,
};
export default withStyles(TableProjectTeams, useStyles);
