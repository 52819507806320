import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TablePagination,
  CardActions,
} from '@mui/material';
import { EditBottom } from './components';
import { CardEditHeader, GroupBox } from 'components';
import { MainTable } from 'components/Tables/components';
import { EditSelectedType } from './components';
import { columns } from 'components/Tables/TableVariableTypes/components';
import { rowsPerPageSmall } from 'helpers';

class EditVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      selected: null,
      to_edit: null,
    };
  }
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };
  handleSelectedRow = selected => {
    this.setState({ selected });
  };
  handleUpdateToEdit = to_edit => {
    this.setState({ to_edit });
  };
  handleUpdatedVarType = selected => {
    this.setState({ selected });
    this.props.handleUpdatedVarType(selected);
  };
  render() {
    const { selected } = this.state;
    return (
      <Card>
        <CardEditHeader
          handleClose={this.props.handleCloseEdit}
          title="Edit Variable Type"
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <MainTable
              columns={columns}
              dense
              disable_checkbox
              handleSelectedRow={this.handleSelectedRow}
              limit={this.state.limit}
              page={this.state.page}
              rows={this.props.types}
            />
            <TablePagination
              component="div"
              count={this.props.types.length}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBox title_box="Click Variable type to Edit">
            <EditSelectedType
              handleUpdateToEdit={this.handleUpdateToEdit}
              selected={selected}
            />
          </GroupBox>
        </CardContent>
        <CardActions disableSpacing>
          <EditBottom
            handleAddError={this.props.handleAddError}
            handleDisableAll={disable_all => this.setState({ disable_all })}
            handleUpdatedVarType={this.handleUpdatedVarType}
            original={this.state.selected}
            to_edit={this.state.to_edit}
          />
        </CardActions>
      </Card>
    );
  }
}

EditVariableTypes.propTypes = {
  classes: PropTypes.object,
};

export default EditVariableTypes;
