/* eslint-disable indent */
import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { IsInvalid } from 'helpers';
import { CheckAxionHeaders } from 'helpers';

const QUERY_USER_DASHBOARD = gql`
  query($idUser: LongLong!) {
    userDashboard(idUser: $idUser) {
      ok
      errors {
        path
        message
      }
      setting_dashboard {
        idDashboard
        idUser
        idProject

        visualization_mode
        datestart
        dateend
        fullHistory
        selected_project {
          id
          name
        }

        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUserDashboard = idUser => {
  // eslint-disable-next-line indent
  if (IsInvalid(idUser) || idUser < 1) {
    console.log('QueryUserDashboard - Invalid:', { idUser });
    return;
  }
  CheckAxionHeaders();
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_DASHBOARD),
    variables: {
      idUser,
    },
  });
};

const MUTATION_UPDATE_DASHBOARD_SETTING = gql`
  mutation($Setting: InputDashboardSetting!) {
    updateDashboardSetting(Setting: $Setting) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateDashboardSettings = Setting => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_DASHBOARD_SETTING),
    variables: {
      Setting,
    },
  });
};
