import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import { IsInvalid, GetData, ServerErrorsString } from 'helpers';
import { getVariables } from 'common';
import { QueryRunSummaryPathologies, dataDefault } from './utils_pathologies';
import { initialState } from 'redux-store/reducers/page_settings';

const limit = 5;

class QuerySummaryPathologies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    const variables = {
      page: 0,
      limit,
      ...getVariables(this.props),
    };
    (async () => {
      QueryRunSummaryPathologies(variables)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SummaryPathology;
          if (ok) {
            this.props.setState({ loading: false });
            this.setState({
              data: this.getData(data.SummaryPathology),
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getEmpty = () => {
    return dataDefault;
  };
  getData = SummaryPathology => {
    const data = this.getEmpty();
    const { ok, Pathologies, Total, errors } = SummaryPathology;
    if (!ok) {
      const error = ServerErrorsString(errors);
      if (this.state.error !== error) {
        this.setState({ error });
      }
      return data;
    }
    let total = Pathologies.length;
    if (total >= 10) {
      total = 10;
    }
    data.labels = Pathologies.map(x => x.Pathology.name);
    const other = Pathologies.reduce((accumulator, object) => {
      return accumulator + object.total;
    }, 0);
    if (IsInvalid(data.datasets)) {
      console.log('Pathologies');
    }
    data.datasets[0].data = Array(total).fill(0, 0);
    for (let i = 0; i < total; i++) {
      const objc = Pathologies[i];
      const value = (objc.total / (Total ? Total : 1)) * 100;
      data.datasets[0].data[i] = parseFloat(value).toFixed(1);
    }
    const value = ((Total - other) / (Total ? Total : 1)) * 100;
    if (value > 0 && data.datasets[0].data.length && Total > limit) {
      data.labels.push('Other');
      data.datasets[0].data.push(parseFloat(value).toFixed(1));
    }
    return data;
  };
  getSubTitles = data => {
    const elements = [];
    for (let i = 0; i < data.datasets[0].data.length; i++) {
      elements.push({
        title: data.labels[i],
        value: `${data.datasets[0].data[i]}`,
        color: data.datasets[0].backgroundColor[i],
      });
    }
    return elements;
  };

  render() {
    const { children } = this.props;
    let { data, loading } = this.state;
    if (IsInvalid(data)) data = this.getEmpty();
    const titles = this.getSubTitles(data);
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        data,
        titles,
        loading,
      });
    });
  }
}

const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, { logout })(QuerySummaryPathologies);
