import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Grid, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { ProjectCard } from './components';
import {
  CreateProject,
  ProjectsToolBar,
  DialogDeleteWarning,
} from 'components';
import useStyles from './Styles';
import { GetData, rowsPerPageBig, ServerErrorsString } from 'helpers';
import { IsProjectAdministrator } from 'helpers/Designers';
import { MutationDeleteProjects } from 'graphql/Projects';

class Browser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_createproject: false,
      open_delete: false,
      project_selected: null,
    };
  }

  handleCreate = () => {
    const { user } = this.props;
    if (!IsProjectAdministrator(user)) return;
    const { open_createproject } = this.state;
    this.setState({ open_createproject: !open_createproject });
  };
  handleCloseCreate = finished => {
    if (finished) {
      this.props.reload();
    }
    this.setState({ open_createproject: false });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.props.reload(newPage, this.props.limit);
  };

  handleChangeRowsPerPage = event => {
    event.preventDefault();
    const limit = parseInt(event.target.value, 10);
    this.props.reload(0, limit);
  };
  handleSearchProject = () => {
    this.props.reload(this.props.page, this.props.limit);
  };
  handleDeleteProject = () => {
    const { deleting, project_selected } = this.state;
    if (deleting || !project_selected) return;
    this.setState({ open_delete: false, deleting: true });
    (async () => {
      MutationDeleteProjects([project_selected.id])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, failed } = data.deleteProjects;
          if (ok) {
            this.setState({ deleting: false, project_selected: null });
            const index = failed.indexOf(project_selected.id);
            if (index === -1) {
              this.props.handleDeletedProjects([project_selected.id]);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, project_selected: null });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const {
      classes,
      projects,
      reload,
      height,
      total,
      page,
      limit,
      loading,
    } = this.props;
    const { open_createproject, project_selected } = this.state;
    // console.log({ user: this.props.user });
    return (
      <React.Fragment>
        <ProjectsToolBar
          handleCreate={this.handleCreate}
          handleSearchProject={this.handleSearchProject}
          holder={this.props.holder}
          loading={loading}
          reload={reload}
          sub_title="Your Current Projects"
          title="BROWSER PROJECTS"
          user={this.props.user}
        />
        <CreateProject
          handleAddError={this.props.handleAddError}
          handleCloseCreate={this.handleCloseCreate}
          open={open_createproject}
        />
        <div style={{ height: height - 295, padding: 20, overflow: 'auto' }}>
          {loading ? (
            <Grid
              alignItems="center"
              className={classes.grid}
              container
              direction="column"
              justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {projects &&
                projects.map(project => (
                  <Grid item key={project.id} lg={4} md={6} xs={12}>
                    <ProjectCard
                      handleDeleteProject={project_selected =>
                        this.setState({ open_delete: true, project_selected })
                      }
                      project={project}
                      user={this.props.user}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end">
          <TablePagination
            component="div"
            count={total}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: false, project_selected: null })
          }
          handleDeleteFolders={this.handleDeleteProject}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`You are about to delete the selected project ${
            project_selected ? project_selected.name : ''
          }, this action will delete the Project and all related object and links to the project`}
          title_text={`Delete Project ${
            project_selected ? project_selected.name : ''
          }`}
        />
      </React.Fragment>
    );
  }
}

Browser.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
};

export default withStyles(withRouter(Browser), useStyles);
