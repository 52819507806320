import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import {
  HTTP_ADDRESS_GRAPHQL_SOFTWARE,
  HTTP_ADDRESS_DOWNLOAD_SOFTWARE,
} from 'config';
import { MutationUploadInputs } from 'common';

const QUERY_SOFTWARES = gql`
  query($name: String, $page: Int, $limit: Int) {
    allSoftware(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      softwares {
        id
        name
        os
        architecture
        filename
        version
        downloads
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySoftwares = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined') variables.name = name;
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (page === undefined || limit === undefined) {
    variables.page = 0;
    variables.limit = 10;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL_SOFTWARE, {
    query: print(QUERY_SOFTWARES),
    variables,
  });
};

const MUTATION_CREATE_SOFTWARE = gql`
  mutation(
    $name: String!
    $os: String!
    $architecture: String!
    $version: String!
    $downloads: Int
  ) {
    createSoftware(
      name: $name
      os: $os
      architecture: $architecture
      version: $version
      downloads: $downloads
    ) {
      ok
      errors {
        path
        message
      }
      software {
        id
        name
        os
        architecture
        filename
        version
        downloads
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationCreateSoftware = variables => {
  console.log('MutationCreateSoftware', { variables });
  return axios.post(HTTP_ADDRESS_GRAPHQL_SOFTWARE, {
    query: print(MUTATION_CREATE_SOFTWARE),
    variables,
  });
};
const MUTATION_UPLOAD_SOFTWARE_FILE = gql`
  mutation($file: Upload!, $idSoftware: Int!) {
    uploadSoftwareFile(file: $file, idSoftware: $idSoftware) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutatioUploadSoftwareFile = (
  file,
  idSoftware,
  handleProgress = null
) => {
  const query = { file, idSoftware };
  return MutationUploadInputs(
    query,
    MUTATION_UPLOAD_SOFTWARE_FILE,
    handleProgress,
    HTTP_ADDRESS_GRAPHQL_SOFTWARE
  );
};
const MUTATION_UPDATE_SOFTWARES = gql`
  mutation($inputs: [InputSoftware!]!) {
    updateSoftwares(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      softwares {
        id
        name
        os
        architecture
        filename
        version
        downloads
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUpdateSoftwares = variables => {
  console.log('MutationUpdateSoftwares', { variables });
  return axios.post(HTTP_ADDRESS_GRAPHQL_SOFTWARE, {
    query: print(MUTATION_UPDATE_SOFTWARES),
    variables: {
      inputs: variables.map(x => {
        const { id, name, os, architecture, version } = x;
        return {
          id,
          name,
          os,
          architecture,
          version,
        };
      }),
    },
  });
};

export const MutationDownloadSoftware = (software, handleProgress = null) => {
  console.log('MutationDownloadSoftware', { software });
  const { filename, os, architecture, version, name } = software;
  if (!handleProgress) {
    // eslint-disable-next-line no-unused-vars
    handleProgress = event => {
      // const { loaded, total } = event;
      // const progress_all = Math.floor((loaded * 100) / total);
    };
  }
  return axios({
    url: `${HTTP_ADDRESS_DOWNLOAD_SOFTWARE}/${filename}`,
    method: 'GET',
    responseType: 'blob', // important
    headers: {
      software: name,
      version: version,
      architecture: architecture,
      os: os,
      name: name,
      'Access-Control-Allow-Origin': '*',
    },
    onDownloadProgress: event => handleProgress(event),
  });
};
