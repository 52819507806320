import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { MutationUploadVariableTypesCSV } from 'graphql/Mapping/utils_var_types';
import { ServerErrorsString, GetData } from 'helpers';
import ToolBarMapping from '../../../ToolBarMapping';
const useStyles = theme => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
  button: {
    textTransform: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
});
class TableVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }
  handleUploadTypesFile = event => {
    const { uploading, page, limit } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadVariableTypesCSV(files[0], page, limit, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types, total } = data.uploadVariableTypesCSV;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddVariableTypes(types, total);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { selected } = this.props;
    return (
      <ToolBarMapping
        enable_upload={this.props.enable_upload}
        handleClearSelected={this.props.handleClearSelected}
        handleOpenCreate={this.props.handleOpenCreate}
        handleOpenEdit={this.props.handleOpenEdit}
        handleReload={this.props.handleReload}
        handleUpload={this.handleUploadTypesFile}
        hide_reload
        loading={this.props.loading}
        selected={selected}
        type="variable_types"
        uploading={this.state.uploading}
      />
    );
  }
}

TableVariableTypes.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
};

export default withStyles(TableVariableTypes, useStyles);
