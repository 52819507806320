import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_TEAMS = gql`
  query($idUser: LongLong!, $name: String, $page: Int, $limit: Int) {
    userCenters(idUser: $idUser, name: $name, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      centers {
        id
        name
        admin {
          id
          FullName
        }
        address {
          city
          country
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUsersCenters = (idUser, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_TEAMS),
    variables: {
      idUser,
      page,
      limit,
    },
  });
};
