import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import useStyles from './Sytles';
import { FormatTime, getInitials, IsOlder } from 'helpers';

class TeamChannelRow extends Component {
  render() {
    const { classes, channel, selected, handleChannelSelection } = this.props;
    const { team, lastMessage, createdAt, name, notread } = channel;
    let avatar = '/images/teams/team_0.png';
    let message = '';
    if (team.avatar) {
      avatar = team.avatar;
    }
    if (lastMessage) {
      const { text } = lastMessage;
      message = text;
    }
    let date = FormatTime(createdAt, 'HH:mm');
    if (IsOlder(createdAt)) {
      date = FormatTime(createdAt, 'MMM Do, YYYY');
    }
    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          button
          className={clsx({
            [classes.divider_item]: !selected,
            [classes.divider_item_selected]: selected,
          })}
          dense
          onClick={() => handleChannelSelection(channel)}>
          <ListItemAvatar>
            <Avatar alt={getInitials(name)} src={avatar} />
          </ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.header_black }}
            primary={name}
            secondary={`${message !== '' ? message.substring(0, 20) : ''}...`}
          />
          <ListItemText>
            <Grid alignItems="center" container direction="column">
              <Grid item>
                <Typography>{date}</Typography>
              </Grid>
              {notread ? (
                <Grid item>
                  <Badge
                    anchororigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    badgeContent={notread}
                    // children={''}
                    className={classes.badge_index}
                    color="error"
                  />
                </Grid>
              ) : null}
            </Grid>
          </ListItemText>
        </ListItem>
      </React.Fragment>
    );
  }
}

TeamChannelRow.propTypes = {
  channel: PropTypes.object.isRequired,
  classes: PropTypes.object,
  selected: PropTypes.bool.isRequired,
};
TeamChannelRow.defaultProps = {
  selected: false,
};

export default withStyles(TeamChannelRow, useStyles);
