import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { BadgeCustom } from 'components';
import { copySeries, copyFiles } from 'redux-store/actions/file_folder_manager';
class ButtonGroupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  copySeriesToExplorer = () => {
    const { copy_series, copy_files, selected } = this.props;
    // console.log({ copy_series, copy_files, selected });
    const total = copy_series.length;
    const total_files = copy_files.length;
    for (let i = 0; i < selected.length; i++) {
      let {
        idObject,
        idSeries,
        idFileObject,
        type,
        name,
        createdAt,
        updatedAt,
      } = selected[i];
      if (idSeries !== undefined && idSeries) {
        const index = copy_series.map(x => x.idSeries).indexOf(idSeries);
        if (index === -1) {
          copy_series.push({
            ...selected[i],
          });
        }
      } else if (idFileObject !== undefined && idFileObject) {
        const index = copy_files.map(x => x.idFileObject).indexOf(idFileObject);
        if (index === -1) {
          copy_files.push({
            ...selected[i],
          });
        }
      } else if (type === 'Series') {
        const index = copy_series.map(x => x.idSeries).indexOf(idObject);
        console.log('Series', { index, idObject });
        if (index === -1) {
          copy_series.push({
            idSeries: idObject,
            SeriesDescription: name,
            createdAt,
            updatedAt,
          });
        }
      } else {
        const index = copy_files.map(x => x.idFileObject).indexOf(idObject);
        console.log('Files', { index, idObject });
        if (index === -1) {
          copy_files.push({
            idFileObject: idObject,
            original_name: name,
            createdAt,
            updatedAt,
          });
        }
      }
    }
    const success = total < copy_series.length;
    const success_files = total_files < copy_files.length;
    // console.log({ total, copy_series, l: copy_series.length });
    // console.log({ total_files, copy_files, l: copy_files.length });
    if (success) {
      this.props.copySeries(copy_series);
      this.props.handleAddSuccess(
        `${copy_series.length} series copied for explorer`
      );
    } else if (total !== copy_series.length) {
      this.props.handleAddError('The series are already copied');
    }
    if (success_files) {
      this.props.copyFiles(copy_files);
      this.props.handleAddSuccess(
        `${copy_files.length} files copied for explorer`
      );
    } else if (total_files !== copy_files.length) {
      this.props.handleAddError('The files are already copied');
    }
  };
  render() {
    const { numSelected } = this.props;
    return (
      <ButtonGroup exclusive="false" size="small" value="center">
        <Button
          disabled={!numSelected}
          onClick={this.props.handleOpenEdit}
          sx={{ paddingBottom: 1 }}>
          <Tooltip title="Edit selected">
            <BadgeCustom badgeContent={numSelected}>
              <BorderColorIcon
                style={{
                  color: numSelected ? 'green' : 'gray',
                }}
              />
            </BadgeCustom>
          </Tooltip>
        </Button>
        <Button disabled={!numSelected} onClick={this.copySeriesToExplorer}>
          <Tooltip title="Copy all selected to explorer">
            <CopyAllIcon
              style={{
                color: numSelected ? 'blue' : 'gray',
              }}
            />
          </Tooltip>
        </Button>
      </ButtonGroup>
    );
  }
}

ButtonGroupEdit.propTypes = {
  size: PropTypes.number,
};

const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let size = copy_series ? copy_series.length : 0;
  if (copy_files) size += copy_files.length;
  return {
    copy_series: copy_series ? copy_series : [],
    copy_files: copy_files ? copy_files : [],
    size,
  };
};

export default connect(mapStateToProps, { copySeries, copyFiles })(
  ButtonGroupEdit
);
