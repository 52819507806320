import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { TableRowDoubleCell } from 'components/Tables/components';

class TabDockerImageFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { image, height } = this.props;
    if (!image || image === undefined) return null;
    let CMD = '';
    let ENTRYPOINT = '';
    let EXPOSE = '';
    let VOLUME = '';
    let ENV = [];
    const { Config } = image;
    if (Config) {
      if (Config.Cmd) CMD = Config.Cmd.join(',');
      if (Config.Entrypoint) ENTRYPOINT = Config.Entrypoint.join(',');
      if (Config.ExposedPorts) EXPOSE = Config.ExposedPorts.join(',');
      if (Config.Volumes) VOLUME = Config.Volumes.join(',');
      if (Config.Env) ENV = Config.Env;
    }
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
        overflowY: 'auto',
      };
    }
    return (
      <TableContainer component={Paper} style={style}>
        <Table aria-label="caption table">
          <caption>A basic about the dockerfile of the selected image</caption>
          <TableBody>
            <TableRowDoubleCell
              name="CMD"
              style={{ fontWeight: 1000 }}
              value={CMD}
            />
            <TableRowDoubleCell
              name="ENTRYPOINT"
              style={{ fontWeight: 1000 }}
              value={ENTRYPOINT}
            />
            <TableRowDoubleCell
              name="EXPOSE"
              style={{ fontWeight: 1000 }}
              value={EXPOSE}
            />
            <TableRowDoubleCell
              name="VOLUME"
              style={{ fontWeight: 1000 }}
              value={VOLUME}
            />
            <TableRow>
              <TableCell
                align="left"
                style={{ verticalAlign: 'top', fontWeight: 1000 }}>
                ENV
              </TableCell>
              <TableCell>
                <Table size="small">
                  <TableBody>
                    {ENV.map((env, index) => {
                      const parts = env.split('=');
                      let name = 'Value';
                      let value = env;
                      if (parts.length > 1) {
                        name = parts[0];
                        value = parts[1];
                      }
                      return (
                        <TableRowDoubleCell
                          key={`cell-env-${index}`}
                          name={name}
                          value={value}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabDockerImageFile.propTypes = {
  classes: PropTypes.object,
};

export default TabDockerImageFile;
