import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { QueryUsersDockers } from 'graphql/Users';
import {
  GetData,
  ServerErrorsString,
  rowsPerPageBig,
  IsInvalid,
} from 'helpers';
import { columns_dockers } from './utils_dockers';
import { DialogSelectDocker, ToolBarUserDocker } from './components';
class UserViewDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      dockers: [],
      new_images: [],
      saving: false,
      idUser: -1,
      open_dialog: false,
      selected: [],
      deleting: false,
    };
  }
  componentDidMount() {
    this.loadUserDockers();
  }
  componentDidUpdate() {
    this.loadUserDockers();
  }
  loadUserDockers = (force, newPage, newLimit) => {
    const { edit_user } = this.props;
    if (IsInvalid(edit_user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === edit_user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryUsersDockers(edit_user.id, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, dockers, total, errors } = data.userDockers;
          if (ok) {
            this.setState({
              idUser: edit_user.id,
              dockers,
              total,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddImage = image => {
    const { dockers, new_images } = this.state;
    const all = dockers.concat(new_images);
    if (IsInvalid(image)) {
      this.setState({ open_dialog: false });
      return;
    }
    const index = all.map(x => x.id).indexOf(image.id);
    if (index !== -1) {
      this.setState({ open_dialog: false });
      return;
    }
    new_images.unshift(image);
    this.setState({ new_images, open_dialog: false });
  };
  render() {
    const { dockers, loading, new_images } = this.state;
    return (
      <React.Fragment>
        <ToolBarUserDocker
          handleAddError={this.props.handleAddError}
          loading={loading}
          new_images={new_images}
          onOpenDialog={() => this.setState({ open_dialog: true })}
          onReloadTable={() => this.loadUserDockers(true)}
          saving={this.state.saving}
          setState={value => this.setState({ ...value })}
          state={this.state}
          user={this.props.edit_user}
        />
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_dockers}
          height={this.props.height - 160}
          loading={loading}
          onPageChange={page =>
            this.loadUserDockers(true, page, this.state.limit)
          }
          onRowsPerPageChange={limit => this.loadUserDockers(true, 0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={dockers.concat(new_images)}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogSelectDocker
          handleAddImage={this.handleAddImage}
          handleClose={() => this.setState({ open_dialog: false })}
          open={this.state.open_dialog}
        />
      </React.Fragment>
    );
  }
}

UserViewDockers.propTypes = {
  classes: PropTypes.object,
};

export default UserViewDockers;
