import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';

import useStyles from '../Style';
import { GetData, ServerErrorsString } from 'helpers';
import { DialogDeleteWarning, SnackMessage } from 'components';
import { Delete } from 'icons';
import { DeleteStudyMutation } from 'graphql/Study';

class ButtonDeleteStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      open: false,
    };
  }

  handleOpenDialog = () => {
    const { loading } = this.state;
    if (loading) return;

    this.setState({ open: true });
  };
  handleDeleteWarningClick = (shared, delete_object) => {
    if (!delete_object) {
      this.setState({ open: false, error: 'Canceled by the user' });
      return;
    }
    this.handleDelete();
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };
  handleDelete = () => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ open: false });
      return;
    }
    const { Study } = this.props;
    this.setState({ loading: true, open: false });
    (async () => {
      DeleteStudyMutation(Study.idStudy)
        .then(res => {
          const data = GetData(res);
          const {
            DeleteStudy: { ok, errors },
          } = data;
          if (ok) {
            this.setState({ loading: false, error: '' });
            this.props.handleStudyDeleted(Study);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            error: ServerErrorsString(error),
            loading: false,
          });
        });
    })();
  };

  render() {
    const { classes, title_text, question_text, shared } = this.props;
    const { loading, error, open } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <SnackMessage
            handleClose={this.handleCloseSnak}
            message_text={error !== '' ? error : 'Unknown warning'}
            open={error && error !== '' ? true : false}
            type="error"
          />
          <DialogDeleteWarning
            handleClose={() => this.setState({ open: false })}
            handleDeleteFolders={this.handleDeleteWarningClick}
            loading={this.state.loading}
            open={open}
            question_text={question_text}
            shared={shared}
            title_text={title_text}
          />
          <Tooltip title="Delete Selected">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                aria-controls="delete-folders"
                // className={classes.download}
                onClick={this.handleOpenDialog}
                size="large">
                {loading ? <DeleteIcon color="disabled" /> : <Delete />}
              </IconButton>
            </span>
          </Tooltip>
          {loading && (
            <CircularProgress className={classes.fabProgress} size={36} />
          )}
        </div>
      </div>
    );
  }
}

ButtonDeleteStudy.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  question_text: PropTypes.string,
  title_text: PropTypes.string,
};

ButtonDeleteStudy.defaultProps = {
  item: {},
  title_text: null,
  question_text: null,
};

export default withStyles(ButtonDeleteStudy, useStyles);
