import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from './Styles';

class ItemsNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: false,
      messages: false,
      uploads: false,
      chat: false,
      set: false,
    };
  }

  set = () => {
    const { notifications } = this.props;
    if (typeof notifications === 'undefined') return;
    const { set } = this.state;
    //
    if (!set && typeof notifications.emails !== 'undefined') {
      this.setState({ ...this.state, ...notifications, set: true });
    }
  };

  componentDidMount() {
    this.set();
  }

  componentDidUpdate() {
    this.set();
  }

  handleChange = event => {
    const { notifications } = this.state;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    this.setState({ ...notifications, [name]: checked });
    this.props.handleChange(event);
  };

  render() {
    let { emails, messages, uploads, chat } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={emails}
                    color="primary"
                    name="emails"
                    onChange={this.handleChange}
                  />
                }
                label="Email"
                style={{ margin: 0, padding: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={uploads}
                    color="primary"
                    name="uploads"
                    onChange={this.handleChange}
                  />
                }
                label="Upload Notifications"
                style={{ margin: 0, padding: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={messages}
                    color="primary"
                    name="messages"
                    onChange={this.handleChange}
                  />
                }
                label="Chat Messages"
                style={{ margin: 0, padding: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chat}
                    color="primary"
                    name="chat"
                    onChange={this.handleChange}
                  />
                }
                label="Private chat messages"
                style={{ margin: 0, padding: 0 }}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

ItemsNotifications.propTypes = {
  classes: PropTypes.object,
};
export default ItemsNotifications;
