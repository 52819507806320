import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CardForgot, CardLogin, useStyles } from './components';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      error: '',
    };
  }

  handleBack = () => {
    this.props.history.goBack();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleLoginForgot = login => {
    this.setState({ login });
  };

  render() {
    const { classes } = this.props;
    const { login } = this.state;
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  The good physician treats the disease; the great physician
                  treats the patient who has the disease.
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    Sir William Osler
                  </Typography>
                  <Typography className={classes.bio} variant="body2">
                    Founder Professor of Johns Hopkins Hospital
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack} size="large">
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                {login ? (
                  <CardLogin
                    classes={classes}
                    handleLoginForgot={this.handleLoginForgot}
                    ReactGA4={this.props.ReactGA4}
                  />
                ) : (
                  <CardForgot handleLoginForgot={this.handleLoginForgot} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(withRouter(SignIn), useStyles);
