import React, { useState } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { ButtonCircularIconLoad, CardHeaderCustom, DivGrow } from 'components';
import { theme, useStyles } from './Styles';

const ProjectNotifications = props => {
  const {
    title,
    subtitle,
    save,
    handleSave,
    maximize,
    show,
    notifications: { emails, messages, uploads, chat },
  } = props;

  const { classes } = useStyles();
  // eslint-disable-next-line
  const [save_on, setSaveOn] = useState(save ? save : false);

  const handleChange = name => event => {
    props.handleChangedChecked(name, event.currentTarget.checked);
  };
  if (!show && !maximize) return null;

  return (
    <Card className={classes.root}>
      <CardHeaderCustom
        subheader={subtitle ? subtitle : 'Manage the notifications'}
        title={title ? title : 'Notifications'}
      />
      <Divider variant="middle" />
      <CardContent className={classes.content}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emails}
                      color="primary"
                      onChange={handleChange('emails')} //
                    />
                  }
                  label="Email"
                  style={{ margin: 0, padding: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={uploads}
                      color="primary"
                      onChange={handleChange('uploads')} //
                    />
                  }
                  label="Upload Notifications"
                  style={{ margin: 0, padding: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={chat}
                      color="primary"
                      onChange={handleChange('chat')}
                    />
                  }
                  label="Chat Messages"
                  style={{ margin: 0, padding: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={messages}
                      color="primary"
                      onChange={handleChange('messages')}
                    />
                  }
                  label="Private chat messages"
                  style={{ margin: 0, padding: 0 }}
                />
              </Grid>
            </Grid>
          </ThemeProvider>
        </StyledEngineProvider>
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={classes.bottom} disableSpacing>
        <DivGrow />
        {save_on && (
          <Button color="primary" variant="outlined">
            Save
          </Button>
        )}
        {handleSave && (
          <ButtonCircularIconLoad
            // disabled={!changed}
            // handleButtonClick={this.handleSave}
            // loading={loading_saving}
            small
          />
        )}
      </CardActions>
    </Card>
  );
};

ProjectNotifications.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object,
};
ProjectNotifications.defaultProps = {
  maximize: false,
  show: true,
  notifications: {
    emails: false,
    messages: false,
    uploads: false,
    chat: false,
  },
};
export default ProjectNotifications;
