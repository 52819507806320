import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        select: {
          paddingTop: 0, // Remove top padding
          paddingBottom: 0, // Remove bottom padding
          marginTop: 0, // Remove top margin
          marginBottom: 0, // Remove bottom margin
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove border
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Reduce padding
          padding: '20px', // Adjust the padding as needed
        },
      },
    },
  },
});

const TableGridSelect = props => {
  const { categories, selectedValue, onCheckboxChange } = props;

  const handleChange = event => {
    const value = event.target.value;
    onCheckboxChange(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Select onChange={handleChange} value={selectedValue ?? ''}>
        {categories.map((cat, index) => (
          <MenuItem key={index} value={cat}>
            {cat}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
};

export default TableGridSelect;
