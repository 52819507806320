import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';

class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getEmail = () => this.state.email;
    }
  }

  componentDidMount() {
    const { email } = this.props;
    if (this.state.email !== email && typeof email !== 'undefined') {
      this.setState({ email });
    }
  }

  handleChange = event => {
    //
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes, error } = this.props;
    const { email } = this.state;
    return (
      <React.Fragment>
        <Typography>
          {`Enter your email and we'll 
			send you a code to reset your password.`}
        </Typography>
        <TextField
          className={classes.margin_double}
          disabled={this.props.loading}
          error={error !== ''}
          fullWidth
          id="email"
          InputLabelProps={{
            shrink: true,
          }}
          label="E-mail"
          name="email"
          onChange={this.handleChange}
          required
          type="email"
          value={email}
          variant="outlined"
        />
      </React.Fragment>
    );
  }
}

StepOne.propTypes = {
  classes: PropTypes.object,
};

export default StepOne;
