import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
class AscendingDescending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onSortHeader, header, order, orderBy } = this.props;
    let colorAsc = order === 'asc' ? 'blue' : 'gray';
    let colorDesc = order === 'desc' ? 'blue' : 'gray';
    if (header.field !== orderBy) {
      colorAsc = colorDesc = 'gray';
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="center"
        spacing={2}
        style={{ padding: 5 }}>
        <Grid item>
          <Button
            onClick={() => onSortHeader('asc', header.field)}
            size="small"
            startIcon={<SortByAlphaIcon style={{ color: colorAsc }} />}
            style={{
              textTransform: 'none',
              backgroundColor: 'transparent',
              color: colorAsc,
            }}
            variant="outlined">
            Ascending
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => onSortHeader('desc', header.field)}
            size="small"
            startIcon={<SortByAlphaIcon style={{ color: colorDesc }} />}
            style={{
              textTransform: 'none',
              backgroundColor: 'transparent',
              color: colorDesc,
            }}
            variant="outlined">
            Descending
          </Button>
        </Grid>
      </Grid>
    );
  }
}

AscendingDescending.propTypes = {
  classes: PropTypes.object,
};

export default AscendingDescending;
