import React from 'react';
import PropTypes from 'prop-types';
import { TableSiteProjectActivities } from './components';
import { QuerySiteProjectActivity } from 'graphql/Administration';
import { GetData, ServerErrorsString } from 'helpers';
import { SearchDoubleText } from '../index';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    margin: theme.spacing(1),
    height: 'calc(100% - 20)',
  },
  search: {
    height: '42px',
    margin: theme.spacing(1),
  },
});

class TabActivityProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      loading: false,
      total: 0,
    };
    this.holder = {
      getPageLimit: null,
    };
  }

  handleSearchUser = (username, projectname) => {
    const { page, limit } = this.holder.getPageLimit();
    this.runUpdate(username, projectname, page, limit);
  };
  runUpdate = (username, projectname, page = null, limit = null) => {
    const { loading } = this.state;
    if (loading) return;
    const page_limit = this.holder.getPageLimit();
    if (!limit && page_limit) {
      page = page_limit.page;
      limit = page_limit.limit;
    }
    this.setState({ loading: true });
    (async () => {
      QuerySiteProjectActivity(username, projectname, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, total, activities, errors } = data.SiteProjectActivity;
          if (ok) {
            this.setState({ loading: false, activities, total, page, limit });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false, page, limit });
        });
    })();
  };
  handleChangePageLimit = (page, limit) => {
    this.runUpdate(null, null, page, limit);
  };

  render() {
    const { classes } = this.props;
    const { loading, activities, total } = this.state;
    return (
      <div>
        <SearchDoubleText
          classes={classes}
          handleSearchText={this.handleSearchUser}
          holder={this.holder}
          id="search-text-notification-errors"
          loading={loading}
          placeholderone="Search User..."
          placeholdertwo="Search Project..."
        />
        <TableSiteProjectActivities
          handleChangePageLimit={this.handleChangePageLimit}
          //   handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={() => this.runUpdate()}
          height={this.props.height - 435}
          holder={this.holder}
          loading={loading}
          rows={activities}
          title="Projects Activities"
          total={total}
        />
      </div>
    );
  }
}

TabActivityProjects.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabActivityProjects, useStyles);
