import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Typography } from '@mui/material';

import { DivGrow, SearchInput } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    height: '42px',
    margin: theme.spacing(1),
  },
  textTop: {
    marginBottom: theme.spacing(2),
  },
}));

const UsersToolbar = props => {
  const {
    className,
    handleUserSearch,
    handleAddCollaborators,
    ...rest
  } = props;

  const { classes } = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography className={classes.textTop} component="div">
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            m={1}
            variant="button">
            MANAGEMENT
          </Box>
          <Box fontSize={22} fontWeight="fontWeightBold" m={1} spacing={3}>
            Collaborators
          </Box>
          <Box m={1}> </Box>
        </Typography>
        <DivGrow />
        <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          onClick={handleAddCollaborators}
          variant="contained">
          Add Collaborator
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={handleUserSearch}
          placeholder="Search user by email..."
        />
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
};

export default UsersToolbar;
