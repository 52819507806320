import {
  LOGOUT_DICOM_VIEWER,
  PAGE_DICOM_DISPLAY_SERIES,
  PAGE_DICOM_DISPLAY_STUDY,
} from '../actions/types';

const initialState = {
  series: null,
  study: null,
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case LOGOUT_DICOM_VIEWER:
      return initialState;
    case PAGE_DICOM_DISPLAY_SERIES:
      return {
        ...state,
        series: action.series,
      };
    case PAGE_DICOM_DISPLAY_STUDY:
      return {
        ...state,
        study: action.study,
      };
    default:
      return state;
  }
};

export default reducer;
