import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { Checkbox, TableCell, TableRow } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TableRowQueryResultSeries } from '../index';
import CircularProgress from '@mui/material/CircularProgress';

import { GetData, getDatePacs, getTimePacs } from 'helpers';
import { QuerySeriesAtStudy } from 'graphql/Series';
import { PatientName, StudyFiles, useRowStyles } from './Styles';

class TableRowQueryResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      Series: [],
    };
    const { holder } = props;
    if (holder) {
      holder.rowsSeries.push(this.handleDeleteSelected);
      holder.getSeries.push(() => this.state.Series);
    }
  }

  componentDidUpdate() {
    const { loading } = this.props;
    if (loading && this.state.Series.length) {
      this.setState({ Series: [], open: false });
    }
  }

  handleDeleteSelected = selectedSeries => {
    if (selectedSeries.length === 0) return;
    const { Series } = this.state;
    const newRows = [];
    for (let i = 0; i < Series.length; i++) {
      const { idSeries } = Series[i];
      const index = selectedSeries.map(x => x.idSeries).indexOf(idSeries);
      if (index === -1) {
        newRows.push(Series[i]);
      }
    }
    this.setState({ Series: newRows });
  };

  handlerOpen() {
    const { open, Series } = this.state;
    if (open) {
      this.setState({ open: false });
      return;
    }
    if (Series && Series.length) {
      this.setState({ open: true });
      return;
    }
    const { row, bar_holder } = this.props;
    if (!row) {
      return;
    }
    const { idStudy } = row;
    if (!idStudy) {
      return;
    }
    const queries = {};
    if (bar_holder) {
      const {
        getModalities,
        getImageType,
        getTabImageType,
        getFilters,
      } = bar_holder;
      let modalities = null;
      let imagetype = {};
      if (getFilters) {
        const filters = getFilters();
        if (filters) {
          if (filters.id_sequences) {
            queries.id_sequences = filters.id_sequences;
          }
          if (filters.project) {
            queries.id_projects = [filters.project.id];
          }
        }
      }
      if (getModalities) {
        modalities = getModalities();
      }
      if (getTabImageType) {
        imagetype = getTabImageType();
      }
      if (Object.keys(imagetype).length === 0 && getImageType) {
        imagetype = getImageType();
      }
      if (Object.keys(imagetype).length) {
        queries.imagetype = imagetype;
      }
      if (modalities && Object.keys(modalities).length) {
        queries.modalities = modalities;
      }
    } else {
      /* empty */
    }

    this.setState({ loading: true });
    (async () => {
      const new_state = {
        open: !open,
        loading: false,
      };
      try {
        const results = QuerySeriesAtStudy(idStudy, queries);
        const [res] = await Promise.all([results]);
        const data = GetData(res);
        const { Series } = data.Series;
        if (Series !== undefined) {
          new_state.Series = Series;
          this.props.addNumberOfSeries(Series);
        }
      } catch (error) {
        /* empty */
      }
      this.setState(new_state);
    })();
  }

  render() {
    const {
      row,
      classes,
      handleSelectedSeries,
      handleSelectedStudy,
      selectedSeries,
      selectedStudy,
    } = this.props;
    const { open, loading, Series } = this.state;
    let numSelected = 0;
    for (let i = 0; i < selectedSeries.length; i++) {
      const { idSeries } = selectedSeries[i];
      if (Series.map(x => x.idSeries).indexOf(idSeries) !== -1) {
        numSelected++;
      }
    }
    const rowCount = Series.length;
    const checked = numSelected ? numSelected === rowCount : false;
    const indeterminate = numSelected > 0 && numSelected < rowCount;
    // if (selectedStudy)
    //   console.log({ numSelected, rowCount, checked, indeterminate });
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell className={classes.cell_expand}>
            <IconButton
              aria-label="expand row"
              onClick={() => this.handlerOpen()}
              size="small">
              {loading ? (
                <CircularProgress />
              ) : open ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell className={classes.cell_empty}>
            <Checkbox
              checked={selectedStudy || checked}
              indeterminate={indeterminate}
              onClick={() => {
                handleSelectedStudy(row);
              }}
            />
          </TableCell>
          <TableCell>{row.idStudy}</TableCell>
          <TableCell className={classes.cell_text} component="th" scope="row">
            {PatientName(row.Patient)}
          </TableCell>
          <TableCell align="left" className={classes.cell_row}>
            <div style={{ marginLeft: 10 }}>{StudyFiles(row)}</div>
          </TableCell>
          <TableCell align="left" className={classes.cell_row}>
            {row.Patient.PatientID}
          </TableCell>
          <TableCell align="center" className={classes.cell_row}>
            {getDatePacs(row.Patient.PatientBirthDate)}
          </TableCell>
          <TableCell align="left" className={classes.cell_row}>
            {row.StudyDescription}
          </TableCell>
          <TableCell align="left" className={classes.cell_row}>
            <div style={{ marginLeft: 10 }}>{row.AccessionNumber}</div>
          </TableCell>
          <TableCell
            align="center"
            className={classes.cell_row}
            sx={{ marginRight: 5 }}>
            {row.ModalitiesInStudy}
          </TableCell>
          <TableCell align="right" className={classes.cell_row}>
            {getDatePacs(row.StudyDate)}
          </TableCell>
          <TableCell align="center" className={classes.cell_row}>
            {getTimePacs(row.StudyTime)}
          </TableCell>
          <TableCell className={classes.cell_row}>
            {row.StudyInstanceUID}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={this.props.headers.length + 2}
            style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableRowQueryResultSeries
                classes={classes}
                handleSelectedSeries={handleSelectedSeries}
                selected={selectedSeries}
                selectedStudy={selectedStudy}
                Series={Series}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

TableRowQueryResult.propTypes = {
  row: PropTypes.shape({
    StudyDescription: PropTypes.string,
    ModalitiesInStudy: PropTypes.string.isRequired,
    StudyDate: PropTypes.string.isRequired,
    StudyTime: PropTypes.string,
    StudyInstanceUID: PropTypes.string.isRequired,
    NumberOfStudyRelatedInstances: PropTypes.number.isRequired,
    NumberOfStudyRelatedSeries: PropTypes.number.isRequired,
    Patient: PropTypes.shape({
      FamilyName: PropTypes.string.isRequired,
      GivenName: PropTypes.string.isRequired,
      MiddleName: PropTypes.string.isRequired,

      PatientID: PropTypes.string.isRequired,
      PatientBirthDate: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
TableRowQueryResult.defaultProps = {
  selectedSeries: [],
  headers: [],
};
export default withStyles(TableRowQueryResult, useRowStyles);
