import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import useStyles from '../components/Styles';
import { MainTable, MainTableToolbar } from '../components';
import { columns } from './components';
import { GenerateSearchName, rowsPerPageSmall } from 'helpers';

class TableMyUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
    };
  }

  isSelected = unit => {
    const { selected } = this.props;
    const index = selected
      .map(x => GenerateSearchName(x))
      .indexOf(GenerateSearchName(unit));
    return index !== -1;
  };

  render() {
    const { classes, rows, title, loading, deleting, selected } = this.props;
    //
    const { page, limit } = this.state;
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={title}
        />
        <div className={classes.tableWrapper}>
          <MainTable
            columns={columns}
            dense={this.props.dense}
            handleAcceptReject={this.props.handleAcceptReject}
            handleButtonView={this.props.handleButtonView}
            handleSelectAllClick={this.props.handleSelectAllClick}
            handleSelectedClick={this.props.handleSelectedClick}
            handleSelectedRow={this.props.handleSelectedRow}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={rows.length}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
      </Paper>
    );
  }
}

TableMyUnits.propTypes = {
  classes: PropTypes.object,
};
TableMyUnits.defaultProps = {
  deleting: false,
  loading: false,
  dense: false,
  rows: [],
  selected: [],
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
};
export default withStyles(TableMyUnits, useStyles);
