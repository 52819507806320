import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Button, CircularProgress } from '@mui/material'; //IconButton
import { Save as SaveIcon } from '@mui/icons-material';
import clsx from 'clsx';

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  buttonDefaul: {},
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    margin: 0,
  },
  button_transform: {
    textTransform: 'none',
  },
});

class ButtonIconLoad extends Component {
  render() {
    const {
      classes,
      loading,
      isValid,
      handleClick,
      disabled,
      color,
      className,
      input,
      transform,
      ...res
    } = this.props;
    let { icon, text } = this.props;
    let running = false;
    if (typeof loading !== 'undefined' && typeof isValid !== 'undefined') {
      running = isValid ? loading : !isValid;
    } else {
      running = loading;
    }
    if (typeof icon === 'undefined') {
      icon = <SaveIcon />;
    }
    if (typeof text === 'undefined') {
      text = '';
    }
    return (
      <div
        className={clsx({
          [classes.wrapper]: true,
          [classes.expand]: classes.expand,
        })}>
        <Button
          className={clsx({
            [classes.button_transform]: transform ? true : false,
            [classes.buttonDefaul]: !className,
            [className]: className,
          })}
          color={color}
          disabled={running || disabled}
          onClick={handleClick}
          startIcon={icon}
          variant={this.props.variant}
          {...res}>
          {text}
          {input && <input hidden type="file" />}
        </Button>
        {loading && (
          <CircularProgress className={classes.buttonProgress} size={24} />
        )}
      </div>
    );
  }
}

ButtonIconLoad.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary']).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
  transform: PropTypes.bool,
  variant: PropTypes.string,
};
ButtonIconLoad.defaultProps = {
  color: 'primary',
  disabled: false,
  loading: false,
  transform: false,
  input: false,
};
export default withStyles(ButtonIconLoad, useStyles);
