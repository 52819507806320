import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { ButtonLoad, SnackMessage } from 'components';
import {
  GetData,
  getQueryResults,
  getStepPayload,
  projectCreateSteps,
} from 'helpers';
import { loadProjects } from 'redux-store/actions/pageProjectsActions';
import { projectCreated } from 'redux-store/actions/projectActions';
import { getStepContent, getSteps, useStyles } from './utils';

const CreateProjectContainer = props => {
  const {
    setFinished,
    project_type,
    project_settings,
    teams,
    users,
    loadProjects,
    projectCreated,
  } = props;
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const steps = getSteps();

  const addError = error => {
    setErrors([...errors, error]);
  };

  const handleNext = event => {
    event.persist();
    if (loading) return;
    const config = {
      project_type,
      project_settings,
      teams,
      users,
    };
    const payload = getStepPayload(activeStep, config);
    setLoading(true);
    (async () => {
      projectCreateSteps(payload, activeStep)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = getQueryResults(activeStep, data);

          setErrors(errors);
          if (ok) {
            if (activeStep + 1 >= 5) {
              setFinished(true);
              projectCreated(true);
              loadProjects(true);
            }
            setActiveStep(prevActiveStep => prevActiveStep + 1);
          }
          setLoading(false);
        })
        .catch(error => {
          console.log({ error });
          setLoading(false);
        });
    })();
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setFinished(false);
    setActiveStep(0);
  };
  const getError = () => {
    let message = 'Unknown error';
    if (errors && errors.length) {
      message = errors[0];
    }
    return message;
  };
  const handleErrorClose = () => {
    setErrors([]);
  };
  return (
    <div className={classes.root}>
      <SnackMessage
        handleClose={handleErrorClose}
        message_text={getError()}
        open={errors && errors.length ? true : false}
        type="error"
      />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index, addError, props.handleAddError)}
              <div className={classes.actionsContainer}>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="flex-start">
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}>
                    Back
                  </Button>
                  <div style={{ width: '90px', height: '30px' }}>
                    <ButtonLoad
                      color="primary"
                      handleClick={handleNext}
                      loading={loading}
                      name={
                        activeStep === steps.length - 1 ? 'Finished' : 'Next'
                      }
                      variant="contained"
                    />
                  </div>
                </Grid>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper className={classes.resetContainer} elevation={0} square>
          <Typography>
            All steps completed - you&apos;re ready to create the project
          </Typography>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start">
            <Button className={classes.button} onClick={handleReset}>
              Reset
            </Button>
            <div style={{ width: '100px' }}>
              <ButtonLoad
                handleClick={handleNext}
                loading={loading}
                name="Create"
              />
            </div>
          </Grid>
        </Paper>
      )}
    </div>
  );
};
CreateProjectContainer.propTypes = {
  // projectCreateTeams: PropTypes.func.isRequired,
  loadProjects: PropTypes.func.isRequired,
  project_type: PropTypes.object.isRequired,
  // teams: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { project_type, project_settings, teams, users } = state.projects;
  const { centers } = project_settings;
  let size = teams.length;
  size += users.length;
  size += centers.length;
  return {
    project_type,
    project_settings,
    teams,
    users,
    size,
  };
};

export default connect(mapStateToProps, {
  loadProjects,
  projectCreated,
})(CreateProjectContainer);
