import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_DOWNLOAD = gql`
  query($name: String, $page: Int, $limit: Int) {
    allDownloads(name: $name, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      downloads {
        idDownload
        idUser
        private_access
        download_count
        key
        save
        idFile
        idSeries
        idFolder
        idFolderContent

        user {
          FullName
        }
        file_object {
          original_name
        }
        folder_content {
          Folder {
            idFolder
            folders_paths {
              idFolder
              name
            }
          }
          Series {
            idSeries
            SeriesDescription
          }
          File {
            idFileObject
            original_name
          }
        }
        link {
          idFolder
          code
        }
        series {
          SeriesDescription
        }
        project {
          name
        }
        folder {
          name
          folders_paths {
            idFolder
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryDownloads = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOWNLOAD),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_DELETE_DOWNLOADS = gql`
  mutation($ids: [LongLong!]!) {
    deleteDownloads(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteDownload = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_DOWNLOADS),
    variables: {
      ids,
    },
  });
};
