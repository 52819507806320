import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ButtonReloadDelete, ButtonDownloadType } from 'components';
const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class ToolBarProjectObjectMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, selected, isAdmin, types } = this.props;
    const inputs = JSON.stringify(types.map(x => x.idType));
    // console.log({ inputs });
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{
          width: '100%',
          paddingBottom: 5,
          paddingRight: 0,
          marginRight: 0,
        }}>
        <Grid item>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenVarMappping}>
              <AccountTreeIcon
                style={{ color: selected.length ? 'green' : 'gray' }}
              />
            </Button>
            <ButtonDownloadType
              disabled={!this.props.rowCount}
              idProject={this.props.idProject}
              inputs={inputs}
              type="project_objects_mapping"
            />
          </ButtonGroup>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          style={{ paddingRight: 0, marginRight: 0 }}
          xs={2}>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={selected.length}
            show_delete={isAdmin}
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarProjectObjectMapping.propTypes = {
  classes: PropTypes.object,
};
ToolBarProjectObjectMapping.defaultProps = {
  isAdmin: false,
  selected: [],
  idProject: -1,
  handleOpenVarMappping: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  loading: false,
  loading_table: false,
  rowCount: 0,
};

export default withStyles(ToolBarProjectObjectMapping, useStyles);
