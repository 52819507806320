import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { columns } from './Styles';
import useStyles from '../components/Styles';

import { MainTableToolbar, MainTable } from '../components';
import {
  ServerErrorsString,
  SelectedArray,
  GetData,
  rowsPerPageSmall,
} from 'helpers';
import { SnackMessage } from 'components';
import { MutationRemoveTeamMembers } from 'graphql/Teams/utils_team_muations';

class TableTeamMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      selected: [],
      deleting: false,
      error: '',
    };
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.members });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangePageLimit(0, +event.target.value);
  };
  handleDeleteTeamMembers = () => {
    const { selected, deleting } = this.state;
    if (deleting) return;
    const { team } = this.props;

    if (typeof team === 'undefined') {
      this.setState({
        deleting: false,
        error: ServerErrorsString('Team is missing'),
      });
      return;
    }
    const ids = selected.map(x => x.id);
    this.setState({ deleting: true });
    (async () => {
      MutationRemoveTeamMembers(ids, team.id)
        .then(res => {
          const data = GetData(res);

          const { ok, errors } = data.removeTeamMembers;
          if (ok) {
            this.setState({ deleting: false, selected: [] });
            this.props.handleDeleteSelected(ids);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({
            deleting: false,
            error: ServerErrorsString(errors),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const { classes, members, loading, handleReloadTable, height } = this.props;
    const { selected, page, limit, deleting, error } = this.state;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteTeamMembers}
          handleReloadTable={handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="Team Members"
        />
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={members}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={members.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Paper>
    );
  }
}

TableTeamMembers.propTypes = {
  classes: PropTypes.object.isRequired,
  deleting: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  members: PropTypes.array.isRequired,
};
TableTeamMembers.defaultProps = {
  members: [],
  loading: false,
  deleting: false,
  handleChangePageLimit: () => '',
};
export default withStyles(TableTeamMembers, useStyles);
