import React from 'react';
import PropTypes from 'prop-types';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import path from 'common/path';
import {
  ServerErrorsString,
  GetData,
  GetNameFromHeader,
  GetResponseError,
} from 'helpers';
import { IsInvalid } from 'helpers';

class ExplorerMenuDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      total: 0,
    };
    if (props.holder) {
      props.holder.openDownload = () => this.handleDownload();
    }
  }
  handleProgress = (event, loading) => {
    const { downloading } = this.state;
    if (IsInvalid(event)) {
      console.log('ExplorerMenuDownload - invalid', { progress });
      return;
    }
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      if (loaded > this.state.total) {
        total = loaded * 3;
        this.setState({ total });
      } else {
        total = this.state.total;
      }
    }
    let progress = Math.floor((loaded * 100) / total);
    if (loading !== undefined) {
      if (!loading) {
        progress = 0;
      }
    }
    console.log('ExplorerMenuDownload', { progress, downloading });
    if (downloading) this.props.handleProgress(progress);
  };
  handleDownload = () => {
    const { downloading } = this.state;
    if (downloading) {
      return;
    }
    const { selected_objects } = this.props;
    let total_download = 0;
    let selected_content = [];
    let selected_folder = [];
    if (selected_objects && selected_objects.length) {
      selected_folder = selected_objects
        .filter(x => x.idFolderContent === undefined && x.idFolder)
        .filter(x => x);
      selected_content = selected_objects
        .filter(x => x.idFolderContent)
        .filter(x => x);
    } else if (this.props.isFolder) {
      selected_folder.push(this.props.itemOver);
    } else {
      selected_content.push(this.props.itemOver);
    }
    let selected = selected_content.map(x => ({
      idFolderContent: x.idFolderContent,
    }));
    selected = selected.concat(
      selected_folder.map(x => ({ idFolder: x.idFolder }))
    );

    if (selected_content.length) {
      total_download +=
        selected_content.map(x => x.Size).reduce((a, b) => a + b, 0) * 0.3;
    }
    if (selected_folder.length) {
      total_download +=
        selected_folder.map(x => x.Size).reduce((a, b) => a + b, 0) * 0.3;
    }
    this.setState({ downloading: true, total_download });

    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(selected);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          throw server_error;
        }
      } catch (error) {
        this.setState({ downloading: false });
        this.props.handleAddError(ServerErrorsString(error));
        return;
      }
      DownloadItemsAtKey(download_key, this.handleProgress)
        .then(async response => {
          let disposition = response.headers['Content-Disposition'];
          if (IsInvalid(disposition)) {
            disposition = response.headers['content-disposition'];
          }
          let name = 'download_all';
          let extension = 'zip';
          if (selected.length === 1) {
            if (selected_content.length) {
              const { Series, File } = selected_content[0];
              if (Series) {
                name = Series.SeriesDescription;
              } else if (File) {
                let ext = path.extname(File.original_name);
                name = path.basename(File.original_name, ext);
                if (ext) {
                  if (ext.startsWith('.')) {
                    ext = ext.replace('.', '');
                  }
                  extension = ext;
                }
              }
            } else if (selected_folder.length) {
              name = selected_folder[0].name;
            } else if (disposition && disposition.indexOf('filename=') !== -1) {
              name = GetNameFromHeader(response);
              extension = null;
            }
          }
          let file_name = `${name}.${extension}`;
          if (!extension) file_name = name;
          console.log({
            name,
            extension,
            length: selected.length,
            disposition,
            response,
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          this.setState({ downloading: false, total: 0 });
          await MutationDownloadFinished(download_key);
          this.props.handleProgress(0);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ downloading: false, total: 0 });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.handleProgress(0);
        });
    })();
  };
  render() {
    return null;
  }
}

ExplorerMenuDownload.propTypes = {
  handleAddError: PropTypes.func,
  handleProgress: PropTypes.func.isRequired,
  itemOver: PropTypes.object,
};
ExplorerMenuDownload.defaultProps = {
  handleAddError: error => console.log({ error }),
};

export default ExplorerMenuDownload;
