import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'icon',
    label: 'Type',
    align: 'left',
    type: 'icon',
  },
  {
    id: 'User',
    label: 'User',
    align: 'left',
    minWidth: 170,
    format: value => {
      if (value === undefined || !value) return 'Name Missing';
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Name Missing';
      return FullName;
    },
  },
  {
    id: 'activity',
    label: 'Activity',
    minWidth: 100,
  },
  {
    id: 'updatedAt',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
