import React from 'react';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { FormatTime, getInitials } from 'helpers';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: '100%',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  bigAvatar: {
    margin: 10,
    // width: 60,
    // height: 60
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const StatusComponent = value => {
  //0 canceled, 1 started, 2 in progress, 3 finished
  switch (value) {
    case 0:
      return {
        text: 'Cancel',
        color: 'red',
      };
    case 1:
      return {
        text: 'Started',
        color: 'blue',
      };
    case 2:
      return {
        text: 'In Progress',
        color: 'orange',
      };
    default:
      return {
        text: 'Finished',
        color: 'green',
      };
  }
};

class ProjectsItem extends React.Component {
  render() {
    const { classes, project, handleViewProject } = this.props;
    let { name, avatar, members, createdAt, status } = project;
    if (!avatar) {
      avatar = '/images/projects/project_0.png';
    }
    let total_members = 0;
    if (typeof members !== 'undefined') {
      total_members = members.length;
    }
    const { text, color } = StatusComponent(status);
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar className={classes.bigAvatar} src={avatar}>
                {getInitials(name)}
              </Avatar>
            </Grid>
            <Grid container item sm xs={12}>
              <Grid container direction="column" item spacing={1} xs>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    Project Name
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {name ? name : ''}
                  </Typography>
                  {/* <Typography variant="body2" color="textSecondary">
                    ID: 1030114
                  </Typography> */}
                </Grid>
                {/* <Grid item>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                    Remove
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid container direction="column" item spacing={1} xs>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    Team Members
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {total_members}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" item spacing={1} xs>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    Project started
                  </Typography>
                  <Typography gutterBottom variant="body2">
                    {FormatTime(createdAt, 'calendar')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" item spacing={1} xs>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    Project status
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{ color: color }}
                    variant="body2">
                    {text}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  className={classes.margin}
                  color="primary"
                  onClick={() => {
                    if (handleViewProject) {
                      handleViewProject(project);
                    }
                  }}
                  size="medium"
                  variant="outlined">
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(ProjectsItem, useStyles);
