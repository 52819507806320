import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CardProjectHeader, useStyles } from 'components/Cards/components';
import { TableProjectMembers, DialogDeleteWarning } from 'components';
import moment from 'moment';
import {
  AddProjectMembers,
  MembersAtProject,
  RemoveProjectMembers,
} from 'graphql/Projects';
import { UsersToolbar } from './components';
import { GetData, ServerErrorsString } from 'helpers';
import { IsProjectFullAccess } from 'common';
import { IsInvalid } from 'helpers';

class CardProjectMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: false,
      loading_members: false,
      idProject: -1,
      new_members: [],
      deleting: false,
      open_delete: false,
      todelete: [],
    };
    this.holder = { cleanSelected: null };
  }

  componentDidMount() {
    if (!this.props.isActive) return;
    this.updateMembers();
  }

  componentDidUpdate() {
    if (!this.props.isActive) return;
    const { idProject } = this.state;
    const { project } = this.props;
    if (project.id !== idProject) {
      this.updateMembers();
    }
  }

  updateMembers = () => {
    const { loading } = this.state;
    if (loading) return;
    const { project } = this.props;
    this.setState({ loading: true });
    (async () => {
      MembersAtProject(project.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, members } = data.projectsMembers;
          if (ok) {
            this.setState({
              loading: false,
              members,
              idProject: project.id,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject: project.id });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddUser = user => {
    if (IsInvalid(user) || user.id === undefined) {
      this.props.handleAddError('Invalid member please try again.');
      return;
    }
    const { members, new_members } = this.state;
    let index = members.map(x => x.user.id).indexOf(user.id);
    if (index !== -1) {
      const { idMember } = members[index];
      if (idMember > 0) {
        this.props.handleAddError('Member is already here.');
      }
      members.splice(index, 1);
      index = -1;
    }
    if (index === -1) {
      index = new_members.map(x => x.user.id).indexOf(user.id);
      if (index === -1) {
        new_members.unshift({
          idMember: -2,
          idUser: user.id,
          idCenterTeam: -1,
          user,
          center: null,
          team: null,
          createdAt: `${moment().valueOf()}`,
          updatedAt: `${moment().valueOf()}`,
        });
        this.setState({ new_members, members });
      }
    }
  };
  handleDeleteSelected = () => {
    this.handleDeleteUser(this.state.todelete);
  };
  handleDeleteUser = selected => {
    console.log({ selected });
    if (selected === undefined || selected.length === 0) {
      this.props.handleAddError('Select members first, please try again.');
      this.setState({ open_delete: false });
      return;
    }
    let { members, new_members } = this.state;
    let new_total = new_members.length;
    const to_delete = [];
    for (let i = 0; i < selected.length; i++) {
      const member = selected[i];
      let index = new_members.map(x => x.user.id).indexOf(member.user.id);
      if (index !== -1) {
        new_members.splice(index, 1);
      } else {
        index = members.map(x => x.user.id).indexOf(member.user.id);
        if (index !== -1) {
          to_delete.push(member);
        }
      }
    }
    if (to_delete.length === 0) {
      this.setState({
        new_members,
        open_delete: false,
      });
    } else {
      if (new_total !== new_members.length) {
        this.setState({ new_members });
      }
      const ids = to_delete.map(x => x.user.id).filter(x => x > 0);
      const emails = selected.filter(x => x.user.id < 1).map(x => x.user.email);
      this.handleRemoveMembers(ids, emails);
      // ---------- TO TEST --------------
      // console.log({ ids, emails });
      // members = members.filter(x => ids.indexOf(x.user.id) === -1);
      // members = members.filter(x => emails.indexOf(x.user.email) === -1);
      // this.setState({
      //   deleting: false,
      //   members,
      //   open_delete: false,
      // });
      // this.props.handleDeletedMembers(ids);
    }
  };
  handleRemoveMembers = (ids, emails) => {
    const { deleting, idProject } = this.state;
    if (deleting) return;
    let { members } = this.state;
    this.setState({ deleting: true });
    (async () => {
      RemoveProjectMembers(idProject, ids, emails)
        .then(res => {
          const data = GetData(res);
          if (typeof data.RemoveProjectMembers === 'undefined') {
            throw Error('Unknow network error');
          }
          const { ok, errors } = data.RemoveProjectMembers;
          if (ok) {
            members = members.filter(x => ids.indexOf(x.user.id) === -1);
            members = members.filter(x => emails.indexOf(x.user.email) === -1);
            this.setState({
              deleting: false,
              members,
              open_delete: false,
            });
            this.props.handleDeletedMembers(ids);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, open_delete: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveClicked = () => {
    const { loading_members, idProject } = this.state;
    if (loading_members) return;
    let { new_members, members } = this.state;
    this.setState({ loading_members: true });
    (async () => {
      AddProjectMembers(
        idProject,
        new_members.map(x => x.user.id)
      )
        .then(res => {
          const data = GetData(res);
          if (typeof data.AddProjectMembers !== 'undefined') {
            const { ok, errors } = data.AddProjectMembers;
            if (ok) {
              members = members.concat(
                new_members.map(x => {
                  return {
                    ...x,
                    idMember: x.user.id,
                  };
                })
              );
              this.setState({
                loading_members: false,
                members,
                new_members: [],
              });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknow network error');
          }
        })
        .catch(error => {
          this.setState({ loading_members: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, show, maximize, height, admin, access_level } = this.props;
    const {
      members,
      loading,
      new_members,
      loading_members,
      deleting,
    } = this.state;
    if (!show && !maximize) return null;
    const all_members = new_members.concat(members);
    let ComponentSearchUser = null;
    if (admin) {
      ComponentSearchUser = (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-start"
          spacing={1}
          style={{ paddingRight: 5 }}
          xs={12}>
          <UsersToolbar
            admin
            handleAddUser={this.handleAddUser}
            handleSaveClicked={this.handleSaveClicked}
            save_changed={new_members.length}
            saving={loading_members}
          />
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Card className={classes.root}>
          <CardProjectHeader
            handleMaximize={() => this.props.handleMaximize('members')}
            subtitle="List of project members"
            title="Members"
          />
          <Divider />
          <CardContent className={classes.content}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={1}>
              {ComponentSearchUser}
              <TableProjectMembers
                checkboxSelection={admin}
                deleting={deleting}
                dense={this.props.dense}
                download_type={IsProjectFullAccess(admin, access_level)}
                handleDeleteMembers={todelete =>
                  this.setState({ open_delete: true, todelete })
                }
                handleReloadTable={this.updateMembers}
                height={height}
                holder={this.holder}
                idProject={this.state.idProject}
                loading={loading}
                project_members={all_members}
                title={'Project Members'}
                total={all_members.length}
              />
            </Grid>
          </CardContent>
        </Card>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the selected members, this action will remove the members from the project, this action cannot be undone"
          title_text="Delete Members"
        />
      </React.Fragment>
    );
  }
}

CardProjectMembers.propTypes = {
  classes: PropTypes.object,
  dense: PropTypes.bool,
};
CardProjectMembers.defaultProps = {
  maximize: false,
  show: true,
  admin: false,
  handleDeletedMembers: () => '',
  access_level: 0,
  dense: false,
};
export default withStyles(CardProjectMembers, useStyles);
