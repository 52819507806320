import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_MY_UNITS = gql`
  query($name: String, $page: Int, $limit: Int) {
    MyUnits(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      units {
        idUnit
        Admin {
          id
          email
          FullName
        }
        name
        code
        serial_number
        institution
        description
        api
        ip
        ip_private
        latitude
        longitude
        country
        country_code
        city
        setting {
          id
          read
          receive
          find
          status
        }
        status
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryMyUnits = (name, page, limit) => {
  const variables = {};
  if (name && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MY_UNITS),
    variables,
  });
};

const QUERY_ALL_UNITS = gql`
  query($name: String, $page: Int, $limit: Int, $local: Boolean) {
    units(name: $name, page: $page, limit: $limit, local: $local) {
      ok
      units {
        idUnit
        name
        ip
        serial_number
        Admin {
          id
          FullName
          avatar
          email
        }
        status
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryUnits = (name, page, limit, local) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_UNITS),
    variables: {
      name,
      page,
      limit,
      local,
    },
  });
};

const MUATION_UPDATE_UNIT = gql`
  mutation($idUnit: LongLong!, $unit: InputUnit, $setting: InputUnitSetting) {
    UpdateUnit(idUnit: $idUnit, unit: $unit, setting: $setting) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateUnit = (idUnit, unit, setting) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUATION_UPDATE_UNIT),
    variables: {
      idUnit,
      unit,
      setting,
    },
  });
};

const QUERY_SELECTED_UNITS = gql`
  query($page: Int, $limit: Int) {
    MySelectedUnits(page: $page, limit: $limit) {
      ok
      units {
        idUnit
        name
        serial_number
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryMySelectedUnits = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SELECTED_UNITS),
    // variables: {
    //   id,
    //   unit,
    //   setting,
    // },
  });
};
