import {
  MutationCreateBatchProcess,
  MutationExecuteActionAtBatchProcess,
} from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';

export const addToBatchProcess = self => {
  const { selected } = self.props;
  console.log('addToBatchProcess', { selected });
  const { running } = self.state;
  if (running || !selected.length) return;
  const idDockerImage = selected[0].idDockerImage;
  const inputs = selected
    .filter(x => x.idDockerImage === idDockerImage && x.id)
    .map(x => {
      return {
        idJob: x.id,
        idDockerImage,
      };
    });
  if (!inputs.length) {
    self.props.handleAddError(
      'Inputs variables is empty check your process setting'
    );
    return;
  }
  const setting = self.holder.getSetting();
  self.setState({ running: true });
  (async () => {
    MutationCreateBatchProcess(idDockerImage, inputs, setting)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, fails } = data.createBatchProcess;
        if (ok) {
          self.setState({ running: false });
          if (fails && fails.length) {
            self.props.handleProcessFailed(fails);
            for (let i = 0; i < fails.length; i++) {
              const { idSeries, idFileObject } = fails[i];
              const j = selected
                .map(x => `${x.idSeries}_${x.idFileObject}`)
                .indexOf(`${idSeries}_${idFileObject}`);
              if (j !== -1) {
                selected.splice(j, 1);
              }
            }
          }
          self.props.handleProcessExecuted([...selected]);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ running: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const executeActionToBatchProcess = (self, action) => {
  const { selected, batch } = self.props;
  console.log({ selected });
  const { running } = self.state;
  if (running) return;
  if (!selected.length) {
    self.props.handleAddError('You need to select a batch first');
    return;
  }
  const inputs = selected.map(x => {
    const { project, tags, folder } = x.setting;
    let folder_structure = null;
    if (tags && tags.length) {
      folder_structure = tags
        .map(x => {
          if (x.tag === undefined) return x;
          return x.tag;
        })
        .join('/');
    }
    const setting = {
      folder_structure,
      own_folder: x.setting.own_folder,
      on_content: x.setting.on_content,
      idProject: project ? project.id : null,
      idFolder: folder ? folder.idFolder : null,
    };
    return {
      idBatch: x.id,
      setting,
      action,
    };
  });
  self.setState({ running: true });
  (async () => {
    MutationExecuteActionAtBatchProcess(inputs)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.executeActionAtBatchProcess;
        if (ok) {
          self.setState({ running: false });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ running: false });
        const text = ServerErrorsString(error);
        console.log({ text, batch });
        if (batch) {
          self.props.handleUpdateBatches([{ ...batch, status: 'error' }]);
        }
        if (self.props.handleAddError) {
          self.props.handleAddError(text);
        }
      });
  })();
};
