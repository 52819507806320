import React from 'react';
import PropTypes from 'prop-types';
import { Countries, InputUsers, TextFieldCustom } from 'components';
import { Grid } from '@mui/material';

class EditCenterComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      disable_all,
      name,
      admin,
      country,
      city,
      line_1,
      handleChange,
    } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextFieldCustom
            disabled={disable_all}
            fullWidth
            helperText="Please specify the name of the center"
            label="Center name"
            margin="dense"
            name="name"
            onChange={handleChange}
            required
            value={name}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputUsers
            disabled={disable_all}
            filter={false}
            multiple={false}
            onChange={this.props.handleAddUser}
            textfield={{
              fullWidth: true,
              helperText: 'Search specify the name of user administrator',
              label: 'Administrator',
              margin: 'dense',
              required: true,
            }}
            value={admin}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Countries
            country={country}
            disabled={disable_all}
            handleCountryChanged={this.props.handleCountryChanged}
          />
        </Grid>
        <Grid item md={6} mt={1} xs={12}>
          <TextFieldCustom
            disabled={disable_all}
            fullWidth
            label="City"
            margin="dense"
            name="city"
            onChange={handleChange}
            placeholder="Select your city"
            small
            value={city}
            variant="outlined"
          />
        </Grid>
        <Grid item xs>
          <TextFieldCustom
            dense
            disabled={disable_all}
            fullWidth
            label="Street"
            name="line_1"
            onChange={handleChange}
            placeholder="Select center street"
            small
            value={line_1}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

EditCenterComponents.propTypes = {
  line_1: PropTypes.string,
};

export default EditCenterComponents;
