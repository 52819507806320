import React from 'react';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Avatar from '@mui/material/Avatar';

import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { connect } from 'react-redux';

import { GetData, getInitials, ServerErrorsString } from 'helpers';
import useStyles from './Styles';
import { SendTeamChannelMessage } from 'graphql/Communications';
import { SnackMessage } from 'components';

const SendMessageToTeam = props => {
  const { user, channelId } = props;
  const { classes } = useStyles();
  const [values, setValues] = React.useState({
    message: '',
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const sendMessage = event => {
    const { key } = event;
    if (!key) return;
    if (key === 'Enter') {
      handleSendTeamMessage();
    }
  };
  const handleSendTeamMessage = () => {
    const { message } = values;
    if (loading) return;
    if (message === '') {
      setError('Empty message');
      return;
    }
    setLoading(true);
    SendTeamChannelMessage(channelId, message)
      .then(res => {
        const data = GetData(res);
        const { createMessage } = data;
        if (createMessage) {
          setValues({ ...values, message: '' });
        } else {
          setError('Unknown error');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(ServerErrorsString(error));
        setLoading(false);
      });
  };
  const handleErrorClose = () => {
    setError('');
  };
  return (
    <Paper className={classes.root}>
      <Avatar
        alt={getInitials(user.FullName)}
        className={classes.avatar}
        src={user.avatar}
      />
      <TextField
        className={clsx(classes.margin, classes.textField)}
        id="outlined-adornment-message"
        label="Send a Message"
        onChange={handleChange('message')}
        onKeyPress={sendMessage}
        value={values.message}
        variant="outlined"
      />
      <IconButton
        aria-label="send"
        className={classes.iconButton}
        onClick={handleSendTeamMessage}
        size="large">
        <SendIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        aria-label="directions"
        className={classes.iconButton}
        color="primary"
        size="large">
        <AttachFileIcon />
      </IconButton>
      <SnackMessage
        handleClose={handleErrorClose}
        message_text={error}
        open={error !== '' ? true : false}
        type="error"
      />
    </Paper>
  );
};
const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(mapStateToProps)(SendMessageToTeam);
