import {
  LOGOUT_USER_NOTIFICATION,
  NOTIFICATION_DELETE_SHARE_FOLDER,
  NOTIFICATION_NEW,
  NOTIFICATION_NEW_CHAT,
  NOTIFICATION_NEW_CHAT_MESSAGE,
  NOTIFICATION_NEW_CHAT_MESSAGE_READ,
  NOTIFICATION_ADD_CHAT_MESSAGES,
  NOTIFICATION_NEW_SHARE_FOLDER,
  NOTIFICATION_RELOAD,
  NOTIFICATION_SET,
} from '../actions/types';

import { decodeNotification } from '../actions/notifications';
import { IsInvalid } from 'helpers';

const initialState = {
  shared_folders: [],
  chat_creation: [],
  chat_messages: [],
  group_message: [],
  group_creation: [],
  notifications: [],
  notifications_reload: false,
  total_notification: 0,
  total_to_read: 0,
};

export default function reducer_notifications(state = initialState, action) {
  let current_notifications = state.notifications;
  //
  switch (action.type) {
    case LOGOUT_USER_NOTIFICATION:
      return initialState;
    case NOTIFICATION_NEW_SHARE_FOLDER: {
      const { shared_folder } = action;
      const { Owner, Right, Folder, updatedAt } = shared_folder;
      current_notifications.unshift({
        id: Folder.idFolder,
        name: 'Shared Folder',
        from: Owner,
        updatedAt: updatedAt,
      });
      if (!Folder.Owner) {
        Folder.Owner = Owner;
      }
      if (!Folder.Rights || Folder.Rights.length === 0) {
        Folder.Rights = [Right];
      }
      let { shared_folders } = state;
      shared_folders.unshift(Folder);
      return {
        ...state,
        shared_folders,
        notifications: current_notifications,
      };
    }
    case NOTIFICATION_DELETE_SHARE_FOLDER: {
      const shared = {
        ...state,
        shared_folders: action.shared_folders,
      };
      if (action.notifications) {
        shared.notifications = action.notifications;
        shared.notifications_reload = true;
      }
      return shared;
    }
    case NOTIFICATION_NEW_CHAT: {
      const { new_chat } = action;
      let nothere = true;
      for (let index = 0; index < current_notifications.length; index++) {
        const { name, from } = current_notifications[index];
        if (name !== 'New Message') {
          continue;
        }
        if (from.id === new_chat.User.id) {
          current_notifications[index] = {
            name: 'New Message',
            from: new_chat.User,
            updatedAt: new_chat.createdAt,
          };
          nothere = false;
          break;
        }
      }
      if (nothere) {
        current_notifications.push({
          name: 'New Message',
          from: new_chat.Owner,
          updatedAt: new_chat.updatedAt,
        });
      }
      const { chat_creation } = state;
      chat_creation.unshift(new_chat);
      return {
        ...state,
        chat_creation,
      };
    }
    case NOTIFICATION_NEW_CHAT_MESSAGE: {
      if (!action.chat_message) {
        return state;
      }
      const { chat_messages } = state;
      const index = chat_messages
        .map(x => x.sender.id)
        .indexOf(action.chat_message.sender.id);
      if (index === -1) {
        chat_messages.unshift(action.chat_message);
      } else {
        chat_messages[index] = action.chat_message;
      }
      return {
        ...state,
        chat_messages,
        total_to_read: chat_messages.filter(x => x.read === false).length,
      };
    }
    case NOTIFICATION_ADD_CHAT_MESSAGES: {
      const { chat_messages } = action;
      if (IsInvalid(chat_messages)) {
        return state;
      }
      // Create a Set of existing ids in array b
      const existingIds = new Set(state.chat_messages.map(item => item.id));
      // Filter array a to include only objects with ids not present in b
      const filteredA = chat_messages.filter(item => !existingIds.has(item.id));
      // Combine filteredA and b to get the desired result
      const new_chat_messages = [...filteredA, ...state.chat_messages];
      return {
        ...state,
        chat_messages: new_chat_messages,
        total_to_read: new_chat_messages.filter(x => x.read === false).length,
      };
    }
    case NOTIFICATION_NEW: {
      const { notification } = action;
      decodeNotification(notification, state);
      const index = current_notifications
        .map(x => x.id)
        .indexOf(notification.id);
      if (index !== -1) return state;
      current_notifications.unshift(notification);
      if (current_notifications.length > 100) {
        current_notifications = current_notifications.splice(0, 100);
      }
      return {
        ...state,
        notifications: current_notifications,
        total_notification: current_notifications.length,
      };
    }
    case NOTIFICATION_SET: {
      return {
        ...state,
        notifications: action.notifications,
      };
    }
    case NOTIFICATION_NEW_CHAT_MESSAGE_READ: {
      let { chat_messages } = state;
      chat_messages = chat_messages.map(x => ({ ...x, read: true }));
      return {
        ...state,
        chat_messages,
      };
    }
    case NOTIFICATION_RELOAD:
      return {
        ...state,
        notifications_reload: action.notifications_reload,
      };
    default:
      return state;
  }
}
// export default ReducerNotification;
