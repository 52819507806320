import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  card: {
    maxWidth: '100%',
  },
});
class CreateCardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleClose()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={this.props.icon}
        subheader={this.props.description}
        title={this.props.title}
      />
    );
  }
}

CreateCardHeader.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
};
CreateCardHeader.defaultProps = {
  description: '',
  icon: <AddIcon style={{ color: 'blue' }} />,
  title: '',
};

export default withStyles(CreateCardHeader, useStyles);
