import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
// import { GroupAdd } from '@mui/icons-material';
import { SearchSitesInputs } from 'components';

const useStyles = () => ({
  button: {},
});

class SearchUnit extends Component {
  render() {
    // const { classes } = this.props;
    // return <SearchSitesInputs placeholder="Search site..." />;

    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="space-between"
        xs={12}>
        <SearchSitesInputs
          handleAddUnit={this.props.handleAddUnit}
          placeholder="Search Unit..."
        />
        {/* <Button
          className={classes.button}
          color="primary"
          endIcon={<GroupAdd />}
          onClick={this.props.handleOpenCreate}
          variant="contained">
          Create
        </Button> */}
      </Grid>
    );
  }
}

SearchUnit.propTypes = {
  classes: PropTypes.object,
};
SearchUnit.defaultProps = {};

export default withStyles(SearchUnit, useStyles);
