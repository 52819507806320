import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_JOB_SETTING = gql`
  mutation($idJob: LongLong!, $setting: InputJobSetting!) {
    updateJobSetting(idJob: $idJob, setting: $setting) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        idJob

        idProject
        idFolder
        own_folder
        on_content

        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationUpdateJobSetting = (idJob, setting) => {
  console.log('MutationUpdateJobSetting', {
    idJob,
    setting,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_JOB_SETTING),
    variables: {
      idJob,
      setting,
    },
  });
};
