import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import RefreshIcon from '@mui/icons-material/Refresh';

import useStyles from './Styles';
import { SummaryCard } from './components';
import { DivGrow, IconButtonLoad, RowText } from 'components';
import { QuerySummaryUnit } from 'graphql/Units';
import { GetData, ServerErrorsString } from 'helpers';

const defaultSummary = {
  id: -1,
  users: {
    value: 0,
    per: 0,
    up: false,
  },
  files: {
    value: 0,
    per: 0,
    up: false,
  },
  patients: {
    value: 0,
    per: 0,
    up: false,
  },
  studies: {
    value: 0,
    per: 0,
    up: false,
  },
  series: {
    value: 0,
    per: 0,
    up: false,
  },
  unit: {
    name: 'Undefined',
    Admin: { FullName: 'Undefined', id: -1 },
    setting: { read: 0 },
  },
};

class SummaryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  runUpdateSummary = () => {
    const { summary } = this.props;
    const { loading } = this.state;
    if (loading || !summary) return;
    const { idUnit } = summary;
    this.setState({ loading: true });
    (async () => {
      QuerySummaryUnit(idUnit)
        .then(res => {
          const data = GetData(res);
          const { ok, summary, errors } = data.summaryUnit;
          // console.log('runUpdateSummary', { ok, summary });
          if (ok) {
            this.props.handleUpdateSummary(summary);
            this.setState({
              loading: false,
              total: this.getTotalElements(summary),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getTotalElements = summary => {
    const { users, files, patients, studies, series, dicoms } = summary;
    let total = 0;
    if (users) total += users.value;
    if (files) total += files.value;
    if (patients) total += patients.value;
    if (studies) total += studies.value;
    if (series) total += series.value;
    if (dicoms) total += dicoms.value;
    return total;
  };

  render() {
    let { classes, summary, myunit } = this.props;
    const { loading } = this.state;
    if (typeof summary === 'undefined' || !summary) {
      summary = defaultSummary;
    }
    const { users, files, dicoms, patients, studies, series, unit } = summary;
    let {
      Admin: { FullName },
      api,
      setting,
    } = unit;
    let read = 0;
    if (myunit.idUnit === unit.idUnit) {
      read = 1;
    } else if (setting) {
      read = setting.read;
    }
    // console.log('render', { files, dicoms });
    return (
      <div style={{ maxHeight: 200 }}>
        <Grid alignItems="center" container direction="row">
          <Grid item style={{ paddingRight: 10 }}>
            <RowText
              display="block ruby"
              first="Unit name:"
              second={unit.name}
            />
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <RowText
              display="block ruby"
              first="Admin:"
              second={FullName}
              styleBox={{ fontWeight: 2000 }}
            />
          </Grid>
          <Grid item>
            <RowText
              display="block ruby"
              first="API:"
              second={api}
              styleBox={{ fontWeight: 2000 }}
            />
          </Grid>
          <DivGrow />
          <IconButtonLoad
            // disabled={!changed}
            handleClick={this.runUpdateSummary}
            icon={<RefreshIcon />}
            loading={loading}
            tooltip="Reload Summary"
          />
        </Grid>
        <Card className={classes.root}>
          <Grid
            className={classes.container}
            container
            direction="row"
            justifyContent="flex-start">
            <Grid item md={2} sm={4}>
              <SummaryCard
                color="blue"
                count={users.value}
                icon="supervisoraccount"
                percentage={users.per}
                read={read}
                title="Total Users"
                up={users.per > 0}
              />
            </Grid>
            <Divider
              className={classes.divider}
              flexItem
              orientation="vertical"
            />
            <Grid item md={2} sm={4}>
              <SummaryCard
                color="#7b1fa2"
                count={patients.value}
                icon="contactmail"
                percentage={patients.per}
                read={read}
                title="Total Patients"
                up={patients.per > 0}
              />
            </Grid>
            <Divider
              className={classes.divider}
              flexItem
              orientation="vertical"
            />
            <Grid item md={2} sm={4}>
              <SummaryCard
                color="green"
                count={studies.value}
                icon="assessment"
                percentage={studies.per}
                read={read}
                title="Total Studies"
                up={studies.per > 0}
              />
            </Grid>
            <Divider
              className={classes.divider}
              flexItem
              orientation="vertical"
            />
            <Grid item md={2} sm={4}>
              <SummaryCard
                color="red"
                count={series.value}
                icon="airlineseatflatangled"
                percentage={series.per}
                read={read}
                title="Total Series"
                up={series.per > 0}
              />
            </Grid>
            <Divider
              className={classes.divider}
              flexItem
              orientation="vertical"
            />
            <Grid item md={2} sm={4}>
              <SummaryCard
                å
                color="orange"
                count={files.value + dicoms.value}
                icon="description"
                percentage={files.per}
                read={read}
                title="Dicoms/Files"
                up={files.per > 0}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

SummaryHeader.propTypes = {
  classes: PropTypes.object,
};
SummaryHeader.defaultProps = {
  summary: defaultSummary,
};

export default withStyles(SummaryHeader, useStyles);
