import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { QueryImageFocus } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';
import { DetailsAutocomplete } from '../index';

const default_focus = {
  idFocus: -1,
  name: '',
  updatedAt: '',
};

class ImageFocus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      focus: { ...default_focus },
      imagefocuses: [],
      open: false,
      error: '',
      empty: false,
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.getFocus = () => {
        return this.state.focus;
      };
      props.holder.resetFocus = () => {
        this.state.focus = { ...default_focus };
      };
    }
  }

  componentDidMount() {
    this.runSearch();
  }

  componentDidUpdate() {
    const { focus } = this.state;
    if (focus.idFocus === -1 && this.props.focus.idFocus !== -1) {
      this.setState({ focus: this.props.focus });
    }
  }

  setOpen = open => {
    this.setState({ open });
  };
  setImageFocus = focus => {
    this.setState({ focus });
  };
  handleTyping = event => {
    const { loading } = this.state;
    let focus = event.target.value;
    if (loading || !focus || focus === '') {
      return;
    }
    this.runSearch(focus);
  };
  runSearch = focus => {
    const { loading } = this.state;
    if (loading || this.state.empty) {
      return;
    }
    const { imagefocuses } = this.state;
    if (imagefocuses.length) {
      focus = imagefocuses.toLowerCase();
      const here = imagefocuses.filter(x =>
        x.name.toLowerCase().startsWith(focus)
      );
      if (here.length) {
        return;
      }
    }
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryImageFocus(focus)
        .then(res => {
          const data = GetData(res);
          let imagefocuses = [];
          let empty = true;
          if (typeof data !== 'undefined' && data.ImageFocus) {
            empty = data.ImageFocus.length ? false : true;
            imagefocuses = data.ImageFocus;
          }
          this.setState({ loading: false, empty, imagefocuses });
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChanged = (label, newValue) => {
    if (!newValue) {
      newValue = { ...default_focus };
    }
    this.setImageFocus(newValue);
    this.props.handleSelectedChanged(label, newValue);
  };

  render() {
    const { minWidth } = this.props;
    const { loading, imagefocuses, open } = this.state;
    let focus = this.state.focus;
    if (focus.idFocus === -1) {
      focus = undefined;
    }
    return (
      <DetailsAutocomplete
        detail={focus}
        details={imagefocuses}
        handleSelectedChanged={this.handleSelectedChanged}
        handleTyping={this.handleTyping}
        label="focus"
        loading={loading}
        minWidth={minWidth}
        open={open}
        setOpen={this.setOpen}
        title="Image Focus"
      />
    );
  }
}

ImageFocus.propTypes = {
  classes: PropTypes.object,
};

ImageFocus.defaultProps = {
  className: '',
  focus: default_focus,
  minWidth: false,
};
export default ImageFocus;
