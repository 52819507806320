import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material';
import { SearchFilesProjects, SearchFilesUsers, TitleSort } from 'components';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { ListModalities, options_nifti, defaultFilter } from './components';
import { TypeSequences } from 'components/Components';
import { AutoComboBox } from 'components/AutoComboBoxes';
import { DateRangePicker } from 'components/DatePickers';
const options = [
  { id: 1, name: 'A' },
  { id: 1, name: 'B' },
  { id: 1, name: 'C' },
];
class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      setRange: null,
      getSequence: null,
      resetUser: null,
      resetSequence: null,
      resetProject: null,
      setAutoComboBox: null,
    };
    if (props.holder) {
      props.holder.reset = () => this.handleReset();
    }
  }
  handleAddProject = project => {
    const { filters } = this.props;
    if (project) {
      filters.idProject = project.id;
      filters.project = project;
    } else {
      filters.idProject = null;
      filters.project = null;
    }
    this.props.onFilterChanged(filters);
  };
  handleAddUser = user => {
    const { filters } = this.props;
    if (user) {
      filters.idUser = user.id;
      filters.user = user;
    } else {
      filters.idUser = null;
      filters.user = null;
    }
    this.props.onFilterChanged(filters);
  };
  handleFromDateChange = dates => {
    const datestart = dates[0].toISOString();
    const dateend = dates[1].toISOString();
    console.log({ datestart, dateend });
    const init = moment(datestart).format('YYYYMMDD');
    const end = moment(dateend).format('YYYYMMDD');
    const { filters } = this.props;
    filters.SeriesDate = `${init}-${end}`;
    this.props.onFilterChanged(filters);
  };
  handleSelectedSequence = sequence => {
    const { filters } = this.props;
    filters.sequence = sequence;
    console.log({ sequence });
    this.props.onFilterChanged(filters);
  };
  handleNiftiChanged = selected => {
    const { filters } = this.props;
    if (selected) filters.nifti = selected.id;
    else filters.nifti = null;
    this.props.onFilterChanged(filters);
  };
  handleModalitiesChanged = modalities => {
    const { filters } = this.props;
    if (modalities && modalities.length)
      filters.modalities = modalities.map(x => x.name);
    else filters.modalities = null;
    console.log({ modalities: filters.modalities });
    this.props.onFilterChanged(filters);
  };
  handleReset = () => {
    if (this.holder.resetUser) this.holder.resetUser();
    if (this.holder.resetSequence) this.holder.resetSequence();
    if (this.holder.setAutoComboBox)
      this.holder.setAutoComboBox(options_nifti[0]);
    if (this.holder.setRange) this.holder.setRange('01/01/1900', '01/01/9999');
    if (this.holder.resetProject) this.holder.resetProject();
  };
  render() {
    const { filters, view, hide_nii, hide_date, hide_modalities } = this.props;
    let selectedFromDate = '01/01/1900';
    let selectedToDate = '01/01/9999';
    if (filters.SeriesDate) {
      const parts = filters.SeriesDate.split('-');
      if (parts.length === 2) {
        selectedFromDate = moment(parts[0].trim(), 'YYYYMMDD').toString(
          'MM/DD/YYYY'
        );
        selectedToDate = moment(parts[1].trim(), 'YYYYMMDD').toString(
          'MM/DD/YYYY'
        );
      }
    }
    let ComponentSequence = null;
    let ComponentNifti = null;
    let ComponentMoldalities = null;
    let ComponentUsers = null;
    if (this.props.show_user) {
      ComponentUsers = (
        <Grid item xs={12}>
          <SearchFilesUsers
            handleAddError={this.props.handleAddError}
            handleAddSeletedItem={this.handleAddUser}
            holder={this.holder}
            no_icon
            selected={filters.user}
            small
          />
        </Grid>
      );
    }
    if (view === 'series') {
      let modalities = [];
      let selected = options_nifti[0];
      if (filters.nifti) {
        const index = options_nifti.map(x => x.id).indexOf(filters.nifti);
        if (index !== -1) selected = options_nifti[index];
      }
      if (filters.modalities && filters.modalities.length) {
        modalities = [];
        filters.modalities.forEach(x => {
          const index = ListModalities.map(x => x.name).indexOf(x);
          if (index !== -1) modalities.push(ListModalities[index]);
        });
      }
      ComponentSequence = (
        <Grid item sx={{ maxWidth: 200 }} xs={12}>
          <TypeSequences
            external={[
              { idSequence: -1, name: 'Without Sequence' },
              { idSequence: -2, name: 'With Sequence' },
            ]}
            multiple
            onSequenceChanged={this.handleSelectedSequence}
            options={options}
            sequence={filters.sequence}
            size="small"
          />
        </Grid>
      );
      ComponentNifti = hide_nii ? null : (
        <Grid item>
          <AutoComboBox
            defaultValue={selected}
            holder={this.holder}
            label="NIfTI"
            onObjectChanged={this.handleNiftiChanged}
            options={options_nifti}
            small
            width={150}
          />
        </Grid>
      );
      ComponentMoldalities = hide_modalities ? null : (
        <Grid item xs={12}>
          <Autocomplete
            getOptionLabel={option => option.name}
            id="multiple-limit-tags"
            isOptionEqualToValue={(option, value) => {
              if (value) return option.name === value.name;
              else return false;
            }}
            limitTags={2}
            multiple
            onChange={(event, values) => this.handleModalitiesChanged(values)}
            options={ListModalities}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Modalities"
                placeholder="Select modalities..."
              />
            )}
            size="small"
            value={modalities}
          />
        </Grid>
      );
    }
    const ComponentDate = hide_date ? null : (
      <Grid item>
        <DateRangePicker
          dateend={selectedToDate}
          datestart={selectedFromDate}
          handleDateChange={this.handleFromDateChange}
          holder={this.holder}
          label="Date Range"
          text_size="small"
          withPortal={false}
        />
      </Grid>
    );
    return (
      <React.Fragment>
        <TitleSort title="Filters" />
        <Grid
          container
          spacing={1}
          sx={{
            paddingLeft: 1.5,
            paddingRight: 1,
            paddingBottom: 2,
            minWidth: 350,
            maxWidth: 400,
          }}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-start"
            xs={12}>
            <Grid item xs={10}>
              <SearchFilesProjects
                add_empty
                handleAddError={this.props.handleAddError}
                handleAddSeletedItem={this.handleAddProject}
                holder={this.holder}
                no_icon
                selected={filters.project}
                small
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="search"
                color="inherit"
                onClick={this.props.handleSearch}
                size="large"
                sx={{ marginTop: 1, marginLeft: 1 }}>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
          {ComponentUsers}
          {ComponentSequence}
        </Grid>
        <Divider />
        {/* <TitleSort title="Date Range" /> */}
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          spacing={1}
          style={{
            padding: 6,
            paddingLeft: 13,
            marginTop: 1,
            maxWidth: 390,
          }}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="center"
            spacing={1}
            xs={12}>
            {ComponentDate}
            {ComponentNifti}
          </Grid>
          {ComponentMoldalities}
        </Grid>
      </React.Fragment>
    );
  }
}

FilterContainer.propTypes = {
  filters: PropTypes.object,
  handleAddError: PropTypes.func,
  handleSearch: PropTypes.func,
  holder: PropTypes.object,
  onFilterChanged: PropTypes.func,
  show_user: PropTypes.bool,
  view: PropTypes.string,
};
FilterContainer.defaultProps = {
  filters: {
    ...defaultFilter,
  },
  handleAddError: () => '',
  handleSearch: () => '',
  onFilterChanged: () => '',
  holder: null,
  show_user: false,
  view: 'series',
  hide_nii: false,
  hide_date: false,
  hide_modalities: false,
};
export default FilterContainer;
