import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { withRouter } from 'react-router-dom';

import FolderIcon from '@mui/icons-material/Folder';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { DialogShareFolders, TableRights } from 'components';
import { DownloadLink, SharedLink } from '../components';
import useStyles from './Style';
import { QueryFolderContentAtFolder } from 'graphql/Folders';
import {
  FormatBytes,
  FormatTime,
  GetData,
  GetNameFromHeader,
  GetResponseError,
  ServerErrorsString,
} from 'helpers';
import ButtonBoxShareDelete from '../ButtonBoxShareDelete';

import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import { IsInvalid } from 'helpers';

class FolderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open_share: false,
      downloading: false,
      folder_contents: null,
      idFolder: null,
      total_elements: 0,
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.renderAgainFolderDetails = () => {
        this.forceUpdate();
      };
    }
  }
  componentDidMount() {
    this.runUpdateContent();
  }
  componentDidUpdate() {
    const { folder } = this.props;
    if (folder === undefined || !folder) return;
    if (folder.idFolder !== this.state.idFolder) {
      this.runUpdateContent();
    }
  }
  runUpdateContent = () => {
    const { folder } = this.props;
    if (IsInvalid(folder)) return;
    const { idFolder } = folder;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryFolderContentAtFolder(idFolder)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, folder_contents } = data.allFolderContent;
          if (ok) {
            this.setState({
              idFolder,
              folder_contents,
              total_elements: folder_contents.length,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleShareFolder = rights => {
    this.props.folder.Rights = rights;
  };
  handleOpenShare = status => {
    this.setState({ open_share: status });
  };
  handleClickDownload = () => {
    const { downloading } = this.state;
    if (downloading) return;
    const { folder } = this.props;
    if (!folder) return;
    const { idFolder } = folder;
    const Ids = [{ idFolder }];
    this.setState({ downloading: true });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(Ids);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key, this.handleDownloadProgress)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.handleDownloadFinished();
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleClickShow = () => {
    const { folder, history, base } = this.props;
    const { idFolder } = folder;
    if (!idFolder) return;
    if (base === 'Shared') {
      history.push(`/explorer/shared/folder/${idFolder}`);
    } else history.push(`/explorer/folder/${idFolder}`);
  };
  handleDownloadProgress = (event, loading) => {
    const { folder } = this.props;
    if (folder === undefined) return;
    let size = folder.Size;
    this.props.handleProgress(event, loading, size);
  };
  handleDownloadFinished = () => {
    const { folder } = this.props;
    if (folder === undefined) return;
    let size = folder.Size;
    this.props.handleProgress({ loaded: size, total: size }, size, size);
  };
  render() {
    const {
      folder,
      loading,
      user,
      handleSubFolderNameChanged,
      classes,
      theme,
      handleDrawerClose,
      isMobile,
    } = this.props;
    const { open_share, downloading, folder_contents } = this.state;
    const { Owner } = folder;
    let OwnerName = '';
    let owner = false;
    let size = FormatBytes(folder.Size);
    if (Owner && Owner.FullName) {
      OwnerName = Owner.FullName;
      owner = user.id === Owner.id;
    }
    let OpenClose = null;
    if (!isMobile) {
      OpenClose = (
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === 'rtl' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      );
    }
    let ComponentSharedLink = null;
    let ComponentDownloadLink = null;
    if (this.props.base === 'Home') {
      ComponentSharedLink = (
        <SharedLink
          folder={folder}
          handleError={this.props.handleError}
          handleGetSharedLink={this.props.handleGetSharedLink}
          handleSharedLinkChanged={this.props.handleSharedLinkChanged}
          owner={owner}
          user={user}
        />
      );
      ComponentDownloadLink = (
        <DownloadLink
          folder={folder}
          handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
          handleError={this.props.handleError}
          handleGetDownloadLink={this.props.handleGetDownloadLink}
          owner={owner}
          user={user}
        />
      );
    }
    let total_files = folder.total_files;
    let total_series_files = 0;
    let total_series = 0;
    if (folder_contents && !loading && !this.state.loading) {
      total_files = 0;
      folder_contents.forEach(x => {
        const { File, Series } = x;
        if (File) total_files += 1;
        if (Series) {
          total_series++;
          total_series_files += Series.NumberOfSeriesRelatedInstances;
        }
      });
    }
    // console.log({ folder_contents });
    let ComponentSeriesFiles = null;
    if (total_series > 0) {
      ComponentSeriesFiles = (
        <ListItem
          secondaryAction={
            <Typography gutterBottom style={{ color: 'black' }} variant="body2">
              {`${total_series}/${total_series_files}`}
            </Typography>
          }
          sx={{ padding: 0, margin: 0 }}>
          <ListItemText
            primary={
              <Typography
                gutterBottom
                style={{ fontWeight: 1000, color: 'black' }}
                variant="body2">
                #Series/Files
              </Typography>
            }
          />
        </ListItem>
      );
    }
    return (
      <React.Fragment>
        <Divider />
        <ListItem className={classes.drawerHeader}>
          {OpenClose}
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            onDoubleClick={() =>
              handleSubFolderNameChanged(folder.idFolder, folder.name)
            }
            primary={`${folder.name} `}
            secondary={`Created: ${FormatTime(
              folder.createdAt,
              'MMMM Do YYYY, h:mm:ss a'
            )} (ID: ${folder.idFolder})`}
          />
        </ListItem>
        <Divider variant="inset" />

        <div className={classes.section1}>
          <Grid alignItems="center" container>
            <Grid item xs>
              <Typography gutterBottom variant="h4">
                Settings
              </Typography>
            </Grid>
          </Grid>
          <ListItemText
            primary={
              <Typography gutterBottom variant="h6">
                Owner
              </Typography>
            }
            secondary={OwnerName}
          />
          <ListItem
            secondaryAction={
              <Typography
                gutterBottom
                style={{ color: 'black' }}
                variant="body2">
                {total_files}
              </Typography>
            }
            sx={{ padding: 0, margin: 0 }}>
            <ListItemText
              primary={
                <Typography
                  gutterBottom
                  style={{ fontWeight: 1000, color: 'black' }}
                  variant="body2">
                  #Files
                </Typography>
              }
            />
          </ListItem>
          {ComponentSeriesFiles}
          <ListItem
            secondaryAction={
              <Typography
                gutterBottom
                style={{ color: 'black' }}
                variant="body2">
                {folder.total_sub_folders}
              </Typography>
            }
            sx={{ padding: 0, margin: 0, paddingRight: 2 }}>
            <ListItemText
              primary={
                <Typography
                  gutterBottom
                  style={{ fontWeight: 1000, color: 'black' }}
                  variant="body2">
                  #Subfolder
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            secondaryAction={
              <Typography
                gutterBottom
                style={{ color: 'black' }}
                variant="body2">
                {size}
              </Typography>
            }
            sx={{ padding: 0, margin: 0, paddingRight: 2 }}>
            <ListItemText
              primary={
                <Typography
                  gutterBottom
                  style={{ fontWeight: 1000, color: 'black' }}
                  variant="body2">
                  Size
                </Typography>
              }
            />
          </ListItem>
          {ComponentSharedLink}
          {ComponentDownloadLink}
          <ListItemText
            primary={
              <Typography
                gutterBottom
                style={{ textTransform: 'none', fontSize: 15 }}
                variant="h6">
                Permissions
              </Typography>
            }
          />
          <TableRights rights={folder.Rights} />
        </div>
        <Divider />
        <ButtonBoxShareDelete
          downloading={downloading}
          handleButtonDelete={() => this.props.handleOpenDelete(folder)}
          handleButtonShare={() => this.handleOpenShare(true)}
          handleClickDownload={this.handleClickDownload}
          handleClickShow={this.handleClickShow}
          loading={loading}
          open
          owner={owner}
          show_text="Open Folder"
        />
        <DialogShareFolders
          folders={[folder]}
          handleClose={() => this.handleOpenShare(false)}
          handleShareFolder={this.handleShareFolder}
          open={open_share}
        />
      </React.Fragment>
    );
  }
}

FolderDetails.defaultProps = {
  handleGetSharedLink: () => '',
  handleProgress: () => '',
  deleting: false,
};
const ContainerFolderDetails = props => {
  const theme = useTheme();
  return <FolderDetails theme={theme} {...props} />;
};
export default withStyles(withRouter(ContainerFolderDetails), useStyles);
