import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetContentDate, GetContentSize } from 'helpers/utils_explorer';
import { FormatTime } from 'helpers';
function createData(name, description, group_name = null) {
  return { name, description, group_name };
}

const rows_original = [
  createData('Klinik', 'Neurochirurgie', 'Clinics Designer'),
  createData('Thema', 'Atlas of Craniotomies', 'Projects'),
  createData('Projekt', 'Andreas Raabe', 'Project Designer'),
  createData('Autor', 'Andreas Raabe', 'Project Designer'),
  createData('Urheber', 'Alain Blank', 'Creators Designer'),
  createData(
    'Copyright',
    'Inselspital, Bern University Hospital, Dept. of Neurosurgery',
    'Copyright'
  ),
  createData('Keywords', 'Craniotomy atlas infratentorial approach ....'),
  // createData('Thema (Folder)', ''),
  createData('Publikation', '', 'Publication Designer'),
  createData('Bildtyp', 'Illustration', 'Image Type Designer'),
  createData('Notizen', ''),
  createData('Referenz', ''),
  createData('Original name', ''),
  createData('Auflösung', 'automatisch ausgelesen'),
  createData('Dateigrösse', 'automatisch ausgelesen'),
  createData('Erstelldatum', 'automatisch ausgelesen'),
];

class TableImageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getGroupValue = group_name => {
    const { metadata } = this.props;
    if (metadata) {
      const { group_mappings } = metadata;
      if (!group_mappings || group_mappings === undefined) {
        return '';
      }
      const index = group_mappings.map(x => x.group.name).indexOf(group_name);
      if (index === -1) return '';
      return group_mappings[index].name;
    }
    return '';
  };
  getRows = () => {
    const rows = [];
    for (let i = 0; i < rows_original.length; i++) {
      const { group_name, name } = rows_original[i];
      const row = { name, description: '' };
      if (group_name) {
        row.description = this.getGroupValue(group_name);
      }
      rows.push(row);
    }
    const { folder_content, metadata } = this.props;
    let index = -1;
    if (folder_content) {
      index = rows.map(x => x.name).indexOf('Dateigrösse');
      if (index !== -1) {
        rows[index].description = GetContentSize(folder_content);
      }
      index = rows.map(x => x.name).indexOf('Erstelldatum');
      if (index !== -1) {
        rows[index].description = GetContentDate(folder_content);
      }
      index = rows.map(x => x.name).indexOf('Thema');
      if (index !== -1) {
        const { project } = folder_content;
        rows[index].description = project ? project.name : '';
      }
      index = rows.map(x => x.name).indexOf('Original name');
      if (index !== -1) {
        const { File } = folder_content;
        rows[index].description = File ? File.original_name : '';
      }
    }
    if (metadata) {
      let index = rows.map(x => x.name).indexOf('Notizen');
      if (index !== -1 && metadata.notes) {
        rows[index].description = metadata.notes;
      }
      index = rows.map(x => x.name).indexOf('Referenz');
      if (index !== -1 && metadata.comments) {
        rows[index].description = metadata.comments;
      }
      index = rows.map(x => x.name).indexOf('Projekt');
      if (index !== -1 && metadata.group_mappings) {
        rows[index].description = metadata.group_mappings
          .filter(x => x.group && x.group.name === 'Project Designer')
          .map(x => x.name)
          .join(', ');
      }
      index = rows.map(x => x.name).indexOf('Publikation');
      if (index !== -1 && metadata.group_mappings) {
        rows[index].description = metadata.group_mappings
          .filter(x => x.group && x.group.name === 'Publication Designer')
          .map(x => x.name)
          .join(', ');
      }
      index = rows.map(x => x.name).indexOf('Autor');
      if (index !== -1 && metadata.group_mappings) {
        rows[index].description = metadata.group_mappings
          .filter(x => x.group && x.group.name === 'Authors Designer')
          .map(x => x.name)
          .join(', ');
      }
      index = rows.map(x => x.name).indexOf('Keywords');
      if (index !== -1 && metadata.group_mappings) {
        rows[index].description = metadata.group_mappings
          .filter(x => x.group && x.group.name === 'Keyword Designer')
          .map(x => x.name)
          .join(', ');
      }
      index = rows.map(x => x.name).indexOf('Copyright');
      if (index !== -1 && metadata.group_mappings) {
        rows[index].description = metadata.group_mappings
          .filter(x => x.group && x.group.name === 'Copyright')
          .map(x => x.name)
          .join(', ');
      }
      index = rows.map(x => x.name).indexOf('Erstelldatum');
      if (
        index !== -1 &&
        metadata.creationTime &&
        metadata.creationTime !== 'Empty'
      ) {
        rows[index].description = FormatTime(
          metadata.creationTime,
          'MMM Do - YYYY, h:mm a'
        );
      }
      let meta_image = null;
      try {
        meta_image = JSON.parse(metadata.information);
      } catch (error) {
        console.log('Missing information in metadata');
      }
      if (meta_image) {
        index = rows.map(x => x.name).indexOf('Auflösung');
        if (index !== -1 && meta_image.width && meta_image.height) {
          rows[index].description = `${meta_image.width}x${meta_image.height}`;
        }
      }
    }
    return rows;
  };
  render() {
    const rows = this.getRows();
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small" sx={{ minWidth: 150 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 2000 }}>Klassifikation</TableCell>
              <TableCell align="right">Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 1000 }}>
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TableImageDetails.propTypes = {
  classes: PropTypes.object,
};

export default TableImageDetails;
