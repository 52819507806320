import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CheckRights } from './components';
import { getRightName } from 'helpers/utils_rights';

const useStyles = makeStyles()({
  table: {
    minWidth: 250,
  },
  tablecell: { fontSize: '8pt' },
});

const TableRights = props => {
  const { rights, add_id } = props;
  const { classes } = useStyles();
  let style = {
    minHeight: 90,
    overflow: 'auto',
  };
  let ComponentHeaderID = null;
  if (add_id) {
    ComponentHeaderID = <TableCell>ID</TableCell>;
  }
  return (
    <TableContainer component={Paper} style={style}>
      <Table
        aria-label="a dense table"
        className={classes.table}
        size="small"
        stickyHeader>
        <TableHead>
          <TableRow>
            {ComponentHeaderID}
            <TableCell>User/Project/Team</TableCell>
            <TableCell>W/R/Do/De/F</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rights.map((right, i) => {
            const name = getRightName(right);
            let ComponentID = null;
            if (add_id) {
              ComponentID = (
                <TableCell style={{ maxWidth: 'auto', fontSize: '8pt' }}>
                  {right.idRight}
                </TableCell>
              );
            }
            return (
              <TableRow className={classes.tablecell} key={`right-${i}`}>
                {ComponentID}
                <TableCell style={{ maxWidth: 'auto', fontSize: '8pt' }}>
                  {name}
                </TableCell>
                <TableCell style={{ maxWidth: 'auto' }}>
                  <CheckRights right={right} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
TableRights.defaultProps = {
  rights: [],
};
TableRights.propTypes = {
  rights: PropTypes.array,
};
export default TableRights;
