import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  ButtonDeleteIcon,
  DivGrow,
  IconButtonLoad,
  TableGrid,
} from 'components';
import {
  ServerErrorsString,
  GetData,
  IsInvalid,
  rowsPerPageBig,
} from 'helpers';
import { Replay as ReloadIcon } from '@mui/icons-material';
import { columns_sessions } from './Styles';
import { MutationDeleteUserSessions, QueryUserSessions } from 'graphql/Users';

class UserViewSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 0,
      limit: 10,
      sessions: [],
      selected: [],
      idUser: -1,
      empty: false,
      deleting: false,
    };
  }
  componentDidMount() {
    this.loadSessions();
  }
  componentDidUpdate() {
    const { edit_user } = this.props;
    if (edit_user && edit_user.id !== this.state.idUser) {
      this.loadSessions();
    }
  }
  loadSessions = (newPage, newLimit) => {
    const { edit_user } = this.props;
    if (IsInvalid(edit_user)) return;
    const { loading } = this.state;
    let { page, limit } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      const idUser = edit_user.id;
      QueryUserSessions({ idUser }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, sessions, errors, total } = data.userSessions;
          if (ok) {
            this.setState({
              page,
              limit,
              loading: false,
              sessions,
              idUser,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idUser: edit_user.id });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSessions = () => {
    const { deleting, selected, sessions, total } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteUserSessions(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSessions;
          if (ok) {
            this.setState({
              deleting: false,
              sessions: sessions.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
              total: total - ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, selected, deleting } = this.state;
    let ComponetDelete = null;
    if (selected.length) {
      ComponetDelete = (
        <Grid item>
          <ButtonDeleteIcon
            loading={deleting}
            onClick={this.handleDeleteSessions}
            selected={selected}
          />
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          p={1}
          spacing={1}>
          <Grid item>User Sessions</Grid>
          <DivGrow />
          {ComponetDelete}
          <Grid item>
            <IconButtonLoad
              handleClick={() => this.loadSessions()}
              icon={<ReloadIcon />}
              loading={loading}
              small
              tooltip="Reload Table"
            />
          </Grid>
        </Grid>
        <TableGrid
          checkboxSelection
          dense
          external_selected={selected}
          headers={columns_sessions}
          loading={loading}
          onPageChange={page => this.loadSessions(page, this.state.limit)}
          onRowsPerPageChange={limit => this.loadSessions(0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.sessions}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </React.Fragment>
    );
  }
}

UserViewSessions.propTypes = {
  classes: PropTypes.object,
};

export default UserViewSessions;
