import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
// import ContainerUpperLogo from '../HomeMain';
import { HomeMain as ContainerUpperLogo } from 'components';
import CustomButton from '../CustomButton';
import useStyles from '../../Styles';

class HomeUpperLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <ContainerUpperLogo filter image={'/images/profile_1.png'}>
        <div className={classes.container}>
          <Grid className={classes.grid} container>
            <Grid item md={8} sm={12} xs={12}>
              <h1 className={classes.title}>
                Helping to facilitate biomedical research.
              </h1>
              <h4>
                Alliances provide a key strategic advantage in the research of
                diseases and in the development of new treatments. We provide
                the tools and system to help your collaborative work and
                research. Privacy and security are in the core of our system to
                ensure the anonymity during your research.
              </h4>
              <br />
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                justifyContent="flex-start"
                spacing={3}>
                <Grid item>
                  <CustomButton
                    color="danger"
                    href="https://www.youtube.com/watch?v=WO0nARK-0Fc"
                    rel="noopener noreferrer"
                    size="lg"
                    target="_blank">
                    <i className="fas fa-play" />
                    Watch video
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton
                    color="info"
                    href="https://formative.jmir.org/2022/4/e32287/"
                    rel="noopener noreferrer"
                    size="lg"
                    style={{ maxWidth: 165 }}
                    target="_blank">
                    <i className="fas fa-play" />
                    Read Our Paper
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </ContainerUpperLogo>
    );
  }
}

HomeUpperLogo.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(HomeUpperLogo, useStyles);
