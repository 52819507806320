import { colors } from '@mui/material';

export const useStyles = theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'space-between', //'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  button_content: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  menu_item: {
    fontSize: '13px',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  selected: {
    backgroundColor: '#c8c4c4',
    color: 'white',
    fontWeight: 600,
    borderLeft: '4px solid #949494',
  },
  menu_item_selected: {
    backgroundColor: '#d5d3d3',
    fontWeight: 600,
    borderLeft: '4px solid #898989',
    color: 'grey',
  },
});
