import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUER_REQUEST_USER = gql`
  query usersRequest($idUser: LongLong!) {
    usersRequest(idUser: $idUser) {
      ok
      request {
        idRequest
        idUser
        Unit {
          idUnit
          name
        }
        User {
          id
          FullName
        }
        email
        Message
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryRequestUser = idUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUER_REQUEST_USER),
    variables: {
      idUser,
    },
  });
};
const ACCEPT_REQUESTS = gql`
  mutation registerRequest($idRequest: Int!) {
    registerRequest(idRequest: $idRequest) {
      ok
      warning
      errors {
        path
        message
      }
    }
  }
`;

export const AcceptRequest = idRequest => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(ACCEPT_REQUESTS),
    variables: {
      idRequest,
    },
  });
};

const DELETE_REQUESTS = gql`
  mutation deleteRequest($idRequest: Int!) {
    deleteRequest(idRequest: $idRequest) {
      ok
      warning
      errors {
        path
        message
      }
    }
  }
`;

export const DeleteRequest = idRequest => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_REQUESTS),
    variables: {
      idRequest,
    },
  });
};

const QUERY_UPDATE_REQUESTS = gql`
  query usersRequests($name: String, $page: Int, $limit: Int) {
    usersRequests(name: $name, page: $page, limit: $limit) {
      ok
      total
      requests {
        idRequest
        Unit {
          idUnit
          name
        }
        User {
          id
          FullName
          email
          address {
            country
          }
        }
        email
        Message
        createdAt
      }
      warning
      errors {
        path
        message
      }
    }
  }
`;

export const QueryRequests = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_UPDATE_REQUESTS),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const DEVICE_CREATE = gql`
  mutation createDevice($DeviceInput: DeviceInput!) {
    createDevice(DeviceInput: $DeviceInput) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const CreateDevice = DeviceInput => {
  console.log('CreateDevice'); //DeviceInput
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DEVICE_CREATE),
    variables: {
      DeviceInput,
    },
  });
};
