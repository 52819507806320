import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { TableUsers } from 'components';
import { FormatTime, getInitials, PaperComponent } from 'helpers';

const useStyles = theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200
  },
});

class DialogViewTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_complete: false,
      options: [],
      emails: [],
      email: '',
    };
  }

  render() {
    const { open, handleCloseDialog, handleSetUsers, team } = this.props;
    return team ? (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={handleCloseDialog}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={team.avatarUrl}>{getInitials(team.name)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={team.name}
                secondary={
                  'Created: ' + FormatTime(team.createdAt, 'MMM Do, YYYY')
                }
              />
            </ListItem>
            <Divider variant="inset" />
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ListItemText
                  primary={
                    <Typography gutterBottom variant="h6">
                      Administrator
                    </Typography>
                  }
                  secondary={
                    team.administrator ? team.administrator : 'Not defined'
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <ListItemText
                  primary={
                    <Typography gutterBottom variant="h6">
                      Project
                    </Typography>
                  }
                  secondary={team.project ? team.project : 'Not defined'}
                />
              </Grid>
            </Grid>
            <TableUsers
              handleDeleteUser={handleSetUsers}
              rows={isNaN(team.members) ? team.members : []}
              title={'Team Users'}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={handleCloseDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  }
}

DialogViewTeam.propTypes = {
  className: PropTypes.string,
  //   projectCreateUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  users: state.projects.users,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(DialogViewTeam, useStyles));
