import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Divider, Drawer, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useStyles, drawFilterWidth } from './useStyles';
import { CategoryProjects, GetFilters } from './components';
import { ComboxGroupMapping, ComboxMultiGroupMapping } from 'components';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

class ExplorerSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { project: null, filters: [] };
  }
  componentDidUpdate() {
    const { project } = this.props;
    if (project) {
      if (this.state.project && this.state.project.id !== project.id) {
        this.setState({ project });
      } else if (!this.state.project) {
        this.setState({ project });
      }
    }
  }
  handleSelectProject = project => {
    console.log('handleSelectProject', { project });
    this.props.handleSelectProject(project);
    this.setState({ project });
  };
  handleGroupMappingSelected = (idGroup, group_name, newValue) => {
    const filters = GetFilters(idGroup, newValue, this);
    console.log({ idGroup, filters });
    this.props.handleSelectFilters(filters);
    this.setState({ filters });
  };
  handleProjectSelected = project => {
    console.log('handleProjectSelected', { project });
    this.props.handleSelectProject(project);
    this.setState({ project });
  };
  render() {
    const {
      classes,
      open_drawer,
      isMobileSmall,
      handleDrawerClose,
    } = this.props;
    return (
      <Drawer
        anchor="left"
        classes={{
          paper: clsx(classes.drawer_paper, {
            [classes.drawer_position]: !open_drawer,
            [classes.drawer_mobile]: !isMobileSmall && !open_drawer,
          }),
        }}
        onClose={() => handleDrawerClose(false)}
        open={open_drawer}
        // sytle={{ height: height + 65 }}
        variant={isMobileSmall ? 'relative' : 'persistent'}>
        <DrawerHeader>
          <div className={classes.filter}>Filters</div>
          <IconButton onClick={() => handleDrawerClose(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Grid
          alignItems="stretch"
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
          style={{
            marginLeft: 2,
            maxWidth: drawFilterWidth - 15,
            paddingTop: 25,
          }}>
          <Grid item xs={12}>
            <ComboxGroupMapping
              default_input="All"
              group_name="Clinics Designer"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Clinics"
            />
          </Grid>
          <Grid item xs={12}>
            <CategoryProjects
              default_input="All"
              handleProjectSelected={this.handleProjectSelected}
              label="Thema"
            />
          </Grid>
          <Grid item xs={12}>
            <ComboxGroupMapping
              default_input="All"
              group_name="Project Designer"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Projekt"
            />
          </Grid>
          <Grid item xs={12}>
            <ComboxGroupMapping
              default_input="All"
              group_name="Authors Designer"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Authors"
            />
          </Grid>
          <Grid item xs={12}>
            <ComboxGroupMapping
              default_input="All"
              group_name="Creators Designer"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Creators"
            />
          </Grid>
          <Grid item xs={12}>
            <ComboxMultiGroupMapping
              default_input="All"
              group_name="Publication Designer"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Publications"
            />
          </Grid>
          <Grid item xs={12}>
            <ComboxGroupMapping
              default_input="All"
              group_name="Copyright"
              handleGroupMappingSelected={this.handleGroupMappingSelected}
              label="Copyrights"
            />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

ExplorerSideBar.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ExplorerSideBar, useStyles);
