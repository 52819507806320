import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { QueryClinicalType } from 'graphql/DataClinics/utils_clinical_type';

class DialogClinicalType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clinical_types: [],
      code: 1,
      description: '',
      loading_types: false,
      init: false,
    };
  }
  componentDidUpdate() {
    if (this.props.open && !this.state.init) {
      this.handleUpdateClinicalDataType();
    }
  }
  handleUpdateClinicalDataType = () => {
    if (!this.props.isActive) return;
    const { loading_clinical } = this.state;
    if (loading_clinical) return;
    this.setState({ loading_clinical: true });
    (async () => {
      QueryClinicalType()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, clinical_types, total } = data.clinicalDataTypes;
          if (ok) {
            clinical_types.unshift({ description: 'None', id: 0 });
            this.setState({
              loading_clinical: false,
              clinical_types,
              total,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_clinical: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddClinicalType = () => {
    const { code, description } = this.state;
    if (
      IsInvalid(code) ||
      IsInvalid(description) ||
      code === '' ||
      description === ''
    ) {
      this.props.handleAddError('You need to write a code and a clinical type');
      return;
    }
    this.props.handleAddClinicalType({ code, description });
  };
  handleChangeCode = event => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      this.setState({ code: inputValue });
    }
  };
  render() {
    const { open, handleClose } = this.props;
    if (!open) return null;
    const { code, description } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add Clinical type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill the clinical type fields and press ADD.
          </DialogContentText>
          <Grid container pt={2} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="type-code"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                label="Clinical type code"
                margin="normal"
                name="code"
                onChange={this.handleChangeCode}
                placeholder="Add a code number for this clinical type"
                type="number"
                value={code}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="type-description"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Clinical type name"
                margin="normal"
                name="description"
                onChange={event =>
                  this.setState({ description: event.target.value })
                }
                placeholder="Add a name for this clinical type"
                value={description}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAddClinicalType}>Add</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogClinicalType.propTypes = {
  open: PropTypes.bool,
};

export default DialogClinicalType;
