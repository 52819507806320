import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Grid,
  Divider,
} from '@mui/material';
import {
  SearchProjectsAtUser,
  GroupBox,
  ButtonLoading,
  TextFieldCustom,
} from 'components';
import ButtonAddFolder from '../ButtonAddFolder';
import {
  dicom_tags,
  defaultState,
  handleUpdateBatchSetting,
  handleUpdateJobSetting,
} from './Styles';
import { IsInvalid } from 'helpers';

class DialogBatchProcessSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      set: false,
    };
    let { batch, setting } = props;
    if (setting === undefined || !setting) {
      if (batch && batch.setting) setting = { ...batch.setting };
    }
    if (!setting) {
      setting = {
        ...defaultState,
      };
    }
    if (
      !setting.tags.length &&
      setting.folder_structure &&
      setting.folder_structure !== ''
    ) {
      setting.tags = setting.folder_structure.split('/');
    }
    this.state = {
      changed: [0, 0, 0, 0, 0],
      setting: {
        ...setting,
      },
      updating: false,
    };
    this.holder = {
      getFolder: null,
    };
    if (props.holder) {
      props.holder.setSetting = setting => {
        if (!setting) {
          setting = {
            ...defaultState,
          };
        }
        this.setState({ setting, set: true });
      };
    }
  }
  componentDidMount() {
    let { batch, setting } = this.props;
    if (IsInvalid(setting)) {
      if (batch && batch.setting) setting = { ...batch.setting };
    }
    if (setting) {
      this.setState({ setting, set: true });
    }
  }
  componentDidUpdate() {
    const { set } = this.state;
    let { batch, setting } = this.props;
    if (!set) {
      if (IsInvalid(setting)) {
        if (batch && batch.setting) {
          setting = { ...batch.setting };
        }
      }
      if (setting) {
        this.setState({ setting, set: true });
      }
    }
  }
  handleChange = event => {
    const setting = {
      ...this.state.setting,
      [event.target.name]: event.target.checked,
    };
    this.setState({ setting });
  };
  handleSelectProject = project => {
    const setting = {
      ...this.state.setting,
      project,
    };
    this.setState({ setting });
  };
  handleSelectFolder = folder => {
    const setting = {
      ...this.state.setting,
      folder,
    };
    this.setState({ setting });
  };
  handleSelectBatchLogFolder = batch_log_folder => {
    const setting = {
      ...this.state.setting,
      batch_log_folder,
    };
    this.setState({ setting });
  };
  handleTagsChanged = (event, new_tags) => {
    let { setting } = this.state;
    this.setState({
      setting: { ...setting, tags: new_tags },
    });
  };
  handleSave = () => {
    if (this.props.type === 'batch') {
      handleUpdateBatchSetting(this);
    } else if (this.props.type === 'job') {
      handleUpdateJobSetting(this);
    } else {
      this.props.handleAddError('Invalid setting type (Batch or Job)');
    }
  };
  IsChanged = () => {
    const { batch } = this.props;
    if (!batch) return false;
    const setting_props = batch.setting;
    if (!setting_props) return false;
    let { setting } = this.state;
    if (setting.tags === undefined || !setting.tags) {
      setting.tags = [];
    }
    if (setting_props.tags === undefined || !setting_props.tags) {
      setting_props.tags = [];
    }
    const text_tags = setting.tags
      .map(x => {
        if (x.tag) return x.tag;
        return x;
      })
      .join('/');
    const text_tags_new = setting_props.tags
      .map(x => {
        if (x.tag) return x.tag;
        return x;
      })
      .join('/');
    if (text_tags !== text_tags_new) return true;
    if (setting.own_folder !== setting_props.own_folder) return true;
    if (setting.create_batch_log !== setting_props.create_batch_log)
      return true;
    if (setting.on_content !== setting_props.on_content) return true;
    if (setting.folder && !setting_props.folder) return true;
    else if (!setting.folder && setting_props.folder) return true;
    else if (setting.folder && setting_props.folder) {
      if (setting.folder.idFolder !== setting_props.folder.idFolder)
        return true;
    } else if (setting.folder !== setting_props.folder) return true;
    if (setting.batch_log_folder && !setting_props.batch_log_folder)
      return true;
    else if (!setting.batch_log_folder && setting_props.batch_log_folder)
      return true;
    else if (setting.batch_log_folder && setting_props.batch_log_folder) {
      if (
        setting.batch_log_folder.idFolder !==
        setting_props.batch_log_folder.idFolder
      )
        return true;
    } else if (setting.batch_log_folder !== setting_props.batch_log_folder)
      return true;
    if (setting.project && !setting_props.project) return true;
    else if (!setting.project && setting_props.project) return true;
    else if (setting.project && setting_props.project) {
      if (setting.project.id !== setting_props.project.id) return true;
    } else if (setting.project !== setting_props.project) return true;
    return false;
  };
  render() {
    const { open, batch } = this.props;
    if (IsInvalid(batch)) {
      return null;
    }
    const { setting } = this.state;
    const { on_content, own_folder, project, folder } = setting;
    let tags = [];
    if (setting.tags) {
      tags = setting.tags.map(x => {
        if (x.tag) return x;
        return { tag: x };
      });
    }
    return (
      <Dialog onClose={this.props.handleClose} open={open}>
        <DialogTitle>
          {`Batch Process Setting ${
            batch && batch.id ? `( ID: ${batch.id})` : ''
          }`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Please select your configuration and press save.
          </DialogContentText>
          <Grid container spacing={1} sx={{ paddingTop: 1 }}>
            <Grid item xs={10}>
              <SearchProjectsAtUser
                external_selected={project}
                handleAddProject={this.handleSelectProject}
                handleSetProject={this.handleSelectProject}
                no_icon
                placeholder="Search My Projects..."
              />
            </Grid>
            <Grid item sx={{ marginTop: 1, marginBottom: 1 }} xs={12}>
              <GroupBox title_box="Base Folder">
                <ButtonAddFolder
                  folder={folder}
                  handleAddError={this.props.handleAddError}
                  handleSelectFolder={this.handleSelectFolder}
                  holder={this.holder}
                />
              </GroupBox>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={option => option.tag}
                id="dicom-tags"
                multiple
                onChange={this.handleTagsChanged}
                options={dicom_tags}
                renderInput={params => (
                  <TextFieldCustom
                    {...params}
                    label="Folder structure"
                    placeholder="structure"
                    variant="standard"
                  />
                )}
                value={tags}
              />
            </Grid>
            <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={own_folder}
                    name="own_folder"
                    onChange={this.handleChange}
                  />
                }
                label="Own folder"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={on_content}
                    name="on_content"
                    onChange={this.handleChange}
                  />
                }
                label="On content"
              />
            </Grid>
            {/* <Grid container item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={create_batch_log}
                    name="create_batch_log"
                    onChange={this.handleChange}
                  />
                }
                label="Generate a batch log file"
              />
            </Grid>
            {create_batch_log ? (
              <Grid item sx={{ marginTop: 1, marginBottom: 1 }} xs={12}>
                <GroupBox title_box="Batch Log File Location">
                  <ButtonAddFolder
                    folder={batch_log_folder}
                    handleAddError={this.props.handleAddError}
                    handleSelectFolder={this.handleSelectBatchLogFolder}
                    holder={this.holder}
                  />
                </GroupBox>
              </Grid>
            ) : (
              ''
            )} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <ButtonLoading
            disable_button={!this.IsChanged()}
            handleClick={this.handleSave}
            loading={this.state.updating}
            name="Save"
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogBatchProcessSetting.propTypes = {
  handleClose: PropTypes.func,
  handleUpdateBatchProcess: PropTypes.func,
};
DialogBatchProcessSetting.defaultProps = {
  handleClose: () => '',
  open: false,
  handleSave: () => '',
  handleUpdateBatchProcess: () => '',
  type: 'batch', // batch, job
};

export default DialogBatchProcessSetting;
