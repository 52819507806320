import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Avatar from '@mui/material/Avatar';
import { IsValid, IsInvalid, FormatTime, GetThumbnailFile } from 'helpers';

class ClinicalFileCatalogueViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  formatFileList(files) {
    let formatted_files = [];
    files.forEach(file => {
      const {
        id,
        idEpilepsyCase,
        idSleepCase,
        idPatient,
        idFileObject,
        idSeries,
        idSignalEDF,
        idFolder,
        folder_content,
        file_object,
        series,
        edf,
        folder,
      } = file;
      let filename = '';
      let type = '';
      let filepath = '';
      let icon = '';
      let created = '';
      if (IsValid(idFileObject)) {
        filename = file_object.original_name;
        type = 'file_object';
        filepath = file_object.filepath;
        created = this.formatDate(file_object.createdAt);
        icon = GetThumbnailFile(filename);
      } else if (IsValid(idSeries)) {
        filename = series.DicomFiles[0].OriginalName;
        type = 'series';
        filepath = series.DicomFiles[0].FilePath;
        if (IsInvalid(series.Thumbnail) || series.Thumbnail === '') {
          icon = GetThumbnailFile(filename);
        } else {
          icon = `data:image/jpeg;base64,${series.Thumbnail}`;
        }
        created = this.formatDate(series.createdAt);
      } else if (IsValid(idSignalEDF)) {
        filename = edf.file_object.original_name;
        type = 'edf';
        filepath = edf.file_object.filepath;
        created = this.formatDate(edf.createdAt);
        icon = GetThumbnailFile(filename);
      } else if (IsValid(idFolder)) {
        filename = folder.name;
        type = 'folder';
        created = this.formatDate(folder.createdAt);
      }
      formatted_files.push({
        id,
        idPatient,
        idSleepCase,
        idEpilepsyCase,
        idFolder,
        filename,
        type,
        filepath,
        created,
        icon,
        folder_content,
      });
    });
    return formatted_files;
  }

  handleOpenFolder = item => {
    console.log('Trying to open explorer of file ', item);

    const { folder_content, idFolder } = item;
    let location = '/explorer';

    if (IsValid(idFolder)) {
      location = `/explorer/folder/${idFolder}`;
    } else {
      if (IsInvalid(folder_content)) {
        this.props.handleAddError('Folder is not set');
        return;
      }
      location = `/explorer/folder/${folder_content.idFolder}`;
    }
    this.props.history.push(location);
  };

  render() {
    const { files } = this.props;
    const formatted_files = this.formatFileList(files);
    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          Linked Files
        </Typography>
        {formatted_files.length === 0 ? (
          <Typography component="p" gutterBottom sx={{ p: 2 }} variant="p">
            No files/folders linked to this case to show
          </Typography>
        ) : null}
        <List sx={{ width: '100%', p: 2 }}>
          {formatted_files.map(item => (
            <ListItemButton
              key={`item-${item.id}`}
              onClick={() => this.handleOpenFolder(item)}>
              <ListItemAvatar>
                {item.type === 'folder' ? (
                  <FolderIcon fontSize="large" style={{ color: 'blue' }} />
                ) : (
                  <Avatar src={item.icon} variant="rounded" />
                )}
              </ListItemAvatar>
              <ListItemText primary={item.filename} secondary={item.created} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  }
}

ClinicalFileCatalogueViewer.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalFileCatalogueViewer;
