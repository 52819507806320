import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';

import {
  ErrorsList,
  GetData,
  IsValidEmail,
  PaperComponent,
  ServerErrors,
} from 'helpers';
import { ButtonLoad } from 'components';

import { QueryUsersByEmail } from 'graphql/Users';
import { projectCreateUsers } from 'redux-store/actions/projectActions';

const useStyles = theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200
  },
  button: {
    textTransform: 'uppercase',
  },
});

class DialogInviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      touched: {},
      errors: {},
    };
  }

  handleClose = () => {
    this.props.handleCloseDialog(false);
  };

  handleCreate = () => {
    const { user, loading } = this.state;
    if (loading) return;
    const validation = IsValidEmail(user);
    if (validation) {
      this.setState({ errors: validation });
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryUsersByEmail(user.email, true)
        .then(res => {
          const data = GetData(res);
          if (data) {
            const { ok, users, errors } = data.allUsers;
            if (ok) {
              if (users && users.length) {
                throw Error('The email is already registered');
              } else {
                this.addInvitation(user);
              }
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          this.setState({
            errors: { email: ServerErrors(error) },
            loading: false,
          });
        });
    })();
  };
  addInvitation = user => {
    const { email } = user;

    const { users } = this.props;

    const new_user = {
      id: -(users.length + 1),
      FullName: 'To Invite',
      email: email,
      createdAt: `${moment().valueOf()}`,
    };

    if (users) {
      const index = users.map(e => e.email).indexOf(email);
      if (index === -1) {
        users.unshift(new_user);
        this.props.projectCreateUsers(users);
      } else {
        this.setState({ errors: { email: ['Email exists'] }, loading: false });
        return;
      }
    } else {
      this.props.projectCreateUsers([new_user]);
    }
    this.props.handleCloseDialog(false);
    this.setState({ loading: false });
  };
  handleChange = event => {
    const { user, touched, errors } = this.state;
    this.setState({
      user: {
        ...user,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...touched,
        [event.target.name]: event.target.value ? true : false,
      },
      errors: {
        ...errors,
        [event.target.name]: null,
      },
    });
  };
  hasError = field => {
    const { errors } = this.state;
    const result = errors[field] ? true : false;
    //
    return result;
  };

  render() {
    const { open, classes } = this.props;
    const { errors, touched, loading } = this.state;
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={this.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Invite User
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              To invite a new user, please fill all the necessary information.
            </DialogContentText>
            <TextField
              autoFocus
              // className={classes.textField}
              error={this.hasError('email')}
              fullWidth
              helperText={
                this.hasError('email') ? ErrorsList(errors, 'email') : null
              }
              id="email"
              InputLabelProps={{
                shrink: true,
              }}
              label="email"
              margin="dense"
              name="email"
              onChange={this.handleChange}
              onKeyPress={event => {
                const { key } = event;
                if (key === 'Enter') {
                  this.handleCreate();
                }
              }}
              placeholder="User email"
              type="email"
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <ButtonLoad
              className={classes.button}
              disable_button={Object.keys(touched).length ? false : true}
              handleClick={this.handleCreate}
              loading={loading}
              name="Invite"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogInviteUser.propTypes = {
  className: PropTypes.string,
  projectCreateUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  users: state.projects.users,
});

export default connect(mapStateToProps, {
  projectCreateUsers,
})(withStyles(DialogInviteUser, useStyles));
