import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'components';
import { Upload, Download } from './components';
import { getParams } from 'helpers';

class DownloadUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  componentDidUpdate() {
    const { tabValue } = this.state;
    const params = getParams(this.props);
    if (!params) return null;
    const { level_1 } = params;
    if (level_1 === 'upload' && tabValue !== 0) {
      this.setState({ tabValue: 0 });
    } else if (level_1 === 'download' && tabValue !== 1) {
      this.setState({ tabValue: 1 });
    }
  }

  handleTabValue = (event, newValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(newValue, 10) });
    if (newValue === 0) {
      this.props.history.push('/upload_download/upload');
    } else {
      this.props.history.push('/upload_download/download');
    }
  };

  render() {
    const { height } = this.props;
    const { tabValue } = this.state;
    let foter = null;
    if (tabValue) {
      foter = (
        <Typography style={{ paddingLeft: 10 }} variant="body1">
          {
            'If a dialog box appears, configure your browser to avoid its display'
          }
        </Typography>
      );
    }
    return (
      <React.Fragment>
        <Tabs
          indicatorColor="primary"
          onChange={this.handleTabValue}
          scrollButtons="auto"
          textColor="primary"
          value={tabValue}
          variant="scrollable">
          <Tab label="Upload" />
          <Tab label="Download" />
        </Tabs>
        <div style={{ width: '100%', height: height - 160, overflow: 'auto' }}>
          <TabPanel index={0} value={tabValue}>
            <Upload
              handleAddError={this.props.handleAddError}
              height={height}
            />
          </TabPanel>
          <TabPanel index={1} value={tabValue}>
            <Download
              handleAddError={this.props.handleAddError}
              height={height}
            />
          </TabPanel>
        </div>
        {foter}
      </React.Fragment>
    );
  }
}

DownloadUpload.propTypes = {
  classes: PropTypes.object,
};

export default DownloadUpload;
