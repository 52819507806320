import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import validate from 'validate.js';

import { ErrorsList, ServerErrors, GetData } from 'helpers';
import { schema, PaperComponent, useStyles } from './Styles';
import { ButtonLoad, SearchCentersInputs } from 'components';
import { MutationCreateTeam } from 'graphql/Teams/utils_team_muations';
import { inputProps } from 'common';

class DialogCreateTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {
        name: '',
      },
      touched: {},
      errors: [],
      selected_center: null,
    };
  }

  componentDidUpdate() {
    const { page_errors, teamsErrors } = this.props;
    const { errors } = this.state;

    if (page_errors && page_errors.length) {
      const { message } = page_errors[0];
      this.setState({
        errors: {
          ...errors,
          name: [message],
        },
      });
      teamsErrors([]);
    }
  }

  handleClose = () => {
    this.setState({ errors: [] });
    this.props.handleCloseDialog(false);
  };
  handleChange = event => {
    const { team, touched, errors } = this.state;
    this.setState({
      team: {
        ...team,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...touched,
        [event.target.name]: event.target.value ? true : false,
      },
      errors: {
        ...errors,
        [event.target.name]: null,
      },
    });
  };
  hasError = field => {
    const { errors } = this.state;
    const result = errors[field] ? true : false;
    //
    return result;
  };
  handleCreateTeam = () => {
    const { loading } = this.state;
    if (loading) return;
    const validation = validate(this.state.team, schema);
    //
    if (validation) {
      this.setState({ errors: validation });
      return;
    }
    const {
      team: { name },
      selected_center,
    } = this.state;
    const { idProject, center } = this.props;
    let idCenter = null;
    if (center) idCenter = center.id;
    else if (selected_center) idCenter = selected_center.id;
    //
    this.setState({ loading: true });
    (async () => {
      MutationCreateTeam(name, idProject, idCenter)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, team } = data.createTeam;
          if (ok) {
            this.setState({ loading: false });
            this.props.handleTeamCreated(team);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('MutationCreateTeam-catch-error', errors);
          this.setState({
            loading: false,
            errors: { name: ServerErrors(errors) },
          });
        });
    })();
  };
  handleAddCenter = selected_center => {
    this.setState({ selected_center });
  };
  render() {
    let { classes, open, center, external_centers } = this.props;
    let { errors, loading, team, selected_center } = this.state;
    //
    let center_component = true;
    let text =
      'To create a new team, please fill all the necessary information.';
    if (center) {
      center_component = false;
    } else {
      center = selected_center;
    }
    if (center) {
      text = `To create a new team for the Center (${center.name}), `;
      text += 'please fill all the necessary information.';
    }
    if (external_centers === undefined || !external_centers) {
      external_centers = [];
    }
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={this.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Create Team
          </DialogTitle>

          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
            <Grid alignItems="center" container spacing={1}>
              {center_component && (
                <Grid item xs={12}>
                  <SearchCentersInputs
                    clean_output={false}
                    external_options={external_centers}
                    handleAddCenter={this.handleAddCenter}
                    handleSetCenter={this.handleAddCenter}
                    no_icon
                    placeholder="Search Center..."
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  className={classes.textField}
                  error={this.hasError('name')}
                  fullWidth
                  helperText={
                    this.hasError('name') ? ErrorsList(errors, 'name') : null
                  }
                  id="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={inputProps}
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={this.handleChange}
                  onKeyPress={event => {
                    const { key } = event;
                    if (key === 'Enter') {
                      this.handleCreateTeam();
                    }
                  }}
                  placeholder="Name for the team"
                  type="name"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <ButtonLoad
              className={classes.button}
              disable_button={team.name === '' && !center}
              handleClick={this.handleCreateTeam}
              loading={loading}
              name="Create"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogCreateTeam.propTypes = {
  className: PropTypes.string,
  external_centers: PropTypes.array.isRequired,
  handleTeamCreated: PropTypes.func.isRequired,
};
DialogCreateTeam.defaultProps = {
  external_centers: [],
};
export default withStyles(DialogCreateTeam, useStyles);
