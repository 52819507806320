import React from 'react';
import PropTypes from 'prop-types';
import { AutoMutilSingle } from 'components/Basics';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';
import { QueryProjectTeams } from 'graphql/Projects';

class ProjectTeamCenters extends React.Component {
  constructor(props) {
    super(props);
    let { mulitple, pc_team } = props;
    if (pc_team) {
      pc_team = {
        idCenterTeam: pc_team.id,
        // center: pc_team.center,
        team: pc_team.team,
        name: pc_team.team.name,
      };
    }
    this.state = {
      idProject: null,
      loading: false,
      pc_teams: pc_team ? [pc_team] : [],
      selected: mulitple ? [] : pc_team ? pc_team : undefined,
    };
  }
  componentDidMount() {
    this.runSearch();
  }
  componentDidUpdate() {
    if (IsInvalid(this.state.idProject)) this.runSearch();
  }
  runSearch = (name, empty) => {
    console.log({ name });
    const { idProject } = this.props;
    if (IsInvalid(idProject)) return;
    const { loading } = this.state;
    if (loading || empty) {
      return;
    }
    let { external, pc_team } = this.props;
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryProjectTeams(idProject, name, 0, 5)
        .then(res => {
          const data = GetData(res);
          let { ok, errors, total, pc_teams } = data.projectTeams;
          if (ok) {
            if (external.length) {
              external.forEach(element => {
                pc_teams.unshift(element);
              });
            }
            pc_teams = pc_teams.map(x => ({
              ...x,
              idCenterTeam: x.center_team.id,
              center: x.center_team.center,
              team: x.center_team.team,
              name: x.center_team.team.name,
            }));
            if (pc_team) {
              const i = pc_teams.map(x => x.center_team.id).indexOf(pc_team.id);
              if (i !== -1) {
                pc_team = pc_teams[i];
              }
            }
            this.setState({
              total,
              pc_teams,
              pc_team,
              loading: false,
              empty: !pc_teams.length,
              idProject,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChangedd = selected => {
    this.setState({ selected });
    this.props.onChanged(selected);
  };
  render() {
    const { pc_team, pc_teams } = this.state;
    console.log({ pc_teams, pc_team });
    if (pc_team) {
      const id = pc_teams.map(x => x.id).indexOf(pc_team.id);
      if (id === -1) pc_teams.push(pc_team);
    }
    return (
      <AutoMutilSingle
        disabled={this.props.disabled}
        label="Project Teams"
        loading={this.state.loading}
        multiple={this.props.multiple}
        onSelectionChanged={this.handleSelectedChangedd}
        option={this.state.selected}
        options={pc_teams}
        size={this.props.size}
      />
    );
  }
}

ProjectTeamCenters.propTypes = {
  external: PropTypes.array,
};
ProjectTeamCenters.defaultProps = {
  external: [],
  multiple: false,
  size: '',
  onChanged: () => '',
  pc_team: undefined,
  disabled: false,
};

export default ProjectTeamCenters;
