import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { GroupBox } from 'components';
class GroupPatientLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { gender: false, birthday: false };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const { gender, birthday } = this.state;
    return (
      <GroupBox title_box="Patient Level">
        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gender}
                  name="gender"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Gender"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={birthday}
                  name="birthday"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Birthday"
            />
          </FormGroup>
          <FormHelperText>Be careful</FormHelperText>
        </FormControl>
      </GroupBox>
    );
  }
}

GroupPatientLevel.propTypes = {
  classes: PropTypes.object,
};

export default GroupPatientLevel;
