import { IsInvalid } from 'helpers';

export const labels = {
  delete: {
    confirmation: {
      title: 'Delete Selected',
      text1: 'You are about to delete the',
      text2: 'and all its referenced data, this action cannot be undone.',
    },
  },
  studies: {
    epilepsy: 'epilepsy',
    ms: 'ms',
    sleep: 'sleep',
    stroke: 'stroke',
  },
  errors: {
    nothingToSave: 'Nothing to save',
    fileNotSelected: 'Select file first',
  },
  tables: {
    stroke: {
      ssrCases: 'ssr_cases',
      SsrEncounter: 'ssr_encounter',
      SsrFollowup: 'ssr_followup',
      SsrThromboProcedure: 'ssr_thrombo_procedure',
      SsrTreatment: 'ssr_treatment',
      SsrPatient: 'ssr_patient',
    },
    sleep: {
      sleepCase: { name: 'sleep_case', title: '' },
      sleepPolysomnography: {
        name: 'sleep_polysomnography',
        title: 'Sleep Polysomnography',
      },
      sleepRepetition: { name: 'sleep_repetition', title: 'Sleep Repetition' },
      sleepTestType: { name: 'sleep_test_type', title: 'Sleep Test Type' },
      sleepVigilance: { name: 'sleep_vigilance', title: 'Sleep Vigilance' },
    },
    epilepsy: {
      epilepsyCase: { name: 'epilepsy_case', title: 'Epilepsy Case' },
      epilepsyOutcome: { name: 'epilepsy_outcome', title: 'Epilepsy Outcome' },
      epilepsyResearchStudy: {
        name: 'epilepsy_research_study',
        title: 'Epilepsy Research Study',
      },
      epilepsyResection: {
        name: 'epilepsy_resection',
        title: 'Epilepsy Resection',
      },
    },
  },
};

export const titleCase = word => {
  if (IsInvalid(word)) return word;
  return word.charAt(0).toUpperCase() + word.slice(1);
};
