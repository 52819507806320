import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import RefreshIcon from '@mui/icons-material/Refresh';
import { green } from '@mui/material/colors';
import clsx from 'clsx';

const useStyles = theme => ({
  wrapper: {
    margin: theme.spacing(1),
    marginLeft: 0,
    position: 'relative',
  },
  progress: {
    color: green[800],
    position: 'absolute',
    top: 4,
    left: 3.5,
    zIndex: 1,
  },
  progressSmall: {
    color: green[800],
    position: 'absolute',
    top: 1.5,
    left: 2.5,
    zIndex: 1,
  },
  progressSmall_wrapper: {
    top: 0,
    left: 0,
  },
});

class ButtonReload extends Component {
  render() {
    const {
      classes,
      className,
      small,
      wrapper,
      ComponentIcon,
      color,
    } = this.props;
    return (
      <div className={classes.wrapper} style={wrapper}>
        <Tooltip title={this.props.tooltip}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-label={this.props.title}
              className={className}
              component="span"
              disabled={this.props.loading || this.props.disabled}
              onClick={this.props.handleReload}
              size="large">
              <ComponentIcon
                fontSize={small ? 'small' : 'large'}
                style={{ color }}
              />
            </IconButton>
          </span>
        </Tooltip>
        {this.props.loading && (
          <CircularProgress
            className={clsx({
              [classes.progress]: !small,
              [classes.progressSmall]: small,
              [classes.progressSmall_wrapper]: wrapper,
            })}
            size={small ? 40 : 50}
          />
        )}
      </div>
    );
  }
}

ButtonReload.propTypes = {
  classes: PropTypes.object.isRequired,
  reloadDetails: PropTypes.func,
};
ButtonReload.defaultProps = {
  tooltip: 'Reload',
  title: 'Title',
  loading: false,
  disabled: false,
  small: false,
  wrapper: null,
  ComponentIcon: RefreshIcon,
  color: 'gray',
};
export default withStyles(ButtonReload, useStyles);
