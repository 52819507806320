/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DivGrow } from 'components';
import { IsInvalid } from 'helpers';
import { HTTP_ADDRESS_DOWNLOAD } from 'config';

class DialogGetDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied_link: false,
      copied_code: false,
    };
  }
  handleClose = () => {
    this.setState({ copied_link: false, copied_code: false });
    this.props.handleClose();
  };
  render() {
    const { open, download_link } = this.props;
    if (IsInvalid(download_link)) return null;
    const { copied_link, copied_code } = this.state;
    let text_link = `${HTTP_ADDRESS_DOWNLOAD}/${download_link}`;
    const command = `wget --content-disposition ${text_link}`;
    // let page = getParamsUrl(this.props);
    // if (page) {
    //   let real_url = window.location.href;
    //   real_url = real_url.replace(page, '');
    //   if (HTTP_ADDRESS_WEB !== real_url) {
    //     text_link = `${real_url}/download/${download_link}`;
    //   }
    // }
    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={this.handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">Download Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By default download links are <strong>public</strong>,{' '}
            <strong>anyone</strong> with the download link can download the
            folder or content.
          </DialogContentText>
          <Grid
            alignItems="stretch"
            container
            direction="column"
            justifyContent="flex-start"
            pt={2}>
            <CardHeader
              style={{ padding: 0 }}
              subheader={
                <Link
                  href={text_link}
                  onClick={this.handleClose}
                  rel="noopener"
                  target="_blank"
                  variant="body2">
                  {text_link}
                </Link>
              }
              title={
                <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                  Download Link:
                </Typography>
              }
            />
            <CardHeader
              style={{ padding: 0, paddingTop: 10 }}
              subheader={<Typography variant="body2">{command}</Typography>}
              title={
                <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                  Download Command:
                </Typography>
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          {copied_link || copied_code ? (
            <span style={{ color: 'green', fontWeight: 1000, marginLeft: 20 }}>
              {copied_link
                ? 'Link Copied to Clipboard...'
                : copied_code
                ? 'Command Copied to Clipboard...'
                : ''}
            </span>
          ) : null}
          <DivGrow />
          <CopyToClipboard
            onCopy={() =>
              this.setState({ copied_link: false, copied_code: true })
            }
            text={command}>
            <Button color="primary">Copy Command</Button>
          </CopyToClipboard>
          <CopyToClipboard
            onCopy={() =>
              this.setState({ copied_link: true, copied_code: false })
            }
            text={text_link}>
            <Button color="primary">Copy Link</Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogGetDownloadLink.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(DialogGetDownloadLink);
