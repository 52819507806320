import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
// import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { ButtonReload } from 'components';
import { AutoCompleteCommunication, useStyles } from './components';

class CommuncationToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleLoading = loading => {
    this.setState({ loading });
  };
  handleAddReceiver = receiver => {
    this.props.handleAddReceiver(receiver);
  };
  render() {
    const { classes, handleLoadReceiver, receiving, creating } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.upper_bar}>
        <Grid container direction="row" justifyContent="space-between">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            {creating ? (
              'Creating Channel...'
            ) : (
              <AutoCompleteCommunication
                creating={creating}
                handleAddReceiver={this.handleAddReceiver}
                handleLoading={this.handleLoading}
              />
            )}
          </div>
          <ButtonReload
            handleReload={handleLoadReceiver}
            loading={loading || creating || receiving}
            small
            tooltip="Reload Receivers"
            wrapper={{ margin: 2 }}
          />
        </Grid>
      </div>
    );
  }
}

CommuncationToolbar.propTypes = {
  classes: PropTypes.object,
};
CommuncationToolbar.defaultProps = {
  handleAddReceiver: () => '',
  handleLoadReceiver: () => '',
  receiving: false,
};

export default withStyles(CommuncationToolbar, useStyles);
