import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { withRouter } from 'react-router-dom';

import {
  ProjectSettingAnonymization,
  ProjectSettingMapping,
  ProjectSettingStorage,
  ProjectSettingVariableTypes,
} from './components';
import { TabPanel } from 'components';
import { withStyles } from 'tss-react/mui';
import { getParams } from 'helpers';
const useStyle = {
  root: {
    textTransform: 'none',
    padding: 0,
    paddingRight: 5,
    margin: 0,
    maxHeight: 50,
    minWidth: 'auto', // Ensure tabs don't stretch horizontally
    textAlign: 'left', // Align text to the left
    alignItems: 'flex-start',
  },
};
class TabProjectSettingMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        { name: 'General', TabComponent: ProjectSettingStorage },
        { name: 'Variables', TabComponent: ProjectSettingVariableTypes },
        { name: 'Mapping', TabComponent: ProjectSettingMapping },
        { name: 'Anonymization', TabComponent: ProjectSettingAnonymization },
      ],
    };
  }
  componentDidMount() {
    const { tabs } = this.state;
    const { level_3 } = getParams(this.props);
    let tabValue = 0;
    if (level_3 && level_3 !== '') {
      tabValue = tabs.map(x => x.name.toLowerCase()).indexOf(level_3);
      if (tabValue < 0) tabValue = 0;
      this.setState({ tabValue });
    }
  }
  handleChange = (event, tabValue) => {
    const { idProject } = this.props;
    const { tabs } = this.state;
    this.setState({ tabValue });
    let tab = 'general';
    if (tabValue >= 0 && tabValue < tabs.length) {
      tab = tabs[tabValue].name.toLowerCase();
    }
    this.props.history.push(
      `/project_management/${idProject}/settings/setting/${tab}`
    );
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, project_setting, loading, height } = this.props;
    const { tabValue } = this.state;
    let style = null;
    if (height) {
      style = { height, maxHeight: height, overflowY: 'auto' };
    }
    // let style_tab = {
    //   textTransform: 'none',
    //   padding: 0,
    //   margin: 0,
    //   maxHeight: 50,
    //   textAlign: 'left',
    // };
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          minHeight: 230,
          width: '100%',
        }}>
        <Tabs
          aria-label="Vertical tabs example"
          onChange={this.handleChange}
          orientation="vertical"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: 95 }}
          value={tabValue}
          variant="scrollable">
          {this.state.tabs.map((tab, index) => {
            const { name } = tab;
            return (
              <Tab
                className={classes.root}
                key={`tab-pro-setting-${index}`}
                label={name}
              />
            );
          })}
        </Tabs>
        <div
          style={{
            width: '100%',
            height: height - 10,
            margin: 0,
            paddingRight: 20,
          }}>
          {this.state.tabs.map((tab, index) => {
            const { TabComponent } = tab;
            return (
              <TabPanel
                index={index}
                key={`tab-pro-setting-component-${index}`}
                value={tabValue}>
                <Grid item p={1} xs={12}>
                  <TabComponent
                    classes={classes}
                    handleAddError={this.props.handleAddError}
                    handleUpdateProjectSetting={
                      this.props.handleUpdateProjectSetting
                    }
                    height={height}
                    idProject={this.props.idProject}
                    isActive={index === tabValue}
                    loading={loading}
                    project_setting={project_setting}
                    style={style}
                  />
                </Grid>
              </TabPanel>
            );
          })}
        </div>
      </Box>
    );
  }
}

TabProjectSettingMain.propTypes = {
  classes: PropTypes.object,
};
TabProjectSettingMain.defaultProps = {
  handleUpdateProjectSetting: () => '',
  handleAddError: () => '',
};
export default withStyles(withRouter(TabProjectSettingMain), useStyle);
