import { FormatBytes, GetThumbnailFile } from 'helpers';

export const getFileSeries = (object, row) => {
  const { Series, File } = object;
  if (typeof Series !== 'undefined' && Series) {
    row.name = Series.SeriesDescription;
    row.Thumbnail = Series.Thumbnail;
    row.kind = 'dcm';
    row.size = FormatBytes(Series.Size);
  } else if (typeof File !== 'undefined' && File) {
    row.name = File.original_name;
    row.kind = '';
    row.Thumbnail = GetThumbnailFile(File.original_name);
    row.size = FormatBytes(File.Size);
    //
  }
  row.permission = object.Rights;
  return row;
};
export const getRow = value => {
  let row = {
    id: -1,
    Thumbnail: null,
    kind: '',
    name: '',
    size: '0 Bytes',
    data_modified: '',
    permission: [],
  };
  const { id, Folder, FolderContent, Object: MultiObject, updatedAt } = value;
  row.id = id;
  row.data_modified = updatedAt;
  if (typeof Folder !== 'undefined' && Folder) {
    row.name = Folder.name;
    row.kind = '--';
    row.permission = Folder.Rights;
    row.size = FormatBytes(Folder.Size);
  } else if (typeof FolderContent !== 'undefined' && FolderContent) {
    row = getFileSeries(FolderContent, row);
    row.permission = FolderContent.Rights;
  } else if (typeof MultiObject !== 'undefined' && MultiObject) {
    row = getFileSeries(MultiObject, row);
    row.permission = [MultiObject.Rights];
  }
  return row;
};
