import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_DOCKER_ACCOUNTS = gql`
  query($idAccountType: LongLong!, $page: Int, $limit: Int) {
    accountDockers(idAccountType: $idAccountType, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      account_dockers {
        id
        idAccountType
        idDockerImage
        account_type {
          id
          code
          description
        }
        docker_image {
          id
          idUser
          idImage

          name
          operation
          filepath

          user {
            id
            FullName
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryAccountDockers = (idAccountType, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_ACCOUNTS),
    variables: {
      idAccountType,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_DOCKER_ACCOUNTS = gql`
  mutation($inputs: [InputAccountDocker!]!) {
    addAccountDockers(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      account_dockers {
        id
        idAccountType
        idDockerImage
        account_type {
          id
          code
          description
        }
        docker_image {
          id
          idUser
          idImage

          name
          operation
          filepath

          user {
            id
            FullName
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationAddAccountDockers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_DOCKER_ACCOUNTS),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_DOCKER_ACCOUNTS = gql`
  mutation($inputs: [InputAccountDocker!]!) {
    removeAccountDockers(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveAccountDockers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_DOCKER_ACCOUNTS),
    variables: {
      inputs,
    },
  });
};
