export const useStyles = () => ({
  splitter: {
    '@global': {
      '.gutter': {
        backgroundColor: '#eee',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
      },
      '.gutter.gutter-horizontal': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==")',
        cursor: 'col-resize',
      },
      '.gutter.gutter-vertical': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=")',
        cursor: 'row-resize',
      },
    },
  },
});
