import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import {
  AvatarsList,
  AvatarUpload,
  ChangeAvatarBottom,
  useStyles,
} from './components';

class ChangeAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexSelected: 0,
      upload: false,
      avatar: null,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = event => {
    const { alt } = event.target;
    if (alt) {
      if (alt.startsWith('avatar')) {
        const parts = alt.split('_');
        if (parts.length === 2) {
          const index = parseInt(parts[1], 10);
          this.setState({ indexSelected: index });
        }
      } else {
        /** empty */
      }
    }
  };
  handleUploadNew = avatar => {
    this.setState({ avatar, indexSelected: -1, upload: false });
    this.props.handleSave(avatar);
  };
  handleSave = () => {
    const { indexSelected, avatar } = this.state;
    if (indexSelected >= 0) {
      this.props.handleSave(`/images/avatars/avatar_${indexSelected}.png`);
    } else if (avatar) {
      this.props.handleSave(avatar);
    }
  };

  render() {
    const { classes, open, handleCancel } = this.props;
    const { indexSelected, upload } = this.state;
    return (
      <div key="change-avatar-channel-modal">
        <Dialog
          aria-labelledby="form-dialog-title"
          key="dialog-modal"
          onClose={handleCancel}
          open={open}>
          <DialogTitle id="form-dialog-title">Change Avatar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change the avatar, please click on the image to select it.
            </DialogContentText>
            {upload ? (
              <div className={classes.root}>
                <AvatarUpload
                  handleClose={() => this.setState({ upload: false })}
                  handleUploadNew={this.handleUploadNew}
                />
              </div>
            ) : (
              <AvatarsList
                classes={classes}
                handleClick={this.handleClick}
                indexSelected={indexSelected}
              />
            )}
          </DialogContent>
          <DialogActions>
            {upload ? null : (
              <ChangeAvatarBottom
                handleCancel={this.handleCancel}
                handleSave={this.handleSave}
                handleUploadNew={() => this.setState({ upload: true })}
              />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(ChangeAvatar, useStyles);
