import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { EpilepsyToolbar } from './components';
import { TableGrid } from 'components';
import { getEmptyRow, getHeader } from './components/Styles';
import {
  rowsPerPageBig,
  IsInvalid,
  IsValid,
  ServerErrorsString,
  GetData,
} from 'helpers';
import { GetResponse, QueryEpilepsy } from './components/utils_epilepsy';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/* const table_map = [
  'patient',
  'cases',
  'research_study',
  'resection',
  'outcome',
]; */

const table_map = [
  'patient',
  'epilepsy_case',
  'epilepsy_research_study',
  'epilepsy_resection',
  'epilepsy_outcome',
];

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Reduce padding
          padding: '0px', // Adjust the padding as needed
        },
      },
    },
  },
});

class ClinicEpilepsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: 0,
      page: 0,
      limit: 25,
      total: 0,
      selected: [],
      rows: [],
      row_clicked: null,
      new_rows: {},
      table_rows_edited: {},
      loading: false,
      nowrap: false,
      anonymize: 1,
    };
  }
  handleAddNewData = () => {
    const { table } = this.state;
    const new_row = getEmptyRow(table);
    console.log({ new_row });
    const { new_rows } = this.state;
    let rows = new_rows[table];
    if (IsInvalid(rows)) rows = [];
    new_row['id'] = (rows.length + 1) * -1;
    rows.unshift(new_row);
    this.setState({ new_rows: { ...new_rows, [table]: rows } });
  };
  handleSetEditRows = rows_edited => {
    console.log({ rows_edited });
    let { table, table_rows_edited } = this.state;
    this.setState({
      table_rows_edited: {
        ...table_rows_edited,
        [table]: rows_edited,
      },
    });
  };
  handleSetNewRows = rows_new => {
    console.log({ rows_new });
    let { table, new_rows } = this.state;
    this.setState({
      new_rows: {
        ...new_rows,
        [table]: rows_new,
      },
    });
  };
  handleChangeCheck = event => {
    this.setState({ nowrap: event.target.checked });
  };
  handleChange = (param_table, param_page, param_limit, anonymize = 1) => {
    const { loading, page, limit, table } = this.state;
    if (loading) return;
    let t = null;
    let p = null;
    let l = null;
    if (IsValid(param_table)) {
      t = param_table;
    } else {
      t = table_map[table];
    }
    if (IsValid(param_page)) {
      p = param_page;
    } else {
      p = page;
    }
    if (IsValid(param_limit)) {
      l = param_limit;
    } else {
      l = limit;
    }
    if (IsInvalid(t)) return;
    this.props.history.push(`/clinical_data/epilepsy/${t}`);

    this.setState({ loading: true, table: table_map.indexOf(t) });
    (async () => {
      QueryEpilepsy(t, name, p, l, anonymize)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, total, rows } = GetResponse(t, data);
          if (ok) {
            this.setState({ rows, total, page: p, limit: l, loading: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  handleToggleAnonymize = () => {
    const { table, page, limit, anonymize } = this.state;
    const temp = anonymize === 0 ? 1 : 0;
    this.setState({ anonymize: temp });
    this.handleChange(table_map[table], page, limit, temp);
  };

  handleOnCellClicked = ({ row }) => {
    this.setState({ row_clicked: row });
  };
  render() {
    if (!this.props.isActive) return null;
    let { new_rows, table, rows, table_rows_edited, total } = this.state;
    let add_rows = [];
    let rows_edited = [];
    if (new_rows[table]) {
      add_rows = new_rows[table];
    }
    if (table_rows_edited[table]) {
      rows_edited = table_rows_edited[table];
    }
    return (
      <ThemeProvider theme={theme}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end">
          <Grid item xs={12}>
            <Paper
              style={{
                margin: 2,
                marginTop: 3,
                paddingTop: 3,
                paddingLeft: 2,
              }}>
              <EpilepsyToolbar
                add_rows={add_rows}
                anonymize={this.state.anonymize}
                handleAddError={this.props.handleAddError}
                handleAddNewData={this.handleAddNewData}
                handleChangeCheck={this.handleChangeCheck}
                handleReload={(table, page, limit) =>
                  this.handleChange(table, page, limit)
                }
                handleSetEditRows={this.handleSetEditRows}
                handleSetNewRows={this.handleSetNewRows}
                handleToggleAnonymize={this.handleToggleAnonymize}
                history={this.props.history}
                index_table={this.state.table}
                isDesktop={this.props.isDesktop}
                limit={this.state.limit}
                loading={this.state.loading}
                match={this.props.match}
                new_rows={new_rows}
                nowrap={this.state.nowrap}
                page={this.state.page}
                row_clicked={this.state.row_clicked}
                rows={this.state.rows}
                rows_edited={rows_edited}
                selected={this.state.selected}
                setState={state => this.setState(state)}
                total={this.state.total}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              cell_selection
              checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={rows_edited}
              external_selected={this.state.selected}
              grid
              headers={getHeader(this.state.table)}
              height={this.props.height - 220}
              loading={this.state.loading}
              nowrap={this.state.nowrap}
              onCellClicked={rowHighlighted =>
                this.handleOnCellClicked(rowHighlighted)
              }
              onPageChange={page =>
                this.handleChange(null, page, this.state.limit)
              }
              onRowEditedChanged={this.handleSetEditRows}
              onRowsPerPageChange={limit => this.handleChange(null, 0, limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              paginationMode="server"
              row_height={30}
              rowCount={total}
              rows={add_rows.concat(rows)}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

ClinicEpilepsy.propTypes = {
  classes: PropTypes.object,
};

export default ClinicEpilepsy;
