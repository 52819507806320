import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  row: {
    display: 'flex',
    margin: theme.spacing(2),
    wordWrap: 'break-word',
  },
});

class MainTableRowLogErrorExand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, row } = this.props;

    return <div className={classes.row}>{row.error}</div>;
  }
}

MainTableRowLogErrorExand.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(MainTableRowLogErrorExand, useStyles);
