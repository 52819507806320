import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { IsInvalid } from 'helpers';

const useStyles = theme => ({
  root: {
    minWidth: 250,
    margin: theme.spacing(1),
  },
  root_short: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
});

class DetailsAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
    const { holder } = props;
    if (holder) {
      holder.reset = () => {
        this.setState({ inputValue: '' });
      };
    }
  }

  handleTextCountryChange = (event, inputValue) => {
    this.setState({ inputValue });
  };

  render() {
    const {
      minWidth,
      classes,
      details,
      detail,
      open,
      loading,
      style,
      hasError,
      handleSelectedChanged,
      required,
      label,
      title,
      setOpen,
      handleTyping,
    } = this.props;
    let { inputValue } = this.state;
    let error = hasError(label);
    if (!error) {
      error = '';
    }
    let placeholder = `Select series ${title}`;
    if (this.props.placeholder) {
      placeholder = this.props.placeholder;
    }
    // console.log({ detail });
    return (
      <Autocomplete
        className={clsx({
          [classes.root]: !minWidth,
          [classes.root_short]: minWidth,
        })}
        disabled={this.props.disabled}
        getOptionLabel={option => {
          if (option) return option.name;
          return '';
        }}
        id={`${label}-region-selection`}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          if (IsInvalid(option)) return false;
          if (IsInvalid(value)) return false;
          if (value.name === undefined) return false;
          if (option) return option.name === value.name;
          if (value.name === '') return false;
          return false;
        }}
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          let name = '';
          console.log({ newValue });
          if (newValue) {
            if (this.props.multiple) {
              if (newValue.length) name = newValue[0].name;
            } else name = newValue.name;
          }
          console.log({ newValue });
          this.setState({ inputValue: name });
          handleSelectedChanged(label, newValue);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={this.handleTextCountryChange}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        options={details}
        renderInput={params => (
          <TextField
            {...params}
            error={error !== '' ? true : false}
            helperText={error !== '' ? error : null}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={title}
            margin="dense"
            onChange={handleTyping}
            placeholder={placeholder}
            required={required}
            variant={this.props.variant}
          />
        )}
        size={this.props.small ? 'small' : 'medium'}
        style={style}
        value={detail}
      />
    );
  }
}

DetailsAutocomplete.propTypes = {
  classes: PropTypes.object,
  detail: PropTypes.object,
  details: PropTypes.array,
  handleSelectedChanged: PropTypes.func,
  handleTyping: PropTypes.func,
  hasError: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  minWidth: PropTypes.bool.isRequired,
  multiple: PropTypes.bool,
  open: PropTypes.bool,
  required: PropTypes.bool,
  setOpen: PropTypes.func,
  small: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
};
DetailsAutocomplete.defaultProps = {
  details: [],
  detail: { name: '' },
  open: false,
  loading: false,
  style: {},

  hasError: () => {
    return null;
  },
  required: false,
  label: '',
  title: 'Undefined',
  minWidth: false,
  variant: 'outlined',
  disabled: false,
  small: false,
  multiple: false,
};

export default withStyles(DetailsAutocomplete, useStyles);
