import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styleToolbar } from 'common';
import { ButtonReloadDelete, BadgeIcon, ToggleButtonUpload } from 'components';
import SaveIcon from '@mui/icons-material/Save';
import { GetData, ServerErrorsString } from 'helpers';
import {
  MutationUploadRulesCSV,
  MutationAddAnonymizationTags,
} from 'graphql/Anonymization';
class ToolBarAnonyRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      name: null,
    };
  }
  handleSelectFile = event => {
    const { uploading } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadRulesCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.uploadAnonymizationRules;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handelAddRules(rules);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAction = (event, action) => {
    console.log({ action });
    if (action === 'save') {
      this.mutationSaveChanges();
    }
  };
  mutationSaveChanges = () => {
    const { rows_edited } = this.props;
    if (rows_edited === undefined || !rows_edited || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      return {
        tag: x.tag,
        tag_rule: x.tag_rule ? x.tag_rule : null,
        tag_value: x.tag_value ? x.tag_value : null,
      };
    });
    this.setState({ saving: true });
    (async () => {
      MutationAddAnonymizationTags(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAnonymizationTags;
          if (ok) {
            this.setState({ saving: false });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, rows_edited, disabled } = this.props;
    const count_save = rows_edited.length;
    // console.log({ rows_edited });
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.handleAction}
              size="small"
              value={this.props.view}>
              <ToggleButton
                aria-label="left aligned"
                sx={{ paddingTop: count_save ? 0 : '7px' }}
                value="save">
                <BadgeIcon
                  color="blue"
                  count={count_save}
                  disabled={!count_save}
                  Icon={SaveIcon}
                  title="Save"
                />
              </ToggleButton>
              {/* <ToggleButton
                aria-label="centered"
                sx={{ paddingTop: count_save_all ? 0 : '7px' }}
                value="run">
                <BadgeIcon Icon={SaveAltIcon} />
              </ToggleButton> */}
              <ToggleButtonUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReloadTable}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              show_delete
              tooltip="Reload Jobs"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarAnonyRules.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};
ToolBarAnonyRules.defaultProps = {
  handelAddRules: () => '',
  handleAddError: () => '',
};
export default withStyles(ToolBarAnonyRules, styleToolbar);
