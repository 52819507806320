/* eslint-disable quotes */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryRequestAccess } from 'graphql/Units';
import { ButtonLoading } from 'components';

// const protocols = ['HTTP', 'HTTPS'];
class DialogAddUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      protocol: 'HTTP',
      api: '',
      port: 4000,
      loading: false,
    };
  }

  handleText = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  //   handleProtocol = protocol => {
  //     this.setState({ protocol });
  //   };
  requestAccess = () => {
    const { loading } = this.props;
    if (loading) return;
    this.setState({ loading: true });
    const { api } = this.state;
    (async () => {
      QueryRequestAccess(api)
        .then(res => {
          const data = GetData(res);
          const { ok, unit, errors } = data.RequestAccess;
          if (ok) {
            this.setState({ loading: false, api: '' });
            this.props.addUnit(unit, null);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error, json: error.toJSON() });
          this.setState({ loading: false });
          let errors = ServerErrorsString(error);
          if (
            errors &&
            errors.indexOf(
              'Error: Client never received a response, or request never left'
            ) !== -1
          ) {
            errors =
              "There is an internet problem or your API address doesn't exist.";
            errors += `\n\nAPI address: ${api}`;
          }
          this.props.addUnit(null, errors);
        });
    })();
  };

  render() {
    const { open, handleClose } = this.props;
    const { api, loading } = this.state;
    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">Add Unit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a unit, please enter the IP address and press Request.
          </DialogContentText>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
            style={{ marginTop: 15, marginBottom: 10 }}>
            <TextField
              autoFocus
              fullWidth
              label="API Address"
              margin="dense"
              name="api"
              onChange={this.handleText}
              placeholder="Write API address (e.g. http://localhost/api)"
              style={{ marginLeft: 10, marginRight: 10 }}
              value={api}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoading
            handleClick={this.requestAccess}
            loading={loading}
            name="Request Access"
            transform={false}
            variant="standard"
          />
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

DialogAddUnit.propTypes = {
  classes: PropTypes.object,
};

export default DialogAddUnit;
