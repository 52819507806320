/* eslint-disable indent */
import { green, red } from '@mui/material/colors';
import { lighten } from '@mui/material/styles';
import { SeriesAtStudies } from 'graphql/Study';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
export { default as DialogWarningDeleteSeries } from './DialogWarningDeleteSeries';
export const useToolbarStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.75),
        }
      : {
          color: theme.palette.text.primary,
          //   backgroundColor: theme.palette.secondary.dark,
          backgroundColor: lighten(theme.palette.secondary.dark, 0.5),
        },
  title: {
    flex: '1 1 100%',
  },
  button_red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  button_green: {
    color: theme.palette.getContrastText(green[800]),
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
});

export const handleCopySeries = (THIS, edit) => {
  const { loading, editing } = THIS.state;
  if (loading || editing) return;
  const { selectedStudies, selectedSeries } = THIS.props;
  if (selectedStudies.length === 0) {
    if (selectedSeries.length) {
      THIS.copySeriesToExplorer(selectedSeries);
    } else {
      THIS.props.handleAddError('You need to select series first');
    }
    return;
  }
  const { SeriesQuery, setting } = THIS.getQuerySeriesSetting();
  const ids = selectedStudies.map(x => x.idStudy);
  if (edit) THIS.setState({ editing: true });
  else THIS.setState({ loading: true });
  (async () => {
    SeriesAtStudies(ids, null, null, SeriesQuery, setting)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, ListSeries } = data.SeriesAtStudies;
        if (ok) {
          for (let i = 0; i < ListSeries.length; i++) {
            const series = ListSeries[i];
            const index = selectedSeries
              .map(x => x.idSeries)
              .indexOf(series.idSeries);
            if (index === -1) {
              selectedSeries.push(series);
            }
          }
          THIS.copySeriesToExplorer(selectedSeries);
          if (edit) {
            THIS.handleMultiEditSeries();
            THIS.setState({ editing: false });
          }
        } else {
          throw errors;
        }
      })
      .catch(error => {
        if (edit) THIS.setState({ editing: false });
        else THIS.setState({ loading: false });
        THIS.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
