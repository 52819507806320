import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, List } from '@mui/material';
import { v1 as uuid } from 'uuid';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import {
  UploadDropArea,
  UploadProgress,
  UploadItem,
  UploadToolBar,
} from './components';
import { GetData, ServerErrors } from 'helpers';
import { useStyles } from './components/useStyles';
import { IsFile } from './components/UploadItem/components';
import { setSkipOnline } from 'redux-store/actions/page_globals';
import { MutationUploadFile } from 'common';
const GetNameFile = x => {
  return `${x.name}-${x.path}-${x.size}-${x.type}`;
};
class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 12,
      loading: false,
      files: [],
      uploaded_contents: [],
      progress_all: 0,
    };
    this.holder = {
      deleteFile: null,
      clearAll: null,
      getSettings: null,
    };
  }

  getFileIndex = file => {
    const i = this.state.files.map(x => x.name).indexOf(file.name);
    if (i !== -1 && file.size === this.state.files[i].size) {
      return i;
    }
    return -1;
  };
  getUploadIndex = item => {
    if (IsFile(item)) return -1;
    const { uploaded_contents } = this.state;
    const { filename: name, filesize: size } = item;
    for (let i = 0; i < uploaded_contents.length; i++) {
      const { filename, filesize } = uploaded_contents[i];
      if (name === filename && size === filesize) {
        return i;
      }
    }
    return -1;
  };
  handleDelete = file => {
    if (!file) {
      return;
    }
    let { files, uploaded_contents } = this.state;
    let i = this.getFileIndex(file);
    if (i !== -1) {
      files.splice(i, 1);
      this.setState({ files });
    }
    i = this.getUploadIndex(file);
    if (i !== -1) {
      uploaded_contents.splice(i, 1);
      this.setState({ uploaded_contents });
    }
    this.holder.deleteFile(file);
  };

  handleUplodedItem = (item, index) => {
    if (typeof item === 'undefined' || !item) {
      return;
    }
    const { ok } = item;

    let { files, uploaded_contents } = this.state;
    if (ok) {
      this.holder.deleteFile(files[index]);
    }
    uploaded_contents.push(item);
    files.splice(index, 1);
    this.setState({ uploaded_contents, files, progress_all: 0 });
  };
  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const progress_all = Math.floor((loaded * 100) / total);
    const { files } = this.state;
    files[0].loaded = loaded;
    files[0].loading = true;
    this.setState({ progress_all, files });
  };
  handleUpload = () => {
    const { files } = this.state;
    if (!files.length) {
      this.setState({
        loading: false,
        progress: 0,
      });
      this.props.setSkipOnline(false);
      return;
    }
    this.setState({ loading: true });
    this.props.setSkipOnline(true);
    const file = files[0];
    let settings = null;
    if (this.holder.getSettings) {
      settings = this.holder.getSettings();
    }
    (async () => {
      MutationUploadFile(file, this.handleProgress, null, settings)
        .then(res => {
          const data = GetData(res);
          const {
            uploadFile: { ok, errors },
          } = data;
          let uploaded_content = null;
          if (ok) {
            const { contents, warnings } = data.uploadFile;
            uploaded_content = {
              ok: true,
              filename: file.name,
              filesize: file.size,
              loaded: file.size,
              loading: false,
              content: contents && contents.length ? contents[0] : null,
              warnings: warnings && warnings.length ? warnings : [],
            };
          } else {
            throw errors;
          }
          this.handleUplodedItem(uploaded_content, 0);
          this.handleUpload();
        })
        .catch(error => {
          const new_information = {
            ok: false,
            filename: file.name,
            filesize: file.size,
            file,
            loading: false,
            errors: ServerErrors(error),
          };
          this.handleUplodedItem(new_information, 0);
          this.handleUpload();
        });
    })();
  };
  handleDeleteAll = () => {
    this.holder.clearAll();
    this.setState({ files: [], uploaded_contents: [], progress_all: 0 });
  };
  handleInformation = index => {
    const { uploaded_contents } = this.state;
    if (index >= 0 && index < uploaded_contents.length) {
      uploaded_contents[index] = {
        ...uploaded_contents[index],
        finished_error: true,
      };
      this.setState({ uploaded_contents });
    }
  };
  handleAddFiles = new_files => {
    const { files } = this.state;
    for (let i = 0; i < new_files.length; i++) {
      const index = files
        .map(x => GetNameFile(x))
        .indexOf(GetNameFile(new_files[i]));
      if (index === -1) {
        files.unshift(new_files[i]);
      }
    }
    this.setState({ files });
  };
  render() {
    const { uploaded_contents, files } = this.state;
    const { classes, height } = this.props;
    const ListItems = uploaded_contents.concat(files);
    const total_files = ListItems.length;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height - 180,
        overflow: 'auto',
        marginTop: 2,
      };
    }
    return (
      <React.Fragment>
        <UploadToolBar
          handleAddError={this.props.handleAddError}
          holder={this.holder}
        />
        <div style={style}>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12}>
                <UploadDropArea
                  handleChange={files => {
                    this.handleAddFiles(files);
                  }}
                  holder={this.holder}
                />
              </Grid>
              <UploadProgress
                handleDeleteAll={this.handleDeleteAll}
                handleUploadAll={() => this.handleUpload()}
                loading={this.state.loading}
                progress_all={this.state.progress_all}
                total_files={total_files}
                total_uploaded={uploaded_contents.length}
              />
            </Grid>
            <div className={classes.root_container}>
              <div className={classes.container}>
                <Grid container>
                  <Grid item xs={12}>
                    <List>
                      {ListItems.map((item, index) => {
                        return (
                          <UploadItem
                            handleDelete={this.handleDelete}
                            handleInformation={this.handleInformation}
                            handleUplodedItem={this.handleUplodedItem}
                            holder={this.holder}
                            index={index}
                            item={item}
                            key={`file-item-upload-${uuid()}`}
                          />
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Upload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, {
  setSkipOnline,
})(withStyles(Upload, useStyles));
