import React, { Component } from 'react';
import { GetData } from 'helpers';
import { QueryShared } from './utils_share_query';
class SharedQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Folder: {
        idFolder: -1,
        idParent: -1,
        name: 'Unknown',
        Rights: [],
        SubFolders: [],
      },
      idFolder: -1,
      loading: false,
      messages: [],
    };
  }
  getVariables = () => {
    let { idFolder, level } = this.props;
    if (!idFolder) {
      idFolder = -1;
      level = 0;
    } else if (idFolder >= 1) {
      level = 1;
    }
    if (!level) {
      level = 0;
    }
    return { idFolder, level };
  };
  componentDidMount() {
    const { idFolder, level } = this.getVariables();
    this.handleLoadShared(idFolder, level);
  }
  componentDidUpdate() {
    const { idFolder, level } = this.getVariables();
    if (idFolder !== this.state.idFolder) {
      this.handleLoadShared(idFolder, level);
    }
  }
  handleLoadShared = (idFolder, level) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryShared(idFolder, level)
        .then(res => {
          const data = GetData(res);
          const { ok, Folder, errors } = data.shared;
          if (ok) {
            this.setState({
              idFolder,
              level,
              Folder,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, idFolder });
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { idFolder, Folder, loading } = this.state;
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        idFolder,
        Folder,
        ParentFolder: Folder,
        loading,
      });
    });
  }
}

export default SharedQuery;
