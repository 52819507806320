export const udpateSingleGroup = (idGroup, group_map, metadata) => {
  const { group_mappings } = metadata;
  if (group_mappings) {
    const index = group_mappings.map(x => x.idGroup).indexOf(idGroup);
    // console.log({ index, group_map });
    if (index === -1 && group_map) {
      metadata.group_mappings.push(group_map);
    } else if (index !== -1 && !group_map) {
      metadata.group_mappings.splice(index, 1);
    } else if (index !== -1 && group_map) {
      metadata.group_mappings[index] = { ...group_map };
    }
  } else if (group_map) {
    metadata.group_mappings = [group_map];
  }
  return metadata;
};
export const udpateMutltiGroup = (idGroup, group_map, metadata) => {
  let { group_mappings } = metadata;
  if (group_mappings) {
    group_mappings = group_mappings.filter(x => x.idGroup !== idGroup);
    metadata.group_mappings = group_mappings.concat(group_map);
  } else if (group_map && group_map.length) {
    metadata.group_mappings = [...group_map];
  }
  return metadata;
};
