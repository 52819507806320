import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  SearchProjectsAtUser,
  DialogSelectFolder,
  SearchProjectCenterTeams,
} from 'components';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
class UploadToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center_team: null,
      project: null,
      loading: false,
      folder: null,
      open: false,
    };
    this.holder = {
      getFolderCenterTeam: null,
      cleanSelected: null,
    };
    if (props.holder) {
      props.holder.getSettings = () => {
        const { project, folder, center_team } = this.state;
        let settings = null;
        if (project) {
          settings = {
            projectIds: [project.id],
          };
          settings.send_project_email = true;
        }
        if (folder) {
          if (!settings) settings = {};
          settings.folderIds = [folder.idFolder];
          const { folders_paths } = folder;
          if (folders_paths) {
            settings.folderStructure = folders_paths.map(x => x.name).join('/');
          }
        }
        if (center_team) {
          settings.idCenterTeam = center_team.id;
        }
        return settings;
      };
    }
  }
  handleSelectProject = project => {
    console.log({ project });
    let folder = null;
    if (project) {
      const { ProjectFolder } = project;
      folder = ProjectFolder;
    }
    if (this.holder.cleanSelected) {
      this.holder.cleanSelected();
    }
    this.setState({ project, folder });
  };
  handleFolderSelection = folder => {
    this.setState({ folder, open: false });
  };
  handleAddCenterTeam = (center_team, folder) => {
    if (folder === undefined || !folder) {
      const { project } = this.state;
      if (project) {
        const { ProjectFolder } = project;
        folder = ProjectFolder;
      }
    }
    this.setState({ center_team, folder });
  };
  render() {
    const { project, loading, folder } = this.state;
    let folder_name = 'Home';
    if (folder) {
      folder_name = folder.name;
    }
    let ComponentProjectTeams = null;
    if (!loading && project) {
      ComponentProjectTeams = (
        <Grid item xs={6}>
          <SearchProjectCenterTeams
            handleAddCenterTeam={this.handleAddCenterTeam}
            holder={this.holder}
            idProject={project.id}
            no_icon
          />
        </Grid>
      );
    }
    return (
      <Paper square style={{ height: 80 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-start"
          spacing={2}
          style={{ minHeight: 78 }}
          xs={12}>
          <Grid container item spacing={1} xs={6}>
            <Grid item xs={ComponentProjectTeams ? 6 : 12}>
              <SearchProjectsAtUser
                external_selected={project}
                handleAddProject={this.handleSelectProject}
                handleSetProject={this.handleSelectProject}
                label="Asign upload to Project"
                no_icon
                placeholder="Search My Projects..."
              />
            </Grid>
            {ComponentProjectTeams}
          </Grid>
          <Grid item style={{ paddingTop: 25 }} xs={3}>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              onClick={() => this.setState({ open: true })}
              size="small"
              startIcon={<DriveFolderUploadIcon />}
              style={{ textTransform: 'none' }}
              variant="contained">
              {`Folder destiny: ${folder_name}`}
            </LoadingButton>
          </Grid>
        </Grid>
        <DialogSelectFolder
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open: false })}
          handleSelectFolder={this.handleFolderSelection}
          open={this.state.open}
        />
      </Paper>
    );
  }
}

UploadToolBar.propTypes = {
  handleAddError: PropTypes.func,
};

export default UploadToolBar;
