import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_DOWNLOAD, HTTP_ADDRESS_GRAPHQL } from 'config';

const GENERATE_DOWNLOAD = gql`
  mutation downloadGenerate(
    $downloadIds: [InputDownload!]!
    $public_access: Boolean
  ) {
    downloadGenerate(downloadIds: $downloadIds, public_access: $public_access) {
      ok
      key
      errors {
        path
        message
      }
    }
  }
`;
export const GenerateDownload = (downloadIds, public_access = false) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GENERATE_DOWNLOAD),
    variables: {
      downloadIds,
      public_access,
    },
  });
};

export const DownloadItemsAtKey = (key, handleProgress = null) => {
  if (!handleProgress) {
    handleProgress = () => {
      // const { loaded, total } = event;
      // const progress_all = Math.floor((loaded * 100) / total);
    };
  }
  return axios({
    url: `${HTTP_ADDRESS_DOWNLOAD}/${key}`,
    method: 'GET',
    responseType: 'blob', // important
    onDownloadProgress: event => handleProgress(event),
  });
};

const FINISHED_DOWNLOAD = gql`
  mutation downloadFinished($key: String!) {
    downloadFinished(key: $key)
  }
`;

export const MutationDownloadFinished = key => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(FINISHED_DOWNLOAD),
    variables: {
      key,
    },
  });
};
