import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { useStyles } from './Style';
import {
  ButtonCircularIconLoad,
  CardCreateHeader,
  DockerInputs,
} from 'components';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationUpdateDockerImageSetting } from 'graphql/Dockers/utils_docker_system';

class EditSystemDockerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      to_edit: null,
      idSelected: 0,
      selected_object: null,
      list_selections: [],
      changed: false,
      saving: false,
    };
    this.holder = {
      setSetting: null,
      getSetting: null,
      IsChange: null,
    };
  }
  componentDidMount() {
    const { selected } = this.props;
    const list_selections = selected.map((x, index) => ({
      index,
      id: x.id,
      idDocker: x.settings ? x.settings.idDocker : null,
      name: x.name,
      operation_name: x.operation,
      settings: x.settings,
    }));
    this.setState({ list_selections, selected_object: list_selections[0] });
  }
  handleSelectionChanged = selected => {
    console.log({ selected });
  };
  handleSaveClicked = () => {
    console.log('save');
    const { saving, selected_object } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      const { id, idDocker } = selected_object;
      const input = this.holder.getSetting();
      for (let i = input.inputs_outputs.length - 1; i >= 0; i--) {
        const element = input.inputs_outputs[i];
        if (element.volume === '' || element.type === '') {
          input.inputs_outputs.splice(i, 1);
        }
      }
      input.operation_name = selected_object.operation_name;
      MutationUpdateDockerImageSetting(
        selected_object.settings.id,
        idDocker,
        input
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors, setting } = data.udpateSystemDockerImageSetting;
          if (ok) {
            this.setState({ saving: false, changed: false });
            if (selected_object.operation_name) {
              setting.operation_name = selected_object.operation_name;
            }
            selected_object.settings = setting;
            this.holder.setSetting(setting);
            this.props.handleUpdateDockerSetting(id, setting);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangeOperationName = event => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const operation_name = event.target.value;
    const index = selected_object.index;
    let changed = selected[index].operation !== operation_name;
    if (this.holder.IsChange()) {
      changed = true;
    }
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        operation_name,
      },
    });
  };
  render() {
    const { classes, height } = this.props;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    let { list_selections, selected_object, saving, changed } = this.state;

    let name_selected = '';
    let settings = null;
    let operation_name = '';
    if (selected_object) {
      name_selected = selected_object.name;
      settings = selected_object.settings;
      operation_name = selected_object.operation_name;
    }
    return (
      <Card className={classes.card} style={style}>
        <CardCreateHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateDocker}
          icon={<EditIcon style={{ color: 'green' }} />}
          loading={this.state.loading}
          subheader="Edit the necessary field and save it"
          title="Edit System MB-Docker Image"
        />
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SearchAutocomplete
              // disabled={this.state.uploading}
              handleAddResultObject={this.handleSelectionChanged}
              label="Selected Image"
              name={name_selected}
              no_icon
              options={list_selections}
              placeholder="Select the image to edit..."
              selected={selected_object}
              setObject={this.handleSelectionChanged}
              sytle_autocomplete={{ minWidth: 200, paddingLeft: 15 }}
            />
          </Grid>
          <Grid item>
            <ButtonCircularIconLoad
              disabled={!changed}
              handleButtonClick={this.handleSaveClicked}
              loading={saving}
              small
            />
          </Grid>
        </Grid>
        <Divider sx={{ paddingTop: 1 }} variant="middle" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="operation-name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Operation Name"
                onChange={this.handleChangeOperationName}
                placeholder="Name of the docker operation (e.g. tar.gz)..."
                required
                sx={{ paddingBottom: 1 }}
                value={operation_name}
              />
            </Grid>
          </Grid>
          <DockerInputs
            defaultSetting={settings}
            handleAddError={this.props.handleAddError}
            handleChanged={changed => this.setState({ changed })}
            holder={this.holder}
          />
        </CardContent>
      </Card>
    );
  }
}

EditSystemDockerImage.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
EditSystemDockerImage.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  handleUpdateDockerSetting: () => '',
};
export default withStyles(EditSystemDockerImage, useStyles);
