import { FormatTime } from 'helpers';
import { IsInvalid } from 'helpers';
export const getPrivateAccess = private_access => {
  if (typeof private_access === 'string') {
    if (
      private_access === '1' ||
      private_access.toUpperCase() === 'YES' ||
      private_access.toUpperCase() === 'TRUE' ||
      private_access.toUpperCase().startsWith('T') ||
      private_access.toUpperCase().startsWith('Y')
    )
      return true;
    else return false;
  }
  return private_access;
};
export const columns = [
  { field: 'idLink', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      const { FullName } = value;
      return FullName ? FullName : '';
    },
  },
  { field: 'code', headerName: 'Code', align: 'left', edit: true },
  {
    field: 'private_access',
    headerName: 'Privated',
    align: 'left',
    edit: true,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const private_access = params.getValue(params.id, 'private_access');
      if (IsInvalid(private_access)) return 'FALSE';
      return getPrivateAccess(private_access) ? 'TRUE' : 'FALSE';
    },
  },
  {
    field: 'folder',
    headerName: 'Folder',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const folder = params.getValue(params.id, 'folder');
      if (IsInvalid(folder)) return '';
      return folder.name;
    },
  },
  {
    field: 'content',
    headerName: 'Content',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const content = params.getValue(params.id, 'content');
      if (IsInvalid(content)) return '';
      const { Series, File } = content;
      if (Series) return `Series: ${Series.SeriesDescription}`;
      else if (File) return `File: ${File.original_name}`;
      return '';
    },
  },
  {
    field: 'location',
    headerName: 'Location',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const folder = params.getValue(params.id, 'folder');
      if (folder) return folder.folders_paths.map(x => x.name).join('/');
      const content = params.getValue(params.id, 'content');
      if (IsInvalid(content)) return '';
      const { Folder } = content;
      if (Folder) return Folder.folders_paths.map(x => x.name).join('/');
      else return '';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
