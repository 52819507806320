/* eslint-disable indent */
import { grey, lightBlue } from '@mui/material/colors';
import { lighten } from '@mui/material/styles';
import { FormatBytes, FormatTime, getFileSeries } from 'helpers';

export const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'FullName',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: true,
    label: 'Type',
    format: value => {
      if (!value || value === undefined) return 'Missing';
      const { description } = value;
      if (description) return description;
      return 'Missing';
    },
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-Mail',
  },
  {
    id: 'username',
    numeric: true,
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'address',
    numeric: true,
    disablePadding: false,
    label: 'Country',
    format: value => {
      if (!value || value === undefined) return 'Missing';
      const { country } = value;
      if (country) return country;
      return 'Missing';
    },
  },
  {
    id: 'last_login',
    label: 'Last Login',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Register',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  { id: 'show', numeric: false, disablePadding: false, label: 'Show' },
];
export const useStyles = theme => ({
  root: {
    width: 'calc(100% - 16px)',
    height: 'auto',
    margin: theme.spacing(1),
    padding: 0,
  },
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
  table_head: {
    backgroundColor: grey[300],
  },
  table_body: {
    width: '100%',
    // maxHeight: 440,
    overflow: 'auto',
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
    // // position: 'absolute',
    // top: 0,
    // left: 0,
    // maxHeight: 'calc(100% - 65px)',
    // /* uncomment this static height to see it work */
    // /* height: 100%; */
    // width: '100%',
    // overflow: 'hidden',
    // display: 'flex',
    // flexDirection: 'column',
    // // maxHeight: 'calc(100%-500px)',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table_pagination: { height: '38px' },
  cell_user: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: 'red',
  },
  online: {
    color: 'green',
    fontWeight: 1000,
  },
  cell_avatar: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  cell_avatar_login: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  table_row: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'blue',
      },
    },
  },
  date_width: {
    minWidth: 200,
  },
});

export const getRow = value => {
  let row = {
    id: -1,
    Thumbnail: null,
    kind: '',
    name: '',
    size: '0 Bytes',
    data_modified: '',
    permission: [],
  };
  const { id, Folder, FolderContent, Object: MultiObject, updatedAt } = value;
  row.id = id;
  row.data_modified = updatedAt;
  if (typeof Folder !== 'undefined' && Folder) {
    row.name = Folder.name;
    row.kind = '--';
    row.permission = Folder.Rights;
    row.size = FormatBytes(Folder.Size);
  } else if (typeof FolderContent !== 'undefined' && FolderContent) {
    row = getFileSeries(FolderContent, row);
    row.permission = FolderContent.Rights;
  } else if (typeof MultiObject !== 'undefined' && MultiObject) {
    row = getFileSeries(MultiObject, row);
    row.permission = [MultiObject.Rights];
  }
  return row;
};
export const useToolbarStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(lightBlue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: 'none',
  },
});
