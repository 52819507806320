import { title } from '../HomeHeader/variables';

const productStyle = {
  section: {
    padding: '70px 0',
    paddingBottom: '10px',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
};

export default productStyle;
