import React from 'react';
import PropTypes from 'prop-types';
import { GetData, ServerErrorsString } from 'helpers';
import { Paper } from '@mui/material';
import { TableGrid } from 'components';
import { columns_prophet_folders } from './utils_columns';
import { QueryProphetSystemFolders } from 'graphql/utils_prophet';

class TabListFolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      loading: false,
      open: true,
      init: false,
      // list_folders: [
      //   {
      //     id: 1,
      //     folder_name: '/share/new_uploads/SSR-INS-P09275',
      //     encryption_key: 'SSR-INS-P09275',
      //     status: 'Uploaded',
      //     updatedAt: '',
      //     createdAt: '',
      //   },
      //   {
      //     id: 2,
      //     folder_name: '/share/new_uploads/SSR-INS-P09276',
      //     encryption_key: 'SSR-INS-P09276',
      //     status: 'Uploaded',
      //     updatedAt: '',
      //     createdAt: '',
      //   },
      //   {
      //     id: 3,
      //     folder_name: '/share/new_uploads/SSR-INS-P09277',
      //     encryption_key: 'SSR-INS-P09277',
      //     status: 'Uploaded',
      //     updatedAt: '',
      //     createdAt: '',
      //   },
      // ],
    };
  }

  componentDidMount() {
    this.queryProphetSystemFolders();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryProphetSystemFolders();
  }
  queryProphetSystemFolders = () => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryProphetSystemFolders()
        .then(res => {
          const data = GetData(res);
          const { folders, ok, errors } = data.getProphetSystemFolders;
          if (ok) {
            this.setState({
              loading: false,
              list_folders: folders,
              changed: false,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    return (
      <Paper>
        <div style={{ marginTop: '5px' }}>
          <TableGrid
            checkboxSelection
            dense
            // disableSelectionOnClick
            // external_edited={this.state.rows_edited}
            // external_selected={selected}
            grid
            headers={columns_prophet_folders}
            // height={this.props.height}
            // limit={this.props.limit}
            // loading={loading}
            // onPageChange={page => this.props.handleChangePage(page)}
            // onRowClicked={row_highlight => this.setState({ row_highlight })}
            // onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            // onRowsPerPageChange={limit =>
            //   this.props.handleChangeRowsPerPage(limit)
            // }
            // onSelectedChanged={selected =>
            //   this.props.onSelectedChanged(selected)
            // }
            // page={this.props.page}
            rows={this.state.list_folders}
            // rowsPerPageOptions={rowsPerPageBig}
          />
        </div>
      </Paper>
    );
  }
}

TabListFolders.propTypes = {
  classes: PropTypes.object,
};

export default TabListFolders;
