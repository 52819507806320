import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio } from '@mui/material';

class DateTimeCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const sylte_radio = {
      size: 'small',
      style: {
        margin: 0,
        padding: 0,
        paddingTop: 5,
      },
    };
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="center">
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Today"
          value="today"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Today AM"
          value="today_am"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Today PM"
          value="today_pm"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Yesterday"
          value="yesterday"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 24 hours"
          value="last_24_hours"
        />
      </Grid>
    );
  }
}

DateTimeCenter.propTypes = {
  classes: PropTypes.object,
};

export default DateTimeCenter;
