import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper } from '@mui/material';
import { CardHeaderPatient } from './components';
import moment from 'moment';
import { FormatTime } from 'helpers';
import { QuerySurveyAtLink } from 'graphql/Surveys';
import { GetData, ServerErrorsString } from 'helpers';
import { SurveyStepper, ViewerSurvey, defaultSurvey } from 'components';
import {
  GetSteps,
  handleValueChanged,
  handleSave,
  createSurvey,
} from 'helpers/Surveys';

class PatientSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultSurvey,
      updatedAt: null,
      loading: false,
      link: null,
      currentPatient: null,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  componentDidUpdate() {
    this.handleQuery();
  }
  handleQuery = (force = false) => {
    const { link } = this.props;
    const { loading, empty } = this.state;
    if (loading || empty || link === undefined || !link) return;
    if (link === this.state.link && !force) return;
    this.setState({ loading: true, empty: false });
    (async () => {
      QuerySurveyAtLink(link)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, survey } = data.surveyPatientAtLink;
          if (ok) {
            const { patient } = survey;
            const reply = createSurvey(
              survey,
              survey.id,
              patient.id,
              this.handleSave,
              this.handleBackToOverview,
              this.handleValueChanged
            );
            this.setState({
              link,
              loading: false,
              empty: false,
              updatedAt: survey.updatedAt,
              survey_json: reply.survey_json,
              currentSurvey: reply.survey,
              buttonSave: reply.buttonSave,
              buttonCancel: reply.buttonCancel,
              currentPatient: patient,
              value_changed: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleBackToOverview = () => {};
  handleSave = (sender, complete) => {
    handleSave(sender, complete, this, this.state.link);
  };
  handleValueChanged = (sender, options) => {
    handleValueChanged(sender, options, this);
  };
  handlePageChanged = survey => {
    const { currentPageNo, isFirstPage, isLastPage } = survey;
    this.setState({ currentPageNo, isFirstPage, isLastPage });
  };
  render() {
    const {
      currentSurvey,
      value_changed,
      currentPageNo,
      currentPatient,
    } = this.state;
    let updatedAt = this.state.updatedAt;
    if (updatedAt === undefined || !updatedAt) updatedAt = moment().valueOf();
    if (currentSurvey) {
      currentSurvey.currentPageNo = currentPageNo;
    }
    const json = GetSteps('surveyNeuro');
    let steps = [];
    let pages = [];
    if (json && json.pages) {
      pages = json.pages;
      steps = json.pages.map((x, index) => index);
    }
    let title = '';
    let header = 'Patient Survey';
    if (currentPatient) {
      title = currentPatient.PatientName;
      header = 'Patient Survey:';
    }
    return (
      <Paper>
        <CardHeaderPatient
          description={
            'Updated: ' + FormatTime(updatedAt, 'MMM Do, YYYY (h:mm a)')
          }
          handleReload={() => this.handleQuery(true)}
          loading={this.state.loading}
          title={
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-start">
              <Grid
                item
                sx={{
                  fontWeight: 800,
                  fontSize: 20,
                  padding: 1,
                  paddingLeft: 0,
                }}>
                {header}
              </Grid>
              <Grid item sx={{ fontSize: 20 }}>
                {title}
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <SurveyStepper
          activeStep={this.state.currentPageNo}
          buttonCancel={this.state.buttonCancel}
          buttonSave={this.state.buttonSave}
          currentSurvey={currentSurvey}
          handleBack={() => this.setState({ currentPageNo: currentPageNo - 1 })}
          handleNext={() => this.setState({ currentPageNo: currentPageNo + 1 })}
          handleSaved={() => this.setState({ value_changed: [] })}
          isChanged={Boolean(value_changed.length)}
          isFirstPage={this.state.isFirstPage}
          isLastPage={this.state.isLastPage}
          loading={this.state.loading}
          maxSteps={steps.length}
          mode="patient"
          pages={pages}>
          <ViewerSurvey
            currentSurvey={currentSurvey}
            delta={250}
            handlePageChanged={this.handlePageChanged}
            height={this.props.height}
            loading={this.state.loading}
          />
        </SurveyStepper>
      </Paper>
    );
  }
}

PatientSurvey.propTypes = {
  handleUpdateSurvey: PropTypes.func,
};
PatientSurvey.defaultProps = {
  handleUpdateSurvey: () => '',
};

export default PatientSurvey;
