import {
  MutationUpdateBatchSetting,
  MutationUpdateJobSetting,
} from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';

export const dicom_tags = [
  { tag: 'FolderCenter' },
  { tag: 'TimePoint' },
  { tag: 'PatientName' },
  { tag: 'PatientID' },
  { tag: 'PatientBirthDay' },
  { tag: 'PatientSex' },
  { tag: 'AccessionNumber' },
  { tag: 'StudyDescription' },
  { tag: 'StudyID' },
  { tag: 'StudyDate' },
  { tag: 'StudyTime' },
  { tag: 'StudyInstanceUID' },
  { tag: 'SeriesDescription' },
  { tag: 'SeriesDate' },
  { tag: 'SeriesTime' },
  { tag: 'SeriesNumber' },
  { tag: 'SeriesInstanceUID' },
  { tag: 'Modality' },
  { tag: 'STUDY_TIME_STAMP' },
  { tag: 'STUDY_YEAR' },
  { tag: 'STUDY_MONTH' },
  { tag: 'STUDY_DAY' },
  { tag: 'SEQUENCE_MAPPING' },
  { tag: 'FILE_TIME_STAMP' },
  { tag: 'FILE_YEAR' },
  { tag: 'FILE_MONTH' },
  { tag: 'FILE_DAY' },
];
export const defaultState = {
  folder: null,
  batch_log_folder: null,
  own_folder: false,
  on_content: false,
  project: null,
  tags: [],
};
export const handleUpdateBatchSetting = self => {
  const { updating, setting } = self.state;
  const { batch } = self.props;
  if (updating || !batch || batch === undefined) return;
  self.setState({ updating: true });
  let folder_structure = null;
  const { project, tags, folder, batch_log_folder } = setting;
  if (tags && tags.length) {
    folder_structure = tags
      .map(x => {
        if (x.tag === undefined) return x;
        return x.tag;
      })
      .join('/');
  }
  const in_setting = {
    folder_structure,
    own_folder: setting.own_folder,
    on_content: setting.on_content,
    create_batch_log: setting.create_batch_log,
    idProject: project ? project.id : null,
    idFolder: folder ? folder.idFolder : null,
    idBatchLogFolder: batch_log_folder ? batch_log_folder.idFolder : null,
  };
  (async () => {
    MutationUpdateBatchSetting(batch.id, in_setting)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, setting } = data.updateBatchSetting;
        if (ok) {
          self.setState({
            count: -1,
            updating: false,
            // setting: { ...defaultState },
            setting: { ...setting },
          });
          let tmp_batch = batch;
          tmp_batch.setting = setting;
          self.props.handleUpdateBatchProcess(tmp_batch);
          self.props.handleSave(setting);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ updating: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const handleUpdateJobSetting = self => {
  const { updating, setting } = self.state;
  const { job, batch } = self.props;
  if (updating || !job || job === undefined) return;
  self.setState({ updating: true });
  const in_setting = {
    own_folder: setting.own_folder,
  };
  (async () => {
    MutationUpdateJobSetting(batch.id, in_setting)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.updateJobSetting;
        if (ok) {
          self.setState({ updating: false });
          self.props.handleSave(setting);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ updating: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
