import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadClinicalFileCatalogueCSV } from './utils';

const QUERY_CLINICAL_FILE_CATALOGUES = gql`
  query($query_inputs: QueryClinicalFileCatalogue, $page: Int, $limit: Int) {
    clinicalFileCatalogues(
      query_inputs: $query_inputs
      page: $page
      limit: $limit
    ) {
      ok
      files {
        id
        idEpilepsyCase
        idSleepCase
        idPatient
        idFileObject
        idSeries
        idSignalEDF
        idFolder

        epilepsy_case {
          id
        }
        sleep_case {
          id
        }
        file_object {
          idFileObject
          original_name
          mimetype
          filepath
          createdAt
        }
        series {
          idSeries
          Thumbnail
          DicomFiles {
            idFile
            OriginalName
            FilePath
          }
          createdAt
        }
        edf {
          id
          file_object {
            idFileObject
            original_name
            mimetype
            filepath
          }
          createdAt
        }
        folder {
          idFolder
          name
          createdAt
        }
        folder_content {
          idFolderContent
          idFolder
        }
        patient {
          id
          PatientID
        }
      }
      errors {
        path
        message
      }
      total
    }
  }
`;
export const QueryClinicalFileCatalogues = (query_inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CLINICAL_FILE_CATALOGUES),
    variables: {
      query_inputs,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_CLINICAL_FILE_CATALOGUES = gql`
  mutation($ids: [LongLong!]!) {
    deleteClinicalFileCatalogues(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveClinicalFileCatalogues = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_CLINICAL_FILE_CATALOGUES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_CLINICAL_FILE_CATALOGUES = gql`
  mutation($inputs: [InputClinicalFileCatalogue!]!) {
    addClinicalFileCatalogues(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddClinicalFileCatalogues = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_CLINICAL_FILE_CATALOGUES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_CLINICAL_FILE_CATALOGUES = gql`
  mutation($file: Upload!) {
    uploadClinicalFileCatalogues(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      edss {
        id
        idPatient
        sequence_number
        date
        score
        fs1_visual
        fs2_brainstem
        fs3_pyramidal
        fs4_cerebellar
        fs5_sensory
        fs6_bowel_bladder
        fs7_cerebral
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadClinicalFileCatalogues = (
  file,
  handleProgress = null
) => {
  return MutationUploadClinicalFileCatalogueCSV(
    file,
    MUTATION_UPLOAD_CLINICAL_FILE_CATALOGUES,
    handleProgress
  );
};
