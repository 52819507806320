import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_RUNNING_PROCESS = gql`
  query($page: LongLong, $limit: LongLong) {
    runningProcess(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      docker_process {
        id
        idUser
        idFileObject
        idSeries
        idFolderContent

        operation
        status
        docker_id
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryRunningProcess = (page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_RUNNING_PROCESS),
    variables: {
      page,
      limit
    },
  });
};

export const MUTATION_EXECUTE_PROCESS = gql`
  mutation($input: InputDockerProcess!) {
    executeProcess(input: $input) {
      ok
      errors {
        path
        message
      }
      docker_process {
        id
        idUser
        idFileObject
        idSeries
        idFolderContent

        operation
        status
        docker_id
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationExecuteProcess = input => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_EXECUTE_PROCESS),
    variables: {
      input,
    },
  });
};
export const MUTATION_STOP_PROCESS = gql`
  mutation($idDockerProcess: LongLong!) {
    stopProcess(idDockerProcess: $idDockerProcess) {
      ok
      errors {
        path
        message
      }
      warning
    }
  }
`;
export const MutationStopProcess = idDockerProcess => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_STOP_PROCESS),
    variables: {
      idDockerProcess,
    },
  });
};
