import { Line3D, Vector3D } from 'helpers/Utils3D';
import * as m4 from '../../utils/m4';
import LineWebGL from './LineWebGL';

class AngleWebGL {
  constructor(gl) {
    this.gl = gl;
    this.line1 = new Line3D();
    this.line2 = null;
    this.angle = new LineWebGL(this.gl);
    this.arc = new LineWebGL(this.gl);
    this.click = 0;
    // make a 2d canvas for making text textures.
    this.ctx = document.createElement('canvas').getContext('2d');
    this.center = null;
  }
  setOrigin(point3d) {
    if (!point3d || point3d === undefined) return;
    this.line1.set(point3d.clone(), point3d.clone());
    const vertices = this.line1.getVertices();
    this.angle.setVertices(vertices, [0, 255, 0], 2);
    this.click = 1;
  }
  setEndLine1(point3d, click = null) {
    if (!point3d || point3d === undefined) return;
    this.line1.setEnd(point3d.clone());
    const vertices = this.line1.getVertices();
    this.angle.setVertices(vertices, [0, 255, 0], 2);
    if (click) {
      this.click = 2;
    }
  }
  IsLine1Ready() {
    return this.click >= 2;
  }
  setEndLine2(point3d, click = null) {
    if (!point3d || point3d === undefined) return;
    if (!this.line2) {
      this.line2 = new Line3D(this.line1.getStart(), point3d.clone());
    } else {
      this.line2.setEnd(point3d.clone());
    }
    const vertices1 = this.line1.getVertices();
    const vertices2 = this.line2.getVertices();
    this.angle.setVertices(vertices1.concat(vertices2), [0, 255, 0], 4);
    const radius = this.line1.length() * 0.25;
    const RESULT_ANGLE = this.line1.angle(this.line2);
    const delta = RESULT_ANGLE / 10;
    const axis = this.line1.crossVectors(this.line2);
    axis.multiplyBy(radius);
    if (axis) {
      const direction = this.line1.normal().toArray();
      direction.push(1.0);
      const P0 = this.line1.pointAtLength(radius).toArray();
      let points = [P0[0], P0[1], P0[2]];
      const origin = this.line1.getStart();
      for (let i = 1; i <= 10; i++) {
        const matrix = m4.axisRotation(axis.toArray(), delta * i);
        const dir = m4.vectorMultiply(direction, matrix);
        const a = new Vector3D(dir[0], dir[1], dir[2]);
        a.multiplyBy(radius);
        const point = origin.add(a);
        points = points.concat(point.toArray());
        points = points.concat(point.toArray());
        if (i === 5) {
          this.center = point;
        }
      }
      // console.log({ points });
      this.arc.setVertices(points, [0, 255, 0], points.length / 3);
    }
    if (click) {
      this.click = 3;
    }
  }
  draw(viewProjectionMatrix, projectionMatrix) {
    this.angle.draw(viewProjectionMatrix);
    this.arc.draw(viewProjectionMatrix);
    if (!this.center) return;
    // compute a clipspace position
    // using the matrix we computed for the F
    var clipspace = m4.transformVector(projectionMatrix, [
      this.center.x,
      this.center.y,
      this.center.z,
      1,
    ]);

    // divide X and Y by W just like the GPU does.
    clipspace[0] /= clipspace[3];
    clipspace[1] /= clipspace[3];

    // convert from clipspace to pixels
    // var pixelX = (clipspace[0] * 0.5 + 0.5) * this.gl.canvas.width;
    // var pixelY = (clipspace[1] * -0.5 + 0.5) * this.gl.canvas.height;

    this.ctx.fillText('hola', 0, 0);
  }
}
export default AngleWebGL;
