import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { SearchInput } from 'components';
import DivGrow from 'components/DivGrow';

const useStyles = theme => ({
  searchInput: {
    height: '42px',
    margin: theme.spacing(1),
  },
  searchButton: {
    height: '42px',
    margin: theme.spacing(1),
  },
  textTop: {
    marginBottom: theme.spacing(2),
  },
});

class TeamsToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, handleOpenCreateTeam, handleTeamSearch } = this.props;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start"
        // style={{ margin: 20, width: 'calc(100% - 40px)' }}
      >
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="space-between"
          style={{ padding: 20 }}>
          <Grid item xs={6}>
            <Typography className={classes.textTop} component="div">
              <Box
                m={1}
                style={{ fontWeight: 400, fontSize: 12 }}
                variant="button">
                MANAGEMENT
              </Box>
              <Box m={1} spacing={3} style={{ fontWeight: 1000, fontSize: 22 }}>
                Teams
              </Box>
              <Box m={1}> </Box>
            </Typography>
          </Grid>
          <DivGrow />
          <Button
            color="primary"
            onClick={handleOpenCreateTeam}
            style={{ marginTop: 20 }}
            variant="contained">
            Create Team
          </Button>
        </Grid>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          style={{ padding: 20, paddingTop: 0, paddingBottom: 0 }}>
          <SearchInput
            className={classes.searchInput}
            onChange={handleTeamSearch}
            placeholder="Search team by name..."
          />
          <Button
            className={classes.searchButton}
            component="span"
            variant="contained">
            Search
          </Button>
        </Grid>
      </Grid>
    );
  }
}

TeamsToolBar.propTypes = {
  className: PropTypes.string,
};

export default withStyles(TeamsToolBar, useStyles);
