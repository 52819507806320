import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Button, CircularProgress } from '@mui/material';
import clsx from 'clsx';

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  signInButton: {
    marginTop: 0,
    marginBottom: 0,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '47%',
    marginTop: -12,
    marginLeft: 0,
  },
});

class ButtonLoad extends Component {
  render() {
    const {
      classes,
      name,
      loading,
      isValid,
      handleClick,
      disable_button,
      size,
      variant,
      className,
      transform,
      ...res
    } = this.props;
    let disabled = false;
    if (typeof loading !== 'undefined' && typeof isValid !== 'undefined') {
      disabled = isValid ? loading : !isValid;
    } else {
      disabled = loading;
    }
    let style = { textTransform: 'none' };
    if (transform) {
      style = {};
    }
    //
    return (
      <div className={classes.wrapper}>
        <Button
          className={clsx({
            [classes.signInButton]: !className,
            [className]: className,
          })}
          color={this.props.color}
          disabled={disabled || disable_button}
          fullWidth
          onClick={handleClick}
          size={size ? size : 'large'}
          style={{ ...style }}
          // type="submit"
          variant={variant}
          {...res}>
          {name}
        </Button>
        {loading && (
          <CircularProgress className={classes.buttonProgress} size={24} />
        )}
      </div>
    );
  }
}

ButtonLoad.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  disable_button: PropTypes.bool,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  name: PropTypes.string,
  transform: PropTypes.bool,
};
ButtonLoad.defaultProps = {
  disable_button: false,
  variant: 'contained',
  className: null,
  loading: false,
  transform: false,
  color: 'inherit',
};
export default withStyles(ButtonLoad, useStyles);
