/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { Card, Divider, Grid } from '@mui/material';
import clsx from 'clsx';

import { drawerWidthShow, TopMenuShow, useStylesShow } from '../TopMenu';
import BottonMenu from '../BottonMenu';

import { FileMetaData, SeriesFilesThumbnails } from './components';

class ViewShowSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      width: -1,
      height: -1,
      File: null,
      seleted: -1,
    };
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth, // - getWidth(),
      height: window.innerHeight,
    });
  };
  handleOnClick = (index, File) => {
    const { loading } = this.state;
    if (loading) return;
    this.state.selected = index;
    this.props.handleClickFileItem(File);
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleClickFileItem = (file, index) => {
    const { File, open } = this.state;
    if (
      !File ||
      Object.keys(File).length === 0 ||
      file.idFile !== File.idFile
    ) {
      if (!open) {
        this.setState(prevState => ({
          details: !prevState.details,
          File: file,
          seleted: index,
        }));
      } else {
        this.setState({ File: file, seleted: index });
      }
    } else {
      this.setState({ File: file, seleted: index });
    }
  };

  render() {
    const { Series, classes } = this.props;
    const { open, width, height, File } = this.state;
    return (
      <Grid className={classes.root} container direction="column">
        <Card
          className={classes.card}
          style={{
            height: height - 72,
          }}>
          <TopMenuShow
            handleBack={this.handleBack}
            handleDrawerOpen={this.handleDrawerOpen}
            open={open}
            Series={Series}
          />
          <Divider />
          <Grid
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
            item>
            <SeriesFilesThumbnails
              handleError={this.props.handleError}
              handleOnClick={this.handleClickFileItem}
              Series={Series}
              width={open ? width - drawerWidthShow : null}
            />
          </Grid>
          <BottonMenu Series={Series} />
        </Card>
        <FileMetaData
          File={File}
          handleDrawerClose={this.handleDrawerClose}
          handleError={this.props.handleError}
          open={open}
        />
      </Grid>
    );
  }
}

ViewShowSeries.propTypes = {
  classes: PropTypes.object,
  handleError: PropTypes.func,
  idSeries: PropTypes.number,
};
ViewShowSeries.defaultProps = {};

export default withStyles(withRouter(ViewShowSeries), useStylesShow);
