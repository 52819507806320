import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Grid, TablePagination } from '@mui/material';
import { StatusDashboardText } from './components';
import { rowsPerPageBig } from 'helpers';
class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      isDesktop,
      isMobile,
      display_database,
      setting,
    } = this.props;
    return (
      <div
        className={clsx({
          [classes.footer]: true,
          // [classes.shiftContent]: !isDesktop,
          [classes.footer_list]: display_database === 'list',
        })}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start">
          {!isMobile && (
            <Grid item xs={4}>
              <StatusDashboardText
                filters={this.props.filters}
                setting={setting}
              />
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : isDesktop ? 6 : 5}>
            <TablePagination
              className={classes.pagination}
              component="div"
              count={this.props.Total}
              onPageChange={this.props.handleChangePage}
              onRowsPerPageChange={this.props.handleChangeRowsPerPage}
              page={this.props.page}
              rowsPerPage={this.props.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
          {!isMobile && <Grid item xs={1} />}
        </Grid>
      </div>
    );
  }
}

StatusDashboard.propTypes = {
  Total: PropTypes.number,
  classes: PropTypes.object,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  limit: PropTypes.number,
  page: PropTypes.number,
};

const mapStateToProps = state => {
  const { setting } = state.page_database;
  return {
    setting,
  };
};

export default connect(mapStateToProps)(StatusDashboard);
