import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, CircularProgress } from '@mui/material';
import { ListKeywords } from '../index';
import {
  ComboxGroupMapping,
  SearchCategoryProjects,
  ComboxMultiGroupMapping,
} from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryGroupTypesByNames } from 'graphql/Mapping';
const groups_names = [
  'Clinics Designer',
  'Authors Designer',
  'Project Designer',
  'Creators Designer',
  'Publication Designer',
  'Copyright',
  'Image Type Designer',
];
class EditMetadataContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      groups: [],
    };
  }
  componentDidMount() {
    this.updateGroups();
  }
  updateGroups = () => {
    const { loading } = this.state;
    if (loading) return;
    this.props.handleLoading(true);
    this.setState({ loading: true });
    (async () => {
      QueryGroupTypesByNames(groups_names)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, groups } = data.groupTypesByNames;
          if (ok) {
            this.props.handleLoading(false);
            if (groups.length) {
              this.setState({ groups, loading: false });
            } else {
              this.setState({ loading: false });
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleLoading(true);

          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getValue = group_name => {
    let { metadata } = this.props;
    const { groups } = this.state;
    if (groups.length === 0 || !metadata || metadata === undefined)
      return undefined;
    let index = groups.map(x => x.name).indexOf(group_name);
    const { group_mappings } = metadata;
    if (index === -1 || !group_mappings || group_mappings === undefined) {
      return undefined;
    }
    if (group_name === 'Publication Designer') {
      return group_mappings.filter(x => x.idGroup === groups[index].id);
    }
    index = group_mappings.map(x => x.idGroup).indexOf(groups[index].id);
    if (index === -1) return undefined;
    return group_mappings[index];
  };
  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <Grid
          alignItems="center"
          container
          item
          justifyContent="center"
          spacing={3}
          style={{ minHeight: '50%' }}
          xs={12}>
          <CircularProgress />
        </Grid>
      );
    }
    let { metadata, folder_content } = this.props;
    let notes = '';
    let comments = '';
    let keywords = [];
    let copyrights = [];
    let project = null;
    if (metadata && metadata.notes) {
      notes = metadata.notes ? metadata.notes : '';
    }
    if (metadata && metadata.comments) {
      comments = metadata.comments ? metadata.comments : '';
    }
    if (metadata && metadata.group_mappings) {
      keywords = metadata.group_mappings.filter(
        x => x.group && x.group.name === 'Keyword Designer'
      );
      copyrights = metadata.group_mappings.filter(
        x => x.group && x.group.name === 'Copyright'
      );
    }
    if (folder_content && folder_content.project) {
      project = folder_content.project;
    } else if (metadata && metadata.project) {
      project = metadata.project;
    }
    // console.log({ notes, metadata });
    const clinic = this.getValue('Clinics Designer');
    const pro = this.getValue('Project Designer');
    const author = this.getValue('Authors Designer');
    const creator = this.getValue('Creators Designer');
    const publications = this.getValue('Publication Designer');
    const image = this.getValue('Image Type Designer');
    console.log({ publications });
    return (
      <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} container rowSpacing={2}>
        <Grid item xs={6}>
          <ComboxGroupMapping
            default_input={clinic ? clinic.name : 'All'}
            group_name="Clinics Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Clinics"
            value={clinic}
          />
        </Grid>
        <Grid item xs={6}>
          <SearchCategoryProjects
            default_input="All"
            handleProjectSelected={this.props.handleProjectSelected}
            label="Thema"
            value={project}
          />
        </Grid>
        <Grid item xs={6}>
          <ComboxGroupMapping
            default_input={pro ? pro.name : 'All'}
            group_name="Project Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Projekt"
            value={pro}
          />
        </Grid>
        <Grid item xs={6}>
          <ComboxGroupMapping
            default_input={author ? author.name : 'All'}
            group_name="Authors Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Authors"
            value={author}
          />
        </Grid>
        <Grid item xs={6}>
          <ComboxGroupMapping
            default_input={creator ? creator.name : 'All'}
            group_name="Creators Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Creators"
            value={creator}
          />
        </Grid>
        <Grid item xs={6}>
          <ComboxMultiGroupMapping
            group_name="Publication Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Publications"
            value={publications}
          />
        </Grid>
        <Grid item xs={6}>
          <ComboxGroupMapping
            default_input={image ? image.name : 'All'}
            group_name="Image Type Designer"
            handleGroupMappingSelected={this.props.handleGroupMappingSelected}
            label="Image Type"
            value={image}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="metadata-notes"
            InputLabelProps={{
              shrink: true,
            }}
            label="Notes"
            multiline
            onChange={this.props.handleNotesChanged}
            rows={4}
            value={notes}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="metadata-comments"
            InputLabelProps={{
              shrink: true,
            }}
            label="Referenz"
            multiline
            onChange={this.props.handleCommentsChanged}
            rows={4}
            value={comments}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ListKeywords
            handleAddError={this.props.handleAddError}
            handleUpdateKeywords={this.props.handleUpdateKeywords}
            keywords={keywords}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ListKeywords
            group="Copyrights"
            group_type="Copyright"
            handleAddError={this.props.handleAddError}
            handleUpdateKeywords={this.props.handleUpdateKeywords}
            keywords={copyrights}
          />
        </Grid>
      </Grid>
    );
  }
}

EditMetadataContent.propTypes = {
  metadata: PropTypes.object,
};
EditMetadataContent.defaultProps = {
  metadata: null,
  handleAddError: () => '',
  handleNotesChanged: () => '',
  handleCommentsChanged: () => '',
  handleUpdateKeywords: () => '',
  handleGroupMappingSelected: () => '',
  handleProjectSelected: () => '',
  handleLoading: () => '',
};

export default EditMetadataContent;
