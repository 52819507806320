export const getVariables = props => {
  const { dashboard_view } = props;
  const { visualization_mode, fullHistory } = dashboard_view;
  let idProject = 0;
  if (dashboard_view.selected_project) {
    idProject = dashboard_view.selected_project.id;
  }
  let datestart = '';
  let dateend = '';
  if (!fullHistory) {
    datestart = dashboard_view.datestart;
    dateend = dashboard_view.dateend;
  }
  return {
    mode: visualization_mode,
    idProject,
    datestart,
    dateend,
    fullHistory,
  };
};
