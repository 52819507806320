import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { CircularProgress, Fab, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 1000,
  },
  fabProgressSmall: {
    color: green[800],
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class ButtonCircularIconLoad extends Component {
  render() {
    const {
      classes,
      loading,
      success,
      icon,
      handleButtonClick,
      className,
      left,
      disabled,
      tooltip,
      small,
    } = this.props;
    // console.log({ loading });
    return (
      <div
        className={clsx({
          [classes.wrapper]: true,
          [left]: left,
        })}>
        <Tooltip title={tooltip}>
          <span>
            <Fab
              aria-label="save"
              className={clsx({
                [className]: className,
                [classes.buttonSuccess]: success,
              })}
              color={this.props.color}
              disabled={loading || disabled}
              onClick={handleButtonClick}
              size={small ? 'small' : 'medium'}>
              {success ? <CheckIcon /> : icon ? icon : <SaveIcon />}
            </Fab>
          </span>
        </Tooltip>
        {loading && (
          <CircularProgress
            className={clsx({
              [classes.fabProgress]: !small,
              [classes.fabProgressSmall]: small,
            })}
            size={small ? 40 : 50}
          />
        )}
      </div>
    );
  }
}

ButtonCircularIconLoad.defaultProps = {
  success: false,
  loading: false,
  disabled: false,
  icon: <SaveIcon />,
  tooltip: '',
  small: false,
  color: 'primary',
};
ButtonCircularIconLoad.propTypes = {
  classes: PropTypes.object,
  tooltip: PropTypes.string,
};

export default withStyles(ButtonCircularIconLoad, useStyles);
