import React from 'react';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { columns, useStyles } from '../utils';

import { MainTable, MainTableToolbar } from 'components/Tables/components';
import { DeleteActivities } from 'graphql/Projects/utils_activities';
import { SelectedArray, GetData, rowsPerPageBig } from 'helpers';
import clsx from 'clsx';

class TableSiteProjectActivities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      selected: [],
      deleting: false,
    };
    const { holder } = props;
    if (holder) {
      holder.getPageLimit = () => {
        const { page, limit } = this.state;
        return { page, limit };
      };
    }
  }

  handleDeleteSelected = event => {
    event.preventDefault();
    const { selected, deleting } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      DeleteActivities(selected.map(x => x.id))
        .then(res => {
          const data = GetData(res);
          if (data.DeleteActivities) {
            const { ok, errors } = data.DeleteActivities;
            if (ok) {
              this.setState({ deleting: false, selected: [] });
              if (this.props.handleDeleteSelected) {
                this.props.handleDeleteSelected(selected);
              }
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ deleting: false });
        });
    })();
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    //
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selected: rows });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangePageLimit(0, +event.target.value);
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const { classes, rows, title, loading, total, height } = this.props;
    const { selected, page, limit, deleting } = this.state;
    //
    let style = {};
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={title}
        />
        <div
          className={clsx({
            [classes.tableWrapper]: true,
            [classes.max_height]: height,
          })}
          style={{ ...style }}>
          <MainTable
            columns={columns}
            disable_splice={this.props.disable_splice}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableSiteProjectActivities.defaultProps = {
  loading: false,
  rows: [],
  total: 0,
  disable_splice: true,
  handleChangePageLimit: () => console.log('handleChangePageLimit'),
};
TableSiteProjectActivities.propTypes = {
  disable_splice: PropTypes.bool,
  rows: PropTypes.array,
};
export default withStyles(TableSiteProjectActivities, useStyles);
