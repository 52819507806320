import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CardSettingFiles } from './components';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
});

class Cloud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.isActive) return null;
    const { classes, user } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <CardSettingFiles user={user} />
          </Grid>
          {/* <Grid item lg={8} md={6} xl={8} xs={12}>
            <AccountDetails user={user} />
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

Cloud.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Cloud, useStyles);
