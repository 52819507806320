import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Paper,
  Table,
  TablePagination,
  TableBody,
} from '@mui/material';
import { TableGridRow, TableGridHeader } from './components';
import { withStyles } from 'tss-react/mui';
import { v1 as uuid } from 'uuid';
import clsx from 'clsx';
import {
  SelectedArray,
  rowsPerPageSmall,
  getSorting,
  stableSort,
} from 'helpers';
import {
  getHeaders,
  getFinalRows,
} from 'components/Tables/TableGrid/TableGrid/utils_table_grid';

const useStyles = () => ({
  root: {
    'user- select': 'none' /* supported by Chrome and Opera */,
    '-webkit-user-select': 'none' /* Safari */,
    '-khtml-user-select': 'none' /* Konqueror HTML */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
  },
  table: {
    tableLayout: 'auto',
    width: '100%',
    whiteSpace: 'nowrap',
  },
});
const allowedKey = ['Shift', 'ArrowDown', 'ArrowUp'];

class TableGridCollapsible extends React.Component {
  constructor(props) {
    super(props);
    const { page, rowsPerPage, external_selected } = props;
    this.state = {
      order: '',
      orderBy: '',
      selected: external_selected ? external_selected : [],
      page: page ? page : 0,
      limit: rowsPerPage ? rowsPerPage : 5,
      shiftPress: false,
      rowHighlight: null,
      rowClicked: null,
      rows_edited: [],
      headers_to_hide: [],
      open_show_column: false,
      filter: null,
    };
    this.currentRows = null;
  }
  componentDidMount() {
    document.addEventListener('keydown', this.keyDownHandler);
    document.addEventListener('keyup', this.keyUpHandler);
  }
  componentDidUpdate() {
    const { selected } = this.state;
    const { external_selected } = this.props;
    if (external_selected && external_selected.length !== selected.length) {
      this.setState({ selected: external_selected });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler, false);
    document.addEventListener('keyup', this.keyUpHandler, false);
  }
  handleRowEdited = (edit, row) => {
    // console.log({ edit, ID: row.id });
    let { rows_edited } = this.state;
    const index = rows_edited.map(x => x.id).indexOf(row.id);
    if (!edit && index === -1) return;
    if (!edit) {
      rows_edited.splice(index, 1);
    } else if (index === -1) {
      rows_edited.push({ ...row });
    } else {
      rows_edited[index] = { ...row };
    }
    this.setState({ rows_edited });
    this.props.onRowEditedChanged(rows_edited);
  };
  handleSelectAllClick = () => {
    let { selected } = this.state;
    if (selected.length) {
      selected = [];
    } else {
      selected = getFinalRows(this.props.rows, this.state, this.props);
    }
    this.setState({ selected });
    this.props.onSelectedChanged(selected);
  };
  handleIsItemSelected = row => {
    const { selected } = this.state;
    const index = selected.map(x => x.id).indexOf(row.id);
    return index !== -1;
  };
  handleIsRowSelected = row => {
    const { rowHighlight } = this.state;
    if (!rowHighlight) return false;
    // console.log('handleIsRowSelected', {
    //   row: row.id ? row.id : null,
    //   rowHighlight: rowHighlight ? rowHighlight.id : null,
    // });
    return row.id === rowHighlight.id;
  };
  IsRowClicked = row => {
    const { rowClicked } = this.state;
    if (!rowClicked) return false;
    return row.id === rowClicked.id;
  };
  handleOnCheckBoxItemClicked = row => {
    const { selected } = this.state;
    const index = selected ? selected.map(x => x.id).indexOf(row.id) : 0;
    const newSelected = SelectedArray(selected, row, index);
    this.setState({ selected: newSelected });
    this.props.onSelectedChanged(newSelected);
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    console.log({ newPage });
    if (this.props.onPageChange) {
      this.props.onPageChange(newPage);
    }
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    let limit = +event.target.value;
    console.log({ limit });
    this.setState({ page: 0, limit });
    if (this.props.onRowsPerPageChange) {
      this.props.onRowsPerPageChange(limit);
    }
  };
  handleSortHeaderClicked = (orderBy, order) => {
    this.setState({ orderBy, order });
  };

  handleShowHideColumn = header => {
    console.log({ header });
    if (header === undefined || !header) return;
    let { headers_to_hide } = this.state;
    const index = headers_to_hide.map(x => x.field).indexOf(header.field);
    if (index === -1) {
      headers_to_hide.push(header);
    } else {
      headers_to_hide.splice(index, 1);
    }
    this.setState({ headers_to_hide });
  };
  handleClickHideColumn = headers_to_hide => {
    this.setState({ headers_to_hide });
  };
  handleClickShowColumn = () => {
    this.setState({ open_show_column: true });
  };
  keyDownHandler = event => {
    const { key } = event;
    if (allowedKey.indexOf(key) === -1) return;
    // console.log('keyDownHandler');
    if (!this.currentRows) return;
    const total_rows = this.currentRows.length;
    const { rowHighlight } = this.state;
    if (key === 'Shift') {
      this.setState({ shiftPress: true });
    } else if (key === 'ArrowDown' && rowHighlight) {
      let index = this.currentRows.map(x => x.id).indexOf(rowHighlight.id);
      if (index !== -1) {
        index++;
        if (index >= total_rows - 1) {
          index = total_rows - 1;
        }
        this.setState({
          rowHighlight: this.currentRows[index],
          rowClicked: this.currentRows[index],
        });
      } else {
        this.setState({ rowHighlight: null });
      }
    } else if (key === 'ArrowUp' && rowHighlight) {
      let index = this.currentRows.map(x => x.id).indexOf(rowHighlight.id);
      if (index !== -1) {
        index--;
        if (index < 0) {
          index = 0;
        }
        this.setState({
          rowHighlight: this.currentRows[index],
          rowClicked: this.currentRows[index],
        });
      } else {
        this.setState({ rowHighlight: null });
      }
    }
  };
  keyUpHandler = event => {
    const { key } = event;
    if (allowedKey.indexOf(key) === -1) return;
    // console.log('keyUpHandler');
    if (key === 'Shift') {
      this.setState({ shiftPress: false });
    }
    // console.log({ key });
  };
  handleRowClicked = row => {
    let { rowHighlight, rowClicked } = this.state;
    // console.log({
    //   row: row ? row.id : null,
    //   rowHighlight: rowHighlight ? rowHighlight.id : null,
    //   rowClicked: rowClicked ? rowClicked.id : null,
    // });
    if (!rowClicked) {
      rowClicked = row;
    } else if (rowClicked.id !== row.id) {
      rowClicked = row;
    }
    if (rowHighlight && rowHighlight.id === row.id) {
      rowHighlight = null;
    } else {
      rowHighlight = row;
    }
    // console.log({
    //   rowHighlight: rowHighlight ? rowHighlight.id : null,
    //   rowClicked: rowClicked ? rowClicked.id : null,
    // });
    this.setState({ rowHighlight, rowClicked });
    this.props.onRowClicked(rowHighlight);
  };
  handleSearchFilterChanged = filter => {
    console.log('handleSearchFilterChanged', { filter });
    let key = null;
    if (filter) key = filter.key;
    this.setState({ filter, key });
  };
  updateCurrentRows = () => {
    let { rows, paginationMode } = this.props;
    let { page, limit, order, orderBy } = this.state;
    const headers = getHeaders(this.props, this.state);
    const final_rows = getFinalRows(rows, this.state, this.props);
    let total_limit = page * limit + limit;
    let start_limit = page * limit;
    if (paginationMode === 'server') {
      start_limit = 0;
      total_limit = limit;
    }
    this.currentRows = stableSort(
      final_rows,
      getSorting(order, orderBy, headers)
    ).slice(start_limit, total_limit);
    return {
      headers: headers ? headers : [],
      currentRows: this.currentRows ? this.currentRows : [],
    };
  };
  render() {
    const { classes, rows, checkboxSelection, rowsPerPageOptions } = this.props;
    const { currentRows, headers } = this.updateCurrentRows();
    const { rows_edited } = this.state;
    let { rowCount } = this.props;
    if (rowCount === undefined) {
      rowCount = this.props.rows.length;
    }
    if (this.props.rows.length !== currentRows.length) {
      rowCount = currentRows.length;
    }
    // console.log({
    //   rowCount,
    //   numSelected: this.state.selected.length,
    //   dense: this.props.dense,
    // });
    return (
      <TableContainer component={Paper}>
        <Table
          aria-label="collapsible table"
          className={clsx({
            [classes.table]: true,
            [classes.root]: this.state.shiftPress,
          })}
          id={`table-collapsible-${uuid()}`}
          size={this.props.dense ? 'small' : 'medium'}
          stickyHeader>
          <TableGridHeader
            checkboxSelection={checkboxSelection}
            headers={headers}
            numSelected={this.state.selected.length}
            onSearchFilterChanged={this.handleSearchFilterChanged}
            rowCount={rowCount}
          />
          <TableBody>
            {currentRows.map((row, index) => {
              const idModify = rows_edited.map(x => x.id).indexOf(row.id);
              let row_original = null;
              if (idModify !== -1) {
                const idOriginal = rows.map(x => x.id).indexOf(row.id);
                if (idOriginal !== -1) {
                  row_original = { ...rows[idOriginal] };
                }
              }
              let isItemChecked = this.handleIsItemSelected(row);
              let isRowSelected = this.handleIsRowSelected(row);
              // console.log({ isItemChecked, isRowSelected });
              return (
                <TableGridRow
                  checkboxSelection={checkboxSelection}
                  handleRowClicked={this.handleRowClicked}
                  headers={headers}
                  isItemChecked={isItemChecked}
                  isRowClicked={() => this.IsRowClicked(row)}
                  isRowSelected={isRowSelected}
                  key={`table-grid-row-${index}`}
                  onCheckBoxItemClicked={this.handleOnCheckBoxItemClicked}
                  onRowEdited={this.handleRowEdited}
                  row={row}
                  row_original={row_original}
                />
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={rowCount}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={this.state.page}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageOptions}
          style={{ width: '100%' }}
        />
      </TableContainer>
    );
  }
}

TableGridCollapsible.propTypes = {
  classes: PropTypes.object,
};
TableGridCollapsible.defaultProps = {
  rowCount: undefined,
  headers: [],
  external_selected: null,
  rows: [],
  checkboxSelection: false,
  expandTable: true,
  dense: false,
  loading: false,
  rowsPerPageOptions: rowsPerPageSmall,
  paginationMode: 'client',
  onSelectedChanged: () => '',
  onRowClicked: () => '',
  onRowEditedChanged: () => '',
};

export default withStyles(TableGridCollapsible, useStyles);
