import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import validate from 'validate.js';

import { ErrorsList, ServerErrors, GetData, PaperComponent } from 'helpers';
import { MutationCreateVariableType } from 'graphql/Mapping/utils_mapping';
import { schema, useStyles } from './Styles';
import { ButtonLoad } from 'components';
const default_state = {
  errors: [],
  type: {
    name: '',
    description: '',
  },
  touched: {},
  loading: false,
};
class DialogCreateVariableType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...default_state,
    };
  }
  componentDidUpdate() {
    const { page_errors } = this.props;
    const { errors } = this.state;

    if (page_errors && page_errors.length) {
      const { message } = page_errors[0];
      this.setState({
        errors: {
          ...errors,
          name: [message],
        },
      });
    }
  }
  handleClose = () => {
    this.setState({ ...default_state });
    this.props.handleClose(false);
  };
  handleChange = event => {
    const { type, touched, errors } = this.state;
    this.setState({
      type: {
        ...type,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...touched,
        [event.target.name]: event.target.value ? true : false,
      },
      errors: {
        ...errors,
        [event.target.name]: null,
      },
    });
  };
  hasError = field => {
    const { errors } = this.state;
    const result = errors[field] ? true : false;
    // console.log(errors, field, result);
    return result;
  };
  handleCreateVariableType = () => {
    const { loading } = this.state;
    if (loading) return;
    const validation = validate(this.state.type, schema);
    console.log('type: ', validation);
    if (validation) {
      this.setState({ errors: validation });
      return;
    }
    this.setState({ loading: true });
    (async () => {
      MutationCreateVariableType(this.state.type)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, type } = data.addVariableType;
          if (ok) {
            this.setState({ ...default_state });
            this.props.handleCreated(type);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('addVariableType-catch-error', errors);
          this.setState({
            loading: false,
            errors: { name: ServerErrors(errors) },
          });
        });
    })();
  };
  handleAddCenter = selected_center => {
    this.setState({ selected_center });
  };
  render() {
    let { classes, open } = this.props;
    let { errors, loading, type } = this.state;
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={this.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Create Variable Type
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Enter the necessary information for the variable
            </DialogContentText>
            <TextField
              autoFocus
              className={classes.textField}
              error={this.hasError('name')}
              fullWidth
              helperText={
                this.hasError('name') ? ErrorsList(errors, 'name') : null
              }
              id="name"
              InputLabelProps={{
                shrink: true,
              }}
              label="Name"
              margin="dense"
              name="name"
              onChange={this.handleChange}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter') {
                  this.handleCreateVariableType();
                }
              }}
              placeholder="Name for the variable type"
              type="name"
              value={type.name}
            />
            <TextField
              autoFocus
              className={classes.textField}
              error={this.hasError('description')}
              fullWidth
              helperText={
                this.hasError('description')
                  ? ErrorsList(errors, 'description')
                  : null
              }
              id="name"
              InputLabelProps={{
                shrink: true,
              }}
              label="Description"
              margin="dense"
              name="description"
              onChange={this.handleChange}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter') {
                  this.handleCreateVariableType();
                }
              }}
              placeholder="Enter the necessary information for the description for the variable type"
              type="description"
              value={type.description}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <ButtonLoad
              className={classes.button}
              disable_button={type.name === ''}
              handleClick={this.handleCreateVariableType}
              loading={loading}
              name="Create"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogCreateVariableType.propTypes = {
  className: PropTypes.string,
};
DialogCreateVariableType.defaultProps = {
  handleClose: () => console.log('handleClose'),
  handleCreated: () => '',
};
export default withStyles(DialogCreateVariableType, useStyles);
