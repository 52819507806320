import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { withStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  // cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: grey[600],
    backgroundColor: grey[50],
    titleFontColor: grey[600],
    bodyFontColor: grey[600],
    footerFontColor: grey[600],
  },
};

const useStyles = () => ({
  chartContainerInvSide: {
    position: 'relative',
    height: '300px',
  },
});

class Center extends Component {
  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.chartContainerInvSide}>
        <Doughnut data={data} options={options} />
      </div>
    );
  }
}

Center.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Center, useStyles);
