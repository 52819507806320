import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTableToolbar } from 'components/Tables/components';
import { rowsPerPageBig } from 'helpers';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { TableGrid } from 'components';
import { columns_landing } from '../../../utils_common';
class TableLandingObjectRunning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // selected: [],
    };
  }
  render() {
    const { classes, landing_objects, height, selected } = this.props;
    // let count = landing_objects.length;
    // if (total) count = total;
    // let style = {};
    // if (height) {
    //   style = { height, maxHeight: height };
    // }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={() => this.props.setState({ selected: [] })}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={this.props.loading}
          numSelected={selected.length}
          title="Running Landing Objects"
        />
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.props.selected}
          grid
          headers={columns_landing}
          height={height}
          idRow="idLanding"
          loading={this.props.loading}
          onPageChange={page => this.props.handleChangePage(page)}
          onRowsPerPageChange={limit =>
            this.props.handleChangeRowsPerPage(limit)
          }
          onSelectedChanged={selected => this.props.setState({ selected })}
          page={this.props.page}
          row_height={33}
          rows={landing_objects}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableLandingObjectRunning.propTypes = {
  classes: PropTypes.object,
  disable_splice: PropTypes.bool,
};
TableLandingObjectRunning.defaultProps = {
  landing_objects: [],
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  page: 0,
  limit: 50,
  disable_splice: false,
};
export default withStyles(TableLandingObjectRunning, useStyles);
