import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  root: {},
  cell_space: {
    '&.MuiTableCell-paddingCheckbox': {
      width: '24px',
      marging: 0,
      padding: 0,
    },
  },
  cell_check: {
    '&.MuiTableCell-paddingCheckbox': {
      width: '24px',
      marging: 0,
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
});

class StydyHeadRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, Studies, numSelected } = this.props;
    const rowCount = Studies.length;
    return (
      <TableRow>
        <TableCell className={classes.cell_space} padding="checkbox" />
        <TableCell className={classes.cell_check} padding="checkbox">
          <Checkbox
            checked={rowCount ? numSelected === rowCount : false}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell style={{ minWidth: 180 }}>Patient Name</TableCell>
        <TableCell>#Series</TableCell>
        <TableCell>#Files</TableCell>
        <TableCell>PatientID</TableCell>
        <TableCell>Descriptions</TableCell>
        <TableCell style={{ minWidth: 150 }}>Dates</TableCell>
        <TableCell style={{ minWidth: 100 }}>Times</TableCell>
        <TableCell style={{ minWidth: 100 }}>AccessionNumber</TableCell>
        <TableCell align="left">UIDs</TableCell>
      </TableRow>
    );
  }
}

StydyHeadRow.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(StydyHeadRow, useStyles);
