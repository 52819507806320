import React from 'react';
import PropTypes from 'prop-types';

import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
});

class TreeSitesItem extends React.Component {
  constructor(props) {
    super(props);

    const folders = props.folders;
    const labelText = folders && folders.length ? folders[0] : '';
    this.state = {
      labelText: labelText,
      index_item: props.index,
    };
  }

  componentDidUpdate() {
    //
    // const { folders } = this.props;
    // if (!folders) {
    //   return;
    // }
  }

  render() {
    const {
      classes,
      labelInfo,
      color,
      bgColor,
      folders,
      index,
      ...other
    } = this.props;
    if (!classes) {
      return null;
    }
    let folder_name = null;
    if (index < folders.length) {
      folder_name = folders[index];
    }
    const next_index = index + 1;
    return (
      folder_name !== '' && (
        <TreeItem
          classes={{
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            group: classes.group,
            label: classes.label,
          }}
          key={`folder-tree-item-${index}`}
          label={
            <div
              className={classes.labelRoot}
              key={`folder-tree-item-label-${index}`}>
              <FolderOpenIcon
                className={classes.labelIcon}
                color="inherit"
                key={`folder-tree-item-label-icon-${index}`}
              />
              <Typography
                className={classes.labelText}
                key={`folder-tree-item-typography-1-${index}`}
                variant="body2">
                {folder_name}
              </Typography>
              <Typography
                color="inherit"
                key={`folder-tree-item-typography-2-${index}`}
                variant="caption">
                {labelInfo}
              </Typography>
            </div>
          }
          style={{
            '--tree-view-color': color,
            '--tree-view-bg-color': bgColor,
          }}
          {...other}>
          {next_index < folders.length ? (
            <TreeSitesItem
              classes={classes}
              folders={folders}
              index={next_index}
              itemId={next_index.toString()}
              nodeId={next_index.toString()}
            />
          ) : null}
        </TreeItem>
      )
    );
  }
}

TreeSitesItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
};

export default withStyles(TreeSitesItem, useStyles);
