import axios from 'axios';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
// import { REGISTER_ERROR, REGISTER_SUCCESS } from './types';
const REGISTER = gql`
  mutation request($RequestUser: RequestUser!) {
    createRequest(RequestUser: $RequestUser) {
      ok
      warning
      errors {
        path
        message
      }
    }
  }
`;

export const RunRequest = RequestUser => {
  //
  //
  //
  //
  //   return;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(REGISTER),
    variables: {
      RequestUser,
    },
  });
};

const MUTATION_REGISTER_USER = gql`
  mutation($RequestUser: RequestUser!) {
    registerUser(RequestUser: $RequestUser) {
      ok
      warning
      errors {
        path
        message
      }
      user {
        id
        FullName
        firstName
        lastName
        email
        avatar
        createdAt
        phone
        username
        address {
          city
          country
        }
      }
    }
  }
`;

export const MutationRegisterUser = RequestUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REGISTER_USER),
    variables: {
      RequestUser,
    },
  });
};
