import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 5,
    type: 'number',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    type: 'name_only',
    minWidth: 50,
    format: value => {
      if (value === undefined || !value || value === '')
        return 'Name is no defined';
      return value;
    },
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Type is Missing';
      const { name } = value;
      if (name) return name;
      return 'Type is Missing';
    },
  },
  {
    id: 'file',
    label: 'File Object',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'File is Missing';
      const { original_name } = value;
      if (original_name) return original_name;
      return 'File is Missing';
    },
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
