import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogAccountDockers,
  ToolBarAccountDocker,
  columns_account_dockers,
} from './components';
import { TableGrid } from 'components';
import { rowsPerPageBig, IsInvalid } from 'helpers';
import { QueryAccountDockers } from 'graphql/Dockers/utils_docker_accounts';
import { GetData, ServerErrorsString } from 'helpers';

class TabAccountDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      account_dockers: [],
      new_accounts: [],
      saving: false,
      idUser: -1,
      open_dialog: false,
      selected: [],
      deleting: false,
    };
  }
  componentDidMount() {
    this.loadAccountDockers();
  }
  componentDidUpdate() {
    this.loadAccountDockers();
  }
  loadAccountDockers = (force, newPage, newLimit) => {
    if (!this.props.isActive) return;
    const { user } = this.props;
    if (IsInvalid(user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryAccountDockers(-1, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, account_dockers, total, errors } = data.accountDockers;
          if (ok) {
            this.setState({
              idUser: user.id,
              loading: false,
              account_dockers,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getId = new_account => {
    if (IsInvalid(new_account)) return null;
    return `${new_account.idAccountType}-${new_account.idDockerImage}`;
  };
  handleAddImage = (account_type, docker_image) => {
    const { new_accounts, account_dockers } = this.state;
    const list = new_accounts.concat(account_dockers);
    const new_account = {
      id: list.length * -1,
      idAccountType: account_type.id,
      idDockerImage: docker_image.id,
      account_type,
      docker_image,
    };
    const index = list.map(x => this.getId(x)).indexOf(this.getId(new_account));
    if (index !== -1) {
      this.props.handleAddError(
        `The type: ${account_type.description} and docker: ${docker_image.operation} are already here`
      );
    } else {
      new_accounts.unshift(new_account);
      this.setState({ new_accounts, open_dialog: false });
    }
  };
  render() {
    const { account_dockers, loading, new_accounts } = this.state;
    return (
      <React.Fragment>
        <ToolBarAccountDocker
          getId={this.getId}
          handleAddError={this.props.handleAddError}
          loading={loading}
          new_accounts={new_accounts}
          onOpenDialog={() => this.setState({ open_dialog: true })}
          onReloadTable={() => this.loadAccountDockers(true)}
          saving={this.state.saving}
          setState={value => this.setState({ ...value })}
          state={this.state}
          user={this.props.user}
        />
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_account_dockers}
          height={this.props.height - 160}
          loading={loading}
          onPageChange={page =>
            this.loadAccountDockers(true, page, this.state.limit)
          }
          onRowsPerPageChange={limit => this.loadAccountDockers(true, 0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={new_accounts.concat(account_dockers)}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogAccountDockers
          handleAddError={this.props.handleAddError}
          handleAddImage={this.handleAddImage}
          handleClose={() => this.setState({ open_dialog: false })}
          isActive={this.props.isActive}
          open={this.state.open_dialog}
        />
      </React.Fragment>
    );
  }
}

TabAccountDockers.propTypes = {
  classes: PropTypes.object,
};

export default TabAccountDockers;
