import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { CreateProjectTeamBottom, useStyles } from './components';
import {
  SearchCenterTeamsInputs,
  SearchProjectCentersInputs,
  CardEditHeader,
} from 'components';

class CreateProjectTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: null,
      team: null,
      page: 0,
      limit: 5,
    };
    this.holder = {
      getCenter: () => this.state.center,
      getTeam: () => this.state.team,
      setDisableAll: disable_all => {
        this.setState({ disable_all });
      },
      resetCenter: () => {},
    };
  }

  handleAddCenter = center => {
    this.setState({ center });
  };
  handleAddTeam = team => {
    this.setState({ team });
  };

  render() {
    const { classes, project } = this.props;
    let { center, team } = this.state;
    let idCenter = -1;
    if (center) {
      idCenter = center.id;
    }
    return (
      <Card className={classes.card}>
        <CardEditHeader
          handleClose={this.props.handleClose}
          title="New Team Project"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SearchProjectCentersInputs
                handleAddCenter={this.handleAddCenter}
                handleAddError={this.props.handleAddError}
                idProject={project.id}
                no_icon
              />
            </Grid>
            <Grid item xs={6}>
              <SearchCenterTeamsInputs
                handleAddError={this.props.handleAddError}
                handleAddTeam={this.handleAddTeam}
                idCenter={idCenter}
                limit={5}
                no_icon
                page={0}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions disableSpacing>
          <CreateProjectTeamBottom
            center={center}
            changed={team && center}
            handleAddError={this.props.handleAddError}
            handleAddNewProjectTeams={this.props.handleAddNewProjectTeams}
            holder={this.holder}
            project={project}
            project_teams={this.props.project_teams}
          />
        </CardActions>
      </Card>
    );
  }
}

CreateProjectTeam.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func,
  handleAddNewProjectTeams: PropTypes.func,
  handleClose: PropTypes.func,
  project: PropTypes.object,
};
CreateProjectTeam.propTypes = {
  handleAddError: () => '',
  handleAddNewProjectTeams: () => '',
  handleClose: () => '',
};
export default withStyles(CreateProjectTeam, useStyles);
