import React from 'react';
import {
  AccountTree as AccountTreeIcon,
  Chat as ChatIcon,
  Dashboard as DashboardIcon,
  Equalizer as EqualizerIcon,
  Image as ImageIcon,
  People as PeopleIcon,
  PersonOutline as PersonOutlineIcon,
  SaveAlt as SaveAltIcon,
  Search as SearchIcon,
  Security as SecurityIcon,
  Storage as StorageIcon,
  Monitor as MonitorIcon,
} from '@mui/icons-material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { IsEditAllow } from 'helpers/Designers';
import { IsAdministrator } from 'helpers';
import { IsNormalUser, IsUserClinicalTrialUpload } from 'common';
import { IsUserPipeline } from 'helpers';
export const getPages = (user, authorized_clinics) => {
  if (user.idCategory === 2) {
    return getDesigner(user);
  } else if (user.idCategory === 3) {
    return getSurvey(user);
  } else {
    return getDefault(user, authorized_clinics);
  }
};
const getSurvey = user => {
  let menu_account = ['Profile', 'Settings', 'Activity', 'Logs'];
  const pages = [
    {
      //0
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      //8
      title: 'divider',
    },
    {
      //1
      title: 'Account',
      href: '/account',
      icon: <PersonOutlineIcon />,
      menu: menu_account,
    },
    {
      //8
      title: 'divider',
    },
    {
      //9
      title: 'Communications',
      href: '/communications',
      icon: <ChatIcon />,
    },
  ];
  if (IsEditAllow(user)) {
    pages.push(
      {
        //8
        title: 'divider',
      },
      {
        //1
        title: 'Administrator',
        href: '/administrator',
        icon: <SecurityIcon />,
        menu: ['Users', 'Logs', 'Errors', 'Settings'],
      }
    );
  }
  return pages;
};
const getDesigner = user => {
  let menu_account = ['Profile', 'Settings', 'Activity', 'Logs'];
  const pages = [
    {
      //0
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
  ];
  if (IsEditAllow(user)) {
    pages.push({
      //5
      title: 'Explorer',
      href: '/explorer',
      icon: <StorageIcon />,
      menu: [],
    });
  }
  pages.push(
    {
      //8
      title: 'divider',
    },
    {
      //1
      title: 'Account',
      href: '/account',
      icon: <PersonOutlineIcon />,
      menu: menu_account,
    }
  );
  if (IsEditAllow(user)) {
    pages.push(
      {
        //8
        title: 'divider',
      },
      {
        //11
        title: 'Upload/Download',
        href: '/upload_download',
        icon: <SaveAltIcon />,
        menu: ['Upload', 'Download'],
      },
      {
        //4
        title: 'Project Management',
        href: '/project_management',
        icon: <AccountTreeIcon />,
        menu: ['Browser', 'Overview', 'Files', 'Activity', 'Teams'],
      },
      {
        //8
        title: 'divider',
      },
      {
        //1
        title: 'Administrator',
        href: '/administrator',
        icon: <SecurityIcon />,
        menu: ['Users', 'Logs', 'Errors', 'Settings'],
      }
    );
  }
  return pages;
};
const getDefault = (user, authorized_clinics) => {
  let menu_management = [
    'Patients',
    'Elements',
    'Teams',
    'Projects',
    'Registration Requests',
    'Units',
    'Logs',
  ];
  let menu_account = ['Profile', 'Settings', 'Activity', 'Logs'];
  if (user.type === 0) {
    menu_management = ['Units', 'Logs'];
    // menu_account = ['Profile', 'Settings'];
  }
  const pages = [
    {
      //0
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
  ];
  pages.push(
    {
      //7
      title: 'Query/Retrieve',
      href: '/query_retrieve',
      icon: <SearchIcon />,
      menu: [],
    },
    {
      //5
      title: 'Explorer',
      href: '/explorer',
      icon: <StorageIcon />,
      menu: [],
    },
    {
      //8
      title: 'divider',
    }
  );
  if (IsUserPipeline(user)) {
    let sub_menu = ['Input Data', 'Batch Process'];
    if (IsAdministrator(user)) {
      sub_menu.push('Anonymization');
      sub_menu.push('Analysis');
    }
    pages.push({
      //6
      title: 'Pipelines',
      href: '/pipelines',
      icon: <InsertChartIcon />,
      menu: sub_menu,
    });
  }
  if (IsAdministrator(user)) {
    pages.push(
      {
        //8
        title: 'divider',
      },
      {
        //6
        title: 'Signals Data',
        href: '/signals_data',
        icon: <LocalLibraryIcon />,
        menu: [],
      }
    );
  }
  if (IsAdministrator(user)) {
    pages.push({
      //6
      title: 'Clinical Data',
      href: '/clinical_data',
      icon: <LocationCityIcon />,
      menu: ['Clinical Patients', 'MS', 'Stroke', 'Epilepsy', 'Sleep'],
    });
    // console.log('is admin');
    authorized_clinics = [];
  }
  if (IsNormalUser(user) && authorized_clinics.length > 0) {
    let submenu = [];
    submenu = authorized_clinics.map(x => x.description);
    pages.push({
      //6
      title: 'Clinical Data',
      href: '/clinical_data',
      icon: <LocationCityIcon />,
      menu: submenu,
    });
  }
  pages.push({
    //6
    title: 'Image Data',
    href: '/image_analysis',
    icon: <ImageIcon />,
    menu: [],
  });
  if (HTTP_ADDRESS_GRAPHQL.includes('localhost')) {
    pages.push(
      {
        title: 'divider',
      },
      {
        //6
        title: 'Dicom Viewer',
        href: '/dicom_viewer',
        icon: <MonitorIcon />,
        menu: [],
      }
    );
  }
  pages.push({
    title: 'divider',
  });
  if (IsUserClinicalTrialUpload(user)) {
    pages.push(
      {
        //11
        title: 'Upload',
        href: '/upload',
        icon: <FileUploadIcon />,
        menu: [],
      },
      {
        title: 'divider',
      }
    );
  } else if (IsNormalUser(user)) {
    pages.push(
      {
        //11
        title: 'Upload/Download',
        href: '/upload_download',
        icon: <SaveAltIcon />,
        menu: ['Upload', 'Download'],
      },
      {
        title: 'divider',
      },
      {
        //9
        title: 'Communications',
        href: '/communications',
        icon: <ChatIcon />,
      }
    );
  }
  pages.push(
    {
      //4
      title: 'Project Management',
      href: '/project_management',
      icon: <AccountTreeIcon />,
      menu: ['Browser', 'Overview', 'Files', 'Activity', 'Teams'],
    },
    {
      //1
      title: 'Account',
      href: '/account',
      icon: <PersonOutlineIcon />,
      menu: menu_account,
    }
  );

  if (user.type !== 0) {
    if (pages.map(x => x.href).indexOf('/upload') === -1) {
      pages.push({
        //8
        title: 'divider',
      });
    }
  }
  if (user.type === 1) {
    pages.push(
      {
        //8
        title: 'divider',
      },
      {
        //2
        title: 'Management',
        href: '/management',
        icon: <EqualizerIcon />,
        menu: menu_management,
      },
      {
        //3
        title: 'Collaborators',
        href: '/collaborators',
        icon: <PeopleIcon />,
      }
    );
  }
  if (IsAdministrator(user)) {
    pages.push({
      //1
      title: 'Administrator',
      href: '/administrator',
      icon: <SecurityIcon />,
      menu: ['Users', 'Uploads', 'Activities', 'Orphans', 'Logs', 'Errors'],
    });
  }
  return pages;
};
