import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { TableLogErrors } from 'components';
import { MutationDeleteLogsUsers, QueryLogsUser } from 'graphql/Logs';
import { GetData, ServerErrorsString } from 'helpers';
const useStyles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
});

class LogsUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      loading: false,
      deleting: false,
      init: false,
      page: 0,
      limit: 25,
      total: 0,
    };
    this.holder = {
      getSelected: null,
      clearSelected: null,
    };
  }

  componentDidMount() {
    this.updateLogs();
  }

  componentDidUpdate() {
    if (!this.state.init) {
      this.updateLogs();
    }
  }

  updateLogs = () => {
    const { loading, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryLogsUser(page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            LogsUser: { ok, errors, logs, total },
          } = data;
          if (ok) {
            this.setState({ loading: false, logs, total, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage, init: false });
  };

  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value, init: false });
  };
  handleDeleteSelected = () => {
    const selected = this.holder.getSelected();
    const { deleting, logs } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteLogsUsers(ids)
        .then(res => {
          const data = GetData(res);
          const {
            DeleteLogsUser: { ok, errors },
          } = data;
          if (ok) {
            this.holder.clearSelected();

            this.setState({
              deleting: false,
              logs: logs.filter(x => ids.indexOf(x.id) === -1),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, height } = this.props;
    const { logs } = this.state;
    return (
      <div className={classes.root}>
        <TableLogErrors
          deleting={this.state.deleting}
          // dense
          expand_disable
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.updateLogs}
          height={height}
          holder={this.holder}
          limit={this.state.limit}
          loading={this.state.loading}
          nowrap
          page={this.state.page}
          rows={logs}
          title="User Logs"
          total={this.state.total}
        />
      </div>
    );
  }
}

LogsUser.propTypes = {
  classes: PropTypes.object,
};
LogsUser.defaultProps = {
  handleAddError: () => '',
};

export default withStyles(LogsUser, useStyles);
