import { QueryFileMetadata } from 'graphql/Files';
import { GetData } from 'helpers';
import { IsInvalid } from 'helpers';

export const getInputs = (selected_content, metadata) => {
  let inputs = [];
  if (!metadata) return inputs;
  for (let i = 0; i < selected_content.length; i++) {
    const { File } = selected_content[i];
    if (IsInvalid(File)) continue;
    const { idFileObject } = File;
    if (!idFileObject || idFileObject === undefined) continue;
    inputs.push({ idFileObject });
    if (metadata.notes && metadata.notes !== '')
      inputs[i].notes = metadata.notes;
    if (metadata.comments && metadata.comments !== '')
      inputs[i].comments = metadata.comments;
    if (metadata.information && metadata.information !== '')
      inputs[i].information = metadata.information;
    if (metadata.creationTime && metadata.creationTime !== '')
      inputs[i].creationTime = metadata.creationTime;
    if (metadata.group_mappings && metadata.group_mappings.length) {
      inputs[i].mappings = metadata.group_mappings.map(x => {
        const { id, idGroup, name } = x;
        return {
          id,
          idGroup,
          name,
        };
      });
    }
    if (metadata.name && metadata.name !== '') inputs[i].name = metadata.name;
  }
  return inputs;
};

export const updateMetadata = async selected_content => {
  let inputs = [];
  let notes = [];
  let comments = [];
  let information = [];
  let creationTime = [];
  let name = [];
  let group_mappings = [];
  for (let i = 0; i < selected_content.length; i++) {
    const { File } = selected_content[i];
    if (IsInvalid(File)) continue;
    console.log({ f: File });
    let { idFileObject, metadata } = File;
    if (!metadata || metadata === undefined) {
      try {
        const res = await QueryFileMetadata(idFileObject);
        const data = GetData(res);
        const { ok } = data.fileMetadata;
        if (ok) {
          metadata = data.fileMetadata.metadata;
        }
      } catch (error) {
        console.log({ error });
      }
      if (!metadata || metadata === undefined) continue;
    }
    if (!idFileObject || idFileObject === undefined) continue;
    inputs.push({ idFileObject });
    let index = notes.indexOf(metadata.notes);
    if (index === -1) notes.push(metadata.notes);
    index = comments.indexOf(metadata.comments);
    if (index === -1) comments.push(metadata.comments);
    index = information.indexOf(metadata.information);
    if (index === -1) information.push(metadata.information);
    index = creationTime.indexOf(metadata.creationTime);
    if (index === -1) creationTime.push(metadata.creationTime);
    index = name.indexOf(metadata.name);
    if (index === -1) name.push(metadata.name);
    if (metadata.group_mappings) {
      const groups = metadata.group_mappings.map(x => {
        const { id, idGroup, name, group } = x;
        return {
          id,
          idGroup,
          name,
          group,
        };
      });
      for (let i = 0; i < groups.length; i++) {
        const { id } = groups[i];
        index = group_mappings.map(x => x.id).indexOf(id);
        if (index === -1) group_mappings.push(groups[i]);
      }
    }
  }
  let multi_metadata = {};
  if (notes.length === 1) multi_metadata.notes = notes[0];
  if (comments.length === 1) multi_metadata.comments = comments[0];
  if (information.length === 1) multi_metadata.information = information[0];
  if (creationTime.length === 1) multi_metadata.creationTime = creationTime[0];
  if (name.length === 1) multi_metadata.name = name[0];
  multi_metadata.group_mappings = group_mappings;
  console.log({
    multi_metadata,
  });
  return multi_metadata;
};
