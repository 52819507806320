import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import { TabFileVariableMap, TabFileLinks } from './components';
import ToolBarMapping from '../ToolBarMapping';
import { TabPanel } from 'components';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: { textTransform: 'none' },
  };
}
class FilesVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      selected: [],
    };
    this.holder = {
      setCleanLink: null,
      setCleanMap: null,
      deleteLinks: null,
      deleteMaps: null,
    };
  }
  handleOpenEdit = () => {};
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > 3) {
      newValue = 0;
    }
    this.setState({ tabValue: newValue, selected: [] });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
    this.holder.setCleanLink();
    this.holder.setCleanMap();
  };
  handleDeleteSelected = () => {
    const { tabValue } = this.state;
    if (tabValue) {
      this.holder.deleteLinks();
    } else {
      this.holder.deleteMaps();
    }
  };
  render() {
    const { tabValue } = this.state;
    let height = this.props.height;
    if (height) height = height - 20;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ToolBarMapping
            disabled={this.state.deleting}
            handleAddError={this.props.handleAddError}
            handleClearSelected={this.handleClearSelected}
            handleDeleteSelected={this.handleDeleteSelected}
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenEdit={this.handleOpenEdit}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                aria-label="basic tabs example"
                onChange={this.handleTabValue}
                value={tabValue}>
                <Tab label="File variable Mapping" {...a11yProps(0)} />
                <Tab label="File object mapping" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel index={0} value={tabValue}>
              <TabFileVariableMap
                handleAddError={this.props.handleAddError}
                height={height}
                holder={this.holder}
                setState={state => this.setState(state)}
              />
            </TabPanel>
            <TabPanel index={1} value={tabValue}>
              <TabFileLinks
                handleAddError={this.props.handleAddError}
                height={height}
                holder={this.holder}
                setState={state => this.setState(state)}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

FilesVariableTypes.propTypes = {
  classes: PropTypes.object,
};
FilesVariableTypes.defaultProps = {
  handleAddError: () => '',
};
export default FilesVariableTypes;
