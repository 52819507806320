import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

class MainTableRowEmpty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { colSpan, dense, emptyRows } = this.props;
    if (emptyRows <= 0) {
      return null;
    }
    return (
      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
        <TableCell colSpan={colSpan} />
      </TableRow>
    );
  }
}

MainTableRowEmpty.propTypes = {
  classes: PropTypes.object,
  colSpan: PropTypes.number,
  dense: PropTypes.bool,
  emptyRows: PropTypes.number,
};
MainTableRowEmpty.defaultProps = {
  dense: false,
  colSpan: 5,
  emptyRows: 0,
};

export default MainTableRowEmpty;
