import React from 'react';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MainPageStyles } from '../../../utils';

import {
  DialogCreateTeam,
  TableUserTeams,
  TeamsToolBar,
  TeamView,
} from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { QuerySummaryTeams } from 'graphql/Teams';

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_create_team: false,
      name: '',
      teams: [],
      loading: false,
      error: '',
    };
  }

  componentDidMount() {
    this.updateTeams();
  }

  updateTeams = name => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, error: '' });
    (async () => {
      QuerySummaryTeams(name)
        .then(res => {
          const data = GetData(res);
          const { allTeams } = data;
          let teams = [];
          if (typeof allTeams !== 'undefined') {
            const { ok, errors } = allTeams;
            if (ok) {
              teams = allTeams.teams;
            } else {
              throw errors;
            }
          }
          this.setState({ teams, loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleOpenCreateTeam = () => {
    this.setState({ open_create_team: true });
  };
  handleCloseCreateTeam = () => {
    this.setState({ open_create_team: false, error: '' });
  };
  handleTeamCreated = team => {
    let { teams } = this.state;
    const { id } = team;

    // this.props.addNewTeam(name);
    const index = teams.map(x => x.id).indexOf(id);
    if (index === -1) {
      teams.unshift(team);
    }
    this.setState({ teams, open_create_team: false });
  };
  handleTeamSearch = event => {
    this.setState({ name: event.target.value });
    //
    this.updateTeams(event.target.value);
  };
  handleOpenTeamView = (team, update) => {
    const { history } = this.props;
    if (team) {
      history.push(`/management/teams/${team.id}`);
    } else {
      if (update) {
        this.setState({
          open_create_team: false,
        });
      }
      history.push('/management/teams');
    }
  };
  handleDeleteTeams = ids => {
    let { teams } = this.state;
    teams = teams.filter(x => ids.indexOf(x.id) === -1);
    this.setState({ teams });
  };
  handleTeamModified = team => {
    let { teams } = this.state;
    const index = teams.map(x => x.id).indexOf(team.id);
    if (index === -1) return;
    teams[index] = team;
    this.setState({ teams });
  };
  render() {
    if (!this.props.isActive) return null;
    const { teamId, height } = this.props;
    const { open_create_team, loading, teams } = this.state;
    let selected = [];
    let idTeam = null;
    if (teamId) {
      idTeam = parseInt(teamId, 10);
    }
    if (idTeam) {
      return (
        <div style={{ padding: 6 }}>
          <TeamView
            enable_edit
            handleOpenTeamView={this.handleOpenTeamView}
            handleTeamModified={this.handleTeamModified}
            height={height - 125}
            selected={selected}
            teamId={idTeam}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <TeamsToolBar
          handleOpenCreateTeam={this.handleOpenCreateTeam}
          handleTeamSearch={this.handleTeamSearch}
        />
        <div
          style={{
            margin: 10,
            height: height - 315,
            overflow: 'auto',
          }}>
          <TableUserTeams
            handleDeleteSelected={this.handleDeleteTeams}
            handleReloadTable={() => this.updateTeams()}
            handleShowTeam={this.handleOpenTeamView}
            height={height - 430}
            loading={loading}
            teams={teams}
          />
          {/* </div> */}
        </div>
        <DialogCreateTeam
          handleCloseDialog={this.handleCloseCreateTeam}
          handleTeamCreated={this.handleTeamCreated}
          open={open_create_team}
        />
      </React.Fragment>
    );
  }
}

Teams.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withStyles(withRouter(Teams), MainPageStyles);
