import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const ImageSizeGQL = gql`
  query ImageSizes($page: Int, $limit: Int) {
    ImageSizes(page: $page, limit: $limit) {
      Total
      ImageSize {
        Rows
        Columns
        Size
      }
    }
  }
`;
export const ImageSizes = (page, limit) => {
  const variables = {};
  if (page && limit) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(ImageSizeGQL),
    variables: variables,
  });
};

export const ImageSize = ImageSize => {
  if (ImageSize === undefined) {
    return '0x0';
  }
  if (ImageSize.Rows === undefined || ImageSize.Columns === undefined) {
    return '0x0';
  }
  return `${ImageSize.Rows}x${ImageSize.Columns}`;
};
