import 'date-fns';
import React from 'react';
import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';

const UnitDateTimes = ({ classes, createdAt, updatedAt }) => {
  // The first commit of Material-UI
  const [created] = React.useState(new Date(parseInt(createdAt, 10)));
  const [updated] = React.useState(new Date(parseInt(updatedAt, 10)));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        className={classes.datetime}
        container
        justifyContent="space-between">
        <DatePicker
          disabled
          disableToolbar
          format="PPpp"
          id="date-picker-inline"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          label="Created"
          margin="normal"
          renderInput={props => <TextField {...props} />}
          //   onChange={handleDateChange}
          value={created}
          variant="inline"
        />
        <DatePicker
          disabled
          format="PPpp"
          id="date-picker-dialog"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          label="Updated"
          margin="normal"
          //   onChange={handleDateChange}
          renderInput={props => <TextField {...props} />}
          value={updated}
        />
      </Grid>
    </LocalizationProvider>
  );
};
export default UnitDateTimes;
