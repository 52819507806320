class ActionViewer {
  constructor() {
    this.flagZoom = false;
    this.xZoom = 1;
    this.yZoom = 1;
    this.flagPan = false;
    this.flagContrast = false;
    this.press = false;
    this.yPan = 0;
    this.xPan = 0;
  }
  setZoom(status) {
    this.flagZoom = status;
    console.log({ status });
  }
  setPan(status) {
    console.log({ status });
    this.flagPan = status;
  }
  setContrast(status) {
    console.log({ status });
    this.flagContrast = status;
  }
  zoomIn() {
    console.log('zoomIn');
    this.xZoom *= 1.08;
    this.yZoom *= 1.08;
  }
  zoomOut() {
    console.log('zoomOut');
    this.xZoom /= 1.08;
    this.yZoom /= 1.08;
  }
  pan(deltaX, deltaY) {
    this.xPan = deltaX;
    this.yPan = deltaY;
  }
  reset() {
    this.flagZoom = false;
    this.xZoom = 1;
    this.yZoom = 1;
    this.flagPan = false;
    this.flagContrast = false;
    this.press = false;
    this.yPan = 0;
    this.xPan = 0;
  }
}
export default ActionViewer;
