/* eslint-disable indent */
import * as React from 'react';
import PropTypes from 'prop-types';
import { IsInvalid, IsValid } from 'helpers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
class FormTextFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDateChange = (newValue, field) => {
    const { form } = this.props;
    let f = JSON.parse(JSON.stringify(form));
    const date = newValue.valueOf().toString();
    for (let i = 0; i < f.length; i++) {
      const item = f[i];
      if (item.field === field) {
        f[i].value = date;
        break;
      }
    }
    this.props.handleChangeForm(f);
  };
  handleSwitchChange = (event, field) => {
    const { form } = this.props;
    let f = JSON.parse(JSON.stringify(form));
    const checked = event.target.checked;
    for (let i = 0; i < f.length; i++) {
      const item = f[i];
      if (item.field === field) {
        f[i].value = checked;
        break;
      }
    }
    this.props.handleChangeForm(f);
  };
  handleTextChange = (event, field) => {
    const { form } = this.props;
    let f = JSON.parse(JSON.stringify(form));
    const text = event.target.value.trim();
    for (let i = 0; i < f.length; i++) {
      const item = f[i];
      if (item.field === field) {
        f[i].value = text;
        break;
      }
    }
    this.props.handleChangeForm(f);
  };
  render() {
    let { form } = this.props;
    if (IsInvalid(form) || form.length === 0) return null;
    return (
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        {form.map(field => (
          <Grid item key={field.field} xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {field.type === 'date' && (
                <DatePicker
                  label={field.headerName}
                  onChange={event => this.handleDateChange(event, field.field)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: field.required,
                      placeholder: field.example,
                      fullWidth: true,
                      error: field.required && field.value === null,
                      InputLabelProps: {
                        shrink: true,
                        padding: 0,
                      },
                    },
                  }}
                  value={
                    IsValid(field.value) ? moment(parseInt(field.value)) : null
                  }
                />
              )}
              {field.type === 'datetime' && (
                <DateTimePicker
                  label={field.headerName}
                  onChange={event => this.handleDateChange(event, field.field)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      required: field.required,
                      error: field.required && field.value === null,
                      placeholder: field.example,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                        padding: 0,
                      },
                    },
                  }}
                  value={
                    IsValid(field.value) ? moment(parseInt(field.value)) : null
                  }
                />
              )}
              {field.type === 'time' && (
                <TimePicker
                  label={field.headerName}
                  onChange={event => this.handleDateChange(event, field.field)}
                  size="small"
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: field.required && field.value === null,
                      required: field.required,
                      placeholder: field.example,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                        padding: 0,
                      },
                    },
                  }}
                  value={
                    IsValid(field.value) ? moment(parseInt(field.value)) : null
                  }
                />
              )}
            </LocalizationProvider>
            {field.type === 'boolean' && (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    onChange={event =>
                      this.handleSwitchChange(event, field.field)
                    }
                  />
                }
                label={field.headerName}
                required={field.required}
                size="small"
              />
            )}
            {field.type !== 'date' &&
              field.type !== 'time' &&
              field.type !== 'datetime' &&
              field.type !== 'boolean' && (
                <TextField
                  error={field.required && field.value === ''}
                  fullWidth
                  id={field.field}
                  InputLabelProps={{
                    shrink: true,
                    padding: 0,
                  }}
                  label={field.headerName}
                  name={field.field}
                  onChange={event => this.handleTextChange(event, field.field)}
                  placeholder={field.example ? field.example : ''}
                  required={field.required}
                  size="small"
                  type={
                    field.type === 'float' || field.type === 'int'
                      ? 'number'
                      : ''
                  }
                  value={IsValid(field.value) ? field.value : ''}
                />
              )}
          </Grid>
        ))}
      </Grid>
    );
  }
}
FormTextFields.propTypes = {
  handleClose: PropTypes.func,
};
FormTextFields.defaultProps = {
  handleClose: () => '',
};

export default FormTextFields;
