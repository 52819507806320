import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { FormatValidation, GetData, ServerErrors } from 'helpers';
import { SmallRedText } from 'components';
import { MutationUpdatePassword } from 'graphql/Users';
import { connect } from 'react-redux';
import { setUpdateLogin } from 'redux-store/actions/loginActions';
import CardSetting from '../CardSetting';

const Password = props => {
  const { user, setUpdateLogin } = props;

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    current_password: '',
    password: '',
    confirm: '',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const IsInValid = () => {
    const { password, confirm, current_password } = values;
    let error = FormatValidation(password, confirm, current_password);
    if (!error) {
      error = '';
    }
    setErrors(error);
    return error === '' ? false : true;
  };
  const hadleUpdatePassword = () => {
    if (IsInValid()) return;
    setLoading(true);
    (async () => {
      const { current_password, password } = values;
      MutationUpdatePassword(user.id, current_password, password)
        .then(res => {
          const data = GetData(res);
          const { updatePassword } = data;
          if (updatePassword) {
            const { ok, token, refreshToken } = updatePassword;
            if (ok) {
              setLoading(false);
              setUpdateLogin(token, refreshToken);
            } else {
              throw updatePassword.errors;
            }
          }
        })
        .catch(error => {
          setLoading(false);
          setErrors({ current_password: ServerErrors(error) });
        });
    })();
  };
  const hasError = key => {
    return Object.keys(errors).indexOf(key) !== -1;
  };
  const getError = key => {
    if (!hasError(key)) return '';
    return errors[key].split('\n').map((error, i) => {
      return <SmallRedText key={`error-${i}`} text={error} />;
    });
  };
  const IsChanged = () => {
    const { current_password, password, confirm } = values;
    if (current_password === '') return true;
    if (password === '') return true;
    if (confirm === '') return true;
    return false;
  };
  return (
    <CardSetting
      disabled={IsChanged()}
      handleSave={hadleUpdatePassword}
      loading={loading}
      save="Update"
      subheader="Update password"
      title="Password">
      <TextField
        disabled={loading}
        error={hasError('current_password')}
        fullWidth
        helperText={getError('current_password')}
        label="Current Password"
        name="current_password"
        onChange={handleChange}
        style={{ marginBottom: 15 }}
        type="password"
        value={values.current_password}
        variant="outlined"
      />
      <TextField
        disabled={loading}
        error={hasError('password')}
        fullWidth
        helperText={getError('password')}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={values.password}
        variant="outlined"
      />
      <TextField
        disabled={loading}
        error={hasError('confirm')}
        fullWidth
        helperText={getError('confirm')}
        label="Confirm password"
        name="confirm"
        onChange={handleChange}
        style={{ marginTop: '1rem' }}
        type="password"
        value={values.confirm}
        variant="outlined"
      />
    </CardSetting>
  );
};

Password.propTypes = {
  className: PropTypes.string,
};

export default connect(null, { setUpdateLogin })(Password);
