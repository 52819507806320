import React from 'react';
import PropTypes from 'prop-types';
import { QuerySequenceErrors } from 'graphql/Logs';
import { ServerErrorsString, rowsPerPageBig, GetData } from 'helpers';
import { Grid } from '@mui/material';
import { TableGrid } from 'components';
import {
  ToolBarSequenceErrors,
  columns_sequence_error,
  style_paper,
} from './components';

class LogsSequenceErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_highlight: null,
      selected: [],
      rows_edited: [],
      sequence_errors: [],
      deleting: false,
      loading: false,
      init: false,
      page: 0,
      limit: 100,
      nowrap: false,
    };
    this.holder = {
      getSearchText: null,
      getSelected: null,
    };
  }
  componentDidMount() {
    this.updateSequenceErrors();
  }
  componentDidUpdate() {
    if (!this.state.init) this.updateSequenceErrors();
  }
  handleSequencesErrors = text => {
    console.log({ text });
    this.updateSequenceErrors(text);
  };
  updateSequenceErrors = (text = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QuerySequenceErrors(text, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, sequence_errors, total } = data.sequenceErrors;
          if (ok) {
            this.setState({
              loading: false,
              sequence_errors,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    return (
      <Grid container pl={1} spacing={1}>
        <Grid item xs={12}>
          <ToolBarSequenceErrors
            handleAddError={this.props.handleAddError}
            handleReload={text => this.updateSequenceErrors(text)}
            setState={state => this.setState(state)}
            state={this.state}
          />
        </Grid>
        <Grid item style={style_paper} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_sequence_error}
            height={this.props.height - 270}
            loading={this.state.loading}
            nowrap={this.state.nowrap}
            onHighlightChange={row_highlight =>
              this.setState({ row_highlight })
            }
            onPageChange={page =>
              this.updateSequenceErrors(null, page, this.state.limit)
            }
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowsPerPageChange={limit =>
              this.updateSequenceErrors(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.sequence_errors}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

LogsSequenceErrors.propTypes = {
  classes: PropTypes.object,
};

export default LogsSequenceErrors;
