import React from 'react';
import PropTypes from 'prop-types';
import { InputAccountCategories } from 'components';
class ProjectCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null,
      set: false,
    };
    const { holder } = props;
    if (holder) {
      holder.getCategory = () => {
        if (this.state.category) {
          return this.state.category.id;
        }
        return 0;
      };
    }
  }
  setFromProps = () => {
    const {
      project: { category },
    } = this.props;
    if (category === undefined) return;
    this.setState({ category, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    if (!this.state.set) {
      this.setFromProps();
    }
  }
  handleChange = new_category => {
    this.setState({ category: new_category });
    const {
      project: { category },
    } = this.props;
    // console.log({ category, new_category });
    if (category && new_category) {
      this.props.handleChange('category', category.id !== new_category.id);
    } else if (new_category) {
      this.props.handleChange('category', true);
    }
  };
  render() {
    const { category } = this.state;
    return (
      <div style={{ marginTop: -6, marginBottom: 2 }}>
        <InputAccountCategories
          disabled={!this.props.admin}
          onChange={this.handleChange}
          value={category}
          variant="outlined"
        />
      </div>
    );
  }
}

ProjectCategory.propTypes = {
  classes: PropTypes.object,
};

export default ProjectCategory;
