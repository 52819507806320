import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData, PaperComponent, ServerErrorsString } from 'helpers';
import { SearchUnit, TableMyUnits } from 'components';

import { QueryMySelectedUnits } from 'graphql/Units';

const useStyles = theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200
  },
  button: {
    textTransform: 'uppercase',
  },
});

class DialogSelectedUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.runUpdateSelectedUnits();
  }

  handleClose = () => {
    this.props.handleCloseDialog(false);
  };
  handleAddUnit = unit => {
    const { units } = this.state;
    const { myunit } = this.props;
    const index = units.map(x => x.idUnit).indexOf(unit.idUnit);
    if (index !== -1) {
      this.props.handleAddError(`The unit "${unit.name}" is here.`);
    } else if (unit.idUnit === myunit.idUnit) {
      this.props.handleAddError('You cannot add your own unit.');
    } else {
      units.push(unit);
      this.setState({ units });
    }
  };
  runUpdateSelectedUnits = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryMySelectedUnits()
        .then(res => {
          const data = GetData(res);
          const {
            MySelectedUnits: { ok, errors, units },
          } = data;
          if (ok) {
            this.setState({ units, loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            errors: ServerErrorsString(error),
            loading: false,
          });
        });
    })();
  };

  handleChange = () => {};

  render() {
    const { open, classes } = this.props;
    const { loading, units } = this.state;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Unit allowed to read your data summary
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new unit, please type the name of the unit. Only units that
            actived their find function.
          </DialogContentText>
          <Grid
            alignItems="center"
            className={classes.toolbar}
            container
            direction="column"
            justifyContent="space-evenly"
            spacing={1}>
            <SearchUnit handleAddUnit={this.handleAddUnit} />
            <TableMyUnits
              dense
              handleReloadTable={() => this.runUpdateSelectedUnits()}
              loading={loading}
              rows={units}
              title="My Units"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={this.handleClose}>
            Close
          </Button>
          {/* <ButtonLoad
            className={classes.button}
            // disable_button={Object.keys(touched).length ? false : true}
            // handleClick={this.handleCreate}
            loading={loading}
            name="Invite"
            variant="text"
          /> */}
        </DialogActions>
      </Dialog>
    );
  }
}

DialogSelectedUnits.propTypes = {
  classes: PropTypes.object,
};
DialogSelectedUnits.defaultProps = {
  myunit: { idUnit: -1 },
};

export default withStyles(DialogSelectedUnits, useStyles);
