import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { Grid, TextField, Typography } from '@mui/material';

import { StepContainer } from '../../index';
import { DialogChangeProjectAvatar, MenuTopDown } from 'components';
import { GetProjectTypes } from 'helpers';

import {
  projectCreateTeams,
  projectCreateType,
} from 'redux-store/actions/projectActions';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
  },
  text_center: {
    textAlign: 'center',
    align: 'center',
  },
});

class SelectProjectType extends React.Component {
  constructor(props) {
    super(props);
    const { project_type } = props;
    this.state = {
      open_types: false,
      open_avatar: false,
      project_type: project_type,
    };
    this.title = props.title;
    this.handleClose = this.handleClose.bind(this);
    this.holder = { setAvatar: null };
  }

  handleOpenTypes = () => {
    this.setState({ open_types: true });
  };
  handleClose = (type, event) => {
    const { index } = type;
    if (!event) {
      this.setState({ open_types: false });
      return;
    }
    const { project_type } = this.props;
    this.props.projectCreateType({
      ...project_type,
      type: index,
    });
    this.setState({ open_types: false });
  };
  handleOpenChangeAvatar = () => {
    this.holder.setAvatar(this.props.project_type.avatar);
    this.setState({ open_avatar: true });
  };
  handleSelect = avatar => {
    const { project_type } = this.props;
    this.props.projectCreateType({
      ...project_type,
      avatar: avatar,
    });
    this.setState({
      open_avatar: false,
    });
  };
  handleChange = event => {
    const { project_type, teams } = this.props;

    this.props.projectCreateType({
      ...project_type,
      [event.target.name]: event.target.value,
    });
    if (teams && teams.length) {
      for (let index = 0; index < teams.length; index++) {
        teams[index] = {
          ...teams[index],
          project: event.target.value,
        };
      }
      this.props.projectCreateTeams(teams);
    }
  };

  render() {
    const { open_types, open_avatar } = this.state;
    const {
      classes,
      project_type: { type, name, description, avatar },
    } = this.props;
    const project_types = GetProjectTypes(this.props);
    return (
      <StepContainer title={this.title}>
        <DialogChangeProjectAvatar
          handleCancel={() => this.setState({ open_avatar: false })}
          handleSelect={avatar => this.handleSelect(avatar)}
          holder={this.holder}
          open={open_avatar}
        />
        <Grid container direction="column">
          <Grid item xs={12}>
            <div
              className={classes.imageContainer}
              onClick={this.handleOpenChangeAvatar}
              onFocus={() => {}} // Ensure the element can receive focus
              role="button"
              tabIndex={0} // Ensure the element is focusable
            >
              <img alt="Product" className={classes.image} src={avatar} />
            </div>
            <Typography
              // alignCenter
              className={classes.text_center}
              component="p">
              Project Avatar
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              id="project_type"
              InputLabelProps={{
                shrink: true,
              }}
              label="Project Type"
              margin="dense"
              name="project_type"
              onClick={this.handleOpenTypes.bind(this)}
              placeholder="Select Type"
              required
              style={{ width: '150px' }}
              value={project_types[type]}
              variant="outlined">
              {project_types[type]}
            </TextField>
            <MenuTopDown
              handleClose={this.handleClose}
              object_parent={'project_type'}
              objects={project_types}
              open={open_types}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="name"
              InputLabelProps={{
                shrink: true,
              }}
              label="Project Name"
              margin="normal"
              name="name"
              onChange={this.handleChange}
              // style={{ margin: 8 }}
              placeholder="Add a project name"
              required
              value={name}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <TextField
          fullWidth
          // helperText="Full width!"
          id="project-description"
          InputLabelProps={{
            shrink: true,
          }}
          label="Project Description"
          margin="normal"
          name="description"
          // style={{ margin: 8 }}
          onChange={this.handleChange}
          placeholder="Add a brief description of this project"
          value={description}
          variant="outlined"
        />
      </StepContainer>
    );
  }
}

SelectProjectType.propTypes = {
  projectCreateTeams: PropTypes.func.isRequired,
  projectCreateType: PropTypes.func.isRequired,
  project_type: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  project_type: state.projects.project_type,
  teams: state.projects.teams,
  user: state.info_user.user,
});

export default connect(mapStateToProps, {
  projectCreateType,
  projectCreateTeams,
})(withStyles(SelectProjectType, useStyles));
