import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_ALL_PROJECT_VARIABLE_TYPES = gql`
  query($idProject: LongLong!, $name: String, $page: Int, $limit: Int) {
    projectVariableTypes(
      idProject: $idProject
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      total
      errors {
        path
        message
      }
      types {
        id
        idProject
        idType
        level
        type {
          id
          name
        }
      }
    }
  }
`;
export const QueryProjectVariableTypes = (idProject, name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_PROJECT_VARIABLE_TYPES),
    variables: {
      idProject,
      name,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_PROJECT_VARIABLE_TYPES = gql`
  mutation($idProject: LongLong!, $types: [InProjectVariableType!]!) {
    addProjectVariableTypes(idProject: $idProject, types: $types) {
      ok
      total
      errors {
        path
        message
      }
      types {
        id
        idProject
        idType
        level
        type {
          id
          name
        }
      }
    }
  }
`;
export const MutationAddProjectVariableTypes = (idProject, types) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_PROJECT_VARIABLE_TYPES),
    variables: {
      idProject,
      types,
    },
  });
};
const MUTATION_REMOVE_PROJECT_VARIABLE_TYPES = gql`
  mutation($idProject: LongLong!, $types: [LongLong!]!) {
    removeProjectVariableTypes(idProject: $idProject, types: $types) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveProjectVariableTypes = (idProject, types) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_PROJECT_VARIABLE_TYPES),
    variables: {
      idProject,
      types,
    },
  });
};
const MUTATION_UPDATE_PROJECT_VARIABLE_TYPES = gql`
  mutation($idProject: LongLong!, $types: [InProjectVariableType!]!) {
    updateProjectVariableTypes(idProject: $idProject, types: $types) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateProjectVariableTypes = (idProject, types) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PROJECT_VARIABLE_TYPES),
    variables: {
      idProject,
      types,
    },
  });
};
