import React, { useRef } from 'react';
import { InputBase } from '@mui/material';
export const useStyles = theme => ({
  disable_text: {
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-webkit-user-select': 'none',
    '-o-user-select': 'none',
  },
  base: {
    border: '0.5px solid #e0e0e0',
  },
  boder: {
    padding: 0,
    paddingLeft: 5,
    border: '1px solid green',
  },
  boder_selected: {
    padding: 0,
    paddingLeft: 5,
    border: '1px solid blue',
  },
  boder_left: {
    borderLeft: '1px solid #e0e0e0',
    paddingLeft: 0,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
});

export const InputBaseCustom = props => {
  const { onKeyDown, ...res } = props;
  const inputRef = useRef(null);
  const handleKeyDown = event => {
    if (event.key === 'ArrowLeft') {
      // Handle left arrow key press
      if (inputRef.current) {
        const currentPosition = inputRef.current.selectionStart;
        inputRef.current.selectionStart = currentPosition - 1;
        inputRef.current.selectionEnd = currentPosition - 1;
      }
    } else if (event.key === 'ArrowRight') {
      // Handle right arrow key press
      if (inputRef.current) {
        const currentPosition = inputRef.current.selectionStart;
        inputRef.current.selectionStart = currentPosition + 1;
        inputRef.current.selectionEnd = currentPosition + 1;
      }
    }
    onKeyDown(event);
  };
  return (
    <InputBase
      onKeyDown={handleKeyDown}
      {...res} // Pass any additional TextField props
      inputRef={inputRef}
    />
  );
};
