import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonDownloadType, ButtonFileUpload, ButtonReload } from 'components';
import { MutationUploadVariableTypesCSV } from 'graphql/Mapping/utils_var_types';
import { ServerErrorsString, GetData } from 'helpers';
import { styleToolbar } from 'common';
class GridToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }
  handleUploadTypesFile = event => {
    const { uploading, page, limit } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadVariableTypesCSV(files[0], page, limit, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types, total } = data.uploadVariableTypesCSV;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddVariableTypes(types, total);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button onClick={this.props.handleOpenCreate}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
            <ButtonDownloadType key="variable" type="variable_types" />
            <ButtonFileUpload
              handleSelectFile={this.handleUploadTypesFile}
              uploading={this.state.uploading}
            />
          </ButtonGroup>
          <ButtonReload
            className={classes.margin}
            handleReload={this.props.handleReload}
            loading={this.props.loading}
            small
            tooltip="Reload details"
            wrapper={{ margin: 0 }}
          />
        </Grid>
      </Paper>
    );
  }
}

GridToolBar.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
GridToolBar.defaultProps = {
  loading: false,
  selected: [],
  handleOpenEdit: () => '',
  handleOpenCreate: () => '',
  handleReload: () => '',
};
export default withStyles(GridToolBar, styleToolbar);
