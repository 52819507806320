import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

class DivRoot extends React.Component {
  render() {
    const { classes, open_drawer, children, isMobile } = this.props;
    return (
      <div
        className={clsx(classes.root, {
          [classes.contentShift]: open_drawer && !isMobile,
        })}>
        {children}
      </div>
    );
  }
}

DivRoot.propTypes = {
  classes: PropTypes.object,
};

export default DivRoot;
