import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  ButtonReloadDelete,
  DialogDeleteWarning,
  DialogSelectFolder,
} from 'components';
import { ServerErrorsString, GetData } from 'helpers';
import { styleToolbar } from 'common';
import {
  MutationAddProcessFolder,
  MutationDeleteProcessFolder,
} from 'graphql/Process';
class ProcessToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      open_delete: false,
      open_explorer: false,
    };
  }
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { deleting } = this.state;
    const { selected } = this.props;
    const ids = selected.map(x => x.id);
    if (deleting) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      MutationDeleteProcessFolder(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteProcessFolder;
          if (ok) {
            this.setState({ deleting: false });
            this.props.removeSelected();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleFolderSelection = folder => {
    console.log({ folder });
    this.setState({ open_explorer: false });
    const { adding } = this.state;
    if (adding) return;
    this.setState({ adding: true, open_explorer: false });
    (async () => {
      MutationAddProcessFolder([folder.idFolder])
        .then(res => {
          const data = GetData(res);
          const { ok, process, errors } = data.addProcessFolder;
          if (ok) {
            this.setState({ adding: false });
            this.props.addProcess(process);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ adding: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected } = this.props;
    const { open_delete } = this.state;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button onClick={() => this.setState({ open_explorer: true })}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
          </ButtonGroup>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={() => this.setState({ open_delete: true })}
            handleReloadTable={this.props.handleReload}
            loading={this.props.deleting}
            loading_table={this.props.loading}
            numSelected={this.props.selected.length}
            tooltip="Reload Process"
          />
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: !open_delete })}
          handleDeleteFolders={this.handleDeleteSelected}
          open={open_delete}
          question_text="You are about to delete the selected process, this action cannot be undone"
          title_text="Delete Process Folders"
        />
        <DialogSelectFolder
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_explorer: false })}
          handleSelectFolder={this.handleFolderSelection}
          isActive={this.props.isActive}
          open={this.state.open_explorer}
        />
      </Paper>
    );
  }
}

ProcessToolBar.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
ProcessToolBar.defaultProps = {
  loading: false,
  selected: [],
  handleOpenEdit: () => '',
  handleOpenCreate: () => '',
  handleReload: () => '',
};
export default withStyles(ProcessToolBar, styleToolbar);
