export { default as TableQueryResults } from './TableQueryResults';
export { default as TopTabQuery } from './TopTabQuery';
export { default as GroupBoxQuery } from './GroupBoxQuery';
export const useStyles = theme => ({
  main_container: {
    // marginTop: theme.spacing(3),
    // marginRight: theme.spacing(3),
    // marginLeft: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    width: '100%',
    height: '100%',
  },
  sub_container: {
    // width: '100%',
    padding: 20,
    paddingTop: 1,
    // margin: 20,
  },
  tab: {
    minWidth: '160px',
    padding: '3px 6px',
    textTransform: 'none',
    fontSize: '13px',
    '& .MuiButtonBase-root': {
      maxHeight: 40,
    },
  },
  tab_panel: {
    width: '100%',
    height: '60px',
    padding: theme.spacing(2),
    paddingTop: 1,
    paddingBottom: 1,
  },
  channels: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  content: {
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
  grid: {
    '& .MuiButtonBase-root': {
      padding: 10,
    },
  },
});
