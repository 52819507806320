import { green } from '@mui/material/colors';

const useStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
    padding: 0,
    margin: theme.spacing(0.5),
  },
  buttonSuccess: {
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 6,
    left: 6,
    zIndex: 1,
  },
  download: {
    // marginLeft: theme.spacing(2),
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },
});

export default useStyles;
