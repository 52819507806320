import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Fab, Tooltip, MenuItem, Divider } from '@mui/material';

import {
  BadgeSettings,
  GroupBoxImageType,
  GroupBoxModalities,
  GroupBoxStudyTimes,
} from './components';
import SettingsIcon from '@mui/icons-material/Settings';
import { MenuFilters, FilterContainer } from 'components';
import {
  getNumberFilters,
  defaultFilter,
  IsDefaultFilter,
} from 'components/Basics/FilterContainer';

class GroupBoxQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      filters: {
        ...defaultFilter,
      },
    };
    if (props.holder) {
      props.holder.getFilters = () => {
        let setting = null;
        const { filters } = this.state;
        if (IsDefaultFilter(filters)) return setting;
        const { sequence, project } = filters;
        if (sequence && Array.isArray(sequence)) {
          setting = { id_sequences: sequence.map(x => x.idSequence) };
        }
        if (project && Array.isArray(project)) {
          setting = { ...setting, id_projects: project.map(x => x.id) };
        } else if (project) {
          setting = { ...setting, id_projects: [project.id] };
        }
        return setting;
      };
    }
  }
  handleClickSetting = event => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };
  render() {
    const { classes, holder, tabValue } = this.props;
    const { filters } = this.state;
    const count_changed = getNumberFilters(filters);
    return (
      <div className={classes.paper}>
        <Grid
          alignItems="flex-start"
          className={classes.grid}
          container
          direction="row"
          justifyContent="flex-start"
          spacing={3}>
          <Grid container item sm={7} spacing={1} xs={12}>
            <Grid item md={8} xs={12}>
              <GroupBoxStudyTimes holder={holder} />
            </Grid>
            <Grid item md={4} xs={12}>
              <GroupBoxModalities holder={holder} />
            </Grid>
          </Grid>
          <Grid
            alignItems="flex-start"
            container
            item
            justifyContent="space-between"
            sm={5}
            spacing={1}
            xs={12}>
            {tabValue !== 6 && (
              <Grid item sm={8} xs={10}>
                <GroupBoxImageType holder={holder} />
              </Grid>
            )}
            <Grid item sx={{ marginTop: 1 }} xs={2}>
              <Tooltip title="Setting">
                <Fab
                  aria-label="add"
                  onClick={this.handleClickSetting}
                  size="small"
                  sx={{ color: '#9e9e9e' }}>
                  <BadgeSettings
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={count_changed}>
                    <SettingsIcon />
                  </BadgeSettings>
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <MenuFilters
          anchorEl={this.state.anchorEl}
          handleClose={() => this.setState({ anchorEl: null })}
          open={Boolean(this.state.anchorEl)}
          right={15}>
          <FilterContainer
            filters={filters}
            handleAddError={this.props.handleAddError}
            handleSearch={this.props.menuRunSearch}
            hide_date
            hide_modalities
            hide_nii
            onFilterChanged={filters => this.setState({ filters })}
            // show_user={this.props.show_user}
            // view={view}
          />
          <Divider style={{ paddingBottom: 5 }} />
          <MenuItem
            onClick={() => this.setState({ filters: { ...defaultFilter } })}
            onKeyDown={e => e.stopPropagation()}>
            Reset
          </MenuItem>
        </MenuFilters>
      </div>
    );
  }
}

GroupBoxQuery.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default GroupBoxQuery;
