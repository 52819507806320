import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CardActions,
  Divider,
  Drawer,
  IconButton,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useStylesEdit } from '../../../TopMenu';
import { DetailsMeta } from './components';
import { CardHeaderCustom, DetailsMetaMultiSelection } from 'components';
import { copySeries } from 'redux-store/actions/file_folder_manager';

class DetailsMetaData extends Component {
  render() {
    const {
      classes,
      theme,
      handleDrawerClose,
      open,
      edit,
      copy_series,
    } = this.props;
    return (
      <Drawer
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        open={open}
        variant="persistent">
        {/* <div className={classes.drawerHeader}>
          Details
        </div> */}
        <CardActions className={classes.drawerHeader} disableSpacing>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <CardHeaderCustom
            subheader="Select global features"
            title={`Modify ${copy_series.length} Series`}
          />
        </CardActions>
        <Divider />
        {edit === 'multi' ? (
          <DetailsMetaMultiSelection
            handleDetailChanged={this.props.handleDetailChanged}
          />
        ) : (
          <DetailsMeta />
        )}
      </Drawer>
    );
  }
}

DetailsMetaData.propTypes = {
  classes: PropTypes.object,
};
DetailsMetaData.defaultProps = {
  ListSeries: [],
  handleDetailChanged: () => '',
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};
const ContainerDetailsMetaData = props => {
  const theme = useTheme();
  return <DetailsMetaData theme={theme} {...props} />;
};
export default connect(mapStateToProps, { copySeries })(
  withStyles(ContainerDetailsMetaData, useStylesEdit)
);
