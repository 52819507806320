import { IsInvalid } from 'helpers';

export const getIdValue = (row, props) => {
  const { idRow } = props;
  if (idRow) {
    return row[idRow];
  } else if (row && row.id !== undefined) {
    return row.id;
  }
  return Math.random();
};
export const getCleanRow = row => {
  const keys = Object.keys(row);
  let new_row = {};
  for (let i = 0; i < keys.length; i++) {
    new_row = {
      ...new_row,
      [keys[i]]: '',
    };
  }
  return new_row;
};
export const findMinMaxIndices = selectedCells => {
  if (selectedCells.length === 0) {
    return null; // No selected cells
  }

  let minRow = selectedCells[0].row;
  let maxRow = selectedCells[0].row;
  let minCol = selectedCells[0].col;
  let maxCol = selectedCells[0].col;

  for (const cell of selectedCells) {
    if (cell.row < minRow) {
      minRow = cell.row;
    }
    if (cell.row > maxRow) {
      maxRow = cell.row;
    }
    if (cell.col < minCol) {
      minCol = cell.col;
    }
    if (cell.col > maxCol) {
      maxCol = cell.col;
    }
  }

  return { minRow, maxRow, minCol, maxCol };
};

export const GetSelectedBox = reply => {
  const rows = [];
  if (IsInvalid(reply)) return rows;
  const { minRow, maxRow, minCol, maxCol } = reply;
  for (let i = minRow; i <= maxRow; i++) {
    for (let j = minCol; j <= maxCol; j++) {
      rows.push({ row: i, col: j });
    }
  }
  return rows;
};
export const IsInsideSelectedBox = (reply, row, col) => {
  if (IsInvalid(reply)) return false;
  const { minRow, maxRow, minCol, maxCol } = reply;
  if (row > minRow && row < maxRow && col > minCol && col < maxCol) return true;
  return false;
};
export const getSyleSelectedBox = (reply, row, col) => {
  if (IsInvalid(reply)) return false;
  const { minRow, maxRow, minCol, maxCol } = reply;
  let style = {};
  if (row === minRow) {
    style = {
      ...style,
      borderTop: '1px solid green',
    };
  }
  if (row === maxRow) {
    style = {
      ...style,
      borderBottom: '1px solid green',
    };
  }
  if (col === minCol) {
    style = {
      ...style,
      borderLeft: '1px solid green',
    };
  }
  if (col === maxCol) {
    style = {
      ...style,
      borderRight: '1px solid green',
    };
  }
  if (Object.keys(style).length) return { ...style, zIndex: 2 };
  return null;
};
