import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { GetData, ServerErrorsString, IsInvalidUser } from 'helpers';
import {
  QueryUsersClinicals,
  MutationRemoveUsersClinicals,
  MutationAddUsersClinicals,
  MutationAdminAccessToStudy,
} from 'graphql/Users/utils_user_clinical';

const UserViewClinical = props => {
  const [studies, setStudies] = useState([]);
  const [userStudies, setUserStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { edit_user, handleAddError } = props;
  const vertical = 'top';
  const horizontal = 'right';

  useEffect(() => {
    fetchUserStudies();
  }, []);

  useEffect(() => {}, [userStudies]);

  const fetchUserStudies = () => {
    if (IsInvalidUser(edit_user) || loading) return;
    setLoading(true);
    (async () => {
      QueryUsersClinicals(edit_user.id)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            clinical_types,
            clinical_type,
            errors,
          } = data.userClinicalDataType;
          if (ok) {
            setUserStudies(clinical_types);
            setStudies(clinical_type);
            setLoading(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          handleAddError(ServerErrorsString(error));
        });
    })();
  };

  const hasUserAccessToStudy = id => {
    const hasUserAccess = userStudies.some(
      item => item.idClinicalDataType === id
    );
    return hasUserAccess;
  };

  const hasAdminAccessToStudy = id => {
    const isAdmin = userStudies.some(item => {
      return item.idClinicalDataType === id && item.hasAdminAccess;
    });
    return isAdmin;
  };

  const handleUserAdminAccess = (id, studyName) => {
    let newHasAdminAccess = false;
    (async () => {
      MutationAdminAccessToStudy({
        idClinicalDataType: id,
        idUser: edit_user.id,
        hasAdminAccess: newHasAdminAccess,
      })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateAdminAccessToStudy;
          if (ok) {
            const requestedAdminAccess = userStudies.map(item => {
              if (item.idClinicalDataType === id) {
                newHasAdminAccess = !item.hasAdminAccess;
                return { ...item, hasAdminAccess: newHasAdminAccess };
              }
              return item;
            });
            setUserStudies(requestedAdminAccess);
            setMessage(
              `User ${edit_user.FullName}'s Admin access to study ${studyName} was successfully updated!`
            );
            setOpen(true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          handleAddError(ServerErrorsString(error));
        });
    })();
  };

  const removeUserAccessToStudy = (id, studyName) => {
    (async () => {
      MutationRemoveUsersClinicals({
        idClinicalDataType: id,
        idUser: edit_user.id,
      })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeUserClinicalData;
          if (ok) {
            const revokedAdminAccess = userStudies.filter(
              item => item.idClinicalDataType !== id
            );
            setUserStudies(revokedAdminAccess);
            setMessage(
              `User ${edit_user.FullName}'s access to study ${studyName} was successfully revoked!`
            );
            setOpen(true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          handleAddError(ServerErrorsString(error));
        });
    })();
  };

  const addUserAccessToStudy = (id, studyName) => {
    (async () => {
      MutationAddUsersClinicals({
        idClinicalDataType: id,
        idUser: edit_user.id,
      })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addUserClinicalData;
          if (ok) {
            const requestedUserAccess = {
              idUser: edit_user.id,
              idClinicalDataType: id,
              hasAdminAccess: false,
            };
            setUserStudies([...userStudies, requestedUserAccess]);
            setMessage(
              `User ${edit_user.FullName}'s access to study ${studyName} was successfully added!`
            );
            setOpen(true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  const handleUserAccessToStudy = (id, studyName) => {
    if (!hasUserAccessToStudy(id)) {
      addUserAccessToStudy(id, studyName);
    } else {
      removeUserAccessToStudy(id, studyName);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const flashMessage = (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={4000}
      onClose={handleClose}
      open={open}>
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{ width: '100%' }}
        variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );

  return (
    <Box pl={5} pt={3} sx={{ flexGrow: 1, width: 450 }}>
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="center"
        spacing={3}
        sx={{ width: 450 }}>
        <Grid item xs={4}>
          <Typography gutterBottom variant="subtitle2">
            Studies
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom variant="subtitle2">
            User Access
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom variant="subtitle2">
            Admin Access
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      {flashMessage}
      {studies.map(study => (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="center"
          key={study.id}
          spacing={3}
          sx={{ width: 450 }}>
          <Grid item xs={4}>
            <Typography gutterBottom variant="subtitle2">
              {study.description}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" gutterBottom variant="subtitle2">
              {study && (
                <Switch
                  checked={hasUserAccessToStudy(study.id)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={() =>
                    handleUserAccessToStudy(study.id, study.description)
                  }
                  size="small"
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {study && (
              <Switch
                checked={hasAdminAccessToStudy(study.id)}
                disabled={!hasUserAccessToStudy(study.id)}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() =>
                  handleUserAdminAccess(study.id, study.description)
                }
                size="small"
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

UserViewClinical.propTypes = {
  classes: PropTypes.object,
};

export default UserViewClinical;
