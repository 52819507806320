import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import moment from 'moment';

import {
  DialogContainer,
  TableContainers,
  ToolBarContanerActions,
  ViewDockerContainer,
} from './components';
import {
  MutationDockerContainerDelete,
  QueryDockerContainers,
} from 'graphql/Dockers/utils_containers';
import { FormatTime, GetData, ServerErrorsString } from 'helpers';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  toolbar: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

class TabContainers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      loading: false,
      containers: [],
      selected: [],
      container: null,
      open_delete: false,
      init: false,
    };
    const { holder } = props;
    if (holder) {
      holder.addNewContainer = this.handleAddNewContainer;
    }
  }

  componentDidMount() {
    this.queryContainers();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryContainers();
  }
  handleAddNewContainer = container => {
    if (!this.props.isActive) return;
    const { containers } = this.state;
    const index = containers
      .map(x => x.idContainer)
      .indexOf(container.idContainer);

    if (index === -1) {
      containers.unshift(container);
    }
    this.setState({ containers });
  };
  queryContainers = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerContainers()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, containers } = data.dockerContainers;
          if (ok) {
            this.setState({ loading: false, containers, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleClickContainer = container => {
    this.setState({ container });
  };
  handleSetSelected = selected => {
    this.setState({ selected });
    this.props.handleSetSelectedContainers(selected);
  };
  handleActionResults = actions => {
    const { containers } = this.state;
    for (let i = 0; i < actions.length; i++) {
      const {
        idContainer,
        state: { StartedAt, FinishedAt, Status, ExitCode },
      } = actions[i];
      const index = containers.map(x => x.idContainer).indexOf(idContainer);
      if (index !== -1) {
        containers[index].State = Status;
        if (StartedAt) {
          const date1 = FormatTime(StartedAt, 'moment');
          const date2 = FormatTime(FinishedAt, 'moment');
          const diff = date2.diff(date1, 'minutes');
          const date = moment(new Date())
            .subtract(diff, 'minutes')
            .fromNow();
          let status = `${Status} (${ExitCode}) ${date}`;
          if (Status === 'running') {
            status = `Up ${date}`;
          }
          containers[index].Status = status;
        }
      }
    }
    this.setState({ containers });
  };
  handleDeleteSelected = () => {
    this.setState({ open_delete: !this.state.open_delete });
  };
  getSelectedNames = () => {
    const { selected } = this.state;
    let names = [];
    for (let i = 0; i < selected.length; i++) {
      const { ImageName, idContainer } = selected[i];
      if (
        !idContainer &&
        (ImageName === undefined || !ImageName || ImageName === '')
      ) {
        continue;
      }
      // const parts = ImageName.split(':');
      names.push(idContainer);
    }
    if (!names.length) return names;
  };
  executeDeleteSelected = settings => {
    const { deleting, selected } = this.state;
    const names = this.getSelectedNames();

    if (deleting || !names.length) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      MutationDockerContainerDelete(names, settings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteDockerContainer;
          if (ok) {
            let { containers } = this.state;
            const ids = selected.map(x => x.idContainer);
            containers = containers.filter(
              x => ids.indexOf(x.idContainer) === -1
            );
            this.setState({ deleting: false, selected: [], containers });
            this.props.handleSetSelectedContainers([], true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes } = this.props;
    const { containers, container } = this.state;
    if (container) {
      return (
        <ViewDockerContainer
          container={container}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.handleClickContainer(null)}
        />
      );
    }
    return (
      <Grid container>
        <Grid className={classes.toolbar} item xs={12}>
          <ToolBarContanerActions
            handleActionResults={this.handleActionResults}
            handleAddError={this.props.handleAddError}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainers
            containers={containers}
            deleting={this.state.deleting}
            handleChangePage={(event, newPage) => {
              event.preventDefault();
              this.setState({ page: newPage });
            }}
            handleChangeRowsPerPage={event => {
              event.preventDefault();
              this.setState({ page: 0, limit: +event.target.value });
            }}
            handleClickContainer={this.handleClickContainer}
            handleDeleteSelected={this.handleDeleteSelected}
            handleReloadTable={this.queryContainers}
            handleSetSelected={this.handleSetSelected}
            height={this.props.height - 380}
            limit={this.state.limit}
            loading={this.state.loading}
            page={this.state.page}
            selected={this.state.selected}
            total={containers.length}
          />
        </Grid>
        <DialogContainer
          deleting={this.state.deleting}
          executeDeleteSelected={this.executeDeleteSelected}
          handleClose={this.handleDeleteSelected}
          open={this.state.open_delete}
        />
      </Grid>
    );
  }
}

TabContainers.propTypes = {
  classes: PropTypes.object,
};
TabContainers.defaultProps = {
  handleAddError: () => '',
};

export default withStyles(TabContainers, useStyles);
