import React, { useState } from 'react';
import Box from '@mui/material/Box';

import Sheet from './Sheet';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderColor: '#757575',
          color: '#757575',
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default function WorkSheet(props) {
  // eslint-disable-next-line no-unused-vars
  const [workbook, setWorkbook] = useState(props.workbook);
  const [activeWorksheet, setActiveWorksheet] = useState(0);

  const openWorkSheet = event => {
    event.preventDefault();
    if (activeWorksheet != parseInt(event.target.name)) {
      setActiveWorksheet(parseInt(event.target.name));
    }
  };

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="row"
      justifyContent="flex-start">
      <Sheet
        activeWorksheet={activeWorksheet}
        idFileObject={props.idFileObject}
        workbook={workbook}
      />
      <Grid item mt={0} xs={12}>
        <Box
          sx={{
            display: 'flex',
            '& > :not(style)': {
              width: '100%',
              height: 33,
            },
          }}>
          <Paper square variant="outlined">
            <ThemeProvider theme={theme}>
              <ButtonGroup aria-label="small button group" size="small">
                <Button>
                  <SkipPreviousIcon /> <SkipNextIcon />
                </Button>
                {workbook.SheetNames.map((worksheet, index) => (
                  <Button
                    key={`button-${index}`}
                    name={index}
                    onClick={openWorkSheet}
                    sx={{ width: 150 }}>
                    {worksheet}
                  </Button>
                ))}
              </ButtonGroup>
            </ThemeProvider>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
