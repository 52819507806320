const useStyles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    height: '100%',
  },
  inline: {
    display: 'inline',
  },
  table: {
    width: '100%',
  },
  toolbar: {
    width: '100%',
  },
  button: {
    // margin: theme.spacing(1),
  },
  left: {
    marginLeft: 'auto',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  description: {
    marginLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: '0px',
    width: '101%',
    marginLeft: '-50px',
  },
  shiftContent: {
    marginLeft: '-295px',
    // paddingLeft: 240,
  },
});
export default useStyles;
