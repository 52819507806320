import { jwtDecode } from 'jwt-decode';

// const { default: jwt_decode } = require('jwt-decode');

import { IsInvalid } from './utils';
const { REACT_APP_ENV } = process.env;
export const isAuthenticated = (user, logout) => {
  if (!user) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return false;
  }
  try {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    if (IsInvalid(decodedToken)) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      if (logout) {
        logout();
      }
      return false;
    }
    const refreshToken = localStorage.getItem('refreshToken');
    const dec_retoken = jwtDecode(refreshToken);
    if (IsInvalid(dec_retoken)) throw Error('Invalid Re-token');
  } catch (error) {
    console.log({ error });
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    if (logout) {
      logout();
    }
    return false;
  }
  return true;
};
export const IsProduction = () => {
  return REACT_APP_ENV === 'production';
};
