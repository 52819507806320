import {
  FormatTime,
  FormatBytes,
  getUserFullName,
  IsInvalid,
  IsInvalidParam,
} from 'helpers';
export const columns_projects = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    width: 110,
  },
  {
    field: 'admin',
    headerName: 'Administrator',
    align: 'left',
    width: 150,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'admin');
      if (value === undefined || !value) return '';
      value = getUserFullName(value);
      if (value === undefined || !value) return '';
      return value;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const columns = [
  { field: 'idObject', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    width: 110,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    // width: 110,
  },
  {
    field: 'size',
    headerName: 'Size',
    align: 'left',
    // width: 200,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'size');
      if (value === undefined || !value) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'owner',
    headerName: 'Owner',
    align: 'left',
    width: 150,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'owner');
      if (value === undefined || !value) return '';
      value = getUserFullName(value);
      if (value === undefined || !value) return '';
      return value;
    },
  },
  {
    field: 'copies',
    headerName: '#Copies',
    align: 'left',
    // width: 200,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'folder_contents');
      if (value === undefined || !value) return '';
      return value.length;
    },
  },
  {
    field: 'projects',
    headerName: '#Projects',
    align: 'left',
    // width: 200,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'projects');
      if (value === undefined || !value) return '';
      return value.length;
    },
  },
  {
    field: 'folders',
    headerName: '#Folders',
    align: 'left',
    // width: 200,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'folders');
      if (value === undefined || !value) return '';
      return value.length;
    },
  },
  {
    field: 'folder_contents',
    headerName: '#Rights',
    align: 'left',
    // width: 200,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'folder_contents');
      if (value === undefined || !value) return '';
      const Rights = value.map(x => x.Rights).filter(x => x);
      if (Rights === undefined || !Rights) return '';
      let count = 0;
      Rights.forEach(x => {
        count += x.length;
      });
      return count;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const columns_folders = [
  {
    field: 'idFolderContent',
    headerName: 'ID Content',
    width: 75,
    align: 'left',
  },
  {
    field: 'element',
    headerName: 'Element Name',
    align: 'left',
    width: 110,
  },
  {
    field: 'idFolder',
    headerName: 'ID Folder',
    width: 75,
    align: 'left',
  },

  {
    field: 'name',
    headerName: 'Folder Name',
    align: 'left',
    width: 110,
  },
  {
    field: 'Size',
    headerName: 'Size',
    align: 'left',
    width: 90,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'Size');
      if (value === undefined || !value) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    align: 'left',
    width: 150,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'Owner');
      if (value === undefined || !value) return '';
      value = getUserFullName(value);
      if (value === undefined || !value) return '';
      return value;
    },
  },
  {
    field: 'folders_paths',
    headerName: 'Path',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'folders_paths');
      if (value === undefined || !value) return '';
      return value.map(x => x.name).join('/');
    },
  },
  {
    field: 'Rights',
    headerName: 'Rights',
    align: 'left',
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue(params.id, 'Rights');
      if (value === undefined || !value) return '';
      return `${value.length}`;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
