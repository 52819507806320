import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';

class ButtonExpand extends Component {
  render() {
    const { classes, expanded, handleExpandClick } = this.props;
    return (
      <IconButton
        aria-expanded={expanded}
        aria-label="show more"
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        onClick={handleExpandClick}
        size="large">
        <ExpandMoreIcon />
      </IconButton>
    );
  }
}

ButtonExpand.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ButtonExpand;
