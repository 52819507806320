import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const LoadMoreData = props => {
  const { page, limit, total, onChangePage } = props;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(page);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    onChangePage(activeStep + 1);
  };
  const size = page * limit;
  // const handleBack = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep - 1);
  // };
  const steps = total / limit;
  console.log({ total, steps });
  return (
    <MobileStepper
      activeStep={activeStep}
      // backButton={
      //   <Button disabled={activeStep === 0} onClick={handleBack} size="small">
      //     {theme.direction === 'rtl' ? (
      //       <KeyboardArrowRight />
      //     ) : (
      //       <KeyboardArrowLeft />
      //     )}
      //     Back
      //   </Button>
      // }
      nextButton={
        <Button disabled={size >= total} onClick={handleNext} size="small">
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      position="static"
      steps={steps}
      sx={{ width: '100%', flexGrow: 1 }}
      variant="progress"
    />
  );
};
LoadMoreData.defaultProps = {
  total: 1,
  limit: 1,
};
export default LoadMoreData;
