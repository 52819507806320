import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { headers } from '../styles';
import {
  GetData,
  IsInvalid,
  ServerErrorsString,
  rowsPerPageBig,
} from 'helpers';
import { QueryProjectVariableTypes } from 'graphql/Projects/utils_project_types';

class ProjectVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idProject: null,
      loading: false,
      rows_edited: [],
      selected: [],
      page: 0,
      limit: 100,
      total: 0,
      types: [],
      empty: false,
    };
    const { holder } = props;
    if (holder) {
      holder.getTypes = () => {
        return this.getTypes();
      };
    }
  }
  componentDidMount() {
    this.handleLoadTypes();
  }
  componentDidUpdate() {
    const { rows_edited } = this.state;
    console.log({ rows_edited });
    this.handleLoadTypes();
  }
  handleLoadTypes = (
    idNewProject,
    newName = undefined,
    newPage = undefined,
    newLimit = undefined
  ) => {
    const { project } = this.props;
    if (IsInvalid(project)) return;
    if (IsInvalid(idNewProject)) {
      idNewProject = project.id;
    }
    let { loading, name, page, limit, idProject } = this.state;
    // console.log({ project, idNewProject, idProject });
    if (loading || idNewProject === idProject) return;
    if (idNewProject) idProject = idNewProject;
    if (IsInvalid(idProject)) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectVariableTypes(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types, total } = data.projectVariableTypes;
          if (ok) {
            this.setState({
              idProject,
              loading: false,
              page,
              limit,
              types,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ idProject, loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getTypes = () => {
    const { rows_edited } = this.state;
    if (IsInvalid(rows_edited) || !rows_edited.length) return [];
    let types = [];
    for (let i = 0; i < rows_edited.length; i++) {
      const { idType, value } = rows_edited[i];
      if (value && value !== '') types.push({ idType, name: value });
    }
    return types;
  };
  render() {
    const { idProject, loading, types } = this.state;
    return (
      <TableGrid
        checkboxSelection
        dense
        disableSelectionOnClick
        external_edited={this.state.rows_edited}
        external_selected={this.state.selected}
        grid
        headers={headers}
        // height={height - 80}
        // idRow="id"
        loading={loading}
        onPageChange={page =>
          this.handleLoadTypes(idProject, null, page, this.state.limit)
        }
        onRowClicked={row_clicked => this.setState({ row_clicked })}
        onRowEditedChanged={rows_edited => {
          this.setState({ rows_edited });
        }}
        onRowsPerPageChange={limit =>
          this.handleLoadTypes(idProject, null, 0, limit)
        }
        onSelectedChanged={selected => this.setState({ selected })}
        page={this.state.page}
        paginationMode="server"
        rowCount={this.state.total}
        rows={types}
        rowsPerPage={this.state.limit}
        rowsPerPageOptions={rowsPerPageBig}
      />
    );
  }
}

ProjectVariableTypes.propTypes = {
  classes: PropTypes.object,
};

export default ProjectVariableTypes;
