import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SEARCH_SLEEP_CASES = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchSleep(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication
        tot_files
        tot_series
        tot_edfs
        patient {
          PatientName
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const SearchSleep_cases = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_SLEEP_CASES),
    variables: { text, page, limit, table },
  });
};
const QUERY_SEARCH_SLEEP_POLYSOMNOGRAPHIES = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchSleep(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      polysomnographies {
        id
        idPatient
        idSleepCase
        start_datetime
        cpap_device
        sleep_duration
        sleep_latency
        rem_latency
        sleep_efficiency
        rem_percentage
        stage1_percentage
        stage2_percentage
        stage3_percentage
        supine_sleep_index
        apnoea_sleep_index
        hypopnoea_sleep_index
        centrale_sleep_index
        obstructive_sleep_index
        mixed_sleep_index
        average_spo2
        min_spo2
        time_90_spo2
        time_88_spo2
        desat_index
        co2_average_sleep
        co2_max_sleep
        co2_50_more_duration
        average_hf
        arousal_index
        plm_sleep_index
        subject_sleep_quality_pre
        subject_sleep_quality
        subject_sleep_duration
        subject_sleep_latency
        diagnose1
        diagnose2
        diagnose3
        ahi_sleep
        ahi_non_supine
        ahi_supine
        ahi_rem
        ahi_nrem

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const SearchSleep_polysomnographies = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_SLEEP_POLYSOMNOGRAPHIES),
    variables: {
      text,
      page,
      limit,
      table,
    },
  });
};
const QUERY_SEARCH_SLEEP_TEST_TYPE = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchSleep(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      test_types {
        id
        idPatient
        idSleepCase
        type
        instruction
        date
        diagnose1
        diagnose2
        diagnose3
        nr_repetitions
        sleep_latency
        sorem_n
        sleep_case {
          id
          record_id
        }
        repetitions {
          id
          start_datetime
          sleep_latency
          rem_latency
          kss_pre
          kss_post
        }
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchSleep_mslt = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_SLEEP_TEST_TYPE),
    variables: {
      text,
      page,
      limit,
      table,
    },
  });
};

export const SearchSleep_mwt = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_SLEEP_TEST_TYPE),
    variables: {
      text,
      page,
      limit,
      table,
    },
  });
};

const QUERY_SEARCH_SLEEP_VIGILANCES = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchSleep(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      vigilances {
        id
        idPatient
        idSleepCase
        test_type
        pvt_mean
        pvt_lapses
        sart_total_errors
        stear_clear
        pupillography_var
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const SearchSleep_vigilances = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_SLEEP_VIGILANCES),
    variables: {
      text,
      page,
      limit,
      table,
    },
  });
};
