import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from '../EditMappingDocker/Style';
import { ButtonCircularIconLoad, CardCreateHeader } from 'components';
import { MutationCreateMappingTables } from 'graphql/Dockers/utils_docker_mapping_tables';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryTableNames, QueryColumnNames } from 'graphql/utils_tables';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';

class CreateMappingDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_map_name: '',
      table_name_input: null,
      table_name_output: null,
      id_table_input: null,
      id_table_output: null,
      uploading: false,
      changed: false,
      table_list: [],
      column_list: [],
    };
  }
  componentDidMount() {
    this.getTableList();
    this.getColumnList();
  }
  getTableList = () => {
    const { uploading } = this.state;
    if (uploading) return;
    this.setState({ uploading: true });
    (async () => {
      QueryTableNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, tables } = data.tableNames;
          if (ok) {
            this.setState({
              uploading: false,
              table_list: tables,
              changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getColumnList = () => {
    const { uploading } = this.state;
    if (uploading) return;
    this.setState({ uploading: true });
    (async () => {
      QueryColumnNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, columns } = data.columnNames;
          if (ok) {
            this.setState({
              uploading: false,
              column_list: columns,
              changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedTableInput = table_name_input => {
    this.setState({
      table_name_input,
      changed: true,
    });
  };
  handleSelectedTableOutput = table_name_output => {
    this.setState({
      table_name_output,
      changed: true,
    });
  };
  handleSelectedTableIdInput = id_table_input => {
    this.setState({
      id_table_input,
      changed: true,
    });
  };
  handleSelectedTableIdOutput = id_table_output => {
    this.setState({
      id_table_output,
      changed: true,
    });
  };
  handleChangeTableMapName = event => {
    this.setState({ table_map_name: event.target.value, changed: true });
  };
  handleSaveClicked = () => {
    const {
      uploading,
      table_map_name,
      table_name_input,
      table_name_output,
      id_table_input,
      id_table_output,
    } = this.state;
    if (uploading) return;
    if (table_map_name === '') {
      this.props.handleAddError('Table Join Name is missing');
      return;
    }
    if (table_name_input === '') {
      this.props.handleAddError('Table input name is missing');
      return;
    }
    if (table_name_output === '') {
      this.props.handleAddError('Table output name is missing');
      return;
    }
    if (id_table_input === '') {
      this.props.handleAddError('Table input ID is missing');
      return;
    }
    if (id_table_output === '') {
      this.props.handleAddError('Table output ID is missing');
      return;
    }
    let inputs = {
      table_map_name,
      table_name_input: table_name_input.name,
      table_name_output: table_name_output.name,
      id_table_input: id_table_input.name,
      id_table_output: id_table_output.name,
    };

    this.setState({ uploading: true });
    (async () => {
      MutationCreateMappingTables(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.createMappingTables;
          if (ok) {
            this.setState({ uploading: false, changed: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, height } = this.props;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    const {
      table_map_name,
      table_name_input,
      table_name_output,
      id_table_input,
      id_table_output,
      uploading,
      changed,
      table_list,
      column_list,
    } = this.state;
    let input_table = '';
    if (table_name_input) {
      input_table = table_name_input.name;
    }
    let output_table = '';
    if (table_name_output) {
      output_table = table_name_output.name;
    }
    let input_id_table = '';
    if (id_table_input) {
      input_id_table = id_table_input.name;
    }
    let output_id_table = '';
    if (id_table_output) {
      output_id_table = id_table_output.name;
    }
    return (
      <Card className={classes.card} style={style}>
        <CardCreateHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateDocker}
          icon={<AddIcon style={{ color: 'blue' }} />}
          loading={this.state.loading}
          subheader="Fill the necessary field and save it"
          title="Create MB-Docker Mapping"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={uploading}
                handleAddResultObject={this.handleSelectedTableInput}
                label="Input Table Name"
                name={input_table}
                no_icon
                options={table_list}
                placeholder="Name of the input table (e.g. Series)..."
                required
                selected={table_name_input}
                setObject={this.handleSelectedTableInput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={uploading}
                handleAddResultObject={this.handleSelectedTableIdInput}
                label="Input Table ID"
                name={input_id_table}
                no_icon
                options={column_list}
                placeholder="Name of the identifier column in input Table (e.g. idSeries)..."
                required
                selected={id_table_input}
                setObject={this.handleSelectedTableIdInput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={uploading}
                handleAddResultObject={this.handleSelectedTableOutput}
                label="Output Table Name"
                name={output_table}
                no_icon
                options={table_list}
                placeholder="Name of the output table (e.g. Sequence)..."
                required
                selected={table_name_output}
                setObject={this.handleSelectedTableOutput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={uploading}
                handleAddResultObject={this.handleSelectedTableIdOutput}
                label="Output Table ID"
                name={output_id_table}
                no_icon
                options={column_list}
                placeholder="Name of the identifier column in output Table (e.g. idSequence)..."
                required
                selected={id_table_output}
                setObject={this.handleSelectedTableIdOutput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="table-map-name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Table Join Name"
                onChange={this.handleChangeTableMapName}
                placeholder="Name of the joining table (e.g. series_sequences)..."
                required
                sx={{ paddingBottom: 1 }}
                value={table_map_name}
              />
            </Grid>
            <Grid item style={{ margin: 'auto' }}>
              <ButtonCircularIconLoad
                disabled={!changed}
                handleButtonClick={this.handleSaveClicked}
                loading={uploading}
                small
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

CreateMappingDocker.propTypes = {
  classes: PropTypes.object,
};
CreateMappingDocker.defaultProps = {};

export default withStyles(CreateMappingDocker, useStyles);
