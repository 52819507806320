/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ExplorerToolBar } from '../index';
import { ExplorerSharedFolderContent } from './components';
import { SnackMessage } from 'components';
import { GetData, getLevels, ServerErrorsString } from 'helpers';
import { setExplorerFolders } from 'redux-store/actions';
import { useStyles } from '../ExplorerHome/Style';
import { DivRoot } from '../ExplorerHome/components';
import { ExplorerLinearProgress } from '../index';
import { QueryHomeShareFolder } from 'graphql/Folders/utils_folders';
class ExplorerShared extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      Folder: null,
      idFolder: -2,
      open_details: false,
      width: -1,
      height: -1,
      item: {},
      selected_folder: [],
      selected_content: [],
      error: '',
      warning: '',
    };
    this.holder = {
      reloadShared: null,
      clearSelection: null,
    };
  }

  componentDidMount() {
    //
    this.updateWindowDimensions(null);
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    const { idFolder } = this.state;
    const { level_1, level_2, level_3 } = getLevels(this.props);
    // console.log({ level_1, level_2, level_3 });
    if (level_1 === 'shared' && level_2 === 'folder') {
      if (level_3) {
        const id = parseInt(level_3, 10);
        if (id && idFolder !== id) {
          this.queryFolder(id);
          return;
        } else {
          return;
        }
      }
      if (this.state.idFolder > 0) {
        this.queryFolder();
      }
      this.backToHome();
    } else if (idFolder >= 1 || idFolder === -2) {
      this.queryFolder();
    }
  }

  updateWindowDimensions = (event, open_details = null) => {
    // event.d
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      open_details: open_details ? open_details : false,
    });
  };
  handleClickMenuItems = value => {
    if (value === 'home') {
      this.props.history.push('/explorer');
    }
  };
  handleDrawerClose = () => {
    //
    this.updateWindowDimensions(null, false);
  };
  handleDrawerOpen = () => {
    this.updateWindowDimensions(null, true);
  };
  handleDrawerOpenClose = () => {
    const { open_details } = this.state;
    if (open_details) {
      this.handleDrawerClose();
    } else {
      this.handleDrawerOpen();
    }
  };
  handleSelected = (Folder, idContent) => {
    let { selected_folder, selected_content } = this.state;
    if (Folder && typeof Folder.idFolder !== 'undefined') {
      const index = selected_folder
        .map(x => x.idFolder)
        .indexOf(Folder.idFolder);
      if (index === -1) {
        selected_folder.push(Folder);
      } else {
        selected_folder = selected_folder.filter(
          x => x.idFolder !== Folder.idFolder
        );
      }
      this.setState({ selected_folder });
    }
    if (idContent) {
      const index = selected_content.indexOf(idContent);
      if (index === -1) {
        selected_content.push(idContent);
      } else {
        selected_content = selected_content.filter(x => x !== idContent);
      }
      this.setState({ selected_content });
    }
  };
  handleCloseSnak = status => {
    if (status) {
      this.setState({ error: '' });
    } else {
      this.setState({ warning: '' });
    }
  };
  handleDeleteFolders = information => {
    console.log('handleDeleteFolders - shared: ', { information });
    const { ok, errors } = information;
    if (ok) {
      this.setState({
        open_details: false,
        selected_folder: [],
        selected_content: [],
      });
      // this.holder.reloadShared();
      // this.holder.clearSelection();
    } else {
      this.setState({
        open_details: false,
        error: ServerErrorsString(errors),
      });
    }
  };
  backToHome = () => {
    const { folders } = this.props;

    this.handleDrawerClose();
    if (folders.length) {
      this.props.setExplorerFolders([]);
    }
    const { url } = getLevels(this.props);
    if (!url || url !== '/explorer/shared') {
      this.props.history.push('/explorer/shared');
    }
  };
  changeParentFolder = Folder => {
    const { idFolder } = Folder;
    this.handleDrawerClose();
    if (idFolder > 0) {
      this.props.history.push(`/explorer/shared/${idFolder}`);
    }
  };
  handleRefresh = () => {
    //    this.holder.reloadShared();
    //    this.holder.clearSelection();
    this.queryFolder(this.state.idFolder);
    // this.setState({ selected_folder: [], selected_content: [] });
  };
  IsHome = () => {
    const { idFolder } = this.state;
    return idFolder === -1;
  };
  handleAddError = error => {
    if (
      error.indexOf(
        'Client never received a response, or request never left'
      ) !== -1
    ) {
      error = 'The server is down';
    }
    this.setState({ error });
  };
  queryFolder = (idFolder, level) => {
    let { loading } = this.state;
    if (loading) return;
    if (!idFolder) {
      idFolder = -1;
      level = 0;
    } else if (idFolder >= 1) {
      level = 1;
    }
    if (!level) {
      level = 0;
    }
    const { fullpath } = this.props;
    this.setState({ loading: true });
    (async () => {
      QueryHomeShareFolder(idFolder, level)
        .then(res => {
          const data = GetData(res);
          const { ok, Folder, errors } = data.shared;
          if (ok) {
            if (Folder) {
              let { folders_paths } = Folder;
              folders_paths = folders_paths.filter(x => x.name !== 'Home');
              const new_paths = folders_paths.map(x => x.name).join('/');
              // console.log('QueryHomeShareFolder', { new_paths, fullpath });
              if (new_paths !== fullpath) {
                this.props.setExplorerFolders(folders_paths);
              }
            }
            this.setState({
              Folder,
              idFolder,
              loading: false,
              selected_folder: [],
              selected_content: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleSelectedAll = (selected_folder, selected_content) => {
    this.setState({ selected_folder, selected_content });
  };
  render() {
    const { classes, view_type } = this.props;
    const {
      open_details,
      selected_content,
      selected_folder,
      error,
      warning,
      height,
    } = this.state;
    const selected = selected_content.concat(selected_folder);

    return (
      <DivRoot
        classes={classes}
        isDesktop={this.props.isDesktop}
        isMobile={this.props.isMobile}
        open_drawer={open_details}>
        <ExplorerToolBar
          base={'Shared'}
          changeParentFolder={this.changeParentFolder}
          Folder={this.state.Folder}
          handleAddError={this.props.handleAddError}
          handleClickMenuItems={this.handleClickMenuItems}
          handleDeleteFolders={this.handleDeleteFolders}
          handleDrawerClose={this.handleDrawerClose}
          handleProgress={progress => this.setState({ progress })}
          handleRefresh={this.handleRefresh}
          IsHome={this.IsHome}
          loading={this.state.loading}
          menu_items={['Home']}
          open_drawer={open_details}
          ParentFolder={this.state.Folder}
          selected_content={selected_content}
          selected_folder={selected_folder}
          size={selected.length}
        />
        <ExplorerLinearProgress progress={this.state.progress} />
        <ExplorerSharedFolderContent
          base={'Shared'}
          Folder={this.state.Folder}
          handleAddError={this.handleAddError}
          handleDeleteFolders={this.handleDeleteFolders}
          handleDrawerClose={this.handleDrawerClose}
          handleDrawerOpenClose={this.handleDrawerOpenClose}
          handleProgress={progress => this.setState({ progress })}
          handleSelected={this.handleSelected}
          handleSelectedAll={this.handleSelectedAll}
          height={height}
          holder={this.holder}
          isDesktop={this.props.isDesktop}
          isMobile={this.props.isMobile}
          loading={this.state.loading}
          open_details={open_details}
          ParentFolder={this.state.Folder}
          view_type={view_type}
        />
        {/* </SharedQuery> */}
        <SnackMessage
          handleClose={() => this.handleCloseSnak(true)}
          message_text={error}
          open={error !== '' ? true : false}
          type={'error'}
        />
        <SnackMessage
          handleClose={() => this.handleCloseSnak(false)}
          horizontal="left"
          message_text={warning}
          open={warning !== '' ? true : false}
          type={'warning'}
        />
      </DivRoot>
    );
  }
}

ExplorerShared.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { page_explorer, layout } = state;
  let total_path_folders = 0;
  let fullpath = '';
  if (page_explorer.folders) {
    total_path_folders = page_explorer.folders.length;
    fullpath = page_explorer.folders.map(x => x.name).join('/');
  }
  return {
    fullpath,
    total_path_folders,
    folders: page_explorer.folders,
    view_type: page_explorer.view_type,
    isDesktop: layout.isDesktop,
    isMobile: layout.isMobile,
  };
};
export default connect(mapStateToProps, {
  setExplorerFolders,
})(withRouter(withStyles(ExplorerShared, useStyles)));
