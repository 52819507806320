import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import CachedIcon from '@mui/icons-material/Cached';

import { UserViewHeader, UserViewRight, UserViewUser } from './components';
import { MutationUpdateSetting, QueryUserSetting } from 'graphql/Users';
import {
  FormatToBytes,
  GetData,
  GetUnit,
  GetUnitIndex,
  ServerErrorsString,
} from 'helpers';
import { ButtonCircularIconLoad, DivGrow } from 'components'; //SnackMessage
import { useStyles, FormatNumber } from './components/utils';
import { connect } from 'react-redux';
import { setUpdateSetting } from 'redux-store/actions/loginActions';
import { setDashboardViewMode } from 'redux-store/actions';
class UserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_user: null,
      setting: null,
      original: null,
      empty: false,
      changed: false,
      changed_user: false,
      changed_dashboard: false,
      loading: false,
      saving: false,
      saving_dashboard: false,
    };
    this.holder = {
      updateUserInformation: null,
      handleSaveDashboard: null,
      setSavingDashboard: saving_dashboard =>
        this.setState({ saving_dashboard }),
    };
  }

  componentDidMount() {
    this.updateUserSettings();
  }
  IsUserHere = () => {
    const { edit_user } = this.props;
    if (this.state.edit_user && this.state.edit_user.id === edit_user.id) {
      return true;
    }
    return false;
  };
  updateUserSettings = idUser => {
    if (typeof idUser === 'undefined' && this.IsUserHere() && !idUser) {
      return;
    }
    const { edit_user } = this.props;
    const { loading, original } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryUserSetting(edit_user.id)
        .then(res => {
          const data = GetData(res);

          const {
            UserSetting: { ok, errors, setting },
          } = data;
          if (ok) {
            this.setState({
              edit_user,
              loading: false,
              setting,
              original: setting,
              empty: setting ? false : true,
              changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            setting: original,
            loading: false,
            changed: false,
          });
        });
    })();
  };
  saveUserSettings = out_error => {
    const { saving, setting, original } = this.state;
    if (out_error) {
      this.props.handleAddError(ServerErrorsString(out_error));
      return;
    }
    if (saving || !setting) {
      return;
    }
    const { me, edit_user } = this.props;
    this.setState({ saving: true });
    (async () => {
      MutationUpdateSetting(setting)
        .then(res => {
          const data = GetData(res);
          const {
            UpdateSetting: { ok, errors },
          } = data;

          if (ok) {
            this.setState({
              saving: false,
              setting,
              original: setting,
              empty: setting ? false : true,
              changed: false,
            });
            this.props.handleUpdateUserSettings(setting);
            if (me && edit_user && me.id === edit_user.id) {
              this.props.setUpdateSetting(setting);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            setting: original,
            saving: false,
            changed: false,
          });
        });
    })();
  };
  handleChange = prop => event => {
    let number = event.target.value;
    if (number < 0) number = 0;
    const { setting } = this.state;
    let value = FormatNumber(setting[prop]);
    number = FormatToBytes(`${number} ${value.format}`);
    const new_setting = {
      ...setting,
      [prop]: number,
    };
    this.setState({
      setting: new_setting,
      changed: this.IsChanged(new_setting),
    });
  };
  handleTypeChanged = (event, newValue) => {
    if (!newValue) return;
    const { setting } = this.state;
    const new_setting = {
      ...setting,
      type: newValue,
    };
    this.setState({
      setting: new_setting,
      changed: this.IsChanged(new_setting),
    });
  };
  handleAddAccountCategory = newCategory => {
    if (!newCategory) return;
    const { setting } = this.state;
    const new_setting = {
      ...setting,
      category: newCategory,
    };
    this.setState({
      setting: new_setting,
      changed: this.IsChanged(new_setting),
    });
  };
  IsChanged = setting => {
    const { original } = this.state;
    const keys = Object.keys(setting);
    for (let i = 0; i < keys.length; i++) {
      const num = setting[keys[i]];
      if (Number.isInteger(num)) {
        const unit = GetUnit(original[keys[i]]);
        if (GetUnit(setting[keys[i]]) !== unit) {
          return true;
        }
        const value = Math.abs(setting[keys[i]] - original[keys[i]]);
        const iii = GetUnitIndex(unit);
        if (value && value > Math.pow(1024, iii) * 0.8) {
          return true;
        }
      } else if (setting[keys[i]] !== original[keys[i]]) {
        return true;
      }
    }
    return false;
  };
  handleSave = () => {
    const { changed, changed_dashboard, changed_user } = this.state;
    if (changed_user && this.holder.updateUserInformation) {
      this.holder.updateUserInformation();
    } else if (changed) {
      this.saveUserSettings();
    }
    if (changed_dashboard && this.holder.handleSaveDashboard) {
      this.holder.handleSaveDashboard();
    }
  };
  render() {
    const { classes, edit_user } = this.props;
    const {
      setting,
      loading,
      changed,
      saving,
      changed_user,
      changed_dashboard,
      saving_user,
      saving_dashboard,
    } = this.state;
    let height_view = undefined;
    if (document.getElementById('view-user')) {
      height_view = document.getElementById('view-user').clientHeight;
    }
    return (
      <Card className={classes.card}>
        <UserViewHeader
          classes={classes}
          handleOpenUserView={this.props.handleOpenEditUser}
          user={edit_user}
        />
        <Divider />
        <CardContent>
          <Grid
            alignItems="flex-start"
            className={classes.superior}
            container
            direction="row"
            justifyContent="space-between">
            <Grid id="view-user" item sm={6} xs={12}>
              <UserViewUser
                classes={classes}
                handleAddAccountCategory={this.handleAddAccountCategory}
                handleChange={this.handleChange}
                handleError={this.props.handleAddError}
                handleTypeChanged={this.handleTypeChanged}
                handleUpdateUser={this.props.handleUpdateUser}
                holder={this.holder}
                loading={loading}
                saveUserSettings={this.saveUserSettings}
                saving={saving}
                saving_user={saving_user}
                setting={setting}
                updateState={state => this.setState(state)}
                user={edit_user}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <UserViewRight
                classes={classes}
                edit_user={edit_user}
                handleAddError={this.props.handleAddError}
                handleChange={this.handleChange}
                handleChangeDashboard={changed_dashboard =>
                  this.setState({ changed_dashboard })
                }
                handleUserRequest={this.props.handleUserRequest}
                height={height_view}
                holder={this.holder}
                loading={loading}
                setting={setting}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ButtonCircularIconLoad
            disabled={!(changed || changed_user || changed_dashboard)}
            handleButtonClick={this.handleSave}
            loading={saving || saving_user || saving_dashboard}
            name="Save"
            transform={false}
            variant="outlined"
          />
          <DivGrow />
          <ButtonCircularIconLoad
            className={classes.button_grey}
            handleButtonClick={() => this.updateUserSettings(edit_user.id)}
            icon={<CachedIcon />}
            loading={loading}
            small
            tooltip="Reload Settings"
          />
        </CardActions>
      </Card>
    );
  }
}

UserView.propTypes = {
  classes: PropTypes.object,
  edit_user: PropTypes.object,
};
const mapStateToProps = state => ({
  me: state.info_user.user,
});
export default connect(mapStateToProps, {
  setUpdateSetting,
  setDashboardViewMode,
})(withStyles(UserView, useStyles));
