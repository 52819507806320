import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { ToolbarShared, columns } from './components';
import { Grid, Paper } from '@mui/material';
import { QuerySharedLinks } from 'graphql/Syncs';
import { GetData, ServerErrorsString } from 'helpers';
class TabObjectsShared extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_clicked: null,
      loading: false,
      rows_edited: [],
      shared_links: [],
      selected: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    this.handleReloadShared();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleReloadShared();
  }
  handleReloadShared = (code, new_page, new_limit) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    if (code && page > 0) {
      page = 0;
    }
    this.setState({ loading: true });
    (async () => {
      QuerySharedLinks(code, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, shared_links, total } = data.sharedLinks;
          if (ok) {
            this.setState({
              loading: false,
              shared_links,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { shared_links, loading } = this.state;
    const { height } = this.props;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-end">
        <Grid item xs={12}>
          <Paper
            style={{
              margin: 2,
              marginTop: 3,
              paddingTop: 3,
              paddingLeft: 2,
            }}>
            <ToolbarShared
              handleAddError={this.props.handleAddError}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleReload={code => this.handleReloadShared(code)}
              loading={this.state.loading}
              row_clicked={this.state.row_clicked}
              rows_edited={this.state.rows_edited}
              selected={this.state.selected}
              setState={state => this.setState(state)}
              shared_links={this.state.shared_links}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            grid
            headers={columns}
            height={height - 80}
            idRow="idLink"
            loading={loading}
            onPageChange={page =>
              this.handleReloadShared(null, page, this.state.limit)
            }
            onRowClicked={row_clicked => this.setState({ row_clicked })}
            onRowEditedChanged={rows_edited => {
              this.setState({ rows_edited });
            }}
            onRowsPerPageChange={limit =>
              this.handleReloadShared(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={shared_links}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabObjectsShared.propTypes = {
  height: PropTypes.number,
};

export default TabObjectsShared;
