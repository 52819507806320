import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { AddLandingObject, setExplorerFolders } from 'redux-store/actions';
import {
  ExplorerFloatingMenuItem,
  ExplorerMenuDelete,
  ExplorerMenuDownload,
  ExplorerMenuShare,
  ExplorerMenuCopy,
  ExplorerMenuCut,
  ExplorerMenuPaste,
  ExplorerMenuNewFolder,
  ExplorerMenuRunProcess,
  GetMenuItems,
} from './components';
import { IsFolder, copyToClipboard } from 'helpers';
import { DialogRenameFolder } from 'components';
import { IsUserClinicalTrial } from 'common';
const useStyles = () => ({
  root: {},
});
class ExplorerFloatingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ReNameFolder: null,
      open_rename: false,
    };
    this.holder = {
      openDelete: null,
      openShare: null,
      openDownload: null,
      openCopy: null,
      openCut: null,
      openPaste: null,
      openNewFolder: null,
      openRunProccess: null,
      isFolder: () => IsFolder(props.itemOver),
    };
  }
  componentDidUpdate() {
    // const { items } = this.state;
    // const { itemOver } = this.props;
    // const is_folder = IsFolder(itemOver);
  }
  handleMenuItemClicked = event => {
    const { id } = event.currentTarget;
    const { itemOver, Folder } = this.props;
    console.log({ id });
    if (id === 'Open Folder' && itemOver) {
      const { idFolder, folders_paths } = itemOver;
      if (folders_paths && folders_paths.length) {
        if (folders_paths[0].name === 'Home') {
          folders_paths.splice(0, 1);
        }
      }
      this.props.setExplorerFolders(folders_paths);
      this.props.history.push(`/explorer/folder/${idFolder}`);
    } else if (id === 'Rename Folder' && itemOver) {
      this.setState({ ReNameFolder: itemOver, open_rename: true });
    } else if (id === 'Delete') {
      this.holder.openDelete(itemOver);
    } else if (id === 'Share') {
      this.holder.openShare();
    } else if (id === 'Download') {
      this.holder.openDownload();
    } else if (id === 'Copy') {
      this.holder.openCopy();
    } else if (id === 'Cut') {
      this.holder.openCut();
    } else if (id === 'Paste') {
      this.holder.openPaste();
    } else if (id === 'New Folder') {
      this.holder.openNewFolder();
    } else if (id === 'DCM To NIfTI') {
      this.holder.openRunProccess(id);
    } else if (id === 'JPGs To DCM') {
      this.holder.openRunProccess(id);
    } else if (id === 'Flat Panel CT Perfusion') {
      this.holder.openRunProccess(id);
    } else if (id === 'Add to Landing') {
      const { idFolderContent } = itemOver;
      if (idFolderContent) {
        this.props.AddLandingObject(itemOver);
        this.props.history.push(
          '/administrator/system_elements/landing_objects/stopped'
        );
      }
    } else if (id === 'Copy File Path') {
      if (Folder) {
        let locations = '';
        const { folders_paths } = Folder;
        if (folders_paths) locations = folders_paths.map(x => x.name).join('/');
        console.log({ locations });
        copyToClipboard(locations);
      }
    }
    this.props.handleClose();
  };
  render() {
    const { contextMenu, itemOver, user, size, selected_objects } = this.props;
    const { open_rename } = this.state;
    const is_folder = IsFolder(this.props.itemOver);
    const items = GetMenuItems(selected_objects, itemOver, size);
    let ExplorerMenu = null;
    if (contextMenu && items.length) {
      ExplorerMenu = (
        <Menu
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          anchorReference="anchorPosition"
          onClose={this.props.handleClose}
          open={contextMenu !== null}>
          {items.map((x, index) => {
            const { name } = x;
            if (name === 'divider') {
              return <Divider key={`menu-explorer-item-${index}`} />;
            }
            let isEnabled = true;
            if (name === 'Paste' && size === 0) {
              isEnabled = false;
            }
            if (IsUserClinicalTrial(this.props.user)) {
              isEnabled = false;
            }
            return (
              <ExplorerFloatingMenuItem
                handleAddError={this.props.handleAddError}
                key={`menu-explorer-item-${index}`}
                {...x}
                handleMenuItemClicked={this.handleMenuItemClicked}
                isEnabled={isEnabled}
              />
            );
          })}
        </Menu>
      );
    }
    let owner = false;
    if (itemOver && user) {
      const { Owner } = itemOver;
      if (Owner) {
        owner = Owner.id === user.id ? true : false;
      }
    }
    return (
      <React.Fragment>
        {ExplorerMenu}
        <ExplorerMenuRunProcess
          CurrentFolder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
          selected_objects={this.props.selected_objects}
        />
        <ExplorerMenuCopy
          CurrentFolder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
          selected_objects={selected_objects}
        />
        <ExplorerMenuCut
          cleanSelections={this.props.cleanSelections}
          CurrentFolder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
          selected_objects={selected_objects}
        />
        <ExplorerMenuPaste
          CurrentFolder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
        />
        <ExplorerMenuDelete
          handleAddError={this.props.handleAddError}
          handleDeleteFoldersAndContent={
            this.props.handleDeleteFoldersAndContent
          }
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
          selected_objects={selected_objects}
        />
        <ExplorerMenuDownload
          handleAddError={this.props.handleAddError}
          handleProgress={this.props.handleProgress}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          selected_objects={selected_objects}
        />
        <ExplorerMenuShare
          handleAddError={this.props.handleAddError}
          handleItemChanged={this.props.handleItemChanged}
          holder={this.holder}
          isFolder={is_folder}
          itemOver={this.props.itemOver}
          owner={owner}
          selected_objects={selected_objects}
        />
        <ExplorerMenuNewFolder
          CurrentFolder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          handleCurrentFolderChanged={this.props.handleCurrentFolderChanged}
          holder={this.holder}
          owner={owner}
        />
        <DialogRenameFolder
          handleClose={(status, rename_folder) => {
            this.setState({ open_rename: false, ReNameFolder: null });
            this.props.handleRenameFolder(status, rename_folder);
          }}
          open={open_rename}
          ReNameFolder={this.state.ReNameFolder}
        />
      </React.Fragment>
    );
  }
}
// <MenuItem onClick={handleClose}>Copy</MenuItem>
// <MenuItem onClick={handleClose}>Print</MenuItem>
// <MenuItem onClick={handleClose}>Highlight</MenuItem>
// <MenuItem onClick={handleClose}>Email</MenuItem>

ExplorerFloatingMenu.propTypes = {
  contextMenu: PropTypes.object,
  handleAddError: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  itemOver: PropTypes.object,
};

ExplorerFloatingMenu.defaultProps = {
  contextMenu: null,
  handleClose: () => '',
  itemOver: null,
  handleDeleteFolders: () => '',
  removeSharedFoldersIds: () => '',
  handleAddError: error => console.log({ error }),
  handleRenameFolder: () => '',
  selected_objects: [],
  handleItemChanged: () => '',
};

const mapStateToProps = state => {
  const { cut, copy, copy_series, copy_files } = state.manager;
  const objects = cut
    .concat(copy)
    .concat(copy_series)
    .concat(copy_files);
  return {
    user: state.info_user.user,
    size: objects.length,
  };
};

export default connect(mapStateToProps, {
  setExplorerFolders,
  AddLandingObject,
})(withRouter(withStyles(ExplorerFloatingMenu, useStyles)));
