import React, { Component } from 'react';
import { ServerErrorsString, GetData } from 'helpers';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import { QuerySummaryPatients } from './utils_patient_query';
import { getVariables } from 'common';
import { initialState } from 'redux-store/reducers/page_settings';

class TotalPatientQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0,
      percentage: 0,
    };
  }
  componentDidMount() {
    this.handleLoadPatientSummary();
  }
  // componentDidUpdate() {
  //   this.handleLoadPatientSummary();
  // }

  handleLoadPatientSummary = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySummaryPatients(getVariables(this.props))
        .then(res => {
          const data = GetData(res);
          const { ok, total, percentage, errors } = data.SummaryPatients;
          if (ok) {
            this.setState({
              total,
              percentage,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { total, percentage, loading } = this.state;
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        total,
        percentage,
        loading,
      });
    });
  }
}

const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, { logout })(TotalPatientQuery);
