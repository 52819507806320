import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SURVEY_PATIENTS = gql`
  query getFilteredPatients($name: String, $page: Int, $limit: Int) {
    allSurveyPatients(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      patients {
        id
        GivenName
        FamilyName
        PatientID
        PatientBirthDate
        number_surveys
        info_consent
        patient_info
        info_center
      }
    }
  }
`;
export const QuerySurveysPatients = (name, page, limit) => {
  console.log('QuerySurveysPatients', { name });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SURVEY_PATIENTS),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const QUERY_SURVEY_AT_PATIENT = gql`
  query($idPatient: LongLong!) {
    surveyAtPatient(idPatient: $idPatient) {
      ok
      errors {
        message
      }
      patient {
        id
        GivenName
        FamilyName
        PatientID
        PatientBirthDate
        number_surveys
        info_consent
        patient_info
        info_center
      }
    }
  }
`;
export const QuerySurveysAtPatient = idPatient => {
  console.log('QuerySurveysAtPatient', { idPatient });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SURVEY_AT_PATIENT),
    variables: {
      idPatient,
    },
  });
};
const MUTATION_CREATE_SURVEY_PATIENT = gql`
  mutation CreatePatient($input: InputSurveyPatient!) {
    createPatient(input: $input) {
      ok
      errors {
        message
      }
      patient {
        id
        GivenName
        FamilyName
        PatientID
        PatientBirthDate
        number_surveys
        info_consent
        patient_info
        info_center
      }
    }
  }
`;

export const MutationCreatePatient = input => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_SURVEY_PATIENT),
    variables: {
      input,
    },
  });
};
