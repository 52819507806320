import axios from 'axios';
import { print } from 'graphql';
import gql from 'graphql-tag';

import { LOGIN_USER, LOGIN_USER_UPDATE, UPDATE_AVATAR_ERROR } from './types';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { setCurrentUser } from './loginActions';
import { GetData, setAuthorizationToken } from 'helpers';

const allUserCollaboratorsMutation = gql`
  query($id: LongLong!) {
    allCollaborators(id: $id) {
      ok
      allCollaborators
      errors {
        path
        message
      }
    }
  }
`;

const MUTATION_UPDATE_AVATAR = gql`
  mutation($id: LongLong!, $avatar: String!) {
    updateAvatar(id: $id, avatar: $avatar) {
      ok
      token
      errors {
        path
        message
      }
    }
  }
`;
const MUTATION_UPDATE_ACCOUNT_DETAILS = gql`
  mutation($id: LongLong!, $input: InputUserDetails!) {
    updateAccountDetails(id: $id, input: $input) {
      ok
      token
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateAccountDetails = user => {
  const { id, address, ...res } = user;
  let { city, country, line_1 } = user;
  if (address) {
    city = address.city;
    country = address.country;
    line_1 = address.line_1;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_ACCOUNT_DETAILS),
    variables: {
      id: user.id,
      input: {
        id,
        city,
        country,
        line_1,
        ...res,
      },
    },
  });
};
export const updateAccountDetails = user => dispatch => {
  console.log('redux-action-updateAccountDetails', user);

  MutationUpdateAccountDetails(user)
    .then(res => {
      const data = GetData(res);
      const { updateAccountDetails: update_data } = data;
      const { ok, token, refreshToken } = update_data;
      if (ok) {
        console.log('redux-action-update - userDetails', user);
        setAuthorizationToken(token, refreshToken);
        setCurrentUser(LOGIN_USER, user, dispatch);
      } else {
        dispatch(updateAccountDetailsError(update_data.errors));
      }
    })
    .catch(err => {
      console.log('redux-action-update-catch-error', err);
      dispatch(
        updateAccountDetailsError({
          path: 'name',
          message: 'Unknown error',
        })
      );
    });
};

export const updateAvatarError = error => {
  return {
    type: UPDATE_AVATAR_ERROR,
    errors: error,
  };
};
export const updateUser = user => dispatch => {
  dispatch({
    type: LOGIN_USER_UPDATE,
    user,
  });
};
export const updateAvatar = user => dispatch => {
  axios
    .post(HTTP_ADDRESS_GRAPHQL, {
      query: print(MUTATION_UPDATE_AVATAR),
      variables: {
        id: user.id,
        avatar: user.avatar,
      },
    })
    .then(res => {
      const data = GetData(res);
      const { ok, errors, token, refreshToken } = data.updateAvatar;
      if (ok && token) {
        user.photo = user.avatar;
        setAuthorizationToken(token, refreshToken);
        setCurrentUser(LOGIN_USER, user, dispatch);
      } else {
        dispatch(updateAvatarError(errors));
      }
    })
    .catch(() => {
      dispatch(
        updateAvatarError([
          {
            path: 'name',
            email: 'Unknown error',
          },
        ])
      );
    });
};
export const updateAccountDetailsError = error => {
  return {
    type: UPDATE_AVATAR_ERROR,
    errors: error,
  };
};

export const allUserCollaborators = () => {
  axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(allUserCollaboratorsMutation),
    // variables: {
    //   id: user.id
    // }
  });
};
