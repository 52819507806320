import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ButtonCircularIconLoad, ProjectVariableTypes } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { GetMap, GetMapResult, UpdateMapVariableType } from './utils_map';
const useStyles = () => ({
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200
  },
});
class GroupObjecTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      type: {
        idType: -1,
        name: '',
        description: '',
      },
    };
    this.holder = { cleanInput: null };
  }
  getFormatSelected = () => {
    const { selected } = this.props;
    let final = [];
    for (let i = 0; i < selected.length; i++) {
      const { folder_content, folder, multi_object } = selected[i];
      if (folder_content) {
        const { Series, File } = folder_content;
        if (Series) final.push(Series);
        else if (File) final.push(File);
      } else if (folder) {
        final.push(folder);
      } else if (multi_object) {
        const { Series, File } = multi_object;
        if (Series) final.push(Series);
        else if (File) final.push(File);
      }
    }
    return final;
  };
  handleUpdateVarTypeMapping = () => {
    const { updating, type } = this.state;
    const selected = this.getFormatSelected();
    console.log({ type, selected });
    if (updating || !selected.length) return;
    const { map_type, maps } = GetMap(selected, type);
    if (!maps.length) {
      this.setState({ updating: false });
      this.props.handleAddError('There are not valid object to map');
      return;
    }
    this.setState({ updating: true });
    (async () => {
      UpdateMapVariableType(map_type, maps)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = GetMapResult(data);
          if (ok) {
            this.setState({
              updating: false,
              type: {
                idType: -1,
                name: '',
                description: '',
              },
            });
            if (this.holder.cleanInput) {
              this.holder.cleanInput();
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddVariableType = type => {
    console.log({ type });
    if (type === undefined || !type) {
      type = {
        idType: -1,
        name: '',
        description: '',
      };
    }
    this.setState({ type });
  };
  handleValueChanged = event => {
    event.preventDefault();
    const { type } = this.state;
    this.setState({ type: { ...type, value: event.target.value } });
  };
  //
  render() {
    const { classes, selected } = this.props;
    const { type } = this.state;
    let disable_all = true;
    if (type.name !== '' && selected.length) {
      disable_all = false;
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        spacing={1}>
        <Grid item xs={4}>
          <ProjectVariableTypes
            idProject={this.props.idProject}
            multiple={false}
            onSelectionChanged={this.handleAddVariableType}
            variant="outlined"
          />
          {/* <InputsVariableTypes
            filter={false}
            holder={this.holder}
            multiple={false}
            onChange={this.handleAddVariableType}
            textfield={{
              fullWidth: true,
              helperText: 'Search specify the variable name',
              label: 'Variable Types',
              margin: 'dense',
              required: true,
            }}
            // value={admin}
            variant="outlined"
          /> */}
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus
            className={classes.textField}
            disabled={type.name !== '' ? false : true}
            fullWidth
            id="value"
            InputLabelProps={{
              shrink: true,
            }}
            label="Value"
            margin="dense"
            name="description"
            onChange={this.handleValueChanged}
            placeholder="Enter the value associeted to the variable type"
            type="value"
            value={type.value ? type.value : ''}
          />
        </Grid>
        <Grid item>
          <ButtonCircularIconLoad
            // className={classes.download}
            disabled={this.state.updating || disable_all}
            handleButtonClick={this.handleUpdateVarTypeMapping}
            loading={this.state.updating}
            small
            tooltip="Update the variable types map"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoFocus
            className={classes.textField}
            disabled
            fullWidth
            id="name"
            InputLabelProps={{
              shrink: true,
              readOnly: true,
            }}
            label="Description"
            margin="dense"
            name="description"
            placeholder="Enter the necessary information for the description for the variable type"
            type="description"
            value={type.description}
          />
        </Grid>
      </Grid>
    );
  }
}

GroupObjecTypes.propTypes = {
  classes: PropTypes.object,
};
GroupObjecTypes.defaultProps = {
  selected: [],
};
export default withStyles(GroupObjecTypes, useStyles);
