import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

import { StyleText } from 'components';

class CardCreateHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
    };
  }

  render() {
    const { image, loading } = this.props;
    let idImage = '';
    // let name = 'Loading...';
    if (!loading && image) {
      idImage = image.idImage;
      // const { RepoTags } = image;
      // if (RepoTags && RepoTags.length > 0) {
      //   name = `${RepoTags[0].name}:${RepoTags[0].tag}`;
      // }
    }
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleClose()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={this.props.icon}
        subheader={this.props.subheader}
        sx={{ paddingBottom: 1, paddingTop: 1 }}
        title={
          <Grid container>
            <StyleText
              fontSize={15}
              fontWeight={1000}
              spacing={2}
              style
              styleBox={{ margin: 0, padding: 0 }}
              text={this.props.title}
            />
            <Grid item style={{ marginLeft: 0 }}>
              {idImage}
            </Grid>
          </Grid>
        }
      />
    );
  }
}

CardCreateHeader.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
CardCreateHeader.defaultProps = {
  handleClose: () => '',
  title: 'Create MB-Docker Image',
  subheader:
    'Include the necessary information to communication with the MB-System',
  icon: <AddIcon style={{ color: 'green' }} />,
};
export default CardCreateHeader;
