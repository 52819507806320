import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import GetAppIcon from '@mui/icons-material/GetApp';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { blue, red } from '@mui/material/colors';
import { ButtonCircularIconLoad, DivGrow } from 'components';

const useStyles = theme => ({
  button_blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  button_red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
});

class ButtonBoxShareDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, owner, open, loading, downloading } = this.props;
    let OpenButton = null;
    if (open) {
      OpenButton = (
        <ButtonCircularIconLoad
          className={classes.button_blue}
          // disabled={!owner}
          handleButtonClick={this.props.handleClickShow}
          icon={<OpenWithIcon />}
          small
          tooltip={this.props.show_text}
        />
      );
    }
    let tooltip_delete = owner ? 'Delete Folder' : 'Remove Shared Folder';
    if (this.props.tooltip_delete) {
      tooltip_delete = this.props.tooltip_delete;
    }
    return (
      <List>
        <ListItem>
          <ButtonCircularIconLoad
            className={classes.button_blue}
            disabled={!owner}
            handleButtonClick={this.props.handleButtonShare}
            icon={<ShareIcon />}
            small
          />
          <ButtonCircularIconLoad
            // className={classes.button_blue}
            disabled={!owner}
            handleButtonClick={this.props.handleClickDownload}
            icon={<GetAppIcon />}
            loading={downloading}
            small
          />
          {OpenButton}
          <DivGrow />
          <ButtonCircularIconLoad
            className={classes.button_red}
            disabled={!owner}
            handleButtonClick={this.props.handleButtonDelete}
            icon={<DeleteIcon />}
            loading={loading}
            small
            tooltip={tooltip_delete}
          />
        </ListItem>
      </List>
    );
  }
}

ButtonBoxShareDelete.propTypes = {
  classes: PropTypes.object,
  handleButtonDelete: PropTypes.func,
  handleButtonShare: PropTypes.func,
  icon: PropTypes.object,
  loading: PropTypes.bool,
  owner: PropTypes.bool,
};
ButtonBoxShareDelete.defaultProp = {
  loading: false,
  downloading: false,
  owner: false,
  open: false,
  show_text: '',
  tooltip_delete: null,
};

export default withStyles(ButtonBoxShareDelete, useStyles);
