import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Input, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { v1 as uuid } from 'uuid';
import { inputProps } from 'common';

const useStyles = makeStyles()(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px',
  },
}));

const SearchInput = props => {
  let { id, className, onChange, style, value, ...rest } = props;
  if (value === null) {
    value = undefined;
  }
  const { classes } = useStyles();
  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        id={id}
        inputProps={inputProps}
        onChange={onChange}
        value={value}
      />
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};
SearchInput.defaultProps = {
  id: uuid(),
};
export default SearchInput;
