import { IsInvalid, FormatTime, IsInvalidParam } from 'helpers';

export const columns_ano_pro_tags = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'idRule', headerName: 'ID Rule', align: 'left' },
  {
    field: 'project',
    headerName: 'Project',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const project = params.getValue(params.id, 'project');
      if (IsInvalid(project)) return '';
      return project.name;
    },
  },
  {
    field: 'rule',
    headerName: 'Tag Name',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const rule = params.getValue(params.id, 'rule');
      if (IsInvalid(rule)) return '';
      return rule.tag_name;
    },
  },
  {
    field: 'tag_rule',
    headerName: 'Tag Rule',
    edit: true,
    align: 'left',
  },
  {
    field: 'tag_value',
    headerName: 'Tag Value',
    edit: true,
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
