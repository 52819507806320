import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { grey } from '@mui/material/colors';
import { Doughnut } from 'react-chartjs-2';

const useStyles = theme => ({
  chartContainerNerve: {
    position: 'relative',
    height: '240px',
  },
  tableDoughnut: {
    width: '50px',
    padding: 0,
  },
  columnNames: {
    width: '50px',
  },
  columnValues: {
    width: '85px',
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(0.5),
  },
  table: {
    minHeight: '374px',
    maxHeight: 400,
  },
  table_body: {
    minHeight: '374px',
  },
  tablerow: {
    margin: 0,
    padding: 0,
  },
  tablecell: {
    padding: 0,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    margin: theme.spacing(0.5),
    width: '5',
    height: '5px',
  },
});

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: grey[600],
    backgroundColor: grey[50],
    titleFontColor: grey[600],
    bodyFontColor: grey[600],
    footerFontColor: grey[600],
  },
};

function DoughnutView(props) {
  const { classes, data, titles } = props;

  return (
    <Grid
      alignItems="center"
      columns={12}
      container
      direction="row"
      justifyContent="center">
      <Grid item xs={8}>
        <Box ml={1} mt={5}>
          <div className={classes.chartContainerNerve}>
            <Doughnut data={data} options={options} redraw />
          </div>
        </Box>
      </Grid>
      <Grid container item xs={4}>
        <Box mt={5}>
          <List>
            {titles.map((device, i) => (
              <ListItem key={`box-item-top-nerve-${i}`} sx={{ margin: 0 }}>
                <Box
                  bgcolor={device.color}
                  className={classes.status}
                  color="white"
                  p={1}
                />
                <Typography
                  align="left"
                  color={device.color}
                  m={0}
                  p={0}
                  variant="caption">
                  {device.title} ({device.value}%)
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
}

export default withStyles(DoughnutView, useStyles);
