import React from 'react';
import { withStyles } from 'tss-react/mui';
import ProtoTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Delete as DeleteIcon,
  ErrorOutline as ErrorOutlineIcon,
  Publish as PublishIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import { GetData, ServerErrors, GetThumbnailFile, FormatBytes } from 'helpers';
import { ReadyIcon } from '../../../helpers';
import { CircularLoading, SnackMessage } from 'components';
import { IsFile } from './components';
import {
  GetFileObjectName,
  GetContentSize,
  GetError,
  GetPatientSeriesInfo,
  GetWarning,
  IsContentUploaded,
  IsError,
  IsWarning,
} from './components/utils_content';
import { setSkipOnline } from 'redux-store/actions/page_globals';
import { MutationUploadFile } from 'common';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    boxShadow: theme.spacing(6),
  },
  avatar: {},
  avatar_downloaded: {
    backgroundColor: 'green',
  },
  avatar_warning: {
    backgroundColor: 'orange',
  },
  avatar_error: {
    backgroundColor: 'red',
  },
});

class UploadItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: props.index,
      loading: false,
      secondary: false,
      item_progress: 0,
      uploaded: false,
      loaded: 0,
    };
  }

  componentDidUpdate() {
    if (this.props.index !== this.state.index) {
      this.setState({ index: this.props.index });
    }
  }

  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const item_progress = Math.floor((loaded * 100) / total);
    this.setState({ item_progress, loaded });
  };
  handleUpload = () => {
    const { item, holder } = this.props;
    if (!IsFile(item)) {
      return;
    }
    let settings = null;
    if (holder && holder.getSettings) {
      settings = holder.getSettings();
    }
    console.log({ settings });
    this.setState({ loading: true });
    this.props.setSkipOnline(true);
    (async () => {
      MutationUploadFile(item, this.handleProgress, null, settings)
        .then(res => {
          const data = GetData(res);
          const {
            uploadFile: { ok, errors },
          } = data;
          let uploaded_content = null;
          if (ok) {
            const { contents, warnings } = data.uploadFile;
            uploaded_content = {
              filename: item.name,
              filesize: item.size,
              content: contents && contents.length ? contents[0] : null,
              warnings: warnings && warnings.length ? warnings : [],
            };
          } else {
            throw errors;
          }
          this.props.setSkipOnline(false);
          this.props.handleUplodedItem(uploaded_content, this.props.index);
        })
        .catch(error => {
          this.props.setSkipOnline(false);
          const new_information = {
            filename: item.name,
            filesize: item.size,
            file: item,
            errors: ServerErrors(error),
          };
          this.props.handleUplodedItem(new_information, this.props.index);
        });
    })();
  };
  handleCloseWarningError = () => {
    let { content } = this.props;
    if (!content) {
      content = {
        upload_warning: 'Clear',
      };
    } else {
      content.upload_warning = 'Clear';
    }
    this.props.handleInformation(this.state.index, content);
  };
  handleCloseUploadError = () => {
    let { content } = this.props;
    if (!content) {
      content = {
        upload_error: 'Clear',
      };
    } else {
      content.upload_error = 'Clear';
    }
    this.props.handleInformation(this.state.index, content);
    this.setState({ errors: '' });
  };

  render() {
    const { item_progress } = this.state;
    let { loading, loaded } = this.state;
    const { classes, item, handleDelete, disabled } = this.props;
    let external_loading = false;
    if (item.loaded) loaded = item.loaded;
    if (item.loading) external_loading = item.loading;
    let uploaded = IsContentUploaded(item);
    let warning = IsWarning(item);
    let error = IsError(item);
    const { PatientName, SeriesDescription } = GetPatientSeriesInfo(item);
    const filename = GetFileObjectName(item);
    let thumbnail = GetThumbnailFile(filename);
    let text_waring_or_error = '';
    if (warning) {
      text_waring_or_error = GetWarning(item);
    } else if (error) {
      text_waring_or_error = GetError(item);
    }
    let ComponentDownloaded = null;
    if (loaded) {
      const total = FormatBytes(loaded, 2);
      ComponentDownloaded = (
        <Grid item>
          <Typography variant="body2">{total}</Typography>
        </Grid>
      );
    }
    let uploading = loading || external_loading;
    return (
      <Paper className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={item ? (item.name ? item.name[0] : 'NN') : 'NN'}
              className={clsx({
                [classes.avatar]: true,
                [classes.avatar_downloaded]: uploaded,
                [classes.avatar_warning]: warning,
                [classes.avatar_error]: error,
              })}
              src={thumbnail}
            />
          </ListItemAvatar>
          <ListItemText primary={filename} secondary={GetContentSize(item)} />
          {uploaded && (
            <ListItemText primary={PatientName} secondary={SeriesDescription} />
          )}
          <ListItemSecondaryAction>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-end">
              {ComponentDownloaded}
              {uploaded ? (
                <Tooltip title={text_waring_or_error}>
                  <span>
                    <IconButton
                      aria-label="ready"
                      disabled
                      edge="end"
                      size="large">
                      {warning ? (
                        <ErrorOutlineIcon
                          style={{ fontSize: 40, color: 'orange' }}
                        />
                      ) : error ? (
                        <ErrorIcon sx={{ color: 'red' }} />
                      ) : (
                        <ReadyIcon
                          color="primary"
                          style={{ fontSize: 40, color: 'green' }}
                          viewBox={'0 0 16 16'}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              ) : uploading ? (
                <Grid item pl={2}>
                  <CircularLoading simple size={30} small />
                </Grid>
              ) : (
                <IconButton
                  aria-label="upload"
                  disabled={disabled}
                  edge="end"
                  onClick={this.handleUpload}
                  size="large">
                  <PublishIcon style={{ color: disabled ? 'gray' : 'green' }} />
                </IconButton>
              )}
              <IconButton
                aria-label="delete"
                disabled={uploading}
                edge="end"
                onClick={() => handleDelete(item)}
                size="large">
                <DeleteIcon style={{ color: uploading ? 'gray' : 'red' }} />
              </IconButton>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        {(loading || item_progress > 0) && (
          <LinearProgress value={item_progress} variant="determinate" />
        )}
        <SnackMessage
          handleClose={this.handleCloseWarningError}
          message_text={GetWarning(item)}
          open={IsWarning(item)}
          type="warning"
        />
        <SnackMessage
          handleClose={this.handleCloseUploadError}
          message_text={GetError(item)}
          open={IsError(item, true)}
          type="error"
        />
      </Paper>
    );
  }
}

UploadItem.protoTypes = {
  classes: ProtoTypes.object.isRequired,
  disabled: ProtoTypes.bool,
  handleDelete: ProtoTypes.func,
  upload: ProtoTypes.bool,
  item: ProtoTypes.object,
  progress: ProtoTypes.number,
  handleInformation: ProtoTypes.func,
};
UploadItem.defaultProps = {
  handleInformation: () => '',
  disabled: false,
};
export default connect(null, {
  setSkipOnline,
})(withStyles(UploadItem, useStyles));
