import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IsInvalid, ServerErrorsString, GetData } from 'helpers';
import {
  MutationAddEncryptionPatients,
  MutationRemoveEncryptionPatients,
  MutationUploadEncryptionPatientsCSV,
} from 'graphql/Anonymization';
import {
  DialogEditPatientConsent,
  DialogCreateEncryptedPatient,
} from './components';
class TabEncryptionPatientToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      uploading: false,
      open_create: false,
      deleting: false,
      open_edit: false,
      text: '',
    };
  }
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { selected, patients, total } = this.props;
    const { deleting } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    (async () => {
      MutationRemoveEncryptionPatients(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeEncryptionPatients;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              patients: patients.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveChanges = () => {
    const { rows_edited } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    console.log({ rows_edited });
    // return;
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      const {
        // eslint-disable-next-line no-unused-vars
        createdAt,
        // eslint-disable-next-line no-unused-vars
        updatedAt,
        // eslint-disable-next-line no-unused-vars
        address,
        // eslint-disable-next-line no-unused-vars
        UniqueKeyIdentifier,
        // eslint-disable-next-line no-unused-vars
        PatientName,
        // eslint-disable-next-line no-unused-vars
        patient_consent,
        ...res
      } = x;
      return {
        ...res,
      };
    });
    console.log({ inputs, rows_edited });
    this.setState({ saving: true });
    (async () => {
      MutationAddEncryptionPatients(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.addEncryptionPatients;
          if (ok) {
            this.setState({ saving: false });
            this.props.handleEncryptedPatientCreated(patients, true);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadEncryptionPatientsCSV(files[0], this.props.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.uploadEncryptionPatients;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleEncryptedPatientCreated(patients);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleEncryptedPatientCreated = new_patients => {
    this.setState({ open_create: false });
    this.props.handleEncryptedPatientCreated(new_patients);
  };
  handleTextSearch = text => {
    const query = this.getQuery(text);
    if (query) this.props.handleReload(query);
    else this.props.handleReload();
    this.setState({ text });
  };
  getQuery = text => {
    text = text.trim();
    // console.log({ text });
    if (text === '') return null;
    else {
      const tocheck = [
        'PatientName',
        'PatientID',
        'GivenName',
        'FamilyName',
        'NamePrefix',
        'MiddleName',
        'type',
        'PatientSex',
        'PatientBirthDate',
        'PatientAge',
      ];
      const parts = text.split(';');
      let query = null;
      for (let i = 0; i < parts.length; i++) {
        const value = parts[i].trim();
        for (let j = 0; j < tocheck.length; j++) {
          const element = tocheck[j];
          if (value.includes(`${element}:`)) {
            query = {
              ...query,
              [element]: value.split(`${element}:`)[1].trim(),
            };
          }
        }
      }
      if (IsInvalid(query)) {
        query = {
          PatientName: text,
          PatientID: text,
          NamePrefix: text,
          NameSuffix: text,
          type: text,
        };
      }
      return query;
    }
  };
  render() {
    const { classes, selected, rows_edited } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item md={3.5} sm={5} xs={12}>
            <ButtonGroup
              className={classes.button_group}
              exclusive="false"
              size="small"
              value="save">
              <Button
                disabled={!rows_edited.length}
                onClick={this.handleSaveChanges}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </Button>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <Button onClick={() => this.setState({ open_edit: true })}>
                <EditIcon style={{ color: 'red' }} />
              </Button>
              <ButtonDownloadType
                idProject={this.props.idProject}
                inputs={this.getQuery(this.state.text)}
                key="encryptions"
                type="encrypted_patients"
              />
              <ButtonFileUpload
                handleSelectFile={this.handleUploadSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={text => this.handleTextSearch(text)}
              id="id-search-encrypted-patient"
              loading={this.props.loading}
              placeholder="Search Encrypted patient"
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            mr={1}
            xs={2}>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogCreateEncryptedPatient
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleEncryptedPatientCreated={this.handleEncryptedPatientCreated}
          idProject={this.props.idProject}
          open={this.state.open_create}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the encrypted patients, this action cannot be undone"
          title_text="Delete Selected Encrypted Patients"
        />
        <DialogEditPatientConsent
          handleAddError={this.props.handleAddError}
          handleAddPatientConsents={this.props.handleAddPatientConsents}
          handleClose={() => this.setState({ open_edit: false })}
          idProject={this.props.idProject}
          open={this.state.open_edit}
          selected={selected}
        />
      </React.Fragment>
    );
  }
}

TabEncryptionPatientToolbar.propTypes = {
  classes: PropTypes.object,
};

export default TabEncryptionPatientToolbar;
