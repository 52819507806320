/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { lighten } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Toolbar, Typography, Grid } from '@mui/material';
import { blue } from '@mui/material/colors';

import { ButtonReloadDelete } from 'components';

const useToolbarStyles = (theme, props) => ({
  root: {
    width: '100%',
    minWidth: 250,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: props.transform ? '20px' : '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: props.transform ? 'none' : null,
  },
});

class MainTableToolbar extends Component {
  render() {
    const {
      numSelected,
      classes,
      title,
      handleDeleteSelected,
      loading,
      handleReloadTable,
      handleClearSelected,
      tooltip,
      loading_table,
      ComponentAdditional,
    } = this.props;
    let SelectedText = null;
    if (numSelected > 0) {
      SelectedText = (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1">
          {numSelected} selected
        </Typography>
      );
    } else {
      SelectedText = (
        <Typography
          className={classes.title}
          id="tableTitle"
          variant="subtitle1">
          {title}
        </Typography>
      );
    }
    let GridComponentAddition = null;
    if (ComponentAdditional) {
      GridComponentAddition = <Grid item>{ComponentAdditional}</Grid>;
    }
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <Grid item>{SelectedText}</Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={5}>
            {GridComponentAddition}
            <Grid item>
              <ButtonReloadDelete
                handleClearSelected={handleClearSelected}
                handleDeleteSelected={handleDeleteSelected}
                handleReloadTable={handleReloadTable}
                hide_reload={this.props.hide_reload}
                loading={loading}
                loading_table={loading_table}
                numSelected={numSelected}
                tooltip={tooltip}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

MainTableToolbar.propTypes = {
  ComponentAdditional: PropTypes.object,
  handleClearSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  transform: PropTypes.bool,
};
MainTableToolbar.defaultProps = {
  loading_table: false,
  loading: false,
  tooltip: 'Delete',
  handleClearSelected: () => '',
  handleReloadTable: () => '',
  handleDeleteSelected: () => '',
  ComponentAdditional: null,
  numSelected: 0,
  transform: false,
  hide_reload: false,
};
export default withStyles(MainTableToolbar, useToolbarStyles);
