import { LAYOUT_SET } from './types';

export const setLayout = (
  isDesktop,
  isTablet,
  isMobile,
  isMobileSmall
) => dispatch => {
  //
  dispatch({
    type: LAYOUT_SET,
    isDesktop,
    isTablet,
    isMobile,
    isMobileSmall,
  });
};
