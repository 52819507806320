import React from 'react';
import PropTypes from 'prop-types';
import { DialogCreateFolder } from 'components';
class ExplorerMenuNewFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    if (props.holder) {
      props.holder.openNewFolder = () => this.setState({ open: true });
    }
  }
  handleCreatedFolder = folder => {
    const { CurrentFolder } = this.props;
    if (CurrentFolder === undefined) return;
    const index = CurrentFolder.SubFolders.map(x => x.idFolder).indexOf(folder.idFolder);
    if (index === -1) {
      CurrentFolder.SubFolders.push(folder);
      this.props.handleCurrentFolderChanged();
    }
  };
  render() {
    const { CurrentFolder } = this.props;
    return <DialogCreateFolder
      handleClose={() => this.setState({ open: false })}
      handleCreatedFolder={this.handleCreatedFolder}
      idParent={CurrentFolder.idFolder}
      open={this.state.open}
    />;
  }
}

ExplorerMenuNewFolder.propTypes = {
  classes: PropTypes.object
};

export default ExplorerMenuNewFolder;
