import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Checkbox, Grid, TableCell, TableRow } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TableObjectItem, TablePermissionItem } from './components';
import useStyles from './components/Styles';
import { FormatTime } from 'helpers';
import { getRowProjectObjects } from '../../../utils_tag_projectfiles';
import { ButtonCircularDownloadFolderContent } from 'components';
import { IsInvalid } from 'helpers';

class FolderContentTableRow extends Component {
  IsFile = () => {
    const { folder_content } = this.props.row;
    if (folder_content === undefined || !folder_content) return false;
    const { File } = folder_content;
    if (typeof File === 'undefined' || !File) return false;
    return true;
  };
  handleOpenInExplorer = () => {
    const { row } = this.props;
    if (IsInvalid(row)) return;
    const { folder_content, folder } = row;
    if (folder_content) {
      const { idFolder } = folder_content;
      if (idFolder) {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      }
    } else if (folder) {
      if (folder.idParent) {
        this.props.history.push(`/explorer/folder/${folder.idParent}`);
      } else if (folder.idFolder) {
        this.props.history.push(`/explorer/folder/${folder.idFolder}`);
      } else {
        this.props.history.push('/explorer');
      }
    }
  };
  render() {
    const {
      classes,
      isItemSelected,
      labelId,
      dense,
      handleSelected,
      projectId,
      disabled,
      disable_checkbox,
    } = this.props;
    const row = getRowProjectObjects(this.props.row);
    let ButtonClick = null;
    if (this.IsFile()) {
      ButtonClick = (
        <Grid container>
          <Grid item>
            <Button onClick={this.handleOpenInExplorer} variant="outlined">
              Explorer
            </Button>
          </Grid>
          <Grid item>
            <ButtonCircularDownloadFolderContent
              content={this.props.row}
              handleDownloadTriggered={() =>
                this.props.handleContentView(this.props.row)
              }
            />
          </Grid>
        </Grid>
      );
    } else {
      ButtonClick = (
        <Grid container>
          <Grid item pr={2}>
            <Button
              className={clsx({
                [classes.button_no_dense]: true,
                [classes.button_dense]: dense,
              })}
              onClick={this.handleOpenInExplorer}
              variant="outlined">
              Explorer
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={clsx({
                [classes.button_no_dense]: true,
                [classes.button_dense]: dense,
              })}
              color="primary"
              disabled={disabled}
              onClick={() => this.props.handleContentView(this.props.row)}
              variant="outlined">
              {row.kind === '--' ? 'Open' : 'View'}
            </Button>
          </Grid>
        </Grid>
      );
    }
    let ComponentCheckBox = null;
    let padding = null;
    if (!disable_checkbox) {
      padding = 'checkbox';
      ComponentCheckBox = (
        <Checkbox
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={() => {
            handleSelected(row);
          }}
        />
      );
    }
    return (
      <TableRow
        className={classes.tableRow}
        dense="true"
        hover
        key={`table-row-${row.id}`}
        selected={isItemSelected}
        tabIndex={-1}>
        <TableCell padding={padding} style={{ margin: 0, padding: 0 }}>
          {ComponentCheckBox}
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          padding="none"
          scope="row"
          style={{ margin: 0, padding: 0 }}>
          <TableObjectItem isDense={dense} projectId={projectId} row={row} />
        </TableCell>
        <TableCell align="center" style={{ margin: 0, padding: 0 }}>
          <TablePermissionItem dense row={row} />
        </TableCell>
        <TableCell align="right" style={{ margin: 0, padding: 0 }}>
          {FormatTime(row.data_modified, 'dd/MM/yyyy - HH:mm')}
        </TableCell>
        <TableCell align="right" style={{ margin: 0, padding: 0 }}>
          {row.size}
        </TableCell>
        <TableCell
          align="left"
          style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
          {row.kind}
        </TableCell>
        <TableCell align="right" style={{ margin: 0, padding: 0 }}>
          {ButtonClick}
        </TableCell>
      </TableRow>
    );
  }
}

FolderContentTableRow.propTypes = {
  classes: PropTypes.object,
};
FolderContentTableRow.defaultProps = {
  handleContentView: () => '',
  loading: false,
};

export default withStyles(FolderContentTableRow, useStyles);
