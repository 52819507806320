import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

class ProjectFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_name: '',
      set: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getFolderName = () => this.state.project_name;
    }
  }

  setFromProps = () => {
    const {
      project: { ProjectFolder },
    } = this.props;
    if (ProjectFolder === undefined) return;
    this.setState({ project_name: ProjectFolder.name, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    if (!this.state.set) {
      this.setFromProps();
    }
  }

  handleChange = event => {
    event.persist();
    const project_name = event.target.value;
    this.setState({ project_name });
    const {
      project: { ProjectFolder },
    } = this.props;
    if (typeof ProjectFolder === 'undefined') return;
    this.props.handleChange(
      'project_name',
      ProjectFolder.name !== event.target.value
    );
  };

  render() {
    const { project_name } = this.state;
    const { admin, project } = this.props;
    let label = 'Folder Name';
    if (project) {
      const { ProjectFolder } = project;
      if (ProjectFolder) {
        label = `${label} (Folder ID: ${ProjectFolder.idFolder})`;
      } else {
        /** empty */
      }
    }
    return (
      <TextField
        disabled={!admin}
        fullWidth
        id="project_name"
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        margin="normal"
        name="project_name"
        // style={{ margin: 8 }}
        onChange={this.handleChange}
        placeholder="Set the project folder name that will containe the project files"
        value={project_name}
        variant="outlined"
      />
    );
  }
}

ProjectFolder.propTypes = {
  classes: PropTypes.object,
};
ProjectFolder.defaultProps = {
  admin: false,
};

export default ProjectFolder;
