import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { columns } from './index';
import useStyles from 'components/Tables/components/Styles';

import { MainTable } from 'components/Tables/components';
import { SelectedArray, GetData, rowsPerPageBig } from 'helpers';
import { TableToolbarMemberSetting } from './components';
import { DialogDeleteWarning } from 'components';
import { MutationDeleteProjectMembersSettings } from 'graphql/Projects';
import { ServerErrorsString } from 'helpers';

class TableProjectMemberSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      selected: [],
      deleting: false,
      error: '',
      open_delete: false,
    };
  }
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.member_settings });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    console.log({ event, index, row });
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangePageLimit(0, +event.target.value);
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };
  handleDeleteSelected = () => {
    const { deleting, selected } = this.state;
    const { member_settings, original, project_setting } = this.props;
    if (deleting) return;
    this.setState({ deleting: true, open_delete: false });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteProjectMembersSettings(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteProjectMemberSetting;
          if (ok) {
            this.setState({ deleting: false, selected: [] });
            this.props.handleUpdateProjectSetting(
              {
                ...project_setting,
                member_settings: member_settings.filter(
                  x => ids.indexOf(x.id) === -1
                ),
              },
              {
                ...original,
                member_settings: original.member_settings.filter(
                  x => ids.indexOf(x.id) === -1
                ),
              }
            );
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, loading, handleReloadTable, height } = this.props;
    let { selected, page, limit, deleting } = this.state;
    let count = this.props.member_settings.length;
    if (this.props.total && this.props.total > count) {
      count = this.props.total;
    }
    if (this.props.page !== undefined && this.props.limit !== undefined) {
      page = this.props.page;
      limit = this.props.limit;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <TableToolbarMemberSetting
          deleting_member={deleting}
          handelAddMember={this.props.handelAddMember}
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={() => this.setState({ open_delete: true })}
          handleEditSelected={() =>
            this.props.handleEditMembersSettings(this.state.selected)
          }
          handleReloadTable={handleReloadTable}
          handleSearchByName={this.props.handleSearchByName}
          loading_table={loading}
          numSelected={selected.length}
          title="Project Members Settings"
          transform
        />
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            dense
            disable_checkbox={this.props.disable_checkbox}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.props.handleClickedRow}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            nowrap
            page={page}
            rows={this.props.member_settings}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={count}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the selected member settings"
          title_text="Delete Selected Member Setting"
        />
      </Paper>
    );
  }
}

TableProjectMemberSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  deleting: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  member_settings: PropTypes.array.isRequired,
};
TableProjectMemberSetting.defaultProps = {
  member_settings: [],
  loading: false,
  deleting: false,
  disable_checkbox: false,
  handleReloadTable: () => console.log('handleReloadTable'),
  handleDeleteSelected: () => console.log('handleDeleteSelected'),
  handleChangePageLimit: () => console.log('handleChangePageLimit - external'),
  handleShowTeam: () => console.log('handleShowTeam'),
  handleEditMembersSettings: () => '',
  handleClickedRow: () => '',
  handleSearchByName: () => '',
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
};
export default withStyles(TableProjectMemberSetting, useStyles);
