import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { SearchCentersInputs } from 'components/Search';
import { ButtonCircularIconLoad } from 'components/Buttons';
import { TextFieldCustom } from 'components/Texts';

class ToolBarSearchCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, changed, admin } = this.props;
    let ComponentSave = null;
    let xs = 9;
    if (admin) {
      xs = 7;
      ComponentSave = (
        <Grid item>
          <ButtonCircularIconLoad
            disabled={!changed}
            handleButtonClick={this.props.handleSaveClicked}
            loading={loading}
            small
          />
        </Grid>
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}>
        <Grid item sx={{ paddingBottom: 2 }} xs={xs}>
          <SearchCentersInputs
            handleAddCenter={this.props.handleAddCenter}
            holder={this.props.holder}
            placeholder="Search Center..."
          />
        </Grid>
        <Grid item sx={{ paddingBottom: 2 }} xs={3}>
          <TextFieldCustom
            id="code-center"
            label="Center Code"
            onChange={this.props.onChangeCenterName}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleKeyDownCode();
              }
            }}
            required
            size="small"
            value={this.props.code}
            variant="outlined"
          />
        </Grid>
        {ComponentSave}
      </Grid>
    );
  }
}

ToolBarSearchCenters.propTypes = {
  classes: PropTypes.object,
};

export default ToolBarSearchCenters;
