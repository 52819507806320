/* eslint-disable indent */
/* eslint-disable quotes */
import React from 'react';
import { Paper, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import validate from 'validate.js';
import { GetThumbnailFile } from 'helpers/files_utils';

const schema = {
  email: {
    presence: { allowEmpty: true, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
};
export const IsValidUser = saved_user => {
  return !(
    saved_user === undefined ||
    !saved_user ||
    saved_user.id === undefined
  );
};
export const IsInvalidUser = user => {
  if (IsInvalid(user)) return true;
  const { id } = user;
  if (IsInvalid(id)) return true;
  return false;
};
export const IsInvalid = (input, text = false) => {
  if (input === null || input === undefined) return true;
  if (text && !input) return true;
  return false;
};
export const IsInvalidParam = (params, text = false) => {
  if (IsInvalid(params, text)) return true;
  if (IsInvalid(params.getValue, text)) return true;
  return false;
};
export const IsValid = input => {
  if (input === null || input === undefined) return false;
  return true;
};
export const ErrorsList = (errors, field) => {
  let values = [];
  if (!errors) {
    return ['Unknown error'];
  }
  if (Object.prototype.hasOwnProperty.call(errors, field)) {
    values = errors[field];
  } else {
    for (let key in errors) {
      const { message } = errors[key];
      if (message) {
        values.push(message);
      }
    }
    if (values.length === 0) {
      values = ['Unknown error'];
    }
  }

  return values.map((error, i) => {
    return (
      <Typography
        display="block"
        key={`error-${field}-${i}`}
        style={{
          fontSize: '0.5rem',
          marginTop: '-1px',
          marginButtom: '-1px',
          color: 'red',
        }}
        variant="caption">
        - {error}
      </Typography>
    );
  });
};
const GetBadUserInputError = (x, code) => {
  if (IsInvalid(x) || x === '') return x;
  if (x.startsWith('BAD_USER_INPUT:') || code === 'BAD_USER_INPUT') {
    const parts = x
      .replace('BAD_USER_INPUT:', '')
      .trim()
      .split('; Field');
    if (parts.length === 2) {
      return `Field ${parts[1]}`;
    }
  }
  return x;
};
export const ServerErrorsString = (errors, key = '\n') => {
  return ServerErrors(errors).join(key);
};
export const ServerErrors = errors => {
  if (typeof errors === 'undefined' || !errors) {
    return ['Errors is undefined'];
  }

  if (errors instanceof TypeError || errors instanceof Error) {
    let { message, name, response, request } = errors;
    console.log('error', { message, name, response, request });
    message = GetBadUserInputError(message);
    if (message === 'Network Error') {
      if (response) {
        // client received an error response (5xx, 4xx)
        message = 'Client received an error response';
      } else if (request) {
        // client never received a response, or request never left
        message = 'Client never received a response, or request never left';
      }
    } else if (response) {
      try {
        if (response.data instanceof Blob) {
          // let blob = new Blob([response.data]);
          let blob = response.data;
          console.log({ blob });
          let json;
          try {
            let buffer = new Buffer.from(blob.arrayBuffer());
            let jsonString = buffer.toString();
            console.log(jsonString);
            json = JSON.parse(jsonString);
            console.log(json);
          } catch (err) {
            console.log('B');
            console.log({ err });
          }
        } else {
          const data_error = GetData(response);
          console.log({
            data_error,
          });
        }
      } catch (error) {
        console.log('C');
        return ServerErrors(error);
      }
    }
    if (message && name) {
      return [`${name}: ${message}`];
    } else if (message) {
      return [`${message}`];
    } else {
      return ['Unknown error type'];
    }
  }
  if (errors && typeof errors === 'string' && errors.constructor === String) {
    return [`${errors}`];
  }

  if (errors && typeof errors === 'object' && errors.constructor === Object) {
    const { error } = errors;
    if (error) {
      return [`${error}`];
    }
    return [`${errors}`];
  }
  let final_errors = errors.map(x => {
    if (x && typeof x === 'string' && x.constructor === String) {
      return GetBadUserInputError(x);
    }
    let { message, extensions } = x;
    let server_code = '';
    let server_message = 'Unknown internal server error';
    if (extensions && typeof extensions !== 'undefined') {
      const { code } = extensions;
      if (code) {
        server_code = code;
      }
    }
    if (message && message !== '') {
      server_message = GetBadUserInputError(message, server_code);
    }
    if (server_code === '') {
      return `${server_message}`;
    }
    return `${server_code}: ${server_message}`;
  });
  console.log({ final_errors });
  if (final_errors.length > 10) {
    const total = final_errors.length;
    final_errors = final_errors.splice(0, 10);
    final_errors.push(`\nThere are ${total - 10} more errors...`);
  }
  return final_errors;
};
export const sleep = (delay = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export const PaperComponent = props => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export const FindKey = (object, key) => {
  if (object) {
    return Object.keys(object).some(k => key === k);
  }
  return false;
};

export const getLevels = props => {
  const { match } = props;
  if (!match) {
    return {};
  }
  const { url, params } = match;
  if (!params) {
    return { url };
  }
  const { level_1, level_2, level_3 } = params;
  return {
    level_1,
    level_2,
    level_3,
    url,
  };
};
export const getParams = props => {
  const { match } = props;
  if (!match) {
    return null;
  }
  const { params } = match;
  if (IsInvalid(params)) {
    return null;
  }
  return params;
};
export const getParamsUrl = props => {
  const { match } = props;
  if (!match) {
    return null;
  }
  const { url } = match;
  if (!url || typeof url === 'undefined') {
    return null;
  }
  return url;
};

export const getInt = text => {
  const num = parseInt(text, 10);
  if (typeof num === 'undefined' || isNaN(num)) return null;
  return num;
};

export const IsValidEmail = email => {
  const validation = validate(email, schema);
  if (validation) {
    // this.setState({ errors: validation });
    return validation;
  }
  return null;
};

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const units_max = [
  1024, //0
  1048000, //1
  999999999, //2
  999999999999, //3
  1125000000000000,
  1000000000000000000,
  1000000000000000000000,
  1000000000000000000000000,
];
export const GetUnitIndex = x => {
  return units.indexOf(x);
};
export const FormatBytes = (x, digits = null) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  if (digits && l >= 3) {
    /* empty */
  } else {
    digits = 1;
  }
  return n.toFixed(n < 10 && l > 0 ? digits : 0) + ' ' + units[l];
};
export const FormatNumber = to_format => {
  const full = {
    number: 0,
    format: 'Bytes',
  };
  let number = FormatBytes(to_format);
  let parts = number.split(' ');
  if (parts.length === 2) {
    full.number = parseFloat(parts[0]).toFixed(2);
    full.format = parts[1];
  }
  return full;
};
export const GetUnit = x => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return units[l];
};
export const FormatToBytes = x => {
  const parts = x.split(' ');
  if (parts.length !== 2) return x;
  let l = 0,
    n = parseInt(parts[0], 10) || 0;
  // console.log({ n });
  if (n === 0) {
    if (parts[1] === 'Bytes') return 0;
    let i = units.indexOf(parts[1]);
    // console.log({ i, p1: parts[1], b: units[i - 1] });
    i = units.indexOf(units[i - 1]);
    // console.log({ i });
    if (i === 0 || i === -1) return 1023;
    return units_max[i];
  } else {
    while (units[l++] !== parts[1]) {
      n = n * 1024;
    }
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return parseInt(n, 10);
};
export const SelectedArray = (selected, value, index) => {
  let newSelected = [];

  if (index === -1) {
    newSelected = newSelected.concat(selected, value);
  } else if (index === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (index === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (index > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, index),
      selected.slice(index + 1)
    );
  }
  return newSelected;
};
export const GetResponseError = async error => {
  const { response } = error;
  if (response && response.data instanceof Blob) {
    let reply = new Response(response.data);
    let json = await reply.json();
    const { errors } = json;
    error.response.data = { errors: [errors] };
  }
  return error;
};
export const GetData = response => {
  const {
    data: { data, errors },
    error,
    errors: errors_backend,
  } = response;
  if (data === 'undefined' || !data) {
    if (errors) {
      throw errors;
    } else {
      throw Error('Unexpected error: Data is null');
    }
  }
  if (errors) throw errors;
  if (error) throw error;
  if (errors_backend) return errors_backend;
  return data;
};
export const GetRawData = response => {
  const { data, error } = response;
  if (typeof data === 'undefined' || !data) {
    if (error) {
      throw error;
    } else {
      throw Error('Unexpected error: Data is null');
    }
  }
  if (error) throw error;
  return data;
};
export const getFileSeries = (object, row) => {
  const { Series, File } = object;
  if (typeof Series !== 'undefined' && Series) {
    row.name = Series.SeriesDescription;
    row.Thumbnail = Series.Thumbnail;
    row.kind = 'dcm';
    row.size = FormatBytes(Series.Size);
  } else if (typeof File !== 'undefined' && File) {
    row.name = File.original_name;
    row.kind = '';
    row.Thumbnail = GetThumbnailFile(File.original_name);
    row.size = FormatBytes(File.Size);
    //
  }
  row.permission = object.Rights;
  return row;
};

// Shuffle an Array
// Shuffling an array is super easy with sort and random methods.
// console.log(shuffleArray([1, 2, 3, 4]));
// Result: [ 1, 4, 3, 2 ]
export const shuffleArray = arr => arr.sort(() => 0.5 - Math.random());

// Check if Date is Valid
// Use the following snippet to check if a given date is valid or not.
// isDateValid("December 17, 1995 03:24:00");
// Result: true
export const isDateValid = (...val) =>
  !Number.isNaN(new Date(...val).valueOf());

// Copy to Clipboard
// Easily copy any text to clipboard using navigator.clipboard.writeText.
// copyToClipboard('Hello World');
export const copyToClipboard = text => navigator.clipboard.writeText(text);
export const getClipboard = () => {
  // Create a temporary textarea element
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);

  // Focus on the textarea and execute the paste command
  textarea.focus();
  document.execCommand('paste');

  // Retrieve the clipboard content from the textarea
  const clipboardContent = textarea.value;

  // Remove the temporary textarea element
  document.body.removeChild(textarea);

  // Handle the clipboard content
  console.log('Clipboard content:', clipboardContent);
};
// Find the day of the year
// Find which is the day by a given date.
// dayOfYear(new Date());
// Result: 272
export const dayOfYear = date =>
  Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);

// Capitalize a String
// Javascript doesn’t have an inbuilt capitalize function, so we can use
//  the following code for this purpose.
// capitalize("follow for more")
// Result: Follow for more
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

// Find the number of days between two days
// Find the days between 2 given days using the following snippet.
// dayDif(new Date("2020-10-21"), new Date("2021-10-22"))
// Result: 366
export const dayDif = (date1, date2) =>
  Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / 86400000);

// Clear All Cookies
// You can easily clear all cookies stored on a web
// page by accessing the cookie using document.cookie and clearing it.

export const clearCookies = document.cookie
  .split(';')
  .forEach(
    cookie =>
      (document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
  );

// Generate Random Hex
// You can generate random hex colors with Math.random and padEnd properties.
// console.log(randomHex());
// Result: #92b008
export const randomHex = () =>
  `#${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padEnd(6, '0')}`;

// Remove Duplicated from Array
// You can easily remove duplicates with Set in JavaScript. It's a lifesaver.
// console.log(removeArrayDuplicates([1, 2, 3, 3, 4, 4, 5, 5, 6]));
// Result: [ 1, 2, 3, 4, 5, 6 ]
export const removeArrayDuplicates = arr => [...new Set(arr)];

// Get Query Params from URL
// You can easily retrieve query params from
// a URL either bypassing window.location or the raw URL goole.com?search=easy&page=3
// getParameters(window.location)
// Result: { search : "easy", page : 3 }
export const getParameters = URL => {
  URL = JSON.parse(
    '{"' +
      decodeURI(URL.split('?')[1])
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  return JSON.stringify(URL);
};

// Log Time from Date
// We can log time, in the format hour::minutes::seconds from a given date.
// console.log(timeFromDate(new Date(2021, 0, 10, 17, 30, 0)));
// Result: "17:30:00"
export const timeFromDate = date => date.toTimeString().slice(0, 8);

// Check if a number is even or odd
// console.log(isEven(2));
// Result: True
export const isEven = num => num % 2 === 0;

// Find Average of Numbers
// Find the average between multiple numbers using reducemethod.
// average(1, 2, 3, 4);
// Result: 2.5
export const average = (...args) => args.reduce((a, b) => a + b) / args.length;

// Check if the array is empty
// A simple one-liner to check if an array is empty, will return trueor false.
// isArrayNotEmpty([1, 2, 3]);
// Result: true
export const isArrayNotEmpty = arr => Array.isArray(arr) && arr.length > 0;

// Get Selected Text
// Get the text the user has selected using inbuilt getSelectionproperty.
// getSelectedText();
export const getSelectedText = () => window.getSelection().toString();

// Detect Dark Mode
// Check if a user’s device is in dark mode with the following code.
// console.log(isDarkMode)
// Result: True or False
export const isDarkMode =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;
