import { withStyles } from 'tss-react/mui';
import TopMenu from './TopMenu';
import TopMulti from './TopMultiMenu';

export const drawerWidthEdit = 240;

export const useStylesEdit = theme => ({
  root: {
    flex: 1,
  },
  appBar: {
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    display: 'flex',
    width: `calc(100% - ${drawerWidthEdit}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidthEdit,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthEdit,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidthEdit,
  },
  drawerHeader: {
    marginTop: 60,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    height: '88%',
    overflow: 'auto',
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    height: '88%',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidthEdit,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
});
export const drawerWidthShow = 350;
export const useStylesShow = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    display: 'flex',
    width: `calc(100% - ${drawerWidthShow}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidthShow,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidthShow,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidthShow,
  },
  drawerHeader: {
    marginTop: 60,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    marginRight: -2,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidthShow,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
});

export const TopMenuEdit = withStyles(TopMenu, useStylesEdit);
export const TopMenuEditMulti = withStyles(TopMulti, useStylesEdit);
export const TopMenuShow = withStyles(TopMenu, useStylesShow);
