import { gql, useSubscription } from '@apollo/client';

const SUBSCRIPTION_LANDING_OBJECT_CHANGED = gql`
  subscription($processing: Int) {
    landingObjectChanged(processing: $processing) {
      idLanding

      idFileObject
      idUser
      idSequence
      idCenterTeam
      pid

      file {
        idFileObject
        idLanding
        original_name
        Size
        Thumbnail
      }
      user {
        FullName
      }
      sequence {
        name
      }
      center_team {
        id
        center {
          id
          idAdmin
          name
        }
        team {
          id
          name
        }
      }

      isSeries
      processing
      projectIds
      folderIds
      folderStructure
      types
      function_name
      status
      inputs
      num_inputs
      errors

      createdAt
      updatedAt
    }
  }
`;

const SubscribeLandingObjects = props => {
  const { processing } = props;
  const { data, loading } = useSubscription(
    SUBSCRIPTION_LANDING_OBJECT_CHANGED,
    {
      variables: { processing },
    }
  );
  if (loading || !data || data === undefined) return null;
  const { landingObjectChanged } = data;
  if (landingObjectChanged) props.handleLandingChanged(landingObjectChanged);
  console.log('SubscribeLandingObjects', { landingObjectChanged });
  return null;
};
SubscribeLandingObjects.defaultProps = {
  handleLandingChanged: () => '',
  processing: undefined,
};
export default SubscribeLandingObjects;
