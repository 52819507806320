import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers/';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      // Target the outlined input used in TextField
      styleOverrides: {
        root: {
          // Remove border for the outlined variant
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            padding: '0px',
          },
          '& .MuiOutlinedInput-input': {
            fontSize: '0.875rem',
            fontFamily: 'Roboto, Helvetica, Arial ,sans-serif',
            fontWeight: 400,
          },
        },
        input: {
          paddingTop: 0, // Remove top padding
          paddingBottom: 0, // Remove bottom padding
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: 0, // Remove top margin
          marginBottom: 0, // Remove bottom margin
        },
      },
    },
  },
});

const TableGridDatePicker = props => {
  const { value, type, onDateChange } = props;
  // eslint-disable-next-line no-unused-vars
  const [cellValue, setCellValue] = useState(value);
  useEffect(() => {
    setCellValue(moment(value));
  }, [value]);

  const handleDateChange = newValue => {
    onDateChange(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {type === 'date' && (
          <DatePicker
            onChange={handleDateChange}
            size="small"
            sx={{
              m: 0,
              minWidth: 120,
            }}
            value={value ? value : null}
          />
        )}
        {type === 'datetime' && (
          <DateTimePicker
            onChange={handleDateChange}
            value={value ? value : null}
          />
        )}
        {type === 'time' && (
          <TimePicker
            onChange={handleDateChange}
            value={value ? value : null}
          />
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default TableGridDatePicker;
