import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { SearchSitesInputs } from 'components';

const useStyles = () => ({
  button: {},
});

class SearchSites extends Component {
  render() {
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="space-between"
        xs={12}>
        <SearchSitesInputs placeholder="Search Unit..." />
      </Grid>
    );
  }
}

SearchSites.propTypes = {
  classes: PropTypes.object,
};
SearchSites.defaultProps = {};

export default withStyles(SearchSites, useStyles);
