import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Link, Typography } from '@mui/material';
const markdownText = `|-- ...
|--   ID03
|--   ID04
|     |--   ID04_part_1.h5
|     |--   ID04_part_2.h5
|     |--   ID04_total.h5
|--   ID05
|--   ...`;
// Replace multiple consecutive spaces with non-breaking spaces
const formattedText = markdownText.replace(/ {2,}/g, match =>
  '\u00A0'.repeat(match.length)
);
const GrayBox = ({ text }) => {
  return (
    <Box
      bgcolor="grey.300"
      borderRadius={1}
      display="inline-block"
      m={0.5}
      p={0.1}
      pl={1}
      pr={1}>
      {text}
    </Box>
  );
};

class SWECiEEG extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const style_title = {
      paddingTop: 20,
      fontWeight: 800,
      textTransform: 'none',
    };
    const style_subtitle = {
      ...style_title,
      fontSize: 17,
      fontWeight: 700,
      paddingTop: 10,
    };
    return (
      <React.Fragment>
        <Typography gutterBottom style={style_title} variant="h6">
          SWEC iEEG Dataset
        </Typography>
        <Divider />
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          - <strong>Homepage</strong>:{' '}
          <Link
            href="https://mb-neuro.medical-blocks.ch/public_access/databases/ieeg/swec_ieeg"
            rel="noopener noreferrer"
            target="_blank">
            SWEC iEEG homepage
          </Link>
          <br />- <strong>Repository</strong>: TBD <br />-{' '}
          <strong>Paper</strong>: TBD <br />- <strong>Point of Contact</strong>:
          TBD
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Dataset summary
        </Typography>
        <Divider />
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The SWEC iEEG Dataset contains fully anonymised multi-channel iEEG
          recordings collected from a total of 68 subjects suffering from
          pharmacoresistent epilepsy undergoing pre-surgical evaluation for
          epilepsy. The data was recorded at the Sleep Wake Epilepsy Center
          (SWEC) of the Department of Neurology at the Inselspital in Bern,
          Switzerland. The dataset includes a total of 9328 hours of signal and
          704 ictal events, annotated by board-certified epileptologist Prof.
          Kaspar Schindler.
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Dataset structure
        </Typography>
        <Divider />
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The dataset is divided into 68 folders, one per subject. All the files
          in each folder are prefixed with the ID of the corresponding patient.
          Each folder contains the entire data for one patient divided into
          multiple HDF5 <strong>part</strong> files, with parts being roughly
          10GB in (uncompressed) size. Each folder also contains a{' '}
          <strong>total</strong> file in the HDF5 VDS format, combining all the
          parts into one continuous virtual recording for easy access and
          manipulation. The <strong>total</strong> file includes the ictal
          annotations. <br /> <br /> The dataset is comprised of 696 files
          across 68 folders, for a total (compressed, see{' '}
          <strong>Recordings</strong> details) size of 4.6TB.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Structure example
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          As an example of a typical subject folder, here is subject{' '}
          <GrayBox text="ID04" />:
        </Typography>
        <Box bgcolor="grey.300" borderRadius={4} p={2} whiteSpace="pre-line">
          {formattedText}
        </Box>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Recordings
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The recordings can be accessed at the <GrayBox text="data/ieeg" />
          dataset in every <strong>part</strong> file, or alternatively at the{' '}
          <GrayBox text="data/ieeg" />
          dataset of the <strong>total</strong> file for a unified view. Access
          through the <strong>total</strong> file is recommended. The{' '}
          <GrayBox text="ieeg" />
          dataset has shape (C, T) and is chunked into pieces containing 3
          minutes of signal across all the channels (e.g., each chunk is (64,
          92160) for a recording with sample rate 512Hz and 64 channels), to fit
          a suitable random access pattern. Moreover, the data is compressed
          with <GrayBox text="lz4hc" />, requiring an appropriate decoder for
          reading (e.g.,{' '}
          <Link
            href="https://www.h5py.org/"
            rel="noopener noreferrer"
            target="_blank">
            h5py
          </Link>{' '}
          with{' '}
          <Link
            href="https://github.com/silx-kit/hdf5plugin"
            rel="noopener noreferrer"
            target="_blank">
            hdf5plugin
          </Link>
          ).
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Annotations
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The ictal annotations can be accessed at the{' '}
          <GrayBox text="data/seizures" />
          dataset of the <strong>total</strong> file. The{' '}
          <GrayBox text="seizures" />
          dataset is a structured array with fields <GrayBox text="onsets" />
          and <GrayBox text="offsets" />, representing the seizures onsets and
          offsets respectively in seconds since the beginning of recording.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          File integrity
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The <strong>total</strong> file also contains the datasets{' '}
          <GrayBox text="info/files" />
          and <GrayBox text="info/checksums" />
          . The <GrayBox text="files" />
          dataset includes the list of parts name associated with the specific
          patient, while the <GrayBox text="checksum" />
          dataset includes the <GrayBox text="blake2b" />
          checksums of each <strong>part</strong> file to verify their integrate
          (e.g., with the <GrayBox text="b2sum <part>" />
          utility).
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Other attributes
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          Every file contains attributes <GrayBox text="patient" />
          with the patient ID, <GrayBox text="channels" />
          with the number of channels, and <GrayBox text="sampling_rate" />
          with the sampling rate in Hz.
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Dataset curation
        </Typography>
        <Divider />
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Preparation
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The iEEG signals were recorded intracranially by strip, grid, and
          depth electrodes. After 16-bit analog-to-digital conversion, the
          recordings were visually inspected for removal of channels corrupted
          by artifacts. The signals were then digitally band-pass filtered
          between 0.5 and 150 Hz using a fourth-order Butterworth
          forward-backward filter and finally downsampled to either 512Hz or
          1024Hz.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Ethical considerations
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          All the subjects gave written informed consent that their iEEG data
          might be used for research and teaching purposes. The decision on the
          necessity for iEEG recordings, the electrode implantation scheme, and
          the decision about surgical therapy were made entirely on clinical
          grounds. These decisions were taken prior to and completely
          independently from the compilation of this dataset.
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Additional information
        </Typography>
        <Divider />
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Dataset curators
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The dataset was created by Kaspar Schindler and the team at the SWEC.
          The dataset was further prepared for public availability by Francesco
          Carzaniga, Kaspar Schindler, Abbas Rahimi, and the team at the SWEC.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Initial version
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          An initial version containing the first 18 subjects with a different
          format can be found at this{' '}
          <Link
            href="http://ieeg-swez.ethz.ch/"
            rel="noopener noreferrer"
            target="_blank">
            location
          </Link>
          . Please note that that version is considered obsolete and might be
          made unavailable without notice.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Licensing
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The iEEG SWEC dataset is licensed using{' '}
          <Link
            href="https://cdla.dev/permissive-2-0/"
            rel="noopener noreferrer"
            target="_blank">
            the Community Data License Agreement – Permissive, Version 2.0
          </Link>
          .
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Disclaimer
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          This dataset may only be used for research. For other applications any
          liability is denied. In particular, the dataset must not be used for
          diagnostic purposes.
        </Typography>
        <Typography gutterBottom style={style_subtitle} variant="h6">
          Citation
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          If you are using this dataset, please cite the following: TBD
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Access To Database
        </Typography>
        <Divider />
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          To donwload the database please follow this link:{' '}
          <Link
            href="https://mb-neuro.medical-blocks.ch/shared/folder/9fd02930-f31b-11ee-b430-f90e54803d17"
            rel="noopener noreferrer"
            target="_blank">
            SWEC iEEG Database Access
          </Link>
        </Typography>
      </React.Fragment>
    );
  }
}

SWECiEEG.propTypes = {
  classes: PropTypes.object,
};

export default SWECiEEG;
