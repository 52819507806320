import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import useStyles from '../StyleChatList';
import { FormatTime, getInitials } from 'helpers';
import moment from 'moment';
import { BadgePulsar } from 'components';
class DirectChatRow extends Component {
  IsToday = createdAt => {
    return moment(parseInt(createdAt, 10)).isSame(moment(), 'day');
  };
  IsYesterday = createdAt => {
    return moment(parseInt(createdAt, 10)).isSame(
      moment().subtract(1, 'day'),
      'day'
    );
  };
  IsOlder = createdAt => {
    if (this.IsToday(createdAt) || this.IsYesterday(createdAt)) {
      return false;
    }
    return true;
  };

  render() {
    const { classes, row, selected, handleChatSelection } = this.props;
    //
    const { User, NewMessages, LastMessage, createdAt } = row;
    let avatar = '/images/avatars/avatar_0.png';
    let message = '';
    if (User.avatar) {
      avatar = User.avatar;
    }
    if (LastMessage) {
      const { text } = LastMessage;
      message = text;
    }
    let date = FormatTime(createdAt, 'HH:mm');
    if (this.IsOlder(createdAt)) {
      date = FormatTime(createdAt, 'MMM Do, YYYY');
    }
    let ComponentAvatar = null;
    if (User.isOnline) {
      ComponentAvatar = (
        <BadgePulsar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          overlap="circular"
          variant="dot">
          <Avatar alt={getInitials(User.FullName)} src={avatar} />
        </BadgePulsar>
      );
    } else {
      ComponentAvatar = (
        <Avatar alt={getInitials(User.FullName)} src={avatar} />
      );
    }
    return (
      <React.Fragment key={User.id}>
        <ListItem
          alignItems="flex-start"
          button
          className={clsx({
            [classes.divider_item]: !selected,
            [classes.divider_item_selected]: selected,
          })}
          dense
          onClick={() => handleChatSelection(User)}>
          <ListItemAvatar>{ComponentAvatar}</ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.header_black }}
            primary={User.FullName}
            secondary={`${message !== '' ? message.substring(0, 20) : ''}...`}
          />
          <ListItemText>
            <Grid
              alignItems="center"
              container
              // justifyContent="center"
              direction="column">
              <Grid item>
                <Typography>{date}</Typography>
              </Grid>
              {NewMessages ? (
                <Grid item>
                  <Badge
                    anchororigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    badgeContent={NewMessages}
                    // children=""
                    className={classes.badge_index}
                    color="error"
                  />
                </Grid>
              ) : null}
            </Grid>
          </ListItemText>
        </ListItem>
      </React.Fragment>
    );
  }
}

DirectChatRow.propTypes = {
  classes: PropTypes.object,
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};
DirectChatRow.defaultProps = {
  selected: false,
};

export default withStyles(DirectChatRow, useStyles);
