import {
  MutationUpdateFileMapTypes,
  MutationUpdateSeriesMapTypes,
} from 'graphql/Mapping';

export const UpdateMapVariableType = (type, maps) => {
  if (type === 'series') {
    return MutationUpdateSeriesMapTypes(maps);
  } else {
    return MutationUpdateFileMapTypes(maps);
  }
};
export const GetMapResult = data => {
  if (data.mapSeriesTypes) return data.mapSeriesTypes;
  return data.mapFileObjectTypes;
};
export const GetMap = (selected, type) => {
  let maps = selected
    .filter(x => x.idSeries)
    .map(x => {
      const { idSeries } = x;
      return {
        idSeries,
        idType: type.id,
        name: type.value,
      };
    });
  if (maps.length) return { maps, map_type: 'series' };
  maps = selected
    .filter(x => x.idFileObject)
    .map(x => {
      const { idFileObject } = x;
      return {
        idFileObject,
        idType: type.id,
        name: type.value,
      };
    });
  return { maps, map_type: 'file' };
};
