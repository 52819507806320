import { createTheme } from '@mui/material';

export { default as ToolBarSharedBottom } from './ToolBarSharedBottom';
export { default as ToolBarSharedTop } from './ToolBarSharedTop';
export * from './utils_shared_selected';
export const theme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        regular: {
          // backgroundColor: '#ffff00',
          // color: '#000000',
          height: '20px',
          minHeight: '20px',
          '@media(min-width:600px)': {
            minHeight: '40px',
          },
        },
      },
    },
  },
});
