import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { StatusBullet } from 'components';
import { statusColors, useStyles } from '../Style';

class TableRowStatus extends PureComponent {
  getType = () => {
    const {
      notification: {
        name: activity,
        Folder,
        DirectMessage,
        Right,
        FolderContent,
      },
      read,
    } = this.props;
    let type = 'orange';
    let name = 'Unknown';
    if (Folder) {
      type = 'green';
      name = 'New folder';
    } else if (DirectMessage) {
      type = read ? 'red' : 'green';
      name = 'Message';
    } else if (Right) {
      type = 'green';
      name = 'Right created';
    } else if (FolderContent) {
      const { idSeries } = FolderContent;
      if (typeof idSeries !== 'undefined') {
        name = 'Series uploaded';
      } else {
        name = 'File uploaded';
      }
      type = 'green';
    } else if (activity === 'User Request') {
      name = 'New request';
      type = 'warning';
    } else if (activity.startsWith('Shared Folder')) {
      name = 'New folder shared';
      type = 'neutral';
    }
    if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('You added new File') !== -1
    ) {
      name = 'File uploaded and delete';
      type = 'red';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('shared a File') !== -1
    ) {
      name = 'File shared';
      type = 'neutral';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('You created a new Folder') !== -1
    ) {
      type = 'green';
      name = 'New folder';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('Folder deleted') !== -1
    ) {
      type = 'red';
      name = 'Folder deleted';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('Folder rename') !== -1
    ) {
      type = 'warning';
      name = 'Folder rename';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('added new Series') !== -1
    ) {
      type = 'green';
      name = 'New series';
    } else if (
      activity &&
      name === 'Unknown' &&
      activity.indexOf('shared a Series') !== -1
    ) {
      type = 'warning';
      name = 'Shared series';
    }
    return {
      type,
      name,
    };
  };

  render() {
    const { classes } = this.props;
    const { type, name } = this.getType();
    return (
      <div className={classes.statusContainer}>
        <StatusBullet
          className={classes.status}
          color={statusColors[type]}
          size="sm"
        />
        {name}
      </div>
    );
  }
}

TableRowStatus.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TableRowStatus, useStyles);
