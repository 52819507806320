import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  Typography,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';

import useStyles from './Style';
import { DownloadItem } from './components';
import { addDownloads } from 'redux-store/actions';
import {
  GetData,
  SelectedArray,
  GetNameFromHeader,
  GetResponseError,
} from 'helpers';
import { ButtonIconLoad, DivGrow } from 'components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      current_index: -1,
      progress_all: 0,
      total_downloaded: 0,
      download_all: false,
      ready: [],
      progress: [],
    };
  }

  IsDownloaded = index => {
    const { ready } = this.state;
    return ready.indexOf(index) !== -1;
  };
  getIndex = item => {
    const { downloads } = this.props;
    for (let i = 0; i < downloads.length; i++) {
      const { idFolder, idFolderContent } = downloads[i];
      if (idFolderContent !== undefined) {
        if (item.idFolderContent === undefined) {
          /* empty */
        } else if (item.idFolderContent === idFolderContent) {
          return i;
        }
      } else if (idFolder !== undefined) {
        if (item.idFolder === undefined) {
          /* empty */
        } else if (item.idFolder === idFolder) {
          return i;
        }
      }
    }
    return -1;
  };
  handleDelete = item => {
    const { downloads } = this.props;
    const index = this.getIndex(item);
    const newSelected = SelectedArray(downloads, item, index);
    this.props.addDownloads(newSelected);
  };
  handleDownloadItem = index => {
    const { download_all, current_index } = this.state;
    if (download_all || current_index === -1) {
      //
      this.handleDownload(index);
    } else if (index < 0 || index >= this.props.downloads.length) {
      this.setState({
        downloading: false,
        current_index: -1,
        progress_all: 0,
        download_all: false,
        ready: [],
        progress: [],
      });
    }
  };
  handleDeleteAll = () => {
    this.setState({ download_all: false, total_downloaded: 0 });
    this.props.addDownloads([]);
  };
  handleDownloadAll = () => {
    this.setState({ download_all: true, total_downloaded: 0 });
    this.handleDownload(0, true);
  };
  handleProgress = event => {
    const { progress, current_index } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const progress_all = Math.floor((loaded * 100) / total);
    const index = progress.map(x => x.index).indexOf(current_index);
    if (index !== -1) {
      progress[index].index = index;
      progress[index].loaded = loaded;
    } else {
      progress.push({
        index: current_index,
        loaded,
      });
    }
    this.setState({ progress_all, progress });
  };
  handleDownload = (index, download_all = null) => {
    const { downloading, total_downloaded, current_index, ready } = this.state;
    if (!download_all) {
      download_all = this.state.download_all;
    }
    if (downloading && current_index === index) {
      return;
    }
    //
    if (ready.indexOf(index) !== -1) {
      this.handleDownloadItem(++index);
      return;
    }
    const { downloads } = this.props;
    if (index >= downloads.length) {
      this.setState({
        download_all: false,
        downloading: false,
        current_index: -1,
      });
      return;
    }
    const item = downloads[index];
    //
    const { idFolderContent, idFolder } = item;
    const selected = [];
    if (typeof idFolderContent !== 'undefined') {
      selected.push({ idFolderContent });
    } else if (typeof idFolder !== 'undefined') {
      selected.push({ idFolder });
    }
    //
    this.setState({
      downloading: true,
      current_index: index,
      total_downloaded: total_downloaded + 1,
    });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(selected);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors },
        } = data;
        if (ok) {
          download_key = key;
        } else {
          throw errors;
        }
      } catch (error) {
        this.handleDownloadItem(++index, error);
        return;
      }
      DownloadItemsAtKey(download_key, this.handleProgress)
        .then(async response => {
          if (!response.data) {
            throw Error('Unknown error data is null');
          }
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          await MutationDownloadFinished(download_key);
          ready.push(index);
          const state = { ready };
          if (download_all) {
            this.setState(state);
            this.handleDownloadItem(++index);
          } else {
            state.downloading = false;
            this.setState(state);
          }
        })
        .catch(async error => {
          error = await GetResponseError(error);
          this.handleDownloadItem(++index, error);
        });
    })();
  };
  getProgress = index => {
    const { progress } = this.state;
    const i = progress.map(x => x.index).indexOf(index);
    if (i !== -1) return progress[i];
    return null;
  };
  render() {
    const { classes, downloads } = this.props;
    const total_files = downloads.length;
    const {
      current_index,
      progress_all,
      total_downloaded,
      downloading,
    } = this.state;
    const total_progress = (total_downloaded / total_files) * 100;
    return (
      <div style={{ padding: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between">
              <Typography className={classes.text} variant="h4">
                {`Downloading ${total_downloaded} of ${total_files}`}
              </Typography>
              <DivGrow />
              <ButtonIconLoad
                className={classes.expand}
                disabled={total_downloaded === total_files}
                handleClick={this.handleDownloadAll}
                icon={<CloudDownloadIcon />}
                loading={downloading}
                size="small"
                text="Download All"
              />
              <Button
                className={classes.button}
                color="secondary"
                onClick={this.handleDeleteAll}
                size="small"
                startIcon={<DeleteIcon />}
                variant="contained">
                Delete All
              </Button>
            </Grid>
            {downloading && (
              <LinearProgress value={total_progress} variant="determinate" />
            )}
            <Divider style={{ marginBottom: '5px' }} variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <List>
              {downloads.map((item, index) => {
                return (
                  <DownloadItem
                    current_index={current_index}
                    downloading={downloading}
                    handleDelete={this.handleDelete}
                    handleDownloadItem={this.handleDownloadItem}
                    index={index}
                    IsDownloaded={this.IsDownloaded}
                    item={item}
                    item_progress={progress_all}
                    key={`file-item-download-${uuid()}`}
                    progress={this.getProgress(index)}
                  />
                );
              })}
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Download.propTypes = {
  classes: PropTypes.object,
};
Download.defaultProps = {
  progress_all: 0,
  downloading: false,
};
const mapStateToProps = state => {
  const total = state.items.downloads.length;
  return {
    total,
    downloads: state.items.downloads,
  };
};

export default connect(mapStateToProps, { addDownloads })(
  withStyles(Download, useStyles)
);
