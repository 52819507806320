import { MutationDeleteSeriesTypes } from 'graphql/Mapping';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';

export { default as EditSeriesVariableMap } from './EditSeriesVariableMap';
export { default as SeriesMapFilters } from './SeriesMapFilters';
export const runDeleteSelected = THIS => {
  const { deleting, selected } = THIS.state;
  if (deleting || !selected.length) return;
  THIS.setState({ deleting: true, open_delete: false });
  (async () => {
    const ids = selected.map(x => x.id);
    MutationDeleteSeriesTypes(ids)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.deleteSeriesTypes;
        if (ok) {
          THIS.setState({
            deleting: false,
            selected: [],
            mappings: THIS.state.mappings.filter(x => ids.indexOf(x.id) === -1),
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        THIS.setState({ deleting: false });
        THIS.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
