import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';

import { Message } from './components';

const useStyles = () => ({
  team_chats: {
    width: '98%',
    zIndex: 1,
  },
});

class TeamChats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcribe: 0,
    };
  }
  render() {
    const { messages, classes } = this.props;
    let prev_id = -1;
    let prev_side = true;
    return (
      <div className={classes.team_chats}>
        {messages.map(message => {
          if (prev_id !== message.user.id) {
            prev_side = !prev_side;
            prev_id = message.user.id;
          }
          if (prev_side) {
            return <Message key={message.id} left message={message} />;
          } else {
            return <Message key={message.id} message={message} />;
          }
        })}
      </div>
    );
  }
}

TeamChats.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
};
TeamChats.defaultProps = {
  messages: [],
};

export default withStyles(TeamChats, useStyles);
