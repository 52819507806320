import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SSR_FOLLOWUP = gql`
  query($name: String, $page: Int, $limit: Int) {
    ssrFollowups(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      followups {
        id
        idCase
        idPatient
        followup_date
        followup_mode
        mrs_90days
        nih_90days
        stroke_followup
        stroke_followup_date
        ich_followup
        ich_followup_date
        seizure_followup
        seizure_followup_date
        death_followup
        death_followup_date
        death_followup_cause
        followup_comment
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryStrokeFollowup = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_FOLLOWUP),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const MUTATION_ADD_SSR_FOLLOWUP = gql`
  mutation($inputs: [InputSsrFollowup!]!) {
    addSsrFollowup(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      followups {
        id
        idCase
        idPatient
        followup_date
        followup_mode
        mrs_90days
        nih_90days
        stroke_followup
        stroke_followup_date
        ich_followup
        ich_followup_date
        seizure_followup
        seizure_followup_date
        death_followup
        death_followup_date
        death_followup_cause
        followup_comment
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const MutationAddSsrFollowup = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SSR_FOLLOWUP),
    variables: {
      inputs,
    },
  });
};

const MUTATION_REMOVE_STROKE_FOLLOWUP = gql`
  mutation($ids: [LongLong!]!) {
    deleteStrokeFollowup(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveStrokeFollowup = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_STROKE_FOLLOWUP),
    variables: {
      ids,
    },
  });
};
const SEARCH_SSR_FOLLOWUP = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchSsrFollowups(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      followups {
        id
        idCase
        idPatient
        followup_date
        followup_mode
        mrs_90days
        nih_90days
        stroke_followup
        stroke_followup_date
        ich_followup
        ich_followup_date
        seizure_followup
        seizure_followup_date
        death_followup
        death_followup_date
        death_followup_cause
        followup_comment
        createdAt
        updatedAt
        patient {
          id
          PatientID
        }
      }
    }
  }
`;
export const QuerySearchStrokeFollowup = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_SSR_FOLLOWUP),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
