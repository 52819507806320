import path from 'common/path';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';

import {
  GetData,
  GetNameFromHeader,
  GetResponseError,
  IsInvalid,
} from 'helpers';
export const default_state = {
  total_downloaded: 0,
  downloading: false,
  current_index: -1,
  ready: [],
  totals: [],
};
export const handleDownloadSelected = (index, THIS) => {
  const { downloading, total_downloaded, current_index, ready } = THIS.state;
  let item = null;
  let to_download = [];
  try {
    if (downloading && current_index === index) {
      return;
    }
    const { selected } = THIS.props;
    if (index >= selected.length) {
      THIS.setState({
        ...default_state,
      });
      console.log('------ DONE -------');
      return;
    }
    item = selected[index];
    if (ready.indexOf(index) !== -1) {
      console.log('read  - here');
      handleDownloadNextItem(++index, undefined, item, THIS);
      return;
    }
    if (item.content) {
      to_download.push({ idFolderContent: item.content.idFolderContent });
    } else if (item.folder) {
      to_download.push({ idFolder: item.folder.idFolder });
    }
    THIS.props.holder.setDownload(item, 'func');
    THIS.setState({
      downloading: true,
      current_index: index,
      total_downloaded: total_downloaded + 1,
    });
  } catch (error) {
    console.log('handleDownload', { error });
  }
  (async () => {
    let download_key = null;
    try {
      const res = await GenerateDownload(to_download);
      const data = GetData(res);
      const {
        downloadGenerate: { ok, key, errors },
      } = data;
      if (ok) {
        download_key = key;
      } else {
        throw errors;
      }
    } catch (error) {
      console.log({ error });
      handleDownloadNextItem(++index, error, item, THIS);
      return;
    }
    DownloadItemsAtKey(download_key, event => THIS.handleProgress(event, item))
      .then(async response => {
        let disposition = response.headers['Content-Disposition'];
        if (IsInvalid(disposition)) {
          disposition = response.headers['content-disposition'];
        }
        if (!response.data) {
          throw Error('Unknown error data is null');
        }
        let name = 'download_all';
        let extension = 'zip';
        if (item.content) {
          const { Series, File } = item.content;
          if (Series) {
            name = Series.SeriesDescription;
          } else if (File) {
            let ext = path.extname(File.original_name);
            name = path.basename(File.original_name, ext);
            if (ext) {
              if (ext.startsWith('.')) {
                ext = ext.replace('.', '');
              }
              extension = ext;
            }
          }
        } else if (item.folder) {
          name = item.folder.name;
        } else if (disposition && disposition.indexOf('filename=') !== -1) {
          name = GetNameFromHeader(response);
          extension = null;
        }
        let file_name = `${name}.${extension}`;
        if (!extension) file_name = name;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        link.style.display = 'none';
        link.target = '_blank';
        link.hidden = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await MutationDownloadFinished(download_key);
        ready.push(index);
        const state = { ready };
        console.log('download  finished');
        THIS.setState(state);
        handleDownloadNextItem(++index, null, item, THIS);
      })
      .catch(async error => {
        console.log({ error });
        error = await GetResponseError(error);
        handleDownloadNextItem(++index, error, item, THIS);
      });
  })();
};

const handleDownloadNextItem = (index, error, item_prev, THIS) => {
  console.log('handleDownloadNextItem', { index });
  if (item_prev) {
    THIS.props.onDownloadFinished(error, item_prev);
  }
  handleDownloadSelected(index, THIS);
};
