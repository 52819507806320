import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  CreateDockerImage,
  DialogDockerImage,
  TableDockerImages,
  ToolBarImageActions,
  useStyles,
  ViewDockerImage,
} from './components';
import {
  MutationDockerImageDelete,
  QueryDockerImages,
} from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';

class TabImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      images: [],
      selected: [],
      image: null,
      create_docker: false,
      open_delete: false,
      page: 0,
      limit: 100,
    };
    const { holder } = props;
    if (holder) {
      holder.removedContainer = this.handleContainersRemoved;
    }
  }

  componentDidMount() {
    this.queryDockerImages();
  }

  handleContainersRemoved = containers => {
    const { images } = this.state;
    for (let i = 0; i < containers.length; i++) {
      const { idImage } = containers[i];
      const index = images.map(x => x.idImage).indexOf(idImage);
      if (index !== -1) {
        images[index].Status = 'unused';
      }
    }
    this.setState({ images });
  };
  queryDockerImages = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerImages()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images } = data.dockerImages;
          if (ok) {
            this.setState({ loading: false, images });
            this.props.handleDockerImages(images);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleClickContainer = () => {};
  handleSetSelected = selected => {
    this.setState({ selected });
    this.props.handleSetSelectedImages(selected);
  };
  handleClickImageLink = image => {
    //
    this.setState({ image });
  };
  handleOpenCreateDocker = () => {
    this.setState({ create_docker: !this.state.create_docker });
  };
  getSelectedNames = () => {
    const selected = this.state.selected.map(x => x.RepoTags).filter(x => x);

    let names = [];
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].length) {
        const { name } = selected[i][0];
        if (name && name !== '') {
          names.push(name);
        }
      }
    }
    return names;
  };
  handleOpenDeleteSelected = () => {
    this.setState({ open_delete: !this.state.open_delete });
  };
  mutationDeleteSelected = settings => {
    const { deleting, selected } = this.state;
    const names = this.getSelectedNames();

    if (deleting || !names.length) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      MutationDockerImageDelete(names, settings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteDockerImage;
          if (ok) {
            let { images } = this.state;
            const ids = selected.map(x => x.idImage);
            images = images.filter(x => ids.indexOf(x.idImage) === -1);
            this.setState({ deleting: false, selected: [], images });
            this.props.handleSetSelectedImages([]);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleNewDockerImage = image => {
    const { images } = this.state;
    const index = images.map(x => x.idImage).indexOf(image.idImage);
    if (index === -1) {
      images.unshift(image);
    } else {
      this.props.handleAddError(
        `Docker image is already here (${image.idImage})`
      );
    }
    this.setState({ create_docker: false, images });
  };
  handleActionResults = actions => {
    let { images, selected } = this.state;

    let count_containers = 0;
    for (let i = 0; i < actions.length; i++) {
      const { idImage, error, container } = actions[i];
      let index = images.map(x => x.idImage).indexOf(idImage);

      if (index !== -1 && error && error !== '') {
        this.props.handleAddError(error);
      } else {
        selected = selected.filter(x => x.idImage !== idImage);
        if (container) {
          this.props.handleAddNewContainer(container);
          count_containers++;
          images[index].Status = 'running';
        }
      }
    }
    this.setState({ selected, images });
    if (count_containers) {
      this.props.handleTabValue({ preventDefault: () => '' }, 0);
    }
  };

  render() {
    const { classes } = this.props;
    const { image, create_docker } = this.state;
    if (image) {
      return (
        <ViewDockerImage
          handleAddError={this.props.handleAddError}
          handleClose={() => this.handleClickImageLink(null)}
          height={this.props.height - 180}
          image={image}
        />
      );
    }
    if (create_docker) {
      return (
        <CreateDockerImage
          handleAddError={this.props.handleAddError}
          handleNewDockerImage={this.handleNewDockerImage}
          handleOpenCreateDocker={this.handleOpenCreateDocker}
        />
      );
    }
    return (
      <Grid container>
        <Grid className={classes.toolbar} item xs={12}>
          <ToolBarImageActions
            handleActionResults={this.handleActionResults}
            handleAddError={this.props.handleAddError}
            handleOpenCreateDocker={this.handleOpenCreateDocker}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item xs={12}>
          <TableDockerImages
            deleting={this.state.deleting}
            disable_splice={false}
            handleChangePage={(event, newPage) => {
              event.preventDefault();
              this.setState({ page: newPage });
            }}
            handleChangeRowsPerPage={event => {
              event.preventDefault();
              this.setState({ page: 0, limit: +event.target.value });
            }}
            handleClickContainer={this.handleClickContainer}
            handleClickImageLink={this.handleClickImageLink}
            handleDeleteSelected={this.handleOpenDeleteSelected}
            handleReloadTable={this.queryDockerImages}
            handleSetSelected={this.handleSetSelected}
            height={this.props.height - 380}
            images={this.state.images}
            limit={this.state.limit}
            loading={this.state.loading}
            page={this.state.page}
            selected={this.state.selected}
            total={this.state.images.length}
          />
        </Grid>
        <DialogDockerImage
          executeDeleteSelected={this.mutationDeleteSelected}
          handleClose={this.handleOpenDeleteSelected}
          open={this.state.open_delete}
        />
      </Grid>
    );
  }
}

TabImages.propTypes = {
  classes: PropTypes.object,
};
TabImages.defaultProps = {
  handleAddNewContainer: () => '',
};
export default withStyles(TabImages, useStyles);
