import React from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { Avatar, Typography } from '@mui/material';
import clsx from 'clsx';

const theme_active_button = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      // fontSize: '12',
      // fontFamily: 'Arial'
    },
  },
  // palette: {
  //   primary: green
  // }
});

const useStyles = theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  folder_icon: {
    color: 'blue',
  },
  folder_icon_other: {
    color: 'green',
  },
});

class TableObjectItem extends React.Component {
  handleClick = event => {
    event.preventDefault();
  };

  render() {
    const { classes, isDense, row, user } = this.props;
    let { kind, name, Thumbnail } = row;
    if (Thumbnail && !Thumbnail.startsWith('/images/')) {
      Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
    }
    const isFolder = kind === '--';
    let folder_ownership = true;
    let idUser = -1;
    if (isFolder) {
      if (row.Owner) idUser = row.Owner.id;
      if (idUser !== user.id) {
        folder_ownership = false;
      }
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_active_button}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}>
            <Grid item>
              {isFolder ? (
                <FolderIcon
                  className={clsx({
                    [classes.folder_icon]: folder_ownership,
                    [classes.folder_icon_other]: !folder_ownership,
                  })}
                  fontSize={isDense ? 'small' : 'large'}
                />
              ) : Thumbnail ? (
                <Avatar alt={name} src={Thumbnail} />
              ) : (
                <DescriptionIcon fontSize={isDense ? 'small' : 'large'} />
              )}
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>{name}</Typography>
            </Grid>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(TableObjectItem, useStyles));
