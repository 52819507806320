import React from 'react';
import PropTypes from 'prop-types';
import { ImageDetails } from 'views/Dashboard/components/DashboardSurgery/components/ExplorerImage/components';
import { QueryFolderContentWithMetadata } from 'graphql/Folders';
import { ServerErrorsString, GetData } from 'helpers';
import { BoxWaiting, DialgoEditMetadata } from 'components';

class FileDesigner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open_edit: false,
    };
  }
  componentDidMount() {
    this.updateContent();
  }
  componentDidUpdate() {
    this.updateContent();
  }
  getFolderContent = () => {
    const { folder_content } = this.props;
    if (folder_content) return folder_content.idFolderContent;
    return null;
  };
  updateContent = () => {
    const { folder_content, empty } = this.state;
    const idFolderContent = this.getFolderContent();
    if (!idFolderContent || empty) {
      if (empty) {
        this.props.handleAddError(
          // eslint-disable-next-line quotes
          "The file was deleted or you don't have access"
        );
      }
    } else if (
      !folder_content ||
      idFolderContent !== folder_content.idFolderContent
    ) {
      this.loadContent(idFolderContent);
    }
  };
  loadContent = idNewFolderContent => {
    let { loading, idFolderContent } = this.state;
    if (idNewFolderContent) {
      idFolderContent = idNewFolderContent;
    }
    if (loading || idFolderContent === undefined || !idFolderContent) return;
    this.setState({ loading: true });
    (async () => {
      QueryFolderContentWithMetadata(idFolderContent)
        .then(res => {
          const data = GetData(res);
          const { ok, folder_content, errors } = data.folderContent;
          if (ok) {
            let empty = false;
            if (!folder_content || folder_content === undefined) {
              empty = true;
            }
            this.setState({
              loading: false,
              folder_content,
              idFolderContent,
              empty,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idFolderContent, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveImageName = name => {
    const { folder_content } = this.state;
    if (folder_content.File && folder_content.File.metadata) {
      const { metadata } = folder_content.File;
      folder_content.File.metadata = {
        ...metadata,
        name,
      };
    }
    this.setState({ folder_content });
  };
  render() {
    const { loading, open_edit } = this.state;
    if (loading) return <BoxWaiting />;
    // eslint-disable-next-line no-unused-vars
    const { folder_content, handleAddError, ...res } = this.props;
    // console.log({ f: this.state.folder_content });
    return (
      <React.Fragment>
        <ImageDetails
          {...res}
          folder_content={this.state.folder_content}
          handleAddError={handleAddError}
          handleEdit={() => this.setState({ open_edit: true })}
          handleSaveImageName={this.handleSaveImageName}
          padding={0}
        />
        <DialgoEditMetadata
          folder_content={this.state.folder_content}
          handleAddError={handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleUpdateMetadata={metadata => {
            this.setState({
              open_edit: false,
              folder_content: {
                ...this.state.folder_content,
                File: {
                  ...this.state.folder_content.File,
                  metadata,
                },
              },
            });
          }}
          open_edit={open_edit}
        />
      </React.Fragment>
    );
  }
}

FileDesigner.propTypes = {
  classes: PropTypes.object,
};

export default FileDesigner;
