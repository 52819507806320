import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import { UsersToolbar } from './components';
import {
  DialogAddCollaborators,
  DialogQuestion,
  TableCollaborators,
} from 'components';

import { allUserCollaborators, logout } from 'redux-store/actions';

import {
  allCollaborators,
  GetData,
  getUserFullName,
  removeCollaborators,
  updateCollaborationsResponse,
} from 'helpers';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    top: 0,
    left: 0,
    maxHeight: 'calc(100% - 65px)',
    /* uncomment this static height to see it work */
    /* height: 100%; */
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Collaborators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collaborators: [],
      open_collaborators: false,
      loading: false,
      deleting: false,
      open_delete: false,
      to_delete: [],
    };
    this.holder = {
      getSelected: null,
      clearSelected: null,
    };
  }

  componentDidMount() {
    this.handleLoadCollaborators();
  }

  handleLoadCollaborators = email => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      allCollaborators(email, null)
        .then(res => {
          const data = GetData(res);
          const { allCollaborators } = data;

          let collaborators = Object.keys(allCollaborators).map(key => {
            const collaboration = allCollaborators[key];
            let user = null;
            let name = 'Undefined';
            if (collaboration.user) {
              user = collaboration.user;
              name = getUserFullName(user);
            }

            return {
              id: collaboration ? collaboration.id : -1,
              admin: collaboration.admin,
              name: name,
              email: collaboration.email,
              avatar: user ? user.avatar : '',
              invited: collaboration.invited,
              accepted: collaboration.accepted,
              createdAt: parseInt(collaboration.createdAt, 10),
            };
          });

          collaborators = collaborators.filter(e => e !== null);

          if (!collaborators) {
            collaborators = [];
          }
          this.setState({ collaborators, loading: false });
        })
        .catch(err => {
          if (err.toString() === 'TypeError: data is null') {
            this.props.logout();
          }

          this.setState({ collaborators: [], loading: false });
        });
    })();
  };
  handleUserSearch = event => {
    this.handleLoadCollaborators(event.target.value);
  };
  handleAddCollaborators = () => {};
  handleCollaboratorsAdded = () => {
    this.setState({ open_collaborators: false });
    this.handleLoadCollaborators();
  };
  handleDeleteCollaborators = event => {
    event.preventDefault();
    const selected = this.holder.getSelected();

    const emails = selected.map(x => x.email);

    this.setState({ open_delete: true, to_delete: emails });
  };
  handleAcceptReject = (accepted, row) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    updateCollaborationsResponse(accepted, row.id)
      .then(res => {
        const data = GetData(res);
        // eslint-disable-next-line no-unused-vars
        const { updateCollaborationsResponse } = data;

        this.setState({ loading: false });
      })
      .catch(error => {
        console.log({ error });
        this.setState({ loading: false });
      });
  };
  handleRemoveCollaborator = () => {
    const { to_delete } = this.state;
    this.runDelete(to_delete);
  };
  runDelete = emails => {
    const { deleting } = this.state;
    if (deleting) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      removeCollaborators(emails)
        .then(res => {
          const data = GetData(res);
          const {
            removeCollaborators: { ok, errors },
          } = data;

          if (ok) {
            let { collaborators } = this.state;
            collaborators = collaborators.filter(
              x => emails.indexOf(x.email) === -1
            );
            this.setState({ deleting: false, collaborators });
            this.holder.clearSelected();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ deleting: false });
        });
    })();
  };
  getTitle = () => {
    const { to_delete, open_delete } = this.state;
    if (!open_delete) return '';
    const total = to_delete.length;
    if (total) {
      return `Remove Collaborator${total === 1 ? '' : 's'}`;
    } else {
      this.setState({ open_delete: false });
      return '';
    }
  };
  getMessage = () => {
    const { to_delete, open_delete } = this.state;
    if (!open_delete) return '';
    if (to_delete.length) {
      return `Remove collaborators was triggered.\nEmails:\n- ${to_delete.join(
        '\n- '
      )}\n\nThis acciont cannot be undone`;
    } else {
      this.setState({ open_delete: false });
      return '';
    }
  };

  render() {
    const { classes } = this.props;
    const {
      open_collaborators,
      collaborators,
      deleting,
      loading,
      open_delete,
    } = this.state;
    const message = this.getMessage();
    const title = this.getTitle();
    return (
      <div className={classes.root}>
        <UsersToolbar
          handleAddCollaborators={this.handleAddCollaborators}
          handleUserSearch={this.handleUserSearch}
        />
        <div className={classes.content}>
          <TableCollaborators
            collaborators={collaborators}
            deleting={deleting}
            handleAcceptReject={this.CollaboratorshandleAcceptReject}
            handleDeleteCollaborators={this.handleDeleteCollaborators}
            handleReloadTable={() => this.handleLoadCollaborators()}
            holder={this.holder}
            loading={loading}
          />
        </div>
        <DialogAddCollaborators
          handleCloseDialog={() => {
            this.setState({ open_collaborators: false });
          }}
          handleCollaboratorsAdded={this.handleCollaboratorsAdded}
          open={open_collaborators}
        />
        <DialogQuestion
          button_name="Remove"
          handleAction={this.handleRemoveCollaborator}
          handleClose={() => this.setState({ open_delete: false })}
          message={message}
          open={open_delete}
          title={title}
        />
      </div>
    );
  }
}

Collaborators.propTypes = {
  className: PropTypes.string,
  logout: PropTypes.func.isRequired,
  // collaborators: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  collaborators: state.projects.users.collaborators,
});

export default connect(mapStateToProps, {
  allUserCollaborators,
  logout,
})(withStyles(Collaborators, useStyles));
