import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ButtonDownloadType, ButtonReloadDelete, SearchText } from 'components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const useStyles = theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(0.5),
  },
});

class TabUnsortedSequencesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes, selected, name, holder, loading } = this.props;
    let disabled = !selected.length;
    if (name === null) name = '';
    return (
      <div className={classes.root}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button disabled={disabled} onClick={this.props.handleOpenEdit}>
                <EditIcon style={{ color: disabled ? 'gray' : 'red' }} />
              </Button>
              <Button
                disabled={disabled}
                onClick={this.props.handleOpenClassification}>
                <PlayArrowIcon style={{ color: disabled ? 'gray' : 'green' }} />
              </Button>
              <ButtonDownloadType key="center" save type="unsorted_sequences" />
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={this.props.handleSearchSequence}
              holder={holder}
              id="id-search-admin-unsortedsequences"
              loading={loading}
              placeholder="Search Sequence by name"
            />
          </Grid>
          <Grid item pr={2}>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={() => this.props.handleReload()}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload mapping"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

TabUnsortedSequencesHeader.propTypes = {
  classes: PropTypes.object,
  handleOpenEdit: PropTypes.func,
  selected: PropTypes.array,
};
TabUnsortedSequencesHeader.defaultProps = {
  handleOpenEdit: () => '',
  selected: [],
  name: '',
  handleChangeName: () => '',
};
export default withStyles(TabUnsortedSequencesHeader, useStyles);
