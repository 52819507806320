import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { StyleText } from 'components';

class ViewDockerContainerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container } = this.props;
    if (container === undefined) return null;
    const { idContainer } = container;
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleClose()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={<EditIcon style={{ color: 'green' }} />}
        subheader={'Modify all the necessary field and presss save to update'}
        title={
          <Grid container>
            <StyleText
              fontSize={15}
              fontWeight={1000}
              spacing={3}
              style
              text="Container ID:"
            />
            <Grid item style={{ marginLeft: 10 }}>
              {idContainer}
            </Grid>
          </Grid>
        }
      />
    );
  }
}

ViewDockerContainerHeader.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};
ViewDockerContainerHeader.defaultProps = {
  handleClose: () => '',
};
export default ViewDockerContainerHeader;
