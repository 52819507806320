import palette from 'theme/palette';
import { purple, amber } from '@mui/material/colors';

export const useStyles = theme => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0),
  },
  chartContainerSales: {
    height: '350px',
    position: 'relative',
    marginTop: '5px',
  },
  actions: {
    justifyContent: 'flex-end',
  },
});

export const getEmpty = () => {
  const datasets = [
    { label: 'This year', data: [], backgroundColor: palette.primary.main },
    {
      label: 'Last year',
      data: [],
      backgroundColor: palette.primary.neutral,
    },
    {
      label: 'Last month',
      data: [],
      backgroundColor: purple[200],
    },
    {
      label: 'Historical',
      data: [],
      backgroundColor: amber[200],
    },
  ];
  const labels = ['MR', 'CT', 'DWI', 'XR', 'XA', 'SR'];
  return {
    datasets,
    labels,
  };
};
export const getData = data_in => {
  const { ThisYear, LastYear, LastMonth, History } = data_in;
  let labels = ThisYear.map(x => x.Modality);
  let inputs = LastYear.map(x => x.Modality);
  for (let i = 0; i < inputs.length; i++) {
    if (labels.indexOf(inputs[i]) === -1) {
      labels.push(inputs[i]);
    }
  }
  inputs = LastMonth.map(x => x.Modality);
  for (let i = 0; i < inputs.length; i++) {
    if (labels.indexOf(inputs[i]) === -1) {
      labels.push(inputs[i]);
    }
  }
  inputs = History.map(x => x.Modality);
  for (let i = 0; i < inputs.length; i++) {
    if (labels.indexOf(inputs[i]) === -1) {
      labels.push(inputs[i]);
    }
  }
  let { datasets, labels: label_default } = getEmpty();
  if (labels.length === 0) {
    labels = label_default;
  }
  for (let i = 0; i < labels.length; i++) {
    let index = History.map(x => x.Modality).indexOf(labels[i]);
    if (index !== -1) {
      datasets[3].data.push(History[index].Total);
    } else {
      datasets[3].data.push(0);
    }
    index = ThisYear.map(x => x.Modality).indexOf(labels[i]);
    if (index !== -1) {
      datasets[0].data.push(ThisYear[index].Total);
    } else {
      datasets[0].data.push(0);
    }

    index = LastYear.map(x => x.Modality).indexOf(labels[i]);
    if (index !== -1) {
      datasets[1].data.push(LastYear[index].Total);
    } else {
      datasets[1].data.push(0);
    }

    index = LastMonth.map(x => x.Modality).indexOf(labels[i]);
    if (index !== -1) {
      datasets[2].data.push(LastMonth[index].Total);
    } else {
      datasets[2].data.push(0);
    }
  }
  return { labels, datasets };
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
  },
  dataset: {
    maxBarThickness: 10,
    barThickness: 12,
    categoryPercentage: 0.5,
    barPercentage: 0.5,
  },
  layout: { padding: 0 },
  scales: {
    x: {
      ticks: {
        fontColor: palette.text.secondary,
      },
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
    //  xAxes: [
    //    {
    //      ticks: {
    //        fontColor: palette.text.secondary,
    //      },
    //      gridLines: {
    //        display: false,
    //        drawBorder: false,
    //      },
    //    },
    //  ],
    y: {
      ticks: {
        fontColor: palette.text.secondary,
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: palette.divider,
        drawBorder: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
        zeroLineColor: palette.divider,
      },
    },
    //  yAxes: [
    //    {
    //      ticks: {
    //        fontColor: palette.text.secondary,
    //        beginAtZero: true,
    //        min: 0,
    //      },
    //      gridLines: {
    //        borderDash: [2],
    //        borderDashOffset: [2],
    //        color: palette.divider,
    //        drawBorder: false,
    //        zeroLineBorderDash: [2],
    //        zeroLineBorderDashOffset: [2],
    //        zeroLineColor: palette.divider,
    //      },
    //    },
    //  ],
  },
};
