import React from 'react';
import PropTypes from 'prop-types';
import { DialogDeleteWarning } from 'components';
import { DeleteFoldersMutation } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';

class ExplorerMenuDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      open_delete: false,
      itemOver: null,
    };
    if (props.holder) {
      props.holder.openDelete = (itemOver = null) =>
        this.setState({ open_delete: true, itemOver });
    }
  }
  handleDelete = (folders_ids, content_ids, delete_series) => {
    const { deleting } = this.state;
    if (deleting) {
      return;
    }
    this.setState({ deleting: true });

    (async () => {
      DeleteFoldersMutation(folders_ids, content_ids, delete_series)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteFolders;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.handleDeleteFoldersAndContent({
              ...data.deleteFolders,
              folders_ids,
              content_ids,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, open_delete: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteFolderOrFile = (shared, status, delete_series) => {
    let { selected_objects, itemOver } = this.props;
    if (IsInvalid(itemOver)) {
      itemOver = this.state.itemOver;
    }
    if (selected_objects && selected_objects.length) {
      const folders_ids = selected_objects
        .map(x => {
          const { idFolder, idFolderContent } = x;
          if (idFolderContent) return null;
          return idFolder;
        })
        .filter(x => x);
      const content_ids = selected_objects
        .map(x => x.idFolderContent)
        .filter(x => x);
      if (folders_ids.length || content_ids.length) {
        this.handleDelete(folders_ids, content_ids, delete_series);
      } else {
        this.props.handleAddError('Invalid Selection');
      }
    } else if (this.props.holder.isFolder()) {
      const { idFolder } = this.props.itemOver;
      if (idFolder) {
        this.handleDelete([idFolder], []);
      } else {
        this.props.handleAddError('Invalid Folder');
      }
    } else if (itemOver) {
      const { idFolderContent, idFolder } = itemOver;
      if (idFolderContent) {
        this.handleDelete([], [idFolderContent], delete_series);
      } else if (idFolder) {
        this.handleDelete([idFolder], []);
      } else {
        this.props.handleAddError('Invalid File or Folder');
      }
    } else if (!itemOver || itemOver === undefined) {
      console.log('itemOver is null or undefined', { itemOver });
    }
  };
  render() {
    let { owner, itemOver, selected_objects } = this.props;
    if (IsInvalid(itemOver) && this.state.itemOver) {
      itemOver = this.state.itemOver;
    }
    let selected = [];
    if (selected_objects && selected_objects.length)
      selected = selected.concat(selected_objects);
    else if (itemOver) selected.push(itemOver);
    return (
      <React.Fragment>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteFolderOrFile}
          loading={this.state.deleting}
          open={this.state.open_delete}
          selected={selected}
          shared={!owner}
        />
      </React.Fragment>
    );
  }
}

ExplorerMenuDelete.propTypes = {
  handleDeleteFoldersAndContent: PropTypes.func.isRequired,
  itemOver: PropTypes.object,
};

export default ExplorerMenuDelete;
