import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { useStyles } from './Style';
import { ButtonCircularIconLoad, CardCreateHeader } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationUpdateMappingTable } from 'graphql/Dockers/utils_docker_mapping_tables';
import { QueryTableNames, QueryColumnNames } from 'graphql/utils_tables';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';

class EditMappingDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selected_object: null,
      changed: false,
      saving: false,
      table_list: [],
      column_list: [],
    };
    this.holder = {
      setSetting: null,
      getSetting: null,
      IsChange: null,
    };
  }
  componentDidMount() {
    const { selected } = this.props;
    this.getTableList();
    this.getColumnList();
    const list_selections = selected.map((x, index) => ({
      index,
      id: x.id,
      table_map_name: x.table_map_name,
      table_name_input: { name: x.table_name_input },
      table_name_output: { name: x.table_name_output },
      id_table_input: { name: x.id_table_input },
      id_table_output: { name: x.id_table_output },
    }));
    this.setState({ selected_object: list_selections[0] });
  }
  getTableList = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryTableNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, tables } = data.tableNames;
          if (ok) {
            this.setState({
              loading: false,
              table_list: tables,
              changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getColumnList = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryColumnNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, columns } = data.columnNames;
          if (ok) {
            this.setState({
              loading: false,
              column_list: columns,
              changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectionChanged = selected => {
    console.log({ selected });
  };
  handleSaveClicked = () => {
    const { saving, selected_object } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      const inputs = {
        id: selected_object.id,
        table_name_input: selected_object.table_name_input.name,
        table_name_output: selected_object.table_name_output.name,
        id_table_input: selected_object.id_table_input.name,
        id_table_output: selected_object.id_table_output.name,
        table_map_name: selected_object.table_map_name,
      };
      MutationUpdateMappingTable(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateMappingTable;
          if (ok) {
            this.setState({ saving: false, changed: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedTableInput = table_name_input => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const index = selected_object.index;
    let changed = selected[index].table_name_input !== table_name_input;
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        table_name_input,
      },
    });
  };
  handleSelectedTableOutput = table_name_output => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const index = selected_object.index;
    let changed = selected[index].table_name_output !== table_name_output;
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        table_name_output,
      },
    });
  };
  handleSelectedTableIdInput = id_table_input => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const index = selected_object.index;
    let changed = selected[index].input !== id_table_input;
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        id_table_input,
      },
    });
  };
  handleSelectedTableIdOutput = id_table_output => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const index = selected_object.index;
    let changed = selected[index].id_table_output !== id_table_output;
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        id_table_output,
      },
    });
  };
  handleChangeTableMapName = event => {
    const { selected } = this.props;
    const { selected_object } = this.state;
    const table_map_name = event.target.value;
    const index = selected_object.index;
    let changed = selected[index].table_map_name !== table_map_name;
    this.setState({
      changed,
      selected_object: {
        ...selected_object,
        table_map_name,
      },
    });
  };

  render() {
    const { classes, height } = this.props;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    const {
      selected_object,
      saving,
      changed,
      table_list,
      column_list,
      loading,
    } = this.state;
    let table_map_name = null;
    let table_name_input = null;
    let table_name_output = null;
    let id_table_input = null;
    let id_table_output = null;
    if (selected_object) {
      table_map_name = selected_object.table_map_name;
      table_name_input = selected_object.table_name_input;
      table_name_output = selected_object.table_name_output;
      id_table_input = selected_object.id_table_input;
      id_table_output = selected_object.id_table_output;
    }
    let input_table = '';
    if (table_name_input) {
      input_table = table_name_input.name;
    }
    let output_table = '';
    if (table_name_output) {
      output_table = table_name_output.name;
    }
    let input_id_table = '';
    if (id_table_input) {
      input_id_table = id_table_input.name;
    }
    let output_id_table = '';
    if (id_table_output) {
      output_id_table = id_table_output.name;
    }
    return (
      <Card className={classes.card} style={style}>
        <CardCreateHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateDocker}
          icon={<EditIcon style={{ color: 'green' }} />}
          loading={this.state.loading}
          subheader="Edit the necessary field and save it"
          title="Edit MB-Docker Mapping"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={loading}
                handleAddResultObject={this.handleSelectedTableInput}
                label="Input Table Name"
                name={input_table}
                no_icon
                options={table_list}
                placeholder="Name of the input table (e.g. Series)..."
                required
                selected={table_name_input}
                setObject={this.handleSelectedTableInput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={loading}
                handleAddResultObject={this.handleSelectedTableIdInput}
                label="Input Table ID"
                name={input_id_table}
                no_icon
                options={column_list}
                placeholder="Name of the identifier column in input Table (e.g. idSeries)..."
                required
                selected={id_table_input}
                setObject={this.handleSelectedTableIdInput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={loading}
                handleAddResultObject={this.handleSelectedTableOutput}
                label="Output Table Name"
                name={output_table}
                no_icon
                options={table_list}
                placeholder="Name of the output table (e.g. Sequence)..."
                required
                selected={table_name_output}
                setObject={this.handleSelectedTableOutput}
              />
            </Grid>
            <Grid item xs={6}>
              <SearchAutocomplete
                disabled={loading}
                handleAddResultObject={this.handleSelectedTableIdOutput}
                label="Output Table ID"
                name={output_id_table}
                no_icon
                options={column_list}
                placeholder="Name of the identifier column in output Table (e.g. idSequence)..."
                required
                selected={id_table_output}
                setObject={this.handleSelectedTableIdOutput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="table-map-name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Table Join Name"
                onChange={this.handleChangeTableMapName}
                placeholder="Name of the joining table (e.g. series_sequences)..."
                required
                sx={{ paddingBottom: 1 }}
                value={table_map_name}
              />
            </Grid>
            <Grid item style={{ margin: 'auto' }}>
              <ButtonCircularIconLoad
                disabled={!changed}
                handleButtonClick={this.handleSaveClicked}
                loading={saving}
                small
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

EditMappingDocker.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
EditMappingDocker.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
};
export default withStyles(EditMappingDocker, useStyles);
