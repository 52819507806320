import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_LOGS_USER = gql`
  query($page: Int, $limit: Int) {
    LogsUser(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs {
        id
        idUser
        user {
          id
          FullName
        }
        activity
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogsUser = (page, limit) => {
  const variables = {};
  if (typeof page !== 'undefined') variables.page = page;
  if (typeof limit !== 'undefined') variables.limit = limit;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOGS_USER),
    variables,
  });
};

const MUTATION_DELETE_LOGS_USERS = gql`
  mutation($ids: [LongLong!]!) {
    DeleteLogsUser(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogsUsers = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_LOGS_USERS),
    variables: {
      ids,
    },
  });
};

/* -------------- SITE LOGS ------------------ */

const QUERY_LOGS_SITE = gql`
  query($input: InputLogUser, $page: Int, $limit: Int) {
    LogsSite(input: $input, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs {
        id
        idUser
        user {
          id
          FullName
        }
        activity
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogsSite = (input, page, limit) => {
  const variables = {};
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (input !== undefined && input) {
    variables.input = input;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOGS_SITE),
    variables,
  });
};

const MUTATION_DELETE_SITE_LOGS = gql`
  mutation($ids: [LongLong!]!) {
    DeleteLogsSite(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogsSite = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SITE_LOGS),
    variables: {
      ids,
    },
  });
};
