import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'cross-fetch';

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';

class Countries extends React.Component {
  constructor(props) {
    super(props);
    let { country } = props;
    if (country === 'Unknown' || IsInvalid(country)) {
      country = null;
    }
    let countries = [];
    let country_name = '';
    if (country) {
      countries.push({ name: country });
      country_name = country;
    }
    this.state = {
      open: false,
      countries,
      loading: false,
      error: '',
      country_name,
    };
  }

  handleOpen = open => {
    this.setState({ open });
  };
  handleSelected = (event, country) => {
    this.props.handleCountryChanged(country);
  };
  handleTextCountryChange = (event, newInputValue) => {
    const { country_name } = this.state;
    if (country_name !== newInputValue) {
      this.runSearch(newInputValue);
    }
  };
  runSearch = country_name => {
    if (country_name === undefined) {
      this.setState({ loading: false, error: '' });
      return;
    }
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, error: '', country_name });
    (async () => {
      let response = null;
      try {
        let query = `https://restcountries.com/v2/name/${country_name}`;
        if (country_name === '' || country_name === 'Unknown') {
          query = 'https://restcountries.com/v2/all';
        }
        response = await fetch(query);
        let countries = await response.json();
        if (countries.constructor !== Array) {
          countries = [];
        }
        this.setState({ loading: false, countries, country_name });
      } catch (error) {
        this.setState({
          loading: false,
          error: ServerErrorsString(error),
        });
      }
    })();
  };

  render() {
    const { className, style, required, defaultCountry } = this.props;
    let { country } = this.props;
    let { countries, country_name } = this.state;
    if (country === 'Unknown') {
      country = null;
    }
    const { open, loading, error } = this.state;
    if (!countries.length && country) {
      countries.push({ name: country });
    }
    let inputValue = country_name;
    if (!open && country && country_name === '') {
      inputValue = country;
    }
    return (
      <Autocomplete
        className={className}
        defaultValue={{ name: defaultCountry }}
        disabled={this.props.disabled}
        getOptionLabel={option => {
          //
          if (typeof option !== 'undefined' && option) {
            const { name } = option;
            if (name) return name;
          }
          return '';
        }}
        id="country-selection"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          if (value.name === '') return true;
          return option.name === value.name;
        }}
        loading={loading}
        onChange={this.handleSelected}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={this.handleTextCountryChange}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={countries}
        renderInput={params => (
          <TextField
            {...params}
            error={error !== ''}
            helperText={error !== '' ? error : null}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label="Country"
            margin="dense"
            placeholder="Select your country"
            required={required}
            size="small"
            variant="outlined"
          />
        )}
        style={style}
      />
    );
  }
}

Countries.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
};

Countries.defaultProps = {
  style: {},
  required: false,
  error: '',
  className: '',
  handleCountryChanged: value => `Country ${value} selected.`,
  hasError: () => false,
  disabled: false,
  defaultCountry: '',
};
export default Countries;
