import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { imagesStyles } from 'assets';
const useStyles = () => ({
  section: {
    padding: '70px 0',
    paddingTop: 0,
  },
  title: {
    color: '#3C4858',
    margin: '1.75rem 0 0.875rem',
    textDecoration: 'none',
    fontWeight: '700',
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
    marginBottom: '50px',
    marginTop: '30px',
    minHeight: '32px',
    textAlign: 'center',
  },
  description: {
    color: '#999',
    textAlign: 'center',
  },
  ...imagesStyles,
});
class SectionMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <Grid alignItems="center" container justifyContent="center">
          <Grid cs={12} item md={8} sm={12}>
            <h2 className={classes.title}>Our Collaborators</h2>
            <h4 className={classes.description}>
              Our collaborators range from CROs, institutes, universities,
              university hospitals to small clinics, and we assist with sharing,
              classification, anonymization and data collection of both national
              and international clinical trials.
            </h4>
          </Grid>
          <img
            alt="..."
            className={
              classes.imgRaised +
              ' ' +
              classes.imgRounded +
              ' ' +
              classes.imgFluid
            }
            src={'/images/map.png'}
          />
        </Grid>
      </div>
    );
  }
}

SectionMap.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(SectionMap, useStyles);
