import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';

import { Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import {
  ButtonLoad,
  ButtonReload,
  DivGrow,
  SearchInput,
  StyleText,
} from 'components';

import useStyles from './Styles';
import { IsProjectAdministrator } from 'helpers/Designers';

class ProjectsToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    const { holder } = props;
    if (holder) {
      holder.getProjectName = () => {
        return this.state.text;
      };
    }
  }

  onChange = event => {
    this.setState({ text: event.target.value });
  };

  render() {
    const {
      classes,
      handleCreate,
      title,
      reload,
      sub_title,
      loading,
      user,
    } = this.props;
    const { text } = this.state;
    let CreateButton = null;
    if (IsProjectAdministrator(user)) {
      CreateButton = (
        <Button color="primary" onClick={handleCreate} variant="contained">
          Create Project
        </Button>
      );
    }
    return (
      <Grid container style={{ padding: 10 }}>
        <Grid alignItems="center" container justifyContent="space-between">
          <StyleText fontSize={12} style text={title} variant="button" />
        </Grid>
        <Grid alignItems="center" container justifyContent="space-between">
          <StyleText
            fontSize={22}
            fontWeight={1000}
            spacing={3}
            style
            text={sub_title}
          />
          <DivGrow />
          <ButtonReload
            handleReload={reload}
            loading={loading}
            small
            tooltip="Reload Projects"
          />
        </Grid>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item sm={6} xs={12}>
            <SearchInput
              className={classes.searchInput}
              id={uuid()}
              onChange={this.onChange}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter' && !loading) {
                  this.props.handleSearchProject(text);
                }
              }}
              placeholder="Search project"
              value={text}
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            sm={6}
            xs={12}>
            <ButtonLoad
              className={classes.searchButton}
              component="span"
              handleClick={() => this.props.handleSearchProject(text)}
              loading={loading}
              name="Search"
              variant="contained"
            />
            {CreateButton}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ProjectsToolBar.propTypes = {
  className: PropTypes.string,
};
ProjectsToolBar.defaultProps = {
  handleSearchProject: () => '',
};
export default withStyles(ProjectsToolBar, useStyles);
