import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import { useStyles } from './components/utils';
import {
  IconButtonLoad,
  Sequence,
  SearchCentersInputs,
  CreateCardHeader,
} from 'components';
import { Save as SaveIcon, GetApp as DownloadIcon } from '@mui/icons-material';
import TableSequenceMapping from '../TableSequenceMapping';
import { MutationAddSequencesMapping } from 'graphql/Series/sequences';
import { ServerErrorsString, GetData } from 'helpers';

const CreateMapping = (name, center, sequence) => {
  let idSequence = null;
  let idCenter = null;
  if (center) idCenter = center.id;
  if (sequence) idSequence = sequence.idSequence;
  if (!name) name = '';
  return {
    id: 0,
    idSequence,
    idCenter,
    name,
    center,
    sequence,
    updatedAt: `${moment().valueOf()}`,
    createdAt: `${moment().valueOf()}`,
  };
};
class CreateSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      disable_all: null,
      updating: false,
      current: null,
      mappings: [],
      selected: [],
      page: 0,
      limit: 10,
    };
    this.holder = {
      resetSequence: null,
      resetCenter: null,
      reset: () => {
        if (this.holder.resetSequence) {
          this.holder.resetSequence();
        }
        if (this.holder.resetCenter) {
          this.holder.resetCenter();
        }
      },
    };
  }
  handleChange = event => {
    const name = event.target.value;
    let { current } = this.state;
    if (!current) current = CreateMapping(name, null, null);
    else current.name = name;
    this.setState({ current });
  };
  handleSequenceChanged = (label, sequence) => {
    if (sequence === undefined || !sequence) {
      return;
    }
    let { current } = this.state;
    if (!current) current = CreateMapping('', null, sequence);
    else {
      current.idSequence = sequence.idSequence;
      current.sequence = sequence;
    }
    // console.log({ current });
    this.setState({ current });
  };
  handleSelectCenter = center => {
    if (center === undefined || !center) {
      return;
    }
    let { current } = this.state;
    if (!current) current = CreateMapping('', center, null);
    else {
      current.idCenter = center.id;
      current.center = center;
    }
    // console.log({ current });
    this.setState({ current });
  };
  isChanged = () => {
    return this.state.mappings.length;
  };
  IsReady = () => {
    const { current } = this.state;
    // console.log({ current });
    if (!current) {
      return false;
    }
    if (!current.idCenter) return false;
    if (!current.idSequence) return false;
    if (!current.name || current.name === '') return false;
    return true;
  };
  handleAddMapping = () => {
    const { current, mappings } = this.state;
    if (!this.IsReady()) {
      this.props.handleAddError('Missing Information');
      return;
    }
    const test = `${current.idCenter},${current.idSequence},${current.name}`;
    const index = mappings
      .map(x => `${x.idCenter},${x.idSequence},${x.name}`)
      .indexOf(test);
    if (index !== -1) {
      this.props.handleAddError('Sequence mapping is already here');
      return;
    }
    mappings.unshift(current);
    this.setState({
      mappings: mappings.map((x, id) => ({ ...x, id })),
      current: null,
    });
    this.holder.reset();
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleDeleteSelected = () => {
    const { mappings } = this.state;
    const ids = this.state.selected.map(x => x.id);
    this.setState({
      selected: [],
      mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
    });
  };
  runAddSequenceMappings = () => {
    const { adding } = this.state;
    if (adding) return;
    const in_mappings = this.state.mappings.map(x => ({
      id: x.id,
      idSequence: x.idSequence,
      idCenter: x.idCenter,
      name: x.name,
    }));
    console.log({ m: this.state.mappings, in_mappings });
    if (!in_mappings.length) {
      this.props.handleAddError(
        'There are not mapping selected or the sequence is missing'
      );
      return;
    }
    console.log({ in_mappings });
    this.setState({ adding: true });
    (async () => {
      MutationAddSequencesMapping(in_mappings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.addSequencesMapping;
          if (ok) {
            console.log({ mappings });
            this.setState({ adding: false, mappings: [], selected: [] });
            this.holder.reset();
            this.props.handleAddNewMappings(mappings);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ adding: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes } = this.props;
    const { disable_all, adding, current } = this.state;
    let name = '';
    if (current) name = current.name;
    return (
      <Card className={classes.card}>
        <CreateCardHeader
          description={'Insert the necessary information for the mapping'}
          handleClose={this.props.handleClose}
          title="Create Sequence Mappping"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              alignItems="flex-start"
              container
              direction="row"
              itemjustifyContent="flex-start"
              spacing={2}
              xs={12}>
              <Grid item style={{ paddingRight: 5 }} xs={3}>
                <Sequence
                  handleSelectedChanged={this.handleSequenceChanged}
                  holder={this.holder}
                />
              </Grid>
              <Grid item style={{ paddingRight: 5, marginTop: 8 }} xs={3}>
                <SearchCentersInputs
                  handleAddCenter={this.handleSelectCenter}
                  handleSetCenter={this.handleSelectCenter}
                  holder={this.holder}
                  no_icon
                  placeholder="Search Center..."
                />
              </Grid>
              <Grid item style={{ width: '100%', marginTop: 5 }} xs={3}>
                <TextField
                  // disabled
                  fullWidth
                  id="sequence-mapping-value"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Mapping Value"
                  onChange={this.handleChange}
                  sx={{ m: 1, minWidth: '25ch' }}
                  value={name}
                  variant="standard"
                />
              </Grid>
              <Grid item style={{ width: 20, marginTop: 5 }} xs={2}>
                <Tooltip title="Add Sequence Mapping">
                  <span style={{ margin: 0, padding: 0 }}>
                    <IconButton
                      aria-controls="add-mapping"
                      className={classes.root}
                      onClick={this.handleAddMapping}>
                      <DownloadIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableSequenceMapping
                external
                handleDeleteSelected={this.handleDeleteSelected}
                handleSetSelected={this.handleSetSelected}
                limit={this.state.limit}
                page={this.state.page}
                rows={this.state.mappings}
                selected={this.state.selected}
                total={this.state.mappings.length}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <CardActions disableSpacing>
          <Grid container direction="row" item xs={3}>
            <IconButtonLoad
              disabled={!this.isChanged() || adding || disable_all}
              handleClick={this.runAddSequenceMappings}
              icon={
                <SaveIcon color={this.isChanged() ? 'primary' : 'disabled'} />
              }
              loading={adding}
              tooltip="Update Sequence"
            />
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

CreateSequenceMapping.propTypes = {
  classes: PropTypes.object,
  handleAddNewCenter: PropTypes.func,
};
CreateSequenceMapping.propTypes = {
  handleAddNewCenter: () => '',
  handleAddNewMappings: () => '',
  handleClose: () => '',
};
export default withStyles(CreateSequenceMapping, useStyles);
