/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isInteger } from 'lodash';
import clsx from 'clsx';

import {
  ExplorerViews,
  ExplorerToolBar,
  ExplorerLinearProgress,
  UploadProgress,
} from '../index';
import { useStyles, UpdateToThema } from './Style';
import { FolderQuery, QueryHomeFolder } from 'graphql/Folders';
import { SubsNewFolderContent } from 'graphql/Subscriptions';
import { GetData, getInt, getLevels, ServerErrors } from 'helpers';
import {
  setExplorerFolders,
  setExplorerHome,
  setExplorerProgressFiles,
  setExplorerResetUpload,
  setExplorerStatus,
  setExplorerChanged,
  setExplorerFolderBar,
} from 'redux-store/actions';
import { DivDrawer, DivRoot, FloatingDialogs } from './components';
import {
  IsLocalHomeModified,
  IsSubFolderInFolder,
  RemoveSubFolderAtFolder,
  addFolderToParent,
  removeFolderById,
} from 'helpers/explorer';
import { setContentSharedLink, setFolderSharedLink } from './utils_shared_link';
import {
  setContentDownloadLink,
  setFolderDownloadLink,
} from './utils_download_link';

class ExplorerHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_home: false,
      HomeFolder: null,
      CurrentFolder: { idFolder: -2, Files: [] },
      loading: false,
      error: '',
      warning: '',
      selected_folder: [],
      z: [],
      empty: false,
      open_drawer: false,
      item_clicked: {},
      open_rename: false,
      ReNameFolder: {},
      open_remove: false,
      progress: 0,
      total_folder_objects: 0,
      changed: false,
    };
    this.holder = {
      clearSelection: null,
      renderAgain: null,
      renderAgainFolderDetails: null,
      clearSelectedObjects: () => {
        this.setState({
          selected_folder: [],
          selected_content: [],
        });
      },
    };
  }

  IsEmpty = ExternFolder => {
    let {
      CurrentFolder: { SubFolders, Files },
    } = this.state;
    if (ExternFolder) {
      SubFolders = ExternFolder.SubFolders ? ExternFolder.SubFolders : [];
      Files = ExternFolder.Files ? ExternFolder.Files : [];
    } else {
      if (!SubFolders) {
        SubFolders = [];
      }
      if (!Files) {
        Files = [];
      }
    }
    if (!SubFolders.length && !Files.length) {
      return true;
    }
    return false;
  };
  handleLoadHome = () => {
    const { loading_home } = this.state;
    console.log('handleLoadHome', { loading_home });
    if (loading_home) return;
    this.setState({ loading_home: true });
    (async () => {
      QueryHomeFolder()
        .then(res => {
          const data = GetData(res);
          const { ok, Folder, errors } = data.home;
          if (ok) {
            UpdateToThema(Folder, this.props.user);
            if (Folder.name === 'Home' && Folder.idParent < 0) {
              const index = Folder.SubFolders.map(x => x.name).indexOf(
                'Projects'
              );
              if (index !== -1) {
                Folder.SubFolders[index].name = 'Thema';
              }
            }
            this.setState({ loading_home: false, HomeFolder: Folder });
            this.props.setExplorerHome(Folder);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const list_errors = ServerErrors(errors);

          this.setState({ loading_home: false, error: list_errors.join('\n') });
        });
    })();
  };
  UpdateCurrentFolder = () => {
    const { level_1, level_2, url } = getLevels(this.props);
    const { CurrentFolder } = this.state;
    if (!level_1 || !level_2) {
      if (url !== '/explorer') {
        this.props.history.push('/explorer');
        this.setState({ CurrentFolder: { idFolder: -1 } });
      } else if (!this.IsHome()) {
        this.backToHome();
        this.state.CurrentFolder = { idFolder: -1 };
        this.RunLoadFolder();
      }
    } else {
      const id = getInt(level_2);
      if (level_1 === 'folder' && id !== CurrentFolder.idFolder) {
        this.state.CurrentFolder = { idFolder: id };
        this.RunLoadFolder(id);
      } else {
        const { CurrentFolder, empty } = this.state;
        if (this.IsEmpty(CurrentFolder) && !empty) {
          this.RunLoadFolder();
        }
      }
    }
  };
  IsHome = () => {
    const { CurrentFolder, HomeFolder } = this.state;
    if (!HomeFolder) {
      if (CurrentFolder.idFolder === -1) return true;
      else return false;
    } else if (CurrentFolder.idFolder === HomeFolder.idFolder) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {
    const { folders } = this.props;
    const { level_1, level_2, url } = getLevels(this.props);
    if (
      level_1 === undefined &&
      level_2 === undefined &&
      folders &&
      folders.length
    ) {
      const last_folder = folders[folders.length - 1];
      this.props.history.push(`${url}/folder/${last_folder.idFolder}`);
    } else {
      this.UpdateCurrentFolder();
    }
  }

  componentDidUpdate() {
    this.UpdateCurrentFolder();
    this.UpdateBarClicked();
  }
  UpdateBarClicked = () => {
    const { folder_bar_clicked, folders } = this.props;
    let { item_clicked, open_drawer, CurrentFolder } = this.state;
    if (folder_bar_clicked) {
      if (
        item_clicked &&
        item_clicked.idFolder === folder_bar_clicked.idFolder
      ) {
        return;
      } else {
        if (!open_drawer) open_drawer = true;
        this.setState({ item_clicked: CurrentFolder, open_drawer });
      }
    } else if (item_clicked && folders.length) {
      const { idFolder } = item_clicked;
      if (
        idFolder &&
        idFolder === folders[folders.length - 1].idFolder &&
        open_drawer
      ) {
        this.setState({ item_clicked: null, open_drawer: false });
      }
    }
  };
  handleSelectedAll = (selected_folder, selected_content) => {
    this.setState({ selected_folder, selected_content });
  };
  handleSelected = (Folder, FolderContent) => {
    let { selected_folder, selected_content } = this.state;
    if (Folder && Folder.idFolder !== undefined) {
      const index = selected_folder
        .map(x => x.idFolder)
        .indexOf(Folder.idFolder);
      if (index === -1) {
        selected_folder.push(Folder);
      } else {
        selected_folder = selected_folder.filter(
          x => x.idFolder !== Folder.idFolder
        );
      }
      this.setState({ selected_folder });
    }
    if (FolderContent) {
      const index = selected_content
        .map(x => x.idFolderContent)
        .indexOf(FolderContent.idFolderContent);
      if (index === -1) {
        selected_content.push(FolderContent);
      } else {
        selected_content = selected_content.filter(
          x => x.idFolderContent !== FolderContent.idFolderContent
        );
      }
      this.setState({ selected_content });
    }
  };
  handleRemoveDeleted = (folders_ids, content_ids) => {
    const { CurrentFolder, HomeFolder } = this.state;
    if (!CurrentFolder || CurrentFolder === undefined) return;
    let { Files, SubFolders } = CurrentFolder;

    if (Files && content_ids && content_ids.length) {
      Files = Files.filter(x => {
        const { idFolderContent } = x;
        if (idFolderContent === undefined) {
          return true;
        }
        return content_ids.indexOf(idFolderContent) === -1;
      });
    }
    if (SubFolders && folders_ids && folders_ids.length) {
      SubFolders = SubFolders.filter(x => {
        const { idFolder } = x;
        if (idFolder === undefined) {
          return true;
        }
        return folders_ids.indexOf(idFolder) === -1;
      });
    }
    CurrentFolder.Files = Files;
    CurrentFolder.SubFolders = SubFolders;
    if (HomeFolder && CurrentFolder.idFolder === HomeFolder.idFolder) {
      HomeFolder.Files = Files;
      HomeFolder.SubFolders = SubFolders;
    }
    this.setState({
      CurrentFolder,
      HomeFolder,
      selected_folder: [],
      selected_content: [],
    });
    this.holder.clearSelection();
    this.props.setExplorerStatus({
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
  };
  handleDeleteFolders = information => {
    // console.log({ information });
    const { ok, errors, folders_ids, content_ids } = information;
    if (ok) {
      this.handleRemoveDeleted(folders_ids, content_ids);
    } else {
      console.log('ExplorerHome', { errors });
      this.setState({
        error: ServerErrors(errors).join('\n'),
        selected_folder: [],
        selected_content: [],
      });
      this.holder.clearSelection();
    }
  };
  RunLoadFolder = idExternal => {
    if (!isInteger(idExternal)) {
      idExternal = null;
    }
    let { CurrentFolder, loading, loading_home } = this.state;
    if (loading) return;
    const { fullpath } = this.props;
    if (idExternal) {
      CurrentFolder = { idFolder: idExternal };
    }
    this.setState({
      loading: true,
      selected: [],
      empty: false,
      open_drawer: false,
    });
    (async () => {
      FolderQuery(CurrentFolder.idFolder)
        .then(res => {
          const data = GetData(res);
          const {
            folder: { ok, Folder, errors },
          } = data;
          if (ok) {
            this.props.setExplorerFolderBar(null);
            UpdateToThema(Folder, this.props.user);
            if (Folder) {
              let { folders_paths } = Folder;
              folders_paths = folders_paths.filter(x => x.name !== 'Home');
              const new_paths = folders_paths.map(x => x.name).join('/');
              if (new_paths !== fullpath) {
                this.props.setExplorerFolders(folders_paths);
              }
            }
            const state = {
              loading: false,
              CurrentFolder: Folder,
              error: '',
              empty: this.IsEmpty(Folder),
              selected_folder: [],
              selected_content: [],
              item_clicked: null,
            };
            this.holder.clearSelection();
            if (
              CurrentFolder.idFolder === -1 ||
              (Folder.name === 'Home' && !Folder.idParent)
            ) {
              if (!loading_home) {
                state.HomeFolder = { ...Folder };
                this.props.setExplorerHome(state.HomeFolder);
              }
            }
            this.setState(state);
            this.props.setExplorerStatus({
              page: 0,
              folders: Folder.SubFolders.length,
              files: Folder.Files.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({
            loading: false,
            error: ServerErrors(errors).join('\n'),
            warning: '',
            empty: false,
            selected_folder: [],
            selected_content: [],
          });
          this.holder.clearSelection();
          this.backToHome();
        });
    })();
  };
  handleCloseSnak = status => {
    if (status) {
      this.setState({ error: '' });
    } else {
      this.setState({ warning: '' });
    }
  };
  changeParentFolder = Folder => {
    this.state.CurrentFolder = Folder;
    this.RunLoadFolder(Folder.idFolder);
  };
  IsSameFolder = item => {
    if (item === undefined || !item) {
      return false;
    }
    const { content } = item;
    if (content === undefined || !content) {
      return false;
    }
    const {
      CurrentFolder: { idFolder },
    } = this.state;
    return content.idFolder === idFolder;
  };
  getSeries = item => {
    if (item === undefined || !item) {
      return null;
    }
    const { content } = item;
    if (content === undefined || !content) {
      return null;
    }
    const { Series } = content;
    if (Series === undefined || !Series) {
      return null;
    }
    return Series;
  };
  addToFolder = item => {
    if (item === undefined || !item) {
      return { index: -1 };
    }
    const { content } = item;
    if (content === undefined || !content) {
      return { index: -1 };
    }
    const { idFolderContent, Owner, File, Series, createdAt } = content;
    const file = { idFolderContent, Owner, File, Series, createdAt };
    let {
      CurrentFolder: { Files },
    } = this.state;
    let index = -1;
    if (Files === undefined || !Files) {
      Files = [file];
      index = 0;
    } else {
      index = Files.map(x => x.idFolderContent).indexOf(idFolderContent);
      if (index === -1) {
        Files.push(file);
      } else {
        Files[index] = file;
      }
    }
    return { Files, index };
  };
  handleUploadFiles = (item, total) => {
    // const { total_files } = this.props;
    this.props.setExplorerProgressFiles(total);
    let { CurrentFolder, error, warning } = this.state;
    const { errors, warnings } = item;
    if (this.IsSameFolder(item)) {
      const { index, Files } = this.addToFolder(item);
      if (index !== -1) {
        CurrentFolder.Files = Files;
      }
    } else {
      /* empty */
    }
    if (errors && errors.length) {
      error = ServerErrors(errors).join('\n- ');
    }
    if (warnings && warnings.length) {
      warning = ServerErrors(warnings).join('\n- ');
      if (
        warning === 'The Series is already in the folder, we add the file to it'
      ) {
        const Series = this.getSeries(item);
        if (Series) {
          const { NumberOfSeriesRelatedInstances } = Series;
          warning = `The Series (${NumberOfSeriesRelatedInstances}) is already in the folder, we add the file to it`;
        }
      }
    }
    this.setState({ CurrentFolder, warning, error });
  };
  handleDrawerClose = () => {
    this.setState({ open_drawer: false });
  };
  handleClickFolderItem = new_item_clicked => {
    if (this.props.folder_bar_clicked) this.props.setExplorerFolderBar(null);
    const { item_clicked } = this.state;
    let idFolder = -23;
    let idFolderContent = -23;
    if (item_clicked) {
      idFolder = item_clicked.idFolder;
      idFolderContent = item_clicked.idFolderContent;
    }
    if (
      !item_clicked ||
      Object.keys(item_clicked).length === 0 ||
      (new_item_clicked.idFolder === idFolder &&
        new_item_clicked.idFolderContent === idFolderContent)
    ) {
      this.setState(prevState => ({
        open_drawer: !prevState.open_drawer,
        item_clicked: new_item_clicked,
      }));
    } else {
      this.setState({ item_clicked: new_item_clicked });
    }
  };
  backToHome = () => {
    const { folders } = this.props;
    if (folders.length) {
      this.props.setExplorerFolders([]);
    }
    const { url } = getLevels(this.props);
    if (!url || url !== '/explorer') {
      this.props.history.push('/explorer');
    }
  };
  handleClickMenuItems = value => {
    if (value === 'shared') {
      this.props.history.push('/explorer/shared');
    }
  };
  handleShareFolder = () => {
    this.setState({ selected_folder: [], selected_content: [] });
    this.holder.clearSelection();
  };
  handleSubFolderNameChanged = (idFolder, name) => {
    this.setState({ open_rename: true, ReNameFolder: { idFolder, name } });
  };
  handleCloseReName = (status, folder) => {
    const state = { open_rename: false };
    let { rerender } = this.state;

    if (folder) {
      const { CurrentFolder, item_clicked } = this.state;
      const index = CurrentFolder.SubFolders.map(x => x.idFolder).indexOf(
        folder.idFolder
      );
      if (index !== -1) {
        CurrentFolder.SubFolders[index].name = folder.name;
        state.CurrentFolder = CurrentFolder;
        rerender = true;
        if (
          item_clicked &&
          item_clicked.idFolder === CurrentFolder.SubFolders[index].idFolder
        ) {
          item_clicked.name = folder.name;
          state.item = item_clicked;
        }
      }
      if (!status) {
        this.props.handleAddSuccess('Folder re-named');
      }
    }
    console.log({ state, rerender, folder });

    this.setState({ ...state, rerender, changed: !this.state.changed });
    if (rerender) {
      this.holder.renderAgain();
      if (this.holder.renderAgainFolderDetails) {
        this.holder.renderAgainFolderDetails();
      }
    }
    this.props.setExplorerChanged(!this.props.home_change);
  };
  handleRemoveFromFolder = () => {
    const { selected_content } = this.state;
    const indices = selected_content.map(x => x.idFolderContent);
    let { CurrentFolder } = this.state;
    let { Files } = CurrentFolder;
    if (Files === undefined || !Files) {
      return;
    }
    Files = Files.filter(x => {
      const { idFolderContent } = x;
      if (idFolderContent === undefined) {
        return true;
      }
      return indices.indexOf(idFolderContent) === -1;
    });
    CurrentFolder.Files = Files;
    this.setState({ CurrentFolder, selected_content: [], open_remove: false });
    this.holder.clearSelection();
    this.props.setExplorerStatus({
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
  };
  handleOpenRemove = () => {
    this.setState({ open_remove: true });
  };
  handleCloseRemove = () => {
    this.holder.clearSelection();
    this.setState({ open_remove: false, selected_content: [] });
  };
  handleItemChanged = item_clicked => {
    console.log({ item_clicked });
    const { CurrentFolder } = this.state;
    const index = CurrentFolder.Files.map(x => x.idFolderContent).indexOf(
      item_clicked.idFolderContent
    );
    if (index !== -1) {
      CurrentFolder.Files[index] = { ...item_clicked };
      this.setState({ CurrentFolder, item_clicked });
    }
  };
  updateLinkAtFolder = (idFolder, link, SubFolders) => {
    if (SubFolders === undefined || !SubFolders || !SubFolders.length) {
      return null;
    }
    let updated = false;
    for (let i = 0; i < SubFolders.length; i++) {
      const folder = SubFolders[i];

      if (idFolder === folder.idFolder) {
        SubFolders[i].link = link;
        return SubFolders;
      } else {
        const sub_folders = this.updateLinkAtFolder(
          idFolder,
          link,
          folder.SubFolders
        );
        if (sub_folders) {
          SubFolders[i].SubFolders = sub_folders;
          updated = true;
        }
      }
    }
    if (updated) {
      return SubFolders;
    }
    return null;
  };
  handleSharedLinkChanged = ({ idFolder, idFolderContent }, link) => {
    if (idFolder) {
      setFolderSharedLink(idFolder, link, this);
    } else if (idFolderContent) {
      setContentSharedLink(idFolderContent, link, this);
    }
  };
  handleDownloadLinkChanged = (
    { idFolder, idFolderContent },
    download_link
  ) => {
    if (idFolder) {
      setFolderDownloadLink(idFolder, download_link, this);
    } else if (idFolderContent) {
      setContentDownloadLink(idFolderContent, download_link, this);
    }
  };
  addNewFolderContent = content => {
    const { CurrentFolder } = this.state;
    if (CurrentFolder.idFolder !== content.idFolder) {
      return;
    }
    for (let i = 0; i < CurrentFolder.Files.length; i++) {
      const { idFolderContent } = CurrentFolder.Files[i];
      if (idFolderContent === content.idFolderContent) {
        return;
      }
    }
    CurrentFolder.Files.push(content);
    this.props.setExplorerStatus({
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
    this.setState({ CurrentFolder });
  };
  deletedFolderContent = content => {
    const { CurrentFolder } = this.state;

    if (CurrentFolder.idFolder !== content.idFolder) {
      return;
    }
    let count = 0;
    let files = [];
    for (let i = 0; i < CurrentFolder.Files.length; i++) {
      const { idFolderContent } = CurrentFolder.Files[i];
      if (idFolderContent === content.idFolderContent) {
        count++;
      } else {
        files.push(CurrentFolder.Files[i]);
      }
    }
    if (!count) return;
    CurrentFolder.Files = files;
    this.setState({ CurrentFolder });
    this.props.setExplorerStatus({
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
  };
  actionSubFolder = (sub_folder, action) => {
    const { CurrentFolder } = this.state;
    // console.log('actionSubFolder', { action, CurrentFolder });
    if (CurrentFolder.idFolder !== sub_folder.idParent) {
      return;
    }
    const { LocalHomeFolder } = this.props;
    let count = 0;
    let sub_folders = [];
    for (let i = 0; i < CurrentFolder.SubFolders.length; i++) {
      const { idFolder } = CurrentFolder.SubFolders[i];
      if (idFolder === sub_folder.idFolder) {
        count++;
        // if (action === 'new') {
        //   if (IsSubFolderInFolder(LocalHomeFolder, sub_folder)) return;
        // }
      }
      // else {
      sub_folders.push(CurrentFolder.SubFolders[i]);
      // }
    }
    if (action === 'new') {
      if (!count) sub_folders.push(sub_folder);
    } else if (action === 'delete') {
      const itemRemoved = RemoveSubFolderAtFolder(LocalHomeFolder, sub_folder);
      if (!count) {
        // console.log({
        //   itemRemoved,
        //   count,
        //   action,
        //   LocalHomeFolder,
        //   sub_folder,
        // });
        if (itemRemoved) {
          this.props.setExplorerHome(LocalHomeFolder);
        }
        return;
      }
    } else {
      console.log({ count, action });
      return;
    }
    CurrentFolder.SubFolders = sub_folders;
    this.setState({ CurrentFolder });
    // console.log({ action, sub_folder });
    let { HomeFolder } = this.state;
    if (
      IsLocalHomeModified(sub_folder, LocalHomeFolder) ||
      !IsSubFolderInFolder(LocalHomeFolder, sub_folder.idFolder)
    ) {
      if (action === 'new') {
        // console.log({ LocalHomeFolder, sub_folder });
        const addedRemoved = addFolderToParent(LocalHomeFolder, sub_folder);
        console.log({ addedRemoved, sub_folder });
        if (addedRemoved) {
          const local = LocalHomeFolder.SubFolders.length;
          let home = 0;
          if (HomeFolder) home = HomeFolder.SubFolders.length;
          // console.log({ local, home });
          if (home < local) {
            if (HomeFolder) HomeFolder.SubFolders = LocalHomeFolder.SubFolders;
            else HomeFolder = LocalHomeFolder;
            this.setState({ HomeFolder });
          }
          this.props.setExplorerHome(LocalHomeFolder);
        }
      } else if (action === 'delete') {
        const SubFolders = LocalHomeFolder.SubFolders;
        const itemRemoved = removeFolderById(SubFolders, sub_folder.idFolder);
        console.log({ itemRemoved });
        if (itemRemoved) {
          this.props.setExplorerHome(LocalHomeFolder);
        }
      }
    }
    this.props.setExplorerStatus({
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
  };
  handleCurrentFolderChanged = () => {
    console.log('handleCurrentFolderChanged');
    const { CurrentFolder } = this.state;
    const { SubFolders, Files } = CurrentFolder;

    const total_folder_objects = SubFolders.length + Files.length;
    this.setState({ total_folder_objects });
    this.props.setExplorerStatus({
      page: 0,
      folders: CurrentFolder.SubFolders.length,
      files: CurrentFolder.Files.length,
    });
  };
  handleUpdateFolderContent = folder_content => {
    if (folder_content === undefined || !folder_content) return;
    const { idFolderContent } = folder_content;
    if (idFolderContent === undefined || !idFolderContent) return;
    const { CurrentFolder } = this.state;
    if (CurrentFolder === undefined || !CurrentFolder) return;
    const { Files } = CurrentFolder;
    if (Files === undefined || !Files) return;
    const index = Files.map(x => x.idFolderContent).indexOf(idFolderContent);
    if (index === -1) return;
    CurrentFolder.Files[index] = {
      ...folder_content,
    };
    this.setState({ CurrentFolder });
  };
  render() {
    const {
      CurrentFolder,
      loading,
      selected_folder,
      selected_content,
      open_drawer,
      item_clicked,
    } = this.state;
    const {
      classes,
      total_files,
      isDesktop,
      isMobile,
      view_type,
      height,
    } = this.props;
    const { Files } = CurrentFolder;
    let total = 0;
    if (Files !== undefined && Files) {
      total = CurrentFolder.Files.length;
    }
    return (
      <DivRoot
        classes={classes}
        isDesktop={isDesktop}
        isMobile={isMobile}
        open_drawer={open_drawer}>
        <FloatingDialogs
          handleAddSuccess={this.props.handleAddSuccess}
          handleCloseRemove={this.handleCloseRemove}
          handleCloseReName={this.handleCloseReName}
          handleCloseSnak={this.handleCloseSnak}
          handleRemoveFromFolder={this.handleRemoveFromFolder}
          holder={this.holder}
          state={this.state}
        />
        <ExplorerToolBar
          changeParentFolder={this.changeParentFolder}
          handleAddError={this.props.handleAddError}
          handleClickMenuItems={this.handleClickMenuItems}
          handleCurrentFolderChanged={this.handleCurrentFolderChanged}
          handleDeleteFolders={this.handleDeleteFolders}
          handleDrawerClose={this.handleDrawerClose}
          handleExplorerSortBy={this.props.handleExplorerSortBy}
          handleProgress={progress => this.setState({ progress })}
          handleRefresh={this.RunLoadFolder}
          handleShareFolder={this.handleShareFolder}
          isDesktop={isDesktop}
          IsHome={this.IsHome}
          loading={loading}
          menu_items={['Shared']}
          open_drawer={open_drawer}
          order={this.props.order}
          orderBy={this.props.orderBy}
          ParentFolder={CurrentFolder}
          selected_content={selected_content}
          selected_folder={selected_folder}
        />
        <ExplorerLinearProgress progress={this.state.progress} />
        <div
          className={clsx({
            [classes.main_container]: !view_type,
          })}
          style={{ height: height - 240, overflow: 'auto' }}>
          <DivDrawer
            base="Home"
            classes={classes}
            CurrentFolder={CurrentFolder}
            handleAddError={this.props.handleAddError}
            handleDeleteFolders={this.handleDeleteFolders}
            handleDownloadLinkChanged={this.handleDownloadLinkChanged}
            handleDrawerClose={this.handleDrawerClose}
            handleItemChanged={this.handleItemChanged}
            handleProgress={progress => this.setState({ progress })}
            handleSharedLinkChanged={this.handleSharedLinkChanged}
            handleSubFolderNameChanged={this.handleSubFolderNameChanged}
            handleUpdateFolderContent={this.handleUpdateFolderContent}
            height={height}
            holder={this.holder}
            isDesktop={isDesktop}
            isMobile={isMobile}
            item_clicked={item_clicked}
            open_drawer={open_drawer}
            total={total}>
            <div className={classes.folder_container}>
              <SubsNewFolderContent
                actionSubFolder={this.actionSubFolder}
                addNewFolderContent={this.addNewFolderContent}
                deletedFolderContent={this.deletedFolderContent}
                idFolder={CurrentFolder.idFolder}>
                <ExplorerViews
                  base="Home"
                  changed={this.state.changed}
                  Folder={CurrentFolder}
                  handleAddError={this.props.handleAddError}
                  handleAddSuccess={this.props.handleAddSuccess}
                  handleClickFolderItem={this.handleClickFolderItem}
                  handleCurrentFolderChanged={this.handleCurrentFolderChanged}
                  handleDeleteFoldersAndContent={this.handleDeleteFolders}
                  handleExplorerSortBy={this.props.handleExplorerSortBy}
                  handleItemChanged={this.handleItemChanged}
                  handleProgress={progress => this.setState({ progress })}
                  handleRemoveFromFolder={this.handleOpenRemove}
                  handleRenameFolder={this.handleCloseReName}
                  handleSelected={this.handleSelected}
                  handleSelectedAll={this.handleSelectedAll}
                  handleSharedLinkChanged={this.handleSharedLinkChanged}
                  handleSubFolderNameChanged={this.handleSubFolderNameChanged}
                  handleUploadFiles={this.handleUploadFiles}
                  height={height}
                  holder={this.holder}
                  HomeFolder={this.state.HomeFolder}
                  loading={loading}
                  open_drawer={open_drawer}
                  order={this.props.order}
                  orderBy={this.props.orderBy}
                  selected_content={selected_content}
                  selected_folder={selected_folder}
                  total={total}
                />
              </SubsNewFolderContent>
            </div>
          </DivDrawer>
          {total_files ? <UploadProgress /> : null}
        </div>
      </DivRoot>
    );
  }
}

ExplorerHome.defaultProps = {
  total_files: 0,
};
ExplorerHome.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { page_explorer, layout } = state;
  let total_path_folders = 0;
  let fullpath = '';
  if (page_explorer.folders) {
    total_path_folders = page_explorer.folders.length;
    fullpath = page_explorer.folders.map(x => x.name).join('/');
  }
  return {
    LocalHomeFolder: page_explorer.LocalHomeFolder,
    view_type: page_explorer.view_type,
    folders: page_explorer.folders,
    fullpath,
    total_path_folders,
    total_files: page_explorer.total_files,
    isDesktop: layout.isDesktop,
    isMobile: layout.isMobile,
    user: state.info_user.user,
    home_change: page_explorer.home_change,
    folder_bar_clicked: page_explorer.folder_bar_clicked,
  };
};
export default connect(mapStateToProps, {
  setExplorerProgressFiles,
  setExplorerResetUpload,
  setExplorerFolders,
  setExplorerHome,
  setExplorerStatus,
  setExplorerChanged,
  setExplorerFolderBar,
})(withRouter(withStyles(ExplorerHome, useStyles)));
