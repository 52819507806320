import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import { DivGrow, IconButtonLoad } from 'components';
import { MutationUpdateVariableType } from 'graphql/Mapping/utils_var_types';
import { GetData, ServerErrorsString } from 'helpers';

class EditBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { updating: false };
  }
  handleUpdateVarType = () => {
    const { to_edit } = this.props;
    const { updating } = this.state;
    if (updating || !to_edit) return;
    this.setState({ updating: true });
    this.props.handleDisableAll(true);
    (async () => {
      MutationUpdateVariableType(to_edit.id, to_edit.name, to_edit.description)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateVariableType;
          if (ok) {
            this.setState({ updating: false });
            this.props.handleUpdatedVarType(to_edit);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ updating: false });
          let errors = ServerErrorsString(error);
          if (!to_edit.name || to_edit.name === '') {
            errors = 'The name of a variable cannot be empty';
          }
          this.props.handleAddError(errors);
          this.props.handleDisableAll(false);
        });
    })();
  };
  render() {
    const { original, to_edit } = this.props;
    const { updating } = this.state;
    let changed = false;
    if (original && to_edit) {
      if (
        to_edit.name !== original.name ||
        to_edit.description !== original.description
      ) {
        changed = true;
      }
    }
    return (
      <React.Fragment>
        <DivGrow />
        <IconButtonLoad
          disabled={!changed || updating}
          handleClick={this.handleUpdateVarType}
          icon={<SaveIcon color={changed ? 'primary' : 'disabled'} />}
          loading={updating}
          tooltip="Update Variable Type"
        />
      </React.Fragment>
    );
  }
}

EditBottom.propTypes = {
  classes: PropTypes.object,
};
EditBottom.defaultProps = {
  handleAddError: () => '',
  handleDisableAll: () => '',
  handleUpdatedVarType: () => '',
};
export default EditBottom;
