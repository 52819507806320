import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, Divider, Grid } from '@mui/material';
import { SearchProjectsAtUser, Transition } from 'components';
import { SelectStudySeriesLevel } from './components';
import { connect } from 'react-redux';
import {
  setDatabaseQueryLevel,
  setDatabaseQueryProject,
} from 'redux-store/actions/page_database';

class DialogQuerySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
    };
    const { holder } = props;
    if (holder) {
      holder.resetChange = () => this.setState({ changed: false });
    }
  }

  handleSelectLevel = level => {
    this.setState({ changed: true });
    this.props.setDatabaseQueryLevel(level);
  };
  handleSelectProject = selected => {
    this.setState({ changed: true });
    this.props.setDatabaseQueryProject(selected);
  };

  render() {
    const { setting } = this.props;
    let level = null;
    let project = null;
    if (setting) {
      if (setting.level) {
        level = setting.level;
      }
      if (setting.project) {
        project = setting.project;
      }
    }
    if (!level) {
      level = { name: 'All', index_level: 2 };
    }
    let UserProjects = null;
    if (level.index_level === 1) {
      UserProjects = (
        <Grid container style={{ width: '100%', margin: 5, marginRight: 10 }}>
          <Grid item style={{ paddingRight: 10, marginTop: 15 }} xs={12}>
            <SearchProjectsAtUser
              external_selected={project}
              handleAddProject={this.handleSelectProject}
              handleSetProject={this.handleSelectProject}
              no_icon
              placeholder="Search My Projects..."
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => this.props.handleClose(this.state.changed)}
        open={this.props.open}
        // style={{ padding: 10, }}
        // PaperComponent={PaperComponent}
        TransitionComponent={Transition}>
        <DialogTitle
          id="draggable-dialog-title"
          style={{ cursor: 'move', margin: 5, marginLeft: 10, padding: 0 }}>
          Query Settings
        </DialogTitle>
        <Divider />
        <Grid container style={{ maxWidth: 300 }}>
          <SelectStudySeriesLevel
            handleSelectLevel={this.handleSelectLevel}
            level={level}
          />
          {UserProjects}
        </Grid>
        <Divider />
        <div style={{ margin: 10 }} />
      </Dialog>
    );
  }
}

DialogQuerySettings.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { setting } = state.page_database;
  let index_level = 2;
  if (setting && setting.level) {
    index_level = setting.level.index_level;
  }
  return { setting, index_level };
};
export default connect(mapStateToProps, {
  setDatabaseQueryLevel,
  setDatabaseQueryProject,
})(DialogQuerySettings);
