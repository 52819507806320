import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardActions, CardHeader, IconButton } from '@mui/material';
import clsx from 'clsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    flex: 1,
  },
  appBar: {
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  card_header: {
    marginLeft: theme.spacing(1),
    padding: 0,
  },
});

class TopToolBar extends Component {
  render() {
    const { classes, open, handleBack } = this.props;

    return (
      <CardActions
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        disableSpacing>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <CardHeader
          className={classes.card_header}
          subheader="Select global series details"
          title="Series Details"
        />
      </CardActions>
    );
  }
}

TopToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
TopToolBar.defaultProps = {
  open: false,
};
export default withStyles(TopToolBar, useStyles);
