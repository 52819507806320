import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';

import { blue, green, grey, red, yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import DoughnutView from '../DoughnutView/DoughnutView';

const Item = styled(Paper)(() => ({
  backgroundColor: '#F4F6F8',
  boxShadow: 'none',
  borderRadius: '0',
}));

class TableTopFive extends Component {
  render() {
    const { classes, data, titles, type } = this.props;
    return (
      <div style={{ height: 370 }}>
        <Item>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center">
            <Grid item xs={8}>
              <Box mb={1.5} mr={1} mt={1.5}>
                <Typography align="center" variant="caption">
                  <Box
                    sx={{
                      textAlign: 'center',
                      fontWeight: '500',
                      color: '#333333',
                      fontSize: '12px',
                    }}>
                    Top 5
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={1.5} ml={2} mt={1.5}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontWeight: '500',
                    color: '#333333',
                    fontSize: '12px',
                  }}
                  variant="caption">
                  {type} (%)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Item>
        <DoughnutView classes={classes} data={data} titles={titles} />
      </div>
    );
  }
}

TableTopFive.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  titles: PropTypes.array,
  type: PropTypes.string,
};
TableTopFive.defaultProps = {
  data: {
    datasets: [
      {
        data: [0],
        backgroundColor: [
          blue[900],
          red[500],
          '#ED6C02',
          green[800],
          yellow[700],
        ],
        borderColor: grey[50],
        hoverBorderColor: grey[50],
      },
    ],
    labels: ['Not Defined'],
  },
  titles: [],
  type: 'Not Defined',
};
export default TableTopFive;
