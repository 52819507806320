import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  Divider,
  LinearProgress,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonIconLoad, DivGrow } from 'components';
const useStyles = theme => ({
  text: {
    marginLeft: theme.spacing(5),
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'none',
  },
  button: {
    marginLeft: 10,
    marginRight: theme.spacing(5),
    backgroundColor: 'red',
  },
  upload: {
    backgroundColor: 'green',
  },
});
class UploadProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      total_files,
      loading,
      progress_all,
      total_uploaded,
      marginBottom,
      disabled,
    } = this.props;
    if (total_files < 1) return null;
    return (
      <Grid item xs={12}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pt={1}>
          <Typography className={classes.text} component="h6" variant="h6">
            {`Uploading: ${total_uploaded} of ${total_files}`}
          </Typography>
          <DivGrow />
          <ButtonIconLoad
            className={classes.upload}
            disabled={total_uploaded === total_files || disabled}
            handleClick={this.props.handleUploadAll}
            icon={<CloudUploadIcon />}
            loading={loading}
            text="Upload All"
            variant="contained"
          />
          <Button
            className={classes.button}
            // color="secondary"
            onClick={this.props.handleDeleteAll}
            startIcon={<DeleteIcon />}
            variant="contained">
            Delete All
          </Button>
        </Grid>
        {(loading || progress_all > 0) && (
          <LinearProgress value={progress_all} variant="determinate" />
        )}
        <Divider style={{ marginBottom, marginTop: 5 }} variant="middle" />
      </Grid>
    );
  }
}

UploadProgress.propTypes = {
  disabled: PropTypes.bool,
  handleDeleteAll: PropTypes.func,
  handleUploadAll: PropTypes.func,
};
UploadProgress.defaultProps = {
  handleUploadAll: () => '',
  handleDeleteAll: () => '',
  marginBottom: '5px',
  disabled: false,
};

export default withStyles(UploadProgress, useStyles);
