import React from 'react';
import { TableBase } from 'components';
import { getDate, getUserFullName } from 'helpers';

const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'projects',
    label: 'Projects',
    minWidth: 100,
    // format: value => GetSizeFromArray(value),
  },
  {
    id: 'admin',
    label: 'Administrator',
    minWidth: 170,
    align: 'left',
    format: value => getUserFullName(value),
  },
  {
    id: 'members',
    label: 'Number of Users',
    minWidth: 170,
    align: 'right',
    // type: 'count',
    // format: value => (value ? value.toLocaleString() : '0'),
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 200,
    align: 'right',
    format: value => getDate(value),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'right',
    type: 'buttom',
  },
];

const menu_items = ['Add Users', 'divider', 'Delete'];

class TableTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      order: 'asc',
      orderBy: 'name',
      selected: [],
      rows: props.rows ? props.rows : [],
    };
  }

  handleClickMenu = (event, data) => {
    const { action, row } = data;
    const { handleMenuAction } = this.props;
    if (handleMenuAction) {
      handleMenuAction({ action, row });
    }
  };
  handleSelectedRow = () => {};

  render() {
    const {
      rows,
      title,
      handleDeleteSelected,
      handleOpenTeamView,
    } = this.props;
    return (
      <TableBase
        columns={columns}
        externalDeleteSelected={handleDeleteSelected}
        handleButtonView={handleOpenTeamView}
        handleClickMenu={this.handleClickMenu}
        handleSelectedRow={this.handleSelectedRow}
        menu_items={menu_items}
        rows={rows && rows.length ? rows : []}
        title={title ? title : 'Current Teams'}
      />
    );
  }
}

export default TableTeams;
