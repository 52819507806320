import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { SearchUnit, TableMyUnits } from 'components';
import {
  CardButtonActions,
  CardProjectHeader,
} from 'components/Cards/components';
import {
  MutationAddUnits,
  MutationRemoveUnits,
  QueryMyUnits,
} from 'graphql/Units';
import {
  GenerateSearchName,
  GetData,
  SelectedArray,
  ServerErrorsString,
} from 'helpers';
import { DialogAddUnit, DialogUnit } from '../index';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    height: '100%',
  },
  table: {
    height: 'auto',
    margin: theme.spacing(0, 2, 2, 2),
  },
});

class MyUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      loading: false,
      myunits: [],
      newunits: [],
      selected_units: [],
      name: '',
      page: 0,
      limit: 0,
      open: false,
      unit_to_add: null,
      open_add: false,
    };
    const { holder } = props;
    if (holder) {
      holder.openAddNewUnit = () => this.setState({ open_add: true });
    }
  }

  componentDidMount() {
    this.runUpdateUnits();
  }

  componentDidUpdate() {}

  IsUnitHere = (unit, myunits) => {
    if (typeof myunits === 'undefined') {
      myunits = this.state.myunits;
    }
    const index = myunits
      .map(x => GenerateSearchName(x))
      .indexOf(GenerateSearchName(unit));
    return index !== -1;
  };
  runUpdateUnits = external_name => {
    const { loading } = this.state;
    if (loading) return;
    const { name, page, limit } = this.state;
    if (typeof external_name === 'undefined') {
      external_name = name;
    }
    this.setState({ loading: true });
    (async () => {
      QueryMyUnits(external_name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { MyUnits } = data;
          if (MyUnits) {
            const { ok, units } = MyUnits;
            //
            if (ok) {
              this.setState({
                loading: false,
                myunits: units,
                length: units.length,
              });
            } else {
              throw MyUnits.errors;
            }
          } else {
            throw Error('Unkown server error');
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDisplayUnit = unit => {
    if (typeof unit !== 'undefined' && typeof unit.idUnit !== 'undefined') {
      this.setState({ unit_to_add: unit, open: true });
    } else {
      this.setState({ unit_to_add: null, open: false });
    }
  };
  handleDialogAddUnit = () => {
    this.setState(prev => ({ open_add: !prev.open_add }));
  };
  handleAddUnit = (unit, errors) => {
    console.log('handleAddUnit', { unit });
    const { myunits, newunits } = this.state;
    if (unit && this.IsUnitHere(unit)) {
      this.props.handleAddError(`The unit "${unit.name}" is here.`);
    } else if (typeof errors !== 'undefined' && errors) {
      this.props.handleAddError(errors);
    } else if (!unit) {
      this.props.handleAddError('The unit requested is null');
    } else {
      myunits.push(unit);
      newunits.push(unit);
      this.setState({ myunits, open_add: false, newunits });
    }
  };
  handleSelectedUnit = (event, index, unit) => {
    const { selected_units } = this.state;
    const selectedIndex = selected_units
      .map(x => GenerateSearchName(x))
      .indexOf(GenerateSearchName(unit));
    const newSelected = SelectedArray(selected_units, unit, selectedIndex);
    this.setState({ selected_units: newSelected });
  };
  runSaveUnits = () => {
    const { loading } = this.props;
    if (loading) return;
    const { newunits } = this.state;
    const units = [];
    for (let i = 0; i < newunits.length; i++) {
      const { Admin, ...res } = newunits[i];
      const { email } = Admin;
      units.push({ email, ...res });
    }
    this.setState({ loading: true });
    (async () => {
      MutationAddUnits(units)
        .then(res => {
          const data = GetData(res);
          const {
            AddUnits: { ok, errors },
          } = data;
          if (ok) {
            this.setState({ loading: false, newunits: [] });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, newunits: [] });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    const { selected_units, deleting } = this.state;
    if (deleting) return;
    const { unit } = this.props;
    const index = selected_units
      .map(x => GenerateSearchName(x))
      .indexOf(GenerateSearchName(unit));
    if (index !== -1) {
      this.props.handleAddError('You cannot delete your unit');
      return;
    }
    this.setState({ deleting: true });
    const ids = selected_units.map(x => x.idUnit);
    let { myunits } = this.state;
    (async () => {
      MutationRemoveUnits(ids)
        .then(res => {
          const data = GetData(res);
          const {
            RemoveUnits: { ok, errors },
          } = data;
          if (ok) {
            myunits = myunits.filter(x => ids.indexOf(x.idUnit) === -1);
            this.setState({ deleting: false, myunits, selected_units: [] });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, maximize, show } = this.props;
    const {
      deleting,
      loading,
      myunits,
      open,
      unit_to_add,
      open_add,
      newunits,
      selected_units,
    } = this.state;
    if (!show && !maximize) return null;
    return (
      <Card className={classes.root}>
        <CardProjectHeader
          handleMaximize={() => this.props.handleMaximize('sites')}
          subtitle="List of units"
          title="Units"
        />
        <Divider variant="middle" />
        <CardContent className={classes.content}>
          <Grid
            alignItems="stretch"
            className={classes.toolbar}
            container
            direction="column"
            justifyContent="flex-start"
            spacing={1}>
            <Grid item xs={12}>
              <SearchUnit handleAddUnit={this.handleAddUnit} />
            </Grid>
            <Grid item xs={12}>
              <TableMyUnits
                deleting={deleting}
                dense
                handleButtonView={this.handleDisplayUnit}
                handleDeleteSelected={this.handleDeleteSelected}
                handleReloadTable={() => this.runUpdateUnits()}
                handleSelectedClick={this.handleSelectedUnit}
                loading={loading}
                rows={myunits}
                selected={selected_units}
                title="My Units"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <CardButtonActions
          changed={newunits.length}
          changed_left={!loading}
          handleLeftClick={this.handleDialogAddUnit}
          handleSaveClicked={this.runSaveUnits}
          icon_left={<AddIcon />}
          left
          loading={loading}
        />
        <DialogUnit
          handleCloseDialog={this.handleDisplayUnit}
          open={open}
          unit={unit_to_add}
        />
        <DialogAddUnit
          addUnit={this.handleAddUnit}
          handleClose={this.handleDialogAddUnit}
          open={open_add}
        />
      </Card>
    );
  }
}

MyUnits.propTypes = {
  classes: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
};
MyUnits.defaultProps = {
  sites: [],
  maximize: false,
  show: true,
};

export default withStyles(MyUnits, useStyles);
