import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

const useStyles = () => ({
  widgetOuter: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    height: '1.7em',
  },
  widgetSelect: {
    fontSize: 'small',
  },
  widget: {
    opacity: '0.5',
    fontSize: 'large',
    marginLeft: '0.3em',
    marginRight: '0.3em',
  },
  widgetSlider: {
    minWidth: '120px',
  },
  widgetValue: {
    float: 'right',
    fontSize: 'medium',
    order: 1,
    minWidth: '3em',
  },
  widgetLabel: {
    fontSize: 'medium',
    minWidth: '10em',
    textAlign: 'right',
  },
});

class WebGLSlider extends React.Component {
  constructor(props) {
    super(props);
    const { options } = props;
    const precision = options.precision || 0;
    this.state = {
      precision,
      step: options.step === undefined ? 1 : options.step,
      value: options.value === undefined ? 0 : options.value,
      name: options.name ? options.name : '',
      uiPrecision:
        options.uiPrecision === undefined ? precision : options.uiPrecision,
      uiMult: options.uiMult || 1,
    };
    // fn = options.slide,
  }
  handleChange = event => {
    const { step, precision } = this.state;
    let value = parseInt(event.target.value);
    this.setState({ value: value * step });
    let final = parseFloat(value * step).toFixed(precision);
    this.props.slide(event, { value: final });
  };
  render() {
    const { classes, options } = this.props;
    let { name, value, step, precision } = this.state;
    let min = options.min === undefined ? 0 : options.min;
    let max = options.max === undefined ? 1 : options.max;

    min /= step;
    max /= step;
    value /= step;

    return (
      <div className={classes.widgetOuter}>
        <div className={classes.widgetLabel}>{name}</div>
        <div className={classes.widgetValue}>
          {parseFloat(value * step).toFixed(precision)}
        </div>
        <input
          className={clsx(classes.widgetSlider, classes.widget)}
          max={max}
          min={min}
          onChange={this.handleChange}
          onInput={this.handleChange}
          type="range"
          value={value}
        />
      </div>
    );
  }
}

WebGLSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};
WebGLSlider.defaultProps = {
  options: {
    precision: 0,
    min: 0,
    step: 1,
    value: 0,
    max: 1,
    name: '',
  },
  slide: () => '',
};

export default withStyles(WebGLSlider, useStyles);
