import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { CardEditSeries } from 'components';
import { PaperComponent } from 'helpers';

class DialogEditSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open } = this.props;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.props.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <CardEditSeries Series={this.props.Series} />
      </Dialog>
    );
  }
}

DialogEditSeries.propTypes = {
  classes: PropTypes.object,
};

export default DialogEditSeries;
