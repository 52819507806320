import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { AutoComboBox, DialogSelectedUnits } from 'components';

const useStyles = theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: '#263238',
    fontSize: '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: 'none',
    width: '100%',
  },
  check: {
    color: '#263238',
    fontSize: '14px',
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '-0.05px',
  },
  combobox: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
  button: {
    paddingTop: theme.spacing(2),
    height: 10,
  },
});
const reads = [
  { name: 'Access Blocked', id: 0 },
  { name: 'All units', id: 1 },
  { name: 'Selected units', id: 2 },
];

class UnitSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: {
        id: -1,
        read: 0,
        receive: false,
        find: false,
        status: false,
      },
      loading: false,
      open: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined' && holder) {
      holder.getSetting = () => {
        // eslint-disable-next-line no-unused-vars
        const { id, ...res } = this.state.setting;
        return res;
      };
    }
  }

  componentDidMount() {
    const { setting } = this.props;
    //
    if (setting) {
      this.setState({ setting });
    }
  }

  componentDidUpdate() {
    const { setting } = this.props;
    //
    if (setting && this.state.setting.id === -1) {
      this.setState({ setting });
    }
  }

  IsChanged = N => {
    const { setting: O } = this.props;
    if (typeof N === 'undefined') {
      N = this.state.setting;
    }
    if (O.read !== N.read) return true;
    if (O.receive !== N.receive) return true;
    if (O.find !== N.find) return true;
    if (O.status !== N.status) return true;
    return false;
  };
  handleCheckChange = event => {
    let { setting } = this.state;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    setting = { ...setting, [name]: checked };
    this.setState({ setting });
    this.props.onChange(this.IsChanged(setting));
  };
  handleReadChange = value => {
    if (!value) return;
    let { setting } = this.state;
    setting = { ...setting, read: value.id };
    this.setState({ setting });
    this.props.onChange(this.IsChanged(setting));
  };
  handleCloseDialog = open => {
    this.setState({ open });
  };

  render() {
    const { classes, admin, handleAddError, unit } = this.props;
    const { loading, setting, open } = this.state;
    //
    const show = setting.read === 2;
    return (
      <React.Fragment>
        <Grid
          alignItems="flex-start"
          container
          direction="column"
          justifyContent="flex-start">
          <Grid item xs={12}>
            <Typography className={classes.header} variant="h6">
              Unit Settings
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            className={classes.combobox}
            container
            direction="row"
            item
            justifyContent="space-between"
            xs={12}>
            <AutoComboBox
              defaultValue={reads[setting.read]}
              disabled={!admin}
              label="Read access"
              onObjectChanged={this.handleReadChange}
              options={reads}
              small
              width={show ? 200 : 270}
            />
            {show && (
              <Button
                onClick={() => this.handleCloseDialog(true)}
                variant="outlined">
                Show
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={setting.receive}
                  disabled={loading}
                  name="receive"
                  onChange={this.handleCheckChange}
                />
              }
              disabled={!admin}
              label="Receive"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={setting.find}
                  disabled={loading}
                  name="find"
                  onChange={this.handleCheckChange}
                />
              }
              disabled={!admin}
              label="Find"
            />
          </Grid>
        </Grid>
        <DialogSelectedUnits
          handleAddError={handleAddError}
          handleCloseDialog={this.handleCloseDialog}
          myunit={unit}
          open={open}
        />
      </React.Fragment>
    );
  }
}

UnitSettings.propTypes = {
  admin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};
UnitSettings.defaultProps = {
  setting: null,
  admin: false,
};

export default withStyles(UnitSettings, useStyles);
