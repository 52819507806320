import ShareIcon from '@mui/icons-material/Share';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
  ContentCut,
  ContentCopy,
  ContentPaste,
  PlayCircle,
  PlaylistAdd as PlaylistAddIcon,
} from '@mui/icons-material';
import { IsFolder, IsInvalid, IsJPEG, IsSelectedJPEGs } from 'helpers';
// const IsDivider = (index, items) => {
//   if (IsInvalid(items)) return false;
//   const total = items.length;
//   if (!total) return false;
//   if (index >= 0 && index < total) {
//     if (items[index].name === 'divider') return true;
//   }
//   return false;
// };
export const GetMenuItems = (selected_objects, itemOver, size) => {
  let items = [
    {
      name: 'Copy',
      shortcut: '⌘C',
      ContentIcon: ContentCopy,
    },
    {
      name: 'Cut',
      shortcut: '⌘X',
      ContentIcon: ContentCut,
    },
    {
      name: 'Paste',
      shortcut: '⌘V',
      ContentIcon: ContentPaste,
    },
    {
      name: 'Delete',
      shortcut: '⌘D',
      ContentIcon: DeleteOutlineIcon,
    },
    { name: 'divider' },
    {
      name: 'Download',
      shortcut: '⌘D',
      ContentIcon: CloudDownloadIcon,
    },
    {
      name: 'Share',
      shortcut: '⌘S',
      ContentIcon: ShareIcon,
    },
  ];
  let i = items
    .map(x => {
      if (x.type) return x.type;
      else 'None';
    })
    .indexOf('selected');
  if (selected_objects && selected_objects.length) {
    const name = `Selected (${selected_objects.length})`;
    if (i !== -1) {
      items[i].name = name;
    } else {
      items.unshift({
        name: 'divider',
      });
      items.unshift({
        name: name,
        shortcut: '',
        type: 'selected',
        ContentIcon: PlaylistAddCheckIcon,
      });
    }
  }
  const index_nii = items.map(x => x.name).indexOf('DCM To NIfTI');
  let locations = [];
  if (itemOver) {
    const { Series, File } = itemOver;
    if (File) locations = File.locations;
    if (Series && index_nii === -1) {
      locations = Series.locations;
      items.push({
        name: 'divider',
      });
      items.push({
        name: 'DCM To NIfTI',
        shortcut: '⌘P',
        ContentIcon: PlayCircle,
      });
    } else if (index_nii !== -1) {
      items.unshift({
        name: 'divider',
      });
      items.splice(index_nii, 1);
      items.splice(index_nii - 1, 1);
    }
    const index_fpct = items
      .map(x => x.name)
      .indexOf('Flat Panel CT Perfusion');
    if (Series && index_fpct === -1) {
      locations = Series.locations;
      items.push({
        name: 'Flat Panel CT Perfusion',
        shortcut: '⌘F',
        ContentIcon: PlayCircle,
      });
    } else if (index_fpct !== -1) {
      items.splice(index_fpct, 1);
      items.splice(index_fpct - 1, 1);
    }
  } else if (IsInvalid(itemOver)) {
    items = [];
    if (size) {
      items.push({
        name: 'Paste',
        shortcut: '⌘V',
        ContentIcon: ContentPaste,
      });
      items.push({
        name: 'divider',
      });
    }
    items.push({
      name: 'New Folder',
      shortcut: '⌘N',
      ContentIcon: CreateNewFolderIcon,
    });
    items.push({
      name: 'divider',
    });
    items.push({
      name: 'Copy File Path',
      shortcut: '⌘M',
      ContentIcon: ContentCopy,
    });
  }
  const is_folder = IsFolder(itemOver);
  let index_folder = items.map(x => x.name).indexOf('Open Folder');
  const has_folder = index_folder !== -1;
  if (is_folder && !has_folder) {
    items.push({ name: 'divider' });
    items.push({
      name: 'Open Folder',
      shortcut: '⌘O',
      ContentIcon: OpenWithIcon,
    });
    items.push({
      name: 'Rename Folder',
      shortcut: '⌘R',
      ContentIcon: DriveFileRenameOutlineIcon,
    });
    const { folders_paths } = itemOver;
    locations = folders_paths.map(x => x.name);
  }
  i = items.map(x => x.name).indexOf('Copy File Path');
  if (i === -1 && locations && locations.length) {
    items.push({
      name: 'Copy File Path',
      shortcut: '⌘M',
      ContentIcon: ContentCopy,
    });
  }
  if (itemOver && itemOver.File) {
    const { mimetype } = itemOver.File;
    if (mimetype === 'application/zip') {
      i = items.map(x => x.name).indexOf('Add to Landing');
      if (i === -1) {
        items.push({
          name: 'Add to Landing',
          shortcut: '⌘L',
          ContentIcon: PlaylistAddIcon,
        });
      }
    }
  }
  const is_jpg = IsJPEG(itemOver) && IsSelectedJPEGs(selected_objects);
  let index_jpg = items.map(x => x.name).indexOf('JPGs to DCM');
  const has_jpgs = index_jpg !== -1;
  if (is_jpg && !has_jpgs) {
    items.push({ name: 'divider' });
    items.push({
      name: 'JPGs To DCM',
      shortcut: '⌘J',
      ContentIcon: PlayCircle,
    });
    console.log('selected', {selected_objects, itemOver, size});
    locations = itemOver.File.locations;
  }
  return items;
};
