import React from 'react';
import clsx from 'clsx';

import { Avatar, Button, Link, Typography } from '@mui/material';

import { FindKey, getInitials, getNumber, getUserFullName } from 'helpers';
import CellStatus from '../CellStatus';

const CellName = (row, value, classes, column, dense) => {
  let type = null;
  if (column) type = column.type;
  let avatar = '';
  if (type === 'text') {
    return value;
  }
  if (FindKey(row, 'avatarUrl')) {
    avatar = row.avatarUrl;
  } else if (FindKey(row, 'avatar')) {
    avatar = row.avatar;
  } else {
    avatar = '/images/avatars/avatar_0.png';
  }
  //
  let FullName = getUserFullName(row);
  //
  if (FullName === '') {
    if (value && typeof value === 'string' && value.constructor === String) {
      FullName = value;
    } else {
      FullName = getUserFullName(value);
    }
  }
  // console.log({ row, value, FullName });
  if ((type !== undefined && type === 'name_only') || dense) {
    if (column && column.format) {
      return column.format(value);
    }
    return FullName;
  }
  return (
    <div className={classes.nameContainer}>
      <Avatar className={classes.avatar} src={avatar}>
        {getInitials(FullName)}
      </Avatar>
      <Typography variant="body1">{FullName}</Typography>
    </div>
  );
};
const CellThumbnail = (row, value, classes, type) => {
  let avatar = '';
  if (type !== 'thumbnail') {
    return value;
  }
  const { Thumbnail } = row;
  //
  if (!Thumbnail || Thumbnail === undefined || Thumbnail === '') {
    if (value && value !== '') {
      avatar = `data:image/jpeg;base64,${Thumbnail}`;
    } else {
      avatar = '/images/activity/unknown.png';
    }
  } else {
    avatar = `data:image/jpeg;base64,${Thumbnail}`;
  }
  return (
    <div className={classes.nameContainer}>
      <Avatar className={classes.avatar} src={avatar}>
        TN
      </Avatar>
    </div>
  );
};
const CellIcon = (value, classes) => {
  let url = '/images/activity/unknown.png';
  if (value) {
    url = value;
  }
  return (
    <div className={classes.nameContainer}>
      <Avatar className={classes.avatar} src={url}>
        {getInitials(value)}
      </Avatar>
    </div>
  );
};
const CellButton = (row, props, dense, classes, column) => {
  const { handleButtonView } = props;
  let disabled = false;
  if (column && column.disabled) {
    disabled = column.disabled(row[column.id]);
  }
  return (
    <Button
      className={clsx({
        [classes.button_no_dense]: true,
        [classes.button_dense]: dense,
      })}
      color="primary"
      disabled={disabled}
      onClick={() => {
        if (handleButtonView) {
          handleButtonView(row);
        }
      }}
      variant="outlined">
      {row.kind === '--' ? 'Open' : 'View'}
    </Button>
  );
};
const CellElement = (column, row, value, props, dense, classes) => {
  if (column.id === 'name' && column.type !== 'string') {
    return CellName(row, value, classes, column, dense);
  } else if (column.type === 'string') {
    return value;
  } else if (column.type === 'user') {
    return CellName(row, value, classes, null, dense);
  } else if (column.type === 'link') {
    return (
      <Link
        component="button"
        onClick={() => props.handleClickLink(row)}
        tabIndex={0}>
        {value}
      </Link>
    );
  } else if (column.type === 'thumbnail') {
    return CellThumbnail(row, value, classes, column.type);
  } else if (column.type === 'icon') {
    return CellIcon(value, classes);
  } else if (column.type === 'buttom') {
    return CellButton(row, props, dense, classes, column);
  } else if (column.type === 'count') {
    return getNumber(value).toString();
  } else if (column.id === 'status') {
    let values = {
      name: row[column.id],
    };
    if (typeof column.format !== 'undefined') {
      values = column.format(row[column.id]);
    }
    return <CellStatus {...values} />;
  } else if (column.format) {
    if (column.id === 'createdAt' || column.id === 'updatedAt') {
      value = row[column.id];
    }
    return column.format(value);
  } else {
    return value;
  }
};
export default CellElement;
