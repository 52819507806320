import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  FirstSection,
  HomeHeader,
  HomeLogos,
  HomeUpperLogo,
  HomeFooterInformation,
  HomeFooter,
  SectionMap,
} from './components';
import useStyles from './Styles';
import './Home.css';

const dashboardRoutes = [];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user, isDesktop, isAuthenticated } = this.props;
    return (
      <React.Fragment>
        <HomeHeader
          changeColorOnScroll={{
            height: 200,
            color: 'white',
          }}
          color="transparent"
          fixed
          isAuthenticated={isAuthenticated}
          isDesktop={isDesktop}
          routes={dashboardRoutes}
          user={user}
        />
        <HomeUpperLogo />
        <div className={clsx(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <FirstSection />
            <SectionMap />
          </div>
        </div>
        <HomeLogos />
        <HomeFooterInformation />
        <HomeFooter />
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  user: PropTypes.object.isRequired,
};
export default withStyles(withRouter(Home), useStyles);
