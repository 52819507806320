import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const FoldersQueryGQL = gql`
  query($idParent: LongLong, $page: Int, $limit: Int) {
    folders(idParent: $idParent, page: $page, limit: $limit) {
      Total
      ParentFolder {
        idFolder
        name
      }
      Folders {
        idFolder
        name
        Size
        NewFiles {
          idFolderContent
          Series {
            idSeries
            idStudy
            SeriesDescription
            Size
            Thumbnail
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            locations {
              idFolderContent
              Folder {
                idFolder
                name
                folders_paths {
                  idFolder
                  name
                }
              }
            }
            Thumbnail
            updatedAt
            createdAt
          }
        }
        createdAt
      }
      error
    }
  }
`;
export const FoldersQuery = (idFolder, page, limit) => {
  const query = {};
  if (idFolder) query.idParent = idFolder;
  if (page && limit) {
    query.page = page;
    query.limit = limit;
  }
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(FoldersQueryGQL),
    variables: query,
  });
};

const QUERY_FOLDER = gql`
  query($idFolder: LongLong, $code: String) {
    folder(idFolder: $idFolder, code: $code) {
      ok
      errors {
        path
        message
      }
      Folder {
        idFolder
        idParent
        link {
          idFolder
          idFolderContent
          code
          private_access
        }
        download_link
        Size
        name
        total_sub_folders
        total_files
        folders_paths {
          idFolder
          name
        }
        Owner {
          id
          FullName
        }
        ParentFolder {
          idParent
          name
          folders_paths {
            idFolder
            name
          }
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          idParent
          idUser
          name
          Size
          total_sub_folders
          total_files
          link {
            idFolder
            idFolderContent
            code
            private_access
          }
          download_link
          folders_paths {
            idFolder
            name
          }
          Owner {
            id
            FullName
          }
          Rights {
            idRight

            idFolderContent
            idFolder

            idUser
            idProject
            idCenterTeam
            idCenter

            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        Files {
          idFolderContent
          Owner {
            id
            FullName
          }
          link {
            idFolder
            idFolderContent
            code
            private_access
          }
          download_link
          Series {
            idSeries
            idStudy
            nifti
            Patient {
              PatientName
              PatientID
              PatientBirthDate
            }
            Study {
              StudyDescription
              StudyInstanceUID
              AccessionNumber
            }
            ImageSize {
              Size
            }
            ImageResolution {
              Spacing
            }
            sequence {
              idSequence
              name
            }
            locations {
              idFolderContent
              idFolder
            }
            SeriesDescription
            SeriesInstanceUID
            NumberOfSeriesRelatedInstances
            SeriesDate
            SeriesTime
            SeriesNumber
            StationName
            Modality
            Thumbnail
            Size
            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            locations {
              idFolderContent
              Folder {
                idFolder
                name
                folders_paths {
                  idFolder
                  name
                }
              }
            }
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight

            idFolderContent
            idFolder

            idUser
            idProject
            idCenterTeam
            idCenter

            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        Rights {
          idRight

          idFolderContent
          idFolder

          idUser
          idProject
          idCenterTeam
          idCenter

          User {
            id
            FullName
          }
          Project {
            id
            name
          }
          CenterTeam {
            id
            team {
              name
            }
          }
          Center {
            id
            name
          }
          write
          download
          delete
          read
          full
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const FolderQuery = (idFolder, code) => {
  const query = {};
  if (idFolder) query.idFolder = idFolder;
  if (code) query.code = code;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FOLDER),
    variables: query,
  });
};

const MUTATION_CREATE_RIGHTS = gql`
  mutation($rights: [InputPermission!]!) {
    createRights(rights: $rights) {
      ok
      rights {
        idRight
        idFolder
        idFolderContent

        idUser
        idProject
        idCenter
        idCenterTeam

        User {
          id
          FullName
        }
        Project {
          id
          name
        }
        CenterTeam {
          id
          team {
            name
          }
        }
        Center {
          id
          name
        }
        write
        download
        delete
        read
        full
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const MutationCreateRights = in_rights => {
  const rights = [];

  for (let index = 0; index < in_rights.length; index++) {
    const right = {};
    const in_rigth = in_rights[index];
    if (in_rigth.idRight) {
      // right.idRight = in_rigth.idRight;
      const i = rights.map(x => x.idRight).indexOf(in_rigth.idRight);
      if (i === -1) {
        right.idRight = in_rigth.idRight;
      }
    }
    if (in_rigth.idFolder) right.idFolder = in_rigth.idFolder;
    if (in_rigth.idFolderContent)
      right.idFolderContent = in_rigth.idFolderContent;
    if (in_rigth.idUser) right.idUser = in_rigth.idUser;
    if (in_rigth.idProject) right.idProject = in_rigth.idProject;
    if (in_rigth.idCenterTeam) right.idCenterTeam = in_rigth.idCenterTeam;
    if (in_rigth.idCenter) right.idCenter = in_rigth.idCenter;
    right.download = in_rigth.download;
    right.read = in_rigth.read;
    right.write = in_rigth.write;
    right.full = in_rigth.full;
    right.delete = in_rigth.delete;
    rights.push(right);
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_RIGHTS),
    variables: { rights },
  });
};

const DELETE_RIGHTS_QUERY = gql`
  mutation($rightIds: [Int!]!) {
    deleteRights(rightIds: $rightIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteRightsQuery = rightIds => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_RIGHTS_QUERY),
    variables: { rightIds },
  });
};
