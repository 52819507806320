import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import { ButtonLoad, TableGrid } from 'components';
import { IsInvalid, rowsPerPageBig } from 'helpers';
import { columns_consent } from '../Styles';
import EditPatientConsentToolbar from './EditPatientConsentToolbar';

class DialogEditPatientConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows_edited: [],
      new_consents: [],
      selected: [],
      current_consent: null,
      page: 0,
      limit: 100,
    };
  }
  handleAddPatientConsents = (patient_consents, clean = false) => {
    const { selected } = this.props;
    let { new_consents } = this.state;
    for (let i = 0; i < patient_consents.length; i++) {
      const { idPatient } = patient_consents[i];
      let index = selected.map(x => x.id).indexOf(idPatient);
      if (index === -1) {
        index = new_consents.map(x => x.id).indexOf(idPatient);
        if (index === -1) {
          new_consents.push({
            id: idPatient,
            PatientName: '',
            patient_consent: { ...patient_consents[i] },
          });
        } else {
          new_consents[index] = {
            ...new_consents[index],
            patient_consent: { ...patient_consents[i] },
          };
        }
      }
    }
    this.props.handleAddPatientConsents(patient_consents);
    if (clean) {
      this.setState({ rows_edited: [], new_consents });
    } else {
      this.setState({ new_consents });
    }
  };
  render() {
    const { open, selected } = this.props;
    if (IsInvalid(open)) return null;
    const { new_consents } = this.state;
    const rows = new_consents.concat(selected);
    let total = rows.length;
    let height = this.props.height;
    if (IsInvalid(height) || height < 100) {
      height = 350;
      if (rows.length < 3) {
        height = 150;
      } else if (rows.length >= 3 && rows.length < 6) {
        height = this.props.height;
      }
    }
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: 'move' }}>
            Edit Create Patient Consent
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter all the necessary information of the rule.
            </DialogContentText>
            <Divider />
            <Grid container pr={2} pt={2} spacing={1}>
              <Grid item xs={12}>
                <EditPatientConsentToolbar
                  handleAddError={this.props.handleAddError}
                  handleAddPatientConsents={this.handleAddPatientConsents}
                  rows_edited={this.state.rows_edited}
                />
              </Grid>
              <Grid item xs={12}>
                <TableGrid
                  checkboxSelection
                  dense
                  disableSelectionOnClick
                  external_selected={this.state.selected}
                  headers={columns_consent}
                  height={height}
                  loading={this.state.loading}
                  onPageChange={page => this.setState({ page })}
                  onRowClicked={row_highlight =>
                    this.setState({ row_highlight })
                  }
                  onRowEditedChanged={rows_edited =>
                    this.setState({ rows_edited })
                  }
                  onRowsPerPageChange={limit => this.setState({ limit })}
                  onSelectedChanged={selected => this.setState({ selected })}
                  page={this.state.page}
                  rowCount={total}
                  rows={rows}
                  rowsPerPage={this.state.limit}
                  rowsPerPageOptions={rowsPerPageBig}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <GroupConsent
                  current_consent={this.state.current_consent}
                  setState={state => this.setState(state)}
                />
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <ButtonLoad
              color="primary"
              disable_button={this.state.patient ? false : true}
              handleClick={this.handleCreateEncryptionPatient}
              loading={this.state.creating}
              name="Save"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogEditPatientConsent.propTypes = {
  classes: PropTypes.object,
};

export default DialogEditPatientConsent;
