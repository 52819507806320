import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_CENTER_TEAMS = gql`
  query($idCenter: LongLong!, $name: String, $page: Int, $limit: Int) {
    teamsAtCenter(
      idCenter: $idCenter
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total
      teams {
        id
        name
        avatar
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryTeamsAtCenter = (idCenter, name, page, limit) => {
  const variables = { idCenter };
  if (name !== undefined && name !== '') {
    variables.name = name;
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CENTER_TEAMS),
    variables,
  });
};
