const useStyles = theme => ({
  root: {
    marginTop: 10,
    width: '100%',
  },
  header: {
    backgroundColor: '#eaeaea',
  },
  paper: {
    width: '100%',
    marginTop: '2px',
    marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: 410,
  },
  cell_empty: {
    margin: 0,
    padding: 0,
    width: '20px',
  },
  cell_text: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: 0,
    width: '20px',
  },
});

export default useStyles;
