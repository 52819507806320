import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from '@mui/material';
import { CardEditHeader, TableGrid } from 'components';
import { columns_unsorted } from './utils_classification';
import { rowsPerPageBig } from 'helpers';

class ClassificationUnsortedSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      page: 0,
      limit: 100,
    };
  }

  render() {
    console.log({ s: this.props.selected });
    return (
      <Card>
        <CardEditHeader
          handleClose={this.props.handleClose}
          title="Classification Unsorted Sequences"
        />
        <Divider />
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          headers={columns_unsorted}
          // height={height - 600}
          idRow="idSeries"
          // loading={loading}
          onHighlightChange={row_highlight => this.setState({ row_highlight })}
          onPageChange={page => this.setState({ page })}
          onRowClicked={row_highlight => this.setState({ row_highlight })}
          onRowsPerPageChange={limit => this.setState({ limit })}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          // row_styles={row_styles}
          rows={this.props.selected}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Card>
    );
  }
}

ClassificationUnsortedSequences.propTypes = {
  classes: PropTypes.object,
};

export default ClassificationUnsortedSequences;
