import {
  LOGOUT_USER_PAGE_DATABASE,
  PAGE_DATABASE_DISPLAYTYPE,
  PAGE_DATABASE_QUERY_LEVEL,
  PAGE_DATABASE_QUERY_PROJECT,
  PAGE_DATABASE_SERIES_SEQUENCE,
  PAGE_DATABASE_STUDY_SELECTED,
  PAGE_DATABASE_VIEWTYPE,
  PAGE_DATABASE_PATIENTS_IDS,
} from '../actions/types';

const initialState = {
  view_database: 'study',
  display_database: 'grid',
  study_selected: [],
  ids_patients: [],
  sequence: null,
  setting: {
    level: { name: 'All', index_level: 2 },
    project: null,
  },
};

export default function reducer_page_database(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case LOGOUT_USER_PAGE_DATABASE:
      return initialState;
    case PAGE_DATABASE_VIEWTYPE:
      return {
        ...state,
        view_database: action.view_database,
      };
    case PAGE_DATABASE_DISPLAYTYPE:
      return {
        ...state,
        display_database: action.display_database,
      };
    case PAGE_DATABASE_STUDY_SELECTED:
      return {
        ...state,
        study_selected: action.study_selected,
      };
    case PAGE_DATABASE_SERIES_SEQUENCE:
      return {
        ...state,
        sequence: action.sequence,
      };
    case PAGE_DATABASE_PATIENTS_IDS:
      return {
        ...state,
        ids_patients: action.ids_patients,
      };
    case PAGE_DATABASE_QUERY_LEVEL:
      return {
        ...state,
        setting: {
          ...state.setting,
          level: action.level,
        },
      };
    case PAGE_DATABASE_QUERY_PROJECT:
      return {
        ...state,
        setting: {
          ...state.setting,
          project: action.project,
        },
      };

    default:
      return state;
  }
}
