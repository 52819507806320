import React from 'react';
import PropTypes from 'prop-types';
import { DialogAddProjectSettingMembers } from 'components';
import TableProjectMemberSetting from '../TableProjectMemberSetting';
import { QueryProjectMembersSettings } from 'graphql/Projects/utils_project';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
class TabProjectSettingMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_add_member: false,
      refreshing: false,
      page: 0,
      limit: 50,
      total: 0,
    };
  }
  handleSearchByName = name => {
    const { refreshing, page, limit } = this.state;
    const { project_setting } = this.props;
    if (refreshing || !project_setting || project_setting === undefined) return;
    const { idProject } = project_setting;
    if (IsInvalid(idProject)) return;
    this.setState({ refreshing: true, empty: false });
    (async () => {
      QueryProjectMembersSettings(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, member_settings, errors } = data.projectMemberSetting;
          if (ok) {
            let original = null;
            if (!this.props.original) {
              original = { ...project_setting, member_settings };
            } else {
              original = { ...this.props.original, member_settings };
            }
            this.props.handleUpdateProjectSetting(
              {
                ...project_setting,
                member_settings,
              },
              original
            );
            this.setState({ refreshing: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ refreshing: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddMembers = member_settings => {
    const { project_setting, original } = this.props;
    if (
      !project_setting ||
      project_setting === undefined ||
      !member_settings.length
    ) {
      return;
    }
    for (let i = 0; i < member_settings.length; i++) {
      const element = member_settings[i];
      let index = project_setting.member_settings
        .map(x => x.id)
        .indexOf(element.id);
      // console.log({ index, element });
      if (index === -1) {
        project_setting.member_settings.push({ ...element });
      }
      index = original.member_settings.map(x => x.id).indexOf(element.id);
      // console.log({ index, element });
      if (index === -1) {
        original.member_settings.push({ ...element });
      }
    }
    this.setState({ open_add_member: false });
    this.props.handleUpdateProjectSetting(project_setting, original);
  };
  render() {
    if (!this.props.isActive) return null;
    let { idProject, project_setting, loading, height } = this.props;
    const { refreshing } = this.state;
    let member_settings = [];
    if (project_setting && !loading) {
      member_settings = project_setting.member_settings;
    }
    if (height) {
      height = height - 125;
      if (height < 200) height = null;
    }
    return (
      <div>
        <TableProjectMemberSetting
          handelAddMember={() =>
            this.setState({
              open_add_member: !this.state.open_add_member,
            })
          }
          handleAddError={this.props.handleAddError}
          handleChangePageLimit={(page, limit) =>
            this.setState({ page, limit })
          }
          handleEditMembersSettings={this.props.handleEditMembersSettings}
          handleReloadTable={() => this.handleSearchByName()}
          handleSearchByName={this.handleSearchByName}
          handleUpdateProjectSetting={this.props.handleUpdateProjectSetting}
          height={height}
          limit={this.state.limit}
          loading={refreshing || this.props.loading}
          member_settings={member_settings}
          original={this.props.original}
          page={this.state.page}
          project_setting={this.props.project_setting}
          setState={state => this.setState(state)}
          total={this.state.total}
        />
        <DialogAddProjectSettingMembers
          handleAddMembers={this.handleAddMembers}
          handleClose={() =>
            this.setState({
              open_add_member: false,
            })
          }
          open={this.state.open_add_member}
          project={{ id: idProject }}
        />
      </div>
    );
  }
}

TabProjectSettingMembers.propTypes = {
  classes: PropTypes.object,
};
TabProjectSettingMembers.defaultProps = {
  handleUpdateProjectSetting: () => '',
};
export default TabProjectSettingMembers;
