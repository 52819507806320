import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Grid,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Pagination,
} from '@mui/material';
import {
  IsValid,
  IsInvalid,
  ServerErrorsString,
  GetData,
  FormatTime,
  GetThumbnailFile,
} from 'helpers';
import FolderIcon from '@mui/icons-material/Folder';

import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { QueryClinicalFileCatalogues } from 'graphql/DataClinics';
export const useStyles = () => ({
  summary: {
    maxHeight: 8,
    padding: '0px',
    paddingRight: '10px',
    margin: '0px',
  },
  details: {
    padding: '0px',
    paddingLeft: '10px',
    paddingBottom: '5px',
    margin: '0px',
  },
});
class FileViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files_obj: [],
      formatted_files: [],
      loading: false,
      page: 1,
      itemsPerPage: 8,
    };
  }
  componentDidMount() {
    this.handleLoadFiles();
  }

  handleLoadFiles = () => {
    const { loading, files_obj } = this.state;
    const { patient, table } = this.props;
    if (files_obj.length > 0) return;
    if (loading) return;
    if (IsInvalid(patient)) return;
    if (IsInvalid(patient.id)) return;
    this.setState({ loading: true });
    const query_inputs = { idPatient: patient.id };
    console.log({ query_inputs });
    (async () => {
      QueryClinicalFileCatalogues(query_inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, files } = data.clinicalFileCatalogues;
          if (ok) {
            let formatted_files = this.formatFileList(files);
            if (table) {
              formatted_files.forEach(group => {
                const texts = group.parent.toLowerCase().split(' ');
                const regex = /ies$/;
                const table_name = table.replace(regex, 'y');
                const enabled = table_name.includes(texts[texts.length - 1]);
                if (enabled) {
                  group.expanded = true;
                }
              });
              formatted_files = this.moveExpandedToFront(formatted_files);
            } else {
              if (formatted_files.length) {
                formatted_files[0].expanded = true;
              }
            }
            this.setState({
              loading: false,
              files_obj: files,
              formatted_files,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  formatFileList(files) {
    let formatted_files = [];
    if (IsInvalid(files)) return;
    files.forEach(file => {
      const {
        id,
        idEpilepsyCase,
        idSleepCase,
        idStrokeCase,
        idMsCase,
        idPatient,
        idFileObject,
        idSeries,
        idSignalEDF,
        idFolder,
        folder_content,
        file_object,
        series,
        edf,
        folder,
      } = file;
      let filename = '';
      let type = '';
      let filepath = '';
      let icon = '';
      let created = '';
      if (IsValid(idFileObject)) {
        filename = file_object.original_name;
        type = 'file_object';
        filepath = file_object.filepath;
        created = this.formatDate(file_object.createdAt);
        icon = GetThumbnailFile(filename);
      } else if (IsValid(idSeries)) {
        filename = series.DicomFiles[0].OriginalName;
        type = 'series';
        filepath = series.DicomFiles[0].FilePath;
        if (IsInvalid(series.Thumbnail) || series.Thumbnail === '') {
          icon = GetThumbnailFile(filename);
        } else {
          icon = `data:image/jpeg;base64,${series.Thumbnail}`;
        }
        created = this.formatDate(series.createdAt);
      } else if (IsValid(idSignalEDF)) {
        filename = edf.file_object.original_name;
        type = 'edf';
        filepath = edf.file_object.filepath;
        created = this.formatDate(edf.createdAt);
        icon = GetThumbnailFile(filename);
      } else if (IsValid(idFolder)) {
        filename = folder.name;
        type = 'folder';
        created = this.formatDate(folder.createdAt);
      }
      let parent = 'Other';
      if (IsValid(idSleepCase)) {
        parent = 'Sleep';
      } else if (IsValid(idStrokeCase)) {
        parent = 'Stroke';
      } else if (IsValid(idEpilepsyCase)) {
        parent = 'Epilepsy';
      } else if (IsValid(idMsCase)) {
        parent = 'MS';
      }
      formatted_files.push({
        id,
        idPatient,
        idSleepCase,
        idEpilepsyCase,
        idStrokeCase,
        idFolder,
        filename,
        type,
        filepath,
        created,
        icon,
        parent,
        folder_content,
      });
    });
    const grouped = this.groupArraysByCases(formatted_files);
    return grouped;
  }
  groupArraysByCases = arrays => {
    return arrays.reduce((grouped, item) => {
      const { parent, ...rest } = item;
      const existingGroup = grouped.find(group => group.parent === parent);
      if (existingGroup) {
        existingGroup.children.push({ ...rest });
        existingGroup.total_files += 1;
      } else {
        const newGroup = {
          expanded: false,
          enabled: true,
          parent,
          total_files: 1,
          children: [{ ...rest }],
        };
        grouped.push(newGroup);
      }
      return grouped;
    }, []);
  };
  moveExpandedToFront = array => {
    array.sort((a, b) => {
      if (a.expanded && !b.expanded) {
        return -1;
      } else if (!a.expanded && b.expanded) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  };
  handleAccordionChange = index => {
    let { formatted_files } = this.state;
    formatted_files[index].expanded = !formatted_files[index].expanded;
    this.setState({ formatted_files });
  };
  handleEnable = index => {
    let { formatted_files } = this.state;
    formatted_files[index].enabled = !formatted_files[index].enabled;
    formatted_files[index].expanded = formatted_files[index].enabled;
    this.setState({ formatted_files });
  };
  handleOpenFolder = item => {
    const { folder_content, idFolder } = item;
    let location = '/explorer';

    if (IsValid(idFolder)) {
      location = `/explorer/folder/${idFolder}`;
    } else {
      if (IsInvalid(folder_content)) {
        this.props.handleAddError('Folder is not set');
        return;
      }
      location = `/explorer/folder/${folder_content.idFolder}`;
    }
    this.props.history.push(location);
  };
  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  render() {
    const { classes } = this.props;
    const { files_obj, formatted_files, itemsPerPage, page } = this.state;
    if (IsInvalid(files_obj)) return null;
    if (IsInvalid(formatted_files)) return null;

    return (
      <Box style={{ marginTop: '10px' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}>
          <Typography
            display="inline"
            gutterBottom
            style={{
              marginRight: '5px',
              marginBottom: '20px',
              textTransform: 'none',
            }}
            sx={{ p: 1 }}
            variant="h6">
            Files
          </Typography>
          {formatted_files.length > 0 ? (
            formatted_files.map((study, index) => (
              <Accordion
                className={clsx({
                  ['Mui-disabled']: !study.enabled,
                })}
                disableGutters
                expanded={study.expanded}
                key={index}
                onChange={() => {
                  if (study.enabled) this.handleAccordionChange(index);
                }}>
                <AccordionSummary
                  aria-controls={`panel${index + 1}a-content`}
                  className={clsx({
                    [classes.summary]: true,
                  })}
                  expandIcon={study.enabled ? <ExpandLessIcon /> : null}
                  id={`panel${index + 1}a-header`}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div>
                      <Grid alignItems="center" container>
                        <Grid item>
                          <Typography
                            style={{
                              marginLeft: '10px',
                              color: study.enabled ? 'black' : 'gray',
                            }}>
                            Files related to {study.parent} study
                          </Typography>{' '}
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              marginLeft: '5px',
                              color: 'grey',
                              fontSize: '14px',
                            }}>
                            {' '}
                            (click to expand and see files)
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ flexGrow: 1 }} />

                    <div
                      style={{
                        paddingRight: '10px',
                      }}>
                      <Typography
                        style={{
                          marginLeft: '5px',
                          color: 'grey',
                        }}>
                        {' '}
                        Total files: {study.total_files}
                      </Typography>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={clsx({
                    [classes.details]: true,
                  })}>
                  <Grid container>
                    {study &&
                      study.children
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map(item => (
                          <Grid item key={item.key} md={3} sm={4} xs={6}>
                            <ListItemButton
                              key={`item-${item.id}`}
                              onClick={() => this.handleOpenFolder(item)}>
                              <ListItemAvatar>
                                {item.type === 'folder' ? (
                                  <FolderIcon
                                    fontSize="large"
                                    style={{ color: 'blue' }}
                                  />
                                ) : (
                                  <Avatar src={item.icon} variant="rounded" />
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={item.filename}
                                secondary={item.created}
                              />
                            </ListItemButton>
                          </Grid>
                        ))}
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingRight: '16px',
                    }}>
                    <Pagination
                      count={Math.ceil(study.children.length / itemsPerPage)}
                      onChange={this.handleChangePage}
                      page={page}
                      showFirstButton
                      showLastButton
                      size="small"
                    />{' '}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography gutterBottom sx={{ p: 2 }} variant="p">
              No files to show
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
}

FileViewer.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(FileViewer, useStyles);
