const useStyles = theme => ({
  root: {
    // display: 'flex',
    height: '100%',
  },
  root_container: {
    width: '100%',
    height: '65vh',
  },
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'scroll',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    bottom: '0px',
    width: 'calc(100% - 241px)',
  },
  spacer: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(5),
  },
  text: {
    marginLeft: theme.spacing(5),
    fontSize: 18,
    fontWeight: 500,
  },
});

export default useStyles;
