import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_CLINICAL_TYPES = gql`
  query($code: LongLong) {
    clinicalDataTypes(code: $code) {
      ok
      errors {
        path
        message
      }
      clinical_types {
        id
        code
        description
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryClinicalType = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CLINICAL_TYPES),
    variables: {},
  });
};
const MUTATION_ADD_CLINICAL_DATA_TYPE = gql`
  mutation($inputs: [InputClinicalDataType!]!) {
    addClinicalDataType(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      clinical_types {
        id
        code
        description
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationAddClinicalDataType = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_CLINICAL_DATA_TYPE),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_CLINICAL_DATA_TYPE = gql`
  mutation($inputs: [LongLong!]!) {
    removeClinicalDataType(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveClinicalDataType = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_CLINICAL_DATA_TYPE),
    variables: {
      inputs,
    },
  });
};
