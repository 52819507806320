import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import { ButtonLoad, TextFieldCustom } from 'components';
import { IsInvalid, GetData, ServerErrorsString } from 'helpers';
import { MutationAddEncryptionPatients } from 'graphql/Anonymization';
import { fields } from './Styles';

class DialogCreateEncryptedPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: null,
      creating: false,
    };
  }
  handleTextChanged = event => {
    let { patient } = this.state;
    const name = event.target.name;
    if (IsInvalid(patient)) {
      patient = {
        [name]: event.target.value,
      };
    } else {
      patient = {
        ...patient,
        [name]: event.target.value,
      };
    }
    this.setState({ patient });
  };
  handleCreateEncryptionPatient = () => {
    const { creating, patient } = this.state;
    if (creating || IsInvalid(patient)) return;
    this.setState({ creating: false });
    (async () => {
      MutationAddEncryptionPatients([{ ...patient }])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.addEncryptionPatients;
          if (ok) {
            this.setState({ creating: false, patient: null });
            this.props.handleEncryptedPatientCreated(patients);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.open) return null;
    const { patient } = this.state;
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={this.props.open}
          PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: 'move' }}>
            Create Encrypted Patient
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter all the necessary information of the rule.
            </DialogContentText>
            <Divider />
            <Grid container pt={2} spacing={1}>
              {fields.map((field, index) => {
                const { name, start, ...res } = field;
                let add = {};
                if (start && start !== '') {
                  add = {
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {start}
                        </InputAdornment>
                      ),
                    },
                  };
                }
                let text = '';
                if (patient) {
                  if (patient[name]) text = patient[name];
                }
                const label = name.replace('Patient', '');
                return (
                  <Grid item key={`text-patient-${index}`} xs={6}>
                    <TextFieldCustom
                      label={label}
                      name={name}
                      onChange={this.handleTextChanged}
                      value={text}
                      {...add}
                      {...res}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <ButtonLoad
              color="primary"
              disable_button={this.state.patient ? false : true}
              handleClick={this.handleCreateEncryptionPatient}
              loading={this.state.creating}
              name="Create Encrypted Patient"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogCreateEncryptedPatient.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DialogCreateEncryptedPatient;
