import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Badge,
} from '@mui/material';
// import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import MoreIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import { DivGrow, StyledMenu, StyledMenuItem, BadgePulsar } from 'components';
import { useMenuStyle } from 'helpers/MainStyles';
import { SubscribeOnline } from 'graphql/Subscriptions';

const useStyles = theme => ({
  upper_bar: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    padding: '2px 10px 2px 10px',
    margin: 0,
    position: 'sticky',
    top: 0,
  },
  ...useMenuStyle,
  header_black: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  avatar: {
    marginTop: theme.spacing(1),
  },
  mobile: {
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: '50px',
    marginLeft: -10,
  },
});

class DirectChatToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      online: null,
    };
  }

  handleClickMenu = status => {
    console.log('DirectChatToolBar', { status });
    this.setState({ anchorEl: null });
  };
  handleClickMore = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleOnline = online => {
    if (online === undefined || !online) return;
    if (this.state.online && this.state.online.id === online.id) {
      if (online.isOnline === this.state.online.isOnline) return;
    }
    this.setState({ online });
    this.props.handleOnlineUser(online);
  };
  render() {
    const { classes, Receiver, isMobile, handleDrawerToggle } = this.props;
    const { anchorEl, online } = this.state;
    let { avatar, isOnline } = Receiver;
    if (!avatar) {
      avatar = '/images/avatars/avatar_0.png';
    }
    if (online) {
      isOnline = online.isOnline;
    }
    let Mobile = null;
    if (isMobile) {
      Mobile = (
        <IconButton
          className={classes.mobile}
          color="inherit"
          onClick={handleDrawerToggle}
          size="large">
          <MenuIcon />
        </IconButton>
      );
    }
    let ComponentAvatar = null;
    let status = 'Active now';
    if (isOnline) {
      ComponentAvatar = (
        <BadgePulsar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          overlap="circular"
          variant="dot">
          <Avatar alt="Profile Picture" src={avatar} />
        </BadgePulsar>
      );
    } else {
      ComponentAvatar = (
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          color="error"
          overlap="circular"
          variant="dot">
          <Avatar alt="Profile Picture" src={avatar} />
        </Badge>
      );
      status = 'Offline';
    }
    // const style_menu_item = classNames(classes.dropdownItem);
    return (
      <div className={classes.upper_bar}>
        <SubscribeOnline
          handleOnline={this.handleOnline}
          idReceiver={Receiver.id}
        />
        {Mobile}
        <Grid container direction="row" justifyContent="space-between">
          <ListItemAvatar className={classes.avatar}>
            {ComponentAvatar}
          </ListItemAvatar>
          <ListItemText
            classes={{
              primary: classes.header_black,
            }}
            primary={Receiver.FullName}
            secondary={status}
          />

          <DivGrow />
          <IconButton
            color="inherit"
            onClick={this.handleClickMore}
            size="large">
            <MoreIcon />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            id="customized-menu"
            keepMounted
            onClose={() => this.setState({ anchorEl: null })}
            open={Boolean(anchorEl)}>
            <div className={classes.menu}>
              <StyledMenuItem disabled>Menu</StyledMenuItem>
              <Divider />
              <StyledMenuItem
                onClick={() => this.handleClickMenu('clear message')}>
                Clear messages
              </StyledMenuItem>
            </div>
          </StyledMenu>
        </Grid>
      </div>
    );
  }
}

DirectChatToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
DirectChatToolBar.defaultProps = {
  handleOnlineUser: () => '',
};
export default withStyles(DirectChatToolBar, useStyles);
