import {
  LOGOUT_USER_PAGE_PROJECTS,
  PAGE_PROJECT_UPDATE_PROJECT_ERRORS,
  PAGE_PROJECTS_INIT,
  PAGE_PROJECTS_LOAD_PROJECTS,
  PAGE_PROJECTS_PROJECT_TEAMS,
  PAGE_PROJECTS_SAVING,
  PAGE_PROJECTS_SET_NOTIFICATIONS,
  PAGE_PROJECTS_SET_PROJECT,
  PAGE_PROJECTS_UPDATE_PROJECTS,
  PAGE_PROJECTS_WARNING,
} from '../actions/types';

const initialState = {
  projects: [],
  teams: [],
  project: {},
  errors: {},
  update: true,
  saving: false,
  warning: [],
  notifications: {},
};

export default function reducer_page_projects(state = initialState, action) {
  const { type } = action;
  //
  switch (type) {
    case LOGOUT_USER_PAGE_PROJECTS:
      return initialState;
    case PAGE_PROJECTS_PROJECT_TEAMS:
      return {
        ...state,
        teams: action.teams,
      };
    case PAGE_PROJECTS_INIT:
      return {
        ...state,
        notifications: {
          id: -1,
          projectId: action.projectId,
          email: false,
          upload: false,
          user_chat: false,
          team_chat: false,
        },
      };
    case PAGE_PROJECTS_SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case PAGE_PROJECTS_WARNING:
      return {
        ...state,
        warning: action.warning,
      };
    case PAGE_PROJECTS_SET_PROJECT:
      return {
        ...state,
        project: action.project,
      };
    case PAGE_PROJECTS_LOAD_PROJECTS:
      return {
        ...state,
        update: action.update,
      };
    case PAGE_PROJECTS_SAVING:
      return {
        ...state,
        saving: action.saving,
      };
    case PAGE_PROJECTS_UPDATE_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case PAGE_PROJECT_UPDATE_PROJECT_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
