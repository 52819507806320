import palette from 'theme/palette';

export const data = {
  labels: [
    'IR',
    'T2',
    'T1',
    'T2 HR',
    'VIBE PHASE',
    'DWI',
    'MSME',
    'DESS',
    'Other',
  ],
  datasets: [
    {
      label: 'Monthly Series',
      backgroundColor: 'rgba(0,0,255,0.6)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(0,0,255,0.3)',
      hoverBorderColor: 'rgba(0,0,255,1)',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  plugins: {
    legend: {
      display: false,
      // position: 'top' as const,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart',
    // },
  },
  layout: { padding: 0 },
  scales: {
    x: {
      // barThickness: 12,
      // maxBarThickness: 10,
      // barPercentage: 0.5,
      // categoryPercentage: 0.5,
      // ticks: {
      //   fontColor: palette.text.secondary
      // },
      gridLines: {
        display: false,
        drawBorder: false,
      },
    },
    //  xAxes: [
    //    {
    //      // barThickness: 12,
    //      // maxBarThickness: 10,
    //      // barPercentage: 0.5,
    //      // categoryPercentage: 0.5,
    //      // ticks: {
    //      //   fontColor: palette.text.secondary
    //      // },
    //      gridLines: {
    //        display: false,
    //        drawBorder: false,
    //      },
    //    },
    //  ],
    y: {
      ticks: {
        fontColor: palette.text.secondary,
        beginAtZero: true,
        min: 0,
      },
      gridLines: {
        lineWidth: 1.5,
        //   borderDash: [2],
        //   borderDashOffset: [2],
        color: 'rgba(231,231,231,1)', //palette.divider,
        drawBorder: false,
        //   zeroLineBorderDash: [2],
        //   zeroLineBorderDashOffset: [2],
        //   zeroLineColor: palette.divider
      },
    },
    // yAxes: [
    //   {
    //     ticks: {
    //       fontColor: palette.text.secondary,
    //       beginAtZero: true,
    //       min: 0,
    //     },
    //     gridLines: {
    //       lineWidth: 1.5,
    //       //   borderDash: [2],
    //       //   borderDashOffset: [2],
    //       color: 'rgba(231,231,231,1)', //palette.divider,
    //       drawBorder: false,
    //       //   zeroLineBorderDash: [2],
    //       //   zeroLineBorderDashOffset: [2],
    //       //   zeroLineColor: palette.divider
    //     },
    //   },
    // ],
  },
};
