export { default as SearchCenters } from './SearchCenters';
export { default as ToolBarSearchCenters } from './ToolBarSearchCenters';
export const useStyles = () => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    height: '100%',
    '&.MuiCardContent-root': {
      paddingBottom: 2,
    },
  },
  inline: {
    display: 'inline',
  },
  table: {
    width: '100%',
  },
  toolbar: {
    width: '100%',
  },
  button: {
    // margin: theme.spacing(1),
  },
  left: {
    marginLeft: 'auto',
  },
});
