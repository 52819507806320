import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, Checkbox, TableRow } from '@mui/material';
import TableGridHeaderCell from '../TableGridHeaderCell';
import { CircularLoading } from 'components';
import { grey } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  table_head: {
    backgroundColor: grey[300],
  },
  table_check: {
    overflow: 'hidden',
    width: 25,
    minWidth: 30,
    maxWidth: 50,
    backgroundColor: grey[300],
  },
});
class TableGridHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'name',
    };
  }
  getHeaders = () => {
    const { headers, headers_to_hide } = this.props;
    return headers.filter(x => {
      const index = headers_to_hide.map(x => x.field).indexOf(x.field);
      return index === -1;
    });
  };
  handleHeaderSort = (order, orderBy) => {
    console.log({ order, orderBy });
    if (order !== 'asc' && order !== 'desc') return;
    this.setState({ orderBy, order });
    this.props.onSortHeaderClicked(orderBy, order);
  };
  handleSortHeaderClicked = field => {
    let { orderBy, order } = this.state;
    // console.log({ field, orderBy, order });
    if (orderBy === field) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      orderBy = field;
    }
    this.setState({ orderBy, order });
    this.props.onSortHeaderClicked(orderBy, order);
  };
  handleHideColumn = header => {
    const { headers, headers_to_hide } = this.props;
    let index = headers.map(x => x.field).indexOf(header.field);
    if (index === -1) return;
    index = headers_to_hide.map(x => x.field).indexOf(header.field);
    if (index === -1) {
      headers_to_hide.push(header);
      this.props.onClickHideColumn(headers_to_hide);
    }
  };
  render() {
    const {
      classes,
      loading,
      rowCount,
      numSelected,
      onSelectAllClick,
      checkboxSelection,
      expandTable,
    } = this.props;
    const headers = this.getHeaders();
    let TableCellCheckbox = null;
    let style = {
      backgroundColor: grey[300],
    };
    if (checkboxSelection) {
      TableCellCheckbox = (
        <TableCell
          className={classes.table_check}
          padding="checkbox"
          style={{ ...style, width: 25, minWidth: 30, maxWidth: 50 }}>
          {loading ? (
            <CircularLoading simple small />
          ) : (
            <Checkbox
              checked={rowCount ? numSelected === rowCount : false}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{ 'aria-label': 'select all items' }}
              onChange={onSelectAllClick}
            />
          )}
        </TableCell>
      );
    }
    let TableCellExpand = null;
    if (expandTable) {
      TableCellExpand = (
        <TableCell
          className={classes.table_head}
          style={{
            ...style,
            width: 50,
            maxWidth: 50,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
      );
    }
    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {TableCellExpand}
          {TableCellCheckbox}
          {headers.map((header, index) => {
            // console.log({ l: headers.length, index });
            let filter = null;
            let disabled_filter = true;
            if (this.props.filter) {
              if (this.props.filter.header.headerName === header.headerName) {
                filter = this.props.filter;
                disabled_filter =
                  this.props.filter.type === 'Choose One' ? true : false;
              }
            }
            // let disabled_filter = true;
            // if (this.state.filter_name) {
            //   disabled_filter = this.state.filter_name === 'Choose One' ? true : false;
            // } else if (this.state.filter_name === '') disabled_filter = true;
            return (
              <TableGridHeaderCell
                disabled_filter={disabled_filter}
                filter={filter}
                header={header}
                key={`table-grid-header-cell-${index}`}
                last={index >= headers.length - 1 ? true : false}
                onClickHideColumn={this.handleHideColumn}
                onClickShowColumn={this.props.onClickShowColumn}
                onSearchFilterChanged={this.props.onSearchFilterChanged}
                onSortHeader={this.handleHeaderSort}
                onSortHeaderClicked={this.handleSortHeaderClicked}
                order={this.state.order}
                orderBy={this.state.orderBy}
                style={style}
              />
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

TableGridHeader.propTypes = {
  checkboxSelection: PropTypes.bool,
  headers: PropTypes.array,
  headers_to_hide: PropTypes.array,
  loading: PropTypes.bool,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  onSortHeaderClicked: PropTypes.func,
  rowCount: PropTypes.number,
};
TableGridHeader.defaultProps = {
  filter: null,
  headers: [],
  checkboxSelection: false,
  expandTable: false,
  numSelected: 0,
  rowCount: 0,
  loading: false,
  headers_to_hide: [],
  onSelectAllClick: () => '',
  onSortHeaderClicked: () => '',
  onClickHideColumn: () => '',
  onClickShowColumn: () => '',
  onSearchFilterChanged: () => '',
};

export default withStyles(TableGridHeader, useStyles);
