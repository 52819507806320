export const styleToolbar = theme => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  button: {
    textTransform: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    paddingTop: '4px',
    paddingBottom: '2px',
  },
  margin: {
    padding: 5,
    margin: 5,
  },
});

export const useStylesToolBars = () => ({
  root: {},
  button_group: {
    height: 33,
  },
  tabs: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
});
