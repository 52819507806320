import React from 'react';
import PropTypes from 'prop-types';
import { GetData, ServerErrorsString, IsInvalid } from 'helpers';
import {
  Dialog,
  Typography,
  DialogContent,
  Divider,
  Grid,
  Stack,
  ButtonGroup,
  CardHeader,
} from '@mui/material';
import { CardEditHeader } from 'components/Cards';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { QueryClinicalPatients } from 'graphql/DataClinics';
import { ClinicalChildObjectViewer } from 'components/DataClinic';
import { ButtonDownloadType, ButtonDownloadPDF } from 'components';
import { header_patients } from 'views/DataClinics/components/PatientClinics/components/Styles';
import { FileViewer } from './components';

class DialogViewClinicalPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      patient_obj: null,
      pdfRequested: false,
    };
  }
  componentDidMount() {
    this.handleLoadPatients();
  }
  componentDidUpdate() {
    this.handleLoadPatients();
  }

  handleLoadPatients = () => {
    const { loading, patient_obj } = this.state;
    const { open, patient } = this.props;

    if (!open || patient_obj) return;
    if (loading) return;
    if (IsInvalid(patient)) return;
    if (IsInvalid(patient.id)) return;
    this.setState({ loading: true });
    const query_inputs = { idPatient: patient.id };
    (async () => {
      QueryClinicalPatients(query_inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.patientsClinics;
          if (ok) {
            this.setState({
              loading: false,
              patient_obj: patients[0],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  setPdfRequested = () => {
    this.setState({ pdfRequested: !this.state.pdfRequested });
  };

  handleClose = () => {
    console.log('handleClose');
    this.setState({ patient_obj: null });
    this.props.handleClose();
  };
  render() {
    const { open } = this.props;
    const { patient_obj } = this.state;
    if (IsInvalid(patient_obj)) return null;

    const pageId = 'clinical_patient';
    let ids;
    if (patient_obj) {
      ids = [patient_obj.id];
    }
    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={'PatientID: ' + patient_obj.PatientID}
          handleClose={this.handleClose}
          icon={<VisibilityIcon style={{ color: 'green' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              Patient Viewer
            </Typography>
          }
        />
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={0.5}
          pt={0.5}>
          <Grid item ml={7} xs={12}>
            <ButtonGroup aria-label="Basic button group" variant="outlined">
              <ButtonDownloadType
                inputs={{ ids: ids }}
                key={pageId}
                type={pageId}
              />
              <ButtonDownloadPDF
                downloadFileName={pageId}
                rootElementId="download_button"
                setPdfRequested={this.setPdfRequested}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <Grid
              columns={{ xs: 4, sm: 8, md: 12 }}
              container
              direction="row-reverse"
              id="download_button"
              spacing={{ xs: 2, md: 3 }}>
              <Grid item md={12} sm={12} xs={12}>
                <CardHeader
                  title={
                    <Typography
                      display="inline"
                      gutterBottom
                      style={{
                        marginRight: '15px',
                        textTransform: 'none',
                        fontWeight: 600,
                      }}
                      variant="h4">
                      Patient Viewer
                    </Typography>
                  }
                />
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="column">
                  <ClinicalChildObjectViewer
                    columns={header_patients}
                    object={patient_obj}
                    title={'Patient details'}
                  />
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />

                  <FileViewer
                    handleAddError={this.props.handleAddError}
                    history={this.props.history}
                    patient={patient_obj}
                    table={this.props.table}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogViewClinicalPatient.propTypes = {
  epilepsy_case: PropTypes.object,
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogViewClinicalPatient.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  open: false,
  epilepsy_case: null,
};

export default DialogViewClinicalPatient;
