import React from 'react';
import PropTypes from 'prop-types';
import { DivGrow } from 'components';

class ViewDockerContainerBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <DivGrow />
      </React.Fragment>
    );
  }
}

ViewDockerContainerBottom.propTypes = {
  classes: PropTypes.object,
};

export default ViewDockerContainerBottom;
