import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { ListItemText } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import IconButton from '@mui/material/IconButton';

import { StyledMenu, StyledMenuItem } from 'components/StyledMenu';

const useStyles = theme => ({
  menu: {
    position: 'relative',
    borderRadius: '5px',
    background: '#ffffff',
    border: '1px solid #dedede',
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15)',
    paddingTop: 5,
    padding: 2,
    '&::after, &::before': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
    },
    '&::after': {
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#ffffff',
      borderWidth: '7px',
      marginLeft: '-7px',
    },
    '&::before': {
      borderColor: 'rgba(222, 222, 222, 0)',
      borderBottomColor: '#dedede',
      borderWidth: '8px',
      marginLeft: '-8px',
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
});

class ButtonMenuIcon extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      selected: 'date',
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = select => {
    this.setState({ anchorEl: null, selected: select });
  };
  handleClickMenuItem = value => {
    this.setState({ anchorEl: null, selected: value });
    this.props.handleClickMenuItems(value);
  };

  render() {
    const { classes, icon, items } = this.props;
    const { anchorEl, selected } = this.state;
    return (
      <div>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          size="large">
          {icon}
        </IconButton>
        <StyledMenu
          anchorEl={anchorEl}
          id="customized-menu"
          keepMounted
          onClose={this.handleClose}
          open={Boolean(anchorEl)}>
          <div className={classes.menu}>
            {items.map((item, i) => {
              const lower = item.toLowerCase();
              return (
                <StyledMenuItem
                  key={`item-${i}`}
                  onClick={() => this.handleClickMenuItem(lower)}
                  selected={lower === selected}>
                  <ListItemText primary={item} />
                </StyledMenuItem>
              );
            })}
          </div>
        </StyledMenu>
      </div>
    );
  }
}

ButtonMenuIcon.defaultProps = {
  open: false,
  icon: <ViewModuleIcon />,
  items: [],
};
ButtonMenuIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickMenuItems: PropTypes.func,
  icon: PropTypes.object,
  items: PropTypes.array,
  open: PropTypes.bool.isRequired,
};

export default withStyles(ButtonMenuIcon, useStyles);
