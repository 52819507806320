import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  ImageListItem,
} from '@mui/material';
import DivGrow from 'components/DivGrow';
import { AvatarUpload } from 'views/Account/components/Profile/components/ChangeAvatar/components';

const tileData = Array.from(Array(76).keys());
const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  avatar_selected: {
    width: '100%',
    height: '100%',
    border: '10px solid #ddd',
  },
});

class DialogChangeProjectAvatar extends React.Component {
  constructor(props) {
    super(props);
    const { avatar } = props;
    this.state = {
      indexSelected: avatar ? -1 : 0,
      upload: 0,
      avatar,
    };
    this.handleClick = this.handleClick.bind(this);
    const { holder } = props;
    if (holder) {
      holder.setAvatar = avatar => this.setState({ avatar, indexSelected: -1 });
    }
    this.holder = {
      getAvatar: null,
    };
  }
  handleClick = event => {
    const { alt } = event.target;
    if (alt) {
      if (alt.startsWith('avatar')) {
        const parts = alt.split('_');
        if (parts.length === 2) {
          const index = parseInt(parts[1], 10);
          this.setState({ indexSelected: index });
        }
      }
    }
  };
  handleUploadNew = avatar => {
    this.setState({ avatar, indexSelected: -1, upload: false });
    // this.props.handleSelect(avatar);
  };
  handleSelected = () => {
    let { indexSelected, avatar, upload } = this.state;
    if (upload) {
      indexSelected = -1;
      avatar = this.holder.getAvatar();
    }
    if (indexSelected === -1 && avatar && avatar.startsWith('data:image')) {
      this.props.handleSelect(avatar);
      this.setState({ upload: false });
    } else if (indexSelected >= 0) {
      this.props.handleSelect(
        '/images/projects/project_' + indexSelected.toString() + '.png'
      );
    }
  };
  render() {
    const { classes, open, handleCancel } = this.props;
    const { indexSelected, upload, avatar } = this.state;
    let ComponentCenter = null;
    if (upload) {
      ComponentCenter = (
        <div className={classes.root}>
          <AvatarUpload
            handleClose={() => this.setState({ upload: false })}
            handleUploadNew={this.handleUploadNew}
            holder={this.holder}
          />
        </div>
      );
    } else {
      let ComponentAvatar = null;
      if (avatar) {
        ComponentAvatar = (
          <ImageListItem key={`avatar_${-1}`} onClick={this.handleClick}>
            <Avatar
              alt={`avatar_${-1}`}
              className={clsx({
                [classes.avatar]: true,
                [classes.avatar_selected]: -1 === indexSelected,
              })}
              src={avatar}
            />
          </ImageListItem>
        );
      }
      ComponentCenter = (
        <div className={classes.root}>
          <ImageList className={classes.gridList} cols={5} rowHeight={100}>
            {ComponentAvatar}
            {tileData.map(index => {
              let src = '/images/projects/project_' + index.toString() + '.png';
              return (
                <ImageListItem
                  key={`avatar_${index}`}
                  onClick={this.handleClick}>
                  <Avatar
                    alt={`avatar_${index}`}
                    className={clsx({
                      [classes.avatar]: true,
                      [classes.avatar_selected]: index === indexSelected,
                    })}
                    src={src}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </div>
      );
    }
    return (
      <div key="change-avatar-channel-modal">
        <Dialog
          aria-labelledby="form-dialog-title"
          key="dialog-modal"
          onClose={handleCancel}
          open={open}>
          <DialogTitle id="form-dialog-title">
            Change Project Avatar
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change the project avatar, please click on the image to select
              it.
            </DialogContentText>
            {ComponentCenter}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this.setState({ upload: true })}>
              Upload New
            </Button>
            <DivGrow />
            <Button color="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleSelected}>
              Select
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(DialogChangeProjectAvatar, useStyles);
