import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { GetData, ServerErrors } from 'helpers';
import { QueryGroupMapping } from 'graphql/Mapping';

class InputSearchPictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mappings: [],
      loading: false,
      name: null,
      page: 0,
      limit: 5,
      total: 0,
    };
  }
  componentDidMount() {
    this.updateTypes();
  }
  updateTypes = (newName, newPage, newLimit) => {
    let { loading, name, page, limit } = this.state;
    if (loading) {
      return;
    }
    if (newName) {
      name = newName;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryGroupMapping('Keyword Designer', name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, errors } = data.allGroupMapping;
          if (ok) {
            this.setState({
              // open: false,
              loading: false,
              mappings,
              name: name ? name : '',
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleTypeNameChanged = event => {
    // setCount(event.target.value.length);
    // setUser({ ...user, FullName: event.target.value });
    this.updateTypes(event.target.value);
  };
  handleSelectionChanged = value => {
    // console.log({ value });
    this.props.onSelectionChanged(value);
  };
  render() {
    const { disabled } = this.props;
    return (
      <Autocomplete
        disabled={disabled}
        getOptionLabel={option => option.name}
        id="tags-standard"
        multiple
        onChange={(event, value) => this.handleSelectionChanged(value)}
        options={this.state.mappings}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label=""
            onChange={this.handleTypeNameChanged}
            placeholder="Select the keywords that you want to search"
            variant="standard"
          />
        )}
      />
    );
  }
}

InputSearchPictures.propTypes = {
  onSelectionChanged: PropTypes.func,
};
InputSearchPictures.defaultProps = {
  onSelectionChanged: () => '',
};

export default InputSearchPictures;
