import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { Button, ButtonGroup, Grid, TextField, Badge } from '@mui/material';
import { ButtonReloadDelete, ButtonDownloadType } from 'components';
import {
  AutoProjectObjectFilter,
  GetLabelPlaceholder,
  IsFileOrSeries,
  IsNameActive,
  useStyles,
} from './components';
import EditIcon from '@mui/icons-material/Edit';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LaunchIcon from '@mui/icons-material/Launch';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { IsInvalid } from 'helpers';
const BadgeObject = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    right: 5,
    top: 18,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'lightGreen',
  },
}));
class ToolBarProjectObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: '',
      second: '',
      uploading: false,
      access_level_index: props.type,
    };
    const { holder } = props;
    if (holder) {
      holder.getName = () => this.state.center;
      holder.getSecond = () => this.state.second;
    }
  }
  handleAccessLevelChanged = access_level_index => {
    this.setState({ access_level_index });
    this.props.handleSelectionType(access_level_index);
  };
  handleOpenObject = () => {
    const { row_clicked } = this.props;
    if (IsInvalid(row_clicked)) return;
    const { folder_content, folder } = row_clicked;
    let idFolder = null;
    if (folder) idFolder = folder.idFolder;
    else if (folder_content) idFolder = folder_content.idFolder;
    if (idFolder) this.props.history.push(`/explorer/folder/${idFolder}`);
  };
  render() {
    const {
      classes,
      handleOpenEdit,
      handleCloseEditSeries,
      selected,
      handleClearSelected,
      handleOpenVarMappping,
      handleDeleteSelected,
      handleReloadTable,
      loading_table,
      loading,
      isAdmin,
      row_clicked,
    } = this.props;
    const is_series = selected.filter(x => {
      const { folder_content } = x;
      if (IsInvalid(folder_content)) return false;
      const { Series } = folder_content;
      if (IsInvalid(Series)) return false;
      return true;
    });
    let ComponentAdministrator = null;
    let ComponentAdminProjectFilter = null;
    const style_button = {
      paddingTop: is_series.length ? 0 : '7px',
      paddingLeft: is_series.length ? '2px' : '7px',
    };
    if (isAdmin) {
      ComponentAdministrator = (
        <React.Fragment>
          <Button
            disabled={!is_series.length}
            onClick={handleCloseEditSeries}
            sx={style_button}>
            <BadgeObject badgeContent={is_series.length}>
              <EditIcon style={{ color: is_series.length ? 'blue' : 'gray' }} />
            </BadgeObject>
          </Button>
          <Button disabled={!selected.length} onClick={handleOpenEdit}>
            <BadgeObject badgeContent={selected.length - is_series.length}>
              <AppRegistrationIcon
                style={{ color: selected.length ? 'red' : 'gray' }}
              />
            </BadgeObject>
          </Button>
        </React.Fragment>
      );
      ComponentAdminProjectFilter = (
        <AutoProjectObjectFilter
          access_level_index={this.props.type}
          handleAccessLevelChanged={this.handleAccessLevelChanged}
          minWidth={175}
        />
      );
    }
    let ComponentCenter = null;
    let ComponentSecondSearch = null;
    if (IsNameActive(this.props.type)) {
      let {
        label,
        label_second,
        placeholder,
        placeholder_second,
      } = GetLabelPlaceholder(this.props.type);
      ComponentCenter = (
        <Grid item xs>
          <TextField
            id="center-name-search"
            InputLabelProps={{
              shrink: true,
            }}
            label={label}
            onChange={event => {
              this.setState({ center: event.target.value });
            }}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                handleReloadTable();
              }
            }}
            placeholder={placeholder}
            size="small"
            value={this.state.center}
          />
        </Grid>
      );
      if (IsFileOrSeries(this.props.type)) {
        ComponentSecondSearch = (
          <Grid item xs>
            <TextField
              id="second-level-search-object"
              InputLabelProps={{
                shrink: true,
              }}
              label={label_second}
              onChange={event => {
                this.setState({ second: event.target.value });
              }}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter') {
                  handleReloadTable();
                }
              }}
              placeholder={placeholder_second}
              size="small"
              value={this.state.second}
            />
          </Grid>
        );
      }
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{
          width: '100%',
          paddingBottom: 5,
          paddingLeft: 0,
          marginLeft: 0,
          paddingRight: 0,
          marginRight: 0,
        }}>
        <Grid item style={{ paddingLeft: 0 }}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            {ComponentAdministrator}
            <Button disabled={!selected.length} onClick={handleOpenVarMappping}>
              <AccountTreeIcon
                style={{ color: selected.length ? 'green' : 'gray' }}
              />
            </Button>
            <ButtonDownloadType
              idProject={this.props.idProject}
              type="project_objects"
            />
            <Button disabled={!row_clicked} onClick={this.handleOpenObject}>
              <LaunchIcon style={{ color: row_clicked ? 'green' : 'gray' }} />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-start"
          spacing={1}
          xs>
          {ComponentAdminProjectFilter}
          {ComponentCenter}
          {ComponentSecondSearch}
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          style={{ paddingRight: 0, marginRight: 0 }}
          xs={2}>
          <ButtonReloadDelete
            handleClearSelected={handleClearSelected}
            handleDeleteSelected={handleDeleteSelected}
            handleReloadTable={handleReloadTable}
            loading={loading}
            loading_table={loading_table}
            numSelected={selected.length}
            show_delete={isAdmin}
            // tooltip={tooltip}
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarProjectObject.propTypes = {
  handleOpenCreate: PropTypes.func,
};
ToolBarProjectObject.defaultProps = {
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  selected: [],
  handleOpenVarMappping: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSelectionType: () => '',
  loading_table: false,
  loading: false,
  type: 0,
  isAdmin: false,
  access_level: 0,
};

export default withStyles(withRouter(ToolBarProjectObject), useStyles);
