import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import { Checkbox, Fab, FormControlLabel, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { ImageSize, ImageSpacing } from './components';
import { DivGrow } from 'components';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(2),
    width: 50,
  },
  radio_top: {
    marginTop: theme.spacing(1),
  },
  date_picker: {
    width: 140,
  },
});

class TabImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesize: false,
      spacing: false,
      active: props.active,
    };
    this.holder = {
      getSpacing: null,
      getSize: null,
    };
    const { holder } = props;
    if (holder) {
      holder.getTabImageType = () => {
        return this.getImageQuery();
      };
    }
    const { tab_holder, index, query } = props;
    if (tab_holder) {
      tab_holder.getValues[index] = () => ({
        type: query,
        value: this.getImageQuery(),
      });
    }
  }
  getImageQuery = () => {
    const { imagesize, spacing, active } = this.state;
    if (!active) {
      return {};
    }
    const { getSize, getSpacing } = this.holder;
    const query = {};
    if (getSize && imagesize) {
      query.size = getSize();
    }
    if (getSpacing && spacing) {
      query.spacing = getSpacing();
    }
    return query;
  };
  componentDidUpdate() {
    const { active } = this.props;
    if (active !== this.state.active) {
      this.setState({ active });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleExecuteSearch = () => {
    const image = {};
    const { getSize, getSpacing } = this.holder;
    if (getSize) {
      const imagesize = getSize();

      if (imagesize.length) {
        image.size = imagesize;
      }
    }
    if (getSpacing) {
      const imagespacing = getSpacing();

      if (imagespacing.length) {
        image.spacing = imagespacing;
      }
    }
    if (Object.keys(image).length !== 0) {
      this.props.executeSearch(image, 'Images');
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { imagesize, spacing } = this.state;
    return (
      <Grid alignItems="center" container direction="row">
        <FormControlLabel
          className={classes.margin}
          control={
            <Checkbox
              checked={imagesize}
              name="imagesize"
              onChange={this.handleChange}
              size="small"
            />
          }
          label="ImageSize"
        />
        {imagesize && <ImageSize holder={this.holder} />}
        <FormControlLabel
          className={classes.margin}
          control={
            <Checkbox
              checked={spacing}
              name="spacing"
              onChange={this.handleChange}
              size="small"
            />
          }
          label="Spacing"
        />
        {spacing && <ImageSpacing holder={this.holder} />}
        <DivGrow />
        <Fab
          aria-label="add"
          className={classes.margin}
          color="secondary"
          onClick={this.handleExecuteSearch}
          size="small">
          <SearchIcon />
        </Fab>
      </Grid>
    );
  }
}

TabImage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabImage, useStyles);
