import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
} from '@mui/material';
import Draggable from 'react-draggable';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';

const useStyles = theme => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  load_position: {
    color: green[800],
    position: 'absolute',
    top: 0,
    left: 17,
    zIndex: 1,
  },
});

function PaperComponent(props) {
  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      handle="#draggable-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

class DialogCreateSeriesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleClose = () => {
    if (this.props.loading) return;
    this.props.handleClose(false);
  };
  handleChange = event => {
    this.setState({ name: event.target.value });
  };
  handleEnter = event => {
    const { key } = event;
    if (key === 'Enter') {
      this.setState({ name: '' });
      this.props.handleCreateDetail(this.state.name);
    }
  };

  render() {
    const { classes, open, title, loading } = this.props;

    const { name } = this.state;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          {`Create ${title}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To crate a new {title}, please enter the name. The {title} should be
            unique.
          </DialogContentText>
          <TextField
            autoFocus
            disabled={loading}
            fullWidth
            id="name"
            label={title}
            margin="dense"
            onChange={this.handleChange}
            onKeyDown={this.handleEnter}
            value={name}
            // type="email"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            disabled={loading}
            onClick={this.handleClose}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => {
                this.props.handleCreateDetail(name);
              }}>
              Create
            </Button>
            {loading && (
              <CircularProgress className={classes.load_position} size={35} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogCreateSeriesDetail.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  handleCreateDetail: PropTypes.func,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
};
DialogCreateSeriesDetail.defaultProps = {
  open: false,
  title: 'Not Defined',
  loading: false,
};

export default withStyles(DialogCreateSeriesDetail, useStyles);
