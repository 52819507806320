import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageIcon from '@mui/icons-material/Image';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

import { FormatTime, getInitials, GetThumbnailFile } from 'helpers';
import { GetContentThumbnail } from 'helpers/utils_explorer';

class FileDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, handleDrawerClose, theme, folder_content } = this.props;
    const { File } = folder_content;
    let thumbnail = GetContentThumbnail(folder_content);
    if (!thumbnail) {
      thumbnail = GetThumbnailFile(File.original_name);
    }
    return (
      <ListItem className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === 'rtl' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <ListItemAvatar>
          {thumbnail ? (
            <Avatar src={thumbnail}>{getInitials(File.original_name)}</Avatar>
          ) : (
            <Avatar>
              <ImageIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={File.original_name}
          secondary={`${FormatTime(File.createdAt, 'MMM Do, YYYY')} (ID: ${
            File.idFileObject
          })`}
        />
      </ListItem>
    );
  }
}

FileDetailHeader.propTypes = {
  classes: PropTypes.object,
};

export default FileDetailHeader;
