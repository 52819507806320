import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { GroupBox, IconButtonLoad, Sequence } from 'components';
import { Replay as ReloadIcon, Save as SaveIcon } from '@mui/icons-material';
import { InputCenters } from '../index';
import { MutationUpdateSequenceMapping } from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';

class GroupBoxRowSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sequence: null,
      center: null,
      name: '',
      updating: false,
      idSelected: -1,
    };
    this.holder = {
      resetSequence: null,
      resetCenter: null,
      setCenter: null,
      reset: () => {
        if (this.holder.resetSequence) {
          this.holder.resetSequence();
        }
        if (this.holder.resetCenter) {
          this.holder.resetCenter();
        }
      },
    };
  }

  isEqual = mapping => {
    const { center, sequence, name } = this.state;
    if (!center && mapping.center) return false;
    if (center && !mapping.center) return false;
    if (center && mapping.center && center.id !== mapping.center.id)
      return false;
    if (!sequence && mapping.sequence) return false;
    if (sequence && !mapping.sequence) return false;
    if (
      sequence &&
      mapping.sequence &&
      sequence.idSequence !== mapping.sequence.idSequence
    )
      return false;
    if (name !== mapping.name) return false;
    return true;
  };
  isEmpty = () => {
    const { center, sequence, name } = this.state;
    if (!center && !sequence && name === '') return true;
    return false;
  };
  getObject = (full = false, id = null) => {
    let mapping = {};
    if (id) {
      mapping.id = id;
    }
    const { name, center, sequence } = this.state;

    if (center) {
      if (full) mapping.center = center;
      mapping.idCenter = center.id;
    }
    if (sequence) {
      if (full) mapping.sequence = sequence;
      mapping.idSequence = sequence.idSequence;
    }
    if (name !== '') mapping.name = name;
    if (full) {
      return {
        ...this.props.row_selected,
        ...mapping,
      };
    } else {
      return mapping;
    }
  };

  componentDidUpdate() {
    const { row_selected, single } = this.props;
    //
    if (!row_selected || !single) return;
    const { idSelected } = this.state;
    if (this.isEmpty() || idSelected !== row_selected.id) {
      this.handleResetRowSequence();
    }
  }

  handleResetRowSequence = () => {
    if (!this.holder.resetSequence) {
      return;
    }
    let sequence = null;
    let center = null;
    let name = '';
    let idSelected = -1;
    //
    if (this.props.row_selected && this.props.single) {
      sequence = this.props.row_selected.sequence;
      center = this.props.row_selected.center;
      name = this.props.row_selected.name;
      idSelected = this.props.row_selected.id;
      if (this.holder.setCenter) {
        this.holder.setCenter(center);
        this.holder.resetSequence(sequence);
      }
    } else {
      this.holder.reset();
    }
    if (this.isEqual({ name, center, sequence, idSelected })) return;
    this.setState({
      name,
      center,
      sequence,
      idSelected,
    });
  };
  isChanged = () => {
    const { row_selected: original } = this.props;
    const { center, sequence, name } = this.state;
    //
    if (this.isEmpty()) return false;
    if (original && center && original.center) {
      if (center.id !== original.center.id) return true;
    }
    if (original && sequence && original.sequence) {
      if (sequence.idSequence !== original.sequence.idSequence) return true;
    }
    if (original && name !== original.name) return true;
    if (!original && !this.isEmpty()) return true;
    return false;
  };
  getSelected = (full = false) => {
    let list = [];
    const { single, selected } = this.props;
    if (this.isEmpty()) return list;
    if (single) {
      list.push(this.getObject(full, this.props.row_selected.id));
    } else if (selected) {
      for (let i = 0; i < selected.length; i++) {
        const map = this.getObject(full);
        if (full) {
          list.push({
            ...selected[i],
            ...map,
            id: selected[i].id,
          });
        } else {
          list.push({
            ...map,
            id: selected[i].id,
          });
        }
      }
    }
    return list;
  };
  updateMapping = () => {
    const { updating } = this.state;
    if (updating || this.isEmpty()) return;
    this.setState({ updating: true });
    (async () => {
      MutationUpdateSequenceMapping(this.getSelected())
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateSequenceMappings;
          if (ok) {
            this.setState({ updating: false });
            this.props.handleUpdateSequenceMappings(this.getSelected(true));
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            updating: false,
            idSelected: -1,
            name: '',
            center: null,
            sequence: null,
          });
          if (this.holder.setCenter) {
            this.holder.setCenter(null);
            this.holder.resetSequence(null);
          }
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangeMappingValue = event => {
    this.setState({ name: event.target.value });
  };
  handleSequenceChanged = sequence => {
    this.setState({ sequence });
  };
  handleSelectCenterChanged = center => {
    this.setState({ center });
  };

  render() {
    let { updating, single } = this.props;
    const { name, center, sequence } = this.state;
    if (single === undefined) single = true;
    let disable_all = false;
    if (
      single &&
      (!this.props.row_selected || this.props.row_selected.id < 0)
    ) {
      disable_all = true;
    }
    //
    return (
      <GroupBox title_box={this.props.title_box}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start">
          <Grid
            item
            style={{ padding: 0, margin: 0, paddingRight: 50, width: '100%' }}
            xs={3}>
            <Sequence
              disabled={disable_all}
              handleSetSequence={this.handleSequenceChanged}
              holder={this.holder}
              placeholder=" "
              sequence={sequence}
              variant="standard"
            />
          </Grid>
          <Grid
            item
            style={{
              paddingRight: 20,
              paddingTop: 15,
              paddingLeft: 20,
              width: '100%',
            }}
            xs={3}>
            <InputCenters
              center={center}
              disabled={disable_all}
              handleSelectCenter={this.handleSelectCenterChanged}
              holder={this.holder}
            />
          </Grid>
          <Grid item style={{ paddingTop: 15, paddingRight: 10 }} xs={3}>
            <TextField
              // fullWidth
              disabled={disable_all}
              id="sequence-mapping-value"
              InputLabelProps={{
                shrink: true,
              }}
              label="Mapping Value"
              onChange={this.handleChangeMappingValue}
              sx={{ m: 1, minWidth: '25ch' }}
              value={name}
              variant="standard"
            />
          </Grid>
          <Grid
            container
            direction="row"
            item
            style={{ paddingTop: 15, paddingRight: 10, marginTop: 0 }}
            xs={3}>
            <IconButtonLoad
              disabled={updating || disable_all}
              handleClick={this.handleResetRowSequence}
              icon={<ReloadIcon />}
              tooltip="Reset Sequence"
            />
            <IconButtonLoad
              disabled={!this.isChanged() || updating || disable_all}
              handleClick={this.updateMapping}
              icon={
                <SaveIcon color={this.isChanged() ? 'primary' : 'disabled'} />
              }
              loading={updating}
              tooltip="Update Sequence"
            />
          </Grid>
        </Grid>
      </GroupBox>
    );
  }
}

GroupBoxRowSequenceMapping.propTypes = {
  classes: PropTypes.object,
  row_selected: PropTypes.object,
  selected: PropTypes.array,
  single: PropTypes.bool.isRequired,
  title_box: PropTypes.string.isRequired,
};
GroupBoxRowSequenceMapping.defaultProps = {
  handleUpdateSequenceMappings: () => '',
  title_box: '',
  row_selected: null,
  single: true,
  selected: [],
};

export default GroupBoxRowSequenceMapping;
