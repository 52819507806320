import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { QueryGroupMapping } from 'graphql/Mapping';
import { ServerErrors } from 'helpers';
import { GetData } from 'helpers';

class ComboxMultiGroupMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: props.value ? props.value : [],
      mappings: [],
    };
  }
  componentDidMount() {
    const { group_name, value } = this.props;
    if (group_name === undefined || !group_name) return;
    let name = null;
    if (value) name = value.name;
    this.runLoadGroupMapping(name, group_name);
  }
  componentDidUpdate() {
    const { value } = this.props;
    if (value && value.length !== this.state.value.length) {
      this.setState({ value });
    }
  }
  runLoadGroupMapping = (name, newGroupName = null) => {
    let { loading, group_name } = this.state;
    if (loading) {
      if (name) this.setState({ inputValue: name });
      return;
    }
    if (newGroupName && newGroupName !== '') {
      group_name = newGroupName;
    }
    this.setState({ loading: true });
    (async () => {
      QueryGroupMapping(group_name, name, 0, 10)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, errors } = data.allGroupMapping;
          if (ok) {
            let value = this.state.value;
            if (name) {
              const i = mappings.map(x => x.name).indexOf(name);
              if (i !== -1) value = [mappings[i]];
            }
            this.setState({
              value,
              loading: false,
              mappings,
              inputValue: name ? name : '',
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleChange = (event, newValue) => {
    const { mappings } = this.state;
    const { group_name } = this.props;
    let idGroup = -1;
    if (mappings && mappings.length) {
      idGroup = mappings[0].idGroup;
    }
    this.props.handleGroupMappingSelected(idGroup, group_name, newValue);
    this.setState({ value: newValue });
  };
  render() {
    const { mappings, loading, value } = this.state;
    return (
      <Autocomplete
        getOptionLabel={option => option.name}
        id={`group_map_${uuid()}`}
        multiple
        onChange={this.handleChange}
        options={mappings}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={this.props.label}
            placeholder={this.props.placeholder}
          />
        )}
        value={value}
      />
    );
  }
}

ComboxMultiGroupMapping.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
ComboxMultiGroupMapping.defaultProps = {
  label: 'Label Mapping',
  placeholder: 'Placeholder',
};
export default ComboxMultiGroupMapping;
