import React from 'react';
import PropTypes from 'prop-types';
import { ViewSeriesGrid } from 'views/DataImages/components/ViewDatabaseSeries/components';
import { SnackMessage } from 'components';
class ViewStudyGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { idStudy, Study } = this.props;
    if (Study === undefined || !Study) return null;
    let total_series = 0;
    let series = [];
    if (Study.Series) {
      total_series = Study.Series.length;
      series = Study.Series;
    }
    // else console.log({ Study });
    const ErrorMessage = (
      <SnackMessage
        handleClose={this.props.handleCloseSnak}
        message_text={this.props.error}
        open={this.props.error !== '' ? true : false}
        type="error"
      />
    );
    return (
      <React.Fragment>
        <ViewSeriesGrid
          handleSeriesDeleted={this.props.handleSeriesDeleted}
          rows={series}
          series_view={`/image_analysis/study/${idStudy}/series`}
          total_series={total_series}
          user={this.props.user}
        />
        {ErrorMessage}
      </React.Fragment>
    );
  }
}

ViewStudyGrid.propTypes = {
  Study: PropTypes.object.isRequired,
  idStudy: PropTypes.number.isRequired,
};

export default ViewStudyGrid;
