import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

class SettingRegions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dashboard_view, loading, handleCheckChange } = this.props;
    let anatomical_regions = true,
      pathologies = true,
      investigative_side = true,
      image_focus = true;
    if (dashboard_view) {
      anatomical_regions = dashboard_view.anatomical_regions;
      pathologies = dashboard_view.pathologies;
      investigative_side = dashboard_view.investigative_side;
      image_focus = dashboard_view.image_focus;
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start">
        <Typography gutterBottom sx={{ fontSize: 14 }} variant="h6">
          Regions
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={pathologies}
              disabled={loading}
              name="pathologies"
              onChange={handleCheckChange}
            />
          }
          label="Patologies"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={anatomical_regions}
              disabled={loading}
              name="anatomical_regions"
              onChange={handleCheckChange}
            />
          }
          label="Anatomical Regions"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={investigative_side}
              disabled={loading}
              name="investigative_side"
              onChange={handleCheckChange}
            />
          }
          label="Investigative Side"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={image_focus}
              disabled={loading}
              name="image_focus"
              onChange={handleCheckChange}
            />
          }
          label="Image Focus"
        />
      </Grid>
    );
  }
}

SettingRegions.propTypes = {
  dashboard_view: PropTypes.object,
  handleCheckChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default SettingRegions;
