import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SEQUENCE_ERRORS = gql`
  query($text: String, $page: Int, $limit: Int) {
    sequenceErrors(text: $text, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      sequence_errors {
        id
        idUser
        idSeries

        user {
          id
          FullName
        }
        series {
          SeriesDescription
          locations {
            idFolderContent
            idFolder
          }
        }
        count
        prediction_class_category
        prediction_class

        prediction_probability_category
        prediction_probability
        confidence

        error

        AccessionNumber
        AcquisitionDate
        ContrastBolusAgent
        EchoTime
        EchoTrainLength
        FlipAngle
        ImageType
        InversionTime
        Modality
        MRAcquisitionType
        PatientID
        PhotometricInterpretation
        PixelBandwidth
        PixelSpacing
        ProtocolName
        RepetitionTime
        ScanOptions
        ScanningSequence
        SequenceName
        SequenceVariant
        SeriesDescription
        SliceThickness
        SeriesInstanceUID

        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QuerySequenceErrors = (text, page, limit) => {
  console.log('QuerySequenceErrors', { text, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEQUENCE_ERRORS),
    variables: {
      text,
      page,
      limit,
    },
  });
};
const MUTATION_DELETE_SEQUENCE_ERRORS = gql`
  mutation($ids: [LongLong!]!) {
    deleteSequenceErrors(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteSequenceErrors = ids => {
  console.log('MutationDeleteSequenceErrors', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SEQUENCE_ERRORS),
    variables: {
      ids,
    },
  });
};
