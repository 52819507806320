/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { Divider, Paper } from '@mui/material';

import { GetData, ServerErrorsString } from 'helpers';
import { DeleteRightsQuery } from 'graphql/Folders';
import {
  ButtonDeleteIcon,
  SearchUsersProjects,
  SnackMessage,
} from 'components';

const useStyles = theme => ({
  root: {
    padding: '2px 4px',
    paddingBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

class BarAddUserProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      error: '',
      loading: false,
    };
  }

  handleAdd = selected => {
    if (Object.keys(selected).length === 0) {
      this.setState({ error: 'Empty selection' });
    } else {
      this.props.handleAdd(selected);
    }
  };
  getDeleteRights = () => {
    const { to_delete } = this.props;

    const old_rights = to_delete.filter(x => x.idRight);
    const new_rights = to_delete.filter(x => x.idRight === null);
    return { old_rights, new_rights };
  };
  handleDeleteRights = () => {
    const { loading } = this.props;
    const { old_rights, new_rights } = this.getDeleteRights();

    if (loading || (!old_rights.length && !new_rights.length)) return;
    if (old_rights.length === 0) {
      this.props.handleDeleteSelected(new_rights);
      return;
    }
    this.setState({ loading: true });
    (async () => {
      DeleteRightsQuery(old_rights.map(x => x.idRight))
        .then(res => {
          const data = GetData(res);
          const { deleteRights } = data;
          if (!deleteRights.ok) {
            throw deleteRights.errors;
          }
          // eslint-disable-next-line
          this.state.loading = false;
          this.props.handleDeleteSelected(this.props.to_delete);
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const { classes, to_delete } = this.props;
    const { loading, error } = this.state;
    const total_delete = to_delete.length;
    return (
      <Paper className={classes.root}>
        <SearchUsersProjects
          download_button
          handleAddUserProject={this.handleAdd}
        />
        {total_delete !== 0 && (
          <React.Fragment>
            <Divider className={classes.divider} orientation="vertical" />
            <ButtonDeleteIcon
              loading={loading}
              onClick={this.handleDeleteRights}
              selected={to_delete}
            />
          </React.Fragment>
        )}
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Paper>
    );
  }
}

BarAddUserProject.defaultProps = {
  to_delete: [],
};
BarAddUserProject.propTypes = {
  classes: PropTypes.object.isRequired,
  to_delete: PropTypes.array,
};

export default withStyles(BarAddUserProject, useStyles);
