/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';

const useStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
  },
});

class TableToolbar extends Component {
  render() {
    const { numSelected, classes, title, handleDeleteSelected } = this.props;
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} id="tableTitle" variant="h6">
            {title}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                aria-label="delete"
                onClick={handleDeleteSelected}
                size="large">
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton aria-label="filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default withStyles(TableToolbar, useStyles);
