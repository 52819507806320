import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { OutputDocker } from './components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class TabDockerOutputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { settings, holder, uploading, disabled } = this.props;
    let amount_output = 0;
    settings.inputs_outputs.forEach(element => {
      if (element.in_out_type === 'output') amount_output++;
    });
    return (
      <Grid container item spacing={1}>
        {amount_output === 0 ? (
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={0}>
            <p>Press the button to add a new output configuration</p>
          </Grid>
        ) : null}
        {settings.inputs_outputs.map((output, index) => {
          return output.in_out_type === 'output' ? (
            <OutputDocker
              defaultSetting={this.props.defaultSetting}
              disabled={disabled}
              handleChanged={this.props.handleChanged}
              handleRemoveOutput={id => this.props.handleRemoveOutput(id)}
              handleUpdateSetting={settings =>
                this.props.handleUpdateSetting(settings)
              }
              holder={holder}
              key={`${output.id}-${index}`}
              output={output}
              settings={settings}
              uploading={uploading}
            />
          ) : null;
        })}
        <Grid container item justifyContent="center" xs={12}>
          <IconButton
            color="primary"
            onClick={this.props.handleAddOutput}
            size="large">
            <AddCircleOutlineIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

TabDockerOutputs.propTypes = {
  disabled: PropTypes.bool,
};
export default TabDockerOutputs;
