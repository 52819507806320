import React, { Component } from 'react';
import { Subscription } from '@apollo/client/react/components';
import {
  SUBSCRIPTION_NEW_CHAT_MESSAGE,
  SUBSCRIPTION_NEW_CHATS,
} from 'graphql/Communications';
import { QueryRunChatsList } from './utils_chat_list';
import { GetData, IsInvalid } from 'helpers';

class QueryChatsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      chats: [],
    };
  }

  componentDidMount() {
    this.handleLoadChatList();
  }
  handleLoadChatList = () => {
    let { loading } = this.state;
    const { page, limit } = this.props;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryRunChatsList(page, limit)
        .then(res => {
          const data = GetData(res);
          const { chats } = data.directChats;
          this.setState({
            chats,
            loading: false,
          });
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  getChatIndex = (chats, newChatItem, user) => {
    const { createdAt } = newChatItem;
    const chatsIds = chats.map(x => x.User.id);
    let index = -1;
    if (newChatItem.User) {
      index = chatsIds.indexOf(newChatItem.User.id);
    }

    if (index === -1 && createdAt.startsWith('receiverId:')) {
      const parts = createdAt.split(':');
      index = chatsIds.indexOf(parseInt(parts[1], 10));
      if (index === -1) {
        return -1;
      }
    } else if (user) {
      const { sender, receiverId } = newChatItem;

      if (sender && receiverId) {
        if (receiverId === user.id) {
          index = chatsIds.indexOf(sender.id);
        }
      }
    }
    return index;
  };
  onSubscriptionDataChats = ondata => {
    console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { newChatItem } = data;
      if (newChatItem) {
        const { chats } = this.state;
        const index = this.getChatIndex(chats, newChatItem);
        if (index === -1) {
          chats.unshift(newChatItem);
          this.setState({ chats });
          // this.props.handleAddLastDirect(newDirectMessage);
        }
      }
    }
  };
  onSubscriptionDataDirect = ondata => {
    console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { chats } = this.state;
      const { newChatMessage } = data;
      if (newChatMessage) {
        const { user } = this.props;
        const index = this.getChatIndex(chats, newChatMessage, user);
        if (index === -1) {
          chats.unshift(newChatMessage);
          this.setState({ chats });
          // this.props.handleAddLastDirect(newDirectMessage);
        } else {
          const { NewMessages, LastMessage } = chats[index];
          if (LastMessage.text !== newChatMessage.text) {
            chats[index].NewMessages = NewMessages + 1;
            chats[index].LastMessage = newChatMessage;
            this.setState({
              chats,
            });
          }
        }
      }
    }
  };
  render() {
    const { children } = this.props;
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataChats}
        shouldResubscribe
        subscription={SUBSCRIPTION_NEW_CHATS}
        variables={{}}>
        {sub_delete => {
          <Subscription
            fetchPolicy={'no-cache'}
            onSubscriptionData={this.onSubscriptionDataDirect}
            shouldResubscribe
            subscription={SUBSCRIPTION_NEW_CHAT_MESSAGE}
            variables={{}}>
            {({ loading, error }) => {
              if (sub_delete.loading) {
                loading = sub_delete.loading;
              }
              if (sub_delete.error) {
                error = sub_delete.error;
              }
              return React.Children.map(children, function(child) {
                return React.cloneElement(child, {
                  ListOfChats: this.state.chats,
                  loading,
                  error,
                });
              });
            }}
          </Subscription>;
        }}
      </Subscription>
    );
  }
}

export default QueryChatsList;
