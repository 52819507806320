import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { MenuTopDown } from 'components';

const titles = [
  'Mx.',
  'Mr.',
  'Ms.',
  'BSc.',
  'Ph.D.',
  'Dr.',
  'PostDoc',
  'MSc.',
  'PD',
  'Prof.',
  'Prof. Dr. med.',
  'Student',
  'Other',
];

class UserTitles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      open_titles: false,
    };
  }

  handleClickTitles = event => {
    event.preventDefault();
    this.setState({ open_titles: !this.state.open_titles });
  };
  handleCloseTitles = (title, event) => {
    if (!event) {
      this.setState({ open_titles: false });
      return;
    }
    // const event_final = {
    //   persist: () => {},
    //   target: {
    //     type: 'title',
    //     name: 'title',
    //     value: title.option,
    //   },
    // };
    this.setState({ title: title.option, open_titles: false });
    this.props.onChange(title.option);
  };

  render() {
    return (
      <div style={{ width: '100%' }}>
        <TextField
          fullWidth
          id="title"
          InputLabelProps={{
            shrink: true,
          }}
          label="Title"
          margin="dense"
          name="title"
          onClick={this.handleClickTitles}
          placeholder="Select your titile"
          style={{ width: this.props.width }}
          value={this.state.title}
          variant="outlined"
        />
        <MenuTopDown
          handleClose={this.handleCloseTitles}
          object_parent={'title'}
          objects={titles}
          open={this.state.open_titles}
        />
      </div>
    );
  }
}

UserTitles.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  width: PropTypes.string,
};
UserTitles.defaultPrpos = {
  width: '130px',
  onChange: () => '',
};

export default UserTitles;
