export const ListObjectAccessLevel = [
  'Files and Series',
  'Folder',
  'Other Objects',
  'Centers - All', //3
  'Centers - Series', //4
  'Centers - Files', //5
  'Centers - Series and Files', //6
  'Centers - Folders', //7
];
// only_files: 0
// only_folders: 1
// only_objects: 2
// only_centers: 3

export const IsNameActive = index => {
  if (index === 0) return true;
  if (index >= 3) return true;
};
export const IsFileOrSeries = index => {
  if (index === 3) return true;
  if (index === 4) return true;
  if (index === 5) return true;
  if (index === 6) return true;
  return false;
};
export const GetLabelPlaceholder = index => {
  let label = 'Center to search';
  let label_second = 'Series to search';
  let placeholder = 'Type the center name...';
  let placeholder_second = 'Type Series name...';
  if (index === 0) {
    label = 'File or Series to search';
    placeholder = 'Type the file or series name...';
  }
  if (index === 5) {
    placeholder_second = 'Type File name...';
    label_second = 'File to search';
  }
  if (index === 6) {
    placeholder_second = 'Type Series or File name...';
    label_second = 'Series or File to search';
  }
  return {
    label,
    label_second,
    placeholder,
    placeholder_second,
  };
};
export const GetObjectInputs = (type, name, second_search, idCategory) => {
  // only_files: 0
  // only_folders: 1
  // only_objects: 2
  // only_centers: 3
  let inputs = null;
  if (type === 0) {
    inputs = {
      only_files: true,
    };
  } else if (type === 1) {
    inputs = {
      only_folders: true,
    };
  } else if (type === 2) {
    inputs = {
      only_objects: true,
    };
  } else if (type >= 3) {
    inputs = {
      only_centers: true,
    };
  }
  if (idCategory) {
    inputs = {
      ...inputs,
      idCategory,
    };
  }
  if (name !== undefined) {
    inputs = {
      ...inputs,
      name,
    };
    if (second_search) {
      inputs = {
        ...inputs,
        second_search,
      };
    }
  }
  if (inputs) {
    if (type >= 4 && type < 7) inputs.only_files = true;
    if (type === 4) inputs.only_files_series = true;
    if (type === 5) inputs.only_files_object = true;
    if (type === 7) inputs.only_folders = true;
  }
  return inputs;
};
