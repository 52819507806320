import React from 'react';
import PropTypes from 'prop-types';
import path from 'common/path';

import { withStyles } from 'tss-react/mui';
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material';
import { ButtonLoad, DialogQuestion } from 'components';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import {
  GetData,
  ServerErrorsString,
  GetNameFromHeader,
  GetResponseError,
} from 'helpers';

const useStyles = theme => ({
  button: {
    textTransform: 'none',
    padding: 4,
    margin: 5,
  },
  external: {
    margin: 0,
    padding: 0,
    marginRight: theme.spacing(1),
  },
});

class ButtonDownloadAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open_download_all: false,
    };
  }

  handleProgress = (event, loading) => {
    const { link } = this.props;
    if (!link) {
      return;
    }
    const { folder, content } = link;
    let total = 0;
    if (folder) total = folder.Size;
    if (content) {
      const { File, Series } = content;
      if (File) total = File.Size;
      if (Series) total = Series.Size;
    }
    let { loaded } = event;
    if (typeof loading !== 'undefined' && !loading) {
      loaded = total;
    }
    this.props.updateProgress(loaded, total);
  };
  handleDownloadAll = () => {
    const { loading } = this.state;
    const { link } = this.props;
    if (loading || !link) {
      this.setState({ open_download_all: false });
      return;
    }
    const { folder, content } = link;
    let selected = [];
    if (folder) selected.push({ idFolder: folder.idFolder });
    if (content) selected.push({ idFolderContent: content.idFolderContent });

    this.setState({ loading: true, open_download_all: false });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(selected);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            loading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          loading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key, this.handleProgress)
        .then(async response => {
          let name = GetNameFromHeader(response);
          if (selected.length === 1) {
            if (content) {
              const { Series, File } = content;
              if (Series) {
                name = `${Series.SeriesDescription}.zip`;
              } else if (File && File.original_name !== name) {
                name = path.basename(
                  File.original_name,
                  path.extname(File.original_name)
                );
              }
            } else if (folder) {
              name = `${folder.name}.zip`;
            } else {
              console.log({
                name,
                total: selected.length,
                content,
                folder,
              });
            }
          }
          if (name === '') name = 'unknown_filename';
          console.log({ name });
          this.handleProgress({ loaded: 0 });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          this.setState({ loading: false });
          await MutationDownloadFinished(download_key);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleDownloadAllQuestion = () => {
    const { link } = this.props;
    if (!link) {
      return;
    }
    const { folder, content } = link;
    let selected = [];
    let total_elements = 0;
    if (folder) {
      if (folder.Files) {
        total_elements = folder.Files.length;
      }
      if (folder.SubFolders && folder.SubFolders.length) {
        total_elements += folder.SubFolders.length + 1;
      }
      selected.push({ idFolder: folder.idFolder });
    }
    if (content) selected.push({ idFolderContent: content.idFolderContent });
    //
    if (selected.length > 1 || total_elements > 1) {
      this.setState({ open_download_all: true });
    } else {
      this.handleDownloadAll();
    }
  };

  render() {
    const { classes, isMobile } = this.props;
    const { loading } = this.state;
    let name = 'Download All';
    if (isMobile) name = '';
    return (
      <div className={classes.external}>
        <ButtonLoad
          className={classes.button}
          color="inherit"
          handleClick={this.handleDownloadAllQuestion}
          loading={loading}
          name={name}
          startIcon={<CloudDownloadIcon />}
        />
        <DialogQuestion
          button_name="Download All one ZIP"
          button_secondary="Download One at a time"
          handleAction={this.handleDownloadAll}
          handleActionSecondary={() => {
            this.setState({ open_download_all: false });
            this.props.handleActionSecondary();
          }}
          handleClose={() => this.setState({ open_download_all: false })}
          message="This action will compress all the elements in one single file, this could take time. We recommend to download the element individually."
          open={this.state.open_download_all}
          title="Download All Shared Elements"
        />
      </div>
    );
  }
}

ButtonDownloadAll.propTypes = {
  classes: PropTypes.object,
};
ButtonDownloadAll.defaultProps = {
  handleActionSecondary: () => '',
};
export default withStyles(ButtonDownloadAll, useStyles);
