import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { green } from '@mui/material/colors';

import useStyles from 'components/Buttons/ButtonDownloadExplorerContent/Styles';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { withRouter } from 'react-router-dom';
import { copySeries, copyFiles } from 'redux-store/actions/file_folder_manager';

class ButtonAddBatchProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copySeries = selected => {
    const series = selected.map(x => x.Series).filter(x => x);
    console.log('handleAddToBatchprocess series', { selected, series });
    if (!series.length) return;
    let { copy_series } = this.props;
    series.forEach(x => {
      const index = copy_series.map(x => x.idSeries).indexOf(x.idSeries);
      if (index === -1) {
        copy_series.push(x);
      }
    });
    return copy_series;
  };
  copyFiles = selected => {
    const files = selected
      .map(x => ({ File: x.File, Owner: x.Owner }))
      .filter(x => x);
    console.log('handleAddToBatchprocess files', { files });
    if (!files.length) return;
    let { copy_files } = this.props;
    files.forEach(x => {
      const newFile = { ...x.File, Owner: x.Owner };
      const index = copy_files
        .map(x => x.idFileObject)
        .indexOf(newFile.idFileObject);
      if (index === -1) {
        copy_files.push(newFile);
      }
    });
    return copy_files;
  };
  handleAddToBatchprocess = () => {
    console.log('handleAddToBatchprocess');
    const { selected } = this.props;
    this.props.copyFiles(this.copyFiles(selected));
    this.props.copySeries(this.copySeries(selected));
    this.props.history.push('/pipelines/input_data');
  };
  render() {
    const { classes, defaultProps, selected } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Tooltip title="Add to Batch Process...">
            <Badge
              badgeContent={selected.length}
              onClick={this.handleAddToBatchprocess}
              {...defaultProps}
            />
          </Tooltip>
        </div>
      </div>
    );
  }
}

ButtonAddBatchProcess.propTypes = {
  classes: PropTypes.object,
};
ButtonAddBatchProcess.defaultProps = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    color: 'secondary',
    children: <PostAddIcon style={{ color: green[800] }} />,
    style: {
      margin: 0,
      padding: 0,
      marginLeft: 4,
      marginRight: -5,
      marginBottom: 9,
    },
  },
  selected: [],
};
const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    total_series: copy_series ? copy_series.length : 0,
    copy_files: copy_files ? copy_files : [],
    total_files: copy_files ? copy_files.length : 0,
  };
};

export default connect(mapStateToProps, { copySeries, copyFiles })(
  withStyles(withRouter(ButtonAddBatchProcess), useStyles)
);
