import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
});

class ButtonCircularWait extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }
}

ButtonCircularWait.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ButtonCircularWait, useStyles);
