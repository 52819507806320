import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from '@mui/material';

import {
  EditSequenceMappingBottom,
  EditSequenceMappingHeader,
  GroupBoxRowSequenceMapping,
} from './components';
import { MainTable } from 'components/Tables/components';
import { useStyles } from './utils';
import { columns } from '../TableSequenceMapping/utils';
import { rowsPerPageSmall } from 'helpers';

class EditSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      row_selected: {
        id: -1,
        idSequence: -1,
        name: '',
        sequence: null,
        center: null,
      },
    };
  }

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };

  handleSelectedRow = row_selected => {
    this.setState({ row_selected });
  };
  handleUpdateSequenceMappings = mappings => {
    const index = mappings.map(x => x.id).indexOf(this.state.row_selected.id);
    if (index !== -1) {
      this.setState({ row_selected: mappings[index] });
    }
    this.props.handleUpdateSequenceMappings(mappings);
  };

  render() {
    const { classes, selected } = this.props;
    const { row_selected } = this.state;

    return (
      <Card className={classes.card}>
        <EditSequenceMappingHeader
          classes={classes}
          handleOpenEditView={this.props.handleOpenEditView}
          selected={selected}
        />
        <Divider />
        <CardContent>
          <GroupBoxRowSequenceMapping
            handleAddError={this.props.handleAddError}
            handleUpdateSequenceMappings={this.handleUpdateSequenceMappings}
            selected={selected}
            single={false}
            title_box="Modify All Selected Mapping"
          />
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <MainTable
              columns={columns}
              dense
              disable_checkbox
              handleSelectedRow={this.handleSelectedRow}
              limit={this.state.limit}
              page={this.state.page}
              rows={selected}
            />
            <TablePagination
              component="div"
              count={selected.length}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBoxRowSequenceMapping
            handleAddError={this.props.handleAddError}
            handleUpdateSequenceMappings={this.handleUpdateSequenceMappings}
            row_selected={row_selected}
            title_box="Selected Mapping"
          />
        </CardContent>
        <CardActions disableSpacing>
          <EditSequenceMappingBottom classes={classes} />
        </CardActions>
      </Card>
    );
  }
}

EditSequenceMapping.propTypes = {
  classes: PropTypes.object,
  handleOpenEditView: PropTypes.func,
};
EditSequenceMapping.defaultProps = {
  handleOpenEditView: () => '',
  handleUpdateSequenceMappings: () => '',
  handleAddError: () => '',
};
export default withStyles(EditSequenceMapping, useStyles);
