import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from '@mui/material';

import { EditSequenceBottom, GroupBoxEditSelectedRow } from './components';
import { MainTable } from 'components/Tables/components';
import { useStyles, columns } from './components/utils';
import { CardEditHeader } from 'components';
import { rowsPerPageSmall } from 'helpers';

class EditSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      row_selected: {
        idSequence: -1,
        name: '',
      },
    };
  }

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };

  handleSelectedRow = row_selected => {
    this.setState({ row_selected });
  };
  handleUpdateSequence = sequence => {
    const { selected } = this.props;
    const index = selected.map(x => x.idSequence).indexOf(sequence.idSequence);
    if (index !== -1) {
      this.setState({ row_selected: { ...selected[index], ...sequence } });
    }
    this.props.handleUpdateSequence(sequence);
  };

  render() {
    const { classes, selected } = this.props;
    const { row_selected } = this.state;
    return (
      <Card className={classes.card}>
        <CardEditHeader
          handleClose={this.props.handleOpenEditView}
          title="Edit Selected Sequences"
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <MainTable
              columns={columns}
              dense
              disable_checkbox
              handleSelectedRow={this.handleSelectedRow}
              limit={this.state.limit}
              page={this.state.page}
              rows={selected}
            />
            <TablePagination
              component="div"
              count={selected.length}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBoxEditSelectedRow
            handleAddError={this.props.handleAddError}
            handleSave={this.handleUpdateSequence}
            row_selected={row_selected}
            title_box="Selected Sequence Row"
          />
        </CardContent>
        <CardActions disableSpacing>
          <EditSequenceBottom classes={classes} />
        </CardActions>
      </Card>
    );
  }
}

EditSequences.propTypes = {
  classes: PropTypes.object,
  handleOpenEditView: PropTypes.func,
};
EditSequences.defaultProps = {
  handleOpenEditView: () => '',
  handleUpdateSequence: () => '',
  handleAddError: () => '',
};
export default withStyles(EditSequences, useStyles);
