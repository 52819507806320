import {
  PAGE_DATABASE_DISPLAYTYPE,
  PAGE_DATABASE_PATIENTS_IDS,
  PAGE_DATABASE_QUERY_LEVEL,
  PAGE_DATABASE_QUERY_PROJECT,
  PAGE_DATABASE_SERIES_SEQUENCE,
  PAGE_DATABASE_STUDY_SELECTED,
  PAGE_DATABASE_VIEWTYPE,
} from './types';

export const setDatabaseQueryProject = project => dispatch => {
  //
  dispatch({
    type: PAGE_DATABASE_QUERY_PROJECT,
    project,
  });
};
export const setDatabaseQueryLevel = level => dispatch => {
  //
  dispatch({
    type: PAGE_DATABASE_QUERY_LEVEL,
    level,
  });
};
export const setDatabaseView = view_database => dispatch => {
  dispatch({
    type: PAGE_DATABASE_VIEWTYPE,
    view_database,
  });
};
export const setDatabaseDisplay = display_database => dispatch => {
  //
  dispatch({
    type: PAGE_DATABASE_DISPLAYTYPE,
    display_database,
  });
};
export const setDatabaseStudySelected = study_selected => dispatch => {
  //
  dispatch({
    type: PAGE_DATABASE_STUDY_SELECTED,
    study_selected,
  });
};
export const setSeriesSequence = sequence => dispatch => {
  dispatch({
    type: PAGE_DATABASE_SERIES_SEQUENCE,
    sequence,
  });
};
export const setPatientsIds = ids_patients => dispatch => {
  dispatch({
    type: PAGE_DATABASE_PATIENTS_IDS,
    ids_patients,
  });
};
