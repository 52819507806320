import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

import { ProjectDetails } from 'components';
import {
  useStyle,
  CardProjectCenters,
  CardProjectMembers,
  CardProjectNotifications,
} from './components';

class TabProjectOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: props.notifications,
      maximize: '',
      items: [
        { name: 'details', GridComponent: ProjectDetails },
        { name: 'members', GridComponent: CardProjectMembers },
        { name: 'notifications', GridComponent: CardProjectNotifications },
        { name: 'center', GridComponent: CardProjectCenters },
      ],
    };
  }
  handleMaximize = event => {
    let { maximize } = this.state;
    if (maximize === event) {
      maximize = '';
    } else {
      maximize = event;
    }
    this.setState({ maximize });
  };
  handleDeletedMembers = members => {
    console.log('handleDeletedMembers = members');
    if (this.props.holder && this.props.holder.handleMemberDeleted) {
      this.props.holder.handleMemberDeleted(members.map(x => ({ id: x })));
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, project, isAdmin, height } = this.props;
    let { maximize, items } = this.state;
    let height_tables = 300;
    let md = 6;
    if (maximize !== '') {
      md = 12;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height, overflowY: 'auto' };
      if (maximize !== '') {
        height_tables = height - 370;
        style.padding = 0;
        style.maring = 0;
      }
      if (height_tables < 250) height_tables = 250;
    }
    const index = items.map(x => x.name).indexOf(maximize);
    if (index !== -1) {
      items = [items[index]];
    }
    // console.log({ maximize });
    return (
      <div className={classes.root} style={style}>
        <Grid
          alignItems="stretch"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={1}>
          {items.map(item => {
            const { name, GridComponent } = item;
            let isMaximize = maximize === name ? true : false;
            return (
              <Grid item key={`project-item-${name}`} md={md} xs={12}>
                <GridComponent
                  access_level={this.props.access_level}
                  admin={isAdmin}
                  dense
                  handleAddError={this.props.handleAddError}
                  handleDeletedMembers={this.handleDeletedMembers}
                  handleMaximize={this.handleMaximize}
                  height={height_tables}
                  isActive={this.props.isActive}
                  maximize={isMaximize}
                  nowrap
                  project={project}
                  projectChanged={this.props.projectChanged}
                  show
                  user={this.props.user}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

TabProjectOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  project: PropTypes.object.isRequired,
};
TabProjectOverview.defaultProps = {
  project: {
    id: -1,
    nanme: 'undefined',
  },
  access_level: 0,
};

export default withStyles(withRouter(TabProjectOverview), useStyle);
