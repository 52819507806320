import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';

import { logout } from 'redux-store/actions/loginActions';
const defaultProps = {
  height: 110,
  width: 100,
  bgcolor: 'background.paper',
  borderColor: 'white', //'#f4f6f8',
  m: 1,
  border: 6,
  // borderWidth: 6,
  style: { width: '8rem', height: '8rem' },
};

class RadiusProfileImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: null,
    };
  }

  render() {
    const { classes, user } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    if (user === undefined || !user) {
      this.props.logout();
      return null;
    }
    let image_avatar = user.photo;
    if (image_avatar === undefined || !image_avatar) {
      if (this.state.photo) {
        image_avatar = this.state.photo;
      } else {
        image_avatar = '/images/avatars/avatar_0.png';
      }
    }
    return (
      <Box className={classes.avatar} justifyContent="center">
        <Box borderRadius="50%" {...defaultProps}>
          <div>
            <img
              alt="..."
              className={imageClasses}
              src={image_avatar}
              style={{ width: 256, height: 'auto' }}
            />
          </div>
        </Box>
      </Box>
    );
  }
}

RadiusProfileImage.propTypes = {
  classes: PropTypes.object,
};

export default connect(null, { logout })(RadiusProfileImage);
