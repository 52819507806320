import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { v1 as uuid } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import { GetData, ServerErrors } from 'helpers';
import { QueryAccountCategoryProjects } from 'graphql/Accounts';
import { inputProps } from 'common';

class SearchCategoryProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      value: props.value ? props.value : null,
      projects: [],
      inputValue: props.default_input,
      group_name: props.group_name ? props.group_name : '',
    };
  }
  componentDidMount() {
    this.runLoadAccountProjects(null);
  }
  runLoadAccountProjects = name => {
    let { loading } = this.state;
    if (loading) {
      if (name) this.setState({ inputValue: name });
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryAccountCategoryProjects('Designer', name, 0, 15)
        .then(res => {
          const data = GetData(res);
          const { ok, projects, errors } = data.allAccountCategoryProjects;
          if (ok) {
            this.setState({
              loading: false,
              projects,
              inputValue: name ? name : '',
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleTextChange = (event, newInputValue) => {
    // if (
    //   newInputValue === null ||
    //   newInputValue === undefined ||
    //   newInputValue === ''
    // ) {
    //   newInputValue = this.props.default_input;
    // }
    if (newInputValue !== this.state.inputValue) {
      this.setState({ inputValue: newInputValue });
    }
  };
  handleOnChange = (event, newValue) => {
    if (newValue === null || newValue === undefined) {
      newValue = null;
    }
    this.props.handleProjectSelected(newValue);
    this.setState({ value: newValue });
  };
  handleChange = event => {
    const name = event.target.value;
    if (name !== this.state.inputValue) {
      this.runLoadAccountProjects(name);
    }
  };
  render() {
    let { inputValue, value, projects, loading } = this.state;
    return (
      <Autocomplete
        getOptionLabel={option => option.name}
        id={`group_map_${uuid()}`}
        inputValue={inputValue ? inputValue : ''}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={this.state.loading}
        onChange={this.handleOnChange}
        onClose={() => {
          if (projects && projects.length) {
            this.setState({ open: false });
          } else {
            this.setState({
              open: false,
              inputValue: this.props.default_input,
            });
          }
        }}
        onInputChange={this.handleTextChange}
        onOpen={() => {
          this.setState({ open: true });
        }}
        open={this.state.open}
        options={projects}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            label={this.props.label}
            onChange={this.handleChange}
          />
        )}
        sx={{ width: '100%' }}
        value={value}
      />
    );
  }
}

SearchCategoryProjects.propTypes = {
  default_input: PropTypes.string,
  group_name: PropTypes.string,
  handleProjectSelected: PropTypes.func,
  label: PropTypes.string,
};
SearchCategoryProjects.defaultProps = {
  default_input: 'None',
  handleProjectSelected: () => '',
};

export default SearchCategoryProjects;
