/* eslint-disable indent */
// import { IsInvalid } from 'helpers';

export const useStyles = (theme, props) => ({
  menu: {
    width: 365,
    maxWidth: 420,
    position: 'relative',
    borderRadius: '5px',
    background: '#ffffff',
    border: '1px solid #dedede',
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15)',
    paddingTop: 5,
    padding: 2,
    '&::after, &::before': {
      bottom: '100%',
      left: props
        ? props.notifications && props.notifications.length === 0
          ? '50%'
          : props.menu_arrow
          ? props.menu_arrow
          : '50%'
        : '50%',
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
    },
    '&::after': {
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#ffffff',
      borderWidth: '7px',
      marginLeft: '-7px',
    },
    '&::before': {
      borderColor: 'rgba(222, 222, 222, 0)',
      borderBottomColor: '#dedede',
      borderWidth: '8px',
      marginLeft: '-8px',
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  image: {
    width: '40px',
    height: '40px',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  inline: {
    display: 'inline',
  },
});
