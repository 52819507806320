import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogAccountClinical,
  ToolBarAccountClinical,
  columns_account_clinics,
} from './components';
import { TableGrid } from 'components';
import { rowsPerPageBig, IsInvalid } from 'helpers';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryAccountClinicalData } from 'graphql/DataClinics/utils_clinical_accounts';

class TabAccountClinics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      account_clinicals: [],
      new_accounts: [],
      saving: false,
      idUser: -1,
      open_dialog: false,
      selected: [],
      deleting: false,
    };
  }
  componentDidMount() {
    this.loadAccountClinicals();
  }
  componentDidUpdate() {
    this.loadAccountClinicals();
  }
  loadAccountClinicals = (force, newPage, newLimit) => {
    if (!this.props.isActive) return;
    const { user } = this.props;
    if (IsInvalid(user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryAccountClinicalData(-1, page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            account_clinicals,
            total,
            errors,
          } = data.accountClinicalData;
          if (ok) {
            this.setState({
              idUser: user.id,
              loading: false,
              account_clinicals,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getId = new_account => {
    if (IsInvalid(new_account)) return null;
    return `${new_account.idAccountType}-${new_account.idClinicalDataType}`;
  };
  handleAddClinicalDataType = (account_type, clinical_type) => {
    const { new_accounts, account_clinicals } = this.state;
    const list = new_accounts.concat(account_clinicals);
    const new_account = {
      id: list.length * -1,
      idAccountType: account_type.id,
      idClinicalDataType: clinical_type.id,
      account_type,
      clinical_type,
    };
    const index = list.map(x => this.getId(x)).indexOf(this.getId(new_account));
    if (index !== -1) {
      this.props.handleAddError(
        `The type: ${account_type.description} and clinical type: ${clinical_type.description} are already here`
      );
    } else {
      new_accounts.unshift(new_account);
      this.setState({ new_accounts, open_dialog: false });
    }
  };
  render() {
    const { account_clinicals, loading, new_accounts } = this.state;
    return (
      <React.Fragment>
        <ToolBarAccountClinical
          getId={this.getId}
          handleAddError={this.props.handleAddError}
          loading={loading}
          new_accounts={new_accounts}
          onOpenDialog={() => this.setState({ open_dialog: true })}
          onReloadTable={() => this.loadAccountClinicals(true)}
          saving={this.state.saving}
          setState={value => this.setState({ ...value })}
          state={this.state}
          user={this.props.user}
        />
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_account_clinics}
          height={this.props.height - 160}
          loading={loading}
          onPageChange={page =>
            this.loadAccountClinicals(true, page, this.state.limit)
          }
          onRowsPerPageChange={limit =>
            this.loadAccountClinicals(true, 0, limit)
          }
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={new_accounts.concat(account_clinicals)}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogAccountClinical
          handleAddClinicalType={this.handleAddClinicalDataType}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_dialog: false })}
          isActive={this.props.isActive}
          open={this.state.open_dialog}
        />
      </React.Fragment>
    );
  }
}

TabAccountClinics.propTypes = {
  classes: PropTypes.object,
};

export default TabAccountClinics;
