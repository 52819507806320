import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_MS_CASE = gql`
  query($idPatient: LongLong!) {
    msCase(idPatient: $idPatient) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        patient {
          id
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
        comorbidities {
          id
          idPatient
          description
          group
          subgroup_organ
          createdAt
          updatedAt
        }
        medications {
          id
          idPatient
          date
          description
          active_ingredient
          active_ingredient_unknown
          createdAt
          updatedAt
        }
        edsses {
          id
          idPatient
          sequence_number
          date
          score
          fs1_visual
          fs2_brainstem
          fs3_pyramidal
          fs4_cerebellar
          fs5_sensory
          fs6_bowel_bladder
          fs7_cerebral
          createdAt
          updatedAt
        }
        immunotherapies {
          id
          idPatient
          sequence_number
          name
          start_date
          stop_date
          stop_reason
          stop_reason_infection_susceptibility_to_infection
          stop_reason_insufficient_efficacy
          stop_reason_intolerance_side_effects
          stop_reason_patient_request_external_reasons
          stop_reason_safety
          stop_reason_therapy_change
          createdAt
          updatedAt
        }
        msfcs {
          id
          idPatient
          sequence_number
          date
          walk_test_aid
          walk_test_time_1
          walk_test_time_2
          peg_test_handedness
          peg_test_right_time_1
          peg_test_right_time_2
          peg_test_left_time_1
          peg_test_left_time_2
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const QueryMsCase = idPatient => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_CASE),
    variables: {
      idPatient,
    },
  });
};

const QUERY_MS_PATIENTS = gql`
  query($page: Int, $limit: Int, $anonymize: Int) {
    msPatients(page: $page, limit: $limit, anonymize: $anonymize) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryMsPatients = (page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_PATIENTS),
    variables: {
      page,
      limit,
      anonymize,
    },
  });
};
