export { default } from './TabEncryptionKeys';
const getColumns = () => {
  const columns = [];
  for (let i = 0; i < 10; i++) {
    columns.push({
      field: `colum_${i}`,
      headerName: `Column ${i}`,
      width: 75,
      align: 'left',
      edit: true,
      style: {
        width: 50,
        minWidth: 70,
        maxWidth: 100,
      },
    });
  }
};

export const colums = getColumns();
