import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

import { withStyles } from 'tss-react/mui';

import useStyles from '../components/Styles';
import { MainTableToolbar } from '../components';
import { columns_files, columns_series } from './components';
import { rowsPerPageBig } from 'helpers';
import TableGrid from '../TableGrid';

class TableVariableMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
    };
  }

  isSelected = type => {
    const { selected } = this.props;
    const index = selected.map(x => x.id).indexOf(type.id);
    return index !== -1;
  };
  render() {
    const { classes, height, selected, hide_header, isSeries } = this.props;
    let style = {};
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    // let count = mappings.length;
    // if (total) {
    //   count = total;
    // }
    let columns = [];
    if (isSeries) columns = columns_series;
    else columns = columns_files;
    let title = 'File Type Mapping';
    if (isSeries) title = 'Series Type Mapping';
    let ComponentToolbar = (
      <MainTableToolbar
        handleClearSelected={this.props.handleClearSelected}
        handleDeleteSelected={this.props.handleDeleteSelected}
        handleReloadTable={this.props.handleReloadTable}
        loading={this.props.deleting}
        loading_table={this.props.loading}
        numSelected={selected.length}
        title={title}
        transform
      />
    );
    if (hide_header) ComponentToolbar = null;
    return (
      <Paper className={classes.root}>
        {ComponentToolbar}
        <div className={classes.tableWrapper} style={{ ...style }}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={selected}
            grid
            headers={columns}
            height={this.props.height}
            limit={this.props.limit}
            loading={this.props.loading}
            onPageChange={page => this.props.handleChangePage(page)}
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={limit =>
              this.props.handleChangeRowsPerPage(limit)
            }
            onSelectedChanged={selected =>
              this.props.onSelectedChanged(selected)
            }
            page={this.props.page}
            rows={this.props.mappings}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </div>
      </Paper>
    );
  }
}

TableVariableMaps.propTypes = {
  classes: PropTypes.object,
};
TableVariableMaps.defaultProps = {
  deleting: false,
  loading: false,
  mappings: [],
  selected: [],
  disable_splice: false,
  handleSelectedRow: () => console.log('handleSelectedRow'),
  handleReloadTable: () => console.log('handleReloadTable'),
  handleChangePage: (event, newPage) =>
    console.log('handleChangePage: ', newPage),
  handleChangeRowsPerPage: event =>
    console.log('handleChangeRowsPerPage: ', event.target.value),
  handleSelectedClick: () => console.log('handleSelectedClick'),
  handleDeleteSelected: () => console.log('handleDeleteSelected'),
  handleClearSelected: () => '',
};
export default withStyles(TableVariableMaps, useStyles);
