import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { columns_sessions } from 'views/Administrator/components/AdminDefault/TabUsers/components/UserView/components/UserViewRight/components/UserViewSessions/Styles';

class TabSessionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected, height, loading, sessions } = this.props;
    return (
      <TableGrid
        checkboxSelection
        dense
        external_selected={selected}
        headers={columns_sessions}
        height={height}
        loading={loading}
        onPageChange={page =>
          this.props.loadSessions(null, page, this.props.limit)
        }
        onRowsPerPageChange={limit => this.props.loadSessions(null, 0, limit)}
        onSelectedChanged={selected => this.props.handleSelected(selected)}
        page={this.props.page}
        paginationMode="server"
        rowCount={this.props.total}
        rows={sessions}
        rowsPerPage={this.props.limit}
        rowsPerPageOptions={rowsPerPageBig}
      />
    );
  }
}

TabSessionsTable.propTypes = {
  classes: PropTypes.object,
};

export default TabSessionsTable;
