import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { styled } from '@mui/material/styles';
const Input = styled(MuiInput)`
  width: 42px;
`;
class VolumeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSliderChange = (event, newValue) => {
    this.props.setValue(newValue);
  };
  handleInputChange = event => {
    this.props.setValue(
      event.target.value === '' ? '' : Number(event.target.value)
    );
  };
  handleBlur = event => {
    const value = event.target.value;
    if (value < this.props.min) {
      this.props.setValue(0);
    } else if (value > this.props.max) {
      this.props.setValue(this.props.max);
    }
  };
  render() {
    const { value } = this.props;
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item>
          <VolumeUp />
        </Grid>
        <Grid item xs>
          <Slider
            aria-labelledby="input-slider"
            max={this.props.max}
            min={this.props.min}
            onChange={this.handleSliderChange}
            value={typeof value === 'number' ? value : 0}
          />
        </Grid>
        <Grid item>
          <Input
            inputProps={{
              step: 1,
              max: this.props.max,
              min: this.props.min,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
            // onBlur={handleBlur}
            onChange={this.handleInputChange}
            size="small"
            value={value}
          />
        </Grid>
      </Grid>
    );
  }
}

VolumeSlider.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  setValue: PropTypes.func,
  value: PropTypes.number,
};
VolumeSlider.defaultProps = {
  value: 0,
  min: 0,
  max: 100,
  setValue: () => '',
};

export default VolumeSlider;
