import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_STUDY_DETAILS = gql`
  mutation($id_studies: [LongLong!]!, $variables: InputStudyInformation!) {
    updateStudySeriesDetails(id_studies: $id_studies, variables: $variables) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateStudiesDetails = (id_studies, variables) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_STUDY_DETAILS),
    variables: { id_studies, variables },
  });
};
