import React from 'react';
import { Paper } from '@mui/material';

export default function CustomPaper({ children }) {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
          fontWeight: 500,
          fontSize: 12,
        }
      }}
    >
      {children}
    </Paper>
  );
}
