import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Divider, Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { ButtonFilterList } from './components';
import { FilterContainer, MenuFilters } from 'components/Basics';
import { getNumberFilters } from 'components/Basics/FilterContainer';

class ButtonFilterSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      filter_name: null,
      filter_value: '',
    };
    this.holder = {
      reset: null,
    };
    const { holder } = props;
    if (holder) {
      holder.resetFilters = () => this.handleReset();
    }
  }
  handleClick = event => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleHideColumn = () => {
    this.setState({ anchorEl: null });
  };
  handleSearchFilterChanged = event => {
    event.preventDefault();
    event.stopPropagation();
    const { filter_name } = this.state;
    const { header } = this.props;
    let value = null;
    let filter_value = event.target.value;
    if (filter_name) {
      value = {
        key: filter_value,
        type: this.state.filter_name,
        header,
      };
    } else {
      filter_value = '';
    }
    this.props.onSearchFilterChanged(value);
    this.setState({ filter_value });
  };
  handleFilterSelected = filter_name => {
    if (!filter_name || filter_name === '') {
      this.props.onSearchFilterChanged(null);
      this.setState({ filter_name: 'Choose One', filter_value: '' });
    } else {
      const { header } = this.props;
      let value = null;
      if (filter_name) {
        value = {
          key: this.state.filter_value,
          type: this.state.filter_name,
          header,
        };
      }
      if (
        this.props.filter &&
        this.props.filter.type !== this.state.filter_name
      ) {
        this.props.onSearchFilterChanged(value);
      }
      if (this.state.filter_name !== filter_name) {
        this.setState({ filter_name });
      }
    }
  };
  handleReset = () => {
    if (this.holder.reset) this.holder.reset();
    this.props.onClickReset();
  };
  handleSearch = () => {
    this.setState({ anchorEl: null });
    this.props.handleSearch();
  };
  render() {
    const { headerName, disabled_filter, filters, view } = this.props;
    const filtered = getNumberFilters(filters);
    let open = Boolean(this.state.anchorEl);
    let IconFiltered = null;
    let width = '100%';
    let maxWidth = 20;
    if (!disabled_filter) {
      width = '50%';
      maxWidth = 40;
      IconFiltered = (
        <Grid item style={{ width, padding: 0, margin: 0 }}>
          <FilterAltIcon style={{ paddingTop: 5 }} />
        </Grid>
      );
    }
    // console.log({ open, filters });
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end"
          style={{ maxWidth, padding: 0, margin: 0 }}>
          {IconFiltered}
          <Grid item style={{ width, padding: 0, margin: 0 }}>
            <ButtonFilterList
              filtered={filtered}
              handleAddError={this.props.handleAddError}
              handleClick={this.handleClick}
              headerName={headerName}
              open={open}
            />
          </Grid>
        </Grid>
        <MenuFilters
          anchorEl={this.state.anchorEl}
          handleClose={this.handleClose}
          open={open}>
          <FilterContainer
            filters={filters}
            handleAddError={this.props.handleAddError}
            handleSearch={this.handleSearch}
            holder={this.holder}
            onFilterChanged={this.props.onFilterChanged}
            show_user={this.props.show_user}
            view={view}
          />
          <Divider style={{ paddingBottom: 5 }} />
          <MenuItem
            onClick={() => this.handleReset()}
            onKeyDown={e => e.stopPropagation()}>
            Reset
          </MenuItem>
        </MenuFilters>
      </React.Fragment>
    );
  }
}

ButtonFilterSearch.propTypes = {
  classes: PropTypes.object,
};
ButtonFilterSearch.defaultProps = {
  disabled_filter: true,
  filters: {},
  filter: null,
  onClick: () => '',
  onSortHeader: () => '',
  onClickReset: () => '',
  onSearchFilterChanged: () => '',
  onFilterChanged: () => '',
  handleSearch: () => '',
};
export default ButtonFilterSearch;
