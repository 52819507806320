import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { ListModalities } from 'components/Basics/FilterContainer';
class ContentModalities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, modalities } = this.props;
    return (
      <Grid container spacing={0} style={{ padding: 0, margin: 0 }}>
        {ListModalities.map(({ name: modality }) => (
          <Grid
            item
            key={`checkbox-grid-${modality}`}
            md={5}
            sm={4}
            style={{ padding: 0, margin: 0 }}
            xs={4}>
            <FormControlLabel
              className={classes.modality}
              control={
                <Checkbox
                  checked={modalities.indexOf(modality) !== -1}
                  className={classes.check_container}
                  onChange={this.props.handleModalityChecked.bind(
                    this,
                    `${modality}`
                  )}
                  size="small"
                  style={{ padding: 0, margin: 0, paddingRight: 10 }}
                  value={`${modality}`}
                />
              }
              label={modality}
              style={{ padding: 5, paddingTop: 0, paddingBottom: 0, margin: 0 }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

ContentModalities.propTypes = {
  classes: PropTypes.object,
};

export default ContentModalities;
