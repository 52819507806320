import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Card, CardHeader, Divider, Grid } from '@mui/material';

import { projectCreateSettingsCenters } from 'redux-store/actions/projectActions';
import { SearchCentersInputs, TableCenters, TextFieldCustom } from 'components';
import useStyles from './Style';
import { IsInvalid } from 'helpers';

class ProjectCreateCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_create: false,
      limit: 50,
      loading: false,
      page: 0,
      selected: [],
      code: '',
    };
    this.fileInput = React.createRef();
    this.holder = {
      getSelected: () => this.state.selected,
    };
  }

  handleCloseDialog = () => {
    this.setState({ open_create: false });
  };
  handleImportOffer = () => {
    this.fileInput.current.click();
  };

  handleDeleteSelected = () => {
    const {
      project_settings: { centers },
      projectCreateSettingsCenters,
    } = this.props;
    const { selected } = this.state;
    if (!selected.length || !centers) {
      return;
    }

    let new_centers = [];
    for (let i = 0; i < centers.length; i++) {
      const index = selected.map(x => x.id).indexOf(centers[i].id);
      if (index === -1) {
        new_centers.push(centers[i]);
      }
    }
    this.setState({ selected: [], total: new_centers.length });
    projectCreateSettingsCenters(new_centers);
  };
  handleAddCenter = center => {
    if (IsInvalid(center) || !Object.keys(center).length) {
      this.props.handleAddError('You need to select a center first');
      return;
    }
    const { code } = this.state;
    if (IsInvalid(code) || code.trim() === '') {
      this.props.handleAddError('You need to setup the code first');
      return;
    }
    const {
      project_settings: { centers },
      projectCreateSettingsCenters,
    } = this.props;
    let index = centers.map(x => x.id).indexOf(center.id);
    if (index === -1) {
      index = centers
        .map(x => x.code)
        .filter(x => x)
        .indexOf(code);
      if (index !== -1) {
        this.props.handleAddError(
          `The code (${code}) is alreday here, use another code`
        );
        return;
      }
      center.code = code;
      centers.unshift(center);
      projectCreateSettingsCenters(centers);
      this.setState({ total: centers.length, code: '' });
    } else if (center) {
      this.props.handleAddError(
        `The center (${center.name}) is alreday here, use another center`
      );
      return;
    }
  };
  handleChangePage = (event, page) => {
    event.preventDefault();
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };

  render() {
    const {
      classes,
      project_settings: { centers },
      show_header,
    } = this.props;

    return (
      <div>
        <Card className={classes.root}>
          {show_header && (
            <CardHeader
              subheader="List of project centers"
              title="Project Centers"
            />
          )}
          {show_header && <Divider />}
          <Grid
            alignItems="center"
            className={classes.toolbar}
            container
            direction="column"
            justifyContent="space-evenly"
            spacing={1}>
            <Grid
              alignItems="center"
              container
              direction="row"
              item
              justifyContent="space-between"
              spacing={1}
              xs={10}>
              <Grid item xs={3.5}>
                <TextFieldCustom
                  id="code-center"
                  label="Center Code"
                  onChange={event =>
                    this.setState({ code: event.target.value })
                  }
                  required
                  size="small"
                  value={this.state.code}
                  variant="outlined"
                />
              </Grid>
              <Grid item sx={{ paddingBottom: 1 }} xs>
                <SearchCentersInputs
                  disabled={this.state.code ? false : true}
                  handleAddCenter={this.handleAddCenter}
                  no_icon
                  placeholder="Search centers..."
                />
              </Grid>
            </Grid>
            <div className={classes.table}>
              <TableCenters
                // deleting={this.state.deleting}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleDeleteSelected={this.handleDeleteSelected}
                // handleReloadTable={() => this.queryCenters(idProject)}
                handleSetSelected={this.handleSetSelected}
                holder={this.holder}
                limit={this.state.limit}
                loading={this.state.loading}
                page={this.state.page}
                rows={centers}
                selected={this.state.selected}
                total={this.state.total}
              />
            </div>
          </Grid>
          <Divider />
        </Card>
      </div>
    );
  }
}

ProjectCreateCenters.propTypes = {
  className: PropTypes.string,
  projectCreateSettingsCenters: PropTypes.func.isRequired,
  project_settings: PropTypes.object.isRequired,
};

ProjectCreateCenters.defaultProps = {
  show_header: true,
};

const mapStateToProps = state => ({
  project_settings: state.projects.project_settings,
});

export default connect(mapStateToProps, {
  projectCreateSettingsCenters,
})(withStyles(ProjectCreateCenters, useStyles));
