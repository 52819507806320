import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Autocomplete } from '@mui/material';
const filterOptions = [
  'Choose One',
  'Equal',
  'Does Not Equal',
  'Begins with',
  'Contains',
  'End with',
];
class MenuFilterIItem extends React.Component {
  constructor(props) {
    super(props);
    let defaultValue = filterOptions[0];
    if (props.filter) {
      defaultValue = props.filter;
    }
    const index = filterOptions.indexOf(defaultValue);
    if (index === -1) {
      defaultValue = filterOptions[0];
    }
    this.state = {
      defaultValue,
    };
  }

  render() {
    const {
      onChange,
      filter,
      disabled_filter,
      handleSearchFilterChanged,
      text_filter,
    } = this.props;
    const { defaultValue } = this.state;
    return (
      <Grid container spacing={1} style={{ padding: 5 }}>
        <Grid item>
          <Autocomplete
            defaultValue={defaultValue}
            disablePortal
            id="combo-box-filter-options"
            onChange={(event, value) => onChange(value)}
            onInputChange={(event, newInputValue) => {
              onChange(newInputValue);
            }}
            options={filterOptions}
            renderInput={params => <TextField {...params} label="Operator" />}
            size="small"
            sx={{ width: 170, paddingTop: 1 }}
            value={filter ? filter : defaultValue}
          />
        </Grid>
        <Grid item>
          <TextField
            disabled={disabled_filter}
            fullWidth
            id="input-with-icon-textfield"
            InputLabelProps={{
              shrink: true,
            }}
            label="Value to Filter"
            onChange={handleSearchFilterChanged}
            onKeyDown={e => e.stopPropagation()}
            style={{ paddingTop: 0, marginTop: 0 }}
            value={text_filter ? text_filter : ''}
            variant="standard"
          />
        </Grid>
      </Grid>
    );
  }
}

MenuFilterIItem.propTypes = {
  filter: PropTypes.object,
  text_filter: PropTypes.string,
};
MenuFilterIItem.defaultProps = {
  filter: null,
  text_filter: '',
};
export default MenuFilterIItem;
