import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  CreateMappingDocker,
  EditMappingDocker,
  GridDockerToolBar,
  TableMappingDockers,
} from './components';
import {
  MutationRemoveMappingTable,
  QueryDockerMappings,
} from 'graphql/Dockers/utils_docker_mapping_tables';
import { GetData, ServerErrorsString } from 'helpers';
const useStyles = theme => ({
  toolbar: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});
class TabMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      docker_mappings: [],
      selected: [],
      total: 0,
      open_edit: false,
      open_create: false,
    };
  }

  componentDidMount() {
    this.handleUpdateDockerMappings();
  }

  handleUpdateDockerMappings = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerMappings()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, docker_mappings } = data.allDockerMappings;
          if (ok) {
            this.setState({ loading: false, docker_mappings });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getSelectedIds = () => {
    const selected = this.state.selected;
    let ids = [];
    for (let i = 0; i < selected.length; i++) {
      ids.push(selected[i].id);
    }
    return ids;
  };
  mutationDeleteSelected = () => {
    const { deleting, selected } = this.state;
    const ids = this.getSelectedIds();
    if (deleting || !ids.length) return;
    this.setState({ deleting: true });
    (async () => {
      MutationRemoveMappingTable(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeMappingTables;
          if (ok) {
            let { docker_mappings } = this.state;
            const ids = selected.map(x => x.id);
            docker_mappings = docker_mappings.filter(
              x => ids.indexOf(x.id) === -1
            );
            this.setState({ deleting: false, selected: [], docker_mappings });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleNewDockerImage = image => {
    const { images } = this.state;
    const index = images.map(x => x.idImage).indexOf(image.idImage);
    if (index === -1) {
      images.unshift(image);
    } else {
      this.props.handleAddError(
        `Docker image is already here (${image.idImage})`
      );
    }
    this.setState({ create_docker: false, images });
  };
  // handleActionResults = actions => {
  //   let { images, selected } = this.state;

  //   let count_containers = 0;
  //   for (let i = 0; i < actions.length; i++) {
  //     const { idImage, error, container } = actions[i];
  //     let index = images.map(x => x.idImage).indexOf(idImage);

  //     if (index !== -1 && error && error !== '') {
  //       this.props.handleAddError(error);
  //     } else {
  //       selected = selected.filter(x => x.idImage !== idImage);
  //       if (container) {
  //         this.props.handleAddNewContainer(container);
  //         count_containers++;
  //         images[index].Status = 'running';
  //       }
  //     }
  //   }
  //   this.setState({ selected, images });
  //   if (count_containers) {
  //     this.props.handleTabValue({ preventDefault: () => '' }, 0);
  //   }
  // };

  render() {
    const { loading, docker_mappings, open_create, open_edit } = this.state;
    if (open_create) {
      return (
        <CreateMappingDocker
          handleAddError={this.props.handleAddError}
          handleOpenCreateDocker={() => this.setState({ open_create: false })}
        />
      );
    }
    if (open_edit) {
      console.log('selected', this.state.selected);
      return (
        <EditMappingDocker
          handleAddError={this.props.handleAddError}
          handleOpenCreateDocker={() => this.setState({ open_edit: false })}
          selected={this.state.selected}
        />
      );
    }
    return (
      <Grid container spacing={1} style={{ padding: 20, width: '100%' }}>
        <Grid item xs={12}>
          <GridDockerToolBar
            handleDeleteSelected={this.mutationDeleteSelected}
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReload={() => this.handleUpdateDockerMappings()}
            loading={loading}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item style={{ minHeight: 400 }} xs={12}>
          <TableMappingDockers
            checkboxSelection
            external_selected={this.state.selected}
            onSelectedChanged={selected => this.setState({ selected })}
            rows={docker_mappings}
          />
        </Grid>
      </Grid>
    );
  }
}

TabMapping.propTypes = {
  classes: PropTypes.object,
};
TabMapping.defaultProps = {
  handleAddNewContainer: () => '',
};
export default withStyles(TabMapping, useStyles);
