import {
  dangerBoxShadow,
  dangerColor,
  infoBoxShadow,
  infoColor,
  primaryBoxShadow,
  primaryColor,
  roseBoxShadow,
  roseColor,
  successBoxShadow,
  successColor,
  warningBoxShadow,
  warningColor,
} from '../../../../variables';
export const useStyles = theme => ({
  dropdownDividerItem: {
    margin: '5px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    overflow: 'hidden',
  },
  dropdownHeader: {
    display: 'block',
    padding: '0.1875rem 1.25rem',
    fontSize: '0.75rem',
    lineHeight: '1.428571',
    color: '#777',
    whiteSpace: 'nowrap',
    fontWeight: 'inherit',
    marginTop: '10px',
    minHeight: 'unset',
    '&:hover,&:focus': {
      backgroundColor: 'transparent',
      cursor: 'auto',
    },
  },
  menuList: {
    padding: '0',
    minWidth: 150,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dropdownItem: {
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: 'fit-content',
    color: '#333',
    whiteSpace: 'nowrap',
    minHeight: 'unset',
  },
  noLiPadding: {
    padding: '0',
  },
  dropdownItemRTL: {
    textAlign: 'right',
  },
  blackHover: {
    '&:hover': {
      boxShadow:
        '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4)',
      backgroundColor: '#212121',
      color: '#fff',
    },
  },
  primaryHover: {
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      ...primaryBoxShadow,
    },
  },
  infoHover: {
    '&:hover': {
      backgroundColor: infoColor,
      color: '#FFFFFF',
      ...infoBoxShadow,
    },
  },
  successHover: {
    '&:hover': {
      backgroundColor: successColor,
      color: '#FFFFFF',
      ...successBoxShadow,
    },
  },
  warningHover: {
    '&:hover': {
      backgroundColor: warningColor,
      color: '#FFFFFF',
      ...warningBoxShadow,
    },
  },
  dangerHover: {
    '&:hover': {
      backgroundColor: dangerColor,
      color: '#FFFFFF',
      ...dangerBoxShadow,
    },
  },
  roseHover: {
    '&:hover': {
      backgroundColor: roseColor,
      color: '#FFFFFF',
      ...roseBoxShadow,
    },
  },
  button_dropdown: {
    margin: 2,
    fontWeight: 400,
    fontSize: '13px',
    zIndex: 105000,
  },
  button_white: {
    color: 'white',
  },
  button_black: {
    color: 'black',
  },
});
