import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DialogQuerySettings } from './components';

class ButtonQuerySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.holder = {
      resetChange: null,
    };
  }

  handleClick = run => {
    const prev_state = this.state.open;
    this.setState({ open: !prev_state });
    if (run && prev_state) {
      this.holder.resetChange();
      this.props.handleRunQuery();
    }
  };

  render() {
    return (
      <React.Fragment>
        <IconButton
          aria-controls="long-menu"
          aria-haspopup="true"
          aria-label="more"
          onClick={this.handleClick}
          size="large">
          <SettingsIcon />
        </IconButton>
        <DialogQuerySettings
          handleClose={this.handleClick}
          holder={this.holder}
          open={this.state.open}
        />
      </React.Fragment>
    );
  }
}

ButtonQuerySettings.propTypes = {
  classes: PropTypes.object,
};

export default ButtonQuerySettings;
