import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_CREATE_FOLDER = gql`
  mutation createFolder($idParent: LongLong, $name: String!) {
    createFolder(idParent: $idParent, name: $name) {
      ok
      warnings
      errors {
        path
        message
      }
      folder {
        idFolder
        idParent
        link {
          idFolder
          idFolderContent
          code
        }
        download_link
        folders_paths {
          idFolder
          name
        }
        Size
        name
        total_files
        total_sub_folders
        Owner {
          id
          FullName
        }
        ParentFolder {
          idParent
          name
          link {
            idFolder
            idFolderContent
            code
          }
          download_link
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const CreateFolderMutation = (idParent, name) => {
  const query = {
    name,
  };
  if (idParent) {
    query.idParent = idParent;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_FOLDER),
    variables: query,
  });
};

const MUTATION_DELETE_FOLDER_FILES = gql`
  mutation deleteFolders(
    $foldersIds: [Int!]
    $contentIds: [Int!]
    $delete_series: Boolean
  ) {
    deleteFolders(
      foldersIds: $foldersIds
      contentIds: $contentIds
      delete_series: $delete_series
    ) {
      ok
      warnings
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteFoldersMutation = (
  foldersIds,
  contentIds,
  delete_series = false
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_FOLDER_FILES),
    variables: { foldersIds, contentIds, delete_series },
  });
};

const REMOVE_SHARED_FOLDERS = gql`
  mutation removeSharedFolders($foldersIds: [Int!], $contentIds: [Int!]) {
    removeSharedFolders(foldersIds: $foldersIds, contentIds: $contentIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const RemoveSharedMutation = (foldersIds, contentIds) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(REMOVE_SHARED_FOLDERS),
    variables: { foldersIds, contentIds },
  });
};

const RENAME_FOLDER = gql`
  mutation renameFolder($idFolder: LongLong!, $newname: String!) {
    renameFolder(idFolder: $idFolder, newname: $newname) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const RenameFolderMutation = (idFolder, newname) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(RENAME_FOLDER),
    variables: { idFolder, newname },
  });
};
