import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { series_full_no_thumb } from './utils_series_query';

export const QUERY_SERIES_SEQUENCES_MAPPINGS = gql`
  query($ids_sequences: [LongLong!], $name: String, $page: Int, $limit: Int) {
    seriesSequenceMappings(
      ids_sequences: $ids_sequences
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      total
      errors {
        path
        message
      }
      mappings {
        id

        idSequence
        idSeries

        Series {
          idUser
          idSeries
          idStudy
          SeriesDescription
          ImageMr {
            ProtocolName
            SequenceName
            MRAcquisitionType
          }
          Thumbnail
          Size
          updatedAt
        }
        Sequence {
          idSequence
          name
          updatedAt
        }

        updatedAt
        createdAt
      }
    }
  }
`;
export const QUERY_SERIES_SEQUENCES_MAPPINGS_FULL = gql`
  query($ids_sequences: [LongLong!], $name: String, $page: Int, $limit: Int) {
    seriesSequenceMappings(
      ids_sequences: $ids_sequences
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      total
      errors {
        path
        message
      }
      mappings {
        id

        idSequence
        idSeries

        Series {
         ${series_full_no_thumb}
        }
        Sequence {
          idSequence
          name
          updatedAt
        }

        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySeriesSequenceMappings = (inputs, page, limit, full) => {
  const variables = {
    ...inputs,
  };
  if (limit) {
    variables.limit = limit;
    variables.page = page;
  }
  let query = QUERY_SERIES_SEQUENCES_MAPPINGS;
  if (full) {
    query = QUERY_SERIES_SEQUENCES_MAPPINGS_FULL;
  }
  console.log('QuerySeriesSequenceMappings: ', { variables });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(query),
    variables,
  });
};

export const MUTATION_DELETE_SERIES_SEQUENCES_MAPPINGS = gql`
  mutation($ids: [LongLong!]!) {
    deleteSeriesSeuenceMappings(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteSeriesSeuenceMappings = ids => {
  console.log('MutationDeleteSeriesSeuenceMappings: ', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SERIES_SEQUENCES_MAPPINGS),
    variables: {
      ids,
    },
  });
};

const QUERY_SERIES_SEQUENCES_UNMAPPED = gql`
  query($name: String, $page: Int, $limit: Int) {
    seriesSequenceUnMapped(name: $name, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      ListSeries {
        idUser
        idSeries
        idStudy
        SeriesDescription
        ContrastBolusAgent
        Modality
        ImageMr {
          ProtocolName
          SequenceName
          MRAcquisitionType
        }
        Size
        SeriesDate
        NumberOfSeriesRelatedInstances
        center {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySeriesSequenceUnMapped = (name, page, limit) => {
  const variables = {};
  if (name !== undefined && name !== '') {
    variables.name = name;
  }
  if (limit !== undefined && limit) {
    variables.limit = limit;
    variables.page = page;
  }
  console.log('QuerySeriesSequenceUnMapped: ', { variables });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_SEQUENCES_UNMAPPED),
    variables,
  });
};
const MUTATION_SERIES_SEQUENCES_MAP = gql`
  mutation($inputs: [InSeriesSequence!]!) {
    addSeriesSequences(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationSeriesSequenceMap = inputs => {
  console.log('MutationSeriesSequenceMap: ', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SERIES_SEQUENCES_MAP),
    variables: {
      inputs,
    },
  });
};
