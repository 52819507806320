import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ANATOMICAL_REGIONS = gql`
  query($name: String, $page: Int, $limit: Int) {
    AnatomicalRegions(name: $name, page: $page, limit: $limit) {
      idRegion
      name
      updatedAt
    }
  }
`;
export const QueryAnatomicalRegions = (name, page, limit) => {
  const variables = {};
  if (limit) {
    variables.page = page;
    variables.limit = limit;
  }
  if (name && name !== '') {
    variables.name = name;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ANATOMICAL_REGIONS),
    variables: variables,
  });
};

const QUERY_IMAGE_FOCUS = gql`
  query($name: String, $page: Int, $limit: Int) {
    ImageFocus(name: $name, page: $page, limit: $limit) {
      idFocus
      name
      updatedAt
    }
  }
`;
export const QueryImageFocus = (name, page, limit) => {
  const variables = {};
  if (limit) {
    variables.page = page;
    variables.limit = limit;
  }
  if (name && name !== '') {
    variables.name = name;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_IMAGE_FOCUS),
    variables: variables,
  });
};

const QUERY_PATHOLOGIES = gql`
  query($name: String, $page: Int, $limit: Int) {
    Pathologies(name: $name, page: $page, limit: $limit) {
      idPathology
      name
      updatedAt
    }
  }
`;
export const QueryPathologies = (name, page, limit) => {
  const variables = {};
  if (limit) {
    variables.page = page;
    variables.limit = limit;
  }
  if (name && name !== '') {
    variables.name = name;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATHOLOGIES),
    variables: variables,
  });
};

const QUERY_INVESTIGATIVED_SIDE = gql`
  query InvestigatedSides($name: String, $page: Int, $limit: Int) {
    InvestigatedSides(name: $name, page: $page, limit: $limit) {
      idSide
      name
      updatedAt
    }
  }
`;
export const InvestigatedSides = (name, page, limit) => {
  const variables = {};
  if (limit) {
    variables.page = page;
    variables.limit = limit;
  }
  if (name && name !== '') {
    variables.name = name;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_INVESTIGATIVED_SIDE),
    variables: variables,
  });
};

const MUTATION_SHARE_SERIES = gql`
  mutation($ids: [Int!]!, $objects: [InputCombineObject!]!) {
    ShareSeries(ids: $ids, objects: $objects) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const ShareSeries = (ids, objects) => {
  const variables = { ids, objects };
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SHARE_SERIES),
    variables: variables,
  });
};

const MUTATION_UPDATE_SERIES_COUNT = gql`
  mutation($ids: [LongLong!]!) {
    updateSeriesCounts(ids: $ids) {
      ok
      errors {
        path
        message
      }
      Series {
        idStudy
        idSeries
        NumberOfSeriesRelatedInstances
      }
    }
  }
`;
export const MutationUpdateSeriesCounts = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SERIES_COUNT),
    variables: {
      ids,
    },
  });
};
