import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';
import { IsInvalid } from 'helpers';

class CheckBoxText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { style_check, size } = this.props;
    if (IsInvalid(style_check) && size === 'small')
      style_check = { paddingTop: 0, paddingBottom: 0 };
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.props.checked}
            name={this.props.name}
            onChange={this.props.onChange}
            style={style_check}
          />
        }
        label={this.props.label}
        size={size}
      />
    );
  }
}

CheckBoxText.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
CheckBoxText.defaultProps = {
  checked: false,
  onChange: () => '',
  label: 'Name check',
  size: 'medium',
  style_check: undefined,
};

export default CheckBoxText;
