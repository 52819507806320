import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
} from 'components';
import { styleToolbar } from 'common';
class ToolBarMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      selected,
      uploading,
      disabled,
      type,
      enable_upload,
    } = this.props;
    let disable_type = true;
    if (type) {
      disable_type = false;
    }
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pl={0}
          pr={2}
          pt={0}>
          <Grid item mt={1} xs={3}>
            <ButtonGroup
              className={classes.root}
              disabled={disabled}
              exclusive="false"
              size="small"
              value="center">
              <Button onClick={this.props.handleOpenCreate}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <Button
                disabled={!selected.length}
                onClick={this.props.handleOpenEdit}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
              <ButtonDownloadType
                disabled={disable_type}
                key="variable"
                type={type}
              />
              <ButtonFileUpload
                disabled={!enable_upload}
                handleSelectFile={this.props.handleUpload}
                uploading={uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReload}
              hide_reload={this.props.hide_reload}
              loading_table={this.props.loading}
              numSelected={this.props.selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarMapping.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
ToolBarMapping.defaultProps = {
  selected: [],
  uploading: false,
  disabled: false,
  hide_reload: false,
  enable_upload: false,
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  handleUpload: () => '',
  handleDeleteSelected: () => '',
  handleReload: () => '',
};

export default withStyles(ToolBarMapping, styleToolbar);
