import { QueryLinkContent } from 'graphql/Syncs';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
import { getParams } from 'helpers';

export const GetFormatedContent = content => {
  const { File, Series, Owner } = content;
  let name = '';
  let createdAt = '';
  let Size = 0;
  let Thumbnail = '';
  if (File) {
    name = File.original_name;
    createdAt = File.createdAt;
    Size = File.Size;
    Thumbnail = File.Thumbnail;
  } else if (Series) {
    name = Series.SeriesDescription;
    createdAt = Series.createdAt;
    Size = Series.Size;
    Thumbnail = Series.Thumbnail;
  }
  return {
    name,
    createdAt,
    Size,
    Owner: Owner ? Owner.FullName : '',
    Thumbnail,
    content,
    folder: null,
  };
};
export const GetFormatedFolder = folder => {
  //
  const { name, Size, createdAt, Owner } = folder;
  return {
    name,
    createdAt,
    Size,
    Owner: Owner.FullName,
    Thumbnail: '',
    content: null,
    folder,
  };
};
export const isEqual = (one, two) => {
  if (one.content && two.content) {
    return one.content.idFolderContent === two.content.idFolderContent;
  } else if (one.folder && two.folder) {
    return one.folder.idFolder === two.folder.idFolder;
  }
  return false;
};
export const FormatedFolder = folder => {
  let { SubFolders, Files } = folder;
  if (!SubFolders || typeof SubFolders === 'undefined') {
    SubFolders = [];
  }
  if (!Files || typeof Files === 'undefined') {
    Files = [];
  }
  let contents = SubFolders.map(x => {
    return GetFormatedFolder(x);
  });
  contents = contents.concat(
    Files.map(x => {
      return GetFormatedContent(x);
    })
  );
  return contents;
};
export const checkType = THIS => {
  const { loading, error, link } = THIS.state;
  if (loading || error !== '') return null;
  let { type, id, idFolder } = getParams(THIS.props);
  if (typeof type === 'undefined' || !type) {
    THIS.props.history.push('/');
    return null;
  }
  if (type !== 'file' && type !== 'folder') {
    THIS.props.history.push('/');
    return null;
  }
  if (typeof id === 'undefined' || !id || id === '') {
    THIS.props.history.push('/');
    return null;
  }
  if (link === 'invalid') {
    THIS.props.history.push('/');
    return null;
  }
  if (link) {
    if (link.folder && type !== 'folder') {
      THIS.props.history.push('/');
      return null;
    }
    if (link.content && type !== 'file') {
      THIS.props.history.push('/');
      return null;
    }
  }
  if (idFolder === undefined || !idFolder) {
    return { type, id, idFolder: null };
  } else if (type === 'folder') {
    return { type, id, idFolder: parseInt(idFolder, 10) };
  } else {
    THIS.props.history.push('/');
    return null;
  }
};

export const loadLink = (code, type, THIS) => {
  const { loading } = THIS.state;
  if (loading) return;
  THIS.setState({ loading: true });
  (async () => {
    QueryLinkContent(code)
      .then(res => {
        const data = GetData(res);
        //
        const { ok, errors, link } = data.linkContent;
        if (ok) {
          const { folder, content, code } = link;
          if (folder) {
            THIS.setState({ loading: false, folder, code, link });
          } else if (content) {
            THIS.setState({
              loading: false,
              folder: {
                idFolder: -1,
                name: 'content',
                Size: content.Size,
                Owner: content.Owner,
                createdAt: content.createdAt,
                updatedAt: content.updatedAt,
                SubFolders: [],
                Files: [content],
              },
              code,
              link,
            });
          }
        } else {
          throw errors;
        }
      })
      .catch(error => {
        THIS.setState({
          folder: 'invalid',
          loading: false,
          url: `/shared/${type}/${code}`,
          error: ServerErrorsString(error),
        });
      });
  })();
};
