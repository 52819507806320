import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import useStyles from '../Styles';
import { ViewUnitBlocks, ViewUnitList, ToolBarUnitSummary } from './components';
import { QuerySummaryOfMyUnits } from 'graphql/Units';
import { GetData, ServerErrorsString } from 'helpers';
import { BoxWaiting } from 'components';

class UnitSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      summaries: [],
      idUnit: -1,
      total: 0,
      view: 'block',
    };
  }

  componentDidMount() {
    this.runUpdateSummary();
  }

  componentDidUpdate() {
    const { myunit } = this.props;
    const { idUnit, summaries } = this.state;
    if (myunit && idUnit === -1 && summaries.length) {
      this.runUpdateSummary();
    }
  }

  handleUpdateSummary = summary => {
    const { summaries } = this.state;
    let index = summaries.map(x => x.id).indexOf(summary.id);
    // console.log({ index, summaries, summary });
    if (index === -1) {
      index = summaries.map(x => x.idUnit).indexOf(summary.idUnit);
    }
    if (index !== -1) {
      summaries[index] = summary;
      let total = 0;
      for (let i = 0; i < summaries.length; i++) {
        total += this.getTotalElements(summaries[i]);
      }
      console.log({ index, summary, summaries });
      this.setState({ summaries, total });
    }
  };
  runUpdateSummary = id => {
    //
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySummaryOfMyUnits()
        .then(res => {
          const data = GetData(res);
          const { summaryMyUnits } = data;

          this.setState({
            loading: false,
            summaries: summaryMyUnits,
            idUnit: id,
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getTotalElements = summary => {
    const { users, files, patients, studies, series, dicoms } = summary;
    let total = 0;
    if (users) total += users.value;
    if (files) total += files.value;
    if (patients) total += patients.value;
    if (studies) total += studies.value;
    if (series) total += series.value;
    if (dicoms) total += dicoms.value;
    return total;
  };
  handleOpenCreateUnit = () => {
    const { holder } = this.props;
    if (holder === undefined || !holder) return;
    if (holder.openAddNewUnit) {
      holder.openAddNewUnit();
    }
  };
  render() {
    const {
      classes,
      isDesktop,
      handleAddError,
      user,
      myunit,
      height,
    } = this.props;
    const { loading, summaries, view } = this.state;
    let style = { paddingTop: 5 };
    if (height) {
      style = {
        height: height - 410,
        maxHeight: height - 410,
        width: '100%',
        paddingTop: 5,
      };
    }
    let ContainerComponent = null;
    if (loading) ContainerComponent = <BoxWaiting />;
    else if (view === 'list') {
      ContainerComponent = (
        <ViewUnitList
          getTotalElements={this.getTotalElements}
          handleAddError={handleAddError}
          handleUpdateSummary={this.handleUpdateSummary}
          myunit={myunit}
          summaries={summaries}
          user={user}
        />
      );
    } else {
      ContainerComponent = (
        <ViewUnitBlocks
          getTotalElements={this.getTotalElements}
          handleAddError={handleAddError}
          handleUpdateSummary={this.handleUpdateSummary}
          myunit={myunit}
          summaries={summaries}
          user={user}
        />
      );
    }
    return (
      <div className={classes.external}>
        <ToolBarUnitSummary
          handleOpenCreateUnit={this.handleOpenCreateUnit}
          handleReload={() => this.runUpdateSummary()}
          handleViews={(event, view) => this.setState({ view })}
          loading={loading}
          view={this.state.view}
        />
        <div style={style}>
          <div style={{ overflowY: 'auto', height: '100%' }}>
            {ContainerComponent}
          </div>
        </div>
        <div
          className={clsx({
            [classes.footer]: true,
            [classes.shiftContent]: isDesktop,
          })}>
          <TablePagination
            component="div"
            count={summaries.length}
            onPageChange={this.props.handleChangePage}
            onRowsPerPageChange={this.props.handleChangeRowsPerPage}
            page={0}
            rowsPerPage={6}
            rowsPerPageOptions={[6, 12, 24, 100]}
          />
        </div>
      </div>
    );
  }
}

UnitSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};
UnitSummary.defaultProps = {
  loading: false,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
};

export default withStyles(UnitSummary, useStyles);
