import { FormatTime } from 'helpers';
import { getUserFullName, IsInvalid } from 'helpers';
export { default as ProcessToolBar } from './ProcessToolBar/ProcessToolBar';

export const columns_process = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let user = params.getValue(params.id, 'user');
      if (user === undefined || !user) return '';
      return getUserFullName(user);
    },
  },
  {
    field: 'id_folders',
    headerName: 'ID Folders',
    align: 'left',
  },
  {
    field: 'action',
    headerName: 'Action',
    align: 'left',
  },
  {
    field: 'running',
    headerName: 'Running',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let running = params.getValue(params.id, 'running');
      if (running === undefined || !running) return 'Stopped';
      return 'Running';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
