import {
  PAGE_EXPLORER_BOTTOM_BAR_FOLDERS,
  PAGE_EXPLORER_SET_HOME,
  PAGE_EXPLORER_SET_HOME_SUB_FOLDER,
  PAGE_EXPLORER_SET_NODE_ID,
  PAGE_EXPLORER_STATUS_SET,
  PAGE_EXPLORER_STATUS_PAGE,
  PAGE_EXPLORER_STATUS_LIMIT,
  PAGE_EXPLORER_UPLOAD_PROGRESS,
  PAGE_EXPLORER_UPLOAD_PROGRESS_FILES,
  PAGE_EXPLORER_UPLOAD_RESET,
  PAGE_EXPLORER_UPLOAD_TOTAL_FILES,
  PAGE_EXPLORER_VIEWTYPE,
  PAGE_EXPLORER_CHANGED,
  PAGE_EXPLORER_FOLDER_BAR_CLICKED,
} from './types';

export const setExplorerPage = page => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_STATUS_PAGE,
    page,
  });
};
export const setExplorerLimit = rowsPerPage => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_STATUS_LIMIT,
    rowsPerPage,
  });
};
export const setExplorerStatus = status => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_STATUS_SET,
    status,
  });
};
export const setExplorerNodeID = nodeId => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_SET_NODE_ID,
    nodeId,
  });
};
export const setExplorerSubFolders = sub_folders => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_SET_HOME_SUB_FOLDER,
    sub_folders,
  });
};
export const setExplorerHome = home => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_SET_HOME,
    home,
  });
};
export const setExplorerChanged = home_change => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_CHANGED,
    home_change,
  });
};
export const setExplorerView = view_type => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_VIEWTYPE,
    view_type,
  });
};

export const setExplorerFolders = status => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_BOTTOM_BAR_FOLDERS,
    folders: status,
  });
};
export const setExplorerFolderBar = status => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_FOLDER_BAR_CLICKED,
    folder_bar_clicked: status,
  });
};
export const setExplorerProgress = value => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_UPLOAD_PROGRESS,
    progress: value,
  });
};
export const setExplorerProgressFiles = value => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_UPLOAD_PROGRESS_FILES,
    progress_files: value,
  });
};
export const setExplorerTotalFiles = value => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_UPLOAD_TOTAL_FILES,
    total_files: value,
  });
};
export const setExplorerResetUpload = () => dispatch => {
  dispatch({
    type: PAGE_EXPLORER_UPLOAD_RESET,
  });
};
