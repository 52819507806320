import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_OBJECT_RATING = gql`
  query($input: QueryRating) {
    objectRating(input: $input) {
      ok
      errors {
        path
        message
      }
      rating {
        id
        idUser
        idFileObject
        idSeries
        rating
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryObjectRating = ({
  idFolderContent,
  idFileObject,
  idSeries,
}) => {
  const input = {};
  if (idFileObject) input.idFileObject = idFileObject;
  if (idSeries) input.idSeries = idSeries;
  if (idFolderContent) input.idFolderContent = idFolderContent;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_OBJECT_RATING),
    variables: { input },
  });
};
const MUTATION_OBJECT_RATING = gql`
  mutation($input: QueryRating) {
    addObjectRating(input: $input) {
      ok
      errors {
        path
        message
      }
      rating {
        id
        idUser
        idFileObject
        idSeries
        rating
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddObjectRating = (
  rating,
  { idFolderContent, idFileObject, idSeries }
) => {
  const input = { rating };
  if (idFileObject) input.idFileObject = idFileObject;
  if (idSeries) input.idSeries = idSeries;
  if (idFolderContent) input.idFolderContent = idFolderContent;
  console.log('MutationAddObjectRating', { input });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_OBJECT_RATING),
    variables: { input },
  });
};
