import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const TextFieldCustom = props => {
  const { onKeyDown, small, ...res } = props;
  const inputRef = useRef(null);
  const inputLabelProps = {
    shrink: true,
    padding: 0,
    margin: 'dense',
    ...props.InputLabelProps, // Allow custom InputLabelProps to be passed
  };
  const handleKeyDown = event => {
    if (event.key === 'ArrowLeft') {
      // Handle left arrow key press
      if (inputRef.current) {
        const currentPosition = inputRef.current.selectionStart;
        inputRef.current.selectionStart = currentPosition - 1;
        inputRef.current.selectionEnd = currentPosition - 1;
      }
    } else if (event.key === 'ArrowRight') {
      // Handle right arrow key press
      if (inputRef.current) {
        const currentPosition = inputRef.current.selectionStart;
        inputRef.current.selectionStart = currentPosition + 1;
        inputRef.current.selectionEnd = currentPosition + 1;
      }
    }
    onKeyDown(event);
  };
  let size = undefined;
  if (small) size = 'small';
  return (
    <TextField
      InputLabelProps={inputLabelProps}
      onKeyDown={handleKeyDown}
      size={size}
      style={{ margin: 0 }}
      {...res} // Pass any additional TextField props
      inputRef={inputRef}
    />
  );
};
TextFieldCustom.propTypes = {
  classes: PropTypes.object,
  onKeyDown: PropTypes.func,
};
TextFieldCustom.defaultProps = {
  onKeyDown: () => '',
  variant: 'standard',
  margin: 'dense',
  fullWidth: true,
  small: false,
};
export default TextFieldCustom;
