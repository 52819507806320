/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';

import Checkbox from '@mui/material/Checkbox';

function TableShareUsersHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    short_label,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  let style = { width: '100%', padding: 0 };
  let TableCellCheckBox = null;
  if (!props.disable_checkbox) {
    TableCellCheckBox = (
      <TableCell padding="checkbox">
        <Checkbox
          checked={rowCount && numSelected === rowCount}
          indeterminate={numSelected > 0 && numSelected < rowCount}
          inputProps={{ 'aria-label': 'select all desserts' }}
          onChange={onSelectAllClick}
        />
      </TableCell>
    );
  }
  return (
    <TableHead className={classes.header}>
      <TableRow style={style}>
        {TableCellCheckBox}
        {headCells.map(headCell => (
          <TableCell
            align={
              headCell.numeric
                ? 'right'
                : headCell.align
                  ? headCell.align
                  : 'center'
            }
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}>
              {short_label && headCell.short ? headCell.short : headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableShareUsersHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TableShareUsersHead;
