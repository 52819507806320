export const getDefaultRight = () => {
  return {
    idRight: null,
    idFolder: null,
    idFolderContent: null,
    idUser: null,
    idCenterTeam: null,
    idCenter: null,
    User: null,
    Project: null,
    CenterTeam: null,
    Center: null,
    write: false,
    download: false,
    delete: false,
    read: false,
    full: false,
    createdAt: '',
    updatedAt: '',
  };
};

export const createRight = object => {
  const { type, createdAt, updatedAt } = object;
  console.log('createRight', { object });
  const right = getDefaultRight();
  if (type === 'User') {
    right.idUser = object.id;
    right.User = {
      id: object.id,
      FullName: object.name,
    };
  } else if (type === 'Project') {
    right.idProject = object.id;
    right.Project = {
      id: object.id,
      name: object.name,
    };
  } else if (type === 'Team') {
    right.idCenterTeam = object.id;
    right.CenterTeam = {
      id: object.id,
      team: {
        name: object.name,
      },
    };
  } else if (type === 'Center') {
    right.idCenter = object.id;
    right.Center = {
      id: object.id,
      name: object.name,
    };
  }
  right.createdAt = createdAt;
  right.updatedAt = updatedAt;
  return right;
};

export const removeRights = (rights, to_delete) => {
  return rights.filter(a => {
    let index = -1;
    for (let i = 0; i < to_delete.length; i++) {
      const {
        idFolder,
        idFolderContent,
        idProject,
        idRight,
        idUser,
        idCenterTeam,
        idCenter,
      } = to_delete[i];
      if (idRight !== undefined && idRight) {
        if (idRight === a.idRight) {
          index = i;
          break;
        }
      } else if (idFolderContent !== undefined && idFolderContent) {
        if (idFolderContent === a.idFolderContent) {
          index = i;
          break;
        }
      } else if (idFolder !== undefined && idFolder) {
        if (idFolder === a.idFolder) {
          index = i;
          break;
        }
      } else if (idProject !== undefined && idProject) {
        if (idProject === a.idProject) {
          index = i;
          break;
        }
      } else if (idUser !== undefined && idUser) {
        if (idUser === a.idUser) {
          index = i;
          break;
        }
      } else if (idCenterTeam !== undefined && idCenterTeam) {
        if (idCenterTeam === a.idCenterTeam) {
          index = i;
          break;
        }
      } else if (idCenter !== undefined && idCenter) {
        if (idCenter === a.idCenter) {
          index = i;
          break;
        }
      }
    }
    return index === -1;
  });
};

export const AreEqualRights = (a, b) => {
  if (a.idRight === b.idRight) return true;
  if (
    a.idFolder === b.idFolder &&
    a.idFolderContent === b.idFolderContent &&
    a.idUser === b.idUser &&
    a.idProject === b.idProject &&
    a.idCenterTeam === b.idCenterTeam &&
    a.idCenter === b.idCenter
  )
    return true;
  return false;
};
export const AreEqualTypeRights = (a, b) => {
  if (a.idRight === b.idRight) return true;
  if (
    a.idFolder === b.idFolder &&
    a.idUser === b.idUser &&
    a.idProject === b.idProject &&
    a.idCenterTeam === b.idCenterTeam &&
    a.idCenter === b.idCenter
  )
    return true;
  return false;
};
export const IsRightOff = right => {
  if (
    !right.write &&
    !right.download &&
    !right.read &&
    !right.full &&
    !right.delete
  )
    return true;
  return false;
};
export const getRightIndex = right => {
  return `
  ${right.idUser}
  ${right.idProject}
  ${right.idCenterTeam}
  ${right.idCenter}
  `;
};
export const getRightName = right => {
  const { User, Project, CenterTeam, Center } = right;
  let name = 'Undefined (Undefined)';
  if (Project !== undefined && Project) {
    name = `${Project.name} (Project)`;
  } else if (User !== undefined && User) {
    name = `${User.FullName} (User)`;
  } else if (CenterTeam !== undefined && CenterTeam) {
    name = `${CenterTeam.team.name} (Team)`;
  } else if (Center !== undefined && Center) {
    name = `${Center.name} (Center)`;
  }
  return name;
};
