import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogClinicalType,
  ToolBarClinicalType,
  columns_clinical_types,
} from './components';
import { TableGrid } from 'components';
import { rowsPerPageBig, IsInvalid } from 'helpers';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryClinicalType } from 'graphql/DataClinics/utils_clinical_type';

class TabClinicalTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      clinical_types: [],
      new_clinical_types: [],
      saving: false,
      idUser: -1,
      open_dialog: false,
      selected: [],
      deleting: false,
    };
  }
  componentDidMount() {
    this.loadClinicalTypes();
  }
  componentDidUpdate() {
    this.loadClinicalTypes();
  }
  loadClinicalTypes = (force, newPage, newLimit) => {
    if (!this.props.isActive) return;
    const { user } = this.props;
    if (IsInvalid(user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryClinicalType(-1, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, clinical_types, total, errors } = data.clinicalDataTypes;
          if (ok) {
            this.setState({
              idUser: user.id,
              loading: false,
              clinical_types,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddClinicalDataType = clinical_type => {
    const { new_clinical_types, clinical_types } = this.state;
    const list = new_clinical_types.concat(clinical_types);
    console.log({ clinical_type });
    const new_clinical_type = {
      id: list.length * -1,
      code: clinical_type.code,
      description: clinical_type.description,
    };
    const index = list.map(x => x.id).indexOf(new_clinical_type.id);
    if (index !== -1) {
      this.props.handleAddError(
        `The clinical type: ${clinical_type.description} is already here`
      );
    } else {
      new_clinical_types.unshift(new_clinical_type);
      this.setState({ new_clinical_types, open_dialog: false });
    }
  };
  render() {
    const { clinical_types, loading, new_clinical_types } = this.state;
    return (
      <React.Fragment>
        <ToolBarClinicalType
          handleAddError={this.props.handleAddError}
          loading={loading}
          new_clinical_types={new_clinical_types}
          onOpenDialog={() => this.setState({ open_dialog: true })}
          onReloadTable={() => this.loadClinicalTypes(true)}
          saving={this.state.saving}
          setState={value => this.setState({ ...value })}
          state={this.state}
          user={this.props.user}
        />
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_clinical_types}
          height={this.props.height - 160}
          loading={loading}
          onPageChange={page =>
            this.loadClinicalTypes(true, page, this.state.limit)
          }
          onRowsPerPageChange={limit => this.loadClinicalTypes(true, 0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={new_clinical_types.concat(clinical_types)}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogClinicalType
          handleAddClinicalType={this.handleAddClinicalDataType}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_dialog: false })}
          isActive={this.props.isActive}
          open={this.state.open_dialog}
        />
      </React.Fragment>
    );
  }
}

TabClinicalTypes.propTypes = {
  classes: PropTypes.object,
};

export default TabClinicalTypes;
