import axios from 'axios';

import { HTTP_ADDRESS_DICOM } from 'config';

export const QueryDicomImage = (idSeries, position) => {
  // console.log('QueryDicomImage', { idSeries, position });
  return axios({
    url: `${HTTP_ADDRESS_DICOM}`,
    method: 'GET',
    responseType: 'application/json', // important
    headers: {
      idSeries: idSeries,
      position: position,
    },
    // onDownloadProgress: event => handleProgress(event),
  });
};
