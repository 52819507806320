import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

class TabContainerNetworks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container } = this.props;
    if (!container || container === undefined) return null;
    const { NetworkSettings } = container;
    // network_name: String!
    // IPAddress: String
    // Gateway: String
    // IPPrefixLen: String
    // MacAddress: String
    return (
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>A basic about the network of this docker container</caption>
          <TableBody>
            {NetworkSettings.map((row, index) => (
              <TableRow key={`row-network-${index}`}>
                <TableCell component="th" scope="row">
                  {row.network_name}
                </TableCell>
                <TableCell align="left">{row.IPAddress}</TableCell>
                <TableCell align="left">{row.Gateway}</TableCell>
                <TableCell align="left">{row.MacAddress}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabContainerNetworks.propTypes = {
  classes: PropTypes.object,
};

export default TabContainerNetworks;
