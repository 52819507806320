import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadInputs } from 'common';

export const QUERY_DOCKER_IMAGES = gql`
  query {
    dockerImages {
      ok
      errors {
        message
      }
      images {
        idImage
        createdAt
        RepoTags {
          name
          tag
        }
        Labels
        Size
        Status
      }
    }
  }
`;
export const QueryDockerImages = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_IMAGES),
    //   variables: {
    //     receiverId,
    //   },
  });
};

export const QUERY_DOCKER_IMAGE = gql`
  query($idImage: String!) {
    dockerImage(idImage: $idImage) {
      ok
      errors {
        message
      }
      image {
        idImage
        ParentId
        name
        Labels
        RepoDigests
        Size
        createdAt
        Build
        RepoTags {
          name
          tag
        }
        SharedSize
        VirtualSize

        Comment
        DockerVersion
        Author
        Architecture
        Os
        Config {
          Hostname
          Domainname
          User
          AttachStdin
          AttachStdout
          AttachStderr
          ExposedPorts
          Tty
          OpenStdin
          StdinOnce
          Env
          Cmd
          Image
          Volumes
          WorkingDir
          Entrypoint
          OnBuild
          Labels
        }
        RootFS {
          Type
          Layers
        }
        Layers {
          Comment
          Created
          CreatedBy
          Id
          Size
          Tags
        }
      }
    }
  }
`;
export const QueryDockerImage = idImage => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_IMAGE),
    variables: {
      idImage,
    },
  });
};
const MUTATION_UPLOAD_DOCKER_IMAGE = gql`
  mutation($file: Upload!, $settings: InputDockerSetting) {
    uploadDockerImage(file: $file, settings: $settings) {
      ok
      errors {
        path
        message
      }
      image {
        idImage
        ParentId
        name
        Labels
        RepoDigests
        Size
        createdAt
        Build
        RepoTags {
          name
          tag
        }
        SharedSize
        VirtualSize

        Comment
        DockerVersion
        Author
        Architecture
        Os
        Config {
          Hostname
          Domainname
          User
          AttachStdin
          AttachStdout
          AttachStderr
          ExposedPorts
          Tty
          OpenStdin
          StdinOnce
          Env
          Cmd
          Image
          Volumes
          WorkingDir
          Entrypoint
          OnBuild
          Labels
        }
        RootFS {
          Type
          Layers
        }
        Layers {
          Comment
          Created
          CreatedBy
          Id
          Size
          Tags
        }
        Status
      }
    }
  }
`;
export const MutationDockerUpload = (file, settings, handleProgress = null) => {
  return MutationUploadInputs(
    { file, settings },
    MUTATION_UPLOAD_DOCKER_IMAGE,
    handleProgress
  );
};
const MUTATION_DELETE_DOCKER_IMAGE = gql`
  mutation($names: [String!], $settings: DockerDeleteImageSettings) {
    deleteDockerImage(names: $names, settings: $settings) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDockerImageDelete = (names, settings) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_DOCKER_IMAGE),
    variables: {
      names,
      settings,
    },
  });
};

const MUTATION_DOCKER_IMAGE_ACTION = gql`
  mutation($action: String!, $ids: [String!]!) {
    dockerImageAction(action: $action, ids: $ids) {
      ok
      errors {
        path
        message
      }
      actions {
        idImage
        idContainer
        error
        code
        container {
          idContainer
          Labels
          Names
          ImageName
          idImage
          Command
          createdAt
          ip
          ports
          Status
          State
          image {
            idImage
            createdAt
            RepoTags {
              name
              tag
            }
          }
          Ports {
            Type
            PrivatePort
            PublicPort
          }
          NetworkSettings {
            network_name
            IPAddress
          }
          Mounts {
            Type
            Name
            Source
            Destination
          }
        }
      }
    }
  }
`;
export const MutationDockerImageAction = (action, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DOCKER_IMAGE_ACTION),
    variables: {
      action,
      ids,
    },
  });
};

export const QUERY_SYSTEM_DOCKER_IMAGES = gql`
  query($code_account: LongLong, $page: Int, $limit: Int) {
    systemDockerImages(
      code_account: $code_account
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        message
      }
      images {
        id
        idUser
        idImage

        name
        operation
        filepath

        user {
          id
          FullName
        }
        settings {
          id
          idDocker
          inputs_outputs {
            id
            idDockerSetting
            in_out_type
            type
            volume
          }
          volume
          ports
          execute
          access
          dockerfile
          operation_name
          input_commands
          docker_variables
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySystemDockerImages = (code_account, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SYSTEM_DOCKER_IMAGES),
    variables: {
      code_account,
      page,
      limit,
    },
  });
};
