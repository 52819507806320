import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import {
  AutoComboBox,
  ButtonLoading,
  DialogTitle,
  TextFieldCustom,
  TypeSequences,
} from 'components';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { MutationCreateProjectMapping } from 'graphql/Projects/utils_project_mapping';
import { dicom_tags } from 'views/Pipelines/components/TabBatchProcess/components/ToolBarJobsActions/components/DialogBatchProcessSetting/Styles';
import { QueryProjectVariableTypes } from 'graphql/Projects/utils_project_types';
import { PaperComponent } from 'helpers';
const types = [
  { name: 'None' },
  { name: 'FolderStructure', key: 'DICOM Tags' },
  { name: 'Anonymization' },
  { name: 'TimePoint' },
  { name: 'VariableType' },
  { name: 'Sequences', key: 'DICOM Sequences' },
  { name: 'CNumber' },
];
class DialogCreateMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      inputs: null,
      loading: false,
      variable_types: [],
    };
    this.holder = {
      setAutoComboBox: null,
    };
    if (props.holder) {
      props.holder.cleanCreateState = () =>
        this.setState({
          creating: false,
          inputs: null,
          loading: false,
          variable_types: [],
        });
    }
  }
  handleTextChanged = (event, key) => {
    let { inputs } = this.state;
    const name = event.target.name;
    if (IsInvalid(inputs) || !inputs.length) {
      inputs = [{}];
      if (key === 'DICOM Tags') inputs[0].key = key;
      if (key === 'DICOM Sequences') inputs[0].key = key;
    } else if (name !== 'value') {
      inputs[0].key = key;
    }
    // console.log({ a: inputs[0], name, key });
    inputs = [{ ...inputs[0], [name]: event.target.value }];
    this.setState({ inputs });
  };
  handleRunCreateMapping = () => {
    const { idProject } = this.props;
    const { creating, inputs } = this.state;
    if (creating) return;
    if (IsInvalid(inputs)) {
      this.props.handleAddError('You need to fill the inputs first');
      return;
    }
    this.setState({ creating: true });
    (async () => {
      MutationCreateProjectMapping(idProject, inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.createProjectMapping;
          if (ok) {
            this.setState({
              creating: false,
              inputs: null,
              variable_types: [],
            });
            this.props.handleAddNewMapping(mappings);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleTypeChanged = values => {
    const type = values.name;
    let key = this.getKeyValue(type);
    this.handleTextChanged({ target: { name: 'type', value: type } }, key);
    if (type === 'VariableType') {
      this.handleQueryVariableTypes();
    }
  };
  handleChangeMultiKeys = (event, new_values) => {
    let { inputs } = this.state;
    if (IsInvalid(inputs) || !inputs.length) {
      inputs = [{}];
    }
    console.log({ new_values });
    let value = undefined;
    if (new_values.length) {
      if (new_values.filter(x => x.tag).length) {
        value = new_values.map(x => x.tag).join('/');
      } else if (new_values.filter(x => x.name).length) {
        value = new_values.map(x => x.name).join(', ');
      }
    }
    if (IsInvalid(value)) return;
    this.setState({
      inputs: [{ ...inputs[0], value }],
    });
  };
  getKeyValue = type => {
    if (IsInvalid(type) || type === '') return '';
    const keys = types.filter(x => x.name === type && x.key);
    if (keys.length !== 1) return '';
    return keys[0].key;
  };
  handleQueryVariableTypes = () => {
    const { loading, variable_types } = this.state;
    const { idProject } = this.props;
    if (loading || variable_types.length) return;
    this.setState({ loading: true });
    (async () => {
      QueryProjectVariableTypes(idProject)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types } = data.projectVariableTypes;
          if (ok) {
            this.setState({
              loading: false,
              variable_types: types.map(x => x.type),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false });
        });
    })();
  };
  render() {
    const { open } = this.props;
    const { inputs } = this.state;
    let ComponentValue = (
      <TextFieldCustom
        label="Value"
        name="value"
        onChange={this.handleTextChanged}
      />
    );
    let key = undefined;
    let key_disabled = false;
    let type = '';
    if (inputs && inputs.length) {
      // const { value } = inputs[0];
      type = inputs[0].type;
      key = this.getKeyValue(type);
      if (inputs[0].key) {
        key = inputs[0].key;
      }
      if (type === 'FolderStructure') {
        key_disabled = true;
        // const tags = IsInvalid(value)
        //   ? undefined
        //   : value.split('/').map(x => ({ tag: x }));
        ComponentValue = (
          <Autocomplete
            getOptionLabel={option => option.tag}
            id="dicom-tags"
            multiple
            onChange={this.handleChangeMultiKeys}
            options={dicom_tags}
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                label="Value"
                placeholder="Folder structure"
                variant="standard"
              />
            )}
            // value={tags}
          />
        );
      } else if (type === 'Sequences') {
        ComponentValue = (
          <TypeSequences
            multiple
            onSequenceChanged={values =>
              this.handleChangeMultiKeys(null, values)
            }
            // options={options}
            // sequence={filters.sequence}
            size="small"
          />
        );
      }
    }
    let ComponentKeys = null;
    if (type === 'VariableType') {
      ComponentKeys = (
        <AutoComboBox
          disabled={this.state.loading}
          holder={this.holder}
          id="key"
          label="Key"
          onObjectChanged={value =>
            this.handleTextChanged({
              target: { name: 'key', value: value ? value.name : '' },
            })
          }
          options={this.state.variable_types}
          small
          variant="standard"
          width={250}
        />
      );
    } else {
      ComponentKeys = (
        <TextFieldCustom
          disabled={key_disabled}
          label="Key"
          name="key"
          onChange={this.handleTextChanged}
          value={key}
        />
      );
    }
    return (
      <Dialog
        onClose={this.props.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle>Add Project Mapping</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Include all the necessary information to create the mapping.
          </DialogContentText>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            spacing={1}>
            <Grid item xs={6}>
              <AutoComboBox
                holder={this.holder}
                label=""
                onObjectChanged={this.handleTypeChanged}
                options={types}
                small
                width={250}
              />
            </Grid>
            <Grid item xs={6}>
              {ComponentKeys}
            </Grid>
            <Grid item xs>
              {ComponentValue}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <ButtonLoading
            disabled={this.state.inputs ? false : true}
            handleClick={this.handleRunCreateMapping}
            loading={this.state.creating}
            name="Create"
            transform={false}
            variant="standard"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogCreateMapping.propTypes = {
  classes: PropTypes.object,
};

export default DialogCreateMapping;
