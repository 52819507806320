import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';

class StepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getCode = () => this.state.code;
    }
  }

  componentDidMount() {
    const { code } = this.props;
    if (this.state.code !== code) {
      this.setState({ code });
    }
  }

  handleChange = event => {
    //
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes, email, error } = this.props;
    const { code } = this.state;
    return (
      <React.Fragment>
        <Typography>
          {`Enter the verification 
				code that we sent you to your email (${email}).`}
        </Typography>
        <TextField
          className={classes.margin_double}
          error={error !== ''}
          fullWidth
          id="code"
          InputLabelProps={{
            shrink: true,
          }}
          label="Code"
          name="code"
          onChange={this.handleChange}
          required
          value={code}
          variant="outlined"
        />
      </React.Fragment>
    );
  }
}

StepTwo.propTypes = {
  classes: PropTypes.object,
};

export default StepTwo;
