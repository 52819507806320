export const GetTypeString = receiver => {
  if (typeof receiver === 'undefined') return 'Unknown';
  const { idTeam, idUser, idChannel } = receiver;
  if (idTeam) return 'Team';
  if (idUser) return 'User';
  if (idChannel) return 'Channel';
  return 'Unknown';
};

export const GetDirectChats = receivers => {
  return receivers
    .map(x => {
      const { direct } = x;
      if (typeof direct !== 'undefined') return direct;
      return null;
    })
    .filter(x => x);
};

export const GetChannels = receivers => {
  return receivers
    .map(x => {
      const { channel } = x;
      if (typeof channel !== 'undefined') return channel;
      return null;
    })
    .filter(x => x);
};
export const GetIndexAtChannel = (receivers, selected) => {
  for (let i = 0; i < receivers.length; i++) {
    const { channel } = receivers[i];
    if (!channel || typeof channel === 'undefined') continue;
    if (channel.id === selected.id) return i;
  }
  return -1;
};
export const IsChannelHere = (receivers, channel) => {
  const channels = GetChannels(receivers);
  const index = channels.map(x => x.id).indexOf(channel.id);
  return index !== -1;
};
export const IsDirectChatlHere = (receivers, user) => {
  const chats = GetDirectChats(receivers);
  const index = chats
    .map(x => {
      const { User } = x;
      if (typeof User === 'undefined') return null;
      return User.id;
    })
    .filter(x => x)
    .indexOf(user.id);
  return index !== -1;
};
export const GetReceiverIndex = (receivers, idReceiver, type) => {
  for (let i = 0; i < receivers.length; i++) {
    const { direct, channel } = receivers[i];
    if (type === 'chat' && direct && direct.User.id === idReceiver) {
      return i;
    }
    if (type === 'channel' && channel && channel.team.id === idReceiver) {
      return i;
    }
  }
  return -1;
};
