import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { SearchCentersInputs } from 'components';

const useStyles = () => ({
  button: {},
});

class SearchCenters extends Component {
  render() {
    const { xs } = this.props;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="space-between"
        xs={xs}>
        <SearchCentersInputs
          handleAddCenter={this.props.handleAddCenter}
          placeholder="Search Center..."
        />
      </Grid>
    );
  }
}

SearchCenters.propTypes = {
  classes: PropTypes.object,
};
SearchCenters.defaultProps = {
  xs: 12,
  handleOpenCreate: () => console.log('handleOpenCreate'),
};

export default withStyles(SearchCenters, useStyles);
