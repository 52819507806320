import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { Share as ShareIcon } from '@mui/icons-material';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
// import { withRouter } from 'react-router-dom';
import { DialogShareSeries } from 'components';

const useStyles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
});
const defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  color: 'error',
  children: <ShareIcon />,
};

class ButtonShareSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpenShare = () => {
    this.setState({ open: true });
  };

  render() {
    const { size, copy_series } = this.props;
    if (size < 1) {
      return null;
    }
    const { open } = this.state;
    return (
      <React.Fragment>
        <DialogShareSeries
          handleClose={this.handleClose}
          ListSeries={copy_series}
          open={open}
        />
        <Tooltip title="Share Selected Series">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls="share-selected-series"
              onClick={this.handleOpenShare}
              size="large"
              style={{ maring: '5px' }}>
              <Badge badgeContent={size} {...defaultProps} />
            </IconButton>
          </span>
        </Tooltip>
      </React.Fragment>
    );
  }
}

ButtonShareSeries.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps)(
  withStyles(ButtonShareSeries, useStyles)
);
