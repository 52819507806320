import React from 'react';
import PropTypes from 'prop-types';
import MuiMenu from '@mui/material/Menu';
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, anchorEl, open, handleClose } = this.props;
    return (
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoFocus={false}
        id="account-menu"
        onClose={handleClose}
        onKeyDown={e => e.stopPropagation()}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
        {children}
      </MuiMenu>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object,
};

export default Menu;
