import {
  QueryBatchProcesses,
  MutationDeleteBatchProcesses,
  QueryJobProcess,
  MutationDeleteJobProcess,
} from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';

export const queryJobProcess = (self, state = {}) => {
  const { loading, page, limit } = self.state;
  if (loading) return;
  self.setState({ ...state, loading: true });
  (async () => {
    QueryJobProcess(null, page, limit)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, jobs, total } = data.jobsAtBatch;
        if (ok) {
          self.setState({ loading: false, rows: jobs, total });
          self.props.setNewJobProcess(false);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ loading: false });
        self.props.handleAddError(ServerErrorsString(error));
        self.props.setNewJobProcess(false);
      });
  })();
};

export const queryBatchProcess = self => {
  const { loading, job_name, page, limit } = self.state;
  if (loading) return;
  self.setState({ loading: true });
  (async () => {
    QueryBatchProcesses(job_name, page, limit)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, batches, total } = data.batchProcesses;
        if (ok) {
          self.setState({ loading: false, rows: batches, total });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ loading: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};

export const deleteFromJobToExecute = self => {
  const { selected, idRow, row_styles } = self.state;
  let { rows } = self.state;
  for (let i = 0; i < selected.length; i++) {
    const value = selected[i][idRow];
    let index = rows.map(x => x[idRow]).indexOf(value);
    if (index !== -1) {
      rows.splice(index, 1);
    }
    index = row_styles.map(x => x[idRow]).indexOf(value);
    if (index !== -1) {
      row_styles.splice(index, 1);
    }
  }
  let { deleting } = self.state;
  console.log('deleteFromJobToExecute', { selected, rows });
  if (deleting) return;
  self.setState({ deleting: true });
  (async () => {
    const ids = selected.map(x => x.id);
    MutationDeleteJobProcess(ids)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.deleteJobs;
        if (ok) {
          rows = rows.filter(x => ids.indexOf(x.id) === -1);
          self.setState({
            deleting: false,
            selected: [],
            rows,
            job_clicked: null,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        self.setState({ deleting: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const deleteBatchProcess = self => {
  const { deleting, selected, rows } = self.state;
  if (deleting) return;
  self.setState({ deleting: true });
  (async () => {
    MutationDeleteBatchProcesses(selected.map(x => x.id))
      .then(res => {
        const data = GetData(res);
        const { ok, errors, fails } = data.deleteBatchProcesses;
        if (ok) {
          let ids_fails = [];
          if (fails && fails.length) ids_fails = fails.map(x => x.idBatch);
          console.log({ selected, rows, ids_fails, fails });
          self.setState({
            deleting: false,
            rows: rows.filter(x => ids_fails.indexOf(x.id) !== -1),
            selected: selected.filter(x => ids_fails.indexOf(x.id) !== -1),
          });
          self.handleProcessFailed(fails);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ deleting: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};

export const getUpdatedBatchesAtJob = jobs => {
  let batches = [];
  for (let i = 0; i < jobs.length; i++) {
    const { batch, status } = jobs[i];
    if (batch === undefined || !batch) continue;
    let index = batches.map(x => x.id).indexOf(batch.id);
    if (index === -1) {
      index = 0;
      batches.push({
        ...batch,
        total_jobs: 1,
        jobs_waiting: status === 'waiting' ? 1 : 0,
        jobs_running: status === 'running' ? 1 : 0,
        jobs_stopped: status === 'stopped' ? 1 : 0,
        jobs_finished: status === 'finished' ? 1 : 0,
        jobs_error: status === 'error' ? 1 : 0,
      });
    } else {
      batches[index].total_jobs = batches[index].total_jobs + 1;
      let value = status === 'waiting' ? 1 : 0;
      batches[index].jobs_waiting = batches[index].jobs_waiting + value;
      value = status === 'running' ? 1 : 0;
      batches[index].jobs_running = batches[index].jobs_running + value;
      value = status === 'stopped' ? 1 : 0;
      batches[index].jobs_stopped = batches[index].jobs_stopped + value;
      value = status === 'finished' ? 1 : 0;
      batches[index].jobs_finished = batches[index].jobs_finished + value;
      value = status === 'error' ? 1 : 0;
      batches[index].jobs_error = batches[index].jobs_error + value;
    }
    // console.log({ batch, status, b: batches[index] });
  }
  for (let i = 0; i < batches.length; i++) {
    const { jobs_finished, jobs_error, total_jobs, status } = batches[i];
    if (jobs_finished === total_jobs) batches[i].status = 'finished';
    else if (jobs_finished > 0 && jobs_finished < total_jobs)
      batches[i].status = 'running';
    else if (jobs_error) batches[i].status = 'failed';
    else if (status !== 'error') batches[i].status = 'waiting';
  }
  return batches;
};
