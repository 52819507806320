import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { TableLogErrors } from 'components';
import { MutationDeleteLogsSite, QueryLogsSite } from 'graphql/Logs';
import { GetData, ServerErrorsString } from 'helpers';

const useStyles = theme => ({
  root: {
    margin: 0,
    marginLeft: 2,
    marginRight: 0,
    height: 'calc(100% - 20)',
  },
  search: {
    height: '42px',
    margin: theme.spacing(1),
  },
});

class TabLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      deleting: false,
      loading: false,
      init: false,
      page: 0,
      limit: 10,
      type: 'Activity',
    };
    this.holder = {
      getSelected: null,
      clearSelected: null,
      getSearchText: null,
    };
  }

  componentDidMount() {
    this.updateLogs();
  }

  componentDidUpdate() {
    if (!this.state.init) {
      this.updateLogs();
    }
  }

  handleSearchUser = username => {
    console.log({ username });
    this.updateLogs(username);
  };
  updateLogs = (text = null) => {
    if (!this.props.isActive) return;
    const { loading, page, limit, type } = this.state;
    if (loading) return;
    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    let input = null;
    if (text && text !== '') {
      if (type === 'Users') input = { username: text };
      else input = { activity: text };
    }
    this.setState({ loading: true });
    (async () => {
      QueryLogsSite(input, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, logs, total } = data.LogsSite;
          if (ok) {
            this.setState({ loading: false, logs, total, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    const selected = this.holder.getSelected();
    const { deleting, logs } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteLogsSite(ids)
        .then(res => {
          const data = GetData(res);
          const {
            DeleteLogsSite: { ok, errors },
          } = data;
          if (ok) {
            this.holder.clearSelected();

            this.setState({
              deleting: false,
              logs: logs.filter(x => ids.indexOf(x.id) === -1),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage, init: false });
  };

  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value, init: false });
  };
  handleChange = (event, type) => {
    this.setState({ type });
  };
  render() {
    const { classes } = this.props;
    const { type } = this.state;
    let placeholder = 'Search Users...';
    if (type === 'Activity') placeholder = 'Search Activity...';
    return (
      <div className={classes.root}>
        <TableLogErrors
          deleting={this.state.deleting}
          expand_disable
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={() => this.updateLogs()}
          handleSearchUser={this.handleSearchUser}
          handleTypeSearchChange={this.handleChange}
          height={this.props.height - 290}
          holder={this.holder}
          limit={this.state.limit}
          loading={this.state.loading}
          nowrap
          options_search={['Users', 'Activity']}
          page={this.state.page}
          placeholder_type={placeholder}
          rows={this.state.logs}
          title="Users Logs"
          total={this.state.total}
          type_selected={type}
        />
      </div>
    );
  }
}

TabLogs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabLogs, useStyles);
