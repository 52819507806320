export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const headers = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'GivenName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'FamilyName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'PatientID',
    numeric: true,
    disablePadding: false,
    label: 'PatientID',
  },
  {
    id: 'PatientBirthDate',
    numeric: true,
    disablePadding: false,
    label: 'BirthDate',
  },
  {
    id: 'number_surveys',
    numeric: true,
    disablePadding: false,
    label: '#Surveys',
  },
  {
    id: 'patient_info',
    numeric: true,
    disablePadding: false,
    label: 'Patient Info',
  },
  {
    id: 'info_consent',
    numeric: true,
    disablePadding: false,
    label: 'Info Consent',
  },
  {
    id: 'info_center',
    numeric: true,
    disablePadding: false,
    label: 'Info Center',
  },
  {
    id: 'view',
    numeric: true,
    disablePadding: false,
    label: 'View Patient',
  },
];
