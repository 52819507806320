import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERT_SITE_NOTIFICATIONS = gql`
  query($from: String, $to: String, $page: Int, $limit: Int) {
    SiteNotifications(from: $from, to: $to, page: $page, limit: $limit) {
      ok
      total
      activities {
        id
        idFrom
        idTo

        name

        From {
          FullName
        }
        To {
          FullName
        }
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QuerySiteNotifications = (from, to, page, limit) => {
  const variables = {};
  if (from && typeof from !== 'undefined' && from !== '') {
    variables.from = from;
  }
  if (to && typeof to !== 'undefined' && to !== '') {
    variables.to = to;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined') {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERT_SITE_NOTIFICATIONS),
    variables,
  });
};
