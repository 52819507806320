export { default as ButtonCopySeries } from './ButtonCopySeries';
export { default as ButtonDownloadSelected } from './ButtonDownloadSelected';
export { default as ButtonShareSeries } from './ButtonShareSeries';
export { default as ButtonSwithView } from './ButtonSwithView';
export { default as ToolBarImages } from './ToolBarImages';
export { default as ButtonQuerySettings } from './ButtonQuerySettings';
export { default as DatabaseSearchFilter } from './DatabaseSearchFilter/DatabaseSearchFilter';
export const useStyles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  searchInput: {
    height: '42px',
    margin: theme.spacing(1),
  },
  searchButton: {
    height: '42px',
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
  },
});
export const defaultFilter = {
  idUser: null,
  idProject: null,
  user: null,
  project: null,
  SeriesDate: null,
  sequence: null,
  nifti: false,
};
