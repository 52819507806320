import { IsInvalid } from 'helpers/utils';

export const initFields = fields => {
  fields = fields.filter(
    item =>
      !item.headerName.includes('Total') &&
      (item.derived === false || IsInvalid(item.derived))
  );
  let form = [];
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    let record = {};
    if (field.headerName.includes('Total') || field.derived) continue;
    const value = initValue(field, record);
    record.value = value;
    record.headerName = field.headerName;
    record.type = field.type;
    record.field = field.field;
    record.required = field.required;
    record.example = field.example;
    form.push(record);
  }

  form.sort((a, b) => {
    if (a.type === 'boolean' && b.type !== 'boolean') {
      return 1;
    }
    if (a.type !== 'boolean' && b.type === 'boolean') {
      return -1;
    }
    return 0;
  });
  return form;
};
export const initValue = field => {
  let value = '';
  if (field.type === 'boolean') {
    value = false;
  } else if (field.type === 'datetime') {
    value = null;
  } else if (field.type === 'date') {
    value = null;
  } else if (field.type === 'time') {
    value = null;
  } else if (field.type === 'int') {
    value = 0;
  } else if (field.type === 'float') {
    value = 0.0;
  } else if (IsInvalid(field.type)) {
    value = '';
  }
  return value;
};
export const formatInput = (form, inputs, prefix) => {
  for (let i = 0; i < form.length; i++) {
    const field = form[i];
    if (field.type === 'int') {
      field.value = parseInt(field.value);
      if (isNaN(field.value)) {
        field.value = 0;
      }
    } else if (field.type === 'float') {
      field.value = parseFloat(field.value);
      if (isNaN(field.value)) {
        field.value = 0;
      }
    }
    inputs[prefix + field.field] = field.value;
  }
  return inputs;
};
