import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const SEARCH_EPILEPSY_CASES = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchEpilepsy(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient
        name
        date
        first_seizure_date
        patient_age
        neurologist
        assistant
        comment
        laterality
        scalp
        subscalp
        strip
        grid
        depth
        date_pre_implantation_mr
        date_post_implantation_ct
        covered_region
        electrodes_located_mr
        electrodes_labelled
        ct_registered_to_mri
        normalized_to_mni
        pial_reconstruction
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
      }
    }
  }
`;

export const SearchEpilepsy_case = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_EPILEPSY_CASES),
    variables: { text, page, limit, table },
  });
};

const SEARCH_EPILEPSY_RESEARCH = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchEpilepsy(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      research_studies {
        id
        idPatient
        idEpilepsyCase
        brain_map
        input_output
        circadian
        pharmaco
        audio_sleep
        music_start_date
        music_end_date
        epi_os
        spec_ictal
        comment
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchEpilepsy_research_study = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_EPILEPSY_RESEARCH),
    variables: { text, page, limit, table },
  });
};

const SEARCH_EPILEPSY_RESECTION = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchEpilepsy(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      resections {
        id
        idPatient
        idEpilepsyCase
        date
        patient_age
        op_side
        operation
        details
        complication
        histology
        histology_temporolpol
        histology_amygdala
        histology_hippocampus
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchEpilepsy_resection = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_EPILEPSY_RESECTION),
    variables: { text, page, limit, table },
  });
};

const SEARCH_EPILEPSY_OUTCOME = gql`
  query($text: String, $page: Int, $limit: Int, $table: String) {
    searchEpilepsy(text: $text, page: $page, limit: $limit, table: $table) {
      ok
      errors {
        path
        message
      }
      total
      outcomes {
        id
        idPatient
        idEpilepsyCase
        engel_3m
        engel_1y
        engel_2y
        percentage_aed_1y
        percentage_aed_2y
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchEpilepsy_outcome = (text, page, limit, table) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_EPILEPSY_OUTCOME),
    variables: { text, page, limit, table },
  });
};
