import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Avatar from '@mui/material/Avatar';

import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  root: {
    top: 'auto',
    position: 'sticky',
    bottom: 0,
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    margin: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: '2 auto',
    border: '0.5px solid',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexGrow: 1,
  },
}));

export default function DirectChatBottomBar(props) {
  const { classes } = useStyles();
  const [values, setValues] = React.useState({
    message: '',
  });
  const { user } = props;
  let { avatar } = user;
  if (!avatar || avatar === 'image_avatar') {
    avatar = '/images/avatars/avatar_0.png';
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleSendMessage = async event => {
    if (!event) {
      return;
    }
    const localName = event.target.localName;
    if (typeof localName === 'undefined') {
      return;
    }
    const { key } = event;
    if (
      key === 'Enter' ||
      ['button', 'svg', 'path'].indexOf(localName) !== -1
    ) {
      //
      if (!props.IsSubscribed()) {
        await props.Subscribe(props.idReceiver);
      }
      props.handleSendMessage(values.message);
      setValues({ ...values, message: '' });
    }
  };
  // console.log({ user });
  return (
    <Paper className={classes.root}>
      <Tooltip title={user.FullName}>
        <Avatar alt={user.FullName} className={classes.avatar} src={avatar} />
      </Tooltip>
      <TextField
        className={clsx(classes.margin, classes.textField)}
        id="outlined-adornment-message"
        label="Send a Message"
        onChange={handleChange('message')}
        onKeyPress={handleSendMessage}
        value={values.message}
        variant="outlined"
      />
      <IconButton
        aria-label="send"
        className={classes.iconButton}
        onClick={handleSendMessage}
        size="large">
        <SendIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        aria-label="directions"
        className={classes.iconButton}
        color="primary"
        size="large">
        <AttachFileIcon />
      </IconButton>
    </Paper>
  );
}
