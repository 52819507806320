import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material';
import { ButtonLoading } from '../../Buttons';
import { IsValid } from 'helpers';


class DialogJpgToDcmInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PatientID: '',
      PatientName: '',
      StudyDate: '',
      StudyTime: '',
      SeriesDate: '',
      SeriesTime: '',
      SeriesDescription: '',
    };
  }
  checkField = (field) => {
    return (IsValid(field) && field !== '');
  }
  handleRunProcess = () => {
    const {PatientID, PatientName, StudyDate, StudyTime, SeriesDate, SeriesTime, SeriesDescription} = this.state;
    let params = {};
    
    if (this.checkField(PatientID)) {
      params.PatientID = PatientID;
    }
    if (this.checkField(PatientName)) {
      params.PatientName = PatientName;
    }
    if (this.checkField(StudyDate)) {
      params.StudyDate = StudyDate;
    }
    if (this.checkField(StudyTime)) {
      params.StudyTime = StudyTime;
    }
    if (this.checkField(SeriesDate)) {
      params.SeriesDate = SeriesDate;
    }
    if (this.checkField(SeriesTime)) {
      params.SeriesTime = SeriesTime;
    }
    if (this.checkField(SeriesDescription)) {
      params.SeriesDescription = SeriesDescription;
    }
    if (Object.entries(params).length === 0) {
      params = null;
    }
    this.props.handleExecuteProcess(params);
    this.props.handleClose();
  };
  handleChangePatientID = event => {
    this.setState({ PatientID: event.target.value });
  };
  handleChangePatientName = event => {
    this.setState({ PatientName: event.target.value });
  };
  handleChangeStudyDate = event => {
    this.setState({ StudyDate: event.target.value });
  };
  handleChangeStudyTime = event => {
    this.setState({ StudyTime: event.target.value });
  };
  handleChangeSeriesDate = event => {
    this.setState({ SeriesDate: event.target.value });
  };
  handleChangeSeriesTime = event => {
    this.setState({ SeriesTime: event.target.value });
  };
  handleChangeSeriesDescription = event => {
    this.setState({ SeriesDescription: event.target.value });
  };
  keyPress = event => {
    if (event.keyCode === 13) {
      this.handleRunProcess();
    }
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <React.Fragment>
        <Dialog
          aria-labelledby="form-dialog-title"
          name="a"
          onClose={handleClose}
          open={open}>
          <DialogTitle id="form-dialog-title">Run JPEG to DCM conversion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To run the JPEG to DICOM conversion, please fill the following fields. If they are left empty, default values will be used.
            </DialogContentText>
            <Divider> Patient Tags</Divider>
            <TextField
              autoFocus
              fullWidth
              id="PatientID"
              label="PatientID"
              margin="dense"
              onChange={this.handleChangePatientID}
              onKeyDown={this.keyPress}
            />
            <TextField
              autoFocus
              fullWidth
              id="PatientName"
              label="PatientName"
              margin="dense"
              onChange={this.handleChangePatientName}
              onKeyDown={this.keyPress}
            />
            <Divider> Study Tags</Divider>
            <TextField
              autoFocus
              fullWidth
              id="StudyDate"
              label="StudyDate (YYYYMMDD)"
              margin="dense"
              onChange={this.handleChangeStudyDate}
              onKeyDown={this.keyPress}
              placeholder="20240825"
            />
            <TextField
              autoFocus
              fullWidth
              id="StudyTime"
              label="StudyTime (HHMMSS)"
              margin="dense"
              onChange={this.handleChangeStudyTime}
              onKeyDown={this.keyPress}
              placeholder="183212"
            />
            <Divider> Series Tags</Divider>
            <TextField
              autoFocus
              fullWidth
              id="SeriesDate"
              label="SeriesDate (YYYYMMDD)"
              margin="dense"
              onChange={this.handleChangeSeriesDate}
              onKeyDown={this.keyPress}
              placeholder="20240825"
            />
            <TextField
              autoFocus
              fullWidth
              id="SeriesTime"
              label="SeriesTime (HHMMSS)"
              margin="dense"
              onChange={this.handleChangeSeriesTime}
              onKeyDown={this.keyPress}
              placeholder="183212"
            />
            <TextField
              autoFocus
              fullWidth
              id="SeriesDescription"
              label="SeriesDescription"
              margin="dense"
              onChange={this.handleChangeSeriesDescription}
              onKeyDown={this.keyPress}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" name="Cancel" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonLoading
              handleClick={this.handleRunProcess}
              name="Run"
              transform={false}
              variant="standard"
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

DialogJpgToDcmInput.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
};
DialogJpgToDcmInput.defaultProps = {
  open: false,
  handleExecuteProcess: () => '',
  handleRefresh: () => '',
};
export default DialogJpgToDcmInput;
