/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid, Paper } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  ButtonGroupLoading,
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
import {
  MutationDeleteLandingObjects,
  MutationDeleteLandingStatus,
  MutationEditLandingObjects,
  MutationProcessLandingObjects,
} from 'graphql/Administration/utils_landing_objects';

const useStyles = () => ({
  paper: {
    padding: 2,
    paddingTop: 4,
    paddingBottom: 6,
    marginBottom: 2,
  },
});

class ToolBarLandingStopped extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      deleting: false,
      open_delete: false,
      open_create: true,
    };
  }
  handleDeleteLandingFinished = () => {
    const { deleting } = this.state;
    if (deleting) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      MutationDeleteLandingStatus('finished')
        .then(res => {
          const data = GetData(res);
          const { ok, errors, landings } = data.deleteLandingAtStatus;
          if (ok) {
            this.props.handleRemoveStopped(landings);
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
        });
    })();
  };
  handleDeleteLanding = keep_content => {
    const { deleting } = this.state;
    const { selected } = this.props;
    if (deleting) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      MutationDeleteLandingObjects(
        selected.map(x => x.idLanding),
        keep_content
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteLandingObjects;
          if (ok) {
            this.props.handleRemoveStopped(selected);
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
        });
    })();
  };
  handleReRunLandingObject = () => {
    const { proccessing } = this.state;
    if (proccessing) return;
    const { selected, landing_objects } = this.props;
    let { row_highlight } = this.props;
    if (!selected.length) {
      this.props.handleAddError('You need to select first');
      return;
    }
    this.setState({ proccessing: true });
    const ids = selected.map(x => x.idLanding);
    if (row_highlight) {
      const i = ids.indexOf(row_highlight.idLanding);
      if (i !== -1) row_highlight = null;
    }
    (async () => {
      MutationProcessLandingObjects(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.proccessLandingObjects;
          if (ok) {
            this.setState({ proccessing: false, rows_edited: [] });
            this.props.holder.removeStopped(selected);
            this.props.setState({
              landings_stopped: landing_objects.filter(
                x => ids.indexOf(x.idLanding) === -1
              ),
              selected: [],
              row_highlight,
            });
            this.props.holder.addRunning(
              landing_objects
                .filter(x => ids.indexOf(x.idLanding) !== -1)
                .map(x => {
                  return {
                    ...x,
                    proccessing: true,
                  };
                })
            );
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ proccessing: false });
        });
    })();
  };
  handleUpdateLanding = () => {
    const { updating } = this.state;
    const { rows_edited } = this.props;
    if (updating) return;
    this.setState({ updating: true, open_delete: false });
    const inputs = rows_edited.map(x => {
      return {
        idLanding: x.idLanding,
        isSeries: x.isSeries,
        processing: x.processing,
        projectIds: x.projectIds,
        folderIds: x.folderIds,
        folderStructure: x.folderStructure,
        types: x.types,
        errors: x.errors,
      };
    });
    (async () => {
      MutationEditLandingObjects(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.editLandingObjects;
          if (ok) {
            this.props.handleSaveEdited();
            this.setState({ updating: false });
            if (errors && errors.length) throw errors;
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ updating: false });
        });
    })();
  };
  render() {
    const { classes, selected, rows_edited, row_highlight } = this.props;
    const numSelected = selected.length;
    let idFolder = null;
    if (row_highlight) {
      const { folderIds } = row_highlight;
      if (folderIds) {
        const ids = folderIds.split(',');
        if (ids.length) {
          idFolder = parseInt(ids[0], 10);
        }
      }
    }
    const { title } = this.state;
    let delete_landing_object = false;
    if (title === 'Delete Landing Object') delete_landing_object = true;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <Grid item xs={3.5}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                onClick={() => this.props.setState({ open_create: true })}>
                <AddIcon style={{ color: 'green' }} />
              </Button>
              <ButtonGroupLoading
                disabled={!rows_edited.length}
                icon={
                  <SaveIcon
                    style={{ color: rows_edited.length ? 'blue' : 'gray' }}
                  />
                }
                loading={this.state.updating}
                onClick={this.handleUpdateLanding}
                tooltip="Update Landing object"
              />
              <Button
                disabled={!idFolder}
                onClick={() => {
                  this.props.history.push(`/explorer/folder/${idFolder}`);
                }}>
                <LaunchIcon style={{ color: idFolder ? 'green' : 'gray' }} />
              </Button>
              <ButtonGroupLoading
                disabled={!numSelected}
                icon={
                  <CloudUploadIcon
                    style={{ color: numSelected ? '#311b92' : 'gray' }}
                  />
                }
                loading={this.state.proccessing}
                onClick={this.handleReRunLandingObject}
                tooltip="Run Landing object"
              />
              <ButtonGroupLoading
                icon={<DeleteForeverIcon style={{ color: 'red' }} />}
                loading={this.state.deleting}
                onClick={() =>
                  this.setState({
                    open_delete: true,
                    title: 'Delete Landing Finished',
                  })
                }
                tooltip="Delete finished"
              />
            </ButtonGroup>
          </Grid>

          <Grid item xs>
            <SearchText
              handleSearchText={text => this.props.handleReloadTable(text)}
              holder={this.props.holder}
              placeholder="Search name..."
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            pr={2}
            xs={2}>
            <Grid item>
              <ButtonReloadDelete
                className={classes.margin}
                handleClearSelected={this.props.handleClearSelected}
                handleDeleteSelected={() =>
                  this.setState({
                    open_delete: true,
                    title: 'Delete Landing Object',
                  })
                }
                handleReloadTable={() => this.props.handleReloadTable()}
                loading={this.state.deleting}
                loading_table={this.props.loading_table}
                numSelected={numSelected}
                small
                tooltip="Delete Landing Objects"
                wrapper={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={(shared, other, delete_landing) => {
            if (delete_landing_object) this.handleDeleteLanding(delete_landing);
            else this.handleDeleteLandingFinished();
          }}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="Delete Landing Object was triggered, this action cannot be undone."
          text_check={
            delete_landing_object ? 'Keep folder content object' : undefined
          }
          title_text={this.state.title}
        />
      </Paper>
    );
  }
}

ToolBarLandingStopped.propTypes = {
  handleClearSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  transform: PropTypes.bool,
};
ToolBarLandingStopped.defaultProps = {
  loading_table: false,
  loading: false,
  handleReloadTable: () => console.log('handleReloadTable'),
  tooltip: 'Delete',
  handleClearSelected: () => '',
  handleReRunLandingObject: () => '',
  transform: false,
  rows_edited: [],
};
export default withStyles(withRouter(ToolBarLandingStopped), useStyles);
