export { default as CardProjectHeader } from './CardProjectHeader';
export { default as CardProjectActions } from './CardProjectActions';
export { default as CardButtonActions } from './CardButtonActions';
export const useStyles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    height: '100%',
    width: '100%',
  },
  table: {
    hight: 'auto',
    margin: theme.spacing(0, 2, 2, 2),
  },
});
