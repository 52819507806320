import React from 'react';
import PropTypes from 'prop-types';
import {
  HomeFooter,
  HomeFooterInformation,
  HomeHeader,
  HomeLogos,
  HomeFooterSubscribe,
} from 'views/Home/components';
import { HomeMain } from 'components';
import 'views/Home/Home.css';
import { Typography } from '@mui/material';
import useStyles from 'views/Home/Styles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

class HomeAboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user, isDesktop, isAuthenticated } = this.props;
    return (
      <React.Fragment>
        <HomeHeader
          changeColorOnScroll={{
            height: 200,
            color: 'white',
          }}
          color="transparent"
          fixed
          isAuthenticated={isAuthenticated}
          isDesktop={isDesktop}
          user={user}
        />
        <HomeMain image={'/images/aboutus.jpg'} medium>
          <div className={classes.container}>
            <Typography
              sx={() => {
                // console.log({ breakpoints, typography });
                return {
                  // [breakpoints.down('md')]: {
                  //   fontSize: size['3xl'],
                  // },
                  color: 'white',
                };
              }}
              variant="h1">
              About Us
            </Typography>
            <Typography
              color="white"
              mb={3}
              mt={1}
              opacity={0.8}
              sx={{ fontSize: 18, textAlign: 'justify' }}
              variant="body1">
              We&apos;re a team of highly skilled engineers, medical doctors,
              researchers, and IT specialists, dedicated to advancing the field
              of biomedical research. Our goal is to provide innovative tools
              for storing, sharing, linking, and analyzing clinical data, in
              order to improve the understanding of diseases and their
              treatments. With a diverse range of expertise, we work together to
              create cutting-edge solutions that enable researchers to access
              and analyze large amounts of data quickly and efficiently,
              ultimately leading to better patient outcomes. We are committed to
              the highest standards of quality and rigor in our work, and we are
              proud to be at the forefront of the biomedical research community.
            </Typography>
          </div>
        </HomeMain>
        <div className={clsx(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            {/* <TeamSection /> */}
            <HomeLogos />
            <HomeFooterSubscribe
              handleAddError={this.props.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
            />
          </div>
        </div>
        <HomeFooterInformation />
        <HomeFooter />
      </React.Fragment>
    );
  }
}

HomeAboutUs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(HomeAboutUs, useStyles);
