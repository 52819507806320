import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { FormatTime, getInitials, getUserFullName } from 'helpers';
import { useStyles } from '../utils';
import { BadgePulsar } from 'components';

class TableSiteUsersRow extends Component {
  render() {
    const {
      classes,
      isItemSelected,
      labelId,
      dense,
      handleSelected,
      row,
    } = this.props;
    const username = getUserFullName(row);
    let type = 'Missing';
    let { isActive } = row;
    // console.log({ isActive });
    if (row.account) {
      const { description } = row.account;
      if (description) type = description;
    }
    let ComponentActive = null;
    let isOnline = false;
    if (row.isOnline) {
      isOnline = true;
      isActive = true;
      ComponentActive = (
        <BadgePulsar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          overlap="circular"
          style={{ marginRight: 16 }}
          variant="dot">
          <Avatar className={classes.cell_avatar_login} src={row.avatar}>
            {getInitials(username)}
          </Avatar>
        </BadgePulsar>
      );
    } else {
      ComponentActive = (
        <Avatar className={classes.cell_avatar} src={row.avatar}>
          {getInitials(username)}
        </Avatar>
      );
    }
    let LastLogin = 'No login activity';
    if (row.last_login) {
      LastLogin = FormatTime(row.last_login, 'MMM Do - YYYY, h:mm a');
    }
    let classNameActive = {
      [classes.online]: isOnline,
      [classes.red]: !isActive,
    };
    return (
      <TableRow
        className={classes.table_row}
        hover
        key={`table-row-${row.id}`}
        selected={isItemSelected}
        tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            color="default"
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={() => {
              handleSelected(row);
            }}
          />
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell component="th" id={labelId} padding="none" scope="row">
          <div className={clsx(classes.cell_user, classNameActive)}>
            {ComponentActive}
            <Typography className={clsx(classNameActive)} variant="body1">
              {username}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="left" className={clsx(classNameActive)}>
          {type}
        </TableCell>
        <TableCell align="left" className={clsx(classNameActive)}>
          {row.email}
        </TableCell>
        <TableCell align="right" className={clsx(classNameActive)}>
          {row.username}
        </TableCell>
        <TableCell align="right" className={clsx(classNameActive)}>
          {row.address ? row.address.country : ''}
        </TableCell>
        <TableCell
          align="right"
          className={clsx(classes.date_width, classNameActive)}>
          {LastLogin}
        </TableCell>
        <TableCell
          align="right"
          className={clsx(classes.date_width, classNameActive)}>
          {FormatTime(row.createdAt, 'MMM Do - YYYY, h:mm a')}
        </TableCell>
        <TableCell align="right">
          <Button
            className={clsx({
              [classes.button_no_dense]: true,
              [classes.button_dense]: dense,
            })}
            color={isActive ? 'primary' : 'error'}
            onClick={() => this.props.handleEditUser(row)}
            variant="outlined">
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

TableSiteUsersRow.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TableSiteUsersRow, useStyles);
