import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { setDatabaseDisplay } from 'redux-store/actions/page_database';

const useStyles = () => ({
  root: {
    margin: 0,
    padding: 0,
  },
});

class ToogleStudyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view_type: 0,
    };
  }

  handleViewType = (event, value) => {
    this.props.setDatabaseDisplay(value);
  };

  render() {
    const { classes, display_database } = this.props;
    //
    return (
      <ToggleButtonGroup
        aria-label="toggle_buttons_view"
        className={classes.root}
        exclusive
        onChange={this.handleViewType}
        size="small"
        value={display_database}>
        <ToggleButton aria-label="left aligned" value="grid">
          <ViewModuleIcon />
        </ToggleButton>
        <ToggleButton aria-label="centered" value="list">
          <ViewListIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

ToogleStudyView.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { display_database } = state.page_database;
  return {
    display_database,
  };
};
export default connect(mapStateToProps, { setDatabaseDisplay })(
  withStyles(ToogleStudyView, useStyles)
);
