import { shader_2D_IMAGE } from '../../utils/shader';
import { m4, webglViewer, webglDraw } from '../../utils';
import { Vector3D } from 'helpers/Utils3D';
class ImageWebGL {
  constructor(gl) {
    this.plane_vertices = [];
    this.plane_normal = new Vector3D(0, 0, 1);
    this.plane_center = new Vector3D();
    this.gl = gl;
    this.dicom_slice = null;
    // setup GLSL program
    this.program = webglViewer.createProgramFromString(
      this.gl,
      shader_2D_IMAGE
    );

    // look up where the vertex data needs to go.
    this.positionLocation = this.gl.getAttribLocation(
      this.program,
      'a_position'
    );
    this.texcoordLocation = this.gl.getAttribLocation(
      this.program,
      'a_texcoord'
    );

    // lookup uniforms
    this.matrixLocation = this.gl.getUniformLocation(this.program, 'u_matrix');
    this.textureLocation = this.gl.getUniformLocation(
      this.program,
      'u_texture'
    );

    this.positionBuffer = null;
    this.textureInfo = null;
  }
  IsReady() {
    return this.positionBuffer ? true : false;
  }
  getPlaneVertices() {
    return this.plane_vertices;
  }
  getPlaneNormal() {
    return this.plane_normal;
  }
  getPlaneCenter() {
    return this.plane_center;
  }
  getMaximum() {
    if (this.dicom_slice) return this.dicom_slice.getMaximum();
    return 0;
  }
  getMinimum() {
    if (this.dicom_slice) return this.dicom_slice.getMinimum();
    return 0;
  }
  getContrast() {
    if (this.dicom_slice) return this.dicom_slice.getContrast();
    return null;
  }
  setMinMax(min, max) {
    if (!this.dicom_slice) return;
    this.dicom_slice.setMinMax(min, max);
    this.updateSlice(this.dicom_slice);
  }
  setImage(dicom_slice) {
    if (!dicom_slice || dicom_slice === undefined) {
      console.log('setImage - null');
      return;
    }
    this.dicom_slice = dicom_slice;
    // Create a buffer to put positions in
    this.positionBuffer = this.gl.createBuffer();
    // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    // Put geometry data into buffer
    this.plane_vertices = dicom_slice.getPlaneVertices(-1);
    this.plane_normal = dicom_slice.getPlaneNormal();
    this.plane_center = dicom_slice.getPlaneCenter();

    // console.log({ plane: this.plane_vertices });
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(this.plane_vertices),
      this.gl.STATIC_DRAW
    );
    // Create a buffer for texture coords
    this.texcoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texcoordBuffer);

    // Put texcoords in the buffer
    const A = [0, 0];
    const B = [0, 1];
    const C = [1, 1];
    const D = [1, 0];
    const T1 = A.concat(B).concat(C);
    const T2 = A.concat(C).concat(D);
    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      // prettier-ignore
      new Float32Array(T1.concat(T2)),
      this.gl.STATIC_DRAW
    );
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }
  updateSlice(dicom_slice) {
    this.setImage(dicom_slice);
    this.textureInfo = webglDraw.loadImageAndCreateTextureInfo(
      this,
      dicom_slice
    );
    // console.log('loaded image ');
  }
  draw(viewProjectionMatrix) {
    if (!this.textureInfo) {
      if (this.dicom_slice) {
        this.updateSlice(this.dicom_slice);
      } else {
        console.log('--- RENDER IMAGE - EMPTY -----');
        return;
      }
    } else {
      // console.log('--- RENDER IMAGE - TEXTURE -----');
    }
    const gl = this.gl;
    const program = this.program;
    const positionLocation = this.positionLocation;
    const positionBuffer = this.positionBuffer;
    const matrixLocation = this.matrixLocation;
    const texcoordBuffer = this.texcoordBuffer;
    const textureLocation = this.textureLocation;
    const texcoordLocation = this.texcoordLocation;

    // starting with the view projection matrix
    // compute a matrix for the F
    var matrix = m4.translate(viewProjectionMatrix, 0, 0, 0);

    // Tell it to use our program (pair of shaders)
    gl.useProgram(program);

    // Turn on the position attribute
    gl.enableVertexAttribArray(positionLocation);

    // Bind the position buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    var size = 3; // 3 components per iteration
    var type = gl.FLOAT; // the data is 32bit floats
    var normalize = false; // don't normalize the data
    var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    var offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(
      positionLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );

    /* ---------- TEXTURE ----------------------------- */
    // Turn on the texcoord attribute
    gl.enableVertexAttribArray(texcoordLocation);

    // bind the texcoord buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);

    // Tell the texcoord attribute how to get data out of texcoordBuffer (ARRAY_BUFFER)
    gl.vertexAttribPointer(
      texcoordLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );
    /* ---------- TEXTURE ----------------------------- */

    // Set the matrix.
    gl.uniformMatrix4fv(matrixLocation, false, matrix);

    // Tell the shader to use texture unit 0 for u_texture
    gl.uniform1i(textureLocation, 0);

    // Draw the geometry.
    var primitiveType = gl.TRIANGLES;
    // var offset = 0;
    var count = 6;
    gl.drawArrays(primitiveType, offset, count);
  }
}

export default ImageWebGL;
