import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Breadcrumbs, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { ExploreCrumbs } from './components';
import { setSubFolders } from 'redux-store/actions';

const useStyles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingRight: 0,
    marginRight: 0,
    // height: 50,
  },
});

class ToolBarSharedTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleHome = () => {
    this.props.setSubFolders([]);
    const { history, code } = this.props;
    history.push(`/shared/folder/${code}`);
  };
  handleClick = folder => {
    let { subfolders } = this.props;
    const { idFolder } = folder;
    const index = subfolders.map(x => x.idFolder).indexOf(idFolder);
    if (index === -1) {
      subfolders.push(folder);
    } else {
      subfolders = subfolders.splice(0, index + 1);
    }
    this.props.setSubFolders(subfolders);
  };

  render() {
    const { classes, code, home, subfolders } = this.props;
    //
    if (!home) return null;
    return (
      <Toolbar className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <ExploreCrumbs
            code={code}
            handleHome={this.handleHome}
            home={home}
            subfolder={home}
          />
          {subfolders.map((subfolder, index) => {
            return (
              <ExploreCrumbs
                code={code}
                handleClick={this.handleClick}
                home={home}
                key={`item-sub-folder-${index}`}
                subfolder={subfolder}
              />
            );
          })}
        </Breadcrumbs>
      </Toolbar>
    );
  }
}

ToolBarSharedTop.propTypes = {
  classes: PropTypes.object,
};
ToolBarSharedTop.defaultProps = {
  type: 'Unknown',
  subfolders: [],
};
const mapStateToProps = state => ({
  subfolders: state.page_shared.subfolders,
});

export default connect(mapStateToProps, {
  setSubFolders,
})(withStyles(withRouter(ToolBarSharedTop), useStyles));
