import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import { AutoComboBox } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';
import { QueryAccountTypes } from 'graphql/Users/utils_users';
import { IsInvalid } from 'helpers';

class DialogAccountDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      account_types: [],
      loading_images: false,
      loading_types: false,
      selected_type: null,
      selected_docker: null,
      init: false,
    };
  }
  componentDidUpdate() {
    if (this.props.open && !this.state.init) {
      this.handleUpdateImages();
      this.updateAccounts();
    }
  }
  handleUpdateImages = () => {
    if (!this.props.isActive) return;
    const { loading_images } = this.state;
    if (loading_images) return;
    this.setState({ loading_images: true });
    (async () => {
      QuerySystemDockerImages()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images, total } = data.systemDockerImages;
          if (ok) {
            images.unshift({ operation: 'None', id: 0 });
            this.setState({ loading_images: false, images, total, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_images: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  updateAccounts = () => {
    if (!this.props.isActive) return;
    const { loading_types } = this.state;
    if (loading_types) return;
    this.setState({ loading_types: true, empty: false });
    (async () => {
      QueryAccountTypes()
        .then(res => {
          const data = GetData(res);
          const { ok, accounts, errors } = data.accountTypes;
          if (ok) {
            accounts.unshift({ description: 'None', id: 0 });
            this.setState({
              account_types: accounts,
              loading_types: false,
              empty: !accounts.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_types: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddImage = () => {
    const { selected_docker, selected_type } = this.state;
    if (IsInvalid(selected_docker) || IsInvalid(selected_type)) {
      this.props.handleAddError(
        'You need to select a Type and a Docker process'
      );
      return;
    }
    this.props.handleAddImage(selected_type, selected_docker);
  };
  render() {
    const { open, handleClose } = this.props;
    if (!open) return null;
    const { images, account_types } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add Docker Image to User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select an Account Type and docker process and press ADD.
          </DialogContentText>
          <Grid container pt={2} spacing={2}>
            <Grid item xs={12}>
              <AutoComboBox
                defaultValue={account_types[0]}
                disabled={this.state.loading_types}
                getOptionLabel={option => {
                  if (option === undefined || option.description === undefined)
                    return '';
                  return option.description;
                }}
                label="Account Type"
                onObjectChanged={selected_type =>
                  this.setState({ selected_type })
                }
                options={account_types}
                small
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComboBox
                defaultValue={images[0]}
                disabled={this.state.loading_images}
                fullWidth
                getOptionLabel={option => {
                  if (option === undefined || option.operation === undefined)
                    return '';
                  return option.operation;
                }}
                label="Docker Process"
                onObjectChanged={selected_docker =>
                  this.setState({ selected_docker })
                }
                options={images}
                small
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAddImage}>Add</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogAccountDockers.propTypes = {
  open: PropTypes.bool,
};

export default DialogAccountDockers;
