/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, InputAdornment } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from 'moment';

import {
  useStyles,
  FormatDate,
  IsWrongFormat,
  IsGreaterThan,
} from '../utils_picker';
import { inputProps } from 'common';
const ExampleCustomInput = forwardRef(
  (
    {
      value,
      onClick,
      text_size,
      label,
      classes,
      disabled,
      error,
      value_text,
      onChangeDate,
    },
    ref
  ) => {
    const parts = value.split('-');
    let text_from = FormatDate(parts[0]);
    let text_to = FormatDate(parts[1]);
    let final_text = `${text_from} - ${text_to}`;
    if (value_text) final_text = value_text;
    return (
      <TextField
        className={classes.base}
        disabled={disabled}
        error={error}
        fullWidth
        id="filled-start-adornment"
        InputProps={{
          style: { paddingRight: 4 },
          classes: {
            input: classes.fontSize,
          },
          endAdornment: (
            <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
              <DateRangeIcon onClick={onClick} />
            </InputAdornment>
          ),
          ...inputProps,
        }}
        label={label}
        onChange={event => {
          onChangeDate(event.target.value);
        }}
        ref={ref}
        size={text_size}
        style={{ padding: 0 }}
        sx={{ paddingRight: 0 }}
        value={final_text}
      />
    );
  }
);
const DateRangePicker = props => {
  let { dateend, datestart, handleDateChange, disabled, holder } = props;
  if (dateend) {
    let l = datestart.split('-').length;
    if (l === 3) {
      const parts = datestart.split('-').map(part => part.padStart(2, '0'));
      datestart = parts.join('-');
    }
  }
  if (datestart) {
    const l = dateend.split('-').length;
    if (l === 3) {
      const parts = dateend.split('-').map(part => part.padStart(2, '0'));
      dateend = parts.join('-');
    }
  }
  const A = datestart ? new Date(datestart) : new Date();
  const B = dateend ? new Date(dateend) : new Date();
  const { classes } = useStyles();
  const [dateRange, setDateRange] = useState([A, B]);
  const [textDate, setTextDate] = useState(null);
  const [error, setError] = useState(false);
  const [startDate, endDate] = dateRange;
  if (holder) {
    holder.setRange = (A, B) => {
      updateDate([new Date(A), new Date(B)]);
    };
  }
  const updateDate = new_date => {
    if (new_date[0] && new_date[1]) {
      handleDateChange(new_date);
    }
    setDateRange(new_date);
    setError(false);
    setTextDate(null);
  };
  const onChangeText = text => {
    setTextDate(text);
    try {
      const parts = text.split('-');
      if (
        parts.length !== 2 ||
        IsWrongFormat(parts[0]) ||
        IsWrongFormat(parts[1]) ||
        IsGreaterThan(parts[0], parts[1], 'DD/MM/YYYY')
      ) {
        setError(true);
        return;
      }
      setError(false);
      const date_from = moment(parts[0].trim(), 'DD/MM/YYYY');
      const date_to = moment(parts[1].trim(), 'DD/MM/YYYY');
      console.log({ date_from, date_to });
      updateDate([date_from.toDate(), date_to.toDate()]);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log({ startDate, endDate });
  return (
    <DatePicker
      className="red-border"
      customInput={
        <ExampleCustomInput
          classes={classes}
          disabled={disabled}
          error={error}
          label={props.label}
          onChangeDate={onChangeText}
          text_size={props.text_size}
          value_text={textDate}
        />
      }
      dateFormat="P"
      disabled={disabled}
      endDate={endDate}
      monthsShown={props.monthsShown}
      onChange={updateDate}
      selectsRange
      showYearDropdown
      startDate={startDate}
      withPortal={props.withPortal}
    />
  );
};
DateRangePicker.propTypes = {
  dateend: PropTypes.string,
  datestart: PropTypes.string,
};
DateRangePicker.defaultProps = {
  datestart: null,
  dateend: null,
  label: 'Date Range',
  text_size: 'medium',
  handleDateChange: () => '',
  withPortal: true,
  monthsShown: 1,
};
export default DateRangePicker;
