import { IsInvalid } from 'helpers';

export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    style: {
      width: 75,
    },
    align: 'left',
  },
  {
    field: 'type_name',
    headerName: 'Type Name',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const type = params.getValue(params.id, 'type');
      if (type === undefined || !type) return '';
      const { name } = type;
      if (name === undefined || !name) return '';
      return name;
    },
  },
  {
    field: 'level',
    headerName: 'Level',
    style: {
      width: 75,
    },
    align: 'left',
    edit: true,
  },
];
