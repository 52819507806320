import { red } from '@mui/material/colors';

const useStyles = theme => ({
  root: {
    minHeight: 300,
    height: '100%',
    maxHeight: 370,
    minWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  button: {
    margin: 0,
    marginLeft: 'auto',
  },
  tableWrapper: {
    minHeight: 350,
    maxHeight: 440,
    overflow: 'auto',
  },
});
export default useStyles;
