import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { CircularLoading } from '../index';

const useStyles = () => ({
  container: {
    minHeight: '50vh',
    position: 'relative',
  },
  child: {
    // width: '50px',
    // height: '50px',
    /* Center vertically and horizontally */
    position: 'absolute',
    top: '50%',
    left: '50%',
    // margin: theme.spacing(
    //   -25,
    //   0,
    //   0,
    //   -25
    // ) /* apply negative top and left margins to truly center the element */,
  },
});

class BoxWaiting extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.child}>
          <CircularLoading />
        </div>
      </div>
    );
  }
}

BoxWaiting.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(BoxWaiting, useStyles);
