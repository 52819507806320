import { AngleWebGL } from './SingleObjects';
class DrawWebGLAngles {
  constructor(gl) {
    this.gl = gl;
    this.currentAngle = null;
    this.angles = [];
  }
  moveClick(point3d, action) {
    if (!point3d || point3d === undefined) return;
    if (action !== 'down') return;
    if (this.currentAngle) {
      if (this.currentAngle.IsLine1Ready()) {
        this.currentAngle.setEndLine2(point3d, true);
        this.angles.push(this.currentAngle);
        this.currentAngle = null;
      } else {
        this.currentAngle.setEndLine1(point3d, true);
      }
    } else {
      this.currentAngle = new AngleWebGL(this.gl);
      this.currentAngle.setOrigin(point3d);
    }
  }
  moveMove(point3d) {
    if (!point3d || point3d === undefined) return;
    if (this.currentAngle) {
      if (this.currentAngle.IsLine1Ready()) {
        this.currentAngle.setEndLine2(point3d);
      } else {
        this.currentAngle.setEndLine1(point3d);
      }
    }
  }
  draw(viewProjectionMatrix, projectionMatrix) {
    if (this.currentAngle) {
      this.currentAngle.draw(viewProjectionMatrix, projectionMatrix);
    }
    for (let i = 0; i < this.angles.length; i++) {
      this.angles[i].draw(viewProjectionMatrix, projectionMatrix);
    }
  }
  clean() {
    this.currentAngle = null;
    this.angles = [];
  }
}
export default DrawWebGLAngles;
