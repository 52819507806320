import moment from 'moment';

export const getDatePacs = value => {
  if (!value) {
    return '';
  }
  return moment(value, 'YYYYMMDD').format('DD/MM/YYYY');
};
export const getTimePacs = value => {
  if (!value) {
    return '';
  }
  //085003.112
  return moment(value, 'hhmmss.').format('hh:mm:ss');
};

export const FormatSeriesTime = (value, format) => {
  if (typeof value === 'undefined' || value === '') {
    if (value === 'Invalid date') return value;
    return '';
  }
  if (typeof format === 'undefined') {
    format = 'h:mm:ss a';
  }
  return moment(value, 'HH:mm:ss').format(format);
};

export const FormatSeriesDate = (value, format) => {
  if (typeof value === 'undefined' || value === '') {
    if (value === 'Invalid date') return value;
    return '';
  }
  if (typeof format === 'undefined') {
    format = 'MMMM Do YYYY';
  }
  return moment(value, 'YYYY-MM-DD').format(format);
};
