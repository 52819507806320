import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import {
  Fab,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { DivGrow, DatePicker } from 'components';
import { inputProps } from 'common';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(2),
    width: 50,
  },
  radio_top: {
    // marginTop: theme.spacing(1),
    // paddingRight: 20,
    // paddingLeft: 20,
  },
  date_picker: {
    width: 100,
  },
  base: {
    margin: 0,
    minWidth: 80,
    maxWidth: 80,
    paddingRight: 10,
  },
  fontSize: {
    fontSize: 12,
    // padding: 0,
    paddingLeft: '15px',
    paddingRight: '5px',
    // direction: 'rtl',
  },
});

class TabBirthDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      birthDate: '1973-09-05',
      radioValue: 'before',
      age: 1,
    };
    const { tab_holder, index, query } = props;
    if (tab_holder) {
      const PatientBirthDate = this.getPatientBirthDate();
      tab_holder.getValues[index] = () => ({
        type: query,
        value: PatientBirthDate,
      });
    }
  }

  handleChange = (event, newValue) => {
    event.preventDefault();
    this.setState({ radioValue: newValue });
  };
  handleDateChange = date => {
    this.setState({ birthDate: date });
  };
  handleAgeChange = event => {
    this.setState({ age: event.target.value });
  };
  getPatientBirthDate = () => {
    const { radioValue } = this.state;
    const IN_F = 'YYYY-MM-DD';
    const OUT_F = 'YYYYMMDD';
    let PatientBirthDate = this.state.birthDate;
    if (radioValue === 'before') {
      PatientBirthDate = '-' + moment(this.state.birthDate, IN_F).format(OUT_F);
    } else if (radioValue === '45y') {
      PatientBirthDate = moment()
        .subtract(45, 'years')
        .format(OUT_F);
    } else if (radioValue === 'age') {
      PatientBirthDate = moment()
        .subtract(this.state.age, 'years')
        .format(OUT_F);
    } else if (radioValue === 'after') {
      PatientBirthDate = moment(this.state.birthDate, IN_F).format(OUT_F) + '-';
    } else if (radioValue === 'exactly') {
      PatientBirthDate = moment(this.state.birthDate, IN_F).format(OUT_F);
    }
    return PatientBirthDate;
  };
  handleExecuteSearch = event => {
    if (!event) {
      return;
    }
    const PatientBirthDate = this.getPatientBirthDate();
    this.props.executeSearch(PatientBirthDate, 'PatientBirthDate', event);
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { birthDate, radioValue, age } = this.state;
    const sylte_radio = {
      size: 'small',
      // style: {
      //   margin: 0,
      //   padding: 0,
      //   paddingLeft: 2,
      //   paddingTop: 5,
      // },
    };
    return (
      // <LocalizationProvider dateAdapter={AdapterDateFns}>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        onChange={this.handleChange}
        value={radioValue}>
        <Grid alignItems="center" container direction="row">
          <FormControlLabel
            className={classes.radio_top}
            control={<Radio {...sylte_radio} />}
            label="45Y"
            value="45y"
          />
          <FormControlLabel
            className={classes.radio_top}
            control={<Radio {...sylte_radio} />}
            label="Age"
            value="age"
          />
          {radioValue === 'age' && (
            <Grid item>
              <TextField
                className={classes.base}
                id="outlined-age"
                InputProps={{
                  // style: { padding: 0 },
                  classes: {
                    input: classes.fontSize,
                  },
                  ...inputProps,
                }}
                margin="normal"
                onChange={this.handleAgeChange}
                size="small"
                type="number"
                value={age}
                variant="outlined"
              />
            </Grid>
          )}
          <FormControlLabel
            className={classes.radio_top}
            control={<Radio {...sylte_radio} />}
            label="Before"
            value="before"
          />
          <FormControlLabel
            className={classes.radio_top}
            control={<Radio {...sylte_radio} />}
            label="After"
            value="after"
          />
          <FormControlLabel
            className={classes.radio_top}
            control={<Radio {...sylte_radio} />}
            label="Exactly"
            value="exactly"
          />
          <DatePicker
            date={birthDate}
            // disabled={disable_at_date}
            handleDateChange={this.handleDateChange}
            label=""
          />
          <DivGrow />
          <Fab
            aria-label="add"
            className={classes.margin}
            color="secondary"
            onClick={this.handleExecuteSearch}
            size="small">
            <SearchIcon />
          </Fab>
        </Grid>
      </RadioGroup>
      // {/* </LocalizationProvider> */}
    );
  }
}

TabBirthDate.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabBirthDate, useStyles);
