import React from 'react';
import PropTypes from 'prop-types';

// import clsx from 'clsx';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';

import { TableInputsHead, VolumeCheckBox } from './components';
import { useStylesTableShare, headCells } from './Styles';
import { getRightIndex } from 'helpers/utils_rights';
import { SelectedArray, getSorting, stableSort } from 'helpers';

class TableInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      dense: props.dense ? true : false,
      rowsPerPage: 100,
      set: false,
      inputs: [],
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getPermission = () => {
        return this.state.rights;
      };
      holder.getSelected = () => {
        return this.state.selected;
      };
      holder.cleanSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }

  getSelected = () => {
    if (this.props.selected) {
      return this.props.selected;
    } else {
      return this.state.selected;
    }
  };
  setSelected = selected => {
    if (this.props.selected) {
      this.props.handleSelected(selected);
    } else {
      this.setState({ selected });
    }
  };
  setOrder = newOrder => {
    this.setState({ order: newOrder });
  };
  setOrderBy = newOrderBy => {
    this.setState({ orderBy: newOrderBy });
  };
  setPage = newPage => {
    this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setOrder(isDesc ? 'asc' : 'desc');
    this.setOrderBy(property);
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setSelected(this.props.rights);
    } else {
      this.setSelected([]);
    }
  };

  handleSelected = row => {
    const selected = this.getSelected();
    const selectedIndex = selected
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(row));
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setSelected(newSelected);
  };

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  handleChangeDense = event => {
    this.setDense(event.target.checked);
  };
  handleCheckedVolumes = (event, row, index) => {
    this.props.handleCheckedChanged(event, row, index);
  };
  isSelected = row => {
    const selected = this.getSelected();
    const index = selected.map(x => x.idSeries === row.idSeries);
    return index !== -1;
  };

  render() {
    const { classes, short_label, rows, inputs } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const selected = this.getSelected();
    // let emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    // if (!rows.length) {
    //   emptyRows = 2;
    // }
    // let colSpan = 3;
    // if (!this.props.disable_checkbox) {
    //   colSpan = 2;
    // }
    let TableCellCheckBox = null;
    let volumes = [];
    let headers = [];
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        const element = inputs[i];
        volumes.push({
          align: 'center',
          disablePadding: true,
          id: 'volume_' + i,
          label: element.volume,
          numeric: false,
        });
      }
      headers = headCells.concat(volumes);
    }
    let isSingleVolume = volumes.length === 1;
    return (
      <Paper>
        <Table
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
          className={classes.table}
          size={this.state.dense ? 'small' : 'medium'}
          stickyHeader>
          <TableInputsHead
            classes={classes}
            disable_checkbox={this.props.disable_checkbox}
            headCells={headers}
            numSelected={selected.length}
            onRequestSort={this.handleRequestSort}
            onSelectAllClick={this.handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
            short_label={short_label}
          />
          <TableBody style={{ width: '100%' }}>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = this.isSelected(row)[0];
                const labelId = `enhanced-table-checkbox-${index}`;
                if (!this.props.disable_checkbox) {
                  TableCellCheckBox = (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={() => {
                          this.handleSelected(row);
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    hover
                    key={`table-row-${index}`}
                    selected={isItemSelected}
                    tabIndex={-1}>
                    {TableCellCheckBox}
                    <TableCell align="left">{row.sequence_name}</TableCell>
                    <TableCell align="left">{row.idJob}</TableCell>
                    {row.volumes.map((input, index) => {
                      return (
                        <TableCell align="center" key={index}>
                          <VolumeCheckBox
                            checked={input.value}
                            disabled={isSingleVolume}
                            handleChecked={event =>
                              this.handleCheckedVolumes(event, row, index)
                            }
                            title={''}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

TableInputs.defaultProps = {
  selected: null,
  rights: [],
  handleSelected: total =>
    console.log(`TableInputs -> handleSelected -> Total: ${total}`),
  handleCheckedChanged: () => console.log('handleCheckedChanged'),
  disable_checkbox: false,
};

TableInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  disable_checkbox: PropTypes.bool.isRequired,
  rights: PropTypes.array.isRequired,
};
export default withStyles(TableInputs, useStylesTableShare);
