import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';

import { Message } from './components';

const useStyles = () => ({
  root: {
    width: '100%',
    height: '77.3vh',
    // backgroundColor: 'red'
    margin: 0,
    padding: 1,
  },
  root_messages: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    transform: 'scaleY(-1)',
  },
  messages: {
    width: '100%',
  },
});

class DirectChatMessages extends React.Component {
  render() {
    const { classes, messages, idReceiver, height } = this.props;
    return (
      <div style={{ width: '100%', height: height - 125 }}>
        <div className={classes.root_messages}>
          <div className={classes.messages}>
            {messages.map(message => {
              //
              if (message.sender.id === idReceiver) {
                return <Message key={message.id} left message={message} />;
              } else {
                return <Message key={message.id} message={message} />;
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

DirectChatMessages.propTypes = {
  classes: PropTypes.object,
  idReceiver: PropTypes.number,
  messages: PropTypes.array,
};
export default withStyles(DirectChatMessages, useStyles);
