import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  TableSurveyPatients,
  SearchToolBar,
  SurveySummary,
} from './components';
import { QuerySurveysAtPatient, QuerySurveysPatients } from 'graphql/Surveys';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { withRouter } from 'react-router-dom';
import { getParams } from 'helpers';
class DashboardSurveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      patients: [],
      patient: null,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  handleQueryPatient = async patients => {
    let patient_final = null;
    const params = getParams(this.props);
    const { level_1, level_2 } = params;
    if (level_1 && level_2) {
      const idPatient = parseInt(level_2, 10);
      if (idPatient) {
        const index = patients.map(x => x.id).indexOf(idPatient);
        if (index !== -1) {
          patient_final = patients[index];
        } else {
          try {
            const res = await QuerySurveysAtPatient(idPatient);
            const data = GetData(res);
            const { ok, patient, errors } = data.surveyAtPatient;
            console.log({ patient });
            if (ok) {
              patient_final = patient;
              patients.push(patient);
            } else {
              throw errors;
            }
          } catch (error) {
            this.props.history.push('/dashboard');
          }
        }
      }
    }
    return patient_final;
  };
  handleQuery = (patient_name = '') => {
    console.log('handleQuery');
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySurveysPatients(patient_name)
        .then(async res => {
          const data = GetData(res);
          const { ok, patients, errors } = data.allSurveyPatients;
          if (ok) {
            const patient = await this.handleQueryPatient(patients);
            this.setState({ patients, loading: false, patient });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddNewPatient = patient => {
    const { patients } = this.state;
    const index = patients.map(x => x.id).indexOf(patient.id);
    console.log({ patient, patients, index });
    if (index === -1) {
      patients.unshift(patient);
      this.setState({ patients });
    }
  };
  handleOpenPatient = patient => {
    if (patient) this.props.history.push(`/dashboard/survey/${patient.id}`);
    this.setState({ patient });
  };
  clearPatient = () => {
    this.props.history.push('/dashboard');
    this.setState({ patient: null, contentType: undefined });
  };
  render() {
    const { patients, patient } = this.state;
    let height = this.props.height - 190;
    if (patient)
      return (
        <SurveySummary
          clearPatient={this.clearPatient}
          contentType={this.state.contentType}
          currentPatient={patient}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleAddWarning={this.props.handleAddWarning}
          height={height}
          onClose={this.clearPatient}
          setcontentType={contentType => this.setState({ contentType })}
        />
      );
    return (
      <Grid container spacing={4} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <SearchToolBar
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleAddWarning={this.props.handleAddWarning}
            handleTextChanged={text => this.handleQuery(text)}
          />
        </Grid>
        <Grid item xs={12}>
          <TableSurveyPatients
            handleAddError={this.props.handleAddError}
            handleAddNewPatient={this.handleAddNewPatient}
            handleAddSuccess={this.props.handleAddSuccess}
            handleAddWarning={this.props.handleAddWarning}
            handleOpenPatient={this.handleOpenPatient}
            handleReload={() => this.handleQuery()}
            height={height}
            loading={this.state.loading}
            rows={patients}
          />
        </Grid>
      </Grid>
    );
  }
}

DashboardSurveys.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(DashboardSurveys);
