import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { GetProjectTypes } from 'helpers';
import { connect } from 'react-redux';

class ProjectType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      set: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getType = () => this.state.type;
    }
  }

  setFromProps = () => {
    const {
      project: { type },
    } = this.props;
    if (typeof type === 'undefined') return;
    this.setState({ type, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    if (!this.state.set) {
      this.setFromProps();
    }
  }

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
    const {
      project: { type },
    } = this.props;
    if (typeof type === 'undefined') return;
    this.props.handleChange('type', type !== event.target.value);
  };

  render() {
    const { admin } = this.props;
    const { type } = this.state;
    const project_types = GetProjectTypes(this.props);
    const value = project_types[type];
    //
    return (
      <TextField
        disabled={!admin}
        fullWidth
        id="project_type"
        InputLabelProps={{
          shrink: true,
        }}
        label="Project Type"
        margin="dense"
        name="project_type"
        placeholder="Select Type"
        //   required
        style={{ width: '150px' }}
        value={value}
        variant="outlined"
      />
    );
  }
}

ProjectType.propTypes = {
  classes: PropTypes.object,
};
ProjectType.defaultProps = {
  type: 0,
  admin: false,
};
const mapStateToProps = state => ({
  user: state.info_user.user,
});
export default connect(mapStateToProps)(ProjectType);
