import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { connect } from 'react-redux';

import { Sidebar, Topbar } from './components';
import {
  logout,
  setOpenSidebar,
  setCurrentPage,
  setMiniSidebar,
} from 'redux-store/actions';
import { setLayout } from 'redux-store/actions/layoutActions';
import { useStyles } from './components/useStyles';
import { getParamsUrl } from 'helpers';

const Main = props => {
  const {
    children,
    openSidebar,
    setOpenSidebar,
    minSidebar,
    width_sidebar,
  } = props;
  let { current_page } = props;
  const { classes } = useStyles({ width_sidebar });
  const theme = useTheme();
  let isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  let isTablet = useMediaQuery(theme.breakpoints.down('xl'), {
    defaultMatches: true,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: false,
  });
  const isMobileSmall = useMediaQuery('(max-width:400px)', {
    defaultMatches: false,
  });
  if (minSidebar) {
    isDesktop = true;
  }
  const handleSidebarOpen = () => {
    console.log('handleSidebarOpen');
    setOpenSidebar(true);
  };
  const handleSidebarSignOut = event => {
    event.preventDefault();
    props.logout();
    props.history.push('/');
  };

  const handleSidebarClose = () => {
    console.log('handleSidebarClose');
    setOpenSidebar(false);
  };
  const handleMiniSidebar = () => {
    props.setMiniSidebar(true);
  };
  let url = getParamsUrl(props);
  if (url.startsWith('/')) {
    url = url.substring(1);
  }
  if (isMobile) {
    isTablet = false;
  }
  let shouldOpenSidebar = isDesktop ? true : openSidebar;
  let drawer_variant = isDesktop ? 'persistent' : 'temporary';
  let shiftContent = isDesktop;
  if (current_page !== url) {
    current_page = url;
    props.setCurrentPage(url);
  }
  if (current_page.startsWith('dicom_viewer') && isDesktop) {
    shouldOpenSidebar = openSidebar;
    drawer_variant = 'persistent';
    shiftContent = openSidebar;
  }
  props.setLayout(isDesktop, isTablet, isMobile, isMobileSmall);
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: shiftContent && !minSidebar,
        [classes.side_mini]: minSidebar,
      })}>
      <Topbar
        handleAddError={props.handleAddError}
        isDesktop={isDesktop}
        minSidebar={minSidebar}
        onSidebarOpen={handleSidebarOpen}
        onSidebarSignOut={handleSidebarSignOut}
        setMiniSidebar={props.setMiniSidebar}
        shouldOpenSidebar={shouldOpenSidebar}
        user={props.user}
      />
      <Sidebar
        isDesktop={isDesktop}
        minSidebar={minSidebar}
        onClose={handleSidebarClose}
        onMiniSidebar={handleMiniSidebar}
        open={shouldOpenSidebar}
        variant={drawer_variant}
      />
      <main className={classes.main}>
        {children}
        {/* <Footer /> */}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
};
const mapStateToProps = state => {
  const { user } = state.info_user;
  const { openSidebar, page, width_sidebar, minSidebar } = state.page_globals;
  return {
    user,
    minSidebar,
    openSidebar,
    current_page: page,
    width_sidebar,
  };
};
export default connect(mapStateToProps, {
  logout,
  setLayout,
  setOpenSidebar,
  setCurrentPage,
  setMiniSidebar,
})(withRouter(Main));
