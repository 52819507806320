import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { List, ListItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import useStyles from './Styles';
import { withRouter } from 'react-router-dom';

class HomeFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { whiteFont, classes } = this.props;
    const footerClasses = clsx({
      [classes.footer]: true,
      [classes.footerWhiteFont]: whiteFont,
    });
    return (
      <footer className={footerClasses}>
        <div className={classes.container}>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem
                className={classes.inlineBlock}
                onClick={() => this.props.history.push('/about-us')}
                sx={{ cursor: 'pointer' }}>
                <a className={classes.block}>About us</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  className={classes.block}
                  href="http://blog.medical-blocks.ch"
                  rel="noreferrer"
                  target="_blank">
                  Blog
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  className={classes.block}
                  href="https://www.medical-blocks.ch"
                  rel="noreferrer"
                  target="_blank">
                  Licenses
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            Copyright &copy; {1900 + new Date().getYear()} Medical-Blocks
          </div>
        </div>
      </footer>
    );
  }
}

HomeFooter.propTypes = {
  whiteFont: PropTypes.bool,
};

export default withStyles(withRouter(HomeFooter), useStyles);
