import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationUpdateFileMetadata, QueryFileMetadata } from 'graphql/Files';
import { EditMetadataContent, EditMetadataToolBar } from '../index';
import { udpateMutltiGroup, udpateSingleGroup } from '../utils';

class EditMedataSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updating: false,
      metadata: null,
      props_metadata: null,
      changed: false,
    };
  }
  componentDidMount() {
    this.queryMetadata();
  }
  componentDidUpdate() {
    this.queryMetadata();
  }
  queryMetadata = () => {
    const { folder_content } = this.props;
    const { loading, meta_empty, props_metadata } = this.state;
    if (loading || meta_empty) return;
    if (props_metadata && folder_content) {
      if (folder_content.File && folder_content.File.metadata) {
        if (props_metadata.id === folder_content.File.metadata.id) return;
      }
    }
    const idFileObject = this.getFileID();
    let metadata = this.getMetadata();
    if (metadata || !idFileObject) return;
    console.log('queryMetadata', { idFileObject, metadata });
    this.setState({ loading: true });
    (async () => {
      QueryFileMetadata(idFileObject)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, metadata } = data.fileMetadata;
          if (ok) {
            const project = folder_content.project;
            console.log({ metadata });
            this.setState({
              loading: false,
              props_metadata: metadata,
              meta_empty: metadata ? false : true,
            });
            if (project === undefined || !project) {
              folder_content.project = project;
              folder_content.File.metadata = metadata;
              this.props.handleUpdateFolderContent(folder_content);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getCloneProps = () => {
    const props_meta = this.getPropsMetadata();
    if (!props_meta) return null;
    let { group_mappings } = props_meta;
    if (group_mappings === undefined || !group_mappings) group_mappings = [];
    const metadata = { ...props_meta, group_mappings: [...group_mappings] };
    return metadata;
  };
  getProject = () => {
    const { folder_content } = this.props;
    if (folder_content && folder_content.project) {
      return folder_content.project;
    }
    return null;
  };
  getFileID = () => {
    const { folder_content } = this.props;
    if (folder_content && folder_content.File) {
      return folder_content.File.idFileObject;
    }
    return null;
  };
  getPropsMetadata = () => {
    const { folder_content } = this.props;
    if (folder_content && folder_content.File) {
      return folder_content.File.metadata;
    } else if (this.state.props_metadata) {
      return this.state.props_metadata;
    }
    return null;
  };
  getMetadata = () => {
    let metadata = null;
    if (this.getPropsMetadata() && !this.state.metadata) {
      metadata = this.getCloneProps();
    } else if (this.state.metadata) {
      metadata = this.state.metadata;
    } else if (this.state.props_metadata) {
      metadata = this.state.props_metadata;
    }
    return metadata;
  };

  handleGroupMappingSelected = (idGroup, group_name, group_map) => {
    const { changed } = this.state;
    let metadata = this.getMetadata();
    const is_array = Array.isArray(group_map);
    if (metadata) {
      if (is_array) {
        metadata = udpateMutltiGroup(idGroup, group_map, metadata);
      } else {
        metadata = udpateSingleGroup(idGroup, group_map, metadata);
      }
    } else if (group_map) {
      metadata = this.getCloneProps();
      if (is_array) metadata.group_mappings = [...group_map];
      else metadata.group_mappings = [group_map];
    }
    this.setState({ metadata, changed: !changed });
  };
  handleProjectSelected = () => {};
  handleNotesChanged = event => {
    let { metadata } = this.state;
    if (metadata) {
      metadata.notes = event.target.value;
    } else {
      metadata = this.getCloneProps();
      metadata.notes = event.target.value;
    }
    this.setState({ metadata });
  };
  handleCommentsChanged = event => {
    let { metadata } = this.state;
    if (metadata) {
      metadata.comments = event.target.value;
    } else {
      metadata = this.getCloneProps();
      metadata.comments = event.target.value;
    }
    this.setState({ metadata });
  };
  handleUpdateKeywords = (idKeyword, keywords) => {
    let metadata = this.getMetadata();
    if (metadata) {
      const { group_mappings } = metadata;
      if (group_mappings && group_mappings.length) {
        const not_keywords = group_mappings.filter(
          x => x.idGroup !== idKeyword
        );
        metadata.group_mappings = not_keywords.concat(keywords);
      } else {
        metadata.group_mappings = keywords;
      }
    } else {
      metadata = this.getCloneProps();
      metadata.group_mappings = keywords;
    }
    this.setState({ metadata });
  };
  handleUpdateMetadata = () => {
    const { updating, metadata } = this.state;
    if (updating) return;
    this.setState({ updating: true });
    (async () => {
      MutationUpdateFileMetadata(metadata)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateMetadata;
          console.log({ ok, metadata });
          if (ok) {
            this.setState({
              updating: false,
              metadata: null,
              props_metadata: null,
              meta_empty: false,
            });
            this.props.handleUpdateMetadata(metadata);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsNotChanged = () => {
    const new_metadata = this.state.metadata;
    if (!new_metadata) return true;
    const props_metadata = this.getPropsMetadata();
    if (!props_metadata) return false;
    let { group_mappings, notes, comments } = props_metadata;
    if (new_metadata.notes === '') new_metadata.notes = null;
    if (new_metadata.comments === '') new_metadata.comments = null;
    if (notes !== new_metadata.notes) {
      return false;
    }
    if (comments !== new_metadata.comments) {
      return false;
    }
    if (group_mappings.length !== new_metadata.group_mappings.length) {
      return false;
    }
    // console.log({ new: this.state.metadata, old: props_metadata });
    let count = 0;
    for (let i = 0; i < new_metadata.group_mappings.length; i++) {
      const { idGroup, name } = new_metadata.group_mappings[i];
      const key = `${idGroup}-${name}`;
      const index = group_mappings
        .map(x => `${x.idGroup}-${x.name}`)
        .indexOf(key);
      if (index !== -1) count++;
    }
    return count === group_mappings.length;
  };
  handleClose = () => {
    console.log('handleClose');
    this.setState({ meta_empty: false, metadata: null, props_metadata: null });
    this.props.handleClose();
  };
  render() {
    const { updating, loading } = this.state;
    let metadata = this.getMetadata();
    let notchanged = this.IsNotChanged();
    console.log({ metadata });
    return (
      <React.Fragment>
        <EditMetadataToolBar
          handleClose={this.handleClose}
          handleUpdateMetadata={this.handleUpdateMetadata}
          loading={loading}
          notchanged={notchanged}
          updating={updating}
        />
        <Box sx={{ width: '100%', padding: 5 }}>
          <EditMetadataContent
            folder_content={this.props.folder_content}
            handleAddError={this.props.handleAddError}
            handleCommentsChanged={this.handleCommentsChanged}
            handleGroupMappingSelected={this.handleGroupMappingSelected}
            handleLoading={loading => this.setState({ loading })}
            handleNotesChanged={this.handleNotesChanged}
            handleProjectSelected={this.handleProjectSelected}
            handleUpdateKeywords={this.handleUpdateKeywords}
            metadata={metadata}
          />
        </Box>
      </React.Fragment>
    );
  }
}
EditMedataSingle.propTypes = {
  handleClose: PropTypes.func,
};
EditMedataSingle.defaultProps = {
  handleUpdateMetadata: () => '',
  handleClose: () => '',
  handleUpdateFolderContent: () => '',
};

export default EditMedataSingle;
