import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import ShareIcon from '@mui/icons-material/Share';
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { purple } from '@mui/material/colors';

import useStyles from '../ButtonSharedFolders/Styles';
import { DeleteFoldersMutation, RemoveSharedMutation } from 'graphql/Folders';
import { GetData } from 'helpers';
import { DialogShareFiles, SnackMessage } from 'components';

import { removeSharedFoldersIds } from 'redux-store/actions/notifications';

class ButtonSharedFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      open: false,
    };
  }

  handleOpenDialog = () => {
    const { loading } = this.state;
    if (loading) return;

    this.props.handleDrawerClose();
    this.setState(prev => ({ open: !prev.open }));
  };
  handleDeleteWarningClick = (status, shared) => {
    if (!status) {
      this.setState({ open: false, error: 'Canceled by the user' });
      return;
    }
    if (shared) {
      this.handleShared();
    } else {
      this.handleDelete();
    }
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };
  handleDelete = () => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ open: false });
      return;
    }
    this.setState({ loading: true, open: false });
    const { selected_folder, selected_content } = this.props;

    (async () => {
      const folders_ids = selected_folder
        .map(x => {
          const { idFolder, idFolderContent } = x;
          if (idFolderContent) return null;
          return idFolder;
        })
        .filter(x => x);
      const content_ids = selected_content
        .map(x => x.idFolderContent)
        .filter(x => x);
      DeleteFoldersMutation(folders_ids, content_ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, warnings } = data.deleteFolders;
          if (ok) {
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings,
              folders_ids,
              content_ids,
            });
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ loading: false });
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };
  handleShared = () => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ open: false });
      return;
    }
    this.setState({ loading: true, open: false });
    const { selected_folder, selected_content } = this.props;
    (async () => {
      const folders_ids = selected_folder.map(x => x.idFolder).filter(x => x);
      const content_ids = selected_content
        .map(x => x.idFolderContent)
        .filter(x => x);

      RemoveSharedMutation(folders_ids, content_ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeSharedFolders;
          if (ok) {
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings: null,
              folders_ids,
              content_ids,
            });
            this.setState({ open: false });
            this.props.removeSharedFoldersIds(folders_ids);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ open: false });
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };
  handleShareFolder = () => {
    this.setState({ open: false });
    this.props.handleShareFolder();
  };

  render() {
    const { classes, base, selected_content, defaultProps } = this.props;
    const { loading, error, open } = this.state;
    if (selected_content.length === 0 || base === 'Shared') {
      return null;
    }
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <SnackMessage
            handleClose={this.handleCloseSnak}
            message_text={error !== '' ? error : 'Unknown warning'}
            open={error && error !== '' ? true : false}
            type="error"
          />
          <DialogShareFiles
            files_contents={selected_content}
            handleClose={this.handleOpenDialog}
            handleShareFolder={this.handleShareFolder}
            open={open}
          />
          <Tooltip title="Share Selected Files">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                {...this.props.iconProps}
                aria-controls="delete-folders"
                onClick={this.handleOpenDialog}
                size="large">
                {loading ? (
                  <ShareIcon />
                ) : (
                  <span style={{ margin: 0, padding: 0 }}>
                    <Badge
                      badgeContent={selected_content.length}
                      {...defaultProps}
                    />
                  </span>
                )}
              </IconButton>
            </span>
          </Tooltip>
          {loading && (
            <CircularProgress className={classes.fabProgress} size={36} />
          )}
        </div>
      </div>
    );
  }
}

ButtonSharedFiles.propTypes = {
  base: PropTypes.string,
  classes: PropTypes.object,
  defaultProps: PropTypes.object,
  selected_content: PropTypes.array,
};

ButtonSharedFiles.defaultProps = {
  base: 'Home',
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'secondary',
    children: <ShareIcon style={{ color: purple[300] }} />,
  },
  selected_content: [],
  iconProps: {},
};
export default connect(null, { removeSharedFoldersIds })(
  withStyles(ButtonSharedFiles, useStyles)
);
