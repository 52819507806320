import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { v1 as uuid } from 'uuid';

import { ButtonLoad, SearchInput } from 'components';

class SearchDoubleText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text_one: '',
      text_two: '',
    };
    const { holder } = props;
    if (holder) {
      holder.getSearchTextOne = () => this.state.text_one;
      holder.getSearchTextTwo = () => this.state.text_two;
    }
  }

  onChangeOne = event => {
    this.setState({ text_one: event.target.value });
  };
  onChangeTwo = event => {
    this.setState({ text_two: event.target.value });
  };

  render() {
    const { classes, loading } = this.props;
    const { text_one, text_two } = this.state;
    return (
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          <SearchInput
            className={classes.search}
            id={uuid()}
            onChange={this.onChangeOne}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleSearchText(text_one, text_two);
              }
            }}
            placeholder={this.props.placeholderone}
            value={text_one}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SearchInput
            className={classes.search}
            id={uuid()}
            onChange={this.onChangeTwo}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleSearchText(text_one, text_two);
              }
            }}
            placeholder={this.props.placeholdertwo}
            value={text_two}
          />
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sm={6}
          xs={12}>
          <ButtonLoad
            className={classes.search}
            component="span"
            handleClick={() => this.props.handleSearchText(text_one, text_two)}
            loading={loading}
            name="Search"
            variant="contained"
          />
        </Grid>
      </Grid>
    );
  }
}

SearchDoubleText.propTypes = {
  classes: PropTypes.object,
};
SearchDoubleText.defaultProps = {
  loading: false,
  handleSearchText: () => '',
  placeholderone: 'Search one',
  placeholdertwo: 'Search two',
};
export default SearchDoubleText;
