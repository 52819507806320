import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CreateImageFocus,
  DeleteImageFocus,
  QuerySeriesFocus,
} from 'graphql/Series';
import {
  DialogCreateSeriesDetail,
  SnackMessage,
  CardEditListDefault,
} from 'components';
import { GetData, ServerErrors } from 'helpers';

class EditImageFocus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      open: false,
      loading: false,
      error: '',
    };
    this.holder = {
      addNewDetail: null,
      getDetails: null,
      removeDetail: null,
      reload: null,
    };
  }

  handleSelected = selected => {
    this.setState({ selected });
  };
  handleCreateDetailDialog = open => {
    this.setState({ open });
  };
  handleCreateDetail = name => {
    if (name === '') {
      this.setState({ error: 'Name is empty' });
      return;
    }
    const rows = this.holder.getDetails();
    if (rows.map(x => x.name).indexOf(name) !== -1) {
      this.setState({
        error: `${name} is here, please select a different name`,
      });
      return;
    }
    this.runCreate(name);
  };
  handleCloseError = () => {
    this.setState({ error: '' });
  };
  runCreate = name => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      CreateImageFocus(name)
        .then(res => {
          const data = GetData(res);

          if (data && data.createImageFocus) {
            const { ok, ImageFocus, errors } = data.createImageFocus;
            if (ok) {
              this.holder.addNewDetail(ImageFocus);
              this.setState({ open: false, loading: false });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleDelete = () => {
    const { loading } = this.state;
    if (loading) return;
    const { selected } = this.state;
    this.setState({ loading: true });
    (async () => {
      DeleteImageFocus(selected)
        .then(res => {
          const data = GetData(res);

          if (data && data.deleteImageFocus) {
            const { ok, errors } = data.deleteImageFocus;
            if (ok) {
              this.holder.removeDetail();
              this.setState({ open: false, loading: false, selected: [] });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleReloadTable = () => {
    if (this.holder.reload) this.holder.reload();
  };
  render() {
    const { selected, open, loading, error } = this.state;
    const { height } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height, width: '100%' };
    }
    return (
      <div style={style}>
        <QuerySeriesFocus
          handleAddError={this.props.handleAddError}
          holder={this.holder}
          setState={state => this.setState(state)}>
          <CardEditListDefault
            handleCreateDetailDialog={this.handleCreateDetailDialog}
            handleDelete={this.handleDelete}
            handleReloadTable={this.handleReloadTable}
            handleSelected={this.handleSelected}
            height={height - 75}
            holder={this.holder}
            selected={selected}
            style={{ height: height - 140, maxHeight: height - 140 }}
            title="Image Focus"
          />
        </QuerySeriesFocus>
        <DialogCreateSeriesDetail
          handleClose={this.handleCreateDetailDialog}
          handleCreateDetail={this.handleCreateDetail}
          loading={loading}
          open={open}
          title="Image Focus"
        />
        <SnackMessage
          handleClose={this.handleCloseError}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </div>
    );
  }
}

EditImageFocus.propTypes = {
  classes: PropTypes.object,
};

export default EditImageFocus;
