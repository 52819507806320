import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const ALL_TEAMS = gql`
  query($name: String, $page: Int, $limit: Int) {
    allTeams(name: $name, page: $page, limit: $limit) {
      ok
      teams {
        id
        name
        avatar
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryAllTeams = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(ALL_TEAMS),
    variables: {
      name,
    },
  });
};

const ALL_CHANNELS = gql`
  query {
    channels {
      id
      name
      lastMessage {
        text
        createdAt
      }
      team {
        name
        avatar
      }
      notread
      createdAt
    }
  }
`;

export const QueryAllTeamsChannels = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(ALL_CHANNELS),
  });
};

const CREATE_CHANNEL = gql`
  mutation($teamId: Int!, $name: String!) {
    createChannel(name: $name, teamId: $teamId) {
      ok
      channel {
        id
        name
        lastMessage {
          text
          createdAt
        }
        team {
          name
          avatar
        }
        notread
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const CreateTeamChannel = team => {
  const { id, name } = team;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(CREATE_CHANNEL),
    variables: {
      teamId: id,
      name,
    },
  });
};

const CHANNEL_MESSAGE = gql`
  mutation($channelId: Int!, $text: String) {
    createMessage(channelId: $channelId, text: $text)
  }
`;

export const SendTeamChannelMessage = (channelId, message) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(CHANNEL_MESSAGE),
    variables: {
      channelId,
      text: message,
    },
  });
};

export const SUBSCRIPTION_CHANNEL_MESSAGE = gql`
  subscription($channelId: Int!) {
    newChannelMessage(channelId: $channelId) {
      id
      text
      user {
        id
        FullName
        avatar
      }
      channel {
        id
        name
      }
      url
      filetype
      createdAt
    }
  }
`;
