import React from 'react';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';

import { TableUsers } from 'components';
import { QueryRequests } from 'graphql/Users';
import { GetData, ServerErrorsString } from 'helpers';

import { RequestDetails } from './components';
import { columns, useStyles } from './Styles';

class RegistrationRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {},
      requests: [],
      empty: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { empty, requests, loading } = this.state;
    if (empty || requests.length || loading) return;
    this.updateRequests();
  }

  componentDidUpdate() {
    const { empty, requests, loading } = this.state;
    if (empty || requests.length || loading) return;
    this.updateRequests();
  }

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleDrawerOpen = user => {
    //
    this.setState({
      open: true,
      user: user,
    });
  };
  handleUserDeleted = () => {
    this.updateRequests();
  };
  updateRequests = () => {
    const { loading } = this.state;
    if (loading) return;

    this.setState({ loading: true, open: false });
    (async () => {
      QueryRequests()
        .then(res => {
          const data = GetData(res);
          if (data.usersRequests) {
            let { ok, requests, errors } = data.usersRequests;
            let empty = true;
            if (ok) {
              empty = requests.length ? false : true;
              if (!empty) {
                requests = requests.map(x => ({
                  ...x,
                  name: x.User.FullName,
                }));
              }
              this.setState({ loading: false, requests, empty });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({ loading: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedRow = row => {
    const { open } = this.state;
    this.setState({ user: row, open: !open });
  };
  handleRemoveWrongRequest = idRequest => {
    let { requests } = this.state;
    requests = requests.filter(x => x.idRequest !== idRequest);
    this.setState({ requests });
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, height } = this.props;
    const { open, user, requests, loading } = this.state;
    return (
      <div style={{ height: height - 120, overflow: 'auto' }}>
        <div className={classes.container}>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}>
            <TableUsers
              columns={columns}
              handleReloadTable={this.updateRequests}
              handleSelectedRow={this.handleSelectedRow}
              height={height - 280}
              loading={loading}
              rows={requests}
              title={'Reques for Requestration'}
            />
          </div>
          <RequestDetails
            handleAddError={this.props.handleAddError}
            handleDrawerClose={this.handleDrawerClose}
            handleRemoveWrongRequest={this.handleRemoveWrongRequest}
            handleUserDeleted={this.handleUserDeleted}
            open={open}
            user={user}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(RegistrationRequests, useStyles);
