import React from 'react';
import PropTypes from 'prop-types';
import { CardMedia, Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TabPanel } from 'components';
import { LogsErrors, LogsUser } from './components';
import { getParams } from 'helpers';
import useStyles from './components/Styles';

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tabValue: 0,
    };
  }

  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    newValue = parseInt(newValue, 10);
    switch (newValue) {
      case 0:
        history.push('/management/logs/user');
        break;
      case 1:
        history.push('/management/logs/errors');
        break;
      default:
        history.push('/management/logs');
        break;
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, height, isDesktop } = this.props;
    const { level_1 } = getParams(this.props);
    let tabValue = 0;
    if (level_1 === 'user') {
      tabValue = 0;
    } else if (level_1 === 'errors') {
      tabValue = 1;
    }
    let height_content = height - 360;
    let height_table = height - 500;
    if (!isDesktop) {
      height_content = height - 180;
      height_table = height - 320;
    }
    return (
      <React.Fragment>
        {isDesktop && (
          <CardMedia
            className={classes.media}
            image="/images/profile_1.png"
            title="Ted talk"
          />
        )}
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            <Tab label="Logs User" />
            <Tab label="Errors" />
          </Tabs>
          <Divider />
        </Paper>
        <div
          style={{ width: '100%', height: height_content, overflow: 'auto' }}>
          <div className={classes.content_overview}>
            <TabPanel className={classes.files} index={0} value={tabValue}>
              <LogsUser height={height_table} />
            </TabPanel>
            <TabPanel className={classes.files} index={1} value={tabValue}>
              <LogsErrors height={height_table} />
            </TabPanel>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Logs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Logs, useStyles);
