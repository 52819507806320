import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { gql } from '@apollo/client';
import { Subscription } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { addNewMessages, addNewMessage } from 'redux-store/actions';
import { GetData, IsInvalid, IsValid, ServerErrorsString } from 'helpers';
import { QueryDirectMessages } from 'graphql/Communications';
const MESSAGES_SUBSCRIPTION = gql`
  subscription {
    allNewDirectMessages {
      id
      text
      read
      receiverId
      sender {
        id
        FullName
        avatar
      }
      read
      createdAt
    }
  }
`;

class MessagesSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    const { holder } = props;
    if (holder) {
      holder.handleReloadMessages = () => {
        this.handleLoadDirectMessages();
      };
    }
  }
  handleLoadDirectMessages = () => {
    console.log('handleLoadDirectMessages');
    const { user } = this.props;
    if (IsInvalid(user)) return;
    let { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QueryDirectMessages(-1, 0, 10)
        .then(res => {
          const data = GetData(res);
          const messages = data.directMessages;
          if (IsValid(messages)) {
            this.props.addNewMessages(messages);
          }
          this.setState({ loading: false });
          this.props.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  onSubscriptionDataMessages = ondata => {
    console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { allNewDirectMessages } = data;
      if (allNewDirectMessages) {
        this.props.addNewMessage(allNewDirectMessages);
      }
    }
  };
  render() {
    const { children } = this.props;
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataMessages}
        shouldResubscribe
        subscription={MESSAGES_SUBSCRIPTION}>
        {({ loading, error }) => {
          if (!children) {
            return null;
          }
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              loading,
              error,
            });
          });
        }}
      </Subscription>
    );
  }
}

MessagesSubscription.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { chat_messages } = state.page_activity;
  return {
    user: state.info_user.user,
    chat_messages,
    total_messages: chat_messages.length,
  };
};

export default connect(mapStateToProps, {
  addNewMessage,
  addNewMessages,
})(MessagesSubscription);
