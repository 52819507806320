import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import { MutationDeleteLogUnit } from 'graphql/Logs';
import { GetData, ServerErrorsString } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { useStylesToolBars } from 'common';
import SaveIcon from '@mui/icons-material/Save';

class ToolBarLogUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { logs_unit, total, selected } = this.props.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteLogUnit(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteLogsUnit;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              logs_unit: logs_unit.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, open_delete: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes } = this.props;
    const { rows_edited, selected } = this.props.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          pb={1}
          pt={1}
          xs={12}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSaveRules}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'blue' : 'gray' }}
                />
              </Button>
              {/* <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'green' }} />
              </Button> */}
              <ButtonDownloadType key="unit" type="logs_unit" />
              {/* <ButtonFileUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              /> */}
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={text => this.props.handleReload(text)}
              id="id-search-log-units"
              loading={this.props.loading}
              placeholder="Search logs unit..."
              size="small"
            />
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={() => this.props.setState({ selected: [] })}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete these unit logs, this action cannot be undone"
          title_text="Delete Selected Unit Logs"
        />
      </React.Fragment>
    );
  }
}

ToolBarLogUnit.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ToolBarLogUnit, useStylesToolBars);
