export { default as ToolBarEncrypKeys } from './ToolBarEncrypKeys';
import { alphabet } from 'common';
const getColumns = () => {
  const columns = [];
  for (let i = 0; i < alphabet; i++) {
    columns.push({
      field: `colum_${alphabet[i]}`,
      headerName: `${alphabet[i]}`,
      width: 85,
      align: 'left',
      edit: true,
      style: {
        width: 50,
        minWidth: 70,
        maxWidth: 100,
      },
    });
  }
  return columns;
};

export const colums = [...getColumns()];
