import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { QueryVariableTypes } from 'graphql/Mapping/utils_mapping';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';

const SearchVariableTypes = props => {
  const { selected_type, style } = props;

  const classes = useMakeStyles();

  const [name, setTypeName] = React.useState('');
  const [type, setType] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      QueryVariableTypes(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types } = data.variableTypes;
          console.log({ types });
          if (ok) {
            if (types.length === 0) {
              types.push({ name: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(types);
        })
        .catch(error => {
          console.log('type-catch-error', error);
          setOptions([]);
          props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  console.log({ selected_type, type });
  if (selected_type && type && type.id !== selected_type.id) {
    setType(selected_type);
  }

  const handeTypeNameChanged = event => {
    const name = event.target.value;
    // console.log({ name });
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      // console.log({ l: here.length });
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setTypeName(name);
  };
  const handleAddType = type => {
    setType(type);
    props.handleAddType(type);
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        handelSearchNameChanged={handeTypeNameChanged}
        handleAddResultObject={handleAddType}
        label="Variable Type"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={type}
        setObject={handleAddType}
        setObjectName={setTypeName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        handelSearchNameChanged={handeTypeNameChanged}
        handleAddResultObject={handleAddType}
        label="Variable Type"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={type}
        setObject={handleAddType}
        setObjectName={setTypeName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchVariableTypes.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchVariableTypes.defaultProps = {
  placeholder: 'Search Variable Types...',
  handleAddType: type => console.log('handleAddType: ', { type }),
  no_icon: false,
  handleAddError: error => console.log('handleAddError: ', { error }),
};

export default SearchVariableTypes;
