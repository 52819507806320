import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { GroupBox } from 'components';
class GroupStudyLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: false,
      uids: false,
      times: false,
      dates: false,
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const { description, uids, times, dates } = this.state;
    return (
      <GroupBox title_box="Study Level">
        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={description}
                  name="description"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Description"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={uids}
                  name="uids"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep UIDs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={times}
                  name="times"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Times"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dates}
                  name="dates"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Dates"
            />
          </FormGroup>
          <FormHelperText>Be careful</FormHelperText>
        </FormControl>
      </GroupBox>
    );
  }
}

GroupStudyLevel.propTypes = {
  classes: PropTypes.object,
};

export default GroupStudyLevel;
