import {
  NOTIFICATION_DELETE_SHARE_FOLDER,
  NOTIFICATION_NEW,
  NOTIFICATION_NEW_CHAT_MESSAGE,
  NOTIFICATION_NEW_CHAT_MESSAGE_READ,
  NOTIFICATION_ADD_CHAT_MESSAGES,
  NOTIFICATION_NEW_SHARE_FOLDER,
  NOTIFICATION_RELOAD,
  NOTIFICATION_SET,
} from './types';
import { store } from 'redux-store/store';
import { reduce_image_file_size } from 'helpers/utils_images';
import { IsInvalid } from 'helpers';

export const readMessages = () => dispatch => {
  dispatch({
    type: NOTIFICATION_NEW_CHAT_MESSAGE_READ,
  });
};

export const addNewMessage = chat_message => dispatch => {
  dispatch({
    type: NOTIFICATION_NEW_CHAT_MESSAGE,
    chat_message,
  });
};
export const addNewMessages = chat_messages => dispatch => {
  dispatch({
    type: NOTIFICATION_ADD_CHAT_MESSAGES,
    chat_messages,
  });
};
export const addNewSharedFolder = shared_folder => dispatch => {
  dispatch({
    type: NOTIFICATION_NEW_SHARE_FOLDER,
    shared_folder,
  });
};

export const setNotifications = notifications => dispatch => {
  if (IsInvalid(notifications)) return;
  dispatch({
    type: NOTIFICATION_SET,
    notifications,
  });
};

export const addNewNotification = notification => dispatch => {
  console.log('addNewNotification');
  dispatch({
    type: NOTIFICATION_NEW,
    notification,
  });
};
export const deleteNewSharedFolder = shared_folder => dispatch => {
  const { idRight } = shared_folder;
  if (!idRight) return;
  const state = store.getState();
  let { shared_folders } = state.page_activity;
  shared_folders = shared_folders.filter(x => x.idRight !== idRight);
  dispatch({
    type: NOTIFICATION_DELETE_SHARE_FOLDER,
    shared_folders,
  });
};
export const removeSharedFoldersIds = folderIds => dispatch => {
  if (!folderIds || folderIds.length === 0) return;
  const state = store.getState();
  let removed = false;
  let { shared_folders, notifications } = state.page_activity;
  const shared_size = shared_folders.length;
  for (let index = 0; index < folderIds.length; index++) {
    const idFolder = folderIds[index];
    shared_folders = shared_folders.filter(x => x.idFolder !== idFolder);
    for (let i = 0; i < notifications.length; i++) {
      const n = notifications[i];
      if (n.name === 'Shared Folder' && n.Folder.idFolder === idFolder) {
        notifications.splice(i, 1);
        removed = true;
        break;
      }
    }
  }

  if (!removed) {
    if (shared_size !== shared_folders.length) {
      dispatch({
        type: NOTIFICATION_DELETE_SHARE_FOLDER,
        shared_folders,
      });
    }
  } else {
    dispatch({
      type: NOTIFICATION_DELETE_SHARE_FOLDER,
      shared_folders,
      notifications,
    });
  }
};

export const decodeNotification = (notification, state) => {
  const { name } = notification;

  if (name === 'Shared Folder') {
    const { Owner, Right, Folder } = notification;
    if (!Folder) return;
    if (!Folder.Owner) {
      Folder.Owner = Owner;
    }
    if (!Folder.Rights || Folder.Rights.length === 0) {
      Folder.Rights = [Right];
    }
    if (!state) {
      state = store.getState();
    }
    let { shared_folders } = state;
    const index = shared_folders.map(x => x.idFolder).indexOf(Folder.idFolder);
    if (index !== -1) return;
    shared_folders.unshift(Folder);
    store.dispatch(() => ({
      type: NOTIFICATION_NEW_SHARE_FOLDER,
      shared_folders,
    }));
  } else {
    /* empty */
  }
};
export const reloadNotification = reload => dispatch => {
  dispatch({
    type: NOTIFICATION_RELOAD,
    notifications_reload: reload,
  });
};

export const NotificationsAvatarResize = async in_notifications => {
  let notifications = [];
  for (let i = 0; i < in_notifications.length; i++) {
    let notification = await AvatarResize(in_notifications[i]);
    notifications.push(notification);
  }
  return notifications;
};
export const AvatarResize = async notification => {
  let { From, To } = notification;
  let avatar_from = null;
  let avatar_to = null;
  try {
    if (From) {
      avatar_from = From.avatar;
      avatar_from = await reduce_image_file_size(From.avatar, 50, 50);
    }
    if (To) {
      avatar_to = To.avatar;
      avatar_to = await reduce_image_file_size(To.avatar, 50, 50);
    }
  } catch (error) {
    console.log({ error });
  }
  return {
    ...notification,
    To: {
      ...To,
      avatar: avatar_to,
    },
    From: {
      ...From,
      avatar: avatar_from,
    },
  };
};
