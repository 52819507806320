import React from 'react';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';
import { DivGrow, IconButtonLoad } from 'components';
import { MutationUpdateCenter } from 'graphql/Units/utils_centers';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';

class EditCreateBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
    };
  }

  handleUpdateCenter = () => {
    const { updating } = this.state;
    if (updating) return;
    const center = this.props.holder.getCenter();
    if (IsInvalid(center)) {
      this.props.handleAddError('Center is missing');
      return;
    }
    const { id: idCenter } = center;
    if (center.name === '') {
      this.props.handleAddError('Center name is missing');
      return;
    }
    const new_center = {
      name: center.name,
    };
    if (center.admin.id) {
      new_center.idAdmin = center.admin.id;
    }
    if (center.address.country) {
      new_center.country = center.address.country;
    }
    if (center.address.city) {
      new_center.city = center.address.city;
    }
    if (center.address.line_1) {
      new_center.line_1 = center.address.line_1;
    }
    this.setState({ updating: true });
    this.props.holder.setDisableAll(true);
    (async () => {
      MutationUpdateCenter(idCenter, new_center)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateCenter;
          if (ok) {
            this.setState({ updating: false });
            this.props.holder.resetCenter();
            this.props.handleUpdatedCenter(data.updateCenter.center);
            this.props.holder.setDisableAll(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.holder.setDisableAll(false);
        });
    })();
  };
  isChanged = () => {
    const center = this.props.holder.getCenter();
    if (!center) return false;
    const { original } = this.props;
    if (!original) {
      return false;
    }
    if (center.name !== original.name) {
      return true;
    }
    if (center.idAdmin !== original.idAdmin) {
      return true;
    }
    if (center.address.country !== original.address.country) {
      return true;
    }
    if (center.address.city !== original.address.city) {
      return true;
    }
    if (center.address.line_1 !== original.address.line_1) {
      return true;
    }
    return false;
  };

  render() {
    const { updating } = this.state;
    const changed = this.isChanged();
    //
    return (
      <React.Fragment>
        <DivGrow />
        <IconButtonLoad
          disabled={!changed || updating}
          handleClick={this.handleUpdateCenter}
          icon={<SaveIcon color={changed ? 'primary' : 'disabled'} />}
          loading={updating}
          tooltip="Create Center"
        />
      </React.Fragment>
    );
  }
}

EditCreateBottom.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func,
  handleUpdatedCenter: PropTypes.func,
  holder: PropTypes.object,
};
EditCreateBottom.defaultProps = {
  holder: null,
  handleAddError: () => '',
  handleUpdatedCenter: () => '',
};
export default EditCreateBottom;
