/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, List, ListItem } from '@mui/material';
import { CustomRouterLink } from 'common';
import clsx from 'clsx';

class SidebarNavItemCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      open,
      menu,
      classes,
      page,
      url_parts,
      selected_menu,
      isSelected,
    } = this.props;
    const parent_ref = page.title
      .toLowerCase()
      .replace(' ', '_')
      .replace('/', '_');
    return (
      <Collapse
        component="li"
        in={open}
        key="collapse-submenu"
        timeout="auto"
        unmountOnExit>
        <List disablePadding key="list-collapse-menu">
          {menu
            ? menu.map((item_menu, index_menu) => {
                let sub_menu = item_menu
                  .toLowerCase()
                  .replace(' ', '_')
                  .replace('/', '_');
                if (
                  parent_ref === 'project_management' &&
                  url_parts.length === 3
                ) {
                  sub_menu = url_parts[1] + '/' + sub_menu;
                }
                const select_item = index_menu === selected_menu && isSelected;
                const to_link = `${
                  page.href ? page.href : parent_ref
                }/${sub_menu}`;
                return (
                  <ListItem
                    button
                    className={clsx({
                      [classes.menu_item]: true,
                      [classes.menu_item_selected]: select_item,
                    })}
                    component={CustomRouterLink}
                    key={item_menu}
                    to={to_link}>
                    {item_menu}
                  </ListItem>
                );
              })
            : null}
        </List>
      </Collapse>
    );
  }
}

SidebarNavItemCollapse.propTypes = {
  classes: PropTypes.object,
};

export default SidebarNavItemCollapse;
