import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid, Tooltip } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
} from 'components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { MutationUploadProjectMappingCSV } from 'graphql/Projects';
import { ServerErrorsString, GetData } from 'helpers';
class ToolBarProjectMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }
  handleSelectFile = event => {
    const { idProject } = this.props;
    const { uploading } = this.state;
    console.log({ uploading });
    if (uploading) return;
    const { files } = event.target;
    console.log({ files });
    if (!files.length) {
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadProjectMappingCSV(idProject, files[0])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.uploadNewProjectMapping;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handelAddNewProjectMapping(mappings);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading_table, selected } = this.props;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="space-between"
        spacing={2}
        sx={{ width: '100%' }}
        xs={12}>
        <Grid item md={4} sm={4} xs={12}>
          <ButtonGroup
            // className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Tooltip title="Add mapping">
              <Button onClick={this.props.handleOpenCreate}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
            </Tooltip>
            <Tooltip title="Study series details">
              <Button
                disabled={!selected.length}
                onClick={this.props.handleOpenEdit}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
            </Tooltip>
            <ButtonFileUpload
              handleSelectFile={this.handleSelectFile}
              uploading={this.state.uploading}
            />
            <ButtonDownloadType
              idProject={this.props.idProject}
              type="project_mapping"
            />
          </ButtonGroup>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          md={3}
          sm={3}
          style={{ paddingRight: 0, marginRight: 0, maxHeight: 20 }}
          xs={12}>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            // loading={loading}
            loading_table={loading_table}
            numSelected={selected.length}
            // show_delete={isAdmin}
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarProjectMapping.propTypes = {
  classes: PropTypes.object,
};

export default ToolBarProjectMapping;
