import { gql, useSubscription } from '@apollo/client';

const MUTATION_SUBSCRIPTION_ONLINE = gql`
  subscription($idReceiver: LongLong) {
    onlineUser(idReceiver: $idReceiver) {
      id
      FullName
      isOnline
      email
      username
      avatar
      isActive
      last_login
      address {
        city
        country
      }
      account {
        code
        description
      }
      category {
        id
        code
        name
      }
      createdAt
    }
  }
`;

const SubscribeOnline = props => {
  const { idReceiver } = props;
  const { data, loading } = useSubscription(MUTATION_SUBSCRIPTION_ONLINE, {
    variables: { idReceiver },
  });
  if (loading || !data || data === undefined) return null;
  const { onlineUser } = data;
  if (onlineUser) props.handleOnline(onlineUser);
  console.log('SubscribeOnline', { onlineUser });
  return null;
};
SubscribeOnline.defaultProps = {
  handleOnline: () => '',
};
export default SubscribeOnline;
