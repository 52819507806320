export const ListModalities = [
  { name: 'MR', id: 1 },
  { name: 'CT', id: 2 },
  { name: 'CR', id: 3 },
  { name: 'RF', id: 4 },
  { name: 'MG', id: 5 },
  { name: 'AU', id: 6 },
  { name: 'SR', id: 7 },
  { name: 'PT', id: 8 },
  { name: 'PR', id: 9 },
  { name: 'US', id: 10 },
  { name: 'OT', id: 11 },
  { name: 'NM', id: 12 },
  { name: 'XA', id: 13 },
  { name: 'RG', id: 14 },
  { name: 'DX', id: 15 },
  { name: 'XC', id: 16 },
  { name: 'ES', id: 17 },
  { name: 'KO', id: 18 },
  { name: 'RAW', id: 19 },
  { name: 'SEG', id: 20 },
  { name: 'REG', id: 21 },
  { name: 'PDF', id: 22 },
  { name: 'RWV', id: 23 },
  { name: 'ECG', id: 24 },
];
export const options_nifti = [
  { name: 'Both', id: 0 },
  { name: 'Only NIfTI', id: 1 },
  { name: 'NO NIfTI', id: 2 },
];
export const defaultFilter = {
  idUser: null,
  idProject: null,
  user: null,
  project: null,
  SeriesDate: null,
  sequence: null,
  nifti: null,
  modalities: null,
};
export const IsDefaultFilter = filter => {
  if (filter === undefined || !filter) return true;
  if (filter.idUser) return false;
  if (filter.idProject) return false;
  if (filter.user) return false;
  if (filter.project) return false;
  if (filter.SeriesDate) return false;
  if (filter.sequence) return false;
  if (filter.nifti) return false;
  if (filter.modalities) return false;
  return true;
};
export const getNumberFilters = filters => {
  let filtered = 0;
  if (filters.idUser) {
    filtered++;
  }
  if (filters.idProject) {
    filtered++;
  }
  if (filters.SeriesDate) {
    filtered++;
  }
  if (filters.sequence) {
    if (Array.isArray(filters.sequence)) {
      if (filters.sequence.length) filtered++;
    } else filtered++;
  }
  if (filters.nifti) {
    filtered++;
  }
  if (filters.modalities && filters.modalities.length) {
    filtered++;
  }
  return filtered;
};
