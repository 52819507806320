import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import { PublicSelectedDatabase, SlideDatabases } from './components';
import { getParams } from 'helpers';
import { HomeFooterSubscribe } from 'views/Home/components';
class DatabasesMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          title: 'Brain',
          image: '/images/brain.jpg',
          description:
            'The brain is the most complex organ in the human body, and it controls all aspects of our thoughts, emotions, and actions',
        },
        {
          title: 'Heart',
          image: '/images/heart.jpg',
          description:
            'Heart research is critical for understanding how the heart works, how it can be damaged, and how it can be treated. ',
        },
        {
          title: 'Lungs',
          image: '/images/lungs.jpg',
          description:
            'Lung diseases, such as asthma, chronic obstructive pulmonary disease (COPD), and lung cancer, are significant causes of morbidity and mortality worldwide.',
        },
      ],
    };
  }
  handleClose = () => {
    this.props.history.push('/public_access/databases');
  };
  render() {
    const reply = getParams(this.props);
    if (reply && reply.level_2) {
      return (
        <PublicSelectedDatabase
          handleClose={this.handleClose}
          history={this.props.history}
          match={this.props.match}
        />
      );
    }
    return (
      <Box py={6}>
        <Container>
          <Grid
            alignItems="center"
            container
            flexDirection="column"
            item
            mb={2}
            mx="auto"
            textAlign="center"
            xs={8}>
            <Typography mb={1} variant="h2">
              Soon you will have the possibility to explore our databases
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            container
            flexDirection="column"
            item
            mb={6}
            mx="auto"
            textAlign="center"
            xs={10}>
            <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
              Our system has been utilized in a limited number of clinical
              trials, but we are constantly striving to expand our reach and
              impact. As part of this effort, we are planning to incorporate a
              wider range of databases into our platform, allowing researchers
              to explore a broader range of data with free access. This will
              enable us to provide our users with a more comprehensive and
              diverse set of resources, leading to a deeper understanding of
              complex health issues and more effective research approaches.
            </Typography>
          </Grid>
          <Grid container minHeight="40vh" spacing={3}>
            <Grid item xs={12}>
              <SlideDatabases />
            </Grid>
          </Grid>
        </Container>
        <HomeFooterSubscribe
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
        />
      </Box>
    );
  }
}

DatabasesMain.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(DatabasesMain);
