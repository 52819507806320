import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Paper } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { ButtonReload } from 'components';
import { InputSearchPictures } from './components';

class TopBarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open_drawer, loading } = this.props;
    let FilterButton = null;
    if (!open_drawer) {
      FilterButton = (
        <Grid
          alignItems="center"
          container
          item
          justifyContent="center"
          style={{ width: 90 }}>
          <IconButton
            aria-label="fingerprint"
            color="secondary"
            onClick={() => this.props.handleDrawerClose(true)}>
            <TuneIcon />
          </IconButton>
        </Grid>
      );
    }
    return (
      <Paper square style={{ height: 65 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-start"
          style={{ minHeight: 65 }}
          xs={12}>
          {FilterButton}
          <Grid item sx={{ paddingLeft: open_drawer ? 2 : 0 }} xs>
            <InputSearchPictures
              disabled={loading}
              onSelectionChanged={this.props.onKeywordSelection}
            />
          </Grid>
          <Grid container item justifyContent="flex-end" xs={1}>
            <Grid item>
              <ButtonReload
                handleReload={this.props.handleReload}
                loading={loading}
                small
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

TopBarSearch.propTypes = {
  handleReload: PropTypes.func,
};
TopBarSearch.defaultProps = {
  onKeywordSelection: () => '',
  handleReload: () => '',
  loading: false,
};

export default TopBarSearch;
