import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Slide,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { SeriesAtStudies } from 'graphql/Study';
import { DeleteSeriesMutation } from 'graphql/Series';
import { ServerErrorsString, GetData } from 'helpers';
import { ButtonLoad } from 'components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = theme => ({
  title: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  middle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  bottom: {
    marginBottom: theme.spacing(-3),
  },
});

class DialogWarningDeleteSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_delete: false,
    };
  }
  handleDeleteSelected = () => {
    this.setState({ open: false });
    const { loading_delete } = this.state;
    if (loading_delete) return;
    this.setState({ loading_delete: true });
    (async () => {
      const { selectedSeries, selectedStudies } = this.props;
      if (selectedStudies.length) {
        const ids = selectedStudies.map(x => x.idStudy);
        try {
          const res = await SeriesAtStudies(ids);
          const data = GetData(res);
          const { ok, errors, ListSeries } = data.SeriesAtStudies;
          if (ok) {
            for (let i = 0; i < ListSeries.length; i++) {
              const series = ListSeries[i];
              const index = selectedSeries
                .map(x => x.idSeries)
                .indexOf(series.idSeries);
              if (index === -1) {
                selectedSeries.push(series);
              }
            }
          } else {
            throw errors;
          }
        } catch (error) {
          const text = ServerErrorsString(error);
          this.props.handleAddError(`Error study query:\n- ${text}`);
        }
      }
      if (selectedSeries.length === 0) {
        this.setState({
          loading_delete: false,
        });
        this.props.handleAddError(
          'There are not series to delete.\n Or something went wrong.'
        );
      }
      try {
        const idsSeries = selectedSeries.map(x => x.idSeries);
        const res = await DeleteSeriesMutation(idsSeries);
        const data = GetData(res);
        const { ok, errors } = data.DeleteSeries;
        if (ok) {
          const { copy_series } = this.props;
          const newCopySeries = [];
          for (let i = 0; i < copy_series.length; i++) {
            const series = copy_series[i];
            const index = selectedSeries
              .map(x => x.idSeries)
              .indexOf(series.idSeries);
            if (index === -1) {
              newCopySeries.push(series);
            }
          }
          if (copy_series.length !== newCopySeries.length) {
            this.props.copySeries(newCopySeries);
          }
          this.setState({ loading_delete: false });
          this.props.selectedDeleted();
          this.props.handleClose();
        } else {
          throw errors;
        }
      } catch (error) {
        const text = ServerErrorsString(error);
        this.setState({
          loading_delete: false,
        });
        this.props.handleAddError(`Error deleting series:\n- ${text}`);
      }
    })();
  };
  render() {
    const {
      classes,
      handleClose,
      open,
      selectedSeries,
      selectedStudies,
    } = this.props;
    let question = 'You are about to delete ';
    if (selectedStudies.length) {
      question = `${question} ${selectedStudies.length} studies`;
    } else {
      question = `${question} ${selectedSeries.length} series`;
    }
    if (selectedStudies.length && selectedSeries.length) {
      question = `${question} and `;
    }

    if (selectedStudies.length && selectedSeries.length) {
      question = `${question} and ${selectedSeries.length} series`;
    }
    question = `${question}, this action cannot be undone.\n The selected studies/series and its files will be deleted permanently.`;
    return (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => handleClose(false)}
        open={open}
        TransitionComponent={Transition}>
        <DialogContent>
          <CardContent>
            <Typography
              className={classes.title}
              fontWeight="fontWeightBold"
              gutterBottom
              variant="h4">
              Delete Series from Database
            </Typography>
            <Divider variant="middle" />
            <Typography className={classes.middle} color="textSecondary">
              {question}
            </Typography>
            <Typography className={classes.bottom} component="h2" variant="h6">
              Do you want to continue?
            </Typography>
          </CardContent>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button autoFocus onClick={() => handleClose(false)}>
            No
          </Button>
          <ButtonLoad
            color="primary"
            handleClick={() => this.handleDeleteSelected()}
            loading={this.state.loading_delete}
            name="Yes"
            transform
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogWarningDeleteSeries.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

DialogWarningDeleteSeries.defaultProps = {
  open: false,
};
export default withStyles(DialogWarningDeleteSeries, useStyles);
