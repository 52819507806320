import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { SearchInput, ButtonFilterSearch } from 'components';
import { defaultFilter } from 'components/Basics/FilterContainer';

class ToolbarSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        ...defaultFilter,
      },
    };
    const { holder } = props;
    if (holder) {
      holder.getFilter = () => this.state.filters;
    }
  }
  handleFilterChanged = filters => {
    this.setState({ filters });
  };
  render() {
    const { view } = this.props;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="flex-start"
        sm={6}
        xs={10}>
        <Grid item xs={10}>
          <SearchInput
            onChange={this.props.handleChangeName}
            placeholder={`Search ${view}`}
            value={this.props.name}
          />
        </Grid>
        <Grid item xs={2}>
          <ButtonFilterSearch
            filters={this.state.filters}
            handleAddError={this.props.handleAddError}
            handleSearch={this.props.handleReloadTable}
            onClickReset={() =>
              this.setState({ filters: { ...defaultFilter } })
            }
            onFilterChanged={this.handleFilterChanged}
            view={this.props.view}
          />
        </Grid>
      </Grid>
    );
  }
}

ToolbarSearchFilter.propTypes = {
  view: PropTypes.string,
};

export default ToolbarSearchFilter;
