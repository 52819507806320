import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CanvasWebGL } from './components';
import { getLevels } from 'helpers';
import { setDisplayDicomSeries } from 'redux-store/actions';
import { Grid } from '@mui/material';
import { SideStudySeriesBar } from './components';
import { drawerMaxWidth } from './components/SideStudySeriesBar/useStyles';
const useStyles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
});

class DicomViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSeries: null,
      idStudy: null,
      open_thumbnails: false,
      series: null,
      slice_position: -1,
    };
    this.holder = {
      updateWindowSize: [],
      loadVolume: null,
      setSlicePosition: null,
      getSlicePosition: null,
    };
  }
  updateSeriesID = () => {
    const { level_1, level_2 } = getLevels(this.props);
    const { study } = this.props;
    if (level_1 !== undefined) {
      if (level_1 === 'series' && level_2 !== undefined) {
        const idSeries = parseInt(level_2, 10);
        if (idSeries !== this.state.idSeries) {
          this.setState({ idSeries });
        }
      } else if (level_1 === 'study' && level_2 !== undefined) {
        const idStudy = parseInt(level_2, 10);
        if (idStudy !== this.state.idStudy) {
          let series = null;
          if (study && study.Series && study.Series.length) {
            series = study.Series[0];
          }
          console.log({ study });
          this.setState({ idStudy, series });
        } else if (idStudy && this.state.idStudy) {
          if (!this.state.series) {
            if (study && study.Series && study.Series.length) {
              this.setState({ idStudy, series: study.Series[0] });
            }
          }
        }
      }
    }
  };
  componentDidMount() {
    this.updateSeriesID();
  }
  componentDidUpdate() {
    this.updateSeriesID();
  }
  onWindowChange = () => {
    const { updateWindowSize } = this.holder;
    for (let i = 0; i < updateWindowSize.length; i++) {
      updateWindowSize[i]();
    }
  };
  handleOnSeriesClick = series => {
    if (series === undefined || !series) {
      return;
    }
    this.setState({ series });
    if (this.holder.loadVolume) {
      this.holder.loadVolume(series.idSeries);
    }
  };
  render() {
    let { idSeries, open_thumbnails } = this.state;
    let { series, height, width, study } = this.props;
    let position = 0;
    if (!series && study && this.state.series) {
      series = this.state.series;
      idSeries = this.state.series.idSeries;
    }
    if (open_thumbnails) {
      width -= drawerMaxWidth;
    } else {
      width -= 70;
    }
    if (series) {
      position = Math.floor(series.NumberOfSeriesRelatedInstances / 2);
      if (idSeries !== series.idSeries) {
        idSeries = series.idSeries;
      }
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="flex-start"
        // spacing={1}
        style={{ height: '100%' }}>
        <SideStudySeriesBar
          handleDrawerClose={() =>
            this.setState({ open_thumbnails: !open_thumbnails })
          }
          height={height - 15}
          onSeriesClick={this.handleOnSeriesClick}
          open={open_thumbnails}
          series={series}
          study={this.props.study}
        />
        <Grid item style={{ height: height - 70 }}>
          <CanvasWebGL
            drawerWidth={drawerMaxWidth}
            holder={this.holder}
            idSeries={idSeries}
            isDesktop={this.props.isDesktop}
            open={open}
            series={series}
            slice_position={position}
            width={width}
          />
          {/* <Grid item xs={12}></Grid> */}
        </Grid>
      </Grid>
    );
  }
}

DicomViewer.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  let { series, study } = state.page_dicom_viewer;
  let { width_sidebar, openSidebar } = state.page_globals;
  const { isDesktop } = state.layout;
  let width = window.innerWidth;
  if (openSidebar && isDesktop) {
    width -= width_sidebar;
  }
  return { series, study, width };
};

export default connect(mapStateToProps, { setDisplayDicomSeries })(
  withStyles(withRouter(DicomViewer), useStyles)
);
