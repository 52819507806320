/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import {
  DirectChatBottomBar,
  DirectChatMessages,
  DirectChatToolBar,
} from './components';

import { SendDirectMessage } from 'graphql/Communications';
import { GetData } from 'helpers';

const useStyles = () => ({
  main_container: {
    width: '99.7%',
    height: '100%',
    borderLeft: '1px solid #eaeaeb',
  },
  root_messages: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    transform: 'scaleY(-1)',
  },
});

class DirectChatRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      not_sent_message: '',
      subcribedTo: -1,
    };
    this.props.holder.unsubcribeChat = async receiverId => {
      await this.chatsSubCriptions(receiverId);
    };
  }

  IsSubscribed = () => {
    return true;
  };
  chatsSubCriptions = () => {};

  componentDidUpdate() {
    const { not_sent_message, loading } = this.state;
    if (!loading && not_sent_message !== '')
      this.handleSendMessage(not_sent_message);
  }
  handleSendMessage = async message => {
    const { Receiver } = this.props;
    const { subcribedTo } = this.state;
    if (subcribedTo !== Receiver.id) {
      this.setState({ not_sent_message: message, subcribedTo: Receiver.id });
      return;
    }
    this.setState({ loading: true });
    (async () => {
      SendDirectMessage(message, Receiver.id)
        .then(res => {
          const data = GetData(res);
          const { createDirectMessage } = data;
          if (createDirectMessage) {
            this.setState({ not_sent_message: '', loading: false });
          } else {
            throw Error('Failed to send message');
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };

  render() {
    const {
      classes,
      Receiver,
      user,
      messages,
      height,
      isMobile,
      handleDrawerToggle,
    } = this.props;

    return (
      <div>
        <DirectChatToolBar
          handleDrawerToggle={handleDrawerToggle}
          handleOnlineUser={this.props.handleOnlineUser}
          isMobile={isMobile}
          Receiver={Receiver}
        />
        <div className={classes.main_container}>
          <DirectChatMessages
            height={height}
            idReceiver={Receiver.id}
            messages={messages}
          />
        </div>
        <DirectChatBottomBar
          handleSendMessage={this.handleSendMessage}
          idReceiver={Receiver.id}
          IsSubscribed={this.IsSubscribed}
          Subscribe={this.chatsSubCriptions}
          user={user}
        />
      </div>
    );
  }
}

DirectChatRight.propTypes = {
  classes: PropTypes.object.isRequired,
};
DirectChatRight.defaultProps = {
  handleOnlineUser: () => '',
};
export default withStyles(DirectChatRight, useStyles);
