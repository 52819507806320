import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { DivGrow } from 'components';
import { useStyles } from './Styles';

class UnitTopBar extends Component {
  render() {
    const { classes, className, unit, loading } = this.props;
    let administrator = '';
    let name = null;
    if (unit && !loading) {
      const { Admin } = unit;
      administrator = Admin.FullName;
      name = unit.name;
    } else if (loading) {
      name = 'Loading...';
    } else {
      name = 'Unexpected error...';
    }
    return (
      <div className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <Typography className={classes.textTop} component="div">
            <Box
              m={1}
              style={{ fontSize: 12, fontWeight: 400 }}
              variant="button">
              BROWSE UNIT
            </Box>
            <Box m={1} spacing={3} style={{ fontSize: 22, fontWeight: 1000 }}>
              {name ? name : 'Not Defined'}
            </Box>
            <Box m={1}> </Box>
          </Typography>
          <DivGrow />
          <Typography className={classes.textTop} component="div">
            <Box m={1}> </Box>
            <Box
              m={1}
              style={{ fontSize: 12, fontWeight: 400 }}
              variant="button">
              Administrator
            </Box>
            <Box m={1} spacing={1} style={{ fontSize: 22, fontWeight: 1000 }}>
              {administrator}
            </Box>
          </Typography>
        </div>
        <div className={classes.row}>{/* <Box m={1} /> */}</div>
      </div>
    );
  }
}

UnitTopBar.propTypes = {
  className: PropTypes.string,
};

export default withStyles(withRouter(UnitTopBar), useStyles);
