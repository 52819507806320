import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SearchProjectsAtUser, ButtonLoad } from 'components';
import { Grid } from '@mui/material';
import { MutationAddProjectObjects } from 'graphql/Projects';
import { GetData, ServerErrorsString } from 'helpers';

class DialogAddObjectToProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      updating: false,
    };
  }
  handleSelectProject = project => {
    this.setState({ project });
  };
  handleAddProjects = () => {
    const { updating, project } = this.state;
    const { selected } = this.props;
    if (updating || !project) return;
    this.setState({ updating: true });
    const inputs = selected.map(x => {
      const { idFolderContent } = x;
      return {
        idFolderContent,
        idProject: project.id,
      };
    });
    (async () => {
      MutationAddProjectObjects(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addProjectObjects;
          if (ok) {
            this.setState({ updating: false, project: null });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { open, handleClose, selected } = this.props;
    if (!selected.length && open) {
      this.props.handleAddError('You need to select element first');
      handleClose();
      return null;
    }
    return (
      <div>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>{`Add Project to ${selected.length} elements`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a project to the selected objects, please enter the name of
              the project here. This action will add the project to the element
              and will check if the rights are present.
            </DialogContentText>
            {/* <SearchUsersProjects
              download_button
              handleAddUserProject={this.handleSelectProject}
            /> */}
            <Grid container item sx={{ paddingTop: 2 }} xs={12}>
              <SearchProjectsAtUser
                external_selected={this.state.project}
                handleAddProject={this.handleSelectProject}
                handleSetProject={this.handleSelectProject}
                no_icon
                placeholder="Search Projects..."
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <ButtonLoad
              // className={classes.button}
              color="primary"
              disable_button={this.state.project ? false : true}
              handleClick={this.handleAddProjects}
              loading={this.state.updating}
              name="Add Project"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogAddObjectToProject.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default DialogAddObjectToProject;
