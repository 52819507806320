import gql from 'graphql-tag';
import axios from 'axios';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_PATIENTS = gql`
  query($query_inputs: PatientQuery, $page: Int, $limit: Int) {
    Patients(query_inputs: $query_inputs, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      total
      patients {
        idPatient
        owner {
          id
          FullName
        }
        FamilyName
        GivenName
        PatientName
        PatientID
        PatientBirthDate
        PatientSex
        NumberOfPatientRelatedStudies
        NumberOfPatientRelatedSeries
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryPatients = (PatientName, page, limit) => {
  const variables = {};
  if (PatientName !== undefined && PatientName && PatientName !== '') {
    variables.query_inputs = { PatientName };
  }
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }
  console.log('QueryPatients: ', variables);
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATIENTS),
    variables,
  });
};
