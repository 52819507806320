import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import {
  ExplorerViewListLeft,
  ExplorerViewListRight,
  useStyles,
} from './components';

class ExplorerViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_explorer: true,
      init: false,
    };
  }

  handleDrawerClose = () => {
    let { open_explorer, init } = this.state;
    const { isMobileSmall } = this.props;
    if (!init && isMobileSmall) open_explorer = false;
    this.setState({ init: true, open_explorer: !open_explorer });
  };

  render() {
    const {
      classes,
      HomeFolder,
      base,
      isMobile,
      isMobileSmall,
      ...res
    } = this.props;
    const { height } = this.props;
    let { open_explorer, init } = this.state;
    if (!init && isMobileSmall) open_explorer = false;
    //
    return (
      <Grid
        alignItems="stretch"
        container
        direction="row"
        justifyContent="flex-start">
        <Drawer
          anchor="left"
          classes={{
            paper: clsx(classes.drawer_paper, {
              [classes.drawer_position]: !open_explorer,
              [classes.drawer_mobile]: !isMobileSmall && !open_explorer,
            }),
          }}
          onClose={this.handleDrawerClose}
          open={open_explorer}
          variant={isMobileSmall ? 'relative' : 'persistent'}>
          <ExplorerViewListLeft
            base={base}
            handleAddError={this.props.handleAddError}
            handleDeleteFoldersAndContent={
              this.props.handleDeleteFoldersAndContent
            }
            handleDrawerClose={this.handleDrawerClose}
            height={height}
            HomeFolder={HomeFolder}
            idHomeFolder={this.props.idHomeFolder}
            isMobile={isMobile}
            isMobileSmall={isMobileSmall}
            open_explorer={open_explorer}
            user={this.props.user}
          />
        </Drawer>
        <Grid
          className={clsx(classes.content, {
            [classes.contentShift]: isMobileSmall ? false : open_explorer,
          })}
          item>
          <ExplorerViewListRight
            {...res}
            base={base}
            handleDrawerClose={this.handleDrawerClose}
            isMobile={isMobile}
            isMobileSmall={isMobileSmall}
            open_explorer={open_explorer}
          />
        </Grid>
      </Grid>
    );
  }
}

ExplorerViewList.propTypes = {
  classes: PropTypes.object,
};
ExplorerViewList.defaultProps = {
  handleSelectAll: () => '',
  handleSelected: () => '',
  handleOnRowClick: () => '',
  isSelected: () => '',
  handleOpenAtDoubleClick: () => '',
};

export default withStyles(ExplorerViewList, useStyles);
