import React from 'react';
import PropTypes from 'prop-types';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import ImageIcon from '@mui/icons-material/Image';

import {
  FormatTime,
  GetData,
  getInitials,
  GetNameFromHeader,
  GetResponseError,
  ServerErrorsString,
} from 'helpers';
import useStyles from '../Styles';
import { DialogShareFiles, SnackMessage, TableRights } from 'components';
import {
  ButtonBoxSeriesDetails,
  DialogEditSeries,
  SeriesTable,
} from './components';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import { DownloadLink, SharedLink } from '../components';
import { IsInvalid } from 'helpers';

class SeriesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      downloading: false,
      open_share: false,
      open_edit: false,
    };
  }

  handleClearError = () => {
    this.setState({ error: '' });
  };
  handleClickDownload = () => {
    const { downloading } = this.state;
    if (downloading) return;
    const { Series } = this.props;
    if (!Series) return;
    const { idSeries } = Series;
    const Ids = [{ idSeries }];
    this.setState({ downloading: true });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(Ids);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key, this.handleDownloadProgress)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.handleDownloadFinished();
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleDownloadProgress = (event, loading) => {
    const { folder_content } = this.props;
    if (folder_content === undefined) return;
    const { Series } = folder_content;
    let size = 0;
    if (Series) size = Series.Size;
    this.props.handleProgress(event, loading, size);
  };
  handleDownloadFinished = () => {
    const { folder_content } = this.props;
    if (folder_content === undefined) return;
    const { Series } = folder_content;
    let size = 0;
    if (Series) size = Series.Size;
    this.props.handleProgress({ loaded: size, total: size }, size, size);
  };
  handleOpenShare = () => {
    this.setState(s => ({ open_share: !s.open_share }));
  };
  handleShareFolder = () => {
    const { folder_content } = this.props;
    this.props.handleItemChanged(folder_content);
  };

  render() {
    const { classes, theme, handleDrawerClose, owner, height } = this.props;
    let { folder_content, Series } = this.props;
    if (IsInvalid(folder_content)) {
      folder_content = {};
    }
    if (IsInvalid(Series)) {
      Series = {};
    }
    const { Owner, idFolderContent } = folder_content;
    let owner_name = 'Not found!!!';
    if (Owner) {
      owner_name = Owner.FullName;
    }
    const { error, downloading, open_share } = this.state;

    let Study = {
      StudyDescription: '',
      StudyInstanceUID: '',
      AccessionNumber: '',
    };
    if (Series.Study) {
      Study.StudyDescription = Series.Study.StudyDescription;
      Study.StudyInstanceUID = Series.Study.StudyInstanceUID;
      Study.AccessionNumber = Series.Study.AccessionNumber;
    }
    let style = null;
    if (height) {
      style = {
        height: height - 260,
        // maxHeight: height - 400,
        overflowY: 'auto',
      };
    }
    let SeriesDescription = '';
    if (Series) SeriesDescription = Series.SeriesDescription;
    if (SeriesDescription && SeriesDescription.length > 20) {
      SeriesDescription = SeriesDescription.substring(0, 20) + '...';
    }
    return (
      <React.Fragment>
        <Divider />
        <ListItem className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <ListItemAvatar>
            {Series.Thumbnail ? (
              <Avatar src={`data:image/jpeg;base64,${Series.Thumbnail}`}>
                {getInitials(SeriesDescription)}
              </Avatar>
            ) : (
              <Avatar>
                <ImageIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={SeriesDescription}
            secondary={`Series Added: ${FormatTime(
              Series.createdAt,
              'MMMM Do YYYY, h:mm:ss a'
            )} (ID: ${idFolderContent})`}
          />
        </ListItem>
        <Divider variant="inset" />

        <div className={classes.section1} style={style}>
          <ListItemText
            primary={
              <Typography gutterBottom variant="h6">
                Owner
              </Typography>
            }
            secondary={owner_name}
          />
          <Grid alignItems="center" container>
            <Grid item xs>
              <Typography gutterBottom variant="h4">
                {`Series (${Series.idSeries})`}
              </Typography>
            </Grid>
            <SeriesTable Series={Series} Study={Study} />
          </Grid>
          <SharedLink
            folder_content={folder_content}
            handleError={this.props.handleError}
            handleGetSharedLink={this.props.handleGetSharedLink}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            user={this.props.user}
          />
          <DownloadLink
            folder_content={folder_content}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleError={this.props.handleError}
            handleGetDownloadLink={this.props.handleGetDownloadLink}
            user={this.props.user}
          />
          <ListItemText
            primary={
              <Typography
                gutterBottom
                style={{ textTransform: 'none', fontSize: 15 }}
                variant="h6">
                Permissions
              </Typography>
            }
          />
          <TableRights rights={folder_content.Rights} />
        </div>
        <Divider />
        <ButtonBoxSeriesDetails
          classes={classes}
          downloading={downloading}
          handleButtonShare={this.handleOpenShare}
          handleClickDownload={this.handleClickDownload}
          handleEditSeries={() => this.setState({ open_edit: true })}
          handleOpenDelete={() => this.props.handleOpenDelete(folder_content)}
          owner={owner}
          Series={Series}
        />
        <SnackMessage
          handleClose={this.handleClearError}
          message_text={error}
          open={error === '' ? false : true}
          type="error"
        />
        <DialogShareFiles
          files_contents={[folder_content]}
          handleClose={this.handleOpenShare}
          handleShareFolder={this.handleShareFolder}
          open={open_share}
        />
        <DialogEditSeries
          handleClose={() => this.setState({ open_edit: false })}
          open={this.state.open_edit}
          Series={Series}
        />
      </React.Fragment>
    );
  }
}

SeriesDetails.propTypes = {
  classes: PropTypes.object,
};
SeriesDetails.defaultProps = {
  handleProgress: () => '',
};
const ContainerSeriesDetails = props => {
  const theme = useTheme();
  return <SeriesDetails theme={theme} {...props} />;
};
export default withStyles(withRouter(ContainerSeriesDetails), useStyles);
