import React from 'react';
import PropTypes from 'prop-types';

import { Divider, useTheme } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  GetData,
  GetNameFromHeader,
  GetResponseError,
  ServerErrorsString,
} from 'helpers';
import useStyles from '../../../Styles';
import ButtonBoxShareDelete from '../../../ButtonBoxShareDelete';
import {
  DialogShareFiles,
  TableRights,
  DialgoEditMetadata,
  DialogViewFile,
} from 'components';

import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';

import { FileDetailContent, FileDetailHeader } from './components';
import { IsFileReadable } from 'common';
class FileDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_share: false,
      deleting: false,
      downloading: false,
      open_metadata_filecontent: false,
    };
  }
  handleShareFile = () => {};
  handleOpenShare = () => {
    this.setState(s => ({ open_share: !s.open_share }));
  };

  handleShareFolder = rights => {
    const { folder_content } = this.props;
    folder_content.Rights = rights;
    // this.setState({ open_share: false });

    this.props.handleItemChanged(folder_content);
  };
  handleClickDownload = () => {
    const { downloading } = this.state;
    if (downloading) return;
    const { folder_content } = this.props;
    if (!folder_content) return;
    const { idFolderContent } = folder_content;
    const Ids = [{ idFolderContent }];
    this.setState({ downloading: true });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(Ids);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key, this.handleDownloadProgress)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.handleDownloadFinished();
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleDownloadProgress = (event, loading) => {
    const { folder_content } = this.props;
    if (folder_content === undefined) return;
    const { File } = folder_content;
    let size = 0;
    if (File) size = File.Size;
    this.props.handleProgress(event, loading, size);
  };
  handleDownloadFinished = () => {
    const { folder_content } = this.props;
    if (folder_content === undefined) return;
    const { File } = folder_content;
    let size = 0;
    if (File) size = File.Size;
    this.props.handleProgress({ loaded: size, total: size }, size, size);
  };
  render() {
    const {
      classes,
      theme,
      folder_content,
      loading,
      user,
      handleDrawerClose,
    } = this.props;
    const { open_share, downloading } = this.state;
    const { Owner } = folder_content;
    // console.log({ folder_content });

    let owner = false;
    if (Owner && Owner.FullName) {
      owner = user.id === Owner.id;
    }
    let ComponentViewEdit = null;
    const { File } = folder_content;
    let enable_open_button = null;
    if (IsFileReadable(File)) {
      enable_open_button = true;
      ComponentViewEdit = (
        <DialogViewFile
          file={File}
          handleAddError={this.props.handleAddError}
          handleClose={() =>
            this.setState({ open_metadata_filecontent: false })
          }
          open_edit={this.state.open_metadata_filecontent}
        />
      );
    } else {
      enable_open_button = user && user.idCategory === 2;
      if (enable_open_button) {
        ComponentViewEdit = (
          <DialgoEditMetadata
            folder_content={folder_content}
            handleAddError={this.props.handleAddError}
            handleClose={() =>
              this.setState({ open_metadata_filecontent: false })
            }
            handleUpdateFolderContent={this.props.handleUpdateFolderContent}
            handleUpdateMetadata={metadata => {
              this.props.handleUpdateMetadata(metadata);
              folder_content.File.metadata = {
                ...metadata,
              };
              console.log({ folder_content });
              this.props.handleItemChanged(folder_content);
              this.setState({ open_metadata_filecontent: false });
            }}
            open_edit={this.state.open_metadata_filecontent}
          />
        );
      }
    }
    return (
      <React.Fragment>
        <FileDetailHeader
          classes={classes}
          folder_content={folder_content}
          handleDrawerClose={handleDrawerClose}
          theme={theme}
        />
        <Divider variant="inset" />
        <div className={classes.section1}>
          <FileDetailContent
            base={this.props.base}
            classes={classes}
            folder_content={folder_content}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleError={this.props.handleError}
            handleGetDownloadLink={this.props.handleGetDownloadLink}
            handleGetSharedLink={this.props.handleGetSharedLink}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            user={user}
          />
          <TableRights rights={folder_content.Rights} />
        </div>
        <Divider />
        <ButtonBoxShareDelete
          downloading={downloading}
          handleButtonDelete={() => this.props.handleOpenDelete(folder_content)}
          handleButtonShare={this.handleOpenShare}
          handleClickDownload={this.handleClickDownload}
          handleClickShow={() =>
            this.setState({ open_metadata_filecontent: true })
          }
          loading={loading}
          open={enable_open_button}
          owner={owner}
          show_text="Open File"
          tooltip_delete="Delete File"
        />
        <DialogShareFiles
          files_contents={[folder_content]}
          handleClose={this.handleOpenShare}
          handleShareFolder={this.handleShareFolder}
          open={open_share}
        />
        {ComponentViewEdit}
      </React.Fragment>
    );
  }
}

FileDefault.propTypes = {
  classes: PropTypes.object,
};
FileDefault.defaultProps = {
  user: { id: -1 },
  handleProgress: () => '',
  handleUpdateMetadata: () => '',
  handleUpdateFolderContent: () => '',
  handleItemChanged: () => '',
  deleting: false,
};
const ContainerFileDefault = props => {
  const theme = useTheme();
  return <FileDefault theme={theme} {...props} />;
};
export default withStyles(ContainerFileDefault, useStyles);
