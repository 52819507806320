import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_DOCKER_MAPPINGS = gql`
  query {
    allDockerMappings {
      ok
      docker_mappings {
        id
        table_name_input
        table_name_output
        table_map_name
        id_table_input
        id_table_output
      }
      errors {
        message
      }
    }
  }
`;
export const QueryDockerMappings = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPINGS),
  });
};

export const QUERY_DOCKER_MAPPING_TABLES = gql`
  query {
    allDockerMappingTables {
      ok
      errors {
        message
      }
      tables {
        name
      }
      total
    }
  }
`;
export const QueryDockerMappingTables = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPING_TABLES),
  });
};

export const QUERY_DOCKER_MAPPING_JOINS = gql`
  query($table_name_input: String!, $table_name_output: String!) {
    allDockerMappingJoins(
      table_name_input: $table_name_input
      table_name_output: $table_name_output
    ) {
      ok
      errors {
        message
      }
      joins {
        id
        table_map_name
        table_name_input
        table_name_output
        id_table_input
        id_table_output
      }
      total
    }
  }
`;
export const QueryDockerMappingJoins = (
  table_name_input,
  table_name_output
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPING_JOINS),
    variables: {
      table_name_input,
      table_name_output,
    },
  });
};

export const MUTATION_CREATE_MAPPING_TABLES = gql`
  mutation($inputs: [NewMappingTables!]) {
    createMappingTables(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      warnings
      inserted {
        id
        table_map_name
        table_name_input
        table_name_output
        id_table_input
        id_table_output
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationCreateMappingTables = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_MAPPING_TABLES),
    variables: {
      inputs,
    },
  });
};

export const MUTATION_UPDATE_MAPPING_TABLES = gql`
  mutation($inputs: UpdateMappingTable!) {
    updateMappingTable(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateMappingTable = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_MAPPING_TABLES),
    variables: {
      inputs,
    },
  });
};

export const MUTATION_REMOVE_MAPPING_TABLES = gql`
  mutation($mappingTableIds: [LongLong!]) {
    removeMappingTables(mappingTableIds: $mappingTableIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveMappingTable = mappingTableIds => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_MAPPING_TABLES),
    variables: {
      mappingTableIds,
    },
  });
};
