import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
} from 'components';
import { Button, ButtonGroup, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

class TabSummaryToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
    };
  }

  render() {
    const { classes } = this.props;
    const { changed } = this.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={0}
          pl={1}
          pr={2}
          pt={0}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.button_group}
              exclusive="false"
              size="small"
              value="save">
              <Button disabled={!changed} onClick={this.props.handleSave}>
                <SaveIcon style={{ color: changed ? 'green' : 'gray' }} />
              </Button>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <ButtonDownloadType
                disabled
                idProject={this.props.idProject}
                key="anonymization"
                type="anonymization_tags"
              />
              <ButtonFileUpload
                disabled
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              handleReloadTable={this.props.handleReload}
              loading_table={this.props.loading}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

TabSummaryToolbar.propTypes = {
  classes: PropTypes.object,
};

export default TabSummaryToolbar;
