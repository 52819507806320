export const defaultSurvey = {
  currentSurvey: null,
  loading: false,
  currentPageNo: 0,
  saving: false,
  buttonSave: null,
  buttonCancel: null,
  updatedAt: null,
  value_changed: [],
};
export { default } from './SurveyStepper';
