import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSleepCSV } from './utils';

const QUERY_SLEEP_CASE = gql`
  query($id: LongLong) {
    sleepCase(id: $id) {
      ok
      errors {
        path
        message
      }
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication
        tot_files
        tot_series
        tot_edfs
        polysomnography {
          id
          idPatient
          idSleepCase
          start_datetime
          cpap_device
          sleep_duration
          sleep_latency
          rem_latency
          sleep_efficiency
          rem_percentage
          stage1_percentage
          stage2_percentage
          stage3_percentage
          supine_sleep_index
          apnoea_sleep_index
          hypopnoea_sleep_index
          centrale_sleep_index
          obstructive_sleep_index
          mixed_sleep_index
          average_spo2
          min_spo2
          time_90_spo2
          time_88_spo2
          desat_index
          co2_average_sleep
          co2_max_sleep
          co2_50_more_duration
          average_hf
          arousal_index
          plm_sleep_index
          subject_sleep_quality_pre
          subject_sleep_quality
          subject_sleep_duration
          subject_sleep_latency
          diagnose1
          diagnose2
          diagnose3
          ahi_sleep
          ahi_non_supine
          ahi_supine
          ahi_rem
          ahi_nrem
        }
        vigilance {
          id
          idPatient
          idSleepCase
          test_type
          pvt_mean
          pvt_lapses
          sart_total_errors
          stear_clear
          pupillography_var
        }
        test_types {
          idPatient
          idSleepCase
          type
          instruction
          date
          diagnose1
          diagnose2
          diagnose3
          nr_repetitions
          sleep_latency
          sorem_n
          sleep_case {
            id
            record_id
          }
          repetitions {
            id
            start_datetime
            sleep_latency
            rem_latency
            kss_pre
            kss_post
          }
        }
        patient {
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
        files {
          id
          idEpilepsyCase
          idSleepCase
          idPatient
          idFileObject
          idSeries
          idSignalEDF
          idFolder

          epilepsy_case {
            id
          }
          sleep_case {
            id
          }
          file_object {
            idFileObject
            original_name
            mimetype
            filepath
            createdAt
          }
          series {
            idSeries
            Thumbnail
            DicomFiles {
              idFile
              OriginalName
              FilePath
            }
            createdAt
          }
          edf {
            id
            file_object {
              idFileObject
              original_name
              mimetype
              filepath
            }
            createdAt
          }
          folder {
            idFolder
            name
            createdAt
          }
          folder_content {
            idFolderContent
            idFolder
          }
          patient {
            id
            PatientID
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepCase = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_CASE),
    variables: {
      id,
    },
  });
};

const QUERY_SLEEP_CASES = gql`
  query($record_id: String, $page: Int, $limit: Int) {
    sleepCases(record_id: $record_id, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication
        tot_files
        tot_series
        tot_edfs
        patient {
          PatientName
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepCases = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_CASES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const QUERY_SLEEP_PATIENTS = gql`
  query($page: Int, $limit: Int, $anonymize: Int) {
    sleepPatients(page: $page, limit: $limit, anonymize: $anonymize) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepPatients = (page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_PATIENTS),
    variables: {
      page,
      limit,
      anonymize,
    },
  });
};
const MUTATION_REMOVE_SLEEP_CASES = gql`
  mutation($ids: [LongLong!]!) {
    deleteSleepCases(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveSleepCases = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_SLEEP_CASES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_SLEEP_CASES = gql`
  mutation($inputs: [InputSleepCase!]!) {
    addSleepCases(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddSleepCases = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SLEEP_CASES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_SLEEP_CASES = gql`
  mutation($file: Upload!) {
    uploadSleepCases(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication
        tot_files
        tot_series
        tot_edfs
        patient {
          PatientName
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadSleepCases = (file, handleProgress = null) => {
  return MutationUploadSleepCSV(
    file,
    MUTATION_UPLOAD_SLEEP_CASES,
    handleProgress
  );
};

const MUTATION_UPLOAD_SLEEP_ALL = gql`
  mutation($file: Upload!) {
    uploadSleep(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        record_id
        description
        redcap_event_name
        ess_score
        fss_score
        anamnesy
        medication

        patient {
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadSleepAll = (file, handleProgress = null) => {
  return MutationUploadSleepCSV(
    file,
    MUTATION_UPLOAD_SLEEP_ALL,
    handleProgress
  );
};
