import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ACCOUNT_CATEGORIES = gql`
  query($name: String, $page: Int, $limit: Int) {
    accountCategories(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      categories {
        id
        name
        code
        description
        updatedAt
      }
    }
  }
`;

export const QueryAccountCategories = (name, page, limit) => {
  const variables = {};
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (name !== undefined && name) variables.name = name;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ACCOUNT_CATEGORIES),
    variables,
  });
};

const QUERY_ALL_USERS_FULL_BY_CATEGORY = gql`
  query(
    $idCategory: LongLong!
    $email: String
    $page: Int
    $limit: Int
    $name: String
    $orderBy: [String!]
  ) {
    usersByCategory(
      idCategory: $idCategory
      email: $email
      page: $page
      limit: $limit
      name: $name
      orderBy: $orderBy
    ) {
      ok
      errors {
        path
        message
      }
      total
      users {
        id
        title
        FullName
        firstName
        lastName
        email
        avatar
        isActive
        account {
          code
          description
        }
        category {
          id
          code
          name
        }
        createdAt
        phone
        username
        address {
          city
          country
        }
      }
    }
  }
`;
export const QueryFullAllUsersByCategory = (
  idCategory,
  email,
  name,
  page,
  limit,
  orderBy = null
) => {
  const variables = { idCategory };
  if (email !== undefined && email) variables.email = email;
  if (name !== undefined && name) variables.name = name;
  if (limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }
  if (orderBy !== undefined && orderBy !== null) variables.orderBy = orderBy;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_USERS_FULL_BY_CATEGORY),
    variables,
  });
};
