import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styleToolbar } from 'common';
import { ButtonReloadDelete } from 'components';
class ToolBarAnonySeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, selected } = this.props;
    let { numSelected } = this.props;
    if (selected) numSelected = selected.length;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid item>ToolBarAnonySeries</Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReloadTable}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              numSelected={numSelected}
              show_delete
              tooltip="Reload Jobs"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarAnonySeries.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ToolBarAnonySeries, styleToolbar);
