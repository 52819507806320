import { FormatBytes, GetThumbnailFile } from 'helpers';

export const getFileSeries = (object, row) => {
  const { Series, File } = object;
  if (typeof Series !== 'undefined' && Series) {
    row.name = Series.SeriesDescription;
    row.Thumbnail = Series.Thumbnail;
    row.kind = 'dcm';
    row.size = FormatBytes(Series.Size);
  } else if (typeof File !== 'undefined' && File) {
    row.name = File.original_name;
    row.kind = '';
    row.Thumbnail = GetThumbnailFile(File.original_name);
    row.size = FormatBytes(File.Size);
    //
  }
  row.permission = object.Rights;
  return row;
};
export const getRow = value => {
  let row = {
    id: -1,
    Thumbnail: null,
    kind: '',
    name: '',
    size: '0 Bytes',
    data_modified: '',
    permission: [],
    Owner: {
      idUser: -1,
    },
  };
  console.log({ value });
  const { id, Folder, FolderContent, MultiObject, updatedAt } = value;
  row.id = id;
  row.data_modified = updatedAt;
  if (typeof Folder !== 'undefined' && Folder) {
    row.name = Folder.name;
    row.kind = '--';
    row.permission = Folder.Rights;
    row.size = FormatBytes(Folder.Size);
    row.Owner = Folder.Owner;
  } else if (typeof FolderContent !== 'undefined' && FolderContent) {
    row = getFileSeries(FolderContent, row);
    row.permission = FolderContent.Rights;
    row.Owner = FolderContent.Owner;
  } else if (typeof MultiObject !== 'undefined' && MultiObject) {
    row = getFileSeries(MultiObject, row);
    row.permission = [MultiObject.Rights];
    row.Owner = MultiObject.Owner;
  }
  return row;
};
export const getRowProjectObjects = value => {
  let row = {
    id: -1,
    Thumbnail: null,
    kind: '',
    name: '',
    size: '0 Bytes',
    data_modified: '',
    permission: [],
    Owner: {
      idUser: -1,
    },
  };
  const { id, folder, folder_content, multi_object, updatedAt } = value;
  row.id = id;
  row.data_modified = updatedAt;
  if (folder !== undefined && folder) {
    row.name = folder.name;
    row.kind = '--';
    row.permission = folder.Rights;
    row.size = FormatBytes(folder.Size);
    row.Owner = folder.Owner;
  } else if (folder_content !== undefined && folder_content) {
    row = getFileSeries(folder_content, row);
    row.permission = folder_content.Rights;
    row.Owner = folder_content.Owner;
  } else if (multi_object !== undefined && multi_object) {
    row = getFileSeries(multi_object, row);
    row.permission = [multi_object.Rights];
    row.Owner = multi_object.Owner;
  }
  return row;
};
