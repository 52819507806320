import React from 'react';
import Grid from '@mui/material/Grid';
import { RightCheckBox } from './components';

export default function TableCellPermission(props) {
  const handleChecked = event => {
    event.preventDefault();
    const { row } = props;
    const title = event.target.name;
    const checked = event.target.checked;
    //
    if (title === 'Download') {
      row.download = checked;
    } else if (title === 'Read') {
      row.read = checked;
    } else if (title === 'Write') {
      row.write = checked;
    } else if (title === 'Delete') {
      row.delete = checked;
    } else {
      row.full = checked;
      row.read = checked;
      row.download = checked;
      row.delete = checked;
      row.write = checked;
    }
    if (
      title !== 'Full' &&
      row.read &&
      row.download &&
      row.delete &&
      row.write
    ) {
      row.full = true;
    }
    //
    props.handleCheckedUserPermission(props.row);
  };
  const { row } = props;
  return (
    <Grid container direction="row">
      <RightCheckBox
        checked={row.write ? true : false}
        disabled={row.full ? true : false || props.disabled}
        handleChecked={handleChecked}
        title="Write"
      />
      <RightCheckBox
        checked={row.read ? true : false}
        disabled={row.full ? true : false || props.disabled}
        handleChecked={handleChecked}
        title="Read"
      />
      <RightCheckBox
        checked={row.download ? true : false}
        disabled={row.full ? true : false || props.disabled}
        handleChecked={handleChecked}
        title="Download"
      />
      <RightCheckBox
        checked={row.delete ? true : false}
        disabled={row.full ? true : false || props.disabled}
        handleChecked={handleChecked}
        title="Delete"
      />
      <RightCheckBox
        checked={row.full ? true : false}
        disabled={props.disabled}
        handleChecked={handleChecked}
        title="Full"
      />
    </Grid>
  );
}
