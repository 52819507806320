import Vector3D from './Vector3D';
class Line3D {
  constructor(P0, P1) {
    this.set(P0, P1);
  }
  setStart(point3d) {
    this.P0 = point3d;
  }
  setEnd(point3d) {
    this.P1 = point3d;
  }
  getStart() {
    return this.P0.clone();
  }
  getEnd() {
    return this.P1.clone();
  }
  set(P0, P1) {
    this.P0 = P0 || new Vector3D();
    this.P1 = P1 || new Vector3D();
    return this;
  }
  lengthSq() {
    const V = this.P1.subtract(this.P0);
    return V.lengthSq();
  }
  length() {
    const V = this.P1.subtract(this.P0);
    return V.length();
  }
  normal() {
    const V = this.P1.subtract(this.P0);
    V.normalize();
    return V;
  }
  ray() {
    const V = this.P1.subtract(this.P0);
    V.normalize();
    return V;
  }
  middlePoint() {
    const ray = this.ray();
    const size = this.length();
    return this.P0.add(ray.multiplyScalar(size / 2.0));
  }
  getVertices() {
    return this.P0.toArray().concat(this.P1.toArray());
  }
}
// subtract two vectors and reutn a new one
Line3D.prototype.angle = function(line2) {
  const dir1 = this.normal();
  const dir2 = line2.normal();
  return Math.acos(dir1.dot(dir2));
};
Line3D.prototype.crossVectors = function(line2) {
  const dir1 = this.normal();
  dir1.normalize();
  const dir2 = line2.normal();
  dir2.normalize();
  const cross = dir1.crossVectors(dir2);
  // if (cross.length()) cross.normalize();
  return cross;
};
Line3D.prototype.pointAtLength = function(delta) {
  const dir1 = this.normal();
  dir1.normalize();
  return this.P0.add(dir1.multiplyScalar(delta));
};
export default Line3D;
