import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_INPUTS_BY_SETTING_ID = gql`
  query($idSetting: LongLong!) {
    getInputsBySettingId(idSetting: $idSetting) {
      ok
      errors {
        path
        message
      }
      inputs {
        id
        idDockerSetting
        in_out_type
        type
        volume
      }
    }
  }
`;
export const QueryInputsBySettingId = idSetting => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_INPUTS_BY_SETTING_ID),
    variables: {
      idSetting,
    },
  });
};
