import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { Card, Divider, Grid, useTheme } from '@mui/material';
import clsx from 'clsx';

import { DetailsMetaData, EditContent, EditMultiContent } from './components';
import { TopMenuEdit, TopMenuEditMulti, useStylesEdit } from '../TopMenu';
import { SelectedArray } from 'helpers';

class ViewEditSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      width: window.innerWidth,
      height: window.innerHeight,
      selected: [],
    };
    this.holder = {
      reload: [],
      removeRow: null,
      getCopySeries: null,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  handleBack = () => {
    this.props.history.goBack();
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  reloadAllDetails = () => {
    for (let i = 0; i < this.holder.reload.length; i++) {
      this.holder.reload[i]();
    }
  };
  handleSelectRow = idSeries => {
    const { selected } = this.state;
    const selectedIndex = selected ? selected.indexOf(idSeries) : 0;
    const newSelected = SelectedArray(selected, idSeries, selectedIndex);
    this.setState({ selected: newSelected });
  };
  handleDelete = () => {
    this.holder.removeRow(this.state.selected);
    this.setState({ selected: [] });
  };
  handleDetailChanged = (ids, to_send) => {
    const { selected } = this.state;
    const { ListSeries } = this.props;
    const copy = this.holder.getCopySeries();
    console.log({ ids, to_send, selected, ListSeries, copy });
  };
  render() {
    const { Series, classes, edit } = this.props; //Series
    const { open, height, selected } = this.state;
    //
    return (
      <Grid className={classes.root} container direction="column">
        <Card
          style={{
            height: height - 68,
          }}>
          {edit === 'multi' ? (
            <TopMenuEditMulti
              handleBack={this.handleBack}
              handleDelete={this.handleDelete}
              handleDrawerOpen={this.handleDrawerOpen}
              open={open}
              reloadAllDetails={this.reloadAllDetails}
              selected={selected}
            />
          ) : (
            <TopMenuEdit
              edit={edit}
              handleBack={this.handleBack}
              handleDrawerOpen={this.handleDrawerOpen}
              open={open}
              Series={Series}
            />
          )}
          <Divider />
          <Grid
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
            item>
            {edit === 'multi' ? (
              <EditMultiContent
                handleError={this.props.handleError}
                handleSelectRow={this.handleSelectRow}
                holder={this.holder}
                selected={selected}
              />
            ) : (
              <EditContent
                handleError={this.props.handleError}
                Series={Series}
              />
            )}
          </Grid>
        </Card>
        <DetailsMetaData
          edit={edit}
          handleDetailChanged={this.handleDetailChanged}
          handleDrawerClose={this.handleDrawerClose}
          ListSeries={selected}
          open={open}
        />
      </Grid>
    );
  }
}

ViewEditSeries.propTypes = {
  ListSeries: PropTypes.array,
  classes: PropTypes.object,
  handleError: PropTypes.func,
  idSeries: PropTypes.number,
};
ViewEditSeries.defaultProps = {
  idSeries: -1,
};
const ContainerViewEditSeries = props => {
  const theme = useTheme();
  return <ViewEditSeries theme={theme} {...props} />;
};
export default withStyles(withRouter(ContainerViewEditSeries), useStylesEdit);
