import { createTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(theme => ({
  root: {
    // width: '100%',
    // minHeight: '100%',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  grid: {
    display: 'block',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  grow: {
    // display: 'block',
  },
  left: {
    marginRight: 'auto',
    marginBottom: 0,
  },
  content: {
    height: '100%',
  },
  botttom: {
    position: 'absolute',
    bottom: 0,
  },
}));

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: '#263238',
          fontSize: '16px',
          // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '-0.05px',
        },
        body1: {
          color: '#263238',
          fontSize: '14px',
          // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          fontWeight: '400',
          lineHeight: '21px',
          letterSpacing: '-0.05px',
        },
      },
    },
  },
});
