import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import SnackMessage from '../../SnackMessage';
import { ButtonLoading } from '../../Buttons';

import { CreateFolderMutation } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';

class DialogCreateFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      message: '',
      warning: '',
      loading: false,
      folder_name: '',
    };
  }

  handleSnaksClose = () => {
    this.setState({ error: '', message: '', warning: '' });
  };
  handleRunCreateFolder = () => {
    const { loading, folder_name } = this.state;
    if (!folder_name.length) {
      this.setState({ error: 'Folder Name is empty' });
      return;
    }
    if (loading) return;
    const { idParent } = this.props;
    this.setState({ loading: true });
    (async () => {
      CreateFolderMutation(idParent, folder_name)
        .then(res => {
          const data = GetData(res);
          const { ok, warnings, errors, folder } = data.createFolder;
          if (ok) {
            this.setState({ loading: false, message: 'Folder created' });
            this.props.handleCreatedFolder(folder);
            this.props.handleClose();
          } else if (warnings) {
            this.setState({ warning: warnings, loading: false });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({
            loading: false,
            error: ServerErrorsString(errors),
          });
          //   setOpen(false);
        });
    })();
  };
  handleChange = event => {
    this.setState({ folder_name: event.target.value });
  };
  keyPress = event => {
    if (event.keyCode === 13) {
      this.handleRunCreateFolder();
    }
  };

  render() {
    const { open, handleClose } = this.props;
    const { message, error, warning, loading } = this.state;
    return (
      <React.Fragment>
        <SnackMessage
          handleClose={this.handleSnaksClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
        <SnackMessage
          handleClose={this.handleSnaksClose}
          message_text={message}
          open={message !== '' && !loading ? true : false}
        />
        <SnackMessage
          handleClose={this.handleSnaksClose}
          message_text={warning}
          open={warning !== '' && !loading ? true : false}
          type="warning"
        />
        <Dialog
          aria-labelledby="form-dialog-title"
          name="a"
          onClose={handleClose}
          open={open}>
          <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new folder, please enter the name.
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              id="name"
              label="Folder Name"
              // type="email"
              margin="dense"
              onChange={this.handleChange}
              onKeyDown={this.keyPress}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" name="Cancel" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonLoading
              handleClick={this.handleRunCreateFolder}
              loading={loading}
              name="Create"
              transform={false}
              variant="standard"
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

DialogCreateFolder.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
};
DialogCreateFolder.defaultProps = {
  open: false,
  handleCreatedFolder: () => '',
  handleRefresh: () => '',
};
export default DialogCreateFolder;
