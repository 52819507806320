export const useStyles = () => ({
  root: {
    position: 'absolute',
    // height: '100%',
    // width: '100%',
    float: 'right',
    // transition: '0.3s',
  },
  canvas_container: {
    position: 'absolute',
    backgroundColor: 'white', //'#242526',
    float: 'right',
    right: 0,
  },
  box: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '10px',
    padding: '1em',
    paddingTop: 2,
  },
  top_left: {
    left: '1px',
    top: '1px',
  },
  text: {
    padding: 0,
    margin: 0,
    height: 14,
  },
  text_right: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  bottom_left: {
    left: '1px',
    bottom: 0,
  },
  bottom_right: {
    right: '1px',
    bottom: 0,
    textAlign: 'right',
  },
  top_right: {
    right: '1px',
    top: 0,
    textAlign: 'right',
  },
});
