/* eslint-disable react/no-multi-comp */
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StorageIcon from '@mui/icons-material/Storage';
import DraftsIcon from '@mui/icons-material/Drafts';
import TodayIcon from '@mui/icons-material/Today';
import { StyledMenu, StyledMenuItem } from 'components/StyledMenu';
import { SortAscByIcon, SortDescByIcon } from 'icons';

const useStyles = makeStyles()({
  paper: {
    background: 'white',
    border: '2px solid #d3d4d5',
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15)',
  },
});

const SortBy = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(props.orderBy.toLowerCase());
  const { classes } = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.handleSeleted(selected);
  };
  const handleSelected = select => {
    setSelected(select);
    setAnchorEl(null);
    props.handleSeleted(select);
  };
  const style_icon = {
    margin: 0,
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  };
  return (
    <React.Fragment>
      <div
        onClick={handleClick}
        onFocus={() => {}} // Ensure the element can receive focus
        role="button"
        style={props.style}
        tabIndex={0} // Ensure the element is focusable
      >
        {props.order === 'asc' ? (
          <SortAscByIcon style={style_icon} />
        ) : (
          <SortDescByIcon style={style_icon} />
        )}
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        classes={classes}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        position={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transform={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {props.menu.map((x, index) => {
          return (
            <StyledMenuItem
              key={`item-sort-menu-${index}`}
              onClick={handleSelected.bind(this, x.id)}
              selected={x.id === selected}>
              <ListItemIcon>{x.icon}</ListItemIcon>
              <ListItemText primary={x.name} />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </React.Fragment>
  );
};
SortBy.defaultProps = {
  order: 'asc',
  orderBy: 'date',
  style: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  handleSeleted: () => '',
  menu: [
    { id: 'date', name: 'Date', icon: <TodayIcon fontSize="small" /> },
    { id: 'name', name: 'Name', icon: <DraftsIcon fontSize="small" /> },
    { id: 'size', name: 'Size', icon: <StorageIcon fontSize="small" /> },
  ],
};
export default SortBy;
