import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_CORRECTION_FILE = gql`
  query($n: Int!) {
    getCorrectionFile(n: $n) {
      ok
      content
      errors {
        path
        message
      }
    }
  }
`;
export const QueryCorrectionFile = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CORRECTION_FILE),
    variables: { n: 1 },
  });
};

const MUTATION_SAVE_CORRECTION_FILE = gql`
  mutation($json_content: String!) {
    saveConfigurationFile(json_content: $json_content) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationSaveCorrectionFile = json_content => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SAVE_CORRECTION_FILE),
    variables: { json_content: json_content },
  });
};
