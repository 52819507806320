import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { lighten } from '@mui/material/styles';
import clsx from 'clsx';
import { Toolbar, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import { ButtonReloadDelete } from 'components';
import DivGrow from 'components/DivGrow';
import { withStyles } from 'tss-react/mui';

const useStyles = (theme, props) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: props.transform ? '20px' : '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: props.transform ? 'none' : null,
  },
});

class TableContainersToolbar extends Component {
  render() {
    const {
      numSelected,
      classes,
      title,
      handleDeleteSelected,
      loading,
      handleReloadTable,
      handleClearSelected,
      tooltip,
      loading_table,
    } = this.props;
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} id="tableTitle" variant="h6">
            {title}
          </Typography>
        )}
        <DivGrow />
        <ButtonReloadDelete
          handleClearSelected={handleClearSelected}
          handleDeleteSelected={handleDeleteSelected}
          handleReloadTable={handleReloadTable}
          loading={loading}
          loading_table={loading_table}
          numSelected={numSelected}
          tooltip={tooltip}
        />
      </Toolbar>
    );
  }
}

TableContainersToolbar.propTypes = {
  handleClearSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  transform: PropTypes.bool,
};
TableContainersToolbar.defaultProps = {
  loading_table: false,
  loading: false,
  tooltip: 'Delete',
  handleClearSelected: () => '',
  transform: false,
};
export default withStyles(TableContainersToolbar, useStyles);
