import React from 'react';
import PropTypes from 'prop-types';
import {
  UploadDropArea,
  UploadItem,
  UploadProgress,
} from 'views/DownloadUpload/components/Upload/components';
import {
  Divider,
  FormControlLabel,
  Grid,
  List,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { v1 as uuid } from 'uuid';
import TextTypography from './TextTypography';
import { GetData, ServerErrors, IsFile } from 'helpers';
import { GreenCheckbox } from 'components';
import { MutationUploadFile } from 'common';
const useStyles = () => ({
  divider: {
    marginBottom: '2px',
  },
  drop_area: {
    marginTop: 2,
    marginBottom: 3,
  },
});
class UploadStepThree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 12,
      loading: false,
      files: [],
      uploaded_contents: [],
      progress_all: 0,
      pseudoanonymization: false,
    };
    this.holder = {
      deleteFile: null,
      clearAll: null,
      getSettings: null,
    };
  }

  getFileIndex = file => {
    const i = this.state.files.map(x => x.name).indexOf(file.name);
    if (i !== -1 && file.size === this.state.files[i].size) {
      return i;
    }
    return -1;
  };
  getUploadIndex = item => {
    if (IsFile(item)) return -1;
    const { uploaded_contents } = this.state;
    const { filename: name, filesize: size } = item;
    for (let i = 0; i < uploaded_contents.length; i++) {
      const { filename, filesize } = uploaded_contents[i];
      if (name === filename && size === filesize) {
        return i;
      }
    }
    return -1;
  };
  handleDelete = file => {
    if (!file) {
      return;
    }
    let { files, uploaded_contents, pseudoanonymization } = this.state;
    let i = this.getFileIndex(file);
    if (i !== -1) {
      files.splice(i, 1);
      this.setState({ files });
    }
    i = this.getUploadIndex(file);
    if (i !== -1) {
      uploaded_contents.splice(i, 1);
      if (!uploaded_contents.length) {
        pseudoanonymization = false;
      }
      this.setState({ uploaded_contents, pseudoanonymization });
    }
    this.holder.deleteFile(file);
  };

  handleUplodedItem = (item, index) => {
    if (typeof item === 'undefined' || !item) {
      return;
    }
    const { ok } = item;

    let { files, uploaded_contents } = this.state;
    if (ok) {
      this.holder.deleteFile(files[index]);
    }
    uploaded_contents.push(item);
    files.splice(index, 1);
    this.setState({ uploaded_contents, files, progress_all: 0 });
  };
  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const progress_all = Math.floor((loaded * 100) / total);
    const { files } = this.state;
    files[0].loaded = loaded;
    files[0].loading = true;
    this.setState({ progress_all, files });
  };
  getSettings = () => {
    const { project, folder, center_team, patient_number } = this.props;
    let settings = null;
    if (project) {
      settings = {
        projectIds: [project.id],
      };
      settings.send_project_email = true;
    }
    if (patient_number) {
      if (!settings) settings = {};
      settings.patient_number = patient_number;
    }
    if (folder) {
      if (!settings) settings = {};
      settings.folderIds = [folder.idFolder];
      const { folders_paths } = folder;
      if (folders_paths) {
        settings.folderStructure = folders_paths.map(x => x.name).join('/');
      }
    }
    if (center_team) {
      settings.idCenterTeam = center_team.id;
    }
    return settings;
  };
  handleUpload = () => {
    const { files } = this.state;
    if (!files.length) {
      this.setState({
        loading: false,
        progress: 0,
      });
      return;
    }
    this.setState({ loading: true });
    const file = files[0];
    const settings = this.getSettings();
    (async () => {
      MutationUploadFile(file, this.handleProgress, null, settings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.uploadFile;
          let uploaded_content = null;
          if (ok) {
            const { contents, warnings } = data.uploadFile;
            uploaded_content = {
              ok: true,
              filename: file.name,
              filesize: file.size,
              loaded: file.size,
              loading: false,
              content: contents && contents.length ? contents[0] : null,
              warnings: warnings && warnings.length ? warnings : [],
            };
          } else {
            throw errors;
          }
          this.handleUplodedItem(uploaded_content, 0);
          this.handleUpload();
        })
        .catch(error => {
          const new_information = {
            ok: false,
            filename: file.name,
            filesize: file.size,
            file,
            loading: false,
            errors: ServerErrors(error),
          };
          this.handleUplodedItem(new_information, 0);
          this.handleUpload();
        });
    })();
  };
  handleDeleteAll = () => {
    this.holder.clearAll();
    this.setState({
      files: [],
      uploaded_contents: [],
      progress_all: 0,
      pseudoanonymization: false,
    });
  };
  handleInformation = index => {
    const { uploaded_contents } = this.state;
    if (index >= 0 && index < uploaded_contents.length) {
      uploaded_contents[index] = {
        ...uploaded_contents[index],
        finished_error: true,
      };
      this.setState({ uploaded_contents });
    }
  };
  render() {
    const { uploaded_contents, files, pseudoanonymization } = this.state;
    const { classes, project, height, center, patient_number } = this.props;
    const ListItems = uploaded_contents.concat(files);
    const total_files = ListItems.length;
    let ComponentConfirm = null;
    let delta = 410;
    if (total_files) {
      delta = 450;
      ComponentConfirm = (
        <Grid item xs={12}>
          <Typography pl={1} pt={1} variant="body1">
            Please confirm with the{' '}
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              GREEN CheckBox
            </span>{' '}
            that all the data you are uploading has undergone{' '}
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              pseudonymization
            </span>{' '}
            and does not include any patient information.
          </Typography>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={pseudoanonymization}
                name="checkedG"
                onChange={event => {
                  this.setState({
                    pseudoanonymization: event.target.checked,
                  });
                }}
              />
            }
            label="I confirm that the data is pseudonymized and doesn't contain patient information."
            style={{ marginLeft: 10 }}
          />
          <Divider />
        </Grid>
      );
    }
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height - delta,
        overflow: 'auto',
        marginTop: 2,
      };
    }
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Grid container p={1} spacing={2}>
              <Grid item>
                <TextTypography
                  text={project ? project.name : 'Missing project name'}
                  title="Project:"
                />
              </Grid>
              <Grid item>
                <TextTypography
                  text={center ? center.name : 'Missing center name'}
                  title="Center:"
                />
              </Grid>
              <Grid item>
                <TextTypography
                  text={
                    patient_number ? patient_number : 'Missing patient number'
                  }
                  title="Patient Number:"
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
          {ComponentConfirm}
          <Grid item xs={12}>
            <UploadDropArea
              className={classes.drop_area}
              handleChange={files => this.setState({ files })}
              holder={this.holder}
            />
          </Grid>
          <UploadProgress
            disabled={!pseudoanonymization}
            handleDeleteAll={this.handleDeleteAll}
            handleUploadAll={() => this.handleUpload()}
            loading={this.state.loading}
            marginBottom="2px"
            progress_all={this.state.progress_all}
            total_files={total_files}
            total_uploaded={uploaded_contents.length}
          />
        </Grid>
        <div style={{ width: '100%' }}>
          <div style={style}>
            <Grid container>
              <Grid item xs={12}>
                <List>
                  {ListItems.map((item, index) => {
                    return (
                      <UploadItem
                        disabled={!pseudoanonymization}
                        handleDelete={this.handleDelete}
                        handleInformation={this.handleInformation}
                        handleUplodedItem={this.handleUplodedItem}
                        holder={this.holder}
                        index={index}
                        item={item}
                        key={`file-item-upload-${uuid()}`}
                      />
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UploadStepThree.propTypes = {
  project: PropTypes.object,
};

export default withStyles(UploadStepThree, useStyles);
