import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { StyleText } from 'components';

class CreateNewUserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleClose()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={<AddIcon style={{ color: 'green' }} />}
        subheader="Include the necessary information for the new user"
        title={
          <Grid container>
            <StyleText
              fontSize={15}
              fontWeight={1000}
              spacing={3}
              style
              text="Create New User"
            />
            {/* <Grid item style={{ marginLeft: 10 }}>
              {idImage}
            </Grid> */}
          </Grid>
        }
      />
    );
  }
}

CreateNewUserHeader.propTypes = {
  classes: PropTypes.object,
};

export default CreateNewUserHeader;
