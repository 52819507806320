import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';

import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';

import { SeriesCard } from './components';
import { copySeries } from 'redux-store/actions/file_folder_manager';
import { SelectedArray } from 'helpers';
import { BoxWaiting } from 'components';

const useStyles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

class ViewSeriesGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      unhealthy: [],
    };
  }
  handleClickSelected = series => {
    if (series === undefined || !series) return;
    const { copy_series } = this.props;
    const index = copy_series.map(x => x.idSeries).indexOf(series.idSeries);
    const newSelected = SelectedArray(copy_series, series, index);
    this.props.copySeries(newSelected);
  };
  isSelected = series => {
    if (series === undefined || !series) return false;
    const { copy_series } = this.props;
    const index = copy_series.map(x => x.idSeries).indexOf(series.idSeries);
    return index !== -1 ? true : false;
  };

  render() {
    const { loading, rows, handleSeriesDeleted } = this.props;
    if (loading) {
      return <BoxWaiting />;
    }
    return (
      <div style={{ padding: 30 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          spacing={5}>
          {rows.map((series, index) => {
            return (
              <SeriesCard
                handleClickSelected={this.handleClickSelected}
                handleSeriesDeleted={handleSeriesDeleted}
                isSelected={this.isSelected(series)}
                key={`series-card-${index}`}
                series={series}
                series_view={this.props.series_view}
                user={this.props.user}
              />
            );
          })}
        </Grid>
      </div>
    );
  }
}

ViewSeriesGrid.defaultProps = {
  rows: [],
  series_view: '/image_analysis/series',
};
ViewSeriesGrid.propTypes = {
  copy_series: PropTypes.array,
  rows: PropTypes.array,
};

const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, {
  copySeries,
})(withStyles(ViewSeriesGrid, useStyles));
