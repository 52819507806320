import React from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@mui/material';
import { ButtonIconLoad, DivGrow } from 'components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MutationDockerUpload } from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';

class CreateDockerImageBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false };
  }

  handleUploadAll = () => {
    const { handleSettings } = this.props;
    if (!handleSettings || handleSettings === undefined) return;
    const { docker_file, settings } = handleSettings();
    if (!docker_file) {
      this.props.handleAddError('Docker file missing');
      return;
    }
    const { uploading } = this.state;
    if (uploading) return;
    this.setState({ uploading: true, progress: 0 });
    (async () => {
      MutationDockerUpload(docker_file, settings, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, image } = data.uploadDockerImage;
          if (ok) {
            this.setState({
              uploading: false,
              progress: 0,
            });
            this.props.handleNewDockerImage(image);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, progress: 0 });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleProgress = event => {
    const { uploading } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    let progress = Math.floor((loaded * 100) / total);
    if (!uploading) {
      progress = 0;
    }
    this.setState({ progress });
  };

  render() {
    const { uploading, progress } = this.state;
    return (
      <Grid container style={{ margin: 0, padding: 0 }}>
        <Grid item xs={12}>
          {uploading && (
            <LinearProgress
              style={{ width: '100%' }}
              value={progress}
              variant="determinate"
            />
          )}
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6}>
            <a style={{ color: 'gray' }}>
              Click in upload button to uplad and set your docker, if you
              selected execute it will apears in containers
            </a>
          </Grid>
          <DivGrow />
          <Grid alignItems="flex-end" container item xs={6}>
            <ButtonIconLoad
              //   className={classes.expand}
              //   disabled={total_uploaded === total_files}
              handleClick={this.handleUploadAll}
              icon={<CloudUploadIcon />}
              loading={uploading}
              text="Upload Docker Image"
              transform
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CreateDockerImageBottom.propTypes = {
  classes: PropTypes.object,
};

export default CreateDockerImageBottom;
