import React from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import NotInterestedIcon from '@mui/icons-material/NotInterested';
import DeleteIcon from '@mui/icons-material/Delete';
import { green } from '@mui/material/colors';

import { Scissors } from 'icons';
import { drawerWidth } from 'views/Explorer/components/ExplorerHome/Style';

export const useStyles = theme => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  speedDialShift: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: drawerWidth + 15, //theme.spacing(40)
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  speedDialDisabled: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  speedDialDisabledShift: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2) + drawerWidth,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  color: {
    // color: 'disabled',
    '&.MuiFab-primary': {
      color: '#FFFFFF',
      backgroundColor: '#dedfe1',
    },
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    right: theme.spacing(1.7),
    bottom: theme.spacing(1.7),
    zIndex: 10000,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fabProgressShift: {
    color: green[800],
    position: 'absolute',
    right: theme.spacing(1.7) + drawerWidth,
    bottom: theme.spacing(1.7),
    zIndex: 10000,
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

export const actions = [
  { icon: <SaveIcon />, name: 'Paste' },
  { icon: <Scissors />, name: 'Cut' },
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <ShareIcon />, name: 'Share' },
  { icon: <DeleteIcon />, name: 'Remove' },
];
