import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableGrid } from 'components';
import { useStyles, columns } from './useStyles';
import { withStyles } from 'tss-react/mui';
import { CardEditHeader, GroupBox } from 'components';
import { rowsPerPageBig } from 'helpers';
import { GroupObjecTypes } from './components';
import EditIcon from '@mui/icons-material/Edit';
class EditProjectObjectTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      page: 0,
      limit: 50,
    };
  }
  getData = () => {
    return this.props.selected;
  };
  render() {
    const rows = this.getData();
    const { selected } = this.state;
    let ComponentTextSelected = null;
    if (selected && selected.length) {
      ComponentTextSelected = `Selected: ${selected.length} of ${rows.length}`;
    }
    return (
      <React.Fragment>
        <CardEditHeader
          description="To modify multiple object, select with checkbox on the table, and mofidy the necessary fields"
          handleClose={this.props.handleCloseEdit}
          icon={<EditIcon style={{ color: 'red' }} />}
          title="Edit Variable Type of Project Objects"
        />
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}>
          <Grid item style={{ width: '100%' }} xs={12}>
            <GroupBox title_box="Selected Object to Edit">
              <GroupObjecTypes
                handleAddError={this.props.handleAddError}
                idProject={this.props.idProject}
                selected={this.state.selected}
              />
            </GroupBox>
          </Grid>
          <Grid item xs={12}>
            {ComponentTextSelected}
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              headers={columns}
              onPageChange={page => this.setState({ page })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              rows={rows}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EditProjectObjectTypes.propTypes = {
  classes: PropTypes.object,
};
EditProjectObjectTypes.defaultProps = {
  selected: [],
  handleCloseEdit: () => '',
};
export default withStyles(EditProjectObjectTypes, useStyles);
