export const width = 90;

export const useStyles = theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  paper: {
    // padding: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  media: {
    // height: 0,
    // paddingTop: '56.25%' // 16:9
  },
  container: {
    '&:hover': {
      color: '#ed1212',
      cursor: 'pointer',
    },
  },
  selection: {
    backgroundColor: 'white',
    width: '10px',
    height: '10px',
    margin: 0,
    padding: 0,
    paddingLeft: '4px',
    marginTop: 0,
    marginLeft: '2px',
  },
  selected: {
    width: width - 14,
    marginLeft: '7px',
    color: 'blue',
  },
  unselected: {
    width: width,
    height: 90,
  },
  selected_folder: {
    width: width,
    height: 90 - 7,
  },
  color_blue: {
    color: 'blue',
  },
  color_green: {
    color: 'green',
  },
});
