import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Divider } from '@mui/material';
import { CardHeaderCustom, DetailsMetaMultiSelection } from 'components';
import { PaperComponent } from 'helpers';

class DialogEditMultiSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, selected } = this.props;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.props.handleClose}
        open={open}
        PaperComponent={PaperComponent}
        style={{ maxWidth: 530 }}>
        <CardHeaderCustom
          subheader="Select global features"
          title={`Modify ${selected.length} Series`}
        />
        <Divider />
        <DetailsMetaMultiSelection direction="row" selected={selected} />
      </Dialog>
    );
  }
}

DialogEditMultiSeries.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
DialogEditMultiSeries.defaultProps = {
  selected: [],
};

export default DialogEditMultiSeries;
