import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_signals_edf } from './utils';
import { QuerySignalEDFs } from 'graphql/Signals';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import { Grid } from '@mui/material';
import { ToolBarSignalEDF } from './components';

class TabPatientEDFs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selected: [],
      loading: false,
      edfs: [],
      total: 0,
      page: 0,
      limit: 50,
      empty: false,
    };
  }
  componentDidMount() {
    this.loadSignalEDFs();
  }
  loadSignalEDFs = (page = null, limit = null) => {
    const { loading, name } = this.state;
    if (loading) return;
    if (!limit) {
      page = this.state.page;
      limit = this.state.limit;
    }
    this.setState({ loading: true });
    QuerySignalEDFs(name, page, limit)
      .then(res => {
        const data = GetData(res);
        const { ok, edfs, total, errors } = data.signalEDFs;
        if (ok) {
          this.setState({
            loading: false,
            empty: !edfs.length,
            edfs,
            total,
            page,
            limit,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ loading: false, empty: true });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  render() {
    const { height } = this.props;
    const { loading, total, edfs, selected } = this.state;
    return (
      <Grid container spacing={1}>
        <Grid item sx={{ marginTop: '5px' }} xs={12}>
          <ToolBarSignalEDF
            handleReload={() => this.loadSignalEDFs()}
            loading={loading}
            selected={selected}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={selected}
            headers={columns_signals_edf}
            height={height - 225}
            loading={loading}
            onPageChange={page =>
              this.loadSignalEDFs(page, this.state.limit)
            }
            onRowsPerPageChange={limit => this.loadSignalEDFs(0, limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={total}
            rows={edfs}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabPatientEDFs.propTypes = {
  classes: PropTypes.object,
};

export default TabPatientEDFs;
