import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid, Autocomplete, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  margin: {
    margin: theme.spacing(1),
    // width: 'calc(100% - 250px)',
  },
  input: {
    padding: '10.5px 14px',
  },
  textField: {
    width: 'calc(100% - 250px)',
  },
});

class TabUIDs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      type: 'Study',
    };
    const { holder } = props;
    if (holder) {
      holder.getUIDs = () => ({
        type: this.state.type + 'InstanceUID',
        description: this.state.uid,
      });
    }
    const { tab_holder, index, query } = props;
    if (tab_holder) {
      tab_holder.getValues[index] = () => ({
        type: query,
        value: this.state.uid,
      });
    }
  }

  handleUIDsChange = event => {
    event.preventDefault();

    this.setState({ uid: event.target.value });
  };
  handleExecuteSearch = event => {
    if (!event) {
      return;
    }
    const { key } = event;

    if (key === 'Enter') {
      const { type, uid } = this.state;
      if (type === 'Study') {
        this.props.executeSearch(uid, 'StudyInstanceUID', event);
      } else {
        this.props.executeSearch(uid, 'SeriesInstanceUID', event);
      }
    }
  };
  handleTypeUID = value => {
    if (value !== this.state.type) {
      this.setState({ type: value });
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { uid } = this.state;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start">
        <Autocomplete
          defaultValue={'Study'}
          disableClearable
          getOptionLabel={option => {
            if (option) return option;
            return '';
          }}
          onChange={(event, value) => this.handleTypeUID(value)}
          options={['Study', 'Series']}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              InputLabelProps={{
                disabled: true,
              }}
              label="UID Type"
              variant="outlined"
            />
          )}
          size="small"
          style={{ width: 150 }}
        />
        <Grid item xs>
          <TextField
            className={classes.margin}
            fullWidth
            id={'outlined-simple-start-uid'}
            label="Instance UID Value"
            onChange={this.handleUIDsChange}
            onKeyPress={this.handleExecuteSearch}
            size="small"
            value={uid}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

TabUIDs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TabUIDs, useStyles);
