export { default as ToolBarSettings } from './ToolBarSettings';
export { default as GroupPatientLevel } from './GroupPatientLevel';
export { default as GroupStudyLevel } from './GroupStudyLevel';
export { default as GroupSeriesLevel } from './GroupSeriesLevel';
export { default as GroupOtherLevel } from './GroupOtherLevel';
export { default as FolderStructure } from './FolderStructure';
const getColumns = () => {
  const columns = [];
  for (let i = 0; i < 10; i++) {
    columns.push({
      field: `colum_${i}`,
      headerName: `Column ${i}`,
      width: 75,
      align: 'left',
      edit: true,
      style: {
        width: 50,
        minWidth: 70,
        maxWidth: 100,
      },
    });
  }
};

export const colums = getColumns();
