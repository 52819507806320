import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CreateSequence } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';
import {
  DialogCreateSeriesDetail,
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  SearchText,
} from 'components';
import { MutationUploadSequenceCSV } from 'graphql/Series/sequences';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class TabSequenesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      open: false,
    };
  }
  handleProgress = event => {
    const { loading, total_download } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = total_download;
    }
    if (this.props.handleProgress) {
      this.props.handleProgress({ loaded, total }, loading);
    }
  };
  handleSelectFile = event => {
    const { uploading } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadSequenceCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, sequences } = data.uploadNewSequence;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddNewSequences(sequences);
            if (errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  runCreate = name => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      CreateSequence(name)
        .then(res => {
          const data = GetData(res);

          if (data && data.createSequence) {
            const { ok, Sequence, errors } = data.createSequence;
            if (ok) {
              this.props.addNewSequence(Sequence);
              this.setState({ open: false, loading: false });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({ open: false, loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreateDetailDialog = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, selected, holder } = this.props;
    const { open, loading } = this.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button onClick={this.handleCreateDetailDialog}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <Button
                disabled={!selected.length}
                onClick={this.props.handleOpenEditView}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
              <ButtonDownloadType key="center" type="sequences" />
              <ButtonFileUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={this.props.handleSearchSequence}
              holder={holder}
              id="id-search-admin-sequences"
              loading={this.props.loading}
              placeholder="Search Sequence by name"
            />
          </Grid>
          <Grid item pr={2}>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={() => this.props.handleReload()}
              loading={this.props.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload sequences"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogCreateSeriesDetail
          handleClose={this.handleCreateDetailDialog}
          handleCreateDetail={this.runCreate}
          loading={loading}
          open={open}
          title="Sequence"
        />
      </React.Fragment>
    );
  }
}

TabSequenesHeader.propTypes = {
  addNewSequence: PropTypes.func,
  classes: PropTypes.object,
  handleOpenCreate: PropTypes.func,
  handleOpenEditView: PropTypes.func,
  selected: PropTypes.array,
};
TabSequenesHeader.defaultProps = {
  handleOpenCreate: () => '',
  handleOpenEditView: () => '',
  addNewSequence: () => '',
  handleAddError: () => '',
  selected: [],
};
export default withStyles(TabSequenesHeader, useStyles);
