import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Typography } from '@mui/material';

class StepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirm: '',
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getPassword = () => this.state.password;
      holder.getConfirm = () => this.state.confirm;
      holder.IsSamePassword = () => this.state.password === this.state.confirm;
    }
  }

  componentDidMount() {
    const { password, confirm } = this.props;
    if (this.state.password !== password) {
      this.setState({ password, confirm });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes, error } = this.props;
    return (
      <React.Fragment>
        <Typography>{'Enter your new password.'}</Typography>
        <Grid container direction="column">
          <TextField
            className={classes.margin}
            error={error !== ''}
            id="password"
            InputLabelProps={{
              shrink: true,
            }}
            label="Password"
            name="password"
            onChange={this.handleChange}
            required
            type="password"
            value={this.state.password}
            variant="outlined"
          />
          <TextField
            className={classes.margin}
            disabled={this.state.password === ''}
            error={error !== ''}
            id="confirm"
            InputLabelProps={{
              shrink: true,
            }}
            label="Confirm"
            name="confirm"
            onChange={this.handleChange}
            required
            type="password"
            value={this.state.confirm}
            variant="outlined"
          />
          <Typography
            style={{ marginLeft: 20, marginBottom: 20 }}
            variant="caption">
            {'Use 8 or more characters with a mix of letters and numbers'}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}

StepThree.propTypes = {
  classes: PropTypes.object,
};

export default StepThree;
