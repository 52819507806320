import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Collapse } from '@mui/material';
import { TableSeriesVariableMaps, DialogQuestion } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import {
  GetDeleteDataTypes,
  MutationDeleteVariableTypes,
} from './utils_var_types';

class TableGridSecondRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      selected: [],
      open_delete: false,
    };
  }
  runDeleteSelected = () => {
    const { isSeries } = this.props;
    const { deleting, selected } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true, open_delete: false });
    (async () => {
      const ids = selected.map(x => x.id);
      MutationDeleteVariableTypes(isSeries, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = GetDeleteDataTypes(data);
          if (ok) {
            const mappings = this.props.mappings.filter(
              x => ids.indexOf(x.id) === -1
            );
            this.setState({
              deleting: false,
              selected: [],
            });
            this.props.updateMappings(mappings, ids.length);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    this.setState({ open_delete: !this.state.open_delete });
  };
  render() {
    const { open, headers, height } = this.props;
    const { open_delete } = this.state;
    return (
      <React.Fragment>
        <TableRow>
          <TableCell
            colSpan={headers.length + 2}
            style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableSeriesVariableMaps
                deleting={this.state.deleting}
                disable_splice
                handleChangePage={this.props.handleChangePage}
                handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                handleClearSelected={() => this.setState({ selected: [] })}
                handleDeleteSelected={this.handleDeleteSelected}
                handleReloadTable={this.props.handleReloadTable}
                height={height}
                isSeries={this.props.isSeries}
                limit={this.props.limit}
                loading={this.props.loading}
                mappings={this.props.mappings}
                onSelectedChanged={selected => this.setState({ selected })}
                page={this.props.page}
                selected={this.state.selected}
                total={this.props.total}
              />
            </Collapse>
          </TableCell>
        </TableRow>
        <DialogQuestion
          button_name="Delete"
          handleAction={this.runDeleteSelected}
          handleClose={() => this.setState({ open_delete: false })}
          message="This action cannot be undone. Do you want to continue?"
          open={open_delete}
          title="Delete Selected Mappings"
        />
      </React.Fragment>
    );
  }
}

TableGridSecondRow.propTypes = {
  open: PropTypes.bool.isRequired,
};
TableGridSecondRow.defaultProps = {
  open: false,
  headers: [],
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleAddError: () => '',
  updateMappings: () => '',
};

export default TableGridSecondRow;
