import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { QueryUnits } from 'graphql/Units';
import { GetData } from 'helpers';

const Units = props => {
  const { formState, className, handleUnitChanged, hasError } = props;
  const [unit, setUnit] = React.useState({ idUnit: -1, name: '' });
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let results = [];
      try {
        const res = await QueryUnits(unit.name, undefined, undefined, true);
        const data = GetData(res);
        const { units } = data;
        if (units.ok) {
          results = units.units;
        }
      } catch (error) {
        console.log({ error });
      }
      if (active) {
        setOptions(results);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, unit]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const handleUnitNameChanged = event => {
    setUnit({ ...unit, name: event.target.value });
  };
  //
  return (
    <Autocomplete
      className={className}
      getOptionLabel={option => {
        //
        if (typeof option !== 'undefined' && option) {
          const { name } = option;
          if (name) return name;
        }
        return '';
      }}
      id="units-autocomplete"
      isOptionEqualToValue={(option, value) => option.name === value.name}
      loading={loading}
      onChange={(event, value) => handleUnitChanged(value)}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          error={hasError('unit')}
          helperText={hasError('unit') ? formState.errors.unit[0] : null}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label="Units"
          name="unit"
          onChange={handleUnitNameChanged}
          placeholder="Select your unit"
          size="small"
          variant="outlined"
        />
      )}
      style={{ width: 300 }}
    />
  );
};
Units.defaultProps = {
  handleUnitChanged: () => '',
};
// Units.propTypes = {
//   className: PropTypes.string,
//   style: PropTypes.object
// };

export default Units;
