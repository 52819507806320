/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, TextField, Typography } from '@mui/material';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { connect } from 'react-redux';

import { clearLoginError, login } from 'redux-store/actions/loginActions';
import { ButtonLoading, DivGrow, SnackMessage } from 'components';
import { schema } from '../index';
import { ServerErrorsString } from 'helpers';

class CardLogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
      },
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    const { formState } = this.state;
    const errors = validate(formState.values, schema);
    this.setState({
      formState: {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {},
      },
    });
    this.signed();
  }

  componentDidUpdate() {
    if (this.props.errors && this.props.errors.length && this.state.loading) {
      // console.log({ errors: this.props.errors });
      this.setState({
        loading: false,
        error: ServerErrorsString(this.props.errors),
      });
      return;
    }
    const { formState } = this.state;
    const errors = validate(formState.values, schema);
    const isValid = errors ? false : true;
    if (formState.isValid !== isValid) {
      this.setState({
        formState: {
          ...formState,
          isValid: isValid,
          errors: errors || {},
        },
      });
    }
    this.signed();
  }

  handleSignIn = event => {
    event.preventDefault();
    const { formState } = this.state;
    this.setState({ loading: true });
    this.props.login(
      formState.values['email'],
      formState.values['password'],
      this.props.ReactGA4
    );
  };
  handleChange = event => {
    event.persist();
    const { formState } = this.state;
    this.setState({
      loading: false,
      formState: {
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      },
    });
  };
  hasError = field =>
    this.state.formState.touched[field] && this.state.formState.errors[field]
      ? true
      : false;
  signed = () => {
    const { user } = this.props;
    if (user && user.username) {
      this.props.history.push('/dashboard');
    }
  };
  handleCloseError = () => {
    this.props.clearLoginError();
    this.setState({ loading: false, error: null });
  };

  render() {
    const { classes, errors, handleLoginForgot } = this.props;
    const { formState } = this.state;
    let { loading } = this.state;
    let message_text = '';
    if (errors && errors.length) {
      message_text = errors.map(x => (x.message ? x.message : x)).join('\n');
      loading = false;
    } else if (this.state.error) {
      message_text = this.state.error;
    }
    return (
      <React.Fragment>
        <form className={classes.form} onSubmit={this.handleSignIn}>
          <Typography className={classes.title} variant="h2">
            Sign in
          </Typography>
          <TextField
            className={classes.textField}
            error={this.hasError('email')}
            fullWidth
            helperText={
              this.hasError('email') ? formState.errors.email[0] : null
            }
            label="Email address"
            name="email"
            onChange={this.handleChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.handleSignIn(event);
              }
            }}
            type="text"
            value={formState.values.email || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={this.hasError('password')}
            fullWidth
            helperText={
              this.hasError('password') ? formState.errors.password[0] : null
            }
            label="Password"
            name="password"
            onChange={this.handleChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.handleSignIn(event);
              }
            }}
            type="password"
            value={formState.values.password || ''}
            variant="outlined"
          />
          <Grid item style={{ marginTop: 10, marginBottom: 10 }} xs={12}>
            <ButtonLoading
              fullWidth
              handleClick={this.handleSignIn}
              isValid={formState.isValid}
              loading={loading}
              name="Sign in Now"
            />
          </Grid>
          <Grid container direction="row">
            <Typography color="textSecondary" variant="body1">
              Don't have an account?{' '}
              <Link component={RouterLink} to="/sign-up" variant="h6">
                Sign up
              </Link>
            </Typography>
            <DivGrow />
            <Link onClick={() => handleLoginForgot(false)} variant="h6">
              Forgot your password?
            </Link>
          </Grid>
        </form>
        <SnackMessage
          handleClose={this.handleCloseError}
          message_text={message_text}
          open={message_text !== '' ? true : false}
          type="error"
        />
      </React.Fragment>
    );
  }
}

CardLogIn.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.info_user.user,
  errors: state.info_user.errors,
});

export default connect(mapStateToProps, { login, clearLoginError })(
  withRouter(CardLogIn)
);
