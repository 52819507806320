import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_LOG_ADMIN_USERS = gql`
  query($text: String, $page: Int, $limit: Int) {
    logsAdminUsers(text: $text, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs_admin_users {
        id
        idUser
        user {
          id
          FullName
        }
        count
        activity
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogAdminUsers = (text, page, limit) => {
  console.log('QueryLogAdminUsers', { text, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOG_ADMIN_USERS),
    variables: {
      text,
      page,
      limit,
    },
  });
};
const MUTATION_DELETE_LOG_ADMIN_USERS = gql`
  mutation($ids: [LongLong!]!) {
    deleteLogsAdminUsers(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogAdminUsers = ids => {
  console.log('MutationDeleteLogAdminUsers', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_LOG_ADMIN_USERS),
    variables: {
      ids,
    },
  });
};
