import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { StudySeriesList, columns_study, useStylesSplit } from './components';
import {
  setDisplayDicomStudy,
  setDatabaseStudySelected,
  copySeries,
} from 'redux-store/actions';
import { withRouter } from 'react-router-dom';
import { SelectedArray, rowsPerPageBig } from 'helpers';
import { BoxWaiting, TableGrid } from 'components';
import Split from 'react-split';
import { IsInvalid } from 'helpers';
import { loadStudySeries } from './components/utils_study_series';
// import PanelGroup from 'react-panelgroup';

class StudyListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ListSeries: [],
      selected: [],
      page: 0,
      limit: 50,
      sizes: [50, 50],
    };
  }
  updateSelected = () => {
    let { selected } = this.state;
    const count = selected.length;
    const { Studies } = this.props;
    for (let i = 0; i < Studies.length; i++) {
      const { idStudy } = Studies[i];
      const index = selected.map(x => x.idStudy).indexOf(idStudy);
      const element = this.isSelected(Studies[i]);
      if (element && index === -1) {
        selected.push(Studies[i]);
      } else if (!element && index !== -1) {
        selected.splice(index, 1);
      }
    }
    if (count !== selected.length) {
      this.setState({ selected });
    }
  };
  componentDidMount() {
    this.updateSelected();
  }
  componentDidUpdate() {
    this.updateSelected();
  }
  isItemSelected = Study => {
    const { study_selected } = this.props;
    const index = study_selected.map(x => x.idStudy).indexOf(Study.idStudy);
    return index !== -1;
  };
  handleStudyChecked = Study => {
    const { study_selected } = this.props;
    const index = study_selected.map(x => x.idStudy).indexOf(Study.idStudy);
    const newSelected = SelectedArray(study_selected, Study, index);
    this.props.setDatabaseStudySelected(newSelected);
  };
  handleStudyRowClicked = study => {
    let ListSeries = [];
    if (study) {
      const { idStudy, Series, NumberOfStudyRelatedSeries } = study;
      if (Series) ListSeries = Series;
      else if (NumberOfStudyRelatedSeries) {
        loadStudySeries(idStudy, this);
      }
    }
    if (ListSeries) this.setState({ ListSeries });
  };
  handleStudyRowDoubleClick = study => {
    // this.props.setDisplayDicomStudy(study);
    // this.props.history.push(`/dicom_viewer/study/${study.idStudy}`);
    this.props.history.push(`/image_analysis/study/${study.idStudy}`);
  };
  isSelected = Study => {
    if (IsInvalid(Study)) return false;
    const { Series } = Study;
    if (IsInvalid(Series) || !Series.length) return false;
    const { copy_series } = this.props;
    let total = 0;
    for (let i = 0; i < Series.length; i++) {
      const index = copy_series
        .map(x => x.idSeries)
        .indexOf(Series[i].idSeries);
      if (index !== -1) total++;
    }
    return total > 0;
  };
  handleSelectedChanged = selected => {
    let selected_series = [];
    for (let i = 0; i < selected.length; i++) {
      const { Series } = selected[i];
      if (IsInvalid(Series)) continue;
      for (let j = 0; j < Series.length; j++) {
        const { idSeries } = Series[j];
        const index = selected_series.map(x => x.idSeries).indexOf(idSeries);
        if (index === -1) {
          selected_series.push(Series[j]);
        }
      }
    }
    this.props.copySeries(selected_series);
  };
  render() {
    const { classes, Studies, loading } = this.props;
    const { ListSeries, sizes } = this.state;
    const panel_height = this.props.height - 255;
    const height_table = (panel_height * sizes[0]) / 100 - 10;
    return (
      <Split
        className={classes.splitter}
        direction="vertical"
        onDrag={sizes => this.setState({ sizes })}
        sizes={[50, 50]}
        style={{ height: panel_height }}>
        <div style={{ backgroundColor: '#eeeeee', overflow: 'auto' }}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_study}
            height={height_table}
            idRow="idStudy"
            loading={loading}
            onHighlightChange={this.handleStudyRowClicked}
            onRowClicked={this.handleStudyRowClicked}
            onRowDoubleClick={this.handleStudyRowDoubleClick}
            onSelectedChanged={this.handleSelectedChanged}
            paginationMode="server"
            rows={Studies}
            rowsPerPage={this.props.total}
            rowsPerPageOptions={rowsPerPageBig}
            show_pagination={false}
          />
        </div>
        <div style={{ backgroundColor: 'white' }}>
          {this.state.loading ? (
            <BoxWaiting />
          ) : (
            <StudySeriesList ListSeries={ListSeries} />
          )}
        </div>
      </Split>
    );
  }
}

StudyListView.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { study_selected } = state.page_database;
  const { copy_series } = state.manager;
  return {
    study_selected: study_selected === undefined ? [] : study_selected,
    numSelected: study_selected === undefined ? 0 : study_selected.length,
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, {
  setDatabaseStudySelected,
  setDisplayDicomStudy,
  copySeries,
})(withStyles(withRouter(StudyListView), useStylesSplit));
