import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_TABLE_NAMES = gql`
  query {
    tableNames {
      ok
      errors {
        path
        message
      }
      tables {
        id
        name
      }
    }
  }
`;
export const QueryTableNames = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_TABLE_NAMES),
  });
};

const QUERY_COLUMN_NAMES = gql`
  query {
    columnNames {
      ok
      errors {
        path
        message
      }
      columns {
        id
        name
      }
    }
  }
`;
export const QueryColumnNames = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_COLUMN_NAMES),
  });
};

const MUTATION_CREATE_TABLE = gql`
  mutation($name: String!) {
    createTableName(name: $name) {
      ok
      errors {
        path
        message
      }
      table {
        id
        name
      }
    }
  }
`;

export const MutationCreateTable = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_TABLE),
    variables: {
      name,
    },
  });
};

const MUTATION_CREATE_COLUMN = gql`
  mutation($name: String!) {
    createColumnName(name: $name) {
      ok
      errors {
        path
        message
      }
      column {
        id
        name
      }
    }
  }
`;

export const MutationCreateColumn = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_COLUMN),
    variables: {
      name,
    },
  });
};

const MUTATION_DELETE_TABLE = gql`
  mutation($id: LongLong!) {
    deleteTableName(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteTable = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_TABLE),
    variables: {
      id,
    },
  });
};

const MUTATION_DELETE_COLUMN = gql`
  mutation($id: LongLong!) {
    deleteColumnName(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteColumn = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_COLUMN),
    variables: {
      id,
    },
  });
};
