import React from 'react';
import PropTypes from 'prop-types';
import ButtonCircularIconLoad from '../ButtonCircularIconLoad';
import { withStyles } from 'tss-react/mui';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import {
  GetData,
  GetNameFromHeader,
  GetResponseError,
  ServerErrorsString,
} from 'helpers';
import clsx from 'clsx';

const useStyles = () => ({
  download: {
    margin: 0,
    padding: 0,
  },
});

class ButtonCircularDownloadFolderContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }

  getId = () => {
    const { content } = this.props;
    if (content === undefined || !content) return -1;
    let { idFolderContent } = content;
    if (typeof idFolderContent !== 'undefined' && idFolderContent)
      return idFolderContent;
    const { FolderContent } = content;
    if (typeof FolderContent !== 'undefined' && FolderContent) {
      return FolderContent.idFolderContent;
    }
    return -1;
  };
  handleContentDownload = () => {
    const idFolderContent = this.getId();
    const { downloading } = this.state;
    const { download_type } = this.props;
    if (downloading || idFolderContent === -1) return;
    const inputs = [{ idFolderContent }];
    if (download_type) {
      inputs[0].type = download_type;
    }
    this.setState({ downloading: true });
    this.props.handleDownloadTriggered(true);
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(inputs);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          this.props.handleDownloadTriggered(false);
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        this.props.handleDownloadTriggered(false);
        return;
      }
      DownloadItemsAtKey(download_key)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.props.handleDownloadTriggered(false);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ downloading: false });
          this.props.handleDownloadTriggered(false);
        });
    })();
  };

  render() {
    const { classes } = this.props;
    const { downloading } = this.state;
    return (
      <ButtonCircularIconLoad
        className={clsx({
          [classes.download]: true,
          [this.props.className]: this.props.className,
        })}
        disabled={downloading}
        handleButtonClick={this.handleContentDownload}
        icon={this.props.icon}
        loading={downloading}
        small
        tooltip={this.props.tooltip}
      />
    );
  }
}

ButtonCircularDownloadFolderContent.propTypes = {
  classes: PropTypes.object,
};
ButtonCircularDownloadFolderContent.defaultProps = {
  tooltip: '',
  handleDownloadTriggered: () => '',
  downloading: false,
  icon: <GetAppIcon />,
};
export default withStyles(ButtonCircularDownloadFolderContent, useStyles);
