import React from 'react';
import PropTypes from 'prop-types';
import { GetData, ServerErrors } from 'helpers';
import { CardEditListDefault } from 'components';
import { QueryGroupMapping } from 'graphql/Mapping';
import { v1 as uuid } from 'uuid';

class GroupBasics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 0,
      limit: 100,
      mappings: [],
      selected: [],
    };
    const { holder } = props;
    if (holder) {
      holder.addMap = author => {
        if (author === undefined || !author) return;
        const { mappings } = this.state;
        const index = mappings.map(x => x.id).indexOf(author.id);
        if (index === -1) {
          mappings.push(author);
        }
        this.setState({ mappings });
      };
      holder.removeMaps = () => {
        let { mappings, selected } = this.state;
        const ids = selected.map(x => x.id);
        mappings = mappings.filter(x => ids.indexOf(x.id) === -1);
        // console.log({ ids, mappings });
        this.setState({
          mappings,
          selected: [],
        });
        this.holder.setRows(mappings);
      };
    }
    this.holder = {
      cleanRows: null,
      setRows: null,
    };
  }
  componentDidMount() {
    this.runLoadGroupAutors();
  }
  handleSelected = selected => {
    this.setState({ selected });
  };
  handleCreateDetailDialog = open => {
    const { mappings } = this.state;
    let idGroup = -1;
    let title = this.props.map_name;
    if (mappings.length) {
      idGroup = mappings[0].idGroup;
      title = mappings[0].group.name;
    }
    this.props.handleOpenAddDialog(title, idGroup, open);
  };
  runLoadGroupAutors = name => {
    const { loading, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryGroupMapping(this.props.map_name, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, errors } = data.allGroupMapping;
          if (ok) {
            this.holder.cleanRows();
            this.setState({ open: false, loading: false, mappings });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleDelete = selected => {
    // console.log('handleDelete', { selected });
    const { mappings } = this.state;
    let idGroup = -1;
    let title = this.props.map_name;
    if (mappings.length) {
      idGroup = mappings[0].idGroup;
      title = mappings[0].group.name;
    }
    const ids = selected.map(x => x.id);
    this.props.handleOpenRemoveDialog(title, idGroup, ids);
  };
  render() {
    const { selected, loading, mappings } = this.state;
    const { height } = this.props;
    let style = null;
    let style_card = null;
    let table_height = 0;
    if (height) {
      style = { height, maxHeight: height, width: '100%' };
      table_height = height;
    }
    return (
      <div id={`div-${this.props.title}-${uuid()}`} style={style}>
        <CardEditListDefault
          handleClearSelected={() => this.setState({ selected: [] })}
          handleCreateDetailDialog={this.handleCreateDetailDialog}
          handleDelete={this.handleDelete}
          handleReloadTable={() => this.runLoadGroupAutors()}
          handleSelected={this.handleSelected}
          height={table_height}
          holder={this.holder}
          id={`card-${this.props.title}-${uuid()}`}
          loading={loading}
          rows={mappings}
          selected={selected}
          style={style_card}
          title={this.props.title}
        />
      </div>
    );
  }
}

GroupBasics.propTypes = {
  map_name: PropTypes.string,
};
GroupBasics.defaultProps = {
  map_name: 'Project Designer',
  title: 'Projekt',
};
export default GroupBasics;
