import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';

import clsx from 'clsx';
import { CircularProgress, Fab } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

import { DialogShareFolders, SnackMessage } from 'components';

import {
  clean_manager_error,
  copy,
  cut,
  paste,
} from 'redux-store/actions/file_folder_manager';

import { actions, useStyles } from './Style';

class ButtonMenuSpeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      direction: 'up',
      hidden: false,
      open_share: false,
      warning: '',
    };
  }

  handleAction = type => {
    const {
      selected_objects,
      cut_objects,
      copy_objects,
      copy_series,
      copy_files,
      paste_objects,
      Folder,
    } = this.props;
    console.log({ cut_objects, copy_objects, copy_series, copy_files });
    if (
      (selected_objects.length === 0 && type !== 'Paste') ||
      (type === 'Paste' && paste_objects.length === 0)
    ) {
      this.setState({
        warning: `To ${type} you need to select (Folder/Object) first.`,
      });
      return;
    }

    let { open_share } = this.state;
    if (type === 'Share') {
      open_share = true;
    } else if (type === 'Copy') {
      this.props.copy(selected_objects);
    } else if (type === 'Cut') {
      this.props.cut(selected_objects);
      this.props.cleanSelections();
    } else if (type === 'Paste') {
      this.props.paste(
        Folder,
        cut_objects,
        copy_objects,
        copy_series,
        copy_files
      );
    } else if (type === 'Remove') {
      const objects_contents = selected_objects.filter(
        x => typeof x.idFolderContent !== 'undefined'
      );
      this.props.handleRemoveFromFolder(objects_contents);
    }
    this.setState({ open: false, open_share });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleShareFolder = () => {
    this.setState({ open_share: false });
  };
  handleCloseSnack = () => {
    this.setState({ warning: '' });
    if (this.props.warning_manager !== '') {
      this.props.clean_manager_error();
    }
  };
  handleCloseSnackError = () => {
    this.props.clean_manager_error();
  };

  render() {
    const {
      classes,
      Folder,
      open_drawer,
      loading_paste,
      error_manager,
      warning_manager,
    } = this.props;
    const { open, direction, hidden, open_share } = this.state;
    let { warning } = this.state;
    if (warning_manager !== '') {
      warning = warning_manager;
    }
    //
    return (
      <div
        // onBlur={this.handleBlur} // Handle blur event for keyboard users
        onFocus={() => {}} // Ensure the element can receive focus
        onMouseLeave={this.handleClose}
        role="button" // Or another appropriate role based on the actual purpose
        tabIndex={0} // Ensure the element is focusable
      >
        {!loading_paste && (
          <SpeedDial
            ariaLabel="SpeedDial Copy-Cut-Paste"
            className={clsx({
              [classes.speedDial]: !open_drawer,
              [classes.speedDialShift]: open_drawer,
            })}
            direction={direction}
            hidden={hidden}
            icon={
              <SpeedDialIcon icon={<MoreVertIcon />} openIcon={<EditIcon />} />
            }
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            open={open}>
            {actions.map(action => {
              if (Folder.name === 'Home') {
                if (action.name === 'Share') {
                  return null;
                }
              }
              if (Folder.name === 'Shared') {
                if (action.name !== 'Like') {
                  return null;
                }
              }
              return (
                <SpeedDialAction
                  icon={action.icon}
                  key={action.name}
                  onClick={() => this.handleAction(action.name)}
                  tooltipTitle={action.name}
                />
              );
            })}
          </SpeedDial>
        )}
        {loading_paste && (
          <React.Fragment>
            <Fab
              aria-label="disabled-speeddial-buttom"
              className={clsx({
                [classes.speedDialDisabled]: !open_drawer,
                [classes.speedDialDisabledShift]: open_drawer,
              })}
              disabled>
              <MoreVertIcon />
            </Fab>
            <CircularProgress
              className={clsx({
                [classes.fabProgress]: !open_drawer,
                [classes.fabProgressShift]: open_drawer,
              })}
              size={60}
            />
          </React.Fragment>
        )}
        <DialogShareFolders
          folders={[Folder]}
          handleClose={this.handleShareFolder}
          open={open_share}
        />
        <SnackMessage
          handleClose={this.handleCloseSnack}
          horizontal="left"
          message_text={warning}
          open={warning !== '' ? true : false}
          type="warning"
        />
        <SnackMessage
          handleClose={this.handleCloseSnackError}
          message_text={error_manager}
          open={error_manager !== '' ? true : false}
          type="error"
        />
      </div>
    );
  }
}

ButtonMenuSpeed.propTypes = {
  classes: PropTypes.object,
  cleanSelections: PropTypes.func,
  loading_paste: PropTypes.bool,
  open_drawer: PropTypes.bool,
};
ButtonMenuSpeed.defaultProps = {
  open_drawer: false,
  selected_folders: [],
  selected_content: [],
  loading_paste: false,
};
const mapStateToProps = state => {
  const {
    cut,
    copy,
    copy_series,
    copy_files,
    loading_paste,
    error_manager,
    warning_manager,
  } = state.manager;
  const paste_objects = cut
    .concat(copy)
    .concat(copy_series)
    .concat(copy_files);

  return {
    paste_objects,
    cut_objects: cut,
    copy_objects: copy,
    copy_series,
    copy_files,
    loading_paste,
    error_manager,
    warning_manager,
    size: paste_objects.length,
  };
};
export default connect(mapStateToProps, {
  cut,
  copy,
  paste,
  clean_manager_error,
})(withStyles(ButtonMenuSpeed, useStyles));
