import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import {
  MANAGER_CLEAN_COPY_CUT,
  MANAGER_CLEAN_ERROR,
  MANAGER_COPY,
  MANAGER_COPY_SERIES,
  MANAGER_COPY_FILES,
  MANAGER_CUT,
  MANAGER_PASTE_END,
  MANAGER_PASTE_START,
  MANAGER_JOB_SERIES_COPY,
  MANAGER_JOB_FILES_COPY,
  MANAGER_JOB_PROCESSES_NEW,
  MANAGER_SET_COPY,
  MANAGER_SET_CUT,
  MANAGER_ADD_LANDING,
} from './types';
import { GetData, ServerErrorsString } from 'helpers';

export const sleep = (delay = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export const AddLandingObject = add_landing_object => dispatch => {
  dispatch({
    type: MANAGER_ADD_LANDING,
    add_landing_object,
  });
};

export const copy = copy_objects => dispatch => {
  dispatch({
    type: MANAGER_COPY,
    copy: copy_objects,
  });
};

export const cut = cut_objects => dispatch => {
  dispatch({
    type: MANAGER_CUT,
    cut: cut_objects,
  });
};
export const copyJobSeries = jobs_series => dispatch => {
  dispatch({
    type: MANAGER_JOB_SERIES_COPY,
    jobs_series,
  });
};
export const copyJobFiles = jobs_files => dispatch => {
  dispatch({
    type: MANAGER_JOB_FILES_COPY,
    jobs_files,
  });
};
export const setNewJobProcess = new_job_process => dispatch => {
  dispatch({
    type: MANAGER_JOB_PROCESSES_NEW,
    new_job_process,
  });
};
const MUTATION_COPY_PASTE = gql`
  mutation($idDestinyFolder: LongLong!, $inputs: InputPaste!) {
    paste(idDestinyFolder: $idDestinyFolder, inputs: $inputs) {
      ok
      warning
      errors {
        path
        message
      }
      new_folders {
        idCurrentFolder
        folder_duplicated {
          idFolder
          name
        }
      }
      NewFiles {
        idFolderContent
        Owner {
          id
          FullName
        }
        Series {
          idSeries
          idStudy
          Patient {
            PatientName
            PatientBirthDate
            PatientID
          }
          Study {
            StudyDescription
            StudyInstanceUID
            AccessionNumber
          }
          ImageSize {
            Size
          }
          ImageResolution {
            Spacing
          }
          SeriesDescription
          SeriesInstanceUID
          NumberOfSeriesRelatedInstances
          SeriesDate
          SeriesTime
          StationName
          Modality
          Thumbnail

          Size

          createdAt
          updatedAt
        }
        File {
          idFileObject
          original_name
          mimetype
          Size
          Thumbnail
          updatedAt
          createdAt
        }
      }
    }
  }
`;
const PasteObjects = (
  idDestinyFolder,
  cut_objects,
  copy_objects,
  seriesIds,
  filesIds
) => {
  const inputs = {
    CopyObjects: copy_objects,
    CutObjects: cut_objects,
    SeriesIds: seriesIds,
    FilesIds: filesIds,
  };
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_COPY_PASTE),
    variables: {
      idDestinyFolder,
      inputs,
    },
  });
};

export const paste = (
  Folder,
  cut_objects,
  copy_objects,
  copy_series,
  copy_files
) => dispatch => {
  if (Folder.idFolder === -1 && Folder.name === 'Home') {
    // console.log({ cut_objects, copy_objects });
    // dispatch({ type: MANAGER_PASTE_END,error: 'You cannot ' });
    // return;
  } else if (Folder.idFolder === -1 && Folder.name === 'Shared') {
    // console.log({ cut_objects, copy_objects });
    dispatch({
      type: MANAGER_PASTE_END,
      error: 'You cannot paste in a share folder',
    });
    return;
  }
  const final_cuts = filterObjects(cut_objects);
  const final_copy = filterObjects(copy_objects);

  let seriesIds = copy_series.map(x => x.idSeries).filter(x => x);
  const to_remove_series = FilterOutDuplicated(
    seriesIds,
    'series',
    final_copy,
    final_cuts
  );
  seriesIds = seriesIds.filter(x => to_remove_series.indexOf(x) === -1);

  let filesIds = copy_files.map(x => x.idFileObject).filter(x => x);
  const to_remove_files = FilterOutDuplicated(
    filesIds,
    'files',
    final_copy,
    final_cuts
  );
  filesIds = filesIds.filter(x => to_remove_files.indexOf(x) === -1);
  console.log({ cut_objects, final_cuts });
  dispatch({ type: MANAGER_PASTE_START });
  (async () => {
    // await sleep(1e2);
    PasteObjects(Folder.idFolder, final_cuts, final_copy, seriesIds, filesIds)
      .then(res => {
        const data = GetData(res);

        // console.log('paste - end', { data });
        const { ok, errors, NewFiles, new_folders } = data.paste;
        if (!ok) {
          throw errors;
        }
        const objects = cut_objects.concat(copy_objects);
        for (let i = 0; i < objects.length; i++) {
          const { idFolder, idFolderContent } = objects[i];
          if (idFolderContent !== undefined) {
            // const index = Folder.Files.map(x => x.idFolderContent).indexOf(idFolderContent);
            // if (index === -1) {
            //   Folder.Files.push(objects[i]);
            // }
          } else if (idFolder) {
            let idFolderDuplicated = objects[i].idFolder;
            let name_duplicated = objects[i].name;
            const ii = new_folders
              .map(x => x.idCurrentFolder)
              .indexOf(idFolder);
            if (ii !== -1) {
              const { folder_duplicated } = new_folders[ii];
              idFolderDuplicated = folder_duplicated.idFolder;
              name_duplicated = folder_duplicated.name;
            } else {
              let { name } = objects[i];
              let count = 0;
              let index = -1;
              do {
                let new_name = name;
                if (count > 0) {
                  new_name = `${name}_${count}`;
                }
                index = Folder.SubFolders.map(x => x.name).indexOf(new_name);
                if (index === -1) {
                  name = new_name;
                }
                count++;
              } while (index !== -1);
              objects[i].name = name;
            }
            console.log({ ii, idFolderDuplicated, name_duplicated });
            Folder.SubFolders.push({
              ...objects[i],
              idFolder: idFolderDuplicated,
              name: name_duplicated,
            });
          }
        }
        for (let i = 0; i < NewFiles.length; i++) {
          const { idFolderContent } = NewFiles[i];
          const index = Folder.Files.map(x => x.idFolderContent).indexOf(
            idFolderContent
          );
          if (index === -1) {
            Folder.Files.push(NewFiles[i]);
          }
        }
        if (typeof paste.warning !== 'undefined') {
          dispatch({ type: MANAGER_PASTE_END, warning: paste.warning });
        } else {
          dispatch({ type: MANAGER_PASTE_END });
        }
      })
      .catch(error => {
        dispatch({
          type: MANAGER_PASTE_END,
          error: ServerErrorsString(error),
        });
      });
  })();
};

const filterObjects = objects => {
  const final_cut = [];
  for (let i = 0; i < objects.length; i++) {
    const { idFolder, idFolderContent } = objects[i];
    if (idFolderContent) {
      const { Series, File } = objects[i];
      const content = { idFolderContent };
      if (objects[i].idFolder) content.idFolder = objects[i].idFolder;
      if (Series) {
        const { idSeries } = Series;
        if (idSeries) content.idSeries = idSeries;
      }
      if (File) {
        const { idFileObject } = File;
        if (idFileObject) content.idFileObject = idFileObject;
      }
      final_cut.push({ FolderContent: content });
    } else if (idFolder) {
      const { idParent, idUser, idProject, name, Owner } = objects[i];
      const folder = { idFolder };
      if (idParent) folder.idParent = idParent;
      if (idUser) folder.idUser = idUser;
      else if (Owner) folder.idUser = Owner.id;
      if (idProject) folder.idProject = idProject;
      if (name) folder.name = name;
      final_cut.push({ Folder: folder });
    }
  }
  return final_cut;
};
export const clean_manager_error = () => dispatch => {
  dispatch({
    type: MANAGER_CLEAN_ERROR,
  });
};
export const cleanCopyCut = () => dispatch => {
  dispatch({
    type: MANAGER_CLEAN_COPY_CUT,
  });
};

export const copySeries = copy_series => dispatch => {
  dispatch({
    type: MANAGER_COPY_SERIES,
    copy_series,
  });
};
export const copyFiles = copy_files => dispatch => {
  dispatch({
    type: MANAGER_COPY_FILES,
    copy_files,
  });
};
export const setCopy = copy => dispatch => {
  dispatch({
    type: MANAGER_SET_COPY,
    copy,
  });
};
export const setCut = cut => dispatch => {
  dispatch({
    type: MANAGER_SET_CUT,
    cut,
  });
};
export const cleanCopySeries = () => dispatch => {
  dispatch({
    type: MANAGER_COPY_SERIES,
    copy_series: [],
  });
};

export const FilterOutDuplicated = (ids, type, final_copy, final_cuts) => {
  let copy_ids = [];
  if (final_copy) {
    copy_ids = final_copy
      .filter(x => x.FolderContent)
      .map(x => {
        if (type === 'series') return x.FolderContent.idSeries;
        return x.FolderContent.idFileObject;
      });
  }
  let cut_ids = [];
  if (final_cuts) {
    cut_ids = final_cuts
      .filter(x => x.FolderContent)
      .map(x => {
        if (type === 'series') return x.FolderContent.idSeries;
        return x.FolderContent.idFileObject;
      });
  }
  const to_remove = [];
  for (let i = 0; i < ids.length; i++) {
    const idCopy = copy_ids.indexOf(ids[i]);
    const idCut = cut_ids.indexOf(ids[i]);
    if (idCopy !== -1 || idCut !== -1) {
      to_remove.push(ids[i]);
    }
  }
  return to_remove;
};
