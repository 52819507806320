import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { ExplorerHome, ExplorerShared, ExplorerStatusBar } from './components';
import { getLevels } from 'helpers';
import { SnackMessage } from 'components';

const useStyles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
});

class Explorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      error: '',
    };
  }
  handleExplorerSortBy = (order, orderBy) => {
    // console.log({ order, orderBy });
    this.setState({ order, orderBy });
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleAddError = error => {
    this.setState({ error });
  };
  render() {
    const { level_1, level_2, level_3 } = getLevels(this.props);
    const { height, isDesktop } = this.props;
    const { error } = this.state;
    let id_folder = null;
    let home = true;
    if (level_1 === 'folder') {
      id_folder = parseInt(level_2, 10);
    } else if (level_1 === 'shared') {
      id_folder = parseInt(level_3, 10);
      home = false;
    }
    return (
      <React.Fragment>
        {home ? (
          <React.Fragment>
            <ExplorerHome
              handleAddError={this.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
              handleExplorerSortBy={this.handleExplorerSortBy}
              height={height}
              isDesktop={isDesktop}
              order={this.state.order}
              orderBy={this.state.orderBy}
            />
            <ExplorerStatusBar 
              handleAddError={this.handleAddError}
            />
          </React.Fragment>
        ) : (
          <ExplorerShared
            handleAddSuccess={this.props.handleAddSuccess}
            handleExplorerSortBy={this.handleExplorerSortBy}
            height={height}
            id_folder={id_folder}
            isDesktop={isDesktop}
            order={this.state.order}
            orderBy={this.state.orderBy}
          />
        )}
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </React.Fragment>
    );
  }
}

Explorer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(Explorer), useStyles);
