import React from 'react';
import PropTypes from 'prop-types';
import { InputDocker } from './components';
import { Grid, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class TabDockerInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { settings, disabled, holder } = this.props;
    let amount_input = 0;
    settings.inputs_outputs.forEach(element => {
      if (element.in_out_type === 'input') amount_input++;
    });
    return (
      <Grid container item spacing={1}>
        {amount_input === 0 ? (
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={0}
            style={{ marginTop: '10px' }}>
            <p>Press the button to add a new input configuration</p>
          </Grid>
        ) : null}
        {settings.inputs_outputs.map((input, index) => {
          return input.in_out_type === 'input' ? (
            <InputDocker
              defaultSetting={this.props.defaultSetting}
              disabled={disabled}
              handleChanged={this.props.handleChanged}
              handleRemoveInput={id => this.props.handleRemoveInput(id)}
              handleUpdateSetting={settings =>
                this.props.handleUpdateSetting(settings)
              }
              holder={holder}
              input={input}
              key={`${input.id}-${index}`}
              settings={settings}
              uploading={this.state.uploading}
            />
          ) : null;
        })}
        <Grid container item justifyContent="center" xs={12}>
          <IconButton
            color="primary"
            onClick={this.props.handleAddInput}
            size="large">
            <AddCircleOutlineIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

TabDockerInputs.propTypes = {
  disabled: PropTypes.bool,
};

export default TabDockerInputs;
