import { MutationSaveSurvey, QuerySurvey } from 'graphql/Surveys';
import { GetData, ServerErrorsString } from 'helpers/utils';
import SurveyService from './utils_json';
export const GetSteps = surveyStructure => {
  const data = SurveyService.getJsonStructure(surveyStructure);
  return data;
};
export const createSurvey = (
  data = {},
  surveyId = undefined,
  idPatient = undefined,
  saveSurvey = () => '',
  onClickBackToOverview = () => '',
  onSurveyValueChanged = () => ''
) => {
  const surveyStructure = data.surveyType;
  let survey_json = undefined;
  if (data.survey) survey_json = JSON.parse(data.survey);
  const survey = SurveyService.getRawSurveyStructure(surveyStructure);
  survey.surveyId = surveyId;
  survey.data = survey_json;
  survey.idPatient = idPatient;
  survey.showCompletedPage = false;
  survey.showPageTitles = false;
  survey.surveyType = surveyStructure;
  survey.showTitle = false;
  survey.showPageTitles = false;
  survey.showNavigationButtons = false;

  survey.onComplete.add(sender => {
    saveSurvey(sender, true);
  });
  const buttonSave = survey.addNavigationItem({
    id: 'save_survey',
    title: 'save',
    action: () => saveSurvey(survey, false),
  });
  const buttonCancel = survey.addNavigationItem({
    id: 'cancel_survey',
    title: 'cancel',
    action: () => onClickBackToOverview(),
  });
  survey.onValueChanged.add(onSurveyValueChanged);

  return {
    survey_json,
    survey,
    buttonSave,
    buttonCancel,
  };
  // setcurrentSurvey(survey);
};

export const handleValueChanged = (sender, options, THIS) => {
  console.log(
    'Question name: ' + options.name + ', New value: ' + options.value
  );
  let { value_changed } = THIS.state;
  const index = value_changed.map(x => x.name).indexOf(options.name);
  console.log({ t: typeof options.value });
  let length = 0;
  if (typeof options.value === 'number') {
    length = 10;
  } else {
    length = options.value.length;
  }
  if (index === -1 && length) {
    value_changed.push(options);
  } else {
    if (length) {
      value_changed[index] = {
        ...options,
      };
    } else {
      value_changed.splice(index);
    }
  }
  THIS.setState({ value_changed });
};
export const handleSave = (sender, complete, THIS, link = null) => {
  console.log({ sender, complete });
  const { saving } = THIS.state;
  // const { currentPatient } = THIS.props;
  if (saving) return;
  THIS.setState({ saving: true });
  (async () => {
    MutationSaveSurvey(sender, complete, link)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, survey } = data.updateSurvey;
        // console.log({ new_survey });
        if (ok) {
          const { patient } = survey;
          const reply = createSurvey(
            survey,
            survey.id,
            patient.id,
            THIS.handleSave,
            THIS.handleBackToOverview,
            THIS.handleValueChanged
          );
          reply.survey.complete = complete;
          THIS.setState({
            saving: false,
            updatedAt: survey.updatedAt,
            survey_json: reply.survey_json,
            currentSurvey: reply.survey,
            buttonSave: reply.buttonSave,
            buttonCancel: reply.buttonCancel,
            value_changed: [],
          });
          THIS.props.handleUpdateSurvey(survey);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        THIS.setState({ saving: false });
        if (THIS.props.handleAddError)
          THIS.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const handleQuery = (name, THIS) => {
  const { surveys, currentPatient } = THIS.props;
  const index = surveys.map(x => x.surveyType).indexOf(name);
  if (index === -1) {
    return;
  }
  const { loading, currentSurvey } = THIS.state;
  if (loading || currentSurvey) return;
  THIS.setState({ loading: true });
  (async () => {
    QuerySurvey(surveys[index].id)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, survey } = data.surveyRecorded;
        if (ok) {
          const reply = createSurvey(
            survey,
            surveys[index].id,
            currentPatient.id,
            THIS.handleSave,
            THIS.handleBackToOverview,
            THIS.handleValueChanged
          );
          THIS.setState({
            loading: false,
            updatedAt: survey.updatedAt,
            survey_json: reply.survey_json,
            currentSurvey: reply.survey,
            buttonSave: reply.buttonSave,
            buttonCancel: reply.buttonCancel,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        THIS.setState({ loading: false });
      });
  })();
};
