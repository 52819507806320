import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';

import {
  ToolBarSettings,
  GroupPatientLevel,
  GroupStudyLevel,
  GroupSeriesLevel,
  GroupOtherLevel,
  FolderStructure,
} from './components';

class TabFolderStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <ToolBarSettings
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={this.handleDeleteSelected}
            selected={this.state.selected}
          />
        </Grid>
        <Paper sx={{ margin: 0.5, marginTop: 1, padding: 0.5, paddingTop: 2 }}>
          <Grid container item spacing={2} xs={12}>
            <Grid container>
              <Grid item sx={{ padding: 2, paddingLeft: 3 }}>
                <GroupPatientLevel />
              </Grid>
              <Grid item sx={{ padding: 2 }}>
                <GroupStudyLevel />
              </Grid>
              <Grid item sx={{ padding: 2 }}>
                <GroupSeriesLevel />
              </Grid>
              <Grid item sx={{ padding: 2 }}>
                <GroupOtherLevel />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <Paper
            sx={{ margin: 0.5, marginTop: 1, padding: 0.5, paddingTop: 2 }}>
            <FolderStructure />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TabFolderStructure.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};

export default TabFolderStructure;
