import React from 'react';
import PropTypes from 'prop-types';
import WebGLSlider from '../WebGLSlider';
import { webglDraw } from '../../../utils';
class SliderAngles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSliceAngle = (event, { value }, index) => {
    const { renderer } = this.props;
    if (!renderer) return;
    var angleInDegrees = 360 - value;
    renderer.rotation[index] = webglDraw.degToRad(angleInDegrees);
    renderer.drawScene();
  };
  render() {
    const { renderer } = this.props;
    if (renderer === undefined || !renderer) return null;
    return (
      <React.Fragment>
        <WebGLSlider
          options={{
            max: 360,
            name: 'Angle X',
            value: webglDraw.radToDeg(renderer.rotation[0]),
          }}
          slide={(e, v) => this.handleSliceAngle(e, v, 0)}
        />
        <WebGLSlider
          options={{
            max: 360,
            name: 'Angle Y',
            value: webglDraw.radToDeg(renderer.rotation[1]),
          }}
          slide={(e, v) => this.handleSliceAngle(e, v, 1)}
        />
        <WebGLSlider
          options={{
            max: 360,
            name: 'Angle Z',
            value: webglDraw.radToDeg(renderer.rotation[2]),
          }}
          slide={(e, v) => this.handleSliceAngle(e, v, 2)}
        />
      </React.Fragment>
    );
  }
}

SliderAngles.propTypes = {
  classes: PropTypes.object,
};

export default SliderAngles;
