import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, CardHeader, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormatTime, getInitials, getUserFullName } from 'helpers';

class UserViewHeader extends Component {
  render() {
    const { classes, user } = this.props;
    let FullName = getUserFullName(user);
    FullName = `${FullName} (ID: ${user.id})`;
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleOpenUserView(null)}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            onClick={() => this.props.handleOpenCloseAvatar(true)}
            src={user.avatar}>
            {getInitials(FullName)}
          </Avatar>
        }
        subheader={'Registered: ' + FormatTime(user.createdAt, 'LLLL')}
        title={FullName}
      />
    );
  }
}

UserViewHeader.propTypes = {
  classes: PropTypes.object,
};

UserViewHeader.defaultProps = {};

export default UserViewHeader;
