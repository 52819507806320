import React from 'react';
import PropTypes from 'prop-types';
import { GroupBox } from 'components';
class FolderStructure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <GroupBox title_box="Folder Structure">FolderStructure</GroupBox>;
  }
}

FolderStructure.propTypes = {
  classes: PropTypes.object,
};

export default FolderStructure;
