import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { ErrorsList, ServerErrors, GetData, PaperComponent } from 'helpers';
import { useStyles } from '../Styles';
import { ButtonLoad, InputUsers, TextFieldCustom } from 'components';
import { MutationUpdateTeam } from 'graphql/Teams/utils_team_muations';

class DialogEditTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      team: null,
      touched: {},
      errors: [],
    };
  }
  componentDidMount() {
    this.setState({ team: this.props.team });
  }
  componentDidUpdate() {
    const { team } = this.props;
    if (!team) return;
    if (team && !this.state.team) {
      this.setState({ team });
    } else if (team.id !== this.state.team.id) {
      this.setState({ team });
    }
    // const { errors } = this.state;
    // if (page_errors && page_errors.length) {
    //   const { message } = page_errors[0];
    //   this.setState({
    //     errors: {
    //       ...errors,
    //       name: [message],
    //     },
    //   });
    //   teamsErrors([]);
    // }
  }
  handleClose = () => {
    this.setState({ errors: [] });
    this.props.handleCloseDialog(false);
  };
  handleChange = event => {
    const { team, touched, errors } = this.state;
    this.setState({
      team: {
        ...team,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...touched,
        [event.target.name]: event.target.value ? true : false,
      },
      errors: {
        ...errors,
        [event.target.name]: null,
      },
    });
  };
  hasError = field => {
    const { errors } = this.state;
    const result = errors[field] ? true : false;
    // console.log(errors, field, result);
    return result;
  };
  handleUpdateTeam = () => {
    const { updating, team } = this.state;
    if (updating) return;
    // const validation = validate(this.state.team, schema);
    // // console.log('team: ', validation);
    // if (validation) {
    //   this.setState({ errors: validation });
    //   return;
    // }
    // console.log({ team });
    this.setState({ updating: true });
    (async () => {
      MutationUpdateTeam(team)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateTeam;
          if (ok) {
            this.setState({ updating: false });
            this.props.handleUpdatedTeam(team);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('MutationCreateTeam-catch-error', errors);
          this.setState({
            updating: false,
            errors: { name: ServerErrors(errors) },
          });
        });
    })();
  };
  render() {
    let { classes, open } = this.props;
    let { errors, updating, team } = this.state;
    if (!team) return null;
    // console.log({ team });
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Create Team
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To edit the team, please fill all the necessary information.
          </DialogContentText>
        </DialogContent>
        <div style={{ padding: 20, paddingTop: 0 }}>
          <Grid
            alignItems="stretch"
            container
            direction="column"
            justifyContent="flex-start"
            spacing={4}>
            <Grid item>
              <InputUsers
                // disabled={disable_all}
                filter={false}
                multiple={false}
                // onChange={this.handleAddUser}
                textfield={{
                  fullWidth: true,
                  helperText: 'Search specify the name of user administrator',
                  label: 'Administrator',
                  margin: 'dense',
                  required: true,
                }}
                value={team.admin}
                variant="outlined"
              />
            </Grid>
            <Grid item mt={4}>
              <TextFieldCustom
                autoFocus
                className={classes.textField}
                error={this.hasError('name')}
                helperText={
                  this.hasError('name') ? ErrorsList(errors, 'name') : null
                }
                id="name"
                label="Team Name"
                margin="dense"
                name="name"
                onChange={this.handleChange}
                onKeyDown={event => {
                  const { key } = event;
                  if (key === 'Enter') {
                    this.handleUpdateTeam();
                  }
                }}
                placeholder="Name for the team"
                type="name"
                value={this.state.team.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
        <DialogActions>
          <Button autoFocus color="primary" onClick={this.handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            className={classes.button}
            disable_button={team.name === ''}
            handleClick={this.handleUpdateTeam}
            loading={updating}
            name="Edit"
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogEditTeam.propTypes = {
  className: PropTypes.string,
  handleUpdatedTeam: PropTypes.func.isRequired,
};
DialogEditTeam.defaultProps = {
  handleUpdatedTeam: () => '',
  team: [],
};
export default withStyles(DialogEditTeam, useStyles);
