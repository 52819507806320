import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
const useStyles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
});
class SidebarNavItemSmallMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClose = () => {};
  render() {
    // const { classes, onClick, anchorEl } = this.props;
    return null;
    // const menu = ['A', 'B', 'C'];
    // const open = Boolean(anchorEl);
    // console.log({ open });
    // if (!open) {
    //   return null;
    // }
    // return (
    //   <Menu
    //     anchorEl={anchorEl}
    //     id="basic-menu"
    //     MenuListProps={{
    //       'aria-labelledby': 'basic-button',
    //     }}
    //     onClose={this.handleClose}
    //     open={open}>
    //     {menu.map((item, index) => (
    //       <MenuItem key={index} onClick={this.handleClose}>
    //         item
    //       </MenuItem>
    //     ))}
    //   </Menu>
    // );
  }
}

SidebarNavItemSmallMenu.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(SidebarNavItemSmallMenu, useStyles);
