import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ALL_TEAMS = gql`
  query($name: String, $page: Int, $limit: Int) {
    allTeams(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      teams {
        id
        name
        admin {
          id
          FullName
        }
        avatar
        total_members
        total_projects
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySummaryTeams = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined') variables.name = name;
  if (typeof page !== 'undefined') variables.page = page;
  if (typeof limit !== 'undefined') variables.limit = limit;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_TEAMS),
    variables,
  });
};

const MUTATION_ADD_TEAM_TO_PROJECT = gql`
  mutation($idProject: LongLong!, $ids: [Int!]!) {
    projectAddTeams(idProject: $idProject, ids: $ids) {
      ok
      teams {
        id
        name
        admin {
          id
          FullName
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const AddTeamsToProject = (idProject, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_TEAM_TO_PROJECT),
    variables: {
      idProject,
      ids,
    },
  });
};

const QUERY_ALL_TEAMS_AT_PROJECT = gql`
  query($idProject: LongLong!, $name: String) {
    allTeamsAtProject(idProject: $idProject, name: $name) {
      id
      name
      avatar
      members {
        id
        FullName
      }
      admin {
        id
        FullName
      }
      projects {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const AllTeamsAtProject = (idProject, name) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_TEAMS_AT_PROJECT),
    variables: {
      idProject,
      name,
    },
  });
};
