import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { TabPanel } from 'components';
import { getParams } from 'helpers';
import { getTabs } from './components/utils';
import { useStylesAdministrator } from './components/AdminDefault/useStyles';
class TabsAdministrator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: getTabs(props.user),
    };
  }

  componentDidUpdate() {}
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    const { tabs } = this.state;
    if (newValue >= 0 && newValue < tabs.length) {
      history.push(`/administrator/${tabs[newValue].name}`);
    } else {
      history.push('/administrator/users');
    }
  };
  render() {
    const { classes, user, height } = this.props;
    const { level_1 } = getParams(this.props);
    const index = this.state.tabs.map(x => x.name).indexOf(level_1);
    let tabValue = 0;
    if (index !== -1) {
      tabValue = index;
    }
    return (
      <div style={{ margin: 0 }}>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              const { name } = tab;
              return (
                <Tab
                  key={`key-tab-${index}-${name}`}
                  label={name.replace('_', ' ')}
                />
              );
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%', height: height - 115, margin: 0 }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { name, TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`key-tab-panel-${index}-${name}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    height={height}
                    isActive={index === tabValue}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

TabsAdministrator.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(
  withRouter(TabsAdministrator),
  useStylesAdministrator
);
