/* eslint-disable indent */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';

import { SearchText } from 'components/Basics';
import FormTextFields from '../FormTextFields';
import { QueryClinicalPatients } from 'graphql/DataClinics';
import { GetData, ServerErrorsString } from 'helpers';

class PatientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      patient_found: '',
    };
  }
  componentDidMount() {}

  handleSearch = text => {
    const patientID = text.trim();
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, patient_found: '' });
    const input = { PatientID: patientID };
    (async () => {
      QueryClinicalPatients(input, 0, 100, 0)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.patientsClinics;
          if (ok && patients.length > 0) {
            let form = [];
            const patients_keys = Object.keys(patients[0]);
            for (let i = 0; i < patients_keys.length; i++) {
              form.push({
                value: patients[0][patients_keys[i]],
                field: patients_keys[i],
              });
            }
            this.setState({ patient_found: patients[0].PatientName });
            this.props.handleUpdatePatientForm(form);
          }
          if (!ok) {
            throw errors;
          }
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, patient_found } = this.state;
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Typography component="h4" variant="h6">
          Patient Form
        </Typography>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
        <Box sx={{ width: '50%' }}>
          <SearchText
            handleSearchText={text => this.handleSearch(text)}
            loading={loading}
            placeholder="1234567890"
            size="normal"
          />
          <Typography gutterBottom variant="body1">
            {patient_found ? 'Found patient ' + patient_found : ''}
          </Typography>
        </Box>{' '}
        <FormTextFields
          form={this.props.patient_form}
          handleChangeForm={this.props.handleUpdatePatientForm}
        />
      </Box>
    );
  }
}

PatientForm.propTypes = {
  handleClose: PropTypes.func,
};
PatientForm.defaultProps = {
  handleClose: () => '',
};

export default PatientForm;
