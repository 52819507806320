import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  CardProjectActions,
  CardProjectHeader,
  useStyles,
} from 'components/Cards/components';
import { ItemsNotifications } from './components';
import { UpdateProjectNotifications } from 'graphql/Projects';
import { SnackMessage } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
class CardProjectNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: false,
      idProject: -1,
      changed: false,
      notifications: {},
      error: '',
    };
  }

  getNotifications = () => {
    const { emails, messages, uploads, chat } = this.state.notifications;
    return {
      id: this.props.project.id,
      emails,
      messages,
      uploads,
      chat,
    };
  };
  updateNotifications = () => {
    const { loading } = this.state;
    if (loading) return;
    const { project } = this.props;
    this.setState({ loading: true });
    (async () => {
      UpdateProjectNotifications(project.id, this.getNotifications())
        .then(res => {
          const data = GetData(res);
          const { updateProjectNotifications } = data;
          if (typeof updateProjectNotifications !== 'undefined') {
            const { ok, errors, notifications } = updateProjectNotifications;
            if (ok) {
              this.setState({
                loading: false,
                notifications,
                idProject: project.id,
              });
              this.props.projectChanged({
                ...project,
                notifications,
              });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown server error');
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleChange = event => {
    event.persist();
    const { notifications } = this.state;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;

    this.setState({ notifications: { ...notifications, [name]: checked } });
  };
  isChanged = () => {
    const { notifications: status } = this.state;
    if (Object.keys(status).length === 0) return false;
    let count = 0;
    const { notifications } = this.props.project;
    for (let key in status) {
      if (status[key] !== notifications[key]) {
        count++;
      }
    }

    return count ? true : false;
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, show, maximize, project } = this.props;
    const { loading, error } = this.state;
    if (!show && !maximize) return null;
    return (
      <Card className={classes.root}>
        <CardProjectHeader
          handleMaximize={() => this.props.handleMaximize('notifications')}
          subtitle="List of project notifications"
          title="Notifications"
        />
        <Divider />
        <CardContent className={classes.content}>
          <ItemsNotifications
            handleChange={this.handleChange}
            notifications={project.notifications}
          />
        </CardContent>
        <Divider variant="middle" />
        <CardProjectActions
          changed={this.isChanged()}
          handleSaveClicked={this.updateNotifications}
          loading={loading}
        />
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Card>
    );
  }
}

CardProjectNotifications.propTypes = {
  classes: PropTypes.object,
};
CardProjectNotifications.defaultProps = {
  maximize: false,
  show: true,
};
export default withStyles(CardProjectNotifications, useStyles);
