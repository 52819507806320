import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { RowText, RowTextMultiLine } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';

class EpilepsyCaseViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { epilepsy_case } = this.props;
    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          Epilepsy Case
        </Typography>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="Case identifier:"
              second={this.getValue(epilepsy_case.case_identifier)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Case date:"
              second={this.formatDate(epilepsy_case.date)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="First seizure date:"
              second={this.formatDate(epilepsy_case.first_seizure_date)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Patient age:"
              second={this.getValue(epilepsy_case.patient_age)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Neurologist:"
              second={this.getValue(epilepsy_case.neurologist)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Assistant:"
              second={this.getValue(epilepsy_case.assistant)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Depth:"
              second={this.getValue(epilepsy_case.depth)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Date pre implantation MR:"
              second={this.formatDate(epilepsy_case.date_pre_implantation_mr)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Date post implantation CT:"
              second={this.formatDate(epilepsy_case.date_post_implantation_ct)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Laterality:"
              second={this.getValue(epilepsy_case.laterality)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
          <Grid item xs={6}>
            <RowText
              display="block ruby"
              first="Scalp:"
              second={this.getValue(epilepsy_case.scalp)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Subscalp:"
              second={this.getValue(epilepsy_case.subscalp)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Strip:"
              second={this.getValue(epilepsy_case.strip)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Grid:"
              second={this.getValue(epilepsy_case.grid)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="CT registered to MRI:"
              second={this.getValue(epilepsy_case.ct_registered_to_mri)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Normalized to MNI:"
              second={this.getValue(epilepsy_case.normalized_to_mni)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Pial reconstruction:"
              second={this.getValue(epilepsy_case.pial_reconstruction)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Electrodes located MR:"
              second={this.getValue(epilepsy_case.electrodes_located_mr)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowText
              display="block ruby"
              first="Electrodes labelled:"
              second={this.getValue(epilepsy_case.electrodes_labelled)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
          <Grid item xs={12}>
            <RowTextMultiLine
              display="block ruby"
              first="Case comment:"
              second={this.getValue(epilepsy_case.comment)}
              styleBox={{ fontWeight: 1000 }}
            />
            <RowTextMultiLine
              display="block ruby"
              first="Covered region:"
              second={this.getValue(epilepsy_case.covered_region)}
              styleBox={{ fontWeight: 1000 }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

EpilepsyCaseViewer.propTypes = {
  classes: PropTypes.object,
};

export default EpilepsyCaseViewer;
