import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from 'redux-store/actions/loginActions';
import {
  GetDefaultRoutes,
  GetDesignerRoutes,
  GetSurveyRoutes,
} from './components';
import { MutationUpdateOnlineUser } from 'graphql/Users/utils_online';
import { QueryUsersClinicals } from 'graphql/Users/utils_user_clinical';
import { QueryAccountClinicalData } from 'graphql/DataClinics/utils_clinical_accounts';
import {
  ServerErrorsString,
  IsValid,
  GetData,
  IsInvalidUser,
  IsValidUser,
} from 'helpers';

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUser: null,
      count: 0,
      event: 0,
      is_clinical_account: null,
      is_clinical_user: null,
    };
    this.online_interaval = null;
  }
  runOnlineUser = (user, ReactGA4, page) => {
    MutationUpdateOnlineUser(user)
      .then(res => {
        const data = GetData(res);
        let { count, event } = this.state;
        count++;
        event++;
        if (count > 20) {
          count = 0;
          ReactGA4.send({
            hitType: 'pageview',
            page: page,
          });
        } else if (event > 5) {
          event = 0;
          ReactGA4.event({
            category: 'online',
            action: 'running',
            value: 100, // optional, must be a number
            nonInteraction: true, // optional, true/false
          });
        }
        this.setState({ count, event });
        const { ok, errors } = data.updateLastSeen;
        if (!ok) {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        const error_text = ServerErrorsString(error);
        if (error_text.indexOf('Invalid session') !== -1) {
          this.props.logout();
          this.props.history.push('/');
        }
      });
  };
  updateOnlineUser = () => {
    const { user, ReactGA4, page, skip_online } = this.props;
    if (IsValidUser(user)) {
      if (skip_online) {
        console.log('updateOnlineUser - login - skip');
        return;
      }
      console.log('updateOnlineUser - login');
      if (
        this.online_interaval &&
        this.state.idUser &&
        user.id !== this.state.idUser
      ) {
        clearInterval(this.online_interaval);
        this.online_interaval = null;
      }
      if (!this.online_interaval) {
        console.log('updateOnlineUser - online_interval');
        this.runOnlineUser(user, ReactGA4, page);
        this.online_interaval = setInterval(() => {
          if (IsInvalidUser(user)) return;
          this.runOnlineUser(user, ReactGA4, page);
        }, 60000); // every 1 minute
        this.setState({ idUser: user.id });
      }
    } else if (this.online_interaval) {
      console.log('updateOnlineUser - logout');
      clearInterval(this.online_interaval);
      this.online_interaval = null;
      this.setState({ idUser: null });
    }
  };
  queryClinicalPermission = async () => {
    const { user } = this.props;
    if (IsInvalidUser(user)) return;
    const { id, type } = user;
    QueryUsersClinicals(id)
      .then(res => {
        const data = GetData(res);
        const { ok, total } = data.userClinicalDataType;
        if (ok && total > 0) {
          this.setState({ is_clinical_user: true });
        } else {
          this.setState({ is_clinical_user: false });
        }
      })
      .catch(error => {
        console.log({ error });
        this.setState({ is_clinical_user: false });
      });
    QueryAccountClinicalData(type)
      .then(res => {
        const data = GetData(res);
        const { ok, total } = data.accountClinicalData;
        if (ok && total > 0) {
          this.setState({ is_clinical_account: true });
        } else {
          this.setState({ is_clinical_account: false });
        }
      })
      .catch(error => {
        console.log({ error });
        this.setState({ is_clinical_account: true });
      });
  };
  componentDidMount() {
    this.queryClinicalPermission();
    this.updateOnlineUser();
  }
  componentDidUpdate() {
    this.updateOnlineUser();
  }
  componentWillUnmount() {
    if (this.online_interaval) {
      clearInterval(this.online_interaval);
    }
    this.online_interaval = null;
  }
  render() {
    const { user, page } = this.props;
    const { is_clinical_account, is_clinical_user } = this.state;
    let is_clinical_allowed = true;
    if (IsValid(is_clinical_account) && IsValid(is_clinical_user)) {
      is_clinical_allowed = is_clinical_account || is_clinical_user;
    }
    if (user && user.idCategory === 2) {
      console.log('Designer - Routes');
      // return 'Router: Designer - Routes';
      return GetDesignerRoutes(this.props);
    } else if (page.startsWith('survey') || (user && user.idCategory === 3)) {
      console.log('Survey - Routes');
      // return 'Router: Survey - Routes';
      return GetSurveyRoutes(this.props);
    } else {
      console.log('Default - Routes');
      // return 'Router: Default - Routes';
      return GetDefaultRoutes(this.props, is_clinical_allowed);
    }
  }
}

Routes.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.info_user.user,
  isDesktop: state.layout.isDesktop,
  page: state.page_globals.page,
  skip_online: state.page_globals.skip_online,
});
export default connect(mapStateToProps, { logout })(withRouter(Routes));
