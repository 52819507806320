import React from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@mui/material';
import {
  EditSequenceMapping,
  TableSequenceMapping,
  TabSequenceMappingButtons,
  CreateSequenceMapping,
} from './components';
import {
  MutationUploadMappingFile,
  QuerySequenceMapping,
} from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';
import { DialogDeleteSequenceMapping } from 'components';

class TabSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false,
      open_delete: false,
      uploading: false,
      loading: false,
      deleting: false,
      mappings: [],
      init: false,
      page: 0,
      limit: 100,
      total: 0,
      progress_all: 0,
      edit: false,
      selected: [],
      count_changed: false,
    };
    this.holder = {
      cleanSelected: null,
    };
  }

  componentDidMount() {
    this.queryMappings();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryMappings();
  }
  queryMappings = (newName = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }

    this.setState({ loading: true });
    (async () => {
      QuerySequenceMapping(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { mappings, ok, errors, total } = data.sequenceMappings;
          if (ok) {
            this.setState({
              loading: false,
              mappings,
              init: true,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.queryMappings(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.queryMappings(null, 0, +event.target.value);
  };
  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const progress_all = Math.floor((loaded * 100) / total);

    this.setState({ progress_all });
  };
  handleSelectFile = event => {
    const { uploading, page, limit } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadMappingFile(files[0], page, limit, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings, total } = data.addFileSequenceMappings;
          if (ok) {
            this.setState({
              uploading: false,
              mappings,
              total,
              count_changed: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleOpenEditView = () => {
    const { selected } = this.state;
    //
    if (selected.length) {
      this.setState({ edit: !this.state.edit });
    }
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleDeletedSelected = mappings => {
    this.setState({ selected: [], mappings, open_delete: false });
    this.queryMappings();
  };
  handleDeleteDialog = () => {
    this.setState({ open_delete: !this.state.open_delete });
  };
  handleUpdateSequenceMappings = new_mappings => {
    let { mappings, selected, count_changed } = this.state;

    let count = 0;
    for (let i = 0; i < new_mappings.length; i++) {
      const { id } = new_mappings[i];
      let index = mappings.map(x => x.id).indexOf(id);
      if (index !== -1) {
        mappings[index] = { ...new_mappings[i] };
        count++;
      }
      index = selected.map(x => x.id).indexOf(id);
      if (index !== -1) {
        selected[index] = { ...new_mappings[i] };
        count++;
      }
    }
    this.setState({
      mappings,
      selected,
      count_changed: count ? !count_changed : count_changed,
    });
  };
  handleAddNewMappings = newMappings => {
    const { mappings } = this.state;
    for (let i = 0; i < newMappings.length; i++) {
      const { id } = newMappings[i];
      const index = mappings.map(x => x.id).indexOf(id);
      if (index === -1) {
        mappings.unshift({ ...newMappings[i] });
      }
    }
    this.setState({ mappings, create: false });
  };
  handleClearSelected = () => {
    this.holder.cleanSelected();
    this.setState({ selected: [] });
  };
  render() {
    const { uploading, progress_all, edit, create, selected } = this.state;
    if (create) {
      return (
        <CreateSequenceMapping
          handleAddError={this.props.handleAddError}
          handleAddNewMappings={this.handleAddNewMappings}
          handleClose={() => this.setState({ create: false })}
          handleOpenEditView={this.handleOpenEditView}
        />
      );
    }
    if (edit) {
      return (
        <EditSequenceMapping
          handleAddError={this.props.handleAddError}
          handleOpenEditView={this.handleOpenEditView}
          handleUpdateSequenceMappings={this.handleUpdateSequenceMappings}
          selected={selected}
        />
      );
    }
    return (
      <Grid container>
        {uploading && (
          <LinearProgress value={progress_all} variant="determinate" />
        )}
        <TabSequenceMappingButtons
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteDialog}
          handleOpenCreate={() => this.setState({ create: true })}
          handleOpenEdit={this.handleOpenEditView}
          handleReload={() => this.queryMappings()}
          handleSelectFile={this.handleSelectFile}
          loading={this.state.loading}
          selected={selected}
          uploading={uploading}
        />
        <TableSequenceMapping
          deleting={this.state.deleting}
          disable_splice
          external
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSetSelected={this.handleSetSelected}
          height={this.props.height - 90}
          hide_toolbar
          holder={this.holder}
          limit={this.state.limit}
          loading={this.state.loading}
          page={this.state.page}
          rows={this.state.mappings}
          selected={selected}
          total={this.state.total}
        />
        <DialogDeleteSequenceMapping
          handleAddError={this.props.handleAddError}
          handleCloseDialog={this.handleDeleteDialog}
          handleDeletedSelected={this.handleDeletedSelected}
          mappings={this.state.mappings}
          open={this.state.open_delete}
          selected={this.state.selected}
        />
      </Grid>
    );
  }
}

TabSequenceMapping.propTypes = {
  classes: PropTypes.object,
};

export default TabSequenceMapping;
