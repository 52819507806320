import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { TableRowDoubleCell } from 'components/Tables/components';
import { FormatBytes, FormatTime } from 'helpers';

class TabDockerImageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { image } = this.props;
    if (!image || image === undefined) return null;
    return (
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>A basic details about the selected docker image</caption>
          <TableBody>
            <TableRowDoubleCell name="ID" value={image.idImage} />
            <TableRowDoubleCell name="Size" value={FormatBytes(image.Size)} />
            <TableRowDoubleCell
              name="Created"
              value={FormatTime(image.createdAt, 'calendar')}
            />
            <TableRowDoubleCell
              name="Build"
              value={image.Build ? image.Build : 'Not found'}
            />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabDockerImageDetails.propTypes = {
  classes: PropTypes.object,
};

export default TabDockerImageDetails;
