import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import TableCellHeader from '../TableCellHeader';

class TableHeaders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      checked,
      classes,
      selected,
      handleSelectAll,
      rowCount,
      handleSort,
      order,
      orderBy,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checked}
              className={classes.check}
              indeterminate={selected.length > 0 && selected.length < rowCount}
              onClick={handleSelectAll}
            />
          </TableCell>
          <TableCellHeader
            createSortHandler={handleSort}
            headCell="name"
            name="Name"
            order={order}
            orderBy={orderBy}
          />
          <TableCellHeader
            createSortHandler={handleSort}
            headCell="Owner"
            name="Owner"
            order={order}
            orderBy={orderBy}
          />
          <TableCellHeader
            align="right"
            createSortHandler={handleSort}
            headCell="Size"
            name="Size"
            order={order}
            orderBy={orderBy}
          />
          <TableCellHeader
            align="right"
            createSortHandler={handleSort}
            headCell="createdAt"
            name="Last modified"
            order={order}
            orderBy={orderBy}
          />
          <TableCell style={{ width: 90, padding: 0 }}>Download</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

TableHeaders.propTypes = {
  classes: PropTypes.object,
};

export default TableHeaders;
