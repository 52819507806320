import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  top: {
    color: '#1a90ff',
    animationDuration: '800ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
});

class CircularLoading extends Component {
  render() {
    const { classes, small, simple, size } = this.props;
    if (simple && small) {
      return (
        <div className={classes.root}>
          <CircularProgress
            className={classes.bottom}
            size={size}
            thickness={4}
            value={100}
            variant="determinate"
          />
          <CircularProgress
            classes={{
              circle: classes.circle,
            }}
            className={classes.top}
            disableShrink
            size={size}
            thickness={4}
            variant="indeterminate"
          />
        </div>
        // <CircularProgress
        //   style={}
        //   variant="indeterminate"
        //   disableShrink
        // />
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="center"
        xs={12}>
        <CircularProgress />
      </Grid>
    );
  }
}

CircularLoading.propTypes = {
  classes: PropTypes.object,
  simple: PropTypes.bool,
  small: PropTypes.bool,
};
CircularLoading.defaultProps = {
  small: false,
  simple: false,
  size: 30,
};
export default withStyles(CircularLoading, useStyles);
