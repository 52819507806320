import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from '@mui/material';
import clsx from 'clsx';
import useStyles from './Styles';
import { withStyles } from 'tss-react/mui';

class RightCheckBox extends Component {
  render() {
    const { classes, title, checked, disabled } = this.props;
    return (
      <Tooltip title={title}>
        <div>
          <Checkbox
            checked={checked}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            className={classes.root}
            color="default"
            disabled={disabled}
            disableRipple
            icon={<span className={classes.icon} />}
            name={title}
            onChange={this.props.handleChecked}
          />
        </div>
      </Tooltip>
    );
  }
}

RightCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
};
RightCheckBox.defaultProps = {};

export default withStyles(RightCheckBox, useStyles);
