// import isEmpty from 'lodash/isEmpty';
import {
  CREATE_PROJECT_CREATED,
  CREATE_PROJECT_SETTINGS,
  CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE,
  CREATE_PROJECT_SETTINGS_IMAGE_TYPES,
  CREATE_PROJECT_SETTINGS_NOTIFICATIONS,
  CREATE_PROJECT_SETTINGS_SITES,
  CREATE_PROJECT_TEAMS,
  CREATE_PROJECT_TYPE,
  CREATE_PROJECT_USERS,
  LOGOUT_USER_CREATE_PROJECT,
} from '../actions/types';

const initialState = {
  project_type: {
    type: 0,
    name: 'Undefined',
    description: '',
    avatar: '/images/projects/project_0.png',
  },
  project_settings: {
    img_type: [],
    folder_structure: [],
    notifications: {
      id: -1,
      emails: false,
      messages: false,
      uploads: false,
      chat: false,
    },
    centers: [],
  },
  teams: [],
  users: [],
};

export default function reducer_page_project(state = initialState, action) {
  const { type } = action;
  //
  switch (type) {
    case LOGOUT_USER_CREATE_PROJECT:
      return initialState;
    case CREATE_PROJECT_CREATED: {
      if (action.status) {
        return initialState;
      } else {
        return initialState;
      }
    }
    case CREATE_PROJECT_TYPE:
      return {
        ...state,
        project_type: action.project_type,
      };
    case CREATE_PROJECT_SETTINGS:
      return {
        ...state,
        project_type: action.project_settings,
      };
    case CREATE_PROJECT_USERS:
      return {
        ...state,
        users: action.users,
      };

    case CREATE_PROJECT_TEAMS:
      return {
        ...state,
        teams: action.teams,
      };
    case CREATE_PROJECT_SETTINGS_SITES:
      return {
        ...state,
        project_settings: {
          ...state.project_settings,
          centers: action.centers,
        },
      };
    case CREATE_PROJECT_SETTINGS_NOTIFICATIONS: {
      const { notifications } = state.project_settings;
      return {
        ...state,
        project_settings: {
          ...state.project_settings,
          notifications: { ...notifications, ...action.notifications },
        },
      };
    }
    case CREATE_PROJECT_SETTINGS_IMAGE_TYPES:
      return {
        ...state,
        project_settings: {
          ...state.project_settings,
          img_type: action.img_type,
        },
      };
    case CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE:
      return {
        ...state,
        project_settings: {
          ...state.project_settings,
          folder_structure: action.folder_structure,
        },
      };
    default:
      return state;
  }
}
