import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonFileUpload,
  ButtonReloadDelete,
  LoadingButton,
} from 'components';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { ServerErrorsString, GetData } from 'helpers';
import { IsInvalid } from 'helpers';
import { QueryPatientConsentSearch } from 'graphql/Anonymization';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Papa from 'papaparse';

const to_skip = ['Search Consent', 'Consent', 'Information', 'Updated'];
function parseCSV(csvText) {
  const result = Papa.parse(csvText, {
    header: true, // Treat the first row as headers
  });
  if (result.data && result.data.length > 0) {
    return result.data;
  }
  return [];
}
class ToolBarSearchConsents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searching: false,
      uploading: false,
    };
  }
  handleClearSelected = () => {
    const { selected, rows, rows_edited } = this.props;
    for (let i = 0; i < selected.length; i++) {
      const keys = Object.keys(selected[i]);
      const j = rows.map(x => x.id).indexOf(selected[i].id);
      const x = rows_edited.map(x => x.id).indexOf(selected[i].id);
      rows_edited.splice(x, 1);
      if (j === -1) continue;
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (key === 'id') continue;
        selected[i][key] = '';
        rows[j][key] = '';
      }
    }
    this.props.setState({ selected, rows, rows_edited });
  };
  getSearchParams = () => {
    const { rows_edited } = this.props;
    let list = [];
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      return list;
    }
    const keys = Object.keys(rows_edited[0]);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (key === 'id') continue;
      for (let i = 0; i < rows_edited.length; i++) {
        const key_value = rows_edited[i][key];
        if (i === 0) {
          if (key_value === '') break;
          if (to_skip.indexOf(key_value) !== -1) break;
          list.push({
            row: key,
            key: key_value,
            values: [],
          });
        } else if (key_value !== '') {
          const x = list.map(x => x.row).indexOf(key);
          if (x !== -1) {
            list[x].values.push(key_value);
          }
        }
      }
    }
    // console.log({ list });
    return list
      .filter(x => x.values.length)
      .map(x => ({ key: x.key, values: x.values }));
  };
  handleSearch = () => {
    const { searching } = this.state;
    if (searching) return;
    const inputs = this.getSearchParams();
    if (!inputs.length) {
      this.props.handleAddError('Empty params to search');
      return;
    }
    this.setState({ searching: true });
    (async () => {
      QueryPatientConsentSearch(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents } = data.searchPatientConsents;
          if (ok) {
            this.setState({ searching: false });
            this.props.handleAddPatientConsents(patient_consents, true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ searching: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleExport = () => {
    const { rows_edited } = this.props;
    const csv = Papa.unparse(rows_edited);
    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);
    const a = document.createElement('a');
    a.href = csvUrl;
    a.download = 'search_consents.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  handleLoadCSV = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    const reader = new FileReader();
    this.setState({ uploading: true });
    reader.onload = event => {
      const csvText = event.target.result;
      const data = parseCSV(csvText);
      // Process 'data' as needed (e.g., update your state).
      // console.log({ data });
      this.setState({ uploading: false });
      const total = data.length;
      if (total) {
        this.props.setState({ rows: data, total, rows_edited: data });
      } else {
        this.props.handleAddError('File is empty');
      }
    };
    reader.readAsText(files[0]);
  };
  render() {
    const { rows_edited, selected } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="save">
              <LoadingButton
                disabled={!rows_edited.length}
                loading={this.state.searching}
                onClick={this.handleSearch}
                variant="outlined">
                <SearchIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </LoadingButton>
              <Button onClick={this.props.handleClean}>
                <DeleteIcon style={{ color: 'red' }} />{' '}
              </Button>
              <ButtonFileUpload
                handleSelectFile={this.handleLoadCSV}
                uploading={this.state.uploading}
              />
              <Button onClick={this.handleExport}>
                <CloudDownloadIcon style={{ color: 'blue' }} />{' '}
              </Button>
            </ButtonGroup>
          </Grid>
          {/* <Grid item xs>
            <SearchText
              handleSearchText={this.handleSearchText}
              id="id-search-encrypted-patient"
              loading={this.props.loading}
              placeholder="Search Encrypted patient"
            />
          </Grid> */}
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={() => this.props.setState({ selected: [] })}
              handleDeleteSelected={this.handleClearSelected}
              hide_reload
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Clean selected rows"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ToolBarSearchConsents.propTypes = {
  classes: PropTypes.object,
};

export default ToolBarSearchConsents;
