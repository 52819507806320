import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { v1 as uuid } from 'uuid';

import { SearchInput } from 'components';
import { withStyles } from 'tss-react/mui';
import ButtonLoad from 'components/Buttons/ButtonLoad';
const useStyles = () => ({
  small: {
    paddingTop: 4,
    paddingBottom: 2,
  },
  normal: {
    paddingTop: 6,
    paddingBottom: 4,
  },
});
class SearchText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    const { holder } = props;
    if (holder) {
      holder.getSearchText = () => this.state.text;
      holder.cleanText = () => this.setState({ text: '' });
    }
  }

  onChange = event => {
    const text = event.target.value;
    this.setState({ text });
    this.props.onChange(text);
  };

  render() {
    const { classes, loading, size } = this.props;
    const { text } = this.state;
    return (
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={1}>
        <Grid item sm={8} xs={12}>
          <SearchInput
            className={size === 'small' ? classes.small : classes.normal}
            id={uuid()}
            onChange={this.onChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleSearchText(text);
              }
            }}
            placeholder={this.props.placeholder}
            size={size}
            value={text}
          />
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="space-between"
          sm={4}
          xs={12}>
          <ButtonLoad
            className={classes.search}
            component="span"
            handleClick={() => this.props.handleSearchText(text)}
            loading={loading}
            name="Search"
            size={size}
            variant="contained"
          />
        </Grid>
      </Grid>
    );
  }
}

SearchText.propTypes = {
  classes: PropTypes.object,
};
SearchText.defaultProps = {
  loading: false,
  handleSearchText: () => '',
  classes: {},
  placeholder: 'Search user',
  onChange: () => '',
  size: 'normal',
};
export default withStyles(SearchText, useStyles);
