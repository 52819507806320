export const columns_clinical_types = [
  {
    field: 'id',
    headerName: 'ID',
    align: 'left',
  },
  {
    field: 'code',
    headerName: 'Code',
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Clinical type',
    align: 'left',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
  },
];
