import { green } from '@mui/material/colors';
const useStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: -2,
    left: -1,
    zIndex: 1,
  },
};

export default useStyles;
