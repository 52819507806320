import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

class FromLastWeek extends Component {
  render() {
    const { classes, up, percentage, read } = this.props;
    if (read === 0) {
      return (
        <span className={classes.count_bottom}>
          <i className={classes.red}>Access blocked</i>
        </span>
      );
    }
    return (
      <span className={classes.count_bottom}>
        <i
          className={clsx({
            [classes.red]: !up && percentage,
            [classes.green]: up,
          })}>
          {up && percentage ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : percentage ? (
            <ArrowDropDownIcon fontSize="small" />
          ) : null}
          {`${percentage} %`}
        </i>
        {'   '}
        From last Week
      </span>
    );
  }
}

FromLastWeek.propTypes = {
  classes: PropTypes.object,
};

export default FromLastWeek;
