import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import {
  Cached as CachedIcon,
  CreateNewFolder as CreateNewFolderIcon,
} from '@mui/icons-material';
import { connect } from 'react-redux';

import {
  ButtonAddDownloadList,
  ButtonCleanCutCopy,
  ButtonEditMultiSeries,
  DialogEditMultiSeries,
  getNewItems,
  SwitchHomeShared,
  ToggleButtonItems,
  ToogleButtonExplorerView,
  ButtonAddBatchProcess,
} from './components';
import { IconButtonLoad, DialogCreateFolder, SortBy } from 'components';
import { IsNormalUser, IsUserClinicalTrial } from 'common';

const useStyles = () => ({
  grow: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    marginRight: 0,
    paddigRight: 0,
  },
});

class MiddleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_shared: false,
      open: false,
      open_multi_edit: false,
    };
  }

  handleOpenShared = status => {
    this.setState({ open_shared: status });
  };
  handleShareFolder = () => {
    this.handleOpenShared(false);
  };
  handleSeletedSortBy = select => {
    if (select === 'date') {
      select = 'updatedAt';
    } else if (select === 'size') {
      select = 'Size';
    }
    const { orderBy, order } = this.props;
    const isDesc = orderBy === select && order === 'desc';
    this.props.handleExplorerSortBy(isDesc ? 'asc' : 'desc', select);
  };
  handleCreatedFolder = folder => {
    const { ParentFolder } = this.props;
    if (ParentFolder === undefined) return;
    const index = ParentFolder.SubFolders.map(x => x.idFolder).indexOf(
      folder.idFolder
    );
    if (index === -1) {
      ParentFolder.SubFolders.push(folder);
      this.props.handleCurrentFolderChanged();
    }
  };

  render() {
    const {
      classes,
      selected_content,
      selected_folder,
      handleDeleteFolders,
      handleDrawerClose,
      handleShareFolder,
      base,
      cut_copy_objects,
      size,
    } = this.props;
    let { ParentFolder } = this.props;
    if (ParentFolder === undefined || !ParentFolder) {
      ParentFolder = {
        idFolder: -1,
        idParent: -1,
        name: base,
        Rights: [],
        SubFolders: [],
      };
    }
    const selected = selected_content.concat(selected_folder);
    const { open } = this.state;
    let CreateFolder = null;
    let AddList = null;
    let EditSeries = null;
    let BatchProcess = null;
    let isEnabled = true;
    if (IsUserClinicalTrial(this.props.user)) {
      isEnabled = false;
    }
    if (base !== 'Shared') {
      CreateFolder = (
        <Tooltip title="Create folder...">
          <ToggleButton
            aria-label="left aligned"
            disabled={!isEnabled}
            style={{
              padding: 0,
              paddingTop: 5,
              paddingLeft: 11,
              paddingRight: 11,
            }}
            value="createfolder">
            <span>
              <CreateNewFolderIcon
                onClick={() => this.setState({ open: true })}
              />
            </span>
          </ToggleButton>
        </Tooltip>
      );
      const newitems = getNewItems(selected, this.props.downloads);
      if (newitems.length && IsNormalUser(this.props.user)) {
        if (selected.filter(x => x.Series).length > 1) {
          EditSeries = (
            <ToggleButton
              aria-label="left aligned"
              style={{ padding: 0, paddingLeft: 11, paddingRight: 11 }}
              value="add-to-list">
              <ButtonEditMultiSeries
                onClick={() => this.setState({ open_multi_edit: true })}
                selected={selected}
              />
            </ToggleButton>
          );
        }
        if (
          selected.filter(x => x.Series).length >= 1 ||
          selected.filter(x => x.File).length >= 1
        ) {
          BatchProcess = (
            <ToggleButton
              aria-label="left aligned"
              style={{ padding: 0, paddingLeft: 1, paddingRight: 18 }}
              value="add-to-batch-process">
              <span>
                <ButtonAddBatchProcess selected={selected} />
              </span>
            </ToggleButton>
          );
        }
      }
    }
    if (selected && selected.length > 0 && IsNormalUser(this.props.user)) {
      AddList = (
        <ToggleButton
          aria-label="left aligned"
          style={{ padding: 0, paddingLeft: 11, paddingRight: 11 }}
          value="add-to-list">
          <ButtonAddDownloadList selected={selected} />
        </ToggleButton>
      );
    }
    return (
      <Toolbar
        className={classes.toolbarSecondary}
        component="nav"
        variant="dense">
        <DialogCreateFolder
          handleClose={() => this.setState({ open: false })}
          handleCreatedFolder={this.handleCreatedFolder}
          idParent={ParentFolder.idFolder}
          open={open}
        />
        <ToogleButtonExplorerView user={this.props.user} />
        <ToggleButtonGroup
          aria-label="text alignment"
          exclusive
          style={{ height: 40 }}>
          {CreateFolder}
          <ToggleButton
            aria-label="centered"
            style={{ padding: 0, paddingLeft: 11, paddingRight: 11 }}
            value="reload">
            <IconButtonLoad
              handleClick={this.props.handleRefresh}
              icon={<CachedIcon />}
              IconButtonProps={{
                color: 'inherit',
                style: { margin: 0, padding: 0 },
              }}
              id="file-reload"
              left_right
              small
              tooltip="Reload folder..."
            />
          </ToggleButton>
          <Tooltip title="Sort by...">
            <ToggleButton
              aria-label="justified"
              style={{ padding: 0, paddingLeft: 11, paddingRight: 11 }}
              value="sortby">
              <SortBy
                handleSeleted={this.handleSeletedSortBy}
                order={this.props.order}
              />
            </ToggleButton>
          </Tooltip>
          {AddList}
          {EditSeries}
          {BatchProcess}
        </ToggleButtonGroup>
        {size !== 0 && base === 'Home' && (
          <ButtonCleanCutCopy cut_copy_objects={cut_copy_objects} />
        )}
        <div className={classes.grow} />
        <ToggleButtonItems
          base={base}
          handleDeleteFolders={handleDeleteFolders}
          handleDrawerClose={handleDrawerClose}
          handleEditSelected={this.props.handleEditSelected}
          handleProgress={this.props.handleProgress}
          handleShareFolder={handleShareFolder}
          selected={selected}
          selected_content={selected_content}
          selected_folder={selected_folder}
          user={this.props.user}
        />
        <SwitchHomeShared
          handleSwitch={this.props.handleClickMenuItems}
          home_shared={base === 'Shared'}
        />
        <DialogEditMultiSeries
          direction="row"
          handleClose={() => this.setState({ open_multi_edit: false })}
          open={this.state.open_multi_edit}
          selected={selected}
        />
      </Toolbar>
    );
  }
}

MiddleBar.defaultProps = {
  selected_content: [],
  selected_folder: [],
  handleProgress: null,
  order: 'asc',
  orderBy: 'name',
};
MiddleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClickMenuItems: PropTypes.func,
  handleDeleteFolders: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  selected_content: PropTypes.array.isRequired,
  selected_folder: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const { cut, copy, copy_files, copy_series } = state.manager;
  const cut_copy_objects = cut
    .concat(copy)
    .concat(copy_series)
    .concat(copy_files);
  const size = cut_copy_objects.length;

  return {
    cut_copy_objects,
    size,
    downloads: state.items.downloads,
  };
};
export default connect(mapStateToProps, null)(withStyles(MiddleBar, useStyles));
