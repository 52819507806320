import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, MenuItem } from '@mui/material';
import { ButtonFilterList } from '../ButtonFilterSearch/components';
import TuneIcon from '@mui/icons-material/Tune';
import { AutoComboBox, TitleSort, MenuFilters } from 'components';
const options_study = [
  { name: 'Study description', id: 0 },
  { name: 'Patient Name', id: 1 },
  { name: 'Patient ID', id: 2 },
  { name: 'AccessionNumber', id: 3 },
];
const options_series = [
  { name: 'Series description', id: 0 },
  { name: 'Patient Name', id: 1 },
  { name: 'Patient ID', id: 2 },
];
class ButtonSettingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.holder = {
      setAutoComboBox: null,
    };
  }
  handleClick = event => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleHideColumn = () => {
    this.setState({ anchorEl: null });
  };
  handleReset = () => {
    const { search_type } = this.props;
    const options = this.getOptions();
    let selected = options[0];
    if (search_type) {
      const index = options.map(x => x.id).indexOf(search_type.id);
      if (index !== -1) selected = options[index];
    }
    console.log({ selected });
    this.props.handleSearchTypeChanged(null);
    this.holder.setAutoComboBox(selected);
  };
  handleSearchTypeChanged = value => {
    console.log({ value });
    let default_value = 'Study description';
    if (this.props.view === 'series') default_value = 'Series description';
    if (value === undefined || !value || value.name === default_value) {
      this.props.handleSearchTypeChanged(null);
      if (value === undefined || !value || value.name === '')
        this.handleReset();
    } else {
      this.props.handleSearchTypeChanged(value);
    }
  };
  getOptions = () => {
    const { view } = this.props;
    let options = [];
    if (view === 'series') {
      options = options_series;
    } else {
      options = options_study;
    }
    return options;
  };
  render() {
    const { search_type } = this.props;
    const options = this.getOptions();
    let selected = options[0];
    if (search_type) {
      const index = options.map(x => x.id).indexOf(search_type.id);
      if (index !== -1) selected = options[index];
    }
    // console.log({ view });
    let open = Boolean(this.state.anchorEl);
    let width = '100%';
    let maxWidth = 20;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end"
          style={{ maxWidth, padding: 0, margin: 0 }}>
          <Grid item style={{ width, padding: 0, margin: 0 }}>
            <ButtonFilterList
              ButtonIcon={TuneIcon}
              color_icon={search_type ? 'red' : null}
              handleAddError={this.props.handleAddError}
              handleClick={this.handleClick}
              open={open}
            />
          </Grid>
        </Grid>
        <MenuFilters
          anchorEl={this.state.anchorEl}
          anchorOrigin={null}
          arrow_position={105}
          handleClose={this.handleClose}
          left={7}
          open={open}
          transformOrigin={null}>
          <TitleSort style={{ paddingRight: 15 }} title="Main search type" />
          <Grid container sx={{ padding: 1 }}>
            <Grid item sx={{ paddingRight: 1, paddingLeft: 1 }} xs={12}>
              <AutoComboBox
                defaultValue={selected}
                holder={this.holder}
                label=""
                onObjectChanged={this.handleSearchTypeChanged}
                options={options}
                small
              />
            </Grid>
          </Grid>

          <Divider style={{ paddingBottom: 5 }} />
          <MenuItem
            onClick={this.handleReset}
            onKeyDown={e => e.stopPropagation()}>
            Reset
          </MenuItem>
        </MenuFilters>
      </React.Fragment>
    );
  }
}

ButtonSettingMenu.propTypes = {
  classes: PropTypes.object,
};

export default ButtonSettingMenu;
