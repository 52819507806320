import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTable, MainTableToolbar } from 'components/Tables/components';
import { columns, useStyles } from './components';
import { SelectedArray, rowsPerPageMedium } from 'helpers';

class TableDockerImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  setSelected = newSelected => {
    if (this.props.selected) {
      this.props.handleSetSelected(newSelected);
    } else {
      this.setState({ selected: newSelected });
    }
  };
  getSelected = () => {
    if (this.props.selected) {
      return this.props.selected;
    } else {
      return this.state.selected;
    }
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setSelected(this.props.images);
    } else {
      this.setSelected([]);
    }
  };

  handleSelectedClick = (event, i, image) => {
    event.preventDefault();
    const selected = this.getSelected();
    const index = selected.map(x => x.idImage).indexOf(image.idImage);
    const newSelected = SelectedArray(selected, image, index);
    this.setSelected(newSelected);
  };
  handleClearSelected = () => {
    this.setSelected([]);
  };
  IsSelected = image => {
    const selected = this.getSelected();
    const index = selected.map(x => x.idImage).indexOf(image.idImage);
    return index !== -1;
  };

  render() {
    const { classes, images, height, loading, total, selected } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="List of Docker Images"
        />
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            dense
            disable_splice={this.props.disable_splice}
            handleClickLink={this.props.handleClickImageLink}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.IsSelected}
            limit={this.props.limit}
            loading={loading}
            nowrap
            page={this.props.page}
            rows={images}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageMedium}
        />
      </Paper>
    );
  }
}

TableDockerImages.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  handleSetSelected: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  selected: PropTypes.array,
};
TableDockerImages.defaultProps = {
  disable_splice: true,
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  images: [],
  selected: [],
  total: 0,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: () => '',
  handleClickImageLink: () => '',
};
export default withStyles(TableDockerImages, useStyles);
