import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SERIES_FILES = gql`
  query($idSeries: LongLong!, $page: Int, $limit: Int) {
    seriesFiles(idSeries: $idSeries, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      files {
        idFile
        idSeries
        SliceLocation
        InstanceNumber
        Thumbnail
        MetaData
        Size
      }
    }
  }
`;
export const SeriesFilesQuery = (idSeries, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_FILES),
    variables: {
      idSeries,
      page,
      limit,
    },
  });
};
