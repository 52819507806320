import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SHARED = gql`
  query($idFolder: LongLong!, $level: Int!) {
    shared(idFolder: $idFolder, level: $level) {
      ok
      errors {
        path
        message
      }
      Folder {
        idFolder
        idParent
        Size
        name
        Owner {
          id
          FullName
        }
        folders_paths {
          idFolder
          name
        }
        ParentFolder {
          idParent
          name
          folders_paths {
            idFolder
            name
          }
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          idParent
          idUser
          name
          Size
          Owner {
            id
            FullName
          }
          folders_paths {
            idFolder
            name
          }
          Rights {
            idRight

            idFolder
            idFolderContent

            idUser
            idProject
            idCenterTeam
            idCenter

            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        Rights {
          idRight

          idFolder
          idFolderContent

          idUser
          idProject
          idCenterTeam
          idCenter

          User {
            id
            FullName
          }
          Project {
            id
            name
          }
          CenterTeam {
            id
            team {
              name
            }
          }
          Center {
            id
            name
          }
          write
          download
          delete
          read
          full
          createdAt
          updatedAt
        }
        Files {
          idFolderContent
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            Patient {
              PatientName
              PatientBirthDate
              PatientID
            }
            Study {
              StudyDescription
              StudyInstanceUID
              AccessionNumber
            }
            ImageSize {
              Size
            }
            ImageResolution {
              Spacing
            }
            SeriesDescription
            SeriesInstanceUID
            NumberOfSeriesRelatedInstances
            SeriesDate
            SeriesTime
            StationName
            Modality
            Thumbnail

            Size

            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight

            idFolder
            idFolderContent

            idUser
            idProject
            idCenterTeam
            idCenter

            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryShared = (idFolder, level) => {
  console.log('QueryShared', { idFolder, level });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SHARED),
    variables: {
      idFolder,
      level,
    },
  });
};
