import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { SnackMessage } from 'components';
import { CreateFolderMutation } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';
import { Error } from '@mui/icons-material';

const useStyles = makeStyles()(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    textTransform: 'none',
  },
}));

const DialogCreateFolder = props => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [folder_name, setFolderName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { idParent, base, show_button } = props;
  const { classes } = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = event => {
    if (!event) {
      return;
    }
    const { textContent } = event.target;
    if (textContent === '' || textContent === 'Cancel') {
      setError('Cancel by the user');
      setOpen(false);
      return;
    }
    if (folder_name === '') {
      setError('Empty folder name');
      setOpen(false);
      return;
    }
    handleRunCreateFolder();
  };
  const handleRunCreateFolder = () => {
    if (loading) return;
    setOpen(false);
    setLoading(true);
    (async () => {
      CreateFolderMutation(idParent, folder_name)
        .then(res => {
          const data = GetData(res);

          if (typeof data !== 'undefined') {
            const {
              createFolder: { ok, warnings, errors },
            } = data;
            if (ok) {
              setMessage('Folder created');
              props.handleRefresh();
            } else if (warnings) {
              setWarning(warnings);
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
          setLoading(false);
          setOpen(false);
        })
        .catch(error => {
          setLoading(false);
          setError(ServerErrorsString(error));
          setOpen(false);
        });
    })();
  };
  const handleSnaksClose = () => {
    setError('');
    setMessage('');
    setWarning('');
  };
  const handleChange = event => {
    setFolderName(event.target.value);
  };
  const keyPress = event => {
    if (event.keyCode === 13) {
      if (folder_name.length) {
        handleRunCreateFolder();
      } else {
        setError('Folder Name is empty');
      }
    }
  };
  return (
    <div>
      <SnackMessage
        handleClose={handleSnaksClose}
        message_text={error}
        open={error !== '' ? true : false}
        type="error"
      />
      <SnackMessage
        handleClose={handleSnaksClose}
        message_text={message}
        open={message !== '' && !loading ? true : false}
      />
      <SnackMessage
        handleClose={handleSnaksClose}
        message_text={warning}
        open={warning !== '' && !loading ? true : false}
        type="warning"
      />
      {base !== 'Shared' && show_button && (
        <Button
          className={classes.button}
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
          variant="outlined">
          New Folder
        </Button>
      )}
      <Dialog
        aria-labelledby="form-dialog-title"
        name="a"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new folder, please enter the name.
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            id="name"
            label="Folder Name"
            // type="email"
            margin="dense"
            onChange={handleChange}
            onKeyDown={keyPress}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" name="Cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" name="Create" onClick={handleClose}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
DialogCreateFolder.defaultProps = {
  show_button: true,
};
export default DialogCreateFolder;
