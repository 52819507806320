import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { DialogDeleteWarning, TableSeriesDetails } from 'components';

import { QuerySequences } from 'graphql/Series/sequences';
import { DeleteSequence } from 'graphql/Series';
import { GetData, SelectedArray, ServerErrorsString } from 'helpers';
import { EditSequences, TabSequenesHeader } from './components';

const useStyles = theme => ({
  root: {
    width: 'calc(100% - 16px)',
    height: '100%',
    margin: theme.spacing(1),
    padding: 0,
  },
});

class TabSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      deleting: false,
      loading: false,
      selected: [],
      sequences: [],
      page: 0,
      limit: 25,
      total: 0,
      edit: false,
      count_change: false,
      init: false,
    };
    this.holder = {
      getSearchText: null,
    };
  }
  componentDidMount() {
    this.querySequences();
  }
  componentDidUpdate() {
    if (!this.state.init) this.querySequences();
  }
  querySequences = (newName = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    if (!name && this.holder.getSearchText) {
      name = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QuerySequences(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, sequences, total } = data.Sequences;
          if (ok) {
            this.setState({
              sequences,
              loading: false,
              page,
              limit,
              total,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.querySequences(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.querySequences(null, 0, +event.target.value);
  };
  handleSelectClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected.map(x => x.name).indexOf(row.name);
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
  };
  handleSelectAllClick = event => {
    const { sequences } = this.state;
    if (event.target.checked) {
      this.setState({ selected: sequences });
    } else {
      this.setState({ selected: [] });
    }
  };
  isSelected = item => {
    const { selected } = this.state;
    return selected.map(x => x.name).indexOf(item.name) !== -1;
  };
  handleAddNewSequence = sequence => {
    let { sequences } = this.state;
    const index = sequences.map(x => x.idSequence).indexOf(sequence.idSequence);
    if (index === -1) {
      sequences.unshift(sequence);
      this.setState({ sequences });
    }
  };
  runDeleteSequence = () => {
    const { deleting, selected } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    (async () => {
      DeleteSequence(selected)
        .then(res => {
          const data = GetData(res);

          if (data && data.deleteSequence) {
            const { ok, errors } = data.deleteSequence;
            if (ok) {
              const { sequences } = this.state;
              this.setState({
                open_delete: false,
                deleting: false,
                selected: [],
                sequences: sequences.filter(
                  x =>
                    selected.map(x => x.idSequence).indexOf(x.idSequence) === -1
                ),
              });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown error');
          }
        })
        .catch(error => {
          this.setState({
            open_delete: false,
            deleting: false,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUpdateSequence = sequence => {
    let { selected, sequences, count_change } = this.state;
    let index = selected.map(x => x.idSequence).indexOf(sequence.idSequence);
    let count = 0;
    if (index !== -1) {
      selected[index] = { ...selected[index], ...sequence };
      count++;
    }
    index = sequences.map(x => x.idSequence).indexOf(sequence.idSequence);
    if (index !== -1) {
      sequences[index] = { ...sequences[index], ...sequence };
      count++;
    }
    this.setState({
      selected,
      sequences,
      count_change: count > 0 ? !count_change : count_change,
    });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };
  handelAddNewSequences = newSequences => {
    const { sequences } = this.state;
    for (let i = 0; i < newSequences.length; i++) {
      const { idSequence } = newSequences[i];
      const index = sequences.map(x => x.idSequence).indexOf(idSequence);
      if (index === -1) {
        sequences.unshift(newSequences[i]);
      }
    }
    this.setState({ sequences });
  };
  render() {
    const { classes } = this.props;
    const { edit, selected, sequences, loading } = this.state;
    if (edit) {
      return (
        <EditSequences
          handleAddError={this.props.handleAddError}
          handleOpenEditView={() => this.setState({ edit: !this.state.edit })}
          handleUpdateSequence={this.handleUpdateSequence}
          selected={selected}
        />
      );
    }
    let text = 'You are about to delete the selected';
    text += ` ${selected.length > 1 ? 'Sequences' : 'Sequence'}`;
    text +=
      ' this action will remove the Sequence Mappings and sequeneces assigned to some Series';
    return (
      <Grid container>
        <TabSequenesHeader
          addNewSequence={this.handleAddNewSequence}
          deleting={this.state.deleting}
          handelAddNewSequences={this.handelAddNewSequences}
          handleAddError={this.props.handleAddError}
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleOpenEditView={() => this.setState({ edit: !this.state.edit })}
          handleReload={() => this.querySequences()}
          handleSearchSequence={text => this.querySequences(text)}
          holder={this.holder}
          loading={loading}
          selected={selected}
        />
        <Paper className={classes.root}>
          <TableSeriesDetails
            disable_splice
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectClick={this.handleSelectClick}
            height={this.props.height - 100}
            isSelected={this.isSelected}
            limit={this.state.limit}
            loading={loading}
            page={this.state.page}
            rows={sequences}
            selected={selected}
            total={this.state.total}
          />
        </Paper>
        {/* </Grid> */}
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.runDeleteSequence}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={text}
          title_text="Delete Sequences"
        />
      </Grid>
    );
  }
}

TabSequences.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabSequences, useStyles);
