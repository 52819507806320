import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ContentImageType } from './components';
import { BadgeSmall } from '../BadgeSettings/BadgeSettings';

const useStyles = theme => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  to_format: {
    marginTop: theme.spacing(1),
  },
  calendar_format: {
    width: '130px',
  },
  check_container: {
    margin: 0,
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    padding: 0,
    '& .MuiTypography-root': {
      fontSize: 10,
      fontWeight: 500,
    },
  },
  summary: {
    display: 'flex',
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    height: 20,
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
    },
    margin: 0,
    padding: 0,
  },
  accordion: {
    '& .Mui-expanded': {
      margin: 0,
    },
    // maxHeight: 50,
  },
  form_label: {
    '& .MuiTypography-root': {
      fontSize: 12,
    },
  },
});

class GroupBoxImageType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesize: false,
      spacing: false,
      expanded: false,
      nifti: false,
    };
    this.holder = {
      getSpacing: null,
      getSize: null,
      getNifti: null,
    };
    if (
      typeof props.holder !== 'undefined' &&
      typeof props.holder.getImageType !== 'undefined'
    ) {
      props.holder.getImageType = () => {
        const { imagesize, spacing, nifti } = this.state;
        const { getSize, getSpacing, getNifti } = this.holder;
        const query = {};
        if (getSize && imagesize) {
          query.size = getSize();
        }
        if (getSpacing && spacing) {
          query.spacing = getSpacing();
        }
        if (getNifti && nifti) {
          query.nifti = getNifti();
        }
        return query;
      };
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    const { imagesize, spacing, nifti } = this.state;
    let count = 0;
    if (imagesize) count++;
    if (spacing) count++;
    if (nifti) count++;
    return (
      <Card className={classes.card}>
        <Accordion
          expanded={this.state.expanded}
          onChange={() => this.setState({ expanded: !this.state.expanded })}
          style={{ margin: 0, minHeight: 0 }}>
          <AccordionSummary
            aria-controls="panel-stuy-times-content"
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            id="panel-stuy-times-header">
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-start">
              <Grid item xs={8}>
                <CardHeader className={classes.title} title="Image Type" />
              </Grid>
              <div style={{ paddingLeft: 5 }}>
                <BadgeSmall
                  badgeContent={count}
                  style={{ backgroundColor: 'black' }}
                />
              </div>
            </Grid>
          </AccordionSummary>
          <Divider variant="middle" />
          <AccordionDetails>
            <ContentImageType
              classes={classes}
              handleChange={this.handleChange}
              holder={this.holder}
              imagesize={imagesize}
              nifti={nifti}
              spacing={spacing}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  }
}

GroupBoxImageType.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(GroupBoxImageType, useStyles);
