import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, CardContent, CardActions, Grid } from '@mui/material';
import { CreateCardHeader, DivGrow, ButtonCircularIconLoad } from 'components';
import TableSoftware from '../TableSoftware';
import ContentSoftware from '../ContentSoftware';
import { GetData, ServerErrorsString } from 'helpers';
import {
  MutationUpdateSoftwares,
  MutatioUploadSoftwareFile,
} from 'graphql/Software';
class EditSoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      software: null,
      to_update: [],
      saving: false,
      uploading: false,
      changed: false,
    };
    this.holder = {
      getSoftware: null,
      getFile: null,
      resetSoftware: null,
    };
  }
  updateSoftwares = () => {
    const { saving } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      MutationUpdateSoftwares(this.state.to_update)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, softwares } = data.updateSoftwares;
          if (ok) {
            this.setState({ saving: false, to_update: [] });
            this.props.handleUpdateSelected(softwares);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsChanged = (original, software) => {
    if (software.name !== original.name) return true;
    if (software.os !== original.os) return true;
    if (software.architecture !== original.architecture) return true;
    if (software.version !== original.version) return true;
    return false;
  };
  handleRowClicked = software => {
    const { to_update } = this.state;
    const { selected } = this.props;
    let pre_software = this.holder.getSoftware();
    if (pre_software) {
      let index = selected.map(x => x.id).indexOf(pre_software.id);
      if (index !== -1 && this.IsChanged(selected[index], pre_software)) {
        index = to_update.map(x => x.id).indexOf(pre_software.id);
        if (index === -1) {
          to_update.push(pre_software);
        } else {
          to_update[index] = {
            ...pre_software,
          };
        }
      }
      this.holder.resetSoftware();
    }
    this.setState({ software, to_update });
  };
  handleRowEditedChanged = to_update => {
    console.log({ to_update });
    this.setState({ to_update });
  };
  handleSelectFile = (software, file) => {
    const { uploading, to_update } = this.state;
    if (uploading) return;
    this.setState({ uploading: true });
    (async () => {
      MutatioUploadSoftwareFile(file, software.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.uploadSoftwareFile;
          if (ok) {
            software = {
              ...software,
              filename: file.name,
            };
            const index = to_update.map(x => x.id).indexOf(software.id);
            if (index !== -1) {
              to_update[index] = {
                ...software,
              };
            }
            this.setState({
              uploading: false,
              to_update,
            });
            this.props.handleNewSoftware(software);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    let { selected } = this.props;
    let { saving, uploading, changed, to_update, software } = this.state;
    if (software) {
      const index = selected.map(x => x.id).indexOf(software.id);
      if (index !== -1) {
        software = selected[index];
      }
    }
    // uploading = true;
    return (
      <Card>
        <CreateCardHeader
          description={
            'Update the necessary information for the software, by clicking each row'
          }
          handleClose={this.props.handleClose}
          title="Update Software"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <ContentSoftware
                handleAddError={this.props.handleAddError}
                handleSelectFile={this.handleSelectFile}
                holder={this.holder}
                onContentChanged={changed => this.setState({ changed })}
                saving={saving}
                software={software}
                uploading={uploading}
              />
            </Grid>
            <Grid item xs={12}>
              <TableSoftware
                edit
                onRowClicked={this.handleRowClicked}
                onRowEditedChanged={this.handleRowEditedChanged}
                rows={selected}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <DivGrow />
          <ButtonCircularIconLoad
            disabled={to_update.length ? false : true || !changed || uploading}
            handleButtonClick={() => this.updateSoftwares()}
            loading={saving || uploading}
            name="Save"
            transform={false}
            variant="outlined"
          />
        </CardActions>
      </Card>
    );
  }
}

EditSoftware.propTypes = {
  selected: PropTypes.array,
};
EditSoftware.defaultProps = {
  selected: [],
  handleUpdateSelected: () => '',
};

export default EditSoftware;
