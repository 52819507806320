export const styleTabPanels = theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  searchInput: {
    height: '42px',
    margin: theme.spacing(1),
  },
  searchButton: {
    height: '42px',
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
  },
});
