import React, { Component } from 'react';
import { Typography } from '@mui/material';

class SmallRedText extends Component {
  render() {
    const { text } = this.props;
    return (
      <Typography
        display="block"
        style={{
          fontSize: '0.5rem',
          marginTop: '-1px',
          marginButtom: '-1px',
          color: 'red',
        }}
        variant="caption">
        {text}
      </Typography>
    );
  }
}

export default SmallRedText;
