import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { ButtonLoading, Transition, AutoComboBox } from 'components';
import { withStyles } from 'tss-react/mui';
import { removeArrayDuplicates } from 'helpers';
import { MutationUpdateJobs } from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';
const useStyles = theme => ({
  title: {
    margin: 0,
    padding: 0,
    // marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(1.5),
    textTransform: 'none',
    fontWeight: 450,
  },
  middle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottom: {
    padding: 0,
    paddingTop: -2,
    margin: 0,
    marginBottom: theme.spacing(-3),
  },
  top_content: {
    minHeight: 180,
    '&.MuiDialogContent-root': {
      paddingBottom: 0,
    },
  },
  dialog_content: {
    padding: 0,
    margin: 0,
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
});
class DialogEditJobProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      status: undefined,
      job_name: undefined,
    };
  }
  IsChanged = () => {
    const { jobs } = this.props;
    if (jobs === undefined || !jobs.length) {
      return false;
    }
    let { status } = this.state;
    const jobs_status = removeArrayDuplicates(jobs.map(x => x.status));
    if (status === undefined) {
      return false;
    }
    if (jobs_status.length > 1) return true;
    return jobs_status[0].status !== status;
  };
  handleSaveJobSetting = () => {
    const { jobs } = this.props;
    if (jobs === undefined || !jobs.length) {
      return;
    }
    const { editing } = this.state;
    if (editing) return;
    this.setState({ editing: true });
    const inputs = [];
    jobs.forEach(job => {
      inputs.push({
        idJob: job.id,
        status: this.state.status,
      });
    });
    (async () => {
      MutationUpdateJobs(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateJobProcesses;
          if (ok) {
            jobs.forEach(job => {
              let new_job = {
                ...job,
              };
              if (this.state.status !== undefined) {
                new_job = {
                  ...new_job,
                  status: this.state.status,
                  batch: {
                    ...new_job.batch,
                    status: this.state.status,
                  },
                };
              }
              this.props.handleUpdateJobProcess(new_job);
            });
            this.setState({
              editing: false,
              job_name: undefined,
              status: undefined,
            });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            editing: false,
            job_name: undefined,
            status: undefined,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, open, jobs } = this.props;
    if (jobs === undefined || !jobs.length) {
      return null;
    }
    let default_status = null;
    let { status, job_name } = this.state;
    const jobs_status = removeArrayDuplicates(jobs.map(x => x.status));
    if (status === undefined) {
      if (jobs_status.length > 1) status = 'Multi-values...';
      else status = jobs_status[0];
      if (!default_status) default_status = status;
    }
    const jobs_names = removeArrayDuplicates(
      jobs
        .map(x => x.batch)
        .filter(x => x)
        .map(x => x.job_name)
    );
    if (job_name === undefined) {
      if (jobs_names.length > 1) job_name = 'Multi-values...';
      else job_name = jobs_names[0];
    }
    // console.log({ editing: this.state.editing });
    return (
      <Dialog
        aria-describedby="edit-job-dialog-description"
        aria-labelledby="edit-job-dialog-title"
        onClose={this.props.handleClose}
        open={open}
        TransitionComponent={Transition}>
        <DialogContent className={classes.top_content}>
          <Typography gutterBottom sx={{ textTransform: 'none' }} variant="h6">
            {`Edit Selected Jobs (${jobs.length})`}
          </Typography>
          <Divider />
          <Grid container spacing={1} sx={{ paddingTop: 2 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="job-name"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                label="Job Name"
                size="small"
                value={job_name}
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComboBox
                defaultValue={status}
                // disabled={!admin}
                getOptionLabel={option => option}
                label="Job status"
                onObjectChanged={status => {
                  if (!status) status = default_status;
                  this.setState({ status });
                }}
                options={['waiting', 'error', 'finished', 'Multi-values...']}
                small
                width={270}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <ButtonLoading
            disable_button={!this.IsChanged() || this.state.editing}
            handleClick={this.handleSaveJobSetting}
            loading={this.state.editing}
            name="Save"
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogEditJobProcess.propTypes = {
  classes: PropTypes.object,
};
DialogEditJobProcess.defaultProps = {
  handleAddError: () => '',
};
export default withStyles(DialogEditJobProcess, useStyles);
