import React from 'react';
import PropTypes from 'prop-types';
import TableGridFirstRow from './TableGridFirstRow';
import TableGridSecondRow from './TableGridSecondRow';
import { getCellValue } from 'components/Tables/TableGrid/TableGrid/utils_table_grid';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryTypesAtSeries } from 'graphql/Mapping';
class TableGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      internals: [],
      empty: false,
      page: 0,
      limit: 5,
    };
  }
  getSeriesID = () => {
    const { row } = this.props;
    if (row === undefined || !row) return -1;
    const { folder_content, idSeries } = row;
    if (idSeries) return idSeries;
    if (folder_content === undefined || !folder_content) return -1;
    const { Series } = folder_content;
    if (Series === undefined || !Series) return -1;
    return Series.idSeries;
  };
  handleOnClickExpandRow = new_open => {
    const { loading, empty, internals } = this.state;
    if (loading) return;
    const idSeries = this.getSeriesID();
    if (!new_open || internals.length || idSeries < -1 || empty) {
      this.setState({ open: new_open });
      return;
    }
    this.updateMapping(new_open);
  };
  updateMapping = new_open => {
    let { loading, open } = this.state;
    const idSeries = this.getSeriesID();
    if (loading || idSeries < -1) return;
    if (new_open !== undefined) {
      open = new_open;
    }
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryTypesAtSeries(idSeries, 0, 5)
        .then(res => {
          const data = GetData(res);
          console.log({ data });
          const { ok, errors, mappings, total } = data.typesAtSeries;
          console.log({ ok, open });
          if (ok) {
            this.setState({
              loading: false,
              internals: mappings,
              total,
              open,
              empty: !mappings.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  updateTableMappings = (internals, removed) => {
    let { total } = this.state;
    total -= removed;
    if (total < 0) total = 0;
    this.setState({ internals, total });
  };
  render() {
    const { open, loading } = this.state;
    const { headers, row } = this.props;
    return (
      <React.Fragment>
        <TableGridFirstRow
          checkboxSelection={this.props.checkboxSelection}
          getCellValue={getCellValue}
          handleAddError={this.props.handleAddError}
          handleRowClicked={this.props.handleRowClicked}
          headers={headers}
          isItemChecked={this.props.isItemChecked}
          isRowClicked={() => this.props.IsRowClicked(row)}
          isRowSelected={this.props.isRowSelected}
          loading={loading}
          onCheckBoxItemClicked={this.props.onCheckBoxItemClicked}
          onClickExpandRow={this.handleOnClickExpandRow}
          onRowEdited={this.props.handleRowEdited}
          open={open}
          row={row}
          row_original={this.props.row_original}
        />
        <TableGridSecondRow
          handleReloadTable={() => this.updateMapping()}
          headers={headers}
          limit={this.state.limit}
          mappings={this.state.internals}
          open={open}
          page={this.state.page}
          total={this.state.total}
          updateMappings={this.updateTableMappings}
        />
      </React.Fragment>
    );
  }
}

TableGridRow.propTypes = {
  headers: PropTypes.array,
};
TableGridRow.defaultProps = {
  headers: [],
  checkboxSelection: false,
  cells: [],
  onCheckBoxItemClicked: () => '',
  onCellDoubleClick: () => '',
  isItemChecked: false,
  isRowSelected: false,
  isRowClicked: false,
  handleRowClicked: () => '',
  onRowEdited: () => '',
  handleAddError: error => console.log({ error }),
};

export default TableGridRow;
