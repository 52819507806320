import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { IconButtonLoad } from 'components';
import { MutationUpdateProjectVariableTypes } from 'graphql/Projects/utils_project_types';
import { ServerErrorsString, GetData } from 'helpers';
class GridEditedRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
    };
  }
  getRowEdited = () => {
    let { rows_edited } = this.props;
    rows_edited = rows_edited.filter(x => x.id > 0);
    if (!rows_edited.length) return null;
    return rows_edited;
  };
  handleSaveNewTypes = () => {
    const { updating } = this.state;
    if (updating) return;
    const { types, idProject } = this.props;
    const rows_edited = this.getRowEdited();
    if (!rows_edited) return;
    this.setState({ updating: true });
    (async () => {
      const edited = rows_edited.map(x => {
        const { idType, level } = x;
        return {
          idType,
          level: parseInt(level, 10),
        };
      });
      MutationUpdateProjectVariableTypes(idProject, edited)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateProjectVariableTypes;
          if (ok) {
            const updated_types = types.map(x => {
              const index = edited.map(x => x.idType).indexOf(x.idType);
              let level = x.level;
              if (index !== -1) {
                level = edited[index].level;
              }
              return {
                ...x,
                level,
              };
            });
            this.setState({ updating: false });
            this.props.updateStates({
              idProject,
              types: updated_types,
              rows_edited: this.props.rows_edited.filter(x => x.id < 0),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ idProject, loading: false });
        });
    })();
  };
  render() {
    const rows_edited = this.getRowEdited();
    if (!rows_edited) return null;
    const { updating } = this.state;
    return (
      <Grid container item style={{ paddingTop: 10, paddingRight: 5 }} xs={12}>
        <Grid item xs={11}>
          <Typography
            component="div"
            gutterBottom
            style={{ margin: 0, padding: 0, paddingTop: 10 }}
            variant="h6">
            {`Types edited: ${rows_edited.length}`}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButtonLoad
            disabled={updating || !rows_edited.length}
            handleClick={this.handleSaveNewTypes}
            icon={
              <SaveIcon
                style={{ color: rows_edited.length ? 'green' : 'disabled' }}
              />
            }
            tooltip="Save"
          />
        </Grid>
      </Grid>
    );
  }
}

GridEditedRows.propTypes = {
  classes: PropTypes.object,
};
GridEditedRows.defaultProps = {
  rows_edited: [],
};

export default GridEditedRows;
