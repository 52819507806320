import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogContent,
  Divider,
  Button,
  Box,
} from '@mui/material';
import { CardEditHeader } from 'components/Cards';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from 'tss-react/mui';
import { PatientForm } from '../DialogAddViewEpilepsyCase/components/AddEpilepsyStepper/components';
import { header_patients } from 'views/DataClinics/components/PatientClinics/components';
import { GetData, ServerErrorsString } from 'helpers';
import { ButtonLoad } from 'components/Buttons';
import { MutationAddClinicalPatients } from 'graphql/DataClinics';
import SnackMessage from 'components/SnackMessage';
import { formatInput, initFields } from 'helpers/DataClinics';

const useStyles = () => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

class DialogViewClinicalPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: '',
      patient_form: [],
    };
  }

  componentDidMount() {
    const patient_form = initFields(header_patients);

    this.setState({
      patient_form,
    });
  }
  MutationCreatePatient = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const { patient_form } = this.state;
    let inputs = {};
    inputs = formatInput(patient_form, inputs, '');
    inputs.PatientAge = inputs.PatientAge.toString();
    inputs.PatientSize = inputs.PatientSize.toString();
    inputs.PatientWeight = inputs.PatientWeight.toString();
    (async () => {
      MutationAddClinicalPatients(inputs, 'epilepsy')
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients } = data.addPatientsClinics;
          if (ok) {
            const success_str = `Patient ${patients[0].PatientName} successfully created`;
            this.setState({ success: success_str });
            this.resetPatient();
          } else {
            throw errors;
          }
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleClose = () => {
    this.props.handleClose();
  };
  handleUpdatePatientForm = form => {
    let { patient_form } = this.state;
    for (let i = 0; i < patient_form.length; i++) {
      const index = form.findIndex(
        item => item.field === patient_form[i].field
      );
      patient_form[i].value = form[index].value;
    }
    this.setState({ patient_form });
  };
  resetPatient = () => {
    const patient_form = initFields(header_patients);

    this.setState({
      patient_form,
    });
  };
  render() {
    const { open } = this.props;
    const { loading, success, patient_form } = this.state;

    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={
            'Follow the guided procedure to create a new clinical patient'
          }
          handleClose={this.handleClose}
          icon={<AddIcon style={{ color: 'green' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              Create new Clinical Patient
            </Typography>
          }
        />
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <PatientForm
              handleAddError={this.props.handleAddError}
              handleUpdatePatientForm={patient_form =>
                this.handleUpdatePatientForm(patient_form)
              }
              patient_form={patient_form}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={this.resetPatient}>Reset</Button>
              <ButtonLoad
                color="primary"
                handleClick={this.MutationCreatePatient}
                loading={loading}
                name="CREATE"
                variant="contained"
              />
            </Box>
            <SnackMessage
              handleClose={() => this.setState({ success: '' })}
              message_text={success !== '' ? success : 'Unknown warning'}
              open={success && success !== '' ? true : false}
              type="success"
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogViewClinicalPatient.propTypes = {
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogViewClinicalPatient.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  open: false,
};

export default withStyles(DialogViewClinicalPatient, useStyles);
