import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { QuerySeriesThumbnails } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';
import { ViewSeriesList, ViewSeriesGrid } from './components';

class ViewDatabaseSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      loading: false,
      sequence: null,
      Total: props.limit ? props.limit : 5,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getSeries = idSeries => {
        const index = this.state.rows.map(x => x.idSeries).indexOf(idSeries);
        if (index < 0 || index >= this.state.rows.length) {
          return null;
        } else {
          return this.state.rows[index];
        }
      };
      holder.runQuerySeries = page_limit => {
        this.handleRun(page_limit);
      };
    }
  }

  componentDidMount() {
    this.handleRun({});
  }

  componentDidUpdate() {
    const { sequence } = this.state;
    const current = {
      current_page: this.props.page,
      current_limit: this.props.limit,
    };

    if (this.props.sequence && !sequence) {
      this.handleRun(current);
    } else if (
      this.props.sequence &&
      sequence &&
      this.props.sequence.idSequence !== sequence.idSequence
    ) {
      this.handleRun(current);
    } else if (!this.props.sequence && sequence) {
      this.handleRun(current);
    }
  }

  handleRun = current => {
    let { description, current_page, current_limit } = current;
    let { page, limit, index_level, search_type } = this.props;
    const { rows, loading } = this.state;
    if (loading) return;
    if (
      typeof current_limit === 'undefined' &&
      typeof current_page === 'undefined'
    ) {
      if (rows.length !== 0 || loading) {
        return;
      }
    }
    if (current_page) {
      page = current_page;
    }
    if (current_limit) {
      limit = current_limit;
    }
    this.setState({ loading: true });
    if (this.props.holder.setLoading) {
      this.props.holder.setLoading(true);
    }
    let id_sequences = null;
    const { sequence } = this.props;
    if (sequence) {
      id_sequences = [sequence.idSequence];
    }
    const setting = {
      level: index_level,
      id_sequences,
      page,
      limit,
    };
    const { filters } = this.props;
    if (filters) {
      if (filters.idUser) setting.idUser = filters.idUser;
      if (filters.idProject) setting.id_projects = [filters.idProject];
      if (filters.SeriesDate) setting.SeriesDate = filters.SeriesDate;
      if (filters.nifti) setting.nifti = filters.nifti;
      if (filters.modalities) setting.modalities = filters.modalities;
      if (filters.sequence) {
        const seqs = filters.sequence;
        if (Array.isArray(seqs)) {
          setting.id_sequences = seqs.map(x => x.idSequence).filter(x => x);
        } else if (typeof seqs === 'object' && seqs.idSequence) {
          setting.id_sequences = [seqs.idSequence];
        }
      }
    }
    if (search_type) {
      if (search_type.name === 'Patient Name') {
        setting.search_type = {
          PatientName: description,
        };
      }
      if (search_type.name === 'Patient ID') {
        setting.search_type = {
          PatientID: description,
        };
      }
      description = null;
    }
    (async () => {
      QuerySeriesThumbnails(description, setting)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, Series, Total } = data.Series;
          if (ok) {
            this.setState({
              loading: false,
              rows: Series,
              Total,
              sequence: this.props.sequence,
            });
            this.props.holder.setTotal(Total);
            if (this.props.holder.setLoading) {
              this.props.holder.setLoading(false);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, sequence: this.props.sequence });
          this.props.handleAddError(ServerErrorsString(error));
          if (this.props.holder.setLoading) {
            this.props.holder.setLoading(false);
          }
        });
    })();
  };
  handleSeriesDeleted = series => {
    const { idSeries } = series;
    if (typeof idSeries === 'undefined') {
      return;
    }
    let { rows } = this.state;
    const index = rows.map(x => x.idSeries).indexOf(idSeries);
    if (index !== -1) {
      rows.splice(index, 1);
      this.setState({ rows });
    } else {
      /** empty */
    }
  };

  render() {
    const { rows, loading } = this.state;
    const { display_database, height } = this.props;
    // console.log({ display_database });
    if (display_database === 'list') {
      return (
        <ViewSeriesList
          handleAddError={this.props.handleAddError}
          height={height}
          limit={this.props.limit}
          loading={loading}
          page={this.props.page}
          series={rows}
          total={this.state.Total}
        />
      );
    } else {
      return (
        <ViewSeriesGrid
          handleAddError={this.props.handleAddError}
          handleSeriesDeleted={this.handleSeriesDeleted}
          height={height}
          loading={loading}
          rows={rows}
          user={this.props.user}
        />
      );
    }
  }
}

ViewDatabaseSeries.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { sequence, setting } = state.page_database;
  let index_level = 0;
  if (setting && setting.level) {
    index_level = setting.level.index_level;
  }
  let idProject = null;
  if (setting && setting.project) {
    idProject = setting.project.id;
  }
  let idSequence = null;
  if (sequence) {
    idSequence = sequence.idSequence;
  }
  return {
    index_level,
    idSequence,
    idProject,
    sequence,
  };
};

export default connect(mapStateToProps)(ViewDatabaseSeries);
