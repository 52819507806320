import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_DOCKER_SETTINGS = gql`
  query {
    dockerMappingSettings {
      ok
      errors {
        path
        message
      }
      docker_mapping_settings {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const QueryDockerSettings = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_SETTINGS),
  });
};

export const QUERY_DOCKER_SETTING = gql`
  query($id: LongLong!) {
    dockerMappingSetting(id: $id) {
      ok
      errors {
        path
        message
      }
      docker_mapping_setting {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const QueryDockerSetting = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_SETTING),
    variables: {
      id,
    },
  });
};

export const QUERY_DOCKER_MAPPING_SETTING_BY_IMAGE_SETTING_ID = gql`
  query($idImageSetting: LongLong!) {
    dockerMappingSettingByImageSettingId(idImageSetting: $idImageSetting) {
      ok
      errors {
        path
        message
      }
      docker_mapping_setting {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const QueryDockerMappingSettingByImageSettingId = idImageSetting => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPING_SETTING_BY_IMAGE_SETTING_ID),
    variables: {
      idImageSetting,
    },
  });
};

export const QUERY_DOCKER_MAPPING_SETTING_BY_OUTPUT_ID = gql`
  query($idOutput: LongLong!) {
    dockerMappingSettingByOutputId(idOutput: $idOutput) {
      ok
      errors {
        path
        message
      }
      docker_mapping_setting {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const QueryDockerMappingSettingByOutputId = idOutput => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPING_SETTING_BY_OUTPUT_ID),
    variables: {
      idOutput,
    },
  });
};

export const QUERY_DOCKER_MAPPING_SETTING_BY_IMAGE_ID = gql`
  query($idImage: LongLong!) {
    dockerMappingSettingByImageId(idImage: $idImage) {
      ok
      errors {
        path
        message
      }
      docker_mapping_setting {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const QueryDockerMappingSettingByImageId = idImage => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_MAPPING_SETTING_BY_IMAGE_ID),
    variables: {
      idImage,
    },
  });
};

export const MUTATION_CREATE_MAPPING_SETTING = gql`
  mutation($inputs: [NewMappingSettings!]) {
    createDockerMappingSetting(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      inserted {
        id
        table_name
        table_column
        action
        docker_image {
          idImage
          name
        }
        docker_mapping_table {
          id
          table_map_name
          table_name_input
          table_name_output
          id_table_input
          id_table_output
        }
        docker_variable_types {
          id
          type
          name
          value
        }
        input {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        output {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
      }
    }
  }
`;
export const MutationCreateMappingSettings = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_MAPPING_SETTING),
    variables: {
      inputs,
    },
  });
};

export const MUTATION_UPDATE_MAPPING_SETTINGS = gql`
  mutation($inputs: [UpdateMappingSetting!]) {
    updateDockerMappingSetting(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateMappingSettings = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_MAPPING_SETTINGS),
    variables: {
      inputs,
    },
  });
};

// export const MUTATION_REMOVE_MAPPING_TABLES = gql`
//   mutation($mappingTableIds: [LongLong!]) {
//     removeMappingTables(mappingTableIds: $mappingTableIds) {
//       ok
//       errors {
//         path
//         message
//       }
//     }
//   }
// `;
// export const MutationRemoveMappingTable = mappingTableIds => {
//   return axios.post(HTTP_ADDRESS_GRAPHQL, {
//     query: print(MUTATION_REMOVE_MAPPING_TABLES),
//     variables: {
//       mappingTableIds,
//     },
//   });
// };
