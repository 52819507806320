export const dataBase64ToFile = async (dataUrl, fileName, mimeType) => {
  const buff = Buffer.from(dataUrl, 'base64');
  return new File([buff], fileName, { type: mimeType });
};
export const getPureBase64 = preview => {
  let result = { image: null, filename: null, extension: null };
  if (!preview.startsWith('data:')) return result;
  const parts = preview.split(';base64,');
  //
  if (parts.length < 2) return result;
  const second = parts[0].split(':'); // data:image/png
  //
  if (second.length !== 2) return null;
  const mimetype = second[1];
  result.extension = mimetype.split('/')[1];
  result.filename = 'avatar.' + result.extension;
  result.image = parts[1];
  return result;
};

/**
 * Resize a base 64 Image
 * @param {String} base64Str - The base64 string (must include MIME type)
 * @param {Number} MAX_WIDTH - The width of the image in pixels
 * @param {Number} MAX_HEIGHT - The height of the image in pixels
 */
export const reduce_image_file_size = async (
  base64Str,
  MAX_WIDTH = 450,
  MAX_HEIGHT = 450
) => {
  if (
    !base64Str ||
    base64Str === undefined ||
    base64Str.startsWith('/images/')
  ) {
    return base64Str;
  }
  let resized_base64 = await new Promise(resolve => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
      //
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      //
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
};
export const calc_image_size = imageBase64 => {
  if (
    !imageBase64 ||
    imageBase64 === undefined ||
    imageBase64.constructor !== String
  ) {
    return 0;
  }
  let y = 1;
  if (imageBase64.endsWith('==')) {
    y = 2;
  }
  const x_size = imageBase64.length * (3 / 4) - y;
  return Math.round(x_size / 1024);
};
export const image_to_base64 = async file => {
  let result_base64 = await new Promise(resolve => {
    let fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = () => {
      alert('An Error occurred please try again, File might be corrupt');
    };
    fileReader.readAsDataURL(file);
  });
  return result_base64;
};
