import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

class TableCellHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      orderBy,
      headCell,
      order,
      name,
      createSortHandler,
      classes,
      ...res
    } = this.props;
    return (
      <TableCell {...res} sortDirection={orderBy === headCell ? order : false}>
        <TableSortLabel
          active={orderBy === headCell}
          direction={orderBy === headCell ? order : 'asc'}
          onClick={() => createSortHandler(headCell)}>
          {name}
          {orderBy === headCell ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }
}

TableCellHeader.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TableCellHeader, useStyles);
