import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { inputProps } from 'common';
import { IsInvalid } from 'helpers';
import validate from 'validate.js';
const schema_email = {
  text: {
    presence: false,
    email: true,
    length: {
      maximum: 64,
    },
  },
};
class ProjectEmails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      options: [],
    };
  }
  handleChange = (event, value) => {
    if (IsInvalid(event)) return;
    this.setState({ value });
  };
  handleAddEmail = () => {
    let { value } = this.state;
    if (value === '') return;
    const validation = validate({ text: value }, schema_email);
    if (validation) {
      this.props.handleAddError(validation.text.join('\n-'));
      return;
    }
    const { secondary_emails } = this.props;
    if (secondary_emails) {
      const options = secondary_emails ? secondary_emails.split(',') : [];
      if (options.indexOf(value) !== -1) {
        this.props.handleAddError(`Email: ${value} is aready here`);
        return;
      }
      value = `${secondary_emails}, ${value}`;
    }
    this.setState({ value: '' });
    this.props.handleSecondaryEmail(value);
  };
  render() {
    const { email, secondary_emails } = this.props;
    const options = secondary_emails ? secondary_emails.split(',') : [];
    // console.log({ options, value: this.state.value });
    let others = null;
    if (!options.length) {
      others = {
        startAdornment: (
          <InputAdornment position="start">
            <AlternateEmailIcon />
          </InputAdornment>
        ),
      };
    }
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="project-email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
              ),
              ...inputProps,
            }}
            label="Project Email"
            onChange={this.props.handleChange('email')}
            placeholder="Write the primary email address"
            value={email ? email : ''}
          />
        </Grid>
        <Grid item style={{ paddingTop: 10, paddingBottom: 10 }} xs={12}>
          <Autocomplete
            id="autocomplete"
            inputValue={this.state.value}
            multiple
            onChange={(event, options) => {
              this.props.handleSecondaryEmail(options.join(','));
            }}
            onInputChange={(event, value) => {
              if (IsInvalid(event)) return;
              this.setState({ value });
            }}
            open={false}
            options={options}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                  ...others,
                }}
                label="Secondary emails"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (this.state.value === '') return;
                    this.handleAddEmail();
                  }
                }}
                placeholder="To add emails write and press enter"
                variant="outlined"
              />
            )}
            renderTags={(value, getTagProps) => (
              <React.Fragment>
                <InputAdornment position="start">
                  <AlternateEmailIcon />
                </InputAdornment>
                {value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))}
              </React.Fragment>
            )}
            value={options}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

ProjectEmails.propTypes = {
  email: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default ProjectEmails;
