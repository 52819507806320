import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import {
  columns,
  TableContainersMain,
  TableContainersToolbar,
  useStyles,
} from './components';
import { SelectedArray, rowsPerPageMedium } from 'helpers';
import { withStyles } from 'tss-react/mui';

class TableContainers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  setSelected = newSelected => {
    if (this.props.selected) {
      this.props.handleSetSelected(newSelected);
    } else {
      this.setState({ selected: newSelected });
    }
  };
  getSelected = () => {
    if (this.props.selected) {
      return this.props.selected;
    } else {
      return this.state.selected;
    }
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setSelected(this.props.containers);
    } else {
      this.setSelected([]);
    }
  };

  handleSelectedClick = container => {
    const selected = this.getSelected();
    const index = selected
      .map(x => x.idContainer)
      .indexOf(container.idContainer);
    const newSelected = SelectedArray(selected, container, index);
    this.setSelected(newSelected);
  };
  handleClearSelected = () => {
    this.setSelected([]);
  };

  render() {
    const {
      classes,
      containers,
      height,
      loading,
      total,
      selected,
    } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <TableContainersToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="List Containers"
        />
        <div className={classes.tableWrapper} style={style}>
          <TableContainersMain
            columns={columns}
            containers={containers}
            dense
            disable_splice={this.props.disable_splice}
            handleClickContainer={this.props.handleClickContainer}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            limit={this.props.limit}
            loading={loading}
            page={this.props.page}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageMedium}
        />
      </Paper>
    );
  }
}

TableContainers.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  handleSetSelected: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  selected: PropTypes.array,
};
TableContainers.defaultProps = {
  disable_splice: true,
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  rows: [],
  selected: [],
  total: 0,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: () => '',
  handleClickContainer: () => '',
};
export default withStyles(TableContainers, useStyles);
