import { LineWebGL } from './SingleObjects';
class DrawWebGlLines {
  constructor(gl) {
    this.gl = gl;
    this.currentLine = null;
    this.lines = [];
  }
  moveClick(point3d, action) {
    if (!point3d || point3d === undefined) return;
    if (action !== 'down') return;
    if (this.currentLine) {
      this.currentLine.setVertice(1, point3d);
      this.lines.push(this.currentLine);
      this.currentLine = null;
    } else {
      this.currentLine = new LineWebGL(this.gl);
      const vertices = point3d.toArray().concat(point3d.toArray());
      this.currentLine.setVertices(vertices, [0, 255, 0], 2);
    }
  }
  moveMove(point3d) {
    if (!point3d || point3d === undefined) return;
    if (this.currentLine) {
      this.currentLine.setVertice(1, point3d);
    }
  }
  draw(viewProjectionMatrix) {
    if (this.currentLine) {
      this.currentLine.draw(viewProjectionMatrix);
    }
    for (let i = 0; i < this.lines.length; i++) {
      this.lines[i].draw(viewProjectionMatrix);
    }
  }
  clean() {
    this.currentLine = null;
    this.lines = [];
  }
}
export default DrawWebGlLines;
