import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TabAnonymization,
  TabInputData,
  TabBatchProcess,
  TabAnalysis,
} from './components';
import { getLevels } from 'helpers';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components';
import { styleTabPanels } from 'common';
import { IsAdministrator } from 'helpers';

class Pipelines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'batch_process',
      tabs: [
        { name: 'Input Data', TabComponent: TabInputData, key: 'input_data' },
        {
          name: 'Batch Process',
          TabComponent: TabBatchProcess,
          key: 'batch_process',
        },
      ],
      tabValue: 0,
    };
  }
  componentDidMount() {
    const { user } = this.props;
    const { tabs } = this.state;
    const index = tabs.map(x => x.key).indexOf('anonymization');
    if (user && user.id === 1 && index === -1) {
      tabs.push({
        name: 'Anonymization',
        TabComponent: TabAnonymization,
        key: 'anonymization',
      });
      tabs.push({
        name: 'Analysis',
        TabComponent: TabAnalysis,
        key: 'analysis',
      });
    }
    let { level_1 } = getLevels(this.props);
    let tabValue = tabs.map(x => x.key).indexOf(level_1);
    if (tabValue < 0) {
      tabValue = 0;
      level_1 = 'input_data';
    }
    if (level_1 && this.state.tab !== level_1) {
      this.setState({ tab: level_1, tabs, tabValue });
    }
  }
  componentDidUpdate() {
    let { level_1 } = getLevels(this.props);
    if (level_1 === undefined || !level_1) {
      level_1 = 'input_data';
    }
    let tabValue = this.state.tabs.map(x => x.key).indexOf(level_1);
    if (tabValue < 0) {
      tabValue = 0;
      level_1 = 'input_data';
    }
    if (level_1 !== this.state.tab || tabValue !== this.state.tabValue) {
      this.setState({ tab: level_1, tabValue });
    }
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    const { history, user } = this.props;
    this.setState({ tabValue });
    if (tabValue === 1) {
      history.push('/pipelines/batch_process');
    } else if (tabValue === 2 && IsAdministrator(user)) {
      history.push('/pipelines/anonymization');
    } else if (tabValue === 3 && IsAdministrator(user)) {
      history.push('/pipelines/analysis');
    } else {
      history.push('/pipelines/input_data');
    }
  };
  render() {
    const { classes } = this.props;
    const tab_value = parseInt(this.state.tabValue, 10);
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tab_value}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              return <Tab key={`analysis-tab-${index}`} label={tab.name} />;
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`analysis-tab-panel-${index}`}
                  value={tab_value}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    handleAddWarning={this.props.handleAddWarning}
                    handleTabChanged={tabValue =>
                      this.handleTabValue(
                        { preventDefault: () => '' },
                        tabValue
                      )
                    }
                    height={this.props.height}
                    history={this.props.history}
                    isActive={index === tab_value}
                    isDesktop={this.props.isDesktop}
                    match={this.props.match}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Pipelines.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    total_series: copy_series ? copy_series.length : 0,
    copy_files: copy_files ? copy_files : [],
    total_files: copy_files ? copy_files.length : 0,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(withRouter(Pipelines), styleTabPanels));
