import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import { updateAccountDetails } from 'redux-store/actions/userActions';

import { CardHeaderCustom, Countries } from 'components';
import { UserMobile } from './components';

const useStyles = makeStyles()(() => ({
  root: {},
}));

const AccountDetails = props => {
  const { className, user } = props;
  const { classes } = useStyles();

  const [currentUser, setValues] = useState(user);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    //
    setValues(user);
  }, [user]);

  const handleChange = event => {
    // console.log({ city: event.target.value });
    if (event.target.name === 'city' || event.target.name === 'country') {
      currentUser.address = {
        ...currentUser.address,
        [event.target.name]: event.target.value,
      };
    }
    setValues({
      ...currentUser,
      [event.target.name]: event.target.value,
    });
    setTouched(true);
  };
  const handleCountryChanged = selected => {
    // console.log({ selected });
    if (!selected) {
      return;
    }
    setValues({
      ...currentUser,
      address: {
        ...currentUser.address,
        country: selected.name,
      },
      country: selected.name,
    });
    setTouched(true);
  };
  const saveDetails = () => {
    // console.log('Component - saveDetails - AccountDetails: ', currentUser);
    props.updateAccountDetails(currentUser);
    setTouched(false);
  };
  //
  return (
    <Card
      // {...rest}
      className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeaderCustom
          subheader="The information can be edited"
          title="Profile Details"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={currentUser.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first last name"
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={currentUser.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                value={currentUser.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <UserMobile
                external
                handlePhoneChange={phone =>
                  handleChange({ target: { value: phone, name: 'phone' } })
                }
                phone={currentUser.phone}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Countries
                country={currentUser.country}
                defaultCountry={user.country ? user.country : ''}
                handleCountryChanged={handleCountryChanged}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                placeholder="Select your city"
                size="small"
                value={currentUser.city}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!touched}
            onClick={saveDetails}
            variant="contained">
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
  updateAccountDetails: PropTypes.func.isRequired,
};

export default connect(null, { updateAccountDetails })(AccountDetails);
