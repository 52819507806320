import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContentModalities } from './components';
import { BadgeSmall } from '../BadgeSettings/BadgeSettings';

const useStyles = theme => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  to_format: {
    marginTop: theme.spacing(1),
  },
  calendar_format: {
    width: '130px',
  },
  check_container: {
    margin: 0,
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    padding: 0,
    '& .MuiTypography-root': {
      fontSize: 10,
      // fontWeight: 500,
    },
  },
  summary: {
    display: 'flex',
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    height: 20,
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
    },
    margin: 0,
    padding: 0,
  },
  modality: {
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    padding: 0,
  },
});

class GroupBoxModalities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalities: [],
      expanded: false,
    };
    const { holder } = props;
    if (
      typeof holder !== 'undefined' &&
      typeof holder.getModalities !== 'undefined'
    ) {
      holder.getModalities = () => this.state.modalities;
    }
  }

  handleModalityChecked = (name, event) => {
    const { modalities } = this.state;
    if (event.target.checked) {
      modalities.push(name);
    } else {
      modalities.splice(modalities.indexOf(name), 1);
    }
    this.setState({ modalities: modalities });
    //
  };
  handleToDateChange = date => {
    this.setState({ selectedToDate: date });
  };
  handleFromDateChange = date => {
    this.setState({ selectedFromDate: date });
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(newValue, 10) });
  };

  render() {
    const { classes } = this.props;
    const { modalities } = this.state;
    return (
      <Card className={classes.card}>
        <Accordion
          expanded={this.state.expanded}
          onChange={() => this.setState({ expanded: !this.state.expanded })}
          style={{ margin: 0, minHeight: 0 }}>
          <AccordionSummary
            aria-controls="panel-stuy-modalities"
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            id="panel-stuy-times-header">
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-start">
              <Grid item xs={8}>
                <CardHeader className={classes.title} title="Modalities" />
              </Grid>
              <div style={{ paddingLeft: 5 }}>
                <BadgeSmall
                  badgeContent={modalities.length}
                  style={{ backgroundColor: 'black' }}
                />
              </div>
            </Grid>
          </AccordionSummary>
          <Divider variant="middle" />
          <AccordionDetails>
            <ContentModalities
              classes={classes}
              handleModalityChecked={this.handleModalityChecked}
              modalities={modalities}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  }
}

GroupBoxModalities.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(GroupBoxModalities, useStyles);
