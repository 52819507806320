export const handleResultData = (
  type,
  data,
  object,
  page,
  limit,
  new_tab,
  THIS
) => {
  let { tabValue } = THIS.state;
  if (new_tab !== null) tabValue = new_tab;
  // console.log({ new_tab, type, tabValue, data, object });
  if (tabValue === 0) {
    let data_series = null;
    if (type === 'users') data_series = data.Series;
    else data_series = data.projectSeries;
    if (data_series === undefined || !data_series) throw 'Unknown error';
    const { ok, Series, Total, errors } = data_series;
    if (ok) {
      THIS.setState({
        loading: false,
        series: Series,
        total_series: Total,
        init: true,
        user: object,
        project: null,
        page,
        limit,
        tabValue,
      });
    } else {
      throw errors;
    }
  } else if (tabValue === 1) {
    let data_files = null;
    if (type === 'users') data_files = data.filesAtUser;
    else data_files = data.projectFiles;
    if (data_files === undefined || !data_files) throw 'Unknown error';
    const { ok, files, total, errors } = data_files;
    if (ok) {
      THIS.setState({
        loading: false,
        files,
        total_files: total,
        init: true,
        user: null,
        project: object,
        page,
        limit,
        tabValue,
      });
    } else {
      throw errors;
    }
  } else {
    throw 'Unknown error';
  }
};
export const GetIDtoSearch = (project, user, page, limit, THIS) => {
  // console.log('GetIDtoSearch', { project, user, page, limit });
  let id_to_search = null;
  let object = null;
  if (!user && !THIS.state.user && !project && !THIS.state.project) {
    if (THIS.state.page !== page || THIS.state.limit !== limit) {
      THIS.setState({ page, limit });
    }
  } else if (!user && THIS.state.user) {
    id_to_search = THIS.state.user.id;
    object = THIS.state.user;
  } else if (user) {
    id_to_search = user.id;
    object = user;
  } else if (!project && THIS.state.project) {
    id_to_search = THIS.state.project.id;
    object = THIS.state.project;
  } else if (project) {
    id_to_search = project.id;
    object = project;
  }
  return { id_to_search, object };
};
