export const useStyles = () => ({
  box: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '10px',
    padding: '1em',
    paddingTop: 2,
    pointerEvents: 'none',
  },
  top_left: {
    left: '1px',
    top: '1px',
  },
  text: {
    padding: 0,
    margin: 0,
    height: 14,
  },
  text_right: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  bottom_left: {
    left: '1px',
    bottom: 0,
  },
  bottom_right: {
    right: '1px',
    bottom: 0,
    textAlign: 'right',
  },
  top_right: {
    right: '1px',
    top: 0,
    textAlign: 'right',
  },
});
