import React from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { Avatar, Typography } from '@mui/material';

const theme_active_button = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const useStyles = theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
});

class TableCellThumbnail extends React.Component {
  handleClick = event => {
    event.preventDefault();
  };

  render() {
    const { isDense, name, Thumbnail, content, handleDoubleClick } = this.props;
    const isFolder = content.folder ? true : false;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_active_button}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}>
            <Grid item>
              {isFolder ? (
                <FolderIcon
                  color="primary"
                  fontSize={isDense ? 'small' : 'large'}
                  onDoubleClick={() => handleDoubleClick(content)}
                />
              ) : Thumbnail ? (
                <Avatar alt={name} src={Thumbnail} />
              ) : (
                <DescriptionIcon fontSize={isDense ? 'small' : 'large'} />
              )}
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography noWrap>{name}</Typography>
            </Grid>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

TableCellThumbnail.propTypes = {
  classes: PropTypes.object,
};
TableCellThumbnail.defaultProps = {
  isFolder: false,
  isDense: false,
  name: '',
  Thumbnail: null,
  handleDoubleClick: () => '',
};
export default withStyles(TableCellThumbnail, useStyles);
