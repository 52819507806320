import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
// eslint-disable-next-line no-unused-vars
const statusColors = {
  green: 'success',
  orange: 'info',
  red: 'danger',
  warning: 'warning',
  neutral: 'neutral',
  purple: 'purple',
};
const getStatus = params => {
  const activity = params.getValue(params.id, 'name');
  const Folder = params.getValue(params.id, 'Folder');
  const DirectMessage = params.getValue(params.id, 'DirectMessage');
  const Right = params.getValue(params.id, 'Right');
  const FolderContent = params.getValue(params.id, 'FolderContent');
  const read = params.getValue(params.id, 'read');
  return getStatusNotification({
    activity,
    Folder,
    DirectMessage,
    Right,
    FolderContent,
    read,
  });
};
export const headers_activity = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'name',
    headerName: 'Activity',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'user',
    headerName: 'Status',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    type: 'status',
    color: (value, params) => {
      if (IsInvalidParam(params)) return 'neutral';
      const status = getStatus(params);
      value = status.type;
      const i = Object.keys(statusColors).indexOf(value);
      if (i === -1) return 'neutral';
      return statusColors[value];
    },
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const status = getStatus(params);
      return status.name;
    },
  },
  {
    field: 'From',
    headerName: 'From',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'From');
      if (IsInvalid(value)) return '';
      return value.FullName;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const getStatusNotification = row => {
  const { activity, Folder, DirectMessage, Right, FolderContent, read } = row;
  let type = 'orange';
  let name = 'Unknown';
  if (Folder) {
    type = 'green';
    name = 'New folder';
  } else if (DirectMessage) {
    type = read ? 'red' : 'green';
    name = 'Message';
  } else if (Right) {
    type = 'green';
    name = 'Right created';
  } else if (FolderContent) {
    const { idSeries } = FolderContent;
    if (typeof idSeries !== 'undefined') {
      name = 'Series uploaded';
    } else {
      name = 'File uploaded';
    }
    type = 'green';
  } else if (activity === 'User Request') {
    name = 'New request';
    type = 'warning';
  } else if (activity.startsWith('Shared Folder')) {
    name = 'New folder shared';
    type = 'neutral';
  }
  if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('You added new File') !== -1
  ) {
    name = 'File uploaded and delete';
    type = 'red';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('shared a File') !== -1
  ) {
    name = 'File shared';
    type = 'neutral';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('You created a new Folder') !== -1
  ) {
    type = 'green';
    name = 'New folder';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('Folder deleted') !== -1
  ) {
    type = 'red';
    name = 'Folder deleted';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('Folder rename') !== -1
  ) {
    type = 'warning';
    name = 'Folder rename';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('added new Series') !== -1
  ) {
    type = 'green';
    name = 'New series';
  } else if (
    activity &&
    name === 'Unknown' &&
    activity.indexOf('shared a Series') !== -1
  ) {
    type = 'warning';
    name = 'Shared series';
  }
  return {
    type,
    name,
  };
};
