import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { StyleText } from 'components';

class ViewDockerImageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
    };
  }

  render() {
    const { image, loading } = this.props;
    let idImage = 'Loading...';
    let name = 'Loading...';
    if (!loading && image) {
      idImage = image.idImage;
      const { RepoTags } = image;
      if (RepoTags && RepoTags.length > 0) {
        name = `${RepoTags[0].name}:${RepoTags[0].tag}`;
      }
    }
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleClose()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={<EditIcon style={{ color: 'green' }} />}
        subheader={
          <Grid container>
            <StyleText
              fontSize={15}
              fontWeight={1000}
              spacing={3}
              style
              text="ID:"
            />
            <Grid item style={{ marginLeft: 10 }}>
              {idImage}
            </Grid>
          </Grid>
        }
        title={
          <Grid container>
            <StyleText
              fontSize={15}
              fontWeight={1000}
              spacing={3}
              style
              text="Name:"
            />
            <Grid item style={{ marginLeft: 10 }}>
              {name}
            </Grid>
          </Grid>
        }
      />
    );
  }
}

ViewDockerImageHeader.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};
ViewDockerImageHeader.defaultProps = {
  handleClose: () => '',
};
export default ViewDockerImageHeader;
