import React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';

// import MailIcon from '@mui/icons-material/Mail';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Label from '@mui/icons-material/Label';
// import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
// import InfoIcon from '@mui/icons-material/Info';
// import ForumIcon from '@mui/icons-material/Forum';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { withStyles } from 'tss-react/mui';

import { TreeSitesItem } from '.';

const useStyles = () => ({
  root: {
    height: 264,
    flexGrow: 1,
    // maxWidth: 400
  },
});

class TreeSites extends React.Component {
  render() {
    const { classes, folders } = this.props;
    return folders.length ? (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        defaultExpanded={['0']}
        defaultExpandIcon={<ArrowRightIcon />}
        key="tree-view-sites">
        <TreeSitesItem
          folders={folders}
          index={0}
          itemId="0"
          key={'styled-folder-tree-item-0'}
          nodeId={'0'}
        />
      </TreeView>
    ) : null;
  }
}

export default withStyles(TreeSites, useStyles);
