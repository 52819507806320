import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import classNames from 'classnames';

import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from '../index';

class DroppUploadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Dropzone
        acceptClassName={classes.stripes}
        className={classNames(this.props.dropzoneClass, classes.dropZone)}
        maxSize={this.props.maxFileSize}
        onDrop={this.props.onDrop}
        onDropRejected={this.props.handleDropRejected.bind(this)}
        rejectClassName={classes.rejectStripes}>
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          isDragAccept,
        }) => {
          let finastyle = baseStyle;
          if (isDragActive) {
            finastyle = {
              ...baseStyle,
              ...focusedStyle,
            };
          } else if (isDragReject) {
            finastyle = {
              ...baseStyle,
              ...rejectStyle,
            };
          } else if (isDragAccept) {
            finastyle = {
              ...baseStyle,
              ...acceptStyle,
            };
          }
          return (
            <section className="container">
              <div {...getRootProps()}>
                <div style={finastyle}>
                  <input {...getInputProps()} />
                  <p
                    className={classNames(
                      classes.dropzoneParagraph,
                      this.props.dropzoneParagraphClass
                    )}>
                    {this.props.dropzoneText}
                  </p>
                  <CloudUploadIcon className={classes.uploadIconSize} />
                </div>
              </div>
            </section>
          );
        }}
      </Dropzone>
    );
  }
}

DroppUploadFiles.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DroppUploadFiles;
