import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { withRouter } from 'react-router-dom';

class ExploreCrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = event => {
    event.preventDefault();
    const { history, code, home, subfolder } = this.props;
    if (home.idFolder === subfolder.idFolder) {
      this.props.handleHome();
    } else {
      history.push(`/shared/folder/${code}/${subfolder.idFolder}`);
      this.props.handleClick(subfolder);
    }
    console.info('You clicked a breadcrumb.');
  };

  render() {
    const { subfolder } = this.props;
    if (!subfolder) return null;
    const { name } = subfolder;
    return (
      <Link color="inherit" href="/" onClick={this.handleClick}>
        {name}
      </Link>
    );
  }
}

ExploreCrumbs.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ExploreCrumbs);
