import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SEARCH_RECEIVER = gql`
  query($name: String, $page: Int, $limit: Int) {
    searchReceiver(name: $name, page: $page, limit: $limit) {
      id
      idTeam
      idUser
      idChannel
      name
      team {
        id
        name
      }
      user {
        id
        FullName
      }
      channel {
        id
        name
      }
    }
  }
`;
export const QuerySearchReceiver = (name, page, limit) => {
  if (typeof page === 'undefined') page = 0;
  if (typeof limit === 'undefined') limit = 5;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_RECEIVER),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const QUERY_COMMUNICATIONS = gql`
  query($name: String, $page: Int, $limit: Int) {
    communications(name: $name, page: $page, limit: $limit) {
      id
      direct {
        User {
          id
          FullName
          avatar
          isOnline
        }
        NewMessages
        LastMessage {
          id
          text
          sender {
            id
            FullName
            avatar
          }
          receiver {
            id
            FullName
            avatar
            isOnline
          }
          read
          receiverId
          createdAt
        }
        createdAt
      }
      channel {
        id
        teamId
        name
        public
        lastMessage {
          id
          text
          user {
            id
            FullName
            avatar
          }
          channelId
        }
        notread
        team {
          id
          name
          avatar
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryCommunications = (name, page, limit) => {
  if (typeof page === 'undefined') page = 0;
  if (typeof limit === 'undefined') limit = 100;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_COMMUNICATIONS),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const MUTATION_UPDATE_READ_MESSAGES = gql`
  mutation($idChannel: LongLong!) {
    updateReadMessages(idChannel: $idChannel) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateReadMessages = idChannel => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_READ_MESSAGES),
    variables: {
      idChannel,
    },
  });
};
