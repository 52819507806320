import { QuerySeriesAtStudyThumbnails } from 'graphql/Series';
import { QueryStudiesSeries } from 'graphql/Study';
import { ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
export { default as StudyCard } from './StudyCard';
export { default as StudyThumbnails } from './StudyThumbnails';
export { default as ViewStudy } from './ViewStudy';
export { default as StudyGridView } from './StudyGridView';
export { default as StudyListView } from './StudyListView';
export const LoadSeriesAtStudy = (index, THIS) => {
  const { Studies, index_series } = THIS.state;
  if (index_series === index) return;
  if (!Studies.length || index < 0 || index >= Studies.length) {
    THIS.setState({
      index_series: -1,
    });
    return;
  }
  THIS.setState({ index_series: index });
  (async () => {
    QuerySeriesAtStudyThumbnails(Studies[index].idStudy)
      .then(res => {
        const data = GetData(res);
        const { ok, Series, errors } = data.Series;
        if (ok) {
          Studies[index] = {
            ...Studies[index],
            Series,
          };
          THIS.setState({
            Studies,
          });
          LoadSeriesAtStudy(index + 1, THIS);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        Studies[index] = {
          ...Studies[index],
          Series: [],
        };
        THIS.setState({
          Studies,
        });
        THIS.props.handleAddError(ServerErrorsString(error));
        LoadSeriesAtStudy(index + 1, THIS);
      });
  })();
};

export const LoadStudiesSeries = (index, THIS) => {
  const { Studies, index_series } = THIS.state;
  if (index_series === index) return;
  if (!Studies.length || index < 0) {
    THIS.setState({
      index_series: -1,
    });
    return;
  }
  let id_studies = [];
  let next_index = index;
  for (; next_index < index + 5; next_index++) {
    if (next_index >= Studies.length) break;
    const { idStudy } = Studies[next_index];
    const j = id_studies.indexOf(idStudy);
    if (j === -1) id_studies.push(idStudy);
  }
  if (!id_studies.length) {
    THIS.setState({
      index_series: -1,
    });
    return;
  }
  THIS.setState({ index_series: index === 0 ? index : next_index - 1 });
  (async () => {
    QueryStudiesSeries(id_studies)
      .then(res => {
        const data = GetData(res);
        const { ok, studies_series, errors } = data.StudiesSeries;
        if (ok) {
          for (let x = 0; x < studies_series.length; x++) {
            const { idStudy, series } = studies_series[x];
            const id_study = Studies.map(x => x.idStudy).indexOf(idStudy);
            if (id_study === -1) continue;
            THIS.handleUpdateSeries(idStudy, series);
          }
          // THIS.setState({
          //   Studies,
          // });
          LoadStudiesSeries(next_index, THIS);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        for (let x = 0; x < id_studies.length; x++) {
          const idStudy = id_studies[x];
          const id_study = Studies.map(x => x.idStudy).indexOf(idStudy);
          if (id_study === -1) continue;
          Studies[id_study] = {
            ...Studies[id_study],
            Series: [],
          };
        }
        THIS.setState({
          Studies,
        });
        THIS.props.handleAddError(ServerErrorsString(error));
        LoadStudiesSeries(next_index, THIS);
      });
  })();
};
