import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Toolbar, Typography } from '@mui/material';

import { DivGrow, ButtonReloadDelete } from 'components';
import { useToolbarStyles } from '../utils';

class TableSiteUsersToolbar extends Component {
  render() {
    const {
      numSelected,
      classes,
      title,
      handleDeleteSelected,
      loading,
      handleReloadTable,
      tooltip,
      loading_table,
    } = this.props;
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} id="tableTitle" variant="h6">
            {title}
          </Typography>
        )}
        <DivGrow />
        <ButtonReloadDelete
          handleClearSelected={this.props.handleClearSelected}
          handleDeleteSelected={handleDeleteSelected}
          handleReloadTable={handleReloadTable}
          loading={loading}
          loading_table={loading_table}
          numSelected={numSelected}
          tooltip={tooltip}
        />
      </Toolbar>
    );
  }
}

TableSiteUsersToolbar.propTypes = {
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
};
TableSiteUsersToolbar.defaultProps = {
  loading_table: false,
  loading: false,
  tooltip: 'Delete',
};
export default withStyles(TableSiteUsersToolbar, useToolbarStyles);
