import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { FormatNumber, FormatToBytes } from 'helpers';

import { ProjectEmails, StorageSetting } from './components';
class ProjectSettingStorage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSecondaryEmail = secondary_emails => {
    const { project_setting } = this.props;
    const new_setting = {
      ...project_setting,
      secondary_emails,
    };
    this.props.handleUpdateProjectSetting(new_setting);
  };
  handleChange = prop => event => {
    const { project_setting } = this.props;
    let number = event.target.value;

    if (prop !== 'email' && prop != 'secondary_emails') {
      if (number < 0) number = 0;
      let value = FormatNumber(project_setting[prop]);
      console.log({ number, value, t: `${number} ${value.format}` });
      number = FormatToBytes(`${number} ${value.format}`);
    } else if (number === '') {
      number = null;
    }
    const new_setting = {
      ...project_setting,
      [prop]: number,
    };
    this.props.handleUpdateProjectSetting(new_setting);
  };
  handleCheckChange = event => {
    const { project_setting } = this.props;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    this.props.handleUpdateProjectSetting({
      ...project_setting,
      [name]: checked,
    });
  };
  render() {
    if (!this.props.isActive) return null;
    const { project_setting, loading } = this.props;
    let file_size = FormatNumber('');
    let storage = FormatNumber('');
    let private_folder = false;
    let delete_folder = false;
    let email = '';
    let secondary_emails = '';
    if (project_setting && !loading) {
      storage = FormatNumber(project_setting.storage);
      file_size = FormatNumber(project_setting.file_size);
      private_folder = project_setting.private_folder;
      delete_folder = project_setting.delete_folder;
      email = project_setting.email;
      secondary_emails = project_setting.secondary_emails;
    }
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <StorageSetting
            file_size={file_size}
            handleChange={this.handleChange}
            loading={loading}
            storage={storage}
          />
          <Grid container item xs={12}>
            <Grid item md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={private_folder}
                    disabled={loading}
                    name="private_folder"
                    onChange={this.handleCheckChange}
                  />
                }
                label="Show Only Owned Folders"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delete_folder}
                    disabled={loading}
                    name="delete_folder"
                    onChange={this.handleCheckChange}
                  />
                }
                label="Delete Created Folders"
              />
            </Grid>
            <ProjectEmails
              email={email}
              handleAddError={this.props.handleAddError}
              handleChange={this.handleChange}
              handleSecondaryEmail={this.handleSecondaryEmail}
              secondary_emails={secondary_emails}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ProjectSettingStorage.propTypes = {
  classes: PropTypes.object,
};

export default ProjectSettingStorage;
