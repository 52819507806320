import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { withStyles } from 'tss-react/mui';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  getStudyDate,
  getStudyPatientID,
  getStudyPatientName,
  getStudyTime,
} from 'helpers';

const useStyles = () => ({
  check: {
    '&.MuiTableCell-paddingCheckbox': {
      width: '24px',
      marging: 0,
      padding: 0,
    },
  },
  cell_check: {
    '&.MuiTableCell-paddingCheckbox': {
      width: '24px',
      marging: 0,
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
});

class StudyRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  handlerOpen = () => {
    this.setState(x => ({ open: !x.open }));
  };

  render() {
    const {
      classes,
      Study,
      isItemSelected,
      handleStudyRowClicked,
    } = this.props;
    const { loading, open } = this.state;
    return (
      <TableRow
        hover
        onClick={() => handleStudyRowClicked(Study.Series)}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell className={classes.check} padding="checkbox">
          <IconButton
            aria-label="expand row"
            onClick={() => this.handlerOpen()}
            size="small"
            style={{ margin: 0, padding: 0, rigth: -20 }}>
            {loading ? (
              <CircularProgress />
            ) : open ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell_check} padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            color="primary"
            onChange={() => this.props.handleStudyChecked(Study)}
          />
        </TableCell>
        <TableCell>{getStudyPatientName(Study)}</TableCell>
        <TableCell>{Study.NumberOfStudyRelatedSeries}</TableCell>
        <TableCell>{Study.NumberOfStudyRelatedInstances}</TableCell>
        <TableCell>{getStudyPatientID(Study)}</TableCell>
        <TableCell>{Study.StudyDescription}</TableCell>
        <TableCell>{getStudyDate(Study, 'yyyy/MM/DD')}</TableCell>
        <TableCell>{getStudyTime(Study)}</TableCell>
        <TableCell>{Study.AccessionNumber}</TableCell>
        <TableCell>{Study.StudyInstanceUID}</TableCell>
      </TableRow>
    );
  }
}

StudyRow.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(StudyRow, useStyles);
