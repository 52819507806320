import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadStrokeCSV } from './utils';

const QUERY_SSR_CASES = gql`
  query($name: String, $page: Int, $limit: Int) {
    ssrCases(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient
        idCase
        arrival_date
        event_type
        onset_known
        onset_time
        wakeup_time
        referral
        referring_unitcenter
        transport
        living_pre
        disability_pre
        stroke_syndrome
        nih_admission
        gcs_admission
        bp_syst
        bp_diast
        createdAt
        updatedAt
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryStrokeCases = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_CASES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const QUERY_STROKE_PATIENTS = gql`
  query($page: Int, $limit: Int, $anonymize: Int) {
    ssrPatients(page: $page, limit: $limit, anonymize: $anonymize) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        idUser
        idAddress

        user {
          FullName
        }

        FamilyName
        GivenName
        MiddleName
        NamePrefix
        NameSuffix

        PatientName

        PatientID

        PatientBirthDate
        PatientSex

        PatientAge
        PatientSize
        PatientWeight
        PatientWaist
        PatientHip
        bmi
        PatientEthnicity
        patientSAPStatus
        patientLastActivity
        patientRegistrationDate
        patientVitalStatus
        patientDeathDate
        patientLastVitalityCheck

        UniqueKeyIdentifier
        tot_sleep_files
        tot_epilepsy_files
        tot_stroke_files
        tot_ms_files

        tot_sleep_series
        tot_epilepsy_series
        tot_stroke_series
        tot_ms_series

        tot_sleep_edfs
        tot_epilepsy_edfs
        tot_stroke_edfs
        tot_ms_edfs
        address {
          country
          city
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryStrokePatients = (page, limit, anonymize) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_STROKE_PATIENTS),
    variables: {
      page,
      limit,
      anonymize,
    },
  });
};
const MUTATION_ADD_SSR_CASE = gql`
  mutation($inputs: [InputSsrCase!]!) {
    addSsrCase(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient
        idCase
        arrival_date
        event_type
        onset_known
        onset_time
        wakeup_time
        referral
        referring_unitcenter
        transport
        living_pre
        disability_pre
        stroke_syndrome
        nih_admission
        gcs_admission
        bp_syst
        bp_diast
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const MutationAddSsrCase = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SSR_CASE),
    variables: {
      inputs,
    },
  });
};

const MUTATION_UPLOAD_STROKE_ALL = gql`
  mutation($file: Upload!) {
    uploadStroke(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      cases {
        id
        idPatient

        patient {
          FamilyName
          GivenName
          MiddleName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationUploadStrokeAll = (file, handleProgress = null) => {
  return MutationUploadStrokeCSV(
    file,
    MUTATION_UPLOAD_STROKE_ALL,
    handleProgress
  );
};

const MUTATION_REMOVE_STROKE_CASES = gql`
  mutation($ids: [LongLong!]!) {
    deleteStrokeCase(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveStrokeCase = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_STROKE_CASES),
    variables: {
      ids,
    },
  });
};

// const SEARCH_SSR_CASES = gql`
//   query($phrase: String, $page: Int, $limit: Int) {
//     searchSsrCases(phrase: $phrase, page: $page, limit: $limit) {
//       ok
//       errors {
//         path
//         message
//       }
//       total
//       cases {
//         id
//         idPatient
//         idCase
//         arrival_date
//         event_type
//         onset_known
//         onset_time
//         wakeup_time
//         referral
//         referring_unitcenter
//         transport
//         living_pre
//         disability_pre
//         stroke_syndrome
//         nih_admission
//         gcs_admission
//         bp_syst
//         bp_diast
//         createdAt
//         updatedAt
//         patient {
//           id
//           PatientID
//         }
//       }
//     }
//   }
// `;
