export const useStyles = () => ({
  root: {
    width: '100%',
    height: '70vh',
    overflow: 'hidden',
  },
  pdf_viewer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    overflowY: 'auto',
  },
  pdf_page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
    border: ' 1px solid #ccc',
    marginBottom: '20px',
  },
  pdf_image: {
    width: '100%',
    height: 'auto',
  },
  pdf_text_block: {
    fontFamily: 'sans-serif',
    fontSize: '14px',
    lineHeight: '1.5',
    marginBottom: '5px',
  },
});
