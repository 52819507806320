import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Transition } from 'components';

const useStyles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  switch: {
    color: green[500],
  },
  switchBase: {
    color: green[500],
    '&$checked': {
      color: green[800],
    },
    '&$checked + $track': {
      backgroundColor: green[800],
    },
  },
});

class DialogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      volume: false,
      link: false,
      force: false,
    };
  }

  handleCheckForce = event => {
    this.setState({ force: event.target.checked });
  };
  handleCheckVolume = event => {
    this.setState({ volume: event.target.checked });
  };
  handleCheckLinks = event => {
    this.setState({ link: event.target.checked });
  };

  render() {
    const { classes, handleClose, open } = this.props;
    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        keepMounted
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}>
        <DialogTitle id="alert-dialog-slide-title">
          Delete Docker Container
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You are about to delete the seleted containers, this action cannot
            be undone. Do you want to continue?
          </DialogContentText>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={this.state.force}
                classes={{
                  switchBase: classes.switchBase,
                  // track: classes.switch,
                  // checked: classes.switch,
                }}
                onChange={this.handleCheckForce}
              />
            }
            label="Force"
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={this.state.volume}
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.switchBase,
                  checked: classes.switchBase,
                }}
                onChange={this.handleCheckVolume}
              />
            }
            label="Delete volumes"
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={this.state.link}
                classes={{
                  switchBase: classes.switchBase,
                  //   track: {},
                  //   checked: {},
                }}
                onChange={this.handleCheckLinks}
              />
            }
            label="Delete associated links"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this.props.executeDeleteSelected(this.state)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogContainer.propTypes = {
  classes: PropTypes.object,
};
DialogContainer.defaultProps = {
  handleClose: () => '',
  open: false,
};

export default withStyles(DialogContainer, useStyles);
