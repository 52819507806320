import { getColumns, getRows } from 'common';
import { IsInvalid } from 'helpers';

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
export const columns = [...getColumns(alphabet)];
export const rows = getRows(alphabet);

export const GenerateRows = () => {
  return getRows(alphabet);
};
export const GetRowValues = (row, rows) => {
  if (IsInvalid(rows) || !rows.length) return [];
  if (row < 0 || row > rows.length) return [];
  const keys = Object.keys(rows[0]);
  let values = [];
  for (let i = 0; i < keys.length; i++) {
    const value = rows[row][keys[i]];
    values.push(value ? value : '');
  }
  return values;
};
export const GetColumn = (key, rows) => {
  if (IsInvalid(rows) || !rows.length) return -1;
  const keys = Object.keys(rows[0]);
  for (let i = 0; i < keys.length; i++) {
    // console.log({ value: rows[0][keys[i]], key });
    if (rows[0][keys[i]] === key) return i;
  }
  return -1;
};
export const GetRow = (value_row, column, rows) => {
  if (IsInvalid(column) || column < 0) return -1;
  if (IsInvalid(rows) || !rows.length) return -1;
  const keys = Object.keys(rows[0]);
  if (column < 0 || column > keys.length) return -1;
  const key = keys[column];
  if (IsInvalid(key) || key === '') return -1;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i][key] === value_row) return i;
  }
  return -1;
};
export const GetRowColumn = (row, column, rows) => {
  if (IsInvalid(column) || column < 0) return null;
  if (IsInvalid(row) || row < 0) return null;
  if (IsInvalid(rows) || !rows.length) return null;
  if (row < 0 || row > rows.length) return null;
  const keys = Object.keys(rows[0]);
  if (column < 0 || column > keys.length) return null;
  const key = keys[column];
  return rows[row][key];
};
export const SetRowColumn = (value, row, column, rows) => {
  if (IsInvalid(column) || column < 0) return null;
  if (IsInvalid(row) || row < 0) return null;
  if (IsInvalid(rows) || !rows.length) return null;
  if (row < 0 || row > rows.length) return null;
  const keys = Object.keys(rows[0]);
  if (column < 0 || column > keys.length) return null;
  const key = keys[column];
  rows[row][key] = value;
};
export const GetSearchColumn = (text, rows_edited, THIS) => {
  let found_column = GetColumn(text, rows_edited);
  if (found_column < 0) {
    found_column = GetColumn('', rows_edited);
    SetRowColumn(text, 0, found_column, rows_edited);
    THIS.setState({ rows_edited });
  }
  return found_column;
};
