import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DetailsMeta extends Component {
  render() {
    return <div>Meta</div>;
  }
}

DetailsMeta.propTypes = {
  classes: PropTypes.object,
};

export default DetailsMeta;
