import React from 'react';
import PropTypes from 'prop-types';

// import clsx from 'clsx';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';

import { TableShareUsersHead, TableCellPermission } from './components';
import { useStylesTableShare, headCells } from '../Styles';
import { getRightIndex, getRightName } from 'helpers/utils_rights';
import { SelectedArray, getSorting, stableSort } from 'helpers';

class TableShareOwners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      dense: props.dense ? true : false,
      rowsPerPage: 5,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getPermission = () => {
        return this.state.rights;
      };
      holder.getSelected = () => {
        return this.state.selected;
      };
      holder.cleanSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }

  getSelected = () => {
    if (this.props.selected) {
      return this.props.selected;
    } else {
      return this.state.selected;
    }
  };
  setSelected = selected => {
    if (this.props.selected) {
      this.props.handleSelected(selected);
    } else {
      this.setState({ selected });
    }
  };
  setOrder = newOrder => {
    this.setState({ order: newOrder });
  };
  setOrderBy = newOrderBy => {
    this.setState({ orderBy: newOrderBy });
  };
  setPage = newPage => {
    this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setOrder(isDesc ? 'asc' : 'desc');
    this.setOrderBy(property);
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setSelected(this.props.rights);
    } else {
      this.setSelected([]);
    }
  };

  handleSelected = right => {
    const selected = this.getSelected();
    const selectedIndex = selected
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    const newSelected = SelectedArray(selected, right, selectedIndex);
    this.setSelected(newSelected);
  };

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setPage(newPage);
  };

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10));
    this.setPage(0);
  };

  handleChangeDense = event => {
    this.setDense(event.target.checked);
  };
  handleCheckedUserPermission = right => {
    const selected = this.getSelected();
    const index = selected
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    if (index !== -1) {
      selected[index] = right;
    }
    this.setSelected(selected);
    this.props.handleCheckedChanged(right);
  };
  isSelected = right => {
    const selected = this.getSelected();
    const index = selected
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    return index !== -1;
  };

  render() {
    const { classes, short_label, rights } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const selected = this.getSelected();
    let emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rights.length - page * rowsPerPage);
    if (!rights.length) {
      emptyRows = 2;
    }
    let colSpan = 3;
    let TableCellCheckBox = null;
    if (!this.props.disable_checkbox) {
      colSpan = 2;
    }
    return (
      <Paper>
        <Table
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
          className={classes.table}
          size={this.state.dense ? 'small' : 'medium'}
          stickyHeader>
          <TableShareUsersHead
            classes={classes}
            disable_checkbox={this.props.disable_checkbox}
            headCells={headCells}
            numSelected={selected.length}
            onRequestSort={this.handleRequestSort}
            onSelectAllClick={this.handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rights.length}
            short_label={short_label}
          />
          <TableBody style={{ width: '100%' }}>
            {stableSort(rights, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((right, index) => {
                const isItemSelected = this.isSelected(right);
                const labelId = `enhanced-table-checkbox-${index}`;
                if (!this.props.disable_checkbox) {
                  TableCellCheckBox = (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={() => {
                          this.handleSelected(right);
                        }}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableRow
                    aria-checked={isItemSelected}
                    hover
                    key={`table-row-${index}`}
                    selected={isItemSelected}
                    tabIndex={-1}>
                    {TableCellCheckBox}
                    <TableCell align="left">{getRightName(right)}</TableCell>
                    <TableCell align="center">
                      <TableCellPermission
                        disabled={this.props.disable_checkbox}
                        handleCheckedUserPermission={
                          this.handleCheckedUserPermission
                        }
                        row={right}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (this.state.dense ? 33 : 53) * emptyRows,
                  border: 0,
                  width: '100%',
                  margin: 0,
                  padding: 0,
                }}>
                <TableCell colSpan={colSpan} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

TableShareOwners.defaultProps = {
  selected: null,
  rights: [],
  handleSelected: total =>
    console.log(`TableShareUsers -> handleSelected -> Total: ${total}`),
  handleCheckedChanged: () => console.log('handleCheckedChanged'),
  disable_checkbox: false,
};

TableShareOwners.propTypes = {
  classes: PropTypes.object.isRequired,
  disable_checkbox: PropTypes.bool.isRequired,
  rights: PropTypes.array.isRequired,
};
export default withStyles(TableShareOwners, useStylesTableShare);
