export { default as ExplorerViewListTree } from './ExplorerViewListTree';
export const useStyles = (theme, props) => ({
  root: {
    margin: 0,
    padding: 0,
    border: 0,
    position: 'relative',
    marginLeft: 'auto',
    width: '100%',
    height: '100%',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      width: '100%',
      position: 'absolute',
      height: props.height - 240,
      transition: 'none !important',
    },
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 5,
    marginRight: 0,
  },
});
