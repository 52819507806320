import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROJECT_STUDIES = gql`
  query($idProject: LongLong!, $idCenter: LongLong, $page: Int, $limit: Int) {
    projectStudies(
      idProject: $idProject
      idCenter: $idCenter
      page: $page
      limit: $limit
    ) {
      ok
      total
      project_studies {
        id
        idFolderContent
        idStudy
        idSeries
        total_series
        study_code
        study {
          StudyDescription
          StudyInstanceUID
          StudyDate
          StudyTime
          Patient {
            PatientID
            PatientName
          }
        }
        center {
          id
          name
        }
        mappings {
          idType
          type {
            name
          }
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryProjectStudies = (idProject, idCenter, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_STUDIES),
    variables: {
      idProject,
      idCenter,
      page,
      limit,
    },
  });
};
