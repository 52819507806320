import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { inputProps } from 'common';

const useStyles = () => ({
  root: {
    marginLeft: 0,
  },
});

class UnitText extends Component {
  render() {
    const { classes, text, readOnly, className } = this.props;
    return (
      <TextField
        className={clsx(classes.root, className)}
        // defaultValue={text}
        fullWidth={Boolean(this.props.fullWidth)}
        id={this.props.id}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          readOnly: Boolean(readOnly),
          disabled: Boolean(readOnly),
          ...inputProps,
        }}
        label={this.props.label}
        margin="normal"
        name={this.props.name}
        onChange={this.props.onChange}
        size="small"
        value={text}
      />
    );
  }
}

UnitText.propTypes = {
  classes: PropTypes.object,
};

UnitText.defaultProps = {
  id: 'unknown',
  name: 'unknown',
  readOnly: true,
  fullWidth: false,
  label: '',
  className: null,
};

export default withStyles(UnitText, useStyles);
