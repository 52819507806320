import React from 'react';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';
import { DivGrow, IconButtonLoad } from 'components';
import { MutationCreateCenter } from 'graphql/Units/utils_centers';
import { GetData, ServerErrorsString } from 'helpers';

class CreateCenterBotton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
    };
  }

  handleCreateCenter = () => {
    const { creating } = this.state;
    if (creating) return;
    const center = this.props.holder.getCenter();
    if (center.name === '') {
      this.props.handleAddError('Center name is missing');
      return;
    }
    const new_center = {
      name: center.name,
    };
    if (center.admin.id) {
      new_center.idAdmin = center.admin.id;
    }
    if (center.address.country) {
      new_center.country = center.address.country;
    }
    if (center.address.city) {
      new_center.city = center.address.city;
    }
    if (center.address.line_1) {
      new_center.line_1 = center.address.line_1;
    }
    this.setState({ creating: true });
    this.props.holder.setDisableAll(true);
    (async () => {
      MutationCreateCenter(new_center)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.createCenter;
          if (ok) {
            this.setState({ creating: false });
            this.props.holder.resetCenter();
            this.props.handleAddNewCenter(data.createCenter.center);
            this.props.holder.setDisableAll(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.holder.setDisableAll(false);
        });
    })();
    // center.address.city
    // center.address.country
    // center.name
  };

  render() {
    const { creating } = this.state;
    return (
      <React.Fragment>
        <DivGrow />
        <IconButtonLoad
          // disabled={!changed}
          handleClick={this.handleCreateCenter}
          icon={<SaveIcon color="primary" />}
          loading={creating}
          tooltip="Create Center"
        />
      </React.Fragment>
    );
  }
}

CreateCenterBotton.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func,
  handleAddNewCenter: PropTypes.func,
  holder: PropTypes.object,
};
CreateCenterBotton.defaultProps = {
  holder: null,
  handleAddNewCenter: () => '',
  handleAddError: () => '',
};

export default CreateCenterBotton;
