import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
export default styled(Badge)(({ theme, ...props }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    color: props.batch_color_text ? props.batch_color_text : 'black',
    right: 5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: props.batch_color ? props.batch_color : '#d50000',
  },
}));
