import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const states = [
  { state: 'created', color: '#ff5722' },
  { state: 'restarting', color: 'orange' },
  { state: 'running', color: 'green' },
  { state: 'paused', color: 'yellow' },
  { state: 'exited', color: 'red' },
  { state: 'dead', color: 'red' },
];

class TableContainersMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  IsSelected = container => {
    const { selected } = this.props;
    const index = selected
      .map(x => x.idContainer)
      .indexOf(container.idContainer);
    return index !== -1 ? true : false;
  };

  render() {
    const { dense, columns, containers, selected } = this.props;
    const rowCount = containers.length;
    const numSelected = selected.length;
    let check_header = rowCount > 0 && numSelected === rowCount;
    return (
      <Table
        aria-label="sticky table"
        size={dense ? 'small' : 'medium'}
        stickyHeader
        sx={{ whiteSpace: 'nowrap' }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={check_header}
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onClick={this.props.handleSelectAllClick}
              />
            </TableCell>
            {columns.map((column, index) => {
              return (
                <TableCell key={`head-${index}`}>{column.label}</TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {containers.map((container, i) => {
            const checked = this.IsSelected(container);
            return (
              <TableRow key={`container-row-${i}`}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={checked}
                    color="primary"
                    onClick={() => this.props.handleSelectedClick(container)}
                  />
                </TableCell>
                {columns.map((column, index) => {
                  let name = `${index}`;
                  if (index === 0) {
                    name = (
                      <Link
                        component="button"
                        onClick={() =>
                          this.props.handleClickContainer(container)
                        }
                        tabIndex={0}>
                        {container.ImageName}
                      </Link>
                    );
                  } else if (container[column.id] !== undefined) {
                    if (column.format) {
                      name = column.format(container[column.id]);
                    } else if (column.id === 'State') {
                      const state = container[column.id];
                      const i = states.map(x => x.state).indexOf(state);
                      let color = 'black';
                      if (i !== -1) {
                        color = states[i].color;
                      }
                      name = (
                        <div
                          style={{
                            backgroundColor: color,
                            color: 'white',
                            fontWeight: 1000,
                            borderRadius: 5,
                            marginLeft: 10,
                            marginRight: 10,
                            padding: 2,
                            paddingRight: 5,
                            paddingLeft: 5,
                            textAlign: 'center',
                          }}>
                          {state}
                        </div>
                      );
                    } else {
                      name = container[column.id];
                    }
                  }
                  return <TableCell key={`row-${index}`}>{name}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

TableContainersMain.propTypes = {
  classes: PropTypes.object,
};
TableContainersMain.defaultProps = {
  selected: [],
  handleClickContainer: () => '',
  handleSelectedClick: () => '',
};
export default TableContainersMain;
