import React from 'react';
import PropTypes from 'prop-types';
import TableGrid from '../TableGrid';
import { rowsPerPageBig } from 'helpers';
import { columns } from './useStyles';
import { MainTableToolbar } from '../components';
import { ButtonDownloadType } from 'components';
class TableProjectMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_mapping: false,
      open_editor: false,
      idProject: -1,
      selected: [],
      project_objects: [],
      page: 0,
      limit: 100,
      total: 0,
      empty: false,
      type: 0,
      filter: null,
      headers_to_hide: [],
    };
    if (props.holder) {
      props.holder.cleanSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }
  handleHeadersToHide = headers_to_hide => {
    this.setState({ headers_to_hide });
  };
  handlePageChange = page => {
    this.setState({ page });
  };
  handleRowsPerPageChange = limit => {
    this.setState({ limit });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };
  handleDeleteSelected = event => {
    event.preventDefault();
    const { handleDeleteMembers } = this.props;
    const { selected } = this.state;
    if (handleDeleteMembers) {
      handleDeleteMembers(selected);
    }
  };
  render() {
    const {
      total,
      project_members,
      height,
      title,
      loading,
      deleting,
      download_type,
    } = this.props;
    const { selected } = this.state;
    let ComponentAdditional = null;
    if (download_type) {
      ComponentAdditional = (
        <ButtonDownloadType
          circular
          idProject={this.props.idProject}
          tooltip="Download CSV"
          type="project_members"
        />
      );
    }
    return (
      <React.Fragment>
        <MainTableToolbar
          ComponentAdditional={ComponentAdditional}
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={title}
        />
        <TableGrid
          checkboxSelection={this.props.checkboxSelection}
          dense={this.props.dense}
          disableSelectionOnClick
          external_selected={this.state.selected}
          filter={this.state.filter}
          headers={columns}
          headers_to_hide={this.state.headers_to_hide}
          height={height}
          loading={loading}
          onFilterChanded={filter => this.setState({ filter })}
          onHeadersToHideChanged={this.handleHeadersToHide}
          onPageChange={newPage => this.handlePageChange(newPage)}
          onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode={this.props.paginationMode}
          rowCount={total}
          rows={project_members.map(x => {
            return {
              id: x.user.id,
              ...x,
            };
          })}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </React.Fragment>
    );
  }
}

TableProjectMembers.propTypes = {
  dense: PropTypes.bool,
  download_type: PropTypes.bool,
  loading: PropTypes.bool,
  total: PropTypes.number,
};
TableProjectMembers.defaultProps = {
  handleDeleteMembers: () => '',
  handleReloadTable: () => '',
  paginationMode: 'client',
  checkboxSelection: true,
  project_members: [],
  total: 0,
  loading: false,
  download_type: false,
  dense: false,
};

export default TableProjectMembers;
