import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import {
  CommuncationToolbar,
  CommunicationDrawer,
  CommunicationList,
  GetIndexAtChannel,
  GetTypeString,
  IsDirectChatlHere,
} from './components';
import { CreateTeamChannel, QueryCommunications } from 'graphql/Communications';
import { GetData, getParams, ServerErrorsString } from 'helpers';
import { GetReceiverIndex } from './components/utils';

class CommunicationsLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receivers: [],
      creating: false,
    };
    const { holder } = props;
    if (holder) {
      holder.getSelected = (type, idReceiver) => {
        return this.getSelected(type, idReceiver);
      };
      holder.updateDirectMessage = new_direct => {
        this.updateDirectMessage(new_direct);
      };
    }
  }
  updateDirectMessage = new_direct => {
    const { receivers } = this.state;
    let { receiverId, sender, text, read } = new_direct;
    if (read === undefined) read = false;
    for (let i = 0; i < receivers.length; i++) {
      const { direct } = receivers[i];
      if (direct) {
        const id = direct.User.id;
        if (id === receiverId || id === sender.id) {
          if (receivers[i].direct.LastMessage) {
            if (receivers[i].direct.LastMessage.text === text) return;
            receivers[i].direct.LastMessage.text = text;
            receivers[i].direct.LastMessage.read = read;
            let total = receivers[i].direct.NewMessages;
            if (total === undefined) total = 0;
            receivers[i].direct.NewMessages = total + 1;
          } else
            receivers[i].direct.LastMessage = { text, read, NewMessages: 1 };
          console.log({ i, direct, r: receivers[i] });
          this.setState({ receivers });
          return;
        }
      }
    }
  };
  getSelected = (type, idReceiver) => {
    const { receivers } = this.state;
    const index = GetReceiverIndex(receivers, idReceiver, type);
    if (index !== -1) {
      return receivers[index];
    }
    return null;
  };

  componentDidMount() {
    this.handleLoadReceiver();
  }
  componentDidUpdate() {
    const { chat_messages } = this.props;
    if (chat_messages.length) {
      this.updateDirectMessage(chat_messages[0]);
    }
  }
  createChannel = team => {
    const { creating } = this.state;
    if (creating) {
      return;
    }
    if (!team) {
      this.setState({ error: 'You need to select a team first.' });
      return;
    }
    const { id, name } = team;
    if (typeof id === 'undefined' || name === '') {
      this.setState({ error: 'Something is wrong with the selected team.' });
      return;
    }
    this.setState({ creating: true });
    (async () => {
      const { receivers } = this.state;
      CreateTeamChannel(team)
        .then(res => {
          const data = GetData(res);
          const { ok, channel, errors } = data.createChannel;
          if (ok) {
            const newChannel = {
              id: receivers.length + 1,
              direct: null,
              channel,
            };
            this.setState({
              creating: false,
              receivers: [newChannel, ...receivers],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            creating: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  createDirectChat = user => {
    const { receivers } = this.state;
    const newChat = {
      id: receivers.length + 1,
      direct: {
        User: user,
        NewMessages: 0,
        LastMessage: null,
        createdAt: moment().valueOf(),
      },
      channel: null,
    };
    this.setState({ receivers: [newChat, ...receivers] });
  };
  handleAddReceiver = receiver => {
    console.log({ receiver });
    const { receivers } = this.state;
    const type = GetTypeString(receiver);

    if (type === 'Team') {
      const { team } = receiver;
      this.createChannel(team);
    } else if (type === 'User') {
      const { user } = receiver;
      if (receivers.length && IsDirectChatlHere(receivers, user)) {
        this.props.handleAddReceiver(receiver);
      } else {
        this.createDirectChat(user);
      }
    } else if (type === 'Channel') {
      const { channel } = receiver;
      const index = GetIndexAtChannel(receivers, channel);
      if (index !== -1) {
        this.props.handleAddReceiver(receivers[index]);
      } else {
        receivers.push({ id: receivers.length, channel, direct: null });
        this.setState({ receivers });
      }
    }
    // id: LongLong!
    // direct: DirectChat
    // channel: Channel
  };

  handleLoadReceiver = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryCommunications()
        .then(res => {
          const data = GetData(res);
          const { communications } = data;
          //
          this.setState({ loading: false, receivers: communications });
          this.updateSelected();
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  updateSelected = () => {
    const params = getParams(this.props);
    if (!params) return;
    const { type, id } = params;
    if (typeof type === 'undefined' || !type) return;
    const receiver = this.getSelected(type, parseInt(id, 10));
    if (!receiver) return;
    this.props.handleCommunicationRowSelection(receiver);
  };
  handleCommunicationRowSelection = receiver => {
    const { direct, channel } = receiver;
    const { receivers } = this.state;
    for (let i = 0; i < receivers.length; i++) {
      if (receivers[i].direct && direct) {
        if (receivers[i].direct.id === direct.id) {
          receivers[i].direct.NewMessages = 0;
          this.setState({ receivers });
          break;
        }
      } else if (receivers[i].channel && channel) {
        if (receivers[i].channel.id === channel.id) {
          receivers[i].channel.NewMessages = 0;
          this.setState({ receivers });
          break;
        }
      }
    }
    this.props.handleCommunicationRowSelection(receiver);
  };
  render() {
    const { isMobile, mobileOpen, selected, handleDrawerToggle } = this.props;
    const { receivers, creating, loading } = this.state;
    return (
      <CommunicationDrawer
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        mobileOpen={mobileOpen}>
        <CommuncationToolbar
          creating={creating}
          handleAddReceiver={this.handleAddReceiver}
          handleLoadReceiver={this.handleLoadReceiver}
          receiving={loading}
        />
        {loading ? (
          <Grid
            alignItems="center"
            container
            item
            justifyContent="center"
            spacing={3}
            style={{ minHeight: '50%' }}
            xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <CommunicationList
            handleCommunicationRowSelection={
              this.handleCommunicationRowSelection
            }
            receivers={receivers}
            selected={selected}
          />
        )}
      </CommunicationDrawer>
    );
  }
}

CommunicationsLeft.propTypes = {
  classes: PropTypes.object,
};
CommunicationsLeft.defaultProps = {
  handleCommunicationRowSelection: () => '',
  handleAddReceiver: () => '',
  selected: null,
};

const mapStateToProps = state => {
  const { page_activity } = state;
  let chat_messages = [];
  let size = 0;
  if (page_activity && page_activity.chat_messages) {
    chat_messages = page_activity.chat_messages.filter(x => x.read === false);
    size = chat_messages.length;
  }
  return {
    size,
    chat_messages,
  };
};

export default connect(mapStateToProps, null)(withRouter(CommunicationsLeft));
