import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/GetApp';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';

import { cleanCopySeries } from 'redux-store/actions/file_folder_manager';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import { GetData, GetResponseError, ServerErrorsString } from 'helpers';
import { SnackMessage } from 'components';

const useStyles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[800],
    position: 'absolute',
    top: -2,
    left: -2,
    zIndex: 1,
  },
});

class ButtonDownloadSelected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
  }

  handleDownload = () => {
    const { copy_series } = this.props;
    const seriesIds = copy_series.map(x => ({ idSeries: x.idSeries }));
    if (seriesIds.length === 0) {
      return;
    }
    this.setState({ loading: true });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(seriesIds);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            loading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          loading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key)
        .then(async response => {
          let { SeriesDescription } = copy_series[0];
          if (!SeriesDescription || copy_series.length > 1) {
            SeriesDescription = 'download_series';
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${SeriesDescription}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ loading: false });
          await MutationDownloadFinished(download_key);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };

  render() {
    const { copy_series, classes, size, badge_props } = this.props;
    if (!size) {
      return null;
    }
    const { loading, error } = this.state;
    return (
      <div className={classes.wrapper}>
        <SnackMessage
          handleClose={this.handleCloseSnak}
          message_text={error !== '' ? error : 'Unknown warning'}
          open={error !== '' ? true : false}
          type="error"
        />
        <Tooltip title="Download Selected Series">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls="download-selected-series"
              className={classes.root}
              onClick={this.handleDownload}
              size="large">
              <Badge badgeContent={copy_series.length} {...badge_props} />
            </IconButton>
          </span>
        </Tooltip>
        {loading && <CircularProgress className={classes.progress} size={52} />}
      </div>
    );
  }
}

ButtonDownloadSelected.propTypes = {
  badge_props: PropTypes.object,
  classes: PropTypes.object,
  copy_series: PropTypes.array,
};
ButtonDownloadSelected.defaultProps = {
  badge_props: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'primary',
    children: <DownloadIcon />,
  },
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { cleanCopySeries })(
  withStyles(ButtonDownloadSelected, useStyles)
);
