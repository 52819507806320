import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio } from '@mui/material';
class DateTimeRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const sylte_radio = {
      size: 'small',
      style: {
        margin: 0,
        padding: 0,
        paddingTop: 5,
      },
    };
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="center">
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 2 days"
          value="last_2_days"
        />

        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 7 Days"
          value="last_7_days"
        />

        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last month"
          value="last_month"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 3 months"
          value="last_3_months"
        />
      </Grid>
    );
  }
}

DateTimeRight.propTypes = {
  classes: PropTypes.object,
};

export default DateTimeRight;
