import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { FormatTime } from 'helpers';
import { useStyles } from './Style';
import TableRowStatus from './TableRowStatus';
import { CircularLoading } from 'components';

class TableActivity extends Component {
  getRowCheckBox = (index, notification) => {
    const { check } = this.props;
    if (!check) return null;
    const { handleClickActivity, isItemSelected } = this.props;
    return (
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected(notification)}
          color="default"
          inputProps={{ 'aria-labelledby': `${notification.id}` }}
          onClick={() => handleClickActivity(notification)}
        />
      </TableCell>
    );
  };

  render() {
    const {
      loading,
      classes,
      check,
      notifications,
      className,
      height,
    } = this.props;
    let checkbox_header = null;
    if (check) {
      const { rowCount, numSelected } = this.props;
      checkbox_header = (
        <TableCell padding="checkbox">
          {loading ? (
            <CircularLoading simple small />
          ) : (
            <Checkbox
              checked={rowCount ? numSelected === rowCount : false}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={this.props.handleSelectAllClick}
            />
          )}
        </TableCell>
      );
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <div style={{ overflow: 'auto' }}>
        {/* <PerfectScrollbar> */}
        <div
          className={clsx({
            [className]: className,
            [classes.inner]: !className,
            [classes.nowrap]: this.props.nowrap,
          })}
          style={style}>
          <Table size={this.props.dense ? 'small' : 'medium'} stickyHeader>
            <TableHead>
              <TableRow>
                {checkbox_header}
                <TableCell>Activity</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>From</TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.map((notification, index) => {
                const { From } = notification;
                let FullName = 'Missing';
                if (From) {
                  FullName = From.FullName;
                }
                return (
                  <TableRow
                    hover
                    key={notification.id}
                    style={{
                      paddingTop: this.props.dense ? 0 : '3px',
                    }}>
                    {this.getRowCheckBox(index, notification)}
                    <TableCell>{notification.name}</TableCell>
                    <TableCell>
                      <TableRowStatus notification={notification} />
                    </TableCell>
                    <TableCell>{FullName}</TableCell>
                    <TableCell>
                      {FormatTime(
                        notification.createdAt,
                        'MMM Do, YYYY (h:mm a)'
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    );
  }
}

TableActivity.propTypes = {
  check: PropTypes.bool,
  classes: PropTypes.object,
  handleClickActivity: PropTypes.func,
  height: PropTypes.number,
  isItemSelected: PropTypes.func,
  notifications: PropTypes.array,
};
TableActivity.defaultProps = {
  notifications: [],
  className: null,
  check: false,
  isItemSelected: () => '',
  handleClickActivity: () => '',
  handleSelectAllClick: () => '',
  numSelected: 0,
  rowCount: 0,
  height: 0,
  nowrap: false,
  dense: false,
};
export default withStyles(TableActivity, useStyles);
