import React from 'react';
import { Grid } from '@mui/material';

class PreviewDropped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showPreviews } = this.props;
    if (!showPreviews) return null;
    return (
      <React.Fragment>
        <Grid container>
          <span>Preview:</span>
        </Grid>
        {/* <PreviewList
                fileObjects={this.state.fileObjects}
                handleRemove={this.handleRemove.bind(this)}
                showFileNames={this.props.showFileNamesInPreview}
              /> */}
      </React.Fragment>
    );
  }
}

PreviewDropped.propTypes = {};

export default PreviewDropped;
