import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import { ServerErrorsString } from 'helpers';
import { TableHeaders, TableRows } from './components';
import { isEqual } from '../../../utils_shared';
import { green } from '@mui/material/colors';
const useStyles = () => ({
  table: {
    minWidth: 650,
  },
  check: {
    // padding: 0,
    margin: 0,
  },
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 4,
    left: 4,
    zIndex: 1,
  },
});
const default_download = {
  loading: false,
  item: null,
  progress: 0,
  loaded: 0,
  buffer: 10,
  downloaded: false,
  error: null,
  id_item: -1,
};
class ExplorerViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      download_in_progress: [],
    };
    if (props.holder) {
      props.holder.setProgress = this.handleProgress;
      props.holder.setDownload = this.handleDowloadClicked;
      props.holder.setFinished = this.handleDownloadFinished;
      props.holder.setInit = this.handleInitDownload;
      props.holder.setClean = () => {
        this.setState({ download_in_progress: [] });
      };
    }
  }
  handleInitDownload = new_downloads => {
    let new_download_in_progress = [];
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.download_in_progress = [];
    for (let i = 0; i < new_downloads.length; i++) {
      const { folder, content } = new_downloads[i];
      let item = null;
      if (folder) item = { idFolder: folder.idFolder };
      else if (content) item = { idFolderContent: content.idFolderContent };
      const id_item = this.getIndexContent(item);
      const new_item = {
        ...default_download,
        item,
        id_item,
      };
      new_download_in_progress.push(new_item);
      this.state.download_in_progress.push({ ...new_item });
    }
  };
  isSelected = row => {
    const { selected } = this.props;
    for (let i = 0; i < selected.length; i++) {
      if (isEqual(row, selected[i])) {
        // console.log({ row, s: selected[i] });
        return true;
      }
    }
    return false;
  };
  getIndexSelected = to_find_item => {
    let find = `${to_find_item.idFolder}-${to_find_item.idFolderContent}`;
    if (to_find_item.folder) {
      find = `${to_find_item.folder.idFolder}-${undefined}`;
    } else if (to_find_item.content) {
      find = `${undefined}-${to_find_item.content.idFolderContent}`;
    }
    const { download_in_progress } = this.state;
    for (let i = 0; i < download_in_progress.length; i++) {
      const { item } = download_in_progress[i];
      let index = `${item.idFolder}-${item.idFolderContent}`;
      if (item.folder) {
        index = `${item.folder.idFolder}-${undefined}`;
      } else if (item.content) {
        index = `${undefined}-${item.content.idFolderContent}`;
      }
      if (index === find) return i;
    }
    return -1;
  };
  handleProgress = ({ loaded, total }, loading, item) => {
    const { download_in_progress } = this.state;
    const index = this.getIndexSelected(item);
    if (total === 0 || isNaN(total) || total === undefined) {
      total = this.getSizeContent(item);
    }
    if (total < loaded) total = loaded + loaded * 0.5;
    const diff = Math.random() * 10;
    const diff2 = Math.random() * 10;
    let progress = Math.floor((loaded * 100) / total) + diff;
    let buffer = progress + diff + diff2;
    if (progress > 100)
      progress = Math.floor((loaded * 100) / (total + total * 0.1));
    // console.log({ index, loaded, loading, progress, total, item });
    if (progress >= 100) {
      progress = 0;
      buffer = 10;
    }
    if (index !== -1) {
      download_in_progress[index].loading = loading;
      download_in_progress[index].progress = progress;
      download_in_progress[index].buffer = buffer;
      download_in_progress[index].loaded = loaded;
    } else {
      const id_item = this.getIndexContent(item);
      download_in_progress.push({
        ...default_download,
        item: { ...item },
        progress,
        buffer,
        id_item: id_item,
        loaded,
      });
    }
    this.setState({ download_in_progress });
  };
  getIndexContent = item => {
    const { contents } = this.props;
    let find = `${item.idFolder}-${item.idFolderContent}`;
    if (item.folder) {
      find = `${item.folder.idFolder}-${undefined}`;
    } else if (item.content) {
      find = `${undefined}-${item.content.idFolderContent}`;
    }
    const index = contents
      .map(x => {
        let idFolder = undefined;
        let idFolderContent = undefined;
        if (x.folder) idFolder = x.folder.idFolder;
        else if (x.content) idFolderContent = x.content.idFolderContent;
        return `${idFolder}-${idFolderContent}`;
      })
      .indexOf(find);
    return index;
  };
  getSizeContent = item => {
    const { contents } = this.props;
    const index = this.getIndexContent(item);
    if (index === -1) return 0;
    const { folder, content } = contents[index];
    if (folder) return folder.Size;
    if (content) {
      if (content.Size) return content.Size;
      const { Series, File } = content;
      if (Series.Size) return Series.Size;
      if (File.Size) return File.Size;
    }
    return 0;
  };
  handleDowloadClicked = item_download => {
    const { download_in_progress } = this.state;
    try {
      const index = this.getIndexSelected(item_download);
      const id_item = this.getIndexContent(item_download);
      if (index !== -1) {
        if (download_in_progress[index].id_item !== id_item) {
          console.log('handleDowloadClicked - error:', {
            item: { ...download_in_progress[index] },
          });
        }
        download_in_progress[index].loading = true;
        download_in_progress[index].item = { ...item_download };
        download_in_progress[index].progress = 0;
        download_in_progress[index].downloaded = false;
        download_in_progress[index].buffer = 10;
        download_in_progress[index].error = null;
        download_in_progress[index].id_item = id_item;
      } else {
        download_in_progress.push({
          ...default_download,
          loading: true,
          item: { ...item_download },
          id_item: id_item,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };
  getProgress = item => {
    const { download_in_progress } = this.state;
    const index = this.getIndexSelected(item);
    if (index !== -1) {
      return download_in_progress[index];
    } else {
      return { loading: false, id_item: -1 };
    }
  };
  handleDownloadFinished = (item_download, error) => {
    const { download_in_progress } = this.state;
    try {
      const index = this.getIndexSelected(item_download);
      const id_item = this.getIndexContent(item_download);
      if (index !== -1) {
        if (download_in_progress[index].id_item !== id_item) {
          console.log('handleDownloadFinished - error:', {
            item: { ...download_in_progress[index] },
          });
        }
        download_in_progress[index].loading = false;
        download_in_progress[index].item = { ...item_download };
        download_in_progress[index].progress = 0;
        download_in_progress[index].downloaded = true;
        download_in_progress[index].error = error
          ? ServerErrorsString(error)
          : null;
      } else {
        throw Error('handleDownloadFinished: problem finding download item');
      }
    } catch (error) {
      console.log({ error });
    }
    this.setState({ download_in_progress });
  };
  getComputeProgress = (item, row) => {
    const { progress, buffer, loading, downloaded, id_item, loaded } = item;
    if (id_item < 0 || (downloaded && !loading)) return null;
    let value = progress;
    if (row.Size) {
      const progress_all = Math.floor((loaded * 100) / row.Size);
      if (progress > progress_all) value = progress_all;
    }
    return {
      value,
      buffer,
    };
  };
  render() {
    const {
      classes,
      contents,
      orderBy,
      order,
      handleSort,
      selected,
      handleSelectAll,
      handleSelected,
      height,
    } = this.props;
    const rowCount = contents.length;
    let checked = selected.length === rowCount;
    if (!selected.length) {
      checked = false;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <TableContainer component={Paper} style={style}>
        <Table
          aria-label="simple table"
          className={classes.table}
          size="small"
          stickyHeader>
          <TableHeaders
            checked={checked}
            classes={classes}
            handleSelectAll={handleSelectAll}
            handleSort={handleSort}
            order={order}
            orderBy={orderBy}
            rowCount={rowCount}
            selected={selected}
          />
          <TableBody>
            {contents.map((row, index) => {
              const { content, folder } = row;
              let item = null;
              if (content) {
                item = { idFolderContent: content.idFolderContent };
              } else if (folder) {
                item = { idFolder: folder.idFolder };
              }
              const download_item = this.getProgress(item);
              const progress = this.getComputeProgress(download_item, row);
              return (
                <React.Fragment key={`row-table-shared-${index}`}>
                  <TableRows
                    classes={classes}
                    download_item={download_item}
                    handleDoubleClick={this.props.handleDoubleClick}
                    handleDowloadClicked={this.handleDowloadClicked}
                    handleDownloadFinished={this.handleDownloadFinished}
                    handleProgress={this.handleProgress}
                    handleSelected={handleSelected}
                    index={index}
                    isSelected={this.isSelected}
                    item={item}
                    row={row}
                  />
                  {progress && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ padding: 0 }}>
                        <LinearProgress
                          value={progress.value}
                          valueBuffer={progress.buffer}
                          variant="buffer"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

ExplorerViewList.propTypes = {
  classes: PropTypes.object,
};
ExplorerViewList.defaultProps = {
  selected: [],
  handleSelectAll: () => '',
  handleSelected: () => '',
  handleDoubleClick: () => '',
};
export default withStyles(ExplorerViewList, useStyles);
