import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { withStyles } from 'tss-react/mui';
import { useStyles } from './useStyles';
import { SceneWebGL, DivCorner, LoadingProgress } from './components';

class ViewerWebGL2D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dicom_slice: null,
      zoom: 100,
      idSeries: -1,
      mouse: { x: 0, y: 0 },
      contrast: null,
    };
    this.keyEventPreventReboot = true;
    this.renderer = null;
    this.position = -1;
    if (props.holder) {
      props.holder.setMouseAction = action =>
        this.renderer.setMouseAction(action);
      props.holder.setViewerAction = action => this.renderer.setAction(action);
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDownEvent);
    document.addEventListener('keyup', this.handleKeyUpEvent);
    const noRightClick = document.getElementById(this.props.idViewer);
    noRightClick.addEventListener('contextmenu', e => e.preventDefault());
    this.renderer = new SceneWebGL(this.props);
    this.renderer.drawScene();
  }
  componentDidUpdate() {
    if (this.IsDifferent()) {
      const { series, dicom_slice, slice_position } = this.props;
      let contrast = null;
      if (this.state.idSeries === dicom_slice.getSeriesID()) {
        if (this.state.contrast) {
          dicom_slice.setContrast(this.state.contrast);
          contrast = this.state.contrast;
        }
      }
      this.renderer.updateSlice(dicom_slice);
      this.setState({
        contrast,
        dicom_slice,
        idSeries: series ? series.idSeries : -1,
        InstanceNumber: dicom_slice ? dicom_slice.InstanceNumber : -1,
        position: slice_position,
      });
    } else {
      this.renderer.drawScene();
    }
  }
  IsDifferent = () => {
    const { dicom_slice, series } = this.props;
    if (!dicom_slice) return false;
    if (dicom_slice && !this.state.dicom_slice) return true;
    const { idSeries, InstanceNumber } = this.state;
    if (series && idSeries !== series.idSeries) return true;
    if (dicom_slice.InstanceNumber !== InstanceNumber) return true;
    return false;
  };
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDownEvent, false);
    document.removeEventListener('keyup', this.handleKeyUpEvent, false);
    const noRightClick = document.getElementById(this.props.idViewer);
    noRightClick.removeEventListener(
      'contextmenu',
      e => e.preventDefault(),
      false
    );
  }
  handleKeyDownEvent = event => {
    if (this.keyEventPreventReboot) {
      this.keyEventPreventReboot = false;
      console.log({ keyDown: event.key });
      this.renderer.keyEvent(event.key, 'down');
      if (event.key === 'R' || event.key === 'r') {
        this.setState({ zoom: 100 });
      }
    }
  };
  handleKeyUpEvent = event => {
    if (!this.keyEventPreventReboot) {
      this.keyEventPreventReboot = true;
      console.log({ keyUp: event.key });
      this.renderer.keyEvent(event.key, 'up');
    }
  };
  handleMouseMoveEvent = event => {
    event.persist();
    const mouseEvent = event.nativeEvent;
    this.renderer.mouseEvent(mouseEvent, 'move');
    if (this.renderer.IsZoom()) {
      this.setState({ zoom: this.renderer.getZoom() });
    } else {
      let contrast = this.state.contrast;
      if (this.renderer.IsContrast()) {
        contrast = this.renderer.getContrast();
        console.log({ contrast });
      }
      const mouse = this.renderer.getMouse();
      this.setState({ mouse, contrast });
    }
  };
  handleMouseDownEvent = event => {
    event.persist();
    const mouseEvent = event.nativeEvent;
    event.preventDefault();
    event.stopPropagation();
    this.renderer.mouseEvent(mouseEvent, 'down');
  };
  handleMouseUpEvent = event => {
    event.persist();
    const mouseEvent = event.nativeEvent;
    event.preventDefault();
    this.renderer.mouseEvent(mouseEvent, 'up');
  };
  handleMouseOutEvent = () => {
    console.log('handleMouseOutEvent');
    this.renderer.mouseEvent(null, 'out');
  };
  handleMouseWheelEvent = event => {
    event.persist();
    const mouseEvent = event.nativeEvent;
    event.preventDefault();
    const { series } = this.props;
    const { mouse } = this.state;
    let NumberOfSlices = 0;
    let delta = 1;
    if (series) {
      NumberOfSlices = series.NumberOfSeriesRelatedInstances;
    }
    if (mouseEvent.detail) {
      mouse.wheel = Math.round(mouseEvent.detail);
      this.position = this.position + mouse.wheel;
    } else {
      delta = mouseEvent.deltaY;
      if (Math.abs(delta) > 2) delta = delta / Math.abs(delta);
      // mouse.wheel = Math.round(mouseEvent.deltaY / 3);
      mouse.wheel = Math.round(delta);
      //      let value = mouse.wheel % 4;
      //      if (!value) value = mouse.wheel % 3; check this for mac trackpad
      // delta = mouse.wheel > 0 ? 1 : -1;
      // this.position = this.position + delta;
      this.position = this.position + (mouse.wheel % 3);
    }
    // console.log({
    //    mouse,
    //    wheel: mouse.wheel,
    //    whele_3: mouse.wheel % 3,
    //    deltaY: mouseEvent.deltaY,
    //    detail: mouseEvent.detail,
    //  });
    if (this.position < 0) {
      this.position = 0;
    }
    if (this.position > NumberOfSlices - 1) {
      this.position = NumberOfSlices - 1;
    }
    this.props.updatePosition(this.position);
    console.log({
      delta,
      wheel: mouse.wheel,
      position: this.position,
      NumberOfSlices,
    });
    this.renderer.mouseEvent(mouseEvent, 'wheel');
  };
  render() {
    let { dicom_slice, zoom, mouse } = this.state;
    const { classes, width, height, idViewer, series } = this.props;
    // console.log({ dicom_slice });
    let imW = 0;
    let imH = 0;
    let NumberOfSlices = 0;
    let InstanceNumber = 0;
    if (series) {
      NumberOfSlices = series.NumberOfSeriesRelatedInstances;
    }
    if (dicom_slice) {
      imW = dicom_slice.width;
      imH = dicom_slice.height;
      InstanceNumber = dicom_slice.InstanceNumber;
    } else {
      dicom_slice = {};
    }
    let size = width / 3;
    if (width > height) size = height / 3;
    let ComponentProgress = null;
    if (this.props.progress !== 100) {
      ComponentProgress = (
        <LoadingProgress
          height={height}
          size={size}
          value={this.props.progress}
          width={width}
        />
      );
    }
    return (
      <div className={classes.root} style={{ width, height }}>
        {/* <WebGlSliders renderer={this.renderer} /> */}
        <canvas
          className={classes.canvas_container}
          id={idViewer}
          onKeyDown={this.handleKeyDownEvent}
          onKeyUp={this.handleKeyUpEvent}
          onMouseDown={this.handleMouseDownEvent}
          onMouseMove={this.handleMouseMoveEvent}
          onMouseOut={this.handleMouseOutEvent}
          onMouseUp={this.handleMouseUpEvent}
          onWheel={this.handleMouseWheelEvent}
          style={{ width, height }}
        />
        {ComponentProgress}
        <DivCorner
          line_bottom={`x: ${mouse.x}, y: ${mouse.y}, value: 0`}
          line_middel={`View size: ${width}x${height}`}
          line_top={`Image size: ${imW}x${imH}`}
        />
        <DivCorner
          bottom
          line_bottom={`Thickness: ${
            dicom_slice.SliceThickness
              ? parseFloat(dicom_slice.SliceThickness, 10).toFixed(4)
              : 'Unknown'
          } `}
          line_middel={`Zoom: ${zoom} %`}
          line_top={`Image: ${InstanceNumber}/${NumberOfSlices} Series: ${
            dicom_slice.SeriesNumber ? dicom_slice.SeriesNumber : 'Unknown'
          }`}
          SliceThickness
        />
        <DivCorner
          bottom
          line_bottom={`TE: ${
            dicom_slice.EchoTime ? dicom_slice.EchoTime : 'Unknown'
          } TR: ${
            dicom_slice.RepetitionTime ? dicom_slice.RepetitionTime : 'Unknown'
          }`}
          line_middel={` FS: ${
            dicom_slice.MagneticFieldStrength
              ? dicom_slice.MagneticFieldStrength
              : 'Unknown'
          } `}
          line_top={` StudyDate: ${
            dicom_slice.StudyDate ? dicom_slice.StudyDate : 'Unknown'
          } `}
          right
        />
        <DivCorner
          line_bottom={`ProtocolName: ${
            dicom_slice.ProtocolName ? dicom_slice.ProtocolName : 'Unknown'
          } `}
          line_middel={`StudyDescription: ${
            dicom_slice.StudyDescription
              ? dicom_slice.StudyDescription
              : 'Unknown'
          } `}
          line_top={`Name: ${
            dicom_slice.PatientName ? dicom_slice.PatientName : 'Unknown'
          } `}
          right
        />
      </div>
    );
  }
}

ViewerWebGL2D.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
ViewerWebGL2D.defaultProps = {
  idViewer: uuid(),
  height: 300,
  width: 300,
  dicom_slice: null,
  progress: 100,
};

export default withStyles(ViewerWebGL2D, useStyles);
