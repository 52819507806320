import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TableObjectItem, TablePermissionItem } from './components';
import useStyles from './components/Styles';
// import { ButtonCircularDownloadFolderContent } from 'components';
import {
  GetContentDate,
  GetContentKind,
  GetContentSize,
  // IsFile,
} from 'helpers/utils_explorer';

class ExplorerViewListItem extends Component {
  handleOnDoubleClick = () => {
    const { row } = this.props;
    if (row === undefined || !row) return;
    const { idFolder } = row;
    if (idFolder) {
      this.props.handleOpenFolder(row);
    } else {
      this.props.handleOpenAtDoubleClick(row);
    }
  };
  render() {
    const {
      classes,
      isItemSelected,
      labelId,
      dense,
      handleSelected,
      handleOnRowClick,
      // disabled,
      row,
      rights,
      isMobileSmall,
      open_explorer,
      columns,
      to_filters,
      isContextMenu,
      idHomeFolder,
    } = this.props;
    // let ButtonClick = null;
    // if (IsFile(row)) {
    //   ButtonClick = (
    //     <ButtonCircularDownloadFolderContent
    //       content={this.props.row}
    //       handleDownloadTriggered={() =>
    //         this.props.handleContentView(this.props.row)
    //       }
    //     />
    //   );
    // } else {
    //   ButtonClick = (
    //     <Button
    //       className={clsx({
    //         [classes.button_no_dense]: true,
    //         [classes.button_dense]: dense,
    //       })}
    //       color="primary"
    //       disabled={disabled}
    //       onClick={() => this.props.handleContentView(this.props.row)}
    //       variant="outlined">
    //       {row.kind === '--' ? 'Open' : 'View'}
    //     </Button>
    //   );
    // }
    if (!rights && to_filters.indexOf('rights') === -1) {
      to_filters.push('rights');
    }
    //
    return (
      <TableRow
        className={classes.tableRow}
        hover
        key={`table-row-${row.id}`}
        onClick={() => handleOnRowClick(row)}
        onDoubleClick={this.handleOnDoubleClick}
        selected={isItemSelected || isContextMenu}
        style={{ height: dense ? 33 : 53 }}
        tabIndex={-1}>
        <TableCell className={classes.tableCell} padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={() => {
              handleSelected(row);
            }}
          />
        </TableCell>
        {columns
          .filter(x => to_filters.indexOf(x.id) === -1)
          .map((column, index) => {
            const { align, id } = column;
            let margin_right = false;
            let text = '';
            let res = {};
            if (id === 'updatedAt') {
              text = GetContentDate(row, 'LLLL', 'updatedAt');
            } else if (id === 'Size') {
              text = GetContentSize(row);
              margin_right = true;
            } else if (id === 'Kind') {
              text = GetContentKind(row);
              margin_right = true;
            } else if (id === 'name') {
              res = {
                component: 'th',
                id: labelId,
                padding: 'none',
                scope: 'row',
              };
              if (column.styles) res.style = column.styles;
              text = (
                <TableObjectItem
                  column={column}
                  handleOpenFolder={this.props.handleOpenFolder}
                  idHomeFolder={idHomeFolder}
                  isDense={dense}
                  isMobileSmall={isMobileSmall}
                  open_explorer={open_explorer}
                  row={row}
                />
              );
            } else if (id === 'rights') {
              text = <TablePermissionItem dense row={row} />;
            } else if (id === 'files') {
              const { File, idFolder, Series } = row;
              if (File) {
                text = 1;
              } else if (idFolder) {
                text = 0;
                if (row.total_sub_folders) text += row.total_sub_folders;
                if (row.total_files) text += row.total_files;
              } else if (Series) {
                text = Series.NumberOfSeriesRelatedInstances;
              } else {
                text = 1;
              }
              if (text === undefined) {
                text = 0;
              }
            }
            return (
              <TableCell
                align={align ? align : 'left'}
                className={clsx({
                  [classes.tableCell]: true,
                  [classes.margin_right]: margin_right,
                })}
                key={`explorer-list-view-item-${index}`}
                onContextMenuCapture={event =>
                  this.props.onContextMenu(event, row)
                }
                {...res}>
                {text}
              </TableCell>
            );
          })}
      </TableRow>
    );
  }
}

ExplorerViewListItem.propTypes = {
  classes: PropTypes.object,
  rights: PropTypes.bool.isRequired,
};
ExplorerViewListItem.defaultProps = {
  handleContentView: () => '',
  handleSelected: () => '',
  handleOnRowClick: () => '',
  handleOpenFolder: () => '',
  loading: false,
  isItemSelected: false,
  labelId: 0,
  dense: true,
  projectId: null,
  downloading: false,
  disabled: false,
  rights: true,
  to_filters: [],
  isContextMenu: false,
};

export default withStyles(ExplorerViewListItem, useStyles);
