import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tabs, Tab } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TabQueryImages, TabQueryTypes } from './components';
import { TabPanel } from 'components';
const useStyles = () => ({
  tab: {},
});
class QueryRetrieve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: 'Images', TabComponent: TabQueryImages },
        { name: 'Types', TabComponent: TabQueryTypes },
      ],
      tabValue: 0,
    };
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    if (tabValue === undefined) tabValue = 0;
    this.setState({ tabValue: parseInt(tabValue, 10) });
  };
  render() {
    const { classes, height } = this.props;
    const { tabValue } = this.state;
    return (
      <React.Fragment>
        <Paper square style={{ marginBottom: 1 }}>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            size="small"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((x, index) => {
              return (
                <Tab
                  className={classes.tab}
                  key={`query-retrieves-tab-${index}`}
                  label={x.name}
                />
              );
            })}
          </Tabs>
        </Paper>
        {this.state.tabs.map((tab, index) => {
          const { TabComponent } = tab;
          return (
            <TabPanel
              index={index}
              key={`tab-query-panel-${index}`}
              value={tabValue}>
              <TabComponent
                handleAddError={this.props.handleAddError}
                handleAddSuccess={this.props.handleAddSuccess}
                handleAddWarning={this.props.handleAddWarning}
                height={height}
              />
            </TabPanel>
          );
        })}
      </React.Fragment>
    );
  }
}

QueryRetrieve.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(QueryRetrieve, useStyles);
