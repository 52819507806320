import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import SearchAutocomplete from '../SearchAutocomplete';
import { useStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryProjectCenterTeams } from 'graphql/Projects';
import { withStyles } from 'tss-react/mui';
class SearchProjectCenterTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: '',
      open: false,
      center_team: null,
      project_center_teams: [],
      idProject: null,
    };
    if (props.holder) {
      props.holder.getFolderCenterTeam = idCenterTeam => {
        const { project_center_teams } = this.state;
        const index = project_center_teams
          .map(x => x.idCenterTeam)
          .indexOf(idCenterTeam);
        if (index === -1) return null;
        return project_center_teams[index].folder_center;
      };
      props.holder.cleanSelected = () => {
        this.setState({ center_team: null, project_center_teams: [] });
      };
    }
  }
  componentDidMount() {
    this.handleQueryCenterTeams();
  }
  componentDidUpdate() {
    const { idProject } = this.state;
    if (!idProject || idProject !== this.props.idProject) {
      this.handleQueryCenterTeams();
    }
  }
  handleQueryCenterTeams = () => {
    const { idProject } = this.props;
    if (idProject === undefined || !idProject) return;
    let { loading, name } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const input_query = {};
    if (name && name !== '') {
      input_query.name_team = name;
    }
    (async () => {
      QueryProjectCenterTeams(idProject, input_query)
        .then(res => {
          const data = GetData(res);
          // console.log({ data });
          const {
            ok,
            errors,
            total,
            project_center_teams,
          } = data.projectCenterTeams;
          if (ok) {
            let folder = null;
            let center_team = this.state.center_team;
            if (
              this.state.project_center_teams.length === 0 &&
              !center_team &&
              project_center_teams.length
            ) {
              center_team = project_center_teams[0].center_team;
              if (center_team) {
                const index = project_center_teams
                  .map(x => x.idCenterTeam)
                  .indexOf(center_team.id);
                if (index !== -1)
                  folder = project_center_teams[index].folder_center;
              }
            }
            this.setState({
              loading: false,
              total,
              project_center_teams,
              idProject,
              center_team,
              name,
            });
            if (center_team)
              this.props.handleAddCenterTeam(center_team, folder);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject, name });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handelCenterNameChanged = event => {
    const { project_center_teams } = this.state;
    const name = event.target.value;
    const options = project_center_teams.map(x => x.center_team).filter(x => x);
    if (options.length) {
      const here = options.filter(x => {
        return x.team.name.toUpperCase().startsWith(name.toUpperCase());
      });
      if (!here.length || !name.length) {
        this.setState({ project_center_teams: [] });
      }
    }
    this.setState({ name });
  };
  handleAddCenterTeam = center_team => {
    const { project_center_teams } = this.state;
    let folder = null;
    if (center_team) {
      const index = project_center_teams
        .map(x => x.idCenterTeam)
        .indexOf(center_team.id);
      if (index !== -1) folder = project_center_teams[index].folder_center;
    }
    this.setState({ center_team });
    this.props.handleAddCenterTeam(center_team, folder);
  };
  isOptionEqualToValue = (option, value) => {
    if (option === undefined || !option) return false;
    const { team } = option;
    if (team === undefined || !team) return false;
    return team.name === value.team.name;
  };
  getOptionLabel = option => {
    if (option === undefined || !option) return '';
    const { team } = option;
    if (team === undefined || !team) return '';
    return team.name;
  };
  render() {
    const { classes, style } = this.props;
    const {
      loading,
      name,
      open,
      center_team,
      project_center_teams,
    } = this.state;
    const options = project_center_teams.map(x => x.center_team);
    if (this.props.no_icon) {
      return (
        <SearchAutocomplete
          getOptionLabel={this.getOptionLabel}
          handelSearchNameChanged={this.handelCenterNameChanged}
          handleAddResultObject={this.handleAddCenterTeam}
          isOptionEqualToValue={this.isOptionEqualToValue}
          label="Center Teams"
          loading={loading}
          name={name}
          no_icon
          open={open}
          options={options}
          placeholder={this.props.placeholder}
          selected={center_team}
          setObject={this.handleAddCenterTeam}
          setObjectName={name => this.setState({ name })}
          setOpen={open => this.setState({ open })}
          small={this.props.small}
        />
      );
    }
    return (
      <Paper className={classes.root} style={style}>
        <SearchIcon className={classes.icon} />
        <SearchAutocomplete
          getOptionLabel={this.getOptionLabel}
          handelSearchNameChanged={this.handelCenterNameChanged}
          handleAddResultObject={this.handleAddCenterTeam}
          isOptionEqualToValue={this.isOptionEqualToValue}
          label="Center Teams"
          loading={loading}
          name={name}
          open={open}
          options={options}
          placeholder={this.props.placeholder}
          selected={center_team}
          setObject={this.handleAddCenterTeam}
          setObjectName={name => this.setState({ name })}
          setOpen={open => this.setState({ open })}
          small={this.props.small}
          variant="standard"
        />
      </Paper>
    );
  }
}

SearchProjectCenterTeams.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchProjectCenterTeams.defaultProps = {
  placeholder: 'Search Project Teams...',
  no_icon: false,
  handleAddError: () => '',
  handleAddCenterTeam: () => '',
};
export default withStyles(SearchProjectCenterTeams, useStyles);
