import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { StyleText } from 'components';

class StatusDashboardText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { setting, filters } = this.props;
    if (!setting || setting === undefined) return null;
    const { level, project } = setting;
    if (!level || level === undefined) return null;
    // console.log({ level, filters });
    let ProjectName = null;
    if (level.index_level === 1) {
      let text = 'All';
      if (project) text = project.name;
      if (filters) text = 'Filtered';
      ProjectName = (
        <Grid container item style={{ minWidth: 180, maxWidth: 250 }} xs={6}>
          <Grid item style={{ maxWidth: 90 }}>
            <StyleText fontSize={16} fontWeight={1000} style text="Selected:" />
          </Grid>
          <Grid item style={{ paddingRight: 10 }}>
            <StyleText fontSize={16} fontWeight={100} style text={text} />
          </Grid>
        </Grid>
      );
    }
    if (filters) level.name = 'Filtered';
    return (
      <Grid container style={{ marginTop: 8 }}>
        <Grid container item style={{ minWidth: 150, maxWidth: 170 }} xs={6}>
          <Grid item style={{ maxWidth: 80 }}>
            <StyleText fontSize={16} fontWeight={1000} style text="Display:" />
          </Grid>
          <Grid item style={{ paddingRight: 10, maxWidth: 80 }}>
            <StyleText fontSize={16} fontWeight={100} style text={level.name} />
          </Grid>
        </Grid>
        {ProjectName}
      </Grid>
    );
  }
}

StatusDashboardText.propTypes = {
  text: PropTypes.string,
};

export default StatusDashboardText;
