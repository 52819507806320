import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { IconButtonLoad } from 'components';
import { Replay as ReloadIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MutationAddUsersDockers,
  MutationRemoveUsersDockers,
} from 'graphql/Users';
import { GetData, ServerErrorsString } from 'helpers';

class ToolBarUserDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  saveDockerUser = () => {
    const { saving, state, user } = this.props;
    if (saving) return;
    const { dockers, new_images } = state;
    this.props.setState({ saving: true });
    const inputs = new_images.map(x => {
      return {
        idDockerImage: x.id,
        idUser: user.id,
      };
    });
    (async () => {
      MutationAddUsersDockers(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addUserDockers;
          if (ok) {
            this.props.setState({
              saving: false,
              new_images: [],
              dockers: new_images.concat(dockers),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDelete = () => {
    let { dockers, new_images, selected, deleting } = this.props.state;
    new_images = new_images.filter(x => {
      const i = selected.map(x => x.id).indexOf(x.id);
      if (i !== -1) selected.splice(x, 1);
      return i === -1;
    });
    console.log({ selected });
    if (!selected.length) {
      this.props.setState({ deleting: false, new_images });
      return;
    }
    const { user } = this.props;
    if (deleting) return;
    this.props.setState({ deleting: true });
    (async () => {
      MutationRemoveUsersDockers(
        selected.map(x => {
          return {
            idDockerImage: x.id,
            idUser: user.id,
          };
        })
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeUserDockers;
          if (ok) {
            this.props.setState({
              deleting: false,
              new_images,
              dockers: dockers.filter(x => {
                const i = selected.map(x => x.id).indexOf(x.id);
                return i === -1;
              }),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, new_images, saving, state } = this.props;
    const disabled = new_images.length ? false : true;
    const { selected, deleting } = state;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <IconButtonLoad
          handleClick={this.handleDelete}
          icon={<DeleteIcon style={{ color: 'red' }} />}
          loading={deleting}
          small
          tooltip="Delete Selected"
        />
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        p={1}
        spacing={1}>
        <Grid container item xs={6}>
          <IconButtonLoad
            disabled={disabled || saving}
            handleClick={this.saveDockerUser}
            icon={<SaveIcon style={{ color: disabled ? 'gray' : 'blue' }} />}
            loading={saving}
            small
            tooltip="Save Docker Image"
          />
          <IconButtonLoad
            handleClick={this.props.onOpenDialog}
            icon={<AddIcon style={{ color: 'green' }} />}
            small
            tooltip="Add Docker Image"
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={6}>
          {ComponentDelete}
          <IconButtonLoad
            handleClick={this.props.onReloadTable}
            icon={<ReloadIcon />}
            loading={loading}
            small
            tooltip="Reload Table"
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarUserDocker.propTypes = {
  new_images: PropTypes.array,
};
ToolBarUserDocker.defaultProps = {
  new_images: [],
};

export default ToolBarUserDocker;
