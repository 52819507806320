import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ButtonReload } from 'components';

const useStyles = theme => ({
  margin: {
    margin: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  error: {
    padding: 0,
    margin: 0,
    color: red[500],
  },
});

class ButtonReloadDetails extends Component {
  render() {
    const { classes, ok, reloadDetails } = this.props;
    return (
      <div>
        {!ok && (
          <Tooltip title="Error accessing details">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                aria-label="delete"
                className={classes.error}
                size="large">
                <ErrorOutlineIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <ButtonReload
          className={classes.margin}
          handleReload={reloadDetails}
          tooltip="Reload details"
        />
      </div>
    );
  }
}

ButtonReloadDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  ok: PropTypes.bool.isRequired,
  reloadDetails: PropTypes.func,
};
ButtonReloadDetails.defaultProps = {
  ok: false,
};
export default withStyles(ButtonReloadDetails, useStyles);
