import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { TableGrid, TextFieldCustom } from 'components';
import { columns } from './useStyles';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import { QueryProjectVariableTypes } from 'graphql/Projects/utils_project_types';

import { ToolbarProjectVariableTypes, GridEditedRows } from './components';
class ProjectSettingVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      open_delete: false,
      updating: false,
      headers_to_hide: [],
      filter: null,
      types: [],
      new_types: [],
      selected: [],
      loading: false,
      page: 0,
      limit: 100,
      total: 0,
      rows_edited: [],
      init: false,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    this.updateProjectsTypes(this.props.idProject);
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (this.state.idProject !== this.props.idProject) {
      this.updateProjectsTypes(this.props.idProject);
    }
  }
  updateProjectsTypes = (idNewProject, newPage, newLimit) => {
    let { loading, name, page, limit, idProject } = this.state;
    if (loading) return;
    if (idNewProject) idProject = idNewProject;
    if (idProject === undefined || !idProject) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectVariableTypes(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types, total } = data.projectVariableTypes;
          if (ok) {
            this.setState({
              idProject,
              loading: false,
              page,
              limit,
              types,
              total,
              rows_edited: [],
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ idProject, loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddVariableType = type => {
    if (type === undefined || !type) {
      return;
    }
    const { types, new_types } = this.state;
    let i = new_types.map(x => x.idType).indexOf(type.id);
    let j = types.map(x => x.idType).indexOf(type.id);
    if (i === -1 && j === -1) {
      const total = types.length + new_types.length;
      new_types.push({ id: -(total + 1), idType: type.id, type });
    } else {
      this.props.handleAddError(`The type ${type.name} is already here`);
    }
    this.setState({ types, new_types });
  };
  handleChange = prop => event => {
    const { project_setting } = this.props;
    const new_setting = {
      ...project_setting,
      [prop]: event.target.value,
    };
    // console.log({ [prop]: event.target.value });
    this.props.handleUpdateProjectSetting(new_setting);
  };
  handleChangeCheck = event => {
    const { project_setting } = this.props;
    const new_setting = {
      ...project_setting,
      patient_name_id: event.target.checked,
    };
    // console.log({ [prop]: event.target.value });
    this.props.handleUpdateProjectSetting(new_setting);
  };
  render() {
    if (!this.props.isActive) return null;
    const {
      types,
      page,
      limit,
      idProject,
      loading,
      new_types,
      selected,
    } = this.state;
    const { height, project_setting } = this.props;
    let full_types = types.concat(new_types);
    let total = full_types.length;
    let patient_code = '';
    let patient_name_id = false;
    // console.log({ project_setting });
    if (project_setting && !loading) {
      patient_code = project_setting.patient_code;
      if (project_setting.patient_name_id)
        patient_name_id = project_setting.patient_name_id;
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={1}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="center"
            spacing={1}
            xs={12}>
            <Grid item xs={8}>
              <TextFieldCustom
                id="project-patient-code"
                label="Patient Code"
                onChange={this.handleChange('patient_code')}
                value={patient_code ? patient_code : ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={patient_name_id}
                    onChange={this.handleChangeCheck}
                  />
                }
                label="Equal Name and ID"
              />
            </Grid>
          </Grid>
          <ToolbarProjectVariableTypes
            handleAddError={this.props.handleAddError}
            handleAddVariableType={this.handleAddVariableType}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleReloadTable={() => this.updateProjectsTypes()}
            idProject={this.state.idProject}
            new_types={this.state.new_types}
            rows_edited={this.state.rows_edited}
            selected={selected}
            total={this.state.total}
            types={this.state.types}
            updateStates={state => this.setState(state)}
          />
          <GridEditedRows
            idProject={this.state.idProject}
            rows_edited={this.state.rows_edited}
            types={this.state.types}
            updateStates={state => this.setState(state)}
          />
          <Grid item xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={this.state.rows_edited}
              external_selected={this.state.selected}
              filter={this.state.filter}
              headers={columns}
              headers_to_hide={this.state.headers_to_hide}
              height={height}
              loading={loading}
              onFilterChanded={filter => this.setState({ filter })}
              onPageChange={newPage =>
                this.updateProjectsTypes(idProject, newPage, limit)
              }
              onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
              onRowsPerPageChange={newLimit =>
                this.updateProjectsTypes(idProject, 0, newLimit)
              }
              onSelectedChanged={selected => this.setState({ selected })}
              page={page}
              paginationMode="server"
              rowCount={total}
              rows={full_types}
              rowsPerPage={limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ProjectSettingVariableTypes.propTypes = {
  classes: PropTypes.object,
};
ProjectSettingVariableTypes.defaultProps = {
  handleAddError: () => '',
};
export default ProjectSettingVariableTypes;
