import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components';
import {
  UserViewProjects,
  UserViewPlan,
  UserViewDashboard,
  UserViewSessions,
  UserViewCenters,
  UserViewTeams,
  UserViewDockers,
  UserViewClinical,
} from './components';

class UserViewRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        { name: 'Plan', TabComponent: UserViewPlan },
        { name: 'Projects', TabComponent: UserViewProjects },
        { name: 'Dashboard', TabComponent: UserViewDashboard },
        { name: 'Sessions', TabComponent: UserViewSessions },
        { name: 'Centers', TabComponent: UserViewCenters },
        { name: 'Teams', TabComponent: UserViewTeams },
        { name: 'Dockers', TabComponent: UserViewDockers },
        { name: 'Clinical', TabComponent: UserViewClinical },
      ],
    };
  }

  render() {
    const { tabValue, tabs } = this.state;
    const { classes, loading, setting, edit_user, height } = this.props;
    return (
      <Paper square style={{ height: height ? height : '100%' }}>
        <Tabs
          indicatorColor="primary"
          onChange={(e, tabValue) => this.setState({ tabValue })}
          textColor="primary"
          value={tabValue}
          variant="scrollable">
          {tabs.map((tab, index) => {
            return (
              <Tab key={`tab-user-view-right-${index}`} label={tab.name} />
            );
          })}
        </Tabs>
        <Divider />
        {tabs.map((tab, index) => {
          const { TabComponent } = tab;
          return (
            <TabPanel
              index={index}
              key={`tab-user-view-component-${index}`}
              value={tabValue}>
              <TabComponent
                classes={classes}
                edit_user={edit_user}
                handleAddError={this.props.handleAddError}
                handleChange={this.props.handleChange}
                handleChangeDashboard={this.props.handleChangeDashboard}
                handleUserRequest={this.props.handleUserRequest}
                height={height}
                holder={this.props.holder}
                loading={loading}
                setting={setting}
              />
            </TabPanel>
          );
        })}
      </Paper>
    );
  }
}

UserViewRight.propTypes = {
  classes: PropTypes.object,
};

export default UserViewRight;
