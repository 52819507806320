import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  CreateNewUserBottom,
  CreateNewUserHeader,
  useStyles,
} from './components';
import { Countries, Units, UserTitles } from 'components';

const default_user = {
  firstName: '',
  lastName: '',
  country: '',
  email: '',
  password: false,
  idUnit: -1,
  Message: '',
};

class CreateNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      user: { ...default_user },
    };
  }

  handleClickTitles = title => {
    const { user } = this.state;
    this.setState({
      user: { ...user, title },
    });
  };
  hasError = () => {
    return false;
  };
  handleCountryChanged = country_new => {
    let country = '';
    if (country_new) {
      country = country_new.name;
    }
    const { user } = this.state;
    this.setState({
      user: { ...user, country },
    });
  };
  IsChanged = () => {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.state.user)) {
      if (value.constructor === Number) {
        if (value > 0) count++;
        continue;
      }
      if (value.length) count++;
    }
    return count === 7;
  };
  handleChange = event => {
    const { user } = this.state;
    this.setState({
      user: { ...user, [event.target.name]: event.target.value },
    });
  };
  handleUnitChanged = unit => {
    let idUnit = -1;
    if (unit) {
      idUnit = unit.idUnit;
    }
    const { user } = this.state;
    this.setState({
      user: { ...user, idUnit },
    });
  };
  handleAddNewUser = new_user => {
    this.setState({
      uploading: false,
      user: { ...default_user },
    });
    this.props.handleAddNewUser(new_user);
  };

  render() {
    const { classes, height } = this.props;
    const { user } = this.state;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    return (
      <Card className={classes.card} style={style}>
        <CreateNewUserHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateUser}
          //   loading={this.state.loading}
        />
        <Divider />
        <CardContent>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}>
            <Grid item xs={3}>
              <UserTitles onChange={this.handleClickTitles} width="150px" />
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <TextField
                  // error={this.hasError('firstName')}
                  fullWidth
                  // helperText={
                  //   this.hasError('firstName')
                  //     ? formState.errors.firstName[0]
                  //     : null
                  // }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="First name"
                  name="firstName"
                  onChange={this.handleChange}
                  placeholder="Enter your first name"
                  type="text"
                  value={user.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // className={classes.textField}
                  // error={this.hasError('lastName')}
                  fullWidth
                  // helperText={
                  //   this.hasError('lastName') ? formState.errors.lastName[0] : null
                  // }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Last name"
                  name="lastName"
                  onChange={this.handleChange}
                  placeholder="Enter your first last"
                  type="text"
                  value={user.lastName}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Countries
                // error={
                //   this.hasError('country') ? formState.errors.country[0] : null
                // }
                handleCountryChanged={this.handleCountryChanged}
                hasError={this.hasError}
                style={{ width: 300 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={this.hasError('email')}
                fullWidth
                // helperText={
                //   this.hasError('email') ? formState.errors.email[0] : null
                // }
                InputLabelProps={{
                  shrink: true,
                }}
                label="Email address"
                name="email"
                onChange={this.handleChange}
                placeholder="Enter a valid email address"
                type="text"
                value={user.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={this.hasError('password')}
                fullWidth
                // helperText={
                //   this.hasError('password') ? formState.errors.password[0] : null
                // }
                InputLabelProps={{
                  shrink: true,
                }}
                label="Password"
                name="password"
                onChange={this.handleChange}
                placeholder="Set your password"
                type="password"
                value={user.password}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <Units
                // className={classes.textField}
                // formState={formState}
                handleUnitChanged={this.handleUnitChanged}
                hasError={this.hasError}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions disableSpacing>
          <CreateNewUserBottom
            changed={this.IsChanged()}
            handleAddError={this.props.handleAddError}
            handleAddNewUser={this.handleAddNewUser}
            user={this.state.user}
          />
        </CardActions>
      </Card>
    );
  }
}

CreateNewUser.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
CreateNewUser.defaultProps = {
  handleOpenCreateUser: () => '',
  handleClose: () => '',
  handleAddError: () => '',
};
export default withStyles(CreateNewUser, useStyles);
