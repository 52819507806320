import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import palette from 'theme/palette';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import { IsInvalid, ServerErrorsString, GetData } from 'helpers';
import { QueryRunSummaryModalities } from './utils_devices';
import { getVariables } from 'common';
import { initialState } from 'redux-store/reducers/page_settings';

class QuerySummaryDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    const variables = {
      page: 0,
      limit: 5,
      ...getVariables(this.props),
    };
    (async () => {
      QueryRunSummaryModalities(variables)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SummaryDevices;
          if (ok) {
            this.props.setState({ loading: false });
            this.setState({
              data: this.getData(data.SummaryDevices),
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getEmpty = () => {
    const data = {
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: [
            palette.primary.main,
            palette.error.main,
            palette.warning.main,
          ],
          borderWidth: 8,
          borderColor: palette.white,
          hoverBorderColor: palette.white,
        },
      ],
      labels: ['Desktop', 'Tablet', 'Mobile'],
    };
    return data;
  };
  getData = SummaryDevices => {
    //
    const { ok, Devices } = SummaryDevices;
    const data = this.getEmpty();
    if (!ok || (Devices && Devices.length === 0)) {
      return data;
    }
    let { Total } = SummaryDevices;
    if (!Total) {
      Total = 1;
    }
    const { total_B, total_T, total_M } = Devices[0];
    const total_btm = total_B + total_T + total_M;
    if (total_btm !== Total) {
      Total = total_btm;
    }
    if (IsInvalid(data.datasets)) {
      console.log('Devices');
    }
    data.datasets[0].data[0] = parseInt((total_B / Total) * 100, 10);
    data.datasets[0].data[1] = parseInt((total_T / Total) * 100, 10);
    data.datasets[0].data[2] = parseInt((total_M / Total) * 100, 10);
    return data;
  };
  getDevices = data => {
    const devices = [
      {
        title: 'Desktop',
        value: `${data.datasets[0].data[0]}`,
        icon: <LaptopMacIcon />,
        color: palette.primary.main,
      },
      {
        title: 'Tablet',
        value: `${data.datasets[0].data[1]}`,
        icon: <TabletMacIcon />,
        color: palette.error.main,
      },
      {
        title: 'Mobile',
        value: `${data.datasets[0].data[2]}`,
        icon: <PhoneIphoneIcon />,
        color: palette.warning.main,
      },
    ];
    return devices;
  };

  render() {
    const { children } = this.props;
    let { data, loading } = this.state;
    if (IsInvalid(data)) data = this.getEmpty();
    const devices = this.getDevices(data);
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        data,
        devices,
        loading,
      });
    });
  }
}

const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, { logout })(QuerySummaryDevices);
