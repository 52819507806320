import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TopBarSearch, ContainerPictures } from './components';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryAllProjectObjectByCategory } from 'graphql/Projects/utils_project_objects';
class ExplorerPictures extends React.Component {
  constructor(props) {
    super(props);
    const { limit } = props;
    this.state = {
      open_mapping: false,
      open_editor: false,
      idProject: -1,
      selected: [],
      project_objects: [],
      page: 0,
      limit: limit ? limit : 50,
      total: 0,
      empty: false,
      filter: null,
      headers_to_hide: [],
      keywords: [],
      filters: [],
    };
    const { holder } = props;
    if (holder) {
      holder.setProject = project => {
        let idProject = -1;
        if (project) {
          idProject = project.id;
        }
        console.log({ idProject, project });
        this.runQueryProjectObject(null, null, idProject);
      };
      holder.setPageLimit = (page, limit) => {
        this.runQueryProjectObject(page, limit);
      };
      holder.setFilters = filters => {
        this.runQueryProjectObject(null, null, null, null, filters);
      };
    }
  }
  componentDidMount() {
    this.runQueryProjectObject();
  }
  componentDidUpdate() {
    const { project } = this.props;
    const { idProject, loading } = this.state;
    if (project && !loading) {
      if (project.id !== idProject) {
        this.runQueryProjectObject(null, null, project.id);
      }
    }
  }
  runQueryProjectObject = (
    new_page = null,
    new_limit = null,
    new_id_project = null,
    new_keywords = null,
    new_filters = null
  ) => {
    let { page, limit, loading, keywords, idProject, filters } = this.state;
    if (new_id_project) {
      idProject = new_id_project;
    }
    console.log('runQueryProjectObject', {
      idProject,
    });
    if (loading) return;
    if (new_keywords !== null && Array.isArray(new_keywords)) {
      keywords = new_keywords;
    }
    if (new_filters !== null && Array.isArray(new_filters)) {
      filters = new_filters;
    }

    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryAllProjectObjectByCategory(
        2,
        idProject,
        page,
        limit,
        keywords,
        filters
      )
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            project_objects,
            total,
            errors,
          } = data.allProjectObjectsByCategory;
          if (ok) {
            this.setState({
              idProject,
              loading: false,
              project_objects,
              page,
              limit,
              total,
              empty: project_objects.length ? false : true,
              selected: [],
              keywords,
              filters,
            });
            this.props.handleTotalChanged(total);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            page,
            idProject,
            limit,
            total: 0,
            empty: true,
            keywords,
            filters,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleKeywordSelection = selection => {
    let keywords = [];
    if (selection && selection.length && Array.isArray(selection)) {
      keywords = selection.filter(x => x.idGroup === 4).map(x => x.id);
    }
    this.runQueryProjectObject(null, null, null, keywords);
  };
  render() {
    const { open_drawer, height } = this.props;
    const { loading, total, project_objects } = this.state;
    return (
      <Grid
        alignItems="stretch"
        container
        direction="row"
        justifyContent="center"
        spacing={3}>
        <Grid item xs={12}>
          <TopBarSearch
            handleDrawerClose={this.props.handleDrawerClose}
            handleReload={() => this.runQueryProjectObject()}
            loading={loading}
            onKeywordSelection={this.handleKeywordSelection}
            open_drawer={open_drawer}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item style={{ paddingLeft: 5, paddingRight: 5 }}>
            <ContainerPictures
              height={height}
              loading={loading}
              project_objects={project_objects}
              total={total}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ExplorerPictures.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func,
};
ExplorerPictures.defaultProps = {
  handleAddError: () => '',
};
export default ExplorerPictures;
