import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardActions, Divider, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';

import {
  AnatomicalRegion,
  ButtonCircularIconLoad,
  ImageFocus,
  InvestigativedSide,
  Pathology,
  Sequence,
} from 'components';

import { SaveDetails } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';
import { withRouter } from 'react-router-dom';

class CardCollapseSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      loading_saving: false,
      details: {},
    };
    this.baseState = { ...this.state };
    this.holder = {
      getPathology: null,
      getSide: null,
      getRegion: null,
      getFocus: null,
      getSequence: null,
      resetPathology: null,
      resetSide: null,
      resetRegion: null,
      resetFocus: null,
      resetSequence: null,
    };
  }

  handleSave = () => {
    const { idSeries } = this.props;
    if (typeof idSeries === 'undefined' || idSeries < 1) {
      this.props.addError('Invalid Series or undefined');
      return;
    }

    const { loading_saving } = this.state;
    if (loading_saving) return;
    const { idPathology } = this.holder.getPathology();
    const { idSide } = this.holder.getSide();
    const { idRegion } = this.holder.getRegion();
    const { idFocus } = this.holder.getFocus();
    const { idSequence } = this.holder.getSequence();
    const sm = { idSeries, idPathology, idSide, idRegion, idFocus, idSequence };

    this.setState({ loading_saving: true });
    (async () => {
      SaveDetails(sm)
        .then(res => {
          const data = GetData(res);
          const { ok } = data.SaveDetails;
          if (!ok) {
            throw data.SaveDetails.error;
          }
          let to_send = {};
          const changed = ['region', 'focus', 'side', 'sequence', 'pathology'];
          for (let i = 0; i < changed.length; i++) {
            if (Object.keys(this.state.details).indexOf(changed[i]) !== -1) {
              to_send[changed[i]] = this.state.details[changed[i]];
            }
          }
          this.setState({ loading_saving: false, changed: false });
          this.props.handleDetailChanged(to_send);
        })
        .catch(error => {
          this.setState({ loading_saving: false });
          this.props.addError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedChanged = (type, value) => {
    const { details } = this.state;
    if (Object.keys(this.state).indexOf(type) === -1) {
      this.setState({ changed: true, details: { ...details, [type]: value } });
    } else if (this.state[type].name !== value.name) {
      this.setState({ changed: true, details: { ...details, [type]: value } });
    }
  };
  getDetails = () => {
    const { Details } = this.props;
    const details = { name: '', updatedAt: '' };
    let focus = { idFocus: -1, ...details };
    let region = { idRegion: -1, ...details };
    let pathology = { idPathology: -1, ...details };
    let sequence = { idSequence: -1, ...details };
    let side = { idSide: -1, ...details };
    if (Details.SeriesFocus) {
      focus = Details.SeriesFocus.ImageFocus;
      focus.updatedAt = Details.SeriesFocus.updatedAt;
    }
    if (Details.SeriesRegion) {
      region = Details.SeriesRegion.AnatomicalRegion;
      region.updatedAt = Details.SeriesRegion.updatedAt;
    }
    if (Details.SeriesPathology) {
      pathology = Details.SeriesPathology.Pathology;
      pathology.updatedAt = Details.SeriesPathology.updatedAt;
    }
    if (Details.SeriesSequence) {
      sequence = Details.SeriesSequence.Sequence;
      sequence.updatedAt = Details.SeriesSequence.updatedAt;
    }
    if (Details.SeriesSide) {
      side = Details.SeriesSide.InvestigatedSide;
      side.updatedAt = Details.SeriesSide.updatedAt;
    }
    const to_send = { region, focus, side, sequence, pathology };
    const changed = ['region', 'focus', 'side', 'sequence', 'pathology'];
    for (let i = 0; i < changed.length; i++) {
      if (Object.keys(this.state.details).indexOf(changed[i]) !== -1) {
        to_send[changed[i]] = this.state.details[changed[i]];
      }
    }
    return to_send;
  };

  render() {
    const { classes, handleReload } = this.props;
    const { loading_saving, changed } = this.state;
    const { region, focus, side, sequence, pathology } = this.getDetails();
    return (
      <div>
        <Divider variant="middle" />
        <Grid container spacing={1}>
          <Grid item>
            <AnatomicalRegion
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              region={region}
            />
          </Grid>
          <Grid item>
            <ImageFocus
              focus={focus}
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
            />
          </Grid>
          <Grid item>
            <InvestigativedSide
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              side={side}
            />
          </Grid>
          <Grid item>
            <Pathology
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              pathology={pathology}
            />
          </Grid>
          <Grid item>
            <Sequence
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              sequence={sequence}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <CardActions disableSpacing>
          <ButtonCircularIconLoad
            className={classes.button_grey}
            handleButtonClick={handleReload}
            icon={<CachedIcon />}
            small
            tooltip="Reload Details"
          />
          <ButtonCircularIconLoad
            className={classes.button_grey}
            handleButtonClick={() => {
              this.props.history.push('/image_analysis/details');
            }}
            icon={<AddIcon />}
            small
            tooltip="Edit Details"
          />
          <ButtonCircularIconLoad
            disabled={!changed}
            handleButtonClick={this.handleSave}
            left={classes.left}
            loading={loading_saving}
            small
          />
        </CardActions>
      </div>
    );
  }
}

CardCollapseSeries.propTypes = {
  classes: PropTypes.object,
};
CardCollapseSeries.defaultProps = {
  Details: {},
  handleDetailChanged: () => '',
};

export default withRouter(CardCollapseSeries);
