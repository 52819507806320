import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Fade,
  Divider,
  MenuItem,
  MenuList,
  Paper,
  ClickAwayListener,
  Popper,
  Grid,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Apps } from '@mui/icons-material';
import { useStyles } from './Styles';
class ButtonPublicAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: 'bottom-start',
      anchorEl: null,
      to_white: true,
    };
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener('scroll', this.headerColorChange);
    }
  }

  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener('scroll', this.headerColorChange);
    }
  }
  handleClick = event => {
    let anchorEl = null;
    if (!this.state.anchorEl) {
      anchorEl = event.currentTarget;
    }
    this.setState({ anchorEl });
  };
  handleClose = param => {
    this.setState({ anchorEl: null });
    if (this.props && this.props.onClick) {
      this.props.onClick(param);
    }
  };
  headerColorChange = () => {
    const { changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      this.setState({ to_white: false });
    } else {
      this.setState({ to_white: true });
    }
  };
  render() {
    let { anchorEl, placement, to_white } = this.state;
    const {
      classes,
      icon,
      dropdownList,
      dropdownHeader,
      noLiPadding,
      rtlActive,
      hoverColor,
    } = this.props;
    if (this.props.inDrawer) to_white = false;
    const dropdownItem = clsx({
      [classes.dropdownItem]: true,
      [classes[hoverColor + 'Hover']]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive,
    });
    const buttonStyles = clsx({
      [classes.button_dropdown]: true,
      [classes.button_white]: to_white,
      [classes.button_black]: !to_white,
    });
    const open = Boolean(anchorEl);
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="center"
        style={{ padding: 6 }}>
        <Button
          className={buttonStyles}
          onClick={this.handleClick}
          startIcon={icon}>
          Public Access
        </Button>
        <Popper
          anchorEl={anchorEl}
          open={open}
          placement={placement}
          style={{ zIndex: 20000 }}
          transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ display: anchorEl ? null : 'none' }}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList className={classes.menuList} role="menu">
                    {dropdownHeader !== undefined ? (
                      <MenuItem
                        className={classes.dropdownHeader}
                        onClick={() => this.handleClose(dropdownHeader)}>
                        {dropdownHeader}
                      </MenuItem>
                    ) : null}
                    {dropdownList.map((prop, key) => {
                      if (prop.divider) {
                        return (
                          <Divider
                            className={classes.dropdownDividerItem}
                            key={key}
                            onClick={() => this.handleClose('divider')}
                          />
                        );
                      }
                      return (
                        <MenuItem
                          className={dropdownItem}
                          key={key}
                          onClick={() => this.handleClose(prop)}>
                          {prop}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Grid>
    );
  }
}

ButtonPublicAccess.propTypes = {
  classes: PropTypes.object,
  dropdownHeader: PropTypes.node,
  dropdownList: PropTypes.array,
  dropup: PropTypes.bool,
  hoverColor: PropTypes.oneOf([
    'black',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
  ]),
  inDrawer: PropTypes.bool,
  left: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  // function that retuns the selected item
  onClick: PropTypes.func,
  rtlActive: PropTypes.bool,
};
ButtonPublicAccess.defaultProps = {
  icon: <Apps />,
  dropdownList: [],
  hoverColor: 'primary',
  noLiPadding: true,
  inDrawer: false,
};
export default withStyles(ButtonPublicAccess, useStyles);
