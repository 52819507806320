export { default as DateFromTo } from './DateFromTo';
export { default as DateTimeLeft } from './DateTimeLeft';
export { default as DateTimeCenter } from './DateTimeCenter';
export { default as DateTimeRight } from './DateTimeRight';
export { default as AtDate } from './AtDate';
export const useStyles = theme => ({
  radio: {
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    margin: 0,
    padding: 0,
  },
  to_format: {
    fontSize: 12,
    textTransform: 'none',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1.3),
  },
  calendar_format: {
    width: '100px',
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 100,
    fontSize: 12,
  },
});
