import React from 'react';

import moment from 'moment';
import { Button, ButtonGroup, colors, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.green[600],
    },
    secondary: colors.red,
  },
  typography: {
    button: {
      //   textTransform: 'none'
      fontSize: '8',
      // fontFamily: 'Arial'
    },
  },
});

export const ColorFromInteger = value => {
  let color = 'green';
  switch (value) {
    case 0: //rejected
      color = 'red';
      break;
    case 1: //accepted
      color = 'green';
      break;
    default:
      //waiting
      color = 'orange';
      break;
  }
  return color;
};
export const TextInvited = ({ value, admin, i }) => {
  //
  const text = admin ? 'Sent' : 'Received';
  let color = value ? (admin ? 'blue' : 'green') : admin ? 'green' : 'blue';
  return (
    <Typography
      display="block"
      key={`text-invited-${i}`}
      style={{
        fontSize: '0.9rem',
        marginTop: '-1px',
        marginButtom: '-1px',
        color: color,
      }}
      variant="caption">
      {text}
    </Typography>
  );
};
const TextAccepted = ({ value, admin, i, handleAcceptReject, row }) => {
  //
  let text = 'Rejected';
  let color = ColorFromInteger(value);
  if (value === 1) {
    text = 'Accepted';
  } else if (value === 2) {
    text = admin ? 'Waiting' : 'Accept/Reject';
  }
  let component = (
    <Typography
      display="block"
      key={`text-accepted-${i}`}
      style={{
        fontSize: '0.9rem',
        marginTop: '-1px',
        marginButtom: '-1px',
        color: color,
      }}
      variant="caption">
      {text}
    </Typography>
  );
  if (admin || value !== 2) {
    //empy
  } else {
    component = (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ButtonGroup size="small" variant="contained">
            <Button
              color="primary"
              onClick={() => handleAcceptReject(true, row)}
              startIcon={<Add fontSize="small" />}>
              Accept
            </Button>
            <Button
              color="secondary"
              onClick={() => handleAcceptReject(false, row)}
              startIcon={<Delete fontSize="small" />}>
              Reject
            </Button>
          </ButtonGroup>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
  return component;
};
export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 10,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
  },
  {
    id: 'invited',
    label: 'Invited',
    minWidth: 10,
    align: 'left',
    type: 'WithIndex',
    format: params => TextInvited(params),
  },
  {
    id: 'accepted',
    label: 'Status',
    minWidth: 10,
    align: 'right',
    type: 'WithIndex',
    format: params => TextAccepted(params),
  },
  {
    id: 'createdAt',
    label: 'Registration Date',
    minWidth: 100,
    align: 'right',
    format: value => (value ? moment(value).calendar() : ''),
  },
];
