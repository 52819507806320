/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, InputAdornment } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from 'moment';
import {
  useStylesPicker,
  FormatDate,
  IsWrongFormat,
  CheckDate,
} from '../utils_picker';
import { inputProps } from 'common';
const ExampleCustomInput = forwardRef(
  (
    {
      value,
      onClick,
      label,
      classes,
      disabled,
      onChangeDate,
      value_text,
      error,
    },
    ref
  ) => {
    let final_text = FormatDate(value);
    if (value_text) final_text = value_text;
    return (
      <TextField
        disabled={disabled}
        error={error}
        fullWidth
        id="filled-start-adornment"
        InputProps={{
          style: { paddingRight: 4 },
          classes: {
            input: classes.fontSize,
          },
          endAdornment: (
            <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
              <DateRangeIcon onClick={onClick} />
            </InputAdornment>
          ),
          ...inputProps,
        }}
        label={label}
        onChange={event => {
          onChangeDate(event.target.value);
        }}
        ref={ref}
        size="small"
        sx={{ paddingRight: 0 }}
        value={final_text}
      />
    );
  }
);

const DatePicker = props => {
  let { date, handleDateChange, disabled } = props;
  // console.log({ date });
  date = CheckDate(date);
  const classes = useStylesPicker();
  const [selectedtDate, setDate] = useState(date ? new Date(date) : new Date());
  const [textDate, setTextDate] = useState(null);
  const [error, setError] = useState(false);
  const updateDate = new_date => {
    if (new_date) {
      handleDateChange(new_date);
    }
    setDate(new_date);
    setError(false);
    setTextDate(null);
  };
  const onChangeText = text => {
    setTextDate(text);
    try {
      if (IsWrongFormat(text)) {
        setError(true);
        return;
      }
      setError(false);
      const validation = moment(text, 'DD/MM/YYYY');
      updateDate(validation.toDate());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MuiDatePicker
      customInput={
        <ExampleCustomInput
          classes={classes}
          disabled={disabled}
          error={error}
          label={props.label}
          onChangeDate={onChangeText}
          value_text={textDate}
        />
      }
      disabled={disabled}
      onChange={updateDate}
      placeholderText="Selected or write date"
      selected={selectedtDate}
      withPortal
    />
  );
};
DatePicker.propTypes = {
  classes: PropTypes.object,
};
DatePicker.defaultProps = {
  date: null,
  label: 'Date',
  handleDateChange: () => '',
};
export default DatePicker;
