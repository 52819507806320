import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableProjectActivity } from 'components';
import { ActivitiesAtProject } from 'graphql/Projects';
import { GetData, ServerErrorsString } from 'helpers';

class TabProjectActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      activities: [],
      loading: false,
      total: 0,
      page: 0,
      limit: 10,
    };
  }

  componentDidMount() {
    const { project } = this.props;
    if (!project) return;
    const { id } = project;
    if (typeof id === 'undefined' || id < 1) return;
    if (id === this.state.id) return;
    this.runUpdate(id);
  }

  componentDidUpdate() {
    const { project } = this.props;
    if (!project) return;
    const { id } = project;
    if (typeof id === 'undefined' || id < 1) return;
    if (id === this.state.id) return;
    this.runUpdate(id);
  }

  runUpdate = (id, name, page = null, limit = null) => {
    const { loading } = this.state;
    if (loading) return;
    if (typeof id === 'undefined' || id < 1) {
      id = this.state.id;
    }
    if (!limit || limit === undefined) {
      page = this.state.page;
      limit = this.state.limit;
    }
    this.setState({ loading: true });
    (async () => {
      ActivitiesAtProject(id, name, page, limit)
        .then(res => {
          const data = GetData(res);
          if (data.ActivitiesAtProject) {
            const { ok, activities, total } = data.ActivitiesAtProject;
            if (ok) {
              this.setState({
                loading: false,
                activities,
                id,
                total,
                page,
                limit,
              });
            } else {
              throw data.ActivitiesAtProject.errors;
            }
          } else {
            throw Error('Unkown error');
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false, page, limit });
        });
    })();
  };
  handleDeleteSelected = selected => {
    const { activities } = this.state;
    let new_activity = [];
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      const index = selected.map(x => x.id).indexOf(activity.id);
      if (index === -1) {
        new_activity.push(activity);
      }
    }
    this.setState({ activities: new_activity });
  };
  handleChangeRowsPerPage = (page, limit) => {
    this.runUpdate(this.state.id, null, page, limit);
  };

  render() {
    if (!this.props.isActive) return null;
    const { activities, loading } = this.state;
    const { isAdmin } = this.props;
    return (
      <React.Fragment>
        <TableProjectActivity
          disable_checkbox={!isAdmin}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={() => this.runUpdate()}
          height={this.props.height - 130}
          loading={loading}
          rows={activities}
          title="Project Activity"
          total={this.state.total}
        />
      </React.Fragment>
    );
  }
}

TabProjectActivity.propTypes = {
  classes: PropTypes.object,
};

export default TabProjectActivity;
