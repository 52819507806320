import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FormatBytes } from 'helpers';
// import { FormatNumber } from '../utils';
import { GroupRequestUser } from './components';
import { inputProps } from 'common';
import { FormatNumber } from '../../../utils';
class UserViewPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, setting, loading, edit_user } = this.props;
    let storage = FormatNumber('');
    let text_current_used_storage = 'Using 0 of 0 B storage';
    let file_size = FormatNumber('');
    let devices = 0;
    // let files = 0;
    // let dicoms = 0;
    if (setting && !loading) {
      const used = FormatBytes(setting.used);
      storage = FormatNumber(setting.storage);
      file_size = FormatNumber(setting.file_size);
      text_current_used_storage = `Using ${used} of ${storage.number} ${storage.format}  storage`;
      devices = setting.devices;
      // dicoms = setting.dicoms;
      // files = setting.files;
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start">
        <Divider style={{ marginBottom: 10, marginTop: 5 }} />
        <TextField
          className={clsx(classes.margin, classes.text_width)}
          id="max-devices-sync"
          InputProps={{
            endAdornment: <InputAdornment position="end">PCs</InputAdornment>,
            ...inputProps,
          }}
          label="Maximum number of devices for sync"
          onChange={this.props.handleChange('devices')}
          size="small"
          type="number"
          value={devices}
        />
        <Grid container>
          <TextField
            className={clsx(classes.margin, classes.textField)}
            id="max-file-size"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {file_size.format}
                </InputAdornment>
              ),
              ...inputProps,
            }}
            label="Maximum file size"
            onChange={this.props.handleChange('file_size')}
            size="small"
            type="number"
            value={file_size.number}
          />
          <TextField
            className={clsx(classes.margin, classes.textField)}
            id="max-storage-size"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {storage.format}
                </InputAdornment>
              ),
              ...inputProps,
            }}
            label="Maximum storage size"
            onChange={this.props.handleChange('storage')}
            size="small"
            type="number"
            value={storage.number}
          />
        </Grid>

        <Typography className={classes.margin} gutterBottomvariant="subtitle">
          {text_current_used_storage}
        </Typography>
        <Grid item style={{ width: '100%', marginTop: 10 }} xs={12}>
          <GroupRequestUser
            handleAddError={this.props.handleAddError}
            handleUserRequest={this.props.handleUserRequest}
            loading={loading}
            user={edit_user}
          />
        </Grid>
      </Grid>
    );
  }
}

UserViewPlan.propTypes = {
  classes: PropTypes.object,
  handleUserRequest: PropTypes.func,
};

export default UserViewPlan;
