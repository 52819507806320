import React from 'react';
import { Divider, Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import {
  GetData,
  getLevels,
  ServerErrorsString,
  rowsPerPageSmall,
} from 'helpers';
import {
  FilesToolBar,
  FilesViewGallery,
  FilesViewTable,
  SubFoldersToolBar,
} from './components';
import { ObjectsAtProjectFolder, RemoveProjectObjects } from 'graphql/Projects';
import useStyles from './components/Styles';

class TabProjectFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idProject: -1,
      idSubFolder: -1,
      viewList: true,
      numSelected: 0,
      objects: [],
      loading: false,
      loading_remove: false,
      page: 0,
      limit: 10,
      total: 0,
      setting: {
        idCenters: -1,
        idFolderFile: '',
        idCondingType: '',
      },
    };
    this.holder = {
      clearTable: null,
      clearGallery: null,
      removeTable: null,
      removeGallery: null,
      getSelectedTable: null,
      getSelectedGallery: null,
      addFolder: null,
      backFolder: (idProject, idFolder = null) => {
        let backto = `/project_management/${idProject}/files`;
        if (idFolder) {
          backto = `${backto}/folder/${idFolder}`;
        }
        this.props.history.push(backto);
        if (!idFolder) this.setState({ idSubFolder: -1, idProject: -1 });
      },
      clearSubFolders: null,
    };
  }

  componentDidMount() {
    this.componentObjectUpdate();
  }

  componentDidUpdate() {
    this.componentObjectUpdate();
  }

  componentObjectUpdate = () => {
    const { project } = this.props;
    if (!project) return;
    const { id } = project;
    if (typeof id === 'undefined' || id < 1) return;

    const { level_2, level_3 } = getLevels(this.props);
    //
    if (level_2 === 'folder' && level_3 !== undefined) {
      const idFolder = parseInt(level_3, 10);
      if (idFolder === this.state.idSubFolder) return;
      this.runFolderUpdate(idFolder);
    } else {
      if (id === this.state.idProject) return;
      if (this.props.clearSubFolders) {
        this.props.clearSubFolders();
      }
      this.runUpdate(id);
    }
  };
  runFolderUpdate = (idFolder, setting) => {
    const { project } = this.props;
    let { loading, page, limit } = this.state;
    if (idFolder === this.state.idSubFolder || loading) return;
    if (!setting || setting === undefined) {
      setting = this.state.setting;
    }
    let idProject = this.state.id;
    if (idProject === undefined || idProject < 1) idProject = project.id;

    this.setState({ loading: true });
    (async () => {
      ObjectsAtProjectFolder(idProject, idFolder, setting, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, objects, total, errors } = data.projectObjectFolder;
          if (ok) {
            this.setState({
              loading: false,
              idSubFolder: idFolder,
              objects,
              idProject,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const error = ServerErrorsString(errors);
          const state = {
            loading: false,
            page,
            limit,
            total: 0,
          };
          if (error.indexOf('You have not access to this folder') !== -1) {
            state.idSubFolder = idFolder;
            state.objects = [];
          }
          this.setState(state);
          this.props.handleAddError(error);
        });
    })();
  };
  runUpdate = (
    idProject,
    name,
    setting = null,
    new_page = null,
    new_limit = null
  ) => {
    let { loading, page, limit } = this.state;
    const { project } = this.props;
    if (idProject === this.state.idProject || loading) return;
    if (new_page !== undefined && new_page >= 0 && new_limit) {
      page = new_page;
      limit = new_limit;
    }
    if (!setting || setting === undefined) {
      setting = this.state.setting;
    }
    if (idProject === undefined || idProject < 1) idProject = project.id;

    this.setState({ loading: true });
    (async () => {
      // ObjectsAtProject(idProject, setting, name, page, limit)
      ObjectsAtProjectFolder(idProject, -1, setting, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, objects, total, errors } = data.projectObjectFolder;
          if (ok) {
            this.setState({
              loading: false,
              objects,
              idProject,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, total: 0 });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleFilesView = () => {
    this.setState(prev_state => ({ viewList: !prev_state.viewList }));
  };
  handleSelected = numSelected => {
    this.setState({ numSelected: numSelected });
  };
  handleClear = () => {
    if (this.holder.clearTable) {
      this.holder.clearTable();
    }
    if (this.holder.clearGallery) {
      this.holder.clearGallery();
    }
  };
  handleRemove = () => {
    const { loading_remove, viewList } = this.state;
    if (loading_remove) return;
    let selected = [];
    if (viewList && this.holder.getSelectedTable) {
      selected = this.holder.getSelectedTable();
    } else if (this.holder.getSelectedGallery) {
      selected = this.holder.getSelectedGallery();
    }
    if (selected.length === 0) return;
    const { project } = this.props;

    const objects = [];
    for (let i = 0; i < selected.length; i++) {
      const { id, Folder, FolderContent, Object } = selected[i];
      const object = { id, idProject: project.id };
      if (Folder) object.idFolder = Folder.idFolder;
      else if (FolderContent)
        object.idFolderContent = FolderContent.idFolderContent;
      else if (Object) object.idObject = Object.idObject;
      objects.push(object);
    }
    this.runRemove(objects, selected);
  };
  runRemove = (to_remove, selected) => {
    const { loading_remove, viewList, objects } = this.state;
    if (loading_remove) return;

    this.setState({ loading_remove: true });
    (async () => {
      RemoveProjectObjects(to_remove)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeProjectObjects;
          if (ok) {
            if (viewList && this.holder.clearGallery) {
              this.holder.clearGallery();
            } else if (this.holder.clearTable) {
              this.holder.clearTable();
            }
            const ids = selected.map(x => x.id);
            this.setState({
              loading_remove: false,
              objects: objects.filter(x => ids.indexOf(x.id) === -1),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_remove: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRemoveFromFolder = folder_contents => {
    const indices = folder_contents.map(x => x.idFolderContent);
    let { objects } = this.state;
    objects = objects.filter(x => {
      const { idFolderContent } = x;
      if (typeof idFolderContent === 'undefined') {
        return true;
      }
      return indices.indexOf(idFolderContent) === -1;
    });
    this.handleClear();
    this.setState({ objects });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.runUpdate(null, null, null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    const limit = +event.target.value;
    this.runUpdate(null, null, null, 0, limit);
  };
  handleUpdateSearchSetting = setting => {
    this.setState({ setting });
  };
  handleOpenSubFolder = folder => {
    const { project } = this.props;
    console.log({ folder, project });
    if (!project) return;
    const idProject = project.id;
    this.holder.addFolder(folder);
    const { idFolder } = folder;
    this.props.history.push(
      `/project_management/${idProject}/files/folder/${idFolder}`
    );
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, project, user, height, isAdmin } = this.props;
    const {
      viewList,
      numSelected,
      objects,
      loading,
      loading_remove,
      page,
      limit,
      total,
    } = this.state;
    return (
      <Paper className={classes.paper} style={{ height: height }}>
        <FilesToolBar
          handleClear={this.handleClear}
          handleFilesView={this.handleFilesView}
          handleReload={() => this.runUpdate()}
          handleRemove={this.handleRemove}
          handleUpdateSearchSetting={this.handleUpdateSearchSetting}
          loading={loading}
          loading_remove={loading_remove}
          numSelected={numSelected}
          project={project}
          setting={this.state.setting}
          user={user}
          viewList={viewList}
        />
        <SubFoldersToolBar holder={this.holder} project={project} />
        {(numSelected === 0 || !viewList) && <Divider variant="middle" />}
        <div className={classes.tableWrapper}>
          {viewList ? (
            <FilesViewTable
              admin={isAdmin}
              handleOpenSubFolder={this.handleOpenSubFolder}
              handleSelected={this.handleSelected}
              height={height - 160}
              holder={this.holder}
              loading={loading}
              projectId={project.id}
              rows={objects}
              total={total}
              user={user}
            />
          ) : (
            <FilesViewGallery
              admin={isAdmin}
              handleOpenSubFolder={this.handleOpenSubFolder}
              handleRemoveFromFolder={this.handleRemoveFromFolder}
              handleSelected={this.handleSelected}
              height={height - 80}
              holder={this.holder}
              loading={loading}
              projectId={project.id}
              rows={objects}
              total={total}
              user={user}
            />
          )}
        </div>
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
      </Paper>
    );
  }
}

export default withStyles(withRouter(TabProjectFiles), useStyles);
