import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@mui/material';
import { DatePicker } from 'components';
class AtDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, disable_at_date, selectedAtDate } = this.props;
    const sylte_radio = {
      size: 'small',
      style: {
        margin: 0,
        padding: 0,
        paddingTop: 5,
        marginBottom: 5,
      },
    };
    return (
      <React.Fragment>
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="At Date:"
          value="at_date"
        />
        <DatePicker
          date={selectedAtDate}
          disabled={disable_at_date}
          handleDateChange={this.props.handleAtDateChange}
          label=""
          // format="dd/MM/yyyy"
          // id="at-date-picker-dialog"
          // KeyboardButtonProps={{
          //   'aria-label': 'change date',
          // }}
          // label="At date:"
          // margin="normal"
          // onChange={this.props.handleAtDateChange}
          // renderInput={props => <TextField {...props} />}
          // value={selectedAtDate}
        />
      </React.Fragment>
    );
  }
}

AtDate.propTypes = {
  classes: PropTypes.object,
};

export default AtDate;
