import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    id: 'sequence',
    label: 'Sequence',
    align: 'left',
    minWidth: 170,
    format: value => {
      if (!value) {
        return 'Sequence Missing';
      }
      let { name } = value;
      if (typeof name === 'undefined') return 'Sequence Missing';
      return name;
    },
  },
  {
    id: 'center',
    label: 'Center',
    align: 'left',
    minWidth: 170,
    format: value => {
      if (!value) {
        return 'Center Missing';
      }
      let { name } = value;
      if (typeof name === 'undefined') return 'Center Missing';
      return name;
    },
  },
  {
    id: 'name',
    label: 'Mapping',
    minWidth: 170,
    type: 'text',
  },
  {
    id: 'updatedAt',
    label: 'Update At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
