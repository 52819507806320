import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_CHANNEL_MESSAGES = gql`
  query($channelId: Int!) {
    messages(channelId: $channelId) {
      id
      text
      user {
        id
        FullName
        avatar
      }
      channel {
        id
        name
      }
      url
      filetype
      createdAt
    }
  }
`;

export const QueryRunChannelMessages = channelId => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CHANNEL_MESSAGES),
    variables: {
      channelId,
    },
  });
};
