// Swiper navigation buttons styles
export const navigationStyles = {
  position: 'absolute',
  top: 0,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '15%',
  maxWidth: '20px',
  height: '100%',
  textAlign: 'center',
  opacity: 0.5,
  fontSize: 100,
  cursor: 'pointer',
  transition: 'opacity 0.15s ease',
  '&:hover, &:focus': {
    opacity: 1,
  },
};

export * from './pages';
export { default as DatabaseCard } from './DatabaseCard';
