import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { StatusBullet } from 'components';

const statusColors = {
  green: 'success',
  orange: 'info',
  red: 'danger',
};
const useStyles = theme => ({
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
});

class TableCellStatus extends PureComponent {
  render() {
    const { classes, type, name } = this.props;
    return (
      <div className={classes.statusContainer}>
        <StatusBullet
          className={classes.status}
          color={statusColors[type]}
          size="sm"
        />
        {name}
      </div>
    );
  }
}

TableCellStatus.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  type: PropTypes.oneOf(['green', 'orange', 'red']),
};

TableCellStatus.defaultProps = {
  type: 'red',
};

export default withStyles(TableCellStatus, useStyles);
