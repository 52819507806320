export const header_epilepsy = [
  {
    PAT_NamePrefix: 'Mr.',
    PAT_NameSuffix: 'Jr.',
    PAT_GivenName: 'John',
    PAT_MiddleName: 'Tyler',
    PAT_FamilyName: 'Doe',
    PAT_PatientID: '14726165',
    PAT_PatientSex: 'm',
    PAT_PatientBirthDate: '1993-08-21',
    PAT_PatientAge: '30',
    PAT_PatientSize: '175',
    PAT_PatientWeight: '70',
    PAT_PatientWaist: '75',
    PAT_PatientHip: '68',
    PAT_PatientEthnicity: 'Caucasian',
    PAT_patientLastActivity: '2017-12-19 09:30:00.0000000',
    PAT_patientSAPStatus: 'aktiv',
    PAT_patientRegistrationDate: '2015-11-20 10:30:00.0000000',
    PAT_bmi: '22.9',
    PAT_patientDeathDate: '10.11.2020',
    PAT_patientLastVitalityCheck: '12/5/2020',
    PAT_patientVitalStatus: 'leben (ZAS)',
    CAS_case_identifier: 'EL001',
    CAS_date: '2019-12-09',
    CAS_first_seizure_date: '2008-12-15',
    CAS_patient_age: '26',
    CAS_neurologist: 'H.Gast',
    CAS_assistant: 'A.Seiler',
    CAS_comment: 'Generic comment',
    CAS_laterality: 'R',
    CAS_scalp: 'no',
    CAS_subscalp: 'no',
    CAS_strip: 'no',
    CAS_grid: 'yes',
    CAS_depth: '4right',
    CAS_date_pre_implantation_mr: '2018-08-14 09:03:07',
    CAS_date_post_implantation_ct: '2019-12-10 09:29:11',
    CAS_covered_region:
      'Basotemporal; Laterotemporal; Mesiotemporal; WM; OUT; Parietal; Superotemporal',
    CAS_electrodes_located_mr: 'x',
    CAS_electrodes_labelled: 'no',
    CAS_ct_registered_to_mri: 'x',
    CAS_normalized_to_mni: 'x',
    CAS_pial_reconstruction: 'no',
    R_STUD_brain_map: '0',
    R_STUD_input_output: '0',
    R_STUD_circadian: '1',
    R_STUD_pharmaco: '0',
    R_STUD_audio_sleep: '0',
    R_STUD_music_start_date: '12/15/2022 16:05',
    R_STUD_music_end_date: '12/15/2022 16:38',
    R_STUD_epi_os: '0',
    R_STUD_spec_ictal: '24/2/2022',
    R_STUD_comment: 'stimulations were not sent',
    RES_date: '2020-07-06',
    RES_patient_age: '26',
    RES_op_side: 'right',
    RES_operation: 'Lesionectomy',
    RES_details:
      'Navigationsgestützte temporobasale Kraniotomie und Läsionektomie',
    RES_complication: 'no',
    RES_histology: 'a.e. Gangliogliom, WHO Grad I',
    RES_histology_temporolpol:
      'Exzisat Läsion in T3; Niedriggradige, verdrängend wachsende gliale/glioneuronale Neoplasie',
    RES_histology_amygdala: 'Fokale Dispersion',
    RES_histology_hippocampus:
      'Fokale Dispersion der Fascia dentata, subkortikal betonte Astrogliose und Mikrogliaaktivierung, Nachweis von T-Zellen',
    OUT_engel_3m: '1A',
    OUT_engel_1y: '1A',
    OUT_engel_2y: '1A',
    OUT_percentage_aed_1y: '100',
    OUT_percentage_aed_2y: 'LCM  0, LEV 60%',
  },
];
export const header_sleep = [
  {
    PAT_NamePrefix: 'Mr.',
    PAT_NameSuffix: 'Jr.',
    PAT_GivenName: 'John',
    PAT_MiddleName: 'Tyler',
    PAT_FamilyName: 'Doe',
    PAT_PatientID: '14726165',
    PAT_PatientSex: 'm',
    PAT_PatientBirthDate: '1993-08-21',
    PAT_PatientAge: '30',
    PAT_PatientSize: '175',
    PAT_PatientWeight: '70',
    PAT_PatientWaist: '75',
    PAT_PatientHip: '68',
    PAT_PatientEthnicity: 'Caucasian',
    PAT_patientLastActivity: '2017-12-19 09:30:00.0000000',
    PAT_patientSAPStatus: 'aktiv',
    PAT_patientRegistrationDate: '2015-11-20 10:30:00.0000000',
    PAT_bmi: '22.9',
    PAT_patientDeathDate: '10.11.2020',
    PAT_patientLastVitalityCheck: '12/5/2020',
    PAT_patientVitalStatus: 'leben (ZAS)',
    CAS_record_id: '14385',
    CAS_description: 'Parasomnie / Epilepsie',
    CAS_redcap_event_name: 'event_1_arm_1',
    CAS_ess_score: '15',
    CAS_fss_score: '6.3',
    CAS_anamnesy:
      'Genetische Epilepsie mit Myoklonien und generalisierten Anfällen',
    CAS_medication: 'Keine',
    PSG_start_datetime: '30/10/2023',
    PSG_cpap_device: '1',
    PSG_sleep_duration: '412.5',
    PSG_sleep_latency: '5',
    PSG_rem_latency: '87',
    PSG_sleep_efficiency: '85.8',
    PSG_rem_percentage: '23',
    PSG_stage1_percentage: '9.3',
    PSG_stage2_percentage: '33.6',
    PSG_stage3_percentage: '34.1',
    PSG_supine_sleep_index: '62',
    PSG_apnoea_sleep_index: '6.1',
    PSG_hypopnoea_sleep_index: '15',
    PSG_ahi_sleep: '21.1',
    PSG_ahi_non_supine: '23.4',
    PSG_ahi_supine: '19.7',
    PSG_ahi_rem: '9.5',
    PSG_ahi_nrem: '9.8',
    PSG_centrale_sleep_index: '2.3',
    PSG_obstructive_sleep_index: '8',
    PSG_mixed_sleep_index: '6.7',
    PSG_average_spo2: '23.4',
    PSG_min_spo2: '3.3',
    PSG_time_90_spo2: '1.5',
    PSG_time_88_spo2: '1.3',
    PSG_desat_index: '95',
    PSG_co2_average_sleep: '91',
    PSG_co2_max_sleep: '0',
    PSG_co2_50_more_duration: '0',
    PSG_average_hf: '11.1',
    PSG_arousal_index: '43',
    PSG_plm_sleep_index: '47',
    PSG_subject_sleep_quality_pre: '2',
    PSG_subject_sleep_quality: '3',
    PSG_subject_sleep_duration: '270',
    PSG_subject_sleep_latency: '5',
    PSG_diagnose1: 'Obstruktives Schlafapnoe-Syndrom bei Erwachsenen (G47.31)',
    PSG_diagnose2: 'Spike-Wave-Komplexe',
    PSG_diagnose3: 'Verlangsamungsherd Spikes',
    VIG_test_type: '1',
    VIG_pvt_mean: '254',
    VIG_pvt_lapses: '0',
    VIG_sart_total_errors: '25',
    VIG_stear_clear: '6',
    VIG_pupillography_var: '6.7',
    MSLT_date: '17/10/2023',
    MSLT_instruction: '1',
    MSLT_diagnose1: '0 SOREM',
    MSLT_diagnose2: 'Erhaltene Perzeption',
    MSLT_diagnose3: '0 SOREM',
    MSLT_nr_repetitions: '5',
    MSLT_sleep_latency: '0.74',
    MSLT_sorem_n: '3',
    MWT_date: '17/10/2023',
    MWT_instruction: '2',
    MWT_diagnose1: '0 SOREM',
    MWT_diagnose2: 'Erhaltene Perzeption',
    MWT_diagnose3: '0 SOREM',
    MWT_nr_repetitions: '5',
    MWT_sleep_latency: '0.75',
    MWT_sorem_n: '2',
    MSLT_REP1_start_datetime: '09:00',
    MSLT_REP1_sleep_latency: '13',
    MSLT_REP1_rem_latency: '5',
    MSLT_REP1_kss_pre: '2',
    MSLT_REP1_kss_post: '10',
    MSLT_REP2_start_datetime: '09:00',
    MSLT_REP2_sleep_latency: '13',
    MSLT_REP2_rem_latency: '5',
    MSLT_REP2_kss_pre: '2',
    MSLT_REP2_kss_post: '10',
    MSLT_REP3_start_datetime: '09:00',
    MSLT_REP3_sleep_latency: '13',
    MSLT_REP3_rem_latency: '5',
    MSLT_REP3_kss_pre: '2',
    MSLT_REP3_kss_post: '10',
    MSLT_REP4_start_datetime: '09:00',
    MSLT_REP4_sleep_latency: '13',
    MSLT_REP4_rem_latency: '5',
    MSLT_REP4_kss_pre: '2',
    MSLT_REP4_kss_post: '10',
    MSLT_REP5_start_datetime: '09:00',
    MSLT_REP5_sleep_latency: '13',
    MSLT_REP5_rem_latency: '5',
    MSLT_REP5_kss_pre: '2',
    MSLT_REP5_kss_post: '10',
    MWT_REP1_start_datetime: '09:00',
    MWT_REP1_sleep_latency: '13',
    MWT_REP1_rem_latency: '5',
    MWT_REP1_kss_pre: '2',
    MWT_REP1_kss_post: '10',
    MWT_REP2_start_datetime: '09:00',
    MWT_REP2_sleep_latency: '13',
    MWT_REP2_rem_latency: '5',
    MWT_REP2_kss_pre: '2',
    MWT_REP2_kss_post: '10',
    MWT_REP3_start_datetime: '09:00',
    MWT_REP3_sleep_latency: '13',
    MWT_REP3_rem_latency: '5',
    MWT_REP3_kss_pre: '2',
    MWT_REP3_kss_post: '10',
    MWT_REP4_start_datetime: '09:00',
    MWT_REP4_sleep_latency: '13',
    MWT_REP4_rem_latency: '5',
    MWT_REP4_kss_pre: '2',
    MWT_REP4_kss_post: '10',
    MWT_REP5_start_datetime: '09:00',
    MWT_REP5_sleep_latency: '13',
    MWT_REP5_rem_latency: '5',
    MWT_REP5_kss_pre: '2',
    MWT_REP5_kss_post: '10',
  },
];
export const header_ms_patient = [
  {
    NamePrefix: 'Mr.',
    NameSuffix: 'Jr.',
    GivenName: 'John',
    MiddleName: 'Tyler',
    FamilyName: 'Doe',
    PatientID: '0014726165',
    PatientSex: 'm',
    PatientBirthDate: '1993-08-21',
    PatientAge: '30',
    PatientSize: '175',
    PatientWeight: '70',
    PatientWaist: '75',
    PatientHip: '68',
    PatientEthnicity: 'Caucasian',
    patientLastActivity: '2017-12-19 09:30:00.0000000',
    patientSAPStatus: 'aktiv',
    patientRegistrationDate: '2015-11-20 10:30:00.0000000',
    bmi: '22.9',
    patientDeathDate: '10.11.2020',
    patientLastVitalityCheck: '12/5/2020',
    patientVitalStatus: 'leben (ZAS)',
  },
];
export const header_ms_comorbidity = [
  {
    PatientID: '14726165',
    description: 'Laborchemisch St. n. Hepatitis B',
    group: 'Infection',
    subgroup_organ: 'Hepatitis B',
  },
];
export const header_ms_concomitant_medication = [
  {
    PatientID: '14726165',
    date: '2014-12-04 00:00:00',
    description: 'Vit D Trpf',
    active_ingredient: 'Vitamin D',
    active_ingredient_unknown: '1',
  },
];
export const header_ms_edss = [
  {
    PatientID: '14726165',
    sequence_number: '8',
    date: '2019-03-27 00:00:00',
    score: '6.5',
    fs1_visual: '2',
    fs2_brainstem: '1',
    fs3_pyramidal: '2',
    fs4_cerebellar: '2',
    fs5_sensory: '2',
    fs6_bowel_bladder: '2',
    fs7_cerebral: '2',
  },
];
export const header_ms_immunotherapy = [
  {
    PatientID: '14726165',
    sequence_number: '2',
    name: 'Ocrelizumab',
    start_date: '2010-05-01',
    stop_date: '2013-09-01',
    stop_reason: 'Urosepsis',
    stop_reason_infection_susceptibility_to_infection:
      'dass er den Nutzen der Therapie klar in Zweifel zieht.',
    stop_reason_insufficient_efficacy: ' fehlende Wirkung',
    stop_reason_intolerance_side_effects: '1',
    stop_reason_patient_request_external_reasons: '1',
    stop_reason_safety: '1',
    stop_reason_therapy_change: '1',
  },
];
export const header_ms_msfc = [
  {
    PatientID: '14726165',
    sequence_number: '2',
    date: '2019-12-10 00:00:00',
    walk_test_aid: 'bilateral',
    walk_test_time_1: '22.66',
    walk_test_time_2: '29.52',
    peg_test_handedness: 'right',
    peg_test_right_time_1: '30.4',
    peg_test_right_time_2: '27.16',
    peg_test_left_time_1: '33.1',
    peg_test_left_time_2: '30.24',
  },
];
export const header_stroke = [
  {
    pat_NamePrefix: 'Mr.',
    pat_NameSuffix: 'Jr.',
    pat_GivenName: 'John',
    pat_MiddleName: 'Tyler',
    pat_FamilyName: 'Doe',
    pat_PatientID: '14726165',
    pat_PatientSex: 'm',
    pat_PatientBirthDate: '1993-08-21',
    pat_PatientAge: '30',
    pat_PatientSize: '175',
    pat_PatientWeight: '70',
    pat_PatientWaist: '75',
    pat_PatientHip: '68',
    pat_PatientEthnicity: 'Caucasian',
    pat_patientLastActivity: '2017-12-19 09:30:00.000000',
    pat_patientSAPStatus: 'aktiv',
    pat_patientRegistrationDate: '2015-11-20 10:30:00.000000',
    pat_bmi: '22.9',
    pat_patientDeathDate: '10.11.2020',
    pat_patientLastVitalityCheck: '12/5/2020',
    pat_patientVitalStatus: 'leben (ZAS)',
    case_idCase: 'SSR-INS-00002',
    case_referral: 'Bern Inselspital',
    case_event_type: 'Ischemic stroke',
    case_arrival_date: '20150201',
    case_onset_known: 'wake up',
    case_onset_time: '20150131',
    case_wakeup_time: '20150201',
    case_referring_unitcenter: 'Emergency service (144)',
    case_transport: 'Ambulance',
    case_living_pre: 'Home',
    case_disability_pre: '0',
    case_stroke_syndrome: 'POCS',
    case_nih_admission: '2',
    case_gcs_admission: '15',
    case_bp_syst: '210',
    case_bp_diast: '90',
    treat_aspirin_pre: 'no',
    treat_clopidogrel_pre: 'no',
    treat_prasugrel_pre: 'no',
    treat_ticagrelor_pre: 'no',
    treat_dipyridamole_pre: 'no',
    treat_vka_pre: 'no',
    treat_vka_inr: '3',
    treat_rivaroxaban_pre: 'yes',
    treat_dabigatran_pre: 'yes',
    treat_apixaban_pre: 'no',
    treat_edoxaban_pre: 'no',
    treat_parenteralanticg_pre: 'no',
    treat_antihypertensive_pre: 'no',
    treat_antilipid_pre: 'no',
    treat_hormone_pre: 'no',
    treat_firstimage_type: 'MRI',
    treat_firstimage_time: '20150201',
    treat_door_image_time: '09:53',
    treat_firstimage_result: 'Ischemia',
    treat_perfusion_type: 'Perfusion MRI',
    treat_perfusion_result: 'Focal hypoperfusion without mismatch',
    treat_firstangio_type: 'MR-angiography',
    treat_firstangio_result: 'Occlusion in suspected ischemic territory',
    treat_treat_antiplatelet: 'yes',
    treat_treat_anticoagulant: 'no',
    treat_treat_ivt: 'no',
    treat_ivt_start: '20150204',
    treat_rtpa_dose: '70',
    treat_onset_treat_time: '95',
    treat_door_treat_time: '48',
    treat_treat_iat: 'yes',
    treat_iat_start: '20150204',
    treat_onset_iat_time: '18:30',
    treat_iat_end: '20150204',
    treat_door_iat_time: '19:23',
    treat_anesthesia: 'General anaesthesia',
    treat_iat_rtpa: 'no',
    treat_iat_rtpa_dose: '70',
    treat_iat_uk: 'no',
    treat_iat_uk_dose: '250000',
    treat_iat_mech: 'yes',
    treat_iat_stentretriever: 'no',
    treat_iat_aspiration: 'yes',
    treat_iat_distalretriever: 'no',
    treat_iat_balloon: 'no',
    treat_iat_stentintracran: 'no',
    treat_iat_stentextracran: 'no',
    treat_iat_mech_other: 'no',
    treat_treat_study: 'no',
    treat_hospitalisation: 'Monitored stroke unit in-house',
    enc_followimage_type: 'CT',
    enc_followimage_result: 'Ischemia',
    enc_mca: 'left',
    enc_aca: 'no',
    enc_pca: 'right',
    enc_vertebrobasilar: 'bilateral',
    enc_ocular: 'right',
    enc_etiology_toast: 'Cardiac embolism',
    enc_etiology_toast_other: 'mechanical aortic valve replacement',
    enc_etiology: 'Amyloid angiopathy',
    enc_etiology_other: 'Thrombosis of Sinus sagittalis superior',
    enc_stroke_pre: 'no',
    enc_tia_pre: 'yes',
    enc_ich_pre: 'no',
    enc_hypertension: 'no',
    enc_diabetes: 'no',
    enc_hyperlipidemia: 'yes',
    enc_smoking: 'yes',
    enc_atrialfib: 'yes',
    enc_chd: 'no',
    enc_prostheticvalves: 'Mechanical',
    enc_lowoutput: 'no',
    enc_pad: 'no',
    enc_sleep_average: '6',
    enc_sleep_lastnight: '7',
    enc_snoring: 'yes',
    enc_daytime_sleepiness: 'never',
    enc_follow_mra: 'yes',
    enc_follow_cta: 'no',
    enc_follow_ultrasound: 'no',
    enc_follow_dsa: 'yes',
    enc_follow_tte: 'yes',
    enc_follow_tee: 'yes',
    enc_follow_holter: 'yes',
    enc_follow_holter_length: '7',
    enc_glucose: '6',
    enc_cholesterol_total: '4',
    enc_cholesterol_ldl: '2',
    enc_creatinine_ldl: '91',
    enc_nih_24h: '1',
    enc_ich_sympt_hospital: 'no',
    enc_ich_sympt_hosp_date: '20150210',
    enc_stroke_hospital: 'no',
    enc_stroke_hospital_date: '20150210',
    enc_angioedema: 'no',
    enc_death_hospital: 'no',
    enc_death_hospital_date: '20150426',
    enc_death_hospital_cause: 'Fatal stroke or intracranial hemorrhage',
    enc_seizure_hospital: 'no',
    enc_seizure_hosp_date: '20160918',
    enc_decompression: 'no',
    enc_decompression_date: '20160914',
    enc_cea_symptomatic: 'no',
    enc_cea_symptomatic_date: '20160919',
    enc_cas_symptomatic: 'yes',
    enc_cas_symptomatic_date: '20160916',
    enc_endorevasc_other: 'no',
    enc_surgrevasc_other: 'no',
    enc_pfo_closure: 'no',
    enc_pfo_closure_date: '20160912',
    enc_discharge_destinat: 'Rehabilitation hospital',
    enc_discharge_date: '20150218',
    duration_hospital: '17',
    fol_followup_date: '20150518',
    fol_followup_mode: 'Telephone or other contact with ssrmdadm.patient',
    fol_mrs_90days: '3',
    fol_nih_90days: '0',
    fol_stroke_followup: 'no',
    fol_stroke_followup_date: '20150706',
    fol_ich_followup: 'no',
    fol_ich_followup_date: '20150208',
    fol_death_followup: 'yes',
    fol_death_followup_date: '20150331',
    fol_death_followup_cause: 'Fatal stroke or intracranial hemorrhage',
    fol_seizure_followup: 'no',
    fol_seizure_followup_date: '20170303',
    thr_bridging_regime: 'Full-dose IV rt-PA + IA-treatment',
    thr_bridging_regime_oth:
      'Full-dose IV rt-PA + IA treatment (at the end not performed by IV-recanalization',
    thr_rtpa_score: '86',
    thr_conc_medi: 'Example',
    thr_antithrombotic: 'Antiplatelets',
    thr_antithrombotic_oth: 'planned Antiplatelets after 4weeks',
    thr_recanalization: 'no',
    thr_antithrombotic_start: '2',
    thr_ct_denseartery: 'yes',
    thr_ct_leukoaraiosis: 'yes',
    thr_ct_oldinfarct: 'yes',
    thr_ct_earlyinfarctsings: 'no',
    thr_ct_aspects: '3',
    thr_occ_none: 'no',
    thr_occ_ica_extra: 'yes',
    thr_occ_ica_intra: 'yes',
    thr_occ_ica_t: 'yes',
    thr_occ_mca_m1: 'yes',
    thr_occ_mca_m2: 'yes',
    thr_occ_mca_m3_m4: 'yes',
    thr_occ_ba_proximal: 'yes',
    thr_occ_ba_middle: 'yes',
    thr_occ_ba_distal: 'yes',
    thr_occ_aca_a1: 'yes',
    thr_occ_aca_a2: 'yes',
    thr_occ_va: 'yes',
    thr_occ_pca_p1: 'yes',
    thr_occ_pca_p2: 'yes',
    thr_occ_pica: 'yes',
    thr_occ_suca: 'yes',
    thr_occ_aica: 'yes',
    thr_occ_other: 'yes',
    thr_occ_other_spec: 'PCOM rechts (fetal origin)',
    thr_occ_determined_by: 'CT-angiography',
    thr_occ_core: '24.7',
    thr_occ_penumbra: '12.8',
    thr_lab_inr: '1',
    thr_lab_aptt: '27',
    thr_lab_tt: '11',
    thr_lab_riv: '30',
    thr_lab_dab: '32',
    thr_lab_afxa: '30',
    thr_lab_platelets: '171',
    thr_lab_haem: '138',
    thr_lab_crp: '3',
    thr_lab_calcium: '1',
    thr_lab_albumin: '32',
    thr_lab_uric_acid: '480',
    thr_lab_triglycerides: '2',
    thr_lab_hdl: '1',
    thr_lab_hba1c: '5',
    thr_lab_tropinin_t_hs: '9',
    thr_lab_d_dimer: '5',
    thr_reperfusion_time: '20181001',
    thr_stroke_reper_time: '296',
    thr_door_reper_time: '151',
    thr_ivt_reper_time: '208',
    thr_iat_reper_time: '20',
    thr_ed_none: 'yes',
    thr_ed_solitaire: 'yes',
    thr_ed_trevo: 'yes',
    thr_ed_3d_separator: 'yes',
    thr_ed_preset: 'yes',
    thr_ed_capture: 'yes',
    thr_ed_merci: 'yes',
    thr_ed_other: 'yes',
    thr_ed_other_spec: '5 MAx ACE',
    thr_alo_acc: 'yes',
    thr_alo_aci_prox: 'yes',
    thr_alo_aci_dist: 'yes',
    thr_alo_m1: 'yes',
    thr_alo_m2: 'yes',
    thr_alo_m3: 'yes',
    thr_alo_a1: 'yes',
    thr_alo_av_prox: 'yes',
    thr_alo_av_dist: 'yes',
    thr_alo_ba_prox: 'yes',
    thr_alo_ba_dist: 'yes',
    thr_alo_p1: 'yes',
    thr_alo_p2: 'yes',
    thr_alo_other: 'yes',
    thr_alo_other_spec: 'keine i.a',
    thr_recanal_before: '(0) no perfusion',
    thr_recanal_after: '(3) full perfusion with filling of all distal branches',
    thr_pat_alone_before: 'yes',
    thr_pat_independ_before: 'yes',
    thr_pat_lift_arms_adm: 'no',
    thr_pat_walk_adm: 'no',
    thr_pat_gcs_normal: 'no',
    thr_dragon_prestroke: 'no',
    thr_dragon_age: '>80',
    thr_dragon_glucose: '>=8mmol/l',
    thr_dragon_ott: '<90 minutes',
    thr_dragon_nihss: '>15',
    thr_dragon_score: '2',
    thr_sedan_glucose: '<8mmol/l',
    thr_sedan_age: '<75',
    thr_sedan_nihss: '>9',
    thr_sedan_score: '2',
    thr_fu_timi: 'complete',
    thr_fu_any_ich: 'Hemorrhagic transformation typ I (HI I)',
    thr_fu_deterioration: 'no',
    thr_fu_sympt_ich: 'no',
    thr_sympt_ecass: 'yes',
    thr_sympt_ninds: 'yes',
    thr_sympt_sits: 'yes',
    thr_ich_proact2: 'no',
    thr_bleeding_other: 'yes',
    thr_fu_barthel_90d: '100',
    thr_fu_nihss_90d: '1',
    thr_fu_epi_90d: 'no',
    thr_fu_mrs_1y: '3',
    thr_thrombo_comments: 'Example comment',
  },
];
