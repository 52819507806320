import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  ButtonDeleteFolders,
  ButtonSharedFiles,
  ButtonSharedFolders,
} from '../index';
import { ButtonDownloadExplorerContent, IconButtonBadge } from 'components';
import LineEditIcon from '@mui/icons-material/BorderColor';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  edit: {
    margin: 0,
    padding: 0,
    paddingLeft: '5px',
    paddingBottom: '5px',
  },
  toggle: {
    margin: 0,
    padding: 0,
    paddingLeft: '7px',
    paddingRight: '4px',
    paddingBottom: '4px',
  },
});

class ToggleButtonItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        marginBottom: 8,
      },
    };
  }

  getSharedFiles = () => {
    const { selected_content, base, classes } = this.props;
    if (selected_content.length && base !== 'Shared') {
      return (
        <ToggleButton className={classes.toggle} value="sharedfiles">
          <ButtonSharedFiles
            base={base}
            handleDrawerClose={this.props.handleDrawerClose}
            handleShareFolder={this.props.handleShareFolder}
            iconProps={{ style: this.state.style }}
            selected_content={selected_content}
            size={selected_content.length}
          />
        </ToggleButton>
      );
    }
    return null;
  };
  getDownloadFiles = () => {
    const { selected, classes } = this.props;
    if (selected.length) {
      return (
        <ToggleButton className={classes.toggle} value="download-content">
          <ButtonDownloadExplorerContent
            handleProgress={this.props.handleProgress}
            iconProps={{ style: this.state.style }}
            selected_content={this.props.selected_content}
            selected_folder={this.props.selected_folder}
            size={selected.length}
          />
        </ToggleButton>
      );
    }
    return null;
  };
  getSharedFolder = () => {
    const { selected_folder, base, classes } = this.props;
    if (selected_folder.length && base !== 'Shared') {
      return (
        <ToggleButton className={classes.toggle} value="shared-folders">
          <ButtonSharedFolders
            base={base}
            handleDrawerClose={this.props.handleDrawerClose}
            handleShareFolder={this.props.handleShareFolder}
            iconProps={{ style: this.state.style }}
            selected_folder={selected_folder}
            size={selected_folder.length}
          />
        </ToggleButton>
      );
    }
    return null;
  };
  getDeleteFolders = () => {
    const { selected, base, classes } = this.props;
    // console.log({ selected });
    if (selected.length) {
      return (
        <ToggleButton className={classes.toggle} value="delete-folders">
          <ButtonDeleteFolders
            base={base}
            handleDeleteFolders={this.props.handleDeleteFolders}
            handleDrawerClose={this.props.handleDrawerClose}
            iconProps={{ style: this.state.style }}
            selected_content={this.props.selected_content}
            selected_folder={this.props.selected_folder}
            size={selected.length}
          />
        </ToggleButton>
      );
    }
    return null;
  };
  getEditDesignerFiles = () => {
    const { selected_content, classes, user } = this.props;
    if (user === undefined) return null;
    if (selected_content.length && user.idCategory === 2) {
      return (
        <ToggleButton className={classes.toggle} value="edit-files">
          <IconButtonBadge
            className={classes.edit}
            color_icon="green"
            Icon={LineEditIcon}
            onClick={this.props.handleEditSelected}
            size={selected_content.length}
            size_buttom="small"
            title="Edit Selected"
            vertical="bottom"
          />
        </ToggleButton>
      );
    }
    return null;
  };
  render() {
    return (
      <ToggleButtonGroup
        aria-label="text alignment"
        exclusive
        style={{ height: 40, marginRight: 15 }}>
        {this.getEditDesignerFiles()}
        {this.getSharedFiles()}
        {this.getDownloadFiles()}
        {this.getSharedFolder()}
        {this.getDeleteFolders()}
      </ToggleButtonGroup>
    );
  }
}

ToggleButtonItems.propTypes = {
  classes: PropTypes.object,
};
ToggleButtonItems.defaultProps = {
  handleProgress: null,
  handleEditSelected: () => '',
};
export default withStyles(ToggleButtonItems, useStyles);
