/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { RowText, RowTextMultiLine } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';

class ClinicalSingleChildObjectViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = (value, type) => {
    
    if (type === 'boolean') {
     
      return value ? 'Yes' : 'No';
    }
    if (IsValid(value)) {
      
      return value.toString();
    }
    return '-';
  };

  render() {
    let { clinicalObject, columns } = this.props;
    if (Array.isArray(clinicalObject)) return;
    const multilineFields = columns.filter(item => item.multiline === true);
    columns = columns.filter(
      item => item.multiline === false || item.multiline === undefined
    );
    const filteredColumns = columns.filter(
      item => item.hideViewer === false || item.hideViewer === undefined
    );
    const midPoint = Math.ceil(filteredColumns.length / 2);
    const firstHalf = filteredColumns.slice(0, midPoint);
    const secondHalf = filteredColumns.slice(midPoint);
    return (
      <Box>
        {filteredColumns.length > 0 ? (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={6}>
              {clinicalObject && firstHalf.map(clinicalField => (
                <React.Fragment key={clinicalField.headerName}>
                  {clinicalField.type === 'datetime' || clinicalField.type === 'time' ||
                  clinicalField.type === 'date' ? (
                    <RowText
                      display={clinicalField.headerName}
                      first={clinicalField.headerName + ':'}
                      second={
                        clinicalObject[clinicalField.field]
                          ? this.formatDate(clinicalObject[clinicalField.field])
                          : null
                      }
                      styleBox={{ fontWeight: 800 }}
                    />
                  ) : (
                    <RowText
                      display={clinicalField.headerName}
                      first={clinicalField.headerName + ':'}
                      second={
                        clinicalObject[clinicalField.field]
                          ? this.getValue(clinicalObject[clinicalField.field])
                          : ''
                      }
                      styleBox={{ fontWeight: 800 }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={6}>
              {clinicalObject && secondHalf.map(clinicalField => (
                <React.Fragment key={clinicalField.headerName}>
                  {clinicalField.type === 'datetime' || clinicalField.type === 'time' ||
                  clinicalField.type === 'date' ? (
                    <RowText
                      display={clinicalField.headerName}
                      first={clinicalField.headerName + ':'}
                      second={
                        clinicalObject[clinicalField.field]
                          ? this.formatDate(clinicalObject[clinicalField.field])
                          : null
                      }
                      styleBox={{ fontWeight: 800 }}
                    />
                  ) : (
                    <RowText
                      display={clinicalField.headerName}
                      first={clinicalField.headerName + ':'}
                      second={
                        clinicalObject[clinicalField.field]
                          ? this.getValue(clinicalObject[clinicalField.field])
                          : ''
                      }
                      styleBox={{ fontWeight: 800 }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={12}>
              {clinicalObject && multilineFields.map(clinicalField => (
                <React.Fragment key={clinicalField.headerName}>
                  <RowTextMultiLine
                    display={clinicalField.headerName}
                    first={clinicalField.headerName + ':'}
                    second={this.getValue(
                      clinicalObject[clinicalField.field],
                      clinicalField.type
                    )}
                    styleBox={{ fontWeight: 800 }}
                  />
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Typography component="p" gutterBottom sx={{ p: 2 }} variant="p">
            No data to show
          </Typography>
        )}
      </Box>
    );
  }
}

ClinicalSingleChildObjectViewer.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalSingleChildObjectViewer;
