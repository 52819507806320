import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { Dialog, Grid } from '@mui/material';

import {
  BarShareToUserProject,
  FolderFilesNames,
  useStyles,
} from '../components';

import {
  ButtonLoad,
  DialogActions,
  DialogContent,
  DialogTitle,
  SnackMessage,
  TableToShareSeries,
} from 'components';

import { ShareSeries } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';

class DialogShareSeries extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      objects: [],
      error: '',
      to_delete: [],
      loading: false,
    };
    this.holder = {
      cleanSelected: null,
    };
  }

  runShareSeries = () => {
    const { loading, objects } = this.state;
    if (loading) return;
    const ids = this.props.ListSeries.map(x => x.idSeries);
    this.setState({ loading: true });
    (async () => {
      ShareSeries(ids, objects)
        .then(res => {
          const data = GetData(res);
          if (data.ShareSeries) {
            const { ok, errors } = data.ShareSeries;
            if (ok) {
              this.setState({ loading: false, to_delete: [], objects: [] });
              this.holder.cleanSelected();
              this.props.handleClose();
            } else {
              throw errors;
            }
          } else {
            throw Error('Unkown server reply');
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  // handleShareSeries = () => {
  //
  // };
  getIndexObject = object => {
    const { objects } = this.state;
    let index = -1;
    for (let i = 0; i < objects.length; i++) {
      const { id, name } = objects[i];
      if (id === object.id && name === object.name) {
        index = i;
        break;
      }
    }
    return index;
  };
  handleAddObject = object => {
    const { objects } = this.state;

    if (this.getIndexObject(object) === -1) {
      objects.push(object);
    }
    this.setState({ objects });
  };
  handleSelectionChanged = selected => {
    //
    this.setState({ to_delete: selected });
  };
  handleDeleteSelected = () => {
    let { to_delete, objects } = this.state;
    for (let i = 0; i < to_delete.length; i++) {
      const index = this.getIndexObject(to_delete[i]);
      if (index !== -1) {
        objects.splice(index, 1);
      }
    }
    this.setState({ to_delete: [], objects });
    this.holder.cleanSelected();
  };

  render() {
    const { open, ListSeries } = this.props;
    const { objects, error, to_delete, loading } = this.state;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={this.props.handleClose}
        open={open}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.props.handleClose}>
          <FolderFilesNames
            objects={ListSeries}
            placeholder="List of series"
            size={ListSeries.size}
            title="Series to Share"
          />
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={2}>
            <Grid item>
              <BarShareToUserProject
                handleAdd={this.handleAddObject}
                handleDeleteSelected={this.handleDeleteSelected}
                to_delete={to_delete}
              />
            </Grid>
            <TableToShareSeries
              handleSelectionChanged={this.handleSelectionChanged}
              holder={this.holder}
              rows={objects}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: 5, marginTop: 5 }}>
          <ButtonLoad
            disable_button={!objects.length}
            handleClick={this.runShareSeries}
            loading={loading}
            name="Share Series"
          />
        </DialogActions>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Dialog>
    );
  }
}

DialogShareSeries.defaultProps = {
  ListSeries: [],
};
DialogShareSeries.propTypes = {
  ListSeries: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles(DialogShareSeries, useStyles);
