/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { FullSeries } from 'graphql/Series';
import { GetData, ServerErrors } from 'helpers';
import { ViewEditSeries, ViewShowSeries } from './components';
import { SnackMessage } from 'components';

const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class SeriesViewEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LocalSeries: {},
      loading: false,
      error: '',
    };
  }

  componentDidMount() {
    this.loadSeries();
  }

  componentDidUpdate() {
    this.loadSeries();
  }

  loadSeries = () => {
    const { Series, idSeries, view } = this.props;
    if (view === 'multi') return;
    const { loading, LocalSeries } = this.state;
    if (Series && !idSeries && typeof LocalSeries.idSeries === 'undefined') {
      this.setState({ LocalSeries: Series });
      return;
    }
    if (loading || LocalSeries.idSeries === idSeries) return;
    // this.state.loading = true;
    this.setState({ loading: true });
    (async () => {
      FullSeries(idSeries)
        .then(res => {
          const data = GetData(res);
          if (data && data.Series !== undefined) {
            const { Series } = data.Series;
            if (
              Series !== undefined &&
              Series.length &&
              Series[0].idSeries === idSeries
            ) {
              this.setState({ loading: false, LocalSeries: Series[0] });
            } else {
              throw Error('Unexpected error please refresh the page');
            }
          }
        })
        .catch(errors => {
          const error = ServerErrors(errors);
          this.setState({
            loading: false,
            error: error.join('\n'),
          });
          this.props.handleError(`- ${error.join('\n- ')}`);
        });
    })();
  };
  handleDrawerClose = () => {
    this.setState({ details: false });
  };
  handleError = error => {
    this.setState({ error });
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    let { Series, classes, idSeries, view } = this.props;
    const { LocalSeries, loading, error } = this.state;
    //
    if (!loading && view !== 'multi') {
      if (!Series && LocalSeries.idSeries) {
        Series = LocalSeries;
      } else if (!Series && !idSeries) {
        this.props.history.push('/image_analysis');
        return null;
      } else if (idSeries && !Series) {
        return null;
      }
    }
    return (
      <div style={{ display: 'flex' }}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!loading &&
          (view === 'view' ? (
            <ViewShowSeries
              handleClickFileItem={this.handleClickFileItem}
              handleError={this.handleError}
              Series={Series}
            />
          ) : (
            <ViewEditSeries
              edit={view}
              handleClickFileItem={this.handleClickFileItem}
              handleError={this.handleError}
              Series={Series}
            />
          ))}
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error === '' ? false : true}
          type="error"
        />
      </div>
    );
  }
}

SeriesViewEdit.propTypes = {
  classes: PropTypes.object,
  handleError: PropTypes.func,
  idSeries: PropTypes.number,
};
export default withStyles(withRouter(SeriesViewEdit), useStyles);
