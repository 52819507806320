import React from 'react';
const style = {
  textAlign: 'justify',
  fontSize: 20,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};
export const DL_DiReCT = {
  direction: 'image_bottom',
  image: '/images/dl_direct.jpg',
  title: 'DL+DiReCT',
  description: (
    <p style={style}>
      &nbsp; &nbsp; &nbsp;The <strong>DL+DiReCT</strong> combines a deep
      learning-based neuroanatomy segmentation and cortex parcellation with a
      diffeomorphic registration technique to measure cortical thickness from{' '}
      <strong>T1w</strong> MRI.
      <br />
      &nbsp; &nbsp; &nbsp;A full description of the program is located in{' '}
      <a
        href="https://onlinelibrary.wiley.com/doi/10.1002/hbm.25159"
        rel="noreferrer"
        target="_blank">
        https://doi/10.1002/hbm.25159
      </a>
      , and the source code is located in the GitHub repository:{' '}
      <a
        href="https://github.com/SCAN-NRAD/DL-DiReCT"
        rel="noreferrer"
        target="_blank">
        https://github.com/SCAN-NRAD/DL-DiReCT
      </a>
    </p>
  ),
};
