/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { GetTypeString } from '../../../utils';
import { QuerySearchReceiver } from 'graphql/Communications';
import { GetData } from 'helpers';
import { inputProps } from 'common';

class AutoCompleteCommunication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiver: undefined,
      name: '',
      loading: false,
      receivers: [],
      open: false,
      length: 0,
    };
  }
  componentDidUpdate() {
    const { creating } = this.props;
    const { name } = this.state;
    if (creating && name !== '') {
      this.setState({ name: '' });
    }
  }
  handleOpen = open => {
    this.setState({ open });
  };
  handleReceiverNameChanged = name => {
    this.setState({ name });
    this.RunSearch(name);
  };
  RunSearch = name => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.handleLoading(true);
    (async () => {
      QuerySearchReceiver(name)
        .then(res => {
          const data = GetData(res);
          this.setState({
            loading: false,
            receivers: data.searchReceiver,
            // open: false,
          });
          this.props.handleLoading(false);
        })
        .catch(error => {
          console.log({error})
          this.setState({ loading: false, receivers: [] });
          this.props.handleLoading(false);
        });
    })();
  };
  handleReceiverChanged = receiver => {
    if (typeof receiver === 'undefined' || !receiver) {
      return;
    }
    this.setState({ receiver, length: receiver.name.length });
  };
  render() {
    const { open, receivers, loading, name } = this.state;
    return (
      <Autocomplete
        getOptionLabel={option => {
          if (receivers.length === 0) return '';
          return option.name + ' (' + GetTypeString(option) + ')';
        }}
        id="search-users-teams-input"
        inputValue={name}
        isOptionEqualToValue={(option, value) => {
          if (typeof value === 'undefined') return true;
          const { name } = value;
          if (name && name === '') return true;
          return option.name === name;
        }}
        loading={loading}
        onChange={(event, newValue) => {
          this.handleReceiverChanged(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleReceiverNameChanged(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={receivers}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="user"
            onKeyDown={event => {
              event.persist();
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleAddReceiver(this.state.receiver);
                this.setState({ receiver: undefined, name: '' });
              }
            }}
            placeholder="Search Users or Teams..."
            variant="standard"
          />
        )}
        style={{
          paddingTop: 10,
          paddingLeft: 40,
        }}
      />
    );
  }
}

AutoCompleteCommunication.propTypes = {
  classes: PropTypes.object,
};

export default AutoCompleteCommunication;
