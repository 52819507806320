import React from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import Tooltip from '@mui/material/Tooltip';

import Toolbar from '@mui/material/Toolbar';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import { withRouter } from 'react-router-dom';

const useStyles = () => ({
  button_bar: {
    minHeight: '50px',
    height: '50px',
    color: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  settings: {
    marginLeft: '-20px',
  },
  zindex: {
    zIndex: 2000,
  },
});

class BottomSidebar extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  handleLogOut = e => {
    e.preventDefault();
    console.log('BottomSidebar');
    this.props.logout();
    this.props.history.push('/');
  };

  render() {
    const { classes } = this.props;
    return (
      <Toolbar className={classes.button_bar}>
        <Tooltip title="Settings">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              className={classes.settings}
              component={RouterLink}
              size="large"
              to="/settings">
              <SettingsIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="LogOut">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton onClick={this.handleLogOut} size="large">
              <PowerSettingsNewIcon />
            </IconButton>
          </span>
        </Tooltip>

        <div className={classes.grow} />
        <Tooltip className={classes.zindex} title="Mini Sidebar">
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              color="inherit"
              edge="end"
              onClick={this.props.handleCloseSidebar}
              size="large">
              <ChevronLeftIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Toolbar>
    );
  }
}

BottomSidebar.prototypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
BottomSidebar.defaultProps = {
  handleCloseSidebar: () => '',
};

const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(mapStateToProps, { logout })(
  withStyles(withRouter(BottomSidebar), useStyles)
);
