import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { blue, red, grey, green } from '@mui/material/colors';
import {
  ButtonCircularIconLoad,
  DivGrow,
  ButtonCircularDownloadFolderContent,
  DialogDeleteWarning,
} from 'components';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { connect } from 'react-redux';
import { IsEditAllow } from 'helpers/Designers';
import { SendEmail } from 'graphql/Communications/utils_emails';

const useStyles = theme => ({
  button_blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  button_red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  button_grey: {
    backgroundColor: grey[500],
    '&:hover': {
      backgroundColor: grey[600],
    },
  },
  button_green: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
});

class ActionsButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailing: false,
      open_message: false,
    };
  }

  render() {
    const { classes, owner, open, loading, user } = this.props;
    let OpenButton = null;
    if (open) {
      OpenButton = (
        <ButtonCircularIconLoad
          className={classes.button_blue}
          // disabled={!owner}
          handleButtonClick={this.props.handleClickShow}
          icon={<OpenWithIcon />}
          small
          tooltip="Show Series"
        />
      );
    }
    let EditButtons = null;
    if (IsEditAllow(user)) {
      EditButtons = (
        <ButtonCircularIconLoad
          className={classes.button_blue}
          handleButtonClick={this.props.handleEdit}
          icon={<BorderColorIcon />}
          small
        />
      );
    }
    let ComponentDownload = null;
    if (user && user.type === 1) {
      ComponentDownload = (
        <ButtonCircularDownloadFolderContent
          className={classes.button_grey}
          content={this.props.folder_content}
          icon={<InstallDesktopIcon color="red" />}
          tooltip="Download high-resolution"
        />
      );
    } else {
      ComponentDownload = (
        <ButtonCircularIconLoad
          className={classes.button_green}
          handleButtonClick={() => this.setState({ open_message: true })}
          icon={<InstallDesktopIcon />}
          loading={this.state.emailing}
          small
          tooltip="Request high-resolution"
        />
      );
    }
    return (
      <React.Fragment>
        <List style={{ width: '100%', padding: 0, margin: 0 }}>
          <ListItem>
            {EditButtons}
            <ButtonCircularDownloadFolderContent
              className={classes.button_grey}
              content={this.props.folder_content}
              download_type="thumbnail"
              icon={<InstallMobileIcon color="red" />}
              tooltip="Download low-resolution"
            />
            {ComponentDownload}
            {OpenButton}
            <DivGrow />
            <ButtonCircularIconLoad
              className={classes.button_red}
              disabled={!owner}
              handleButtonClick={this.props.handleButtonDelete}
              icon={<DeleteIcon />}
              loading={loading}
              small
              tooltip={owner ? 'Delete Folder' : 'Remove Shared Folder'}
            />
          </ListItem>
        </List>
        <DialogDeleteWarning
          button_cancel="No"
          button_text="Yes"
          handleButtonClicked={() =>
            SendEmail(this.props, this.state, state =>
              this.setState({ ...state, open_message: false })
            )
          }
          handleClose={() =>
            this.setState({ open_message: !this.state.open_message })
          }
          loading={this.state.emailing}
          open={this.state.open_message}
          question_text="Would you like to request a high-resolution version of the image?. If yes, we will contact you as soon as possible."
          text_question="Do you want to send the request?"
          title_text="Donwload High-Resolution request"
        />
      </React.Fragment>
    );
  }
}

ActionsButtons.propTypes = {
  classes: PropTypes.object,
};
ActionsButtons.defaultProps = {
  loading: false,
  downloading: false,
  owner: false,
  open: false,
};
const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(ActionsButtons, useStyles));
