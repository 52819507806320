/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  Checkbox,
  Collapse,
  Grid,
  Typography,
} from '@mui/material';
import { withRouter } from 'react-router-dom';

import { withStyles } from 'tss-react/mui';

import useStyles from './Styles';

import { FormatTime, GetData, getInitials, ServerErrorsString } from 'helpers';
import { QueryRunSeriesDetails } from 'graphql/Series';
import {
  ButtonCircularWait,
  ButtonExpand,
  ButtonReloadDetails,
  CardCollapseSeries,
} from './components';
import { IsInvalid } from 'helpers';
import { SeriesTagValue } from 'helpers/Series';

class CardEditSeries extends Component {
  constructor(props) {
    super(props);
    const { expanded } = props;
    //
    this.state = {
      expanded: expanded ? expanded : true,
      loading_details: false,
      Details: { ok: false },
    };
    const { holder } = props;
    if (typeof holder !== 'undefined' && typeof holder.reload !== 'undefined') {
      holder.reload.push(() => this.handleReload());
    }
  }

  componentDidMount() {
    const { expanded } = this.props;
    if (typeof expanded !== 'undefined' && expanded !== this.state.expanded) {
      this.setState({ expanded });
    }
    this.handleReload();
  }

  handleExpandClick = () => {
    this.setState(prev => ({ expanded: !prev.expanded }));
  };
  handleReload = () => {
    const { Series } = this.props;
    const { loading_details } = this.state;
    if (loading_details || !Series || Series === undefined) {
      return;
    }
    this.setState({ loading_details: true, Details: { ok: false } });
    QueryRunSeriesDetails(Series.idSeries)
      .then(res => {
        const data = GetData(res);
        let Details = { ok: false };
        if (typeof data !== 'undefined' && data.SeriesDetails) {
          const { ok, errors } = data.SeriesDetails;
          //
          if (ok) {
            Details = { ...data.SeriesDetails.Details, ok };
          } else {
            throw errors;
          }
        }
        this.setState({ loading_details: false, Details });
      })
      .catch(error => {
        this.setState({ loading_details: false });
        this.props.addError(ServerErrorsString(error));
      });
  };
  handleDetailChanged = to_send => {
    const { Details } = this.state;
    const keys = Object.keys(to_send);
    //
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key === 'focus') {
        if (Details.SeriesFocus) {
          Details.SeriesFocus.ImageFocus = to_send[key];
        } else {
          Details.SeriesFocus = { ImageFocus: to_send[key] };
        }
      } else if (key === 'region') {
        if (Details.SeriesRegion) {
          Details.SeriesRegion.AnatomicalRegion = to_send[key];
        } else {
          Details.SeriesRegion = { AnatomicalRegion: to_send[key] };
        }
      } else if (key === 'pathology') {
        if (Details.SeriesPathology) {
          Details.SeriesPathology.Pathology = to_send[key];
        } else {
          Details.SeriesPathology = { Pathology: to_send[key] };
        }
      } else if (key === 'sequence') {
        if (Details.SeriesSequence) {
          Details.SeriesSequence.Sequence = to_send[key];
        } else {
          Details.SeriesSequence = { Sequence: to_send[key] };
        }
      } else if (key === 'side') {
        if (Details.SeriesSide) {
          Details.SeriesSide.InvestigatedSide = to_send[key];
        } else {
          Details.SeriesSide = { InvestigatedSide: to_send[key] };
        }
      }
    }
    this.setState({ Details });
  };

  render() {
    const {
      classes,
      Series,
      addError,
      handleSelectRow,
      isItemSelected,
    } = this.props;
    if (Series === undefined || !Series) return null;
    const { expanded, Details, loading_details } = this.state;
    //
    //   idSeries: Series.idSeries,
    //   expanded,
    //   Details,
    //   Series,
    // });
    let SeriesDescription = 'Undefined';
    let Thumbnail = '';
    let idSeries = -1;
    let updatedAt = '';
    let owner = '';
    let ComponentPatient = null;
    if (Series) {
      if (IsInvalid(Series.Thumbnail) || Series.Thumbnail === '') {
        Thumbnail = '/images/icons/dcm.png';
      } else {
        Thumbnail = `data:image/jpeg;base64,${Series.Thumbnail}`;
      }
      SeriesDescription = Series.SeriesDescription;
      idSeries = Series.idSeries;
      updatedAt = FormatTime(Series.updatedAt, 'MMM Do, YYYY (h:mm a)');
      if (Series.Owner) owner = Series.Owner.FullName;
      if (Series.Patient) {
        ComponentPatient = (
          <Grid item>
            <Typography
              display="inline"
              gutterBottom
              style={{ marginRight: '5px', textTransform: 'none' }}
              variant="h6">
              Patient:
            </Typography>
            <Typography display="inline" gutterBottom variant="caption">
              {SeriesTagValue(Series, 'PatientName')}
            </Typography>
          </Grid>
        );
      }
    }
    return (
      <Card className={classes.root}>
        <CardActions disableSpacing>
          {typeof isItemSelected !== 'undefined' && (
            <Checkbox
              checked={isItemSelected}
              onClick={() => {
                handleSelectRow(idSeries);
              }}
            />
          )}
          <CardHeader
            action={
              loading_details ? (
                <ButtonCircularWait />
              ) : Details.ok ? (
                <ButtonExpand
                  classes={classes}
                  expanded={expanded}
                  handleExpandClick={this.handleExpandClick}
                />
              ) : (
                <ButtonReloadDetails
                  ok={Details.ok}
                  reloadDetails={this.handleReload}
                />
              )
            }
            avatar={
              <Avatar
                alt={getInitials(SeriesDescription)}
                aria-label="recipe"
                className={classes.avatar}
                src={Thumbnail}
              />
            }
            className={classes.card_header}
            // style={{ width }}
            subheader={
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                justifyContent="space-between">
                <Grid item>
                  <Typography
                    display="inline"
                    gutterBottom
                    style={{ marginRight: '5px', textTransform: 'none' }}
                    variant="h6">
                    Last udpate:
                  </Typography>
                  <Typography display="inline" gutterBottom variant="caption">
                    {updatedAt}
                  </Typography>
                </Grid>
                {/* <DivGrow /> */}
                <Grid item>
                  <Typography
                    display="inline"
                    gutterBottom
                    style={{ marginRight: '5px', textTransform: 'none' }}
                    variant="h6">
                    Owner:
                  </Typography>
                  <Typography display="inline" gutterBottom variant="caption">
                    {owner}
                  </Typography>
                </Grid>
              </Grid>
            }
            title={
              <Grid
                alignItems="flex-start"
                container
                direction="row"
                justifyContent="space-between">
                <Grid item>
                  <Typography
                    display="inline"
                    gutterBottom
                    style={{ marginRight: '5px', textTransform: 'none' }}
                    variant="h6">
                    SeriesDescription:
                  </Typography>
                  <Typography
                    display="inline"
                    gutterBottom
                    style={{ fontSize: '13px' }}
                    variant="caption">
                    {`${SeriesDescription} (ID: ${idSeries})`}
                  </Typography>
                </Grid>
                {ComponentPatient}
              </Grid>
            }
          />
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {!loading_details && Details.ok && (
            <CardCollapseSeries
              addError={addError}
              classes={classes}
              Details={Details}
              handleDetailChanged={this.handleDetailChanged}
              handleReload={this.handleReload}
              idSeries={idSeries}
            />
          )}
        </Collapse>
      </Card>
    );
  }
}

CardEditSeries.propTypes = {
  Series: PropTypes.object,
  classes: PropTypes.object,
};
CardEditSeries.defaultProps = {
  Series: null,
};

export default withStyles(withRouter(CardEditSeries), useStyles);
