import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonReload } from 'components';
import BackupIcon from '@mui/icons-material/Backup';
import { blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import { styleToolbar } from 'common';
import { DialogDeleteDockerImage } from 'components';

class GridDockerToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, selected, disabled, deleting } = this.props;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <ButtonReload
          className={classes.margin}
          color="red"
          ComponentIcon={DeleteIcon}
          // handleReload={this.props.handleOpenDelete}
          handleReload={this.props.handleDeleteSelected}
          loading={deleting}
          small
          tooltip="Delete details"
          wrapper={{ margin: 0 }}
        />
      );
    }
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button onClick={this.props.handleOpenCreate}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
            <Button onClick={this.props.handleOpenCreateUpload}>
              <BackupIcon style={{ color: !disabled ? blue[500] : 'gray' }} />
            </Button>
          </ButtonGroup>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={2}>
            {ComponentDelete}
            <ButtonReload
              className={classes.margin}
              handleReload={this.props.handleReload}
              loading={this.props.loading}
              small
              tooltip="Reload details"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteDockerImage
          handleCloseDialog={this.props.handleDeleteDialog}
          handleDeletedSelected={this.handleDeletedSelected}
          handleError={this.props.handleAddError}
          open={this.state.open_delete}
          selected={this.state.selected}
        />
      </Paper>
    );
  }
}

GridDockerToolBar.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
GridDockerToolBar.defaultProps = {
  loading: false,
  deleting: false,
  selected: [],
  handleOpenEdit: () => '',
  handleOpenCreate: () => '',
  handleReload: () => '',
  // handleDeleteSelected: () => '',
};
export default withStyles(GridDockerToolBar, styleToolbar);
