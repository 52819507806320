import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import { ButtonReloadDelete } from 'components';

import { styleToolbar } from 'common';
import { copySeries, copyFiles } from 'redux-store/actions/file_folder_manager';
import {
  ButtonGroupEdit,
  ToggleButtonSelection,
  ToolbarSearchFilter,
} from './components';
class ToolBarPatientsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, disabled, view, selected } = this.props;
    let ComponentSearchUsers = null;
    if (view !== 'settings') {
      ComponentSearchUsers = (
        <ToolbarSearchFilter
          handleAddError={this.props.handleAddError}
          handleChangeName={this.props.handleChangeName}
          handleReloadTable={this.props.handleReloadTable}
          holder={this.props.holder}
          name={this.props.name}
          view={view}
        />
      );
    }
    let numSelectedSeries = 0;
    let numSelectedFiles = 0;
    if (view === 'series') numSelectedSeries = selected.length;
    else numSelectedFiles = selected.length;
    const numSelected = numSelectedSeries + numSelectedFiles;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45 }}>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            spacing={1}
            xs={3}>
            <Grid item>
              <ToggleButtonSelection
                disabled={disabled}
                handleAddError={this.props.handleAddError}
                handleAddSuccess={this.props.handleAddSuccess}
                handleViews={this.props.handleViews}
                view={this.props.view}
              />
            </Grid>
            <Grid item>
              <ButtonGroupEdit
                handleAddError={this.props.handleAddError}
                handleAddSuccess={this.props.handleAddSuccess}
                handleOpenEdit={this.props.handleOpenEdit}
                numSelected={numSelected}
                selected={selected}
              />
            </Grid>
          </Grid>
          {ComponentSearchUsers}
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={this.props.numSelected}
            tooltip="Reload USer"
          />
        </Grid>
      </Paper>
    );
  }
}

ToolBarPatientsActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarPatientsActions.defautlProps = {
  name: '',
  handleChangeName: () => '',
  handleOpenCreateUser: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  loading: false,
  loading_table: false,
  numSelected: 0,
};
const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let size = copy_series ? copy_series.length : 0;
  if (copy_files) size += copy_files.length;
  return {
    copy_series: copy_series ? copy_series : [],
    copy_files: copy_files ? copy_files : [],
    size,
  };
};

export default connect(mapStateToProps, { copySeries, copyFiles })(
  withStyles(ToolBarPatientsActions, styleToolbar)
);
