import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

class ComboxBasic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { inputValue, value, options, ...res } = this.props;
    return (
      <Autocomplete
        {...res}
        id="controllable-states-demo"
        inputValue={inputValue}
        onChange={(event, newValue) => {
          if (newValue === null || newValue === undefined) {
            newValue = 'None';
          }
          this.setState({ value: newValue });
        }}
        onInputChange={(event, newInputValue) => {
          if (
            newInputValue === null ||
            newInputValue === undefined ||
            newInputValue === ''
          ) {
            newInputValue = 'None';
          }
          this.setState({ inputValue: newInputValue });
        }}
        options={options}
        renderInput={params => (
          <TextField {...params} label={this.props.label} />
        )}
        sx={{ width: '100%' }}
        value={value}
      />
    );
  }
}

ComboxBasic.propTypes = {
  inputValue: PropTypes.string,
  options: PropTypes.array,
};
ComboxBasic.defaultProps = {
  inputValue: 'None',
  value: 'None',
  options: ['None'],
  label: 'Controllable',
};

export default ComboxBasic;
