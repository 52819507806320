import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 5,
    type: 'number',
  },
  {
    id: 'name',
    label: 'Name',
    type: 'name_only',
    minWidth: 50,
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
