import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from 'tss-react/mui';
import { CardHeader, Divider, Grid, Paper } from '@mui/material';

import { StepContainer } from '../index';
import { DialogInviteUser, TableUsers } from 'components';
import { projectCreateUsers } from 'redux-store/actions/projectActions';

import { UsersToolbar } from './components';

const useStyles = theme => ({
  container: {
    padding: theme.spacing(3, 2),
  },
  content: {
    width: '100%',
  },
  table: {
    width: '98.5%',
    hight: 'auto',
    margin: theme.spacing(0, 2, 2, 2),
  },
});

class StepSelectUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_invite: false,
    };
  }

  handleAddUser = user => {
    const { users } = this.props;
    if (!user) {
      return;
    }
    const index = users.map(x => x.id).indexOf(user.id);

    if (index === -1 && typeof user.id !== 'undefined') {
      users.push(user);
      this.props.projectCreateUsers(users);
    } else {
      if (index !== -1) {
        this.props.addError('User is already here');
      } else {
        this.props.addError('Invalid user');
      }
    }
  };
  handelOpenInviteUser = open_invite => {
    this.setState({ open_invite });
  };
  handleDeleteUser = selected => {
    const { users } = this.props;
    if (!selected || !users) {
      return;
    }
    let new_users = [];
    for (let i = 0; i < users.length; i++) {
      let index = selected.map(x => x.id).indexOf(users[i].id);
      if (index === -1) {
        index = selected.map(x => x.email).indexOf(users[i].email);
        if (index === -1) {
          new_users.push(users[i]);
        }
      }
    }

    this.props.projectCreateUsers(new_users);
  };

  render() {
    const { title, users, classes } = this.props;
    const { open_invite } = this.state;
    return (
      <StepContainer off_heander title={title}>
        <Paper className={classes.content}>
          <CardHeader
            subheader="Project Users"
            title="List of users for this project"
          />
          <Divider />
          <Grid
            alignItems="stretch"
            className={classes.content}
            container
            direction="column"
            justifyContent="flex-start"
            spacing={2}>
            <UsersToolbar
              handleAddUser={this.handleAddUser}
              handleOpenInviteUser={this.handelOpenInviteUser}
            />
            <div className={classes.table}>
              <TableUsers
                handleDeleteUser={this.handleDeleteUser}
                rows={users}
                title={'Project Members'}
              />
            </div>
          </Grid>
        </Paper>
        <DialogInviteUser
          handleCloseDialog={this.handelOpenInviteUser}
          open={open_invite}
        />
      </StepContainer>
    );
  }
}

StepSelectUsers.propTypes = {
  className: PropTypes.string,
  projectCreateUsers: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  users: state.projects.users,
  size: state.projects.users.length,
});

export default connect(mapStateToProps, {
  projectCreateUsers,
})(withStyles(StepSelectUsers, useStyles));
