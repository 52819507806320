import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';

import { Transition } from 'components';
import { EditMedataMulti, EditMedataSingle } from './components';

class DialgoEditMetadata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      updating: false,
      metadata: null,
      props_metadata: null,
      changed: false,
    };
  }
  handleClose = () => {
    this.props.handleClose();
  };
  updateMultiMetadata = inputs => {
    console.log({ inputs });
  };
  render() {
    let {
      folder_content,
      selected_content,
      open_edit,
      handleAddError,
    } = this.props;
    if (!open_edit) return null;
    if (selected_content && selected_content.length === 1) {
      folder_content = selected_content[0];
    }
    let ComponentContent = null;
    if (folder_content) {
      ComponentContent = (
        <EditMedataSingle
          folder_content={folder_content}
          handleAddError={handleAddError}
          handleClose={this.handleClose}
          handleUpdateFolderContent={this.props.handleUpdateFolderContent}
          handleUpdateMetadata={this.props.handleUpdateMetadata}
        />
      );
    } else {
      ComponentContent = (
        <EditMedataMulti
          handleAddError={handleAddError}
          handleClose={this.handleClose}
          handleUpdateMetadata={this.updateMultiMetadata}
          selected_content={selected_content}
        />
      );
    }
    return (
      <Dialog
        fullScreen
        onClose={this.handleClose}
        open={open_edit}
        TransitionComponent={Transition}>
        {ComponentContent}
      </Dialog>
    );
  }
}

DialgoEditMetadata.propTypes = {
  handleClose: PropTypes.func,
  open_edit: PropTypes.bool,
};
DialgoEditMetadata.defaultProps = {
  handleUpdateMetadata: () => '',
  handleClose: () => '',
  open_edit: false,
  handleUpdateFolderContent: () => '',
};

export default DialgoEditMetadata;
