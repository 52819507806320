import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Button, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { v1 as uuid } from 'uuid';
const useStyles = () => ({
  root: {},
  button_transform: {
    textTransform: 'none',
  },
  default_style: {
    marginTop: 0,
    marginBottom: 0,
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
    width: 'fit-content',
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '40%',
    marginTop: -12,
    // marginLeft: -theme.spacing(3),
  },
});

class ButtonLoading extends Component {
  render() {
    const {
      classes,
      loading,
      isValid,
      handleClick,
      disable_button,
      className,
    } = this.props;
    let disabled = false;
    if (loading !== undefined && isValid !== undefined) {
      disabled = isValid ? loading : !isValid;
    } else {
      disabled = loading;
    }
    //
    return (
      <div className={classes.wrapper}>
        <Button
          className={clsx(
            { [classes.button_transform]: this.props.transform },
            {
              [classes.default_style]: !className,
              [className]: className,
            }
          )}
          color={this.props.color}
          disabled={disabled || disable_button}
          fullWidth={this.props.fullWidth}
          id={`button-loading-${uuid()}`}
          onClick={handleClick}
          size={this.props.size ? this.props.size : 'large'}
          // type="submit"
          variant={this.props.variant}>
          {this.props.name}
        </Button>
        {loading && (
          <CircularProgress className={classes.buttonProgress} size={24} />
        )}
      </div>
    );
  }
}

ButtonLoading.defaultProps = {
  transform: true,
  loading: false,
  disable_button: false,
  variant: 'contained',
  className: null,
  fullWidth: false,
  color: 'primary',
  name: '',
};
ButtonLoading.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disable_button: PropTypes.bool,
  loading: PropTypes.bool,
  name: PropTypes.string,
};
export default withStyles(ButtonLoading, useStyles);
