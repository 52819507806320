import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, CardContent, CardActions } from '@mui/material';
import { CreateCardHeader, DivGrow, ButtonCircularIconLoad } from 'components';
import { withStyles } from 'tss-react/mui';
import {
  MutationCreateSoftware,
  MutatioUploadSoftwareFile,
} from 'graphql/Software';
import { ServerErrorsString, GetData } from 'helpers';
import ContentSoftware from '../ContentSoftware';
const useStyles = () => ({
  card: {
    maxWidth: '100%',
  },
});
class CreateSoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      saving: false,
      uploading: false,
    };
    this.holder = {
      getSoftware: null,
      getFile: null,
      resetSoftware: null,
    };
  }
  runCreateSoftware = () => {
    const { saving, uploading } = this.state;
    if (saving || uploading) return;
    this.setState({ saving: true });
    (async () => {
      let final_software = null;
      try {
        const res = await MutationCreateSoftware(this.holder.getSoftware());
        const data = GetData(res);
        const { ok, errors, software } = data.createSoftware;
        // console.log({ software });
        if (ok) {
          this.setState({ saving: false });
          final_software = software;
          this.holder.resetSoftware();
        } else {
          throw errors;
        }
      } catch (error) {
        this.setState({ saving: false });
        this.props.handleAddError(ServerErrorsString(error));
        return;
      }
      const file = this.holder.getFile();
      if (!file) {
        this.props.handleNewSoftware(final_software);
        this.props.handleAddError('File is missing');
        return;
      }
      this.setState({ uploading: true });

      MutatioUploadSoftwareFile(file, final_software.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.uploadSoftwareFile;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleNewSoftware({
              ...final_software,
              filename: file.name,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes } = this.props;
    const { saving, uploading } = this.state;
    return (
      <Card className={classes.card}>
        <CreateCardHeader
          description={'Insert the necessary information for the software'}
          handleClose={this.props.handleClose}
          title="Create Software"
        />
        <Divider />
        <CardContent>
          <ContentSoftware
            holder={this.holder}
            onContentChanged={changed => this.setState({ changed })}
            saving={saving}
            uploading={uploading}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <DivGrow />
          <ButtonCircularIconLoad
            disabled={!this.state.changed}
            handleButtonClick={() => this.runCreateSoftware()}
            loading={saving || uploading}
            name="Save"
            transform={false}
            variant="outlined"
          />
        </CardActions>
      </Card>
    );
  }
}

CreateSoftware.propTypes = {
  classes: PropTypes.object,
  handleNewSoftware: PropTypes.func,
};
CreateSoftware.defaultProps = {
  open: false,
  handleNewSoftware: () => '',
};
export default withStyles(CreateSoftware, useStyles);
