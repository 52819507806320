import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SnackMessage } from 'components';

import ExplorerViews from '../../../ExplorerViews';

class SharedFolderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      net_error: '',
    };
  }

  componentDidUpdate() {
    const { notifications_reload, loading, error, idFolder } = this.props;
    if (notifications_reload) {
      this.props.refetch();
    }
    if (loading) return;
    if (idFolder > 0 && error) {
      const { net_error } = this.state;
      if (net_error !== error) {
        this.setState({ net_error: error });
      }

      this.props.history.push('/explorer/shared');
    }
  }

  handleErrorClose = () => {
    this.setState({ net_error: '' });
  };
  handleUploadFiles = () => {};
  handleSelectedAll = (selected_folder, selected_content) => {
    this.setState({ selected_folder, selected_content });
  };
  render() {
    const {
      loading,
      handleClickFolderItem,
      handleSelected,
      shared_folders,
      open_drawer,
      holder,
      base,
      height,
    } = this.props;
    let { Folder } = this.props;
    if (Folder === undefined || !Folder) {
      Folder = {
        idFolder: -1,
        idParent: -1,
        name: 'Shared',
        Rights: [],
        SubFolders: [],
      };
    }
    const SubFolders = Folder.SubFolders;
    if (SubFolders) {
      //
      for (let index = 0; index < shared_folders.length; index++) {
        const { idFolder, idParent } = shared_folders[index];
        const i = SubFolders.map(x => x.idFolder).indexOf(idFolder);
        if (i === -1 && idParent === Folder.idFolder) {
          Folder.SubFolders.unshift(shared_folders[index]);
        }
      }
    }
    const { net_error } = this.state;
    return (
      <React.Fragment>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={net_error}
          open={net_error !== '' ? true : false}
          type="error"
        />
        <ExplorerViews
          base={base}
          Folder={Folder}
          handleAddError={this.props.handleAddError}
          handleClickFolderItem={handleClickFolderItem}
          handleProgress={this.props.handleProgress}
          handleSelected={handleSelected}
          handleSelectedAll={this.props.handleSelectedAll}
          handleUploadFiles={this.handleUploadFiles}
          height={height}
          holder={holder}
          loading={loading}
          open_drawer={open_drawer}
        />
      </React.Fragment>
    );
  }
}

SharedFolderContent.propTypes = {
  classes: PropTypes.object,
  handleProgress: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  //
  const shared_folders = state.page_activity.shared_folders;
  const size = shared_folders.length;
  return {
    shared_folders,
    size,
    notifications_reload: state.page_activity.notifications_reload,
  };
};
export default connect(mapStateToProps)(withRouter(SharedFolderContent));
