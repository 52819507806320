import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
class SettingLatest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dashboard_view, loading, handleCheckChange } = this.props;
    let latest_project_activity = true,
      latest_activity = true,
      user_by_device = true,
      latest_series_modalities = true,
      latest_series_sequences = true;
    if (dashboard_view) {
      latest_series_sequences = dashboard_view.latest_series_sequences;
      latest_project_activity = dashboard_view.latest_project_activity;
      latest_activity = dashboard_view.latest_activity;
      user_by_device = dashboard_view.user_by_device;
      latest_series_modalities = dashboard_view.latest_series_modalities;
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start">
        <Typography gutterBottom sx={{ fontSize: 14 }} variant={'h6'}>
          Latest
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={latest_project_activity}
              disabled={loading}
              name="latest_project_activity"
              onChange={handleCheckChange}
            />
          }
          label="Latest Project Activity"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={latest_activity}
              disabled={loading}
              name="latest_activity"
              onChange={handleCheckChange}
            />
          }
          label="Latest Activities"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user_by_device}
              disabled={loading}
              name="user_by_device"
              onChange={handleCheckChange}
            />
          }
          label="User by Device"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={latest_series_modalities}
              disabled={loading}
              name="latest_series_modalities"
              onChange={handleCheckChange}
            />
          }
          label="Latest Series Modalities"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={latest_series_sequences}
              disabled={loading}
              name="latest_series_sequences"
              onChange={handleCheckChange}
            />
          }
          label="Latest Series Sequences"
        />
      </Grid>
    );
  }
}

SettingLatest.propTypes = {
  loading: PropTypes.bool,
};

export default SettingLatest;
