import React from 'react';
import PropTypes from 'prop-types';
import { DetailsAutocomplete } from 'components/Components/SeriesComponents';

class SelectStudySeriesLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      level: { name: 'All', index_level: 2 },
      levels: [
        { name: 'All', index_level: 2 },
        { name: 'Only Me', index_level: 0 },
        { name: 'Projects', index_level: 1 },
      ],
      open: false,
      error: '',
    };
  }

  handleSelectedChanged = (label, newValue) => {
    if (!newValue) {
      newValue = { name: 'All', index_level: 2 };
    }
    this.setState({ level: newValue });
    this.props.handleSelectLevel(newValue);
  };
  setOpen = open => {
    this.setState({ open });
  };

  render() {
    return (
      <DetailsAutocomplete
        detail={this.props.level}
        details={this.state.levels}
        // disabled={this.props.disabled}
        handleSelectedChanged={this.handleSelectedChanged}
        // handleTyping={this.handleTyping}
        // holder={this.holder}
        label="level"
        // loading={loading}
        // minWidth={minWidth}
        open={this.state.open}
        placeholder="Select display level"
        setOpen={this.setOpen}
        title="Query Level"
        // variant={this.props.variant}
      />
    );
  }
}

SelectStudySeriesLevel.propTypes = {
  classes: PropTypes.object,
};
SelectStudySeriesLevel.defaultProps = {
  handleSelectLevel: () => '',
  level: { name: 'All', index_level: 2 },
};

export default SelectStudySeriesLevel;
