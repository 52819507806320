import { QueryStudiesSeries } from 'graphql/Study';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';

export const loadStudySeries = (idStudy, THIS) => {
  if (IsInvalid(idStudy)) return;
  const { loading } = THIS.state;
  if (loading) return;
  THIS.setState({ loading: true });
  (async () => {
    QueryStudiesSeries([idStudy])
      .then(res => {
        const data = GetData(res);
        const { ok, studies_series, errors } = data.StudiesSeries;
        if (ok) {
          for (let x = 0; x < studies_series.length; x++) {
            const { series } = studies_series[x];
            THIS.setState({ ListSeries: series, loading: false });
            const index = THIS.props.Studies.map(x => x.idStudy).indexOf(
              idStudy
            );
            if (index !== -1) {
              THIS.props.Studies[index].Series = series;
            }
          }
        } else {
          throw errors;
        }
      })
      .catch(error => {
        THIS.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
