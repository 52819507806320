import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Link, Typography } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = props => {
  const { className, ...rest } = props;

  const { classes } = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{' '}
        <Link component="a" href="https://medical-blocks.ch/" target="_blank">
          Medical-Blocks
        </Link>
        . 2007
      </Typography>
      <Typography variant="caption">
        Helping to facilitate biomedical research
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
