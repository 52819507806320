import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

import {
  DashboardSettings,
  ManageNotifications,
  Password,
  GeneralSettings,
} from './components';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Settings = props => {
  const { user } = props;
  const { classes } = useStyles();
  let ComponentNotifications = null;
  let ComponentDashboardSettings = null;
  if (user && user.idCategory !== 1) {
    ComponentNotifications = null;
    ComponentDashboardSettings = null;
  } else {
    ComponentNotifications = (
      <Grid item md={7} xs={12}>
        <ManageNotifications user={user} />
      </Grid>
    );
    ComponentDashboardSettings = (
      <Grid item md={7} style={{ width: '100%' }} xs={12}>
        <DashboardSettings user={user} />
      </Grid>
    );
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {ComponentNotifications}
        <Grid item md={5} xs={12}>
          <Password handleAddError={props.handleAddError} user={user} />
        </Grid>
        {ComponentDashboardSettings}
        <Grid item md={5} pt={2} xs={12}>
          <GeneralSettings handleAddError={props.handleAddError} user={user} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Settings;
