import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import 'views/Home/Home.css';
import { HomeSoftware, HomeDatabases, HomeProjects } from './components';
import useStyles from 'views/Home/Styles';
import { withStyles } from 'tss-react/mui';
import { getParams } from 'helpers';

class HomePublicAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { level_1 } = getParams(this.props);
    // eslint-disable-next-line no-unused-vars
    const { classes, ...res } = this.props;
    if (level_1 && level_1 === 'software') {
      return <HomeSoftware {...res} />;
    } else if (level_1 && level_1 === 'projects') {
      return <HomeProjects {...this.props} />;
    } else {
      return <HomeDatabases {...res} />;
    }
  }
}

HomePublicAccess.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(HomePublicAccess), useStyles);
