/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { CircularLoading } from 'components';

class MainTableUserHeaders extends Component {
  createSortHandler = property => event => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      loading,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      headCells,
      add_space,
      disable_checkbox,
    } = this.props;
    let TableCellCheckbox = null;
    if (!disable_checkbox) {
      TableCellCheckbox = (
        <TableCell padding="checkbox">
          {loading ? (
            <CircularLoading simple small />
          ) : (
            <Checkbox
              checked={rowCount ? numSelected === rowCount : false}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          )}
        </TableCell>
      );
    }
    return (
      <TableHead>
        <TableRow>
          {add_space && <TableCell style={{ minWidth: 10 }} />}
          {TableCellCheckbox}
          {headCells.map((headCell, index) => {
            const { cellStyle } = headCell;
            let cell_style = {};
            if (cellStyle) {
              cell_style = cellStyle;
            }
            if (headCell.minWidth) {
              cell_style.minWidth = headCell.minWidth;
            }
            if (headCell.maxWidth) {
              cell_style.maxWidth = headCell.maxWidth;
            }
            // console.log({ headCell });
            let align = 'center';
            if (headCell.numeric) align = 'right';
            else if (headCell.align) align = headCell.align;
            return (
              <TableCell
                align={align}
                key={`cell-${index}-${headCell.id}`}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={cell_style}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={this.createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

MainTableUserHeaders.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
MainTableUserHeaders.defaultProps = {
  add_space: false,
  disable_checkbox: false,
};
export default MainTableUserHeaders;
