import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import { TabPanel } from 'components';
import CloseIcon from '@mui/icons-material/Close';
import { getParams } from 'helpers';
import { IsInvalid } from 'helpers';
import { SWECiEEG } from './components';
class PublicSelectedDatabase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        { name: 'SWEC iEEG', url: 'swec_ieeg', TabComponent: SWECiEEG },
        // { name: 'Short-term', url: 'short_term', TabComponent: ShortTerm },
        // { name: 'Long-term', url: 'long_term' },
        // { name: 'Super-long-term', url: 'super_long_term' },
      ],
    };
  }
  componentDidMount() {
    const reply = getParams(this.props);
    if (IsInvalid(reply)) return;
    const { level_2, level_3 } = reply;
    if (IsInvalid(level_3) && level_2 === 'ieeg') {
      this.props.history.push('/public_access/databases/ieeg/swec_ieeg');
    } else {
      const index = this.state.tabs.map(x => x.url).indexOf(level_3);
      if (index !== -1) {
        this.setState({ tabValue: index });
      }
    }
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    this.setState({ tabValue });
    this.props.history.push(
      `/public_access/databases/ieeg/${this.state.tabs[tabValue].url}`
    );
  };
  render() {
    const { tabValue } = this.state;

    return (
      <Box>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end">
          <Grid item pt={1}>
            <IconButton
              aria-label="close_page"
              onClick={this.props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box py={6}>
          <Container>
            <Grid
              alignItems="center"
              container
              flexDirection="column"
              item
              mb={2}
              mx="auto"
              xs={8}>
              <Tabs
                indicatorColor="primary"
                onChange={this.handleTabValue}
                textColor="primary"
                value={tabValue}
                variant="scrollable">
                {this.state.tabs.map((tab, index) => {
                  return <Tab key={`tab-ieeg-${index}`} label={tab.name} />;
                })}
              </Tabs>
              <Divider />
              {this.state.tabs.map((tab, index) => {
                const { TabComponent } = tab;
                return (
                  <TabPanel
                    index={index}
                    key={`tab-ieeg-component-${index}`}
                    value={tabValue}>
                    <TabComponent />
                  </TabPanel>
                );
              })}
            </Grid>
          </Container>
        </Box>
      </Box>
    );
  }
}

PublicSelectedDatabase.propTypes = {
  classes: PropTypes.object,
};

export default PublicSelectedDatabase;
