import { QuerySeriesTypeMaps, QueryVariableTypes } from 'graphql/Mapping';
import { QuerySequences } from 'graphql/Series/sequences';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';

export const runSearchTypes = (new_name, THIS) => {
  const { loading_types } = THIS.state;
  if (loading_types) return;
  THIS.setState({ loading_types: false });
  (async () => {
    QueryVariableTypes(new_name, 0, 5)
      .then(res => {
        const data = GetData(res);
        const { ok, types, errors } = data.variableTypes;
        if (ok) {
          if (!types.length) {
            types.push({ name: 'Not found' });
          }
          let length_type = 0;
          if (new_name) {
            length_type = new_name.length;
          }
          THIS.setState({
            types,
            loading_types: false,
            name_type: new_name ? new_name : '',
            length_type,
          });
        } else {
          throw errors;
        }
      })
      .catch(errors => {
        console.log('catch-runSearchTypes', errors);
        THIS.setState({ types: [], loading_types: false });
        THIS.props.handleAddError(ServerErrorsString(errors));
      });
  })();
};
export const runSearchSequence = (new_name, empty, THIS) => {
  // console.log({ sequence });
  const { loading_values } = THIS.state;
  if (empty === undefined) empty = THIS.state.empty;
  if (loading_values || empty) {
    this.setState({ name_sequence: new_name ? new_name : '' });
    return;
  }
  THIS.setState({ loading_values: true, empty: false });
  (async () => {
    QuerySequences(new_name, 0, 5)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, sequences } = data.Sequences;
        if (ok) {
          let length_sequence = 0;
          if (new_name) {
            length_sequence = new_name.length;
          }
          THIS.setState({
            sequences,
            loading_values: false,
            length_sequence,
            name_sequence: new_name ? new_name : '',
            empty: !sequences.length,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        THIS.setState({
          loading_values: false,
          sequences: [],
          name_sequence: '',
          length_sequence: 0,
        });
        THIS.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const runSearchTypeValues = (name, THIS) => {
  const { loading_values } = THIS.state;
  if (loading_values) return;
  THIS.setState({ loading_values: false });
  (async () => {
    QuerySeriesTypeMaps({ input: { name }, page: 0, limit: 5 })
      .then(res => {
        const data = GetData(res);
        const { ok, mappings, errors } = data.seriesTypeMaps;
        if (ok) {
          if (!mappings.length) {
            mappings.push({ name: 'Not found' });
          }
          let length_value = 0;
          if (name) {
            length_value = name.length;
          }
          let type_values = [];
          for (let i = 0; i < mappings.length; i++) {
            const index = type_values
              .map(x => x.name)
              .indexOf(mappings[i].name);
            if (index === -1) type_values.push(mappings[i]);
          }
          THIS.setState({
            type_values,
            loading_values: false,
            name_type: name ? name : '',
            length_value,
          });
        } else {
          throw errors;
        }
      })
      .catch(errors => {
        console.log('catch-seriesAtType', errors);
        THIS.setState({ type_values: [], loading_values: false });
        THIS.props.handleAddError(ServerErrorsString(errors));
      });
  })();
};
