import { IsInvalid } from 'helpers';
import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'idSeries',
    label: 'ID',
    align: 'left',
    cellStyle: {
      maxWidth: 50,
      padding: 0,
      margin: 0,
      paddingLeft: 15,
    },
  },
  {
    id: 'Thumbnail',
    label: 'Series',
    align: 'left',
    cellStyle: {
      maxWidth: 50,
      padding: 0,
      margin: 0,
      paddingLeft: 15,
    },
    type: 'thumbnail',
  },
  {
    id: 'user',
    label: 'Owner',
    align: 'left',
    minWidth: 170,
    type: 'user',
    format: value => {
      if (!value) {
        return 'Owner Missing';
      }
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Owner Missing';
      return FullName;
    },
  },
  {
    id: 'series',
    label: 'Modality',
    minWidth: 50,
    format: value => {
      if (!value) return '';
      let { Modality } = value;
      if (Modality) return Modality;
      return '';
    },
  },
  {
    id: 'series',
    label: 'Type',
    minWidth: 50,
    format: value => {
      if (!value) return '';
      let { ImageMr } = value;
      if (ImageMr) {
        const { MRAcquisitionType } = ImageMr;
        if (MRAcquisitionType) return MRAcquisitionType;
      }
      return '';
    },
  },
  {
    id: 'SeriesDescription',
    label: 'SeriesDescription',
    minWidth: 170,
    type: 'text',
  },
  {
    id: 'ProtocolName',
    label: 'ProtocolName',
    minWidth: 170,
    type: 'text',
  },
  {
    id: 'SequenceName',
    label: 'SequenceName',
    minWidth: 170,
    type: 'text',
  },
  {
    id: 'series',
    label: 'Contrast Agent',
    minWidth: 170,
    format: value => {
      if (!value) return '';
      let { ContrastBolusAgent } = value;
      if (ContrastBolusAgent) return ContrastBolusAgent;
      return '';
    },
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'series',
    label: 'Locations',
    minWidth: 200,
    align: 'right',
    kind: '--',
    type: 'buttom',
    disabled: series => {
      if (IsInvalid(series)) return true;
      const { locations } = series;
      if (IsInvalid(locations)) return true;
      if (!Array.isArray(locations) || !locations.length) {
        return true;
      }
      return false;
    },
  },
];
