import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { IconButtonLoad } from 'components';
import { Replay as ReloadIcon } from '@mui/icons-material';
class CardEditHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { show_reload, small, loading } = this.props;
    let ComponentReload = null;
    if (show_reload) {
      ComponentReload = (
        <IconButtonLoad
          handleClick={this.props.handleReload}
          icon={this.props.icon_reload}
          loading={loading}
          small={small}
          tooltip={this.props.tooltip_clear}
        />
      );
    }
    let style = null;
    if (small) {
      style = {
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
      };
    }
    // console.log({ small, style });
    return (
      <CardHeader
        action={
          <Grid container sx={{ paddingTop: small ? 1 : null }}>
            {ComponentReload}
            <IconButton
              aria-label="settings"
              onClick={() => this.props.handleClose()}
              size={small ? 'small' : 'large'}>
              <CancelIcon />
            </IconButton>
          </Grid>
        }
        avatar={this.props.icon}
        style={style}
        subheader={this.props.description}
        title={this.props.title}
      />
    );
  }
}

CardEditHeader.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    // Add more types as needed
  ]).isRequired,
  handleClose: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
  show_reload: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    // Add more types as needed
  ]).isRequired,
  tooltip_clear: PropTypes.string,
};
CardEditHeader.defaultProps = {
  handleReload: () => '',
  handleClose: () => '',
  description: 'Modify all the necessary field and press save to update',
  icon: <EditIcon style={{ color: 'green' }} />,
  icon_reload: <ReloadIcon />,
  loading: false,
  show_reload: false,
  tooltip_clear: 'Reload',
  small: true,
};

export default CardEditHeader;
