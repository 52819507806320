import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_CREATE_SHARE_LINK = gql`
  mutation($idFolder: LongLong, $idFolderContent: LongLong) {
    createShareLink(idFolder: $idFolder, idFolderContent: $idFolderContent) {
      ok
      errors {
        path
        message
      }
      link {
        idLink

        idUser
        idFolder
        idFolderContent

        code

        createdAt
      }
    }
  }
`;
export const MutationCreateShareLink = ({ idFolder, idFolderContent }) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_SHARE_LINK),
    variables: {
      idFolder,
      idFolderContent,
    },
  });
};

const MUTATION_REMOVE_SHARE_LINK = gql`
  mutation($code: String!) {
    removeShareLink(code: $code) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveShareLink = code => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_SHARE_LINK),
    variables: {
      code,
    },
  });
};

const QUERY_SHARED_LINK = gql`
  query($code: String!) {
    linkContent(code: $code) {
      ok
      errors {
        path
        message
      }
      link {
        idLink
        code
        private_access
        user {
          id
          FullName
          avatar
        }
        folder {
          idFolder
          name
          Size
          Owner {
            id
            FullName
          }
          SubFolders {
            idFolder
            idParent
            idUser
            name
            Size
            Owner {
              id
              FullName
            }
            total_files
            total_sub_folders
            createdAt
            updatedAt
          }
          Files {
            idFolderContent
            Owner {
              id
              FullName
            }
            File {
              idFileObject
              original_name
              mimetype
              Size
              Thumbnail
              createdAt
              updatedAt
            }
            Series {
              idSeries
              idStudy
              SeriesDescription
              Size
              Thumbnail
              createdAt
              updatedAt
            }
          }
        }
        content {
          idFolderContent
          Owner {
            id
            FullName
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            createdAt
            updatedAt
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Size
            Thumbnail
            createdAt
            updatedAt
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryLinkContent = code => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SHARED_LINK),
    variables: {
      code,
    },
  });
};

const MUTATION_SHARE_LINK_TO_PRIVATE = gql`
  mutation($private_access: Boolean!, $code: String!) {
    sharedLinkToPrivated(private_access: $private_access, code: $code) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationSharedLinkToPrivated = (private_access, code) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SHARE_LINK_TO_PRIVATE),
    variables: {
      private_access,
      code,
    },
  });
};
