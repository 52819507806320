import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { MutationDockerImageAction } from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';
import { styleToolbar } from 'common';
class ToolBarImageActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleOnClickButton = action => {
    const { selected } = this.props;
    if (!selected || selected === undefined || !selected.length) {
      this.props.handleAddError('You need to select an image first');
      return;
    }
    const ids = selected.map(x => x.idImage);
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationDockerImageAction(action, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, actions } = data.dockerImageAction;
          if (ok) {
            this.setState({ loading: false });
            this.props.handleActionResults(actions);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, selected } = this.props;
    const disabled = !selected.length;
    return (
      <Paper className={classes.paper}>
        <ButtonGroup disableElevation exclusive="false" variant="contained">
          <Button
            className={classes.button}
            color="primary"
            disabled={disabled}
            onClick={() => this.handleOnClickButton('start')}
            size="small"
            startIcon={<PlayArrowIcon />}
            style={{ backgroundColor: disabled ? '#e0e0e0' : 'green' }}
            variant="contained">
            Start
          </Button>
          <Button
            className={classes.button}
            color="primary"
            onClick={this.props.handleOpenCreateDocker}
            size="small"
            startIcon={<AddIcon />}
            style={{
              backgroundColor: 'blue',
              color: 'white',
            }}
            variant="contained">
            Add new image
          </Button>
        </ButtonGroup>
      </Paper>
    );
  }
}

ToolBarImageActions.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ToolBarImageActions, styleToolbar);
