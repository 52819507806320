import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import { green } from '@mui/material/colors';

const theme_active_button = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '12',
      fontFamily: 'Arial',
    },
  },
  palette: {
    primary: green,
  },
});

class ButtonProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, project, history } = this.props;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_active_button}>
          <Button
            className={classes.activeButton}
            // component="span"
            color="primary"
            disableRipple
            onClick={() => {
              if (!project.length) {
                history.push('/project_management/browser');
              }
            }}
            variant="outlined">
            {project.length ? 'Active Project' : 'Back to Browser'}
          </Button>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

ButtonProject.propTypes = {
  classes: PropTypes.object,
};

export default ButtonProject;
