import { FormatTime, getUserFullName } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 50,
  },
  {
    id: 'Admin',
    label: 'Admin',
    minWidth: 100,
    align: 'right',
    format: value => getUserFullName(value),
  },
  {
    id: 'patients',
    label: 'Patients',
  },
  {
    id: 'studies',
    label: 'Studies',
  },
  {
    id: 'series',
    label: 'Series',
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 50,
    align: 'right',
    type: 'buttom',
  },
];
