import { FormatTime, getCityCountry } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
  },
  {
    id: 'address',
    label: 'Location',
    minWidth: 170,
    align: 'left',
    format: value => (value ? getCityCountry(value.city, value.country) : ''),
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 170,
    align: 'right',
    format: value => (value ? value.toLocaleString() : ''),
  },
  {
    id: 'createdAt',
    label: 'Registration Date',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
