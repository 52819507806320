import React from 'react';
import PropTypes from 'prop-types';
import { Badge, CircularProgress, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from 'components/Buttons/ButtonDownloadExplorerContent/Styles';

class ButtonEditMultiSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  getSelected = () => {
    const { selected } = this.props;
    //
    return selected.filter(x => x.Series);
  };

  render() {
    const { classes, loading, defaultProps } = this.props;
    const selected = this.getSelected();
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Tooltip title="Edit series...">
            <Badge
              badgeContent={selected.length}
              onClick={this.props.onClick}
              {...defaultProps}
            />
          </Tooltip>
          {loading && (
            <CircularProgress className={classes.fabProgress} size={36} />
          )}
        </div>
      </div>
    );
  }
}

ButtonEditMultiSeries.propTypes = {
  ButtonEditMultiSeries: PropTypes.object,
};
ButtonEditMultiSeries.defaultProps = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    color: 'secondary',
    children: <EditIcon style={{ color: '#ED6C02' }} />,
    style: {
      margin: 0,
      padding: 0,
      marginLeft: -2,
      marginRight: 5,
      marginBottom: 10,
    },
  },
  onClick: () => '',
  selected: [],
  loading: false,
};

export default withStyles(ButtonEditMultiSeries, useStyles);
