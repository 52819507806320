import React from 'react';
const style = {
  textAlign: 'justify',
  fontSize: 20,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};
export const skullstripping = {
  image: '/images/skullstripping.png',
  title: 'Skullstriping of DICOM images',
  description: (
    <p style={style}>
      &nbsp; &nbsp; &nbsp;The <strong>Medical-Blocks</strong> platform include
      severals methods for removing the skull to isolate the brain. Brain
      extraction also known as <strong>Skullstripping</strong> is a neuroimaging
      technique used to remove non-brain tissue from structural MRI (magnetic
      resonance imaging) scans of the head, including the skull, scalp, and
      extracerebral tissues. This technique is typically used to isolate the
      brain from surrounding tissues, allowing for more accurate and precise
      analysis of brain structure and function.
      <br />
      &nbsp; &nbsp; &nbsp;We integrated several algorithms for{' '}
      <strong>Skullstripping</strong> of DICOM images. The process received a
      DICOM image with Skull and output a DICOM image without the Skull.
      <p style={{ ...style, marginLeft: 30, marginTop: 15 }}>
        <ol type="1">
          <li>
            <strong>HD-BET</strong> Automated brain extraction of multisequence
            (e.g. ADC, DWI and FLAIR) MRI using artificial neural networks. You
            can find the source code of the algorithm{' '}
            <a
              href="https://github.com/MIC-DKFZ/HD-BET"
              rel="noreferrer"
              target="_blank">
              https://github.com/MIC-DKFZ/HD-BET
            </a>
            , the publication here:{' '}
            <a
              href="https://doi.org/10.1002/hbm.24750"
              rel="noreferrer"
              target="_blank">
              https://doi.org/10.1002/hbm.24750
            </a>
          </li>
        </ol>
      </p>
    </p>
  ),
};
