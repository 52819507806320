export { default as ExplorerViewListLeft } from './ExplorerViewListLeft';
export { default as ExplorerViewListRight } from './ExplorerViewListRight';
export const useStyles = (theme, props) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
  },
  left: {
    padding: 0,
    margin: 0,
  },
  right: {
    padding: 0,
    margin: 0,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: 'calc(100% - 205px)',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer_root: {
    width: 200,
    margin: 0,
    padding: 0,
    flexShrink: 0,
    position: 'relative',
  },
  drawer_paper: {
    width: 200,
    margin: 0,
    padding: 0,
    position: 'relative',
    height: props.isMobileSmall ? '100%' : props.height - 240,
  },
  drawer_position: {
    position: 'absolute',
  },
  drawer_mobile: {
    display: 'none',
  },
});
