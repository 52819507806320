import { QueryFolderContents } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';

export const handleQueryContent = (copy, THIS) => {
  const { selected, copying, cutting } = THIS.state;
  if (copying || cutting) return;
  const inputs = selected
    .map(x => {
      const { idSeries, idFileObject } = x;
      if (idSeries) return { idSeries };
      else if (idFileObject) return { idFileObject };
      else return null;
    })
    .filter(x => x);
  THIS.setState({ copying: copy, cutting: !copy });
  QueryFolderContents(inputs)
    .then(res => {
      const data = GetData(res);
      const { ok, errors, folder_contents } = data.folderContents;
      if (ok) {
        if (copy) {
          THIS.handleCopy(folder_contents);
        } else {
          THIS.handleCut(folder_contents);
        }
      } else {
        throw errors;
      }
    })
    .catch(error => {
      THIS.setState({ copying: false, cutting: false });
      THIS.props.handleAddError(ServerErrorsString(error));
    });
};
