import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { SharedFolderContent } from '../index';
import { DivDrawer } from '../../../ExplorerHome/components';
import { useStyles } from '../../../ExplorerHome/Style';

class ExplorerSharedFolderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item_clicked: null,
    };
  }
  handleClickFolderItem = new_item_clicked => {
    let idFolder = undefined;
    let idFolderContent = undefined;
    if (this.state.item_clicked) {
      idFolder = this.state.item_clicked.idFolder;
      idFolderContent = this.state.item_clicked.idFolderContent;
    } else {
      this.props.handleDrawerOpenClose();
    }
    if (
      new_item_clicked.idFolder === idFolder &&
      new_item_clicked.idFolderContent === idFolderContent
    ) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.item_clicked = new_item_clicked;
      this.props.handleDrawerOpenClose();
    } else {
      this.setState({ item_clicked: new_item_clicked });
    }
  };

  render() {
    const {
      classes,
      open_details,
      handleDrawerClose,
      handleDeleteFolders,
      handleSelected,
      Folder,
      loading,
      refetch,
      holder,
      base,
      height,
      view_type,
    } = this.props;
    const { item_clicked } = this.state;
    //
    return (
      <div
        className={clsx({
          [classes.main_container]: !view_type,
        })}
        style={{ height: height - 240, overflow: 'auto' }}>
        <DivDrawer
          base={base}
          classes={classes}
          CurrentFolder={Folder}
          handleAddError={this.props.handleAddError}
          handleDeleteFolders={handleDeleteFolders}
          handleDrawerClose={handleDrawerClose}
          // handleItemChanged={this.handleItemChanged}
          // handleSharedLinkChanged={this.handleSharedLinkChanged}
          // handleSubFolderNameChanged={this.handleSubFolderNameChanged}
          handleProgress={this.props.handleProgress}
          holder={this.holder}
          isDesktop={this.props.isDesktop}
          isMobile={this.props.isMobile}
          item_clicked={item_clicked}
          open_drawer={open_details}
          // total={total}
        >
          <div className={classes.folder_container}>
            <SharedFolderContent
              base={base}
              Folder={Folder}
              handleAddError={this.props.handleAddError}
              handleClickFolderItem={this.handleClickFolderItem}
              handleProgress={this.props.handleProgress}
              handleSelected={handleSelected}
              handleSelectedAll={this.props.handleSelectedAll}
              height={height}
              holder={holder}
              loading={loading}
              open_drawer={open_details}
              refetch={refetch}
            />
          </div>
        </DivDrawer>
      </div>
    );
  }
}

ExplorerSharedFolderContent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ExplorerSharedFolderContent, useStyles);
