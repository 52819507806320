import {
  LOGOUT_USER_MANAGER,
  MANAGER_CLEAN_COPY_CUT,
  MANAGER_CLEAN_ERROR,
  MANAGER_COPY,
  MANAGER_COPY_SERIES,
  MANAGER_COPY_FILES,
  MANAGER_CUT,
  MANAGER_PASTE_END,
  MANAGER_PASTE_START,
  MANAGER_JOB_SERIES_COPY,
  MANAGER_JOB_FILES_COPY,
  MANAGER_JOB_PROCESSES_NEW,
  MANAGER_SET_CUT,
  MANAGER_SET_COPY,
  MANAGER_ADD_LANDING,
} from '../actions/types';

const addObjects = (source, to_add) => {
  for (let i = 0; i < to_add.length; i++) {
    const { idFolder, idFolderContent } = to_add[i];
    let add = true;
    for (let j = 0; j < source.length; j++) {
      const obj = source[j];
      if (
        obj.idFolder === idFolder &&
        obj.idFolderContent === idFolderContent
      ) {
        add = false;
        break;
      }
    }
    if (add) {
      source.push(to_add[i]);
    }
  }
  return source;
};
const initialState = {
  copy: [],
  cut: [],
  copy_series: [],
  copy_files: [],
  jobs_series: [],
  jobs_files: [],
  new_job_process: false,
  loading_paste: false,
  error_manager: '',
  warning_manager: '',
  add_landing_object: null,
};
const reducer_file_folder_manager = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER_MANAGER:
      return initialState;
    case MANAGER_JOB_SERIES_COPY:
      return {
        ...state,
        jobs_series: action.jobs_series,
      };
    case MANAGER_JOB_FILES_COPY:
      return {
        ...state,
        jobs_files: action.jobs_files,
      };
    case MANAGER_JOB_PROCESSES_NEW:
      return {
        ...state,
        new_job_process: action.new_job_process,
      };
    case MANAGER_CLEAN_COPY_CUT:
      return {
        ...state,
        copy: [],
        cut: [],
        copy_series: [],
        copy_files: [],
        jobs_series: [],
        jobs_files: [],
      };
    case MANAGER_COPY_SERIES:
      return {
        ...state,
        copy_series: action.copy_series,
      };
    case MANAGER_COPY_FILES:
      return {
        ...state,
        copy_files: action.copy_files,
      };
    case MANAGER_COPY: {
      const to_cut = [...state.cut];

      for (let i = 0; i < action.copy.length; i++) {
        const { idFolder, idFolderContent } = action.copy[i];
        for (let j = 0; j < to_cut.length; j++) {
          const obj = to_cut[j];
          if (
            obj.idFolder === idFolder &&
            obj.idFolderContent === idFolderContent
          ) {
            to_cut.splice(j, 1);
            break;
          }
        }
      }

      if (to_cut.length !== state.cut.length) {
        return {
          ...state,
          copy: addObjects(state.copy, action.copy),
          cut: to_cut,
        };
      }
      return {
        ...state,
        copy: addObjects(state.copy, action.copy),
      };
    }
    case MANAGER_SET_CUT: {
      return {
        ...state,
        cut: action.cut,
      };
    }
    case MANAGER_SET_COPY: {
      return {
        ...state,
        copy: action.copy,
      };
    }
    case MANAGER_CUT: {
      let { cut, copy } = action;
      if (cut === undefined) {
        cut = [];
      }
      if (copy === undefined) {
        copy = [];
      }
      const to_copy = [...state.copy];

      for (let i = 0; i < cut.length; i++) {
        const { idFolder, idFolderContent } = action.cut[i];
        for (let j = 0; j < to_copy.length; j++) {
          const obj = to_copy[j];
          if (
            obj.idFolder === idFolder &&
            obj.idFolderContent === idFolderContent
          ) {
            to_copy.splice(j, 1);
            break;
          }
        }
      }

      if (to_copy.length !== copy.length) {
        return {
          ...state,
          copy: to_copy,
          cut: addObjects(state.cut, cut),
        };
      }
      return {
        ...state,
        cut: addObjects(state.cut, cut),
      };
    }
    case MANAGER_PASTE_START:
      return {
        ...state,
        loading_paste: true,
      };
    case MANAGER_PASTE_END: {
      const { error, warning } = action;

      return {
        ...state,
        loading_paste: false,
        error_manager: error ? error : '',
        warning_manager: warning ? warning : '',
        copy: [],
        cut: [],
        copy_series: [],
        copy_files: [],
      };
    }
    case MANAGER_CLEAN_ERROR:
      return {
        ...state,
        error_manager: '',
        warning_manager: '',
      };
    case MANAGER_ADD_LANDING:
      return {
        ...state,
        add_landing_object: action.add_landing_object,
      };
    default:
      return state;
  }
};
export default reducer_file_folder_manager;
