import axios from 'axios';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { IsInvalid } from 'helpers';
export const MutationUploadSingleFile = (
  file,
  MUTATION,
  handleProgress = null,
  type = null
) => {
  const query = { file, type };
  return MutationUploadInputs(query, MUTATION, handleProgress);
};
export const MutationUploadInputs = (
  query,
  MUTATION,
  handleProgress = null,
  GRAPHQL_ADDRESS = null
) => {
  const file = query.file;
  if (!handleProgress) {
    handleProgress = () => '';
  }
  var formData = new FormData();
  const operations = JSON.stringify({
    query: print(MUTATION),
    variables: query,
  });
  formData.append('operations', operations);
  const map = {
    '0': ['variables.file'],
  };
  formData.append('map', JSON.stringify(map));
  formData.append('0', file);
  if (IsInvalid(GRAPHQL_ADDRESS)) GRAPHQL_ADDRESS = HTTP_ADDRESS_GRAPHQL;
  return axios.post(GRAPHQL_ADDRESS, formData, {
    onUploadProgress: event => handleProgress(event),
    headers: {
      'Content-Type': 'multipart/form-data',
      'apollo-require-preflight': true,
    },
    crossDomain: true,
  });
};
