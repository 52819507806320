import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { ButtonReload, DivGrow } from 'components';

const defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  color: 'secondary',
  children: <DeleteIcon color="error" />,
};

class TopMultiMenu extends Component {
  render() {
    const {
      classes,
      open,
      handleBack,
      reloadAllDetails,
      handleDelete,
      selected,
      handleDrawerOpen,
    } = this.props;

    return (
      <CardActions
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        disableSpacing>
        <IconButton onClick={handleBack} size="large">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          display="inline"
          gutterBottom
          style={{ marginRight: '5px' }}
          variant="h6">
          Multi Selections Series
        </Typography>
        <DivGrow />
        {selected.length > 0 && (
          <Tooltip title="Delete selected">
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                aria-label="delete"
                onClick={handleDelete}
                size="large">
                <Badge badgeContent={selected.length} {...defaultProps} />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <ButtonReload
          handleReload={reloadAllDetails}
          tooltip="Reload all details"
        />
        <IconButton
          aria-label="show more"
          className={clsx(classes.expand, {
            [classes.hide]: open,
          })}
          // aria-expanded={expanded}
          onClick={handleDrawerOpen}
          size="large">
          <MenuIcon />
        </IconButton>
      </CardActions>
    );
  }
}

TopMultiMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};
TopMultiMenu.defaultProps = {
  open: false,
};
export default TopMultiMenu;
