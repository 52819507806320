import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardActions } from '@mui/material';
import { ButtonCircularIconLoad, DivGrow } from 'components';

class CardProjectActions extends Component {
  render() {
    const { loading, changed, admin } = this.props;
    let style = null;
    let ComponentSave = null;
    if (!admin) {
      style = { height: 50 };
    } else {
      ComponentSave = (
        <ButtonCircularIconLoad
          disabled={!changed}
          handleButtonClick={this.props.handleSaveClicked}
          loading={loading}
          small
        />
      );
    }
    return (
      <CardActions disableSpacing style={style}>
        <DivGrow />
        {ComponentSave}
      </CardActions>
    );
  }
}

CardProjectActions.propTypes = {
  classes: PropTypes.object,
};

export default CardProjectActions;
