export const options_types = [
  { name: 'None' },
  { name: 'CSV' },
  { name: 'DICOM' },
  { name: 'EEG' },
  { name: 'Folder' },
  { name: 'JSON' },
  { name: 'NIfTI' },
  { name: 'TEXT' },
  // { name: 'String' },
  // { name: 'Number' },
];
export const options_access = [
  { name: 'None' },
  { name: 'All' },
  { name: 'Teams' },
  { name: 'Projects' },
];
export const options_action = [
  { name: 'find' },
  { name: 'create' },
  // { name: 'findcreate' },
];
