export { default as TableProjectMemberSetting } from './TableProjectMemberSetting';
export { default as ProjectMemberSettingEditor } from './ProjectMemberSettingEditor';
export { default as TabProjectSettingMain } from './TabProjectSettingMain';
export { default as TabProjectSettingMembers } from './TabProjectSettingMembers';
export { default as TabProjectSettingCenters } from './TabProjectSettingCenters';
export { default as TabProjectSettingStudies } from './TabProjectSettingStudies';
export { default as TabProjectSettingObjects } from './TabProjectSettingObjects';
export { default as TabProjectSettingMapping } from './TabProjectSettingMapping';
export { default as TabProjectSettingPatients } from './TabProjectSettingPatients';
export const useStyle = () => ({
  root: {
    height: '100%',
  },
  root_setting: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  files: {
    paddingTop: 10,
    paddingLeft: 1,
    paddingRight: 1,
    height: '100%',
  },
});
