import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ButtonLoading } from 'components';

class CardSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      title,
      subheader,
      className,
      handleRealod,
      handleSave,
      loading,
      save,
      disabled,
      ...rest
    } = this.props;
    let { Actions } = this.props;
    let action_reload = null;
    if (handleRealod) {
      action_reload = (
        <IconButton
          onClick={handleRealod}
          size="small"
          style={{ marginTop: 15, marginRight: 10 }}>
          <RefreshIcon />
        </IconButton>
      );
    }
    let ComponentActions = null;
    if (handleSave) {
      Actions = (
        <ButtonLoading
          disable_button={disabled}
          handleClick={handleSave}
          loading={loading}
          name={save}
          transform={false}
          variant="outlined"
        />
      );
    }
    if (Actions) {
      ComponentActions = (
        <React.Fragment>
          <Divider />
          <CardActions>{Actions}</CardActions>
        </React.Fragment>
      );
    }
    return (
      <Card {...rest} className={clsx(className)}>
        <CardHeader
          action={action_reload}
          subheader={title}
          title={subheader}
        />
        <Divider />
        <CardContent>{children}</CardContent>
        {ComponentActions}
      </Card>
    );
  }
}

CardSetting.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};
CardSetting.defaultProps = {
  save: 'Save',
};

export default CardSetting;
