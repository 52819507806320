import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  channels: {
    width: '100%',
    maxWidth: 400,
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  appResponsive: {
    margin: 0,
    width: '100%',
  },
  drawerPaper: {
    width: 300,
    backgroundColor: '#f1f4f6',
  },
});

class CommunicationDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      children,
      isMobile,
      mobileOpen,
      handleDrawerToggle,
    } = this.props;

    if (isMobile) {
      return (
        <Drawer
          anchor={'left'}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          open={mobileOpen}
          variant="temporary">
          <div className={classes.appResponsive}>{children}</div>
        </Drawer>
      );
    } else {
      return <div className={classes.channels}>{children}</div>;
    }
  }
}

CommunicationDrawer.propTypes = {
  classes: PropTypes.object,
};
CommunicationDrawer.defaultProps = {};

export default withStyles(CommunicationDrawer, useStyles);
