import React from 'react';
import PropTypes from 'prop-types';
import GroupBasics from '../GroupBasics';
class GroupCopyright extends React.Component {
  constructor(props) {
    super(props);
    this.holder = {
      addMap: null,
      removeMaps: null,
    };
    if (props.holder) {
      props.holder.addCopyright = copyright => {
        if (this.holder.addMap) this.holder.addMap(copyright);
      };
      props.holder.removeCopyright = () => {
        if (this.holder.removeMaps) this.holder.removeMaps();
      };
    }
  }
  render() {
    return (
      <GroupBasics
        handleOpenAddDialog={this.props.handleOpenAddDialog}
        handleOpenRemoveDialog={this.props.handleOpenRemoveDialog}
        holder={this.holder}
        map_name="Copyright"
        title="Copyrights"
      />
    );
  }
}

GroupCopyright.propTypes = {
  classes: PropTypes.object,
};

export default GroupCopyright;
