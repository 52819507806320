import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import {
  // Layouts
  Main as MainLayout,
  Minimal as MinimalLayout,
} from '../../layouts';
import {
  Home as HomeView,
  HomePublicAccess as HomePublicAccessView,
  HomeAboutUs as HomeAboutUsView,
  Account as AccountView,
  Dashboard as DashboardView,
  Communications as CommunicationsView,
  Surveys as PatientSurveyView,
  SignIn as SignInView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
} from 'views';
export const GetSurveyRoutes = props => {
  const { ReactGA4 } = props;
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        path="/"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomePublicAccessView}
        exact
        path="/public_access/:level_1?/:level_2?/:level_3?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomeAboutUsView}
        exact
        path="/about-us"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard/:level_1?/:level_2?"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account/:item?/:level_1?"
      />

      <RouteWithLayout
        component={PatientSurveyView}
        exact
        layout={MinimalLayout}
        path="/survey/:link?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={CommunicationsView}
        exact
        layout={MainLayout}
        path="/communications/:type?/:id?"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        public_access
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};
