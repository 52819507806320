import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import validate from 'validate.js';
import {
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Divider,
  TextField,
  Typography,
} from '@mui/material';

import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';

import { green } from '@mui/material/colors';
import axios from 'axios';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { ObjectSize } from '../../../../../utils/utils';
import { DivGrow } from 'components';
import { GetData } from 'helpers';

const updatePasswordMutation = gql`
  mutation($id: LongLong!, $current_password: String!, $password: String!) {
    updatePassword(
      id: $id
      current_password: $current_password
      password: $password
    ) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;
const schema = {
  current_password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 20,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 20,
    },
    equality: {
      attribute: 'current_password',
      message: 'The new password has to be different from current password',
      comparator: function(v1, v2) {
        return JSON.stringify(v1) !== JSON.stringify(v2);
      },
    },
  },
  confirm_password: {
    // You need to confirm your password
    presence: { allowEmpty: false, message: 'is required' },
    // and it needs to be equal to the other password
    equality: {
      attribute: 'password',
      message: 'The passwords does not match',
      comparator: function(v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2);
      },
    },
  },
};

const Errors = (errors, field) => {
  const values = errors[field];
  return values.map((error, i) => {
    return (
      <Typography
        display="block"
        key={`error-${field}-${i}`}
        style={{ fontSize: '0.5rem', marginTop: '-1px', marginButtom: '-1px' }}
        variant="caption">
        - {error}
      </Typography>
    );
  });
  //   return lines;
};

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  avatar_selected: {
    width: '100%',
    height: '100%',
    border: '10px solid #ddd',
  },
  confirm: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900],
    },
  },
  successLocation: {
    marginLeft: '1.5rem',
  },
});

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_values: {
        current_password: '',
        password: '',
        confirm_password: '',
        touched: {},
      },
      not_ready: false,
      loading: false,
      success: false,
      server_error: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      input_values: {
        current_password: '',
        password: '',
        confirm_password: '',
        touched: {},
      },
      not_ready: false,
      loading: false,
      success: false,
      server_error: false,
      errors: {},
    });
  }

  componentDidUpdate() {
    const {
      input_values,
      not_ready,
      errors,
      server_error,
      ...res
    } = this.state;
    let new_errors = errors;
    if (!server_error) {
      new_errors = validate(input_values, schema);
    }

    const old_size = ObjectSize(errors);
    const new_size = ObjectSize(new_errors);

    const invalid = new_size ? true : false;
    if (not_ready !== invalid || old_size !== new_size) {
      this.setState({
        ...input_values,
        ...res,
        errors: new_errors || {},
        not_ready: invalid,
      });
    }
  }

  handleChange = event => {
    event.persist();
    const { input_values, server_error, errors } = this.state;
    //
    this.setState({
      success: false,
      server_error: false,
      errors: server_error ? {} : errors,
      input_values: {
        ...input_values,
        [event.target.name]: event.target.value,
        touched: {
          ...input_values.touched,
          [event.target.name]: true,
        },
      },
    });
  };

  handleUpdate = async () => {
    const { user } = this.props;
    const { input_values } = this.state;
    const validation = validate(input_values, schema);

    if (validation) {
      this.setState({ errors: validation });
      return;
    }
    this.setState({ loading: true, success: false }, () => {
      axios
        .post(HTTP_ADDRESS_GRAPHQL, {
          query: print(updatePasswordMutation),
          variables: {
            id: user.id,
            current_password: input_values['current_password'],
            password: input_values['password'],
          },
        })
        .then(res => {
          const data = GetData(res);
          const { updatePassword: user_data } = data;
          const { ok, errors } = user_data;

          if (ok) {
            this.setState({
              server_error: false,
              errors: {},
              success: true,
              loading: false,
            });
          } else {
            let server_error = {};
            for (let key in errors) {
              const value = errors[key];
              server_error = {
                ...server_error,
                [value.path]: [value.message],
              };
            }
            this.setState({
              server_error: true,
              errors: server_error,
              success: false,
              loading: false,
            });
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ success: false, loading: false });
        });
    });
  };
  hasError = field => {
    const {
      input_values: { touched },
      errors,
    } = this.state;
    const result = touched[field] && errors[field] ? true : false;
    //
    return result;
  };

  render() {
    const { classes, open, handleCancel } = this.props;
    const { errors, input_values, loading, success, not_ready } = this.state;
    return (
      <div key="update-password-modal">
        <Dialog
          aria-labelledby="form-dialog-title"
          key="dialog-update-password-modal"
          onClose={handleCancel}
          open={open}>
          <CardHeader subheader="Update password" title="Password" />
          <CardContent>
            <Divider />
            <TextField
              className={classes.confirm}
              error={this.hasError('current_password')}
              fullWidth
              helperText={
                this.hasError('current_password')
                  ? Errors(errors, 'current_password')
                  : null
              }
              label="Current Password"
              name="current_password"
              onChange={this.handleChange}
              type="password"
              value={input_values.current_password}
              variant="outlined"
            />

            <TextField
              error={this.hasError('password')}
              fullWidth
              helperText={
                this.hasError('password') ? Errors(errors, 'password') : null
              }
              label="Password"
              name="password"
              onChange={this.handleChange}
              style={{ marginTop: '1rem' }}
              type="password"
              value={input_values.password}
              variant="outlined"
            />
            <TextField
              className={classes.confirm}
              error={this.hasError('confirm_password')}
              fullWidth
              helperText={
                this.hasError('confirm_password')
                  ? Errors(errors, 'confirm_password')
                  : null
              }
              label="Confirm password"
              name="confirm_password"
              onChange={this.handleChange}
              type="password"
              value={input_values['confirm_password']}
              variant="outlined"
            />
            <Divider />
          </CardContent>
          <CardActions>
            {success ? (
              <Fab
                aria-label="save"
                className={classNames(
                  classes.buttonSuccess,
                  classes.successLocation
                )}
                color="primary">
                <CheckIcon />
              </Fab>
            ) : (
              <div className={classes.wrapper}>
                <Button
                  className={clsx({
                    [classes.buttonSuccess]: success,
                  })}
                  color="primary"
                  disabled={loading || not_ready}
                  onClick={this.handleUpdate}
                  variant="contained">
                  Update
                </Button>
                {loading && (
                  <CircularProgress
                    className={classes.buttonProgress}
                    size={24}
                  />
                )}
              </div>
            )}
            <DivGrow />
            <Button onClick={handleCancel} variant="outlined">
              {success ? 'Close' : 'Cancel'}
            </Button>
          </CardActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(UpdatePassword, useStyles);
