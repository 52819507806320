import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { v1 as uuid } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
class AutoMutilSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { option, options, label, loading } = this.props;
    return (
      <Autocomplete
        disabled={this.props.disabled}
        filterSelectedOptions
        fullWidth
        getOptionLabel={option => option.name}
        id={`auto-${label}-${uuid()}`}
        limitTags={this.props.limitTags}
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, selected) => this.props.onSelectionChanged(selected)}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={label}
            placeholder={label}
          />
        )}
        size={this.props.size}
        value={option}
      />
    );
  }
}

AutoMutilSingle.propTypes = {
  options: PropTypes.array,
};
AutoMutilSingle.defaultProps = {
  loading: false,
  options: [],
  option: undefined,
  limitTags: undefined,
  size: '',
  multiple: false,
  label: 'Auto multi - single selection',
  onSelectionChanged: () => '',
  disabled: false,
};
export default AutoMutilSingle;
