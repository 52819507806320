import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const Resolutions = gql`
  query QueryResolution($page: Int, $limit: Int) {
    ImageResolutions(page: $page, limit: $limit) {
      Total
      ImageResolutions {
        Spacing
      }
    }
  }
`;
export const ImageResolutions = (page, limit) => {
  const variables = {};
  if (page && limit) {
    variables.page = page;
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(Resolutions),
    variables: variables,
  });
};

export const ImageResolution = value => {
  let PixelSpacingX = 0.0;
  let PixelSpacingY = 0.0;
  let SpacingBetweenSlices = 0.0;
  let SliceThickness = 0.0;
  if (value === undefined) {
    return '0x0x0';
  }
  if (value.PixelSpacingX !== undefined) {
    PixelSpacingX = value.PixelSpacingX;
  }
  if (value.PixelSpacingY !== undefined) {
    PixelSpacingY = value.PixelSpacingY;
  }
  if (value.SpacingBetweenSlices !== undefined) {
    SpacingBetweenSlices = value.SpacingBetweenSlices;
  }
  if (value.SliceThickness !== undefined) {
    SpacingBetweenSlices = value.SliceThickness;
  }
  if (
    !SpacingBetweenSlices &&
    !SpacingBetweenSlices &&
    !PixelSpacingX &&
    !PixelSpacingY
  ) {
    return '0x0x0';
  }
  if (SpacingBetweenSlices === SliceThickness) {
    return `${PixelSpacingX.toFixed(3)}x${PixelSpacingY.toFixed(
      3
    )}x${SliceThickness.toFixed(3)}`;
  }
  if (SpacingBetweenSlices > SliceThickness) {
    return `${PixelSpacingX.toFixed(3)}x${PixelSpacingY.toFixed(
      3
    )}x${SpacingBetweenSlices.toFixed(3)}`;
  }
  return `${PixelSpacingX.toFixed(3)}x${PixelSpacingY.toFixed(
    3
  )}x${SliceThickness.toFixed(3)}`;
};
