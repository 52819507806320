import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_FILE_OBJECT_INFORMATION = gql`
  query($input: InputFileInfo!) {
    fileObjectInfo(input: $input) {
      ok
      errors {
        path
        message
      }
      file_info {
        idObject
        name
        type
        size
        createdAt
        updatedAt
        projects {
          id
          name
        }
        folder_contents {
          idFolderContent
          Rights {
            idRight
            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        folders {
          idFolder
          name
          Size
          createdAt
          updatedAt
          Owner {
            id
            FullName
          }
          folders_paths {
            idFolder
            name
          }
          Rights {
            idRight
            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        projects {
          id
          name
          type
          membership {
            idMember
            member_setting {
              id
              idProject
              idUser
              access_level
            }
          }
          admin {
            id
            FullName
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const QueryFileObjectInformation = input => {
  console.log('QueryFileObjectInformation', { input });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_OBJECT_INFORMATION),
    variables: { input },
  });
};

const QUERY_FILE_OBJECT_INFORMATIONS = gql`
  query($inputs: [InputFileInfo!]!) {
    fileObjectInfos(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      file_infos {
        idObject
        name
        type
        size
        healthy
        createdAt
        updatedAt
        owner {
          id
          FullName
        }
        projects {
          id
          name
        }
        folder_contents {
          idFolderContent
          idFolder
          Rights {
            idRight
            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        folders {
          idFolder
          name
          Size
          createdAt
          updatedAt
          Owner {
            id
            FullName
          }
          folders_paths {
            idFolder
            name
          }
          Rights {
            idRight
            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
        projects {
          id
          name
          type
          membership {
            idMember
            member_setting {
              id
              idProject
              idUser
              access_level
            }
          }
          admin {
            id
            FullName
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const QueryFileObjectInformations = inputs => {
  console.log('QueryFileObjectInformations', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_OBJECT_INFORMATIONS),
    variables: { inputs },
  });
};
