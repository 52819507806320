import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CommunicationsLeft, CommunicationsRight } from './components';
import { GetData } from 'helpers';
import { MutationUpdateReadMessages } from 'graphql/Communications';
class Communications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      selected: null,
      updating: false,
    };
    this.holder = {
      getSelected: null,
      updateDirectMessage: null,
    };
  }
  updateReadsChannels = selected => {
    const { updating } = this.state;
    if (updating) return;
    this.setState({ updating: true });
    (async () => {
      MutationUpdateReadMessages(selected.channel.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateReadMessages;
          if (ok) {
            selected.channel.notread = 0;
            this.setState({ updating: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ updating: false });
        });
    })();
  };
  handleCommunicationRowSelection = selected => {
    const { direct, channel } = selected;
    // console.log({ channel });
    if (channel) {
      const { id } = channel;
      if (typeof id === 'undefined') {
        this.props.history.push('/communications');
        selected = null;
      } else {
        this.props.history.push(`/communications/channel/${id}`);
      }
      if (channel.notread) {
        this.updateReadsChannels(selected);
      }
    } else if (direct) {
      const { User } = direct;
      if (typeof User === 'undefined' || !User) {
        this.props.history.push('/communications');
        selected = null;
      } else {
        this.props.history.push(`/communications/chat/${User.id}`);
      }
    } else {
      selected = null;
    }
    this.setState({ selected });
  };
  handleAddReceiver = selected => {
    this.setState({ selected });
  };
  handleDrawerToggle = () => {
    this.setState(prev => ({ mobileOpen: !prev.mobileOpen }));
  };
  handleOnlineUser = online => {
    if (online === undefined || !online) return;
    const { selected } = this.state;
    if (selected === undefined || !selected) return;
    const index = selected
      .map(x => {
        const { direct } = x;
        if (direct) return direct.User.id;
        return -1;
      })
      .indexOf(online.id);
    if (index !== -1) {
      selected[index].direct.User.isOnline = online.isOnline;
      this.setState({ selected });
    }
  };
  handleAddLastDirect = direct => {
    if (this.holder.updateDirectMessage)
      this.holder.updateDirectMessage(direct);
  };
  render() {
    const { mobileOpen, selected } = this.state;
    const { height, ...res } = this.props;
    const height_content = height - 70;
    return (
      <div style={{ display: 'flex', height: height_content }}>
        <CommunicationsLeft
          handleAddReceiver={this.handleAddReceiver}
          handleCommunicationRowSelection={this.handleCommunicationRowSelection}
          handleDrawerToggle={this.handleDrawerToggle}
          height={height_content}
          holder={this.holder}
          mobileOpen={mobileOpen}
          selected={selected}
          {...res}
        />
        <CommunicationsRight
          handleAddLastDirect={this.handleAddLastDirect}
          handleDrawerToggle={this.handleDrawerToggle}
          handleOnlineUser={this.handleOnlineUser}
          height={height_content}
          mobileOpen={mobileOpen}
          selected={selected}
          {...res}
        />
      </div>
    );
  }
}

Communications.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { layout } = state;
  return {
    prevUserId: state.info_user.prevUserId,
    isDesktop: layout.isDesktop,
    isMobile: layout.isMobile,
    isTablet: layout.isTablet,
  };
};

export default connect(mapStateToProps, null)(withRouter(Communications));
