/* eslint-disable quotes */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Checkbox from '@mui/material/Checkbox';

const width_check = 12;
const useStyles = makeStyles()(theme => ({
  root: {
    margin: theme.spacing(0, 1, 0, 0),
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: width_check,
    height: width_check,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.3), inset 0 -1px 0 rgba(16,22,26,.2)',
    backgroundColor: '#c8d0d5',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.8)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      // background: 'rgba(206,217,224,.5)'
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: width_check,
      height: width_check,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

// Inspired by blueprintjs
const CustomeCheckRight = props => {
  const { classes } = useStyles();

  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      className={classes.root}
      color="default"
      disableRipple
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
};

const CheckRights = ({ right }) => {
  return (
    <div>
      <CustomeCheckRight checked={right.write} disabled />
      <CustomeCheckRight checked={right.read} disabled />
      <CustomeCheckRight checked={right.download} disabled />
      <CustomeCheckRight checked={right.delete} disabled />
      <CustomeCheckRight checked={right.full} disabled />
    </div>
  );
};
CheckRights.defaultProps = {
  right: {
    write: false,
    read: false,
    download: false,
    delete: false,
    full: false,
  },
};
export default CheckRights;
