import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Image as ImageIcon,
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { GetContentThumbnail } from 'helpers';

class FileMetaHeader extends Component {
  render() {
    const { classes, theme, File, handleDrawerClose } = this.props;
    let Thumbnail = '/images/icons/dcm.png';
    let idFile = 0;
    let InstanceNumber = 'Instance Number:';
    let SliceLocation = 'Slice location:';
    if (File) {
      File.original_name = 'file.dcm';
      idFile = File.idFile;
      Thumbnail = GetContentThumbnail({ File });
      InstanceNumber = `Instance Number: ${File.InstanceNumber}`;
      SliceLocation = `ID: ${idFile}, Slice location: ${File.SliceLocation}`;
    }
    return (
      <ListItem className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === 'rtl' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <ListItemAvatar>
          {Thumbnail !== '' ? (
            <Avatar src={Thumbnail}>{`File-${idFile}`}</Avatar>
          ) : (
            <Avatar>
              <ImageIcon />
            </Avatar>
          )}
        </ListItemAvatar>
        <ListItemText primary={InstanceNumber} secondary={SliceLocation} />
      </ListItem>
    );
  }
}

FileMetaHeader.propTypes = {
  classes: PropTypes.object,
};

export default FileMetaHeader;
