import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { TableInputs } from './components';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { ButtonLoading } from 'components';
import { QuerySequenceNameBySeriesId } from 'graphql/Series/sequences/utils_sequences';
import { GetData, ServerErrorsString } from 'helpers';
import { connect } from 'react-redux';
import { IsInvalid } from 'helpers';
const useStyles = () => ({
  root: {},
});
class DialogConfigurationDockerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      set: false,
      rows_inputs: [],
      inputs: [],
    };
  }
  componentDidMount() {
    this.initRows();
  }
  componentDidUpdate() {
    if (this.state.set) return;
    this.initRows();
  }
  initRows = () => {
    const { loading } = this.state;
    const { process, row_jobs } = this.props;
    if (!process.settings.id) return;
    if (loading) return;
    this.setState({ loading: true, inputs: row_jobs[0].volumes });
    if (row_jobs.length % row_jobs[0].volumes.length != 0) {
      this.props.handleAddError(
        'Amount of data does not match expected amount of inputs'
      );
      this.props.handleClose();
    }
    const rows = [];
    for (let i = 0; i < row_jobs.length; i++) {
      const job = row_jobs[i];
      const { idSeries } = job;
      if (IsInvalid(idSeries)) {
        this.updateRow(rows, i, job, '#' + i);
      } else {
        const SeriesQuery = { idSeries };
        (async () => {
          QuerySequenceNameBySeriesId(SeriesQuery)
            .then(res => {
              const data = GetData(res);
              const { Series } = data.Series;
              let sequence_name = 'SeriesID-' + idSeries;
              if (Series.sequence) {
                if (Series[0].sequence) {
                  sequence_name = Series[0].sequence.name;
                }
              }
              this.updateRow(rows, i, job, sequence_name);
            })
            .catch(error => {
              this.updateRow(rows, i, job, '#' + i);
              this.props.handleAddError(ServerErrorsString(error));
            });
        })();
      }
    }
  };
  handleChange = event => {
    event.preventDefault();
    this.setState({ text: event.target.value });
  };
  handleCheckedChanged = (event, row, index) => {
    let { rows_inputs } = this.state;
    for (let i = 0; i < rows_inputs.length; i++) {
      const element = rows_inputs[i];
      if (element.idSeries === row.idSeries) {
        for (let j = 0; j < rows_inputs[i].volumes.length; j++) {
          rows_inputs[i].volumes[j].value = false;
        }
        rows_inputs[i].volumes[index].value = true;
      }
    }
    this.setState({ rows_inputs });
  };
  checkJobIds = job_id => {
    const { inputs, rows_inputs } = this.state;
    if (!job_id) return false;
    const count = rows_inputs.filter(el => el.idJob === job_id).length;
    if (count === inputs.length) return true;
    return false;
  };
  checkVolumes = volumes => {
    if (!volumes) return false;
    if (volumes.length === 0) return false;
    const count = volumes.filter(el => el.value).length;
    if (count === 1) return true;
    return false;
  };
  checkJobVolumes = job_id => {
    const { inputs, rows_inputs } = this.state;
    if (!job_id) return false;
    const found = rows_inputs.filter(el => el.idJob === job_id);
    const result = new Array(inputs.length).fill(1);
    let counts = new Array(inputs.length).fill(0);
    for (let i = 0; i < found.length; i++) {
      const job = found[i];
      const vol = [];
      for (let j = 0; j < job.volumes.length; j++) {
        vol.push(job.volumes[j].value ? 1 : 0);
      }
      counts = counts.map(function(num, idx) {
        return num + vol[idx];
      });
    }
    if (JSON.stringify(counts) === JSON.stringify(result)) return true;
    return false;
  };
  handleSave = () => {
    const { rows_inputs } = this.state;
    let error = [];
    for (let i = 0; i < rows_inputs.length; i++) {
      const row = rows_inputs[i];
      error = [];
      if (!this.checkJobIds(row['idJob'])) {
        error.push(
          'One or more Job ids are not correct, check that every job has the correct amount of volumes'
        );
      }
      if (!this.checkVolumes(row['volumes'])) {
        error.push(
          'One or more Volumes are not correct, check that every Series has only one volume selected'
        );
      }
      if (!this.checkJobVolumes(row['idJob'])) {
        error.push(
          'One or more Jobs are not correct, check that every Job has all the volumes selected'
        );
      }
      if (error.length) {
        this.props.handleAddError(error.join('\n'));
      }
    }
    if (!error.length) {
      this.props.handleClose();
      this.props.handleUpdateJobs(rows_inputs);
    }
  };
  updateRow(rows_inputs, i, element, sequence_name) {
    const { inputs } = this.state;
    rows_inputs.push({
      id: i,
      idFileObject: element.idFileObject,
      idSeries: element.idSeries,
      sequence_name,
      idJob: Math.floor(i / inputs.length) + 1,
      volumes: element.volumes,
    });
    this.setState({
      loading: false,
      inputs,
      rows_inputs,
      set: true,
    });
  }
  render() {
    const { classes, open, process } = this.props;
    const { inputs, rows_inputs } = this.state;
    return (
      <Dialog aria-labelledby="form-dialog-title" open={open}>
        <DialogTitle
          className={classNames(classes.title)}
          id="form-dialog-title">
          Connect inputs
        </DialogTitle>
        <DialogContent>
          <Grid item style={{ minHeight: 400 }} xs={12}>
            {process ? (
              <TableInputs
                dense
                disable_checkbox
                handleCheckedChanged={(event, row, index) =>
                  this.handleCheckedChanged(event, row, index)
                }
                holder={this.holder}
                inputs={inputs}
                rows={rows_inputs}
              />
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonLoading
            handleClick={() => this.handleSave()}
            name="Save"
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogConfigurationDockerInput.propTypes = {
  open: PropTypes.bool,
};
DialogConfigurationDockerInput.defaultProps = {};

const mapStateToProps = () => {
  let size = 0;
  return {
    size,
  };
};

export default connect(mapStateToProps)(
  withStyles(DialogConfigurationDockerInput, useStyles)
);
