const useStyles = (theme, props) => ({
  root: {
    height: '100%',
    width: '100%',
    // marginTop: theme.spacing(3)
  },
  paper: {
    height: props.height ? props.height : 'calc(100% - 67px)',
    width: '100%',
    marginBottom: 0,
  },
  table_body: {
    width: '100%',
    // maxHeight: 'calc(100% - 80px)',
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  tableWrapper: {
    // position: 'absolute',
    top: 0,
    left: 0,
    maxHeight: 'calc(100% - 80px)',
    /* uncomment this static height to see it work */
    /* height: 100%; */
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    // maxHeight: 'calc(100%-500px)',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table_pagination: { height: '38px' },
});

export default useStyles;
