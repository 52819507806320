import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_GROUP_TYPES = gql`
  query($name: String, $page: Int, $limit: Int) {
    allGroupTypes(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      groups {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryGroupTypes = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_GROUP_TYPES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const QUERY_GROUP_TYPES_BY_NAMES = gql`
  query($names: [String!]!) {
    groupTypesByNames(names: $names) {
      ok
      errors {
        path
        message
      }
      total
      groups {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryGroupTypesByNames = names => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_GROUP_TYPES_BY_NAMES),
    variables: {
      names,
    },
  });
};
const MUTATION_ADD_GROUP_TYPES = gql`
  mutation($inputs: [InputGroupType!]!) {
    addGroupTypes(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      groups {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddGroupTypes = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_GROUP_TYPES),
    variables: {
      inputs,
    },
  });
};

const MUTATION_REMOVE_GROUP_TYPES = gql`
  mutation($ids: [LongLong!]!) {
    removeGroupTypes(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveGroupTypes = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_GROUP_TYPES),
    variables: {
      ids,
    },
  });
};
