export { default as AvatarsList } from './AvatarsList';
export { default as AvatarUpload } from './AvatarUpload';
export { default as ChangeAvatarBottom } from './ChangeAvatarBottom';

export const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  avatar_selected: {
    width: '100%',
    height: '100%',
    border: '10px solid #ddd',
  },
});
