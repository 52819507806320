import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

class RowTextMultiLine extends Component {
  render() {
    const { first, second, display, width } = this.props;
    let { style, styleBox } = this.props;
    if (width) {
      if (style) {
        style = { ...style, width };
      } else {
        style = { width };
      }
    }
    if (display) {
      if (style) {
        style = { ...style, display };
      } else {
        style = { display };
      }
    }
    //
    if (styleBox !== undefined && styleBox) {
      styleBox = { ...styleBox, marginRight: '5px' };
    } else {
      styleBox = {
        marginRight: '5px',
        ...styleBox,
      };
    }
    //
    return (
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box display="inline" style={{ ...styleBox }}>
          {first}
        </Box>
        <Box style={{ marginLeft: '4px' }}>
          <Typography
            color="textSecondary"
            component="p"
            display="inline"
            // noWrap
            // style={{ ...styleTypography }}
            variant={this.props.variant_second}>
            {second}
          </Typography>
        </Box>
      </div>
    );
  }
}

RowTextMultiLine.propTypes = {
  classes: PropTypes.object,
};
RowTextMultiLine.defaultProps = {
  style: null,
  variant_first: 'body2',
  variant_second: 'body2',
};
export default RowTextMultiLine;
