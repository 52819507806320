import { blueGrey } from '@mui/material/colors';
import { FormatBytes } from 'helpers';

export const useStyles = theme => ({
  superior: {
    width: '100%',
  },
  type: {
    minWidth: 200,
    marginBottom: 20,
    marginRight: 20,
  },
  marginright: {
    marginRight: 20,
  },
  card: {
    maxWidth: '100%',
    maring: theme.spacing(1),
  },
  avatar: {
    backgroundColor: blueGrey[500],
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  text_width: {
    width: '100%',
    maxWidth: '50ch',
  },
});
export const account_types = [
  { title: 'Test' },
  { title: 'Demo' },
  { title: 'Administrator' },
  { title: 'Basic' },
  { title: 'User of a Clinical Trial' },
];

export const FormatNumber = to_format => {
  const full = {
    number: 0,
    format: 'Bytes',
  };
  let number = FormatBytes(to_format);
  let parts = number.split(' ');
  if (parts.length === 2) {
    full.number = parseFloat(parts[0]).toFixed(2);
    full.format = parts[1];
  }
  return full;
};
