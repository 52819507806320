import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Divider, Paper } from '@mui/material';
import { TabPanel, TabSmall, TabsSmall } from 'components';
import { TabDataFiles, TabDataSeries } from './components';
import { styleTabPanels } from 'common';
import { copyFiles, copySeries } from 'redux-store/actions/file_folder_manager';

class TabsInputData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        {
          name: 'Series',
          TabComponent: TabDataSeries,
          key: 'dataseries',
        },
        {
          name: 'Files',
          TabComponent: TabDataFiles,
          key: 'datafiles',
        },
      ],
    };
    if (props.holder) {
      props.holder.handleAction = this.handleAction;
      props.holder.handleDeleteSelected = () => this.handleDeleteSelected();
    }
    this.holder = {
      handleActionSeries: null,
      handleActionFiles: null,
      handleDeleteSeries: null,
      handleDeleteFiles: null,
    };
  }
  componentDidMount() {
    const { num_series, num_files } = this.props;
    if (num_files > num_series) {
      this.setState({ tabValue: 1 });
      this.props.copySeries([]);
    } else {
      this.setState({ tabValue: 0 });
      this.props.copyFiles([]);
    }
  }
  handleAction = action => {
    const { process } = this.props;
    if (this.state.tabValue) {
      this.holder.handleActionFiles(action, process);
    } else {
      this.holder.handleActionSeries(action, process);
    }
  };
  handleDeleteSelected = () => {
    if (this.state.tabValue) {
      this.holder.handleDeleteFiles();
    } else {
      this.holder.handleDeleteSeries();
    }
  };
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    this.props.handleChangedTabValue(tabValue);
  };
  render() {
    const { classes, num_series, num_files } = this.props;
    const tab_value = parseInt(this.state.tabValue, 10);
    return (
      <React.Fragment>
        <Paper square>
          <TabsSmall
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tab_value}
            variant="scrollable">
            <TabSmall
              label={num_series ? `Series (${num_series})` : 'Series'}
            />
            <TabSmall label={num_files ? `Files (${num_files})` : 'Files'} />
          </TabsSmall>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`inputdata-tab-panel-${index}`}
                  value={tab_value}>
                  <TabComponent
                    cleanSelected={this.props.cleanSelected}
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    handleTabChanged={this.props.handleTabChanged}
                    height={this.props.height - 40}
                    history={this.props.history}
                    holder={this.holder}
                    isDesktop={this.props.isDesktop}
                    setSelected={this.props.setSelected}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TabsInputData.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let num_series = copy_series ? copy_series.length : 0;
  let num_files = copy_files ? copy_files.length : 0;
  return {
    num_series,
    num_files,
  };
};
export default connect(mapStateToProps, {
  copyFiles,
  copySeries,
})(withStyles(TabsInputData, styleTabPanels));
