import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { withStyles } from 'tss-react/mui';
import {
  Checkbox,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ObjectSize } from '../utils/utils';
import { MenuTopDown, SnackMessage, Units } from 'components';
import {
  CountriesGoogle,
  DialogRequestSuccess,
  TermsAndConditions,
} from './components';
import schema from './SchemaValidation';
import { titles, useStyles } from './Styles';
import { GetData, RunRequest, ServerErrorsString } from 'helpers';
import ButtonLoad from 'components/Buttons/ButtonLoad';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
      },
      open_titles: false,
      open_terms: false,
      loading: false,
      server_error: '',
      server_warning: '',
      success: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    const { formState } = this.state;
    const old_size = ObjectSize(formState.errors);
    const errors = validate(formState.values, schema);
    let errors_local = {};
    //
    for (let key in errors) {
      errors_local[key] = errors[key];
    }
    const new_size = ObjectSize(errors_local);
    const isValid = new_size ? false : true;
    //
    //
    if (formState.isValid !== isValid || old_size !== new_size) {
      this.setState({
        formState: {
          ...formState,
          isValid: isValid,
          errors: errors_local || {},
        },
      });
    }
  }

  handleChange = (event, open_titles) => {
    event.persist();
    const { formState } = this.state;
    //
    //
    const { registration } = this.props;
    if (registration) {
      for (let key in registration.errors) {
        const { path } = registration.errors[key];
        //
        if (event.target.name === path) {
          this.props.clearRegistration();
          break;
        }
      }
    }
    const state = {
      formState: {
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      },
    };
    if (typeof open_titles !== 'undefined') state.open_titles = false;
    this.setState(state);
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSignUp = event => {
    if (event) event.preventDefault();
    const { formState } = this.state;
    const RegisterUser = {
      title: formState.values.title,
      firstName: formState.values.firstName,
      lastName: formState.values.lastName,
      email: formState.values.email,
      password: formState.values.password,
      idUnit: formState.values.unit.idUnit,
      Message: formState.values.message,
      country: formState.values.country,
    };

    this.handleRunRequest(RegisterUser);
  };
  handleRunRequest = RegisterUser => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });

    (async () => {
      RunRequest(RegisterUser)
        .then(res => {
          const data = GetData(res);
          let server_error = '';
          let server_warning = '';
          const { createRequest } = data;
          if (createRequest) {
            const { ok, warning, errors } = createRequest;
            if (ok) {
              this.props.ReactGA4.ga('event', 'sign_up', {
                method: 'Google',
              });
              // this.props.history.push('/');
              this.setState({
                loading: false,
                server_error,
                server_warning,
                success: true,
              });
              return;
            } else if (warning) {
              server_warning = warning;
            } else if (errors && errors.length) {
              server_error = errors[0].message;
            } else {
              server_error = 'Unknown server error';
            }
          } else {
            server_error = 'Unknown server error';
          }
          this.setState({ loading: false, server_error, server_warning });
        })
        .catch(error => {
          this.setState({
            loading: false,
            server_error: ServerErrorsString(error),
          });
        });
    })();
  };
  hasError = field =>
    this.state.formState.touched[field] && this.state.formState.errors[field]
      ? true
      : false;

  handleCloseTitles = (title, event) => {
    if (!event) {
      this.setState({ open_titles: false });
      return;
    }
    const event_final = {
      persist: () => {},
      target: {
        type: 'title',
        name: 'title',
        value: title.option,
      },
    };
    this.handleChange(event_final, false);
  };
  handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ open_titles: false });
    }
  };
  handleClickTitles = event => {
    event.preventDefault();
    this.setState(prev_state => ({ open_titles: !prev_state.open_titles }));
  };
  handleOpenTermsAndConditions = () => {
    this.setState({ open_terms: true });
  };
  handleCloseTermsAndConditions = agree => {
    if (!this.state.open_terms) {
      return;
    }
    if (agree) {
      const { formState } = this.state;
      this.setState({
        formState: {
          ...formState,
          values: {
            ...formState.values,
            policy: true,
          },
        },
        open_terms: false,
      });
    } else {
      this.setState({ open_terms: false });
    }
  };
  handleErrorClose = () => {
    const { formState } = this.state;

    this.setState({
      server_error: '',
      server_warning: '',
      formState: {
        ...formState,
        isValid: true,
        errors: {},
      },
    });
  };
  handleUnitChanged = unit => {
    const event = {
      persist: () => {},
      target: {
        type: 'unit',
        name: 'unit',
        value: unit,
      },
    };
    this.handleChange(event);
  };
  handleCountryChanged = country => {
    let name = '';
    if (country) {
      name = country.name;
    }

    const event = {
      persist: () => {},
      target: {
        type: 'country',
        name: 'country',
        value: name,
      },
    };
    this.handleChange(event);
  };

  render() {
    const { classes } = this.props;
    const {
      formState,
      open_titles,
      open_terms,
      server_error,
      server_warning,
      success,
      loading,
    } = this.state;
    let title = formState.values.title;
    if (!title) {
      title = '';
    }
    let snack_message = '';
    let type_error_snack = true;
    if (server_error !== '') {
      snack_message = server_error;
    } else if (server_warning !== '') {
      snack_message = server_warning;
      type_error_snack = false;
    }
    const open_snack = snack_message !== '' ? true : false;
    const unit_name = formState.values.unit ? formState.values.unit.name : '';
    let name = '';
    if (formState.values.firstName && formState.values.lastName) {
      name = `${formState.values.firstName} ${formState.values.lastName}`;
    }
    return (
      <div className={classes.root}>
        <DialogRequestSuccess
          email={formState.values.email}
          history={this.props.history}
          name={name}
          open={success}
          unit_name={unit_name}
        />
        {open_snack && (
          <SnackMessage
            handleClose={this.handleErrorClose}
            message_text={snack_message}
            open={open_snack}
            type={type_error_snack ? 'error' : 'warning'}
          />
        )}
        <TermsAndConditions
          handleCloseTerms={this.handleCloseTermsAndConditions}
          open={open_terms}
        />
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h1">
                  The good physician treats the disease; the great physician
                  treats the patient who has the disease.
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    Sir William Osler
                  </Typography>
                  <Typography className={classes.bio} variant="body2">
                    Founder Professor of Johns Hopkins Hospital
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack} size="large">
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSignUp}>
                  <Typography className={classes.title} variant="h2">
                    Request an account
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    Use a valid email to create new account
                  </Typography>
                  <TextField
                    fullWidth
                    id="title"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Title"
                    margin="dense"
                    name="title"
                    onClick={this.handleClickTitles.bind(this)}
                    placeholder="Select your titile"
                    size="small"
                    style={{ width: '130px' }}
                    value={title}
                    variant="outlined"
                  />
                  <MenuTopDown
                    handleClose={this.handleCloseTitles}
                    object_parent={'title'}
                    objects={titles}
                    open={open_titles}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('firstName')}
                    fullWidth
                    helperText={
                      this.hasError('firstName')
                        ? formState.errors.firstName[0]
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="First name"
                    name="firstName"
                    onChange={this.handleChange}
                    placeholder="Enter your first name"
                    size="small"
                    type="text"
                    value={formState.values.firstName || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('lastName')}
                    fullWidth
                    helperText={
                      this.hasError('lastName')
                        ? formState.errors.lastName[0]
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Last name"
                    name="lastName"
                    onChange={this.handleChange}
                    placeholder="Enter your first last"
                    size="small"
                    type="text"
                    value={formState.values.lastName || ''}
                    variant="outlined"
                  />
                  <Grid item pt={2}>
                    <CountriesGoogle
                      error={
                        this.hasError('country')
                          ? formState.errors.country[0]
                          : null
                      }
                      handleCountryChanged={this.handleCountryChanged}
                      hasError={this.hasError}
                    />
                  </Grid>
                  {/* <Countries
                    className={classes.textField}
                    error={
                      this.hasError('country')
                        ? formState.errors.country[0]
                        : null
                    }
                    handleCountryChanged={this.handleCountryChanged}
                    hasError={this.hasError}
                    style={{ width: 300 }}
                  /> */}
                  <TextField
                    className={classes.textField}
                    error={this.hasError('email')}
                    fullWidth
                    helperText={
                      this.hasError('email') ? formState.errors.email[0] : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Email address"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Enter a valid email address"
                    size="small"
                    type="text"
                    value={formState.values.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('password')}
                    fullWidth
                    helperText={
                      this.hasError('password')
                        ? formState.errors.password[0]
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Set your password"
                    size="small"
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />
                  <Units
                    className={classes.textField}
                    formState={formState}
                    handleUnitChanged={this.handleUnitChanged}
                    hasError={this.hasError}
                  />
                  <TextField
                    className={classes.textField}
                    error={this.hasError('message')}
                    fullWidth
                    helperText={
                      this.hasError('message')
                        ? formState.errors.message[0]
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Reason for the request"
                    multiline
                    name="message"
                    onChange={this.handleChange}
                    placeholder="Please describe the reason for your request"
                    size="small"
                    type="message"
                    value={formState.values.message || ''}
                    variant="outlined"
                  />
                  <div className={classes.policy}>
                    <Checkbox
                      checked={formState.values.policy || false}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={this.handleChange}
                    />
                    <Typography
                      className={classes.policyText}
                      color="textSecondary"
                      variant="body1">
                      I have read the{' '}
                      <Link
                        color="primary"
                        component={RouterLink}
                        onClick={() => {
                          this.handleOpenTermsAndConditions();
                        }}
                        to="#"
                        underline="always"
                        variant="h6">
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </div>
                  {this.hasError('policy') && (
                    <FormHelperText error>
                      {formState.errors.policy[0]}
                    </FormHelperText>
                  )}
                  <ButtonLoad
                    disable_button={!formState.isValid}
                    handleClick={this.handleSignUp}
                    loading={loading}
                    name={'Sign up now'}
                  />
                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/sign-in" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignUp.propTypes = {
  history: PropTypes.object,
  // register: PropTypes.func.isRequired
  // registration: PropTypes.object
};
// const mapStateToProps = state => ({
//   registration: state.register.registration
// });

// export default connect(mapStateToProps, { register, clearRegistration })(

// );
export default withStyles(withRouter(SignUp), useStyles);
// export default withRouter(SignUp);
