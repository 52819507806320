import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';

import {
  AnatomicalRegion,
  ButtonCircularIconLoad,
  DivGrow,
  ImageFocus,
  InvestigativedSide,
  Pathology,
  Sequence,
  SnackMessage,
} from 'components';

import { GetData, ServerErrorsString } from 'helpers';
import { SaveMultiDetails } from 'graphql/Series';
import { copySeries } from 'redux-store/actions/file_folder_manager';

const useStyles = theme => ({
  button_blue: {
    // margin: theme.spacing(1)
    width: '100px',
  },
  button_red: {
    width: '100px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
});

class DetailsMetaMultiSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      changed: false,
      details: {},
    };
    this.holder = {
      getPathology: null,
      getSide: null,
      getRegion: null,
      getFocus: null,
      getSequence: null,
      resetPathology: null,
      resetSide: null,
      resetRegion: null,
      resetFocus: null,
      resetSequence: null,
    };
  }

  getSelected = () => {
    const { copy_series, selected } = this.props;
    if (selected) return selected.filter(x => x.Series).map(x => x.Series);
    return copy_series;
  };
  handleSave = () => {
    const selected = this.getSelected();
    if (selected.length === 0) {
      this.setState({ error: 'Invalid or missing Series' });
      return;
    }

    const { loading } = this.state;
    if (loading) return;
    const ids = selected.map(x => x.idSeries);
    const { idPathology } = this.holder.getPathology();
    const { idSide } = this.holder.getSide();
    const { idRegion } = this.holder.getRegion();
    const { idFocus } = this.holder.getFocus();
    const { idSequence } = this.holder.getSequence();
    const sm = {
      idSeries: 0,
      idPathology,
      idSide,
      idRegion,
      idFocus,
      idSequence,
    };

    this.setState({ loading: true });
    (async () => {
      SaveMultiDetails(ids, sm)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SaveMultiDetails;
          if (!ok) {
            throw errors;
          }
          let to_send = {};
          const changed = ['region', 'focus', 'side', 'sequence', 'pathology'];
          for (let i = 0; i < changed.length; i++) {
            if (Object.keys(this.state.details).indexOf(changed[i]) !== -1) {
              to_send[changed[i]] = this.state.details[changed[i]];
            }
          }
          this.setState({ loading: false, changed: false });
          this.props.handleDetailChanged(ids, to_send);
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChanged = (type, value) => {
    const { details } = this.state;
    if (Object.keys(this.state).indexOf(type) === -1) {
      this.setState({ changed: true, details: { ...details, [type]: value } });
    } else if (this.state[type].name !== value.name) {
      this.setState({ changed: true, details: { ...details, [type]: value } });
    }
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const { classes } = this.props;
    const minWidth = true;
    const { loading, error, changed } = this.state;
    let justifyContent = 'flex-start';
    if (this.props.direction === 'row') {
      justifyContent = 'center';
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="flex-start"
          container
          direction={this.props.direction}
          justifyContent={justifyContent}>
          <Pathology
            handleSelectedChanged={this.handleSelectedChanged}
            holder={this.holder}
            minWidth={minWidth}
          />
          <ImageFocus
            handleSelectedChanged={this.handleSelectedChanged}
            holder={this.holder}
            minWidth={minWidth}
          />
          <InvestigativedSide
            handleSelectedChanged={this.handleSelectedChanged}
            holder={this.holder}
            minWidth={minWidth}
          />
          <Sequence
            handleSelectedChanged={this.handleSelectedChanged}
            holder={this.holder}
            minWidth={minWidth}
          />
          <AnatomicalRegion
            handleSelectedChanged={this.handleSelectedChanged}
            holder={this.holder}
            minWidth={minWidth}
          />
        </Grid>
        <Divider />
        <Grid container item style={{ width: '100%' }} xs={12}>
          <DivGrow />
          <ButtonCircularIconLoad
            className={classes.button}
            disabled={!changed}
            handleButtonClick={this.handleSave}
            loading={loading}
          />
        </Grid>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </React.Fragment>
    );
  }
}

DetailsMetaMultiSelection.propTypes = {
  ListSeries: PropTypes.array,
  classes: PropTypes.object,
  direction: PropTypes.string.isRequired,
};
DetailsMetaMultiSelection.defaultProps = {
  ListSeries: [],
  direction: 'column',
  handleDetailChanged: () => '',
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { copySeries })(
  withStyles(DetailsMetaMultiSelection, useStyles)
);
