import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styleToolbar } from 'common';
import { ButtonReloadDelete, BadgeIcon } from 'components';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
class ToolBarSkipRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, count_save, count_save_all, disabled } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45, marginTop: 0 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.props.handleAction}
              size="small"
              value={this.props.view}>
              <ToggleButton
                aria-label="left aligned"
                sx={{ paddingTop: count_save ? 0 : '7px' }}
                value="copy">
                <BadgeIcon Icon={SaveIcon} title="Save" />
              </ToggleButton>
              <ToggleButton
                aria-label="centered"
                sx={{ paddingTop: count_save_all ? 0 : '7px' }}
                value="run">
                <BadgeIcon Icon={SaveAltIcon} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReloadTable}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              // numSelected={numSelected}
              show_delete
              tooltip="Reload Jobs"
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarSkipRules.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};

export default withStyles(ToolBarSkipRules, styleToolbar);
