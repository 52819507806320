import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { ToolBarEncrypKeys } from './components';
import { rowsPerPageBig } from 'helpers';
import { TableGrid } from 'components';
import { alphabet_columns, alphabet_rows } from 'common';

class TabEncryptionKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 50,
    };
  }
  render() {
    const { height } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ToolBarEncrypKeys
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={this.handleDeleteSelected}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item sx={{ paddingTop: 1 }} xs={12}>
          <TableGrid
            cell_selection
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            grid
            headers={alphabet_columns}
            height={height - 240}
            limit={this.state.limit}
            onHighlightChange={this.handleSeriesRowClicked}
            onRowClicked={this.handleSeriesRowClicked}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            row_height={33}
            rows={alphabet_rows}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabEncryptionKeys.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};

export default TabEncryptionKeys;
