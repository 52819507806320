import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import { Grid, Autocomplete, TextField } from '@mui/material';

const useStyles = theme => ({
  margin: {
    margin: theme.spacing(1),
    // width: 'calc(100% - 250px)',
  },
  input: {
    padding: '10.5px 14px',
  },
  textField: {
    // width: 'calc(100% - 250px)',
  },
});

class TabDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueDescription: '',
      type: 'Study',
    };
    const { holder } = props;
    if (holder) {
      holder.getDescription = () => ({
        type: this.state.type + 'Description',
        description: this.state.valueDescription,
      });
    }
    const { tab_holder, index, query } = props;
    if (tab_holder) {
      tab_holder.getValues[index] = () => ({
        type: query,
        value: this.state.valueDescription,
      });
    }
  }

  handleDescriptionChange = event => {
    event.preventDefault();
    this.setState({ valueDescription: event.target.value });
  };
  handleExecuteSearch = event => {
    if (!event) {
      return;
    }
    const { key } = event;

    if (key === 'Enter') {
      this.props.executeSearch(
        this.state.valueDescription,
        'Description',
        event
      );
    }
  };
  handleTypeDescription = value => {
    // const { value } = option;
    if (value !== this.state.type) {
      this.setState({ type: value });
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { valueDescription } = this.state;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start">
        <Autocomplete
          defaultValue={'Study'}
          disableClearable
          getOptionLabel={option => {
            if (option) return option;
            return '';
          }}
          onChange={(event, value) => this.handleTypeDescription(value)}
          options={['Study', 'Series']}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              InputLabelProps={{
                disabled: true,
              }}
              label="Description Type"
              variant="outlined"
            />
          )}
          size="small"
          style={{ width: 150 }}
        />
        <Grid item xs>
          <TextField
            className={classes.margin}
            fullWidth
            id="textfield-query-description"
            label="Description Value"
            onChange={this.handleDescriptionChange}
            onKeyPress={this.handleExecuteSearch}
            size="small"
            value={valueDescription}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

TabDescription.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabDescription, useStyles);
