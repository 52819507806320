import {
  LOGOUT_USER_PAGE_PROJECT,
  PAGE_PROJECT_UPDATE_SUBFOLDERS,
} from '../actions/types';

const initialState = {
  subfolders: [],
};

export default function reducer_page_project_view(
  state = initialState,
  action
) {
  const { type } = action;
  switch (type) {
    case LOGOUT_USER_PAGE_PROJECT:
      return initialState;
    case PAGE_PROJECT_UPDATE_SUBFOLDERS:
      return {
        ...state,
        subfolders: action.subfolders,
      };
    default:
      return state;
  }
}
