import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_VARIABLE_TYPES = gql`
  query($name: String, $page: Int, $limit: Int) {
    variableTypes(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      types {
        id
        name
        description
        updatedAt
      }
    }
  }
`;

export const QueryVariableTypes = (name, page, limit) => {
  const variables = {};
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (name !== undefined && name) variables.name = name;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_VARIABLE_TYPES),
    variables,
  });
};
const MUTATION_CREATE_VARIABLE = gql`
  mutation($name: String!, $description: String) {
    addVariableType(name: $name, description: $description) {
      ok
      errors {
        path
        message
      }
      type {
        id
        name
        description
        updatedAt
      }
    }
  }
`;
export const MutationCreateVariableType = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_VARIABLE),
    variables,
  });
};

const QUERY_VARIABLE_TYPE_MAPPING = gql`
  query($input: InTypeMap, $page: Int, $limit: Int) {
    seriesTypeMaps(input: $input, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSeries
        idType
        name
        series {
          idSeries
          SeriesDescription
          sequence {
            idSequence
            name
          }
          ImageMr {
            ProtocolName
            SequenceName
            MRAcquisitionType
          }
        }
        type {
          id
          name
        }
        updatedAt
      }
    }
  }
`;
export const QuerySeriesTypeMaps = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_VARIABLE_TYPE_MAPPING),
    variables,
  });
};
