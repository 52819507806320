import isEmpty from 'lodash/isEmpty';
import {
  LOGIN_ERRORS,
  LOGIN_PREV_USER_ID,
  LOGIN_USER,
  LOGIN_USER_UPDATE,
  LOGOUT_USER,
  UPDATE_AVATAR_ERROR,
  UPDATE_USER_SETTING,
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  prevUserId: -1,
  errors: {},
  setting: null,
};

export default function reducer_login(state = initialState, action) {
  const { type, user, errors, prevUserId } = action;
  //
  switch (type) {
    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: user ? user : {},
        errors: null,
      };
    case LOGIN_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: user ? user : {},
        errors: null,
      };
    case LOGIN_USER_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          ...user,
        },
      };
    case LOGIN_ERRORS:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        errors: errors,
      };
    case LOGIN_PREV_USER_ID:
      return {
        ...state,
        prevUserId: prevUserId,
      };
    case UPDATE_AVATAR_ERROR:
      return {
        ...state,
        errors: errors,
      };
    case UPDATE_USER_SETTING:
      return {
        ...state,
        setting: action.setting,
      };
    default:
      return state;
  }
}
