/* eslint-disable no-prototype-builtins */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Stepper, Step, StepLabel, Button } from '@mui/material';
import { JoinSelection, TablesDefinition, MappingSummary } from './components';
import { QueryDockerMappingJoins } from 'graphql/Dockers/utils_docker_mapping_tables';
import {
  MutationCreateMappingSettings,
  MutationUpdateMappingSettings,
  QueryDockerMappingSettingByOutputId,
} from 'graphql/Dockers/utils_docker_mapping_settings';
import { QueryTableNames } from 'graphql/utils_tables';

import { GetData, ServerErrorsString } from 'helpers';

const steps = ['Tables Definition', 'Join Selection', 'Mapping'];

class HorizontalLinearStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      activeStep: 0,
      table_list: [],
      join_list: [],
      tables_definition: null,
      selected_join: null,
      docker_mapping_setting: null,
      terminated: false,
    };
  }
  componentDidMount() {
    const { output } = this.props;
    this.queryTables();
    if (output !== null && output !== undefined) {
      this.queryMappingSettings();
    }
  }
  setOutputType = () => {
    const { output } = this.props;
    const tables_definition = {
      output_definition: {
        selected_type: { id: output.id, name: output.type },
      },
    };
    const state = { tables_definition };
    this.setState(state);
  };
  setMappingSetting = docker_mapping_setting => {
    console.log(docker_mapping_setting);
    if (
      docker_mapping_setting === null ||
      docker_mapping_setting === undefined
    ) {
      this.setOutputType();
      return;
    }
    const {
      docker_mapping_table,
      action,
      docker_variable_types,
      table_name,
      table_column,
      input,
      output,
    } = docker_mapping_setting;
    this.setState({ docker_mapping_setting });
    let configuration = {};
    for (let i = 0; i < docker_variable_types.length; i++) {
      const element = docker_variable_types[i];
      configuration[element.name] = element;
    }
    const tables_definition = {
      input_definition: {
        selected_type: { id: input.id, name: input.type },
        selected_table: { name: docker_mapping_table.table_name_input },
      },
      output_definition: {
        selected_type: { id: output.id, name: output.type },
        selected_action: { name: action },
        selected_table: { name: table_name },
        selected_column: { name: table_column },
        configuration,
      },
    };
    const selected_join = docker_mapping_table;
    const state = { tables_definition, selected_join };
    this.setState(state);
  };
  queryMappingSettings = () => {
    const { output } = this.props;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerMappingSettingByOutputId(output.id)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            errors,
            docker_mapping_setting,
          } = data.dockerMappingSettingByOutputId;
          if (ok) {
            console.log({ docker_mapping_setting });
            this.setMappingSetting(docker_mapping_setting[0]);
            this.setState({
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  queryTables = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryTableNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, tables } = data.tableNames;
          if (ok) {
            this.setState({
              loading: false,
              table_list: tables,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  queryJoinList = (table1, table2) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerMappingJoins(table1, table2)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, joins } = data.allDockerMappingJoins;
          if (ok) {
            this.setState({
              loading: false,
              join_list: joins,
              selected_join: joins[0],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationCreateMappingSettings = inputs => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, terminated: false });
    (async () => {
      MutationCreateMappingSettings(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, inserted } = data.createDockerMappingSetting;
          if (ok) {
            this.setMappingSetting(inserted);
            this.setState({
              uploading: false,
              changed: false,
              terminated: true,
            });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationUpdateMappingSettings = inputs => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, terminated: false });
    (async () => {
      MutationUpdateMappingSettings(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateDockerMappingSetting;
          if (ok) {
            this.setState({
              uploading: false,
              changed: false,
              terminated: true,
            });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false, changed: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getTableNameInput = () => {
    const { tables_definition } = this.state;
    try {
      return tables_definition.input_definition.selected_table.name;
    } catch (error) {
      this.props.handleAddError(ServerErrorsString(error));
    }
  };
  getTableNameOutput = () => {
    const { tables_definition } = this.state;
    try {
      return tables_definition.output_definition.selected_table.name;
    } catch (error) {
      this.props.handleAddError(ServerErrorsString(error));
    }
  };
  checkValues = object => {
    if (object !== undefined && object !== null) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          let value = object[key];
          if (value !== null && value !== undefined) {
            if (value.hasOwnProperty('name')) {
              value = object[key].name;
            }
          }
          if (value === '' || value === undefined || value === null) {
            this.props.handleAddError(
              ServerErrorsString('One or more mandatory fields are missing')
            );
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };
  handleNext = () => {
    const { activeStep } = this.state;
    if (!this._renderStepActions(activeStep)) return;
    this.setState({ activeStep: activeStep + 1 });
  };
  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };
  handleReset = () => {
    this.setState({ activeStep: 0 });
  };
  handleUpdateTablesDefinition = tables_definition => {
    this.setState({ tables_definition });
  };
  handleUpdateJoinSelection = selected_join => {
    this.setState({ selected_join });
  };
  handleSaveSettings = () => {
    const docker_setting_id = this.props.output.idDockerSetting;
    const {
      docker_mapping_setting,
      uploading,
      tables_definition,
      selected_join,
    } = this.state;
    if (uploading) return;
    let config = [];
    const configuration = tables_definition.output_definition.configuration;
    for (var key in configuration) {
      if (configuration.hasOwnProperty(key)) {
        let obj = configuration[key];
        if (obj.name === '' || obj.name === undefined || obj.name === null) {
          continue;
        }
        if (obj.hasOwnProperty('id')) {
          config.push(obj);
        } else {
          delete obj.id;
          config.push(obj);
        }
      } else {
        continue;
      }
    }
    let inputs = {
      table_name: tables_definition.output_definition.selected_table.name,
      table_column: tables_definition.output_definition.selected_column.name,
      action: tables_definition.output_definition.selected_action.name,
      idDockerSetting: docker_setting_id,
      idInput: tables_definition.input_definition.selected_type.id,
      idOutput: tables_definition.output_definition.selected_type.id,
      idDockerMappingTable: selected_join.id,
      dockerVariableTypes: config,
    };
    this.setState({ uploading: true });
    if (
      docker_mapping_setting === null ||
      docker_mapping_setting === undefined
    ) {
      this.mutationCreateMappingSettings(inputs);
    } else {
      inputs['id'] = docker_mapping_setting.id;
      this.mutationUpdateMappingSettings(inputs);
    }
  };
  _renderStepContent = step => {
    switch (step) {
      case 0:
        return (
          <TablesDefinition
            handleUpdateTablesDefinition={tables_definition =>
              this.handleUpdateTablesDefinition(tables_definition)
            }
            setting_id={this.props.output.idDockerSetting}
            table_list={this.state.table_list}
            tables_definition={this.state.tables_definition}
          />
        );
      case 1:
        return (
          <JoinSelection
            handleUpdateJoinSelection={selected_join =>
              this.handleUpdateJoinSelection(selected_join)
            }
            join_list={this.state.join_list}
            selected_join={this.state.selected_join}
          />
        );
      case 2:
        return (
          <MappingSummary
            selected_join={this.state.selected_join}
            tables_definition={this.state.tables_definition}
          />
        );
      default:
        return <div>Finished</div>;
    }
  };
  _renderStepActions = step => {
    const { tables_definition, selected_join } = this.state;
    switch (step) {
      case 0:
        if (tables_definition === null || tables_definition === undefined)
          return 1;
        if (!this.checkValues(tables_definition.input_definition)) {
          return 2;
        }
        if (!this.checkValues(tables_definition.output_definition)) {
          return 3;
        }
        if (
          !this.checkValues(tables_definition.output_definition.configuration)
        ) {
          return false;
        }
        this.queryJoinList(this.getTableNameInput(), this.getTableNameOutput());
        return true;
      case 1:
        if (selected_join === null || selected_join === undefined) {
          return false;
        }
        return true;
      case 2:
        this.handleSaveSettings();
        return true;
      default:
        return false;
    }
  };
  render() {
    const { activeStep, docker_mapping_setting } = this.state;
    // if (!docker_mapping_setting || docker_mapping_setting === undefined) {
    //   return (
    //     <Box sx={{ width: '100%' }}>
    //       Create a Docker Image with its settings before adding a mapping to the
    //       inputs and outputs
    //       <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
    //         <Box sx={{ flex: '1 1 auto' }} />
    //         <Button onClick={this.props.handleClose}>Close</Button>
    //       </Box>
    //     </Box>
    //   );
    // }
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={`${label}-${index}`} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment>
          {this._renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={this.handleBack}
              sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={this.props.handleClose}>Close</Button>
            <Button onClick={this.handleNext}>
              {activeStep === steps.length - 1
                ? docker_mapping_setting === null
                  ? 'Create'
                  : 'Save'
                : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  handleClose: PropTypes.func,
};
HorizontalLinearStepper.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
};

export default HorizontalLinearStepper;
