import React, { useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import {
  arrayToWorksheet,
  cellContainsFunction,
  getWorksheetContent,
  getOperands,
  updateAllCellsRecursively,
} from './utils_data';
import { UpdateExcelFile } from 'graphql/Files';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';

import * as XLSX from 'xlsx';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderColor: '#757575',
          color: '#757575',
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

function Sheet(props) {
  const { idFileObject, activeWorksheet } = props;
  const [workbook, setWorkbook] = useState(props.workbook);
  const [worksheet, setWorksheet] = useState([]);
  const [activeCellValue, setActiveCellValue] = useState('');
  const [activeCellAddress, setActiveCellAddress] = useState();

  useEffect(() => {
    const matrix = getWorksheetContent(props.workbook, activeWorksheet);
    if (
      typeof workbook.Sheets[workbook.SheetNames[activeWorksheet]] ===
      'undefined'
    ) {
      const ws = XLSX.utils.aoa_to_sheet(matrix);
      XLSX.utils.book_append_sheet(workbook, ws, `Sheet${activeWorksheet + 1}`);
    }
    setWorksheet(matrix);
  }, [activeWorksheet]);

  const updateCellStates = coordinate => {
    const cellValue = worksheet[coordinate.row][coordinate.column];
    const cellAddress = XLSX.utils.encode_cell({
      c: coordinate.column,
      r: coordinate.row,
    });

    setActiveCellAddress(cellAddress);

    let cellValueOrFunction;
    const cellValueShadow = +cellValue.result;
    if (
      typeof cellValueShadow === 'number' &&
      cellValueShadow === cellValue.value
    ) {
      cellValueOrFunction = cellValue.value;
      setActiveCellValue(cellValue.value);
      // eslint-disable-next-line quotes
    } else if (cellValue.result.includes("'")) {
      cellValueOrFunction = cellValue.value;
      setActiveCellValue(cellValue.result);
    } else if (cellContainsFunction(cellValue.result)) {
      cellValueOrFunction = '=' + cellValue.result;
      setActiveCellValue('=' + cellValue.result);
    } else {
      cellValueOrFunction = '';
    }
    return {
      cellAddress,
      cellValueOrFunction,
    };
  };

  const handleCellFocus = coordinate => {
    updateCellStates(coordinate);
  };

  const handleInputChange = event => {
    let inputValue = event.target.value;
    setActiveCellValue(inputValue);
  };

  // handles when the input that shows the current cell value or result
  const handleChangeSelected = () => {
    const coordinates = XLSX.utils.decode_cell(activeCellAddress);
    const params = {
      coordinates: coordinates,
      cellValue: activeCellValue,
      sheet: worksheet,
      cellAddress: activeCellAddress,
      references: [],
    };
    if (activeCellValue.charAt(0) === '=') {
      const cellValue = getOperands(worksheet, activeCellValue.substring(1));
      let worksheetCopy = worksheet.map((row, i) => {
        if (coordinates.r != i) return row;
        else {
          return row.map((col, j) => {
            if (coordinates.c != j) return col;
            else {
              return {
                value: parseFloat(cellValue),
                result: activeCellValue.substring(1),
              };
            }
          });
        }
      });
      params.sheet = worksheetCopy;

      const outcome = updateAllCellsRecursively(params);
      setWorksheet(outcome[outcome.length - 1]);
      const cellValueType = 'n';
      const updatedWorkbook = arrayToWorksheet(
        outcome[outcome.length - 1],
        props.workbook,
        activeCellAddress,
        coordinates,
        cellValueType,
        activeWorksheet
      );
      setWorkbook(updatedWorkbook);
      updateWorksheet(updatedWorkbook);
    }
  };

  const handleChange = sheet => {
    const params = {
      coordinates: XLSX.utils.decode_cell(activeCellAddress),
      cellValue: activeCellValue,
      sheet,
      cellAddress: activeCellAddress,
      references: [],
    };
    let cellValueType = 's';
    let worksheetCopy = sheet.map((row, i) => {
      if (params.coordinates.r != i) return row;
      else {
        return row.map((col, j) => {
          if (params.coordinates.c != j) return col;
          else {
            if (Number.isNaN(+col.value) && !cellContainsFunction(col.value)) {
              return { value: col.value, result: `'${col.value}` };
            } else if (cellContainsFunction(col.value)) {
              cellValueType = 'n';
              return { value: col.value, result: `${col.value}` };
            } else {
              cellValueType = 'n';
              return { value: parseFloat(col.value), result: `${col.value}` };
            }
          }
        });
      }
    });
    params.sheet = worksheetCopy;

    const outcome = updateAllCellsRecursively(params);
    let updatedWorkbook;
    if (typeof outcome !== 'undefined') {
      setWorksheet(outcome[outcome.length - 1]);
      updatedWorkbook = arrayToWorksheet(
        outcome[outcome.length - 1],
        props.workbook,
        activeCellAddress,
        params.coordinates,
        cellValueType,
        activeWorksheet
      );
    } else {
      updatedWorkbook = arrayToWorksheet(
        worksheetCopy,
        props.workbook,
        activeCellAddress,
        params.coordinates,
        cellValueType,
        activeWorksheet
      );
    }
    setWorkbook(updatedWorkbook);
    updateWorksheet(updatedWorkbook);
  };

  const updateWorksheet = workbook => {
    (async () => {
      UpdateExcelFile(idFileObject, JSON.stringify(workbook))
        .then(res => {
          console.log('  *\n ***\n*****\n Response \n', { res });
        })
        .catch(error => {
          console.log('  *\n ***\n*****\n Response \n', { error });
        });
    })();
  };

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="row"
      justifyContent="flex-start">
      <Grid item xs={1}>
        <Box
          sx={{
            display: 'flex',
            '& > :not(style)': {
              width: '100%',
              height: 33,
            },
          }}>
          <Paper square variant="outlined">
            <Chip
              label={activeCellAddress}
              sx={{ borderRadius: 0, border: 0, height: 33 }}
              variant="outlined"
            />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={11}>
        <Box
          sx={{
            display: 'flex',
            '& > :not(style)': {
              width: '100%',
              height: 33,
            },
          }}>
          <Paper square variant="outlined">
            <ThemeProvider theme={theme}>
              <ButtonGroup
                aria-label="small button group"
                size="small"
                sx={{
                  height: 33,
                  borderRight: '1px solid #eeeeee',
                  borderRadius: 0,
                }}>
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ p: '5px', color: 'red' }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="menu" sx={{ p: '10px' }}>
                  <DoneIcon fontSize="small" />
                </IconButton>
              </ButtonGroup>
              <TextField
                id="standard-basic"
                onBlur={handleChangeSelected}
                onChange={handleInputChange}
                size="small"
                sx={{
                  ml: 1,
                  flex: 1,
                  '& .MuiInputBase-root': {
                    height: 40,
                  },
                }}
                value={activeCellValue}
                variant="standard"
              />
            </ThemeProvider>
          </Paper>
        </Box>
      </Grid>
      <Grid item mt={1} xs={12}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Spreadsheet
            data={worksheet}
            onActivate={handleCellFocus}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Sheet;
