import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import { Grid, Autocomplete, TextField } from '@mui/material';
import clsx from 'clsx';

const useStyles = () => ({
  margin: {
    // margin: theme.spacing(1),
    width: 'calc(100% - 250px)',
  },
  textField: {
    width: 'calc(100% - 250px)',
  },
});

class TabCustomField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueDescription: '',
    };
  }

  handleChange = (event, newValue) => {
    event.preventDefault();
    this.setState({ valueDescription: newValue });
  };
  handleExecuteSearch = event => {
    if (!event) {
      return;
    }
    const { key } = event;
    if (key === 'Enter') {
      this.props.executeSearch('', 'CustomFiled', event);
    }
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const { valueDescription } = this.state;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start">
        <Autocomplete
          getOptionLabel={option => {
            if (option) return option;
            return '';
          }}
          options={['Study', 'Series']}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label="DICOM Tag"
              variant="outlined"
            />
          )}
          size="small"
          style={{ width: 150, height: 10 }}
        />
        <TextField
          className={clsx(classes.margin, classes.textField)}
          id="textfield-custome-field"
          label="Custom Field"
          onChange={this.handleDescriptionChange}
          onKeyPress={this.handleExecuteSearch}
          size="small"
          value={valueDescription}
          variant="outlined"
        />
      </Grid>
    );
  }
}

TabCustomField.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(TabCustomField, useStyles);
