import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tabs, Tab, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { TabPanel } from 'components';
import {
  TabLandingObjects,
  TabOrphans,
  TabProcessFolders,
  TabSessions,
} from './components';
import { getParams } from 'helpers';
import { useStylesAdministrator } from 'views/Administrator/components/AdminDefault/useStyles';
class TabSystemElements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { name: 'landing_objects', TabComponent: TabLandingObjects },
        { name: 'orphans', TabComponent: TabOrphans },
        { name: 'process_folders', TabComponent: TabProcessFolders },
        { name: 'user_sessions', TabComponent: TabSessions },
      ],
      error: '',
    };
    this.holder = {
      getSelected: null,
      clean: null,
    };
  }
  componentDidMount() {
    // this.updateOrphans();
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    const { tabs } = this.state;
    if (newValue >= 0 && newValue < tabs.length) {
      history.push(`/administrator/system_elements/${tabs[newValue].name}`);
    } else {
      history.push('/administrator/system_elements/landing_objects');
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, user, height } = this.props;
    const { tabs } = this.state;
    const { level_2 } = getParams(this.props);
    const index = tabs.map(x => x.name).indexOf(level_2);
    let tabValue = 0;
    if (index !== -1) {
      tabValue = index;
    }
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            className={classes.tabs_small}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              const { name } = tab;
              return (
                <Tab
                  className={classes.tab_small_trans}
                  key={`key-tab-${index}-${name}`}
                  label={name.replace('_', ' ')}
                />
              );
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%', height: height - 185 }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { name, TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`key-tab-system-elements-panel-${index}-${name}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    height={height - 8}
                    isActive={index === tabValue}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TabSystemElements.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(
  withRouter(TabSystemElements),
  useStylesAdministrator
);
