import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_INVESTIGATIVED_SIDE = gql`
  query($name: String, $page: Int, $limit: Int) {
    InvestigatedSides(name: $name, page: $page, limit: $limit) {
      idSide
      name
      createdAt
      updatedAt
    }
  }
`;
export const QueryRunInvestigatedSide = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_INVESTIGATIVED_SIDE),
    variables: {
      name,
      page,
      limit,
    },
  });
};
