import { IsInvalid } from 'helpers/utils';

export const GenerateDictionary = metadata => {
  let dictionary = [];
  if (!metadata || metadata === undefined) return dictionary;
  console.log(
    '\n-----------------------------------------------------------\n'
  );
  try {
    const parts = metadata.split('}},{"tag":{');
    for (let i = 0; i < parts.length; i++) {
      let text = parts[i];
      if (i === 0) {
        text = text.replace('{"tags":[{"tag":{', '');
      }
      let first_part = text.split(',"Value":');
      if (first_part.length !== 2) continue;
      const tag_meta = first_part[1].split(',"Tag":');
      if (tag_meta.length !== 2) continue;
      const name = first_part[0];
      let Name = name.replace('"Name":', '').trim();
      Name = Name.replace(/"/g, '').trim();
      let Value = tag_meta[0].trim();
      Value = Value.replace(/"/g, '').trim();
      const Tag = tag_meta[1]
        .replace(/"/g, '')
        .trim()
        .replace(')}}]}', '');
      if (IsInvalid(Name)) continue;
      if (IsInvalid(Value)) continue;
      const tag = {
        Name,
        Value,
        Tag,
      };
      dictionary.push({ tag });
    }
  } catch (error) {
    console.log(error);
  }
  //   console.log({ dictionary });
  console.log(`GenerateDictionary - Decode with ${dictionary.length} TAGS`);
  console.log(
    '-----------------------------------------------------------\n\n'
  );
  return dictionary;
};
