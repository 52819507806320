export const useMenuStyle = {
  menu: {
    position: 'relative',
    borderRadius: '5px',
    background: '#ffffff',
    border: '1px solid #dedede',
    boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.15)',
    paddingTop: 5,
    padding: 2,
    '&::after, &::before': {
      bottom: '100%',
      left: '74%',
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
      borderBottomColor: '#dedede',
      pointerEvents: 'none',
    },
    '&::after': {
      borderColor: 'rgba(255, 255, 255, 0)',
      borderBottomColor: '#ffffff',
      borderWidth: '7px',
      marginLeft: '-7px',
    },
    '&::before': {
      borderColor: 'rgba(200, 200, 200, 0)',
      borderBottomColor: '#dedede',
      borderWidth: '8px',
      marginLeft: '-8px',
    },
  },
  dropdownItem: {
    // ...defaultFont,
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: 'fit-content',
    color: '#333',
    whiteSpace: 'nowrap',
    minHeight: 'unset',
  },
};
