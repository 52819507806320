import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

class StyleText extends Component {
  render() {
    const { fontSize, fontWeight, m, spacing, variant, text } = this.props;
    const settings = {};
    let style = null;
    if (this.props.style) {
      style = {};
      if (fontSize) style.fontSize = fontSize;
      if (fontWeight) style.fontWeight = fontWeight;
    } else {
      if (fontSize) settings.fontSize = fontSize;
      if (fontWeight) settings.fontWeight = fontWeight;
    }
    if (m) settings.m = m;
    if (spacing) settings.spacing = spacing;
    if (variant) settings.variant = variant;
    if (this.props.styleBox) {
      style = { ...style, ...this.props.styleBox };
    }
    return (
      <Typography component="div">
        <Box {...settings} style={style}>
          {text}
        </Box>
      </Typography>
    );
  }
}

StyleText.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
StyleText.defaultProps = {
  text: '',
  fontSize: null,
  fontWeight: 'fontWeightRegular',
  m: 1,
  spacing: null,
  variant: null,
  style: false,
};
export default StyleText;
