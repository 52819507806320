import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const DELETE_STUDY_BY_ID = gql`
  mutation($idStudy: LongLong!) {
    DeleteStudy(idStudy: $idStudy) {
      ok
      errors {
        path
        message
      }
      FilesDeleted
    }
  }
`;
export const DeleteStudyMutation = idStudy => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_STUDY_BY_ID),
    variables: {
      idStudy,
    },
  });
};

const MUTATION_UPDATE_STUDIES_COUNT = gql`
  mutation($ids: [LongLong!]!) {
    updateStudyCounts(ids: $ids) {
      ok
      errors {
        path
        message
      }
      studies {
        idStudy
        NumberOfStudyRelatedInstances
        NumberOfStudyRelatedSeries
      }
    }
  }
`;
export const MutationUpdateStudyCounts = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_STUDIES_COUNT),
    variables: {
      ids,
    },
  });
};
