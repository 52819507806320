import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import FormTextFields from '../FormTextFields';

class StudyResearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Typography component="h4" variant="h6">
          Study Research Form
        </Typography>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
        <FormTextFields
          form={this.props.research_study_form}
          handleChangeForm={this.props.handleUpdateStudyResearchForm}
        />
      </Box>
    );
  }
}

StudyResearchForm.propTypes = {
  handleClose: PropTypes.func,
};
StudyResearchForm.defaultProps = {
  handleClose: () => '',
};

export default StudyResearchForm;
