import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
} from 'components';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class TabSequenceClassificationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, changed } = this.props;
    return (
      // <React.Fragment>
      //   <ButtonCircularIconLoad
      //     disabled={loading}
      //     handleButtonClick={this.props.handleSave}
      //     loading={loading}
      //     small
      //   />
      // </React.Fragment>
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pr={2}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.button_group}
              exclusive="false"
              size="small"
              value="save">
              <Button disabled={!changed} onClick={this.props.handleSave}>
                <SaveIcon style={{ color: changed ? 'green' : 'gray' }} />
              </Button>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <ButtonDownloadType
                disabled
                idProject={this.props.idProject}
                key="anonymization"
                type="anonymization_tags"
              />
              <ButtonFileUpload
                disabled
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              // handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              // loading={this.state.deleting}
              loading_table={this.props.loading}
              // numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

TabSequenceClassificationBar.propTypes = {
  classes: PropTypes.object,
};
TabSequenceClassificationBar.defaultProps = {
  handleSave: () => '',
  uploading: false,
  rows_edited: [],
};

export default withStyles(TabSequenceClassificationBar, useStyles);
