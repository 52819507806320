/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { QueryDicomImage } from 'graphql/Dicom';
import { GetRawData } from 'helpers';
import { DicomSlice } from 'helpers/Dicom';
import { v1 as uuid } from 'uuid';
import ViewerWebGL2D from '../ViewerWebGL2D';
import ToolBarViewer from '../ToolBarViewer';

class CanvasWebGL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dicom_slice: null,
      idSeries: null,
      slice_position: null,
      volume: [],
      progress: 0,
    };
    this.holder = {
      setMouseAction: null,
      setViewerAction: null,
    };
  }
  componentDidMount() {
    let { idSeries, slice_position } = this.props;
    if (idSeries !== this.state.idSeries) {
      // console.log({ idSeries, slice_position });
      if (slice_position < 0) slice_position = 0;
      this.loadSlice(idSeries, slice_position);
    }
  }
  componentDidUpdate() {
    let { idSeries, slice_position } = this.props;
    if (idSeries !== this.state.idSeries) {
      // console.log({ idSeries, slice_position });
      if (slice_position < 0) slice_position = 0;
      this.loadSlice(idSeries, slice_position);
    }
  }
  handleUpdatePostion = slice_position => {
    if (this.state.progress !== 100) return;
    this.loadSlice(this.state.idSeries, slice_position);
  };
  loadSlice = (idSeries, slice_position) => {
    let { loading, volume } = this.state;
    const { series } = this.props;
    if (slice_position < 0) return;
    const { NumberOfSeriesRelatedInstances } = series;
    if (slice_position >= NumberOfSeriesRelatedInstances) return;
    if (idSeries !== this.state.idSeries) {
      volume = [];
    } else if (this.state.progress === 100) {
      const index = volume.map(x => x.position).indexOf(slice_position);
      if (index !== -1) {
        this.setState({
          loading: false,
          dicom_slice: volume[index].dicom,
          idSeries,
          slice_position,
        });
        return;
      }
    }
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryDicomImage(idSeries, slice_position)
        .then(res => {
          const data = GetRawData(res);
          const { pixelData } = data;
          if (pixelData) {
            const dicom_slice = DicomSlice.fromData(data, idSeries);
            console.log({
              slice_position,
              InstanceNumber: dicom_slice.InstanceNumber,
              width: dicom_slice.width,
              height: dicom_slice.height,
              dicom_slice,
            });

            const index = volume.map(x => x.position).indexOf(slice_position);
            console.log({ index, slice_position });
            let delta = 1;
            if (index === -1) {
              volume.push({ position: slice_position, dicom: dicom_slice });
            } else {
              delta = 2;
            }
            this.state.loading = false;
            this.state.volume = volume;
            this.setState({
              dicom_slice,
              idSeries,
              slice_position,
            });
            if (volume.length < NumberOfSeriesRelatedInstances) {
              let next_positons = volume.length + delta;
              let progress = Math.floor(
                (next_positons * 100) / NumberOfSeriesRelatedInstances
              );
              console.log({ progress, next_positons });
              this.setState({ progress });
              this.loadSlice(idSeries, next_positons);
            } else {
              this.setState({ progress: 100 });
            }
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(e => {
          console.log({ e });
          this.setState({ loading: false });
        });
    })();
  };
  handleViewerAction = action => {
    this.holder.setMouseAction(action);
  };
  handleImageAction = action => {
    this.holder.setViewerAction(action);
  };
  render() {
    const { dicom_slice } = this.state;
    const { width, series } = this.props;
    let height = 0;
    if (window.orientation !== undefined) {
      // if mobile
      height = document.documentElement.clientHeight - 100;
    } else {
      height = window.innerHeight - 100;
    }
    let final_width = width - 5;
    let max_position = 0;
    if (series) {
      max_position = series.NumberOfSeriesRelatedInstances;
    }
    // console.log({ loading, slice_position });
    const viewer_height = height - 50;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="flex-start"
        style={{
          paddingTop: 0,
          display: 'relative',
          margin: 0,
          width,
          height: '100%',
        }}>
        <Grid item sx={{ maxHeight: 50 }} xs={12}>
          <ToolBarViewer
            handleImageAction={this.handleImageAction}
            handleViewerAction={this.handleViewerAction}
          />
        </Grid>
        <Grid
          item
          style={{
            width: final_width,
            height: viewer_height,
            paddingLeft: 0,
            marginLeft: 10,
          }}
          xs={12}>
          <ViewerWebGL2D
            dicom_slice={dicom_slice}
            height={viewer_height}
            holder={this.holder}
            idViewer={uuid()}
            isDesktop={this.props.isDesktop}
            max_position={max_position}
            open={this.props.open}
            progress={this.state.progress}
            series={series}
            slice_position={this.state.slice_position}
            updatePosition={this.handleUpdatePostion}
            width={final_width - 10}
          />
        </Grid>
      </Grid>
    );
  }
}

CanvasWebGL.propTypes = {
  classes: PropTypes.object,
};

export default CanvasWebGL;
