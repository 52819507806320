export { default as ListProjectActivities } from './ListProjectActivities';
export const useStyles = theme => ({
  root: {
    height: '100%',
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  reload: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
  },
});
