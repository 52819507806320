import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { SearchInput, ButtonReload } from 'components';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import AddIcon from '@mui/icons-material/Add';
import { styleToolbar } from 'common';

class ToolBarUnitSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alignment: 'all' };
  }
  handleAlignment = (event, alignment) => {
    this.setState({ alignment });
  };
  render() {
    const { classes, disabled, view } = this.props;
    let ComponentSearchUsers = null;
    if (view === 'all') {
      ComponentSearchUsers = (
        <Grid item sx={{ paddingLeft: 5 }} xs={6}>
          <SearchInput
            onChange={this.props.handleChangeName}
            placeholder="Search unit"
            value={this.props.name}
          />
        </Grid>
      );
    }
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45 }}>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            sx={{ minHeight: 45 }}
            xs={9}>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.props.handleViews}
              size="small"
              value={this.props.view}>
              <ToggleButton aria-label="left aligned" value="block">
                <ViewDayIcon
                  style={{ color: view === 'block' ? 'green' : null }}
                />
              </ToggleButton>
              <ToggleButton aria-label="left aligned" value="list">
                <ViewListIcon
                  style={{ color: view === 'list' ? 'green' : null }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
            <ButtonGroup
              // className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                className={classes.button}
                color="primary"
                onClick={this.props.handleOpenCreateUnit}
                size="small"
                startIcon={<AddIcon />}
                style={{
                  marginLeft: 5,
                  backgroundColor: '#e0e0e0',
                  color: 'white',
                }}
                variant="contained">
                Add new unit
              </Button>
            </ButtonGroup>

            {ComponentSearchUsers}
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={1}>
            <ButtonReload
              // className={classes.margin}
              handleReload={this.props.handleReload}
              loading={this.props.loading}
              small
              tooltip="Reload details"
              wrapper={{ margin: 0, maxWidth: 50 }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ToolBarUnitSummary.propTypes = {
  classes: PropTypes.object,
};
ToolBarUnitSummary.defautlProps = {
  loading: false,
  name: '',
  handleChangeName: () => '',
  handleOpenCreateUnit: () => '',
  handleReload: () => '',
};
export default withStyles(ToolBarUnitSummary, styleToolbar);
