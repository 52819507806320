import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio } from '@mui/material';

class DateTimeLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const sylte_radio = {
      size: 'small',
      style: {
        margin: 0,
        padding: 0,
        paddingTop: 5,
      },
    };
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="center"
        spacing={0}>
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Any Date"
          value="any_date"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 30 min"
          value="last_30_min"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last hour"
          value="last_hour"
        />
        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 2 hours"
          value="last_2_hours"
        />

        <FormControlLabel
          className={classes.radio}
          control={<Radio {...sylte_radio} />}
          label="Last 6 hours"
          value="last_6_hours"
        />
      </Grid>
    );
  }
}

DateTimeLeft.propTypes = {
  classes: PropTypes.object,
};

export default DateTimeLeft;
