import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { ExplorerPictures, ExplorerSideBar, ExplorerImage } from './components';
import { useStyles } from './components/ExplorerSideBar/useStyles';
import { SnackMessage } from 'components';
import { rowsPerPageBig } from 'helpers';
import { connect } from 'react-redux';
import { setDashboardPageDesigner } from 'redux-store/actions/page_settings';

class DashboardSurgery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_drawer: false,
      error: '',
      project: null,
      total: 0,
      filters: [],
    };
    this.holder = {
      setProject: null,
      setPageLimit: null,
      setFilters: null,
    };
  }
  componentDidUpdate() {
    // const { idType, view_type } = this.props;
    // console.log({ idType, view_type });
  }
  handleDrawerClose = open_drawer => {
    console.log({ open_drawer });
    this.setState({ open_drawer });
  };
  handleSelectProject = project => {
    this.setState({ project });
    if (this.holder.setProject) {
      this.holder.setProject(project);
    }
  };
  handleSelectFilters = filters => {
    this.setState({ filters });
    if (this.holder.setFilters) {
      this.holder.setFilters(filters.map(x => x.id));
    }
  };
  handleChangePage = (event, page) => {
    event.preventDefault();
    const limit = this.props.dashboard_view.limit_designer;
    this.props.setDashboardPageDesigner(page, limit);
    this.holder.setPageLimit(page, limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    let limit = +event.target.value;
    this.props.setDashboardPageDesigner(0, limit);
    this.holder.setPageLimit(0, limit);
  };
  handleTotalChanged = total => {
    this.setState({ total });
  };
  render() {
    const {
      classes,
      height,
      isMobileSmall,
      isMobile,
      idType,
      view_type,
    } = this.props;
    const { error } = this.state;
    if (idType && view_type) {
      return (
        <React.Fragment>
          <ExplorerImage
            handleAddError={error => this.setState({ error })}
            height={height}
            idType={idType}
            view_type={view_type}
          />
          <SnackMessage
            handleClose={() => this.setState({ error: '' })}
            message_text={error !== '' ? error : 'Unknown warning'}
            open={error && error !== '' ? true : false}
            type="error"
          />
        </React.Fragment>
      );
    }
    const { open_drawer } = this.state;
    const { dashboard_view } = this.props;
    const page_designer = dashboard_view.page_designer;
    const limit_designer = dashboard_view.limit_designer;
    return (
      <React.Fragment>
        <Grid
          alignItems="stretch"
          container
          direction="row"
          justifyContent="flex-start">
          <ExplorerSideBar
            handleDrawerClose={this.handleDrawerClose}
            handleSelectFilters={this.handleSelectFilters}
            handleSelectProject={this.handleSelectProject}
            height={height}
            isMobile={isMobile}
            isMobileSmall={isMobileSmall}
            open_drawer={open_drawer}
            project={this.state.project}
          />
          <Grid
            className={clsx(classes.content, {
              [classes.contentShift]: isMobileSmall ? false : open_drawer,
            })}
            item>
            <ExplorerPictures
              filters={this.state.filters}
              handleDrawerClose={this.handleDrawerClose}
              handleTotalChanged={this.handleTotalChanged}
              height={height}
              holder={this.holder}
              isMobile={isMobile}
              isMobileSmall={isMobileSmall}
              limit={limit_designer}
              open_drawer={open_drawer}
              project={this.state.project}
            />
          </Grid>
        </Grid>
        <TablePagination
          className={classes.tablepagination}
          component="div"
          count={this.state.total}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page_designer}
          rowsPerPage={limit_designer}
          rowsPerPageOptions={rowsPerPageBig}
          sx={{ margin: 0, padding: 0, backgroundColor: '#e0e0e0', height: 50 }}
        />
        <SnackMessage
          handleClose={() => this.setState({ error: '' })}
          message_text={error !== '' ? error : 'Unknown warning'}
          open={error && error !== '' ? true : false}
          type="error"
        />
      </React.Fragment>
    );
  }
}

DashboardSurgery.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { dashboard_view } = state.page_settings;
  const { limit_designer } = dashboard_view;
  if (limit_designer === undefined || !limit_designer) {
    dashboard_view.page_designer = 0;
    dashboard_view.limit_designer = 10;
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, {
  setDashboardPageDesigner,
})(withStyles(DashboardSurgery, useStyles));
