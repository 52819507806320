import React from 'react';
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';
// import * as html2pdf from 'html2pdf.js';
import { green, red } from '@mui/material/colors';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 3,
    left: 8,
    zIndex: 1,
  },
});
class ButtonDownloadPDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }
  downloadPdfDocument = () => {
    // const scale = window.devicePixelRatio;
    // const input = document.getElementById(this.props.rootElementId);

    this.props.setPdfRequested(true);
    this.setState({ downloading: true });
    // var opt = {
    //   margin: [20, 15, 20, 15],
    //   filename: `${this.props.downloadFileName}.pdf`,
    //   image: { type: 'jpeg', quality: 0.95 },
    //   jsPDF: {
    //     unit: 'mm',
    //     format: 'a4',
    //     orientation: 'portrait',
    //     putTotalPages: true,
    //   },
    //   html2canvas: { scale: 2, useCORS: true, dpi: 192, letterRendering: true },
    // };
    // html2pdf()
    //   .from(input)
    //   .set(opt)
    //   .save()
    //   .then(() => {
    //     this.props.setPdfRequested(false);
    //     this.setState({ downloading: false });
    //   });
  };
  render() {
    const { classes } = this.props;
    let { downloading } = this.state;
    let icon = (
      <PictureAsPdfIcon
        style={{
          color: red[500],
        }}
      />
    );
    return (
      <>
        <Button
          disabled={this.props.disabled || downloading}
          onClick={this.downloadPdfDocument}
          style={{ margin: 0, padding: 0 }}>
          {icon}
          {downloading && (
            <CircularProgress className={classes.fabProgress} size={25} />
          )}
        </Button>
      </>
    );
  }
}

ButtonDownloadPDF.propTypes = {
  classes: PropTypes.object,
};
ButtonDownloadPDF.defaultProps = {
  disabled: false,
  handleDownloadTriggered: () => '',
};

export default withStyles(ButtonDownloadPDF, useStyles);
