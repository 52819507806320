import axios from 'axios';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const MutationUploadSleepCSV = (
  file,
  MUTATION,
  handleProgress = null
) => {
  const query = { file };
  if (!handleProgress) {
    handleProgress = () => '';
  }
  var formData = new FormData();
  const operations = JSON.stringify({
    query: print(MUTATION),
    variables: query,
  });
  formData.append('operations', operations);
  const map = {
    '0': ['variables.file'],
  };
  formData.append('map', JSON.stringify(map));
  formData.append('0', file);

  return axios.post(HTTP_ADDRESS_GRAPHQL, formData, {
    onUploadProgress: event => handleProgress(event),
  });
};
