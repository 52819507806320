import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { CommunicationMessages, NoSelection } from './components';

const useStyles = () => ({
  content: {
    width: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
    // padding: theme.spacing(3)
  },
});

class CommunicationsRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  IsSelected = () => {
    const { selected } = this.props;
    if (typeof selected === 'undefined' || !selected) return false;
    const { direct, channel } = selected;
    if (direct) return true;
    if (channel) return true;
    return false;
  };

  render() {
    const {
      classes,
      selected,
      isMobile,
      height,
      user,
      handleDrawerToggle,
    } = this.props;
    return (
      <div className={classes.content} style={{ height }}>
        {this.IsSelected() ? (
          <CommunicationMessages
            handleAddLastDirect={this.props.handleAddLastDirect}
            handleDrawerToggle={handleDrawerToggle}
            handleOnlineUser={this.props.handleOnlineUser}
            height={height}
            isMobile={isMobile}
            selected={selected}
            user={user}
          />
        ) : (
          <NoSelection
            handleDrawerToggle={handleDrawerToggle}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  }
}

CommunicationsRight.propTypes = {
  classes: PropTypes.object,
};
CommunicationsRight.defaultProps = {
  handleOnlineUser: () => '',
  handleAddLastDirect: () => '',
};
export default withStyles(withRouter(CommunicationsRight), useStyles);
