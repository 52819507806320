import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import {
  GetApp as DownloadIcon,
  NotInterested as CopyIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { green } from '@mui/material/colors';
import { cleanCopySeries } from 'redux-store/actions/file_folder_manager';
import { GetData, GetResponseError, ServerErrorsString } from 'helpers';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';
import { IsNormalUser } from 'common';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingLeft: 10,
  },
  icon: {
    // margin: 0,
    // padding: 0,
    width: 48,
    height: 40,
    marginLeft: 'auto',
    marginRight: 0,
  },
  badge: {
    '& .MuiBadge-badge': {
      right: 25,
      top: 22,
      // border: `2px solid ${theme.palette.background.paper}`,
      // padding: '0 4px',
    },
    '&.MuiBadge-root': {
      top: -3,
      right: -5,
    },
  },
  buttonProgress: {
    color: green[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -17,
    marginLeft: -15,
    zIndex: 1000,
  },
});
const shareSelected = {
  color: 'error',
  children: <ShareIcon />,
};
const downloadSelected = {
  color: 'primary',
  children: <DownloadIcon />,
};
const copySelected = {
  color: 'error',
  children: <CopyIcon />,
};

class ToogleSelectedActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total_download: 0,
    };
  }

  handleViewType = (event, value) => {
    if (value === 'clean') {
      this.props.cleanCopySeries();
    } else if (value === 'download') {
      this.handleDownload();
    } else if (value === 'shared') {
      this.props.handleOpenShared();
    }
    // this.props.setExplorerView(value);
  };
  handleProgress = event => {
    const { loading, total_download } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = total_download;
    }
    this.props.handleProgress({ loaded, total }, loading);
  };
  handleDownload = () => {
    const { copy_series } = this.props;
    const seriesIds = copy_series.map(x => ({ idSeries: x.idSeries }));
    if (seriesIds.length === 0) {
      return;
    }
    const total_download =
      copy_series.map(x => x.Size).reduce((a, b) => a + b, 0) * 0.3;
    this.setState({ loading: true, total_download });
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(seriesIds);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            loading: false,
            error: ServerErrorsString(server_error),
          });
          return;
        }
      } catch (error) {
        this.setState({
          loading: false,
          error: ServerErrorsString(error),
        });
        return;
      }
      DownloadItemsAtKey(download_key, this.handleProgress)
        .then(async response => {
          let { SeriesDescription } = copy_series[0];
          if (!SeriesDescription || copy_series.length > 1) {
            SeriesDescription = 'download_series';
          }
          this.handleProgress({ loaded: 0 });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${SeriesDescription}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ loading: false });
          await MutationDownloadFinished(download_key);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };

  render() {
    const { classes, size } = this.props;
    if (!size) return null;
    const { loading } = this.state;
    const size_load = 33;
    let ButtonsShareDownload = null;
    if (IsNormalUser(this.props.user)) {
      ButtonsShareDownload = (
        <React.Fragment>
          <ToggleButton
            aria-label="left aligned"
            className={classes.icon}
            disabled={loading}
            value="shared">
            <Badge
              badgeContent={size}
              className={classes.badge}
              {...shareSelected}
            />
          </ToggleButton>
          <ToggleButton
            aria-label="centered"
            className={classes.icon}
            disabled={loading}
            value="download">
            <Badge
              badgeContent={size}
              className={classes.badge}
              {...downloadSelected}
            />
            {loading && (
              <CircularProgress
                className={classes.buttonProgress}
                size={size_load}
              />
            )}
          </ToggleButton>
        </React.Fragment>
      );
    }
    return (
      <ToggleButtonGroup
        aria-label="toggle_buttons_view"
        className={classes.root}
        exclusive
        onChange={this.handleViewType}
        size="small"
        value={-1}>
        {ButtonsShareDownload}
        <ToggleButton
          aria-label="centered"
          className={classes.icon}
          disabled={loading}
          value="clean">
          <Badge
            badgeContent={size}
            className={classes.badge}
            {...copySelected}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

ToogleSelectedActions.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { cleanCopySeries })(
  withStyles(ToogleSelectedActions, useStyles)
);
