import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import { ToolbarDownloads, columns } from './components';
import { rowsPerPageBig } from 'helpers';
import { QueryDownloads } from 'graphql/Download';
import { GetData, ServerErrorsString } from 'helpers';
class TabDownloads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_clicked: null,
      loading: false,
      rows_edited: [],
      selected: [],
      downloads: [],
      page: 0,
      limit: 100,
      total: 0,
      init: false,
    };
  }
  componentDidMount() {
    this.handleReloadDownloads();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleReloadDownloads();
  }
  handleReloadDownloads = (name, new_page, new_limit) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryDownloads(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, downloads, total } = data.allDownloads;
          if (ok) {
            this.setState({
              loading: false,
              downloads,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { downloads, loading } = this.state;
    const { height } = this.props;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-end">
        <Grid item xs={12}>
          <Paper
            style={{
              margin: 2,
              marginTop: 3,
              paddingTop: 3,
              paddingLeft: 2,
            }}>
            <ToolbarDownloads
              downloads={this.state.downloads}
              handleAddError={this.props.handleAddError}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleReload={name => this.handleReloadDownloads(name)}
              loading={this.state.loading}
              row_clicked={this.state.row_clicked}
              rows_edited={this.state.rows_edited}
              selected={this.state.selected}
              setState={state => this.setState(state)}
              total={this.state.total}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            grid
            headers={columns}
            height={height - 80}
            idRow="idDownload"
            loading={loading}
            onPageChange={page =>
              this.handleReloadDownloads(null, page, this.state.limit)
            }
            onRowClicked={row_clicked => this.setState({ row_clicked })}
            onRowEditedChanged={rows_edited => {
              this.setState({ rows_edited });
            }}
            onRowsPerPageChange={limit =>
              this.handleReloadDownloads(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={downloads}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabDownloads.propTypes = {
  classes: PropTypes.object,
};

export default TabDownloads;
