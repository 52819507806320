import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { PdfService } from 'helpers/Surveys';
import { CardHeaderCreate } from '../CreateNewSurvey/components';
import { QuerySurvey } from 'graphql/Surveys';
import moment from 'moment';
import { GetData, FormatTime, IsInvalid } from 'helpers';

class ShowSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSurvey: null,
      detailSurvey: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  componentDidUpdate() {
    this.handleQuery();
  }
  handleQuery = () => {
    const { edit_survey } = this.props;
    const { loading, currentSurvey } = this.state;
    if (loading || currentSurvey) return;
    if (currentSurvey && edit_survey.id === currentSurvey.id) return;
    this.setState({ loading: true });
    (async () => {
      QuerySurvey(edit_survey.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, survey } = data.surveyRecorded;
          if (ok) {
            if (survey.survey_raw) {
              this.setState({
                loading: false,
                currentSurvey: survey,
                detailSurvey: JSON.parse(survey.survey_raw),
              });
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  onClickGeneratePdf = data => {
    // console.log({ data });
    const data2 = data.filter(value => {
      return value.displayValue != '';
    });
    const filteredData = data2.map(row => [
      row.name,
      row.title,
      row.displayValue,
    ]);
    /* Currently hardcoded patient information
        TODO: needs to pe passed from patient/survey object */
    const title = 'CRF from Medical-Blocks; Since 12.10.2022';
    const headers = [['var_name', 'Question', 'Response']];
    PdfService.generatePdf(filteredData, headers, title);
  };
  render() {
    const { detailSurvey, loading } = this.state;
    const createdAt = moment().valueOf();
    let { height } = this.props;
    let style = null;
    if (height) {
      height = height - 120;
      style = {
        height,
        maxHeight: height,
        overflow: 'auto',
      };
    }
    // console.log({ detailSurvey });
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <CardHeaderCreate
              description={'Updated: ' + FormatTime(createdAt, 'MMM Do, YYYY')}
              handleClose={this.props.onClickBackToOverview}
              title="Show Survey"
            />
          </Paper>
        </Grid>
        <Divider />
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          xs={12}>
          <Grid item xs={3}>
            <Button
              onClick={() => this.onClickGeneratePdf(detailSurvey)}
              style={{ backgroundColor: 'green' }}
              variant="contained">
              Export as pdf
            </Button>
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={3}>
            <CSVLink
              data={detailSurvey.map(x => {
                // eslint-disable-next-line no-unused-vars
                let { displayValue, isNode, data, ...res } = x;
                if (IsInvalid(displayValue) || displayValue === '') {
                  displayValue = 'No';
                }
                return { ...res, Answered: displayValue };
              })}>
              <Button style={{ backgroundColor: 'green' }} variant="contained">
                Export as csv
              </Button>
            </CSVLink>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} style={style}>
            <Table aria-label="a dense table" size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Question</TableCell>
                  <TableCell>Answered</TableCell>
                  <TableCell>Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow sx={{ height: '100%' }}>
                    <TableCell
                      align="center"
                      colSpan={4}
                      sx={{ height: '100%' }}>
                      <CircularProgress disableShrink />
                    </TableCell>
                  </TableRow>
                ) : (
                  detailSurvey.map((row, index) => {
                    let { value } = row;
                    if (IsInvalid(value)) value = '';
                    return (
                      <TableRow
                        key={`table-row-show-${index}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.displayValue}</TableCell>
                        <TableCell>{String(value)}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

ShowSurvey.propTypes = {
  classes: PropTypes.object,
};

export default ShowSurvey;
