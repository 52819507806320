import { FormatBytes, FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_files = [
  {
    field: 'idFileObject',
    headerName: 'ID',
    width: 75,
    align: 'left',
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    align: 'left',
    minWidth: 170,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'Size');
      if (IsInvalid(value)) return '';
      let { FullName } = value;
      if (IsInvalid(FullName)) return 'Name Missing';
      return FullName;
    },
  },
  {
    field: 'locations',
    headerName: 'Locations',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'Size');
      if (IsInvalid(value)) return '';
      if (!Array.isArray(value)) return '';
      let names = value
        .map(x => x.Folder)
        .filter(x => x)
        .map(x => x.name);
      return names.join(', ');
    },
  },
  {
    field: 'original_name',
    headerName: 'Original Name',
    align: 'left',
    minWidth: 170,
  },
  {
    field: 'mimetype',
    headerName: 'Type',
  },
  {
    field: 'Size',
    headerName: 'Size',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'Size');
      if (IsInvalid(value)) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'filepath',
    headerName: 'FilePath',
    minWidth: 550,
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
