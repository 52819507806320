import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControl,
  Select,
  Input,
  MenuItem,
} from '@mui/material';
import { ServerErrors } from 'helpers';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { QueryBackendSummarySequences } from 'graphql/Series/sequences/index.js';
import { ButtonReload } from 'components';
import { FormatTime } from 'helpers';
import moment from 'moment';
import { IsInvalid } from 'helpers';
import { GetData } from 'helpers';
import { SequencesChartBars } from './components';

const useStyles = theme => ({
  root: {
    height: '100%',
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  selectedOption: {
    color: 'rgb(63, 81, 181)',
    fontWeight: '500',
  },
});

const emptyData = {
  labels: [
    'IR',
    'T2',
    'T1',
    'T2 HR',
    'VIBE PHASE',
    'DWI',
    'MSME',
    'DESS',
    'Other',
  ],
  datasets: [
    {
      label: 'Monthly Series',
      backgroundColor: 'rgba(0,0,255,0.6)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(0,0,255,0.3)',
      hoverBorderColor: 'rgba(0,0,255,1)',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

const options = [
  { value: 1, label: 'Today' },
  { value: 2, label: 'Yesterday' },
  { value: 7, label: 'Last 7 days' },
  { value: 30, label: 'Last 30 days' },
  { value: 100, label: 'Full history' },
];

function LatestSeriesSequences(props) {
  const [loading, setLoading] = useState(false);
  const [timeframe, setTimeframe] = useState(options[4].value);
  const [data, setData] = useState(emptyData);
  const [error, setError] = useState('');
  const [title, setTitle] = useState([]);

  const dashboard_view = useSelector(
    state => state.page_settings.dashboard_view,
    shallowEqual
  );

  const { visualization_mode, fullHistory } = dashboard_view;

  let datestart,
    dateend = '';
  if (!fullHistory) {
    datestart = dashboard_view.datestart;
    dateend = dashboard_view.dateend;
  }
  let idProject = 0;
  if (dashboard_view.selected_project) {
    idProject = dashboard_view.selected_project.id;
  }

  let dateInterval = '';
  if (!fullHistory) {
    if (IsInvalid(datestart)) {
      datestart = moment().valueOf();
    }
    if (IsInvalid(dateend)) {
      dateend = moment().valueOf();
    }
    const startDate = FormatTime(datestart, 'DD/MM/YYYY');
    const endtDate = FormatTime(dateend, 'DD/MM/YYYY');
    dateInterval = `${startDate} - ${endtDate}`;
  }
  const { classes } = props;

  useEffect(() => {
    handleReloadQuery();
  }, [data]);

  const handleChange = event => {
    event.preventDefault();
    const selectedTimeframe = event.target.value;

    if (timeframe !== selectedTimeframe) {
      setLoading(true);
      setTimeframe(selectedTimeframe);
      handleReloadQuery();
    }
  };

  const handleReloadQuery = () => {
    setLoading(true);
    (async () => {
      try {
        const res = await QueryBackendSummarySequences(
          timeframe,
          visualization_mode,
          idProject,
          dateend,
          datestart,
          fullHistory
        );
        const data_res = GetData(res);
        const SummarySequences = data_res.SummarySequences;
        if (SummarySequences) {
          const new_data = getData(SummarySequences);
          setData(new_data);
          setTitle(getSubTitles(data));
          setLoading(false);
          return;
        }
      } catch (error) {
        setLoading(false);
        handleNewError(error);
        return;
      }
    })();
  };

  const getData = SummarySequences => {
    const dataEmpty = emptyData;
    let total = SummarySequences.length;
    // if (total >= 10) {
    //   total = 10;
    // }
    dataEmpty.labels = SummarySequences.map(x => x.Sequence.name);
    dataEmpty.datasets[0].data = Array(total).fill(0, 0);
    for (let i = 0; i < total; i++) {
      const objc = SummarySequences[i];
      dataEmpty.datasets[0].data[i] = objc.total;
    }
    return dataEmpty;
  };

  const handleNewError = net_error => {
    if (!net_error) return;
    const errors = ServerErrors(net_error);
    console.log({ errors });
    if (error === errors.join('\n')) {
      return;
    }
    setError(errors.join('\n'));
  };

  const getSubTitles = data => {
    const elements = [];
    for (let i = 0; i < data.datasets[0].data.length; i++) {
      elements.push({
        title: data.labels[i],
        value: `${data.datasets[0].data[i]}`,
        color: data.datasets[0].backgroundColor[i],
      });
    }
    return elements;
  };

  let ComponentSelectRange = null;
  if (fullHistory) {
    ComponentSelectRange = (
      <Grid item xs={8}>
        <FormControl>
          <Select
            disableUnderline
            id="demo-simple-select"
            input={<Input className={classes.selectedOption} />}
            labelId="demo-simple-select-label"
            onChange={handleChange}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'rgb(63, 81, 181)',
              },
            }}
            value={timeframe}>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  } else {
    ComponentSelectRange = (
      <Grid
        item
        sx={{ fontSize: 13, fontWeight: 500, textAlign: 'left' }}
        xs={fullHistory ? 8 : 9}>
        {fullHistory ? 'Full history' : dateInterval}
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader
        action={
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            minWidth={150}
            spacing={1}>
            {ComponentSelectRange}
            <Grid item sx={{ paddingLeft: 0 }} xs={fullHistory ? 4 : 3}>
              <ButtonReload
                handleReload={handleReloadQuery}
                loading={loading}
                small
                tooltip="Reload Project Activity"
              />
            </Grid>
          </Grid>
        }
        className={classes.header}
        title="Latest Image Sequences"
      />

      <Divider />
      <CardContent className={classes.root}>
        <SequencesChartBars data={data} titles={title} />
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          onClick={() => {
            props.history.push(
              '/management/mapping/sequence_series mapping/summary'
            );
          }}
          size="small"
          variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
}

LatestSeriesSequences.propTypes = {
  className: PropTypes.string,
};
export default withStyles(withRouter(LatestSeriesSequences), useStyles);
