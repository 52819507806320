import gql from 'graphql-tag';
import { MutationUploadSingleFile } from 'common';

const MUTATION_UPLOAD_PROJECT_CENTERS_CSV = gql`
  mutation($file: Upload!) {
    uploadNewProjectCenters(file: $file) {
      ok
      errors {
        path
        message
      }
      project_centers {
        id
        idCenter
        idProject
        code
        center {
          id
          idAdmin
          admin {
            id
            FullName
          }
          name
          address {
            city
            country
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
    }
  }
`;
export const MutationUploadProjectCenterCSV = (file, handleProgress = null) => {
  return MutationUploadSingleFile(
    file,
    MUTATION_UPLOAD_PROJECT_CENTERS_CSV,
    handleProgress
  );
};
