export { default as TableRowQueryResultSeries } from './TableRowQueryResultSeries';
export { default as TableRowQueryResult } from './TableRowQueryResult';
export { default as ToolBarQueryRetrieve } from './ToolBarQueryRetrieve/ToolBarQueryRetrieve';

export const headers = [
  { name: 'ID' },
  { name: 'PatientName' },
  { name: '#Files', align: 'right' },
  { name: 'PatientID', align: 'left' },
  { name: 'BirthDate', align: 'right', sx: { minWidth: 100 } },
  { name: 'Description', align: 'left' },
  { name: 'AccessionNumber' },
  { name: 'Modality', align: 'right' },
  { name: 'Date', align: 'left' },
  { name: 'Time', align: 'left', sx: { minWidth: 80 } },
  { name: 'StudyInstanceUID' },
];
