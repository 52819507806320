import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { useStyles } from './useStyles';

class DivCorner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      bottom,
      right,
      line_top,
      line_middel,
      line_bottom,
    } = this.props;
    let box = classes.top_left;
    let text = null;
    if (bottom && right) {
      box = classes.bottom_right;
      text = classes.text_right;
    } else if (bottom && !right) {
      box = classes.bottom_left;
      text = null;
    } else if (!bottom && right) {
      box = classes.top_right;
      text = null;
    }
    return (
      <div className={clsx(classes.box, box)}>
        <div className={clsx(classes.text)}>{line_top}</div>
        <div className={clsx(classes.text, text)}>{line_middel}</div>
        <div className={clsx(classes.text, text)}>{line_bottom}</div>
      </div>
    );
  }
}

DivCorner.propTypes = {
  bottom: PropTypes.bool,
  classes: PropTypes.object,
  line_bottom: PropTypes.string,
  line_middel: PropTypes.string,
  line_top: PropTypes.string,
  right: PropTypes.bool,
};
DivCorner.defaultProps = {
  bottom: false,
  right: false,
  line_top: '',
  line_middel: '',
  line_bottom: '',
};

export default withStyles(DivCorner, useStyles);
