import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { MutationDockerContainerAction } from 'graphql/Dockers/utils_containers';
import { GetData, ServerErrorsString } from 'helpers';
import { styleToolbar } from 'common';
class ToolBarContainerActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleOnClickButton = action => {
    const { selected } = this.props;
    if (!selected || selected === undefined || !selected.length) {
      this.props.handleAddError('You need to select a container first');
      return;
    }
    const ids = selected.map(x => x.idContainer);
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationDockerContainerAction(action, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, actions } = data.dockerContainerAction;
          if (ok) {
            this.setState({ loading: false });
            this.props.handleActionResults(actions);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, selected } = this.props;
    const disabled = !selected.length;
    return (
      <Paper className={classes.paper}>
        <div>
          <ButtonGroup
            disabled={disabled}
            disableElevation
            exclusive="false"
            variant="contained">
            <Button
              className={classes.button}
              color="primary"
              onClick={() => this.handleOnClickButton('start')}
              size="small"
              startIcon={<PlayArrowIcon />}
              style={{ backgroundColor: disabled ? '#e0e0e0' : 'green' }}
              variant="contained">
              Start
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => this.handleOnClickButton('stop')}
              size="small"
              startIcon={<StopIcon />}
              style={{ backgroundColor: disabled ? '#e0e0e0' : 'red' }}
              variant="contained">
              Stop
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => this.handleOnClickButton('kill')}
              size="small"
              startIcon={<ClearIcon />}
              style={{ backgroundColor: disabled ? '#e0e0e0' : 'red' }}
              variant="contained">
              Kill
            </Button>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => this.handleOnClickButton('restart')}
              size="small"
              startIcon={<AutorenewIcon />}
              variant="contained">
              Restart
            </Button>
          </ButtonGroup>
        </div>
      </Paper>
    );
  }
}

ToolBarContainerActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarContainerActions.defaultProps = {
  selected: [],
};

export default withStyles(ToolBarContainerActions, styleToolbar);
