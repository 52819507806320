import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import HomeIcon from '@mui/icons-material/Home';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setProjectSubfolders } from 'redux-store/actions';

import { StyledBreadcrumb } from 'components';
import { getLevels } from 'helpers';

const useStyles = theme => ({
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
});

class SubFoldersToolBar extends React.Component {
  constructor(props) {
    super(props);
    if (props.holder) {
      props.holder.addFolder = folder => this.handleClickFolder(folder);
      props.holder.clearSubFolders = () => {
        if (this.props.length) {
          this.props.setProjectSubfolders([]);
        }
      };
    }
  }

  componentDidMount() {
    this.checkSubFolders();
  }

  componentDidUpdate() {
    this.checkSubFolders();
  }

  checkSubFolders = () => {
    const { level_2, level_3 } = getLevels(this.props);
    if (level_2 === 'folder' && level_3 !== undefined) {
      /* empty */
    } else if (this.props.length) {
      this.props.setProjectSubfolders([]);
    }
  };
  handleClickFolder = folder => {
    let { subfolders, length } = this.props;
    const index = subfolders.map(x => x.idFolder).indexOf(folder.idFolder);
    if (index === -1) {
      subfolders.push(folder);
    } else {
      if (index === -1 || index + 1 >= length) {
        return;
      }
      subfolders.splice(index + 1, length);
      //
      const { project } = this.props;
      if (project) {
        this.props.holder.backFolder(project.id, folder.idFolder);
      }
    }
    this.props.setProjectSubfolders(subfolders);
  };
  handleBackRootFolder = () => {
    const { project } = this.props;
    if (!project) return;
    this.props.setProjectSubfolders([]);
    this.props.holder.backFolder(project.id);
  };

  render() {
    const { classes, subfolders, project } = this.props;
    if (!project) {
      return null;
    }
    const {
      ProjectFolder: { name },
    } = project;
    return (
      <Toolbar
        className={classes.toolbarSecondary}
        component="nav"
        variant="dense">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            // href="#"
            icon={<HomeIcon fontSize="small" />}
            label={name}
            onClick={this.handleBackRootFolder}
          />
          {subfolders.map(folder => {
            //
            return (
              <StyledBreadcrumb
                component="a"
                key={`folder_${folder.idFolder}`}
                label={folder.name}
                onClick={() => this.handleClickFolder(folder)}
              />
            );
          })}
        </Breadcrumbs>
      </Toolbar>
    );
  }
}

SubFoldersToolBar.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const length = state.page_project_view.subfolders.length;
  return { subfolders: state.page_project_view.subfolders, length };
};

export default connect(mapStateToProps, {
  setProjectSubfolders,
})(withStyles(withRouter(SubFoldersToolBar), useStyles));
