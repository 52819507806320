const stylesForMessage = theme => ({
  avatar: { maxWidth: 32, maxHeight: 32, marginRight: 5 },
  userName: {
    display: 'inline-block',
    marginRight: 5,
  },
  timestamp: { display: 'inline-block' },
  root: {
    transform: 'scaleY(-1)',
    width: '80%',
    alignItems: 'right',
  },
  root_right: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root_left: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  paper: {
    padding: '2px',
    textAlign: 'left',
  },
  text_content_left: {
    backgroundColor: '#cfd8dc',
    borderRadius: '5px',
    padding: '10px',
  },
  text_content_right: {
    backgroundColor: 'blue',
    borderRadius: '5px',
    padding: '10px',
  },
  header_white: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  header_black: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  message_white: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  message_black: {
    color: theme.palette.common.black,
    fontWeight: 400,
  },
});

export default stylesForMessage;
