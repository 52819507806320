import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { columns_clinics } from './utils';

class TabPatientClinics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      empty: false,
      loading: false,
      patients: [],
      selected: [],
      total: 0,
      limit: 50,
    };
    const { holder } = props;
    if (holder) {
      holder.reloadClinics = name => this.handleLoadClinic(name);
      holder.reloadClinicsClean = () => this.setState({ name: '' });
    }
  }
  handleLoadClinic = () => {};
  render() {
    const { height } = this.props;
    return (
      <div>
        <TableGrid
          checkboxSelection
          dense
          // disableSelectionOnClick
          external_selected={this.state.selected}
          headers={columns_clinics}
          height={height - 260}
          idRow="idPatient"
          loading={this.state.loading}
          onPageChange={newPage => this.handlePageChange(newPage)}
          onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.patients}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabPatientClinics.propTypes = {
  classes: PropTypes.object,
};

export default TabPatientClinics;
