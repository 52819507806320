import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, Divider } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import { CardButtonActions } from 'components/Cards/components';
import { GetData, ServerErrorsString } from 'helpers';
import useStyles from '../Styles';
import { CardUnitHeader } from './components';
import { MutationUpdateSummary, MutationUpdateUnit } from 'graphql/Units';
import { UnitDescription } from '../index';

class UnitEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setting_changed: false,
      unit_changed: false,
      unit_modify: {
        idUnit: -1,
        name: '',
        description: '',
        institution: '',
        api: '',
      },
      loading_update: false,
    };
    this.holder = {
      getSetting: null,
    };
  }

  setUnit = () => {
    const { unit } = this.props;
    const { unit_modify } = this.state;
    if (unit && unit.idUnit !== unit_modify.idUnit) {
      const { idUnit, name, description, institution } = unit;
      this.setState({
        unit_modify: { idUnit, name, description, institution },
      });
    }
  };

  componentDidMount() {
    this.setUnit();
  }

  componentDidUpdate() {
    this.setUnit();
  }

  runUpdateSave = () => {
    const { loading } = this.state;
    if (loading) return;
    const { idUnit } = this.props.unit;
    this.setState({ loading: true });
    (async () => {
      let { idUnit: id, ...res_unit } = this.state.unit_modify;
      if (id === -1) {
        res_unit = null;
      }
      const setting = this.holder.getSetting();
      MutationUpdateUnit(idUnit, res_unit, setting)
        .then(res => {
          const data = GetData(res);
          const {
            UpdateUnit: { ok, errors },
          } = data;
          if (ok) {
            this.setState({
              loading: false,
              setting_changed: false,
              unit_changed: false,
            });
            if (setting || res_unit) {
              let new_unit = {
                ...this.props.unit,
                ...setting,
              };
              if (res_unit) {
                new_unit = {
                  ...new_unit,
                  ...res_unit,
                };
              }
              this.props.setUnit(new_unit);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handelChange = setting_changed => {
    this.setState({ setting_changed });
  };
  handelChangeText = event => {
    let { unit_modify } = this.state;
    unit_modify = {
      ...unit_modify,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    this.setState({
      unit_modify,
      unit_changed: this.IsUnitChanged(unit_modify),
    });
  };
  handleReNameUnit = name => {
    let { unit_modify } = this.state;
    unit_modify = {
      ...unit_modify,
      name,
    };
    this.setState({
      unit_modify,
      unit_changed: this.IsUnitChanged(unit_modify),
    });
  };
  IsUnitChanged = N => {
    if (typeof N === 'undefined') {
      N = this.state.unit_modify;
    }
    const { unit: O } = this.props;
    if (O.name !== N.name) return true;
    if (O.description !== N.description) return true;
    if (O.institution !== N.institution) return true;
    if (O.api !== N.api) return true;
    return false;
  };
  runUpdateSummary = () => {
    const { loading_update } = this.state;
    if (loading_update) return;
    this.setState({ loading_update: true });
    (async () => {
      MutationUpdateSummary()
        .then(res => {
          const data = GetData(res);
          const {
            updateSummary: { ok, errors },
          } = data;
          //
          if (ok) {
            this.setState({ loading_update: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_update: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, unit, user, handleAddError } = this.props;
    const {
      loading,
      unit_changed,
      setting_changed,
      loading_update,
    } = this.state;
    if (!unit) return null;
    let admin = false;
    if (typeof user !== 'undefined') {
      admin = user.id === unit.Admin.id;
    }
    const { name, description, institution, api } = this.state.unit_modify;
    //
    const { updatedAt, createdAt } = unit;
    return (
      <Card className={classes.root}>
        <CardUnitHeader
          createdAt={createdAt}
          handleMaximize={() => this.props.handleMaximize('sites')}
          handleReNameUnit={this.handleReNameUnit}
          title={name}
          updatedAt={updatedAt}
        />
        <Divider variant="middle" />
        <CardContent className={classes.content}>
          <UnitDescription
            admin={admin}
            api={api}
            description={description}
            handelChange={this.handelChange}
            handelChangeText={this.handelChangeText}
            handleAddError={handleAddError}
            holder={this.holder}
            institution={institution}
            unit={unit}
          />
        </CardContent>
        <Divider variant="middle" />
        <CardButtonActions
          changed={setting_changed || unit_changed}
          changed_left={!loading}
          handleLeftClick={this.runUpdateSummary}
          handleSaveClicked={() => this.runUpdateSave()}
          icon_left={<UpdateIcon />}
          left
          loading={loading}
          loading_left={loading_update}
          tooltip_left="Update Summary"
        />
      </Card>
    );
  }
}

UnitEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
UnitEdit.defaultProps = {};

export default withStyles(UnitEdit, useStyles);
