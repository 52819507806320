import gql from 'graphql-tag';
import { MutationUploadInputs } from 'common';
const MUTATION_UPLOAD_CENTERS = gql`
  mutation($file: Upload!, $page: Int, $limit: Int) {
    uploadNewCenters(file: $file, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      centers {
        id
        idAdmin
        idAddress
        admin {
          id
          FullName
        }
        address {
          id
          country
          city
        }
        name

        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationUploadCenterCSV = (
  file,
  page = null,
  limit = null,
  handleProgress = null
) => {
  const query = { file };
  if (page !== undefined && limit !== undefined) {
    query.page = page;
    query.limit = limit;
  }
  return MutationUploadInputs(query, MUTATION_UPLOAD_CENTERS, handleProgress);
};
