export { default as DialogShowColumns } from './DialogShowColumns';
export const useStyles = () => ({
  root: {
    'user- select': 'none' /* supported by Chrome and Opera */,
    '-webkit-user-select': 'none' /* Safari */,
    '-khtml-user-select': 'none' /* Konqueror HTML */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
  },
  table: {
    whiteSpace: 'nowrap',
  },
  parent: {
    // backgroundColor: 'dodgerBlue',
    padding: 0,
    // padding: '1em',
    // paddingLeft: 0,
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginLeft: 0,
    marginRight: 0,
  },
  child: {
    // backgroundColor: 'tomato',
    // padding: '1em',
    padding: 0,
    maring: 0,
    // marginLeft: '0.5em',
    // marginRight: '0.5em',
    color: 'white',
    width: '100%',
  },
});
export const keydown = event => {
  if (
    ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(event.code) > -1
  ) {
    event.preventDefault();
  }
};
