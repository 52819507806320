import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, CardActions } from '@mui/material';
import { EditBottom } from './components';
import { CardEditHeader, GroupBox, TableGrid } from 'components';
import { EditSelectedType } from './components';
import { columns_series } from 'components/Tables/TableSeriesVariableMaps/components';
import { rowsPerPageSmall } from 'helpers';

class EditSeriesVariableMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      selected: null,
      to_edit: null,
    };
  }
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };
  handleSelectedRow = selected => {
    this.setState({ selected });
  };
  handleUpdateToEdit = to_edit => {
    this.setState({ to_edit });
  };
  handleUpdatedMapping = selected => {
    this.setState({ selected });
    this.props.handleUpdatedMapping(selected);
  };
  render() {
    const { selected } = this.state;
    return (
      <Card>
        <CardEditHeader
          handleClose={this.props.handleCloseEdit}
          title="Edit Series Variable Mapping"
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <TableGrid
              // checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={this.state.rows_edited}
              external_selected={selected}
              grid
              headers={columns_series}
              height={this.props.height}
              limit={this.state.limit}
              loading={this.props.loading}
              onPageChange={page => this.setState({ page })}
              onRowClicked={row_highlight =>
                this.handleSelectedRow(row_highlight)
              }
              // onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              rows={this.props.mappings}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBox title_box="Click Series Mapping row to Edit">
            <EditSelectedType
              handleUpdateToEdit={this.handleUpdateToEdit}
              selected={selected}
            />
          </GroupBox>
        </CardContent>
        <CardActions disableSpacing>
          <EditBottom
            handleAddError={this.props.handleAddError}
            handleDisableAll={disable_all => this.setState({ disable_all })}
            handleUpdatedMapping={this.handleUpdatedMapping}
            original={this.state.selected}
            to_edit={this.state.to_edit}
          />
        </CardActions>
      </Card>
    );
  }
}

EditSeriesVariableMap.propTypes = {
  classes: PropTypes.object,
};

export default EditSeriesVariableMap;
