import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { Divider, Paper } from '@mui/material';

import {
  ButtonDeleteIcon,
  SearchUsersProjects,
  SnackMessage,
} from 'components';

const useStyles = theme => ({
  root: {
    padding: '2px 4px',
    paddingBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
  divider: {
    height: 28,
    margin: 4,
  },
});

class BarShareToUserProject extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected: null,
      error: '',
      loading: false,
    };
  }

  handleAdd = selected => {
    if (Object.keys(selected).length === 0) {
      this.setState({ error: 'Empty selection' });
    } else {
      this.props.handleAdd(selected);
    }
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const { classes, to_delete } = this.props;
    const { loading, error } = this.state;
    const total_delete = to_delete.length;
    return (
      <Paper className={classes.root}>
        <SearchUsersProjects
          download_button
          handleAddUserProject={this.handleAdd}
        />
        {total_delete !== 0 && (
          <React.Fragment>
            <Divider className={classes.divider} orientation="vertical" />
            <ButtonDeleteIcon
              loading={loading}
              onClick={this.props.handleDeleteSelected}
              selected={to_delete}
            />
          </React.Fragment>
        )}
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Paper>
    );
  }
}

BarShareToUserProject.defaultProps = {
  to_delete: [],
};
BarShareToUserProject.propTypes = {
  classes: PropTypes.object.isRequired,
  to_delete: PropTypes.array,
};

export default withStyles(BarShareToUserProject, useStyles);
