import { FormatBytes, FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_series_user = [
  {
    field: 'idSeries',
    headerName: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    align: 'left',
    minWidth: 170,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'Owner');
      if (IsInvalid(value)) return '';
      let { FullName } = value;
      if (IsInvalid(FullName)) return 'Name Missing';
      return FullName;
    },
  },
  {
    field: 'locations',
    headerName: 'Locations',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'locations');
      if (IsInvalid(value)) return '';
      if (!Array.isArray(value)) return '';
      let names = value
        .map(x => x.Folder)
        .filter(x => x)
        .map(x => x.name);
      return names.join(', ');
    },
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
  },
  {
    field: 'Modality',
    headerName: 'Modality',
  },
  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Dicoms',
  },
  {
    field: 'Size',
    headerName: 'Size',
    // align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'Size');
      if (IsInvalid(value)) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Update At',
    minWidth: 200,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    minWidth: 200,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
