import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_prophet_folders = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  // {
  //   field: 'patient',
  //   headerName: 'PatientID',
  //   edit: true,
  //   style: {
  //     width: 50,
  //     minWidth: 70,
  //   },
  //   align: 'left',
  //   valueGetter: params => {
  //     if (IsInvalid(params)) return '';
  //     if (params.getValue === undefined) return '';
  //     const value = params.getValue(params.id, 'patient');
  //     if (IsInvalid(value)) return '';
  //     return value.PatientID;
  //   },
  //   editerGetter: (row, value) => {
  //     row = {
  //       ...row,
  //       patient: {
  //         PatientID: value,
  //       },
  //     };
  //     return row;
  //   },
  // },
  {
    field: 'folder_name',
    headerName: 'Folder name',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'encryption_key',
    headerName: 'Encryption key',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  { field: 'status', headerName: 'Upload status', width: 75, align: 'left' },
  // {
  //   field: 'updatedAt',
  //   headerName: 'Updated At',
  //   align: 'left',
  //   width: 222,
  //   type: 'datetime',
  //   valueGetter: params => {
  //     if (IsInvalid(params)) return '';
  //     if (params.getValue === undefined) return '';
  //     const value = params.getValue(params.id, 'updatedAt');
  //     if (IsInvalid(value)) return '';
  //     return FormatTime(value, 'MMM Do - YYYY, h:mm a');
  //   },
  // },
  // {
  //   field: 'createdAt',
  //   headerName: 'Created At',
  //   align: 'left',
  //   width: 222,
  //   type: 'datetime',
  //   valueGetter: params => {
  //     if (IsInvalidParam(params)) return '';
  //     const value = params.getValue(params.id, 'createdAt');
  //     if (IsInvalid(value)) return '';
  //     return FormatTime(value, 'MMM Do - YYYY, h:mm a');
  //   },
  // },
];

export const columns_prophet_uploads = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'patient',
    headerName: 'PatientID',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) return '';
      return value.PatientID;
    },
    editerGetter: (row, value) => {
      row = {
        ...row,
        patient: {
          PatientID: value,
        },
      };
      return row;
    },
  },
  {
    field: 'folder_name',
    headerName: 'Folder name',
    width: 75,
    align: 'left',
  },
  {
    field: 'patient',
    headerName: 'Encryption key',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) return '';
      return value.key;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
