export { default as ToolBarAnonyRules } from './ToolBarAnonyRules';

export const columns = [
  {
    field: 'tag',
    headerName: 'TAG',
    width: 75,
    align: 'left',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
      maxWidth: 100,
    },
  },
  {
    field: 'tag_name',
    headerName: 'Tag Name',
    width: 75,
    align: 'left',
    // edit: true,
    style: {
      width: 50,
      minWidth: 70,
      // maxWidth: 100,
    },
  },
  {
    field: 'tag_rule',
    headerName: 'Rule',
    width: 75,
    align: 'left',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
      maxWidth: 100,
    },
  },
  {
    field: 'tag_value',
    headerName: 'Custome value',
    width: 75,
    align: 'left',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
      maxWidth: 100,
    },
  },
];
