import { FormatTime } from 'helpers';

export { default as TableContainersToolbar } from './TableContainersToolbar';
export { default as TableContainersMain } from './TableContainersMain';
export const useStyles = theme => ({
  root: {
    width: 'calc(100% - 16px)',
    height: '100%',
    margin: theme.spacing(1),
    padding: 0,
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
});
export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 10,
  },
  {
    id: 'State',
    label: 'State',
    minWidth: 100,
  },
  {
    id: 'Status',
    label: 'Up time',
    minWidth: 100,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 100,
    format: value => (value ? FormatTime(value, 'calendar') : ''),
  },
  {
    id: 'ip',
    label: 'IP',
    minWidth: 100,
  },
  {
    id: 'ports',
    label: 'Ports',
    minWidth: 100,
  },
];
