import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { FormatTime, getInitials } from 'helpers';
import { CardHeaderCustom, DialogChangeProjectAvatar } from 'components';

const useStyles = () => ({
  avatar: {
    margin: 0,
  },
});

class ProjectAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      createdAt: '',
      open: false,
      set: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getAvatar = () => this.state.avatar;
    }
    this.holder = {
      setAvatar: null,
    };
  }

  setFromProps = () => {
    const {
      project: { avatar, createdAt, name },
    } = this.props;
    if (typeof avatar === 'undefined') return;
    this.setState({ avatar, createdAt, name, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    const { set } = this.state;
    if (!set) {
      this.setFromProps();
    }
  }
  handleOpenAvatar = () => {
    this.holder.setAvatar(this.state.avatar);
    this.setState({ open: true });
  };
  handleSelectAvatar = new_avatar => {
    this.setState({ avatar: new_avatar, open: false });
    const {
      project: { avatar },
    } = this.props;
    if (typeof avatar === 'undefined') return;
    this.props.handleChange('avatar', avatar !== new_avatar);
  };

  render() {
    const { classes, admin } = this.props;
    const { avatar, open, name } = this.state;
    return (
      <React.Fragment>
        <CardHeaderCustom
          avatar={
            <Avatar
              aria-label="avatar-project"
              className={classes.avatar}
              onClick={() => {
                if (admin) this.handleOpenAvatar();
              }}
              src={avatar}>
              {getInitials(name)}
            </Avatar>
          }
          subheader={FormatTime(
            this.props.project.updatedAt,
            'MMM Do, YYYY (h:mm a)'
          )}
          title={'Project Description'}
        />
        <DialogChangeProjectAvatar
          avatar={avatar}
          handleCancel={() => this.setState({ open: false })}
          handleSelect={avatar => this.handleSelectAvatar(avatar)}
          holder={this.holder}
          open={open}
        />
      </React.Fragment>
    );
  }
}

ProjectAvatar.propTypes = {
  classes: PropTypes.object,
};
ProjectAvatar.defaultProps = {
  admin: false,
};

export default withStyles(ProjectAvatar, useStyles);
