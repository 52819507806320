import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import Badge from '@mui/material/Badge';

const BadgeUsers = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    right: 5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'lightGreen',
  },
}));
class ButtonFilterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleClick, open, filtered, ButtonIcon, color_icon } = this.props;
    let res = {};
    // console.log({ color_icon });
    if (color_icon) {
      res = { style: { color: color_icon } };
    }
    return (
      <Box
        sx={{
          // display: isMouseOver ? 'flex' : 'none',
          alignItems: 'center',
          textAlign: 'center',
          padding: 0,
          margin: 0,
          paddingLeft: 1,
          width: 20,
        }}>
        <Tooltip title={'Filters'}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, margin: 0, padding: 0 }}>
              <BadgeUsers badgeContent={filtered}>
                <ButtonIcon size="small" {...res} />
              </BadgeUsers>
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  }
}

ButtonFilterList.propTypes = {
  filtered: PropTypes.number,
};
ButtonFilterList.defaultProps = {
  ButtonIcon: FilterListIcon,
};
export default ButtonFilterList;
