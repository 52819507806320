import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';

import {
  addCollaborators,
  ErrorsList,
  GetData,
  getUserFullName,
  PaperComponent,
  QueryAllUsers,
} from 'helpers';
import { inputProps } from 'common';
// import { ErrorSharp } from '@mui/icons-material';

const useStyles = theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200
  },
});

class DialogAddCollaborators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_complete: false,
      options: [],
      emails: [],
      email: '',
      errors: [],
    };
  }

  componentDidUpdate() {
    const { me } = this.props;
    const { open_complete, options, email } = this.state;
    const loading = open_complete && options.length === 0;
    if (!loading) {
      return;
    }
    (async () => {
      let { users: input_users } = this.props;
      QueryAllUsers(email, null)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users } = data.allUsers;
          if (ok) {
            let emails = Object.keys(users)
              .map(key => {
                const user = users[key];
                if (input_users && input_users.length) {
                  input_users = input_users.filter(
                    e => e.email.toLowerCase() !== user.email.toLowerCase()
                  );
                }

                return {
                  id: user.id,
                  FullName: getUserFullName(user),
                  email: user.email,
                  avatar: user.avatar,
                  createdAt: `${parseInt(user.createdAt, 10)}`,
                  phone: user.phone,
                  address: {
                    city: user.city,
                    country: user.country,
                  },
                };
              })
              .filter(e => e.email !== me.email);
            if (input_users && input_users.length) {
              input_users = Object.keys(input_users).map((key, i) => {
                return {
                  ...input_users[key],
                  id: -(emails.length + i),
                  email: input_users[key].email,
                };
              });
              emails = emails.concat(input_users);
            }
            if (!emails || emails.length === 0) {
              this.setOptions([{ email: email }]);
            } else {
              this.setOptions(emails);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ options: [], errors: ['Server error'] });
        });
    })();
  }

  handleClose = () => {
    this.props.handleCloseDialog(false);
  };

  setOpen = status => {
    if (status) {
      this.setState({ open_complete: status });
    } else {
      this.setState({ open_complete: status, options: [] });
    }
  };
  setOptions = options => {
    this.setState({ options: options });
  };
  handleSelectUser = (event, newValue) => {
    event.preventDefault();

    this.setState({ emails: newValue, errors: [] });
  };
  handleChange = event => {
    const e_mail = event.target.value;
    const { options } = this.state;
    const mail = e_mail.slice(0, e_mail.length - 1);
    const found = options.map(e => e.email).indexOf(mail);
    if (found !== -1 && e_mail.length) {
      this.setState({
        email: e_mail,
        options: [{ email: e_mail }],
        errors: [],
      });
    } else {
      this.setState({ email: e_mail, options: [], errors: [] });
    }
  };
  handleAddCollaborators = () => {
    const { emails } = this.state;
    addCollaborators(emails)
      .then(res => {
        const data = GetData(res);
        const { addCollaborators } = data;
        if (!addCollaborators) {
          return;
        }
        const { ok, errors } = addCollaborators;
        if (ok) {
          this.props.handleCollaboratorsAdded();
        } else {
          this.setState({ email: '', errors: errors });
        }
      })
      .catch(error => {
        console.log({ error });
        this.setState({
          email: '',
          options: [],
          errors: [{ collaborators: 'Server error' }],
        });
      });
  };

  render() {
    const { open } = this.props;
    const { open_complete, options, errors } = this.state;
    const loading = open_complete && options.length === 0;
    // ErrorsList(errors, 'collaborators')
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={this.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Add Collaborators
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              To add a new collaborators, please fill all the necessary
              information.
            </DialogContentText>
            <Autocomplete
              getOptionLabel={option => {
                if (option) return option.email;
                return '';
              }}
              id="tags-standard"
              loading={loading}
              multiple
              onChange={this.handleSelectUser}
              onClose={() => {
                this.setOpen(false);
              }}
              onOpen={() => {
                this.setOpen(true);
              }}
              open={open_complete}
              options={options}
              renderInput={params => (
                <TextField
                  {...params}
                  error={errors.length !== 0}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    ...inputProps,
                  }}
                  label="E-mail"
                  onChange={this.handleChange}
                  placeholder="Start to write an email..."
                  variant="standard"
                />
              )}
            />
            {errors.length ? ErrorsList(errors, 'collaborators') : null}
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleAddCollaborators}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogAddCollaborators.propTypes = {
  className: PropTypes.string,
  me: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  collaborators: state.projects.users.collaborators,
  me: state.info_user.user,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(DialogAddCollaborators, useStyles));
