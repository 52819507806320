import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { ImageList, ImageListItem } from '@mui/material';
import { GalleryProjectItem } from './components';
import { getRowProjectObjects } from '../utils_tag_projectfiles';
import { SelectedArray } from 'helpers';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  paper: {
    height: 110,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
});

class FilesViewGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numSelected: 0,
      selected: [],
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.clearGallery = () => {
        this.setSelected([]);
      };
      holder.getSelectedGallery = () => {
        let selected = [];
        const { rows } = this.props;
        for (let i = 0; i < rows.length; i++) {
          if (this.isSelected(rows[i])) {
            selected.push(rows[i]);
          }
        }
        return selected;
      };
    }
  }

  setSelected = selected => {
    this.setState({ selected });
    this.props.handleSelected(selected.length);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('keydown', this.keyDown);
    // this.updateSeriesFiles();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('keydown', this.keyDown);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  getGridListCols = () => {
    let { width } = this.props;
    if (!width) {
      width = window.innerWidth;
    }
    const cols = parseInt(width / 90, 10);
    const { rows } = this.props;
    if (rows.length < cols) {
      return rows.length;
    }
    if (cols) {
      return cols;
    }
    return 1;
  };
  handleSelected = row => {
    if (!this.props.admin) return;
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.name}`)
      .indexOf(`${row.id}_${row.name}`);
    const newSelected = SelectedArray(selected, row, index);
    this.setSelected(newSelected);
  };
  isSelected = value => {
    if (!this.props.admin) return false;
    const row = getRowProjectObjects(value);
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.name}`)
      .indexOf(`${row.id}_${row.name}`);

    return index !== -1 ? true : false;
  };
  handleDoubleClick = row => {
    this.setSelected([]);
    const { folder } = row;
    if (folder) {
      this.props.handleOpenSubFolder(folder);
    }
  };

  render() {
    const { rows, height } = this.props;
    let emptyRows = 3 - rows.length;
    if (emptyRows < 1) {
      emptyRows = 0;
    }
    return (
      <ImageList
        cols={this.getGridListCols()}
        rowHeight={135}
        style={{
          height: height - 100, //window.innerHeight - 425,
          margin: '10px',
          overflowY: 'scroll',
        }}>
        {rows.map((row, index) => {
          const isItemSelected = this.isSelected(row);
          return (
            <ImageListItem key={index} style={{ width: 'auto' }}>
              <GalleryProjectItem
                handleDoubleClick={this.handleDoubleClick}
                handleSelected={this.handleSelected}
                isItemSelected={isItemSelected}
                key={`item-folder-grid-${row.id}`}
                row={row}
              />
            </ImageListItem>
          );
        })}
        {emptyRows > 0 && (
          <ImageListItem style={{ height: 110 * emptyRows - 100 }} />
        )}
      </ImageList>
    );
  }
}

FilesViewGallery.propTypes = {
  rows: PropTypes.array.isRequired,
};
FilesViewGallery.defaultProps = {
  rows: [],
};
export default withStyles(FilesViewGallery, useStyles);
