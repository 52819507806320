import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadInputs } from 'common';

const QUERY_SEQUENCE_MAPPING = gql`
  query($name: String, $page: Int, $limit: Int) {
    sequenceMappings(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSequence
        idCenter
        name
        center {
          id
          name
        }
        sequence {
          idSequence
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QuerySequenceMapping = (name, page, limit) => {
  const variables = {};
  if (name && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEQUENCE_MAPPING),
    variables,
  });
};

const MUTATION_UPLOAD_MAPPING = gql`
  mutation($file: Upload!, $page: Int, $limit: Int) {
    addFileSequenceMappings(file: $file, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      mappings {
        id
        idSequence
        idCenter
        name
        center {
          id
          name
        }
        sequence {
          idSequence
          name
        }
        updatedAt
        createdAt
      }
      total
    }
  }
`;
export const MutationUploadMappingFile = (
  file,
  page = null,
  limit = null,
  handleProgress = null
) => {
  const query = { file };
  if (page !== undefined && limit !== undefined) {
    query.page = page;
    query.limit = limit;
  }
  return MutationUploadInputs(query, MUTATION_UPLOAD_MAPPING, handleProgress);
};

const MUTATION_DELETE_SEQUENCE_MAPPING = gql`
  mutation($mappings: [LongLong!]!) {
    removeSequenceMappings(mappings: $mappings) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteSequenceMapping = mappings => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SEQUENCE_MAPPING),
    variables: {
      mappings,
    },
  });
};

const MUTATION_UPDATE_SEQUENCE_MAPPING = gql`
  mutation($mappings: [InSequenceMapping!]!) {
    updateSequenceMappings(mappings: $mappings) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateSequenceMapping = mappings => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SEQUENCE_MAPPING),
    variables: {
      mappings,
    },
  });
};

const QUERY_UNSORTED_SEQUENCE = gql`
  query($name: String, $page: Int, $limit: Int) {
    unsortedSequences(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        idImageMr
        idSeries
        user {
          id
          FullName
        }
        series {
          idSeries
          SeriesDescription
          ContrastBolusAgent
          Modality
          locations {
            idFolderContent
            idFolder
          }
          ImageMr {
            MRAcquisitionType
          }
        }
        SeriesDescription
        ProtocolName
        SequenceName
        updatedAt
        createdAt
        Thumbnail
      }
    }
  }
`;

export const QueryUnsortedSequence = (name, page, limit) => {
  const variables = {};
  if (name && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_UNSORTED_SEQUENCE),
    variables,
  });
};

const MUTATION_ADD_SERIES_SEQUENSE = gql`
  mutation($mappings: [InSequenceMapping!]!) {
    addSequencesMapping(mappings: $mappings) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idSequence
        idCenter
        name
        center {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationAddSequencesMapping = mappings => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SERIES_SEQUENSE),
    variables: {
      mappings,
    },
  });
};
