import React from 'react';
import PropTypes from 'prop-types';
import { CreateCenter, EditCenter, TabCentersHeader } from './components';
import { QueryCenters } from 'graphql/Units/utils_centers';
import { TableCenters } from 'components';
import { GetData, ServerErrors } from 'helpers';

class TabCentersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleting: false,
      centers: [],
      init: false,
      page: 0,
      limit: 100,
      total: 0,
      open: '',
      selected: [],
    };
    this.holder = {
      getSelected: () => this.state.selected,
      getSearchText: null,
    };
  }

  componentDidMount() {
    this.queryCenters();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryCenters();
  }
  queryCenters = (newName = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    if (!name && this.holder.getSearchText) {
      name = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QueryCenters(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { centers, ok, errors, total } = data.centers;
          if (ok) {
            //
            this.setState({
              loading: false,
              centers,
              init: true,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          let errors = ServerErrors(error);
          if (errors.length > 10) {
            const total = errors.length;
            errors = errors.splice(0, 10);
            errors.push(`There are ${total - 10} errors...`);
          }
          this.props.handleAddError(errors.join('\n'));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.queryCenters(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.queryCenters(null, 0, +event.target.value);
  };
  handleDeleteSelected = () => {};
  handleClose = () => {
    this.setState({ open: '' });
  };
  handleAddNewCenter = center => {
    const { centers } = this.state;
    const index = centers.map(x => x.id).indexOf(center.id);
    if (index === -1) {
      centers.push(center);
      this.setState({ centers, open: '' });
    } else {
      this.props.handleAddError('Center is already here');
    }
  };
  handleUpdatedCenter = center => {
    const { centers, selected } = this.state;
    let index = selected.map(x => x.id).indexOf(center.id);
    if (index !== -1) {
      selected[index] = center;
    }
    index = centers.map(x => x.id).indexOf(center.id);
    if (index !== -1) {
      centers[index] = center;
      this.setState({ centers, selected, open: '' });
    } else {
      this.props.handleAddError('Center is not here');
    }
  };
  handelAddNewCenters = newCenters => {
    const { centers } = this.state;
    for (let i = 0; i < newCenters.length; i++) {
      const { id } = newCenters[i];
      const index = centers.map(x => x.id).indexOf(id);
      if (index === -1) {
        centers.unshift(newCenters[i]);
      }
    }
    this.setState({ centers });
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };

  render() {
    const { open, selected } = this.state;
    if (open === 'create') {
      return (
        <CreateCenter
          handleAddError={this.props.handleAddError}
          handleAddNewCenter={this.handleAddNewCenter}
          handleClose={this.handleClose}
        />
      );
    } else if (open === 'edit') {
      return (
        <EditCenter
          handleAddError={this.props.handleAddError}
          handleClose={this.handleClose}
          handleUpdatedCenter={this.handleUpdatedCenter}
          selected={selected}
        />
      );
    }
    return (
      <div>
        <TabCentersHeader
          handelAddNewCenters={this.handelAddNewCenters}
          handleAddError={this.props.handleAddError}
          handleClearSelected={() => this.setState({ selected: [] })}
          handleDeleteSelected={this.handleDeleteSelected}
          handleOpenCreate={() => this.setState({ open: 'create' })}
          handleOpenEdit={() => this.setState({ open: 'edit' })}
          handleReload={() => this.queryCenters()}
          handleSearchCenter={text => this.queryCenters(text)}
          holder={this.holder}
          loading={this.state.loading}
          selected={selected}
        />
        <TableCenters
          deleting={this.state.deleting}
          dense
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSetSelected={this.handleSetSelected}
          height={this.props.height - 90}
          hide_toolbar
          holder={this.holder}
          limit={this.state.limit}
          loading={this.state.loading}
          nowrap
          page={this.state.page}
          rows={this.state.centers}
          selected={selected}
          total={this.state.total}
        />
      </div>
    );
  }
}

TabCentersView.propTypes = {
  classes: PropTypes.object,
};

export default TabCentersView;
