import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { QuerySummaryTeams } from 'graphql/Teams';

import { useStyles } from '../Styles';
import { InputText } from '../components';
import { GetData } from 'helpers';

class IntpuTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: {},
      name: '',
      loading: false,
      teams: [],
      open: false,
      length: 0,
    };
  }

  componentDidMount() {
    this.runSearchTeam('');
  }

  componentDidUpdate() {
    const { length, name } = this.state;
    if (length !== name.length) {
      this.runSearchTeam(name);
    }
  }

  handleSelectedTeamsChanged = selected => {
    if (!selected || typeof selected === 'undefined') return;
    this.props.onChange(selected);
    // this.setState({ user, length: user.name.length });
  };
  handleTeamNameChanged = name => {
    this.setState({ name });
    this.runSearchTeam(name);
  };
  runSearchTeam = name => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, length: name.length, users: [] });
    (async () => {
      QuerySummaryTeams(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { allTeams } = data;

          if (typeof allTeams !== 'undefined') {
            const { ok, errors, teams } = allTeams;
            if (ok) {
              this.setState({ teams, loading: false });
            } else {
              throw errors;
            }
          } else {
            throw Error('Unknown server error');
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ teams: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddTeams = () => {
    // this.setState({ name: '', users: [], length: 0 });
  };

  render() {
    const { teams, open, loading, email } = this.state;
    return (
      <InputText
        handleEnterPressed={this.handleAddTeams}
        handleOpen={this.handleOpen}
        handleSelectOptionChanged={this.handleSelectedTeamsChanged}
        handleTextChanged={this.handleTeamNameChanged}
        loading={loading}
        multiple
        open={open}
        options={teams}
        placeholder="Search Teams..."
        text={email}
      />
    );
  }
}

IntpuTeams.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};
IntpuTeams.defaultProps = {};

export default withStyles(IntpuTeams, useStyles);
