import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/Tabs';
import { TabDockerInputs, TabDockerOutputs } from './components';

class TabWidgetDockerInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  render() {
    const { tabValue } = this.state;
    const { disabled, settings, holder, uploading } = this.props;
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={(e, tabValue) => this.setState({ tabValue })}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            <Tab label="Inputs" />
            <Tab label="Outputs" />
          </Tabs>
          <Divider />
          <div style={{ padding: 4 }}>
            <TabPanel index={0} value={tabValue}>
              <TabDockerInputs
                defaultSetting={this.props.defaultSetting}
                disabled={disabled}
                handleAddInput={this.props.handleAddInput}
                handleChanged={this.props.handleChanged}
                handleRemoveInput={id => this.props.handleRemoveInput(id)}
                handleUpdateSetting={settings =>
                  this.props.handleUpdateSetting(settings)
                }
                holder={holder}
                settings={settings}
                uploading={uploading}
              />
            </TabPanel>
            <TabPanel index={1} value={tabValue}>
              <TabDockerOutputs
                defaultSetting={this.props.defaultSetting}
                disabled={disabled}
                handleAddOutput={this.props.handleAddOutput}
                handleChanged={this.props.handleChanged}
                handleRemoveOutput={id => this.props.handleRemoveOutput(id)}
                handleUpdateSetting={settings =>
                  this.props.handleUpdateSetting(settings)
                }
                holder={holder}
                settings={settings}
                uploading={uploading}
              />
            </TabPanel>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

TabWidgetDockerInputs.propTypes = {
  disabled: PropTypes.bool,
};

export default TabWidgetDockerInputs;
