import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import { DivGrow, IconButtonLoad } from 'components';
import { MutationUpdateSeriesTypes } from 'graphql/Mapping/utils_series_var_map';
import { GetData, ServerErrorsString } from 'helpers';

class EditBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
    };
  }
  handleUpdateSeriesMapping = () => {
    const { to_edit } = this.props;
    const { updating } = this.state;
    if (updating || !to_edit) return;
    const { type } = to_edit;
    if (!type) return;
    this.setState({ updating: true });
    this.props.handleDisableAll(true);
    (async () => {
      MutationUpdateSeriesTypes(to_edit.id, type.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateSeriesTypes;
          if (ok) {
            this.setState({ updating: false });
            this.props.handleUpdatedMapping(to_edit);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.handleDisableAll(false);
        });
    })();
  };
  render() {
    const { original, to_edit } = this.props;
    const { updating } = this.state;
    let changed = false;
    if (original && to_edit) {
      if (to_edit.type && to_edit.type.id !== original.type.id) {
        changed = true;
      }
    }
    return (
      <React.Fragment>
        <DivGrow />
        <IconButtonLoad
          disabled={!changed || updating}
          handleClick={this.handleUpdateSeriesMapping}
          icon={<SaveIcon color={changed ? 'primary' : 'disabled'} />}
          loading={updating}
          tooltip="Update Mapping"
        />
      </React.Fragment>
    );
  }
}

EditBottom.propTypes = {
  classes: PropTypes.object,
};
EditBottom.defaultProps = {
  handleError: () => '',
  handleDisableAll: () => '',
  handleUpdatedMapping: () => '',
};

export default EditBottom;
