import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const DELETE_SERIES_BY_ID = gql`
  mutation($seriesIds: [Int!]!) {
    DeleteSeries(seriesIds: $seriesIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteSeriesMutation = seriesIds => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_SERIES_BY_ID),
    variables: {
      seriesIds,
    },
  });
};
