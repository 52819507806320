import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
const useStyles = () => ({
  boder: {
    padding: 0,
    // marginTop: 5,
    // paddingLeft: 5,
    border: '1px solid green',
    // height: '100%',
  },
  cell_boder: {
    padding: 0,
    marginTop: 6,
    // display: 'grid',
  },
});
class TableGridRowCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }
  componentDidUpdate() {
    const { isActive } = this.props;
    const { edit } = this.state;
    if (!isActive && edit) {
      this.setState({ edit: false });
    }
  }
  handleTextChange = event => {
    const { header, isActive } = this.props;
    if (header === undefined || isActive === undefined || !isActive) return;
    const { field, edit } = header;
    if (edit === undefined || !edit) return;
    this.props.onTextChanged(field, event.target.value);
  };
  handleDoubleClick = () => {
    const { header, value } = this.props;
    if (header === undefined) return;
    const { field, edit } = header;
    // console.log('handleDoubleClick', { field, edit });
    if (edit === undefined || !edit) return;
    this.setState({ edit: true });
    this.props.onCellDoubleClick(field, value);
  };
  handleSingleClick = () => {
    const { edit } = this.state;
    if (edit) return;
    const { header } = this.props;
    if (header === undefined) return;
    const { field } = header;
    this.props.onCellClick(field);
  };
  render() {
    const { classes, value } = this.props;
    let ComponentCell = value;
    if (this.state.edit) {
      ComponentCell = (
        <InputBase
          onChange={this.handleTextChange}
          onKeyDown={event => {
            const { key } = event;
            if (key === 'Enter') {
              this.props.onCellKeyEnter();
            }
          }}
          style={{ margin: 0, padding: 0, height: '100%' }}
          value={value}
        />
      );
    }
    return (
      <TableCell
        className={clsx({
          [classes.boder]: this.state.edit,
        })}
        onClick={this.handleSingleClick}
        onDoubleClick={this.handleDoubleClick}>
        {ComponentCell}
      </TableCell>
    );
  }
}

TableGridRowCell.propTypes = {
  onCellKeyEnter: PropTypes.func,
};
TableGridRowCell.defaultProps = {
  value: '',
  isActive: false,
  onCellDoubleClick: () => '',
  onTextChanged: () => '',
  onCellClick: () => '',
  onCellKeyEnter: () => '',
};

export default withStyles(TableGridRowCell, useStyles);
