/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = theme => ({
  root: {
    width: '80%',
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  root_mobile: {
    width: '65%',
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  bottom: {
    position: 'fixed',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRigth: theme.spacing(2),
    bottom: 0,
  },
});

class UploadProgress extends Component {
  render() {
    const {
      classes,
      progress,
      progress_files,
      total_files,
      isDesktop,
    } = this.props;
    const total_progress = Math.floor((progress_files * 100) / total_files);
    return (
      <Grid
        alignItems="flex-start"
        className={classes.bottom}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}>
        <Grid item>
          <Typography gutterBottom variant="h6">
            {`Total (${progress_files} / ${total_files})`}
          </Typography>
        </Grid>
        <div
          className={clsx({
            [classes.root]: !isDesktop,
            [classes.root_mobile]: isDesktop,
          })}>
          <LinearProgress value={total_progress} variant="determinate" />
          <LinearProgress
            color="secondary"
            value={progress}
            variant="determinate"
          />
        </div>
      </Grid>
    );
  }
}

UploadProgress.defaultProps = {
  progress: 0,
  progress_files: 0,
  total_files: 0,
};
UploadProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  progress_files: PropTypes.number.isRequired,
  total_files: PropTypes.number.isRequired,
};
const mapStateToProps = state => ({
  progress: state.page_explorer.progress,
  progress_files: state.page_explorer.progress_files,
  total_files: state.page_explorer.total_files,
  isDesktop: state.layout.isDesktop,
});
export default connect(
  mapStateToProps,
  null
)(withStyles(UploadProgress, useStyles));
