import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { SearchInput } from 'components';
import { QuerySoftwares } from 'graphql/Software';
import { GetData, ServerErrorsString } from 'helpers';
import {
  GridToolBar,
  CreateSoftware,
  TableSoftware,
  EditSoftware,
} from './components';
import { rowsPerPageBig } from 'helpers';
class TabSoftwares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      rows: [],
      loading: false,
      open_create: false,
      open_edit: false,
      page: 0,
      limit: 50,
      init: false,
    };
  }
  componentDidMount() {
    this.runSoftware();
  }
  componentDidUpdate() {
    if (!this.state.init) this.runSoftware();
  }
  // eslint-disable-next-line no-unused-vars
  runSoftware = (new_name = null, new_page = null, new_limit = null) => {
    if (!this.props.isActive) return;
    let { loading, page, limit } = this.state;
    console.log({ new_page, new_limit });

    if (loading) return;
    this.setState({ loading: true });
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    (async () => {
      QuerySoftwares(null, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, softwares, total } = data.allSoftware;
          if (ok) {
            this.setState({
              loading: false,
              rows: softwares,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleNewSoftware = software => {
    console.log({ software });
    const { rows } = this.state;
    const index = rows.map(x => x.id).indexOf(software.id);
    if (index === -1) {
      rows.unshift(software);
    }
    this.handleUpdateSelected([software]);
    this.setState({ rows, open_create: false });
  };
  handleUpdateSelected = softwares => {
    const { selected, rows } = this.state;
    for (let i = 0; i < softwares.length; i++) {
      let index = rows.map(x => x.id).indexOf(softwares[i].id);
      if (index !== -1) {
        rows[index] = softwares[i];
      }
      index = selected.map(x => x.id).indexOf(softwares[i].id);
      if (index !== -1) {
        selected[index] = softwares[i];
      }
    }
    this.setState({ rows, selected });
  };
  render() {
    const { rows, loading } = this.state;
    const { height } = this.props;
    if (!this.props.isActive) return null;
    if (this.state.open_edit) {
      return (
        <EditSoftware
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleNewSoftware={this.handleNewSoftware}
          handleUpdateSelected={this.handleUpdateSelected}
          selected={this.state.selected}
        />
      );
    }
    if (this.state.open_create) {
      return (
        <CreateSoftware
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleNewSoftware={this.handleNewSoftware}
        />
      );
    }
    return (
      <Grid container spacing={1} style={{ padding: 20, width: '100%' }}>
        <Grid item xs={6}>
          <SearchInput placeholder="Search Software" />
        </Grid>
        <Grid item xs={12}>
          <GridToolBar
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReload={() => this.runSoftware()}
            loading={loading}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item style={{ minHeight: 400 }} xs={12}>
          <TableSoftware
            checkboxSelection
            height={height - 340}
            onPageChange={page =>
              this.runSoftware(null, page, this.state.limit)
            }
            onRowsPerPageChange={limit => this.runSoftware(null, 0, limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={rows}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabSoftwares.propTypes = {
  classes: PropTypes.object,
};

export default TabSoftwares;
