import {
  LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS,
  PAGE_UPLOAD_DOWNLOADS,
  PAGE_UPLOAD_DOWNLOADS_ADD,
} from '../actions/types';

const initialState = {
  downloads: [],
};

export default function reducer_page_uploaddownload(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS:
      return initialState;
    case PAGE_UPLOAD_DOWNLOADS:
      return {
        ...state,
        downloads: action.downloads,
      };
    case PAGE_UPLOAD_DOWNLOADS_ADD: {
      const { item } = action;
      const { downloads } = state;
      downloads.push(item);
      return {
        ...state,
        downloads,
      };
    }
    default:
      return state;
  }
}
