import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import DeleteIcon from '@mui/icons-material/Delete';
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';

import useStyles from './Styles';
import { DeleteFoldersMutation, RemoveSharedMutation } from 'graphql/Folders';
import { GetData, GetFoldersIds, GetFolderContentIds } from 'helpers';
import { SnackMessage, DialogDeleteWarning } from 'components';
import { removeSharedFoldersIds } from 'redux-store/actions/notifications';

class ButtonDeleteFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
      open: false,
    };
  }

  handleOpenDialog = () => {
    const { loading } = this.state;
    if (loading) return;

    this.props.handleDrawerClose();
    this.setState({ open: true });
  };
  handleDeleteWarningClick = (shared, delete_object, delete_series) => {
    if (delete_object === false) {
      /* empty */
    }
    if (shared) {
      this.handleShared();
    } else {
      this.handleDelete(delete_series);
    }
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };
  handleCloseDelete = () => {
    this.setState({ open: false });
  };
  handleDelete = delete_series => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ open: false });
      return;
    }
    this.setState({ loading: true, open: false });
    const { selected_folder, selected_content } = this.props;
    (async () => {
      const folders_ids = GetFoldersIds(selected_folder);
      const content_ids = GetFolderContentIds(selected_content);
      DeleteFoldersMutation(folders_ids, content_ids, delete_series)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, warnings } = data.deleteFolders;
          if (ok) {
            this.setState({ loading: false });
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings,
              folders_ids,
              content_ids,
            });
          } else if (errors) {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ loading: false });
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };
  handleShared = () => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ open: false });
      return;
    }
    this.setState({ loading: true, open: false });
    const { selected_folder, selected_content } = this.props;
    (async () => {
      const folders_ids = GetFoldersIds(selected_folder);
      const content_ids = GetFolderContentIds(selected_content);
      RemoveSharedMutation(folders_ids, content_ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeSharedFolders;
          console.log({ ok, errors });
          if (ok) {
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings: null,
              folders_ids,
              content_ids,
            });
            this.setState({ open: false, loading: false });
            this.props.removeSharedFoldersIds(folders_ids);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ open: false, loading: false });
          // this.state.loading = false;
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };

  render() {
    const {
      base,
      classes,
      selected_content,
      selected_folder,
      defaultProps,
    } = this.props;
    const { loading, error, open } = this.state;
    const selected = selected_content.concat(selected_folder);
    if (selected.length === 0) {
      return null;
    }
    let title = 'Delete Selected';
    if (base === 'Shared') {
      title = 'Remove Selected';
    }
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <SnackMessage
            handleClose={this.handleCloseSnak}
            message_text={error !== '' ? error : 'Unknown warning'}
            open={error && error !== '' ? true : false}
            type="error"
          />
          <DialogDeleteWarning
            handleClose={this.handleCloseDelete}
            handleDeleteFolders={this.handleDeleteWarningClick}
            loading={this.state.loading}
            open={open}
            selected={selected}
            shared={base === 'Shared'}
          />
          <Tooltip title={title}>
            <span style={{ margin: 0, padding: 0 }}>
              <IconButton
                aria-controls="delete-folders"
                {...this.props.iconProps}
                onClick={this.handleOpenDialog}
                size="large">
                {loading ? (
                  <DeleteIcon color="disabled" />
                ) : (
                  <Badge badgeContent={selected.length} {...defaultProps} />
                )}
              </IconButton>
            </span>
          </Tooltip>
          {loading && (
            <CircularProgress className={classes.fabProgress} size={36} />
          )}
        </div>
      </div>
    );
  }
}

ButtonDeleteFolders.propTypes = {
  base: PropTypes.string,
  classes: PropTypes.object,
  defaultProps: PropTypes.object,
  selected_content: PropTypes.array,
  selected_folder: PropTypes.array,
};

ButtonDeleteFolders.defaultProps = {
  base: 'Home',
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'secondary',
    children: <DeleteIcon color="error" />,
  },
  selected_content: [],
  selected_folder: [],
  iconProps: {},
};
export default connect(null, { removeSharedFoldersIds })(
  withStyles(ButtonDeleteFolders, useStyles)
);
