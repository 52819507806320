import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { ToogleSelectedActions, ToogleStudyView } from './components';
import {
  ButtonReload,
  DialogShareSeries,
  DivGrow,
  IconButtonBadge,
} from 'components';
import { withRouter } from 'react-router-dom';

const useStyles = theme => ({
  grow: {
    flex: 1,
  },
  root: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  },
});

class ToolBarImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress_all: 0,
      open_shared: false,
    };
  }

  handleProgress = (event, loading) => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    let progress_all = Math.floor((loaded * 100) / total);
    if (!loading) {
      progress_all = 0;
    }
    //
    this.setState({ progress_all });
  };
  handleOpenShared = () => {
    this.setState({ open_shared: true });
  };
  handleCloseShared = () => {
    this.setState({ open_shared: false });
  };
  handleProcessSeries = () => {
    console.log('handleProcessSeries');
    this.props.history.push('/pipelines/input_data');
  };
  handleMultiEditSeries = () => {
    this.props.history.push('/image_analysis/series/edit/multi');
  };
  render() {
    const { classes, copy_series, total_series } = this.props;
    const { progress_all, open_shared } = this.state;
    let Progress = false;
    if (progress_all > 0) {
      Progress = true;
    }
    //
    return (
      <React.Fragment>
        <Toolbar className={classes.root} component="nav" variant="dense">
          <ToogleStudyView />
          <ButtonReload
            handleReload={this.props.handleReload}
            loading={this.props.loading}
            small
          />
          <IconButtonBadge
            hide={total_series <= 1}
            onClick={this.handleMultiEditSeries}
            size={total_series}
            title="Edit selected series"
          />
          <IconButtonBadge
            color="green"
            hide={!total_series}
            horizontal="bottom"
            Icon={PostAddIcon}
            onClick={this.handleProcessSeries}
            size={total_series}
            title="Add to batch processes"
          />
          <DivGrow />
          <ToogleSelectedActions
            handleOpenShared={this.handleOpenShared}
            handleProgress={this.handleProgress}
            user={this.props.user}
          />
        </Toolbar>
        {Progress && (
          <LinearProgress
            style={{ width: '100%' }}
            value={progress_all}
            variant="determinate"
          />
        )}
        <DialogShareSeries
          handleClose={this.handleCloseShared}
          ListSeries={copy_series}
          open={open_shared}
        />
      </React.Fragment>
    );
  }
}

ToolBarImages.propTypes = {
  classes: PropTypes.object,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
ToolBarImages.defaultProps = {
  loading: false,
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    total_series: copy_series ? copy_series.length : 0,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(withRouter(ToolBarImages), useStyles));
