import { FormatBytes, FormatTime } from 'helpers';
import {
  TabActivities,
  TabGeneral,
  TabDockers,
  TabLogs,
  TabSystemElements,
  TabUsers,
  TabData,
  TabSoftwares,
  TabConfigurationFiles,
  TabClinics,
  TabProphet,
} from './AdminDefault';
import { TabDesignerUser, TabDesignerSettings } from './AdminDesigner';

export const columns_files = [
  {
    id: 'idFileObject',
    label: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    id: 'Owner',
    label: 'Owner',
    align: 'left',
    minWidth: 170,
    format: value => {
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Name Missing';
      return FullName;
    },
  },
  {
    id: 'locations',
    label: 'Locations',
    align: 'left',
    format: value => {
      if (!Array.isArray(value)) return '';
      let names = value
        .map(x => x.Folder)
        .filter(x => x)
        .map(x => x.name);
      return names.join(', ');
    },
  },
  {
    id: 'original_name',
    label: 'Original Name',
    minWidth: 170,
  },
  {
    id: 'mimetype',
    label: 'Type',
  },
  {
    id: 'Size',
    label: 'Size',
    // align: 'left',
    format: value => FormatBytes(value),
  },
  {
    id: 'filepath',
    label: 'FilePath',
    minWidth: 550,
  },
  {
    id: 'updatedAt',
    label: 'Update At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];

export const columns_series_user = [
  {
    id: 'idSeries',
    label: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    id: 'Owner',
    label: 'Owner',
    align: 'left',
    minWidth: 170,
    format: value => {
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Name Missing';
      return FullName;
    },
  },
  {
    id: 'locations',
    label: 'Locations',
    align: 'left',
    format: value => {
      if (!Array.isArray(value)) return '';
      let names = value
        .map(x => x.Folder)
        .filter(x => x)
        .map(x => x.name);
      return names.join(', ');
    },
  },
  {
    id: 'SeriesDescription',
    label: 'SeriesDescription',
  },
  {
    id: 'Modality',
    label: 'Modality',
  },
  {
    id: 'NumberOfSeriesRelatedInstances',
    label: '#Dicoms',
  },
  {
    id: 'Size',
    label: 'Size',
    // align: 'left',
    format: value => FormatBytes(value),
  },
  {
    id: 'updatedAt',
    label: 'Update At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];

export const getTabs = user => {
  if (user.type !== 1) return [];
  if (user.idCategory === undefined) return [];
  if (user.idCategory === 2) {
    return [
      { name: 'users', TabComponent: TabDesignerUser },
      { name: 'settings', TabComponent: TabDesignerSettings },
    ];
  } else if (user.idCategory === 1) {
    return [
      { name: 'users', TabComponent: TabUsers },
      { name: 'general', TabComponent: TabGeneral },
      { name: 'dockers', TabComponent: TabDockers },
      { name: 'clinics', TabComponent: TabClinics },
      { name: 'data', TabComponent: TabData },
      { name: 'software', TabComponent: TabSoftwares },
      { name: 'activities', TabComponent: TabActivities },
      {
        name: 'system_elements',
        TabComponent: TabSystemElements,
      },
      { name: 'logs', TabComponent: TabLogs },
      {
        name: 'configuration_files',
        TabComponent: TabConfigurationFiles,
      },
      {
        name: 'prophet',
        TabComponent: TabProphet,
      },
    ];
  }
};
