import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography } from '@mui/material';
class HomeLogos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logos: [
        {
          name: 'Inselspital, Universitätsspital Bern',
          icon:
            'https://upload.wikimedia.org/wikipedia/de/8/8d/Inselspital.svg',
        },
        {
          name: 'University of Bern',
          icon: '/images/unibe.png',
        },
      ],
    };
  }

  render() {
    const { logos } = this.state;
    return (
      <Box py={6}>
        <Container>
          <Grid
            container
            item
            justifyContent="center"
            sx={{ mx: 'auto', textAlign: 'center' }}
            xs={8}>
            <Typography mb={1} variant="h3">
              Our work is supported by
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            spacing={3}
            sx={{ mt: 3 }}>
            {logos.map((logo, index) => {
              const { icon, name } = logo;
              return (
                <Grid item key={`logo-${index}`} lg={2} md={4} xs={6}>
                  <Box
                    alt={name}
                    component="img"
                    opacity={0.9}
                    src={icon}
                    sx={{ maxHeight: 100 }}
                    width="100%"
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    );
  }
}

HomeLogos.propTypes = {
  classes: PropTypes.object,
};

export default HomeLogos;
