import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROCESS_FOLDER = gql`
  query($page: Int, $limit: Int) {
    processFolders(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      process {
        id
        idUser

        id_folders
        action
        running
        user {
          id
          FullName
        }

        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryProcessFolder = (page, limit) => {
  console.log('QueryProcessFolder', {
    page,
    limit,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROCESS_FOLDER),
    variables: {
      page,
      limit,
    },
  });
};
const MUTATION_DELETE_PROCESS_FOLDER = gql`
  mutation($ids: [Int!]!) {
    deleteProcessFolder(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteProcessFolder = ids => {
  console.log('MutationDeleteProcessFolder', {
    ids,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROCESS_FOLDER),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_PROCESS_FOLDER = gql`
  mutation($ids: [Int!]!) {
    addProcessFolder(ids: $ids) {
      ok
      errors {
        path
        message
      }
      process {
        id
        idUser

        id_folders
        action
        running
        user {
          id
          FullName
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddProcessFolder = ids => {
  console.log('MutationAddProcessFolder', {
    ids,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_PROCESS_FOLDER),
    variables: {
      ids,
    },
  });
};
