import React from 'react';
import PropTypes from 'prop-types';
import { DialogAnonymizationKeys, TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { Grid } from '@mui/material';
import { QueryAnonymizationTags } from 'graphql/Anonymization';
import { ServerErrorsString, GetData } from 'helpers';
import { columns_ano_pro_tags } from './Styles';
import { TabRulesTagsToolbar } from 'views/Administrator/components/AdminDefault/TabGeneral/components/TabAnonymization/components';

class ProjectSettingAnonymization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_help: false,
      total: 0,
      loading: false,
      rules: [],
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    this.handleLoadRulesProject();
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (!this.state.init) this.handleLoadRulesProject();
  }
  handleLoadRulesProject = () => {
    const { loading } = this.state;
    const { idProject } = this.props;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryAnonymizationTags({ idProject })
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules, total } = data.anonymizationTags;
          if (ok) {
            this.setState({ loading: false, rules, total, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreatedTagRule = (new_rules, clean_edit = false) => {
    let { rules, total, rows_edited } = this.state;
    for (let i = 0; i < new_rules.length; i++) {
      const { id } = new_rules[i];
      const index = rules.map(x => x.id).indexOf(id);
      if (index === -1) {
        rules.unshift(new_rules[i]);
        total++;
      } else {
        rules[index] = {
          ...rules[index],
          ...new_rules[i],
        };
      }
    }
    if (clean_edit) rows_edited = [];
    this.setState({ rules, total, rows_edited });
  };
  render() {
    if (!this.props.isActive) return null;
    return (
      <React.Fragment>
        <Grid container sx={{ width: '100%', paddingLeft: 1, paddingRight: 5 }}>
          <Grid item xs={12}>
            <TabRulesTagsToolbar
              classes={this.props.classes}
              handleAddError={this.props.handleAddError}
              handleClearSelected={() => this.setState({ selected: [] })}
              handleCreatedTagRule={this.handleCreatedTagRule}
              handleOpenHelp={() => this.setState({ open_help: true })}
              handleReload={this.handleLoadRulesProject}
              idProject={this.props.idProject}
              loading={this.state.loading}
              rows_edited={this.state.rows_edited}
              rules={this.state.rules}
              selected={this.state.selected}
              setState={state => this.setState(state)}
            />
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={this.state.rows_edited}
              external_selected={this.state.selected}
              grid
              headers={columns_ano_pro_tags}
              height={this.props.height - 120}
              loading={this.state.loading}
              onPageChange={page => this.setState({ page })}
              onRowClicked={row_highlight => this.setState({ row_highlight })}
              onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              rowCount={this.state.total}
              rows={this.state.rules}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
        <DialogAnonymizationKeys
          handleClose={() => this.setState({ open_help: false })}
          open={this.state.open_help}
        />
      </React.Fragment>
    );
  }
}

ProjectSettingAnonymization.propTypes = {
  classes: PropTypes.object,
};

export default ProjectSettingAnonymization;
