import { IsInvalidParam } from 'helpers';
import { getUserFullName } from 'helpers';
import { IsInvalid } from 'helpers';
import { FormatTime } from 'helpers';

export const columns_landing = [
  { field: 'idLanding', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'file',
    headerName: 'Object',
    align: 'left',
    style: {
      minWidth: 150,
    },
    valueGetter: params => {
      if (IsInvalidParam(params)) return 'Object Missing';
      const file_object = params.getValue(params.id, 'file');
      if (file_object) {
        let { idFileObject, original_name } = file_object;
        if (idFileObject === undefined) return 'Object Missing';
        return `${original_name} (${idFileObject})`;
      } else {
        const num_inputs = params.getValue(params.id, 'num_inputs');
        if (num_inputs >= 0) return `#inpust (${num_inputs})`;
        return '';
      }
    },
  },
  {
    field: 'projectIds',
    headerName: 'Project IDs',
    align: 'left',
    style: {
      minWidth: 150,
    },
  },
  {
    field: 'folderIds',
    headerName: 'Folder IDs',
    align: 'left',
    style: {
      minWidth: 150,
    },
  },
  {
    field: 'center_team',
    headerName: 'Center/Team',
    align: 'left',
    style: {
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalidParam(params)) return 'Not defined';
      const center_team = params.getValue(params.id, 'center_team');
      if (IsInvalid(center_team)) return '';
      const { center, team } = center_team;
      let text = '';
      if (center !== undefined) text = center.name;
      if (team) {
        if (text !== '') text = `${text} / ${team.name}`;
      }
      if (text !== '') return text;
      return 'Not defined';
    },
  },
  {
    field: 'isSeries',
    headerName: 'Is Series',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return 'No';
      const isSeries = params.getValue(params.id, 'isSeries');
      const sequence = params.getValue(params.id, 'sequence');
      if (IsInvalid(isSeries)) return 'No';
      if (sequence) {
        return `Yes (${sequence.name})`;
      }
      return isSeries ? 'Yes' : 'No';
    },
  },
  {
    field: 'processing',
    headerName: 'Status',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return 'Stopped';
      const processing = params.getValue(params.id, 'processing');
      const status = params.getValue(params.id, 'status');
      if (IsInvalid(status) || status.length < 2)
        return processing ? 'Proccessing' : 'Stopped';
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
  },
  {
    field: 'folderStructure',
    headerName: 'Folder Structure',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const folderStructure = params.getValue(params.id, 'folderStructure');
      if (folderStructure) return folderStructure;
      const inputs = params.getValue(params.id, 'inputs');
      if (IsInvalid(inputs) || inputs === '') return '';
      const parts = inputs.split('},{');
      if (parts.length) return parts[0].replace('[', '');
      return '';
    },
  },
  {
    field: 'types',
    headerName: 'Types',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const types = params.getValue(params.id, 'types');
      if (types) return types;
      const function_name = params.getValue(params.id, 'function_name');
      return function_name;
    },
  },
  {
    field: 'errors',
    headerName: 'Errors',
    align: 'left',
    edit: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
