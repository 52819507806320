import React from 'react';
import PropTypes from 'prop-types';
import { QueryLogUnits } from 'graphql/Logs';
import { GetData, ServerErrorsString, rowsPerPageBig } from 'helpers';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import { ToolBarLogUnit, columns_logs_error } from './components';

class UnitLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows_edited: [],
      selected: [],
      logs_unit: [],
      deleting: false,
      loading: false,
      init: false,
      page: 0,
      limit: 100,
    };
    this.holder = {
      getSearchText: null,
      getSelected: null,
    };
  }
  componentDidMount() {
    this.updateLogsUnit();
  }
  componentDidUpdate() {
    if (!this.state.init) this.updateLogsUnit();
  }
  handleSequencesErrors = text => {
    console.log({ text });
    this.updateLogsUnit(text);
  };
  updateLogsUnit = (text = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QueryLogUnits(text, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, logs_unit, total } = data.logsUnit;
          if (ok) {
            this.setState({
              loading: false,
              logs_unit,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ToolBarLogUnit
            handleAddError={this.props.handleAddError}
            handleReload={text => this.updateLogsUnit(text)}
            setState={state => this.setState(state)}
            state={this.state}
          />
        </Grid>
        <Grid item style={{ minHeight: 350 }} xs={12}>
          <Paper>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns_logs_error}
              height={this.props.height - 280}
              loading={this.state.loading}
              nowrap={this.state.nowrap}
              onHighlightChange={row_highlight =>
                this.setState({ row_highlight })
              }
              onPageChange={page =>
                this.updateLogsUnit(null, page, this.state.limit)
              }
              onRowClicked={row_highlight => this.setState({ row_highlight })}
              onRowsPerPageChange={limit => this.updateLogsUnit(null, 0, limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              paginationMode="server"
              rowCount={this.state.total}
              rows={this.state.logs_unit}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

UnitLogs.propTypes = {
  classes: PropTypes.object,
};

export default UnitLogs;
