import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ButtonCircularIconLoad, DivGrow, SnackMessage } from 'components';
import { GetData, MutationRegisterUser, ServerErrorsString } from 'helpers';

class CreateNewUserBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registering: false,
      warning: '',
    };
  }

  handleRunRequest = () => {
    const { registering } = this.state;
    if (registering) return;
    this.setState({ registering: true });
    (async () => {
      MutationRegisterUser(this.props.user)
        .then(res => {
          const data = GetData(res);
          const { ok, warning, errors, user } = data.registerUser;
          if (ok) {
            this.setState({
              registering: false,
            });
            this.props.handleAddNewUser(user);
          } else if (warning) {
            this.setState({
              registering: false,
              warning,
            });
          } else if (errors && errors.length) {
            throw errors;
          } else {
            throw Error('Unknown server error');
          }
        })
        .catch(error => {
          this.setState({
            registering: false,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCloseSnak = () => {
    this.setState({ warning: '' });
  };

  render() {
    const { changed } = this.props;
    const { warning } = this.state;
    return (
      <Grid container>
        <DivGrow />
        <ButtonCircularIconLoad
          disabled={!changed}
          handleButtonClick={this.handleRunRequest}
          loading={this.state.registering}
          name="Save"
          transform={false}
          variant="outlined"
        />
        <SnackMessage
          handleClose={this.handleCloseSnak}
          message_text={warning !== '' ? warning : 'Unknown warning'}
          open={warning && warning !== '' ? true : false}
          type="warning"
        />
      </Grid>
    );
  }
}

CreateNewUserBottom.propTypes = {
  changed: PropTypes.bool,
};
CreateNewUserBottom.defaultProps = {
  changed: false,
  handleAddNewUser: () => '',
};
export default CreateNewUserBottom;
