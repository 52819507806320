import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import { CircularLoading } from 'components';

class MainTableRowLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dense, colSpan } = this.props;
    return (
      <TableRow
        style={{
          height: (dense ? 33 : 53) * 4,
          paddingTop: dense ? 0 : '3px',
        }}>
        <TableCell colSpan={colSpan + 1}>
          <CircularLoading />
        </TableCell>
      </TableRow>
    );
  }
}

MainTableRowLoading.propTypes = {
  classes: PropTypes.object,
  colSpan: PropTypes.number,
  dense: PropTypes.bool,
};
MainTableRowLoading.defaultProps = {
  dense: false,
  colSpan: 6,
};
export default MainTableRowLoading;
