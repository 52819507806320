import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
/* -------------- USER LOGS ------------------ */
const QUERY_USER_LOGS_ERRORS = gql`
  query($page: Int, $limit: Int) {
    LogsUserErrors(page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs {
        id
        idUser
        user {
          id
          FullName
        }
        error
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogsUserErrors = (page, limit) => {
  const variables = {};
  if (typeof page !== 'undefined') variables.page = page;
  if (typeof limit !== 'undefined') variables.limit = limit;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_LOGS_ERRORS),
    variables,
  });
};
const MUTATION_DELETE_USER_LOGS_ERRORS = gql`
  mutation($ids: [LongLong!]!) {
    DeleteLogsUserErrors(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogsUserErrors = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_USER_LOGS_ERRORS),
    variables: {
      ids,
    },
  });
};

/* -------------- SITE LOGS ------------------ */

const QUERY_LOGS_SITE_ERRORS = gql`
  query($input: InputLogErrors, $page: Int, $limit: Int) {
    LogsSiteErrors(input: $input, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs {
        id
        idUser
        user {
          id
          FullName
        }
        count
        error
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogsSiteErrors = (input, page, limit) => {
  const variables = {};
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (input !== undefined && input) {
    variables.input = input;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOGS_SITE_ERRORS),
    variables,
  });
};

const MUTATION_DELETE_SITE_LOGS_ERRORS = gql`
  mutation($ids: [LongLong!]!) {
    DeleteLogsSiteErrors(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogsSiteErrors = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SITE_LOGS_ERRORS),
    variables: {
      ids,
    },
  });
};
