import React from 'react';
import PropTypes from 'prop-types';
import {
  DataSignalTabBar,
  TabPatientEDFs,
  TabPatientSignals,
} from './components';
import { TabPanel } from 'components';

class DataSignals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_value: 0,
      tabs: [
        { name: 'Patients', TabComponent: TabPatientSignals },
        { name: 'EDFs', TabComponent: TabPatientEDFs },
      ],
    };
  }

  render() {
    const { user, height, ...res } = this.props;
    const { tabs, tab_value } = this.state;
    return (
      <React.Fragment>
        <DataSignalTabBar
          onTabClicked={tab_value => this.setState({ tab_value })}
          tab_value={tab_value}
          tabs={tabs.map(x => x.name)}
        />
        {tabs.map((tab, index) => {
          const { name, TabComponent } = tab;
          return (
            <TabPanel
              // className={classes.tab_panel}
              index={index}
              key={`key-tab-panel-${index}-${name}`}
              value={tab_value}>
              <TabComponent {...res} height={height} user={user} />
            </TabPanel>
          );
        })}
      </React.Fragment>
    );
  }
}

DataSignals.propTypes = {
  classes: PropTypes.object,
};

export default DataSignals;
