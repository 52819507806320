import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

import { blue, green, grey, red, yellow, purple } from '@mui/material/colors';

export const dataDefault = {
  datasets: [
    {
      data: [0],
      backgroundColor: [
        blue[900],
        red[500],
        '#ED6C02',
        green[800],
        yellow[700],
        purple[500],
      ],
      borderColor: grey[50],
      hoverBorderColor: grey[50],
    },
  ],
  labels: ['Not Defined'],
};

const QUERY_SUMMARY_PATHOLOGIES = gql`
  query(
    $page: Int
    $limit: Int
    $mode: Int
    $idProject: Int
    $datestart: String
    $dateend: String
    $fullHistory: Boolean
  ) {
    SummaryPathology(
      page: $page
      limit: $limit
      mode: $mode
      idProject: $idProject
      datestart: $datestart
      dateend: $dateend
      fullHistory: $fullHistory
    ) {
      ok
      Pathologies {
        Pathology {
          idPathology
          name
        }
        total
      }
      Total
      errors {
        path
        message
      }
    }
  }
`;
export const QueryRunSummaryPathologies = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_PATHOLOGIES),
    variables,
  });
};
