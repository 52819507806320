import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  TextField,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles } from 'tss-react/mui';
import { GetData, ServerErrorsString } from 'helpers';
import {
  QueryTableNames,
  QueryColumnNames,
  MutationDeleteTable,
  MutationDeleteColumn,
  MutationCreateTable,
  MutationCreateColumn,
} from 'graphql/utils_tables';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = theme => ({
  toolbar: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});
class TabConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      table_list: [],
      column_list: [],
      table_name: '',
      column_name: '',
    };
  }

  componentDidMount() {
    this.queryTables();
    this.queryColumns();
  }
  queryTables = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryTableNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, tables } = data.tableNames;
          if (ok) {
            this.setState({
              loading: false,
              table_list: tables,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  queryColumns = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryColumnNames()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, columns } = data.columnNames;
          if (ok) {
            this.setState({
              loading: false,
              column_list: columns,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationCreateTable = table_name => {
    let { table_list } = this.state;
    if (table_name === null || table_name === '') return;
    (async () => {
      MutationCreateTable(table_name)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, table } = data.createTableName;
          if (ok) {
            table_list.push(table);
            this.setState({ table_name: '', table_list });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationCreateColumn = column_name => {
    let { column_list } = this.state;
    if (column_name === null || column_name === '') return;
    (async () => {
      MutationCreateColumn(column_name)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, column } = data.createColumnName;
          if (ok) {
            column_list.push(column);
            this.setState({ column_name: '', column_list });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationDeleteSelectedTable = table => {
    let { table_list } = this.state;
    if (table === null || table === undefined) return;
    (async () => {
      MutationDeleteTable(table.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteTableName;
          if (ok) {
            table_list = table_list.filter(el => el.id !== table.id);
            this.setState({ table_list });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  mutationDeleteSelectedColumn = column => {
    let { column_list } = this.state;
    if (column === null || column === undefined) return;
    (async () => {
      MutationDeleteColumn(column.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteColumnName;
          if (ok) {
            column_list = column_list.filter(el => el.id !== column.id);
            this.setState({ column_list });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRemoveTable = (event, table) => {
    console.log('remove', { table });
    this.mutationDeleteSelectedTable(table);
  };
  handleRemoveColumn = (event, column) => {
    console.log('remove', { column });
    this.mutationDeleteSelectedColumn(column);
  };
  handleUpdateTableName = event => {
    const name = event.target.value;
    this.setState({ table_name: name });
  };
  handleCreateTable = () => {
    const { table_name } = this.state;
    console.log('create', { table_name });
    this.mutationCreateTable(table_name);
  };
  handleUpdateColumnName = event => {
    const name = event.target.value;
    this.setState({ column_name: name });
  };
  handleCreateColumn = () => {
    const { column_name } = this.state;
    console.log('create', { column_name });
    this.mutationCreateColumn(column_name);
  };
  render() {
    const { table_list, column_list, table_name, column_name } = this.state;

    return (
      <Grid container spacing={1} style={{ padding: 20, width: '100%' }}>
        <Grid item xs={6}>
          <Paper style={{ padding: '10px' }}>
            <Grid container style={{ width: '100%' }}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="table-name-json"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="New Table Name"
                  margin="dense"
                  name={table_name}
                  onChange={event => this.handleUpdateTableName(event)}
                  onKeyDown={event => {
                    const { key } = event;
                    if (key === 'Enter') {
                      this.handleUpdateTableName(event);
                      this.handleCreateTable();
                    }
                  }}
                  placeholder="Type name of a new table..."
                  required
                  value={table_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                style={{ marginTop: 7 }}
                xs={2}>
                <IconButton
                  color="primary"
                  onClick={this.handleCreateTable}
                  size="small">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Typography component="div" sx={{ mt: 4, mb: 2 }} variant="h6">
                Table list
              </Typography>
              <List>
                {table_list.map(table => {
                  return (
                    <ListItem
                      key={table.id}
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          edge="end"
                          onClick={event =>
                            this.handleRemoveTable(event, table)
                          }>
                          <DeleteIcon />
                        </IconButton>
                      }>
                      <ListItemText primary={table.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: '10px' }}>
            <Grid container>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="column-name-json"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="New Column Name"
                  margin="dense"
                  name={column_name}
                  onChange={event => this.handleUpdateColumnName(event)}
                  onKeyDown={event => {
                    const { key } = event;
                    if (key === 'Enter') {
                      this.handleUpdateColumnName(event);
                      this.handleCreateColumn();
                    }
                  }}
                  placeholder="Type name of a new column..."
                  required
                  value={column_name}
                  variant="outlined"
                />
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                style={{ marginTop: 7 }}
                xs={2}>
                <IconButton
                  color="primary"
                  onClick={this.handleCreateColumn}
                  size="small">
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Typography component="div" sx={{ mt: 4, mb: 2 }} variant="h6">
                Column list
              </Typography>
              <List>
                {column_list.map(column => {
                  return (
                    <ListItem
                      key={column.id}
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          edge="end"
                          onClick={event =>
                            this.handleRemoveColumn(event, column)
                          }>
                          <DeleteIcon />
                        </IconButton>
                      }>
                      <ListItemText primary={column.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TabConfiguration.propTypes = {
  classes: PropTypes.object,
};
TabConfiguration.defaultProps = {
  handleAddNewContainer: () => '',
};
export default withStyles(TabConfiguration, useStyles);
