import React from 'react';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ContentStudyTimes } from './components';
import { BadgeSmall } from '../BadgeSettings/BadgeSettings';

const useStyles = theme => ({
  root: {
    // display: 'flex'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: 0,
  },
  card: {
    width: '100%',
    marginTop: theme.spacing(1),
    fontSize: 10,
  },
  to_format: {
    marginTop: theme.spacing(1),
  },
  calendar_format: {
    width: '140px',
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 100,
  },
  summary: {
    display: 'flex',
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    height: 20,
  },
  title: {
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
    },
    margin: 0,
    padding: 0,
  },
});

class GroupBoxStudyTimes extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    var sec = new Date().getSeconds(); //Current Seconds
    this.state = {
      tabValue: 0,
      radioValue: 'any_date',
      selectedAtDate: `${year}-${month}-${date}`,
      selectedToDate: `${year}-${month}-${date}`,
      selectedFromDate: `${year}-${month}-${date}`,
      selectedTime: `${hours}:${min}:${sec}`,
      last_minutes: 10,
      expanded: false,
    };
    //
    //
    const { holder } = props;
    if (
      typeof holder !== 'undefined' &&
      typeof holder.getStudyTimes !== 'undefined'
    ) {
      holder.getStudyTimes = () => this.handleTimeDates(this.state.radioValue);
    }
  }

  handleTimeDates = value => {
    let time = null;
    let date = null;
    const D_F = 'YYYYMMDD';
    const T_F = 'hhmmss';
    if (value === 'today_am') {
      date = moment().format(D_F);
      time = '000000-120000';
    } else if (value === 'today_pm') {
      date = moment().format(D_F);
      time = '120000-';
    } else if (value === 'today') {
      date = moment().format(D_F) + '-';
    } else if (value === 'last_6_hours') {
      const current = moment().seconds(-3600 * 6);
      date = current.format(D_F) + '-';
      time = current.format(T_F) + '-';
    } else if (value === 'last_2_hours') {
      const current = moment().seconds(-3600 * 2);
      date = current.format(D_F) + '-';
      time = current.format(T_F) + '-';
    } else if (value === 'last_hour') {
      const current = moment().seconds(-3600);
      date = current.format(D_F) + '-';
      time = current.format(T_F) + '-';
    } else if (value === 'last_30_min') {
      const current = moment().seconds(-1800);
      date = current.format(D_F) + '-';
      time = current.format(T_F) + '-';
    } else if (value === 'last_24_hours') {
      const current = moment().seconds(-3600 * 24);
      date = current.format(D_F) + '-';
      time = current.format(T_F) + '-';
    } else if (value === 'last_2_days') {
      const current = moment().subtract(2, 'day');
      date = current.format(D_F) + '-';
    } else if (value === 'last_month') {
      const current = moment().subtract(1, 'months');
      date = current.format(D_F) + '-';
    } else if (value === 'last_7_days') {
      const current = moment().subtract(7, 'day');
      date = current.format(D_F) + '-';
    } else if (value === 'last_minutes') {
      const { last_minutes } = this.state;
      const current = moment().subtract(last_minutes, 'minutes');
      date = current.format(D_F);
      time = current.format(T_F) + '-';
    } else if (value === 'yesterday') {
      const current = moment().subtract(1, 'day');
      date = current.format(D_F) + '-';
    } else if (value === 'last_3_months') {
      const current = moment().subtract(3, 'months');
      date = current.format(D_F) + '-';
    } else if (value === 'at_date') {
      const { selectedAtDate } = this.state;
      date = moment(selectedAtDate, 'YYYY-M-DD').format(D_F);
    } else if (value === 'from') {
      const { selectedToDate, selectedFromDate } = this.state;
      const from = moment(selectedFromDate, 'YYYY-M-DD').format(D_F);
      const to = moment(selectedToDate, 'YYYY-M-DD').format(D_F);
      date = `${from}-${to}`;
    }

    return {
      date,
      time,
    };
  };
  handleRadioChange = event => {
    event.preventDefault();
    const value = event.target.value;
    this.setState({ radioValue: value });
  };
  handleAtDateChange = date => {
    const selectedAtDate = moment(date).format('YYYY-M-DD');
    console.log({ date, selectedAtDate });
    this.setState({ selectedAtDate });
  };
  handleToDateChange = date => {
    const selectedToDate = moment(date).format('YYYY-M-DD');
    console.log({ date, selectedToDate });
    this.setState({ selectedToDate });
  };
  handleFromDateChange = date => {
    const selectedFromDate = moment(date).format('YYYY-M-DD');
    console.log({ date, selectedFromDate });
    this.setState({ selectedFromDate });
  };
  handleLastMinutesChange = event => {
    event.preventDefault();
    this.setState({ last_minutes: event.target.value });
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(newValue, 10) });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <Accordion
          expanded={this.state.expanded}
          onChange={() => this.setState({ expanded: !this.state.expanded })}
          style={{ margin: 0, minHeight: 0 }}>
          <AccordionSummary
            aria-controls="panel-stuy-times-content"
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            id="panel-stuy-times-header">
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-start">
              <Grid item xs={8}>
                <CardHeader
                  className={classes.title}
                  title="Study Time and Date"
                />
              </Grid>
              <div style={{ paddingLeft: 5 }}>
                <BadgeSmall
                  badgeContent={this.state.radioValue === 'any_date' ? 0 : 1}
                  style={{ backgroundColor: 'black' }}
                />
              </div>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant="middle" />
            <ContentStudyTimes
              {...this.state}
              handleAtDateChange={this.handleAtDateChange}
              handleFromDateChange={this.handleFromDateChange}
              handleLastMinutesChange={this.handleLastMinutesChange}
              handleRadioChange={this.handleRadioChange}
              handleToDateChange={this.handleToDateChange}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  }
}

GroupBoxStudyTimes.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(GroupBoxStudyTimes, useStyles);
