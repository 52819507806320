import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { PaperComponent } from 'helpers';
import { IsInvalid } from 'helpers';
import { FormatBytes } from 'helpers';
import DialogCleanObjectsToolbar from './DialogCleanObjectsToolbar';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import {
  setCopy,
  setCut,
  copySeries,
  copyFiles,
} from 'redux-store/actions/file_folder_manager';
const columns = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'id_object', headerName: 'ID Object', width: 75, align: 'left' },
  { field: 'type', headerName: 'Type', width: 75, align: 'left' },
  { field: 'name', headerName: 'Name', width: 75, align: 'left' },
  { field: 'Size', headerName: 'Size', width: 75, align: 'left' },
];
const useStyles = () => ({
  root: {},
});
class DialogCleanObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
    };
  }
  handleDeleteSelected = () => {
    const { selected } = this.state;
    // console.log('handleDeleteSelected', { selected });
    const { cut, copy, copy_series } = this.props;
    let new_cut = [...cut];
    let new_copy = [...copy];
    let new_series = [...copy_series];
    for (let index = 0; index < selected.length; index++) {
      const { id_object, type } = selected[index];
      if (type === 'Content File' || type === 'Content Series') {
        new_cut = new_cut.filter(x => x.idFolderContent !== id_object);
        new_copy = new_copy.filter(x => x.idFolderContent !== id_object);
      } else if (type === 'Folder') {
        new_cut = new_cut.filter(x => x.idFolder !== id_object);
        new_copy = new_copy.filter(x => x.idFolder !== id_object);
      } else if (type === 'Series') {
        new_series = new_series.filter(x => x.idSeries !== id_object);
      }
    }
    // console.log({ cut, copy, copy_series });
    // console.log({ new_cut, new_copy, new_series });
    if (cut && cut.length) this.props.setCut(new_cut);
    if (copy && copy.length) this.props.setCopy(new_copy);
    if (copy_series && copy_series.length) this.props.copySeries(new_series);
    this.setState({ selected: [] });
  };
  render() {
    const { open, cut_copy_objects } = this.props;
    if (IsInvalid(open) || !cut_copy_objects.length) return null;
    let objects = cut_copy_objects.map((x, index) => {
      const { idFolderContent, idFolder, name, Series, File: FileObject } = x;
      let { idSeries, Size } = x;
      let name_object = name;
      let type = 'Folder';
      let id_object = idFolderContent ? idFolderContent : idFolder;
      if (Series) {
        name_object = Series.SeriesDescription;
        type = 'Content Series';
        Size = Series.Size;
      } else if (FileObject) {
        name_object = FileObject.original_name;
        type = 'Content File';
        Size = FileObject.Size;
      } else if (idSeries) {
        name_object = x.SeriesDescription;
        type = 'Series';
        Size = x.Size;
        id_object = idSeries;
      }
      return {
        id: index,
        id_object,
        type,
        name: name_object,
        Size: FormatBytes(Size),
      };
    });
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle style={{ cursor: 'move' }}>
            Objects selected to Cut or Copy
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 0, paddingTop: 0 }}>
            <DialogCleanObjectsToolbar
              handleDeleteSelected={this.handleDeleteSelected}
              selected={this.state.selected}
              setState={state => this.setState(state)}
              total={this.state.selected.length}
            />
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={this.props.height}
              onPageChange={page => this.setState({ page })}
              onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => {
                this.setState({ selected });
              }}
              page={this.state.page}
              rowCount={this.state.total}
              rows={objects}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogCleanObjects.propTypes = {
  open: PropTypes.bool,
};

const mapStateToProps = state => {
  const { cut, copy, copy_files, copy_series } = state.manager;
  const cut_copy_objects = cut
    .concat(copy)
    .concat(copy_series)
    .concat(copy_files);
  const numSelected = cut_copy_objects.length;
  return {
    numSelected,
    cut,
    copy,
    copy_files,
    copy_series,
    cut_copy_objects,
  };
};
export default connect(mapStateToProps, {
  setCopy,
  setCut,
  copySeries,
  copyFiles,
})(withStyles(DialogCleanObjects, useStyles));
