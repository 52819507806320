import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { GroupBox } from 'components';
class GroupOtherLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      private_tag: false,
      uids: false,
      information: false,
      sequences: false,
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const { private_tag, uids, information, sequences } = this.state;
    return (
      <GroupBox title_box="Study Level">
        <FormControl component="fieldset" sx={{ m: 3 }} variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={uids}
                  name="uids"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Files UIDs"
              size="small"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={private_tag}
                  name="private_tag"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Erase private tags"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sequences}
                  name="sequences"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Erase private tags"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={information}
                  name="information"
                  onChange={this.handleChange}
                  size="small"
                />
              }
              label="Keep Institution information"
            />
          </FormGroup>
          <FormHelperText>Be careful</FormHelperText>
        </FormControl>
      </GroupBox>
    );
  }
}

GroupOtherLevel.propTypes = {
  classes: PropTypes.object,
};

export default GroupOtherLevel;
