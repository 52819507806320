import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { ButtonLoad } from 'components';
import { MutationRemoveFromFolder } from 'graphql/Folders';
import { GetData } from 'helpers';

class DialogRemoveFromFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      delete_series: false,
    };
  }

  handleRemove = () => {
    const { loading, delete_series } = this.state;
    if (loading) return;
    const { selected_content } = this.props;
    const indices = selected_content.map(x => x.idFolderContent);
    this.setState({ loading: true });
    (async () => {
      MutationRemoveFromFolder(indices, delete_series)
        .then(res => {
          const data = GetData(res);
          const {
            RemoveFromFolder: { ok, errors },
          } = data;
          if (ok) {
            this.setState({ loading: false, delete_series: false });
            this.props.handleRemoveFromFolder();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, delete_series: false });
          this.props.handleClose();
        });
    })();
  };
  handleChangeCheck = event => {
    event.preventDefault();
    this.setState({ delete_series: event.target.checked });
  };
  render() {
    const { open, handleClose, selected_content } = this.props;
    if (!open) return null;
    let text = 'this';
    if (selected_content.length > 1) {
      text = 'these';
    }
    const count_series = selected_content.filter(x => x.Series);
    const count_files = selected_content.filter(x => x.File);
    const { loading, delete_series } = this.state;
    let ComponentCheckBox = null;
    let type = 'series';
    if (count_series.length) {
      ComponentCheckBox = (
        <Grid item mt={1} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={delete_series}
                onChange={this.handleChangeCheck}
              />
            }
            label="Delete series permanently"
          />
        </Grid>
      );
      console.log({ count_series });
    }
    if (count_files.length && !count_series.length) {
      type = 'files';
      if (text === 'this') type = 'file';
    } else if (!count_files.length && count_series.length) {
      type = 'series';
    } else {
      type = 'files and series';
    }
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={handleClose}
        open={open}
        // PaperComponent={PaperComponent}
      >
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          <Typography fontWeight="fontWeightBold" variant="h4">
            {`Are you sure you want to deleted ${text} ${type}?`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Divider variant="middle" />
            <Typography color="textSecondary" style={{ margin: 10 }}>
              {`This action will remove the selected ${type} from the current
              folder. This action can not be undone.`}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  component="h2"
                  style={{ marginTop: 20, marginBottom: 0 }}
                  variant="h6">
                  Do you want to continue?
                </Typography>
              </Grid>
              {ComponentCheckBox}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            handleClick={this.handleRemove}
            loading={loading}
            name="Remove"
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogRemoveFromFolder.propTypes = {
  classes: PropTypes.object,
};
DialogRemoveFromFolder.defaultProps = {
  folder_content: [],
};
export default DialogRemoveFromFolder;
