import React from 'react';
import ReactJson from 'react18-json-view';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Dialog, Typography, DialogContent, Divider } from '@mui/material';
import WorkSheet from 'components/Tables/WorkSheet';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryFileContent } from 'graphql/Files';
import { IsExcelFile, IsMarkDownFile, IsTextFile } from 'common';
import ViewerCode from 'components/ViewerCode';
import { BoxWaiting } from 'components/Waiting';
import { CSVViewer, PDFViewer } from './components';
import { CardEditHeader } from 'components/Cards';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { RowText } from 'components/Texts';

class DialogViewFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: null,
      file: null,
    };
  }
  componentDidMount() {
    this.queryFileContent();
  }
  componentDidUpdate() {
    this.queryFileContent();
  }
  queryFileContent = () => {
    const { loading } = this.state;
    const { open_edit, file } = this.props;
    if (!open_edit || (this.state.file && this.state.content)) return;
    if (loading) return;
    const idFileObject = this.getFileID();
    let content = this.getFileContent();
    if (content || !idFileObject) return;
    this.setState({ loading: true });
    (async () => {
      QueryFileContent(idFileObject)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, content } = data.getFileContent;
          if (ok) {
            this.setState({
              loading: false,
              content,
              file,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getFileID = () => {
    const { file } = this.props;
    if (file) {
      return file.idFileObject;
    }
    return null;
  };
  getFileName = () => {
    const { file } = this.props;
    if (file) {
      return file.original_name;
    }
    return null;
  };
  getFileContent = () => {
    let content = null;
    if (this.state.content) {
      content = this.state.content;
    }
    return content;
  };
  handleClose = () => {
    // console.log('handleClose');
    this.setState({ content: null });
    this.props.handleClose();
  };
  render() {
    const { open_edit, file } = this.props;
    const idFileObject = this.getFileID();
    const { content, loading } = this.state;
    let file_content = null;
    if (content) {
      try {
        const { mimetype } = file;
        if (IsExcelFile(file)) {
          const workbook = JSON.parse(content);
          file_content = (
            <WorkSheet idFileObject={idFileObject} workbook={workbook} />
          );
        } else if (IsMarkDownFile(file)) {
          file_content = (
            <div style={{ padding: '25px' }}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          );
        } else if (mimetype === 'application/pdf') {
          file_content = (
            <PDFViewer filename={file.original_name} pdfData={content} />
          );
        } else if (
          mimetype ===
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
          file_content = (
            <PDFViewer filename={file.original_name} pdfData={content} />
          );
          // file_content = 'No implemented yet';
        } else if (mimetype.startsWith('image/')) {
          file_content = (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // height: '100vh',
              }}>
              <img
                alt={file.original_name}
                src={`data:${mimetype};base64,${content}`}
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          );
        } else if (mimetype === 'text/csv') {
          file_content = (
            <CSVViewer content={content} filename={file.original_name} />
          );
        } else if (IsTextFile(file.original_name)) {
          file_content = (
            <ViewerCode code={content} filename={file.original_name} />
          );
        } else {
          const json_content = JSON.parse(content);
          file_content = (
            <ReactJson
              enableClipboard={false}
              src={json_content}
              style={{ fontSize: 12, lineHeight: 1.4 }}
            />
          );
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
          file_content = (
            <ViewerCode code={content} filename={file.original_name} />
          );
        }
      }
    } else if (loading) {
      file_content = <BoxWaiting />;
    }
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="xl"
          onClose={this.handleClose}
          open={open_edit}>
          <CardEditHeader
            description={
              <RowText
                display="block ruby"
                first="Filename:"
                second={this.getFileName()}
                styleBox={{ fontWeight: 1000 }}
              />
            }
            handleClose={this.handleClose}
            icon={<InsertDriveFileIcon style={{ color: 'green' }} />}
            title={
              <Typography
                display="inline"
                gutterBottom
                style={{ marginRight: '5px', textTransform: 'none' }}
                variant="h4">
                File Viewer
              </Typography>
            }
          />
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            {file_content}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogViewFile.propTypes = {
  handleClose: PropTypes.func,
  open_edit: PropTypes.bool,
};
DialogViewFile.defaultProps = {
  handleClose: () => '',
  open_edit: false,
};

export default DialogViewFile;
