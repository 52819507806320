import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Tab, Tabs } from '@mui/material';
import { TabAccountClinics } from './components';
import { withStyles } from 'tss-react/mui';
import { TabPanel } from 'components';
import TabClinicalTypes from './components/TabClinicalTypes/TabClinicalTypes';

const useStyles = theme => ({
  toolbar: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

class TabClinics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tabValue: 0,
    };
    this.tabs = [
      { TabComponent: TabClinicalTypes, label: 'Clinical Types' },
      { TabComponent: TabAccountClinics, label: 'Account Clinics' },
    ];
  }

  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(tabValue, 10) });
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, user, height } = this.props;
    let { tabValue } = this.state;
    if (tabValue === undefined) tabValue = 0;
    else tabValue = parseInt(tabValue, 10);
    return (
      <Grid container>
        <Grid item style={{ width: '100%' }} xs={12}>
          {/* <Paper square> */}
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.tabs.map((tab, index) => {
              return <Tab key={`tab-header-${index}`} label={tab.label} />;
            })}
          </Tabs>
          <Divider />
          <div style={{ width: '100%', height: height - 250 }}>
            <div className={classes.tab_content}>
              {this.tabs.map((tab, index) => {
                const { TabComponent } = tab;
                return (
                  <TabPanel
                    className={classes.tab_panel}
                    index={index}
                    key={`tab-clinics-${index}`}
                    value={tabValue}>
                    <TabComponent
                      handleAddError={this.props.handleAddError}
                      handleTabValue={this.handleTabValue}
                      height={height}
                      isActive={this.props.isActive}
                      user={user}
                    />
                  </TabPanel>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

TabClinics.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabClinics, useStyles);
