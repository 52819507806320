import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { SearchInput, ButtonReloadDelete } from 'components';
import AddIcon from '@mui/icons-material/Add';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Badge from '@mui/material/Badge';
import { styleToolbar } from 'common';
const BadgeUsers = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    right: 5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'lightGreen',
  },
}));

class ToolBarPatientsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alignment: 'all' };
  }
  handleAlignment = (event, alignment) => {
    this.setState({ alignment });
  };
  render() {
    const { classes, disabled, view, requests, onlines, selected } = this.props;
    let ComponentSearchUsers = null;
    if (view === 'all') {
      ComponentSearchUsers = (
        <Grid item sx={{ paddingLeft: 5 }} xs={6}>
          <SearchInput
            onChange={this.props.handleChangeName}
            placeholder="Search patient"
            value={this.props.name}
          />
        </Grid>
      );
    }
    let { numSelected } = this.props;
    if (selected) numSelected = selected.length;
    // console.log({ numSelected: this.props.numSelected });
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.props.handleViews}
              size="small"
              value={this.props.view}>
              <ToggleButton
                aria-label="left aligned"
                sx={{ paddingTop: numSelected ? 0 : '7px' }}
                value="all">
                <BadgeUsers badgeContent={numSelected}>
                  <PeopleOutlineIcon
                    style={{ color: view === 'all' ? 'green' : null }}
                  />
                </BadgeUsers>
              </ToggleButton>
              <ToggleButton
                aria-label="left aligned"
                sx={{ paddingTop: onlines ? 0 : '7px' }}
                value="online">
                <BadgeUsers badgeContent={onlines}>
                  <PeopleAltIcon
                    style={{ color: view === 'online' ? 'green' : null }}
                  />
                </BadgeUsers>
              </ToggleButton>
              <ToggleButton
                aria-label="centered"
                sx={{ paddingTop: requests ? 0 : '7px' }}
                value="requests">
                <BadgeUsers badgeContent={requests}>
                  <GroupAddIcon
                    style={{
                      color: view === 'requests' ? 'green' : null,
                    }}
                  />
                </BadgeUsers>
              </ToggleButton>
            </ToggleButtonGroup>
            <ButtonGroup
              // className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                className={classes.button}
                color="primary"
                onClick={this.props.handleOpenCreateUser}
                size="small"
                startIcon={<AddIcon />}
                style={{
                  marginLeft: 5,
                  backgroundColor: 'blue',
                  color: 'white',
                }}
                variant="contained">
                Add new user
              </Button>
            </ButtonGroup>
          </Grid>
          {ComponentSearchUsers}
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={this.props.numSelected}
            tooltip="Reload USer"
          />
        </Grid>
      </Paper>
    );
  }
}

ToolBarPatientsActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarPatientsActions.defautlProps = {
  name: '',
  handleChangeName: () => '',
  handleOpenCreateUser: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  loading: false,
  loading_table: false,
  numSelected: 0,
};
export default withStyles(ToolBarPatientsActions, styleToolbar);
