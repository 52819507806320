import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Fab,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import DialogCreatePatient from '../DialogCreatePatient/DialogCreatePatient';
const useStyles = () => ({
  root: {},
});
class TableToolBarSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { numSelected } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: theme =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}>
          {numSelected > 0 ? (
            <Typography
              color="inherit"
              component="div"
              sx={{ flex: '1 1 100%' }}
              variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              component="div"
              id="tableTitle"
              sx={{ flex: '1 1 100%' }}
              variant="h6">
              Patient List
            </Typography>
          )}
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <span style={{ margin: 0, padding: 0 }}>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <React.Fragment>
              <Tooltip title="Add New Patient">
                <Fab
                  aria-label="add"
                  color="secondary"
                  onClick={() => this.setState({ open: true })}
                  size="small">
                  <AddIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Reload">
                <span style={{ margin: 0, padding: 0 }}>
                  <IconButton onClick={this.props.handleReload}>
                    <ReplayIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </React.Fragment>
          )}
        </Toolbar>
        <DialogCreatePatient
          addNewPatient={this.props.handleAddNewPatient}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open: false })}
          open={open}
        />
      </React.Fragment>
    );
  }
}

TableToolBarSurvey.propTypes = {
  classes: PropTypes.object,
};
TableToolBarSurvey.defaultProps = {
  handleAddNewPatient: () => '',
  handleReload: () => '',
};
const ContainerTableToolBarSurvey = props => {
  const theme = useTheme();
  return <TableToolBarSurvey theme={theme} {...props} />;
};
export default withStyles(ContainerTableToolBarSurvey, useStyles);
