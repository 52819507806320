import { FormatTime, getUserFullName } from 'helpers';

export const columns = [
  {
    id: 'Admin',
    label: 'Admin',
    type: 'user',
    minWidth: 100,
    // align: 'right',
    format: value => getUserFullName(value),
  },
  {
    id: 'name',
    label: 'Name',
    type: 'name_only',
    minWidth: 50,
  },
  {
    id: 'ip',
    label: 'IP Address',
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 100,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'status',
    label: 'Status',
    format: value => {
      const reply = {
        name: value,
        type: 'orange',
      };
      if (value === 'online') {
        reply.type = 'green';
      } else if (value === 'offline') {
        reply.type = 'red';
      }
      return reply;
    },
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 50,
    align: 'right',
    type: 'buttom',
  },
];
