import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import clsx from 'clsx';
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LinearScaleIcon from '@mui/icons-material/LinearScale';

const useStyles = theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  differencePositiveIcon: {
    color: theme.palette.success.dark,
  },
  noDifferenceIcon: {
    color: theme.palette.info.dark,
  },
  differenceNegativeIcon: {
    color: theme.palette.error.dark,
  },
  differencePositiveValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
  noDifferenceValue: {
    color: theme.palette.info.dark,
    marginRight: theme.spacing(1),
  },
  differenceNegativeValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
});

const ProgressIcon = props => {
  const { percentage, classes, subtitle } = props;
  return (
    <div className={classes.difference}>
      {percentage === 0 && (
        <>
          <LinearScaleIcon className={classes.noDifferenceIcon} />
          <Typography
            className={classes.noDifferenceValue}
            pl={1}
            variant="body2">
            {`${parseFloat(percentage).toFixed(1)}%`}
          </Typography>
        </>
      )}
      {percentage > 0 && (
        <>
          <ArrowUpwardIcon className={classes.differencePositiveIcon} />
          <Typography
            className={classes.differencePositiveValue}
            pl={1}
            variant="body2">
            {`${parseFloat(percentage).toFixed(1)}%`}
          </Typography>
        </>
      )}
      {percentage < 0 && (
        <>
          <ArrowDownwardIcon className={classes.differenceNegativeIcon} />
          <Typography
            className={classes.differenceNegativeValue}
            pl={1}
            variant="body2">
            {`${parseFloat(percentage).toFixed(1)}%`}
          </Typography>
        </>
      )}
      <Typography className={classes.caption} variant="caption">
        {subtitle}
      </Typography>
    </div>
  );
};

class CardSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title ? props.title : 'TOTAL',
      total: props.total ? props.total : 0,
      Icon: props.icon ? props.icon : PeopleIcon,
      percentage: props.percentage ? props.percentage : 0,
      subtitle: props.subtitle ? props.subtitle : 'Since last Week',
    };
  }

  componentDidUpdate = () => {
    const { total, loading, percentage } = this.props;
    if (loading) {
      return;
    }
    if (total !== this.state.total) {
      this.setState({ total: total });
    }
    if (percentage !== this.state.percentage) {
      this.setState({ percentage: percentage });
    }
  };

  render() {
    const { classes, className, loading, avatar, ...rest } = this.props;
    const { title, total, Icon, percentage, subtitle } = this.state;

    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2">
                {title}
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="h3">{total}</Typography>
              )}
            </Grid>
            <Grid item>
              <Avatar className={clsx(classes.avatar, avatar)}>
                <Icon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.difference}>
            <ProgressIcon
              classes={classes}
              percentage={percentage}
              subtitle={subtitle}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}

CardSummary.propTypes = {
  className: PropTypes.string,
};

export default withStyles(CardSummary, useStyles);
