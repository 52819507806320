import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { ButtonLoading } from 'components';
export const useStyles = theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    background: 'white',
    '& h2': {
      color: 'black',
      fontSize: '20px',
      fontWeight: 1000,
    },
  },
});

class DialogInputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
    const { holder } = props;
    if (holder) {
      holder.cleanText = () => this.setState({ text: '' });
    }
  }
  handleChange = event => {
    event.preventDefault();
    this.setState({ text: event.target.value });
  };
  render() {
    const { classes, open, handleClose } = this.props;
    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => handleClose()}
        open={open}>
        <DialogTitle
          className={classNames(classes.title)}
          id="form-dialog-title">
          {this.props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.description}</DialogContentText>
          <TextField
            className={classes.textField}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label={this.props.input_name}
            name={this.props.input_name}
            onChange={this.handleChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleAddText(this.state.text);
              }
            }}
            value={this.state.text || ''}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <ButtonLoading
            handleClick={() => this.props.handleAddText(this.state.text)}
            name={this.props.text_botton}
            transform={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogInputText.propTypes = {
  description: PropTypes.string,
  handleAddText: PropTypes.func,
  input_name: PropTypes.string,
  open: PropTypes.bool,
  text_botton: PropTypes.string,
  title: PropTypes.string,
};
DialogInputText.defaultProps = {
  title: 'Input Text',
  description: 'Type the input text',
  input_name: 'Text',
  text_botton: 'Ok',
  handleAddText: () => '',
};

export default withStyles(DialogInputText, useStyles);
