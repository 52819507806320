import React from 'react';
import PropTypes from 'prop-types';
import { Grid, RadioGroup } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  useStyles,
  DateTimeLeft,
  DateTimeCenter,
  DateTimeRight,
  DateFromTo,
  AtDate,
} from './components';

class ContentStudyTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleFromDateChange = date => {
    const datestart = date[0].toISOString();
    const dateend = date[1].toISOString();
    this.props.handleFromDateChange(datestart);
    this.props.handleToDateChange(dateend);
  };
  handleAtDateChange = date => {
    if (date === undefined || !date) return;
    try {
      const datestart = date.toISOString();
      this.props.handleAtDateChange(datestart);
    } catch (error) {
      console.log({ error });
    }
  };
  render() {
    const {
      classes,
      radioValue,
      selectedAtDate,
      selectedFromDate,
      selectedToDate,
      // last_minutes,
    } = this.props;
    let disable_from = true;
    let disable_at_date = true;
    // let disable_last_minutes = true;
    if (radioValue === 'from') disable_from = false;
    // else if (radioValue === 'last_minutes"') disable_last_minutes = false;
    else if (radioValue === 'at_date') disable_at_date = false;
    // console.log({ selectedAtDate, selectedFromDate, selectedToDate });
    return (
      <div style={{ fontSize: 10 }}>
        <RadioGroup
          aria-label="radio_query_actions"
          name="radio_query_actions"
          onChange={this.props.handleRadioChange}
          value={radioValue}>
          <Grid
            alignItems="stretch"
            container
            direction="column"
            justifyContent="center">
            <Grid
              alignItems="flex-start"
              container
              // justifyContent="center"
              direction="row">
              <Grid item xs>
                <DateTimeLeft classes={classes} />
              </Grid>
              <Grid item xs>
                <DateTimeCenter classes={classes} />
              </Grid>
              <Grid item xs>
                <DateTimeRight classes={classes} />
              </Grid>
            </Grid>
            <Grid
              alignItems="center"
              container
              direction="row"
              item
              justifyContent="space-between"
              spacing={1}
              xs>
              <Grid item xs={6}>
                <DateFromTo
                  classes={classes}
                  disable_from={disable_from}
                  handleFromDateChange={this.handleFromDateChange}
                  selectedFromDate={selectedFromDate}
                  selectedToDate={selectedToDate}
                />
              </Grid>
              <Grid item xs={6}>
                <AtDate
                  classes={classes}
                  disable_at_date={disable_at_date}
                  handleAtDateChange={this.handleAtDateChange}
                  selectedAtDate={selectedAtDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </RadioGroup>
      </div>
    );
  }
}

ContentStudyTimes.propTypes = {
  handleAtDateChange: PropTypes.func,
  handleFromDateChange: PropTypes.func,
  handleLastMinutesChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleToDateChange: PropTypes.func,
};

export default withStyles(ContentStudyTimes, useStyles);
