import React from 'react';
import PropTypes from 'prop-types';
import SummaryHeader from '../SummaryHeader';
class ViewUnitBlocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      summaries,
      getTotalElements,
      handleUpdateSummary,
      handleAddError,
      user,
      myunit,
    } = this.props;
    return (
      <React.Fragment>
        {summaries.map((summary, index) => {
          const total = getTotalElements(summary);
          return (
            <SummaryHeader
              handleAddError={handleAddError}
              handleUpdateSummary={handleUpdateSummary}
              key={`summary-${index}`}
              myunit={myunit}
              summary={summary}
              total={total}
              user={user}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

ViewUnitBlocks.propTypes = {
  classes: PropTypes.object,
};

export default ViewUnitBlocks;
