import React from 'react';
import PropTypes from 'prop-types';
import GroupBasics from '../GroupBasics';
class GroupClinics extends React.Component {
  constructor(props) {
    super(props);
    this.holder = {
      addMap: null,
      removeMaps: null,
    };
    if (props.holder) {
      props.holder.addClinic = clinic => {
        if (this.holder.addMap) this.holder.addMap(clinic);
      };
      props.holder.removeClinic = () => {
        if (this.holder.removeMaps) this.holder.removeMaps();
      };
    }
    this.holder = {
      cleanRows: null,
      setRows: null,
    };
  }
  render() {
    return (
      <GroupBasics
        handleOpenAddDialog={this.props.handleOpenAddDialog}
        handleOpenRemoveDialog={this.props.handleOpenRemoveDialog}
        holder={this.holder}
        map_name="Clinics Designer"
        title="Clinics"
      />
    );
  }
}

GroupClinics.propTypes = {
  classes: PropTypes.object
};

export default GroupClinics;
