import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from 'tss-react/mui';

import { Checkbox, TableCell, TableRow } from '@mui/material';
// import { ContextMenuTrigger } from 'react-contextmenu';
import { FindKey, getUserFullName, IsUserObject } from 'helpers';
import { useStyles } from './Styles';
import { CellElement } from './components';
import { v1 as uuid } from 'uuid';
class MainTableUserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
    };
  }

  handleClickMouse = e => {
    // eslint-disable-next-line react/no-find-dom-node
    if (ReactDOM.findDOMNode(this).contains(e.target)) {
      this.props.handleRowOver(this.state.row);
    }
  };

  componentDidUpdate() {
    const { row } = this.state;
    if (row !== this.props.row) {
      this.setState({ row: this.props.row });
    }
  }

  componentDidMount() {
    document.addEventListener('mouseover', this.handleClickMouse, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseover', this.handleClickMouse, false);
  }

  render() {
    const {
      classes,
      row,
      handleSelectedRow,
      isItemSelected,
      handleClick,
      columns,
      index,
      dense,
      handleAcceptReject,
      disable_checkbox,
      isHighLighted,
    } = this.props;
    const labelId = `enhanced-table-checkbox-${index}`;
    // console.log({ isHighLighted });
    let TableCellCheckbox = null;
    if (!disable_checkbox) {
      TableCellCheckbox = (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            color="default"
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={event => handleClick(event, index, row)}
          />
        </TableCell>
      );
    }
    return (
      <TableRow
        className={classes.table_row}
        hover
        key={`table-row-${row.id}`}
        onClick={() => handleSelectedRow(row)}
        role="checkbox"
        selected={isItemSelected || isHighLighted}
        style={{
          paddingTop: dense ? 0 : '3px',
        }}
        tabIndex={-1}>
        {TableCellCheckbox}
        {columns.map((column, col_index) => {
          const index_column = col_index + index;
          //
          let value = '';
          if (column.id === 'name' && IsUserObject(row)) {
            value = getUserFullName(row);
          } else if (FindKey(row, column.id)) {
            value = row[column.id];
          }
          if (column.type === 'WithIndex') {
            value = {
              value,
              admin: row.admin,
              i: index_column,
              handleAcceptReject: handleAcceptReject,
              row,
            };
          } else if (column.type === 'full_access') {
            value = {
              row,
              column,
            };
          }
          return (
            <TableCell
              align={column.align}
              key={`cell-key-${uuid()}-${column.id}`}
              style={{
                paddingTop: dense ? 0 : '4px',
                paddingBottom: dense ? 0 : '4px',
              }}>
              {/* <ContextMenuTrigger id={ID}> */}
              {CellElement(column, row, value, this.props, dense, classes)}
              {/* </ContextMenuTrigger> */}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

MainTableUserRow.propTypes = {
  classes: PropTypes.object,
  isHighLighted: PropTypes.bool,
};
MainTableUserRow.defaultProps = {
  handleClickLink: () => '',
  disable_checkbox: false,
  isHighLighted: false,
};
export default withStyles(MainTableUserRow, useStyles);
