import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const CheckAxionHeaders = () => {
  // eslint-disable-next-line no-prototype-builtins
  if (axios.defaults.headers.common.hasOwnProperty('x-token')) {
    return;
  }
  if (localStorage.token) {
    axios.defaults.headers.common['x-token'] = localStorage.token;
  }
  if (localStorage.refreshToken) {
    axios.defaults.headers.common['x-refresh-token'] =
      localStorage.refreshToken;
  }
};
export const setAuthorizationToken = (token, refreshToken) => {
  let user = null;
  if (token) {
    //
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['x-token'] = token;
    axios.defaults.headers.common['x-refresh-token'] = refreshToken;

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    const { user: user_token } = jwtDecode(token);
    user = {
      id: user_token.id,
      type: user_token.type ? user_token.type : 0,
      idSession: user_token.idSession ? user_token.idSession : null,
      idCategory: user_token.idCategory ? user_token.idCategory : 1,
      FullName: user_token.FullName,
      firstName: user_token.firstName,
      lastName: user_token.lastName,
      username: user_token.username,
      bio: user_token.bio ? user_token.bio : 'Bio: Not defined',
      avatar: user_token.avatar
        ? user_token.avatar
        : '/images/avatars/avatar_0.png',
      email: user_token.email,
      phone: user_token.phone ? user_token.phone : '',
      city: user_token.city ? user_token.city : '',
      state: user_token.state ? user_token.state : '',
      country: user_token.country ? user_token.country : '',
      createdAt: user_token.createdAt ? user_token.createdAt : '',
    };
  } else {
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['x-token'];
    delete axios.defaults.headers.common['x-refresh-token'];
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
  return user;
};
