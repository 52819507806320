import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_SESSION = gql`
  query($inputs: InputUserSession, $page: Int, $limit: Int) {
    userSessions(inputs: $inputs, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      sessions {
        id
        idUser

        blocked
        user_agent
        timestamp
        city
        country
        count_logins
        count_refresh

        user {
          id
          FullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUserSessions = (inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_SESSION),
    variables: {
      inputs,
      page,
      limit,
    },
  });
};
const MUTATION_DELETE_USER_SESSIONS = gql`
  mutation($ids: [LongLong!]!) {
    deleteSessions(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteUserSessions = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_USER_SESSIONS),
    variables: {
      ids,
    },
  });
};
