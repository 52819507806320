/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete } from '@mui/material';

class AutoComboBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      value: '',
      defaultValue: null,
    };
    const { holder } = props;
    if (holder) {
      holder.setAutoComboBox = value => {
        this.setState({ value, inputValue: value.name });
      };
    }
  }

  IsDefaultChanged = () => {
    const { defaultValue } = this.props;
    if (defaultValue === undefined || !defaultValue) return false;
    if (!this.state.defaultValue) return true;
    if (
      typeof defaultValue === 'string' &&
      defaultValue.constructor === String
    ) {
      return defaultValue !== this.state.defaultValue;
    }
    const keys = Object.keys(defaultValue);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (defaultValue[key] !== this.state.defaultValue[key]) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    const { defaultValue, options } = this.props;
    if (defaultValue !== undefined) {
      this.setState({ value: defaultValue, defaultValue });
    } else if (options.length) {
      this.setState({ value: options[0], inputValue: options[0].name });
    }
  }

  componentDidUpdate() {
    const { defaultValue } = this.props;
    if (this.IsDefaultChanged()) {
      this.setState({ value: defaultValue, defaultValue });
    }
  }

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };
  handleValueChange = value => {
    this.setState({ value });
  };

  render() {
    const { inputValue, value } = this.state;
    const {
      id,
      width,
      label,
      options,
      enter,
      onObjectChanged,
      variant,
      small,
      holder,
      ...res
    } = this.props;
    return (
      <Autocomplete
        {...res}
        id={id}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          this.handleValueChange(newValue);
          onObjectChanged(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleInputChange(newInputValue);
        }}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label={label}
            onKeyDown={event => {
              const { key } = event;
              if (enter && key === 'Enter') {
                this.handleInputChange('');
                this.props.onObjectChanged(value);
              }
            }}
            variant={variant}
          />
        )}
        size={small ? 'small' : 'medium'}
        style={{ width }}
        value={value}
      />
    );
  }
}

AutoComboBox.propTypes = {
  classes: PropTypes.object,
  enter: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onObjectChanged: PropTypes.func,
  options: PropTypes.array,
  variant: PropTypes.string,
  width: PropTypes.number,
};
AutoComboBox.defaultProps = {
  fullWidth: true,
  id: 'autocomplete',
  width: 300,
  label: 'Unknown',
  options: [],
  enter: false,
  variant: 'outlined',
  onObjectChanged: () => '',
  getOptionLabel: option => {
    if (option.name === undefined) return '';
    return option.name;
  },
  isOptionEqualToValue: (option, value) => {
    if (value) return option.name === value.name;
    else return false;
  },
};
export default AutoComboBox;
