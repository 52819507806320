import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, IconButton } from '@mui/material';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { options_types } from 'helpers/Docker';
import { IsChanged } from '../../../utils';
import DialogDockerMapping from 'components/Dialogs/DialogDockerMapping';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

class OutputDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      output_type_object: null,
      vol_output: '',
      set: false,
      is_configure_mapping: false,
    };
    const { holder } = props;
    if (holder) {
      holder.setOuputVolume = (id, output_type_object, vol_output) =>
        this.setState({ id, output_type_object, vol_output, set: true });
    }
  }
  componentDidMount() {
    if (this.state.set) return;
    const { output } = this.props;
    if (!output || output === undefined) return;
    let output_type_object = null;
    const index = options_types.map(x => x.name).indexOf(output.type);
    if (index !== -1) output_type_object = options_types[index];
    this.setState({
      id: output.id,
      output_type_object,
      vol_output: output.volume,
      set: true,
    });
  }
  componentDidUpdate() {
    if (this.state.set) return;
    const { output } = this.props;
    if (!output || output === undefined) return;
    let output_type_object = null;
    const index = options_types.map(x => x.name).indexOf(output.type);
    if (index !== -1) output_type_object = options_types[index];
    this.setState({
      id: output.id,
      output_type_object,
      vol_output: output.volume,
      set: true,
    });
  }
  isDockerMappingSettingDisabled = () => {
    const { output } = this.props;
    if (output.type.toLowerCase() === 'json') {
      return false;
    }
    return true;
  };
  handleSelectedOutputType = output_type_object => {
    const { settings } = this.props;
    const { id } = this.state;
    let output_type = '';
    if (output_type_object) output_type = output_type_object.name;
    let edited_output = settings.inputs_outputs.find(item => item.id === id);
    if (edited_output) {
      edited_output.type = output_type;
    } else {
      edited_output = {
        id,
        idDockerSetting: settings.id,
        in_out_type: 'output',
        type: output_type,
        volume: '',
      };
      settings.inputs_outputs.push(edited_output);
    }
    const changed = IsChanged(
      { ...settings, edited_element: edited_output },
      this
    );
    this.setState({ output_type_object });
    this.props.handleUpdateSetting(settings);
    this.props.handleChanged(changed);
  };
  handleChangePorts = event => {
    const { settings } = this.props;
    const { id } = this.state;
    const vol_output = event.target.value;
    let edited_output = settings.inputs_outputs.find(item => item.id === id);
    if (edited_output) {
      edited_output.volume = vol_output;
    } else {
      edited_output = {
        id,
        idDockerSetting: settings.id,
        in_out_type: 'output',
        type: '',
        volume: vol_output,
      };
      settings.inputs_outputs.push(edited_output);
    }
    const changed = IsChanged(
      { ...settings, edited_element: edited_output },
      this
    );
    this.setState({ vol_output });
    this.props.handleUpdateSetting(settings);
    this.props.handleChanged(changed);
  };
  handleConfigureMapping = () => {
    this.setState({ is_configure_mapping: true });
    console.log('Open configuration of docker');
  };
  handleRemoveOutput = () => {
    this.props.handleRemoveOutput(this.state.id);
  };
  render() {
    const { disabled, uploading, output } = this.props;
    const { is_configure_mapping, output_type_object } = this.state;
    let output_type = '';
    if (output_type_object) {
      output_type = output_type_object.name;
    }
    let isDockerMappingDisabled = this.isDockerMappingSettingDisabled();
    return (
      <Grid container item spacing={2} xs={12}>
        <Grid item sm={6} style={{ width: '100%' }} xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            id="output-docker-volume"
            InputLabelProps={{
              shrink: true,
            }}
            label="Output Docker Volume"
            onChange={this.handleChangePorts}
            placeholder="Type the output volume name (e.g. /temp)..."
            value={this.state.vol_output}
            variant="filled"
          />
        </Grid>
        <Grid item sm={4} style={{ width: '100%' }} xs={8}>
          <SearchAutocomplete
            disabled={uploading}
            handleAddResultObject={this.handleSelectedOutputType}
            label="Output Data Type"
            name={output_type}
            no_icon
            options={options_types}
            placeholder="Select Out type of your docker..."
            selected={this.state.output_type_object}
            setObject={this.handleSelectedOutputType}
          />
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          justifyContent="flex-start"
          style={{ width: '100%' }}
          xs={2}>
          <IconButton
            color="primary"
            disabled={isDockerMappingDisabled}
            onClick={this.handleConfigureMapping}
            size="small">
            <SettingsIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={this.handleRemoveOutput}
            size="small">
            <RemoveCircleOutlineIcon />
          </IconButton>
          <DialogDockerMapping
            // docker_setting_id={settings.id}
            handleAddError={this.props.handleAddError}
            handleClose={() => this.setState({ is_configure_mapping: false })}
            open_dialog={is_configure_mapping}
            output={output}
          />
        </Grid>
      </Grid>
    );
  }
}

OutputDocker.propTypes = {
  handleUpdateSetting: PropTypes.func,
};
OutputDocker.defaultProps = {
  handleUpdateSetting: () => '',
};

export default OutputDocker;
