import { getPatientName } from 'helpers';
import { IsInvalid } from 'helpers';
import { FormatTime } from 'helpers';

const GetValueParams = (params, key) => {
  if (IsInvalid(params)) return null;
  if (IsInvalid(params.getValue)) return null;
  const value = params.getValue(params.id, key);
  if (IsInvalid(value)) return null;
  return value;
};
export const columns = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'idPatient',
    headerName: 'ID Patient',
    width: 75,
    align: 'left',
  },
  {
    field: 'PatientName',
    headerName: 'PatientName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      const encrypted_patient = GetValueParams(params, 'encrypted_patient');
      if (IsInvalid(encrypted_patient)) return '';
      return getPatientName(encrypted_patient);
    },
  },
  {
    field: 'consent',
    headerName: 'Consent',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const consent = GetValueParams(params, 'consent');
      if (IsInvalid(consent) || consent === '') return 'No';
      if (typeof consent === 'string') {
        if (consent.startsWith('y') || consent.startsWith('Y')) return 'Yes';
        return 'No';
      }
      return consent ? 'Yes' : 'No';
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const type = GetValueParams(params, 'type');
      if (IsInvalid(type)) return '';
      return type;
    },
  },
  {
    field: 'information',
    headerName: 'Information',
    width: 75,
    align: 'left',
    edit: true,
    valueGetter: params => {
      const information = GetValueParams(params, 'information');
      if (IsInvalid(information)) return '';
      return information;
    },
  },
  {
    field: 'file_path',
    headerName: 'FileName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      const file_path = GetValueParams(params, 'file_path');
      if (IsInvalid(file_path) || file_path === '') return '';
      const filename = file_path.match(/[^/]+$/)[0];
      return filename;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      const createdAt = GetValueParams(params, 'createdAt');
      if (IsInvalid(createdAt)) return '';
      return FormatTime(createdAt, 'MMM Do - YYYY, h:mm a');
    },
  },
];
