import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { ListObjectAccessLevel } from '../utils_project_objects';

class AutoProjectObjectFilter extends React.Component {
  constructor(props) {
    super(props);
    const { access_level_index } = props;
    this.state = {
      access_level_index,
    };
  }
  componentDidUpdate() {
    const { access_level_index } = this.props;
    if (this.state.access_level_index !== access_level_index) {
      this.setState({ access_level_index });
    }
  }
  handleAccessLevelChanged = access => {
    console.log({ access });
    const access_level_index = ListObjectAccessLevel.indexOf(access);
    this.setState({ access_level_index });
    this.props.handleAccessLevelChanged(access_level_index);
  };
  render() {
    const { access_level_index } = this.state;
    // console.log({ access_level_index });
    let value = null;
    if (
      access_level_index >= 0 &&
      access_level_index < ListObjectAccessLevel.length
    ) {
      value = ListObjectAccessLevel[access_level_index];
    }
    return (
      <Grid item>
        <Autocomplete
          id={`objec-filter-${uuid()}`}
          onChange={(event, newValue) => {
            this.handleAccessLevelChanged(newValue);
          }}
          options={ListObjectAccessLevel}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label="Search Filter"
              size="small"
              variant="standard"
            />
          )}
          size="small"
          style={{
            width: '100%',
            minWidth: this.props.minWidth,
            paddingTop: -10,
          }}
          value={value}
        />
      </Grid>
    );
  }
}

AutoProjectObjectFilter.propTypes = {
  handleAccessLevelChanged: PropTypes.func,
};
AutoProjectObjectFilter.defaultProps = {
  handleAccessLevelChanged: () => '',
  access_level_index: -1,
  minWidth: 220,
};

export default AutoProjectObjectFilter;
