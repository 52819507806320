export const useStyles = () => ({
  root: {
    // display: 'flex',
    height: '100%',
  },
  root_container: {
    width: '100%',
    // height: '65vh',
    overflow: 'auto',
  },
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'scroll',
  },
});
