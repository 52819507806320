import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Paper,
  ListItemText,
  ListItem,
  List,
  Divider,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  DialogDeleteWarning,
  DialogGroupMapping,
  IconButtonLoad,
} from 'components';
import { QueryGroupTypes } from 'graphql/Mapping';
import { ServerErrorsString, GetData } from 'helpers';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  maxHeight: 200,
  overflow: 'auto',
}));

class ListKeywords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      to_delete: null,
      open: false,
      open_add: false,
      keywords: [],
      group_keyword: null,
    };
    this.holder = {
      cleanText: null,
    };
  }
  componentDidMount() {
    this.updateGroupKeyword();
  }
  componentDidUpdate() {
    const { keywords } = this.props;
    if (keywords && keywords.length !== this.state.keywords.length) {
      this.setState({ keywords });
    }
  }
  updateGroupKeyword = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryGroupTypes(this.props.group_type)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, groups } = data.allGroupTypes;
          if (ok) {
            if (groups.length) {
              this.setState({ group_keyword: groups[0], loading: false });
            } else {
              this.setState({ loading: false });
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  // id
  // idGroup
  // group {
  //   name
  // }
  // name
  handleDeleteWarningClick = () => {
    let { keywords, to_delete, group_keyword } = this.state;
    if (to_delete === undefined || !to_delete) {
      return;
    }
    const index = keywords.map(x => x.id).indexOf(to_delete.id);
    if (index === -1) return;
    keywords.splice(index, 1);
    this.setState({ open: false, to_delete: null });
    let idGroup = -1;
    if (group_keyword) {
      idGroup = group_keyword.id;
    }
    this.props.handleUpdateKeywords(idGroup, keywords);
  };
  handleAddKeyword = keyword => {
    console.log('handleAddKeyword');
    if (keyword === undefined || keyword === '') {
      this.props.handleAddError(`${this.props.group} is empty`);
      return;
    }
    let { keywords, group_keyword } = this.state;
    const index = keywords.map(x => x.name).indexOf(keyword);
    if (index === -1) {
      let idGroup = -1;
      let group = {
        name: this.props.group_type,
      };
      if (group_keyword) {
        idGroup = group_keyword.id;
        group = group_keyword;
      }
      keywords.push({
        id: -(keywords.length + 1),
        idGroup,
        group,
        name: keyword,
      });
      this.setState({ open_add: false });
      this.holder.cleanText();
      this.props.handleUpdateKeywords(idGroup, keywords);
    } else {
      console.log('index');
      this.props.handleAddError(
        `${this.props.group} ${keyword} is already here`
      );
    }
  };
  handleOpenKeyword = () => {
    // event.preventDefault();
    console.log('IconButton');
    this.setState({ open_add: true });
  };
  render() {
    const { keywords, open, open_add } = this.state;
    const { group } = this.props;
    // console.log({ open_add });
    return (
      <Paper sx={{ width: '100%', padding: 1, paddingBottom: 2 }}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          sx={{ width: '100%', paddingRight: 2 }}>
          <Typography component="div" variant="h6">
            {`${group} of the image`}
          </Typography>
          <IconButtonLoad
            disabled={this.state.open_add}
            handleClick={() => this.handleOpenKeyword()}
            icon={<AddIcon sx={{ color: 'green' }} />}
            tooltip={`Add ${group}`}
          />
        </Grid>
        <Divider />
        <Demo>
          <List
            dense
            sx={{
              width: '100%',
              // maxWidth: 360,
              bgcolor: 'background.paper',
            }}>
            {keywords.map((keyword, index) => {
              const { name } = keyword;
              return (
                <React.Fragment key={`item-${group}-${index}`}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        aria-label="delete"
                        edge="end"
                        onClick={() =>
                          this.setState({ open: true, to_delete: keyword })
                        }
                        sx={{ color: 'red' }}>
                        <DeleteIcon />
                      </IconButton>
                    }>
                    <ListItemText
                      primary={name ? name : 'Empty'}
                      // secondary="Secondary text"
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </Demo>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open: false })}
          handleDeleteFolders={this.handleDeleteWarningClick}
          open={open}
          question_text="You are about to delete a keyword, this action cannot be undone."
          title_text={`Delete ${group}`}
        />
        <DialogGroupMapping
          description="Type the new keyboard, this need to be unique"
          group_name={this.props.group_type}
          handleAddError={this.props.handleAddError}
          handleAddKeyword={this.handleAddKeyword}
          handleClose={() => this.setState({ open_add: false })}
          holder={this.holder}
          input_name={group}
          open={open_add}
          text_botton="Add"
          title={`Create a ${group}`}
        />
      </Paper>
    );
  }
}

ListKeywords.propTypes = {
  keywords: PropTypes.array,
};
ListKeywords.defaultProps = {
  handleUpdateKeywords: () => '',
  keywords: [],
  group_type: 'Keyword Designer',
  group: 'Keyword',
};

export default ListKeywords;
