import React from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import {
  TableHead,
  TableSortLabel,
  TableRow,
  Checkbox,
  TableCell,
  Box,
} from '@mui/material';

class TableHeadSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  createSortHandler = (event, property) => {
    this.props.onRequestSort(event, property);
  };
  render() {
    const {
      rowCount,
      numSelected,
      onSelectAllClick,
      headers,
      orderBy,
      order,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headers.map(headCell => (
            <TableCell
              align={headCell.numeric ? 'right' : 'left'}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => this.createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeadSurvey.propTypes = {
  onRequestSort: PropTypes.func,
};
TableHeadSurvey.defaultProps = {
  onRequestSort: () => '',
};

export default TableHeadSurvey;
