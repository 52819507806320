import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import {
  Box,
  Typography,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { getDatePacs, getTimePacs } from 'helpers';
import { ImageResolution, ImageSize } from 'graphql/Series';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BlockIcon from '@mui/icons-material/Block';
import { IsInvalid } from 'helpers';
class TableRowQueryResultSeries extends Component {
  handleOpenExplorer = Series => {
    if (IsInvalid(Series)) return;
    const { locations } = Series;
    console.log({ Series, locations });
    if (IsInvalid(locations) || !locations.length) return;
    this.props.history.push(`/explorer/folder/${locations[0].idFolder}`);
  };
  render() {
    const {
      classes,
      Series,
      selected,
      handleSelectedSeries,
      selectedStudy,
    } = this.props;
    let idStudy = null;
    if (Series && Series.length) idStudy = Series[0].idStudy;
    return (
      <Box margin={1}>
        <Typography
          component="div"
          gutterBottom
          sx={{ fontWeight: 1000 }}
          variant="subtitle1">
          {`Series at Study (${idStudy})`}
        </Typography>
        <Table aria-label="purchases" size="small" stickyHeader>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell style={{ margin: 0, padding: 0, width: '30px' }} />
              <TableCell>ID</TableCell>
              <TableCell>SeriesDescription</TableCell>
              <TableCell>Modality</TableCell>
              <TableCell>Sequence</TableCell>
              <TableCell>#Files</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Spacing</TableCell>
              <TableCell align="right">SeriesDate</TableCell>
              <TableCell align="right">SeriesTime</TableCell>
              <TableCell>ImageType</TableCell>
              <TableCell>SeriesInstanceUID</TableCell>
              <TableCell>#Locations</TableCell>
              <TableCell>Open</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Series.map(row => {
              const { idSeries, sequence } = row;
              let isItemSelected =
                selected.map(x => x.idSeries).indexOf(idSeries) !== -1;
              if (!selected.length) isItemSelected = selectedStudy;
              let sequence_name = '';
              if (sequence) sequence_name = sequence.name;
              const total_locations = row.locations ? row.locations.length : 0;
              let IconOpen = BlockIcon;
              if (total_locations) IconOpen = OpenInNewIcon;
              return (
                <TableRow key={`row-query-series-result${idSeries}`}>
                  <TableCell
                    style={{
                      marginLeft: '20px',
                      marginRight: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      padding: 0,
                      width: '20px',
                    }}>
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': idSeries }}
                      onClick={() => {
                        handleSelectedSeries(row);
                      }}
                      style={{ margin: 0, padding: 0 }}
                    />
                  </TableCell>
                  <TableCell>{row.idSeries}</TableCell>

                  <TableCell component="th" scope="row">
                    {row.SeriesDescription}
                  </TableCell>
                  <TableCell>{row.Modality}</TableCell>
                  <TableCell>{sequence_name}</TableCell>
                  <TableCell>{row.NumberOfSeriesRelatedInstances}</TableCell>
                  <TableCell>{ImageSize(row.ImageSize)}</TableCell>
                  <TableCell>{ImageResolution(row.ImageResolution)}</TableCell>
                  <TableCell align="right">
                    {getDatePacs(row.SeriesDate)}
                  </TableCell>
                  <TableCell align="right">
                    {getTimePacs(row.SeriesTime)}
                  </TableCell>
                  <TableCell>{row.ImageType}</TableCell>
                  <TableCell>{row.SeriesInstanceUID}</TableCell>
                  <TableCell align="center">{total_locations}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      disabled={!total_locations}
                      onClick={() => this.handleOpenExplorer(row)}
                      size="small">
                      <IconOpen
                        fontSize="inherit"
                        style={{ color: total_locations ? 'green' : 'red' }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}

TableRowQueryResultSeries.propTypes = {
  Series: PropTypes.array.isRequired,
  handleSelectedSeries: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};
TableRowQueryResultSeries.defaultProps = {
  selected: [],
};
export default withRouter(TableRowQueryResultSeries);
