import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { columns_people } from './utils';

class TabPeople extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      people: [],
      selected: [],
      total: 0,
      limit: 50,
    };
    const { holder } = props;
    if (holder) {
      holder.reloadPeople = () => this.handleLoadPeople();
      holder.reloadPeopleClean = () => this.setState({ name: '' });
    }
  }
  handlePageChange = () => {
    // this.handleUpdate(null, page, this.state.limit);
  };
  handleRowsPerPageChange = () => {
    // this.handleUpdate(null, 0, limit);
  };
  handleLoadPeople = () => {};
  render() {
    const { height } = this.props;
    return (
      <div>
        <TableGrid
          checkboxSelection
          dense
          external_selected={this.state.selected}
          headers={columns_people}
          height={height - 260}
          idRow="idPatient"
          loading={this.state.loading}
          onPageChange={newPage => this.handlePageChange(newPage)}
          onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.people}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabPeople.propTypes = {
  classes: PropTypes.object,
};

export default TabPeople;
