import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './reducers';

// const initialState = {};
const middleware = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
let ini_store = null;
const redux_tool =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (window.navigator.userAgent.includes('Safari')) {
  ini_store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware))
    // compose(applyMiddleware(...middleware))
  );
  // eslint-disable-next-line no-undef
} else if (
  process.env.REACT_APP_ENV === 'production' ||
  typeof redux_tool === 'undefined'
) {
  ini_store = createStore(
    persistedReducer,
    compose(applyMiddleware(...middleware))
  );
} else {
  ini_store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
}
export const store = ini_store;
export const persistor = persistStore(store);
