import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';

import { TableSurveysRow } from './components';
class TableSurveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      neuroSurveys,
      onClickContinueSurvey,
      onClickShowSurvey,
    } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table
          aria-label="a dense table"
          size="small"
          sx={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#e0e0e0' }}>
              <TableCell>Id</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Last Update</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Patient Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {neuroSurveys.map((row, index) => (
              <TableSurveysRow
                handleAddError={this.props.handleAddError}
                handleUpdateSurvey={this.props.handleUpdateSurvey}
                key={`row-table-survey-${index}`}
                onClickContinueSurvey={onClickContinueSurvey}
                onClickShowSurvey={onClickShowSurvey}
                row={row}
              />
            ))}
            {neuroSurveys.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant="body1">
                    There are no surveys...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TableSurveys.propTypes = {
  neuroSurveys: PropTypes.array,
};
TableSurveys.defaultProps = {
  neuroSurveys: [],
  onClickShowSurvey: () => '',
  onClickContinueSurvey: () => '',
  handleUpdateSurvey: () => '',
};

export default TableSurveys;
