import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { List, ListItem } from '@mui/material';
import { CardEditSeries } from 'components';
import { copySeries } from 'redux-store/actions/file_folder_manager';

const useStyles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
});

class EditMultiContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
    const { holder } = props;
    if (holder) {
      holder.removeRow = selected => {
        const { copy_series } = this.props;
        const series = [];
        for (let i = 0; i < copy_series.length; i++) {
          const { idSeries } = copy_series[i];
          if (selected.indexOf(idSeries) === -1) {
            series.push({ ...copy_series[i] });
          }
        }

        this.props.copySeries(series);
      };
      holder.getCopySeries = () => this.props.copy_series;
    }
  }

  addError = error => {
    const { errors } = this.state;
    if (errors.indexOf(error) === -1) {
      errors.push(error);
      this.setState({ errors });
      this.props.handleError(`- ${errors.join('\n- ')}`);
    }
  };

  render() {
    const { copy_series, holder, handleSelectRow, selected } = this.props;
    console.log({ copy_series, selected });
    return (
      <React.Fragment>
        <List disablePadding key="list-edit-series">
          {copy_series.map((Series, i) => {
            const isItemSelected = selected.indexOf(Series.idSeries) !== -1;
            return (
              <ListItem key={i}>
                <CardEditSeries
                  addError={this.addError}
                  expanded={false}
                  handleSelectRow={handleSelectRow}
                  holder={holder}
                  isItemSelected={isItemSelected}
                  Series={Series}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    );
  }
}

EditMultiContent.propTypes = {
  classes: PropTypes.object,
  handleError: PropTypes.func,
};
EditMultiContent.defaultProps = {};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { copySeries })(
  withStyles(EditMultiContent, useStyles)
);
