import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Fab } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import useStyles from './useStyles';
import TableSeriesDetails from 'components/Tables/TableSeriesDetails';
import { MainTableToolbar } from 'components/Tables/components';
import { SelectedArray } from 'helpers';

class CardEditListDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      rows: props.rows ? props.rows : [],
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.setRows = rows => {
        this.setState({ rows });
      };
      props.holder.addNewDetail = item => {
        let { rows } = this.state;
        if (!item) return;
        const i = rows.map(x => x.name).indexOf(item.name);
        if (i === -1) {
          rows = [item, ...rows];

          this.setState({ rows });
        }
      };
      props.holder.getDetails = () => {
        return this.state.rows;
      };
      props.holder.cleanRows = () => {
        this.setState({ rows: [] });
      };
      props.holder.removeDetail = () => {
        const { selected } = this.props;
        const { rows } = this.state;
        let new_rows = [];
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (selected.map(x => x.name).indexOf(row.name) === -1) {
            new_rows.push({ ...row });
          }
        }
        this.setState({ rows: new_rows });
      };
    }
  }

  componentDidMount() {
    const { rows } = this.props;
    if (rows) {
      this.setState({ rows });
    }
  }

  componentDidUpdate() {
    const { rows } = this.props;
    if (
      rows &&
      rows.length !== this.state.rows.length &&
      !this.state.rows.length
    ) {
      this.setState({ rows });
    }
  }

  handleExpandClick = () => {
    this.setState(prev => ({ expanded: !prev.expanded }));
  };
  handleSelectClick = (event, index, row) => {
    const { selected } = this.props;
    const selectedIndex = selected.map(x => x.name).indexOf(row.name);
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.props.handleSelected(newSelected);
  };
  handleSelectAllClick = event => {
    const { rows } = this.state;
    if (event.target.checked) {
      this.props.handleSelected(rows);
    } else {
      this.props.handleSelected([]);
    }
  };
  isSelected = row => {
    const { selected } = this.props;
    return selected.map(x => x.name).indexOf(row.name) !== -1;
  };

  render() {
    const {
      classes,
      selected,
      title,
      handleCreateDetailDialog,
      handleClearSelected,
      handleDelete,
      loading,
      height,
    } = this.props;
    const { rows } = this.state;
    let ComponentAdditional = null;
    if (!selected.length) {
      ComponentAdditional = (
        <Fab
          aria-label="add"
          className={classes.button}
          disabled={loading}
          onClick={() => {
            handleCreateDetailDialog(true);
          }}
          size="small">
          <AddIcon />
        </Fab>
      );
    }

    return (
      <Card className={classes.root}>
        <MainTableToolbar
          ComponentAdditional={ComponentAdditional}
          handleClearSelected={handleClearSelected}
          handleDeleteSelected={() => {
            handleDelete(selected);
          }}
          handleReloadTable={this.props.handleReloadTable}
          loading={loading}
          numSelected={selected.length}
          title={title}
        />
        <div className={classes.tableWrapper} style={this.props.style}>
          <TableSeriesDetails
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectClick={this.handleSelectClick}
            height={height - 90}
            isSelected={this.isSelected}
            maxHeight={300}
            minHeight={250}
            rows={rows}
            selected={selected}
          />
        </div>
      </Card>
    );
  }
}

CardEditListDefault.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
};
CardEditListDefault.defaultProps = {
  style: null,
  handleReloadTable: () => '',
  handleDelete: () => '',
  handleClearSelected: () => '',
};

export default withStyles(CardEditListDefault, useStyles);
