import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Subscription } from '@apollo/client/react/components';
import { IsInvalid } from 'helpers';

const SUBSCRIPTION_JOB_FINISHED = gql`
  subscription($ids: [LongLong!]!) {
    jobFinished(ids: $ids) {
      id
      idUser
      idBatch
      idSetting
      idSeries
      idFileObject
      idFolder

      status
      docker_image {
        id
        operation
      }
      user {
        id
        FullName
      }
      setting {
        id
        idJob
        job_name
        own_folder
        on_content
        idFolder
        error
        folder_content {
          idFolderContent
          project {
            id
            name
          }
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        folder_project {
          idFolder
          name
        }
      }
      series {
        idSeries
        SeriesDescription
      }
      file_object {
        idFileObject
        original_name
      }
      folder {
        idFolder
        name
      }
      createdAt
      updatedAt
    }
  }
`;

class SubsJobFinished extends React.Component {
  constructor(props) {
    super(props);
  }
  onSubscriptionDataNew = ondata => {
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { jobFinished } = data;
      this.props.handleJobFinished(jobFinished);
    }
  };
  render() {
    let { children, ids } = this.props;
    // console.log('SubsJobFinished', { ids });
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataNew}
        shouldResubscribe
        subscription={SUBSCRIPTION_JOB_FINISHED}
        variables={{ ids }}>
        {sub_job_finished => {
          if (!children) {
            return null;
          }
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              load_subscription: sub_job_finished.loading,
              error: sub_job_finished.error,
            });
          });
        }}
      </Subscription>
    );
  }
}

SubsJobFinished.propTypes = {
  handleJobFinished: PropTypes.func,
};
SubsJobFinished.defaultProps = {
  handleJobFinished: () => '',
  ids: [],
};

export default SubsJobFinished;
