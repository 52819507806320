import React from 'react';
import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const MuiCheckBox = styled(Checkbox)(() => ({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
}));

const GreenCheckbox = props => {
  return <MuiCheckBox color="default" {...props} />;
};
export default GreenCheckbox;
