import React from 'react';
import PropTypes from 'prop-types';
import {
  QuerySeriesSequenceMappings,
  MutationDeleteSeriesSeuenceMappings,
} from 'graphql/Series/utils_series_sequences';
import { GetData, ServerErrorsString, SelectedArray } from 'helpers';
import { TableSeriesSequenceMapping, DialogQuestion } from 'components';
class MappedSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      // open_create: false,
      // open_edit: false,
      name: null,
      deleting: false,
      loading: false,
      page: 0,
      limit: 50,
      selected: [],
      mappings: [],
    };
  }
  componentDidMount() {
    this.runQuerySeriesSequenceMaps();
  }
  runQuerySeriesSequenceMaps = (newPage = null, newLimit = null) => {
    let { loading, page, limit, name } = this.state;
    if (loading) {
      return;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QuerySeriesSequenceMappings({ name }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, total, errors } = data.seriesSequenceMappings;
          if (ok) {
            this.setState({
              mappings,
              total,
              loading: false,
              page,
              limit,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-UsersProjects', error);
          this.setState({ types: [], loading: false, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.runQuerySeriesSequenceMaps(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.runQuerySeriesSequenceMaps(0, +event.target.value);
  };
  runDeleteSelected = () => {
    const { deleting, selected, mappings } = this.state;
    if (deleting || selected.length === 0) {
      return;
    }
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteSeriesSeuenceMappings(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSeriesSeuenceMappings;
          if (ok) {
            this.setState({
              mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
              deleting: false,
              selected: [],
              open_delete: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log('catch-MutationDeleteSeriesSeuenceMappings', error);
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    let { height } = this.props;
    if (height) {
      height = height - 18;
    }
    return (
      <div style={{ padding: 10 }}>
        <TableSeriesSequenceMapping
          deleting={this.state.deleting}
          disable_splice
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDeleteSelected={() => this.setState({ open_delete: true })}
          handleReloadTable={this.runQuerySeriesSequenceMaps}
          handleSelectedClick={this.handleSelectedClick}
          height={height}
          limit={this.state.limit}
          loading={this.state.loading}
          mappings={this.state.mappings}
          page={this.state.page}
          selected={this.state.selected}
          total={this.state.total}
        />
        <DialogQuestion
          button_name="Delete"
          handleAction={this.runDeleteSelected}
          handleClose={() => this.setState({ open_delete: false })}
          message="This action cannot be undone. Do you want to continue?"
          open={this.state.open_delete}
          title="Delete Selected Mappings"
        />
      </div>
    );
  }
}

MappedSeries.propTypes = {
  classes: PropTypes.object,
};

export default MappedSeries;
