import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns } from './index';
import { rowsPerPageBig } from 'helpers';

class TableFullTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      page: 0,
      limit: 100,
    };
    if (props.holder) {
      props.holder.getPageLimit = () => {
        const { page, limit } = this.state;
        return { page, limit };
      };
    }
  }
  handleSelected = (selected, details) => {
    console.log({ selected, details });
  };
  handleChangePage = newPage => {
    this.props.handleChangePageLimit(newPage, this.state.limit);
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = limit => {
    this.props.handleChangePageLimit(this.state.page, limit);
    this.setState({ page: 0, limit });
  };
  render() {
    const { height, mappings, loading } = this.props;
    let total_height = 300;
    if (height) total_height = height - 145;
    if (total_height < 0) total_height = 300;
    return (
      <div style={{ height: total_height, width: '100%' }}>
        <TableGrid
          checkboxSelection
          headers={columns}
          height={total_height}
          loading={loading}
          onPageChange={this.handleChangePage}
          onPageSizeChange={this.handleChangeRowsPerPage}
          onSelectionModelChange={this.handleSelected}
          pageSize={this.state.limit}
          pagination
          paginationMode="server"
          rows={mappings}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TableFullTypes.propTypes = {
  classes: PropTypes.object,
};

export default TableFullTypes;
