import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
export const CheckDate = date => {
  if (date === undefined || !date) return null;
  if (!(date instanceof String)) {
    console.log('wrong format');
    return null;
  }
  const parts = date.split('-');
  if (parts.length !== 3) return null;
  let year = null;
  let month = null;
  let day = null;
  if (parts[0].length === 4) year = parts[0];
  else return null;
  if (parts[1].length > 2 || parts[1].length === 0) return null;
  else if (parts[1].length === 2) month = parts[1];
  else if (parts[1].length === 1) month = '0' + parts[1];
  else return null;
  if (parts[2].length > 2 || parts[2].length === 0) return null;
  else if (parts[2].length === 2) day = parts[2];
  else if (parts[2].length === 1) day = '0' + parts[2];
  else return null;
  return year + '-' + month + '-' + day;
};
export const useStyles = makeStyles()(() => ({
  fontSize: {
    fontSize: 12,
    paddingRight: 0,
  },
  base: {
    '&.MuiInputBase-input': {
      paddingRight: 0,
    },
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 1,
    },
  },
}));
export const useStylesPicker = makeStyles()(() => ({
  fontSize: {
    fontSize: 12,
    width: '100%',
  },
}));
export const FormatDate = text => {
  if (text === undefined || !text) return text;
  const parts = text.trim().split('/');
  return `${parts[1]}/${parts[0]}/${parts[2]}`;
};
export const IsWrongFormat = text => {
  text = text.trim();
  const parts = text.split('/');
  if (parts.length !== 3) return true;
  if (parts[0].trim().length !== 2) return true;
  if (parts[1].trim().length !== 2) return true;
  if (parts[2].trim().length !== 4) return true;
  const day = parseInt(parts[0], 10);
  if (day < 1 || day > 31) return true;
  const month = parseInt(parts[1], 10);
  if (month < 1 || month > 12) return true;
  return false;
};
export const IsGreaterThan = (A, B, format) => {
  const a = moment(A.trim(), format);
  const b = moment(B.trim(), format);
  const reply = a.diff(b);
  console.log({ reply, A, B });
  return reply >= 1;
};
