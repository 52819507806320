import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clean_manager_error,
  copy,
  cut,
  paste,
} from 'redux-store/actions/file_folder_manager';

class ExplorerMenuCut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.holder) {
      props.holder.openCut = () => this.handleCut();
    }
  }
  handleCut = () => {
    const { itemOver, selected_objects } = this.props;
    if (itemOver || (selected_objects && selected_objects.length)) {
      if (selected_objects && selected_objects.length)
        this.props.cut(selected_objects);
      else this.props.cut([itemOver]);
      this.props.cleanSelections();
    } else {
      this.props.handleAddError(
        'To Cut you need to select (Folder/Object) first.'
      );
    }
  };
  render() {
    return null;
  }
}

ExplorerMenuCut.propTypes = {
  itemOver: PropTypes.object,
};

const mapStateToProps = state => {
  //
  const { manager } = state;
  const objects = manager.cut.concat(manager.copy).concat(manager.copy_series);

  return {
    cut_objects: manager.cut,
    copy_objects: manager.copy,
    copy_series: manager.copy_series,
    loading_paste: manager.loading_paste,
    error_manager: manager.error_manager,
    warning_manager: manager.warning_manager,
    size: objects.length,
  };
};
export default connect(mapStateToProps, {
  cut,
  copy,
  paste,
  clean_manager_error,
})(ExplorerMenuCut);
