import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { connect } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  ButtonLoading,
  SnackMessage,
  DateRangePicker,
  SearchProjectsAtUser,
} from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { setDashboardSetting } from 'redux-store/actions/page_settings';

import {
  MutationUpdateDashboardSettings,
  QueryUserDashboard,
} from 'graphql/Users/utils_dashboard';
import { SettingLatest, SettingRegions } from './components';
import moment from 'moment';
import { IsInvalid } from 'helpers';
import { IsUserClinicalTrial, projectFilterOptions } from 'common';

let fitler_options = projectFilterOptions;

const CardRoot = styled(Card)(() => ({}));

const DashboardSettings = props => {
  const {
    className,
    dashboard_view,
    user,
    setDashboardSetting,
    ...rest
  } = props;
  if (user && IsUserClinicalTrial(user)) {
    fitler_options = [
      { value: 3, label: 'Project' }, // current project related
      { value: 4, label: 'Mine' }, // filter only my data
    ];
  }
  const [errors, setErrors] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [settings_saved, setSettingsSaved] = React.useState(null);
  const [settings, setSettings] = React.useState({
    idDashboard: 0,
    datestart: moment()
      .subtract(1, 'day')
      .toISOString(),
    dateend: moment().toISOString(),
  });
  React.useEffect(() => {
    handleLoad();
  }, []);
  const handleLoad = () => {
    if (loading) return;
    setLoading(true);
    console.log('handleLoad');
    (async () => {
      QueryUserDashboard(user.id)
        .then(res => {
          const data = GetData(res);
          const { userDashboard } = data;
          console.log('handleLoad', { userDashboard });
          if (userDashboard) {
            const { ok, setting_dashboard } = userDashboard;
            if (ok) {
              setSettings(setting_dashboard);
              setSettingsSaved({ ...setting_dashboard });
              if (setting_dashboard.visualization_mode) {
                setDashboardSetting({
                  ...dashboard_view,
                  visualization_mode: setting_dashboard.visualization_mode,
                });
              }
              setLoading(false);
            } else {
              throw userDashboard.errors;
            }
          }
        })
        .catch(error => {
          setErrors(ServerErrorsString(error));
          setLoading(false);
        });
    })();
  };
  const handleCheckChange = event => {
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    setDashboardSetting({
      ...dashboard_view,
      [name]: checked,
    });
  };
  const handleSaveDashboard = () => {
    if (loading) return;
    setLoading(true);
    const to_save = {
      idDashboard: settings.idDashboard,
      idUser: user.id,
      fullHistory: settings.fullHistory,
      datestart: settings.datestart,
      dateend: settings.dateend,
      visualization_mode: settings.visualization_mode,
      idProject: settings.selected_project
        ? settings.selected_project.id
        : undefined,
    };
    (async () => {
      MutationUpdateDashboardSettings(to_save)
        .then(res => {
          const data = GetData(res);
          const { updateDashboardSetting } = data;
          if (updateDashboardSetting) {
            const { ok } = updateDashboardSetting;
            if (ok) {
              setLoading(false);
              setSettingsSaved({
                ...to_save,
                selected_project: settings.selected_project,
              });
            } else {
              throw updateDashboardSetting.errors;
            }
          }
        })
        .catch(error => {
          setErrors(ServerErrorsString(error));
          setLoading(false);
        });
    })();
  };

  const handleCloseError = () => {
    setErrors('');
  };
  //
  let visualization_mode = 1,
    datestart = null,
    dateend = null,
    fullHistory = true,
    selected_project = null;
  if (dashboard_view) {
    visualization_mode = dashboard_view.visualization_mode;
    selected_project = dashboard_view.selected_project;
    datestart = dashboard_view.datestart;
    dateend = dashboard_view.dateend;
    fullHistory = dashboard_view.fullHistory;
  }
  if (visualization_mode === undefined) visualization_mode = 1;
  if (IsInvalid(datestart)) datestart = settings.datestart;
  if (IsInvalid(dateend)) dateend = settings.dateend;
  const handleProjectSelection = selected_project => {
    setSettings({
      ...settings,
      selected_project: selected_project,
    });
    setDashboardSetting({
      ...dashboard_view,
      selected_project: selected_project,
    });
  };
  const handleDateChange = date => {
    setSettings({
      ...settings,
      datestart: date[0].toISOString(),
      dateend: date[1].toISOString(),
    });
    setDashboardSetting({
      ...dashboard_view,
      datestart: date[0].toISOString(),
      dateend: date[1].toISOString(),
    });
  };
  const handleHistory = event => {
    const fullHistory = event.target.checked;
    let datestart = null;
    let dateend = null;
    if (!fullHistory) {
      datestart = settings.datestart;
      dateend = settings.dateend;
    }
    setDashboardSetting({
      ...dashboard_view,
      fullHistory,
      datestart,
      dateend,
    });
  };
  const IsModified = () => {
    if (IsInvalid(settings_saved)) return true;
    if (
      settings.datestart !== undefined &&
      settings_saved.datestart !== settings.datestart
    )
      return true;
    if (
      (settings.dateend !== undefined && settings_saved.dateend) !==
      settings.dateend
    )
      return true;
    if (
      settings.visualization_mode !== undefined &&
      settings_saved.visualization_mode !== settings.visualization_mode
    )
      return true;
    let idA = null;
    let idB = null;
    if (settings_saved.selected_project)
      idA = settings_saved.selected_project.id;
    if (settings.selected_project) idB = settings.selected_project.id;
    if (idA !== idB) return true;
    return false;
  };
  const handleVisualizationMode = (event, new_selection) => {
    new_selection = new_selection ? new_selection.value : 4;
    let selected_project = dashboard_view.selected_project;
    if (new_selection !== 3) selected_project = null;
    setDashboardSetting({
      ...dashboard_view,
      visualization_mode: new_selection,
      selected_project,
    });
    setSettings({
      ...settings,
      visualization_mode: new_selection,
      selected_project,
    });
  };
  let ComponentInterval = null;
  // console.log({ datestart, dateend });
  if (user.type === 1 || user.type === 6) {
    ComponentInterval = (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="flex-start"
        md={6}
        sm={5}
        xs={12}>
        <Grid
          item
          sx={{
            minHeight: selected_project ? 10 : 50,
          }}
          xs={12}>
          <Typography
            gutterBottom
            sx={{ fontSize: 14, margin: 0 }}
            variant="h6">
            Interval
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fullHistory}
                disabled={loading}
                name="history"
                onChange={handleHistory}
              />
            }
            label="Full History"
          />
        </Grid>
        <Grid item xs={12}>
          <DateRangePicker
            dateend={dateend}
            datestart={datestart}
            disabled={fullHistory ? true : false}
            handleDateChange={handleDateChange}
            small
          />
        </Grid>
        <Grid item />
      </Grid>
    );
  }
  const index = fitler_options.map(x => x.value).indexOf(visualization_mode);
  return (
    <CardRoot {...rest} className={clsx(className)}>
      <CardHeader
        action={
          <IconButton
            onClick={handleLoad}
            size="small"
            style={{
              marginTop: 15,
              marginRight: 10,
            }}>
            <RefreshIcon />
          </IconButton>
        }
        subheader="Dashboard settings"
        title="Dashboard"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid container item spacing={2} xs={12}>
            <Grid item md={6} sm={6} xs={12}>
              <Grid item sx={{ paddingBottom: 3.5 }} xs={12}>
                <Typography gutterBottom sx={{ fontSize: 14 }} variant="h6">
                  Visualize summary by
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  getOptionLabel={option => option.label}
                  id="visualization_mode"
                  isOptionEqualToValue={(option, value) => {
                    if (value) return option.label === value.label;
                    else return false;
                  }}
                  onChange={handleVisualizationMode}
                  options={fitler_options}
                  renderInput={params => (
                    <TextField {...params} label="Visualization Modes" />
                  )}
                  size="small"
                  value={fitler_options[index]}
                />
              </Grid>
              {visualization_mode === 3 && (
                <Grid item sx={{ paddingTop: 1 }} xs={11}>
                  <SearchProjectsAtUser
                    external_selected={selected_project}
                    handleAddProject={handleProjectSelection}
                    handleSetProject={handleProjectSelection}
                    label="Asign upload to Project"
                    no_icon
                    placeholder="Search My Projects..."
                    selected={selected_project}
                  />
                </Grid>
              )}
            </Grid>
            {ComponentInterval}
          </Grid>
          <Grid container direction="row" item sx={{ paddingTop: 1 }} xs={12}>
            <Grid item md={6} sm={5} xs={12}>
              <SettingLatest
                dashboard_view={dashboard_view}
                handleCheckChange={handleCheckChange}
                loading={loading}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SettingRegions
                dashboard_view={dashboard_view}
                handleCheckChange={handleCheckChange}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <ButtonLoading
          disable_button={!IsModified()}
          handleClick={handleSaveDashboard}
          loading={loading}
          name="Save"
          transform={false}
          variant="outlined"
        />
      </CardActions>
      <SnackMessage
        handleClose={handleCloseError}
        message_text={errors !== '' ? errors : 'Unknown warning'}
        open={!!(errors && errors !== '')}
        type="error"
      />
    </CardRoot>
  );
};

DashboardSettings.propTypes = {
  className: PropTypes.string,
};
const mapStateToProps = state => {
  const { dashboard_view } = state.page_settings;
  const { user } = state.info_user;
  return { dashboard_view, user };
};

export default connect(mapStateToProps, {
  setDashboardSetting,
})(DashboardSettings);
