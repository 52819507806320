import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';

import { QueryUsersAndProjects } from 'graphql/utils_objects';

import { useStyles } from '../Styles';
import { GetData } from 'helpers';
import { inputProps } from 'common';

class SearchUsersProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      user_project: {},
      open: false,
      options: [],
      loading: false,
      inputValue: '',
    };
  }

  runSearch = () => {
    const { loading, name } = this.state;
    const { types } = this.props;
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryUsersAndProjects(name, null)
        .then(res => {
          const data = GetData(res);
          const { ok, objects } = data.SearchUsersAndProjects;
          if (ok) {
            this.setState({
              options: objects
                .map(x => {
                  const { type } = x;
                  if (types && types.length) {
                    if (types.indexOf(type) !== -1) return x;
                    return null;
                  }
                  return x;
                })
                .filter(x => x),
              loading: false,
            });
          } else {
            throw data.SearchUsersAndProjects.errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ options: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handelNameChanged = event => {
    this.setState({ name: event.target.value, open: true });
    this.runSearch();
  };
  handleSelectUserProject = user_project => {
    this.setState({ user_project });
    this.props.onSelected(user_project);
  };
  handleTextInputChangen = inputValue => {
    this.setState({ inputValue });
  };

  render() {
    const { classes, style, download_button } = this.props;
    const { user_project, open, options, loading, inputValue } = this.state;
    const height = '25px';
    let size = undefined;
    let variant = 'standard';
    let style_text = {
      height,
      margin: 0,
      padding: 0,
      borderWidth: 0,
      border: 0,
    };
    if (this.props.text_field) {
      size = 'small';
      variant = undefined;
      style_text = undefined;
    }
    const ComponentProject = (
      <Autocomplete
        // freeSolo
        getOptionLabel={option => {
          if (options.length === 0) return '';
          return option.name + ' (' + option.type + ')';
        }}
        id="search-usersprojects--input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          const { name } = option;
          if (typeof name === 'undefined') return true;
          if (typeof value.name === 'undefined') return true;
          return option.name === value.name;
        }}
        loading={loading}
        onChange={(event, newValue) => {
          this.handleSelectUserProject(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleTextInputChangen(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
              // style: {
              //   borderWidth: 0,
              //   border: 0,
              // },
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { style_text },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="user_project"
            onChange={this.handelNameChanged}
            onKeyDown={event => {
              const { key } = event;
              if (
                key === 'Enter' &&
                user_project &&
                Object.keys(user_project).length
              ) {
                this.props.handleAddUserProject(user_project);
                this.setState({
                  options: [],
                  user_project: {},
                  open: false,
                  inputValue: '',
                });
              }
            }}
            placeholder="Search Users or Projects..."
            size={size}
            style={{
              marginLeft: '6px',
              margin: 0,
              height,
              borderWidth: 0,
              border: 0,
            }}
            // value={user_project}
            variant={variant}
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
      />
    );
    if (this.props.text_field) {
      return <React.Fragment>{ComponentProject}</React.Fragment>;
    }
    return (
      <Paper className={classes.root} style={style}>
        <SearchIcon className={classes.icon} />
        {ComponentProject}
        {download_button && (
          <React.Fragment>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              aria-label="directions"
              className={classes.iconButton}
              color="primary"
              onClick={() => {
                this.props.handleAddUserProject(user_project);
                this.setState({
                  options: [],
                  user_project: {},
                  inputValue: '',
                  open: false,
                });
              }}
              size="large">
              <GetAppIcon />
            </IconButton>
          </React.Fragment>
        )}
      </Paper>
    );
  }
}

SearchUsersProjects.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  types: PropTypes.arrayOf(PropTypes.oneOf(['User', 'Project', 'Team'])),
};
SearchUsersProjects.defaultProps = {
  download_button: false,
  text_field: false,
  onSelected: () => '',
  types: [],
};
export default withStyles(SearchUsersProjects, useStyles);
