import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, CardContent, Divider, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { CardEditHeader, TableGrid, ButtonIconLoad } from 'components';
import { withStyles } from 'tss-react/mui';
import { MutationUpdateProjectCenters } from 'graphql/Projects';
import { GetData, ServerErrorsString } from 'helpers';
const useStyles = () => ({
  card: {
    maxWidth: '100%',
  },
  button: {
    width: '100px',
    margin: 0,
  },
  title: {
    fontSize: 15,
    fontWeight: 700,
    textTransform: 'none',
  },
});
class EditProjectCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      rows_edited: [],
      page: 0,
      limit: 50,
    };
    if (props.holder) {
      props.holder.setSelected = selected_center =>
        this.setState({
          selected_center,
        });
    }
  }
  handleUpdateProjectCenter = () => {
    let { updating, rows_edited } = this.state;
    if (updating || !rows_edited.length) return;
    this.setState({ updating: true });
    let inputs = [];
    for (let i = 0; i < rows_edited.length; i++) {
      const {
        id,
        center,
        code,
        idProject,
        folder_name,
        short_name,
      } = rows_edited[i];
      inputs.push({
        id,
        idCenter: center.id,
        code,
        idProject,
        folder_name,
        short_name,
      });
    }
    (async () => {
      MutationUpdateProjectCenters(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, project_centers } = data.updateProjectCenters;
          if (ok) {
            this.setState({
              updating: false,
              rows_edited: [],
            });
            this.props.handleUpdateProjectCenters(project_centers);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, height, columns, selected } = this.props;
    const { rows_edited } = this.state;
    const final_columns = columns.map(x => {
      let edit = false;
      if (
        x.field === 'code' ||
        x.field === 'folder_name' ||
        x.field === 'short_name' ||
        x.field === 'center'
      ) {
        edit = true;
      }
      return {
        ...x,
        edit,
      };
    });
    let title = 'Project Centers';
    if (rows_edited.length) {
      title = `Edited Centers ${
        rows_edited.length ? `(${rows_edited.length})` : ''
      }`;
    }
    return (
      <Card className={classes.card}>
        <CardEditHeader
          description="Double click in the cell table to edit"
          handleClose={this.props.handleClose}
          title="Edit Seleted Center "
        />
        <Divider />
        <CardContent>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-start"
            spacing={1}
            xs={12}>
            <Grid
              alignItems="center"
              container
              item
              justifyContent="space-between"
              xs={12}>
              <Grid item xs={6}>
                <Typography
                  className={classes.title}
                  id="tableTitle"
                  variant="h6">
                  {title}
                </Typography>
              </Grid>
              <ButtonIconLoad
                className={classes.button}
                disabled={!rows_edited.length}
                handleClick={this.handleUpdateProjectCenter}
                icon={<SaveIcon />}
                loading={this.state.updating}
                text="Accept"
                variant="outlined"
              />
            </Grid>
            <Grid item sx={{ width: '100%' }} xs={12}>
              <TableGrid
                dense
                external_edited={rows_edited}
                headers={final_columns}
                height={height - 150}
                loading={this.state.uploading}
                onPageChange={page => this.setState({ page })}
                onRowEditedChanged={rows_edited => {
                  this.setState({ rows_edited });
                }}
                onRowsPerPageChange={limit => this.setState({ limit })}
                page={this.state.page}
                rows={selected}
                rowsPerPage={this.state.limit}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

EditProjectCenter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(EditProjectCenter, useStyles);
