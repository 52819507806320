import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_TABLE_NAMES = gql`
  query {
    getProphetSystemFolders {
      ok
      errors {
        path
        message
      }
      folders {
        id
        folder_name
        encryption_key
        status
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryProphetSystemFolders = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_TABLE_NAMES),
  });
};
