import React from 'react';
import PropTypes from 'prop-types';
import { BoxWaiting, TableGrid } from 'components';
import { withStyles } from 'tss-react/mui';
import Split from 'react-split';
import { connect } from 'react-redux';

import { copySeries } from 'redux-store/actions/file_folder_manager';
import { useStyles } from './components';
import { rowsPerPageBig } from 'helpers';
import { columns_series } from 'common';
import { SeriesFilesThumbnails } from 'views/DataImages/components/SeriesViewEdit/components/ViewShowSeries/components';

class ViewGridList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: null,
      selected: [],
      page: 0,
      limit: 50,
      sizes: [50, 50],
    };
  }
  handleClickFileItem = () => {};
  handleSeriesRowClicked = series => {
    console.log({ idSeries: series ? series.idSeries : null });
    this.setState({ series });
  };
  handleClickSelected = selected => {
    if (selected === undefined || !selected) return;
    // const { copy_series } = this.props;
    // const index = copy_series.map(x => x.idSeries).indexOf(series.idSeries);
    // const newSelected = SelectedArray(copy_series, series, index);
    this.props.copySeries(selected);
  };
  render() {
    const { classes, height, series, loading, total } = this.props;
    const { sizes } = this.state;
    const panel_height = height - 270;
    const height_table = (panel_height * sizes[0]) / 100 - 10;
    const height_bottom = (panel_height * sizes[1]) / 100 - 20;
    // console.log({ total });
    return (
      <Split
        className={classes.splitter}
        direction="vertical"
        onDrag={sizes => this.setState({ sizes })}
        sizes={[50, 50]}
        style={{ height: panel_height }}>
        <div style={{ backgroundColor: '#eeeeee', overflow: 'auto' }}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.props.copy_series}
            headers={columns_series}
            height={height_table}
            idRow="idSeries"
            loading={loading}
            onHighlightChange={this.handleSeriesRowClicked}
            onRowClicked={this.handleSeriesRowClicked}
            onSelectedChanged={this.handleClickSelected}
            paginationMode="server"
            rows={series}
            rowsPerPage={total}
            rowsPerPageOptions={rowsPerPageBig}
            show_pagination={false}
          />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            overflow: 'auto',
            padding: 2,
            // height: height_bottom,
            // maxHeight: height_bottom,
          }}>
          {loading ? (
            <BoxWaiting />
          ) : (
            <SeriesFilesThumbnails
              handleError={this.props.handleAddError}
              handleOnClick={this.handleClickFileItem}
              height={height_bottom}
              limit={100}
              Series={this.state.series}
            />
          )}
        </div>
      </Split>
    );
  }
}

ViewGridList.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, {
  copySeries,
})(withStyles(ViewGridList, useStyles));
