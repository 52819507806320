import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { gql } from '@apollo/client';
import { Subscription } from '@apollo/client/react/components';
import { IsInvalid } from 'helpers';
const SUBSCRIPTION_NEW_ACTION_IN_FOLDER = gql`
  subscription($idFolder: LongLong!) {
    actionInFolder(idFolder: $idFolder) {
      action
      folders {
        idFolder
        idParent
        idUser
        link {
          idFolder
          idFolderContent
          code
        }
        download_link
        folders_paths {
          idFolder
          name
        }
        Size
        name
        total_files
        total_sub_folders
        Owner {
          id
          FullName
        }
        ParentFolder {
          idParent
          name
          link {
            idFolder
            idFolderContent
            code
          }
          download_link
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          idUser
          name
        }
        createdAt
        updatedAt
      }
      folder_content {
        idFolderContent
        idFolder
        idUser
        idFileObject
        idSeries
        Owner {
          id
          FullName
        }
        Series {
          idSeries
          idStudy
          Patient {
            PatientName
            PatientBirthDate
            PatientID
          }
          StationName
          SeriesInstanceUID
          SeriesDescription
          SeriesTime
          SeriesDate
          Size
          Thumbnail
          Study {
            StudyDescription
            StudyInstanceUID
            AccessionNumber
          }
          NumberOfSeriesRelatedInstances
          updatedAt
          createdAt
        }
        File {
          idFileObject
          original_name
          mimetype
          Size
          Thumbnail
          updatedAt
          createdAt
        }
        updatedAt
        createdAt
      }
    }
  }
`;
const SUBSCRIPTION_NEW_FILE_FOLDER = gql`
  subscription($idFolder: LongLong!) {
    newFileInFolder(idFolder: $idFolder) {
      idFolderContent
      idFolder
      idUser
      idFileObject
      idSeries
      Owner {
        id
        FullName
      }
      Series {
        idSeries
        idStudy
        Patient {
          PatientName
          PatientBirthDate
          PatientID
        }
        StationName
        SeriesInstanceUID
        SeriesDescription
        SeriesTime
        SeriesDate
        Size
        Thumbnail
        Study {
          StudyDescription
          StudyInstanceUID
          AccessionNumber
        }
        NumberOfSeriesRelatedInstances
        updatedAt
        createdAt
      }
      File {
        idFileObject
        original_name
        mimetype
        Size
        Thumbnail
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
const SUBSCRIPTION_DELETE_FILE_FOLDER = gql`
  subscription($idFolder: LongLong!) {
    fileFolderDeleted(idFolder: $idFolder) {
      idFolderContent
      idFolder
      idUser
      idFileObject
      idSeries
      Series {
        idSeries
        idStudy
        SeriesDescription
        Size
        Thumbnail
      }
      File {
        idFileObject
        original_name
        mimetype
        Size
        Thumbnail
        updatedAt
        createdAt
      }
      createdAt
    }
  }
`;

class SubsNewFolderContent extends Component {
  constructor(props) {
    super(props);
    this.subscribeToMore = null;
    this.refetch = null;
    this.subscriptions = [];
  }
  onSubscriptionDataDelete = ondata => {
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { fileFolderDeleted } = data;
      this.props.deletedFolderContent(fileFolderDeleted);
    }
    console.log('----------------------------');
    console.log('----------------------------');
    console.log('onSubscriptionDataDelete', { subscriptionData });
    console.log('----------------------------');
    console.log('----------------------------');
  };
  onSubscriptionDataNew = ondata => {
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { newFileInFolder } = data;
      this.props.addNewFolderContent(newFileInFolder);
    }
    console.log('----------------------------');
    console.log('----------------------------');
    console.log('onSubscriptionDataNew: ', { subscriptionData });
    console.log('----------------------------');
    console.log('----------------------------');
  };
  onSubscriptionDataAction = ondata => {
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    // console.log({ loading, data });
    if (!loading && data) {
      const { actionInFolder } = data;
      if (actionInFolder) {
        const { action, folders, folder_content } = actionInFolder;
        if (folder_content) this.props.actionSubFolder(folder_content, action);
        else if (folders) {
          for (let i = 0; i < folders.length; i++) {
            const folder = folders[i];
            this.props.actionSubFolder(folder, action);
          }
        }
      }
    }
    console.log('----------------------------');
    console.log('----------------------------');
    console.log('onSubscriptionDataAction: ', { subscriptionData });
    console.log('----------------------------');
    console.log('----------------------------');
  };
  render() {
    const { children, idFolder } = this.props;
    const variables = {
      idFolder,
    };
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataNew}
        shouldResubscribe
        subscription={SUBSCRIPTION_NEW_FILE_FOLDER}
        variables={variables}>
        {sub_new_data => {
          return (
            <Subscription
              fetchPolicy={'no-cache'}
              onSubscriptionData={this.onSubscriptionDataDelete}
              subscription={SUBSCRIPTION_DELETE_FILE_FOLDER}
              variables={variables}>
              {sub_delete => {
                return (
                  <Subscription
                    fetchPolicy={'no-cache'}
                    onSubscriptionData={this.onSubscriptionDataAction}
                    subscription={SUBSCRIPTION_NEW_ACTION_IN_FOLDER}
                    variables={variables}>
                    {sub_action_data => {
                      if (!children) {
                        return null;
                      }
                      return React.Children.map(children, function(child) {
                        return React.cloneElement(child, {
                          load_subscription: sub_new_data.loading,
                          loading_delete: sub_delete.loading,
                          loading_action: sub_action_data.loading,
                          error: sub_new_data.error,
                          error_delete: sub_delete.error,
                          error_action: sub_action_data.error,
                        });
                      });
                    }}
                  </Subscription>
                );
              }}
            </Subscription>
          );
        }}
      </Subscription>
    );
  }
}

SubsNewFolderContent.propTypes = {
  addNewFolderContent: PropTypes.func.isRequired,
  classes: PropTypes.object,
  deletedFolderContent: PropTypes.func.isRequired,
  idFolder: PropTypes.number.isRequired,
};
SubsNewFolderContent.defaultProps = {
  addNewFolderContent: () => '',
  deletedFolderContent: () => '',
  actionSubFolder: () => '',
  idFolder: -1,
};

export default SubsNewFolderContent;
