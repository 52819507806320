import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Divider, Grid, IconButton } from '@mui/material';
import Avatar from 'react-avatar-edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAltIcon from '@mui/icons-material/Save';

import { reduce_image_file_size } from 'helpers/utils_images';

class AvatarUpload extends React.Component {
  constructor(props) {
    super(props);
    // const src = '/images/avatars/avatar_1.png';
    this.state = {
      preview: null,
      resized: null,
      src: null,
      name: null,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
    const { holder } = props;
    if (holder) holder.getAvatar = () => this.state.preview;
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    //
    this.setState({ preview });
  }

  onBeforeFileLoad(elem) {
    // if (elem.target.files[0].size > 71680) {
    //   alert('File is too big!');
    //   elem.target.value = '';
    // } else
    {
      this.setState({ name: elem.target.files[0].filename });
    }
  }

  handleUploadNew = async () => {
    const resized = await reduce_image_file_size(this.state.preview, 50, 50);

    this.setState({ resized });
    this.props.handleUploadNew(this.state.preview);
  };

  render() {
    let { preview, resized } = this.state;
    if (resized) {
      preview = resized;
    }
    return (
      <React.Fragment>
        <div style={{ width: '100%', marginBottom: 5 }}>
          <Divider />
        </div>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <Grid item xs={12}>
            <CardHeader
              action={
                <IconButton
                  aria-label="settings"
                  onClick={() => this.props.handleClose()}
                  size="large"
                  style={{ marginTop: 5 }}>
                  <CancelIcon />
                </IconButton>
              }
              avatar={
                <IconButton
                  aria-label="settings"
                  onClick={this.handleUploadNew}
                  // onClick={() => this.props.handleUploadNew(this.state.preview)}
                  size="large"
                  style={{ color: 'blue' }}>
                  <SaveAltIcon />
                </IconButton>
              }
              style={{ margin: 0, padding: 0, marginRight: 10 }}
            />
          </Grid>
          <Grid item style={{ marginBottom: 5 }} xs={12}>
            <Divider />
          </Grid>
          <Grid item style={{ minWidth: 290 }} xs={6}>
            <Avatar
              height={195}
              onBeforeFileLoad={this.onBeforeFileLoad}
              onClose={this.onClose}
              onCrop={this.onCrop}
              src={this.state.src}
              width={290}
            />
          </Grid>
          <Grid item style={{ paddingRight: 30 }} xs={4}>
            <img
              alt="Preview"
              src={preview}
              style={{ paddingRight: 70, width: 256, height: 'auto' }}
            />
          </Grid>
          <Grid item style={{ marginTop: 5 }} xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

AvatarUpload.propTypes = {
  classes: PropTypes.object,
};

export default AvatarUpload;
