import React from 'react';
import PropTypes from 'prop-types';
import GroupBasics from '../GroupBasics';
class GroupAutors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      addMap: null,
      removeMaps: null,
    };
    if (props.holder) {
      props.holder.addAuthor = author => {
        if (this.holder.addMap) this.holder.addMap(author);
      };
      props.holder.removeAuthors = () => {
        if (this.holder.removeMaps) this.holder.removeMaps();
      };
    }
  }
  render() {
    return (
      <GroupBasics
        handleOpenAddDialog={this.props.handleOpenAddDialog}
        handleOpenRemoveDialog={this.props.handleOpenRemoveDialog}
        holder={this.holder}
        map_name="Authors Designer"
        title="Authors"
      />
    );
  }
}

GroupAutors.propTypes = {
  classes: PropTypes.object,
};

export default GroupAutors;
