import {
  FormatTime,
  getUserFullName,
  FormatBytes,
  IsInvalid,
  IsInvalidParam,
} from 'helpers';

export const columns_series = [
  {
    field: 'idSeries',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'Owner');
      if (value === undefined || !value) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'project');
      if (value === undefined || !value) return '';
      return value.name;
    },
  },
  {
    field: 'idStudy',
    headerName: 'ID Study',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const sequence = params.getValue(params.id, 'sequence');
      if (sequence === undefined || !sequence) return '';
      return sequence.name;
    },
  },
  {
    field: 'SeriesDate',
    headerName: 'SeriesDate',
  },

  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Files',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'nifti',
    headerName: 'NIfTI',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const columns_files = [
  {
    field: 'idFileObject',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'Owner');
      if (value === undefined || !value) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'project');
      if (value === undefined || !value) return '';
      return value.name;
    },
  },
  {
    field: 'original_name',
    headerName: 'Filename',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'Size',
    headerName: 'Size',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'Size');
      if (value === undefined || !value) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'derivated',
    headerName: 'Derivated',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const derivated = params.getValue(params.id, 'derivated');
      if (derivated === undefined || !derivated) return '';
      return derivated > 0 ? 'YES' : '';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
