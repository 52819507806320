import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { withStyles } from 'tss-react/mui';

import { options } from './chart_options';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = () => ({
  chartContainerSeries: {
    minHeight: '348px',
    // height: '100%',
    position: 'relative',
    marginTop: '5px',
  },
});

class SequencesChartBars extends Component {
  render() {
    const { classes, data } = this.props;
    // console.log({ data });
    return (
      <div className={classes.chartContainerSeries}>
        <Bar data={data} options={options} redraw />
      </div>
    );
  }
}

SequencesChartBars.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};

export default withStyles(SequencesChartBars, useStyles);
