import React from 'react';
import PropTypes from 'prop-types';
import {
  QueryLandingObjects,
  MutationStopLandingObjects,
} from 'graphql/Administration/utils_landing_objects';
import { ServerErrorsString, GetData } from 'helpers';
import { TableLandingObjectRunning } from './components';
import { DialogDeleteWarning } from 'components';
import { IsInvalid } from 'helpers';

class TabLandingRunning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landings: [],
      loading: false,
      deleting: false,
      name: '',
      page: 0,
      limit: 50,
      total: 0,
      open_delete: false,
      selected: [],
      init: false,
    };
    const { holder } = props;
    if (holder) {
      holder.addRunning = new_landings => {
        let { landings, total } = this.state;
        let count = 0;
        for (let i = 0; i < new_landings.length; i++) {
          const { idLanding } = new_landings[i];
          const index = landings.map(x => x.idLanding).indexOf(idLanding);
          if (index === -1) {
            landings.push({ ...new_landings[i] });
            count++;
          }
        }
        if (count) this.setState({ landings, total: total + count });
      };
      holder.landingChanged = landing => {
        if (IsInvalid(landing)) return;
        const { idLanding, processing } = landing;
        let { landings, total } = this.state;
        const index = landings.map(x => x.idLanding).indexOf(idLanding);
        if (index !== -1) {
          if (landings[index].processing === processing) return;
          if (!processing) {
            landings = landings.filter(x => x.idLanding !== idLanding);
            this.props.handleAddToStopped([landing]);
            total--;
          } else {
            return;
          }
        } else if (processing) {
          landings.unshift(landing);
          total++;
        }
        this.setState({ landings, total });
      };
      holder.IsRunning = landing => {
        if (IsInvalid(landing)) return;
        const { landings } = this.state;
        const index = landings.map(x => x.idLanding).indexOf(landing.idLanding);
        return index === -1 ? false : true;
      };
    }
    this.holder = {
      getSelected: null,
      clean: null,
    };
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleReloadTable();
  }
  handleReloadTable = (newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    let { loading, name, page, limit } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true, page, limit });
    (async () => {
      QueryLandingObjects(name, page, limit, true)
        .then(res => {
          const data = GetData(res);
          const { ok, landings, total, errors } = data.landingObjects;
          if (ok) {
            this.setState({
              loading: false,
              empty: !landings.length,
              landings,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            loading: false,
            empty: true,
            init: true,
          });
        });
    })();
  };
  handleDeleteSelected = () => {
    let { deleting, landings, selected } = this.state;
    if (deleting) return;
    const ids = selected.map(x => x.idLanding);
    this.setState({ deleting: true });
    (async () => {
      MutationStopLandingObjects(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.stopLandingObjects;
          if (ok) {
            this.setState({
              deleting: false,
              open_delete: false,
              landings: landings.filter(x => ids.indexOf(x.idLanding) === -1),
              selected: [],
            });
            this.props.handleAddToStopped(selected);
            if (errors) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false, open_delete: false });
        });
    })();
  };
  render() {
    let { height } = this.props;
    if (height) {
      height = height - 300;
      if (height < 300) height = null;
    }
    let question_text =
      'You are about to stop the selected running landing object,';
    question_text +=
      ' this will move the selected object to the stopped landing object';
    return (
      <React.Fragment>
        <TableLandingObjectRunning
          disable_splice
          handleChangePage={page =>
            this.handleReloadTable(page, this.state.limit)
          }
          handleChangeRowsPerPage={limit => this.handleReloadTable(0, limit)}
          handleDeleteSelected={() => this.setState({ open_delete: true })}
          handleReloadTable={this.handleReloadTable}
          height={height}
          holder={this.holder}
          landing_objects={this.state.landings}
          limit={this.state.limit}
          page={this.state.page}
          selected={this.state.selected}
          setState={status => this.setState(status)}
          total={this.state.total}
        />
        <DialogDeleteWarning
          button_text="Stop"
          handleButtonClicked={this.handleDeleteSelected}
          handleClose={() => this.setState({ open_delete: false })}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={question_text}
          title_text={'Stop Running Landing Object'}
        />
      </React.Fragment>
    );
  }
}

TabLandingRunning.propTypes = {
  classes: PropTypes.object,
};

export default TabLandingRunning;
