import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';

import useStyles from './Styles';
import { FormatTime, getInitials, IsToday } from 'helpers';

class Message extends React.Component {
  render() {
    const {
      left,
      classes,
      message: {
        user: { avatar, FullName },
        createdAt,
        text,
        // url,
        // filetype
      },
    } = this.props;
    let date = '';
    if (IsToday(createdAt)) {
      date = FormatTime(createdAt, 'HH:mm');
    } else {
      date = FormatTime(createdAt, 'MMM Do, YYYY - h:mm a');
    }
    return (
      <div
        className={clsx({
          [classes.root_right]: true,
          [classes.root_left]: left,
        })}>
        <Grid className={classes.root} container>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={getInitials(FullName)} src={avatar} />
            </ListItemAvatar>
            <Grid container direction="column" justifyContent="space-between">
              <ListItemText
                classes={{
                  primary: clsx({
                    [classes.header_black]: left,
                    [classes.header_white]: !left,
                  }),
                  secondary: clsx({
                    [classes.message_black]: left,
                    [classes.message_white]: !left,
                  }),
                }}
                className={clsx({
                  [classes.text_content_right]: !left,
                  [classes.text_content_left]: left,
                })}
                primary={FullName ? FullName : 'Unkown'}
                secondary={text}
              />
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-end">
                <Typography
                  className={classes.inline}
                  color="textPrimary"
                  component="span"
                  variant="body2">
                  <div>{date}</div>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
      </div>
    );
  }
}

export default withStyles(Message, useStyles);
