import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import { Divider, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { createSurvey } from 'helpers/Surveys';
import { MutationCreateSurvey } from 'graphql/Surveys';
import moment from 'moment';
import { GetData } from 'helpers';
import { DivGrow, IconButtonLoad } from 'components';
import { Replay as ReloadIcon } from '@mui/icons-material';
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

class TabToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      clicked: props.currentSurvey ? 'create' : 'save',
    };
  }
  handleSave = (sender, complete) => {
    console.log({ sender, complete });
    const { saving } = this.state;
    const { currentPatient } = this.props;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      MutationCreateSurvey(sender, complete)
        .then(res => {
          const data = GetData(res);
          const new_survey = data.createSurvey;
          const reply = createSurvey(
            new_survey,
            new_survey.id,
            currentPatient.id,
            this.props.handleSave
          );
          this.props.handleCreatedSurvey({
            updatedAt: new_survey.updatedAt,
            survey_json: reply.survey_json,
            currentSurvey: reply.survey,
            buttonSave: reply.buttonSave,
            buttonCancel: reply.buttonCancel,
            value_changed: [{ name: '' }],
          });
          this.setState({ saving: false });
        })
        .catch(error => {
          console.log({ error });
          this.setState({ saving: false });
        });
    })();
  };
  handleAlignment = (event, clicked) => {
    const { surveyType, currentPatient } = this.props;
    console.log({ clicked });
    if (clicked === 'create') {
      const reply = createSurvey(
        { surveyType, survey: null },
        undefined,
        currentPatient.id,
        this.handleSave,
        this.handleBackToOverview
      );
      this.props.handleCreatedSurvey({
        updatedAt: moment().valueOf(),
        survey_json: reply.survey_json,
        currentSurvey: reply.survey,
        buttonSave: reply.buttonSave,
        buttonCancel: reply.buttonCancel,
        value_changed: [{ name: '' }],
      });
      this.setState({ clicked: 'save' });
    } else if (clicked === 'survey') {
      this.props.handleCreateSurvey();
      this.setState({ clicked });
    }
  };
  render() {
    const { clicked } = this.state;
    const { currentSurvey, add_survey } = this.props;
    // console.log({ currentSurvey });
    let ComponentSave = null;
    if (currentSurvey && !add_survey) {
      ComponentSave = (
        <ToggleButton aria-label="left aligned" value="save">
          <SaveIcon />
        </ToggleButton>
      );
    } else if (!add_survey) {
      ComponentSave = (
        <ToggleButton aria-label="right aligned" value="create">
          <AddCircleOutlineIcon />
        </ToggleButton>
      );
    } else {
      ComponentSave = (
        <ToggleButton aria-label="right aligned" value="survey">
          <PlaylistAddIcon sx={{ color: 'green' }} />
        </ToggleButton>
      );
    }

    return (
      <div>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            border: theme => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            width: '100%',
          }}>
          <StyledToggleButtonGroup
            aria-label="text alignment"
            exclusive
            onChange={this.handleAlignment}
            size="small"
            value={clicked}>
            {ComponentSave}
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <DivGrow />
          <IconButtonLoad
            // disabled={downloading || disabled}
            handleClick={this.props.handleReload}
            icon={<ReloadIcon />}
            loading={this.props.loading}
            // tooltip={this.props.tooltip}
          />
        </Paper>
      </div>
    );
  }
}

TabToolBar.propTypes = {
  handleCreatedSurvey: PropTypes.func,
};
TabToolBar.defaultProps = {
  currentSurvey: null,
  handleAddNewSurvey: () => '',
  handleCreateSurvey: () => '',
  handleSave: () => '',
  add_survey: null,
};

export default TabToolBar;
