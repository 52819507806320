/* eslint-disable indent */
import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ACCOUNT_CATEGORIES = gql`
  query($name: String, $page: Int, $limit: Int) {
    accountCategories(name: $name, page: $page, limit: $limit) {
      ok
      total
      categories {
        id
        code
        name
        description
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryAccountCategories = (name, page, limit) => {
  // eslint-disable-next-line indent
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ACCOUNT_CATEGORIES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
