import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { StyleText } from 'components';

class TextStyle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid container item style={{ minWidth: 180, maxWidth: 250 }} xs={6}>
        <Grid item style={{ maxWidth: 150 }}>
          <StyleText
            fontSize={16}
            fontWeight={1000}
            style
            text={this.props.title}
          />
        </Grid>
        <Grid item style={{ paddingRight: 10 }}>
          <StyleText
            fontSize={16}
            fontWeight={100}
            style
            text={this.props.sub_title}
          />
        </Grid>
      </Grid>
    );
  }
}

TextStyle.propTypes = {
  classes: PropTypes.object,
};

export default TextStyle;
