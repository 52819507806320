import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import {
  ClinicalPatientsToolbar,
  getEmptyRow,
  header_patients,
} from './components';
import { rowsPerPageBig } from 'helpers';
import { QueryClinicalPatients } from 'graphql/DataClinics';
import { ServerErrorsString, GetData, IsValid } from 'helpers';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // Reduce padding
          padding: '0px', // Adjust the padding as needed
        },
      },
    },
  },
});

class PatientClinics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 25,
      total: 0,
      new_rows: [],
      patients: [],
      nowrap: false,
      selected: [],
      rows_edited: [],
      row_clicked: null,
      saving: false,
      anonymize: 1,
    };
  }
  componentDidMount() {
    this.handleLoadPatients();
  }
  handleAddNewData = () => {
    const new_row = getEmptyRow();
    console.log({ new_row });
    const { new_rows } = this.state;
    new_row['id'] = (new_rows.length + 1) * -1;
    new_rows.unshift(new_row);
    this.setState({ new_rows });
  };

  handleSetEditRows = rows_edited => {
    let { table, table_rows_edited } = this.state;
    this.setState({
      table_rows_edited: {
        ...table_rows_edited,
        [table]: rows_edited,
      },
    });
  };

  handleChangeCheck = event => {
    this.setState({ nowrap: event.target.checked });
  };
  handleOnCellClicked = ({ row }) => {
    this.setState({ row_clicked: row });
  };
  handleLoadPatients = (
    page_param = null,
    limit_param = null,
    anonymize = 1
  ) => {
    const { loading, name } = this.state;
    let { page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    if (IsValid(page_param)) {
      page = page_param;
    }
    if (IsValid(limit_param)) {
      limit = limit_param;
    }
    this.setState({ page, limit });

    (async () => {
      QueryClinicalPatients(name, page, limit, anonymize)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients, total } = data.patientsClinics;
          if (ok) {
            this.setState({ loading: false, patients, total, page, limit });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleToggleAnonymize = () => {
    const { page, limit, anonymize } = this.state;
    const temp = anonymize === 0 ? 1 : 0;
    this.setState({ anonymize: temp });
    this.handleLoadPatients(page, limit, temp);
  };
  render() {
    if (!this.props.isActive) return null;
    const { patients, rows_edited, new_rows, total } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end">
          <Grid item xs={12}>
            <Paper
              style={{
                margin: 2,
                marginTop: 3,
                paddingTop: 3,
                paddingLeft: 2,
              }}>
              <ClinicalPatientsToolbar
                anonymize={this.state.anonymize}
                handleAddError={this.props.handleAddError}
                handleAddNewData={this.handleAddNewData}
                handleChangeCheck={this.handleChangeCheck}
                handleClearSelected={() => this.setState({ selected: [] })}
                handleReload={() => this.handleLoadPatients()}
                handleToggleAnonymize={this.handleToggleAnonymize}
                history={this.props.history}
                loading={this.state.loading}
                new_rows={new_rows}
                nowrap={this.state.nowrap}
                patients={this.state.patients}
                row_clicked={this.state.row_clicked}
                rows_edited={rows_edited}
                saving={this.state.saving}
                selected={this.state.selected}
                setState={state => this.setState(state)}
                total={this.state.total}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              cell_selection
              checkboxSelection
              dense
              disableSelectionOnClick
              external_edited={rows_edited}
              external_selected={this.state.selected}
              grid
              headers={header_patients}
              height={this.props.height - 220}
              loading={this.state.loading}
              nowrap={this.state.nowrap}
              onCellClicked={rowHighlighted =>
                this.handleOnCellClicked(rowHighlighted)
              }
              onPageChange={page =>
                this.handleLoadPatients(page, this.state.limit)
              }
              onRowEditedChanged={this.handleSetEditRows}
              onRowsPerPageChange={limit => this.handleLoadPatients(0, limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              paginationMode="server"
              row_height={30}
              rowCount={total}
              rows={new_rows.concat(patients)}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
              saving={this.state.saving}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

PatientClinics.propTypes = {
  classes: PropTypes.object,
};

export default PatientClinics;
