import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { FormatTime } from 'helpers';
import { TableRowDoubleCell } from 'components/Tables/components';

class TabContainerStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container } = this.props;
    if (!container || container === undefined) return null;
    return (
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>
            General information about the status of the docker container
          </caption>
          <TableBody>
            <TableRowDoubleCell name="ID" value={container.idContainer} />
            <TableRowDoubleCell name="Name" value={container.ImageName} />
            <TableRowDoubleCell name="IP Address" value={container.ip} />
            <TableRowDoubleCell
              name="Status"
              value={`${container.State} ${container.ImageName}`}
            />
            <TableRowDoubleCell
              name="Created"
              value={FormatTime(container.createdAt, 'calendar')}
            />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabContainerStatus.propTypes = {
  classes: PropTypes.object,
};

export default TabContainerStatus;
