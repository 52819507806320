import gql from 'graphql-tag';
import { MutationUploadSingleFile } from 'common';

const MUTATION_UPLOAD_SEQUENCE_CSV = gql`
  mutation($file: Upload!) {
    uploadNewSequence(file: $file) {
      ok
      errors {
        path
        message
      }
      sequences {
        idSequence
        name
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationUploadSequenceCSV = (file, handleProgress = null) => {
  return MutationUploadSingleFile(
    file,
    MUTATION_UPLOAD_SEQUENCE_CSV,
    handleProgress
  );
};
