import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  SearchText,
} from 'components';
import { MutationUploadCenterCSV } from 'graphql/Units/utils_mutation_centers';
import { ServerErrorsString, GetData } from 'helpers';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class TabCentersHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }
  handleProgress = event => {
    const { loading, total_download } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = total_download;
    }
    if (this.props.handleProgress) {
      this.props.handleProgress({ loaded, total }, loading);
    }
  };
  handleSelectFile = event => {
    const { uploading, page, limit } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadCenterCSV(files[0], page, limit, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, centers, total } = data.uploadNewCenters;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddNewCenters(centers, total);
            if (errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected, loading, holder } = this.props;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        pb={1}
        pt={1}>
        <Grid item xs={3}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button onClick={this.props.handleOpenCreate}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
            <ButtonFileUpload
              handleSelectFile={this.handleSelectFile}
              uploading={this.state.uploading}
            />
            <ButtonDownloadType key="center" type="centers" />
          </ButtonGroup>
        </Grid>
        <Grid item xs>
          <SearchText
            handleSearchText={this.props.handleSearchCenter}
            holder={holder}
            id="id-search-admin-centers"
            loading={loading}
            placeholder="Search Center by name"
          />
        </Grid>
        <Grid item pr={2}>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={() => this.props.handleReload()}
            loading={this.props.deleting}
            loading_table={this.props.loading}
            numSelected={selected.length}
            small
            tooltip="Reload sequences"
            wrapper={{ margin: 0 }}
          />
        </Grid>
      </Grid>
    );
  }
}

TabCentersHeader.propTypes = {
  classes: PropTypes.object,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  selected: PropTypes.array,
};
TabCentersHeader.defaultProps = {
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  handleAddError: () => '',
  handelAddNewCenters: () => '',
  selected: [],
};
export default withStyles(TabCentersHeader, useStyles);
