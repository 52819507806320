import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components';
import { getLevels } from 'helpers';
import { styleTabPanels } from 'common';
import { IsInvalid } from 'helpers';

class TabProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      tabValue: 0,
    };
  }
  componentDidUpdate() {
    const { tabs, tabValue } = this.state;
    const { history } = this.props;
    const { level_1, level_2 } = getLevels(this.props);
    console.log({ level_1, level_2 });
    if (level_1 && level_1 !== 'batch_process') {
      history.push('/pipelines');
    } else if (IsInvalid(level_2)) {
      history.push('/pipelines/batch_process/inputdata');
    } else {
      const index = tabs.map(x => x.key).indexOf(level_2);
      console.log({ tabValue, index });
      if (tabValue !== index) {
        if (index === -1) {
          history.push('/pipelines');
        } else {
          this.setState({ tabValue: index, tab: tabs[index].key });
        }
      }
    }
    // console.log({ level_1, level_2, tabValue });
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    const { history } = this.props;
    // console.log({ tabValue });
    this.setState({ tabValue });
    switch (tabValue) {
      case 1:
        history.push('/pipelines/batch_process/anonymization');
        break;
      case 2:
        history.push('/pipelines/batch_process/currentjobs');
        break;
      default:
        history.push('/pipelines/batch_process/inputdata');
        break;
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    const tab_value = parseInt(this.state.tabValue, 10);
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tab_value}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              return <Tab key={`analysis-tab-${index}`} label={tab.name} />;
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`analysis-tab-panel-${index}`}
                  value={tab_value}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    handleAddWarning={this.props.handleAddWarning}
                    handleTabChanged={tabValue =>
                      this.handleTabValue(
                        { preventDefault: () => '' },
                        tabValue
                      )
                    }
                    height={this.props.height}
                    history={this.props.history}
                    isDesktop={this.props.isDesktop}
                    match={this.props.match}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TabProcess.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    total_series: copy_series ? copy_series.length : 0,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(withRouter(TabProcess), styleTabPanels));
