import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SSR_CASE = gql`
  query($id: Int) {
    ssrCase(id: $id) {
      ok
      errors {
        path
        message
      }
      selectedCase {
        id
        idPatient
        idCase
        arrival_date
        event_type
        onset_known
        onset_time
        wakeup_time
        referral
        referring_unitcenter
        transport
        living_pre
        disability_pre
        stroke_syndrome
        nih_admission
        gcs_admission
        bp_syst
        bp_diast
        tot_files
        tot_series
        tot_edfs
        createdAt
        updatedAt
        patient {
          id
          PatientID
          FamilyName
          GivenName
          MiddleName
          NamePrefix
          NameSuffix

          PatientName

          PatientID

          PatientBirthDate
          PatientSex
          PatientEthnicity
          patientSAPStatus
          patientLastActivity
          patientRegistrationDate

          PatientAge
          PatientSize
          PatientWeight
          patientVitalStatus
          patientDeathDate
          patientLastVitalityCheck
          tot_sleep_files
          tot_epilepsy_files
          tot_stroke_files
          tot_ms_files

          tot_sleep_series
          tot_epilepsy_series
          tot_stroke_series
          tot_ms_series

          tot_sleep_edfs
          tot_epilepsy_edfs
          tot_stroke_edfs
          tot_ms_edfs
        }
        followup {
          id
          followup_date
          followup_mode
          mrs_90days
          nih_90days
          stroke_followup
          stroke_followup_date
          ich_followup
          ich_followup_date
          seizure_followup
          seizure_followup_date
          death_followup
          death_followup_date
          death_followup_cause
          followup_comment
        }
        treatment {
          id
          aspirin_pre
          clopidogrel_pre
          prasugrel_pre
          ticagrelor_pre
          dipyridamole_pre
          vka_pre
          vka_inr
          rivaroxaban_pre
          dabigatran_pre
          apixaban_pre
          edoxaban_pre
          parenteralanticg_pre
          antihypertensive_pre
          antilipid_pre
          hormone_pre
          firstimage_type
          firstimage_time
          door_image_time
          firstimage_result
          perfusion_type
          perfusion_result
          firstangio_type
          firstangio_result
          treat_antiplatelet
          treat_anticoagulant
          treat_ivt
          ivt_start
          rtpa_dose
          onset_treat_time
          door_treat_time
          treat_iat
          iat_start
          onset_iat_time
          door_iat_time
          anesthesia
          iat_rtpa
          iat_rtpa_dose
          iat_uk
          iat_uk_dose
          iat_mech
          iat_stentretriever
          iat_aspiration
          iat_distalretriever
          iat_balloon
          iat_stentintracran
          iat_stentextracran
          iat_mech_other
          iat_end
          treat_study
          hospitalisation
          other_unitcenter
          other_unitcenter_rsn
          other_unit_datetime
          other_unit_dido
          not_monitored_rsn
          admisstreat_comment
          sys_fu_plan_date
          sys_noac_isp
        }
        procedure {
          id
          bridging_regime
          bridging_regime_oth
          rtpa_score
          conc_medi
          antithrombotic
          antithrombotic_oth
          recanalization
          antithrombotic_start
          ct_denseartery
          ct_leukoaraiosis
          ct_oldinfarct
          ct_earlyinfarctsings
          ct_aspects
          occ_none
          occ_ica_extra
          occ_ica_intra
          occ_ica_t
          occ_mca_m1
          occ_mca_m2
          occ_mca_m3_m4
          occ_ba_proximal
          occ_ba_middle
          occ_ba_distal
          occ_aca_a1
          occ_aca_a2
          occ_va
          occ_pca_p1
          occ_pca_p2
          occ_pica
          occ_suca
          occ_aica
          occ_other
          occ_other_spec
          occ_determined_by
          occ_determined_oth
          occ_core
          occ_penumbra
          lab_inr
          lab_aptt
          lab_tt
          lab_riv
          lab_dab
          lab_afxa
          lab_platelets
          lab_haem
          lab_crp
          lab_calcium
          lab_albumin
          lab_uric_acid
          lab_triglycerides
          lab_hdl
          lab_hba1c
          lab_tropinin_t_hs
          lab_d_dimer
          reperfusion_time
          stroke_reper_time
          door_reper_time
          ivt_reper_time
          iat_reper_time
          ed_none
          ed_solitaire
          ed_trevo
          ed_3d_separator
          ed_preset
          ed_capture
          ed_merci
          ed_other
          ed_other_spec
          alo_acc
          alo_aci_prox
          alo_aci_dist
          alo_m1
          alo_m2
          alo_m3
          alo_a1
          alo_av_prox
          alo_av_dist
          alo_ba_prox
          alo_ba_dist
          alo_p1
          alo_p2
          alo_other
          alo_other_spec
          recanal_before
          recanal_after
          pat_alone_before
          pat_independ_before
          pat_lift_arms_adm
          pat_walk_adm
          pat_gcs_normal
          dragon_prestroke
          dragon_age
          dragon_glucose
          dragon_ott
          dragon_nihss
          dragon_score
          sedan_glucose
          sedan_age
          sedan_nihss
          sedan_score
          fu_timi
          fu_any_ich
          fu_deterioration
          fu_sympt_ich
          sympt_ecass
          sympt_ninds
          sympt_sits
          ich_proact2
          bleeding_other
          fu_barthel_90d
          fu_nihss_90d
          fu_epi_90d
          fu_mrs_1y
          thrombo_comments
        }
        encounter {
          id
          followimage_type
          followimage_result
          follow_mra
          follow_cta
          follow_ultrasound
          follow_dsa
          follow_tte
          follow_tee
          follow_holter
          follow_holter_length
          mca
          aca
          pca
          vertebrobasilar
          ocular
          etiology_toast
          etiology_toast_other
          etiology
          etiology_other
          stroke_pre
          tia_pre
          ich_pre
          hypertension
          diabetes
          hyperlipidemia
          smoking
          atrialfib
          chd
          prostheticvalves
          lowoutput
          pad
          sleep_average
          sleep_lastnight
          snoring
          daytime_sleepiness
          glucose
          cholesterol_total
          cholesterol_ldl
          creatinine_ldl
          nih_24h
          ich_sympt_hospital
          ich_sympt_hosp_date
          stroke_hospital
          stroke_hospital_date
          angioedema
          seizure_hospital
          seizure_hosp_date
          decompression
          decompression_date
          death_hospital
          death_hospital_date
          death_hospital_cause
          cea_symptomatic
          cea_symptomatic_date
          cas_symptomatic
          cas_symptomatic_date
          endorevasc_other
          surgrevasc_other
          revasc_other_date
          revasc_other_specify
          pfo_closure
          pfo_closure_date
          discharge_destinat
          discharge_date
          duration_hospital
          hosp_comment
        }
        files {
          id
          idEpilepsyCase
          idSleepCase
          idPatient
          idFileObject
          idSeries
          idSignalEDF
          idFolder

          epilepsy_case {
            id
          }
          sleep_case {
            id
          }
          file_object {
            idFileObject
            original_name
            mimetype
            filepath
            createdAt
          }
          series {
            idSeries
            Thumbnail
            DicomFiles {
              idFile
              OriginalName
              FilePath
            }
            createdAt
          }
          edf {
            id
            file_object {
              idFileObject
              original_name
              mimetype
              filepath
            }
            createdAt
          }
          folder {
            idFolder
            name
            createdAt
          }
          folder_content {
            idFolderContent
            idFolder
          }
        }
      }
    }
  }
`;

export const QueryStrokeCase = id => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_CASE),
    variables: {
      id,
    },
  });
};
