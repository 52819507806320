import React from 'react';
import PropTypes from 'prop-types';
import { AutoMutilSingle } from 'components/Basics';
import { QuerySequences } from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';

class TypeSequences extends React.Component {
  constructor(props) {
    super(props);
    const { mulitple, sequence } = props;
    this.state = {
      loading: false,
      sequences: [],
      selected: mulitple ? [] : sequence ? sequence : undefined,
    };
  }
  componentDidMount() {
    this.runSearch();
  }
  runSearch = (sequence_name, empty) => {
    console.log({ sequence_name });
    const { loading } = this.state;
    if (loading || empty) {
      return;
    }
    const { external } = this.props;
    this.setState({ loading: true, empty: false });
    (async () => {
      QuerySequences(sequence_name)
        .then(res => {
          const data = GetData(res);
          const { ok, total, errors, sequences } = data.Sequences;

          if (ok) {
            if (external.length) {
              external.forEach(element => {
                sequences.unshift(element);
              });
            }
            this.setState({
              total,
              sequences,
              loading: false,
              empty: !sequences.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSelectedChangedd = selected => {
    this.setState({ selected });
    this.props.onSequenceChanged(selected);
  };
  render() {
    return (
      <AutoMutilSingle
        label="Sequences"
        loading={this.state.loading}
        multiple={this.props.multiple}
        onSelectionChanged={this.handleSelectedChangedd}
        option={this.state.selected}
        options={this.state.sequences}
        size={this.props.size}
      />
    );
  }
}

TypeSequences.propTypes = {
  external: PropTypes.array,
};
TypeSequences.defaultProps = {
  external: [],
  multiple: false,
  size: '',
  onSequenceChanged: () => '',
  sequence: undefined,
};

export default TypeSequences;
