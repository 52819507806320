import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@mui/material';
import { IsValid } from 'helpers';

import { TestType } from './components';

class SleepTestTypeViewer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { test_types } = this.props;
    let test_types_to_show = [];
    for (let i = 0; i < test_types.length; i++) {
      if (IsValid(test_types[i].idSleepCase)) {
        test_types_to_show.push(test_types[i]);
      }
    }

    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          Sleep Tests
        </Typography>

        {test_types_to_show.map((item, index) => (
          <Box key={index}>
            <TestType key={index} test_type={item} />
            {test_types_to_show.length !== index + 1 ? (
              <Divider
                style={{ marginTop: '10px', marginBottom: '10px' }}
                variant="middle"
              />
            ) : null}
          </Box>
        ))}
        {test_types_to_show.length === 0 ? (
          <Typography component="p" gutterBottom sx={{ p: 2 }} variant="p">
            No test types to show
          </Typography>
        ) : null}
      </Box>
    );
  }
}

SleepTestTypeViewer.propTypes = {
  classes: PropTypes.object,
};

export default SleepTestTypeViewer;
