import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ButtonGroup, Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { connect } from 'react-redux';
import {
  ButtonDownloadType,
  SearchProjectCentersInputs,
  ButtonReloadDelete,
} from 'components';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { setPatientsIds } from 'redux-store/actions';

const useStyles = () => ({
  root: { height: 38, margin: 0, padding: 0, paddingTop: 5, paddingLeft: 10 },
});
class ToolBarProjectPatients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleOpenPatient = () => {
    const { selected, row_clicked } = this.props;
    const { idPatient } = row_clicked;
    if (idPatient && !selected.length) {
      this.props.history.push(`/image_analysis/patients/${idPatient}`);
    } else if (selected.length) {
      const ids_patients = selected.map(x => x.idPatient).filter(x => x);
      this.props.setPatientsIds(ids_patients);
      this.props.history.push('/image_analysis/patients/group');
    }
  };
  render() {
    const {
      classes,
      loading,
      selected,
      loading_table,
      idProject,
      row_clicked,
    } = this.props;
    // console.log({ row_clicked });
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="space-between"
        spacing={1}
        sx={{ paddingLeft: 1, width: '100%' }}
        xs={12}>
        <Grid item md={3} sm={3} xs={3}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <ButtonDownloadType
              idProject={this.props.idProject}
              type="project_patients"
            />
            <Button disabled={!row_clicked} onClick={this.handleOpenPatient}>
              <LaunchIcon style={{ color: row_clicked ? 'green' : 'gray' }} />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item sm={5} xs={6}>
          <SearchProjectCentersInputs
            external_center={this.props.center}
            handleAddCenter={this.props.handleAddCenter}
            handleAddError={this.props.handleAddError}
            idProject={idProject}
            no_icon
            small
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={1}>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={loading}
            loading_table={loading_table}
            numSelected={selected.length}
            show_delete={false}
            small
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarProjectPatients.propTypes = {
  classes: PropTypes.object,
};
ToolBarProjectPatients.defaultProps = {
  handleReloadTable: () => '',
  handleClearSelected: () => '',
  selected: [],
  loading_table: false,
  loading: false,
};

export default connect(null, { setPatientsIds })(
  withStyles(withRouter(ToolBarProjectPatients), useStyles)
);
