import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  DashboardGeneral,
  DashboardSurgery,
  DashboardSurveys,
} from './components';
import { getParams, getParamsUrl } from 'helpers';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getParamView = () => {
    const params = getParams(this.props);
    let view_type = null;
    let idType = null;
    if (params) {
      const { level_1, level_2 } = params;
      const url = getParamsUrl(this.props);
      if (level_1 === undefined || level_2 === undefined) {
        if (url !== '/dashboard') this.props.history.push('/dashboard');
      } else if (level_1 && level_2 !== undefined) {
        view_type = level_1;
        idType = parseInt(level_2, 10);
        if (isNaN(idType)) {
          if (url !== '/dashboard') this.props.history.push('/dashboard');
        }
      }
    }
    return {
      view_type,
      idType,
    };
  };
  render() {
    const { user } = this.props;
    // console.log({ user });
    const params = this.getParamView();
    // const let_change = 0;
    if (user.idCategory === 2) {
      return (
        <DashboardSurgery
          {...this.props}
          idType={params.idType}
          view_type={params.view_type}
        />
      );
    } else if (user.idCategory === 3) {
      return (
        <DashboardSurveys
          {...this.props}
          idType={params.idType}
          view_type={params.view_type}
        />
      );
    }
    return <DashboardGeneral {...this.props} />;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(Dashboard);
