// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import { TextField, CircularProgress, Autocomplete } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ImageResolutions } from 'graphql/Series';
import CustomPaper from '../Customizations/CustomPaper';
import { inputProps } from 'common';
const useStyles = makeStyles()(() => ({
  root: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      padding: 0,
      margin: 0,
    },
    '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
      padding: 0,
      margin: 0,
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 12,
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

export default function ImageSpacing({ holder }) {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [spacing, setImageSpacing] = React.useState('');
  const loading = open && options.length === 0;
  if (
    typeof holder !== 'undefined' &&
    typeof holder.getSpacing !== 'undefined'
  ) {
    holder.getSpacing = () => spacing;
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const response = await ImageResolutions();
        const resolutions = response.data.data;
        const imageresolutions = resolutions.ImageResolutions;
        //
        if (active) {
          setOptions(imageresolutions.ImageResolutions);
        }
      } catch (error) {
        /* empty*/
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      className={classes.root}
      getOptionLabel={option => {
        if (option) return option.Spacing;
        return '';
      }}
      id="ImageSpacing-Componet"
      isOptionEqualToValue={(option, value) => option.Spacing === value.Spacing}
      loading={loading}
      onChange={(event, value) => {
        if (value) {
          setImageSpacing(value.Spacing);
        } else {
          setImageSpacing('');
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={options}
      PaperComponent={CustomPaper}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            ...inputProps,
          }}
          label="ImageSpacing"
          variant="outlined"
        />
      )}
      size="small"
      style={{ minWidth: 200, maxWidth: 450 }}
    />
  );
}
