import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { QuerySeriesBodyParts } from 'graphql/Series';
import { Center, Titles } from './components';
import { ButtonReload } from 'components';

const useStyles = theme => ({
  root: {
    height: '100%',
    maxHeight: 500,
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
  reload: {
    margin: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
});

class BodyParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.holder = {
      reload: null,
      setLoading: loading => {
        if (loading === this.state.loading) {
          return;
        }
        this.setState({ loading });
      },
    };
  }

  reload = () => {
    this.setState({ loading: true });
    this.holder.reload();
  };

  render() {
    const { classes, className, handleAddError, ...rest } = this.props;
    const { loading } = this.state;
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <ButtonReload
              handleReload={this.reload}
              loading={loading}
              small
              tooltip="Body Parts"
            />
          }
          className={classes.reload}
          title="Body Parts"
        />
        <Divider />
        <CardContent>
          <QuerySeriesBodyParts
            handleAddError={handleAddError}
            holder={this.holder}
            setState={state => this.setState(state)}>
            <Center />
            <Titles />
          </QuerySeriesBodyParts>
        </CardContent>
      </Card>
    );
  }
}

BodyParts.propTypes = {
  className: PropTypes.string,
};

export default withStyles(BodyParts, useStyles);
