import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { GetData } from 'helpers';
import { QueryCenters } from 'graphql/Units/utils_centers';
import { inputProps } from 'common';

class InputCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centers: [],
      loading: false,
      open: false,
      selected: null,
      name: '',
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.resetCenter = () => {
        if (this.state.selected === null) return;
        this.setState({ selected: null });
      };
      props.holder.setCenter = (new_center = null) => {
        if (
          this.state.selected &&
          new_center &&
          this.state.selected.id === new_center.id
        ) {
          /**empty */
        } else if (this.state.selected && !new_center) {
          this.setState({ selected: null, name: '' });
        } else if (
          (this.state.selected && new_center) ||
          (!this.state.selected && new_center)
        ) {
          this.setState({ selected: new_center, name: new_center.name });
        } else {
          this.setState({ selected: null, name: '' });
        }
      };
    }
  }

  handleSelectCenter = (event, selected) => {
    event.preventDefault();
    //
    let name = '';
    if (selected) {
      name = selected.name;
    }
    this.setState({ selected, name });
    this.props.handleSelectCenter(selected);
  };
  handleChange = event => {
    const name = event.target.value;
    this.setState({ name, centers: [] });
  };
  handleTexCenterChange = (event, name) => {
    if (this.state.name === name) {
      return;
    }
    this.runSearh(name);
    this.setState({ name });
  };
  runSearh = (newName = null) => {
    const { loading } = this.state;
    if (loading) return;
    let { name } = this.state;
    if (newName && newName !== '') {
      name = newName;
    }
    this.setState({ loading: false, centers: [], name });
    (async () => {
      QueryCenters(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { centers, ok, errors } = data.centers;
          if (ok) {
            this.setState({ centers, loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  setOpen = open => {
    this.setState({ open });
  };

  render() {
    const { loading, centers, selected, open, name } = this.state;
    //
    const { disabled } = this.props;
    return (
      <Autocomplete
        disabled={disabled}
        getOptionLabel={option => {
          if (option) return option.name;
          return '';
        }}
        id="input-autocomplete-center"
        inputValue={name}
        // multiple
        loading={loading}
        onChange={this.handleSelectCenter}
        onClose={() => {
          this.setOpen(false);
        }}
        onInputChange={this.handleTexCenterChange}
        onOpen={() => {
          this.setOpen(true);
        }}
        open={open}
        options={centers}
        renderInput={params => (
          <TextField
            {...params}
            disabled={disabled}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            label="Centers"
            onChange={this.handleChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleAddCenter(selected);
              }
            }}
            variant="standard"
          />
        )}
        value={selected}
      />
    );
  }
}

InputCenters.propTypes = {
  classes: PropTypes.object,
  handleSelectCenter: PropTypes.func,
};
InputCenters.defaultProps = {
  disabled: false,
  handleSelectCenter: () => '',
};

export default InputCenters;
