import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { connect } from 'react-redux';

import ImageIcon from '@mui/icons-material/Image';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { theme_style, useStyles } from './Styles';
import { FormatTime, GetData, getInitials, ServerErrorsString } from 'helpers';
import { AcceptRequest, DeleteRequest } from 'graphql/Users';
import { ButtonsAcceptDelete } from 'components';
import { reloadNotification } from 'redux-store/actions';

class RequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepting: false,
      deleting: false,
    };
  }

  handleAcceptUsers = () => {
    const { user } = this.props;
    const { accepting } = this.state;
    if (accepting || !user) return;

    this.setState({ accepting: true });
    (async () => {
      AcceptRequest(user.idRequest)
        .then(res => {
          const data = GetData(res);
          this.props.reloadNotification(true);
          const { ok, errors } = data.registerRequest;
          if (ok) {
            this.setState({ accepting: false });
            this.props.handleUserDeleted();
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const error = ServerErrorsString(errors);

          this.setState({ accepting: false, error });
          if (error.startsWith('The request is not here')) {
            this.props.handleRemoveWrongRequest(user.idRequest);
          }
          this.props.handleAddError(error);
        });
    })();
  };
  handleDeleteUsers = () => {
    const { user } = this.props;
    const { deleting } = this.state;
    if (deleting || !user) return;
    this.setState({ deleting: true });

    (async () => {
      DeleteRequest(user.idRequest)
        .then(res => {
          const data = GetData(res);
          this.props.reloadNotification(true);
          const { ok, errors } = data.deleteRequest;
          if (ok) {
            this.props.handleUserDeleted();
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, open, user, handleDrawerClose, theme } = this.props;
    const { deleting, accepting } = this.state;

    const avatar = user.avatar ? user.avatar : '/images/avatars/avatar_0.png';
    const createdAt = `Created: ${FormatTime(user.createdAt, 'MMM Do, YYYY')}`;
    const message = user.Message !== '' ? user.Message : 'Empty';
    let unit_name = '';
    if (user.Unit) {
      unit_name = user.Unit.name;
    }
    let country = '';
    if (user.User && user.User.address) {
      country = user.User.address.country;
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_style}>
          <Drawer
            anchor="right"
            classes={{
              paper: classes.drawerPaper,
            }}
            className={classes.drawer}
            open={open}
            variant="persistent">
            <Divider />
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'rtl' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <ListItem>
              <ListItemAvatar>
                {avatar ? (
                  <Avatar src={avatar}>{getInitials(user.name)}</Avatar>
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText primary={user.name} secondary={createdAt} />
            </ListItem>
            <Divider variant="inset" />

            <div className={classes.section1}>
              <Grid alignItems="flex-start" container direction="column">
                <Grid item xs>
                  <Typography variant="h5">Message</Typography>
                </Grid>
                <Typography color="textSecondary" component="p" variant="body2">
                  {message}
                </Typography>
              </Grid>
              <ListItemText
                primary={<Typography variant="h6">Country</Typography>}
                secondary={country}
              />
              <ListItemText
                primary={<Typography variant="h6">Unit</Typography>}
                secondary={unit_name}
              />
              <ListItemText
                primary={<Typography variant="h6">E-mail</Typography>}
                secondary={user.email}
              />
              <ListItemText
                primary={<Typography variant="h6">Project Request</Typography>}
                secondary={user.project ? user.project : 'No defined'}
              />
              <ListItemText
                primary={
                  <Typography variant="h6">Existing Projects</Typography>
                }
                secondary={
                  user.other_projects ? user.other_projects : 'Not found'
                }
              />
            </div>
            <Divider />
            <ButtonsAcceptDelete
              accepting={accepting}
              deleting={deleting}
              handleAcceptUsers={this.handleAcceptUsers}
              handleDeleteUsers={this.handleDeleteUsers}
            />
          </Drawer>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

RequestDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  notifications_reload: state.page_activity.notifications_reload,
});
const ContainerRequestDetails = props => {
  const theme = useTheme();
  return <RequestDetails theme={theme} {...props} />;
};
export default connect(mapStateToProps, { reloadNotification })(
  withStyles(ContainerRequestDetails, useStyles)
);
