import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { clearLoginError, login } from 'redux-store/actions/loginActions';
import { ButtonLoading } from '../../Buttons';
import { schema, useStyles } from './components/Styles';
import { inputProps } from 'common';

class DialogLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
      },
      loading: false,
    };
  }

  componentDidMount() {
    const { formState } = this.state;
    const errors = validate(formState.values, schema);
    this.setState({
      formState: {
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {},
      },
    });
    this.signed();
  }

  componentDidUpdate() {
    const { formState } = this.state;
    const errors = validate(formState.values, schema);
    const isValid = errors ? false : true;
    if (formState.isValid !== isValid) {
      this.setState({
        formState: {
          ...formState,
          isValid: isValid,
          errors: errors || {},
        },
      });
    }
    this.signed();
  }

  handleLogin = () => {
    const { formState } = this.state;
    this.setState({ loading: true });
    this.props.login(
      formState.values['email'],
      formState.values['password'],
      this.props.ReactGA4
    );
  };

  handleChange = event => {
    event.persist();
    const { formState } = this.state;
    this.setState({
      loading: false,
      formState: {
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true,
        },
      },
    });
  };
  hasError = field =>
    this.state.formState.touched[field] && this.state.formState.errors[field]
      ? true
      : false;
  signed = () => {
    const { user } = this.props;
    if (user && user.username) {
      this.props.handleLogin();
    }
  };
  handleCloseError = () => {
    this.props.clearLoginError();
    this.setState({ loading: false });
  };

  render() {
    const { classes, open, errors, handleClose, user } = this.props;
    const { formState } = this.state;
    let { loading } = this.state;
    // let message_text = '';
    if (errors && errors.length) {
      // message_text = errors.map(x => x.message).join('\n');
      loading = false;
    }
    return (
      <div>
        <Dialog
          aria-labelledby="form-dialog-login"
          onClose={() => handleClose(user)}
          open={open}>
          <DialogTitle
            className={classNames(classes.title)}
            id="form-dialog-loging-mb">
            <Grid container spacing={1}>
              <Grid item>
                <AccountBalanceIcon />
              </Grid>
              <Grid item style={{ marginTop: 2 }}>
                Medical-Blocks Login
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To login to the website, please enter your email address and
              password.
            </DialogContentText>
            <TextField
              className={classes.textField}
              error={this.hasError('email')}
              fullWidth
              helperText={
                this.hasError('email') ? formState.errors.email[0] : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ width: 30, height: 30, color: '#bdbdbd' }}
                    />
                  </InputAdornment>
                ),
                ...inputProps,
              }}
              label="Email address"
              name="email"
              onChange={this.handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={this.hasError('password')}
              fullWidth
              helperText={
                this.hasError('password') ? formState.errors.password[0] : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faUnlockAlt}
                      style={{ width: 30, height: 30, color: '#bdbdbd' }}
                    />
                  </InputAdornment>
                ),
                ...inputProps,
              }}
              label="Password"
              name="password"
              onChange={this.handleChange}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter') {
                  this.handleSignIn(event);
                }
              }}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => handleClose(null)}>
              Cancel
            </Button>
            <ButtonLoading
              handleClick={this.handleLogin}
              loading={loading}
              name="Login"
              transform={false}
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogLogin.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  handleLogin: PropTypes.func,
};
DialogLogin.defaultProps = {
  handleClose: () => '',
  handleLogin: () => '',
};

const mapStateToProps = state => ({
  user: state.info_user.user,
  errors: state.info_user.errors,
});

export default connect(mapStateToProps, { login, clearLoginError })(
  withStyles(withRouter(DialogLogin), useStyles)
);
