import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_encryp_patients } from '../Styles';
import TabEncryptionPatientToolbar from './TabEncryptionPatientToolbar';
import { Grid } from '@mui/material';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import { QueryEncryptionPatients } from 'graphql/Anonymization';
import { IsInvalid } from 'helpers';

class TabEncryptionPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PatientName: '',
      total: 0,
      loading: false,
      patients: [],
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    this.handleLoadPatients();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoadPatients();
  }
  handleLoadPatients = (inputs, new_page = null, new_limit = null) => {
    if (!this.props.isActive) return;
    let { loading, PatientName, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    if (IsInvalid(inputs)) {
      inputs = { PatientName };
    } else {
      /** empty */
    }
    console.log('TabEncryptionPatient', { inputs });

    (async () => {
      QueryEncryptionPatients(inputs, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients, total } = data.encryptionPatients;
          if (ok) {
            this.setState({
              loading: false,
              patients: patients.map(x => {
                return { ...x };
              }),
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleEncryptedPatientCreated = (new_patients, clean_edit = false) => {
    console.log({ new_patients, clean_edit });
    let { patients, total, rows_edited } = this.state;
    for (let i = 0; i < new_patients.length; i++) {
      const { id } = new_patients[i];
      const index = patients.map(x => x.id).indexOf(id);
      if (index === -1) {
        patients.unshift(new_patients[i]);
        total++;
      } else {
        patients[index] = {
          ...patients[index],
          ...new_patients[i],
        };
      }
    }
    if (clean_edit) rows_edited = [];
    this.setState({ patients, total, rows_edited });
  };
  handleAddPatientConsents = patient_consents => {
    console.log({ patient_consents });
    let { patients, selected } = this.state;
    for (let i = 0; i < patients.length; i++) {
      const { id } = patients[i];
      const index = patient_consents.map(x => x.idPatient).indexOf(id);
      if (index !== -1) {
        patients[i] = {
          ...patients[i],
          patient_consent: {
            ...patient_consents[index],
          },
        };
        const j = selected.map(x => x.id).indexOf(id);
        if (j !== -1) {
          selected[j] = {
            ...selected[j],
            patient_consent: {
              ...patient_consents[index],
            },
          };
        }
      }
    }
    this.setState({ patients, selected });
  };
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TabEncryptionPatientToolbar
            classes={this.props.classes}
            handleAddError={this.props.handleAddError}
            handleAddPatientConsents={this.handleAddPatientConsents}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleEncryptedPatientCreated={this.handleEncryptedPatientCreated}
            handleReload={inputs => this.handleLoadPatients(inputs)}
            idProject={this.props.idProject}
            loading={this.state.loading}
            patients={this.state.patients}
            rows_edited={this.state.rows_edited}
            selected={this.state.selected}
            setState={state => this.setState(state)}
            total={this.state.total}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_encryp_patients}
            height={this.props.height}
            loading={this.state.loading}
            onPageChange={page =>
              this.handleLoadPatients(null, page, this.state.limit)
            }
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={limit =>
              this.handleLoadPatients(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.patients}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabEncryptionPatient.propTypes = {
  classes: PropTypes.object,
};

export default TabEncryptionPatient;
