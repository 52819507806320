import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useStyles } from '../Styles';
import { GetData } from 'helpers';
import { inputProps } from 'common';
import { QueryEncryptionPatients } from 'graphql/Anonymization';
import { IsInvalid } from 'helpers';

class SearchEncryptedPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      patient: {},
      open: false,
      options: [],
      loading: false,
      inputValue: '',
    };
  }

  runSearch = () => {
    const { loading, name: PatientName } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    console.log('SearchEncryptedPatients', { PatientName });
    (async () => {
      QueryEncryptionPatients({ PatientName }, 0, 10)
        .then(res => {
          const data = GetData(res);
          const { ok, patients, errors } = data.encryptionPatients;
          if (ok) {
            this.setState({ options: patients, loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ options: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handelNameChanged = event => {
    this.setState({ name: event.target.value, open: true });
    this.runSearch();
  };
  handleSelectPatient = patient => {
    this.setState({ patient });
    this.props.handleAddEncryptedPatient(patient);
  };
  handleTextInputChangen = inputValue => {
    this.setState({ inputValue });
  };

  render() {
    const { classes, style, download_button } = this.props;
    const { patient, open, options, loading, inputValue } = this.state;
    const height = '25px';
    let style_text = {
      height,
      margin: 0,
      padding: 0,
      borderWidth: 0,
      border: 0,
    };
    let style_auto = {
      width: '100%',
      height,
    };
    let variant = undefined;
    let style_textfield = undefined;
    let style_textfield_extern = undefined;
    if (this.props.text_field) {
      style_text = {};
    } else {
      variant = 'standard';
      style_textfield = {
        borderWidth: 0,
        border: 0,
      };
      style_auto = {
        ...style_auto,
        borderWidth: 0,
        border: 0,
      };
      style_textfield_extern = {
        marginLeft: '6px',
        margin: 0,
        height,
        borderWidth: 0,
        border: 0,
      };
    }
    const ComponentSearch = (
      <Autocomplete
        // freeSolo
        getOptionLabel={option => {
          if (options.length === 0) return '';
          return option.PatientName;
        }}
        id="search-encrypted-patients--input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          const { PatientName } = option;
          if (IsInvalid(PatientName)) return true;
          if (typeof value.PatientName === 'undefined') return true;
          return option.PatientName === value.PatientName;
        }}
        label="DICOM Tag"
        loading={loading}
        onChange={(event, newValue) => {
          this.handleSelectPatient(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleTextInputChangen(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
              style: style_textfield,
            }}
            InputProps={{
              ...params.InputProps,
              // disableUnderline: true,
              style: style_text,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="patient"
            onChange={this.handelNameChanged}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter' && patient && Object.keys(patient).length) {
                this.props.handleAddEncryptedPatient(patient);
                this.setState({
                  options: [],
                  patient: {},
                  open: false,
                  inputValue: '',
                });
              }
            }}
            placeholder="Search Encrypted Patient..."
            style={style_textfield_extern}
            variant={variant}
          />
        )}
        size={this.props.size}
        style={style_auto}
      />
    );
    if (this.props.text_field) {
      return <React.Fragment>{ComponentSearch}</React.Fragment>;
    }
    return (
      <Paper className={classes.root} style={style}>
        <SearchIcon className={classes.icon} />
        {ComponentSearch}
        {download_button && (
          <React.Fragment>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              aria-label="directions"
              className={classes.iconButton}
              color="primary"
              onClick={() => {
                this.props.handleAddEncryptedPatient(patient);
                this.setState({
                  options: [],
                  patient: {},
                  inputValue: '',
                  open: false,
                });
              }}
              size="large">
              <GetAppIcon />
            </IconButton>
          </React.Fragment>
        )}
      </Paper>
    );
  }
}

SearchEncryptedPatients.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  text_field: PropTypes.bool,
};
SearchEncryptedPatients.defaultProps = {
  download_button: false,
  handleAddEncryptedPatient: () => '',
  text_field: false,
  size: 'small',
};
export default withStyles(SearchEncryptedPatients, useStyles);
