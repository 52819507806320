import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROJECT_PATIENTS = gql`
  query($idProject: LongLong!, $idCenter: LongLong, $page: Int, $limit: Int) {
    projectPatients(
      idProject: $idProject
      idCenter: $idCenter
      page: $page
      limit: $limit
    ) {
      ok
      total
      project_patients {
        id
        idFolderContent
        idPatient
        idSeries
        patient_code
        patient {
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
          PatientAge
        }
        center {
          id
          name
        }
        total_studies
        total_series
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryProjectPatients = (idProject, idCenter, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_PATIENTS),
    variables: {
      idProject,
      idCenter,
      page,
      limit,
    },
  });
};
