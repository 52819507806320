import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';

import { CreateLandingObject, ToolBarLandingStopped } from './components';
import { TableGrid } from 'components';
import { QueryLandingObjects } from 'graphql/Administration/utils_landing_objects';
import { GetData, ServerErrorsString } from 'helpers';
import { columns_landing } from '../utils_common';
import { Paper } from '@mui/material';
import { rowsPerPageBig } from 'helpers';
import { IsInvalid } from 'helpers';
import { AddLandingObject } from 'redux-store/actions';
const useStyles = () => ({
  paper: {
    padding: 2,
    paddingTop: 6,
    paddintBottom: 7,
    marginBottom: 2,
  },
});
class TabLandingStopped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 100,
      loading: false,
      deleting: false,
      page: 0,
      open_delete: false,
      selected: [],
      landings_stopped: [],
      rows_edited: [],
      init: false,
      open_create: false,
    };
    const { holder } = props;
    if (holder) {
      holder.IsStopped = landing => {
        if (IsInvalid(landing)) return;
        const { landings_stopped } = this.state;
        const index = landings_stopped
          .map(x => x.idLanding)
          .indexOf(landing.idLanding);
        return index === -1 ? false : true;
      };
      holder.addToStopped = landing => {
        if (IsInvalid(landing)) return;
        const { idLanding, processing } = landing;
        const { landings_stopped } = this.state;
        const index = landings_stopped.map(x => x.idLanding).indexOf(idLanding);
        if (index === -1 && !processing) {
          landings_stopped.push(landing);
        } else if (processing) {
          this.handleRemoveStopped([landing]);
          this.props.addRunning([landing]);
        }
      };
    }
  }
  componentDidMount() {
    if (!this.state.init) this.handleReloadTable();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleReloadTable();
  }
  handleReloadTable = (newName = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    let { loading, name, page, limit } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) name = newName;
    else if (this.props.holder.getSearchText) {
      name = this.props.holder.getSearchText();
    }
    this.setState({ loading: true, page, limit });
    (async () => {
      QueryLandingObjects(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, landings, total, errors } = data.landingObjects;
          if (ok) {
            this.setState({
              loading: false,
              empty: !landings.length,
              landings_stopped: landings.filter(x => !x.processing),
              total,
              page,
              limit,
              rows_edited: [],
              init: true,
            });
            this.props.addRunning(landings.filter(x => x.processing));
            this.props.setState({ landings_new: [] });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            loading: false,
            empty: true,
            init: true,
          });
        });
    })();
  };
  handleRemoveStopped = selected => {
    let { landings_stopped, total } = this.state;
    const ids = selected.map(x => x.idLanding);
    landings_stopped = landings_stopped.filter(
      x => ids.indexOf(x.idLanding) === -1
    );
    total -= ids.length;
    this.setState({ landings_stopped, total, deleting: false, selected: [] });
  };
  handleSaveEdited = () => {
    const { rows_edited, landings_stopped } = this.state;
    const { landings_new } = this.props;
    for (let i = 0; i < rows_edited.length; i++) {
      const id = rows_edited[i].idLanding;
      let index = landings_stopped.map(x => x.idLanding).indexOf(id);
      if (index !== -1) {
        landings_stopped[index] = {
          ...rows_edited[i],
        };
      }
      index = landings_new.map(x => x.idLanding).indexOf(id);
      if (index !== -1) {
        landings_new[index] = {
          ...rows_edited[i],
        };
      }
    }
    this.setState({ landings_stopped, rows_edited: [] });
    this.props.setState({ landings_stopped: landings_new });
  };
  handleAddLanding = landings => {
    for (let i = 0; i < landings.length; i++) {
      this.props.holder.addToStopped(landings[i]);
    }
    this.props.AddLandingObject(null);
    this.setState({ open_create: false });
  };
  render() {
    const { classes, landings_new, add_landing_object } = this.props;
    let { height } = this.props;
    if (height) {
      height = height - 300;
      if (height < 300) height = null;
    }
    if (this.state.open_create || add_landing_object) {
      return (
        <CreateLandingObject
          add_landing_object={add_landing_object}
          AddLandingObject={this.props.AddLandingObject}
          handleAddError={this.props.handleAddError}
          handleAddLanding={this.handleAddLanding}
          handleClose={() => {
            this.props.AddLandingObject(null);
            this.setState({ open_create: false });
          }}
        />
      );
    }
    const { landings_stopped } = this.state;
    let total = this.state.total + landings_new.length;
    const { limit } = this.state;
    let all_landing = landings_new.concat(landings_stopped);
    return (
      <Paper className={classes.root}>
        <ToolBarLandingStopped
          handleAddError={this.props.handleAddError}
          handleClearSelected={() => this.setState({ selected: [] })}
          handleReloadTable={name => this.handleReloadTable(name)}
          handleRemoveStopped={this.handleRemoveStopped}
          handleSaveEdited={this.handleSaveEdited}
          holder={this.props.holder}
          landing_objects={all_landing}
          loading_table={this.state.loading}
          row_highlight={this.state.row_highlight}
          rows_edited={this.state.rows_edited}
          selected={this.state.selected}
          setState={state => this.setState(state)}
          title="Stopped Landing Objects"
        />
        <TableGrid
          checkboxSelection
          dense
          external_edited={this.state.rows_edited}
          external_selected={this.state.selected}
          grid
          headers={columns_landing}
          height={height}
          idRow="idLanding"
          loading={this.state.loading}
          onPageChange={page => this.handleReloadTable(null, page, limit)}
          onRowClicked={row_highlight => this.setState({ row_highlight })}
          onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
          onRowsPerPageChange={limit => this.handleReloadTable(null, 0, limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          row_height={33}
          rowCount={total}
          rows={all_landing}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TabLandingStopped.propTypes = {
  classes: PropTypes.object,
};
TabLandingStopped.defaultProps = {
  landings_new: [],
};
const mapStateToProps = state => {
  const { add_landing_object } = state.manager;
  return {
    add_landing_object,
  };
};

export default connect(mapStateToProps, {
  AddLandingObject,
})(withRouter(withStyles(TabLandingStopped, useStyles)));
