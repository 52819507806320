import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { ButtonLoading, DialogTitle, TextFieldCustom } from 'components';
import { GetData, ServerErrorsString, IsInvalid } from 'helpers';
import { MutationUpdateProjectMapping } from 'graphql/Projects/utils_project_mapping';
class EditProjectMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: null,
      updating: false,
    };
  }
  componentDidMount() {
    const { selected } = this.props;
    if (!selected.length) return;
    this.setState({
      inputs: [
        {
          id: selected[0].id,
          key: selected[0].key,
          type: selected[0].type,
          value: selected[0].value,
        },
      ],
    });
  }
  componentDidUpdate() {
    const { selected } = this.props;
    if (!selected.length) {
      if (this.state.inputs) {
        this.setState({ inputs: null });
      }
      return;
    }
    if (this.state.inputs) return;
    this.setState({
      inputs: [
        {
          id: selected[0].id,
          key: selected[0].key,
          type: selected[0].type,
          value: selected[0].value,
        },
      ],
    });
  }
  handleTextChanged = event => {
    let { inputs } = this.state;
    if (IsInvalid(inputs) || !inputs.length) {
      inputs = [{}];
    }
    this.setState({
      inputs: [{ ...inputs[0], [event.target.name]: event.target.value }],
    });
  };
  handleRunUpdateMapping = () => {
    const { idProject } = this.props;
    const { updating, inputs } = this.state;
    if (updating) return;
    if (IsInvalid(inputs)) {
      this.props.handleAddError('You need to fill the inputs first');
      return;
    }
    this.setState({ updating: true });
    (async () => {
      MutationUpdateProjectMapping(idProject, inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.editProjectMapping;
          if (ok) {
            this.setState({ updating: false, inputs: null });
            this.props.handlEditMapping(inputs);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { open } = this.props;
    // if (IsInvalid(this.state.inputs)) return null;
    let input = { key: '', value: '', type: '' };
    if (this.state.inputs) {
      input = this.state.inputs[0];
    }
    return (
      <Dialog onClose={this.props.handleClose} open={open}>
        <DialogTitle>Edit Project Mapping</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit all the necessary information of the mapping.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextFieldCustom
                autoFocus
                label="Type"
                name="type"
                onChange={this.handleTextChanged}
                value={input.type}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldCustom
                label="Key"
                name="key"
                onChange={this.handleTextChanged}
                value={input.key}
              />
            </Grid>
            <Grid item xs>
              <TextFieldCustom
                label="Value"
                name="value"
                onChange={this.handleTextChanged}
                value={input.value}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <ButtonLoading
            disabled={this.state.inputs ? false : true}
            handleClick={this.handleRunUpdateMapping}
            loading={this.state.updating}
            name="Update"
            transform={false}
            variant="standard"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

EditProjectMapping.propTypes = {
  classes: PropTypes.object,
};

export default EditProjectMapping;
