import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tabs, Tab, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { TabLandingRunning, TabLandingStopped } from './components';
import { TabPanel } from 'components';
import { useStylesAdministrator } from 'views/Administrator/components/AdminDefault/useStyles';
import SubscribeLandingObjects from 'graphql/Subscriptions/SubscribeLandingObjects';
import { IsInvalid, getLevels, getTabLabel } from 'helpers';
import { AddLandingObject } from 'redux-store/actions';
class TabLandingObjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      landings_new: [],
      name: '',
      tabValue: 0,
      proccessing: false,
      tabs: [
        { name: 'stopped', TabComponent: TabLandingStopped },
        { name: 'running', TabComponent: TabLandingRunning },
      ],
    };
    this.holder = {
      getSelected: null,
      clean: null,
      addRunning: null,
      addToStopped: null,
      IsRunning: null,
      IsStopped: null,
      landingChanged: null,
      getSearchText: null,
      removeStopped: selected => this.handleRemoveStopped(selected),
    };
  }
  componentDidMount() {
    const { level_3 } = getLevels(this.props);
    // console.log({ level_2, level_3 });
    let tabValue = 0;
    const index = this.state.tabs
      .map(x => getTabLabel(x.name))
      .indexOf(level_3);
    if (index >= 0 && index < this.state.tabs.length) {
      tabValue = index;
    }
    this.setState({ tabValue });
  }
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.handleReloadTable(newPage, this.state.limit);
  };
  handleRemoveStopped = selected => {
    let { landings_new, total } = this.state;
    const ids = selected.map(x => x.idLanding);
    landings_new = landings_new.filter(x => ids.indexOf(x.idLanding) === -1);
    total -= ids.length;
    this.setState({ landings_new, total });
  };
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    const { tabs } = this.state;
    this.setState({ tabValue }, () => {
      this.props.history.push(
        `/administrator/system_elements/landing_objects/${tabs[tabValue].name}`
      );
    });
  };
  handleAddToStopped = stopped => {
    let { landings_new, total } = this.state;
    for (let i = 0; i < stopped.length; i++) {
      const { idLanding } = stopped[i];
      const index = landings_new.map(x => x.idLanding).indexOf(idLanding);
      if (index === -1) {
        landings_new.unshift(stopped[i]);
        total++;
      }
    }
    this.setState({ landings_new, total });
  };
  handleLandingChanged = landing => {
    if (IsInvalid(landing)) return;
    const { idLanding, proccessing, status } = landing;
    if (this.holder.IsRunning(landing)) {
      this.holder.landingChanged(landing);
    } else {
      const { landings_new } = this.state;
      const index = landings_new.map(x => x.idLanding).indexOf(idLanding);
      if (index !== -1) {
        if (landings_new[index].proccessing === proccessing) return;
        landings_new[index].proccessing = proccessing;
        landings_new[index].status = status;
        this.setState({ landings_new });
      } else {
        this.holder.addToStopped(landing);
      }
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { height, classes } = this.props;
    const { tabValue } = this.state;
    return (
      <React.Fragment>
        <SubscribeLandingObjects
          handleLandingChanged={this.handleLandingChanged}
        />
        <Paper square>
          <Tabs
            className={classes.tabs_small}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map(tab => {
              const label = getTabLabel(tab.name);
              return (
                <Tab className={classes.tab_small} key={label} label={label} />
              );
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div
          style={{
            width: '100%',
            height: height - 185,
            padding: 0,
            margin: 0,
          }}>
          <div style={{ height: '100%', padding: 0, margin: 0, paddingTop: 5 }}>
            {this.state.tabs.map((tab, index) => {
              const { TabComponent, name } = tab;
              const label = getTabLabel(name);
              return (
                <TabPanel
                  index={index}
                  key={`tab-landing-${index}-${label}`}
                  style={{ padding: 0, margin: 0 }}
                  value={tabValue}>
                  <TabComponent
                    addRunning={landings => this.holder.addRunning(landings)}
                    handleAddError={this.props.handleAddError}
                    handleAddToStopped={this.handleAddToStopped}
                    height={height}
                    holder={this.holder}
                    isActive={index === tabValue}
                    landings_new={this.state.landings_new}
                    proccessing={this.state.proccessing}
                    setState={state => this.setState(state)}
                    user={this.props.user}
                  />
                </TabPanel>
              );
            })}
            {/* <TabPanel
              index={0}
              style={{ padding: 0, margin: 0 }}
              value={tabValue}>
              <TabLandingStopped
                addRunning={landings => this.holder.addRunning(landings)}
                handleAddError={this.props.handleAddError}
                height={height}
                holder={this.holder}
                isActive={0 === tabValue}
                landings_new={this.state.landings_new}
                proccessing={this.state.proccessing}
                setState={state => this.setState(state)}
                user={this.props.user}
              />
            </TabPanel>
            <TabPanel
              index={1}
              style={{ padding: 0, margin: 0 }}
              value={tabValue}>
              <TabLandingRunning
                handleAddError={this.props.handleAddError}
                handleAddToStopped={this.handleAddToStopped}
                height={height - 12}
                holder={this.holder}
                isActive={1 === tabValue}
                user={this.props.user}
              />
            </TabPanel> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TabLandingObjects.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { add_landing_object } = state.manager;
  return {
    add_landing_object,
  };
};

export default connect(mapStateToProps, {
  AddLandingObject,
})(withStyles(withRouter(TabLandingObjects), useStylesAdministrator));
