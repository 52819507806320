import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, TableCell, TableRow } from '@mui/material';

class MetaRow extends Component {
  render() {
    let { Name, Value, Tag } = this.props;
    if (Array.isArray(Value)) {
      Value = Value.join('\\\\');
    }
    return (
      <TableRow style={{ margin: 0, padding: 0 }}>
        <TableCell style={{ margin: 0, padding: 0, width: 300 }}>
          <Box fontSize={10} fontWeight="fontWeightBold">
            {Name}
          </Box>
        </TableCell>
        <TableCell style={{ margin: 0, padding: 0, width: 400 }}>
          <Box fontSize={10} fontWeight="normal">
            {Value}
          </Box>
        </TableCell>
        <TableCell style={{ margin: 0, padding: 0, width: 400 }}>
          <Box fontSize={10} fontWeight="normal">
            {Tag}
          </Box>
        </TableCell>
      </TableRow>
    );
  }
}

MetaRow.propTypes = {
  classes: PropTypes.object,
};

export default MetaRow;
