import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Divider, Grid, Paper } from '@mui/material';
import { TableGrid, GroupBox, CardEditHeader } from 'components';
import { columns_batch_jobs, columns_batches } from 'common';
import { rowsPerPageBig } from 'helpers';
import { ServerErrorsString, GetData } from 'helpers';
import { QueryJobsAtBatches, MutationDeleteJobProcess } from 'graphql/Process';

import { useStyles } from './Styles';
import {
  DialogBatchProcessSetting,
  DialogJobProcessSetting,
} from '../ToolBarJobsActions/components';
import { ToolbarBatchProcesses, ToolbarJobsAtBatch } from './components';
import { SubsBatchJobFinished } from 'graphql/Subscriptions';
import { getUpdatedBatchesAtJob } from '../utils_jobs';
class EditBatchJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: null,
      open_job_setting: false,
      open_setting: false,
      loading: false,
      selected_jobs: [],
      page: 0,
      limit: 50,
      jobs: [],
      total: 0,
      batch: null,
      deleting: false,
    };
    this.holder = {
      setSetting: null,
      cleanHighlight: null,
    };
  }
  componentDidMount() {
    this.loadBatchesJobs();
  }
  loadBatchesJobs = () => {
    const { batches_with_jobs } = this.props;
    if (batches_with_jobs.length <= 0) return;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, selected_jobs: [] });
    (async () => {
      QueryJobsAtBatches(batches_with_jobs.map(x => x.id))
        .then(res => {
          const data = GetData(res);
          const { ok, errors, jobs, total } = data.jobsAtBatches;
          if (ok) {
            let batches = [];
            for (let i = 0; i < jobs.length; i++) {
              const { batch } = jobs[i];
              if (batch === undefined || !batch) continue;
              let index = batches.map(x => x.id).indexOf(batch.id);
              if (index === -1) {
                batches.push(batch);
              }
            }
            this.setState({ loading: false, jobs, total });
            this.props.handleUpdateBatches(batches);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleReloadTable = () => {
    this.loadBatchesJobs();
  };
  handleBatchRowClicked = batch => {
    const { job } = this.state;
    this.holder.setSetting(batch ? batch.setting : null);
    this.setState({ batch, selected_jobs: [], job: batch ? job : null });
  };
  handleJobRowClicked = job => {
    this.setState({ job });
  };
  handleSaveSetting = setting => {
    const { batch } = this.state;
    const index = this.props.batches_with_jobs.map(x => x.id).indexOf(batch.id);
    if (index !== -1) {
      this.props.batches_with_jobs[index] = {
        ...this.props.batches_with_jobs[index],
        setting: { ...setting },
      };
    }
    this.holder.cleanHighlight();
    this.setState({
      setting,
      open_setting: false,
      batch: null,
    });
    console.log('handleSaveSetting', {
      setting,
      batches_with_jobs: this.props.batches_with_jobs,
    });
    this.props.handleSaveSetting(setting);
  };
  handleDeleteSelectedJobs = () => {
    let { deleting, selected_jobs, jobs, batch } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      const ids = selected_jobs.map(x => x.id);
      MutationDeleteJobProcess(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteJobs;
          if (ok) {
            jobs = jobs.filter(x => ids.indexOf(x.id) === -1);
            this.setState({
              deleting: false,
              selected_jobs: [],
              jobs,
            });
            this.props.handleUpdateJobs(batch.id, jobs);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUpdateJobProcess = job => {
    // console.log({ job });
    if (job === undefined || !job) return;
    const { jobs } = this.state;
    const index = jobs.map(x => x.id).indexOf(job.id);
    if (index !== -1) {
      jobs[index] = {
        ...job,
      };
      this.handleUpdateBatches(jobs);
      this.setState({ jobs, job });
    }
  };
  handleUpdateBatches = jobs => {
    const batches = getUpdatedBatchesAtJob(jobs);
    this.props.handleUpdateBatches(batches);
  };
  handleBatchJobFinished = job => {
    console.log('handleBatchJobFinished', { job });
    const { jobs, batch } = this.state;

    const index = jobs.map(x => x.id).indexOf(job.id);
    if (index === -1) return;
    jobs[index] = {
      ...jobs[index],
      ...job,
      batch: {
        ...jobs[index].batch,
        ...job.batch,
      },
    };
    let jobs_at_batch = [jobs[index]];
    if (batch) {
      jobs_at_batch = jobs.filter(x => x.idBatch === batch.id);
    }
    this.setState({ jobs });
    this.handleUpdateBatches(jobs_at_batch);
  };
  render() {
    const { classes, batches_with_jobs } = this.props;
    let height = this.props.height;
    const { jobs, batch } = this.state;
    let jobs_at_batch = [];
    let setting = null;
    let ComponentSubscription = null;
    if (batch) {
      jobs_at_batch = jobs.filter(x => x.idBatch === batch.id);
      setting = { ...batch.setting };
      ComponentSubscription = (
        <SubsBatchJobFinished
          handleBatchJobFinished={this.handleBatchJobFinished}
          idBatch={batch.id}
        />
      );
    }
    if (height) {
      height = height - 130;
    }
    let hegiht_top = null;
    let hegiht_bottom = null;
    if (height === undefined || !height || height < 50) {
      height = '100%';
    } else {
      hegiht_top = height - height * 0.8;
      hegiht_bottom = height - height * 0.6;
    }
    // console.log('EditBatchJobs', { batch, batches_with_jobs });
    return (
      <Paper sx={{ height, margin: 1 }}>
        {ComponentSubscription}
        <Grid container>
          <Grid item xs={12}>
            <CardEditHeader
              description="Click in the row to display element information of the batch"
              handleClose={() => this.props.handleOpenBatchEdit(false)}
              handleReload={this.handleReloadTable}
              loading={this.state.loading}
              show_reload
              title="Edit selected elements"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ToolbarBatchProcesses
              batch={batch}
              classes={classes}
              handleAddError={this.props.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
              handleOpenEdit={() => this.setState({ open_setting: true })}
              handleUpdateBatches={this.props.handleUpdateBatches}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item sx={{ overflow: 'auto' }}>
              <TableGrid
                dense
                disabled={this.state.loading}
                disableSelectionOnClick
                headers={columns_batches}
                height={hegiht_top}
                holder={this.holder}
                limit={this.state.limit}
                onHighlightChange={this.handleBatchRowClicked}
                onRowClicked={this.handleBatchRowClicked}
                page={this.state.page}
                rows={batches_with_jobs}
                rowsPerPage={this.state.limit}
                rowsPerPageOptions={rowsPerPageBig}
              />
            </Grid>
            <Grid
              item
              sx={{
                overflow: 'auto',
                paddingTop: 2,
                paddingBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
              }}
              xs={12}>
              <GroupBox
                title_box={`Current JOBs of Batch ${
                  batch ? `(${batch.id})` : ''
                }`}>
                <Grid container>
                  <Grid item sx={{ paddingBottom: 1 }}>
                    <ToolbarJobsAtBatch
                      deleting={this.state.deleting}
                      handleAddError={this.props.handleAddError}
                      handleDeleteSelectedJobs={this.handleDeleteSelectedJobs}
                      handleOpenJobSetting={() =>
                        this.setState({ open_job_setting: true })
                      }
                      handleUpdateJobProcess={this.handleUpdateJobProcess}
                      job={this.state.job}
                      selected={this.state.selected_jobs}
                    />
                  </Grid>
                  <Grid item style={{ padding: 0 }} xs={12}>
                    <TableGrid
                      checkboxSelection
                      dense
                      disableSelectionOnClick
                      external_selected={this.state.selected_jobs}
                      headers={columns_batch_jobs}
                      height={hegiht_bottom}
                      loading={this.state.loading}
                      onHighlightChange={this.handleJobRowClicked}
                      onRowClicked={this.handleJobRowClicked}
                      onSelectedChanged={selected_jobs =>
                        this.setState({
                          selected_jobs,
                          job: selected_jobs.length ? null : this.state.job,
                        })
                      }
                      rows={jobs_at_batch}
                    />
                  </Grid>
                </Grid>
              </GroupBox>
            </Grid>
          </Grid>
        </Grid>
        <DialogBatchProcessSetting
          batch={batch}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_setting: false })}
          handleSave={this.handleSaveSetting}
          holder={this.holder}
          open={this.state.open_setting}
          setting={setting}
        />
        <DialogJobProcessSetting
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_job_setting: false })}
          handleUpdateJobProcess={this.handleUpdateJobProcess}
          holder={this.holder}
          job={this.state.job}
          open={this.state.open_job_setting}
        />
      </Paper>
    );
  }
}

EditBatchJobs.propTypes = {
  batches_with_jobs: PropTypes.array,
  classes: PropTypes.object,
};
EditBatchJobs.defaultProps = {
  batches_with_jobs: [],
  handleSaveSetting: () => '',
  handleUpdateBatches: () => '',
};

export default withStyles(EditBatchJobs, useStyles);
