/* eslint-disable indent */
import { lighten } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material';

export const useToolbarStyles = theme => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(grey[500], 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: grey[200],
        },
  title: {
    flex: '1 1 100%',
  },
});

export const theme = createTheme({
  typography: {
    h4: {
      // fontFamily: '"Montserrat", Open Sans',
      fontSize: '20px',
      fontWeight: 1000,
    },
    h6: {
      fontSize: '15px',
    },
    body1: {
      fontSize: '15px',
    },
  },
});
