import React from 'react';
import PropTypes from 'prop-types';
import { Badge, CircularProgress, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { green } from '@mui/material/colors';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

import useStyles from 'components/Buttons/ButtonDownloadExplorerContent/Styles';
import { addDownloads } from 'redux-store/actions';
import { getNewItems } from '../index';

class ButtonAddDownloadList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleAddToList = () => {
    const { downloads } = this.props;
    const selected = getNewItems(this.props.selected, downloads);
    const totals = selected.concat(downloads);
    console.log({ totals });
    this.props.addDownloads(totals);
  };

  render() {
    const { classes, loading, defaultProps } = this.props;
    const selected = getNewItems(this.props.selected, this.props.downloads);
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Tooltip title="Add to Download List...">
            <Badge
              badgeContent={selected.length}
              onClick={this.handleAddToList}
              {...defaultProps}
            />
          </Tooltip>
          {loading && (
            <CircularProgress className={classes.fabProgress} size={36} />
          )}
        </div>
      </div>
    );
  }
}

ButtonAddDownloadList.propTypes = {
  classes: PropTypes.object,
};
ButtonAddDownloadList.defaultProps = {
  defaultProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'secondary',
    children: <BrowserUpdatedIcon style={{ color: green[800] }} />,
    style: {
      margin: 0,
      padding: 0,
      marginLeft: 4,
      marginRight: -5,
      marginBottom: 9,
    },
  },
  selected: [],
  loading: false,
};

const mapStateToProps = state => {
  const total = state.items.downloads.length;
  return {
    total,
    downloads: state.items.downloads,
  };
};

export default connect(mapStateToProps, { addDownloads })(
  withStyles(ButtonAddDownloadList, useStyles)
);
