import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';

const QUERY_ANONYMIZATION_TAGS = gql`
  query($name: String, $idProject: LongLong, $idUser: LongLong) {
    anonymizationTags(name: $name, idProject: $idProject, idUser: $idUser) {
      ok
      errors {
        path
        message
      }
      rules {
        id
        idRule
        idUser
        idProject

        tag_rule
        tag_value

        rule {
          tag
          tag_name
          tag_options
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryAnonymizationTags = (inputs, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ANONYMIZATION_TAGS),
    variables: {
      ...inputs,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_ANONYMIZATION_TAGS = gql`
  mutation($inputs: [InputRuleTag!]!) {
    addAnonymizationTags(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      rules {
        id
        idRule
        idUser
        idProject

        tag_rule
        tag_value

        rule {
          tag
          tag_name
          tag_options
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationAddAnonymizationTags = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_ANONYMIZATION_TAGS),
    variables: { inputs },
  });
};
const MUTATIOM_UPLOAD_ANONYMIZATION_TAGS = gql`
  mutation($file: Upload!) {
    uploadAnonymizationTags(file: $file) {
      ok
      errors {
        path
        message
      }
      rules {
        id
        idRule
        idUser
        idProject

        tag_rule
        tag_value

        rule {
          tag
          tag_name
          tag_options
        }
        user {
          FullName
        }
        project {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadAnonymizationTagsCSV = (
  file,
  handleProgress = null
) => {
  return MutationUploadSingleFile(
    file,
    MUTATIOM_UPLOAD_ANONYMIZATION_TAGS,
    handleProgress
  );
};
const MUTATION_REMOVE_ANONYMIZATION_TAGS = gql`
  mutation($ids: [LongLong!]!) {
    removeAnonymizationTags(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveAnonymizationTags = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_ANONYMIZATION_TAGS),
    variables: { ids },
  });
};
