import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { SearchInput, ButtonFilterSearch } from 'components';
import { defaultFilter } from 'components/Basics/FilterContainer';
class DatabaseSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleFilterChanged = filters => {
    this.props.setState(filters);
  };
  render() {
    const {
      classes,
      isStudySeriesView,
      view_database,
      isPatientView,
    } = this.props;
    let ComponentSearchFilter = null;
    if (!isStudySeriesView && !isPatientView) {
      ComponentSearchFilter = (
        <Grid item xs={2}>
          <ButtonFilterSearch
            filters={this.props.filters}
            handleAddError={this.props.handleAddError}
            handleSearch={this.props.handleSearch}
            holder={this.props.holder}
            onClickReset={() => this.props.setState({ ...defaultFilter })}
            onFilterChanged={this.handleFilterChanged}
            view={view_database}
          />
        </Grid>
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="flex-start"
        sm={6}
        xs={10}>
        <Grid item xs={10}>
          <SearchInput
            className={classes.searchInput}
            onChange={this.props.handleTextChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleRunAtEnter(
                  this.props.description,
                  this.props.index_level
                );
              }
            }}
            placeholder={this.props.placeholder}
            value={this.props.description}
          />
        </Grid>
        {ComponentSearchFilter}
      </Grid>
    );
  }
}

DatabaseSearchFilter.propTypes = {
  classes: PropTypes.object,
};

export default DatabaseSearchFilter;
