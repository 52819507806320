import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { getParams } from 'helpers';
import { PatientSurvey } from './components';
const useStyles = () => ({
  root: {},
});
class Surveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // const params = getParams(this.props);
  }
  componentDidUpdate() {
    // const params = getParams(this.props);
  }
  render() {
    const params = getParams(this.props);
    if (params && params.link) {
      return <PatientSurvey link={params.link} {...this.props} />;
    }
    return <div>Missing Link for Surveys</div>;
  }
}

Surveys.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(Surveys), useStyles);
