/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { lighten } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import {
  Toolbar,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import { blue } from '@mui/material/colors';

import { ButtonReloadDelete, SearchText } from 'components';

const useStyles = (theme, props) => ({
  root: {
    width: '100%',
    minWidth: 250,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: props.transform ? '20px' : '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: props.transform ? 'none' : null,
  },
});

class ToolbarLogsErrors extends Component {
  render() {
    const {
      numSelected,
      classes,
      handleDeleteSelected,
      loading,
      handleReloadTable,
      handleClearSelected,
      tooltip,
      loading_table,
      ComponentAdditional,
    } = this.props;
    let SelectedText = null;
    if (numSelected > 0) {
      SelectedText = (
        <Grid item>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1">
            {numSelected} selected
          </Typography>
        </Grid>
      );
    } else {
      SelectedText = (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-start"
          spacing={1}
          xs={7}>
          <Grid item xs={4}>
            <Autocomplete
              onChange={this.props.handleTypeSearchChange}
              options={this.props.options_search}
              renderInput={params => <TextField {...params} label="Type" />}
              size="small"
              value={this.props.type}
            />
          </Grid>
          <Grid item xs={8}>
            <SearchText
              classes={classes}
              handleSearchText={this.props.handleSearchUser}
              holder={this.holder}
              loading={loading}
              placeholder={this.props.placeholder_type}
            />
          </Grid>
        </Grid>
      );
    }
    let GridComponentAddition = null;
    if (ComponentAdditional) {
      GridComponentAddition = <Grid item>{ComponentAdditional}</Grid>;
    }
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          {SelectedText}
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={5}>
            {GridComponentAddition}
            <Grid
              alignItems="center"
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={4}>
              <ButtonReloadDelete
                handleClearSelected={handleClearSelected}
                handleDeleteSelected={handleDeleteSelected}
                handleReloadTable={handleReloadTable}
                loading={loading}
                loading_table={loading_table}
                numSelected={numSelected}
                tooltip={tooltip}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

ToolbarLogsErrors.propTypes = {
  ComponentAdditional: PropTypes.object,
  handleClearSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  transform: PropTypes.bool,
};
ToolbarLogsErrors.defaultProps = {
  loading_table: false,
  loading: false,
  tooltip: 'Delete',
  handleClearSelected: () => '',
  handleReloadTable: () => '',
  handleDeleteSelected: () => '',
  ComponentAdditional: null,
  numSelected: 0,
  transform: false,
};
export default withStyles(ToolbarLogsErrors, useStyles);
