import React from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles()({
  root: {
    width: '100%',
  },
});

const BorderLinearProgress = withStyles(LinearProgress, theme => ({
  root: {
    height: 10,
    // borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}));

export default function LinearWaiting({ loading }) {
  const { classes } = useStyles();
  const [progress, setProgress] = React.useState(0);
  if (!loading) {
    setProgress(100);
  }
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);
  // if (!loading) {
  //   return null;
  // }
  return (
    <div className={classes.root}>
      <BorderLinearProgress value={progress} variant="determinate" />
    </div>
  );
}
