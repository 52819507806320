import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import { ButtonLoading } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryGroupMapping } from 'graphql/Mapping';
import { inputProps } from 'common';
export const useStyles = theme => ({
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    background: 'white',
    '& h2': {
      color: 'black',
      fontSize: '20px',
      fontWeight: 1000,
    },
  },
});

class DialogGroupMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      value: { name: '' },
      mappings: [],
    };
    const { holder } = props;
    if (holder) {
      holder.cleanText = () => this.setState({ text: '' });
    }
    this.holder = {
      setAutoComboBox: null,
    };
  }
  componentDidMount() {
    this.runLoadGroupKeywords();
  }
  handleChange = (event, newInputValue) => {
    if (this.state.loading) {
      this.setState({ text: newInputValue });
    } else {
      this.runLoadGroupKeywords(newInputValue);
    }
  };
  runLoadGroupKeywords = name => {
    const { loading, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true, text: name });
    (async () => {
      QueryGroupMapping(this.props.group_name, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, errors } = data.allGroupMapping;
          if (ok) {
            this.setState({ open: false, loading: false, mappings });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleKeywordSelected = (event, keyword) => {
    console.log({ keyword });
    this.setState({ value: keyword });
    // if (keyword === undefined || !keyword || keyword === '') return;
    // this.props.handleAddKeyword(keyword.name);
  };
  render() {
    const { classes, open, handleClose } = this.props;
    const { mappings, loading, open_auto, text } = this.state;
    if (text && text.length) {
      const index = mappings.map(x => x.name).indexOf(text);
      if (index === -1) {
        mappings.push({ name: text });
      }
    }
    return (
      <div>
        <Dialog
          aria-labelledby="form-dialog-title"
          onClose={() => handleClose()}
          open={open}>
          <DialogTitle
            className={classNames(classes.title)}
            id="form-dialog-title">
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.description}</DialogContentText>
            <Grid container sx={{ paddingTop: 2 }}>
              <Grid item xs={12}>
                <Autocomplete
                  getOptionLabel={option => option.name}
                  id="group-mapping-search"
                  inputValue={text}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  loading={loading}
                  onChange={this.handleKeywordSelected}
                  onClose={() => {
                    this.setState({ open_auto: false });
                  }}
                  onInputChange={this.handleChange}
                  onOpen={() => {
                    this.setState({ open_auto: true });
                  }}
                  open={open_auto}
                  options={mappings}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        ...inputProps,
                      }}
                      label="Keyword"
                      placeholder="Type the keyword to search or add"
                      required
                    />
                  )}
                  value={this.state.value}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => handleClose()}>
              Cancel
            </Button>
            <ButtonLoading
              handleClick={() => this.props.handleAddKeyword(this.state.text)}
              name={this.props.text_botton}
              transform={false}
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogGroupMapping.propTypes = {
  description: PropTypes.string,
  handleAddText: PropTypes.func,
  input_name: PropTypes.string,
  open: PropTypes.bool,
  text_botton: PropTypes.string,
  title: PropTypes.string,
};
DialogGroupMapping.defaultProps = {
  title: 'Input Text',
  description: 'Type the input text',
  input_name: 'Text',
  text_botton: 'Ok',
  handleAddKeyword: () => '',
  group_name: 'Keyword Designer',
};

export default withStyles(DialogGroupMapping, useStyles);
