import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  ButtonReloadDelete,
  DialogDeleteWarning,
  // IconButtonLoad,
} from 'components';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
class DialogCleanObjectsToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
    };
  }
  handleDeleteSelected = () => {
    this.setState({ open_delete: false });
    this.props.handleDeleteSelected();
  };
  render() {
    const { selected } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            {/* <IconButtonLoad
              bottom_top
              color="red"
              handleClick={() => this.setState({ open_delete: true })}
              icon={<RotateLeftIcon sx={{ color: 'green' }} />}
              left_right
              length={selected.length}
              loading={this.props.deleting}
              small
              tooltip="Restore objects"
            /> */}
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={() => this.props.setState({ selected: [] })}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              icon_delete={<RotateLeftIcon sx={{ color: 'green' }} />}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          button_text="Restore"
          handleButtonClicked={this.handleDeleteSelected}
          handleClose={() => this.setState({ open_delete: false })}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to restore the cut objects, this action cannot be undone"
          title_text="Restore Selected Cut Objects"
        />
      </React.Fragment>
    );
  }
}

DialogCleanObjectsToolbar.propTypes = {
  rows_edited: PropTypes.array,
};
DialogCleanObjectsToolbar.defaultProps = {
  rows_edited: [],
  selected: [],
};
export default DialogCleanObjectsToolbar;
