import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { GroupBox, IconButtonLoad } from 'components';
import { Replay as ReloadIcon, Save as SaveIcon } from '@mui/icons-material';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationUpdateSequence } from 'graphql/Series/sequences';

class GroupBoxEditSelectedRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      name: '',
      idSequence: -1,
    };
  }

  componentDidUpdate() {
    const { row_selected, single } = this.props;
    if (single) {
      if (row_selected.idSequence !== this.state.idSequence) {
        this.setState({
          name: row_selected.name,
          idSequence: row_selected.idSequence,
        });
      }
    } else {
      /** empty */
    }
  }

  isChanged = () => {
    const { name } = this.state;
    const { row_selected, single } = this.props;
    if (single) {
      if (row_selected.name !== name) return true;
    } else {
      return name !== '';
    }
    return false;
  };
  handleResetRowSequence = () => {
    this.setState({
      name: '',
      idSequence: -1,
    });
  };
  updateSequence = () => {
    const { updating, name, idSequence } = this.state;
    if (updating) return;

    this.setState({ updating: true });
    (async () => {
      MutationUpdateSequence(name, idSequence)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateSequence;
          if (ok) {
            this.setState({ updating: false });
            this.props.handleSave({ name, idSequence });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChange = event => {
    this.setState({ name: event.target.value });
  };

  render() {
    const { updating, name } = this.state;
    let disable_all = false;
    // if(this.props.single){
    //   if()
    // }
    return (
      <GroupBox title_box={this.props.title_box}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start">
          <Grid item style={{ paddingRight: 50 }} xs={3}>
            <TextField
              disabled={disable_all}
              fullWidth
              id="sequence-mapping-value"
              InputLabelProps={{
                shrink: true,
              }}
              label="Sequence Name"
              onChange={this.handleChange}
              sx={{ m: 1, minWidth: '25ch' }}
              value={name}
              variant="standard"
            />
          </Grid>
          <Grid item style={{ paddingLeft: 40 }} xs={3}>
            {/* {show_mapping && (

            )} */}
          </Grid>
          <Grid
            container
            direction="row"
            item
            style={{ paddingRight: 10, marginTop: 0 }}
            xs={3}>
            <IconButtonLoad
              disabled={updating || disable_all}
              handleClick={this.handleResetRowSequence}
              icon={<ReloadIcon />}
              tooltip="Reset Sequence"
            />
            <IconButtonLoad
              disabled={!this.isChanged() || updating || disable_all}
              handleClick={this.updateSequence}
              icon={
                <SaveIcon color={this.isChanged() ? 'primary' : 'disabled'} />
              }
              loading={updating}
              tooltip="Update Sequence"
            />
          </Grid>
        </Grid>
      </GroupBox>
    );
  }
}

GroupBoxEditSelectedRow.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  row_selected: PropTypes.object,
  selected: PropTypes.array,
  show_mapping: PropTypes.bool.isRequired,
  single: PropTypes.bool.isRequired,
  title_box: PropTypes.string.isRequired,
};
GroupBoxEditSelectedRow.defaultProps = {
  updateSequence: () => '',
  title_box: '',
  row_selected: null,
  single: true,
  selected: [],
  show_mapping: true,
  handleSave: () => '',
  handleAddError: () => '',
};

export default GroupBoxEditSelectedRow;
