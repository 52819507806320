import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
// import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';

const useStyles = theme => ({
  root: {
    height: 10,
    maxHeight: 10,
    position: 'sticky',
    top: 0,
    zIndex: 200,
    backgroundColor: '#f9f9f9',
  },
  menuButton: {
    padding: 0,
    margin: 0,
    marginRight: theme.spacing(1),
  },
  hide_menu: {
    display: 'none',
  },
  check: {
    padding: 0,
    margin: 0,
  },
  check_open: {
    marginLeft: theme.spacing(1),
  },
});

class ExplorerViewListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order ? props.order : 'asc',
      orderBy: props.orderBy ? props.orderBy : 'name',
    };
  }
  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
    });
    this.props.handleRequestSort(event, property);
  };
  render() {
    const {
      classes,
      selected_objects,
      rowCount,
      handleDrawerClose,
      open_explorer,
      rights,
      to_filters,
      columns,
    } = this.props;
    let checked = selected_objects.length === rowCount;
    if (!selected_objects.length) {
      checked = false;
    }
    let marginLeft = open_explorer ? 0 : -20;

    if (!rights && to_filters.indexOf('rights') === -1) {
      to_filters.push('rights');
    }
    // console.log({ rights, to_filters });
    const { order, orderBy } = this.state;
    return (
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell
            padding="checkbox"
            style={{ width: open_explorer ? 45 : 70 }}>
            <Grid alignItems="flex-start" container direction="row">
              <Grid item>
                <IconButton
                  aria-label="open drawer"
                  className={clsx(
                    classes.menuButton,
                    open_explorer && classes.hide_menu
                  )}
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerClose}
                  size="large">
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Checkbox
                  checked={checked}
                  className={clsx(
                    classes.check,
                    open_explorer && classes.check_open
                  )}
                  indeterminate={
                    selected_objects.length > 0 &&
                    selected_objects.length < rowCount
                  }
                  onClick={this.props.handleSelectAll}
                />
              </Grid>
            </Grid>
          </TableCell>
          {columns
            .filter(x => to_filters.indexOf(x.id) === -1)
            .map((column, index) => {
              const { id, label, align } = column;
              let styles = column.styles;
              if (styles && id === 'name') {
                styles = {
                  ...styles,
                  marginLeft,
                };
              }
              return (
                <TableCell
                  align={align ? align : 'left'}
                  key={`header-explorer-view-${index}`}
                  sortDirection={orderBy === column.id ? order : false}
                  style={styles}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={event => this.handleRequestSort(event, column.id)}>
                    {label}
                  </TableSortLabel>
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

ExplorerViewListHeader.propTypes = {
  classes: PropTypes.object,
  rights: PropTypes.bool.isRequired,
};
ExplorerViewListHeader.defaultProps = {
  rights: true,
  to_filters: [],
};
const mapStateToProps = state => {
  const cut_copy_objects = state.manager.cut.concat(state.manager.copy);
  const numSelected = cut_copy_objects.length;
  return {
    cut_copy_objects,
    numSelected,
    downloads: state.items.downloads,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(ExplorerViewListHeader, useStyles));
