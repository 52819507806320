import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';
import { AutoComboBox } from 'components';

class DialogSelectDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      loading: false,
      images: [{ operation: 'None', id: 0 }],
    };
  }
  componentDidMount() {
    this.handleUpdateImages();
  }
  handleUpdateImages = () => {
    console.log('DialogSelectDocker');
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySystemDockerImages()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images, total } = data.systemDockerImages;
          if (ok) {
            images.unshift({ operation: 'None', id: 0 });
            this.setState({ loading: false, images, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { open, handleClose, handleAddImage } = this.props;
    if (!open) return null;
    const { images } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add Docker Image to User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select docker image a press ADD.
          </DialogContentText>
          <AutoComboBox
            defaultValue={images[0]}
            disabled={this.state.loading}
            getOptionLabel={option => {
              if (option === undefined || option.operation === undefined)
                return '';
              return option.operation;
            }}
            label="Process"
            onObjectChanged={selected => this.setState({ selected })}
            options={images}
            small
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAddImage(this.state.selected)}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogSelectDocker.propTypes = {
  open: PropTypes.bool,
};

export default DialogSelectDocker;
