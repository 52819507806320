/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { CreateProjectContainer } from './components';
import { Transition } from 'components';

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    background: '#095275',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function CreateProjectDialog(props) {
  const { open, handleCloseCreate } = props;
  const { classes } = useStyles();
  const [finished, setFinished] = useState(false);
  return (
    <div>
      <Dialog
        fullScreen
        onClose={() => handleCloseCreate(false)}
        open={open}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="close"
              color="inherit"
              edge="start"
              onClick={() => handleCloseCreate(finished)}
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography className={classes.title} color="inherit" variant="h2">
              {finished ? 'Project Created!' : 'Crate a New Project'}
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateProjectContainer
          handleAddError={props.handleAddError}
          setFinished={setFinished}
        />
      </Dialog>
    </div>
  );
}
