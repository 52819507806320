import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Transition } from 'components';

const useStyles = theme => ({
  title: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  middle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bottom: {
    marginBottom: theme.spacing(-3),
  },
});

class DialogQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      open,
      handleClose,
      handleAction,
      button_name,
      title,
      message,
      handleActionSecondary,
      button_secondary,
    } = this.props;
    let ComponentSecondary = null;
    if (button_secondary) {
      ComponentSecondary = (
        <Button color="primary" onClick={handleActionSecondary}>
          {button_secondary}
        </Button>
      );
    }
    return (
      <Dialog
        // aria-describedby="alert-dialog-description"
        // aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          {title}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent style={{ minHeight: 120 }}>
          <DialogContentText>{message}</DialogContentText>
          <Typography className={classes.bottom} component="h2" variant="h6">
            Do you want to continue?
          </Typography>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          {ComponentSecondary}
          <Button color="primary" onClick={handleAction}>
            {button_name}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogQuestion.propTypes = {
  button_name: PropTypes.string,
  classes: PropTypes.object,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
};
DialogQuestion.defaultProps = {
  open: false,
  message: 'Message not defined',
  title: 'Title not defined',
  button_name: 'No Defined',
  button_secondary: null,
  handleActionSecondary: () => '',
};

export default withStyles(DialogQuestion, useStyles);
