import React from 'react';
import PropTypes from 'prop-types';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
const useStyles = () => ({
  root: {
    color: green[800],
  },
});
class LoadingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, classes, className, size, ...res } = this.props;
    return (
      <MuiLoadingButton
        {...res}
        className={clsx({
          [classes.root]: true,
          [className]: className,
        })}
        loadingIndicator={<CircularProgress color="primary" size={size} />}>
        {children}
      </MuiLoadingButton>
    );
  }
}

LoadingButton.propTypes = {
  size: PropTypes.number,
};
LoadingButton.defaultProps = {
  size: 24,
};

export default withStyles(LoadingButton, useStyles);
