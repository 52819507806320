import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { columns } from './Styles';
import useStyles from '../components/Styles';

import { MainTableToolbar, MainTable } from '../components';
import {
  ServerErrorsString,
  SelectedArray,
  GetData,
  rowsPerPageBig,
} from 'helpers';
import { SnackMessage } from 'components';
import { MutationRemoveTeams } from 'graphql/Teams';
class TableUserTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      selected: [],
      deleting: false,
      error: '',
    };
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.teams });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    //
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangePageLimit(0, +event.target.value);
  };
  handleDeleteTeams = () => {
    const { selected, deleting } = this.state;
    if (deleting) return;
    const { idProject } = this.props;
    const ids = selected.map(x => x.id);
    this.setState({ deleting: true });
    (async () => {
      MutationRemoveTeams(ids, idProject)
        .then(res => {
          const data = GetData(res);
          const { removeTeams } = data;
          if (typeof removeTeams !== 'undefined') {
            const { ok, errors } = removeTeams;
            if (ok) {
              this.setState({ deleting: false, selected: [] });
              this.props.handleDeleteSelected(ids);
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          this.setState({
            deleting: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const { classes, teams, loading, handleReloadTable, height } = this.props;
    const { selected, page, limit, deleting, error } = this.state;
    let style = {};
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteTeams}
          handleReloadTable={handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="Teams"
          tooltip="Delete Team"
        />
        <div className={classes.tableWrapper} style={{ ...style }}>
          <MainTable
            columns={columns}
            handleButtonView={this.props.handleShowTeam}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={teams}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={teams.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Paper>
    );
  }
}

TableUserTeams.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  teams: PropTypes.array.isRequired,
};
TableUserTeams.defaultProps = {
  teams: [],
  loading: false,
  idProject: null,
  handleChangePageLimit: () => '',
};
export default withStyles(TableUserTeams, useStyles);
