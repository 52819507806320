import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_BATCH_SETTING = gql`
  mutation($idBatch: LongLong!, $setting: InputBatchSetting!) {
    updateBatchSetting(idBatch: $idBatch, setting: $setting) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        idBatch

        folder_structure
        idProject
        idFolder
        idBatchLogFolder
        own_folder
        on_content
        create_batch_log

        tags
        project {
          id
          name
        }
        folder {
          idFolder
          name
        }
        batch_log_folder {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationUpdateBatchSetting = (idBatch, setting) => {
  console.log('MutationUpdateBatchSetting', {
    idBatch,
    setting,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_BATCH_SETTING),
    variables: {
      idBatch,
      setting,
    },
  });
};
