export const useStyles = theme => ({
  inner: {
    minWidth: 800,
    minHeight: 370,
    maxHeight: 370,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});
export const statusColors = {
  green: 'success',
  orange: 'info',
  red: 'danger',
  warning: 'warning',
  neutral: 'neutral',
  purple: 'purple',
};
