import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { TableGrid } from 'components';
import { columns_prophet_uploads } from '../TabListFolders/utils_columns';

class TabExecutedUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      loading: false,
      open: true,
      init: false,
      rows: [
        {
          id: 1,
          folder_name: '/share/new_uploads/SSR-INS-P09275',
          patient: {
            key: 'SSR-INS-P09275',
            PatientID: '001245789',
          },
        },
        {
          id: 2,
          folder_name: '/share/new_uploads/SSR-INS-P09276',
          patient: {
            key: 'SSR-INS-P09276',
            PatientID: '001245765',
          },
        },
        {
          id: 3,
          folder_name: '/share/new_uploads/SSR-INS-P09277',
          ssr_pid: 'SSR-INS-P09277',
          patient: {
            key: 'SSR-INS-P09277',
            PatientID: '001895765',
          },
        },
      ],
    };
  }

  componentDidMount() {
    // this.queryConfigurationFile();
  }
  componentDidUpdate() {
    // if (!this.state.init) this.queryConfigurationFile();
  }
  // queryConfigurationFile = () => {
  //   if (!this.props.isActive) return;
  //   if (this.state.loading) return;
  //   this.setState({ loading: true });
  //   (async () => {
  //     QueryCorrectionFile()
  //       .then(res => {
  //         const data = GetData(res);
  //         const { content, ok, errors } = data.getCorrectionFile;
  //         if (ok) {
  //           let json_content = JSON.parse(content);
  //           this.setState({
  //             loading: false,
  //             json_content,
  //             changed: false,
  //             init: true,
  //           });
  //         } else {
  //           throw errors;
  //         }
  //       })
  //       .catch(error => {
  //         this.setState({ loading: false, init: true });
  //         this.props.handleError(ServerErrorsString(error));
  //       });
  //   })();
  // };
  render() {
    const { rows } = this.state;
    return (
      <Paper>
        <div style={{ marginTop: '5px' }}>
          <TableGrid
            checkboxSelection
            dense
            // disableSelectionOnClick
            // external_edited={this.state.rows_edited}
            // external_selected={selected}
            grid
            headers={columns_prophet_uploads}
            // height={this.props.height}
            // limit={this.props.limit}
            // loading={loading}
            // onPageChange={page => this.props.handleChangePage(page)}
            // onRowClicked={row_highlight => this.setState({ row_highlight })}
            // onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            // onRowsPerPageChange={limit =>
            //   this.props.handleChangeRowsPerPage(limit)
            // }
            // onSelectedChanged={selected =>
            //   this.props.onSelectedChanged(selected)
            // }
            // page={this.props.page}
            rows={rows}
            // rowsPerPageOptions={rowsPerPageBig}
          />
        </div>
      </Paper>
    );
  }
}

TabExecutedUpload.propTypes = {
  classes: PropTypes.object,
};

export default TabExecutedUpload;
