import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';

import useStyles from './Styles';
import { SnackMessage } from 'components'; //DialogDeleteWarning

const ButtonDeleteIcon = props => {
  const { classes } = useStyles();
  const [error, setError] = React.useState('');

  const { selected, loading, onClick } = props;

  const defaultProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    color: 'secondary',
    children: <DeleteIcon color="error" />,
  };
  const handleCloseSnak = () => {
    setError('');
  };
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <SnackMessage
          handleClose={handleCloseSnak}
          message_text={error !== '' ? error : 'Unknown warning'}
          open={error && error !== '' ? true : false}
          type="error"
        />
        <Tooltip title={props.title}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-controls="delete-icon"
              onClick={onClick}
              size="large">
              {loading ? (
                <DeleteIcon color="disabled" />
              ) : (
                <Badge badgeContent={selected.length} {...defaultProps} />
              )}
            </IconButton>
          </span>
        </Tooltip>
        {loading && (
          <CircularProgress className={classes.fabProgress} size={36} />
        )}
      </div>
    </div>
  );
};
ButtonDeleteIcon.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.array,
  title: PropTypes.string,
};
ButtonDeleteIcon.defaultProps = {
  selected: [],
  loading: false,
  title: 'Delete',
};

export default ButtonDeleteIcon;
