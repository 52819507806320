import React from 'react';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { columns } from './Styles';
import useStyles from '../components/Styles';

import { MainTable, MainTableToolbar } from '../components';
import { SelectedArray, rowsPerPageSmall } from 'helpers';

class TableUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      selected: [],
    };
  }

  handleDeleteSelected = event => {
    event.preventDefault();
    const { handleDeleteUser } = this.props;
    const { selected } = this.state;
    if (handleDeleteUser) {
      handleDeleteUser(selected);
    }
    this.setState({ selected: [] });
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();

    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleSelectedRow = item => {
    this.props.handleSelectedRow(item);
  };
  handleAcceptReject = () => {};
  handleButtonView = () => {};
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selected: rows });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const { classes, rows, title, loading, deleting, height } = this.props;
    const { selected, page, limit } = this.state;
    let headers = columns;
    if (this.props.columns) {
      headers = this.props.columns;
    }
    let style = {};
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={title}
        />
        <div className={classes.tableWrapper} style={{ ...style }}>
          <MainTable
            columns={headers}
            dense={this.props.dense}
            disable_checkbox={this.props.disable_checkbox}
            handleAcceptReject={this.handleAcceptReject}
            handleButtonView={this.handleButtonView}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.handleSelectedRow}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={rows.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
      </Paper>
    );
  }
}

TableUsers.defaultProps = {
  dense: false,
  rows: [],
  deleting: false,
  loading: false,
  columns: null,
  disable_checkbox: false,
  handleSelectedRow: () => '',
};
TableUsers.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};
export default withStyles(TableUsers, useStyles);
