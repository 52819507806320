import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import useStyles from './Style';
import { CardEditSeries } from 'components'; //ColapseCard
class EditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      errors: [],
    };
  }

  handleExpandClick = () => {
    this.setState(prev => ({ expanded: !prev.expanded }));
  };
  addError = error => {
    const { errors } = this.state;
    if (errors.indexOf(error) === -1) {
      errors.push(error);
      this.setState({ errors });
      this.props.handleError(`- ${errors.join('\n- ')}`);
    }
  };

  render() {
    const { classes, Series } = this.props;
    if (!Series) {
      return null;
    }
    return (
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12}>
          <CardEditSeries addError={this.addError} Series={Series} />
        </Grid>
      </Grid>
    );
  }
}

EditContent.propTypes = {
  Series: PropTypes.object,
  classes: PropTypes.object,
};
EditContent.defaultProps = {
  Series: null,
};

export default withStyles(EditContent, useStyles);
