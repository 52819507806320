import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Icon } from 'components';
import { FromLastWeek } from './components';

const useStyles = theme => ({
  root: {
    margin: theme.spacing(1, 0, 1, 0),
    width: '150px',
    maxWidth: '150px',
  },
  count: {
    fontSize: '30px',
    lineHeight: '47px',
    fontWeight: '600',
  },
  count_bottom: {
    width: '12px',
  },
  red: {
    color: 'red',
  },
  green: {
    color: 'green',
  },
});

class SummaryCard extends Component {
  render() {
    const { classes, icon, title, count, color } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start">
          <Icon
            style={{ fontSize: 20, marginRight: 4, color: color }}
            type={icon}
          />
          {title}
        </Grid>
        <div className={classes.count}>{count}</div>
        <FromLastWeek {...this.props} />
        <Divider className={classes.divider} orientation="vertical" />
      </div>
    );
  }
}

SummaryCard.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number,
  icon: PropTypes.string,
  percentage: PropTypes.number,
  title: PropTypes.string,
  up: PropTypes.bool,
};
SummaryCard.defaultProps = {
  up: false,
  title: 'Total',
  percentage: 0,
  icon: 'user',
  count: 0,
  color: 'black',
};
export default withStyles(SummaryCard, useStyles);
