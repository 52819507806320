import React from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  StepConfigureSettings,
  StepCreateTeams,
  StepProjectType,
  StepSelectUsers,
} from '../Steps';

export const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1, 3, 3, 3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    padding: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const getSteps = () => {
  return [
    'Select project type',
    'Configure settings',
    'Select users',
    'Create teams',
  ];
};

export const getStepContent = (step, addError, handleAddError) => {
  const title = getSteps()[step];
  switch (step) {
    case 0:
      return (
        <StepProjectType
          addError={addError}
          handleAddError={handleAddError}
          title={title}
        />
      );
    case 1:
      return (
        <StepConfigureSettings
          addError={addError}
          handleAddError={handleAddError}
          title={title}
        />
      );
    case 2:
      return (
        <StepSelectUsers
          addError={addError}
          handleAddError={handleAddError}
          title={title}
        />
      );
    case 3:
      return (
        <StepCreateTeams
          addError={addError}
          handleAddError={handleAddError}
          title={title}
        />
      );
    default:
      return 'Unknown step';
  }
};
