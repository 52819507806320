import { IsInvalid, IsInvalidParam } from 'helpers';
import { getUserFullName } from 'helpers';
import { FormatTime } from 'helpers';

export const columns_docker = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    align: 'left',
  },
  {
    field: 'idUser',
    headerName: 'User',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      let value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      value = getUserFullName(value);
      if (IsInvalid(value)) return '';
      return value;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    align: 'left',
  },
  {
    field: 'count',
    headerName: 'Count',
    align: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
  },
  {
    field: 'error',
    headerName: 'Errors',
    align: 'left',
    width: 1000,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
