import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SIGNAL_PATIENT = gql`
  query($name: String, $page: Int, $limit: Int) {
    signalPatients(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      patients {
        id
        owner {
          id
          FullName
        }
        FamilyName
        GivenName
        PatientBirthDate
        PatientSex
        PatientID
        NumberOfPatientRelatedInstances
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySignalPatients = (name, page, limit) => {
  const variables = {};
  if (name !== undefined) variables.name = name;
  if (page !== undefined && limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SIGNAL_PATIENT),
    variables,
  });
};
