import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import FolderIcon from '@mui/icons-material/Folder';
import { connect } from 'react-redux';
import { Box, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import Skeleton from '@mui/lab/Skeleton';
import { CheckBoxIcon } from 'components';
import { getRowProjectObjects } from '../../../utils_tag_projectfiles';

import { useStyles, width } from './useStyles';

class GalleryProjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
      isSelected: false,
    };
  }

  handleMouseHover = () => {
    this.setState(prev_state => ({ isMouseOver: !prev_state.isMouseOver }));
  };
  handleSelected = () => {
    this.setState(prev_state => ({ isSelected: !prev_state.isSelected }));
  };

  render() {
    const { isItemSelected, classes, handleSelected, user } = this.props;
    const row = getRowProjectObjects(this.props.row);
    let defaultProps = {};
    if (isItemSelected) {
      defaultProps = {
        bgcolor: 'grey.500',
        border: 3,
        style: { borderColor: 'red', marginTop: '-10px' },
      };
    }
    //
    let { name, Thumbnail, kind } = row;
    if (Thumbnail && !Thumbnail.startsWith('/images/')) {
      Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
    }
    let title_short = name;
    let title = name;
    if (title_short.length > 15) {
      title_short = name.substring(0, 12) + '...';
    } else {
      title = '';
    }
    const isFolder = kind === '--';
    let folder_ownership = true;
    if (isFolder) {
      const {
        Owner: { id: idUser },
      } = row;
      if (idUser !== user.id) {
        folder_ownership = false;
      }
    }
    return (
      <React.Fragment>
        {isItemSelected && (
          <CheckBoxIcon
            checked
            className={classes.selection}
            onChange={() => handleSelected(row)}
          />
        )}
        <Tooltip title={title}>
          <Box
            {...defaultProps}
            onClick={() => handleSelected(row)}
            onDoubleClick={() => this.props.handleDoubleClick(this.props.row)}
            width={isItemSelected ? width + 10 : width + 4}>
            {row ? (
              isFolder ? (
                <FolderIcon
                  className={clsx({
                    [classes.unselected]: !isItemSelected,
                    [classes.selected_folder]: isItemSelected,
                    [classes.color_blue]: folder_ownership,
                    [classes.color_green]: !folder_ownership,
                  })}
                />
              ) : (
                <img
                  alt={name}
                  className={clsx({
                    [classes.unselected]: !isItemSelected,
                    [classes.selected]: isItemSelected,
                  })}
                  src={Thumbnail}
                />
              )
            ) : (
              <Skeleton height={90} variant="rectangular" width={width} />
            )}
            {row ? (
              <Box pr={2} width={width}>
                <Typography
                  align="center"
                  color="textSecondary"
                  display="block"
                  variant="caption">
                  {title_short}
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  display="block"
                  variant="caption">
                  {' '}
                </Typography>
              </Box>
            ) : (
              <Box pt={0.2}>
                <Skeleton width={width} />
                <Skeleton width={width} />
              </Box>
            )}
          </Box>
        </Tooltip>
      </React.Fragment>
    );
  }
}

GalleryProjectItem.defaultProps = {
  index: 0,
  selected: -1,
};
GalleryProjectItem.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
};

const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(GalleryProjectItem, useStyles));
