import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_SEQUENCES = gql`
  query($name: String, $page: Int, $limit: Int) {
    Sequences(name: $name, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      sequences {
        idSequence
        name
        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySequences = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined' && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEQUENCES),
    variables,
  });
};
export const QUERY_SEQUENCE_NAME_BY_SERIES_ID = gql`
  query($SeriesQuery: SeriesQuery) {
    Series(SeriesQuery: $SeriesQuery) {
      Series {
        sequence {
          name
        }
      }
    }
  }
`;
export let QuerySequenceNameBySeriesId = query => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEQUENCE_NAME_BY_SERIES_ID),
    variables: {
      SeriesQuery: query,
    },
  });
};
const MUTATION_UPDATE_SEQUENCE = gql`
  mutation($idSequence: LongLong!, $name: String!) {
    updateSequence(idSequence: $idSequence, name: $name) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateSequence = (name, idSequence) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SEQUENCE),
    variables: {
      name,
      idSequence,
    },
  });
};
const QUERY_SUMMARY_SEQUENCES = gql`
  query SummarySequences(
    $timeframe: Int
    $mode: Int
    $idProject: Int
    $dateend: String
    $datestart: String
    $fullHistory: Boolean
    $page: Int
    $limit: Int
  ) {
    SummarySequences(
      timeframe: $timeframe
      mode: $mode
      idProject: $idProject
      dateend: $dateend
      datestart: $datestart
      fullHistory: $fullHistory
      page: $page
      limit: $limit
    ) {
      idSequence
      Sequence {
        idSequence
        name
      }
      total
    }
  }
`;
export const QueryBackendSummarySequences = (
  timeframe,
  mode,
  idProject,
  dateend,
  datestart,
  fullHistory,
  page,
  limit
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_SEQUENCES),
    variables: {
      timeframe,
      mode,
      idProject,
      dateend,
      datestart,
      fullHistory,
      page,
      limit,
    },
  });
};
