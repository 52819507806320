import React from 'react';
import PropTypes from 'prop-types';

import { gql } from '@apollo/client';
import { Subscription } from '@apollo/client/react/components';
import { IsInvalid } from 'helpers';

const SUBSCRIPTION_BATCH_JOB_FINISHED = gql`
  subscription($batch_ids: [LongLong!]!) {
    batchJobFinished(batch_ids: $batch_ids) {
      id
      idUser
      idDockerImage
      idBatch
      idSetting
      idSeries
      idFileObject
      idFolder

      status

      docker_image {
        id
        operation
      }

      batch {
        total_jobs
        jobs_waiting
        jobs_running
        jobs_stopped
        jobs_finished
        jobs_error
        status
        setting {
          id
          idBatch
          folder_structure
          idProject
          idFolder
          tags
          folder {
            idFolder
            name
          }
          project {
            id
            name
          }
          own_folder
          on_content
        }
      }
      setting {
        id
        idJob

        job_name
        own_folder
        on_content

        created_folder
        # in_folder
        # out_folder
        folderStructureFiles
        docker_command
        error
        folder_project {
          idFolder
          name
        }
        folder_content {
          idFolderContent
          project {
            id
            name
          }
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
      }
    }
  }
`;

class SubsBatchJobFinished extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onSubscriptionDataNew = ondata => {
    // console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { batchJobFinished } = data;
      this.props.handleBatchJobFinished(batchJobFinished);
    }
  };
  render() {
    let { children, idBatch, batch_ids } = this.props;
    if (!batch_ids.length) {
      batch_ids = [idBatch];
    }
    const variables = {
      batch_ids,
    };
    // console.log({ batch_ids });
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataNew}
        shouldResubscribe
        subscription={SUBSCRIPTION_BATCH_JOB_FINISHED}
        variables={variables}>
        {sub_job_finished => {
          if (!children) {
            return null;
          }
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              load_subscription: sub_job_finished.loading,
              error: sub_job_finished.error,
            });
          });
        }}
      </Subscription>
    );
  }
}

SubsBatchJobFinished.propTypes = {
  handleBatchJobFinished: PropTypes.func,
  idBatch: PropTypes.number,
};
SubsBatchJobFinished.defaultProps = {
  batch_ids: [],
};

export default SubsBatchJobFinished;
