import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardMedia, Typography, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  image: {
    width: '100%',
    opacity: 1,
    background: 'transparent',
    color: 'rgb(52, 71, 103)',
    borderRadius: '0.375rem',
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
  },
  title: {
    margin: '24px 0px',
    fontFamily: '"Roboto Slab", sans-serif',
    fontSize: '1.875rem',
    lineHeight: '1.375',
    fontWeight: 700,
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(52, 71, 103)',
    letterSpacing: '-0.125px',
  },
  description: {
    margin: '24px 0px',
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 60,
    // fontWeight: 400,
    // lineHeight: 1.6,
    // opacity: 1,
    textTransform: 'none',
    // verticalAlign: 'unset',
    // textDecoration: 'none',
    // color: 'rgb(123, 128, 154)',
    // letterSpacing: '-0.125px',
  },
  description2: {
    margin: '24px 0px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: 1.6,
    opacity: 1,
    textTransform: 'none',
    verticalAlign: 'unset',
    textDecoration: 'none',
    color: 'rgb(123, 128, 154)',
    letterSpacing: '-0.125px',
  },
});
class SlideCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const width = window.innerWidth;
    const {
      classes,
      title,
      // icon,
      direction,
      // small,
      description,
      image,
    } = this.props;
    // console.log({ width });
    //width <= 898 ? '100%' : 251
    if (direction === 'image_top') {
      return (
        <Card sx={{ display: 'flex', m: 1, backgroundColor: '#fafafa' }}>
          <Grid container>
            <Grid item p={1} xs={12}>
              <CardMedia
                alt="Live from space album cover"
                // className={classes.image}
                component="img"
                image={image}
              />
            </Grid>
            <Grid item xs={12}>
              <Box lineHeight={1} ml={2} mr={2}>
                <Typography className={classes.title} mb={1.5} variant="h3">
                  {title}
                </Typography>
                <div className={classes.description}>{description}</div>
              </Box>
            </Grid>
          </Grid>
        </Card>
      );
    }
    if (direction === 'image_bottom') {
      return (
        <Card sx={{ display: 'flex', m: 1, backgroundColor: '#fafafa' }}>
          <Grid container>
            <Grid item xs={12}>
              <Box lineHeight={1} ml={2} mr={2}>
                <Typography className={classes.title} mb={1.5} variant="h3">
                  {title}
                </Typography>
                <div className={classes.description}>{description}</div>
              </Box>
            </Grid>
            <Grid item p={1} xs={12}>
              <CardMedia
                alt="Live from space album cover"
                // className={classes.image}
                component="img"
                image={image}
              />
            </Grid>
          </Grid>
        </Card>
      );
    }
    return (
      <Card sx={{ display: 'flex', m: 1, backgroundColor: '#fafafa' }}>
        <Grid container>
          <Grid item md={5} p={1} xs={12}>
            <CardMedia
              alt="Live from space album cover"
              className={classes.image}
              component="img"
              image={image}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <Box lineHeight={1} ml={2} mr={2}>
              <Typography className={classes.title} mb={1.5} variant="h3">
                {title}
              </Typography>
              <div className={classes.description}>{description}</div>
            </Box>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

SlideCard.propTypes = {
  small: PropTypes.bool,
};
SlideCard.defaultProps = {
  small: false,
};

export default withStyles(SlideCard, useStyles);
