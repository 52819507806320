import React, { useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';

import { StepContainer } from '../index';
import {
  DialogCreateTeam,
  DialogUsersToTeam,
  DialogViewTeam,
  TableTeams,
} from 'components';
import { projectCreateTeams } from 'redux-store/actions/projectActions';
import { CreateTeamsToolBar } from './components';

const useStyles = makeStyles()(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
}));

const StepCreateTeams = props => {
  const { title, teams, projectCreateTeams } = props;
  const { classes } = useStyles();
  const [open_create, setOpenCreate] = useState(false);
  const [open_add_users, setAddUsers] = useState(false);
  const [open_team_view, setOpenTeamView] = useState(false);

  const [team, setCurrentTeam] = useState(null);

  const fileInput = React.createRef();

  const handelOpenCreate = status => {
    setOpenCreate(status);
  };
  const handleDeleteTeams = selected => {
    if (!selected || !teams) {
      return;
    }
    let new_teams = [];
    for (let index = 0; index < teams.length; index++) {
      if (selected.indexOf(index) === -1) {
        const team = teams[index];
        new_teams.push(team);
      }
    }

    projectCreateTeams(new_teams);
  };

  const handleImportTeams = () => {
    fileInput.current.click();
  };

  const getIndexTeam = team => {
    for (let index = 0; index < teams.length; index++) {
      if (team === teams[index]) {
        return index;
      }
    }
    return -1;
  };
  const handleMenuAction = ({ action, row }) => {
    setCurrentTeam(row);

    if (action === 'Delete') {
      const index = getIndexTeam(row);

      if (index >= 0) {
        handleDeleteTeams([index]);
      }
    } else if (action === 'Add Users') {
      setAddUsers(true);
    }
  };
  const handelOpenAddUsers = () => {
    setAddUsers(false);
  };
  const handleOpenTeamView = team_selected => {
    const { id, name, administrator } = team_selected;

    if (id && name && administrator) {
      setCurrentTeam(team_selected);
      setOpenTeamView(true);
    } else {
      setOpenTeamView(false);
    }
  };
  const handleAddUsers = users => {
    if (!team) {
      setAddUsers(false);
      return;
    }
    const index = getIndexTeam(team);

    if (index < 0) {
      setAddUsers(false);
      return;
    }
    const { members } = team;
    let new_members = null;
    if (members && isNaN(members)) {
      if (Array.isArray(members)) {
        const total = members.length;
        for (let i = 0; i < users.length; i++) {
          const here = members.map(e => e.email).indexOf(users[i].email);
          if (here === -1) {
            members.push(users[i]);
          }
        }
        if (total < members.length) {
          new_members = members;
        } else {
          setAddUsers(false);
          return;
        }
      }
    } else {
      new_members = users;
    }
    if (!new_members) {
      setAddUsers(false);
      return;
    }
    teams[index] = {
      ...team,
      members: new_members,
    };
    projectCreateTeams(teams);
    setAddUsers(false);
  };
  const handleSetUsers = users => {
    const index = getIndexTeam(team);
    if (index <= 0) {
      return;
    }
    let { members } = team;
    let newMembers = [];
    for (let i = 0; i < members.length; i++) {
      if (users.indexOf(i) === -1) {
        newMembers.push(members[i]);
      }
    }
    teams[index] = {
      ...team,
      members: newMembers && newMembers.length ? newMembers : 0,
    };
    projectCreateTeams(teams);
    setCurrentTeam(teams[index]);
    // forceUpdate();
  };
  const handleTeamCreated = team => {
    const index = teams.map(x => x.id).indexOf(team.id);
    if (index === -1) {
      teams.push(team);
      projectCreateTeams(teams);
    }
    setOpenCreate(false);
  };
  return (
    <StepContainer off_heander title={title}>
      <CreateTeamsToolBar
        handleAddTeam={handleTeamCreated}
        handleImportTeams={handleImportTeams}
        handleOpenCreateTeam={handelOpenCreate}
      />
      <div className={classes.container}>
        <TableTeams
          handleDeleteSelected={handleDeleteTeams}
          handleMenuAction={handleMenuAction}
          handleOpenTeamView={handleOpenTeamView}
          rows={teams}
        />
      </div>
      <DialogCreateTeam
        external_centers={props.centers}
        handleCloseDialog={handelOpenCreate}
        handleTeamCreated={handleTeamCreated}
        open={open_create}
      />
      <DialogUsersToTeam
        handleAddUsers={handleAddUsers}
        handleCloseDialog={handelOpenAddUsers}
        open={open_add_users}
      />
      <DialogViewTeam
        handleCloseDialog={handleOpenTeamView}
        handleSetUsers={handleSetUsers}
        open={open_team_view}
        team={team}
      />
    </StepContainer>
  );
};

StepCreateTeams.propTypes = {
  className: PropTypes.string,
  projectCreateTeams: PropTypes.func.isRequired,
  project_name: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const {
    projects: {
      teams,
      project_type: { name },
      project_settings: { centers },
      users,
    },
    info_user: { user },
  } = state;
  let size = teams.length;
  size += users.length;
  size += teams.length;
  size += centers.length;
  return {
    teams,
    project_name: name,
    user,
    users,
    centers,
    size,
  };
};

export default connect(mapStateToProps, {
  projectCreateTeams,
})(StepCreateTeams);
