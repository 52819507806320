import React from 'react';
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import clsx from 'clsx';
// @mui/material components
import { withStyles } from 'tss-react/mui';

import useStyles from './Styles';

class InfoArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      title,
      description,
      iconColor,
      vertical,
      icon: Icon,
      styleBox,
    } = this.props;
    // console.log({ description });
    const iconWrapper = clsx({
      [classes.iconWrapper]: true,
      [classes[iconColor]]: true,
      [classes.iconWrapperVertical]: vertical,
    });
    const iconClasses = clsx({
      [classes.icon]: true,
      [classes.iconVertical]: vertical,
    });
    return (
      <div className={classes.infoArea} style={styleBox}>
        <div className={iconWrapper}>
          <Icon className={iconClasses} />
        </div>
        <div className={classes.descriptionWrapper}>
          <h4 className={classes.title}>{title}</h4>
          <p className={classes.description}>{description}</p>
        </div>
      </div>
    );
  }
}

InfoArea.defaultProps = {
  iconColor: 'gray',
  styleBox: null,
};

InfoArea.propTypes = {
  description: PropTypes.any.isRequired,
  icon: PropTypes.object.isRequired,
  iconColor: PropTypes.oneOf([
    'primary',
    'warning',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  title: PropTypes.string.isRequired,
  vertical: PropTypes.bool,
};
export default withStyles(InfoArea, useStyles);
