import React from 'react';
import PropTypes from 'prop-types';

import { Fab } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import { DialogCreateTeam, DivGrow, IconButtonLoad } from 'components';
import { MutationAddProjectTeams } from 'graphql/Projects/utils_project';
import { GetData, ServerErrorsString } from 'helpers';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  button: {
    margin: 0,
    marginLeft: 'auto',
  },
});

class CreateProjectTeamBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      open: false,
    };
  }

  handleUpdateCenter = () => {
    const { updating } = this.state;
    if (updating) return;
    const center = this.props.holder.getCenter();
    if (!center || center === undefined) {
      this.props.handleAddError('Center is missing');
      return;
    }
    const team = this.props.holder.getTeam();
    if (!team || team === undefined) {
      this.props.handleAddError('Team is missing');
      return;
    }
    const { project, project_teams } = this.props;
    for (let i = 0; i < project_teams.length; i++) {
      const { idCenter, idTeam } = project_teams[i];
      if (idTeam === team.id && idCenter && center.id) {
        this.props.handleAddError(
          `Team (${team.name}) of the center (${center.name}) is already here`
        );
        return;
      }
    }
    this.setState({ updating: true });
    this.props.holder.setDisableAll(true);
    (async () => {
      MutationAddProjectTeams(project.id, [
        { idTeam: team.id, idCenter: center.id },
      ])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, pc_teams } = data.addTeamsToProject;
          if (ok) {
            this.setState({ updating: false });
            this.props.holder.resetCenter();
            this.props.handleAddNewProjectTeams(
              pc_teams.map(x => ({
                ...x,
                center: x.center_team.center,
              }))
            );
            this.props.holder.setDisableAll(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.holder.setDisableAll(false);
        });
    })();
  };

  handleOpenCrateTeam = () => {
    this.setState({ open: !this.state.open });
  };
  handleTeamCreated = team => {
    this.setState({ open: false });
    this.props.handleTeamCreated(team);
  };

  render() {
    const { classes, changed, center } = this.props;
    const { updating } = this.state;
    //
    return (
      <React.Fragment>
        <Fab
          aria-label="add"
          className={classes.button}
          color="primary"
          disabled={center ? false : true || updating}
          onClick={this.handleOpenCrateTeam}
          size="size">
          <AddIcon />
        </Fab>
        <DivGrow />
        <IconButtonLoad
          disabled={!changed || updating}
          handleClick={this.handleUpdateCenter}
          icon={<SaveIcon color={changed ? 'primary' : 'disabled'} />}
          loading={updating}
          tooltip="Create Team"
        />
        <DialogCreateTeam
          center={center}
          handleCloseDialog={this.handleOpenCrateTeam}
          handleTeamCreated={this.handleTeamCreated}
          open={this.state.open}
        />
      </React.Fragment>
    );
  }
}

CreateProjectTeamBottom.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func,
  handleUpdatedCenter: PropTypes.func,
  holder: PropTypes.object,
  project_teams: PropTypes.array,
};
CreateProjectTeamBottom.defaultProps = {
  holder: null,
  handleAddError: () => '',
  handleUpdatedCenter: () => '',
  handleTeamCreated: () => '',
  handleAddNewProjectTeams: () => '',
  project_teams: [],
};
export default withStyles(CreateProjectTeamBottom, useStyles);
