import React from 'react';
import PropTypes from 'prop-types';
import { DirectChatRow, TeamChannelRow } from './components';

class CommunicationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  IsSelected = () => {
    const { selected, receiver } = this.props;
    if (!selected) return false;
    const { direct, channel } = receiver;
    if (direct && selected.direct) {
      return direct.User.id === selected.direct.User.id;
    }
    if (channel && selected.channel) {
      return channel.id === selected.channel.id;
    }
    return false;
  };
  handleClicked = () => {
    const { receiver, handleCommunicationRowSelection } = this.props;
    handleCommunicationRowSelection(receiver);
  };

  render() {
    const { receiver } = this.props;
    //
    if (!receiver) return '';
    const { direct, channel } = receiver;
    if (direct)
      return (
        <DirectChatRow
          handleChatSelection={this.handleClicked}
          row={direct}
          selected={this.IsSelected()}
        />
      );
    if (channel)
      return (
        <TeamChannelRow
          channel={channel}
          handleChannelSelection={this.handleClicked}
          selected={this.IsSelected()}
        />
      );
  }
}

CommunicationRow.propTypes = {
  classes: PropTypes.object,
};
CommunicationRow.defaultProps = {
  handleCommunicationRowSelection: () => '',
  selected: null,
};

export default CommunicationRow;
