import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import {
  ButtonCircularIconLoad,
  ButtonReload,
  CardHeaderCustom,
  DivGrow,
} from 'components'; //SnackMessage
import { QueryUserSetting } from 'graphql/Users';
import { FormatBytes, GetData, ServerErrorsString } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { setUpdateSetting } from 'redux-store/actions/loginActions';

const useStyles = () => ({
  root: {
    width: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    marginTop: 10,
    fontWeight: 600,
    textTransform: 'none',
  },
  text_value: {
    fontSize: 15,
    textTransform: 'none',
  },
});

class CardSettingFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      loading: false,
      setting: null,
      empty: false,
    };
  }

  componentDidMount() {
    this.updateSettings();
  }

  getUserID = () => {
    const { setting } = this.state;
    if (!setting) return null;
    const { idUser } = setting;
    if (typeof idUser === 'undefined' || !idUser) {
      return null;
    }
    return idUser;
  };
  updateSettings = () => {
    const { user } = this.props;
    if (
      typeof user === 'undefined' ||
      !user ||
      typeof user.id === 'undefined' ||
      user.id < 1
    ) {
      return;
    }
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryUserSetting(user.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, setting } = data.UserSetting;
          if (ok) {
            this.setState({
              loading: false,
              setting,
              empty: setting ? false : true,
            });
            this.props.setUpdateSetting(setting);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };

  render() {
    const { classes, className } = this.props;
    const { loading, setting } = this.state;
    let storage = 0;
    let text_storage = 'Using 0 of 10 T storage';
    let file_size = 0;
    let devices = 0;
    let type = 'Unkwon';
    let files = 0;
    let dicoms = 0;
    if (setting) {
      const used = FormatBytes(setting.used);
      storage = FormatBytes(setting.storage);
      file_size = FormatBytes(setting.file_size);
      text_storage = `Using ${used} of ${storage} storage`;
      devices = setting.devices;
      dicoms = setting.dicoms;
      files = setting.files;
      if (setting.type) {
        type = setting.type.description;
      }

      //   storage = Math.ceil((setting.used / setting.storage) * 100, 10);
    }
    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeaderCustom
          action={
            <ButtonReload
              handleReload={this.updateSettings}
              loading={loading}
              small
              tooltip="Reload account setting"
            />
          }
          subheader="Account settings assigned to your account"
          title="Cloud Settings"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item>
              <Typography className={classes.title} variant="subtitle1">
                Account type
              </Typography>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Typography className={classes.text_value} variant="subtitle1">
                {type}
              </Typography>
              <Typography className={classes.title} variant="subtitle1">
                Current plan
              </Typography>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Typography className={classes.text_value} variant="subtitle1">
                {`Maximum file size (${file_size}) allow to upload`}
              </Typography>
              <Typography className={classes.text_value} variant="subtitle1">
                {`Maximum storage size (${storage})`}
              </Typography>
              <Typography className={classes.text_value} variant="subtitle1">
                {text_storage}
              </Typography>
              <Typography className={classes.text_value} variant="subtitle1">
                {`Only ${devices} devices are allow to sync`}
              </Typography>
              {/* <Divider style={{ marginBottom: 10, marginTop: 5 }} /> */}
              <Typography className={classes.title} variant="subtitle1">
                Files summary
              </Typography>
              <Divider style={{ marginBottom: 10, marginTop: 5 }} />
              <Typography className={classes.text_value} variant="subtitle1">
                {`Number of DICOM files: ${dicoms}`}
              </Typography>
              <Typography className={classes.text_value} variant="subtitle1">
                {`Number of normal files: ${files}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <DivGrow />
          <ButtonCircularIconLoad
            // handleClick={handleUpdateNotifications}
            // loading={loading}
            disabled
            name="Save"
            transform={false}
            variant="outlined"
          />
        </CardActions>
      </Card>
    );
  }
}

CardSettingFiles.propTypes = {
  classes: PropTypes.object,
};

export default connect(null, { setUpdateSetting })(
  withStyles(CardSettingFiles, useStyles)
);
