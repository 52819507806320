import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData, ServerErrorsString } from 'helpers';

import { rowsPerPageBig } from 'helpers';
import { TableGrid } from 'components';
import { columns_series } from 'common';
import { connect } from 'react-redux';
import {
  copySeries,
  copyJobSeries,
  setNewJobProcess,
} from 'redux-store/actions/file_folder_manager';
import { IsInvalid } from 'helpers';
import { MutationCreateJobProcess } from 'graphql/Process';
import {
  getInputVolumes,
  processGroups,
  processJobs,
} from './utils_group_series';
import DialogConfigurationDockerInput from 'components/Dialogs/DialogConfigurationDockerInput';

const useStyles = () => ({ root: {} });
class TabDataSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      selected: [],
      page: 0,
      limit: 50,
      is_configure_input: false,
      process: null,
      jobs_grouped: [],
    };
    const { holder } = props;
    if (holder) {
      holder.handleActionSeries = (action, process) =>
        this.handleAction(action, process);
      holder.handleDeleteSeries = () => this.handleDeleteSelected();
    }
  }
  handleDeleteSelected = () => {
    let { copy_series } = this.props;
    const { selected } = this.state;
    const ids = selected.map(x => x.idSeries);
    copy_series = copy_series.filter(x => ids.indexOf(x.idSeries) === -1);
    // console.log({ selected, copy_series, ids });
    this.setState({ selected: [] });
    this.props.copySeries(copy_series);
    this.props.cleanSelected();
  };
  handleCreateJobProcess = (rows, copy_series, docker_image) => {
    const { creating } = this.state;
    if (creating) return;
    if (!rows.length) {
      this.props.handleAddError('You need to select a input data first');
      return;
    }
    console.log({ rows });
    this.setState({ creating: true });
    const inputs = rows.map(x => {
      let { job_input_objects, setting, volumes, idSeries } = x;
      if (IsInvalid(job_input_objects)) {
        job_input_objects = [
          {
            idJob: x.idJob,
            idSeries,
            volumes: volumes ? volumes : [],
          },
        ];
      }
      copy_series = copy_series.filter(c_s => {
        const i = job_input_objects.map(x => x.idSeries).indexOf(c_s.idSeries);
        return i === -1;
      });
      return {
        idDockerImage: docker_image.id,
        idSeries,
        job_setting: setting,
        job_input_objects,
      };
    });
    (async () => {
      MutationCreateJobProcess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, jobs, fails } = data.createJobs;
          if (ok) {
            console.log({ jobs, fails });
            // show only one series if multiple inputs available
            this.props.copySeries(copy_series);
            // this.props.handleTabChanged(1);
            this.setState({
              selected: [],
              process,
              creating: false,
            });
            this.props.setSelected([]);
            this.props.setNewJobProcess(jobs.length ? true : false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAction = async (action, process) => {
    if (process === undefined || process === null) {
      this.props.handleAddError('Invalid operation');
      return;
    }
    const check = process.settings.inputs_outputs.filter(
      x => x.type.toLowerCase() === 'dicom' && x.in_out_type === 'input'
    );
    let { copy_series, jobs_series } = this.props;
    let error = null;
    if (copy_series.length === 0) error = 'No series to add to job';
    if (!check.length) error = 'Only accepting Dicoms as input';
    if (process.operation === 'None') error = 'Invalid operation';
    if (error) {
      this.props.handleAddError(error);
      return;
    }
    if (jobs_series === undefined || !jobs_series) {
      jobs_series = [];
    }
    let { selected } = this.state;
    const ids = selected.map(x => x.idSeries);
    const inputs = await getInputVolumes(process);
    const n_inputs = inputs.length;
    if (action === 'run') {
      copy_series = copy_series.filter(x => ids.indexOf(x.idSeries) === -1);
      if (!selected.length || selected.length % n_inputs != 0) {
        this.props.handleAddError('Not enough inputs to run this process');
        return;
      }
      console.log('handleAction', { n_inputs, selected, inputs });
      // auto group jobs based on criterias
      let new_jobs = [];
      if (n_inputs > 1) {
        new_jobs = processGroups(selected, inputs, new_jobs);
        if (!new_jobs) {
          this.props.handleAddError(
            'Not able to group the inputs automatically'
          );
          return;
        }
      } else {
        new_jobs = processJobs(selected, inputs, new_jobs);
      }
      new_jobs = new_jobs.map(x => ({ ...x, process }));
      console.log('new_jobs', { new_jobs });
      this.setState({
        is_configure_input: n_inputs > 1,
        jobs_grouped: new_jobs,
        process,
      });
      if (n_inputs === 1) {
        this.handleCreateJobProcess(new_jobs, copy_series, process);
      }
    } else if (action === 'copy') {
      copy_series = copy_series.filter(x => ids.indexOf(x.idSeries) === -1);
      for (let i = 0; i < selected.length; i++) {
        console.log({ jobs_series });
        const index = jobs_series
          .map(x => x.idSeries)
          .indexOf(selected[i].idSeries);
        console.log({ index });
        if (index === -1) {
          jobs_series.push(selected[i]);
        }
      }
      console.log({ copy_series, jobs_series });
      this.props.copySeries(copy_series);
      this.props.copyJobSeries(jobs_series);
      this.props.handleTabChanged(1);
      this.setState({ selected: [] });
      this.props.setSelected(copy_series);
    }
  };
  handleSelected = selected => {
    this.setState({ selected });
    this.props.setSelected(selected);
  };
  handleUpdateJobs = jobs => {
    const { copy_series } = this.props;
    const { process } = this.state;
    this.handleCreateJobProcess(jobs, copy_series, process);
  };
  render() {
    const { height, copy_series } = this.props;
    const { is_configure_input, jobs_grouped, process } = this.state;
    return (
      <Box>
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={this.state.selected}
          headers={columns_series}
          height={height - 240}
          idRow="idSeries"
          limit={this.state.limit}
          onSelectedChanged={this.handleSelected}
          page={this.state.page}
          rows={copy_series}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        {process ? (
          <DialogConfigurationDockerInput
            handleAddError={this.props.handleAddError}
            handleClose={() => this.setState({ is_configure_input: false })}
            handleUpdateJobs={jobs => this.handleUpdateJobs(jobs)}
            open={is_configure_input}
            process={process}
            row_jobs={jobs_grouped}
          />
        ) : null}
      </Box>
    );
  }
}

TabDataSeries.propTypes = {};

const mapStateToProps = state => {
  const { copy_series, jobs_series } = state.manager;
  let size = copy_series ? copy_series.length : 0;
  if (jobs_series) size += jobs_series.length;
  return {
    jobs_series: jobs_series ? jobs_series : [],
    copy_series: copy_series ? copy_series : [],
    size,
  };
};

export default connect(mapStateToProps, {
  copySeries,
  copyJobSeries,
  setNewJobProcess,
})(withStyles(TabDataSeries, useStyles));
