import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  ErrorOutline as ErrorOutlineIcon,
  Folder as FolderIcon,
  GetApp as GetAppIcon,
} from '@mui/icons-material';
import { ReadyIcon } from 'views/DownloadUpload/components/helpers';
import { getInitials, GetThumbnailFile } from 'helpers';
import {
  GetContentDate,
  GetContentName,
  GetContentOwner,
  GetContentSize,
  GetContentThumbnail,
} from 'helpers/utils_explorer';
import { FormatBytes } from 'helpers';

class DownloadItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  GetWarning = () => {
    return false;
  };
  getAvatar = () => {
    const { idFolder, Series, File } = this.props.item;
    if (Series) {
      let thumbnail = GetContentThumbnail({ Series });
      if (thumbnail.indexOf('/images') === -1)
        thumbnail = `data:image/jpeg;base64,${thumbnail}`;
      // console.log({ thumbnail });
      return (
        <Avatar alt={getInitials(Series.SeriesDescription)} src={thumbnail} />
      );
    } else if (File) {
      return (
        <Avatar
          alt={getInitials(File.original_name)}
          src={GetThumbnailFile(File.original_name)}
        />
      );
    } else if (idFolder !== undefined && idFolder) {
      return (
        <Avatar>
          <FolderIcon />
        </Avatar>
      );
    }
  };

  render() {
    const { current_index, index, item, item_progress, progress } = this.props;
    const downloading = current_index === index;
    const warning = this.GetWarning();
    const avatar = this.getAvatar();
    const downloaded = this.props.IsDownloaded(index);
    let ComponentUploadSize = null;
    if (progress) {
      ComponentUploadSize = (
        <ListItemText
          primary="Uploaded"
          secondary={FormatBytes(progress.loaded)}
        />
      );
    }
    return (
      <Paper style={{ marginTop: 5 }}>
        <ListItem>
          <ListItemAvatar>{avatar}</ListItemAvatar>
          <ListItemText
            primary={GetContentName(item)}
            secondary={GetContentSize(item)}
          />
          <ListItemText
            primary={GetContentOwner(item)}
            secondary={GetContentDate(item)}
          />
          {ComponentUploadSize}
          <ListItemSecondaryAction>
            {downloaded ? (
              <IconButton aria-label="ready" disabled edge="end" size="large">
                {warning ? (
                  <ErrorOutlineIcon style={{ fontSize: 40, color: 'orange' }} />
                ) : (
                  <ReadyIcon
                    color="primary"
                    style={{ fontSize: 40, color: 'green' }}
                    viewBox={'0 0 16 16'}
                  />
                )}
              </IconButton>
            ) : (
              <IconButton
                aria-label="upload"
                edge="end"
                onClick={() => this.props.handleDownloadItem(index)}
                size="large">
                <GetAppIcon color="primary" />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              disabled={downloading}
              edge="end"
              onClick={() => this.props.handleDelete(item)}
              size="large">
              <DeleteIcon color="secondary" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {downloading && (
          <LinearProgress value={item_progress} variant="determinate" />
        )}
      </Paper>
    );
  }
}

DownloadItem.propTypes = {
  classes: PropTypes.object,
};
DownloadItem.defaultProps = {
  handleDelete: () => '',
  handleDownloadItem: () => '',
  item_progress: 0,
  downloading: false,
  downloaded: false,
  progress: null,
};

export default DownloadItem;
