import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { Dialog, Grid } from '@mui/material';

import {
  BarAddUserProject,
  FolderFilesNames,
  TableShareOwners,
  useStyles,
} from '../components';

import { MutationCreateRights } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';
import {
  ButtonLoad,
  DialogActions,
  DialogContent,
  DialogTitle,
  SnackMessage,
} from 'components';
import {
  createRight,
  getRightIndex,
  getRightName,
  IsRightOff,
} from 'helpers/utils_rights';

class DialogShareFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_rights: [],
      update_rights: [],
      error: '',
      to_delete: [],
      loading: false,
      changed: false,
    };
    this.holder = {
      getPermission: null,
      getSelected: null,
    };
  }

  handleSaveRights = () => {
    let { new_rights, update_rights } = this.state;
    const rights = new_rights.concat(update_rights);
    for (let i = 0; i < new_rights.length; i++) {
      if (IsRightOff(rights[i])) {
        this.setState({
          error: `- The permission of ${getRightName(rights[i])} are not set.`,
        });
        return;
      }
    }
    this.handleSetRights(rights);
  };
  handleSetRights = new_rights => {
    const { loading } = this.props;
    if (loading) return;
    const { folders } = this.props;
    let final_rights = [];
    for (let i = 0; i < folders.length; i++) {
      const idFolder = folders[i].idFolder;
      for (let j = 0; j < new_rights.length; j++) {
        final_rights.push({ ...new_rights[j], idFolder });
      }
    }
    this.setState({ loading: true });
    (async () => {
      MutationCreateRights(final_rights)
        .then(res => {
          const data = GetData(res);
          const { ok, rights, errors } = data.createRights;
          if (ok) {
            this.props.handleShareFolder(rights);
            this.setState({
              loading: false,
              new_rights: [],
              update_rights: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleAddObject = object => {
    if (!object || Object.keys(object).length === 0) {
      this.setState({ error: 'Please select a user or project first.' });
      return;
    }
    const { new_rights } = this.state;
    const props_rights = this.getPropsRights();

    const right = createRight(object);
    let index_new = new_rights
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    let index_props = props_rights
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    if (index_new === -1 && index_props === -1) {
      new_rights.push(right);
      this.setState({ new_rights });
    } else {
      this.setState({
        error: `${object.type}:\n  - ${object.name} is already here.`,
      });
    }
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleAddToDelete = to_delete => {
    this.setState({ to_delete });
  };
  handleDeleteSelected = to_delete => {
    const { new_rights } = this.state;
    for (let i = 0; i < to_delete.length; i++) {
      const right = to_delete[i];
      let index_new = new_rights
        .map(x => getRightIndex(x))
        .indexOf(getRightIndex(right));
      if (index_new !== -1) {
        new_rights.splice(index_new, 1);
      }
      this.handlePropsFolderDelete(right);
    }
    this.setState({ new_rights, to_delete: [] });
  };
  handleClose = () => {
    this.setState({
      new_rights: [],
      update_rights: [],
      error: '',
      to_delete: [],
      loading: false,
      changed: false,
    });
    this.props.handleClose();
  };
  handleCheckedChanged = right => {
    const { new_rights, update_rights } = this.state;
    let index = new_rights
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    if (index !== -1) {
      new_rights[index] = right;
      this.setState({ new_rights, changed: true });
    }
    const props_rights = this.getPropsRights();
    index = props_rights
      .map(x => getRightIndex(x))
      .indexOf(getRightIndex(right));
    if (index !== -1) {
      index = update_rights
        .map(x => getRightIndex(x))
        .indexOf(getRightIndex(right));
      if (index !== -1) {
        update_rights[index] = right;
      } else {
        update_rights.push(right);
      }
      this.handlePropsFolderUpdate(right);
      this.setState({ update_rights });
    }
  };
  handlePropsFolderDelete = right => {
    for (let i = 0; i < this.props.folders.length; i++) {
      let { Rights } = this.props.folders[i];
      if (Rights === undefined || Rights.length === 0) {
        continue;
      }
      const index = Rights.map(x => getRightIndex(x)).indexOf(
        getRightIndex(right)
      );
      if (index !== -1) {
        Rights.splice(index, 1);
        this.props.folders[i].Rights = Rights;
        this.props.updateRights(this.props.folders[i]);
      }
    }
  };
  handlePropsFolderUpdate = right => {
    for (let i = 0; i < this.props.folders.length; i++) {
      let { Rights } = this.props.folders[i];
      if (Rights === undefined || Rights.length === 0) {
        continue;
      }
      const index = Rights.map(x => getRightIndex(x)).indexOf(
        getRightIndex(right)
      );
      if (index !== -1) {
        this.props.folders[i].Rights[index] = right;
      }
    }
  };
  getPropsRights = () => {
    const { folders } = this.props;
    let rights = [];
    for (let i = 0; i < folders.length; i++) {
      const { Rights } = folders[i];
      if (Rights === undefined || Rights.length === 0) {
        continue;
      }
      for (let j = 0; j < Rights.length; j++) {
        const here = rights
          .map(x => getRightIndex(x))
          .indexOf(getRightIndex(Rights[j]));
        if (here === -1) {
          rights.push(Rights[j]);
        }
      }
    }
    return rights;
  };

  render() {
    const { open, folders } = this.props;
    const { new_rights, error, to_delete, loading, update_rights } = this.state;
    let rights = this.getPropsRights();
    rights = rights.concat(new_rights);
    const changed = new_rights.length + update_rights.length;
    let HeaderDialog = null;
    let disable_modification = true;
    if (this.props.user.type !== 5) {
      disable_modification = false;
      HeaderDialog = (
        <Grid item>
          <BarAddUserProject
            handleAdd={this.handleAddObject}
            handleDeleteSelected={this.handleDeleteSelected}
            Rights={this.getPropsRights()}
            to_delete={to_delete}
          />
        </Grid>
      );
    }
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={this.handleClose}
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          <FolderFilesNames objects={folders} size={folders.size} />
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={2}>
            {HeaderDialog}
            <Grid item>
              <TableShareOwners
                disable_checkbox={disable_modification}
                handleCheckedChanged={this.handleCheckedChanged}
                handleSelected={this.handleAddToDelete}
                holder={this.holder}
                rights={rights}
                selected={this.state.to_delete}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: 5, marginTop: 5 }}>
          <ButtonLoad
            disable_button={!changed}
            handleClick={this.handleSaveRights}
            loading={loading}
            name="Save Rights"
          />
        </DialogActions>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Dialog>
    );
  }
}

DialogShareFolders.defaultProps = {
  folders: [],
  updateRights: () => '',
};
DialogShareFolders.propTypes = {
  classes: PropTypes.object,
  folders: PropTypes.array,
};

const mapStateToProps = state => {
  const {
    info_user: { user },
  } = state;
  return {
    user,
  };
};

export default connect(mapStateToProps)(
  withStyles(DialogShareFolders, useStyles)
);
