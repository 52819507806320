export const useStyles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  nameContainer: {
    // marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
});
export const ID = 'ID';
