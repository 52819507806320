import { IsInvalid } from 'helpers';

export const GetInputConsent = props => {
  let inputs = [];
  let errors = [];
  if (IsInvalid(props)) return { inputs, errors };
  const { rows_edited } = props;
  if (IsInvalid(rows_edited) || !rows_edited.length) {
    return { inputs, errors };
  }
  for (let i = 0; i < rows_edited.length; i++) {
    const { id: idPatient, patient_consent } = rows_edited[i];
    if (IsInvalid(patient_consent)) {
      continue;
    }
    let { type, information } = patient_consent;
    const index = options_types
      .map(x => x.name.toLowerCase())
      .indexOf(type.toLocaleLowerCase());
    if (index === -1) {
      const text = `Invalid type (${type}), options are: Automatic/Written/Verbal`;
      const j = errors.indexOf(text);
      if (j === -1) {
        errors.push(text);
      }
      continue;
    } else {
      type = options_types[index].name;
    }
    let consent = false;
    const to_check = patient_consent.consent;
    if (typeof to_check === 'string') {
      if (to_check && to_check.toLocaleLowerCase().startsWith('y')) {
        consent = true;
      }
    } else {
      consent = to_check ? true : false;
    }
    inputs.push({
      idPatient,
      type,
      information,
      consent,
    });
  }
  return { errors, inputs };
};
export const options_types = [
  { name: 'None', id: 0 },
  { name: 'Automatic', id: 1 },
  { name: 'Written', id: 2 },
  { name: 'Verbal', id: 2 },
];
