import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import {
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import {
  TableRowQueryResult,
  ToolBarQueryRetrieve,
  headers,
} from './components';
import useStyles from './Styles';
import { IsInvalid, SelectedArray, rowsPerPageBig } from 'helpers';

class TableQueryResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      dense: false,
      selectedSeries: [],
      selectedStudies: [],
      numSeries: 0,
      StudiesSeries: [],
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getPageLimit = () => ({
        page: this.state.page,
        limit: this.state.limit,
      });
      holder.setPageLimit = (page, limit) => {
        this.setState({
          page,
          limit,
        });
      };
    }
    this.holder = {
      rowsSeries: [],
      getSeries: [],
    };
  }

  addNumberOfSeries = ListSeries => {
    if (ListSeries.length === 0) return;
    const { idStudy } = ListSeries[0];
    let {
      numSeries,
      selectedStudies,
      selectedSeries,
      StudiesSeries,
    } = this.state;
    let indexStudy = selectedStudies.map(x => x.idStudy).indexOf(idStudy);
    if (indexStudy !== -1) {
      selectedStudies = selectedStudies.filter(x => x.idStudy !== idStudy);
      selectedSeries = selectedSeries.concat(ListSeries);
    }
    indexStudy = StudiesSeries.indexOf(idStudy);
    if (indexStudy === -1) {
      StudiesSeries.push(idStudy);
    }
    numSeries += ListSeries.length;

    this.setState({ numSeries, selectedStudies, selectedSeries });
  };
  handleSelectedAllSeries = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selectedStudies: rows });
    } else {
      this.setState({ selectedStudies: [], selectedSeries: [] });
    }
  };
  handleSelectedSeries = series => {
    const { idStudy, idSeries } = series;
    const { rows } = this.props;
    let { selectedSeries, selectedStudies } = this.state;
    const indexStudy = rows.map(x => x.idStudy).indexOf(idStudy);
    const id = selectedStudies.map(x => x.idStudy).indexOf(idStudy);
    if (id !== -1) {
      const list_series = this.getStudySeries(idStudy);
      selectedSeries = selectedSeries.concat(list_series);
    }
    const index = selectedSeries
      ? selectedSeries.map(x => x.idSeries).indexOf(idSeries)
      : 0;
    let newSelectedSeries = SelectedArray(selectedSeries, series, index);
    if (index !== -1) {
      selectedStudies = selectedStudies.filter(x => x.idStudy !== idStudy);
      newSelectedSeries = newSelectedSeries.filter(
        x => x.idSeries !== idSeries
      );
    } else {
      const { NumberOfStudyRelatedSeries } = rows[indexStudy];
      const numSeries = newSelectedSeries.filter(x => x.idStudy === idStudy)
        .length;
      if (NumberOfStudyRelatedSeries === numSeries) {
        const i = selectedStudies.map(x => x.idStudy).indexOf(idStudy);
        if (i === -1) {
          selectedStudies = [...selectedStudies, rows[indexStudy]];
        } else {
          selectedStudies = selectedStudies.filter(x => x.idStudy !== idStudy);
        }
        newSelectedSeries = newSelectedSeries.filter(
          x => x.idStudy !== idStudy
        );
      } else {
        selectedStudies = selectedStudies.filter(x => x.idStudy !== idStudy);
      }
    }
    this.setState({ selectedSeries: newSelectedSeries, selectedStudies });
  };
  handleSelectedStudy = study => {
    const { idStudy } = study;
    let { selectedStudies, selectedSeries } = this.state;
    const index = selectedStudies
      ? selectedStudies.map(x => x.idStudy).indexOf(idStudy)
      : 0;
    const newSelected = SelectedArray(selectedStudies, study, index);
    if (index === -1) {
      selectedSeries = selectedSeries.filter(x => x.idStudy !== idStudy);
    }
    this.setState({ selectedStudies: newSelected, selectedSeries });
  };
  handleDeleteSelected = () => {};
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.handleRunSearch(null, newPage, this.state.limit);
  };

  handleChangeLimit = event => {
    const limit = parseInt(event.target.value, 10);
    this.setState({ page: 0, limit });
    this.handleRunSearch(null, 0, limit);
  };
  handleSelectedDeleted = () => {
    const { selectedSeries, selectedStudies } = this.state;
    this.props.handleStudyDeleted(selectedStudies);
    for (let i = 0; i < this.holder.rowsSeries.length; i++) {
      this.holder.rowsSeries[i](selectedSeries);
    }
    this.setState({ selectedSeries: [], selectedStudies: [] });
  };
  getStudySeries = idStudy => {
    for (let i = 0; i < this.holder.getSeries.length; i++) {
      const series = this.holder.getSeries[i]();
      if (!series.length) continue;
      if (series[0].idStudy === idStudy) return series;
    }
    return [];
  };
  handleRunSearch = (current_query, page, limit) => {
    if (IsInvalid(limit)) limit = this.state.limit;
    if (IsInvalid(current_query)) current_query = this.props.current_query;
    if (IsInvalid(current_query) || !Object.keys(current_query).length) {
      current_query = this.props.holder.getQuerySearch('');
    }
    if (page === undefined) page = this.state.page;
    this.setState({
      selectedSeries: [],
      selectedStudies: [],
      StudiesSeries: [],
    });
    this.props.runSearch(current_query, page, limit);
  };
  render() {
    const { classes, total, rows, loading, numSeries, height } = this.props;
    const { page, limit, dense, selectedSeries, selectedStudies } = this.state;
    const total_studies = rows.length;
    let emptyRows = 7;
    if (total_studies < emptyRows) emptyRows = emptyRows - total_studies;
    //Math.min(limit, Math.abs(rows.length - limit));
    else emptyRows = 0;
    let numSelected = selectedSeries.length;
    const rowCount = numSeries;
    let checked = numSelected ? numSelected === rowCount : false;
    let indeterminate = numSelected > 0 && numSelected < rowCount;
    const TotalStudies = selectedStudies.length;
    if (TotalStudies) {
      indeterminate = TotalStudies > 0 && TotalStudies < total_studies;
      checked = TotalStudies ? TotalStudies === total_studies : false;
    }
    for (let i = 0; i < selectedStudies.length; i++) {
      const { NumberOfStudyRelatedSeries } = selectedStudies[i];
      numSelected += NumberOfStudyRelatedSeries;
    }
    let ids_studies = [
      ...new Set(
        selectedSeries
          .map(x => x.idStudy)
          .concat(selectedStudies.map(x => x.idStudy))
      ),
    ];
    return (
      <Paper className={classes.root}>
        <ToolBarQueryRetrieve
          bar_holder={this.props.holder}
          current_query={this.props.current_query}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleAddWarning={this.props.handleAddWarning}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReload={() => this.handleRunSearch()}
          loading={loading}
          numSelected={numSelected}
          selectedDeleted={this.handleSelectedDeleted}
          selectedSeries={selectedSeries}
          selectedStudies={selectedStudies}
          setState={state => this.setState(state)}
          setting={this.props.setting}
          total_studies={total_studies}
        />
        <TableContainer sx={{ height: height - 290 }}>
          <Table
            aria-label="sticky table"
            size="small"
            stickyHeader
            sx={{ whiteSpace: 'nowrap' }}>
            <TableHead className={classes.header}>
              <TableRow>
                <TableCell className={classes.cell_empty} />
                <TableCell className={classes.cell_empty}>
                  {rowCount !== 0 && (
                    <Checkbox
                      checked={checked}
                      indeterminate={indeterminate}
                      onChange={this.handleSelectedAllSeries}
                    />
                  )}
                </TableCell>
                {headers.map((header, index) => {
                  const { name, align, sx } = header;
                  let res = {};
                  if (name === 'PatientName')
                    res = { className: classes.cell_text };
                  if (align) res = { ...res, align };
                  if (sx) res = { ...res, sx };
                  return (
                    <TableCell key={`row-header-${index}-${name}`} {...res}>
                      {name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                const { idStudy } = row;
                const isSelected = ids_studies.indexOf(idStudy) !== -1;
                return (
                  <TableRowQueryResult
                    addNumberOfSeries={this.addNumberOfSeries}
                    bar_holder={this.props.holder}
                    handleSelectedSeries={this.handleSelectedSeries}
                    handleSelectedStudy={this.handleSelectedStudy}
                    headers={headers}
                    holder={this.holder}
                    key={`${idStudy}`}
                    loading={loading}
                    row={row}
                    selectedSeries={selectedSeries}
                    selectedStudy={isSelected}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 70) * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper className={classes.paper}>
          <Divider />
          <TablePagination
            component="div"
            count={total}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeLimit}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Paper>
      </Paper>
    );
  }
}

TableQueryResults.propTypes = {
  addNumberOfSeries: PropTypes.func,
};

export default withStyles(TableQueryResults, useStyles);
