import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Grid } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
  EditAnatomicalRegion,
  EditImageFocus,
  EditPathology,
  EditSequence,
  EditSide,
  TopToolBar,
} from './components';

class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { height } = this.state;
    const height_card = 380;
    return (
      <div
        style={{
          height: height - 68,
          width: '100%',
        }}>
        <Card>
          <TopToolBar handleBack={this.handleBack} />
        </Card>
        <Divider />
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="space-between"
          spacing={4}
          style={{
            width: '100%',
            overflow: 'auto',
            height: height - 70 * 2,
            paddingTop: 0,
            marginTop: 5,
            marginLeft: 0,
            marginRight: 0,
            paddingRight: 40,
          }}>
          <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
            <EditSide
              handleAddError={this.props.handleAddError}
              height={height_card}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
            <EditPathology
              handleAddError={this.props.handleAddError}
              height={height_card}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
            <EditImageFocus
              handleAddError={this.props.handleAddError}
              height={height_card}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
            <EditSequence
              handleAddError={this.props.handleAddError}
              height={height_card}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} sx={{ minWidth: 310 }} xs={12}>
            <EditAnatomicalRegion
              handleAddError={this.props.handleAddError}
              height={height_card}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ViewDetails.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ViewDetails);
