import React from 'react';
import PropTypes from 'prop-types';
import { TableFullTypes, ToolBarQueryTypes } from './components';
import { Grid } from '@mui/material';
import { QuerySiteMappings } from 'graphql/Mapping';
import { GetData, ServerErrorsString } from 'helpers';

class TabQueryTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0,
      mappings: [],
    };
    this.holder = {
      getPageLimit: null,
    };
  }
  componentDidMount() {
    this.runSearch();
  }
  runSearch = (newPage, newLimit) => {
    const { loading } = this.state;
    if (loading) {
      return;
    }

    let { page, limit } = this.holder.getPageLimit();
    if (newLimit !== undefined) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QuerySiteMappings(null, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings, total } = data.siteMappings;
          // console.log({ mappings });
          if (ok) {
            this.setState({ loading: false, mappings, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePageLimit = (page, limit) => {
    this.runSearch(page, limit);
  };
  render() {
    const { height } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToolBarQueryTypes
            handleReload={this.runSearch}
            loading={this.state.loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TableFullTypes
            handleChangePageLimit={this.handleChangePageLimit}
            height={height - 90}
            holder={this.holder}
            loading={this.state.loading}
            mappings={this.state.mappings}
            total={this.state.total}
          />
        </Grid>
      </Grid>
    );
  }
}

TabQueryTypes.propTypes = {
  classes: PropTypes.object,
};

export default TabQueryTypes;
