import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItem, ListItemText } from '@mui/material';
import { Transition } from 'components';

export default function DialogRequestSuccess({
  open,
  history,
  email,
  unit_name,
  name,
}) {
  const handleClose = () => {
    history.push('/');
  };

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      keepMounted
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}>
      <DialogTitle id="alert-dialog-slide-title">
        {'Account Request'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your request has been entered. The administrator of the Unit that you
          selected will evaluate your request and will respond accordingly to
          your email address.
        </DialogContentText>
        <ListItem>
          <ListItemText primary="Name:" secondary={name} />
          <ListItemText primary="Email:" secondary={email} />
          <ListItemText primary="Unit:" secondary={unit_name} />
        </ListItem>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
