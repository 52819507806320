/* eslint-disable indent */
import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_CATEGORY_PROJECTS = gql`
  query($account: InputAccount!, $name: String, $page: Int, $limit: Int) {
    allAccountCategoryProjects(
      account: $account
      name: $name
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      projects {
        id
        name
        type
        membership {
          idMember
          member_setting {
            id
            idProject
            idUser
            access_level
          }
        }
        admin {
          id
          FullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryAccountCategoryProjects = (category, name, page, limit) => {
  // eslint-disable-next-line indent
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CATEGORY_PROJECTS),
    variables: {
      account: { name: category },
      name,
      page,
      limit,
    },
  });
};
