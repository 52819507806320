import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

class TextTypography extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, text } = this.props;
    return (
      <React.Fragment>
        <Typography
          display="inline"
          gutterBottom
          style={{ marginRight: '5px', textTransform: 'none' }}
          variant="h6">
          {title}
        </Typography>
        <Typography
          display="inline"
          gutterBottom
          style={{ textTransform: 'none' }}
          variant="caption">
          {text}
        </Typography>
      </React.Fragment>
    );
  }
}

TextTypography.propTypes = {
  classes: PropTypes.object,
};

export default TextTypography;
