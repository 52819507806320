import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useStyles } from './Style';
import { PaperComponent } from 'helpers';
import CloseIcon from '@mui/icons-material/Close';
class DialogAnonymizationKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, classes } = this.props;
    if (!open) return null;
    const style = { fontWeight: 1000, fontSize: '13px' };
    return (
      <div>
        <Dialog
          aria-labelledby="rules-dialog-title"
          key="rules-dialog-modal"
          onClose={this.props.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle
            id="form-dialog-title"
            style={{ cursor: 'move', paddingTop: 1 }}>
            <ListItem
              secondaryAction={
                <IconButton
                  aria-label="delete"
                  edge="end"
                  onClick={this.props.handleClose}>
                  <CloseIcon />
                </IconButton>
              }>
              <ListItemText
                primary={
                  <Typography fontWeight="fontWeightBold" variant="body">
                    Rules for Anonymization Keys
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>D</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>
                Replace with a non-zero length value that may be a dummy value
                and consistent with the VR
              </span>
            </p>
            <p>
              <strong>
                <span className={classes.span}>Z</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>
                Replace with a zero-length value, or a non-zero length value
                that may be a dummy value and consistent with the VR
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>X</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>remove</span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>K</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>
                Keep (unchanged for non-sequence attributes, cleaned for
                sequences)
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>C</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>
                Clean, that is replace with values of similar meaning known not
                to contain identifying information and consistent with the VR
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>U</span>
              </strong>
              <span className={classes.span}>:&nbsp;</span>
              <span className={classes.span_black}>
                Replace with a non-zero length UID that is internally consistent
                within a set of Instances
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>Z/D</span>
              </strong>
              <span className={classes.span}>: &nbsp;</span>
              <span className={classes.span_black}>
                <span style={style}>Z</span> unless <span style={style}>D</span>{' '}
                is required to Maintain IOD conformance (Type 2 versus Type 1)
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>X/Z</span>
              </strong>
              <span className={classes.span}>: &nbsp;</span>
              <span className={classes.span_black}>
                <span style={style}>X</span> unless <span style={style}>Z</span>{' '}
                is required to Maintain IOD conformance (Type 3 versus Type 2)
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>X/D</span>
              </strong>
              <span className={classes.span}>: &nbsp;</span>
              <span className={classes.span_black}>
                <span style={style}>X</span> unless <span style={style}>D</span>{' '}
                is required to Maintain IOD conformance (Type 3 versus Type 1)
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>X/Z/D</span>
              </strong>
              <span className={classes.span}>: &nbsp;</span>
              <span className={classes.span_black}>
                <span style={style}>X</span> unless <span style={style}>Z</span>{' '}
                or <span style={style}>D</span> is required to maintain IOD
                conformance (Type 3 versus Type 2 versus Type 1)
              </span>
            </p>
            <p className={classes.p}>
              <strong>
                <span className={classes.span}>X/Z/U</span>
              </strong>
              <span className={classes.span}>*: &nbsp;</span>
              <span className={classes.span_black}>
                <span style={style}>X</span> unless <span style={style}>Z</span>{' '}
                or replacement of contained instance UIDs (
                <span style={style}>U</span>) is required to maintain IOD
                conformance (Type 3 versus Type 2 versus Type 1 sequences
                containing UID references)
              </span>
            </p>
            <Divider />
            <div style={{ marginTop: 8 }}>
              <a
                href="https://dicom.nema.org/medical/dicom/current/output/chtml/part02/sect_n.11.2.6.html"
                rel="noreferrer"
                target="_blank">
                NEMA DICOM: Attribute Confidentiality Details
              </a>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogAnonymizationKeys.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(DialogAnonymizationKeys, useStyles);
