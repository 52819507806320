import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  ButtonCircularIconLoad,
  CardEditHeader,
  CheckBoxText,
  DialogSelectFolder,
  DivGrow,
  SearchFilesProjects,
  TextFieldCustom,
  TypeSequences,
} from 'components';
import ProjectTeamCenters from 'components/Projects/ProjectTeamCenters';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationCreateLandingObjects } from 'graphql/Administration/utils_landing_create';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { LoadingButton } from '@mui/lab';
import { ProjectVariableTypes } from './components';
const options = [{ id: 1, name: 'A' }];
class CreateLandingObject extends React.Component {
  constructor(props) {
    super(props);
    let { project, idFolderContent, folder, add_landing_object } = props;
    console.log({ add_landing_object });
    let isSeries = false;
    let folderStructure = undefined;
    let idCenterTeam = undefined;
    let pc_team = undefined;
    if (add_landing_object) {
      idFolderContent = add_landing_object.idFolderContent;
      const { File } = add_landing_object;
      if (add_landing_object.Rights) {
        let rights = add_landing_object.Rights.filter(x => x.Project);
        if (rights.length) {
          project = rights[0].Project;
        }
        rights = add_landing_object.Rights.filter(x => x.CenterTeam);
        if (rights.length) {
          idCenterTeam = rights[0].CenterTeam.id;
          pc_team = rights[0].CenterTeam;
        }
      }
      if (File) {
        const { locations, mimetype } = File;
        if (locations && locations.length) {
          folder = locations[0].Folder;
          const { folders_paths } = locations[0].Folder;
          if (folders_paths) {
            folderStructure = folders_paths.map(x => x.name).join('/');
          }
        }
        if (mimetype === 'application/zip') {
          isSeries = true;
        }
      }
    }
    this.state = {
      open: false,
      loading: false,
      creating: false,
      isSeries,
      idCenterTeam,
      project: project ? project : undefined,
      idFolderContent: idFolderContent ? idFolderContent : project,
      folder: folder ? folder : undefined,
      folderStructure,
      pc_team,
    };
    this.holder = { getTypes: null };
  }

  IsChanged = () => {
    const {
      idFolderContent,
      folder,
      sequence,
      project,
      idCenterTeam,
      folderStructure,
    } = this.state;
    if (idFolderContent) return false;
    if (folder) return false;
    if (sequence) return false;
    if (project) return false;
    if (idCenterTeam) return false;
    if (folderStructure) return false;
    return true;
  };
  runCreateLanding = () => {
    const {
      // eslint-disable-next-line no-unused-vars
      loading,
      creating,
      folder,
      sequence,
      project,
      // eslint-disable-next-line no-unused-vars
      pc_team,
      // eslint-disable-next-line no-unused-vars
      open,
      ...res
    } = this.state;
    if (creating) return;
    this.setState({ creating: true });
    const projectIds = [];
    const folderIds = [];
    let idSequence = null;
    let types = [];
    if (sequence) idSequence = sequence.idSequence;
    if (folder) folderIds.push(folder.idFolder);
    if (project) projectIds.push(project.id);
    if (this.holder.getTypes) types = this.holder.getTypes();
    (async () => {
      MutationCreateLandingObjects([
        { ...res, types, projectIds, folderIds, idSequence },
      ])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, landings } = data.createLandingObjects;
          if (ok) {
            this.setState({ creating: false });
            this.props.handleAddLanding(landings);
          } else throw errors;
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ creating: false });
        });
    })();
  };
  handleFolderSelection = folder => {
    let folderStructure = '';
    console.log({ folder });
    if (folder)
      folderStructure = folder.folders_paths.map(x => x.name).join('/');
    this.setState({ folderStructure, folder, open: false });
  };
  handleChangeCheck = event => {
    event.preventDefault();
    this.setState({ isSeries: event.target.checked });
  };
  render() {
    const { project, creating, idFolderContent, loading, folder } = this.state;
    let folder_name = 'Home';
    let idFolder = undefined;
    let folderStructure = '';
    if (folder) {
      idFolder = folder.idFolder;
      folder_name = folder.name;
      folderStructure = folder.folders_paths.map(x => x.name).join('/');
    }
    return (
      <React.Fragment>
        <Card>
          <CardEditHeader
            description="Fill the necessary field and save it"
            handleClose={this.props.handleClose}
            icon={<AddIcon style={{ color: 'blue' }} />}
            title="Create Landing Object"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextFieldCustom
                  id="idFolderContent"
                  label="Folder Content"
                  onChange={event =>
                    this.setState({ idFolderContent: event.target.value })
                  }
                  required
                  size="small"
                  type="number"
                  value={idFolderContent}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldCustom
                  id="idFolder"
                  label="Folder ID"
                  required
                  size="small"
                  type="number"
                  value={idFolder}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TypeSequences
                  onSequenceChanged={sequence => {
                    this.setState({ sequence });
                  }}
                  options={options}
                  sequence={this.state.sequence}
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <SearchFilesProjects
                  add_empty
                  handleAddError={this.props.handleAddError}
                  handleAddSeletedItem={project => this.setState({ project })}
                  holder={this.holder}
                  no_icon
                  selected={this.state.project}
                  small
                  sytle_autocomplete={{ paddingTop: 0 }}
                />
              </Grid>
              <Grid item xs={4}>
                <ProjectTeamCenters
                  disabled={project ? false : true}
                  idProject={project ? project.id : undefined}
                  onChanged={pc_teams => {
                    console.log({ pc_teams });
                    this.setState({
                      pc_team: pc_teams,
                      idCenterTeam: pc_teams ? pc_teams.idCenterTeam : null,
                    });
                  }}
                  pc_team={this.state.pc_team}
                  size="small"
                />
              </Grid>

              <Grid item xs={8}>
                <TextFieldCustom
                  id="folderStructure"
                  label="Folder Structure"
                  onChange={event =>
                    this.setState({ folderStructure: event.target.value })
                  }
                  required
                  size="small"
                  value={folderStructure}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  onClick={() => this.setState({ open: true })}
                  size="small"
                  startIcon={<DriveFolderUploadIcon />}
                  style={{ textTransform: 'none' }}
                  variant="contained">
                  {`Select folder: ${folder_name}`}
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <ProjectVariableTypes
                  handleAddError={this.props.handleAddError}
                  holder={this.holder}
                  project={this.state.project}
                />
              </Grid>
              <Grid item xs={3}>
                <CheckBoxText
                  checked={this.state.isSeries}
                  label="Is Series"
                  onChange={this.handleChangeCheck}
                  size="small"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions style={{ padding: 0 }}>
            <DivGrow />
            <ButtonCircularIconLoad
              disabled={this.IsChanged()}
              handleButtonClick={() => this.runCreateLanding()}
              loading={creating}
              name="Save"
              transform={false}
              variant="outlined"
            />
          </CardActions>
        </Card>
        <DialogSelectFolder
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open: false })}
          handleSelectFolder={this.handleFolderSelection}
          open={this.state.open}
        />
      </React.Fragment>
    );
  }
}

CreateLandingObject.propTypes = {
  handleAddLanding: PropTypes.func.isRequired,
};
CreateLandingObject.defaultProps = {
  handleAddLanding: () => '',
  add_landing_object: null,
  AddLandingObject: () => '',
};

export default CreateLandingObject;
