import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERT_LANDING_OBJECTS = gql`
  query($name: String, $processing: Boolean, $page: Int, $limit: Int) {
    landingObjects(
      name: $name
      processing: $processing
      page: $page
      limit: $limit
    ) {
      ok
      total
      landings {
        idLanding

        idFileObject
        idUser
        idSequence
        idCenterTeam
        pid

        file {
          idFileObject
          idLanding
          original_name
          Size
          Thumbnail
        }
        user {
          FullName
        }
        sequence {
          name
        }
        center_team {
          id
          center {
            id
            idAdmin
            name
          }
          team {
            id
            name
          }
        }

        isSeries
        processing
        projectIds
        folderIds
        folderStructure
        types
        function_name
        status
        inputs
        num_inputs
        errors

        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryLandingObjects = (name, page, limit, processing = null) => {
  console.log('QueryLandingObjects', { name, page, limit, processing });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERT_LANDING_OBJECTS),
    variables: {
      name,
      page,
      limit,
      processing,
    },
  });
};
const DELETE_LANDING_OBJECTS = gql`
  mutation($ids: [LongLong!]!, $keep_content: Boolean) {
    deleteLandingObjects(ids: $ids, keep_content: $keep_content) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLandingObjects = (ids, keep_content = false) => {
  console.log('MutationDeleteLandingObjects', { ids, keep_content });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_LANDING_OBJECTS),
    variables: {
      ids,
      keep_content,
    },
  });
};
const DELETE_LANDING_FINISHED = gql`
  mutation($status: String!) {
    deleteLandingAtStatus(status: $status) {
      ok
      errors {
        path
        message
      }
      landings {
        idLanding
        idFileObject
        idUser
        idSequence
        idCenterTeam
        status
      }
    }
  }
`;
export const MutationDeleteLandingStatus = status => {
  const variables = { status };
  console.log('MutationDeleteLandingStatus', { status });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_LANDING_FINISHED),
    variables,
  });
};
const MUTATION_PROCESS_LANDING_OBJECTS = gql`
  mutation($ids: [LongLong!]!) {
    proccessLandingObjects(ids: $ids) {
      ok
      errors {
        message
      }
    }
  }
`;
export const MutationProcessLandingObjects = ids => {
  console.log('MutationProcessLandingObjects', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_PROCESS_LANDING_OBJECTS),
    variables: {
      ids,
    },
  });
};

const MUTATION_STOP_LANDING_OBJECTS = gql`
  mutation($ids: [LongLong!]!) {
    stopLandingObjects(ids: $ids) {
      ok
      errors {
        message
      }
    }
  }
`;
export const MutationStopLandingObjects = ids => {
  console.log('MutationProcessLandingObjects', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_STOP_LANDING_OBJECTS),
    variables: {
      ids,
    },
  });
};
const MUTATION_EDIT_LANDING_OBJECTS = gql`
  mutation($inputs: [InputLanding!]!) {
    editLandingObjects(inputs: $inputs) {
      ok
      errors {
        message
      }
    }
  }
`;
export const MutationEditLandingObjects = inputs => {
  console.log('MutationEditLandingObjects', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_EDIT_LANDING_OBJECTS),
    variables: {
      inputs,
    },
  });
};
