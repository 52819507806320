import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GroupAdd } from '@mui/icons-material';

import { SearchTeamInputs } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  typo_header: {
    paddingBottom: theme.spacing(2),
  },
  add_button: {
    color: 'white',
    backgroundColor: green[400],
  },
}));

const CreateTeamsToolBar = props => {
  const { className, handleOpenCreateTeam, handleAddTeam } = props;

  const { classes } = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography className={classes.typo_header} component="div">
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            m={1}
            variant="button">
            PROJECT TEAMS
          </Box>
          <Box fontSize={22} fontWeight="fontWeightBold" m={1} spacing={3}>
            Select or Create Team
          </Box>
          <Box m={1}> </Box>
        </Typography>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchTeamInputs
          handleAddTeam={handleAddTeam}
          placeholder="Search an existing team"
          style={{'display': 'flex', 'width': '260px'}}
        />
        <span className={classes.spacer} />
        <Button
          color="primary"
          endIcon={<GroupAdd />}
          onClick={() => handleOpenCreateTeam(true)}
          variant="contained">
          Create Team
        </Button>
      </div>
    </div>
  );
};

CreateTeamsToolBar.propTypes = {
  className: PropTypes.string,
};

export default CreateTeamsToolBar;
