import { QueryInputsBySettingId } from 'graphql/Dockers/utils_docker_setting';
import { GetData, ServerErrorsString, IsInvalid } from 'helpers';
const groupByStudyInstanceUID = data => {
  const groupedData = new Map();
  data.forEach(item => {
    const studyInstanceUID = item.Study.StudyInstanceUID;
    if (!groupedData.has(studyInstanceUID)) {
      groupedData.set(studyInstanceUID, []);
    }
    groupedData.get(studyInstanceUID).push(item);
  });
  return groupedData;
};
const filterMap = (map, filterFn) => {
  const filteredMap = new Map();
  for (const [key, value] of map.entries()) {
    if (filterFn(value, key)) {
      filteredMap.set(key, value);
    }
  }
  return filteredMap;
};
const groupByFolderContent = (data, n_inputs) => {
  let groupedData = new Map();
  const n_data = data.length;
  data.forEach(series => {
    series.locations.forEach(location => {
      const idFolder = location.idFolder;
      if (!groupedData.has(idFolder)) {
        groupedData.set(idFolder, []);
      }
      groupedData.get(idFolder).push(series);
    });
  });
  const iterations = Math.floor(n_data / n_inputs);
  let keys = Array.from(groupedData.keys());

  for (let i = 1; i < iterations; i++) {
    groupedData = new Map(
      [...groupedData.entries()].sort((a, b) => a[1].length - b[1].length)
    );
    keys = Array.from(groupedData.keys());
    const groupedDataKey = keys[i - 1];
    const first = groupedData.get(groupedDataKey).map(f => f.idSeries);
    if (first.length > n_inputs) {
      groupedData.get(groupedDataKey).splice(n_inputs - first.length);
    } else if (first.length < n_inputs) {
      groupedData.delete(groupedDataKey);
      continue;
    }
    for (let m = i; m < keys.length; m++) {
      const old_element = groupedData.get(keys[m]);
      const new_element = old_element.filter(
        el => !first.includes(el.idSeries)
      );
      groupedData.set(keys[m], new_element);
    }
    groupedData = filterMap(groupedData, value => value.length >= n_inputs);
  }

  if (keys.length > iterations) {
    const n = keys.length - iterations;
    const keysToRemove = keys.slice(-n);
    const newGroupedData = Object.assign({}, groupedData);
    groupedData = keysToRemove.forEach(key => delete newGroupedData[key]);
  }
  console.log('groupedData', { groupedData });
  return groupedData;
};
const groupByPatientID = data => {
  const groupedData = new Map();
  data.forEach(item => {
    const patientID = item.Patient.PatientID;
    if (!groupedData.has(patientID)) {
      groupedData.set(patientID, []);
    }
    groupedData.get(patientID).push(item);
  });
  return groupedData;
};
const autoGroupJobs = (selected, inputs) => {
  let invalid = false;
  const n_inputs = inputs.length;
  const group_studies = groupByStudyInstanceUID(selected);
  console.log('groups_studies', { group_studies });
  Object.keys(group_studies).forEach(study => {
    if (group_studies[study].length != n_inputs) invalid = true;
  });
  if (invalid) {
    invalid = false;
    const group_patients = groupByPatientID(selected);
    console.log('group_patients', { group_patients });
    Object.keys(group_patients).forEach(patient => {
      if (group_patients[patient].length != n_inputs) invalid = true;
    });
    if (invalid) {
      invalid = false;
      const group_folders = groupByFolderContent(selected, n_inputs);
      console.log('group_folders', { group_folders });
      Object.keys(group_folders).forEach(folder => {
        if (group_folders[folder].length != n_inputs) invalid = true;
      });
      if (invalid) {
        return null;
      } else {
        return group_folders;
      }
    } else {
      return group_patients;
    }
  } else {
    return group_studies;
  }
};
const fillEmptyVolumes = (n_inputs, jobs_to_execute) => {
  let n_jobs = jobs_to_execute.length;
  for (let i = n_jobs - n_inputs; i < jobs_to_execute.length; i++) {
    for (let j = 0; j < jobs_to_execute[i].volumes.length; j++) {
      if (!jobs_to_execute[i].volumes[j].value) {
        let allZeroInRow = true;
        let allZeroInCol = true;
        for (let m = n_jobs - n_inputs; m < jobs_to_execute.length; m++) {
          if (jobs_to_execute[m].volumes[j].value) {
            allZeroInCol = false;
            break;
          }
        }
        for (let n = 0; n < n_inputs; n++) {
          if (jobs_to_execute[i].volumes[n].value) {
            allZeroInRow = false;
            break;
          }
        }
        if (allZeroInCol && allZeroInRow) {
          jobs_to_execute[i].volumes[j].value = true;
        }
      }
    }
  }
};
export const processGroups = (selected, inputs, jobs_to_execute) => {
  const grouped_jobs = autoGroupJobs(selected, inputs);
  if (grouped_jobs == null) {
    //'Not able to group the inputs automatically'
    return null;
  }
  const keys = Array.from(grouped_jobs.keys());
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const grouped_job = grouped_jobs.get(key);
    for (let j = 0; j < grouped_job.length; j++) {
      const job = grouped_job[j];
      let volumes = [];
      inputs.forEach(input => {
        let value = false;
        if (job.sequencename) {
          value = input.name === job.sequence.name;
        }
        volumes.push({
          id: input.id,
          volume: input.volume,
          value,
        });
      });

      job['idJob'] = i + 1;
      // job['idGroupJob'] = i + 1;
      job['volumes'] = volumes;
      const index = jobs_to_execute.map(x => x.idSeries).indexOf(job.idSeries);
      if (index === -1) {
        jobs_to_execute.push(job);
      }
    }
    fillEmptyVolumes(inputs.length, jobs_to_execute);
  }
  return jobs_to_execute;
};
export const processJobs = (selected, inputs, jobs_to_execute) => {
  for (let i = 0; i < selected.length; i++) {
    let volumes = [];
    let value = false;
    if (inputs.length === 1) value = true;
    inputs.forEach(input => {
      volumes.push({
        id: input.id,
        volume: input.volume,
        value,
      });
    });
    selected[i]['idJob'] = Math.floor(i / inputs.length) + 1;
    selected[i]['volumes'] = volumes;
    const index = jobs_to_execute
      .map(x => x.idSeries)
      .indexOf(selected[i].idSeries);
    if (index === -1) {
      jobs_to_execute.push({ ...selected[i] });
    }
  }
  return jobs_to_execute;
};
export const groupBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getInputVolumes = async (process, self) => {
  if (IsInvalid(process)) {
    return [];
  }
  const inputs = await QueryInputsBySettingId(process.settings.id)
    .then(async res => {
      const data = GetData(res);
      const { ok, errors, inputs } = data.getInputsBySettingId;
      if (ok) {
        return inputs;
      } else {
        throw errors;
      }
    })
    .catch(error => {
      self.props.handleAddError(ServerErrorsString(error));
    });
  return inputs;
};
