import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSleepCSV } from './utils';

const QUERY_SLEEP_POLYSOMNOGRAPHIES = gql`
  query($name: String, $page: Int, $limit: Int) {
    sleepPolysomnographies(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      polysomnographies {
        id
        idPatient
        idSleepCase
        start_datetime
        cpap_device
        sleep_duration
        sleep_latency
        rem_latency
        sleep_efficiency
        rem_percentage
        stage1_percentage
        stage2_percentage
        stage3_percentage
        supine_sleep_index
        apnoea_sleep_index
        hypopnoea_sleep_index
        centrale_sleep_index
        obstructive_sleep_index
        mixed_sleep_index
        average_spo2
        min_spo2
        time_90_spo2
        time_88_spo2
        desat_index
        co2_average_sleep
        co2_max_sleep
        co2_50_more_duration
        average_hf
        arousal_index
        plm_sleep_index
        subject_sleep_quality_pre
        subject_sleep_quality
        subject_sleep_duration
        subject_sleep_latency
        diagnose1
        diagnose2
        diagnose3
        ahi_sleep
        ahi_non_supine
        ahi_supine
        ahi_rem
        ahi_nrem

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepPolysomnographies = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_POLYSOMNOGRAPHIES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_SLEEP_POLYSOMNOGRAPHIES = gql`
  mutation($ids: [LongLong!]!) {
    deleteSleepPolysomnographies(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveSleepPolysomnographies = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_SLEEP_POLYSOMNOGRAPHIES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_SLEEP_POLYSOMNOGRAPHIES = gql`
  mutation($inputs: [InputSleepPolysomnography!]!) {
    addSleepPolysomnographies(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      polysomnographies {
        id
        idPatient
        idSleepCase
        start_datetime
        cpap_device
        sleep_duration
        sleep_latency
        rem_latency
        sleep_efficiency
        rem_percentage
        stage1_percentage
        stage2_percentage
        stage3_percentage
        supine_sleep_index
        apnoea_sleep_index
        hypopnoea_sleep_index
        centrale_sleep_index
        obstructive_sleep_index
        mixed_sleep_index
        average_spo2
        min_spo2
        time_90_spo2
        time_88_spo2
        desat_index
        co2_average_sleep
        co2_max_sleep
        co2_50_more_duration
        average_hf
        arousal_index
        plm_sleep_index
        subject_sleep_quality_pre
        subject_sleep_quality
        subject_sleep_duration
        subject_sleep_latency
        diagnose1
        diagnose2
        diagnose3
        ahi_sleep
        ahi_non_supine
        ahi_supine
        ahi_rem
        ahi_nrem

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddSleepPolysomnographies = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SLEEP_POLYSOMNOGRAPHIES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_SLEEP_POLYSOMNOGRAPHIES = gql`
  mutation($file: Upload!) {
    uploadSleepPolysomnographies(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      polysomnographies {
        id
        idPatient
        idSleepCase
        start_datetime
        cpap_device
        sleep_duration
        sleep_latency
        rem_latency
        sleep_efficiency
        rem_percentage
        stage1_percentage
        stage2_percentage
        stage3_percentage
        supine_sleep_index
        apnoea_sleep_index
        hypopnoea_sleep_index
        centrale_sleep_index
        obstructive_sleep_index
        mixed_sleep_index
        average_spo2
        min_spo2
        time_90_spo2
        time_88_spo2
        desat_index
        co2_average_sleep
        co2_max_sleep
        co2_50_more_duration
        average_hf
        arousal_index
        plm_sleep_index
        subject_sleep_quality_pre
        subject_sleep_quality
        subject_sleep_duration
        subject_sleep_latency
        diagnose1
        diagnose2
        diagnose3
        ahi_sleep
        ahi_non_supine
        ahi_supine
        ahi_rem
        ahi_nrem

        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadSleepPolysomnographies = (
  file,
  handleProgress = null
) => {
  return MutationUploadSleepCSV(
    file,
    MUTATION_UPLOAD_SLEEP_POLYSOMNOGRAPHIES,
    handleProgress
  );
};
