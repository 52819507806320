import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, ImageList, ImageListItem } from '@mui/material';
import clsx from 'clsx';

const tileData = Array.from(Array(28).keys());

class AvatarsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, indexSelected } = this.props;

    return (
      <ImageList className={classes.gridList} cols={5} rowHeight={100}>
        {tileData.map(index => (
          <ImageListItem
            // cols={tile.cols || 1}
            key={`avatar_${index}`}
            onClick={this.props.handleClick}>
            <Avatar
              alt={`avatar_${index}`}
              className={clsx({
                [classes.avatar]: true,
                [classes.avatar_selected]: index === indexSelected,
              })}
              src={'/images/avatars/avatar_' + index.toString() + '.png'}
            />
            {/* <img
                  alt={`avatar_${index}`}
                  src={
                    '/images/avatars/avatar_' + index.toString() + '.png'
                  }
                /> */}
          </ImageListItem>
        ))}
      </ImageList>
    );
  }
}

AvatarsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AvatarsList;
