import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@mui/material';

const TableRowDoubleCell = props => {
  return (
    <TableRow>
      <TableCell component="th" scope="row" style={props.style}>
        {props.name}
      </TableCell>
      <TableCell align="right">{props.value}</TableCell>
    </TableRow>
  );
};

TableRowDoubleCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
TableRowDoubleCell.defaultProps = {
  value: '',
  name: '',
};
export default TableRowDoubleCell;
