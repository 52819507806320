import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { blueGrey } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppBar, Toolbar } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
class EditMetadataToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { notchanged, loading, updating, title } = this.props;
    return (
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: blueGrey[500],
        }}>
        <Toolbar>
          <IconButton
            aria-label="close"
            color="inherit"
            edge="start"
            onClick={this.props.handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            component="div"
            sx={{ ml: 2, flex: 1 }}
            variant="h3">
            {title}
          </Typography>
          <LoadingButton
            disabled={notchanged}
            loading={updating || loading}
            loadingPosition="start"
            onClick={this.props.handleUpdateMetadata}
            startIcon={<SaveIcon />}
            sx={{
              color: 'green',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
            variant="contained">
            Save
          </LoadingButton>
        </Toolbar>
      </AppBar>
    );
  }
}

EditMetadataToolBar.propTypes = {
  title: PropTypes.string,
};
EditMetadataToolBar.defaultProps = {
  title: 'Edit Image Metadata',
};
export default EditMetadataToolBar;
