const useStyles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  container: {
    margin: theme.spacing(0, 2, 0, 2),
    // padding: theme.spacing(0, 2, 0, 2),
  },
  divider: {
    margin: theme.spacing(1),
    border: '1px solid',
  },
});
export default useStyles;
