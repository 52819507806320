export { default as CategoryProjects } from './CategoryProjects';
export const GetFilters = (idGroup, newValue, THIS) => {
  let { filters } = THIS.state;
  const is_array = Array.isArray(newValue);
  if (newValue && is_array) {
    return GetMultiFilters(idGroup, newValue, THIS);
  } else if (newValue && !is_array) {
    return GetSingleFilters(idGroup, newValue, THIS);
  } else if (idGroup) {
    const index = filters.map(x => x.idGroup).indexOf(idGroup);
    if (index !== -1) {
      filters = filters.filter(x => x.idGroup !== idGroup);
    }
  }
};
const GetMultiFilters = (idGroup, newValue, THIS) => {
  let { filters } = THIS.state;
  filters = filters.filter(x => x.idGroup !== idGroup);
  for (let i = 0; i < newValue.length; i++) {
    const value = newValue[i];
    const text = `${value.idGroup}-${value.name}`;
    const index = filters.map(x => `${x.idGroup}-${x.name}`).indexOf(text);
    if (index === -1) {
      filters.push(value);
    } else {
      filters[index] = value;
    }
  }
  return filters;
};
const GetSingleFilters = (idGroup, newValue, THIS) => {
  let { filters } = THIS.state;
  if (newValue) {
    const index = filters.map(x => x.idGroup).indexOf(newValue.idGroup);
    if (index === -1) {
      filters.push(newValue);
    } else {
      filters[index] = newValue;
    }
  } else if (idGroup) {
    const index = filters.map(x => x.idGroup).indexOf(idGroup);
    if (index !== -1) {
      filters = filters.filter(x => x.idGroup !== idGroup);
    }
  }
  return filters;
};
