import {
  LOGOUT_USER_PAGE_GLOBAL,
  PAGE_GLOBAL_SET_PAGE,
  PAGE_GLOBAL_OPEN_SIDEBAR,
  PAGE_GLOBAL_SET_SKIP_ONLINE,
  PAGE_GLOBAL_MIN_SIDEBAR,
} from '../actions/types';

const initialState = {
  page: '',
  openSidebar: false,
  minSidebar: false,
  width_sidebar: 240,
  skip_online: false,
};

const REDUCER = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case LOGOUT_USER_PAGE_GLOBAL:
      return initialState;
    case PAGE_GLOBAL_SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case PAGE_GLOBAL_OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: action.openSidebar,
      };
    case PAGE_GLOBAL_MIN_SIDEBAR:
      return {
        ...state,
        minSidebar: action.minSidebar,
      };
    case PAGE_GLOBAL_SET_SKIP_ONLINE:
      return {
        ...state,
        skip_online: action.skip_online,
      };
    default:
      return state;
  }
};

export default REDUCER;
