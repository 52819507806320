import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
} from 'components';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid, Paper } from '@mui/material';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class TabSequenceMappingButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: true,
    };
  }

  render() {
    const { classes, selected } = this.props;
    return (
      <Paper style={{ width: '100%', margin: 2, marginTop: 3 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button onClick={this.props.handleOpenCreate}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              <Button
                disabled={!selected.length}
                onClick={this.props.handleOpenEdit}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
              <ButtonDownloadType
                key="encryptions"
                tooltip="Download Mapping"
                type="CSV-SeriesMapping"
              />
              <ButtonFileUpload
                handleSelectFile={this.props.handleSelectFile}
                uploading={this.props.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item pr={2}>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={() => this.props.handleReload()}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload mapping"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

TabSequenceMappingButtons.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
TabSequenceMappingButtons.defaultProps = {
  handleSelectFile: () => '',
  uploading: false,
  handleClickButton: () => '',
  handleOpenEdit: () => '',
  handleOpenCreate: () => '',
  selected: [],
};

export default withStyles(TabSequenceMappingButtons, useStyles);
