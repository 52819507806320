import moment from 'moment';

export const SeriesTagValue = (Series, TagName) => {
  if (!Series) return '';
  if (TagName === 'SeriesTime') {
    if (typeof Series[TagName] === 'undefined') return '';
    return Series.SeriesTime === 'Invalid date'
      ? Series.SeriesTime
      : moment(Series.SeriesTime, 'HH:mm:ss').format('h:mm:ss a');
  }
  if (TagName === 'SeriesDate') {
    if (typeof Series[TagName] === 'undefined') return '';
    return Series.SeriesDate === 'Invalid date'
      ? Series.SeriesDate
      : moment(Series.SeriesDate, 'YYYY-MM-DD').format('MMMM Do YYYY');
  }
  if (typeof Series[TagName] !== 'undefined') {
    return Series[TagName];
  }
  if (TagName === 'PatientBirthDate') {
    if (!Series.Patient) return '';
    return moment(Series.Patient.PatientBirthDate, 'YYYY-MM-DD').format(
      'MMMM Do YYYY'
    );
  }
  if (TagName === 'PatientName') {
    if (!Series.Patient) return '';
    const { PatientName, FamilyName, GivenName } = Series.Patient;
    if (PatientName) return PatientName;
    if (FamilyName === 'Empty' || FamilyName === '')
      return GivenName ? GivenName : '';
    if (GivenName === 'Empty' || GivenName === '')
      return FamilyName ? FamilyName : '';
    return `${GivenName} ${FamilyName}`;
  }
  if (TagName === 'Size') {
    if (!Series.ImageSize) return '';
    return Series.ImageSize.Size;
  }
  if (TagName === 'Spacing') {
    if (!Series.ImageResolution) return '';
    return Series.ImageResolution.Spacing;
  }
  return '';
};
