import React from 'react';
import PropTypes from 'prop-types';
import ImageDetails from '../ImageDetails/ImageDetails';
import { Drawer } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useStylesImageViewer } from '../useStyles';

class DrawerImageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, height, ...res } = this.props;
    return (
      <Drawer
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        open={open}
        style={{ maxHeight: height - 50 }}
        variant="persistent">
        <ImageDetails {...res} />
      </Drawer>
    );
  }
}

DrawerImageDetails.propTypes = {
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool,
};
DrawerImageDetails.defaultProps = {
  handleDrawerClose: () => '',
  open: true,
};

export default withStyles(DrawerImageDetails, useStylesImageViewer);
