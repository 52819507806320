import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    type: 'number',
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 170,
    type: 'string',
  },
  {
    id: 'admin',
    label: 'Admin',
    align: 'left',
    minWidth: 250,
    type: 'user',
    format: value => {
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Name Missing';
      return FullName;
    },
  },
  {
    id: 'address',
    label: 'Address',
    align: 'left',
    minWidth: 170,
    format: value => {
      if (!value || value === undefined) {
        return 'Address not defined';
      }
      let { country, city } = value;
      if (!country || country === undefined || country === 'Unknown')
        return 'Country Missing';
      if (!city || city === undefined || city === 'Unknown') return country;
      return `${country}, ${city}`;
    },
  },
  {
    id: 'updatedAt',
    label: 'Update At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
