import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

class UserMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { phone: '' };
  }
  getPhone = () => {
    const { phone, external } = this.props;
    if (phone || external) return phone;
    return this.state.phone;
  };
  handlePhoneChange = new_phone => {
    const { phone, external } = this.props;
    console.log({ phone, new_phone });
    if (phone || external) {
      this.props.handlePhoneChange(new_phone);
    } else {
      this.setState({ phone: new_phone });
    }
  };
  render() {
    const phone = this.getPhone();
    // console.log({ phone });
    return (
      <PhoneInput
        containerStyle={{ marginTop: this.props.mt, width: '100%' }}
        country={'ch'}
        enableSearch
        inputProps={{
          // name: 'phone number',
          required: true,
          // autoFocus: false,
        }}
        inputStyle={{ width: '100%', height: 45, marginTop: 2 }}
        onChange={phone => this.handlePhoneChange(phone)}
        specialLabel="Mobile Number"
        value={phone}
      />
    );
  }
}

UserMobile.propTypes = {
  classes: PropTypes.object,
};
UserMobile.defaultProps = {
  phone: undefined,
  handlePhoneChange: () => '',
  external: false,
  mt: 5,
};

export default UserMobile;
