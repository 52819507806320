import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Typography,
  DialogContent,
  Divider,
  DialogActions,
  Button,
} from '@mui/material';
import { CardEditHeader } from 'components/Cards';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IsInvalid } from 'helpers';
import {
  header_epilepsy,
  header_ms_comorbidity,
  header_ms_concomitant_medication,
  header_ms_edss,
  header_ms_immunotherapy,
  header_ms_msfc,
  header_ms_patient,
  header_sleep,
  header_stroke,
} from './utils_csv_file';

class DialogShowUploadInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }

  handleClose = () => {
    console.log('handleClose');
    this.props.handleClose();
  };
  convertToCSV = data => {
    const header = Object.keys(data[0]).join('\t') + '\n';
    const rows = data.map(obj => Object.values(obj).join('\t')).join('\n');
    return header + rows;
  };
  handleContentDownload = () => {
    const { type } = this.props;
    let header = null;
    if (type === 'clinical_epilepsy') {
      header = header_epilepsy;
    } else if (type === 'clinical_sleep') {
      header = header_sleep;
    } else if (type === 'clinical_stroke') {
      header = header_stroke;
    } else if (type === 'clinical_ms_patient') {
      header = header_ms_patient;
    } else if (type === 'clinical_ms_comorbidities') {
      header = header_ms_comorbidity;
    } else if (type === 'clinical_ms_medication') {
      header = header_ms_concomitant_medication;
    } else if (type === 'clinical_ms_edss') {
      header = header_ms_edss;
    } else if (type === 'clinical_ms_immunotherapy') {
      header = header_ms_immunotherapy;
    } else if (type === 'clinical_ms_msfc') {
      header = header_ms_msfc;
    }
    if (IsInvalid(header)) return null;
    const csv = this.convertToCSV(header);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvData);
    link.setAttribute('download', `import_example_${type}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  getExampleString = () => {
    const { studyName } = this.props;
    let example = '';
    if (studyName === 'Epilepsy') {
      example =
        '"Outcome" use the prefix "OUT_". The field "engel_3m" would be changed into "OUT_engel_3m"';
    } else if (studyName === 'Sleep') {
      example =
        '"Polysomnography" use the prefix "PSG_". The field "sleep_duration" would be changed into "PSG_sleep_duration"';
    } else if (studyName === 'Stroke') {
      example =
        '"Follow up" use the prefix "fol_". The field "followup_mode" would be changed into "fol_followup_mode"';
    }
    return example;
  };
  render() {
    const { open, studyName } = this.props;

    const example_string = this.getExampleString();
    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={
            'Expected format of the upload file for the ' + studyName + ' case'
          }
          handleClose={this.handleClose}
          icon={<HelpOutlineIcon style={{ color: 'grey' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              Upload file information
            </Typography>
          }
        />
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <Typography color="textSecondary">
              The upload file needs to fullfill the following requirements:
            </Typography>

            <ul style={{ margin: '20px' }}>
              <li style={{ color: 'grey' }}>
                <Typography color="textSecondary">
                  The values of the CSV file must be separated by the tab (\t)
                  character
                </Typography>
              </li>
              <li style={{ color: 'grey' }}>
                <Typography color="textSecondary">
                  The first row of the CSV file must include the name of the
                  fields.
                  {!studyName.includes('MS') ? (
                    <span>
                      {' '}
                      Add a prefix string defining from which category is the
                      field coming (eg. for the category {example_string})
                    </span>
                  ) : null}
                </Typography>
              </li>

              <li style={{ color: 'grey' }}>
                <Typography color="textSecondary">
                  The CSV file allows to have empty values for all the column
                </Typography>
              </li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.props.handleClose}>
              Close
            </Button>
            <Button onClick={this.handleContentDownload}>
              Download example file
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogShowUploadInfo.propTypes = {
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  save: PropTypes.bool,
};
DialogShowUploadInfo.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  open: false,
  save: false,
};

export default DialogShowUploadInfo;
