import React from 'react';
import PropTypes from 'prop-types';
import { QueryOrphans } from 'graphql/Folders';
import { ServerErrorsString, GetData } from 'helpers';
import { TableSiteOrphans, ToolbarOrphans } from './components';

class TabOrphans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleting: false,
      orphans: [],
      open_delete: false,
      selected: [],
      copying: false,
      init: false,
    };
    this.holder = {
      getSelected: null,
      clean: null,
    };
  }
  componentDidMount() {
    this.updateOrphans();
  }
  componentDidUpdate() {
    if (!this.state.init) this.updateOrphans();
  }
  updateOrphans = () => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryOrphans()
        .then(res => {
          const data = GetData(res);
          this.setState({
            orphans: data.orphans,
            loading: false,
            init: true,
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            init: true,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    const { orphans, loading, deleting } = this.state;
    return (
      <React.Fragment>
        <ToolbarOrphans
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleReloadTable={this.updateOrphans}
          handleState={state => this.setState(state)}
          holder={this.holder}
          state={this.state}
          title="File object Orphans"
        />
        <TableSiteOrphans
          deleting={deleting}
          handleDeleteSelected={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleReloadTable={this.updateOrphans}
          handleSelected={selected => this.setState({ selected })}
          height={this.props.height - 275}
          holder={this.holder}
          loading={loading}
          orphans={orphans}
        />
      </React.Fragment>
    );
  }
}

TabOrphans.propTypes = {
  classes: PropTypes.object,
};

export default TabOrphans;
