import React from 'react';
import PropTypes from 'prop-types';
import { CardEditHeader } from 'components';
import { columns } from '../EditProjectObjectTypes/useStyles';
import { TableGridCollapsible } from './components';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
class MappingProjectObjectTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected } = this.props;
    return (
      <React.Fragment>
        <CardEditHeader
          description="To modify the current maps, expand the row to see and delete the current maps"
          handleClose={this.props.handleCloseEdit}
          icon={<AccountTreeIcon style={{ color: 'green' }} />}
          title="Current variable type mapping of the Project Objects"
        />
        <TableGridCollapsible
          checkboxSelection
          dense
          headers={columns}
          rows={selected}
        />
      </React.Fragment>
    );
  }
}

MappingProjectObjectTypes.propTypes = {
  classes: PropTypes.object,
};

export default MappingProjectObjectTypes;
