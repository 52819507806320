import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const MUTATION_UPDATE_DOCKER_IMAGE_SETTTING = gql`
  mutation(
    $idSetting: LongLong!
    $idDocker: LongLong!
    $setting: InputDockerSetting!
  ) {
    udpateSystemDockerImageSetting(
      idSetting: $idSetting
      idDocker: $idDocker
      setting: $setting
    ) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        idDocker
        inputs_outputs {
          id
          idDockerSetting
          in_out_type
          type
          volume
        }
        volume
        ports
        execute
        access
        dockerfile
        operation_name
      }
    }
  }
`;
export const MutationUpdateDockerImageSetting = (
  idSetting,
  idDocker,
  setting
) => {
  console.log({ idSetting, idDocker, setting });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_DOCKER_IMAGE_SETTTING),
    variables: {
      idSetting,
      idDocker,
      setting,
    },
  });
};
const MUTATION_CREATE_DOCKER_IMAGE = gql`
  mutation($idImage: String!, $setting: InputDockerSetting!) {
    createSystemDockerImage(idImage: $idImage, setting: $setting) {
      ok
      errors {
        path
        message
      }
      image {
        idImage
        ParentId
        name
        Labels
        RepoDigests
        Size
        createdAt
        Build
        RepoTags {
          name
          tag
        }
        SharedSize
        VirtualSize

        Comment
        DockerVersion
        Author
        Architecture
        Os
        Config {
          Hostname
          Domainname
          User
          AttachStdin
          AttachStdout
          AttachStderr
          ExposedPorts
          Tty
          OpenStdin
          StdinOnce
          Env
          Cmd
          Image
          Volumes
          WorkingDir
          Entrypoint
          OnBuild
          Labels
        }
        RootFS {
          Type
          Layers
        }
        Layers {
          Comment
          Created
          CreatedBy
          Id
          Size
          Tags
        }
        Status
      }
    }
  }
`;
export const MutationCreateDockerImage = (idImage, setting) => {
  console.log({ idImage, setting });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_DOCKER_IMAGE),
    variables: {
      idImage,
      setting,
    },
  });
};
const MUTATION_DELETE_DOCKER_IMAGE = gql`
  mutation($idsDocker: [LongLong!]!) {
    deleteSystemDockerImage(idsDocker: $idsDocker) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteDockerImage = idsDocker => {
  console.log({ idsDocker });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_DOCKER_IMAGE),
    variables: {
      idsDocker,
    },
  });
};
