import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Divider, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components';
import {
  LogsUnit,
  LogsUsers,
  LogsDockers,
  LogsErrors,
  LogsSequenceErrors,
  LogAdminUsers,
} from './components';
import { withStyles } from 'tss-react/mui';
import { getTabLabel, getParams } from 'helpers';
import { useStylesAdministrator } from '../useStyles';

class TabLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        {
          name: 'Users',
          TabComponent: LogsUsers,
        },
        {
          name: 'Admin Users',
          TabComponent: LogAdminUsers,
        },
        {
          name: 'Errors',
          TabComponent: LogsErrors,
        },
        {
          name: 'Unit',
          TabComponent: LogsUnit,
        },
        {
          name: 'Dockers',
          TabComponent: LogsDockers,
        },
        {
          name: 'Prediction Errors',
          TabComponent: LogsSequenceErrors,
        },
      ],
    };
  }
  componentDidMount() {
    const { level_2 } = getParams(this.props);
    const index = this.state.tabs
      .map(x => getTabLabel(x.name))
      .indexOf(level_2);
    if (index !== -1) {
      this.setState({ tabValue: index });
    }
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    if (tabValue >= 0 && tabValue < this.state.tabs.length) {
      this.props.history.push(
        `/administrator/logs/${getTabLabel(this.state.tabs[tabValue].name)}`
      );
    }
  };
  render() {
    const { tabValue } = this.state;
    const { classes } = this.props;
    if (!this.props.isActive) return null;
    return (
      <div>
        <Tabs
          className={classes.tabs_small}
          indicatorColor="primary"
          onChange={this.handleTabValue}
          textColor="primary"
          value={tabValue}
          variant="scrollable">
          {this.state.tabs.map((tab, index) => {
            const { name } = tab;
            return (
              <Tab
                className={classes.tab_small}
                key={`tab-logs-${index}`}
                label={name}
              />
            );
          })}
        </Tabs>
        <Divider />
        {this.state.tabs.map((tab, index) => {
          const { TabComponent } = tab;
          return (
            <TabPanel
              className={classes.tab_panel}
              index={index}
              key={`tab-container-${index}`}
              value={tabValue}>
              <TabComponent
                handleAddError={this.props.handleAddError}
                height={this.props.height}
                isActive={index === tabValue}
                user={this.props.user}
              />
            </TabPanel>
          );
        })}
      </div>
    );
  }
}

TabLogs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(TabLogs), useStylesAdministrator);
