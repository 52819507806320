import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import Split from 'react-split';
import { withRouter } from 'react-router-dom';

import {
  StudySeriesList,
  columns_study,
  useStylesSplit,
} from 'views/DataImages/components/ViewDatabaseStudy/components/StudyListView/components';
import { TableGrid } from 'components';
import {
  setDisplayDicomStudy,
  setDatabaseStudySelected,
  copySeries,
} from 'redux-store/actions';
import { IsInvalid, rowsPerPageBig } from 'helpers';

class PatientListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListSeries: [],
      selected: [],
      page: 0,
      limit: 50,
      sizes: [50, 50],
    };
  }
  handleStudyRowClicked = study => {
    console.log({ study });
    let ListSeries = [];
    if (study) {
      const { Series } = study;
      ListSeries = Series;
    }
    this.setState({ ListSeries });
  };
  handleSelectedChanged = selected => {
    let selected_series = [];
    for (let i = 0; i < selected.length; i++) {
      const { Series } = selected[i];
      if (IsInvalid(Series)) continue;
      for (let j = 0; j < Series.length; j++) {
        const { idSeries } = Series[j];
        const index = selected_series.map(x => x.idSeries).indexOf(idSeries);
        if (index === -1) {
          selected_series.push(Series[j]);
        }
      }
    }
    this.props.copySeries(selected_series);
  };
  handleStudyRowDoubleClick = study => {
    this.props.history.push(`/image_analysis/study/${study.idStudy}`);
  };
  render() {
    const { classes, studies, loading } = this.props;
    const { ListSeries, sizes } = this.state;
    console.log({ ListSeries });
    const panel_height = this.props.height - 255;
    const height_table = (panel_height * sizes[0]) / 100 - 10;
    return (
      <Split
        className={classes.splitter}
        direction="vertical"
        onDrag={sizes => this.setState({ sizes })}
        sizes={[50, 50]}
        style={{ height: panel_height }}>
        <div style={{ backgroundColor: '#eeeeee', overflow: 'auto' }}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_study}
            height={height_table}
            idRow="idStudy"
            loading={loading}
            onHighlightChange={this.handleStudyRowClicked}
            onRowClicked={this.handleStudyRowClicked}
            onRowDoubleClick={this.handleStudyRowDoubleClick}
            onSelectedChanged={this.handleSelectedChanged}
            paginationMode="server"
            rows={studies}
            rowsPerPage={this.props.total}
            rowsPerPageOptions={rowsPerPageBig}
            show_pagination={false}
          />
        </div>
        <div style={{ backgroundColor: 'white' }}>
          <StudySeriesList ListSeries={ListSeries} />
        </div>
      </Split>
    );
  }
}

PatientListView.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { study_selected } = state.page_database;
  const { copy_series } = state.manager;
  return {
    study_selected: study_selected === undefined ? [] : study_selected,
    numSelected: study_selected === undefined ? 0 : study_selected.length,
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, {
  setDatabaseStudySelected,
  setDisplayDicomStudy,
  copySeries,
})(withStyles(withRouter(PatientListView), useStylesSplit));
