import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { ServerErrorsString, GetData } from 'helpers';
import { PaperComponent, useStyles } from '../DialogCreateTeam/Styles';
import { ButtonLoad } from 'components';
import { MutationDeleteSequenceMapping } from 'graphql/Series/sequences';

class DialogDeleteSequenceMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };
  }

  handleClose = () => {
    const { deleting } = this.state;
    if (deleting) return;
    this.props.handleCloseDialog(false);
  };
  handleDeleteSelected = () => {
    const { selected } = this.props;
    const { deleting } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteSequenceMapping(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeSequenceMappings;
          if (ok) {
            let { mappings } = this.props;
            mappings = mappings.filter(x => ids.indexOf(x.id) === -1);
            this.setState({ deleting: false });
            this.props.handleDeletedSelected(mappings);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.handleCloseDialog();
        });
    })();
  };

  render() {
    const { classes, open } = this.props;
    const { deleting } = this.state;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={this.handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Delete Selected Sequence Mapping
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to delete the selected sequence mapping. This action
            cannot be undone. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={this.handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            className={classes.button}
            disable_button={deleting}
            handleClick={this.handleDeleteSelected}
            loading={deleting}
            name="Yes"
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogDeleteSequenceMapping.propTypes = {
  className: PropTypes.string,
  handleCloseDialog: PropTypes.func.isRequired,
};
DialogDeleteSequenceMapping.defaultProps = {
  handleDeletedSelected: () => '',
  handleCloseDialog: () => '',
  handleAddError: () => '',
  mappings: [],
  selected: [],
  open: false,
};
export default withStyles(DialogDeleteSequenceMapping, useStyles);
