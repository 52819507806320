import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonLoad } from 'components';
import { Grid } from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { Replay as ReloadIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MutationAddClinicalDataType,
  MutationRemoveClinicalDataType,
} from 'graphql/DataClinics/utils_clinical_type';

class ToolBarClinicalType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDelete = () => {
    let {
      clinical_types,
      new_clinical_types,
      selected,
      deleting,
    } = this.props.state;
    new_clinical_types = new_clinical_types.filter(x => {
      const i = selected.map(x => x.id).indexOf(x.id);
      if (i !== -1) selected.splice(x, 1);
      return i === -1;
    });
    console.log({ selected });
    if (!selected.length) {
      this.props.setState({ deleting: false, new_clinical_types });
      return;
    }
    if (deleting) return;
    this.props.setState({ deleting: true });
    (async () => {
      MutationRemoveClinicalDataType(
        selected.map(x => {
          return {
            id: x.id,
          };
        })
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeClinicalDataType;
          if (ok) {
            this.props.setState({
              deleting: false,
              new_clinical_types,
              clinical_types: clinical_types.filter(x => {
                const i = selected.map(x => x.id).indexOf(x.id);
                return i === -1;
              }),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  saveAccountClinicalDataType = () => {
    const { saving, state } = this.props;
    if (saving) return;
    let { clinical_types, new_clinical_types } = state;
    this.props.setState({ saving: true });
    const inputs = new_clinical_types.map(x => {
      return {
        code: x.code,
        description: x.description,
      };
    });
    (async () => {
      MutationAddClinicalDataType(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addClinicalDataType;
          if (ok) {
            const new_clinicals = data.addClinicalDataType.clinical_types;
            new_clinical_types = new_clinical_types.filter(x => {
              const i = new_clinicals
                .map(x => parseInt(x.code))
                .indexOf(parseInt(x.code));
              return i === -1;
            });
            this.props.setState({
              saving: false,
              new_clinical_types,
              clinical_types: new_clinicals.concat(
                new_clinical_types.concat(clinical_types)
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, new_clinical_types, saving, state } = this.props;
    const disabled = new_clinical_types.length ? false : true;
    const { selected, deleting } = state;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <IconButtonLoad
          handleClick={this.handleDelete}
          icon={<DeleteIcon style={{ color: 'red' }} />}
          loading={deleting}
          small
          tooltip="Delete Selected"
        />
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        p={1}
        spacing={1}>
        <Grid container item xs={6}>
          <IconButtonLoad
            disabled={disabled || saving}
            handleClick={this.saveAccountClinicalDataType}
            icon={<SaveIcon style={{ color: disabled ? 'gray' : 'blue' }} />}
            loading={saving}
            small
            tooltip="Save Clinical Type"
          />
          <IconButtonLoad
            handleClick={this.props.onOpenDialog}
            icon={<AddIcon style={{ color: 'green' }} />}
            small
            tooltip="Add Clinical Type"
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={6}>
          {ComponentDelete}
          <IconButtonLoad
            handleClick={this.props.onReloadTable}
            icon={<ReloadIcon />}
            loading={loading}
            small
            tooltip="Reload Table"
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarClinicalType.propTypes = {
  new_images: PropTypes.array,
};
ToolBarClinicalType.defaultProps = {
  new_images: [],
};
export default ToolBarClinicalType;
