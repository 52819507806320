import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';
const MUTATION_REMOVE_PATIENT_CONSENTS = gql`
  mutation($ids: [LongLong!]!) {
    removePatientConsents(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemovePatientConsents = ids => {
  console.log('MutationRemovePatientConsents', { ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_PATIENT_CONSENTS),
    variables: {
      ids,
    },
  });
};
export const MUTATION_UPDATE_DOCKER_IMAGE_SETTTING = gql`
  mutation($inputs: [InputPatientConsent!]!) {
    addPatientConsents(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      patient_consents {
        id
        idPatient

        consent
        file_path
        type
        information

        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const MutationAddPatientConsents = inputs => {
  console.log('MutationAddPatientConsents', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_DOCKER_IMAGE_SETTTING),
    variables: {
      inputs,
    },
  });
};
const MUTATIOM_UPLOAD_PATIENT_CONSENTS = gql`
  mutation($file: Upload!) {
    uploadPatientConsents(file: $file) {
      ok
      errors {
        path
        message
      }
      patient_consents {
        id
        idPatient

        consent
        file_path
        type
        information

        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const MutationUploadPatientConsentsCSV = (
  file,
  handleProgress = null
) => {
  return MutationUploadSingleFile(
    file,
    MUTATIOM_UPLOAD_PATIENT_CONSENTS,
    handleProgress
  );
};

const QUERY_PATIENTS_CONSENTS = gql`
  query($inputs: InputPatientConsentQuery, $page: Int, $limit: Int) {
    patientConsents(inputs: $inputs, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      patient_consents {
        id
        idPatient

        consent
        file_path
        type
        information

        encrypted_patient {
          PatientName
        }

        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const QueryPatientConsents = (inputs, page, limit) => {
  console.log('QueryPatientConsents', { inputs, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATIENTS_CONSENTS),
    variables: {
      inputs,
      page,
      limit,
    },
  });
};

const QUERY_PATIENTS_CONSENT_SEARCH = gql`
  query($inputs: [InputPatientConsentSearch!]!, $page: Int, $limit: Int) {
    searchPatientConsents(inputs: $inputs, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      patient_consents {
        id
        idPatient

        consent
        file_path
        type
        information

        encrypted_patient {
          GivenName
          FamilyName
          PatientName
          PatientID
          PatientBirthDate
          PatientSex
        }

        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const QueryPatientConsentSearch = (inputs, page, limit) => {
  console.log('QueryPatientConsentSearch', { inputs, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATIENTS_CONSENT_SEARCH),
    variables: {
      inputs,
      page,
      limit,
    },
  });
};
