import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

import { FormatTime } from 'helpers';

class EditSequenceMappingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selected } = this.props;
    let createdAt = '';
    if (selected.length) {
      createdAt = selected[0].createdAt;
    }
    return (
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => this.props.handleOpenEditView()}
            size="large">
            <CancelIcon />
          </IconButton>
        }
        avatar={<EditIcon style={{ color: 'red' }} />}
        subheader={'Created: ' + FormatTime(createdAt, 'MMM Do, YYYY')}
        title="Edit Selected Sequence Mapping"
      />
    );
  }
}

EditSequenceMappingHeader.propTypes = {
  classes: PropTypes.object,
};
EditSequenceMappingHeader.defaultProps = {};
export default EditSequenceMappingHeader;
