import { GetData, ServerErrorsString } from 'helpers';
export { default as FileInfoDetails } from './FileInfoDetails/FileInfoDetails';
export { default as DialogAddObjectToProject } from './DialogAddObjectToProject/DialogAddObjectToProject';
export { default as ToolBarSummary } from './ToolBarSummary';
export * from './Styles';
import {
  QueryFileObjectInformation,
  QueryFileObjectInformations,
} from 'graphql/Files';
export const queryFileInfo = self => {
  const { loading } = self.state;
  const { selected } = self.props;
  if (loading) return;
  if (selected && !selected.length) return;
  self.setState({ loading: true });
  const { idFileObject, idSeries } = selected[0];
  (async () => {
    QueryFileObjectInformation({ idFileObject, idSeries })
      .then(res => {
        const data = GetData(res);
        const { ok, errors, file_info } = data.fileObjectInfo;
        if (ok) {
          self.setState({ loading: false, file_info });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ loading: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
export const queryFileInfos = self => {
  const { loading } = self.state;
  const { selected } = self.props;
  if (loading) return;
  if (selected && !selected.length) return;
  self.setState({ loading: true });
  (async () => {
    QueryFileObjectInformations(
      selected.map(x => {
        const { idFileObject, idSeries } = x;
        return {
          idFileObject,
          idSeries,
        };
      })
    )
      .then(res => {
        const data = GetData(res);
        const { ok, errors, file_infos } = data.fileObjectInfos;
        if (ok) {
          self.setState({ loading: false, file_infos });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        self.setState({ loading: false });
        self.props.handleAddError(ServerErrorsString(error));
      });
  })();
};
