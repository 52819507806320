import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, TablePagination } from '@mui/material';

import { withStyles } from 'tss-react/mui';

import useStyles from '../components/Styles';
import { MainTableToolbar, MainTable } from '../components';
import { columns } from './components';
import { rowsPerPageBig } from 'helpers';

class TableFileVariableMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  isSelected = file => {
    if (file === undefined) return;
    const { selected } = this.props;
    const index = selected.map(x => x.idFileObject).indexOf(file.idFileObject);
    return index !== -1;
  };
  render() {
    const { classes, mappings, height, selected, total } = this.props;
    let style = {};
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    let count = mappings.length;
    if (total) {
      count = total;
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.props.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={this.props.loading}
          numSelected={selected.length}
          title="File Type Mapping"
          transform
        />
        <div className={classes.tableWrapper} style={{ ...style }}>
          <MainTable
            columns={columns}
            disable_splice={this.props.disable_splice}
            handleAcceptReject={this.props.handleAcceptReject}
            handleButtonView={this.props.handleButtonView}
            handleSelectAllClick={this.props.handleSelectAllClick}
            handleSelectedClick={this.props.handleSelectedClick}
            handleSelectedRow={this.props.handleSelectedRow}
            isSelected={this.isSelected}
            limit={this.props.limit}
            loading={this.props.loading}
            page={this.props.page}
            rows={mappings}
            selected={selected}
          />
        </div>
        <Divider />
        <TablePagination
          component="div"
          count={count}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableFileVariableMaps.propTypes = {
  classes: PropTypes.object,
  mappings: PropTypes.array.isRequired,
};
TableFileVariableMaps.defaultProps = {
  deleting: false,
  loading: false,
  mappings: [],
  selected: [],
  handleButtonView: () => console.log('handleButtonView'),
  handleSelectedRow: () => console.log('handleSelectedRow'),
  handleReloadTable: () => console.log('handleReloadTable'),
  handleChangePage: (event, newPage) =>
    console.log('handleChangePage: ', newPage),
  handleChangeRowsPerPage: event =>
    console.log('handleChangeRowsPerPage: ', event.target.value),
  handleSelectedClick: () => console.log('handleSelectedClick'),
  handleDeleteSelected: () => console.log('handleDeleteSelected'),
  handleClearSelected: () => '',
  disable_splice: false,
};
export default withStyles(TableFileVariableMaps, useStyles);
