import React from 'react';
import PropTypes from 'prop-types';
import WebGLSlider from '../WebGLSlider';
class SliderScales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSliceScale = (event, { value }, index) => {
    const { renderer } = this.props;
    if (!renderer) return;
    renderer.scale[index] = value;
    renderer.drawScene();
  };
  render() {
    const { renderer } = this.props;
    if (renderer === undefined || !renderer) return null;
    return (
      <React.Fragment>
        <WebGLSlider
          options={{
            min: -5,
            max: 5,
            step: 0.01,
            precision: 2,
            name: 'Scale X',
            value: renderer.scale[0],
          }}
          slide={(e, v) => this.handleSliceScale(e, v, 0)}
        />
        <WebGLSlider
          options={{
            min: -5,
            max: 5,
            step: 0.01,
            precision: 2,
            name: 'Scale Y',
            value: renderer.scale[1],
          }}
          slide={(e, v) => this.handleSliceScale(e, v, 1)}
        />
        <WebGLSlider
          options={{
            min: -5,
            max: 5,
            step: 0.01,
            precision: 2,
            name: 'Scale Z',
            value: renderer.scale[2],
          }}
          slide={(e, v) => this.handleSliceScale(e, v, 2)}
        />
      </React.Fragment>
    );
  }
}

SliderScales.propTypes = {
  classes: PropTypes.object,
};

export default SliderScales;
