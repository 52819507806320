import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { CreateDockerImageBottom, useStyles } from './components';
import {
  ButtonInputCircular,
  DivGrow,
  GroupBox,
  DockerInputs,
  CardCreateHeader,
} from 'components';

const default_state = {
  input_type: { name: '' },
  output_type: { name: '' },
  volume: '',
  ports: '',
  execute: false,
  access: { name: '' },
  operation_name: null,
};

class CreateDockerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      docker_file: null,
      ...default_state,
    };
    this.holder = {
      setSetting: null,
      getSetting: null,
    };
  }

  handleUpload = uploading => {
    this.setState({ uploading });
  };
  handleSettings = () => {
    const { docker_file, operation_name } = this.state;
    const settings = this.holder.getSetting();
    if (operation_name) {
      settings.operation_name = operation_name;
    }
    return { docker_file, settings };
  };
  handleSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      return;
    }
    this.setState({ docker_file: files[0] });
  };
  handleNewDockerImage = image => {
    if (!image) return;
    this.setState({
      uploading: false,
      docker_file: null,
      ...default_state,
    });
    this.props.handleNewDockerImage(image);
  };
  handleChangeOperationName = event => {
    this.setState({ operation_name: event.target.value });
  };
  render() {
    const { classes, height } = this.props;
    const { docker_file, operation_name } = this.state;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    let file_name = '';
    if (docker_file) {
      file_name = docker_file.name;
    }
    return (
      <Card className={classes.card} style={style}>
        <CardCreateHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateDocker}
          loading={this.state.loading}
        />
        <Divider />
        <CardContent>
          <Grid
            alignItems="flex-start"
            container
            direction="column"
            justifyContent="flex-start"
            spacing={2}>
            <Grid item style={{ height: 'auto', width: '100%' }}>
              <GroupBox title_box="Input docker image">
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="operation-name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Operation Name"
                      onChange={this.handleChangeOperationName}
                      placeholder="Name of the docker operation (e.g. tar.gz)..."
                      required
                      value={operation_name}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <TextField
                      defaultValue="Load the docker image (e.g. tar.gz)..."
                      disabled
                      fullWidth
                      id="standard-disabled"
                      label="Docker Image"
                      value={file_name}
                      variant="filled"
                    />
                  </Grid>
                  <DivGrow />
                  <Grid item>
                    <ButtonInputCircular
                      accept={'.*'}
                      disabled={this.state.uploading}
                      handleSelectFile={this.handleSelectFile}
                      icon={<AddIcon />}
                      text="Import Docker"
                    />
                  </Grid>
                </Grid>
              </GroupBox>
            </Grid>
            <Grid item>
              <DockerInputs holder={this.holder} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions disableSpacing>
          <CreateDockerImageBottom
            handleAddError={this.props.handleAddError}
            handleNewDockerImage={this.handleNewDockerImage}
            handleSettings={this.handleSettings}
            handleUpload={this.handleUpload}
          />
        </CardActions>
      </Card>
    );
  }
}

CreateDockerImage.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
CreateDockerImage.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
};
export default withStyles(CreateDockerImage, useStyles);
