import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_TEAM_MEMBERS = gql`
  query($teamId: Int!, $name: String) {
    allTeamMembers(teamId: $teamId, name: $name) {
      ok
      members {
        id
        firstName
        lastName
        FullName
        email
        avatar
        createdAt
        phone
        address {
          city
          country
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

const QUERY_ALL_TEAMS = gql`
  query($name: String, $ids: [LongLong!], $page: Int, $limit: Int) {
    allTeams(name: $name, ids: $ids, page: $page, limit: $limit) {
      ok
      teams {
        id
        name
        admin {
          id
          firstName
          lastName
          FullName
        }
        projects {
          id
          name
        }
        members {
          id
          firstName
          lastName
          FullName
          email
          avatar
          createdAt
          phone
          address {
            city
            country
          }
        }
        avatar
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryAllTeamsAndMembers = (name, ids, page, limit) => {
  console.log('QueryAllTeamsAndMembers', { name, ids });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_TEAMS),
    variables: {
      name,
      ids,
      page,
      limit,
    },
  });
};

export const QueryTeamMembers = (teamId, name) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_TEAM_MEMBERS),
    variables: {
      teamId,
      name,
    },
  });
};

const MUTATION_ADD_TEAM_MEMBERS = gql`
  mutation($emails: [String!], $teamId: Int!, $idProject: LongLong) {
    addTeamMembers(emails: $emails, teamId: $teamId, idProject: $idProject) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAddTeamMembers = (emails, teamId, idProject) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_TEAM_MEMBERS),
    variables: {
      emails,
      teamId,
      idProject,
    },
  });
};
