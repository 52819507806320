import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody } from '@mui/material';
import SeriesRow from '../SeriesRow';
import { FormatSeriesDate, FormatSeriesTime } from 'helpers';

class SeriesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { Series, Study } = this.props;
    let ImageSize = { Size: '' };
    if (Series.ImageSize) {
      ImageSize = Series.ImageSize;
    }
    let ImageResolution = { Spacing: '' };
    if (Series.ImageResolution) {
      ImageResolution = Series.ImageResolution;
    }
    const Patient = {
      PatientName: '',
      PatientID: '',
      PatientBirthDate: '',
    };
    if (Series.Patient) {
      Patient.PatientName = Series.Patient.PatientName;
      Patient.PatientID = Series.Patient.PatientID;
      Patient.PatientBirthDate = FormatSeriesDate(
        Series.Patient.PatientBirthDate
      );
    }
    let sequence = '';
    if (Series.sequence) {
      sequence = Series.sequence.name;
    }
    const SeriesTime = FormatSeriesTime(Series.SeriesTime);
    const SeriesDate = FormatSeriesDate(Series.SeriesDate);
    //FormatSeriesDate(Series.SeriesDate)
    // console.log({ Study });
    return (
      <Table stickyHeader>
        <TableBody>
          <SeriesRow TagName={'Patient Name'} TagValue={Patient.PatientName} />
          <SeriesRow TagName={'PatientID'} TagValue={Patient.PatientID} />
          <SeriesRow
            TagName={'BirthDate'}
            TagValue={Patient.PatientBirthDate}
          />
          <SeriesRow TagName={'Station Name'} TagValue={Series.StationName} />
          <SeriesRow
            TagName={'StudyDescription'}
            TagValue={Study.StudyDescription}
          />
          <SeriesRow
            TagName={'AccessionNumber'}
            TagValue={Study.AccessionNumber}
          />
          <SeriesRow TagName={'StudyDate'} TagValue={Series.SeriesDate} />
          <SeriesRow TagName={'Modality'} TagValue={Series.Modality} />
          <SeriesRow
            TagName={'SeriesDescription'}
            TagValue={Series.SeriesDescription}
          />
          <SeriesRow TagName={'SeriesNumber'} TagValue={Series.SeriesNumber} />
          <SeriesRow TagName={'Image size'} TagValue={ImageSize.Size} />
          <SeriesRow
            TagName={'Resolution'}
            TagValue={ImageResolution.Spacing}
          />
          <SeriesRow TagName={'Sequence'} TagValue={sequence} />
          <SeriesRow
            TagName={'SeriesDate'}
            TagValue={`${SeriesDate}, ${SeriesTime}`}
          />
          <SeriesRow
            TagName={`SeriesInstanceUID ${' '}`}
            TagValue={Series.SeriesInstanceUID}
          />
          <SeriesRow
            TagName="Number of Files"
            TagValue={Series.NumberOfSeriesRelatedInstances}
          />
        </TableBody>
      </Table>
    );
  }
}

SeriesTable.propTypes = { classes: PropTypes.object };

export default SeriesTable;
