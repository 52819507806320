import React from 'react';
import PropTypes from 'prop-types';
import { ButtonReloadDelete, DialogDeleteWarning } from 'components';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { ServerErrorsString, GetData, IsInvalid, getParamsUrl } from 'helpers';
import { withRouter } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HTTP_ADDRESS_WEB } from 'config';
import { MutationDeleteNotifications } from 'graphql/Communications';

class ToolbarActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      saving: false,
    };
  }

  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { selected, notifications } = this.props;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteNotifications(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteNotifications;
          if (ok) {
            this.setState({
              deleting: false,
              open_delete: false,
              selected: [],
            });
            this.props.setState({
              notifications: notifications.filter(
                x => ids.indexOf(x.id) === -1
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getLink = row_clicked => {
    if (IsInvalid(row_clicked)) return null;
    const { Folder } = row_clicked;
    if (IsInvalid(Folder)) return null;
    const { link } = Folder;
    if (IsInvalid(link)) return null;
    const { code } = link;
    if (IsInvalid(code) || code === '') return null;
    return this.getAddress(`shared/folder/${code}`);
  };
  getAddress = text => {
    let text_link = `${HTTP_ADDRESS_WEB}/${text}`;
    let page = getParamsUrl(this.props);
    if (page) {
      let real_url = window.location.href;
      real_url = real_url.replace(page, '');
      if (HTTP_ADDRESS_WEB !== real_url) {
        text_link = `${real_url}/${text}`;
      }
    }
    return text_link;
  };
  render() {
    const { selected, row_clicked } = this.props;
    let idFolder = null;
    let text_link = null;
    let IconLink = <LinkOffIcon style={{ color: 'gray' }} />;
    let IconOpen = <OpenInNewOffIcon style={{ color: 'gray' }} />;
    console.log({ row_clicked });
    if (row_clicked) {
      let { FolderContent, Folder } = row_clicked;
      if (FolderContent) {
        if (FolderContent.idFolder) idFolder = FolderContent.idFolder;
      } else if (Folder) idFolder = Folder.idFolder;
      text_link = this.getLink(row_clicked);
      if (text_link) {
        IconLink = <LinkIcon style={{ color: '#9c27b0' }} />;
      }
    }
    if (idFolder) IconOpen = <OpenInNewIcon style={{ color: 'green' }} />;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="center">
              <Button
                disabled={!idFolder}
                onClick={() => {
                  if (idFolder)
                    window.open(
                      this.getAddress(`explorer/folder/${idFolder}`),
                      '_blank'
                    );
                }}>
                {IconOpen}
              </Button>
              <Button
                disabled={!text_link}
                onClick={() => {
                  if (text_link) window.open(text_link, '_blank');
                }}>
                {IconLink}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Delete Activity"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the activity, this action cannot be undone"
          title_text="Delete Selected Activities"
        />
      </React.Fragment>
    );
  }
}

ToolbarActivity.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ToolbarActivity);
