const useStyles = theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  tableWrapper: {
    width: '100%',
    maxHeight: 405,
    overflow: 'auto',
  },
  nameContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    // paddingBottom: -theme.spacing(1)
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
});

export default useStyles;
