import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemText, Typography } from '@mui/material';
import { FormatBytes } from 'helpers';
import {
  DownloadLink,
  SharedLink,
} from 'views/Explorer/components/ExplorerDetails/components/components';

class FileDetailContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { folder_content, user } = this.props;
    const { idFolderContent, Owner, File } = folder_content;
    let OwnerName = '';
    let owner = false;
    if (Owner && Owner.FullName) {
      OwnerName = Owner.FullName;
      owner = user.id === Owner.id;
    }
    let ComponentSharedLink = null;
    let ComponentDownloadLink = null;
    if (this.props.base === 'Home') {
      ComponentSharedLink = (
        <SharedLink
          folder_content={folder_content}
          handleError={this.props.handleError}
          handleGetSharedLink={this.props.handleGetSharedLink}
          handleSharedLinkChanged={this.props.handleSharedLinkChanged}
          isOwner={owner}
          user={user}
        />
      );
      ComponentDownloadLink = (
        <DownloadLink
          folder_content={folder_content}
          handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
          handleError={this.props.handleError}
          handleGetDownloadLink={this.props.handleGetDownloadLink}
          isOwner={owner}
          user={user}
        />
      );
    }
    return (
      <React.Fragment>
        <Grid alignItems="center" container>
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              {`Settings (ID: ${idFolderContent})`}
            </Typography>
          </Grid>
        </Grid>
        <ListItemText
          primary={
            <Typography gutterBottom variant="h6">
              Owner
            </Typography>
          }
          secondary={OwnerName}
        />
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={1}>
          <Grid item>
            <Typography
              gutterBottom
              style={{ fontWeight: 1000, color: 'black' }}
              variant="body2">
              Size
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2">
              {FormatBytes(File.Size)}
            </Typography>
          </Grid>
        </Grid>
        {ComponentSharedLink}
        {ComponentDownloadLink}
        <ListItemText
          primary={
            <Typography
              gutterBottom
              style={{ textTransform: 'none', fontSize: 15 }}
              variant="h6">
              Permissions
            </Typography>
          }
        />
      </React.Fragment>
    );
  }
}

FileDetailContent.propTypes = {
  classes: PropTypes.object,
};

export default FileDetailContent;
