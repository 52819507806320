import { grey } from '@mui/material/colors';

export const useStyles = theme => ({
  root: {
    width: 'calc(100% - 1px)',
    // width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    paddingRight: 2,
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  max_height: {
    maxHeight: '100%',
  },
  nameContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    // paddingBottom: -theme.spacing(1)
  },
  table_row: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'yellow',
      },
    },
  },
});
export const useStylesAdministrator = theme => ({
  tab_main: {
    width: '100%',
    height: 'calc(100% - 49px)',
  },
  tab_content: {
    width: '100%',
    minHeight: '100%',
    // paddingBottom: 5,
    overflowY: 'scroll',
  },
  tab_panel: {
    height: '100%',
    margin: theme.spacing(1),
    marginRight: 5,
    marginLeft: 3,
    marginBottom: 0,
  },
  tabs_small: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab_small: {
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'none',
  },
  tab_small_trans: {
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
  },
});
