import React from 'react';
import PropTypes from 'prop-types';
import { List, ListSubheader } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';
import { CommunicationRow, useStyles } from './components';

class CommunicationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  IsToday = createdAt => {
    return moment(parseInt(createdAt, 10)).isSame(moment(), 'day');
  };
  IsYesterday = createdAt => {
    return moment(parseInt(createdAt, 10)).isSame(
      moment().subtract(1, 'day'),
      'day'
    );
  };

  render() {
    const {
      classes,
      selected,
      receivers,
      handleCommunicationRowSelection,
    } = this.props;
    const today = [];
    const yesterday = [];
    const older = [];
    receivers.forEach(receiver => {
      const { direct, channel } = receiver;
      let createdAt = '';
      if (direct) {
        createdAt = direct.createdAt;
      } else if (channel) {
        createdAt = channel.createdAt;
      } else return;
      if (this.IsToday(createdAt)) {
        today.push(receiver);
      } else if (this.IsYesterday(createdAt)) {
        yesterday.push(receiver);
      } else {
        older.push(receiver);
      }
    });
    return (
      <List className={classes.list}>
        {today.length !== 0 && (
          <ListSubheader className={classes.subheader}>Today</ListSubheader>
        )}
        {today.map((row, i) => (
          <CommunicationRow
            handleCommunicationRowSelection={handleCommunicationRowSelection}
            key={`chat-row-${i}`}
            receiver={row}
            selected={selected}
          />
        ))}
        {yesterday.length !== 0 && (
          <ListSubheader className={classes.subheader}>Yesterday</ListSubheader>
        )}
        {yesterday.map((row, i) => (
          <CommunicationRow
            handleCommunicationRowSelection={handleCommunicationRowSelection}
            key={`chat-row-${i + today.length}`}
            receiver={row}
            selected={selected}
          />
        ))}
        {older.length !== 0 && (
          <ListSubheader className={classes.subheader}>Older</ListSubheader>
        )}
        {older.map((row, i) => (
          <CommunicationRow
            handleCommunicationRowSelection={handleCommunicationRowSelection}
            key={`chat-row-${i + today.length + yesterday.length}`}
            receiver={row}
            selected={selected}
          />
        ))}
      </List>
    );
  }
}

CommunicationList.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(CommunicationList, useStyles);
