import React from 'react';
import PropTypes from 'prop-types';
import { Box, TableCell, TableRow } from '@mui/material';

class SeriesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { TagName, TagValue } = this.props;
    return (
      <TableRow style={{ margin: 0, padding: 0 }}>
        <TableCell
          style={{ margin: 0, padding: 0, width: 300, paddingRight: 5 }}>
          <Box fontSize={10} fontWeight="fontWeightBold">
            {TagName}
          </Box>
        </TableCell>
        <TableCell style={{ margin: 0, padding: 0, width: 400 }}>
          <Box fontSize={10} fontWeight="normal">
            {TagValue}
          </Box>
        </TableCell>
      </TableRow>
    );
  }
}

SeriesRow.propTypes = {
  classes: PropTypes.object,
};

export default SeriesRow;
