import { LAYOUT_SET, LOGOUT_USER_LAYAOUT } from '../actions/types';

const initialState = {
  isDesktop: true,
  isMobile: false,
  isTablet: false,
  isMobileSmall: false,
};

const reducer_layout = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER_LAYAOUT:
      return initialState;
    case LAYOUT_SET:
      return {
        ...state,
        isDesktop: action.isDesktop,
        isTablet: action.isTablet,
        isMobile: action.isMobile,
        isMobileSmall: action.isMobileSmall,
      };
    default:
      return state;
  }
};
export default reducer_layout;
