import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import palette from 'theme/palette';

const useStyles = theme => ({
  root: {
    // height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '280px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 370,
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
});

class Devices extends Component {
  render() {
    const { data, classes, devices } = this.props;
    const options = {
      plugins: {
        legend: {
          display: false,
          // position: 'top' as const,
        },
        // title: {
        //   display: true,
        //   text: 'Chart.js Bar Chart',
        // },
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      cutoutPercentage: 80,
      layout: { padding: 0 },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: palette.divider,
        backgroundColor: palette.white,
        titleFontColor: palette.text.primary,
        bodyFontColor: palette.text.secondary,
        footerFontColor: palette.text.secondary,
      },
    };
    return (
      <div style={{ maxHeight: 400 }}>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div className={classes.device} key={device.title}>
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h2">
                {device.value}%
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Devices.propTypes = {
  classes: PropTypes.object,
};
Devices.defaultProps = {
  data: {},
  devices: [],
};

export default withStyles(Devices, useStyles);
