export const getTransformedObject = props => {
  const { currentCase, project, columns } = props;

  const tables = [];

  for (const key in columns) {
    // eslint-disable-next-line no-prototype-builtins
    if (columns.hasOwnProperty(key)) {
      const title =
        project.charAt(0).toUpperCase() +
        project.slice(1) +
        ' ' +
        key.charAt(0).toUpperCase() +
        key.slice(1);
      tables.push({
        columns: columns[key],
        object: key === 'cases' ? currentCase : currentCase[key],
        title: title,
      });
    }
  }
  return { tables, files: currentCase.files };
  //files = currentCase.files;
};
