import React from 'react';
import PropTypes from 'prop-types';
import { columns } from './Styles';
import { TableGrid } from 'components';
const header_to_edit = [];
class TableSystemDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { edit } = this.props;
    if (edit) {
      for (let i = 0; i < header_to_edit.length; i++) {
        const index = columns.map(x => x.field).indexOf(header_to_edit[i]);
        if (index !== -1) {
          columns[index].edit = true;
        }
      }
    }
    // console.log({ rows: this.props.rows });
    return <TableGrid {...this.props} headers={columns} />;
  }
}

TableSystemDockers.propTypes = {
  checkboxSelection: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  edit: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowClicked: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectedChanged: PropTypes.func,
  pageSize: PropTypes.number,
  rows: PropTypes.array,
};
TableSystemDockers.defaultProps = {
  checkboxSelection: false,
  disableSelectionOnClick: false,
  onChangePage: () => '',
  onChangeRowsPerPage: () => '',
  onPageChange: () => '',
  onRowClicked: () => '',
  onRowsPerPageChange: () => '',
  onSelectedChanged: () => '',
  onRowEditedChanged: () => '',
  pageSize: 5,
  rows: [],
  edit: false,
};

export default TableSystemDockers;
