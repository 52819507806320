import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Checkbox, Button } from '@mui/material';

class TableSurveyRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { row, isItemSelected, labelId } = this.props;
    return (
      <TableRow
        aria-checked={isItemSelected}
        hover
        role="checkbox"
        selected={isItemSelected}
        tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            color="primary"
            inputProps={{
              'aria-labelledby': labelId,
            }}
            onClick={event => this.props.handleClick(event, row)}
          />
        </TableCell>
        <TableCell component="th" id={labelId} padding="none" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="left">{row.GivenName}</TableCell>
        <TableCell align="left">{row.FamilyName}</TableCell>
        <TableCell align="right">{row.PatientID}</TableCell>
        <TableCell align="right">{row.PatientBirthDate}</TableCell>
        <TableCell align="right">{row.number_surveys}</TableCell>
        <TableCell align="right">
          {row.patient_info ? 'Created' : 'Missing'}
        </TableCell>
        <TableCell align="right">
          {row.info_consent ? 'Created' : 'Missing'}
        </TableCell>
        <TableCell align="right">
          {row.info_center ? 'Created' : 'Missing'}
        </TableCell>
        <TableCell align="right">
          <Button
            onClick={() => this.props.handleOpenPatient(row)}
            variant="outlined">
            View
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

TableSurveyRow.propTypes = {
  classes: PropTypes.object,
};

export default TableSurveyRow;
