import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Add as AddIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import { ButtonDownloadType, ButtonFileUpload, BadgeCustom } from 'components';
import { styleToolbar } from 'common';
class ToolBarMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      selected,
      uploading,
      disabled,
      type,
      enable_upload,
      folder_contents,
    } = this.props;
    let disable_type = true;
    if (type) {
      disable_type = false;
    }
    return (
      <Paper className={classes.paper}>
        <div>
          <ButtonGroup
            className={classes.root}
            disabled={disabled}
            exclusive="false"
            size="small"
            value="center">
            <Button
              disabled={!folder_contents.length}
              onClick={this.props.handleOpenCreate}>
              <AddIcon
                style={{ color: folder_contents.length ? 'blue' : 'gray' }}
              />
            </Button>
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}
              sx={{ paddingTop: selected.length ? 0 : '7px' }}>
              <BadgeCustom badgeContent={selected.length}>
                <FileCopyIcon
                  style={{ color: selected.length ? 'red' : 'gray' }}
                />
              </BadgeCustom>
            </Button>
            <ButtonDownloadType
              disabled={disable_type}
              key="variable"
              type={type}
            />
            <ButtonFileUpload
              disabled={!enable_upload}
              handleSelectFile={this.props.handleUpload}
              uploading={uploading}
            />
          </ButtonGroup>
        </div>
      </Paper>
    );
  }
}

ToolBarMapping.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
ToolBarMapping.defaultProps = {
  selected: [],
  folder_contents: [],
  uploading: false,
  disabled: false,
  enable_upload: false,
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  handleUpload: () => '',
};

export default withStyles(ToolBarMapping, styleToolbar);
