import { IsInvalid } from 'helpers/utils';
export const IsNotChangeInternally = (props, check_base = true) => {
  const { HomeFolder, LocalHomeFolder, base } = props;
  if (IsInvalid(LocalHomeFolder)) return false;
  if (HomeFolder) {
    const total_sub = HomeFolder.SubFolders.length;
    if (LocalHomeFolder) {
      const sub_redux = LocalHomeFolder.SubFolders.length;
      if (total_sub === sub_redux) return true;
      else return false;
    } else {
      return false;
    }
  }
  if (check_base) {
    const { name } = LocalHomeFolder;
    if (base === 'Shared' && name === 'Shared') return true;
    if (base === 'Home' && name === 'Home') return true;
  }

  return false;
};

export const IsLocalHomeModified = (action_folder, LocalHomeFolder) => {
  if (IsInvalid(LocalHomeFolder)) return false;
  if (IsInvalid(action_folder)) return false;
  const { idFolder, idParent } = action_folder;
  if (IsInvalid(idParent)) return false;
  if (idParent === LocalHomeFolder.idFolder) return true;
  if (IsInvalid(idFolder)) return false;
  if (IsSubFolderInFolder(LocalHomeFolder, idFolder)) {
    return true;
  }
  if (IsSubFolderInFolder(LocalHomeFolder, idParent)) {
    return true;
  }
  return false;
};

export const removeFolderById = (arr, targetId) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].idFolder === targetId) {
      arr.splice(i, 1);
      return true; // Item found and removed
    }

    if (arr[i].SubFolders && arr[i].SubFolders.length > 0) {
      if (removeFolderById(arr[i].SubFolders, targetId)) {
        return true; // Item found and removed in nested array
      }
    }
  }
  return false; // Item not found
};
export const RemoveSubFolderAtFolder = (folder, del_folder) => {
  if (IsInvalid(del_folder)) return false;
  if (IsInvalid(folder)) return false;
  const { idFolder: idFolderInput } = folder;
  if (IsInvalid(idFolderInput)) return false;
  const { idParent, idFolder } = del_folder;
  if (IsInvalid(idParent)) return false;
  if (idFolderInput === idParent) {
    if (IsInvalid(folder.SubFolders)) {
      folder.total_sub_folders = folder.total_sub_folders - 1;
      if (folder.total_sub_folders < 0) folder.total_sub_folders = 0;
      return true;
    }
    const index = folder.SubFolders.map(x => x.idFolder).indexOf(idFolder);
    if (index !== -1) {
      folder.SubFolders.splice(index, 1);
      folder.total_sub_folders = folder.SubFolders.length;
      return true; // Item found and removed
    }
  }
  if (IsInvalid(folder.SubFolders)) return false;
  for (let i = 0; i < folder.SubFolders.length; i++) {
    if (folder.SubFolders[i].idFolder === idParent) {
      if (IsInvalid(folder.SubFolders[i].SubFolders)) {
        folder.SubFolders[i].total_sub_folders =
          folder.SubFolders[i].total_sub_folders - 1;
        if (folder.SubFolders[i].total_sub_folders < 0)
          folder.SubFolders[i].total_sub_folders = 0;
        return true;
      }
      const index = folder.SubFolders[i].SubFolders.map(
        x => x.idFolder
      ).indexOf(idFolder);
      if (index !== -1) {
        folder.SubFolders[i].SubFolders.splice(index, 1);
        folder.SubFolders[i].total_sub_folders =
          folder.SubFolders[i].SubFolders.length;
        return true; // Item found and removed
      }
    }
    if (IsInvalid(folder.SubFolders[i].SubFolders)) {
      continue;
    }
    for (let j = 0; j < folder.SubFolders[i].SubFolders.length; j++) {
      if (
        RemoveSubFolderAtFolder(folder.SubFolders[i].SubFolders[j], del_folder)
      ) {
        return true; // Item found and removed in nested array
      }
    }
  }
  return false; // Item not found
};
export const IsSubFolderInFolder = (folder, targetId) => {
  if (IsInvalid(folder)) return false;
  const { SubFolders } = folder;
  if (IsInvalid(SubFolders)) return false;
  for (let i = 0; i < SubFolders.length; i++) {
    if (SubFolders[i].idFolder === targetId) {
      return true; // Item found and removed
    }
    if (IsInvalid(SubFolders[i].SubFolders)) continue;
    for (let j = 0; j < SubFolders[i].SubFolders.length; j++) {
      const sub_folder = SubFolders[i].SubFolders[j];
      if (IsSubFolderInFolder(sub_folder, targetId)) {
        return true; // Item found and removed in nested array
      }
    }
  }
  return false; // Item not found
};
export const addFolderToParent = (folder, new_folder) => {
  if (IsInvalid(new_folder)) return false;
  if (IsInvalid(folder)) return false;
  const { idFolder: idFolderInput } = folder;
  if (IsInvalid(idFolderInput)) return false;
  const { idParent, idFolder } = new_folder;
  if (IsInvalid(idParent)) return false;
  if (idFolderInput === idParent) {
    if (IsInvalid(folder.SubFolders)) {
      folder.total_sub_folders = folder.total_sub_folders + 1;
      return true;
    }
    const index = folder.SubFolders.map(x => x.idFolder).indexOf(idFolder);
    if (index === -1) {
      folder.SubFolders.push(new_folder);
      folder.total_sub_folders = folder.SubFolders.length;
      return true; // Item found and removed
    }
  }
  if (IsInvalid(folder.SubFolders)) return false;
  for (let i = 0; i < folder.SubFolders.length; i++) {
    if (folder.SubFolders[i].idFolder === idParent) {
      if (IsInvalid(folder.SubFolders[i].SubFolders)) {
        // console.log('Invalid - SubFolders');
        folder.SubFolders[i].total_sub_folders =
          folder.SubFolders[i].total_sub_folders + 1;
        return true;
      }
      const index = folder.SubFolders[i].SubFolders.map(
        x => x.idFolder
      ).indexOf(idFolder);
      if (index === -1) {
        folder.SubFolders[i].SubFolders.push(new_folder);
        folder.SubFolders[i].total_sub_folders =
          folder.SubFolders[i].SubFolders.length;
        return true; // Item found and removed
      }
    }
    if (IsInvalid(folder.SubFolders[i].SubFolders)) {
      // console.log('Invalid - SubFolders  - Skip');
      continue;
    }
    for (let j = 0; j < folder.SubFolders[i].SubFolders.length; j++) {
      if (addFolderToParent(folder.SubFolders[i].SubFolders[j], new_folder)) {
        return true; // Item found and removed in nested array
      }
    }
  }
  return false; // Item not found
};
