/* eslint-disable quotes */
import { IsInvalid, FormatTime, IsInvalidParam } from 'helpers';

export { default } from './TableFullTypes';

export const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'project',
    headerName: 'Project',
    width: 250,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let projects = params.getValue(params.id, 'projects');
      if (!projects || projects === undefined)
        return "Doesn't belong to any project";
      return projects.map(x => x.name).join(', ');
    },
  },
  {
    field: 'mapping_type',
    headerName: 'Mapping Type',
    width: 250,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let map_file = params.getValue(params.id, 'map_file');
      let map_series = params.getValue(params.id, 'map_series');
      let map_sequence = params.getValue(params.id, 'map_sequence');
      if (map_file) return 'File Type Mapping';
      if (map_series) return 'Variable Type Series Mapping';
      if (map_sequence) return 'Series Sequence Mapping';
      return 'Missing';
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 150,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let map_file = params.getValue(params.id, 'map_file');
      let map_series = params.getValue(params.id, 'map_series');
      let map_sequence = params.getValue(params.id, 'map_sequence');
      if (map_file) return map_file.type.name;
      if (map_series) return map_series.type.name;
      if (map_sequence) return map_sequence.Sequence.name;
      return 'Missing';
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    align: 'left',
    width: 160,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let map_file = params.getValue(params.id, 'map_file');
      let map_series = params.getValue(params.id, 'map_series');
      let map_sequence = params.getValue(params.id, 'map_sequence');
      if (map_file) return map_file.name;
      if (map_series) return map_series.name;
      if (map_sequence) return '';
      return '';
    },
  },
  {
    field: 'files',
    headerName: '#Files',
    editable: false,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let map_file = params.getValue(params.id, 'map_file');
      let map_series = params.getValue(params.id, 'map_series');
      let map_sequence = params.getValue(params.id, 'map_sequence');
      if (map_file) return 1;
      if (map_series) return map_series.series.NumberOfSeriesRelatedInstances;
      if (map_sequence)
        return map_sequence.Series.NumberOfSeriesRelatedInstances;
      return 0;
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    width: 210,
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let updatedAt = params.getValue(params.id, 'updatedAt');
      if (updatedAt === undefined || !updatedAt) return '';
      return FormatTime(updatedAt, 'calendar');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: 210,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      let createdAt = params.getValue(params.id, 'createdAt');
      if (IsInvalid(createdAt)) return '';
      return FormatTime(createdAt, 'calendar');
    },
  },
];

export const rows = [
  {
    id: 1,
    project: { id: 1, name: 'TRIAL ELAN' },
    mapping_type: 'Series Type Mapping',
    type: 'SSR',
    description: 'SSR-012-234',
    files: 35,
    updatedAt: '1650527032000',
    createdAt: '1650527032000',
  },
  {
    id: 2,
    mapping_type: 'File Type Mapping',
    type: 'EEG',
    description: 'Patient_10_EEG.edf',
    files: 1,
  },
  {
    id: 3,
    mapping_type: 'Series Sequence Mapping',
    type: 'Series',
    description: 'flair_space_sag_iso_1mm_MPR_tra',
    files: 45,
  },
];
