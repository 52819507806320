import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const useStyles = () => ({
  root: {
    height: '30px',
    padding: 0,
  },
});

class ButtonViewType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
    };
  }

  handleChange = (event, view) => {
    this.setState({ view });
    this.props.handleViewType(view === 'list' ? 0 : 1);
  };

  render() {
    const { classes } = this.props;
    const { view } = this.state;
    return (
      <ToggleButtonGroup
        className={classes.root}
        exclusive
        onChange={this.handleChange}
        value={view}>
        <ToggleButton
          aria-label="list"
          style={{ margin: 0, padding: 0, paddingLeft: 10, paddingRight: 10 }}
          value="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton
          aria-label="module"
          style={{ margin: 0, padding: 0, paddingLeft: 10, paddingRight: 10 }}
          value="module">
          <ViewModuleIcon />
        </ToggleButton>
        {/* <ToggleButton value="quilt" aria-label="quilt">
        <ViewQuiltIcon />
      </ToggleButton> */}
      </ToggleButtonGroup>
    );
  }
}

ButtonViewType.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ButtonViewType, useStyles);
