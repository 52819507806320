import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 5,
    type: 'number',
  },
  {
    id: 'Sequence',
    label: 'Sequence',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Sequence is Missing';
      const { name } = value;
      if (name) return name;
      return 'Sequence is Missing';
    },
  },
  {
    id: 'idSeries',
    label: 'ID Series',
    minWidth: 25,
    maxWidth: 80,
    type: 'number',
  },
  {
    id: 'Series',
    label: 'Type',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Series is Missing';
      const { ImageMr } = value;
      if (ImageMr === undefined || !ImageMr) return '';
      const { MRAcquisitionType } = ImageMr;
      if (MRAcquisitionType === undefined || !MRAcquisitionType) return '';
      return MRAcquisitionType;
    },
  },
  {
    id: 'Series',
    label: 'SeriesDescription',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Series is Missing';
      const { SeriesDescription } = value;
      if (SeriesDescription) return SeriesDescription;
      return 'Series is Missing';
    },
  },
  {
    id: 'Series',
    label: 'ProtocolName',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Series is Missing';
      const { ImageMr } = value;
      if (ImageMr === undefined || !ImageMr) return '';
      const { ProtocolName } = ImageMr;
      if (ProtocolName === undefined || !ProtocolName) return '';
      return ProtocolName;
    },
  },
  {
    id: 'Series',
    label: 'SequenceName',
    align: 'left',
    format: value => {
      if (value === undefined || !value) return 'Series is Missing';
      const { ImageMr } = value;
      if (ImageMr === undefined || !ImageMr) return '';
      const { SequenceName } = ImageMr;
      if (SequenceName === undefined || !SequenceName) return '';
      return SequenceName;
    },
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
