import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import { v1 as uuid } from 'uuid';
class DataSignalTabBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tabs, tab_value, onTabClicked } = this.props;
    return (
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: 200, sm: '100%' },
          bgcolor: 'background.paper',
        }}>
        <Tabs
          aria-label="visible arrows tabs example"
          onChange={(event, value) => onTabClicked(value)}
          scrollButtons
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
          value={tab_value}
          variant="scrollable">
          {tabs.map((tab, index) => {
            return <Tab key={`tab-${index}-${uuid()}`} label={tab} />;
          })}
        </Tabs>
      </Box>
    );
  }
}

DataSignalTabBar.propTypes = {
  onTabClicked: PropTypes.func,
  tab_value: PropTypes.number,
  tabs: PropTypes.array,
};
DataSignalTabBar.defaultProps = {
  tabs: [],
  tab_value: 0,
  onTabClicked: () => '',
};

export default DataSignalTabBar;
