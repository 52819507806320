import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { QueryFilesUsers } from 'graphql/Folders';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';

const SearchFilesUsers = props => {
  const { style, selected, holder } = props;
  const classes = useMakeStyles();

  const [name, setCenterName] = React.useState(selected ? selected.name : '');
  const [center, setCenter] = React.useState(selected ? selected : {});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(selected ? [selected] : []);
  const loading = open && options.length === 0;
  if (holder) {
    holder.resetUser = () => {
      setCenterName('');
      setCenter({});
    };
  }
  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      QueryFilesUsers(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users } = data.fileUsers;
          // console.log({ project_centers });
          let centers = [];
          if (ok) {
            if (users.length === 0) {
              centers.push({ name: 'Empty' });
            } else {
              centers = users.map(x => {
                return {
                  ...x,
                  name: x.FullName,
                };
              });
            }
          } else {
            throw errors;
          }
          setOptions(centers);
        })
        .catch(error => {
          setOptions([]);
          props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  const handelCenterNameChanged = event => {
    const name = event.target.value;
    //
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      //
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setCenterName(name);
  };
  const handleAddSeletedItem = center => {
    setCenter(center);
    props.handleAddSeletedItem(center);
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        handelSearchNameChanged={handelCenterNameChanged}
        handleAddResultObject={handleAddSeletedItem}
        label="File user"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={center}
        setObject={handleAddSeletedItem}
        setObjectName={setCenterName}
        setOpen={setOpen}
        setOptions={setOptions}
        small={props.small}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        handelSearchNameChanged={handelCenterNameChanged}
        handleAddResultObject={handleAddSeletedItem}
        label="File user"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={center}
        setObject={handleAddSeletedItem}
        setObjectName={setCenterName}
        setOpen={setOpen}
        setOptions={setOptions}
        small={props.small}
        variant="standard"
      />
    </Paper>
  );
};

SearchFilesUsers.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchFilesUsers.defaultProps = {
  placeholder: 'Search file users...',
  no_icon: false,
  handleAddSeletedItem: () => '',
};

export default SearchFilesUsers;
