import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from 'components';
import { getLevels, getTabLabel } from 'helpers';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { TabDownloads, TabShared, TabUpload } from './components';
const useStyles = () => ({
  root: {},
  tabs: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'none',
  },
});
class TabData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      error: '',
      tabs: [
        { name: 'Download', TabComponent: TabDownloads },
        { name: 'Upload', TabComponent: TabUpload },
        { name: 'Shared', TabComponent: TabShared },
      ],
    };
  }
  componentDidMount() {
    const { level_2 } = getLevels(this.props);
    let tabValue = 0;
    const index = this.state.tabs
      .map(x => getTabLabel(x.name))
      .indexOf(level_2);
    if (index >= 0 && index < this.state.tabs.length) {
      tabValue = index;
    }
    this.setState({ tabValue });
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > this.state.tabs.length) {
      newValue = 0;
    }
    const tab = this.state.tabs[newValue].name;
    this.setState({ tabValue: newValue });
    this.props.history.push(`/administrator/data/${getTabLabel(tab)}`);
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, user, height } = this.props;
    const internal_height = height - 200;
    const { tabValue, tabs } = this.state;
    return (
      <div style={{ margin: 10 }}>
        <Paper square>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  className={classes.tab}
                  key={`tab-data-${index}`}
                  label={tab.name}
                />
              );
            })}
          </Tabs>
        </Paper>
        <Divider />
        <div style={{ width: '100%', height: internal_height }}>
          <div className={classes.tab_content}>
            {tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`tab-data-panel-${index}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    height={internal_height}
                    history={this.props.history}
                    isActive={this.props.isActive}
                    match={this.props.match}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

TabData.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(TabData), useStyles);
