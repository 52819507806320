/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { RowText } from 'components/Texts';
import { IsValid, IsInvalid, FormatTime } from 'helpers';

class ClinicalPatientViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = value => {
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  renderPatientData = (column, patient) => {
    if (
      (column.type === 'date' || column.type === 'datetime') &&
      patient[column.field]
    ) {
      /*  console.log('***\n**\n*', {
        headerName: column.headerName,
        type: column.type,
      }); */
      return (
        <RowText
          display="block ruby"
          first={`${column.headerName}: `}
          key={column.field}
          second={this.formatDate(patient[column.field])}
          styleBox={{ fontWeight: 1000 }}
        />
      );
    } else if (column.type === 'int' && patient[column.field] > 0) {
      return (
        <RowText
          display="block ruby"
          first={`${column.headerName}: `}
          key={column.field}
          second={this.getValue(patient[column.field])}
          styleBox={{ fontWeight: 1000 }}
        />
      );
    } else if (
      column.type != 'int' &&
      column.type !== 'date' &&
      IsValid(patient[column.field])
    ) {
      return (
        <RowText
          display="block ruby"
          first={`${column.headerName}: `}
          key={column.field}
          second={this.getValue(patient[column.field])}
          styleBox={{ fontWeight: 1000 }}
        />
      );
    }
  };

  render() {
    const { currentCase, columns } = this.props;
    const patient = currentCase.patient;

    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          Clinical Patient
        </Typography>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            {columns.map(col => this.renderPatientData(col, patient))}
            {/*  {columns.map((col, index) =>
              col.type === 'date' || col.type === 'datetime'
                ? patient[col.field] && (
                    <RowText
                      display="block ruby"
                      first={`${col.headerName}: `}
                      key={col.field}
                      second={this.formatDate(patient[col.field])}
                      styleBox={{ fontWeight: 1000 }}
                    />
                  )
                : patient[col.field] && patient[col.field] && (
                    <RowText
                      display="block ruby"
                      first={`${col.headerName}: `}
                      key={col.field}
                      second={this.getValue(patient[col.field])}
                      styleBox={{ fontWeight: 1000 }}
                    />
                  )
            )} */}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

ClinicalPatientViewer.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalPatientViewer;
