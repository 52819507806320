import { short_patients } from '../../PatientClinics/components';

export const cases = [
  {
    field: 'case_identifier',
    headerName: 'Case ID',
    example: 'EL001',
    width: 75,
    edit: false,
    align: 'left',
    required: true,
  },
  {
    field: 'date',
    headerName: 'Date',
    example: '2019-12-09',
    align: 'left',
    width: 157,
    type: 'date',
    edit: true,
  },
  {
    field: 'first_seizure_date',
    headerName: 'First seizure date',
    example: '2008-12-15',
    align: 'left',
    width: 157,
    edit: true,
    type: 'date',
  },
  {
    field: 'patient_age',
    headerName: 'Patient age',
    example: '26',
    width: 75,
    edit: true,
    type: 'int',
    align: 'left',
    editerGetter: (row, value) => {
      row['patient_age'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'neurologist',
    headerName: 'Neurologist',
    example: 'J.Doe',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'assistant',
    headerName: 'Assistant',
    example: 'J.Doe',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'date_pre_implantation_mr',
    headerName: 'Date pre-implantation MR',
    example: '2018-08-14 09:03:07',
    align: 'left',
    edit: true,
    width: 222,
    type: 'datetime',
  },
  {
    field: 'date_post_implantation_ct',
    headerName: 'Date post-implantation CT',
    example: '2019-12-10 09:29:11',
    align: 'left',
    edit: true,
    width: 222,
    type: 'datetime',
  },
  {
    field: 'comment',
    headerName: 'Comment',
    example: 'Generic comment',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'laterality',
    headerName: 'Laterality',
    example: 'R',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'scalp',
    headerName: 'Scalp',
    example: 'Boolean',
    align: 'left',
    width: 75,
    edit: true,
    type: 'boolean',
  },
  {
    field: 'subscalp',
    headerName: 'Subscalp',
    example: 'no',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'grid',
    headerName: 'Grid',
    example: 'Boolean',
    align: 'left',
    edit: true,
    type: 'boolean',
    width: 75,
  },
  {
    field: 'strip',
    headerName: 'Strip',
    example: 'Boolean',
    align: 'left',
    edit: true,
    type: 'boolean',
    width: 75,
  },
  {
    field: 'depth',
    headerName: 'Depth',
    example: '4right',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'pial_reconstruction',
    headerName: 'PIAL reconstruction',
    example: 'Boolean',
    align: 'left',
    edit: true,
    type: 'boolean',
    width: 75,
  },
  {
    field: 'normalized_to_mni',
    headerName: 'Normalized to MNI',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'ct_registered_to_mri',
    headerName: 'CT registered to MRI',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'electrodes_labelled',
    headerName: 'Electrodes labelled',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'electrodes_located_mr',
    headerName: 'Electrodes located on MRI',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'covered_region',
    headerName: 'Covered region',
    example:
      'Basotemporal; Laterotemporal; Mesiotemporal; WM; OUT; Parietal; Superotemporal',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    example: '0',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];

export const research_study = [
  {
    field: 'idEpilepsyCase',
    headerName: 'ID Epilepsy Case',
    example: '21',
    width: 75,
    edit: true,
    align: 'left',
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idEpilepsyCase'] = parseInt(value);
      return row;
    },
    derived: true,
  },
  {
    field: 'brain_map',
    headerName: 'Brain map',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'input_output',
    headerName: 'Input/Output',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'circadian',
    headerName: 'Circadian',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'pharmaco',
    headerName: 'Pharmaco',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'audio_sleep',
    headerName: 'Audio sleep',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'music_start_date',
    headerName: 'Music start',
    example: '12/15/2022 16:05',
    align: 'left',
    edit: true,
    width: 200,
  },
  {
    field: 'music_end_date',
    headerName: 'Music end',
    example: '2/15/2022 16:38',
    align: 'left',
    edit: true,
    width: 200,
  },
  {
    field: 'epi_os',
    headerName: 'EpiOs',
    example: 'Boolean',
    align: 'left',
    edit: true,
    width: 75,
    type: 'boolean',
  },
  {
    field: 'spec_ictal',
    headerName: 'SpecIctal',
    example: '24/2/2022',
    align: 'left',
    width: 157,
    edit: true,
    type: 'date',
  },
  {
    field: 'comment',
    headerName: 'Comment',
    example: 'stimulations were not sent',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
];

export const resection = [
  {
    field: 'idEpilepsyCase',
    headerName: 'ID Epilepsy Case',
    example: '21',
    width: 75,
    edit: true,
    align: 'left',
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idEpilepsyCase'] = parseInt(value);
      return row;
    },
    derived: true,
  },
  {
    field: 'date',
    headerName: 'Date',
    example: '2020-07-06',
    align: 'left',
    width: 157,
    edit: true,
    type: 'date',
  },
  {
    field: 'patient_age',
    headerName: 'Patient age',
    example: '26',
    width: 75,
    type: 'int',
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['patient_age'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'op_side',
    headerName: 'OP side',
    example: 'right',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'operation',
    headerName: 'Operation',
    example: 'Lesionectomy',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'details',
    headerName: 'Details',
    example: 'Navigationsgestützte temporobasale Kraniotomie und Läsionektomie',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'complication',
    headerName: 'Complication',
    example: 'no',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'histology',
    headerName: 'Histology',
    example: 'a.e. Gangliogliom, WHO Grad I',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'histology_temporolpol',
    headerName: 'Histo Temporolpol',
    example:
      'Exzisat Läsion in T3; Niedriggradige, verdrängend wachsende gliale/glioneuronale Neoplasie',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'histology_amygdala',
    headerName: 'Histo Amygdala',
    example: 'Fokale Dispersion',
    width: 75,
    edit: true,
    multiline: true,
    align: 'left',
  },
  {
    field: 'histology_hippocampus',
    headerName: 'Histo Hippocampus',
    example:
      'Fokale Dispersion der Fascia dentata, subkortikal betonte Astrogliose und Mikrogliaaktivierung, Nachweis von T-Zellen',
    width: 75,
    edit: true,
    multiline: true,
    align: 'left',
  },
];
export const outcome = [
  {
    field: 'idEpilepsyCase',
    headerName: 'ID Epilepsy Case',
    example: '21',
    width: 75,
    edit: true,
    align: 'left',
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idEpilepsyCase'] = parseInt(value);
      return row;
    },
    derived: true,
  },
  {
    field: 'engel_3m',
    headerName: 'Engel 3m',
    example: '1A',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'engel_1y',
    headerName: 'Engel 1y',
    example: '1A',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'engel_2y',
    headerName: 'Engel 2y',
    example: '1A',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'percentage_aed_1y',
    headerName: '%AED 1y',
    example: '100',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'percentage_aed_2y',
    headerName: '%AED 2y',
    example: 'LCM  0, LEV 60%',
    width: 75,
    edit: true,
    align: 'left',
  },
];

export const getColumnList = () => {
  let columns = null;

  const new_patients = short_patients.map(x => {
    return { key: 'PAT_' + x.field, value: x.headerName, parent: 'Patient' };
  });
  const new_cases = cases.map(x => {
    return { key: 'CAS_' + x.field, value: x.headerName, parent: 'Case' };
  });
  const new_research_studies = research_study.map(x => {
    return {
      key: 'R_STUD_' + x.field,
      value: x.headerName,
      parent: 'Research Study',
    };
  });
  const new_resections = resection.map(x => {
    return { key: 'RES_' + x.field, value: x.headerName, parent: 'Resection' };
  });
  const new_outcomes = outcome.map(x => {
    return { key: 'OUT_' + x.field, value: x.headerName, parent: 'Outcome' };
  });

  columns = new_patients.concat(
    new_cases,
    new_research_studies,
    new_resections,
    new_outcomes
  );
  columns = columns.filter(item => item.value !== 'ID Epilepsy Case');
  return columns;
};
