export const PatientName = value => {
  if (!value) {
    return 'Unknown';
  }
  const { FamilyName, GivenName, MiddleName } = value;
  if (FamilyName !== 'Empty' && GivenName !== 'Empty' && MiddleName !== 'Empty')
    return `${FamilyName}, ${GivenName} ${MiddleName}`;
  if (FamilyName !== 'Empty' && GivenName !== 'Empty')
    return `${FamilyName}, ${GivenName}`;
  if (FamilyName !== 'Empty') return `${FamilyName}`;
  return 'Unknown';
};
export const StudyFiles = value => {
  if (!value) {
    return '0/0';
  }
  const { NumberOfStudyRelatedSeries, NumberOfStudyRelatedInstances } = value;

  return `${NumberOfStudyRelatedSeries}/${NumberOfStudyRelatedInstances}`;
};

export const useRowStyles = theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  header: {
    backgroundColor: '#eaeaea',
  },
  cell_empty: {
    margin: 0,
    padding: 0,
    width: '20px',
  },
  cell_row: {
    margin: theme.spacing(1),
    padding: 0,
  },
  cell_text: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: 0,
    width: '20px',
  },
  cell_expand: {
    marginLeft: theme.spacing(0.1),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: 0,
    width: '20px',
  },
});
