import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, TablePagination } from '@mui/material';
import {
  TableSiteUsers,
  UserView,
} from 'views/Administrator/components/AdminDefault/TabUsers/components';
import { QueryFullAllUsersByCategory } from 'graphql/Users/utils_user_category';
import { ServerErrorsString, GetData, rowsPerPageMedium } from 'helpers';

class TabDesignerUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      loading: false,
      selected: [],
      users: [],
      page: 0,
      limit: 100,
      total: 0,
      edit_user: null,
    };
  }
  componentDidMount() {
    this.updateUsers();
  }
  updateUsers = (
    newName = null,
    newPage = null,
    newLimit = null,
    orderBy = null
  ) => {
    if (newName && newName.constructor !== String) {
      newName = null;
    }
    const { loading } = this.state;
    if (loading) return;
    let { page, limit, name } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName !== null) {
      name = newName;
    }
    this.setState({ loading: true, name });
    (async () => {
      QueryFullAllUsersByCategory(2, null, name, page, limit, orderBy)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users, total } = data.usersByCategory;
          if (ok) {
            this.setState({ loading: false, users, name, page, limit, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleEditUser = user => {
    this.setState({ edit_user: user });
  };
  handleOrderByChanged = (orderBy, order) => {
    this.updateUsers(null, null, null, [`${orderBy}:${order}`]);
  };
  handleDeleteSelected = () => {};

  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.updateUsers(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.updateUsers(null, 0, +event.target.value);
  };
  handleChangeName = event => {
    event.preventDefault();
    this.updateUsers(event.target.value);
  };
  handleUpdateUserSettings = setting => {
    const { users } = this.state;
    const index = users.map(x => x.id).indexOf(setting.idUser);
    if (index !== -1) {
      users[index].account = setting.type;
      this.setState({ users });
    }
  };
  handleUserRequest = (idUser, added) => {
    let { users } = this.state;
    const index = users.map(x => x.id).indexOf(idUser);
    if (index === -1) {
      return;
    }
    if (added) {
      users[index].isActive = false;
    } else {
      users = users.filter(x => x.id !== idUser);
    }
    this.setState({ users, edit_user: false });
  };
  render() {
    const {
      deleting,
      loading,
      users,
      page,
      limit,
      total,
      edit_user,
    } = this.state;
    if (edit_user) {
      return (
        <UserView
          edit_user={edit_user}
          handleAddError={this.props.handleAddError}
          handleOpenEditUser={this.handleEditUser}
          handleUpdateUserSettings={this.handleUpdateUserSettings}
          handleUserRequest={this.handleUserRequest}
        />
      );
    }
    return (
      <Grid>
        <Paper>
          <TableSiteUsers
            deleting={deleting}
            disable_splice
            handleError={this.props.handleAddError}
            handleOpenEditUser={this.handleEditUser}
            handleReloadTable={this.updateUsers}
            height={this.props.height - 320}
            limit={limit}
            loading={loading}
            onOrderByChanged={this.handleOrderByChanged}
            page={page}
            paper={false}
            users={users}
          />
          <TablePagination
            component="div"
            count={total ? total : users.length}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={rowsPerPageMedium}
          />
        </Paper>
      </Grid>
    );
  }
}

TabDesignerUser.propTypes = {
  classes: PropTypes.object,
};

export default TabDesignerUser;
