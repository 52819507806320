import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';

import { IsEditAllow } from 'helpers/Designers';
import {
  ButtonDownloadToggle,
  DialogDeleteWarning,
  DialgoEditMetadata,
} from 'components';
import { SendEmail } from 'graphql/Communications/utils_emails';
class ExplorerImageToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_message: false,
      alignment: 'left',
      open_edit: false,
      emailing: false,
    };
    const { holder } = props;
    if (holder) {
      holder.openEdit = () => this.setState({ open_edit: true });
    }
  }
  handleBackToDashboard = () => {
    this.props.history.push('/dashboard');
  };
  handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      this.setState({ alignment: newAlignment });
    }
    console.log({ newAlignment });
    if (newAlignment === 'edit') {
      this.setState({ open_edit: true });
    } else if (newAlignment === 'download') {
      const { user } = this.props;
      if (user && user.type === 1) {
        /** empty */
      } else {
        this.setState({ open_message: true });
      }
    }
  };

  render() {
    const { user, open_meta, handleDrawerClose, folder_content } = this.props;
    const { emailing } = this.state;
    let EditButtons = null;
    if (IsEditAllow(user)) {
      EditButtons = (
        <ToggleButton aria-label="left aligned" value="edit">
          <Tooltip title="Edit information">
            <BorderColorIcon />
          </Tooltip>
        </ToggleButton>
      );
    }
    console.log({ user });
    let ComponentDownload = null;
    if (user && user.type === 1) {
      ComponentDownload = (
        <ButtonDownloadToggle
          folder_content={folder_content}
          IconDownload={<InstallDesktopIcon />}
          tooltip="Download high-resolution"
        />
      );
    } else {
      ComponentDownload = (
        <ButtonDownloadToggle
          downloading={this.state.emailing}
          IconDownload={<InstallDesktopIcon />}
          onClick={() => ''}
          tooltip="Request high-resolution"
        />
      );
    }
    return (
      <Paper
        square
        style={{
          height: 64,
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 15,
          zIndex: this.state.open_edit ? 0 : 1000,
          position: 'relative',
          display: 'flex',
        }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-start"
            spacing={2}
            xs={8}>
            <Grid item>
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="end"
                onClick={this.handleBackToDashboard}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                aria-label="text alignment"
                exclusive
                onChange={this.handleAlignment}>
                {EditButtons}
                <ToggleButton aria-label="centered" value="share">
                  <ButtonDownloadToggle
                    download_type="thumbnail"
                    folder_content={folder_content}
                    IconDownload={<InstallMobileIcon />}
                    tooltip="Download low-resolution"
                  />
                </ToggleButton>
                <ToggleButton
                  aria-label="right aligned"
                  disabled={emailing}
                  value="download">
                  {ComponentDownload}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="end"
              onClick={handleDrawerClose}
              sx={{ ...(open_meta && { display: 'none' }) }}>
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialgoEditMetadata
          folder_content={this.props.folder_content}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleUpdateMetadata={metadata => {
            this.props.handleUpdateMetadata(metadata);
            this.setState({ open_edit: false });
          }}
          open_edit={this.state.open_edit}
        />
        <DialogDeleteWarning
          button_cancel="No"
          button_text="Yes"
          handleButtonClicked={() =>
            SendEmail(this.props, this.state, state =>
              this.setState({ ...state, open_message: false })
            )
          }
          handleClose={() =>
            this.setState({ open_message: !this.state.open_message })
          }
          loading={this.state.emailing}
          open={this.state.open_message}
          question_text="Would you like to request a high-resolution version of the image?. If yes, we will contact you as soon as possible."
          text_question="Do you want to send the request?"
          title_text="Donwload High-Resolution request"
        />
      </Paper>
    );
  }
}

ExplorerImageToolbar.propTypes = {
  open_meta: PropTypes.bool,
};

const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(mapStateToProps, null)(withRouter(ExplorerImageToolbar));
