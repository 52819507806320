import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import HorizontalLinearStepper from './components/HorizontalLinearStepper';

class DialogDockerMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  handleClose = () => {
    console.log('handleClose');
    this.setState({ loading: false });
    this.props.handleClose();
  };
  render() {
    const { open_dialog, output } = this.props;
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="lg"
          onClose={this.handleClose}
          open={open_dialog}>
          <DialogTitle>Docker Mapping</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <HorizontalLinearStepper
              // docker_setting_id={docker_setting_id}
              handleAddError={this.props.handleAddError}
              handleClose={this.handleClose}
              output={output}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogDockerMapping.propTypes = {
  handleClose: PropTypes.func,
  open_dialog: PropTypes.bool,
};
DialogDockerMapping.defaultProps = {
  handleClose: () => '',
  open_dialog: false,
};

export default DialogDockerMapping;
