import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TabPanel } from 'components';
import { TabUserFiles, TabUserSeries, TabUserToolBar } from './components';
import { QuerySeriesAtUser } from 'graphql/Series';
import { GetData, ServerErrorsString, getParams } from 'helpers';
import { QueryUserFiles } from 'graphql/Folders';
import {
  QueryProjectUsersSeries,
  QueryProjectUsersFiles,
} from 'graphql/Projects';
import { handleResultData, GetIDtoSearch } from './components/utils_query';

const useStyles = () => ({
  root: {
    margin: 0,
    padding: 0,
  },
  tabs: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'none',
  },
});

class TabUserUploads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'users',
      tabValue: 0,
      deleting: false,
      loading: false,
      init: false,
      page: 0,
      limit: 200,
      series: [],
      files: [],
      total_files: 0,
      total_series: 0,
      user: null,
      project: null,
      tabs: [
        { name: 'Series', TabComponent: TabUserSeries },
        { name: 'Files', TabComponent: TabUserFiles },
      ],
    };
    this.holder = {
      getSelected: null,
      clearSelected: null,
      getSearchText: null,
      getType: null,
    };
  }

  componentDidMount() {
    const { level_1, level_2, level_3, level_4, level_5 } = getParams(
      this.props
    );
    let type = 'users';
    let tabValue = 0;
    if (level_1 && level_2) {
      if (level_3 && level_3 === 'projects') type = 'projects';
      if (level_4 && level_4 !== 'series') tabValue = 1;
    }
    console.log({
      level_1,
      level_2,
      level_3,
      level_4,
      level_5,
      type,
      tabValue,
    });
    this.setState({ type, tabValue });
    this.updateUpload();
  }

  componentDidUpdate() {
    if (!this.state.init) {
      this.updateUpload();
    }
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > 1) {
      newValue = 0;
    }
    const { type, tabs } = this.state;
    this.props.history.push(
      `/administrator/data/upload/${type}/${tabs[newValue].name.toLowerCase()}`
    );
    this.updateUpload(null, null, null, null, newValue);
  };
  handleSetUser = user => {
    this.updateUpload(user);
  };
  handleSetProject = project => {
    console.log({ project });
    this.updateUpload(null, project);
  };
  QueryType = (...input) => {
    const type = this.holder.getType();
    let { tabValue } = this.state;
    if (input.length === 5 && input[4] !== null) {
      tabValue = input[4];
    }
    if (tabValue === 0) {
      if (type === 'users') return QuerySeriesAtUser(...input);
      else return QueryProjectUsersSeries(...input);
    } else {
      if (type === 'users') return QueryUserFiles(...input);
      else return QueryProjectUsersFiles(...input);
    }
  };
  handleChangePage = (event, page) => {
    event.preventDefault();
    this.updateUpload(null, null, page, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.updateUpload(null, null, 0, +event.target.value);
  };
  updateUpload = (
    user = null,
    project = null,
    new_page = null,
    new_limit = null,
    new_tab = null
  ) => {
    if (!this.props.isActive) return;
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (new_page !== undefined && new_page >= 0 && new_limit) {
      page = new_page;
      limit = new_limit;
    }
    const { id_to_search, object } = GetIDtoSearch(
      project,
      user,
      page,
      limit,
      this
    );
    if (!object) {
      const { tabValue } = this.state;
      if (new_tab !== null && tabValue !== new_tab) {
        this.setState({ tabValue: new_tab });
      }
      return;
    }
    this.setState({ loading: true });
    (async () => {
      this.QueryType(null, id_to_search, page, limit, new_tab)
        .then(res => {
          const data = GetData(res);
          const type = this.holder.getType();
          handleResultData(type, data, object, page, limit, new_tab, this);
        })
        .catch(error => {
          this.setState({ loading: false, init: true, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleTabType = type => {
    this.props.history.push(
      `/administrator/data/upload/${type}/${this.state.tabs[
        this.state.tabValue
      ].name.toLowerCase()}`
    );
    this.setState({
      type,
      user: null,
      project: null,
      series: [],
      total_files: 0,
      files: [],
      total_series: 0,
    });
  };
  render() {
    const { classes, height } = this.props;
    const { tabValue, user, loading, total_files, total_series } = this.state;
    if (!this.props.isActive) return null;
    const internal_height = height - 210;
    return (
      <div style={{ margin: 0, marginTop: 5 }}>
        <TabUserToolBar
          handleReset={this.handleTabType}
          handleSetProject={this.handleSetProject}
          handleSetUser={this.handleSetUser}
          history={this.props.history}
          holder={this.holder}
          match={this.props.match}
        />
        <Paper square>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((x, index) => (
              <Tab
                className={classes.tab}
                key={`tab-files-upload-tab-${index}`}
                label={x.name}
              />
            ))}
          </Tabs>
          <Divider />
          <div style={{ width: '100%', height: height - 250 }}>
            <div className={classes.tab_content}>
              {this.state.tabs.map((x, index) => {
                const { TabComponent } = x;
                let rows = [];
                if (x.name === 'Series') rows = this.state.series;
                else rows = this.state.files;
                return (
                  <TabPanel
                    className={classes.tab_panel}
                    index={index}
                    key={`tab-files-upload-tab_panel-${index}`}
                    value={tabValue}>
                    <TabComponent
                      handleAddError={this.props.handleAddError}
                      handleAddSuccess={this.props.handleAddSuccess}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      handleReloadTable={this.updateUpload}
                      height={internal_height}
                      history={this.props.history}
                      holder={this.holder}
                      limit={this.state.limit}
                      loading={loading}
                      page={this.state.page}
                      project={this.state.project}
                      rows={rows}
                      total={x.name === 'Series' ? total_series : total_files}
                      type={this.state.type}
                      user={user}
                    />
                  </TabPanel>
                );
              })}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

TabUserUploads.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(TabUserUploads), useStyles);
