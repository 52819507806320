import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import { setExplorerLimit } from 'redux-store/actions';
import { setDashboardPageDesigner } from 'redux-store/actions/page_settings';
import { rowsPerPageBig } from 'helpers';
import CardSetting from '../CardSetting';
import { IsInvalid } from 'helpers';
import { MutationUpdateSetting, QueryUserSetting } from 'graphql/Users';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
class GeneralSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.rowsPerPage,
      inputValue: props.rowsPerPage,
      settings: null,
      saving: false,
    };
  }
  componentDidMount() {
    this.handleLoadSetting();
  }
  handleLoadSetting = () => {
    const { loading } = this.state;
    const { user } = this.props;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryUserSetting(user.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, setting } = data.UserSetting;
          if (ok) {
            this.setState({
              loading: false,
              setting,
              empty: setting ? false : true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRowsPerPage = (event, value) => {
    this.setState({ value });
    this.props.setExplorerLimit(value);
  };
  handlePageDesigner = (event, limit_designer) => {
    if (limit_designer === null || limit_designer === undefined) return;
    const { page_designer } = this.props;
    this.props.setDashboardPageDesigner(page_designer, limit_designer);
  };
  handleCheckChange = event => {
    let { settings } = this.state;
    const checked = event.currentTarget.checked;
    if (IsInvalid(settings)) {
      settings = {
        volume_to_dcm: checked,
      };
    } else {
      settings.volume_to_dcm = checked;
    }
    this.setState({ settings });
  };
  IsModified = () => {
    let { settings } = this.state;
    if (settings) return true;
    return false;
  };
  handleSaveSetting = () => {
    const { saving, settings, setting } = this.state;
    if (saving || IsInvalid(settings)) return;
    this.setState({ saving: true });
    const inputs = { ...setting, ...settings };
    (async () => {
      MutationUpdateSetting(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.UpdateSetting;
          if (ok) {
            this.setState({
              saving: false,
              settings: null,
              setting: {
                ...inputs,
              },
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { user, limit_designer } = this.props;
    const { settings, setting } = this.state;
    let volume_to_dcm = false;
    if (settings) {
      volume_to_dcm = settings.volume_to_dcm;
    } else if (setting) {
      volume_to_dcm = setting.volume_to_dcm;
    }
    const { inputValue, value } = this.state;
    let ComponentPageDesigner = null;
    if (user && user.idCategory === 2) {
      ComponentPageDesigner = (
        <Grid item>
          <Autocomplete
            getOptionLabel={option => {
              return `${option}`;
            }}
            id="id-autocomplete-limit_designer"
            onChange={this.handlePageDesigner}
            options={rowsPerPageBig}
            renderInput={params => (
              <TextField {...params} label="Page Limit - Dashboard" />
            )}
            sx={{ width: 300 }}
            value={limit_designer}
          />
        </Grid>
      );
    }
    return (
      <CardSetting
        disabled={!this.IsModified()}
        handleRealod={() => this.handleLoadSetting()}
        handleSave={this.handleSaveSetting}
        loading={this.state.saving}
        save="Update"
        subheader="General Settings"
        title="General">
        <Grid container spacing={2}>
          <Grid item>
            <Autocomplete
              getOptionLabel={option => {
                return `${option}`;
              }}
              id="controllable-states-demo"
              inputValue={`${inputValue}`}
              onChange={this.handleRowsPerPage}
              onInputChange={(event, inputValue) => {
                this.setState({ inputValue });
              }}
              options={rowsPerPageBig}
              renderInput={params => (
                <TextField {...params} label="Page Limit - Explorer" />
              )}
              sx={{ width: 300 }}
              value={value}
            />
          </Grid>
          {ComponentPageDesigner}
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={volume_to_dcm}
                  name="volume_to_dcm"
                  onChange={this.handleCheckChange}
                />
              }
              label="Volume to DCM"
            />
          </Grid>
        </Grid>
      </CardSetting>
    );
  }
}

GeneralSettings.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { status } = state.page_explorer;
  const { dashboard_view } = state.page_settings;
  let page = 0;
  let rowsPerPage = 500;
  let page_designer = 0;
  let limit_designer = 10;
  if (status) {
    if (status.page) page = status.page;
    if (status.rowsPerPage) {
      rowsPerPage = status.rowsPerPage;
    }
  }
  if (dashboard_view) {
    if (dashboard_view.page_designer) {
      page_designer = dashboard_view.page_designer;
    }
    if (dashboard_view.limit_designer) {
      limit_designer = dashboard_view.limit_designer;
    }
  }
  return {
    page,
    rowsPerPage,
    page_designer,
    limit_designer,
  };
};
export default connect(mapStateToProps, {
  setExplorerLimit,
  setDashboardPageDesigner,
})(GeneralSettings);
