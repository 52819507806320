import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
const useStyles = () => ({
  main_button: {
    palette: {
      primary: 'white',
      secondary: 'white',
    },
  },
  main_text: {
    marginTop: '4px',
    marginLeft: '10px',
    fontFamily: 'Arial',
    fontWeight: 700,
    palette: {
      primary: 'white',
      secondary: 'white',
    },
  },
});
class HomeFooterInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const menu = [
      {
        title: 'help & support',
        items: [
          {
            name: 'contact us',
            route: '',
            href: 'mailto:contact@medical-blocks.ch',
          },
        ],
      },
      {
        title: 'Resources',
        items: [
          {
            name: 'tutorials',
            route: '',
            href: 'https://www.youtube.com/watch?v=WO0nARK-0Fc',
          },
          {
            name: 'Publication',
            route: '',
            href: 'https://formative.jmir.org/2022/4/e32287',
          },
        ],
      },
      {
        title: 'Contact us',
        items: [
          {
            name: 'e-mail: contact@medical-blocks.ch',
            route: '',
            href: 'mailto:contact@medical-blocks.ch',
            cap: false,
          },
        ],
      },
    ];
    return (
      <Box component="footer" sx={{ paddingTop: 5 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item md={3} sx={{ ml: 'auto', mb: 3, mr: 6 }} xs={12}>
              <Box>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="flex-start">
                  <AccountBalanceIcon
                    className={clsx({
                      [classes.main_button]: true,
                    })}
                    fontSize="large"
                  />
                  <Typography
                    className={clsx({
                      [classes.main_text]: true,
                    })}
                    variant="h3">
                    Medical-Blocks
                  </Typography>
                </Grid>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    component="a"
                    fontWeight="regular"
                    rel="noreferrer"
                    target="_blank">
                    A project supported by University Hospital of Bern and the
                    University of Bern.
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    component="a"
                    fontWeight="regular"
                    rel="noreferrer"
                    target="_blank">
                    Inselspital, Universitätsspital Bern
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="a"
                    fontWeight="regular"
                    rel="noreferrer"
                    target="_blank">
                    Freiburgstrasse
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="a"
                    fontWeight="regular"
                    rel="noreferrer"
                    target="_blank">
                    CH-3010
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="a"
                    fontWeight="regular"
                    rel="noreferrer"
                    target="_blank">
                    Bern
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {menu.map(({ title, items }, index) => {
              let sx = { mb: 3 };
              if (index + 1 >= menu.length) {
                sx = { mr: 'auto', mb: 3 };
              }
              return (
                <Grid item key={title} md={2} sx={sx} xs={6}>
                  <Typography
                    display="block"
                    fontWeight="bold"
                    mb={1}
                    textTransform="capitalize"
                    variant="button">
                    {title}
                  </Typography>
                  <Box
                    component="ul"
                    key={`menu-${index}`}
                    m={0}
                    p={0}
                    sx={{ listStyle: 'none' }}>
                    {items.map(({ name, route, href, cap }) => {
                      let text = { textTransform: 'none' };
                      if (cap === undefined) {
                        text = { textTransform: 'capitalize' };
                      }
                      return (
                        <Box
                          component="li"
                          key={name}
                          lineHeight={1.25}
                          m={0}
                          p={0}>
                          {href ? (
                            <Typography
                              component="a"
                              fontWeight="regular"
                              href={href}
                              rel="noreferrer"
                              target="_blank"
                              {...text}
                              variant="button">
                              {name}
                            </Typography>
                          ) : (
                            <Typography
                              component={Link}
                              fontWeight="regular"
                              {...text}
                              to={route}
                              variant="button">
                              {name}
                            </Typography>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Box>
    );
  }
}

HomeFooterInformation.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(HomeFooterInformation, useStyles);
