import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadInputs } from 'common';

const MUTATION_UPDATE_VARIABLE_TYPE = gql`
  mutation($idType: LongLong!, $name: String!, $description: String) {
    updateVariableType(
      idType: $idType
      name: $name
      description: $description
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateVariableType = (idType, name, description) => {
  const variables = { idType };
  if (name !== undefined && name) variables.name = name;
  if (description !== undefined) variables.description = description;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_VARIABLE_TYPE),
    variables,
  });
};

const MUTATION_UPLOAD_VARIABLE_TYPES_CSV = gql`
  mutation($file: Upload!, $page: Int, $limit: Int) {
    uploadVariableTypesCSV(file: $file, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      types {
        id
        name
        description
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationUploadVariableTypesCSV = (
  file,
  page = null,
  limit = null,
  handleProgress = null
) => {
  const query = { file };
  if (page !== undefined && limit !== undefined) {
    query.page = page;
    query.limit = limit;
  }
  return MutationUploadInputs(
    query,
    MUTATION_UPLOAD_VARIABLE_TYPES_CSV,
    handleProgress
  );
};
