import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    top: 'auto',
    bottom: 0,
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'sticky',
  },
  avatar: {
    margin: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: '2 auto',
    border: '0.5px solid',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexGrow: 1,
  },
}));

export default useStyles;
