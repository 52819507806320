import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { withRouter } from 'react-router-dom';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { withStyles } from 'tss-react/mui';
import DialogBatchProcessSetting from './DialogBatchProcessSetting';
import DialogJobProcessSetting from './DialogJobProcessSetting/DialogJobProcessSetting';
import { MutationExecuteJob } from 'graphql/Process';
import { GetData, ServerErrorsString } from 'helpers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IsInvalid } from 'helpers';
const useStyles = () => ({
  root: { height: 38, margin: 0, padding: 0, paddingTop: 5, paddingLeft: 10 },
});
class ButtonGroupBatchActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      executing: false,
      open_setting_batch: false,
      setting: null,
      open_batch_edit: false,
      open_job_setting: false,
    };
    const { holder } = props;
    if (holder) {
      holder.getSetting = () => this.state.setting;
    }
    this.holder = {
      setSetting: null,
      cleanHighlight: null,
    };
  }
  handleRunJob = () => {
    const { selected, tabValue, job_clicked } = this.props;
    if (tabValue === 'input_jobs' && !selected.length && job_clicked) {
      this.handleExecuteJob();
    } else {
      this.props.handleRunJob();
    }
  };
  handleExecuteJob = () => {
    const { job_clicked } = this.props;
    const { executing } = this.state;
    if (executing) return;
    this.setState({ executing: true });
    (async () => {
      MutationExecuteJob(job_clicked.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.executeJob;
          if (ok) {
            this.setState({ executing: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ executing: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleOpenSettings = () => {
    const { tabValue } = this.props;
    if (tabValue === 'input_jobs') {
      this.setState({ open_job_setting: true });
    } else if (tabValue === 'batches') {
      this.setState({ open_setting_batch: true });
    }
  };
  handleUpdateJobProcess = job => {
    if (job === undefined || !job) return;
    this.props.handleUpdateJobProcess(job);
    // const { jobs } = this.state;
    // const index = jobs.map(x => x.id).indexOf(job.id);
    // if (index !== -1) {
    //   jobs[index] = {
    //     ...job,
    //   };
    //   this.handleUpdateBatches(jobs);
    //   this.setState({ jobs, job });
    // }
  };
  handleUpdateBatchProcess = batch => {
    if (batch === undefined || !batch) return;
    this.props.handleUpdateBatches([batch]);
  };
  handleOpenFolder = () => {
    const { job_clicked } = this.props;
    if (IsInvalid(job_clicked)) return;
    console.log({ job_clicked });
    const { setting } = job_clicked;
    if (IsInvalid(setting)) return;
    const { folder_content } = setting;
    if (IsInvalid(folder_content)) {
      this.props.handleAddError('Folder content is not set');
      return;
    }
    const { Folder } = folder_content;
    if (IsInvalid(Folder)) {
      this.props.handleAddError('Folder is not set');
      return;
    }
    let location = '/explorer';
    if (Folder.name !== 'Home') {
      location = `/explorer/folder/${Folder.idFolder}`;
    }
    this.props.history.push(location);
  };
  render() {
    const { classes, selected, tabValue, batch, job_clicked } = this.props;
    const { setting } = this.state;
    let run_batch = false;
    let add_batch = false;
    let ComponentOpenFolder = null;
    let ComponentAddToBatchJobs = null;
    if (tabValue !== 'batches') {
      run_batch = false;
      if (tabValue === 'input_jobs') {
        add_batch = Boolean(selected.length);
        if (
          !selected.length &&
          job_clicked &&
          job_clicked.status !== 'running'
        ) {
          run_batch = true;
        }
        if (job_clicked) {
          ComponentOpenFolder = (
            <Button
              disabled={!job_clicked}
              onClick={() => this.handleOpenFolder()}>
              <OpenInNewIcon
                style={{
                  color: job_clicked ? 'green' : 'gray',
                }}
              />
            </Button>
          );
        }
        ComponentAddToBatchJobs = (
          <Button disabled={!add_batch} onClick={this.props.handleRunJob}>
            <Tooltip title="Add to a batch">
              <PlaylistAddIcon style={{ color: add_batch ? 'blue' : 'gray' }} />
            </Tooltip>
          </Button>
        );
      }
    } else if (tabValue === 'batches') {
      add_batch = false;
      run_batch = Boolean(selected.length);
      for (const item of selected) {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('status') && item.status === 'running') {
          run_batch = false;
        }
      }
    }
    let ComponentButtonEdit = null;
    if (tabValue === 'batches') {
      ComponentButtonEdit = (
        <Button
          disabled={selected && selected.length <= 0}
          onClick={() => this.props.handleOpenBatchEdit(true)}>
          <BorderColorIcon
            style={{
              color:
                tabValue === 'batches' && selected && selected.length > 0
                  ? 'green'
                  : 'gray',
            }}
          />
        </Button>
      );
    }
    // console.log({ tabValue, add_batch, run_batch });
    return (
      <React.Fragment>
        <ButtonGroup
          className={classes.root}
          exclusive="false"
          size="small"
          value="center">
          {ComponentAddToBatchJobs}
          <Button disabled={!run_batch} onClick={this.handleRunJob}>
            <PlayArrowIcon style={{ color: run_batch ? 'blue' : 'gray' }} />
          </Button>
          <Button disabled={!run_batch} onClick={this.handleOpenSettings}>
            <Tooltip title="Edit batch setting">
              <SettingsIcon
                style={{
                  color:
                    (setting && setting.count) || run_batch > 0
                      ? '#ffd600'
                      : 'gray',
                }}
              />
            </Tooltip>
          </Button>
          {ComponentOpenFolder}
          {ComponentButtonEdit}
        </ButtonGroup>
        <DialogBatchProcessSetting
          batch={batch}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_setting_batch: false })}
          handleSave={setting =>
            this.setState({ setting, open_setting_batch: false })
          }
          handleUpdateBatchProcess={this.handleUpdateBatchProcess}
          open={this.state.open_setting_batch}
          setting={setting}
        />
        <DialogJobProcessSetting
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_job_setting: false })}
          handleUpdateJobProcess={this.handleUpdateJobProcess}
          holder={this.holder}
          job={job_clicked}
          open={this.state.open_job_setting && job_clicked}
        />
      </React.Fragment>
    );
  }
}

ButtonGroupBatchActions.propTypes = {
  selected: PropTypes.array,
};
ButtonGroupBatchActions.defaultProps = {
  selected: [],
  deleting_centers: false,
  handleRunJob: () => '',
};

export default withStyles(withRouter(ButtonGroupBatchActions), useStyles);
