import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardHeader,
  IconButton,
  Avatar,
  CircularProgress,
  Grid,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { grey } from '@mui/material/colors';
import ClearIcon from '@mui/icons-material/Clear';
import {
  TabsSurveySummary,
  TabInformConsent,
  TabMedicalCenter,
  TabPatientInfo,
  TabGeneralSummary,
} from './components';
import { QueryRecodedPatientSurveys } from 'graphql/Surveys';
import moment from 'moment';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';

class SurveySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab_value: 0,
      tabs: [
        { name: 'Summary', TabComponent: TabGeneralSummary },
        { name: 'Patient Info', TabComponent: TabPatientInfo },
        { name: 'Inform Consent', TabComponent: TabInformConsent },
        { name: 'Medical Center Info', TabComponent: TabMedicalCenter },
      ],
      surveys: [],
      patient: null,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  componentDidUpdate() {
    const { currentPatient } = this.props;
    if (currentPatient === undefined || !currentPatient) {
      this.props.onClose();
      return;
    }
    const { patient } = this.state;
    if (!patient || patient.id !== currentPatient.id) {
      this.handleQuery();
    }
  }
  handleQuery = () => {
    const { currentPatient } = this.props;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const { id } = currentPatient;
    (async () => {
      QueryRecodedPatientSurveys(id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, surveys } = data.allRecodedPatientSurveys;
          if (ok) {
            this.setState({ loading: false, surveys, patient: currentPatient });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, patient: currentPatient });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddNewSurvey = new_survey => {
    const { surveys } = this.state;
    const index = surveys.map(x => x.id).indexOf(new_survey.id);
    console.log({ index, new_survey });
    if (index === -1) {
      surveys.unshift(new_survey);
    }
    this.setState({ surveys });
  };
  handleUpdateSurvey = new_survey => {
    const { surveys } = this.state;
    const index = surveys.map(x => x.id).indexOf(new_survey.id);
    console.log({ index, new_survey });
    if (index !== -1) {
      surveys[index] = {
        ...surveys[index],
        ...new_survey,
      };
    }
    this.setState({ surveys });
  };
  render() {
    const { tab_value, loading } = this.state;
    const { currentPatient, height, ...res } = this.props;
    if (currentPatient === undefined || !currentPatient) {
      this.props.onClose();
      return null;
    }
    const { GivenName, FamilyName, PatientBirthDate } = currentPatient;
    let style = {
      height,
      maxHeight: height,
      overflow: 'auto',
    };
    let avatar = 'U';
    if (GivenName) {
      avatar = `${GivenName[0]}`;
    }
    if (avatar !== 'U' && FamilyName) {
      avatar = `${GivenName[0]}${FamilyName[0]}`;
    } else if (FamilyName) {
      avatar = `${FamilyName[0]}`;
    }
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={this.props.onClose}>
              <ClearIcon />
            </IconButton>
          }
          avatar={
            <Avatar aria-label="recipe" sx={{ bgcolor: grey[500] }}>
              {avatar.toUpperCase()}
            </Avatar>
          }
          subheader={moment(PatientBirthDate, 'YYYY-MM-DD ').format(
            'MMM Do - YYYY'
          )}
          title={`${GivenName} ${FamilyName}`}
        />
        <TabContext value={`${tab_value}`}>
          <TabsSurveySummary
            disabled={loading}
            handleChange={tab_value => this.setState({ tab_value })}
            tab_value={tab_value}
            tabs={this.state.tabs}
          />
          <div style={style}>
            {loading ? (
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="center"
                style={style}>
                <Grid item>
                  <CircularProgress disableShrink />
                </Grid>
              </Grid>
            ) : (
              this.state.tabs.map((item, index) => {
                const { TabComponent } = item;
                return (
                  <TabPanel
                    key={`tab-panel-summary-${index}`}
                    sx={{ paddingBottom: 1, paddingTop: 1 }}
                    value={`${index}`}>
                    <TabComponent
                      currentPatient={currentPatient}
                      handleAddNewSurvey={this.handleAddNewSurvey}
                      handleReload={this.handleQuery}
                      handleUpdateSurvey={this.handleUpdateSurvey}
                      height={height}
                      loading={this.state.loading}
                      surveys={this.state.surveys}
                      {...res}
                    />
                  </TabPanel>
                );
              })
            )}
          </div>
        </TabContext>
      </Box>
    );
  }
}

SurveySummary.propTypes = {
  onClose: PropTypes.func,
};
SurveySummary.defaultProps = {
  onClose: () => '',
};

export default SurveySummary;
