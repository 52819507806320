import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  IconButton,
  Typography,
  Rating,
  Box,
  ListItemText,
  useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { withStyles } from 'tss-react/mui';

import { useStylesImageViewer } from '../useStyles';
import TableImageDetails from './TableImageDetails';
import { ServerErrorsString, GetContentName, GetData } from 'helpers';
import ActionsButtons from './ActionsButtons';
import StarIcon from '@mui/icons-material/Star';
import { MutationAddObjectRating } from 'graphql/Rating';
import { DialogInputText, IconButtonLoad } from 'components';
import SaveIcon from '@mui/icons-material/Save';
import { MutationUpdateFileMetadata } from 'graphql/Files';

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

class ImageDetails extends React.Component {
  constructor(props) {
    super(props);
    const { folder_content } = props;
    let rating = 0;
    if (folder_content) {
      rating = folder_content.rating;
    }
    this.state = {
      value: rating,
      open_rename: false,
      new_name: null,
      updating: false,
    };
  }
  handleRatingChange = (event, newValue) => {
    const { folder_content } = this.props;
    const { updating } = this.state;
    if (updating || !folder_content || folder_content === undefined) return;
    const { idFolderContent } = folder_content;
    if (updating || !idFolderContent || idFolderContent === undefined) return;
    this.setState({ updating: true });
    (async () => {
      MutationAddObjectRating(newValue, { idFolderContent })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addObjectRating;
          console.log({ ok, newValue });
          if (ok) {
            this.setState({ value: newValue, updating: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveImageName = () => {
    const { updating, new_name } = this.state;
    const { folder_content } = this.props;
    if (updating) return;
    const file = folder_content.File;
    if (!file || file === undefined) return;
    this.setState({ updating: true });
    (async () => {
      MutationUpdateFileMetadata({
        name: new_name,
        idFileObject: file.idFileObject,
      })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateMetadata;
          if (ok) {
            this.props.handleSaveImageName(this.state.new_name);
            this.setState({ updating: false, new_name: null });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, theme, folder_content, padding } = this.props;
    let title = GetContentName(folder_content);
    const { value, new_name } = this.state;
    let metadata = null;
    if (folder_content && folder_content.File.metadata) {
      metadata = folder_content.File.metadata;
      if (metadata) {
        const name = metadata.name;
        if (name && name !== '') {
          title = name;
        }
      }
    }
    return (
      <React.Fragment>
        <div style={{ height: padding }} />
        <Divider />
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.props.handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <ListItemText
            onDoubleClick={() => this.setState({ open_rename: true })}
            primary="Image Name"
            secondary={new_name ? new_name : title}
          />
          {new_name && (
            <IconButtonLoad
              // disabled={!changed}
              handleClick={this.handleSaveImageName}
              icon={<SaveIcon />}
              loading={this.state.updating}
              small
              tooltip="Save new image name"
            />
          )}
        </div>
        <Divider />
        <div style={{ padding: 10 }}>
          <TableImageDetails
            folder_content={folder_content}
            metadata={metadata}
          />
          <Box
            sx={{
              '& > legend': { mt: 2 },
              width: '100%',
            }}>
            <Typography component="legend" style={{ width: '100%' }}>
              Classification
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 10,
              }}>
              <Rating
                emptyIcon={
                  <StarIcon fontSize="inherit" style={{ opacity: 0.55 }} />
                }
                name="text-feedback"
                onChange={this.handleRatingChange}
                precision={0.5}
                // readOnly
                value={value}
              />
              <Box sx={{ ml: 2, width: '20%' }}>{labels[value]}</Box>
            </Box>
          </Box>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: 0,
            margin: 0,
          }}>
          <Divider />
          <ActionsButtons
            folder_content={folder_content}
            handleEdit={this.props.handleEdit}
          />
        </div>
        <DialogInputText
          description="Type the new image name"
          handleAddText={new_name =>
            this.setState({ open_rename: false, new_name })
          }
          handleClose={() => this.setState({ open_rename: false })}
          input_name={title}
          open={this.state.open_rename}
          text_botton="Rename"
          title="Rename Image"
        />
      </React.Fragment>
    );
  }
}

ImageDetails.propTypes = {
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool,
};
ImageDetails.defaultProps = {
  handleDrawerClose: () => '',
  open: true,
  padding: 64,
};
const ContainerImageDetails = props => {
  const theme = useTheme();
  return <ImageDetails theme={theme} {...props} />;
};
export default withStyles(ContainerImageDetails, useStylesImageViewer);
