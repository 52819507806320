import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DialogDeleteWarning,
  SearchText,
} from 'components';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { IsInvalid } from 'helpers';
import {
  MutationAddPatientConsents,
  MutationRemovePatientConsents,
  MutationUploadPatientConsentsCSV,
} from 'graphql/Anonymization';
import { ServerErrorsString, GetData } from 'helpers';

class MapPatientConsentToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      deleting: false,
      open_delete: false,
      text: '',
    };
  }
  handleSaveChanges = () => {
    const { saving } = this.state;
    const { rows_edited } = this.props;
    if (saving || !rows_edited.length) return;
    this.setState({ saving: true });
    const inputs = rows_edited.map(x => {
      let { consent } = x;
      if (typeof consent === 'string') {
        consent = consent.toLowerCase();
        if (consent.startsWith('y') || consent.startsWith('1')) {
          consent = true;
        } else {
          consent = false;
        }
      }
      return {
        idPatient: x.idPatient,
        type: x.type,
        information: x.information,
        consent,
      };
    });
    (async () => {
      MutationAddPatientConsents(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents } = data.addPatientConsents;
          if (ok) {
            this.setState({ saving: false });
            this.props.handleAddPatientConsents(patient_consents, true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadPatientConsentsCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents } = data.uploadPatientConsents;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleAddPatientConsents(patient_consents);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSearchText = text => {
    if (IsInvalid(text)) return;
    const query = this.getQuery(text);
    if (query) this.props.handleReload(query);
    else this.props.handleReload();
    this.setState({ text });
  };
  getQuery = text => {
    text = text.trim();
    if (text === '') return null;
    else {
      const tocheck = [
        'PatientName',
        'PatientID',
        'GivenName',
        'FamilyName',
        'consent',
        'type',
        'PatientBirthDate',
        'information',
      ];
      const parts = text.split(';');
      let query = null;
      for (let i = 0; i < parts.length; i++) {
        const value = parts[i].trim();
        for (let j = 0; j < tocheck.length; j++) {
          const element = tocheck[j];
          if (value.includes(`${element}:`)) {
            let question = value.split(`${element}:`)[1].trim();
            if (value.startsWith('consent:')) {
              text = text
                .replace('consent:', '')
                .trim()
                .toLowerCase();
              question = false;
              if (text.startsWith('y') || text.startsWith('1')) {
                question = true;
              }
            }
            query = {
              ...query,
              [element]: question,
            };
          }
        }
      }
      if (IsInvalid(query)) {
        query = {
          PatientName: text,
          type: text,
          information: text,
        };
      }
      return query;
    }
  };
  handleDeleteSelected = () => {
    const { deleting } = this.state;
    const { selected } = this.props;
    if (deleting) return;
    const ids = selected.map(x => x.id);
    this.setState({ deleting: true });
    (async () => {
      MutationRemovePatientConsents(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removePatientConsents;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.handleRemovePatientConsents(ids);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, open_delete: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { rows_edited, selected } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            <ButtonGroup exclusive="false" size="small" value="save">
              <Button
                disabled={!rows_edited.length}
                onClick={this.handleSaveChanges}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </Button>
              <Button onClick={this.props.handleSearch}>
                <SearchIcon style={{ color: 'blue' }} />
              </Button>
              <ButtonDownloadType
                inputs={this.getQuery(this.state.text)}
                key="encryptions"
                type="patient_consents"
              />
              <ButtonFileUpload
                handleSelectFile={this.handleUploadSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={this.handleSearchText}
              id="id-search-encrypted-patient"
              loading={this.props.loading}
              placeholder="Search Encrypted patient"
            />
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload()}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the patients consent, this action cannot be undone"
          title_text="Delete Selected Patient Consent"
        />
      </React.Fragment>
    );
  }
}

MapPatientConsentToolbar.propTypes = {
  classes: PropTypes.object,
};

export default MapPatientConsentToolbar;
