import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadInputs } from 'common';

const QUERY_PROJECT_MAPPING = gql`
  query($idProject: LongLong!, $page: Int, $limit: Int) {
    projectMappings(idProject: $idProject, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      mappings {
        id
        idProject
        type
        use_key
        key
        value
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const QueryProjectMapping = (idProject, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_MAPPING),
    variables: {
      idProject,
      page,
      limit,
    },
  });
};
const MUTATION_CREATE_PROJECT_MAPPING = gql`
  mutation($idProject: LongLong!, $inputs: [InputProjectMapping!]!) {
    createProjectMapping(idProject: $idProject, inputs: $inputs) {
      ok
      errors {
        message
      }
      mappings {
        id
        idProject
        type
        use_key
        key
        value
        createdAt
        updatedAt
      }
    }
  }
`;

export const MutationCreateProjectMapping = (idProject, inputs) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_PROJECT_MAPPING),
    variables: {
      idProject,
      inputs,
    },
  });
};
const MUTATION_DELETE_PROJECT_MAPPING = gql`
  mutation($ids: [LongLong!]!) {
    deleteProjectMapping(ids: $ids) {
      ok
      errors {
        message
      }
    }
  }
`;

export const MutationDeleteProjectMapping = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_PROJECT_MAPPING),
    variables: {
      ids,
    },
  });
};

const MUTATION_UPDATE_PROJECT_MAPPING = gql`
  mutation($idProject: LongLong!, $inputs: [InputProjectMapping!]!) {
    editProjectMapping(idProject: $idProject, inputs: $inputs) {
      ok
      errors {
        message
      }
    }
  }
`;

export const MutationUpdateProjectMapping = (idProject, inputs) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_PROJECT_MAPPING),
    variables: {
      idProject,
      inputs,
    },
  });
};
const MUTATION_UPLOAD_PROJECT_MAPPING_CSV = gql`
  mutation($file: Upload!, $idProject: LongLong!) {
    uploadNewProjectMapping(file: $file, idProject: $idProject) {
      ok
      errors {
        path
        message
      }
      mappings {
        id
        idProject
        type
        use_key
        key
        value
        updatedAt
        createdAt
      }
    }
  }
`;
export const MutationUploadProjectMappingCSV = (
  idProject,
  file,
  handleProgress = null
) => {
  const query = { idProject, file };
  return MutationUploadInputs(
    query,
    MUTATION_UPLOAD_PROJECT_MAPPING_CSV,
    handleProgress
  );
};
