import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_patient_signals } from './utils';
import { QuerySignalPatients } from 'graphql/Signals';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import { ToolBarSignalPatient } from './components';
import { Grid } from '@mui/material';

class TabPatientSignals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selected: [],
      loading: false,
      patients: [],
      total: 0,
      page: 0,
      limit: 50,
      empty: false,
    };
  }
  componentDidMount() {
    this.loadSignalPatients();
  }
  loadSignalPatients = (name = null, page = null, limit = null) => {
    const { loading } = this.state;
    if (loading) return;
    if (!limit) {
      page = this.state.page;
      limit = this.state.limit;
    }
    if (name === null) {
      name = this.state.name;
    }
    this.setState({ loading: true });
    QuerySignalPatients(name, page, limit)
      .then(res => {
        const data = GetData(res);
        const { ok, patients, total, errors } = data.signalPatients;
        if (ok) {
          this.setState({
            loading: false,
            empty: !patients.length,
            patients,
            total,
            page,
            limit,
            name,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ loading: false, empty: true });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  handleChangeName = event => {
    event.preventDefault();
    this.loadSignalPatients(event.target.value);
  };
  render() {
    const { height } = this.props;
    const { loading, total, patients, selected } = this.state;
    return (
      <Grid container spacing={1}>
        <Grid item sx={{ marginTop: '5px' }} xs={12}>
          <ToolBarSignalPatient
            handleChangeName={this.handleChangeName}
            handleReload={() => this.loadSignalPatients()}
            loading={loading}
            name={this.state.name}
            selected={selected}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={selected}
            headers={columns_patient_signals}
            height={height - 230}
            loading={loading}
            onPageChange={page =>
              this.loadSignalPatients(null, page, this.state.limit)
            }
            onRowsPerPageChange={limit =>
              this.loadSignalPatients(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={total}
            rows={patients}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabPatientSignals.propTypes = {
  classes: PropTypes.object,
};

export default TabPatientSignals;
