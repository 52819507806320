import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { inputProps } from 'common';

class StorageSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { storage, loading, file_size } = this.props;
    return (
      // <GroupBox title_box="Storage Setting">
      <Grid container item p={1} spacing={3} xs={12}>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            disabled={loading}
            id="max-file-size"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {file_size.format}
                </InputAdornment>
              ),
              ...inputProps,
            }}
            label="Maximum file size"
            onChange={this.props.handleChange('file_size')}
            size="small"
            type="number"
            value={file_size.number ? file_size.number : 0}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            disabled={loading}
            id="max-storage-size"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {storage.format}
                </InputAdornment>
              ),
              ...inputProps,
            }}
            label="Maximum storage size"
            onChange={this.props.handleChange('storage')}
            size="small"
            type="number"
            value={storage.number ? storage.number : 0}
          />
        </Grid>
      </Grid>
      // </GroupBox>
    );
  }
}

StorageSetting.propTypes = {
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default StorageSetting;
