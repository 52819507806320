import React from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import {
  Close as CloseIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import {
  ButtonDeleteIcon,
  ButtonReload,
  DialogDeleteWarning,
} from 'components';

import { theme, useToolbarStyles } from './Styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

class FilesToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDeleteWarningClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleDeleteFolders = () => {
    this.setState({ open: false });
    this.props.handleRemove();
  };
  handleAddCenter = center => {
    if (!center) return;
    const { setting } = this.props;
    setting.idCenters = center.id;
    this.props.handleUpdateSearchSetting(setting);
  };

  render() {
    const {
      classes,
      numSelected,
      handleFilesView,
      viewList,
      loading,
      loading_remove,
      handleReload,
      handleClear,
    } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}>
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Grid container>
              <Grid item xs={4}>
                {/* Files and folder of the project */}
                {/* <ComboSites
                  handleAddCenter={this.handleAddCenter}
                  idProject={this.props.project.id}
                /> */}
              </Grid>
            </Grid>
          )}

          {numSelected > 0 ? (
            <React.Fragment>
              <ButtonDeleteIcon
                loading={loading_remove}
                onClick={this.handleDeleteWarningClick}
                title="Remove from Project"
              />
              <Tooltip title="Clear">
                <span style={{ margin: 0, padding: 0 }}>
                  <IconButton
                    aria-label="clear"
                    onClick={handleClear}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {viewList ? (
                <Tooltip title="View Gallery">
                  <span style={{ margin: 0, padding: 0 }}>
                    <IconButton
                      aria-label="view gallery"
                      onClick={handleFilesView}
                      size="large">
                      <ViewModuleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="View List">
                  <span style={{ margin: 0, padding: 0 }}>
                    <IconButton
                      aria-label="view list"
                      onClick={handleFilesView}
                      size="large">
                      <ViewListIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <ButtonReload
                handleReload={handleReload}
                loading={loading}
                small
              />
            </React.Fragment>
          )}
        </Toolbar>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <DialogDeleteWarning
              handleClose={this.handleDeleteWarningClick}
              handleDeleteFolders={this.handleDeleteFolders}
              loading={this.props.loading_remove}
              open={open}
              question_text="Remove Object from the project was triggered, this action cannot be undone."
              title_text="Delete Object"
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Fragment>
    );
  }
}

FilesToolBar.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleFilesView: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  loading_remove: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
};
FilesToolBar.defaultProps = {
  numSelected: PropTypes.number.isRequired,
  loading_remove: false,
  project: { id: -1 },
};
export default withStyles(FilesToolBar, useToolbarStyles);
