import React from 'react';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export const DialogTitle = withStyles(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, useStyles);
export const DialogContent = withStyles(MuiDialogContent, theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

export const DialogActions = withStyles(MuiDialogActions, theme => ({
  root: {
    margin: theme.spacing(0, 2, 0, 2), //TOP,RIGHT,BOTTOM,LEFT
    padding: theme.spacing(0, 2, 0, 2),
  },
}));
