/* eslint-disable no-undef */
const { REACT_APP_ENV } = process.env;
const { REACT_APP_WEB_HOST } = process.env;
const { REACT_APP_SERVER_API } = process.env;
const { REACT_APP_SERVER_PORT } = process.env;
const { REACT_APP_WEBSOCKET } = process.env;
const { REACT_APP_PROTOCOL } = process.env;
const { REACT_APP_SERVER_DOWNLOAD_API } = process.env;

let API_AND_PORT = `${REACT_APP_SERVER_API}:${REACT_APP_SERVER_PORT}`;
let PROTOCOL_AND_API_AND_PORT = `${REACT_APP_PROTOCOL}://${API_AND_PORT}`;

let HTTP_ADDRESS = `${REACT_APP_PROTOCOL}://${REACT_APP_WEB_HOST}`;
let GRAPHQL_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/graphql`;
let WEBSOCKET_ADDRESS = `${REACT_APP_WEBSOCKET}://${API_AND_PORT}/graphql`;
let DOWNLOAD_ADDRESS = `${REACT_APP_SERVER_DOWNLOAD_API}/download`;
let DICOM_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/dicom`;
let GRAPHQL_ADDRESS_SOFTWARE = 'http://localhost:8081/graphql';
let GRAPHQL_ADDRESS_SOFTWARE_DOWNLOAD = 'http://localhost:8081/download';
console.log({ REACT_APP_ENV });

if (REACT_APP_ENV === 'production') {
  GRAPHQL_ADDRESS = `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_API}/graphql`;
  WEBSOCKET_ADDRESS = `${REACT_APP_WEBSOCKET}://${REACT_APP_SERVER_API}/graphql`;
  DOWNLOAD_ADDRESS = `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_DOWNLOAD_API}/download`;
  DICOM_ADDRESS = `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_API}/dicom`;
  GRAPHQL_ADDRESS_SOFTWARE = 'https://update.medical-blocks.ch/graphql';
  GRAPHQL_ADDRESS_SOFTWARE_DOWNLOAD =
    'https://update.medical-blocks.ch/download';
} else if (REACT_APP_ENV === 'test') {
  HTTP_ADDRESS = `${HTTP_ADDRESS}:3000`;
  GRAPHQL_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/graphql`;
  WEBSOCKET_ADDRESS = `${REACT_APP_WEBSOCKET}://${API_AND_PORT}/graphql`;
  DOWNLOAD_ADDRESS = `${REACT_APP_SERVER_DOWNLOAD_API}/download`;
  DICOM_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/dicom`;
} else if (REACT_APP_ENV === 'local') {
  HTTP_ADDRESS = `${HTTP_ADDRESS}:3000`;
  GRAPHQL_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/api`;
  WEBSOCKET_ADDRESS = `${REACT_APP_WEBSOCKET}://${API_AND_PORT}/wss`;
  DOWNLOAD_ADDRESS = `${REACT_APP_SERVER_DOWNLOAD_API}/download`;
  DICOM_ADDRESS = `${PROTOCOL_AND_API_AND_PORT}/dicom`;
} else {
  HTTP_ADDRESS = `${HTTP_ADDRESS}:3000`;
  GRAPHQL_ADDRESS = `${REACT_APP_PROTOCOL}://localhost:${REACT_APP_SERVER_PORT}/graphql`;
  WEBSOCKET_ADDRESS = `${REACT_APP_WEBSOCKET}://localhost:${REACT_APP_SERVER_PORT}/graphql`;
  DOWNLOAD_ADDRESS = `${REACT_APP_PROTOCOL}://${REACT_APP_SERVER_DOWNLOAD_API}/download`;
  DICOM_ADDRESS = `${REACT_APP_PROTOCOL}://localhost:${REACT_APP_SERVER_PORT}/dicom`;
}
if (WEBSOCKET_ADDRESS.indexOf('mb-api.insel.ch') !== -1) {
  WEBSOCKET_ADDRESS = 'ws://161.62.108.107/graphql';
}
if (WEBSOCKET_ADDRESS.indexOf('mb-api-02.insel.ch') !== -1) {
  WEBSOCKET_ADDRESS = 'ws://161.62.108.115/graphql';
}
console.log(`
  WEBSOCKET_ADDRESS: ${WEBSOCKET_ADDRESS}
  GRAPHQL_ADDRESS: ${GRAPHQL_ADDRESS}
  GRAPHQL_ADDRESS_SOFTWARE: ${GRAPHQL_ADDRESS_SOFTWARE}
  GRAPHQL_ADDRESS_SOFTWARE_DOWNLOAD: ${GRAPHQL_ADDRESS_SOFTWARE_DOWNLOAD}
  DOWNLOAD_ADDRESS: ${DOWNLOAD_ADDRESS}
  DICOM_ADDRESS: ${DICOM_ADDRESS}
  HTTP_ADDRESS: ${HTTP_ADDRESS}\n`);
export const WS_ADDRESS_GRAPHQL = WEBSOCKET_ADDRESS;
export const HTTP_ADDRESS_GRAPHQL = GRAPHQL_ADDRESS;
export const HTTP_ADDRESS_GRAPHQL_SOFTWARE = GRAPHQL_ADDRESS_SOFTWARE;
export const HTTP_ADDRESS_DOWNLOAD_SOFTWARE = GRAPHQL_ADDRESS_SOFTWARE_DOWNLOAD;
export const HTTP_ADDRESS_DOWNLOAD = DOWNLOAD_ADDRESS;
export const HTTP_ADDRESS_DICOM = DICOM_ADDRESS;
export const HTTP_ADDRESS_WEB = HTTP_ADDRESS;
