import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { columns_files } from './styles';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { rowsPerPageBig } from 'helpers';
import { TableGrid } from 'components';

class TableUserFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, rows, height, loading, total } = this.props;
    // let disabled = false;
    // if (loading || total < this.props.limit) {
    //   disabled = true;
    // }
    return (
      <Paper className={classes.root}>
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_edited={this.props.state.rows_edited}
          external_selected={this.props.state.selected}
          grid
          headers={columns_files}
          height={height - 10}
          idRow="idFileObject"
          loading={loading}
          onPageChange={page =>
            this.props.handleChangePage(
              { event: { preventDefault: () => '' } },
              page
            )
          }
          onRowClicked={row_clicked => this.props.setState({ row_clicked })}
          onRowEditedChanged={rows_edited => {
            this.props.setState({ rows_edited });
          }}
          onRowsPerPageChange={limit =>
            this.props.handleChangeRowsPerPage({
              event: { preventDefault: () => '', target: { value: limit } },
            })
          }
          onSelectedChanged={selected => this.props.setState({ selected })}
          page={this.props.page}
          paginationMode="server"
          rowCount={total}
          rows={rows}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableUserFiles.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  total: PropTypes.number,
};
TableUserFiles.defaultProps = {
  disable_splice: false,
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  rows: [],
  total: 0,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
};
export default withStyles(TableUserFiles, useStyles);
