import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Container, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
class PublicSelectedSoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const style_title = {
      paddingTop: 10,
      paddingBottom: 20,
      fontSize: '20px',
      textTransform: 'none',
      textAlign: 'center',
    };
    const style_p = {
      marginTop: '0cm',
      marginRight: '0cm',
      marginBottom: '8.0pt',
      marginLeft: '0cm',
      textAlign: 'justify',
      lineHeight: '200%',
      fontSize: '15px',
      fontFamily: '"Calibri",sans-serif',
    };
    const style_a = {
      fontSize: '15px',
      lineHeight: '200%',
      fontFamily: '"Calibri",sans-serif',
    };
    return (
      <Box>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end">
          <Grid item pt={1}>
            <IconButton
              aria-label="close_software_page"
              onClick={this.props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box py={6}>
          <Container>
            <Grid
              alignItems="center"
              container
              flexDirection="column"
              item
              mb={2}
              mx="auto"
              xs={8}>
              <Typography gutterBottom style={style_title} variant="h6">
                Automatic quantification of scapular anatomy from CT scans using
                Deep Learning
              </Typography>
              <Typography>
                <p style={style_p}>
                  <strong>Objectives:</strong> Develop and validate a deep
                  learning model for automatic quantification of glenoid
                  morphometry using computed tomography (CT) scan images of
                  normal subjects and osteoarthritis (OA) patients.
                </p>
                <p style={style_p}>
                  <strong>Methods:</strong> Deep learning was used first to
                  segment the scapula from CT images, and then to identify the
                  location of 9 landmarks on the scapula and 4 landmarks
                  defining the boundaries of the glenoid cavity. The 9 scapula
                  landmarks were used to determine a coordinate system
                  unaffected by OA, whereas the 4 glenoid landmarks were used to
                  determine the size and orientation of the glenoid in this
                  scapular coordinate system. Glenoid inclination, glenoid
                  version, critical shoulder angle, glenopolar angle, glenoid
                  width, and glenoid height were measured. A 5-fold
                  cross-validation was performed to evaluate the performance of
                  this approach on 60 normal and 56 pathologic scapulae.
                </p>
                <p style={style_p}>
                  <strong>Results:&nbsp;</strong>The Dice overlap between manual
                  and automatic segmentations exceeded 0.97 in both normal and
                  pathologic cases. No statistical difference was found in
                  landmark positioning between the automatic method and human
                  raters, except for a few landmarks where the automatic method
                  outperformed the human raters. The automatic method provided
                  acceptable estimates of glenoid inclination (R<sup>2</sup>
                  =0.93), glenoid version (R<sup>2</sup>=0.95), critical
                  shoulder angle (R<sup>2</sup>=0.87), glenopolar angle (R
                  <sup>2</sup>
                  =0.95), glenoid height (R<sup>2</sup>=0.88) and width (R
                  <sup>2</sup>=0.94). However, a significant difference was
                  found for glenoid inclination between manual and automatic
                  measurements (p&lt;0.001).
                </p>
                <p>
                  <strong>
                    <span style={style_a}>Conclusions:&nbsp;</span>
                  </strong>
                  <span style={style_a}>
                    This deep learning model provided automatic quantification
                    of glenoid morphology from CT scans of osteoarthritis
                    patients, with sufficient accuracy for clinical use.
                  </span>
                </p>
              </Typography>
            </Grid>
          </Container>
        </Box>
      </Box>
    );
  }
}

PublicSelectedSoftware.propTypes = {
  classes: PropTypes.object,
};

export default PublicSelectedSoftware;
