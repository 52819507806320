import React from 'react';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { MainTable, MainTableToolbar } from '../components';
import { columns } from './functions';
import { SnackMessage } from 'components';
import { SelectedArray, rowsPerPageSmall } from 'helpers';

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  nameContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    // paddingBottom: -theme.spacing(1)
  },
});

class TableCollaborators extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 5,
      order: 'desc',
      orderBy: 'createdAt',
      selected: [],
      rows: props.rows ? props.rows : [],
      error: '',
    };
    const { holder } = props;
    if (typeof holder !== 'undefined' && holder) {
      holder.getSelected = () => this.state.selected;
      holder.clearSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.collaborators });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    // this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    // this.props.handleChangePageLimit(this.state.page, +event.target.value);
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };

  render() {
    const {
      classes,
      collaborators,
      loading,
      deleting,
      handleReloadTable,
      handleDeleteCollaborators,
    } = this.props;
    const { selected, page, limit, error } = this.state;
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={handleDeleteCollaborators}
          handleReloadTable={handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="Collaborators"
        />
        <div className={classes.tableWrapper}>
          <MainTable
            columns={columns}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={collaborators}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={collaborators.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Paper>
    );
    // return (
    //   <TableBase
    //     columns={columns}
    //     externalDeleteSelected={handleDeleteCollaborators}
    //     handleAcceptReject={handleAcceptReject}
    //     handleSelectedRow={handleSelectedRow}
    //     order={this.state.order}
    //     orderBy={this.state.orderBy}
    //     rows={rows ? rows : []}
    //     title={title}
    //   />
    // );
  }
}

TableCollaborators.propTypes = {
  classes: PropTypes.object.isRequired,
  collaborators: PropTypes.array.isRequired,
  deleting: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};
TableCollaborators.defaultProps = {
  collaborators: [],
};
export default withStyles(TableCollaborators, useStyles);
