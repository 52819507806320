import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLoad, Sequence } from 'components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import { MutationSeriesSequenceMap } from 'graphql/Series/utils_series_sequences';
import { GetData } from 'helpers';

class EditSeriesSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sequence: null,
      loading: false,
    };
  }
  handleSequenceChanged = (value, sequence) => {
    console.log({ sequence });
    this.setState({ sequence });
  };
  handleSetMapping = () => {
    const { loading, sequence } = this.state;
    const { selected } = this.props;
    if (loading || !sequence) return;
    this.setState({ loading: true });
    const inputs = selected.map(x => {
      const { center } = x;
      let idCenter = null;
      if (center) idCenter = center.id;
      return {
        idSeries: x.idSeries,
        idSequence: sequence.idSequence,
        idCenter,
      };
    });
    (async () => {
      MutationSeriesSequenceMap(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addSeriesSequences;
          if (ok) {
            this.setState({ loading: false, sequence: null });
            this.props.handleSeriesMapped(sequence);
          } else {
            throw errors;
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    })();
  };
  render() {
    const { sequence, loading } = this.state;
    const { open, handleClose } = this.props;
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Map Series Sequence
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              To map the sequence, please select the option and press Map.
            </DialogContentText>
            <Sequence
              handleSelectedChanged={this.handleSequenceChanged}
              holder={this.holder}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <ButtonLoad
              // className={classes.button}
              disable_button={sequence ? false : true}
              handleClick={this.handleSetMapping}
              loading={loading}
              name="Map"
              variant="text"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditSeriesSequences.propTypes = {
  classes: PropTypes.object,
};

export default EditSeriesSequences;
