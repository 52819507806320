import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { SeriesFileReady, SeriesFileSkeleton } from './components';

const width = 90;
const useStyles = () => ({
  selected: {
    width: width - 2,
  },
  unselected: {
    width: width,
    height: 90,
  },
});

class SeriesFile extends Component {
  render() {
    const { item, index, selected } = this.props;
    let defaultProps = {};
    const border = index === selected;
    if (border && item) {
      defaultProps = {
        bgcolor: 'grey.500',
        border: 3,
        style: { borderColor: 'red' },
      };
    }
    if (item) {
      return (
        <SeriesFileReady
          border={border}
          width={width}
          {...this.props}
          defaultProps={defaultProps}
        />
      );
    } else {
      return <SeriesFileSkeleton width={width} />;
    }
  }
}

SeriesFile.defaultProps = {
  index: 0,
  selected: -1,
  Modality: 'MR',
};
SeriesFile.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
};

export default withStyles(SeriesFile, useStyles);
