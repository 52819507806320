import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { v1 as uuid } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import { GetData, ServerErrors } from 'helpers';
import { QueryGroupMapping } from 'graphql/Mapping';

class ComboxGroupMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      value: props.value ? props.value : undefined,
      mappings: [],
      inputValue: props.default_input,
      group_name: props.group_name ? props.group_name : '',
    };
  }
  componentDidMount() {
    const { group_name, value } = this.props;
    let name = null;
    if (value) name = value.name;
    this.runLoadGroupClinics(name, group_name);
  }
  componentDidUpdate() {
    const { value } = this.props;
    if (value) {
      let new_value = null;
      if (this.state.value === undefined) new_value = value;
      else if (value.name !== this.state.value.name) new_value = value;
      if (new_value) {
        this.setState({
          value,
          inputValue: value.name,
        });
      }
    }
  }
  runLoadGroupClinics = (name, newGroupName = null) => {
    let { loading, group_name } = this.state;
    if (loading) {
      if (name) this.setState({ inputValue: name });
      return;
    }
    if (newGroupName && newGroupName !== '') {
      group_name = newGroupName;
    }
    this.setState({ loading: true });
    (async () => {
      QueryGroupMapping(group_name, name, 0, 10)
        .then(res => {
          const data = GetData(res);
          const { ok, mappings, errors } = data.allGroupMapping;
          if (ok) {
            let value = this.state.value;
            if (name) {
              const i = mappings.map(x => x.name).indexOf(name);
              if (i !== -1) value = mappings[i];
            }
            this.setState({
              value,
              loading: false,
              mappings,
              inputValue: name ? name : '',
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            open: false,
            loading: false,
            error: ServerErrors(error),
          });
        });
    })();
  };
  handleTextChange = (event, newInputValue) => {
    if (!event) return;
    event.preventDefault();
    if (
      newInputValue === null ||
      newInputValue === undefined ||
      newInputValue === ''
    ) {
      newInputValue = ''; //this.props.default_input;
    }
    console.log({ newInputValue });
    if (newInputValue !== this.state.inputValue) {
      this.setState({ inputValue: newInputValue });
      if (newInputValue === '') {
        this.handleOnChange(event, null);
      }
    }
  };
  handleOnChange = (event, newValue) => {
    event.preventDefault();
    if (newValue === null || newValue === undefined) {
      newValue = null;
    }
    const { mappings, group_name } = this.state;
    console.log('handleOnChange', { group_name });
    let idGroup = -1;
    if (mappings && mappings.length) {
      idGroup = mappings[0].idGroup;
    }
    this.props.handleGroupMappingSelected(idGroup, group_name, newValue);
    this.setState({ value: newValue });
  };
  handleChange = event => {
    const name = event.target.value;
    if (name !== this.state.inputValue) {
      this.runLoadGroupClinics(name);
    }
  };
  render() {
    let { inputValue, value, mappings, loading } = this.state;
    // let idGroup = -1;
    // if (mappings && mappings.length) {
    //   idGroup = mappings[0].idGroup;
    // }
    if (!mappings.length && value) {
      inputValue = value.name;
      mappings.push(value);
    } else if (inputValue !== '' && value && value.name !== inputValue) {
      if (!mappings.length) mappings.push(value);
      else {
        const index = mappings.map(x => x.name).indexOf(inputValue);
        if (index !== -1) value = mappings[index];
      }
    }
    return (
      <Autocomplete
        getOptionLabel={option => option.name}
        id={`group_map_${uuid()}`}
        inputValue={inputValue ? inputValue : ''}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={this.state.loading}
        onChange={this.handleOnChange}
        onInputChange={this.handleTextChange}
        options={mappings}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            label={this.props.label}
            onChange={this.handleChange}
          />
        )}
        sx={{ width: '100%' }}
        value={value}
      />
    );
  }
}

ComboxGroupMapping.propTypes = {
  default_input: PropTypes.string,
  group_name: PropTypes.string,
  handleGroupMappingSelected: PropTypes.func,
  label: PropTypes.string,
};
ComboxGroupMapping.defaultProps = {
  default_input: 'None',
  handleGroupMappingSelected: () => '',
  value: undefined,
};

export default ComboxGroupMapping;
