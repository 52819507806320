import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Divider,
  Grid,
  Link,
  ListItemText,
  Typography,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { IOSSwitch } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { GenerateDownload, MutationDownloadFinished } from 'graphql/Download';
import { IsUserDownloadLink } from 'helpers';

class DownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sharing: false,
      switch_link: false,
      download_link: null,
    };
  }

  getLink = () => {
    const { folder_content, folder } = this.props;
    if (folder_content) {
      const { download_link } = folder_content;
      if (IsInvalid(download_link) || download_link === '') {
        return null;
      } else {
        return download_link;
      }
    } else if (folder) {
      const { download_link } = folder;
      if (IsInvalid(download_link) || download_link === '') {
        return null;
      } else {
        return download_link;
      }
    }
    return null;
  };
  getIDs = () => {
    const { folder_content, folder } = this.props;
    if (folder_content) {
      const { idFolderContent } = folder_content;
      return { idFolder: null, idFolderContent };
    } else if (folder) {
      const { idFolder } = folder;
      return { idFolder, idFolderContent: null };
    }
    return null;
  };

  componentDidMount() {
    const download_link = this.getLink();
    if (download_link && download_link !== '') {
      this.setState({ switch_link: true, download_link });
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (!IsUserDownloadLink(user)) {
      return null;
    }
    const { switch_link, sharing } = this.state;
    if (sharing) return;
    const download_link = this.getLink();
    // console.log({ download_link, switch_link });
    if (download_link && !switch_link) {
      this.setState({ switch_link: true });
    } else if (!download_link && switch_link) {
      this.setState({ switch_link: false });
    }
  }

  handleShareLink = () => {
    let switch_link = !this.state.switch_link;
    if (switch_link) {
      this.runCreateDownloadLink(switch_link);
    } else {
      this.runRemoveDownloadLink(switch_link);
    }
  };
  runCreateDownloadLink = switch_link => {
    const { sharing } = this.state;
    const ids = this.getIDs();
    if (sharing || !ids) return;
    this.setState({ sharing: true, switch_link });
    (async () => {
      GenerateDownload(ids, true)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, key } = data.downloadGenerate;
          if (ok) {
            this.props.handleDownloadLinkChanged(ids, key);
            this.setState({ sharing: false, download_link: key });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ sharing: false, link: null });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };
  runRemoveDownloadLink = switch_link => {
    const { sharing } = this.state;
    const download_link = this.getLink();
    const ids = this.getIDs();
    if (sharing || !download_link || !ids) return;
    this.setState({ sharing: true, switch_link });
    (async () => {
      MutationDownloadFinished(download_link)
        .then(res => {
          //
          const data = GetData(res);
          const ok = data.downloadFinished;
          if (ok) {
            this.props.handleDownloadLinkChanged(ids, null);
            this.setState({ sharing: false });
          } else {
            throw 'Error removing the download link';
          }
        })
        .catch(error => {
          this.setState({ sharing: false });
          this.props.handleError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { user } = this.props;
    if (!IsUserDownloadLink(user)) {
      return null;
    }
    const download_link = this.getLink();
    const { switch_link, sharing } = this.state;
    let ShareLink = false;
    let code = null;
    if (download_link) {
      code = download_link ? 'Creating...' : 'Removing...';
      if (!sharing) {
        code = download_link;
      }
      if (code) {
        ShareLink = true;
      }
    }
    return (
      <React.Fragment>
        <Divider />
        <ListItemText
          primary={
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between">
              {ShareLink ? (
                <Grid
                  container
                  style={{
                    padding: 0,
                    margin: 0,
                    maxWidth: 200,
                  }}>
                  <LinkIcon style={{ marginRight: 10 }} />
                  <Link
                    component="button"
                    onClick={() => {
                      this.props.handleGetDownloadLink(download_link);
                    }}>
                    Get Download Link
                  </Link>
                </Grid>
              ) : sharing ? (
                <CircularProgress size={25} style={{ marginRight: 10 }} />
              ) : (
                <Typography
                  gutterBottom
                  style={{ marginTop: 8, fontWeight: 1000 }}
                  variant="h6">
                  Download Link
                </Typography>
              )}

              <IOSSwitch
                checked={switch_link}
                disabled={sharing}
                onChange={this.handleShareLink}
              />
            </Grid>
          }
        />

        <Divider />
      </React.Fragment>
    );
  }
}

DownloadLink.propTypes = {
  folder: PropTypes.object,
  folder_content: PropTypes.object,
  handleDownloadLinkChanged: PropTypes.func,
  handleGetDownloadLink: PropTypes.func,
};
DownloadLink.defaultProps = {
  handleGetDownloadLink: () => '',
  handleDownloadLinkChanged: () => '',
  folder: null,
  folder_content: null,
};
export default DownloadLink;
