import React from 'react';
import PropTypes from 'prop-types';
import { CardEditHeader, TableGrid } from 'components';
import { Card, CardContent, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  columns,
  GenerateRows,
  GetColumn,
  GetRow,
  GetRowValues,
  GetSearchColumn,
  SetRowColumn,
} from './Styles';
import ToolBarSearchConsents from './ToolBarSearchConsents';
import { rowsPerPageBig } from 'helpers';
import { IsInvalid } from 'helpers';
import { FormatTime } from 'helpers';
class DialogSearchConsents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: GenerateRows(),
      selected: [],
      cell: null,
      new_rows: [],
      rows_edited: [],
      limit: 100,
    };
  }
  handleOnCellClicked = row_cell => {
    const {
      cell,
      row: { id },
    } = row_cell;
    console.log({ cell, id });
    this.setState({ cell });
  };
  handleOnAddNewRows = add_rows => {
    const { rows } = this.state;
    console.log('handleOnAddNewRows', { add_rows });
    for (let i = 0; i < add_rows.length; i++) {
      const { id } = add_rows[i];
      const index = rows.map(x => x.id).indexOf(id);
      if (index === -1) {
        rows.push(add_rows[i]);
      }
    }
    this.setState({ rows });
  };
  handleClean = () => {
    this.setState({ rows: [...GenerateRows()], rows_edited: [] });
  };
  handleAddPatientConsents = patient_consents => {
    const { rows_edited } = this.state;
    // console.log({ patient_consents });
    const found_column = GetSearchColumn('Search Consent', rows_edited, this);
    const consent_column = GetSearchColumn('Consent', rows_edited, this);
    const info_column = GetSearchColumn('Information', rows_edited, this);
    const date_column = GetSearchColumn('Updated', rows_edited, this);
    for (let i = 0; i < patient_consents.length; i++) {
      const {
        type,
        information,
        updatedAt,
        encrypted_patient,
      } = patient_consents[i];
      if (IsInvalid(encrypted_patient)) continue;
      const keys = GetRowValues(0, rows_edited);
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];
        if (IsInvalid(key) || key === '') continue;
        const column = GetColumn(key, rows_edited);
        const patient_value = encrypted_patient[key];
        if (IsInvalid(patient_value) || patient_value === '') continue;
        const row = GetRow(patient_value, column, rows_edited);
        // const value = GetRowColumn(row, column, rows_edited);
        // console.log({ row, column, value });
        if (row > 0 || column > 0) {
          SetRowColumn('Found', row, found_column, rows_edited);
          SetRowColumn(type, row, consent_column, rows_edited);
          SetRowColumn(information, row, info_column, rows_edited);
          SetRowColumn(
            FormatTime(updatedAt, 'MMM Do - YYYY, h:mm a'),
            row,
            date_column,
            rows_edited
          );
          this.setState({ rows_edited });
        }
      }
    }
  };
  render() {
    const { height } = this.props;
    const { rows } = this.state;
    return (
      <Card>
        <CardEditHeader
          handleClose={this.props.handleClose}
          icon={<SearchIcon style={{ color: 'green' }} />}
          title="Search Patient Consent"
        />
        <Divider />
        <CardContent>
          <ToolBarSearchConsents
            handleAddError={this.props.handleAddError}
            handleAddPatientConsents={this.handleAddPatientConsents}
            handleClean={this.handleClean}
            rows={rows}
            rows_edited={this.state.rows_edited}
            selected={this.state.selected}
            setState={state => this.setState(state)}
          />
          <TableGrid
            cell_selection
            checkboxSelection
            dense
            disableSelectionOnClick
            external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            grid
            headers={columns}
            height={height - 100}
            onAddNewRows={this.handleOnAddNewRows}
            onCellClicked={this.handleOnCellClicked}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onSelectedChanged={selected => this.setState({ selected })}
            paste
            row_height={33}
            rows={rows}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </CardContent>
      </Card>
    );
  }
}

DialogSearchConsents.propTypes = {
  handleClose: PropTypes.func,
};

export default DialogSearchConsents;
