import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from 'tss-react/mui';

const options = ['All', 'With NIfTI', 'Without NIfTI'];
const useStyles = () => ({
  root: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      padding: 0,
      margin: 0,
    },
    '& .MuiButtonBase-root.MuiAutocomplete-popupIndicator': {
      padding: 0,
      margin: 0,
    },
  },
});
class ImageNIfTI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: options[0],
      inputValue: '',
    };
    const { holder } = props;
    if (holder) {
      holder.getNifti = () => this.state.inputValue;
    }
  }

  render() {
    const { classes } = this.props;
    const { inputValue, value } = this.state;
    return (
      <Autocomplete
        className={classes.root}
        id="auto-nifti-options"
        inputValue={inputValue}
        onChange={(event, value) => {
          this.setState({ value });
        }}
        onInputChange={(event, inputValue) => {
          this.setState({ inputValue });
        }}
        options={options}
        renderInput={params => <TextField {...params} label="" />}
        size="small"
        sx={{ minWidth: 170, maxWidth: 300 }}
        value={value}
      />
    );
  }
}
// sx={{ width: 300 }}

ImageNIfTI.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ImageNIfTI, useStyles);
