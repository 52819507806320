import { IsInvalid, IsInvalidParam } from 'helpers';
import { FormatTime, getUserFullName } from 'helpers';
export const columns_batches = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'job_name',
    headerName: 'BATCH Name',
    width: 75,
    align: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 75,
    align: 'left',
    type: 'status',
    color: value => {
      if (IsInvalid(value) || value === '') return 'neutral';
      const i = Object.keys(statusColors).indexOf(value);
      if (i === -1) return 'neutral';
      return statusColors[value];
    },
  },
  {
    field: 'total_jobs',
    headerName: '#Jobs',
    width: 75,
    align: 'left',
  },
  {
    field: 'jobs_waiting',
    headerName: '#Waiting',
    width: 75,
    align: 'left',
  },
  {
    field: 'jobs_running',
    headerName: '#Running',
    width: 75,
    align: 'left',
  },
  {
    field: 'jobs_error',
    headerName: '#Errors',
    width: 75,
    align: 'left',
  },
  {
    field: 'jobs_finished',
    headerName: '#Finished',
    width: 75,
    align: 'left',
  },
  {
    field: 'setting',
    headerName: 'Setting',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'setting');
      if (IsInvalid(value)) return 'NO';
      return 'YES';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
const statusColors = {
  finished: 'success', //green
  running: 'info', //orange
  error: 'danger', //red
  failed: 'danger', //red
  waiting: 'warning', //warning
  neutral: 'neutral',
  purple: 'purple',
};

export const columns_batch_jobs = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'user');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  { field: 'idBatch', headerName: 'BatchID', width: 75, align: 'left' },
  {
    field: 'job',
    headerName: 'JOB',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const batch = params.getValue(params.id, 'batch');
      if (batch === undefined || !batch) return '';
      return batch.job_name;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 75,
    align: 'left',
    type: 'status',
    color: value => {
      if (IsInvalid(value) || value === '') return 'neutral';
      const i = Object.keys(statusColors).indexOf(value);
      if (i === -1) return 'neutral';
      return statusColors[value];
    },
  },
  {
    field: 'objecttype',
    headerName: 'Object Type',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let type = params.getValue(params.id, 'series');
      if (type) return `Series (${type.idSeries})`;
      type = params.getValue(params.id, 'file_object');
      if (type) return `File (${type.idFileObject})`;
      return 'Not found';
    },
  },
  {
    field: 'file_name',
    headerName: 'Object Name',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let type = params.getValue(params.id, 'series');
      if (type) return type.SeriesDescription;
      type = params.getValue(params.id, 'file_object');
      if (type) return type.original_name;
      return 'Not found';
    },
  },
  {
    field: 'folder',
    headerName: 'Folder',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const folder = params.getValue(params.id, 'folder');
      if (!IsInvalid(folder)) {
        const { folders_paths } = folder;
        if (IsInvalid(folders_paths) || !folders_paths.length)
          return folder.name;
        return folders_paths.map(x => x.name).join('/');
      }
      const setting = params.getValue(params.id, 'setting');
      if (IsInvalid(setting)) return 'Not found';
      const { folder_content } = setting;
      if (IsInvalid(folder_content)) return 'Not found';
      const { Folder } = folder_content;
      if (IsInvalid(Folder)) return 'Not found';
      const { folders_paths } = Folder;
      if (IsInvalid(folders_paths) || !folders_paths.length) return Folder.name;
      return folders_paths.map(x => x.name).join('/');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
