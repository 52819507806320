import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, CardActions } from '@mui/material';
import { CardEditHeader } from 'components';

class EditFileVariableMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card>
        <CardEditHeader
          handleClose={this.props.handleCloseEdit}
          title="Edit File Variable Mapping"
        />
        <Divider />
        <CardContent>HOLA</CardContent>
        <CardActions disableSpacing>
          {/* <EditBottom
            handleAddError={this.props.handleAddError}
            handleDisableAll={disable_all => this.setState({ disable_all })}
            handleUpdatedMapping={this.handleUpdatedMapping}
            original={this.state.selected}
            to_edit={this.state.to_edit}
          /> */}
        </CardActions>
      </Card>
    );
  }
}

EditFileVariableMap.propTypes = {
  classes: PropTypes.object,
};
EditFileVariableMap.defaultProps = {
  handleCloseEdit: () => '',
};
export default EditFileVariableMap;
