import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

class DialogShowColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChange = event => {
    const field = event.target.name;
    console.log({ t: event.target.name });
    const { headers, headers_to_hide } = this.props;
    let header = null;
    let index = headers_to_hide.map(x => x.field).indexOf(field);
    if (index !== -1) {
      header = headers_to_hide[index];
    } else {
      index = headers.map(x => x.field).indexOf(field);
      if (index !== -1) {
        header = headers[index];
      }
    }
    this.props.onShowHideColumn(header);
  };
  IsChecked = header => {
    const { headers_to_hide } = this.props;
    const index = headers_to_hide.map(x => x.field).indexOf(header.field);
    return index === -1;
  };
  render() {
    const { open, handleClose, headers } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        onClose={handleClose}
        open={open}
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}>
        <DialogTitle>Show Table Columns</DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {headers.map((header, index) => {
                const checked = this.IsChecked(header);
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        name={header.field}
                        onChange={this.handleChange}
                      />
                    }
                    key={`header-column-${index}`}
                    label={header.headerName}
                  />
                );
              })}
            </FormGroup>
            <FormHelperText>
              Click in the switch to show/hide column
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button> */}
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogShowColumns.propTypes = {
  id: PropTypes.string,
  keepMounted: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.string,
};
DialogShowColumns.defaultProps = {
  id: '',
  keepMounted: false,
  value: '',
  open: false,
  onClose: () => '',
};
export default DialogShowColumns;
