import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { ZoomOutMap } from '@mui/icons-material';
import { CardHeaderCustom } from 'components';

class CardProjectHeader extends Component {
  render() {
    return (
      <CardHeaderCustom
        action={
          <IconButton
            id="members"
            name={this.props.title}
            onClick={this.props.handleMaximize}
            size="large"
            style={{
              marginTop: '15px',
              marginRight: '10px',
              padding: 0,
              marginBottom: 0,
            }}>
            <ZoomOutMap />
          </IconButton>
        }
        style={{ paddingBottom: '10px', marginBottom: 0 }}
        subheader={this.props.subtitle}
        title={this.props.title}
      />
    );
  }
}

CardProjectHeader.propTypes = {
  classes: PropTypes.object,
};
CardProjectHeader.defaultProps = {
  title: 'Title Missing',
  subtitle: 'SubTitle Missing',
};

export default CardProjectHeader;
