import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const UsersByEmail = gql`
  query($email: String!, $filter: Boolean) {
    allUsers(email: $email, filter: $filter) {
      ok
      total
      errors {
        path
        message
      }
      users {
        id
        FullName
        email
        avatar
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUsersByEmail = (email, filter) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(UsersByEmail),
    variables: {
      email,
      filter,
    },
  });
};

const QUERY_IS_EMAIL_HERE = gql`
  query($email: String!) {
    IsEmailHere(email: $email)
  }
`;

export const IsEmailHere = email => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_IS_EMAIL_HERE),
    variables: {
      email,
    },
  });
};

const QueryUsersQuery = gql`
  query($InputUser: InputUser!) {
    users(InputUser: $InputUser) {
      id
      FullName
      email
      avatar
      createdAt
      updatedAt
    }
  }
`;

export const QueryUsers = InputUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QueryUsersQuery),
    variables: {
      InputUser,
    },
  });
};

const MUTATION_LOGOUT = gql`
  mutation($idUser: LongLong!) {
    logout(idUser: $idUser) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationLogOut = idUser => {
  if (!idUser || typeof idUser === 'undefined') {
    return;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_LOGOUT),
    variables: {
      idUser,
    },
  });
};

const QUERY_USER_SETTING = gql`
  query($idUser: LongLong!) {
    UserSetting(idUser: $idUser) {
      ok
      setting {
        idSetting

        idUser

        type {
          id
          code
          description
        }
        category {
          id
          code
          name
        }
        storage
        file_size
        used
        devices
        files
        dicoms
        volume_to_dcm

        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryUserSetting = idUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_SETTING),
    variables: {
      idUser,
    },
  });
};
const MUTATION_SAVE_USER_SETTING = gql`
  mutation($settings: InputSetting!) {
    UpdateSetting(settings: $settings) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateSetting = inputs => {
  // console.log({ inputs });
  const { type, category } = inputs;
  const settings = {
    ...inputs,
    idType: type.id,
    idCategory: category.id,
  };
  delete settings.idUser;
  delete settings.createdAt;
  delete settings.updatedAt;
  delete settings.type;
  delete settings.category;
  console.log({ settings });

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SAVE_USER_SETTING),
    variables: {
      settings,
    },
  });
};
const QUERY_ME = gql`
  query {
    me {
      ok
      user {
        id
        FullName
        firstName
        lastName
        username
        email
        type
        idCategory
        # teams: [Team!]
        # collaborators: [User!]
        phone
        photo
        address {
          city
          country
        }
        category {
          id
          code
          name
        }
        avatar
        bio
        timezone
        # setting: UserSetting
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryMe = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ME),
    // variables: {
    //   settings,
    // },
  });
};

const QUERY_ACCOUNT_TYPES = gql`
  query {
    accountTypes {
      ok
      errors {
        path
        message
      }
      accounts {
        id
        code
        description
      }
    }
  }
`;

export const QueryAccountTypes = () => {
  console.log('QueryAccountTypes');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ACCOUNT_TYPES),
    // variables: {
    //   idUser,
    // },
  });
};
const MUTATION_DELETE_USERS = gql`
  mutation($ids: [LongLong!]!) {
    deleteUsers(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteUsers = ids => {
  console.log('MutationDeleteUsers');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_USERS),
    variables: {
      ids,
    },
  });
};
