import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { GroupAdd } from '@mui/icons-material';

import { DivGrow, SearchUsersInput } from 'components';
import { withStyles } from 'tss-react/mui';

const useStyles = theme => ({
  root: {
    margin: theme.spacing(2),
    pading: 0,
    width: '98.5%',
  },
  searchInput: {},
});

class UsersToolbar extends Component {
  render() {
    const { classes, handleOpenInviteUser, handleAddUser } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" item xs={12}>
          <SearchUsersInput handleAddUser={handleAddUser} />
          <DivGrow />
          <Button
            color="primary"
            endIcon={<GroupAdd />}
            onClick={() => handleOpenInviteUser(true)}
            variant="contained">
            Invite User
          </Button>
        </Grid>
      </div>
    );
  }
}

UsersToolbar.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(UsersToolbar, useStyles);
