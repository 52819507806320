import {
  PAGE_SETTING_DASHBOARD_SET,
  PAGE_SETTING_DASHBOARD_MODE,
  PAGE_SETTING_DASHBOARD_PAGE_DESIGNER,
} from './types';

export const setDashboardSetting = dashboard_view => dispatch => {
  dispatch({
    type: PAGE_SETTING_DASHBOARD_SET,
    dashboard_view,
  });
};
export const setDashboardViewMode = visualization_mode => dispatch => {
  dispatch({
    type: PAGE_SETTING_DASHBOARD_MODE,
    visualization_mode,
  });
};
export const setDashboardPageDesigner = (page, limit) => dispatch => {
  dispatch({
    type: PAGE_SETTING_DASHBOARD_PAGE_DESIGNER,
    page_designer: page,
    limit_designer: limit,
  });
};
