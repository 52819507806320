import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { CircularProgress, Grid } from '@mui/material';

import useStyles from '../../../utils/StyleOverview';
import { LoadProject } from 'graphql/Projects';
import { GetData, ServerErrorsString } from 'helpers';
import { OverviewMain, OverviewToolBar } from './components';

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: { id: -1 },
    };
    const { holder } = props;
    if (holder) {
      holder.getProjectName = () => {
        const {
          project: { id, name },
        } = this.state;
        if (id === -1) return '';
        if (typeof name === 'undefined') return '';
        return name;
      };
    }
  }

  componentDidMount() {
    const { project } = this.props;
    if (!project) {
      /* empty */
    } else if (project.id < 1) {
      this.props.history.push('/project_management/browser');
    } else if (project.id > 0 && this.state.project.id === -1) {
      this.setState({ project });
    } else if (project.id !== this.state.project.id) {
      this.loadProject();
    }
  }

  getProjectProps = () => {
    const { project } = this.props;
    if (
      typeof project !== 'undefined' &&
      project &&
      typeof project.id !== 'undefined'
    ) {
      return project;
    }
    return null;
  };

  componentDidUpdate() {
    const { loading } = this.state;
    if (loading) return;
    const { idProject, project } = this.props;
    // console.log({ idProject, project, s_project: this.state.project });
    if (idProject < 1) {
      this.props.history.push('/project_management/browser');
    } else if (project && project.id !== this.state.project.id) {
      this.setState({ loading: false, project });
    } else if (idProject > 0 && idProject !== this.state.project.id) {
      this.loadProject();
    }
  }

  loadProject = () => {
    const { idProject } = this.props;
    if (idProject < 1) {
      this.props.history.push('/project_management/browser');
      return;
    }
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      LoadProject(idProject)
        .then(res => {
          const data = GetData(res);
          const { ok, Project, errors } = data.loadProject;
          if (ok) {
            this.setState({ loading: false, project: Project });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          const text_error = ServerErrorsString(error);
          this.setState({ loading: false });
          this.props.handleAddError(text_error);
          if (text_error.indexOf('You are not in this project') !== -1) {
            this.props.history.push('/project_management/browser');
          }
        });
    })();
  };
  projectChanged = project => {
    this.setState({ project });
    this.props.projectChanged(project);
  };

  render() {
    const { classes, user, idProject, height } = this.props;
    const { project, loading } = this.state;
    const aboutload = idProject > 0 && idProject !== project.id;
    // console.log({ loading });
    return (
      <div className={classes.main_container}>
        <OverviewToolBar loading={loading} project={project} user={user} />
        {loading || aboutload ? (
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={3}
            style={{ width: '100%' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <OverviewMain
            handleAddError={this.props.handleAddError}
            height={height}
            project={project}
            projectChanged={this.projectChanged}
            user={user}
          />
        )}
      </div>
    );
  }
}

Overview.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  idProject: PropTypes.number.isRequired,
};
Overview.defaultProps = {
  idProject: -1,
};
export default withStyles(withRouter(Overview), useStyles);
