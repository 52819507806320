import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ButtonLoading, SnackMessage } from 'components';
import { RenameFolderMutation } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';

export default function DialogRenameFolder(props) {
  const [error, setError] = React.useState('');
  const [warning, setWarning] = React.useState('');
  const [folder_name, setFolderName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { open, handleClose, ReNameFolder } = props;
  if (!ReNameFolder) return null;
  const { idFolder, name } = ReNameFolder;
  if (idFolder === undefined) {
    return null;
  }
  const handleReName = event => {
    if (event) {
      const { textContent } = event.target;
      if (textContent === '' || textContent === 'Cancel') {
        setError('Cancel by the user');
        handleClose(false);
        return;
      }
    }
    if (folder_name === '') {
      setError('The folder name is empty');
      handleClose(false);
      return;
    }
    handleRunRenameFolder();
  };
  const handleRunRenameFolder = () => {
    if (loading) return;
    setLoading(true);
    (async () => {
      RenameFolderMutation(idFolder, folder_name)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.renameFolder;
          if (ok) {
            handleClose(false, { idFolder, name: folder_name });
            setLoading(false);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          setLoading(false);
          setError(ServerErrorsString(errors));
        });
    })();
  };
  const handleSnaksClose = () => {
    setError('');
    setWarning('');
  };
  const handleChange = event => {
    setFolderName(event.target.value);
  };
  const keyPress = event => {
    if (event.keyCode === 13) {
      handleReName();
    }
  };
  return (
    <div>
      <SnackMessage
        handleClose={handleSnaksClose}
        message_text={error}
        open={error !== '' ? true : false}
        type="error"
      />
      <SnackMessage
        handleClose={handleSnaksClose}
        message_text={warning}
        open={warning !== '' && !loading ? true : false}
        type="warning"
      />
      <Dialog
        aria-labelledby="form-dialog-title"
        name="a"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">Re-Name Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Re-name folder:{' '}
            <strong>
              {name} (ID: {idFolder})
            </strong>
            , please enter new the name.
          </DialogContentText>
          <TextField
            autoFocus
            disabled={loading}
            fullWidth
            id="name"
            label="New Folder Name"
            margin="dense"
            onChange={handleChange}
            onKeyDown={keyPress}
          />
        </DialogContent>
        <DialogActions disabled={loading}>
          <Button color="primary" name="Cancel" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoading
            handleClick={handleReName}
            loading={loading}
            name="Re-Name"
            transform={false}
            variant="standard"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
