import React from 'react';
import { FormControlLabel, FormGroup, Tooltip } from '@mui/material';
import { IOSSwitch } from 'components';

const SwitchHomeShared = props => {
  //
  const [state, setState] = React.useState({
    home_shared: props.home_shared,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      props.handleSwitch('shared');
    } else {
      props.handleSwitch('home');
    }
  };

  return (
    <Tooltip title="Home/Shared">
      <FormGroup>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={state.home_shared}
              name="home_shared"
              onChange={handleChange}
            />
          }
          label=""
        />
      </FormGroup>
    </Tooltip>
  );
};

export default SwitchHomeShared;
