import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTable } from 'components/Tables/components';
import clsx from 'clsx';
import { SelectedArray, rowsPerPageBig } from 'helpers';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { columns_files } from 'views/Administrator/components/utils';
class TableSiteOrphans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      selected: [],
    };
    const { holder } = props;
    if (holder) {
      holder.getSelected = () => this.state.selected;
      holder.clean = () => this.setState({ selected: [] });
    }
  }
  isSelected = orphan => {
    const { selected } = this.state;
    const index = selected
      .map(x => x.idFileObject)
      .indexOf(orphan.idFileObject);
    return index !== -1;
  };
  handleSelectedClick = (event, index, orphan) => {
    event.preventDefault();
    // console.log({ event, index, row });
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.idFileObject).indexOf(orphan.idFileObject)
      : 0;
    const newSelected = SelectedArray(selected, orphan, selectedIndex);
    this.setState({ selected: newSelected });
    this.props.handleSelected(newSelected);
  };
  handleSelectAllClick = event => {
    let selected = [];
    if (event.target.checked) {
      const { orphans } = this.props;
      selected = orphans;
    }
    this.setState({ selected });
    this.props.handleSelected(selected);
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    this.props.handleChangePageLimit(newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
    this.props.handleChangePageLimit(0, +event.target.value);
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
    this.props.handleSelected([]);
  };
  render() {
    const { classes, loading, orphans, height } = this.props;
    const { page, limit, selected } = this.state;
    let style = {};
    if (height) {
      style = { height, maxHeight: height };
    }
    return (
      <Paper className={classes.root}>
        <div
          className={clsx({
            [classes.tableWrapper]: true,
            [classes.max_height]: height,
          })}
          style={{ ...style }}>
          <MainTable
            columns={columns_files}
            dense
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={limit}
            loading={loading}
            page={page}
            rows={orphans}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={orphans.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableSiteOrphans.propTypes = {
  classes: PropTypes.object,
};
TableSiteOrphans.defaultProps = {
  handleChangePageLimit: () => console.log('handleChangePageLimit'),
  handleDeleteSelected: () => console.log('handleChangePageLimit'),
  handleReloadTable: () => console.log('handleReloadTable'),
  deleting: false,
  loading: false,
  orphans: [],
  holder: null,
};

export default withStyles(TableSiteOrphans, useStyles);
