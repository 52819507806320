import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

import {
  ToolBarSharedBottom,
  ToolBarSharedTop,
  handleDownloadSelected,
  default_state,
  theme,
} from './components';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

class ToolBarShared extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...default_state,
    };
  }
  handleDownloadSelected = () => {
    this.setState({ ...default_state, ready: [], totals: [] }, () => {
      if (this.props.holder.setClean) {
        this.props.holder.setClean();
      }
      handleDownloadSelected(0, this);
    });
  };
  getIndexSelected = item => {
    const { selected } = this.props;
    const find = `${item.idFolder}-${item.idFolderContent}`;
    const index = selected
      .map(x => {
        const { folder, content } = x;
        if (folder) return `${folder.idFolder}-${undefined}`;
        if (content) return `${undefined}-${content.idFolderContent}`;
        return null;
      })
      .indexOf(find);
    return index;
  };
  handleProgress = (event, item_downlading) => {
    const { selected, holder } = this.props;
    const { totals } = this.state;
    let { loaded, total } = event;
    const index = this.getIndexSelected(item_downlading);
    const jj = totals.map(x => x.index).indexOf(index);
    let t = 0;
    if (jj != -1) t = totals[jj].total;
    if (total < loaded) {
      if (t === 0 || loaded > t) {
        total = loaded + loaded * 0.4;
        totals.push({
          index,
          total,
        });
        this.setState({ totals });
      }
    }
    if (isNaN(total) || total === 0 || total === undefined) {
      let total_downloaded = loaded + loaded * 0.1;
      if (index !== -1) {
        const { folder, content } = selected[index];
        if (folder) total_downloaded = folder.Size;
        if (content) total_downloaded = content.Size;
      }
      total = total_downloaded;
    }
    if (holder.setProgress) {
      holder.setProgress({ loaded, total }, true, item_downlading);
    }
  };
  render() {
    const {
      handleSort,
      orderBy,
      order,
      selected,
      code,
      link,
      handleReload,
      isMobile,
      folder,
      home,
      idFolder,
    } = this.props;
    let { loading } = this.props;
    if (!loading) {
      loading = folder ? folder.idFolder > 0 : idFolder > 0;
    }
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Paper square>
            {loading && (
              <ToolBarSharedTop code={code} folder={folder} home={home} />
            )}
            <ToolBarSharedBottom
              code={code}
              downloading={this.state.downloading}
              handleDownloadOneAtaTime={this.props.handleDownloadOneAtaTime}
              handleDownloadSelected={this.handleDownloadSelected}
              handleReload={handleReload}
              handleSort={handleSort}
              handleViewType={this.props.handleViewType}
              isMobile={isMobile}
              link={link}
              onDownloadFinished={this.props.onDownloadFinished}
              order={order}
              orderBy={orderBy}
              selected={selected}
            />
          </Paper>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

ToolBarShared.propTypes = {
  classes: PropTypes.object,
};
ToolBarShared.defaultProps = {
  handleDownloadOneAtaTime: () => '',
  onDownloadFinished: () => '',
};
export default ToolBarShared;
