import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  TabDockerImageDetails,
  TabDockerImageFile,
  TabDockerImageLayers,
  useStyles,
  ViewDockerImageBottom,
  ViewDockerImageHeader,
} from './components';
import { QueryDockerImage } from 'graphql/Dockers/utils_images';
import { GetData, ServerErrorsString } from 'helpers';
import { TabPanel } from 'components';

const tabs = [
  { name: 'details', Component: TabDockerImageDetails, index: 0 },
  { name: 'dockerfile', Component: TabDockerImageFile, index: 1 },
  { name: 'layers', Component: TabDockerImageLayers, index: 2 },
];

class ViewDockerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: null,
      tabValue: 0,
      init: false,
    };
  }

  componentDidMount() {
    const { image } = this.props;
    if (this.state.image || !image || image === undefined) return;
    if (!this.state.image || this.state.image.idImage !== image.idImage) {
      this.queryDockerImage(image.idImage);
    }
  }

  componentDidUpdate() {
    const { image } = this.props;
    if (this.state.init) return;
    if (this.state.image || !image || image === undefined) return;
    if (!this.state.image || this.state.image.idImage !== image.idImage) {
      this.queryDockerImage(image.idImage);
    }
  }

  queryDockerImage = idImage => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryDockerImage(idImage)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, image } = data.dockerImage;

          if (ok) {
            this.setState({ loading: false, image, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    this.setState({ tabValue: parseInt(tabValue, 10) });
  };

  render() {
    const { classes, height, user } = this.props;
    const { image, tabValue } = this.state;

    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    return (
      <Card className={classes.card} style={style}>
        <ViewDockerImageHeader
          classes={classes}
          handleClose={this.props.handleClose}
          image={image}
          loading={this.state.loading}
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <Tabs
              indicatorColor="primary"
              onChange={this.handleTabValue}
              textColor="primary"
              value={tabValue}
              variant="scrollable">
              <Tab label="Details" />
              <Tab label="Dockerfile details" />
              <Tab label="Image Layers" />
            </Tabs>
            <Divider />
            {tabs.map(tab => {
              const { Component, index } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`tab-image-${index}`}
                  value={tabValue}>
                  <Component
                    handleAddError={this.props.handleAddError}
                    height={height - 160}
                    image={image}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <ViewDockerImageBottom handleError={this.props.handleError} />
        </CardActions>
      </Card>
    );
  }
}

ViewDockerImage.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
};
ViewDockerImage.defaultProps = {
  handleClose: () => '',
  handleError: () => '',
};

export default withStyles(ViewDockerImage, useStyles);
