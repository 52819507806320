import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableGrid } from 'components';
import { rowsPerPageBig, GetData, ServerErrorsString } from 'helpers';
import {
  useStyles,
  columns_project_patient,
  ToolBarProjectPatients,
} from './components';
import { withStyles } from 'tss-react/mui';
import { QueryProjectPatients } from 'graphql/Projects';

class TabProjectSettingPatients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: null,
      open_mapping: false,
      loading: false,
      selected: [],
      project_patients: [],
      page: 0,
      limit: 50,
      name: null,
      total: 0,
      init: false,
      row_clicked: null,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    this.queryProjectPatients();
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (!this.state.init) this.queryProjectPatients();
  }
  queryProjectPatients = (newCenter, newPage, newLimit) => {
    const { idProject } = this.props;
    let { loading, page, limit, center } = this.state;
    if (loading) return;
    if (newLimit) limit = newLimit;
    if (newPage !== undefined) page = newPage;
    let idCenter = -1;
    if (newCenter) {
      idCenter = newCenter.id;
      center = newCenter;
    }
    this.setState({ loading: true, center });
    (async () => {
      QueryProjectPatients(idProject, idCenter, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, project_patients, errors, total } = data.projectPatients;
          if (ok) {
            this.setState({
              loading: false,
              project_patients,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            page,
            limit,
            center: null,
            init: true,
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddCenter = center => {
    console.log({ center });
    this.queryProjectPatients(center);
  };
  render() {
    if (!this.props.isActive) return null;
    const { height, idProject } = this.props;
    return (
      <Grid
        alignItems="stretch"
        container
        direction="column"
        item
        justifyContent="flex-start"
        spacing={1}
        xs={12}>
        <Grid item>
          <ToolBarProjectPatients
            center={this.state.center}
            handleAddCenter={this.handleAddCenter}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleReloadTable={() => this.queryProjectPatients()}
            idProject={idProject}
            loading_table={this.state.loading}
            row_clicked={this.state.row_clicked}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_project_patient}
            height={height - 60}
            idRow="idPatient"
            loading={this.state.loading}
            onPageChange={page =>
              this.queryProjectPatients(null, page, this.state.limit)
            }
            onRowClicked={row_clicked => this.setState({ row_clicked })}
            onRowsPerPageChange={limit =>
              this.queryProjectPatients(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.project_patients}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabProjectSettingPatients.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabProjectSettingPatients, useStyles);
