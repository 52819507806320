import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SearchInput, ButtonReloadDelete, BadgeCustom } from 'components';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { styleToolbar } from 'common';
import EEGIcon from '@mui/icons-material/SsidChart';
import ClinicIcon from '@mui/icons-material/Medication';
import ImageIcon from '@mui/icons-material/SensorOccupied';
class ToolBarPatientsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, disabled, view, requests, onlines, selected } = this.props;
    let { numSelected } = this.props;
    if (selected) numSelected = selected.length;
    let search_text = `Search patient with ${view}`;
    if (view === 'people') search_text = 'Search only patients...';
    else if (view === 'clinical') search_text = search_text + ' data';
    search_text = search_text + '...';
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="text alignment"
              disabled={disabled}
              exclusive
              onChange={this.props.handleViews}
              size="small"
              value={this.props.view}>
              <ToggleButton
                aria-label="all-people"
                sx={{ paddingTop: onlines ? 0 : '7px' }}
                value="people">
                <BadgeCustom badgeContent={onlines}>
                  <AccountTreeIcon
                    style={{ color: view === 'people' ? 'green' : null }}
                  />
                </BadgeCustom>
              </ToggleButton>
              <ToggleButton
                aria-label="patient-images"
                sx={{ paddingTop: numSelected ? 0 : '7px' }}
                value="images">
                <BadgeCustom badgeContent={numSelected}>
                  <ImageIcon
                    style={{ color: view === 'images' ? 'green' : null }}
                  />
                </BadgeCustom>
              </ToggleButton>
              <ToggleButton
                aria-label="patient-signals"
                sx={{ paddingTop: numSelected ? 0 : '7px' }}
                value="signals">
                <BadgeCustom badgeContent={numSelected}>
                  <EEGIcon
                    style={{
                      color: view === 'signals' ? 'green' : null,
                    }}
                  />
                </BadgeCustom>
              </ToggleButton>
              <ToggleButton
                aria-label="patient-clinical"
                sx={{ paddingTop: requests ? 0 : '7px' }}
                value="clinical">
                <BadgeCustom badgeContent={requests}>
                  <ClinicIcon
                    style={{
                      color: view === 'clinical' ? 'green' : null,
                    }}
                  />
                </BadgeCustom>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item sx={{ paddingLeft: 5 }} xs={6}>
            <SearchInput
              onChange={this.props.handleChangeName}
              placeholder={search_text}
              value={this.props.name}
            />
          </Grid>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={this.props.numSelected}
            tooltip="Reload USer"
          />
        </Grid>
      </Paper>
    );
  }
}

ToolBarPatientsActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarPatientsActions.defautlProps = {
  name: '',
  handleChangeName: () => '',
  handleOpenCreateUser: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  loading: false,
  loading_table: false,
  numSelected: 0,
};
export default withStyles(ToolBarPatientsActions, styleToolbar);
