export const series_base = `
  idSeries
  idStudy
  nifti
  Owner {
    id
    FullName
  }
  SeriesDate
  SeriesTime
  SeriesInstanceUID
  SeriesDescription
  NumberOfSeriesRelatedInstances
  NumberOfFrames
  Modality
  Size
  CurrentNumberOfFiles
  updatedAt
  createdAt
`;
export const series_full = `
       ${series_base}
       locations {
        idFolderContent
        idFolder
        Folder {
          name
          folders_paths {
            idFolder
            name
          }
        }
       }
       ContrastBolusAgent
        Thumbnail
       
        Patient {
          FamilyName
          GivenName
          PatientID
          PatientBirthDate
        }
        sequence {
          idSequence
          name
        }
        ImageSize {
          Rows
          Columns
          Size
        }
        ImageResolution {
          PixelSpacingX
          PixelSpacingY
          SpacingBetweenSlices
          SliceThickness
          Spacing
        }
        ImageMr {
            ProtocolName
            SequenceName
            MRAcquisitionType
        }
      `;
export const series_full_no_thumb = `
        ${series_base}
        ContrastBolusAgent
        locations {
          idFolderContent
          idFolder
          Folder {
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        Patient {
          FamilyName
          GivenName
          PatientID
          PatientBirthDate
        }
        sequence {
          idSequence
          name
        }
        ImageSize {
          Rows
          Columns
          Size
        }
        ImageResolution {
          PixelSpacingX
          PixelSpacingY
          SpacingBetweenSlices
          SliceThickness
          Spacing
        }
        ImageMr {
            ProtocolName
            SequenceName
            MRAcquisitionType
        }
      `;
