/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DialogDeleteWarning,
  DialogViewEpilepsyCase,
  DialogShowUploadInfo,
  CheckBoxText,
  SearchText,
} from 'components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

import {
  IsAdministrator,
  IsInvalid,
  ServerErrorsString,
  GetData,
  IsValid,
} from 'helpers';
import {
  GetAddResponse,
  GetRemoveResponse,
  GetUploadResponse,
  MutationAddEpilepsy,
  MutationRemoveEpilepsy,
  MutationUploadEpilepsy,
} from './utils_epilepsy';

import { getParams } from 'helpers';
import { getColumnList } from './utils_columns';

import { labels, titleCase } from '../../../components/utils_text';
import { searchEpilepsyTables } from './utils_epilepsy';
import DialogViewClinicalPatient from 'components/Dialogs/DialogViewClinicalPatient';
import LockIcon from '@mui/icons-material/Lock';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import Zoom from '@mui/material/Zoom';

import DialogAddViewEpilepsyCase from 'components/Dialogs/DialogAddViewEpilepsyCase';
import DialogAddViewClinicalPatient from 'components/Dialogs/DialogAddViewClinicalPatient';
const children = [
  { name: 'patient', label: 'PATIENTS' },
  { name: 'epilepsy_case', label: 'CASE' },
  { name: 'epilepsy_research_study', label: 'STUDY' },
  { name: 'epilepsy_resection', label: 'RESECTION' },
  { name: 'epilepsy_outcome', label: 'OUTCOME' },
];

class EpilepsyToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: 'patient',
      open_delete: false,
      uploading: false,
      nowrap: false,
      open_create: false,
      open_add_case: false,
      open_add_patient: false,
      deleting: false,
      searching: false,
    };
    this.holder = {
      getSearchText: null,
      open_upload_info: false,
      searching: false,
    };
    this.holder = {
      getSearchText: null,
    };
  }
  componentDidMount() {
    const { level_1, level_2 } = getParams(this.props);
    let flag = true;
    const index = children.map(x => x.name).indexOf(level_2);
    if (index !== -1) {
      this.props.setState({ table: index });
      this.setState({ table: level_2 });
      this.props.handleReload(children[index].name, null, null);
      flag = false;
    }
    if (flag && level_1 === labels.studies.epilepsy) {
      this.props.history.push(`/clinical_data/epilepsy/${children[0].name}`);
      this.props.setState({ table: 0 });
      this.setState({ table: children[0].name });
      this.props.handleReload(children[0].name, null, null);
    }
  }
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    let { selected, new_rows, total, index_table, rows } = this.props;
    const new_selected = selected.filter(x => x.id < 0);
    if (new_selected.length) {
      for (let i = 0; i < new_selected.length; i++) {
        const { id } = new_selected[i];
        const index = new_rows[index_table].map(x => x.id).indexOf(id);
        if (index === -1) continue;
        new_rows[index_table].splice(index, 1);
      }
      this.props.setState({ new_rows });
    }
    selected = selected.filter(x => x.id >= 0);
    const { deleting } = this.state;
    if (deleting || !selected.length) {
      this.setState({ open_delete: false });
      this.props.setState({ selected });
      return;
    }
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    const { table } = this.state;
    (async () => {
      MutationRemoveEpilepsy(table, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = GetRemoveResponse(table, data);
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              rows: rows.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveChanges = () => {
    let { add_rows, rows_edited, rows } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      rows_edited = [];
    }
    if (IsInvalid(add_rows) || !add_rows.length) {
      add_rows = [];
    }
    for (let i = 0; i < rows_edited.length; i++) {
      const element = rows_edited[i];
      add_rows = add_rows.filter(row => row.id !== element.id);
    }
    add_rows = add_rows.concat(rows_edited);
    if (!add_rows.length) {
      this.props.handleAddError(labels.errors.nothingToSave);
      return;
    }
    const { saving, table } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    const inputs = add_rows
      .map(x => {
        const {
          createdAt,
          updatedAt,
          address,
          user,
          idPatient,
          UniqueKeyIdentifier,
          edit,
          epilepsy_case,
          research_study,
          resection,
          outcome,
          files,
          patient,
          ...res
        } = x;
        if (IsValid(patient)) {
          return {
            idPatient: patient.idPatient,
            PatientID: patient.PatientID,
            ...res,
          };
        } else {
          return {
            ...res,
          };
        }
      })
      .filter(x => x);
    (async () => {
      MutationAddEpilepsy(table, inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rew_rows, total } = GetAddResponse(table, data);
          if (ok) {
            this.setState({ saving: false });
            for (let i = 0; i < rew_rows.length; i++) {
              const { id } = rew_rows[i];

              const index = rows.map(x => x.id).indexOf(id);

              if (index === -1) {
                rows.unshift(rew_rows[i]);
              } else {
                rows[index] = {
                  ...rew_rows[i],
                };
              }
            }
            this.props.handleSetEditRows([]);
            this.props.handleSetNewRows([]);
            const updatedRows = rows.map(row => {
              const edited = add_rows.find(edit => edit.id === row.id);
              return edited ? { ...row, ...edited } : row;
            });
            this.props.setState({
              add_rows: [],
              table_rows_edited: [],
              rows: updatedRows,
              total,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { rows } = this.props;
    const { uploading, table } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError(labels.errors.fileNotSelected);
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadEpilepsy(table, files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, new_rows, total } = GetUploadResponse(
            table,
            data
          );
          if (ok) {
            this.setState({
              uploading: false,
            });
            for (let i = 0; i < new_rows.length; i++) {
              const { id } = new_rows[i];
              const index = rows.map(x => x.id).indexOf(id);
              if (index === -1) {
                rows.unshift(new_rows[i]);
              } else {
                rows[index] = {
                  ...new_rows[i],
                };
              }
            }
            this.setState({ table: children[0].name });
            this.props.setState({ rows, total, table: 0, loading: false });
            this.props.history.push(
              `/clinical_data/epilepsy/${children[0].name}`
            );
            this.props.handleReload(children[0].name, null, null);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  // eslint-disable-next-line no-unused-vars
  handleSearchEpilepsy = (text = null) => {
    // const { page, limit } = this.props;
    // const { searching, table } = this.state;
    // if (searching) return;
    // if (!text && this.holder.getSearchText) {
    //   text = this.holder.getSearchText();
    // }
    // if (IsInvalid(text) || text === '') return null;
    // this.setState({ searching: true });
    // (async () => {
    //   QuerySearchEpilepsy(table, text, page, limit)
    //     .then(res => {
    //       const data = GetData(res);
    //       const { ok, errors, rows, total } = GetSearchResponse(table, data);
    //       if (ok) {
    //         this.props.setState({
    //           rows,
    //           total,
    //           page: page,
    //           limit: limit,
    //         });
    //         this.setState({ searching: false });
    //       } else throw errors;
    //     })
    //     .catch(error => {
    //       this.setState({ searching: false });
    //       throw error;
    //     });
    // })();
  };
  handleChange = (event, table) => {
    const { loading } = this.props;
    if (loading) return;
    if (IsInvalid(table)) return;
    this.setState({ table });
    this.props.setState({
      selected: [],
      table: children.map(x => x.name).indexOf(table),
    });
    if (this.holder.cleanText) {
      this.holder.cleanText();
    }
    this.props.history.push(`/clinical_data/epilepsy/${table}`);
    this.props.handleReload(table, null, null);
  };
  getDeleteText = (table, isShortVersion) => {
    const splitted = table.split('_');
    const newArray = splitted.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    if (isShortVersion) {
      return `${newArray.join(' ')} (${this.props.selected.length})`;
    } else {
      return `${newArray.join(' ')} (${this.props.selected.length} records)`;
    }
  };
  getSelectedIds = () => {
    const { selected } = this.props;
    const { table } = this.state;
    let ids = [];
    selected.forEach(element => {
      const { id, idEpilepsyCase } = element;
      if (IsValid(idEpilepsyCase) && table !== 'epilepsy_case') {
        ids.push(idEpilepsyCase);
      } else if (IsValid(id)) {
        ids.push(id);
      }
    });
    return ids;
  };

  extractResult = data => {
    let result = null;
    if (data.searchEpilepsy && data.searchEpilepsy.cases) {
      const { ok, errors, cases, total } = data.searchEpilepsy;
      result = { ok, errors, results: cases, total };
    } else if (data.searchEpilepsy && data.searchEpilepsy.research_studies) {
      const { ok, errors, research_studies, total } = data.searchEpilepsy;
      result = { ok, errors, results: research_studies, total };
    } else if (data.searchEpilepsy && data.searchEpilepsy.resections) {
      const { ok, errors, resections, total } = data.searchEpilepsy;
      result = { ok, errors, results: resections, total };
    } else if (data.searchEpilepsy && data.searchEpilepsy.outcomes) {
      const { ok, errors, outcomes, total } = data.searchEpilepsy;
      result = { ok, errors, results: outcomes, total };
    }
    return result;
  };

  searchEpilepsy = (text = null) => {
    const { page, limit } = this.props;
    const { searching, table } = this.state;
    if (searching) return;
    if (!text && this.holder.getSearchText) {
      text = this.holder.getSearchText();
    }
    this.setState({ searching: true });
    (async () => {
      searchEpilepsyTables(text, table, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, results, total } = this.extractResult(data);
          if (ok) {
            this.props.setState({
              rows: results,
              total,
              page: page,
              limit: limit,
            });
            this.setState({ searching: false });
          } else throw errors;
        })
        .catch(error => {
          this.setState({ searching: false });
          throw error;
        });
    })();
  };

  render() {
    const { selected, row_clicked, rows_edited, anonymize } = this.props;
    const { table } = this.state;
    const control = {
      value: table,
      onChange: this.handleChange,
      exclusive: true,
    };
    const { level_1 } = getParams(this.props);
    const confirmationTitle = `${labels.delete.confirmation.title} ${titleCase(
      level_1
    )} ${this.getDeleteText(table, false)}`;
    const confirmationText1 = `${labels.delete.confirmation.text1} ${titleCase(
      level_1
    )} ${this.getDeleteText(table, false)}`;
    const confirmationText2 = `${labels.delete.confirmation.text2}`;
    return (
      <React.Fragment>
        <Grid container direction="column">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            pb={0.5}
            pt={0.5}>
            <Grid item>
              <ToggleButtonGroup
                size="small"
                {...control}
                aria-label="Small sizes">
                {children.map(x => (
                  <ToggleButton key={x.name} value={x.name}>
                    {x.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <ButtonGroup exclusive="false" size="small" value="save">
                {IsAdministrator && (
                  <Tooltip
                    placement="top"
                    title={anonymize ? 'De-anonymize' : 'Anonymize'}
                    TransitionComponent={Zoom}>
                    <Button onClick={this.props.handleToggleAnonymize}>
                      {anonymize ? (
                        <LockIcon title="Anonymize" />
                      ) : (
                        <NoEncryptionIcon />
                      )}
                    </Button>
                  </Tooltip>
                )}
                {(table === 'epilepsy_case' || table === 'patient') && (
                  <Button
                    disabled={IsInvalid(row_clicked)}
                    onClick={() => this.setState({ open_view: true })}>
                    <VisibilityIcon />
                  </Button>
                )}
                {table !== 'patient' && (
                  <Button
                    onClick={() => this.setState({ open_add_case: true })}>
                    <AddIcon />
                  </Button>
                )}
                {table === 'patient' && (
                  <Button
                    onClick={() => this.setState({ open_add_patient: true })}>
                    <AddIcon />
                  </Button>
                )}
                <Button
                  disabled={!rows_edited.length}
                  onClick={this.handleSaveChanges}>
                  <SaveIcon
                    style={{ color: rows_edited.length ? 'green' : 'gray' }}
                  />
                </Button>
                <ButtonDownloadType
                  columns={getColumnList()}
                  inputs={{ ids: this.getSelectedIds() }}
                  key="clinical_epilepsy"
                  setState={state => this.setState(state)}
                  studyName="Epilepsy"
                  table={table}
                  type={'clinical_epilepsy'}
                />
                <ButtonFileUpload
                  handleSelectFile={this.handleUploadSelectFile}
                  uploading={this.state.uploading}
                />
                <Tooltip placement="right" title="Upload file instructions">
                  <Button
                    onClick={() => this.setState({ open_upload_info: true })}>
                    <HelpOutlineIcon style={{ color: 'green' }} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <CheckBoxText
                checked={this.props.nowrap}
                label="Wrap"
                onChange={this.props.handleChangeCheck}
                size="small"
              />
            </Grid>
            <Grid item>
              <ButtonReloadDelete
                handleClearSelected={this.props.handleClearSelected}
                handleDeleteSelected={() =>
                  this.setState({ open_delete: table !== 'patient' })
                }
                handleReloadTable={() =>
                  this.props.handleReload(table, null, null)
                }
                loading={this.state.deleting}
                loading_table={this.props.loading}
                numSelected={selected.length}
                show_delete={table !== 'patient'}
                small
                tooltip="Reload rules"
                wrapper={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item style={{ marginBottom: '5px' }} xs={5}>
            {table !== 'patient' && (
              <SearchText
                handleSearchText={this.searchEpilepsy}
                holder={this.holder}
                loading={this.state.searching}
                placeholder={
                  'Search ' + table.slice(9, table.length).replace('_', ' ')
                }
              />
            )}
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`${confirmationText1} ${confirmationText2}`}
          title_text={confirmationTitle}
        />
        <DialogShowUploadInfo
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_upload_info: false })}
          open={this.state.open_upload_info}
          studyName={'Epilepsy'}
          type={'clinical_epilepsy'}
        />
        <DialogAddViewEpilepsyCase
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_add_case: false })}
          history={this.props.history}
          open={this.state.open_add_case}
        />
        <DialogAddViewClinicalPatient
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_add_patient: false })}
          history={this.props.history}
          open={this.state.open_add_patient}
        />
        <DialogViewEpilepsyCase
          epilepsy_case={row_clicked}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_view: false })}
          history={this.props.history}
          open={this.state.open_view && table === 'epilepsy_case'}
        />
        <DialogViewClinicalPatient
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_view: false })}
          history={this.props.history}
          open={this.state.open_view && table === 'patient'}
          patient={row_clicked}
        />
      </React.Fragment>
    );
  }
}

EpilepsyToolbar.propTypes = {
  classes: PropTypes.object,
};
EpilepsyToolbar.defaultProps = {
  rows_edited: [],
  selected: [],
};

export default EpilepsyToolbar;
