import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonLoad, TableGrid } from 'components';
import { Grid } from '@mui/material';
import { Replay as ReloadIcon } from '@mui/icons-material';
import { QueryUsersCenters } from 'graphql/Users';
import {
  GetData,
  ServerErrorsString,
  rowsPerPageBig,
  IsInvalid,
} from 'helpers';
import { columns_centers } from './utils_centers';
class UserViewCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 100,
      centers: [],
      idUser: -1,
    };
  }
  componentDidMount() {
    this.loadUserCenters();
  }
  componentDidUpdate() {
    this.loadUserCenters();
  }
  loadUserCenters = (force, newPage, newLimit) => {
    const { edit_user } = this.props;
    if (IsInvalid(edit_user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === edit_user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryUsersCenters(edit_user.id, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, centers, total, errors } = data.userCenters;
          if (ok) {
            this.setState({
              idUser: edit_user.id,
              centers,
              total,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { centers, loading } = this.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          p={1}
          spacing={1}>
          <Grid item>User Centers</Grid>
          <Grid item>
            <IconButtonLoad
              // disabled={!changed}
              handleClick={() => this.loadUserCenters(true)}
              icon={<ReloadIcon />}
              loading={loading}
              small
              tooltip="Reload Table"
            />
          </Grid>
        </Grid>
        <TableGrid
          checkboxSelection
          dense
          headers={columns_centers}
          height={this.props.height - 160}
          loading={loading}
          onPageChange={page =>
            this.loadUserCenters(true, page, this.state.limit)
          }
          onRowsPerPageChange={limit => this.loadUserCenters(true, 0, limit)}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={centers}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </React.Fragment>
    );
  }
}

UserViewCenters.propTypes = {
  classes: PropTypes.object,
};

export default UserViewCenters;
