import { IsInvalid } from 'helpers';
import { IsValid } from 'helpers';

export const useStyles = () => ({
  root: {},
});
const GetSeriesFileName = value => {
  const { Series, File } = value;
  if (Series && Series.SeriesDescription) {
    return {
      name: Series.SeriesDescription,
      id: Series.idSeries,
      type: 'Series',
    };
  } else if (File && File.original_name) {
    return {
      name: File.original_name,
      id: File.idFileObject,
      type: 'File',
    };
  }
  return null;
};
const GetTypeName = params => {
  if (IsInvalid(params.getValue)) return '';
  let value = params.getValue(params.id, 'folder_content');
  if (IsInvalid(value)) {
    value = params.getValue(params.id, 'multi_object');
    if (IsValid(value)) {
      return GetSeriesFileName(value);
    } else {
      value = params.getValue(params.id, 'folder');
      if (value) {
        const { name } = value;
        if (name) {
          return {
            name,
            id: value.idFolder,
            type: 'Folder',
          };
        }
      }
    }
  } else {
    return GetSeriesFileName(value);
  }
  return null;
};
export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    style: {
      width: 75,
      maxWidth: 90,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = GetTypeName(params);
      if (IsInvalid(value)) {
        return '';
      } else {
        return value.id;
      }
    },
  },
  {
    field: 'type',
    headerName: 'Object Type',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = GetTypeName(params);
      if (IsInvalid(value)) {
        return '';
      } else {
        return value.type;
      }
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    width: 150,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = GetTypeName(params);
      if (IsInvalid(value)) {
        return '';
      } else {
        return value.name;
      }
    },
  },
  {
    field: 'folderStructure',
    headerName: 'Folder Structure',
    align: 'left',
    style: {
      width: 'max-content',
      // minWidth: 290,
    },
    // width: '100%',
  },
];
