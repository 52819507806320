import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_DOCKER_CONTAINERS = gql`
  query {
    dockerContainers {
      ok
      errors {
        message
      }
      containers {
        idContainer
        Labels
        Names
        ImageName
        idImage
        Command
        createdAt
        ip
        ports
        Status
        State
        image {
          idImage
          createdAt
          RepoTags {
            name
            tag
          }
        }
        Ports {
          Type
          PrivatePort
          PublicPort
        }
        NetworkSettings {
          network_name
          IPAddress
        }
        Mounts {
          Type
          Name
          Source
          Destination
        }
      }
    }
  }
`;
export const QueryDockerContainers = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DOCKER_CONTAINERS),
    //   variables: {
    //     receiverId,
    //   },
  });
};
export const MUTATION_DOCKER_CONTAINER_ACTION = gql`
  mutation($action: String!, $ids: [String!]!) {
    dockerContainerAction(action: $action, ids: $ids) {
      ok
      errors {
        message
      }
      actions {
        idContainer
        code
        state {
          Status
          StartedAt
          FinishedAt
          ExitCode
        }
      }
    }
  }
`;
export const MutationDockerContainerAction = (action, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DOCKER_CONTAINER_ACTION),
    variables: {
      action,
      ids,
    },
  });
};
const MUTATION_DELETE_DOCKER_CONTAINER = gql`
  mutation($names: [String!], $settings: DockerContainerSettings) {
    deleteDockerContainer(names: $names, settings: $settings) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDockerContainerDelete = (names, settings) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_DOCKER_CONTAINER),
    variables: {
      names,
      settings,
    },
  });
};
