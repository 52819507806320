import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@mui/material';
import FormTextFields from '../FormTextFields';
class CaseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Typography component="h4" variant="h6">
          Case Form
        </Typography>
        <Divider style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
        <FormTextFields
          form={this.props.case_form}
          handleChangeForm={this.props.handleUpdateCaseForm}
        />
      </Box>
    );
  }
}
CaseForm.propTypes = {
  handleClose: PropTypes.func,
};
CaseForm.defaultProps = {
  handleClose: () => '',
};

export default CaseForm;
