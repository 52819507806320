import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { QueryProjectActivities } from 'graphql/Projects';
import { ListProjectActivities, useStyles } from './components';
import { ButtonReload } from 'components';
import { getVariables } from 'common';
import { ServerErrorsString, GetData } from 'helpers';
import { initialState } from 'redux-store/reducers/page_settings';

class LatestProjectActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activities: [],
    };
  }

  componentDidMount() {
    this.handleLoadProjectActivities();
  }
  handleLoadProjectActivities = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const variables = {
      page: 0,
      limit: 6,
      ...getVariables(this.props),
    };
    (async () => {
      QueryProjectActivities(variables)
        .then(res => {
          const data = GetData(res);
          const { ok, total, activities, errors } = data.ProjectActivities;
          if (ok) {
            this.setState({
              total,
              activities,
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  render() {
    const { classes, className } = this.props;
    const { loading } = this.state;
    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <ButtonReload
              handleReload={() => this.handleLoadProjectActivities()}
              loading={loading}
              small
              tooltip="Reload Project Activity"
            />
          }
          className={classes.reload}
          title="Latest Project Activity"
        />
        <Divider />
        <CardContent className={classes.content}>
          <ListProjectActivities
            data={this.state.activities}
            loading={this.state.loading}
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            onClick={() =>
              this.props.history.push('/project_management/browser')
            }
            size="small"
            variant="text">
            View all <ArrowRightIcon />
          </Button>
        </CardActions>
      </Card>
    );
  }
}

LatestProjectActivity.propTypes = {
  className: PropTypes.string,
};
const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(withRouter(LatestProjectActivity), useStyles));
