/* eslint-disable no-use-before-define */
import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

export default function MultiAutoComplete(props) {
  const { array, title, sub_title, handleSelectedValues, selected } = props;
  return (
    <Autocomplete
      defaultValue={selected ? selected : []}
      getOptionLabel={option => {
        if (option) return option.name;
        return '';
      }}
      id="fixed-tags-demo"
      multiple
      options={array}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label={title ? title : 'Undefined'}
          placeholder={sub_title ? sub_title : 'Holder undefined'}
          variant="outlined"
        />
      )}
      renderTags={(value, getTagProps) => {
        handleSelectedValues(value);
        return value.map((option, index) => (
          <Chip
            key={`chip-multi-auto-key-${index}`}
            label={option.name}
            {...getTagProps({ index })}
          />
        ));
      }}
      style={{ width: 'calc(100% - 2)' }}
    />
  );
}
