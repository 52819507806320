import React from 'react';
import PropTypes from 'prop-types';
import { Survey } from 'survey-react-ui';
import { CircularProgress, Grid } from '@mui/material';
import './defaultV2.min.css';
//import './defaultV21.min.css';
class ViewerSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currentSurvey, loading, handlePageChanged, delta } = this.props;
    let { height } = this.props;
    let style = null;
    if (height) {
      height = height - delta;
      style = {
        height,
        maxHeight: height,
        overflow: 'auto',
      };
    }
    return (
      <div style={style}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          sx={{ width: '100%', height: '100%' }}>
          {loading ? (
            <Grid container item justifyContent="center" xs={12}>
              <CircularProgress disableShrink />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {currentSurvey && (
                <Survey
                  model={currentSurvey}
                  onCurrentPageChanged={handlePageChanged}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

ViewerSurvey.propTypes = {
  loading: PropTypes.bool,
};
ViewerSurvey.defaultProps = {
  loading: undefined,
  delta: 120,
};

export default ViewerSurvey;
