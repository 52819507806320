import { FormatTime, getUserFullName } from 'helpers';

export const columns_errors = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    minWidth: 10,
  },
  {
    id: 'user',
    label: 'User Name',
    type: 'user',
    minWidth: 250,
    align: 'left',
    format: value => {
      if (!value) return 'Not defined';
      return getUserFullName(value);
    },
  },
  {
    id: 'error',
    label: 'Error',
    minWidth: 350,
    format: value => {
      if (!value) return 'empty error';
      const length = 60;
      if (value.size < length) return value;
      return value.substring(0, length) + '...';
    },
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
export const columns_logs = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    minWidth: 10,
  },
  {
    id: 'user',
    label: 'User Name',
    type: 'user',
    minWidth: 200,
    align: 'left',
    format: value => {
      if (!value) return 'Not defined';
      return getUserFullName(value);
    },
  },
  {
    id: 'activity',
    label: 'Activity',
    minWidth: 50,
    format: value => {
      return value;
    },
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
