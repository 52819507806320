import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableContainer,
  TablePagination,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { TableSurveyPatientMain } from './components';
import TableToolBarSurvey from '../TableToolBarSurvey';
class TableSurveyPatients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'idPatient',
      selected: [],
      page: 0,
      dense: true,
      rowsPerPage: 25,
    };
  }
  handleRequestSort = (event, property) => {
    // event.preventDefault();
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = event => {
    event.preventDefault();
    let selected = [];
    if (event.target.checked) {
      selected = this.props.rows;
    }
    this.setState({ selected });
  };

  handleClick = (event, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected.map(x => x.idPatient).indexOf(row.idPatient);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    event.preventDefault();
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleChangeDense = event => {
    event.preventDefault();
    this.setState({ dense: event.target.checked });
  };

  isSelected = row => {
    const { selected } = this.state;
    return selected.map(x => x.idPatient).indexOf(row.idPatient) !== -1;
  };

  render() {
    const { rowsPerPage, page, selected, order, orderBy, dense } = this.state;
    let { rows, height } = this.props;
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    let style = null;
    if (height) {
      height = height - 180;
      style = {
        height,
        maxHeight: height,
        overflow: 'auto',
      };
    }
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableToolBarSurvey
            handleAddError={this.props.handleAddError}
            handleAddNewPatient={this.props.handleAddNewPatient}
            handleReload={this.props.handleReload}
            numSelected={selected.length}
          />
          <TableContainer style={style}>
            <TableSurveyPatientMain
              dense={dense}
              emptyRows={emptyRows}
              handleClick={this.handleClick}
              handleOpenPatient={this.props.handleOpenPatient}
              handleRequestSort={this.handleRequestSort}
              handleSelectAllClick={this.handleSelectAllClick}
              isSelected={this.isSelected}
              loading={this.props.loading}
              order={order}
              orderBy={orderBy}
              page={page}
              rows={rows}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 100, 250, 500]}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={this.handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    );
  }
}

TableSurveyPatients.propTypes = {
  rows: PropTypes.array,
};
TableSurveyPatients.defaultProps = {
  rows: [],
  handleOpenPatient: () => '',
};

export default TableSurveyPatients;
