export { default as SwitchHomeShared } from './SwitchHomeShared';
export { default as ButtonDeleteFolders } from './ButtonDeleteFolders';
export { default as DialogCreateFolder } from './DialogCreateFolder';
export { default as ButtonMenuIcon } from './ButtonMenuIcon';
export { default as ButtonSharedFolders } from './ButtonSharedFolders';
export { default as ButtonCleanCutCopy } from './ButtonCleanCutCopy';
export { default as ButtonSharedFiles } from './ButtonSharedFiles';
export { default as ToggleButtonItems } from './ToggleButtonItems';
export { default as ButtonAddDownloadList } from './ButtonAddDownloadList';
export { default as ButtonEditMultiSeries } from './ButtonEditMultiSeries';
export { default as DialogEditMultiSeries } from './DialogEditMultiSeries';
export { default as ToogleButtonExplorerView } from './ToogleButtonExplorerView';
export { default as ButtonAddBatchProcess } from './ButtonAddBatchProcess';

export const getNewItems = (selected, downloads) => {
  const to_add_items = [];
  for (let i = 0; i < selected.length; i++) {
    const { idFolder, idFolderContent } = selected[i];
    let here = false;
    for (let j = 0; j < downloads.length; j++) {
      const item = downloads[j];
      if (typeof idFolder !== 'undefined') {
        if (typeof item.idFolder === 'undefined') {
          /* emtpy */
        } else if (item.idFolder === idFolder) {
          here = true;
          break;
        }
      } else if (typeof idFolderContent !== 'undefined') {
        if (typeof item.idFolderContent === 'undefined') {
          /* emtpy */
        } else if (item.idFolderContent === idFolderContent) {
          here = true;
          break;
        }
      }
    }
    if (!here) {
      to_add_items.push(selected[i]);
    }
  }
  return to_add_items;
};
