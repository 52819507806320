import React from 'react';
import PropTypes from 'prop-types';
import { ViewSeriesList } from 'views/DataImages/components/ViewDatabaseSeries/components';

class ViewStudyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { Study, height, loading } = this.props;
    let series = [];
    if (Study && Study.Series) {
      const { Patient } = Study;
      if (Patient) series = Study.Series.map(x => ({ ...x, Patient }));
      else series = Study.Series;
    }
    // console.log({ Study });
    return (
      <ViewSeriesList
        handleAddError={this.props.handleAddError}
        height={height}
        loading={loading}
        series={series}
        total={series.length}
        user={this.props.user}
        // limit={this.props.limit}
        //page={this.props.page}
      />
    );
  }
}

ViewStudyList.propTypes = {
  classes: PropTypes.object,
};

export default ViewStudyList;
