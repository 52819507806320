import * as m4 from './m4';
class Vector3D {
  constructor(x, y, z) {
    this.set(x, y, z);
  }
  set(x, y, z) {
    this.x = x || 0;
    this.y = y || 0;
    this.z = z || 0;
    return this;
  }
  dot(v) {
    return this.x * v.x + this.y * v.y + this.z * v.z;
  }
  // TODO lengthSquared?
  lengthSq() {
    return this.x * this.x + this.y * this.y + this.z * this.z;
  }
  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }
  manhattanLength() {
    return Math.abs(this.x) + Math.abs(this.y) + Math.abs(this.z);
  }
  normalize() {
    return this.divideScalar(this.length() || 1);
  }
  divideScalar(scalar) {
    return this.multiplyScalar(1 / scalar);
  }

  multiplyScalar(scalar) {
    this.x *= scalar;
    this.y *= scalar;
    this.z *= scalar;
    return this;
  }
  unproject(camera) {
    const projectionMatrix = camera.update();
    const projectionMatrixInverse = m4.inverse(projectionMatrix);
    const matrixWorld = camera.cameraMatrix();
    return this.applyMatrix4(projectionMatrixInverse).applyMatrix4(matrixWorld);
  }

  applyMatrix4(m) {
    const x = this.x,
      y = this.y,
      z = this.z;

    const w = 1 / (m[3] * x + m[7] * y + m[11] * z + m[15]);

    this.x = (m[0] * x + m[4] * y + m[8] * z + m[12]) * w;
    this.y = (m[1] * x + m[5] * y + m[9] * z + m[13]) * w;
    this.z = (m[2] * x + m[6] * y + m[10] * z + m[14]) * w;

    return this;
  }

  transformDirection(m) {
    // input: Matrix4 affine matrix
    // vector interpreted as a direction

    const x = this.x,
      y = this.y,
      z = this.z;

    this.x = m[0] * x + m[4] * y + m[8] * z;
    this.y = m[1] * x + m[5] * y + m[9] * z;
    this.z = m[2] * x + m[6] * y + m[10] * z;

    return this.normalize();
  }
  clone() {
    return new this.constructor(this.x, this.y, this.z);
  }
}

// return the angle of the vector in radians
Vector3D.prototype.getDirection = function() {
  return Math.atan2(this.y, this.x);
};

// set the direction of the vector in radians
Vector3D.prototype.setDirection = function(angle) {
  var magnitude = this.getMagnitude();
  this.x = Math.cos(angle) * magnitude;
  this.y = Math.sin(angle) * magnitude;
};

// get the magnitude of the vector
Vector3D.prototype.getMagnitude = function() {
  // use pythagoras theorem to work out the magnitude of the vector
  return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
};

// set the magnitude of the vector
Vector3D.prototype.setMagnitude = function(magnitude) {
  var direction = this.getDirection();
  this.x = Math.cos(direction) * magnitude;
  this.y = Math.sin(direction) * magnitude;
};

// add two vectors together and return a new one
Vector3D.prototype.add = function(v3) {
  return new Vector3D(this.x + v3.x, this.y + v3.y, this.z + v3.z);
};

// add a vector to this one
Vector3D.prototype.addTo = function(v3) {
  this.x += v3.x;
  this.y += v3.y;
  this.z += v3.z;
};

// subtract two vectors and reutn a new one
Vector3D.prototype.subtract = function(v3) {
  return new Vector3D(this.x - v3.x, this.y - v3.y, this.z - v3.z);
};

Vector3D.prototype.crossVectors = function(b) {
  const ax = this.x,
    ay = this.y,
    az = this.z;
  const bx = b.x,
    by = b.y,
    bz = b.z;
  return new Vector3D(ay * bz - az * by, az * bx - ax * bz, ax * by - ay * bx);
};

// subtract a vector from this one
Vector3D.prototype.subtractFrom = function(v3) {
  this.x -= v3.x;
  this.y -= v3.y;
  this.z -= v3.z;
};

// multiply this vector by a scalar and return a new one
Vector3D.prototype.multiply = function(scalar) {
  return new Vector3D(this.x * scalar, this.y * scalar, this.z * scalar);
};

// multiply this vector by the scalar
Vector3D.prototype.multiplyBy = function(scalar) {
  this.x *= scalar;
  this.y *= scalar;
  this.z *= scalar;
};

// scale this vector by scalar and return a new vector
Vector3D.prototype.divide = function(scalar) {
  return new Vector3D(this.x / scalar, this.y / scalar, this.z / scalar);
};

// scale this vector by scalar
Vector3D.prototype.divideBy = function(scalar) {
  this.x /= scalar;
  this.y /= scalar;
  this.z /= scalar;
};

// Aliases
Vector3D.prototype.getLength = Vector3D.prototype.getMagnitude;
Vector3D.prototype.setLength = Vector3D.prototype.setMagnitude;

Vector3D.prototype.getAngle = Vector3D.prototype.getDirection;
Vector3D.prototype.setAngle = Vector3D.prototype.setDirection;

// Utilities
Vector3D.prototype.copy = function() {
  return new Vector3D(this.x, this.y, this.z);
};

Vector3D.prototype.toString = function() {
  return 'x: ' + this.x + ', y: ' + this.y + ', z: ' + this.z;
};

Vector3D.prototype.toArray = function() {
  return [this.x, this.y, this.z];
};

Vector3D.prototype.toObject = function() {
  return { x: this.x, y: this.y, z: this.z };
};

// To add
// Scale
// Norm
// Dot?
export default Vector3D;
