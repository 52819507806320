import React from 'react';
import PropTypes from 'prop-types';
import { Paper, IconButton, InputBase, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

class SearchToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }
  handleTextChanged = event => {
    const text = event.target.value;
    this.setState({ text });
    this.props.handleTextChanged(text);
  };
  render() {
    return (
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        <IconButton aria-label="menu" sx={{ p: '10px' }}>
          <MenuIcon />
        </IconButton>
        <InputBase
          inputProps={{ 'aria-label': 'search partient' }}
          onChange={this.handleTextChanged}
          placeholder="Search Patient"
          sx={{ ml: 1, flex: 1 }}
          value={this.state.text}
        />
        <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
        <IconButton aria-label="search" sx={{ p: '10px' }} type="button">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
}

SearchToolBar.propTypes = {
  handleTextChanged: PropTypes.func,
};
SearchToolBar.defaultProps = {
  handleTextChanged: () => '',
};

export default SearchToolBar;
