import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadMsCSV } from './utils';

const QUERY_MS_COMORBIDITIES = gql`
  query($name: String, $page: Int, $limit: Int) {
    msComorbidities(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryMsComorbidities = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_MS_COMORBIDITIES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_MS_COMORBIDITIES = gql`
  mutation($ids: [LongLong!]!) {
    deleteMsComorbidities(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveMsComorbidities = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_MS_COMORBIDITIES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_MS_COMORBIDITIES = gql`
  mutation($inputs: [InputMsComorbidity!]!) {
    addMsComorbidities(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddMsComorbidities = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_MS_COMORBIDITIES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_MS_COMORBIDITIES = gql`
  mutation($file: Upload!) {
    uploadMsComorbidities(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        tot_files
        tot_series
        tot_edfs
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadMsComorbidities = (file, handleProgress = null) => {
  return MutationUploadMsCSV(
    file,
    MUTATION_UPLOAD_MS_COMORBIDITIES,
    handleProgress
  );
};

const QUERY_SEARCH_MS_COMORBIDITIES = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchMsComorbidities(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      comorbidities {
        id
        idPatient
        description
        group
        subgroup_organ
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySearchMsComorbidities = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_MS_COMORBIDITIES),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
