import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Fab } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  DialogCreateVariableType,
  InputsVariableTypes,
  ButtonReloadDelete,
  IconButtonLoad,
  DialogDeleteWarning,
} from 'components';
import {
  MutationAddProjectVariableTypes,
  MutationRemoveProjectVariableTypes,
} from 'graphql/Projects/utils_project_types';
import { ServerErrorsString, GetData } from 'helpers';
class ToolbarProjectVariableTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      updating: false,
      open_create: false,
    };
    this.holder = {
      addType: null,
    };
  }
  updateEdited = () => {
    let { new_types, rows_edited } = this.props;
    if (!rows_edited.length) return new_types;
    return new_types.map(x => {
      const index = rows_edited.map(x => x.idType).indexOf(x.idType);
      let level = x.level;
      console.log({ index, x });
      if (index !== -1) {
        level = rows_edited[index].level;
      }
      return {
        ...x,
        level,
      };
    });
  };
  handleSaveNewTypes = () => {
    let { updating } = this.state;
    if (updating) return;
    let { idProject, rows_edited } = this.props;
    let new_types = this.updateEdited();
    this.setState({ updating: true });
    (async () => {
      const ids = new_types.map(x => {
        const { idType, level } = x;
        if (level === undefined) return { idType };
        return { idType, level };
      });
      MutationAddProjectVariableTypes(idProject, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, types } = data.addProjectVariableTypes;
          if (ok) {
            new_types = new_types.map(x => {
              const index = types.map(x => x.idType).indexOf(x.idType);
              let id = -1;
              if (index !== -1) {
                id = types[index].id;
              }
              return {
                ...x,
                id,
              };
            });
            new_types = this.props.types.concat(new_types);
            this.setState({ updating: false });
            this.props.updateStates({
              idProject,
              new_types: [],
              rows_edited: rows_edited.filter(x => x.id > 0),
              updating: false,
              types: new_types,
              total: this.props.total + types.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ idProject, loading: false });
        });
    })();
  };
  handleDeleteSelected = () => {
    this.setState({ open_delete: false });
    const { deleting } = this.state;
    if (deleting) return;
    const { idProject, types, selected, new_types } = this.props;
    const delete_new_types = selected.filter(x => x.id < 0).map(x => x.id);
    const ids = selected.filter(x => x.id >= 0).map(x => x.idType);
    console.log({ delete_new_types, ids });
    if (delete_new_types.length && !ids.length) {
      this.props.updateStates({
        selected: [],
        new_types: new_types.filter(x => delete_new_types.indexOf(x.id) === -1),
      });
      return;
    }
    this.setState({ deleting: true });
    (async () => {
      MutationRemoveProjectVariableTypes(idProject, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeProjectVariableTypes;
          if (ok) {
            this.setState({
              deleting: false,
            });
            this.props.updateStates({
              selected: [],
              new_types: new_types.filter(
                x => delete_new_types.indexOf(x.id) === -1
              ),
              types: types.filter(x => ids.indexOf(x.idType) === -1),
              total: this.state.total - ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
          this.props.updateStates({ idProject });
        });
    })();
  };
  handleCreatedVariableType = type => {
    if (this.holder.addType) {
      this.holder.addType(type);
      this.setState({ open_create: false });
    }
  };
  render() {
    const {
      handleClearSelected,
      handleAddVariableType,
      selected,
      handleReloadTable,
      deleting,
      loading,
      new_types,
    } = this.props;
    const { updating } = this.state;
    const numSelected = selected.length;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          style={{
            width: '100%',
            minHeight: '70px',
            paddingBottom: 10,
            paddingTop: 0,
            marginBottom: 10,
          }}
          xs={12}>
          <Grid item xs={1}>
            <Fab
              aria-label="add"
              color="secondary"
              disabled={this.state.open_create}
              onClick={() => this.setState({ open_create: true })}
              size="small">
              <AddIcon />
            </Fab>
          </Grid>
          <Grid
            item
            style={{
              paddingLeft: 15,
              paddingTop: 0,
              paddingBottom: 10,
              margin: 0,
            }}
            xs={7}>
            <InputsVariableTypes
              filter={false}
              handleAddVariableType={handleAddVariableType}
              holder={this.holder}
              multiple={false}
              textfield={{
                fullWidth: true,
                helperText: 'Search specify the variable name',
                label: 'Variable Types',
                margin: 'dense',
                required: true,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid
            container
            direction="row"
            item
            justifyContent="flex-end"
            style={{ paddingTop: 0, paddingRight: 0 }}
            xs={4}>
            <Grid item>
              <IconButtonLoad
                disabled={updating || loading || !new_types.length}
                handleClick={this.handleSaveNewTypes}
                icon={
                  <SaveIcon color={new_types.length ? 'primary' : 'disabled'} />
                }
                tooltip="Save"
              />
            </Grid>
            <Grid item>
              <ButtonReloadDelete
                handleClearSelected={handleClearSelected}
                handleDeleteSelected={() =>
                  this.setState({ open_delete: true })
                }
                handleReloadTable={handleReloadTable}
                loading={loading || deleting}
                // loading_table={loading_table}
                numSelected={numSelected}
                // tooltip={tooltip}
              />
            </Grid>
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={
            'You are about to delete the selected types, this action cannot be undone'
          }
          title_text="Delete Project Variable Types"
        />
        <DialogCreateVariableType
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreated={this.handleCreatedVariableType}
          open={this.state.open_create}
        />
      </React.Fragment>
    );
  }
}

ToolbarProjectVariableTypes.propTypes = {
  classes: PropTypes.object,
};

export default ToolbarProjectVariableTypes;
