import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  DialogSearchConsents,
  MapPatientConsentToolbar,
  columns,
} from './components';
import { TableGrid } from 'components';
import { rowsPerPageBig, ServerErrorsString, GetData } from 'helpers';
import { QueryPatientConsents } from 'graphql/Anonymization';

class TabMapPatientConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      patient_consents: [],
      selected: [],
      rows_edited: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    this.handleLoadPatientsConsents();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoadPatientsConsents();
  }
  handleLoadPatientsConsents = (
    inputs = null,
    new_page = null,
    new_limit = null
  ) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    this.setState({ loading: true });
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    (async () => {
      QueryPatientConsents(inputs, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patient_consents, total } = data.patientConsents;
          if (ok) {
            this.setState({
              loading: false,
              patient_consents,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddPatientConsents = (new_consents, clean = false) => {
    let { patient_consents, total } = this.state;
    console.log({ patient_consents, new_consents });
    for (let i = 0; i < new_consents.length; i++) {
      const { id } = new_consents[i];
      let index = patient_consents.map(x => x.id).indexOf(id);
      console.log({ index });
      if (index === -1) {
        patient_consents.push({ ...new_consents[i] });
        total++;
      } else {
        patient_consents[index] = {
          ...patient_consents[index],
          ...new_consents[i],
        };
      }
    }
    if (clean) {
      this.setState({ rows_edited: [], patient_consents, total });
    } else {
      this.setState({ patient_consents, total });
    }
  };
  handleRemovePatientConsents = ids_rows => {
    const { patient_consents } = this.state;
    this.setState({
      patient_consents: patient_consents.filter(
        x => ids_rows.indexOf(x.id) === -1
      ),
      selected: [],
      rows_edited: [],
    });
  };
  render() {
    if (this.state.open_search) {
      return (
        <DialogSearchConsents
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_search: false })}
          height={this.props.height}
        />
      );
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <MapPatientConsentToolbar
            handleAddError={this.props.handleAddError}
            handleAddPatientConsents={this.handleAddPatientConsents}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleReload={inputs => this.handleLoadPatientsConsents(inputs)}
            handleRemovePatientConsents={this.handleRemovePatientConsents}
            handleSearch={() => this.setState({ open_search: true })}
            loading={this.state.loading}
            rows_edited={this.state.rows_edited}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns}
            height={this.props.height}
            loading={this.state.loading}
            onPageChange={page =>
              this.handleLoadPatientsConsents(null, page, this.state.limit)
            }
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={limit =>
              this.handleLoadPatientsConsents(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.patient_consents}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabMapPatientConsent.propTypes = {
  classes: PropTypes.object,
};

export default TabMapPatientConsent;
