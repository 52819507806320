import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid, Paper } from '@mui/material';
import { ButtonDownloadType, ButtonReloadDelete } from 'components';
import EditIcon from '@mui/icons-material/Edit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { EditSeriesSequences } from '../../../UnMappedSeries/components';
import { IsInvalid } from 'helpers';

class ShowSeriesToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copying: false,
      open: false,
    };
  }
  handleSeriesMapped = Sequence => {
    this.setState({ open: false });
    if (IsInvalid(Sequence)) return;
    const { selected } = this.props;
    this.props.handleSeriesMapped(
      selected.map(x => {
        return {
          ...x,
          idSequence: Sequence.idSequence,
          Sequence,
        };
      })
    );
  };
  render() {
    const { copying } = this.state;
    const { selected } = this.props;
    let disabled = selected.length ? false : true;
    return (
      <React.Fragment>
        <Paper>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            sx={{ minHeight: 50 }}>
            <Grid item sx={{ paddingLeft: 1 }} xs={2}>
              <ButtonGroup exclusive="false" size="small" value="center">
                <Button
                  disabled={disabled}
                  onClick={() => this.setState({ open: true })}>
                  <EditIcon style={{ color: disabled ? 'gray' : 'red' }} />
                </Button>
                <Button disabled={disabled} onClick={this.props.handleOpenEdit}>
                  <CopyAllIcon
                    style={{ color: disabled || copying ? 'gray' : 'green' }}
                  />
                </Button>
                <ButtonDownloadType key="center" type="unsorted_sequences" />
              </ButtonGroup>
            </Grid>
            <ButtonReloadDelete
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={this.props.handleDeleteSelected}
              handleReloadTable={this.props.handleReloadTable}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              numSelected={selected.length}
              tooltip="Reload USer"
            />
          </Grid>
        </Paper>
        <EditSeriesSequences
          handleClose={() => this.setState({ open: false })}
          handleSeriesMapped={this.handleSeriesMapped}
          open={this.state.open}
          selected={selected}
        />
      </React.Fragment>
    );
  }
}

ShowSeriesToolbar.propTypes = {
  selected: PropTypes.array,
};
ShowSeriesToolbar.defaultProps = {
  selected: [],
  handleSeriesMapped: () => '',
};

export default ShowSeriesToolbar;
