import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Fab,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogSelectFolder } from 'components';

class ButtonAddFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      folder: null,
    };
    const { holder } = props;
    if (holder) {
      holder.getFolder = () => this.state.folder;
    }
  }
  handleFolderChanged = folder => {
    this.setState({ folder, open: false });
    this.props.handleSelectFolder(folder);
  };
  render() {
    let { folder } = this.state;
    if (!folder && this.props.folder) {
      folder = this.props.folder;
    }
    let secondary = 'Click the plus button to select the folder';
    let primary = 'Not selected';
    let ComponentDelete = null;
    if (folder) {
      primary = folder.name;
      secondary = 'To change the select the folder click the plus button';
      ComponentDelete = (
        <IconButton
          aria-label="delete"
          edge="end"
          onClick={() => this.handleFolderChanged(null)}>
          <DeleteIcon style={{ color: 'red' }} />
        </IconButton>
      );
    }
    return (
      <React.Fragment>
        <ListItem
          secondaryAction={
            <React.Fragment>
              <Fab
                aria-label="add"
                color="warning"
                onClick={() => this.setState({ open: true })}
                size="small">
                <AddIcon />
              </Fab>
              {ComponentDelete}
            </React.Fragment>
          }
          style={{ minWidth: 495 }}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#f5f5f5' }}>
              <FolderIcon style={{ color: folder ? 'blue' : 'gray' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={primary}
            secondary={secondary}
            style={{ paddingRight: 20 }}
          />
        </ListItem>
        <DialogSelectFolder
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open: false })}
          handleSelectFolder={folder => this.handleFolderChanged(folder)}
          open={this.state.open}
          title="Select the base folder"
        />
      </React.Fragment>
    );
  }
}

ButtonAddFolder.propTypes = {
  classes: PropTypes.object,
};

export default ButtonAddFolder;
