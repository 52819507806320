import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/material';
import { TextField, Grid } from '@mui/material';
import { GetData } from 'helpers';
import { QueryAccountTypes } from 'graphql/Users/utils_users';
import { Countries, SearchAccountCategories } from 'components';
import { MutationUpdateAccountDetails } from 'redux-store/actions';
import { UserMobile } from 'views/Account/components/Profile/components/AccountDetails/components';
import { ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
const user_status = ['Active', 'Request', 'Blocked', 'Suspended'];
class UserViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_types: [],
      loading: false,
      empty: false,
      changed: {},
    };
    const { holder } = props;
    if (holder) {
      holder.updateUserInformation = () => this.updateUserInformation();
    }
  }
  componentDidMount() {
    this.updateAccounts();
  }
  componentDidUpdate() {
    const { empty, account_types } = this.state;
    if (empty) return;
    if (account_types.length) return;
    this.updateAccounts();
  }
  updateAccounts = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryAccountTypes()
        .then(res => {
          const data = GetData(res);
          const { ok, accounts, errors } = data.accountTypes;
          if (ok) {
            this.setState({
              account_types: accounts,
              loading: false,
              empty: !accounts.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddAccountCategory = category => {
    const { user } = this.props;
    if (category) {
      user.category = category;
      this.props.handleAddAccountCategory(category);
    }
  };
  handleUserChange = event => {
    event.preventDefault();
    const id = event.target.id;
    const name = event.target.value;
    let { changed } = this.state;
    if (name && name.length) {
      changed = { ...changed, [id]: name };
    } else {
      delete changed[id];
    }
    this.setState({
      changed,
    });
    let changed_user = Object.keys(changed).length ? true : false;
    this.props.updateState({ changed_user });
  };
  updateUserInformation = () => {
    const { changed } = this.state;
    const total = Object.keys(changed).length;
    if (!total) {
      this.props.saveUserSettings();
      return;
    }
    const { saving_user, user } = this.props;
    if (saving_user) return;
    this.props.updateState({ saving_user: true });
    const input = {
      id: user.id,
      ...this.state.changed,
    };
    (async () => {
      MutationUpdateAccountDetails(input)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateAccountDetails;
          if (ok) {
            this.setState({ changed: {} });
            let { address } = user;
            if (IsInvalid(address)) address = {};
            if (input.city) address.city = input.city;
            if (input.country) address.country = input.country;
            if (input.line_1) address.line_1 = input.line_1;
            this.props.handleUpdateUser({
              ...user,
              ...input,
              address: { ...address },
            });
            this.props.updateState({
              saving_user: false,
              changed_user: false,
            });
            this.props.saveUserSettings();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.updateState({
            saving_user: false,
          });
          this.props.saveUserSettings(error);
        });
    })();
  };
  handleUserStatus = (e, status) => {
    const { user } = this.props;
    let { changed } = this.state;
    if (status && status.length && user.status !== status.toLowerCase()) {
      changed = { ...changed, status: status.toLowerCase() };
    } else {
      delete changed['status'];
    }
    this.setState({ changed });
    let changed_user = Object.keys(changed).length ? true : false;
    this.props.updateState({ changed_user });
  };
  render() {
    const { classes, user, setting, loading } = this.props;
    const { account_types, changed } = this.state;
    let index = 0;
    let account_type = null;
    let category = null;
    let index_status = 0;
    if (changed.status) {
      index_status = user_status
        .map(x => x.toLowerCase())
        .indexOf(changed.status);
      if (index_status < 0) index_status = 0;
    } else {
      index_status = user_status.map(x => x.toLowerCase()).indexOf(user.status);
      if (index_status < 0) index_status = 0;
    }
    if (setting && !loading && setting.type) {
      index = account_types.map(x => x.id).indexOf(setting.type.id);
      if (index >= 0) {
        account_type = account_types[index];
      }
    }
    if (setting && !loading && setting.category) {
      category = setting.category;
    }
    if (category === undefined || !category) {
      category = user.category;
    }
    let line_1 = null;
    let city = null;
    let country = null;
    let phone = user.phone;
    let request = user.request;
    const { address } = user;
    if (address) {
      if (address.city) city = address.city;
      if (address.country) country = address.country;
      if (address.line_1) line_1 = address.line_1;
    }
    if (changed.phone) {
      phone = changed.phone;
    }
    if (changed.request) {
      request = changed.request;
    }
    if (changed.city) {
      city = changed.city;
    }
    if (changed.country) {
      country = changed.country;
    }
    if (changed.line_1) {
      line_1 = changed.line_1;
    }
    let ComponentRequest = null;
    if (user.isActive) {
      ComponentRequest = (
        <Grid item style={{ width: '100%', marginTop: 10 }} xs={12}>
          <TextField
            className={classes.marginright}
            fullWidth
            id="request"
            InputLabelProps={{
              shrink: true,
            }}
            label="Reason request"
            onChange={this.handleUserChange}
            size="small"
            value={request}
          />
        </Grid>
      );
    }
    return (
      <Grid
        alignItems="flex-start"
        // className={classes.superior}
        container
        direction="column"
        justifyContent="flex-start"
        style={{ width: '100%' }}>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item style={{ width: '100%' }} xs={5}>
            <TextField
              className={classes.marginright}
              fullWidth
              id="title"
              label="Title"
              size="small"
              type="title"
              value={user.title}
            />
          </Grid>
          <Grid item style={{ width: '100%' }} xs={8}>
            <Autocomplete
              className={classes.type}
              defaultValue={account_types[index]}
              getOptionLabel={option => option.description}
              id="account-type"
              onChange={this.props.handleTypeChanged}
              options={account_types}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Account type"
                  variant="standard"
                />
              )}
              size="small"
              value={account_type}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SearchAccountCategories
              handleAddAccountCategory={this.handleAddAccountCategory}
              no_icon
              selected_type={category}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              alignItems="center"
              container
              direction="row"
              item={12}
              justifyContent="space-between"
              spacing={1}>
              <Grid item xs={5}>
                <TextField
                  className={classes.marginright}
                  fullWidth
                  id="username-user"
                  label="Username"
                  size="small"
                  value={user.username}
                />
              </Grid>
              <Grid item xs={7}>
                <UserMobile
                  external
                  handlePhoneChange={phone =>
                    this.handleUserChange({
                      target: {
                        value: phone,
                        id: 'phone',
                      },
                      preventDefault: () => '',
                    })
                  }
                  mt={0}
                  phone={phone}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', marginTop: 10 }} xs={12}>
            <TextField
              className={classes.marginright}
              fullWidth
              id="email-user"
              label="E-Mail"
              size="small"
              type="email"
              value={user.email}
            />
          </Grid>
          {ComponentRequest}
          <Grid item xs={12}>
            <TextField
              className={classes.marginright}
              fullWidth
              id="line_1"
              InputLabelProps={{
                shrink: true,
              }}
              label="Street"
              onChange={this.handleUserChange}
              placeholder="Street missing..."
              size="small"
              value={line_1}
            />
          </Grid>
          <Grid item style={{ width: '100%', marginTop: 10 }} xs={12}>
            <Grid
              alignItems="center"
              container
              direction="row"
              item={12}
              justifyContent="space-between"
              spacing={1}>
              <Grid item xs={5}>
                <TextField
                  className={classes.marginright}
                  fullWidth
                  id="city"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="City"
                  onChange={this.handleUserChange}
                  placeholder="City missing..."
                  size="small"
                  value={city}
                />
              </Grid>
              <Grid item xs={7}>
                <Countries
                  country={country}
                  defaultCountry={country ? country : ''}
                  handleCountryChanged={selected =>
                    this.handleUserChange({
                      target: {
                        id: 'country',
                        value: selected ? selected.name : '',
                      },
                      preventDefault: () => '',
                    })
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  className={classes.type}
                  defaultValue={user_status[index_status]}
                  id="user-status"
                  onChange={this.handleUserStatus}
                  options={user_status}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="User Status"
                    />
                  )}
                  size="small"
                  value={user_status[index_status]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

UserViewUser.propTypes = {
  classes: PropTypes.object,
};

export default UserViewUser;
