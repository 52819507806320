/* eslint-disable quotes */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { CheckBoxIcon } from 'components';

// Inspired by blueprintjs
function StyledCheckbox(props) {
  const { title, checked } = props;
  return (
    <Tooltip title={title}>
      <div>
        <CheckBoxIcon checked={checked} {...props} />
      </div>
    </Tooltip>
  );
}

export default function TablePermissionItem(props) {
  const {
    row: { Rights },
  } = props;
  let W = false;
  let R = false;
  let De = false;
  let Do = false;
  let F = false;
  if (Rights && Rights.length) {
    const { write, download, delete: delete_right, read, full } = Rights[0];
    W = write;
    R = read;
    De = delete_right;
    F = full;
    Do = download;
  }
  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="center"
      style={{ padding: 0, margin: 0 }}>
      <StyledCheckbox checked={W} title="Write" />
      <StyledCheckbox checked={R} title="Read" />
      <StyledCheckbox checked={Do} title="Download" />
      <StyledCheckbox checked={De} title="Delete" />
      <StyledCheckbox checked={F} title="Full" />
    </Grid>
  );
}
