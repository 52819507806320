import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_CENTERS = gql`
  query($name: String, $page: Int, $limit: Int) {
    centers(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      centers {
        id
        idAdmin
        idAddress
        admin {
          id
          FullName
        }
        address {
          id
          country
          city
          line_1
        }
        name

        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryCenters = (name, page, limit) => {
  const variables = {};
  if (typeof name !== 'undefined' && name !== '') {
    variables.name = name;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined' && limit) {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_CENTERS),
    variables,
  });
};

const MUTATION_CREATE_CENTER = gql`
  mutation($new_center: InputCenter!) {
    createCenter(new_center: $new_center) {
      ok
      errors {
        path
        message
      }
      center {
        id
        idAdmin
        idAddress
        admin {
          id
          FullName
        }
        address {
          id
          country
          city
          line_1
        }
        name

        updatedAt
        createdAt
      }
    }
  }
`;

export const MutationCreateCenter = new_center => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_CENTER),
    variables: {
      new_center,
    },
  });
};

const MUTATION_UPDATE_CENTER = gql`
  mutation($idCenter: LongLong!, $new_center: InputCenter!) {
    updateCenter(idCenter: $idCenter, new_center: $new_center) {
      ok
      errors {
        path
        message
      }
      center {
        id
        idAdmin
        idAddress
        admin {
          id
          FullName
        }
        address {
          id
          country
          city
          line_1
        }
        name

        updatedAt
        createdAt
      }
    }
  }
`;

export const MutationUpdateCenter = (idCenter, new_center) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_CENTER),
    variables: {
      idCenter,
      new_center,
    },
  });
};
