import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonLoad } from 'components';
import { Grid } from '@mui/material';
import { GetData, ServerErrorsString } from 'helpers';
import { Replay as ReloadIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MutationAddAccountClinicalData,
  MutationRemoveAccountClinicalData,
} from 'graphql/DataClinics/utils_clinical_accounts';

class ToolBarAccountClinical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDelete = () => {
    let {
      account_clinicals,
      new_accounts,
      selected,
      deleting,
    } = this.props.state;
    new_accounts = new_accounts.filter(x => {
      const i = selected.map(x => x.id).indexOf(x.id);
      if (i !== -1) selected.splice(x, 1);
      return i === -1;
    });
    console.log({ selected });
    if (!selected.length) {
      this.props.setState({ deleting: false, new_accounts });
      return;
    }
    if (deleting) return;
    this.props.setState({ deleting: true });
    (async () => {
      MutationRemoveAccountClinicalData(
        selected.map(x => {
          return {
            idAccountType: x.idAccountType,
            idClinicalDataType: x.idClinicalDataType,
          };
        })
      )
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeAccountClinicalData;
          if (ok) {
            this.props.setState({
              deleting: false,
              new_accounts,
              account_clinicals: account_clinicals.filter(x => {
                const i = selected.map(x => x.id).indexOf(x.id);
                return i === -1;
              }),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  saveAccountClinicalDataType = () => {
    const { saving, state } = this.props;
    if (saving) return;
    let { account_clinicals, new_accounts } = state;
    this.props.setState({ saving: true });
    const inputs = new_accounts.map(x => {
      return {
        idAccountType: x.idAccountType,
        idClinicalDataType: x.idClinicalDataType,
      };
    });
    (async () => {
      MutationAddAccountClinicalData(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAccountClinicalData;
          if (ok) {
            const new_account_clinicals =
              data.addAccountClinicalData.account_clinicals;
            new_accounts = new_accounts.filter(x => {
              const i = new_account_clinicals
                .map(x => this.props.getId(x))
                .indexOf(this.props.getId(x));
              return i === -1;
            });
            this.props.setState({
              saving: false,
              new_accounts,
              account_clinicals: new_account_clinicals.concat(
                new_accounts.concat(account_clinicals)
              ),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, new_accounts, saving, state } = this.props;
    const disabled = new_accounts.length ? false : true;
    const { selected, deleting } = state;
    let ComponentDelete = null;
    if (selected.length) {
      ComponentDelete = (
        <IconButtonLoad
          handleClick={this.handleDelete}
          icon={<DeleteIcon style={{ color: 'red' }} />}
          loading={deleting}
          small
          tooltip="Delete Selected"
        />
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        p={1}
        spacing={1}>
        <Grid container item xs={6}>
          <IconButtonLoad
            disabled={disabled || saving}
            handleClick={this.saveAccountClinicalDataType}
            icon={<SaveIcon style={{ color: disabled ? 'gray' : 'blue' }} />}
            loading={saving}
            small
            tooltip="Save Clinical Type"
          />
          <IconButtonLoad
            handleClick={this.props.onOpenDialog}
            icon={<AddIcon style={{ color: 'green' }} />}
            small
            tooltip="Add Clinical Type"
          />
        </Grid>
        <Grid container item justifyContent="flex-end" xs={6}>
          {ComponentDelete}
          <IconButtonLoad
            handleClick={this.props.onReloadTable}
            icon={<ReloadIcon />}
            loading={loading}
            small
            tooltip="Reload Table"
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarAccountClinical.propTypes = {
  new_images: PropTypes.array,
};
ToolBarAccountClinical.defaultProps = {
  new_images: [],
};
export default ToolBarAccountClinical;
