import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PdfService = {
  generatePdf: (data, headers, title) => {
    console.log({ data, headers, title });
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    return doc.save('report.pdf');
  },
};

export default PdfService;
