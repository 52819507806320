import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SHARED_LINKS = gql`
  query($code: String, $page: Int, $limit: Int) {
    sharedLinks(code: $code, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      shared_links {
        idLink
        idUser
        idFolder
        idFolderContent

        code
        private_access

        user {
          FullName
        }
        folder {
          name
          folders_paths {
            idFolder
            idParent
            name
          }
        }
        content {
          idFolderContent
          Folder {
            name
            idFolder
            folders_paths {
              idFolder
              idParent
              name
            }
          }
          Series {
            idSeries
            idStudy
            SeriesDescription
            Size
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            locations {
              idFolderContent
              Folder {
                idFolder
                name
                folders_paths {
                  idFolder
                  name
                }
              }
            }
            updatedAt
            createdAt
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySharedLinks = (code, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SHARED_LINKS),
    variables: {
      code,
      page,
      limit,
    },
  });
};
const MUTATION_UPDATE_SHARED_LINKS = gql`
  mutation($inputs: [InputShareLink!]!) {
    updateSharedLinks(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationUpdateSharedLinks = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_SHARED_LINKS),
    variables: {
      inputs,
    },
  });
};

const MUTATION_DELETE_SHARED_LINKS = gql`
  mutation($inputs: [InputShareLink!]!) {
    deleteSharedLinks(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteSharedLinks = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_SHARED_LINKS),
    variables: {
      inputs,
    },
  });
};
