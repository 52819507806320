import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  convertBytesToMbsOrKbs,
  createFileFromUrl,
  findFileIndex,
} from '../helpers/helpers';
import styles from './styles';
import {
  DroppUploadFiles,
  PreviewDropped,
  SnackDroppedFiles,
} from './components';
let count_file = 0;
let message = '';
class DropFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileObjects: [],
      openSnackBar: false,
      snackbarMessage: '',
      snackbarVariant: 'success',
      dropzoneText: props.dropzoneText,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined' && holder) {
      holder.deleteFile = file => {
        let { fileObjects } = this.state;
        const i = this.getIndex(file);
        if (i === -1) return;
        fileObjects.splice(i, 1);
        this.setState({ fileObjects });
      };
      holder.clearAll = () => {
        this.setState({ fileObjects: [] });
      };
    }
  }

  async filesArray(urls) {
    try {
      for (const url of urls) {
        //
        const file = await createFileFromUrl(url);
        const reader = new FileReader();
        reader.onload = event => {
          this.setState({
            fileObjects: this.state.fileObjects.concat({
              file: file,
              data: event.target.result,
            }),
          });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  getIndex = file => {
    const { fileObjects } = this.state;
    let { filename, name, size, filesize } = file;
    if (name === undefined) name = filename;
    if (size === undefined) size = filesize;
    const i = fileObjects.map(x => x.file.name).indexOf(name);
    if (i !== -1 && size === fileObjects[i].file.size) {
      return i;
    }
    return -1;
  };

  componentDidMount() {
    this.filesArray(this.props.initialFiles);
  }

  componentWillUnmount() {
    if (this.props.clearOnUnmount) {
      this.setState({
        fileObjects: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dropzoneText !== prevProps.dropzoneText) {
      this.setState({
        dropzoneText: this.props.dropzoneText,
      });
    }
    if (this.props.deleteFile !== prevProps.deleteFile) {
      const fileIndex = findFileIndex(
        this.state.fileObjects,
        this.props.deleteFile
      );
      this.executeRemove(fileIndex);
    }
    if (this.props.deleteAll !== prevProps.deleteAll) {
      this.setState({
        fileObjects: [],
      });
      this.props.handleDeletedAll();
    }
  }
  addWithoutData = (total, file) => {
    this.setState(
      prev => ({
        fileObjects: prev.fileObjects.concat({
          file: file,
        }),
      }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(
            this.state.fileObjects.map(fileObject => fileObject.file)
          );
        }
        if (this.props.onDrop) {
          this.props.onDrop(file);
        }
        message += this.props.getFileAddedMessage(file.name);
        count_file++; // we cannot rely on the index because this is asynchronous
        //
        if (count_file === total) {
          // display message when the last one fires
          this.setState({
            openSnackBar: true,
            snackbarMessage: message,
            snackbarVariant: 'success',
          });
        }
      }
    );
  };
  // addWithData = (_this, total, file, message) => {
  //   const THIS = this;
  //   const reader = new FileReader();
  //   reader.onload = event => {
  //     _this.setState(
  //       {
  //         fileObjects: _this.state.fileObjects.concat({
  //           file: file,
  //           data: event.target.result,
  //         }),
  //       },
  //       () => {
  //         if (this.props.onChange) {
  //           this.props.onChange(
  //             _this.state.fileObjects.map(fileObject => fileObject.file)
  //           );
  //         }
  //         if (this.props.onDrop) {
  //           this.props.onDrop(file);
  //         }
  //         message += this.props.getFileAddedMessage(file.name);
  //         count_file++; // we cannot rely on the index because this is asynchronous
  //         console.log({ count_file, total, message });
  //         if (count_file === total) {
  //           // display message when the last one fires
  //           this.setState({
  //             openSnackBar: true,
  //             snackbarMessage: message,
  //             snackbarVariant: 'success',
  //           });
  //         }
  //       }
  //     );
  //   };
  //   reader.readAsDataURL(file);
  // };
  onDrop = files => {
    if (this.state.fileObjects.length + files.length > this.props.filesLimit) {
      this.setState({
        openSnackBar: true,
        snackbarMessage: this.props.getFileLimitExceedMessage(
          this.props.filesLimit
        ),
        snackbarVariant: 'error',
      });
    } else {
      count_file = 0;
      message = '';
      const total = files.length;
      files.forEach(file => {
        const i = this.state.fileObjects
          .map(x => x.file.name)
          .indexOf(file.name);
        if (i !== -1 && file.size === this.state.fileObjects[i].file.size) {
          this.setState({
            openSnackBar: true,
            snackbarMessage: `File name is here (${file.name})`,
            snackbarVariant: 'error',
          });
          return;
        }
        if (file.type === '') {
          const extension = file.name.split('.').pop();
          if (extension.length) {
            Object.defineProperty(file, 'type', {
              enumerable: false,
              configurable: false,
              writable: true,
              value: 'static',
            });
            file.type = `application/${extension}`;
          }
        }
        this.addWithoutData(total, file);
        // const { size } = file;
        // if (size >= 1000000000 || IsInvalid(size)) {
        //   //Files bigger of 1G
        // } else {
        //   this.addWithData(_this, total, file, message);
        // }
      });
    }
  };
  handleRemove = fileIndex => event => {
    event.stopPropagation();
    this.executeRemove(fileIndex);
  };
  executeRemove = fileIndex => {
    //
    const { fileObjects } = this.state;
    const file = fileObjects.filter((fileObject, i) => {
      return i === fileIndex;
    })[0].file;
    fileObjects.splice(fileIndex, 1);
    this.setState(fileObjects, () => {
      if (this.props.onDelete) {
        this.props.onDelete(file);
      }
      if (this.props.onChange) {
        this.props.onChange(
          this.state.fileObjects.map(fileObject => fileObject.file)
        );
      }
      this.setState({
        openSnackBar: true,
        snackbarMessage: this.props.getFileRemovedMessage(file.name),
        snackbarVariant: 'info',
      });
    });
  };

  handleDropRejected(rejectedFiles, evt) {
    var message = '';
    rejectedFiles.forEach(rejectedFile => {
      message = this.props.getDropRejectMessage(
        rejectedFile,
        this.props.acceptedFiles,
        this.props.maxFileSize
      );
    });
    if (this.props.onDropRejected) {
      this.props.onDropRejected(rejectedFiles, evt);
    }
    this.setState({
      openSnackBar: true,
      snackbarMessage: message,
      snackbarVariant: 'error',
    });
  }

  onCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const { classes } = this.props;
    const showPreviews =
      this.props.showPreviews && this.state.fileObjects.length > 0;
    return (
      <Fragment>
        <DroppUploadFiles
          classes={classes}
          dropzoneClass={this.props.dropzoneClass}
          dropzoneParagraphClass={this.props.dropzoneParagraphClass}
          dropzoneText={this.state.dropzoneText}
          handleDropRejected={this.handleDropRejected}
          maxSize={this.props.maxFileSize}
          onDrop={this.onDrop}
        />
        <PreviewDropped showPreviews={showPreviews} />
        <SnackDroppedFiles
          onCloseSnackbar={this.onCloseSnackbar}
          openSnackBar={this.state.openSnackBar}
          showAlerts={this.props.showAlerts}
          snackbarMessage={this.state.snackbarMessage}
          snackbarVariant={this.state.snackbarVariant}
        />
      </Fragment>
    );
  }
}

DropFiles.defaultProps = {
  deleteAll: false,
  acceptedFiles: ['image/*', 'video/*', 'application/*'],
  filesLimit: 3,
  maxFileSize: 3000000,
  // dropzoneText: 'Drag and drop an image file here or click',
  dropzoneText: 'Drop files here or click to open the file upload dialog',
  showPreviews: false, // By default previews show up under in the dialog and inside in the standalone
  showPreviewsInDropzone: true,
  showFileNamesInPreview: false,
  showAlerts: true,
  clearOnUnmount: true,
  initialFiles: [],
  getFileLimitExceedMessage: filesLimit =>
    `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`,
  getFileAddedMessage: fileName => `File ${fileName} successfully added.\n`,
  getFileRemovedMessage: fileName => `File ${fileName} removed.`,
  getDropRejectMessage: (rejectedFile, acceptedFiles, maxFileSize) => {
    let message = `File ${rejectedFile.name} was rejected.\n`;
    if (!acceptedFiles.includes(rejectedFile.type)) {
      message += 'File type not supported.\n';
    }
    if (rejectedFile.size > maxFileSize) {
      message +=
        'File is too big. Size limit is ' +
        convertBytesToMbsOrKbs(maxFileSize) +
        '.\n';
    }
    return message;
  },
  onChange: () => {},
  onDrop: () => {},
  onDropRejected: () => {},
  onDelete: () => {},
};
DropFiles.propTypes = {
  acceptedFiles: PropTypes.array,
  clearOnUnmount: PropTypes.bool,
  deleteAll: PropTypes.bool,
  dropzoneClass: PropTypes.string,
  dropzoneText: PropTypes.string,
  filesLimit: PropTypes.number,
  getDropRejectMessage: PropTypes.func,
  getFileAddedMessage: PropTypes.func,
  getFileLimitExceedMessage: PropTypes.func,
  getFileRemovedMessage: PropTypes.func,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  showAlerts: PropTypes.bool,
  showFileNamesInPreview: PropTypes.bool,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
};
export default withStyles(DropFiles, styles);
