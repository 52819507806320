export const IsEditAllow = user => {
  if (user === undefined || !user) return false;
  const { idCategory, type } = user;
  if (idCategory !== 2) return false;
  if (type === 1 || type === 11) return true;
  return false;
};
export const IsProjectAdministrator = user => {
  if (user === undefined || !user) return false;
  const { type } = user;
  if (type === 1 || type === 6) return true;
  return false;
};
