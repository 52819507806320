import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { green } from '@mui/material/colors';
import { Transition } from 'components';

const useStyles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  switchBase: {
    color: green[500],
    '&$checked': {
      color: green[800],
    },
    '&$checked + $track': {
      backgroundColor: green[800],
    },
  },
});

class DialogDockerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      force: false,
      noprune: false,
    };
  }

  handleCheckForce = event => {
    this.setState({ force: event.target.checked });
  };
  handleCheckNoProne = event => {
    this.setState({ noprune: event.target.checked });
  };

  render() {
    const { classes, handleClose, open } = this.props;
    return (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        keepMounted
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}>
        <DialogTitle id="alert-dialog-slide-title">
          Delete Docker Image
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You are about to delete the seleted images, this action cannot be
            undone. Do you want to continue?
          </DialogContentText>
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={this.state.force}
                classes={{
                  switchBase: classes.switchBase,
                  //   track: {},
                  //   checked: {},
                }}
                onChange={this.handleCheckForce}
              />
            }
            label="Force"
          />
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                checked={this.state.noprune}
                classes={{
                  switchBase: classes.switchBase,
                  //   track: {},
                  //   checked: {},
                }}
                onChange={this.handleCheckNoProne}
              />
            }
            label="No-prune"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this.props.executeDeleteSelected(this.state)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogDockerImage.propTypes = {
  classes: PropTypes.object,
};
DialogDockerImage.defaultProps = {
  handleClose: () => '',
  open: false,
};

export default withStyles(DialogDockerImage, useStyles);
