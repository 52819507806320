import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_GROUP_MAPPING = gql`
  query($group: String!, $name: String, $page: Int, $limit: Int) {
    allGroupMapping(group: $group, name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idGroup
        name
        group {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryGroupMapping = (group, name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_GROUP_MAPPING),
    variables: {
      group,
      name,
      page,
      limit,
    },
  });
};

const MUTATION_DELETE_GROUP_MAPPING = gql`
  mutation($idGroup: LongLong!, $ids: [LongLong!]!) {
    removeGroupMapping(idGroup: $idGroup, ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveGroupMapping = (idGroup, ids) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_GROUP_MAPPING),
    variables: {
      idGroup,
      ids,
    },
  });
};
const MUTATION_ADD_GROUP_MAPPING = gql`
  mutation($inputs: [InputGroupMapping!]!) {
    addGroupMappings(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idGroup
        name
        group {
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddGroupMapping = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_GROUP_MAPPING),
    variables: {
      inputs,
    },
  });
};
