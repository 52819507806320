import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import {
  MutationAddAnonymizationRules,
  MutationRemoveAnonymizationRules,
  MutationUploadRulesCSV,
} from 'graphql/Anonymization';
import { ServerErrorsString, GetData, IsInvalid } from 'helpers';
import DialogCreateRule from './DialogCreateRule';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

class TabRulesToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_info: false,
      deleting: false,
      open_create: false,
      uploading: false,
      open_delete: false,
    };
  }
  handleCreateDialog = () => {};
  handleSelectFile = event => {
    const { uploading } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }
    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadRulesCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.uploadAnonymizationRules;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handelAddRules(rules);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveRules = () => {
    const { rows_edited } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      const { tag, tag_name, tag_rule, tag_options } = x;
      return {
        tag,
        tag_name,
        tag_rule,
        tag_options,
      };
    });
    this.setState({ saving: true });
    (async () => {
      MutationAddAnonymizationRules(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addAnonymizationRules;
          if (ok) {
            this.setState({ saving: false });
            this.props.handelAddRules(rows_edited);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { selected, rules } = this.props;
    const { deleting } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    (async () => {
      MutationRemoveAnonymizationRules(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeAnonymizationRules;
          if (ok) {
            this.props.setState({
              rules: rules.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
            });
            this.setState({
              deleting: false,
              open_delete: false,
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected, rows_edited } = this.props;
    let color_save = 'gray';
    if (rows_edited.length && !this.state.saving) {
      color_save = 'blue';
    } else if (this.state.saving) {
      color_save = 'read';
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}
          pt={1}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button
                disabled={!rows_edited.length || this.state.saving}
                onClick={this.handleSaveRules}>
                <SaveIcon style={{ color: color_save }} />
              </Button>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'green' }} />
              </Button>
              <ButtonDownloadType
                key="anonymization"
                type="anonymization_rules"
              />
              <ButtonFileUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
              <Button onClick={this.props.handleOpenHelp}>
                <HelpOutlineIcon style={{ color: 'green' }} />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogCreateRule
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleCreatedTagRule={rules => {
            this.setState({ open_create: false });
            this.props.handelAddRules(rules);
          }}
          open={this.state.open_create}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the rule, this action cannot be undone"
          title_text="Delete Selected Rules"
        />
      </React.Fragment>
    );
  }
}

TabRulesToolbar.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.array,
};
TabRulesToolbar.defaultProps = {
  selected: [],
  rows_edited: [],
};

export default TabRulesToolbar;
