import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@mui/material';
import { MainTable, MainTableToolbar } from 'components/Tables/components';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { columns } from './utils';
import { SelectedArray, rowsPerPageBig } from 'helpers';
import { withStyles } from 'tss-react/mui';
import { IsInvalid } from 'helpers';
import { withRouter } from 'react-router-dom';

class TableUnsortedSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { holder } = props;
    if (holder) {
      holder.cleanSelected = () => '';
    }
  }

  getFileID = file => {
    const { idSeries, idImageMr, name } = file;
    let idSelected = null;
    if (idSeries) idSelected = idSeries;
    else if (idImageMr) idSelected = idImageMr;
    return `${idSelected}_${name}`;
  };
  isSelected = file => {
    let { selected } = this.props;
    selected = selected.concat(this.props.selected);
    const index = selected
      .map(x => this.getFileID(x))
      .indexOf(this.getFileID(file));
    return index !== -1;
  };
  handleSelectedClick = (event, index, file) => {
    event.preventDefault();
    let { selected } = this.props;
    //
    const selectedIndex = selected
      ? selected.map(x => this.getFileID(x)).indexOf(this.getFileID(file))
      : 0;
    const newSelected = SelectedArray(selected, file, selectedIndex);
    this.props.handleSetSelected(newSelected);
    return newSelected.length;
  };
  handleSelectAllClick = event => {
    let selected = [];
    if (event.target.checked) {
      selected = this.props.rows;
    }
    this.props.handleSetSelected(selected);
  };
  handleClearSelected = () => {
    this.props.handleSetSelected([]);
  };
  handleButtonView = row => {
    if (IsInvalid(row)) return;
    const { series } = row;
    if (IsInvalid(series)) return;
    const { locations } = series;
    if (IsInvalid(locations) || !locations.length) return;
    console.log({ locations });
    this.props.history.push(`/explorer/folder/${locations[0].idFolder}`);
  };
  render() {
    const { classes, selected, loading, rows, total, height } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    let ComponentToolBar = null;
    if (!this.props.hide_toolbar) {
      ComponentToolBar = (
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="Unsorted Sequences"
        />
      );
    }
    return (
      <Paper className={classes.root}>
        {ComponentToolBar}
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            dense
            disable_splice={this.props.disable_splice}
            handleButtonView={this.handleButtonView}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            isSelected={this.isSelected}
            limit={this.props.limit}
            loading={loading}
            page={this.props.page}
            rows={rows}
            selected={selected}
          />
        </div>
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableUnsortedSequences.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
};
TableUnsortedSequences.defaultProps = {
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  rows: [],
  total: 0,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: () => '',
  selected: [],
  external: false,
  disable_splice: false,
  hide_toolbar: false,
};
export default withStyles(withRouter(TableUnsortedSequences), useStyles);
