import { getDate, getUserFullName } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'total_projects',
    label: 'Projects',
    minWidth: 100,
  },
  {
    id: 'admin',
    label: 'Administrator',
    minWidth: 170,
    align: 'left',
    format: value => getUserFullName(value),
  },
  {
    id: 'total_members',
    label: 'Number of Users',
    minWidth: 170,
    align: 'right',
    type: 'count',
  },
  {
    id: 'createdAt',
    label: 'Created',
    minWidth: 200,
    align: 'right',
    format: value => getDate(value),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'right',
    type: 'buttom',
  },
];
