import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import { MutationCreatePatient } from 'graphql/Surveys';
import { Divider, Grid } from '@mui/material';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { DatePicker } from 'components';
import moment from 'moment/moment';
const defaultPatient = {
  GivenName: '',
  FamilyName: '',
  PatientID: '',
  PatientBirthDate: moment().format('YYYY-M-DD'),
};
class DialogCreatePatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient: {
        ...defaultPatient,
      },
      loading: false,
    };
  }
  handleAtDateChange = date => {
    const PatientBirthDate = moment(date).format('YYYY-M-DD');
    console.log({ date, PatientBirthDate });
    const { patient } = this.state;
    this.setState({
      patient: { ...patient, PatientBirthDate },
    });
  };
  handleText = event => {
    event.preventDefault();
    const { patient } = this.state;
    this.setState({
      patient: { ...patient, [event.target.id]: event.target.value },
    });
  };
  handleCreate = () => {
    const { loading, patient } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationCreatePatient(patient)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.createPatient;
          if (ok) {
            const new_patient = data.createPatient.patient;
            this.props.addNewPatient({
              ...patient,
              ...new_patient,
            });
            console.log({ new_patient });
            this.setState({ loading: false, patient: { ...defaultPatient } });
            this.props.handleClose();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false });
        });
    })();
  };
  render() {
    const { patient } = this.state;
    const { open, handleClose } = this.props;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Create Patient</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Add the all the patient information.
          </DialogContentText>
          <Grid container spacing={1} sx={{ paddingTop: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="GivenName"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Firstname"
                onChange={this.handleText}
                required
                size="small"
                value={patient.GivenName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="FamilyName"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Lastname"
                onChange={this.handleText}
                required
                size="small"
                value={patient.FamilyName}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <TextField
                fullWidth
                id="PatientBirthDate"
                label="Birthdate"
                onChange={this.handleText}
                placeholder="YYYY-MM-DD"
                required
                size="small"
                value={patient.PatientBirthDate}
              /> */}
              <DatePicker
                date={patient.PatientBirthDate}
                // disabled={disable_at_date}
                handleDateChange={this.handleAtDateChange}
                label="Birthdate"
                // format="dd/MM/yyyy"
                // id="at-date-picker-dialog"
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
                // label="At date:"
                // margin="normal"
                // onChange={this.props.handleAtDateChange}
                // renderInput={props => <TextField {...props} />}
                // value={selectedAtDate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="PatientID"
                InputLabelProps={{
                  shrink: true,
                }}
                label="PID"
                onChange={this.handleText}
                required
                size="small"
                value={patient.PatientID}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={this.state.loading}
            onClick={this.handleCreate}>
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogCreatePatient.propTypes = {
  addNewPatient: PropTypes.func,
};
DialogCreatePatient.defaultProps = {
  addNewPatient: () => '',
};
export default DialogCreatePatient;
