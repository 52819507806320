import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { LogsUser } from 'views/Management/components/Logs/components';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    let internal_height = height - 510;
    if (internal_height < 200) {
      internal_height = null;
    }
    return (
      <Grid container style={{ padding: 10, paddingTop: 5, paddingBottom: 0 }}>
        <Grid item xs={12}>
          <LogsUser height={internal_height} />
        </Grid>
      </Grid>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.object,
};

export default Notifications;
