import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
  },
  {
    id: 'User',
    label: 'Location',
    minWidth: 170,
    align: 'left',
    format: value => {
      const { address } = value;
      return address.country;
    },
  },
  {
    id: 'createdAt',
    label: 'Registration Date',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];

const drawerWidth = 240;

export const useStyles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth - 10,
  },
});
