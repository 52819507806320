import { PAGE_SHARED_SET_SUBFOLDERS } from '../actions/types';

const initialState = {
  subfolders: [],
};

export default function reducer_page_shared(state = initialState, action) {
  const { type } = action;
  switch (type) {
    // case LOGOUT_USER:
    //   return initialState;
    case PAGE_SHARED_SET_SUBFOLDERS:
      return {
        ...state,
        subfolders: action.subfolders,
      };
    default:
      return state;
  }
}
