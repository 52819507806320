import React from 'react';
import PropTypes from 'prop-types';
import { GetData, ServerErrorsString } from 'helpers';
import {
  Dialog,
  Typography,
  DialogContent,
  Divider,
  Grid,
  Stack,
  ButtonGroup,
} from '@mui/material';
import { CardEditHeader } from 'components/Cards';
import CardHeader from '@mui/material/CardHeader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  ClinicalFileCatalogueViewer,
  ClinicalPatientViewer,
  ClinicalChildObjectViewer,
} from 'components/DataClinic';
import { QueryStrokeCase } from 'graphql/DataClinics/stroke/utils_case';
import { QueryEpilepsyCase, QuerySleepCase } from 'graphql/DataClinics';
import { getTransformedObject } from './components/ObjectHelper';
import { ButtonDownloadType, ButtonDownloadPDF } from 'components';
import { header_patients } from '../../../views/DataClinics/components/PatientClinics/components/Styles';
import { labels } from '../../../views/DataClinics/components/utils_text';

class DialogViewClinicalDataCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: null,
      file: null,
      currentCase: null,
      selectedCase: this.props.clinical_case,
      pdfRequested: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.clinical_case !== prevProps.clinical_case) {
      const { clinical_case, project } = this.props;
      if (!clinical_case) return;
      const id = clinical_case.id;
      console.log(id);
      if (project === labels.studies.stroke) {
        this.queryCase(labels.studies.stroke, QueryStrokeCase, id);
      } else if (project === labels.studies.epilepsy) {
        this.queryCase(labels.studies.epilepsy, QueryEpilepsyCase, id);
      } else if (project === labels.studies.sleep) {
        this.queryCase(labels.studies.sleep, QuerySleepCase, id);
      } else {
        return;
      }
    }
  }

  queryCase = async (projectType, queryFunction, id) => {
    try {
      const res = await queryFunction(id);
      const data = GetData(res);
      let caseData;

      switch (projectType) {
        case labels.studies.stroke:
          caseData = data.ssrCase;
          break;
        case labels.studies.epilepsy:
          caseData = data.epilepsyCase;
          break;
        case labels.studies.sleep:
          caseData = data.sleepCase;
          break;
        default:
          throw new Error(`Unknown project type: ${projectType}`);
      }

      const { ok, errors, cases, selectedCase } = caseData;

      if (ok) {
        this.setState({
          currentCase:
            projectType === labels.studies.stroke ? selectedCase : cases,
          loading: false,
        });
        if (errors && errors.length) {
          throw errors;
        }
      } else {
        throw errors;
      }
    } catch (error) {
      this.setState({ loading: false });
      if (projectType === labels.studies.sleep) {
        this.props.handleAddError(ServerErrorsString(error));
      } else {
        throw error;
      }
    }
  };

  setPdfRequested = () => {
    this.setState({ pdfRequested: !this.state.pdfRequested });
  };

  handleClose = () => {
    this.setState({ content: null });
    this.props.handleClose();
  };
  render() {
    const { open, clinical_case, columns, project } = this.props;
    const pageId = `clinical_${project}`;
    let ids, tables, files;
    if (clinical_case) {
      ids = [clinical_case.id];
    }
    if (this.state.currentCase) {
      const result = getTransformedObject({
        columns,
        currentCase: this.state.currentCase,
        project,
      });

      tables = result.tables;
      files = result.files ? result.files : null;
    } else {
      return null;
    }

    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={
            this.state.currentCase
              ? `IdCase: ${this.state.currentCase.idCase}`
              : ''
          }
          handleClose={this.handleClose}
          icon={<VisibilityIcon style={{ color: 'green' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              Stroke Case Viewer
            </Typography>
          }
        />
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={0.5}
          pt={0.5}>
          <Grid item ml={7} xs={12}>
            <ButtonGroup aria-label="Basic button group" variant="outlined">
              <ButtonDownloadType
                inputs={{ ids: ids }}
                key={pageId}
                type={pageId}
              />
              <ButtonDownloadPDF
                downloadFileName={pageId}
                rootElementId="download_button"
                setPdfRequested={this.setPdfRequested}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <Grid
              columns={{ xs: 4, sm: 8, md: 12 }}
              container
              direction="row-reverse"
              id="download_button"
              spacing={{ xs: 2, md: 3 }}>
              <Grid item md={12} sm={12} xs={12}>
                <CardHeader
                  title={
                    <Typography
                      display="inline"
                      gutterBottom
                      style={{
                        marginRight: '15px',
                        textTransform: 'none',
                        fontWeight: 600,
                      }}
                      variant="h4">
                      Stroke Case Viewer
                    </Typography>
                  }
                />
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              </Grid>
              <Grid
                id="clinical_patient"
                item
                xs={this.state.pdfRequested ? 12 : 4}>
                <Stack direction="column">
                  {this.state.currentCase && (
                    <ClinicalPatientViewer
                      columns={header_patients}
                      currentCase={this.state.currentCase}
                    />
                  )}
                  <Divider
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                  {files && (
                    <ClinicalFileCatalogueViewer
                      files={files}
                      history={this.props.history}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={this.state.pdfRequested ? 12 : 8}>
                <Stack direction="column">
                  {tables &&
                    tables.map((table, index) => (
                      <React.Fragment key={table.columns}>
                        {index ? (
                          <Divider
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                          />
                        ) : null}
                        <ClinicalChildObjectViewer
                          columns={table.columns}
                          object={table.object}
                          title={table.title}
                        />
                      </React.Fragment>
                    ))}
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogViewClinicalDataCase.propTypes = {
  case: PropTypes.array,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogViewClinicalDataCase.defaultProps = {
  handleClose: () => '',
  open: false,
  case: [],
};

export default DialogViewClinicalDataCase;
