import { shader_3D_lines } from '../../utils/shader';
import { m4, webglViewer } from '../../utils';

class LineWebGL {
  constructor(gl) {
    this.gl = gl;
    // setup GLSL program
    this.program = webglViewer.createProgramFromString(
      this.gl,
      shader_3D_lines
    );
    // look up where the vertex data needs to go.
    this.positionLine = this.gl.getAttribLocation(this.program, 'a_position');
    this.matrixLine = this.gl.getUniformLocation(this.program, 'u_matrix');
    this.colorLine = this.gl.getAttribLocation(this.program, 'a_color');
    this.bufferLine = null;
    this.bufferColorLine = null;
    this.size = 8;
    this.vertices = null;
    this.color = null;
  }
  setVertice = (index, point3d) => {
    if (!this.vertices || index >= this.vertices.length / 3) return;
    this.vertices[index * 3] = point3d.x;
    this.vertices[index * 3 + 1] = point3d.y;
    this.vertices[index * 3 + 2] = point3d.z;
    this.setVertices(this.vertices);
  };
  setVertices = (vertices = null, color = null, size = null) => {
    if (!vertices) return;
    this.vertices = vertices;
    // if (this.bufferLine) {
    //   this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.bufferLine);
    //   this.gl.bufferSubData(this.gl.ARRAY_BUFFER, 0, vertices);
    // } else
    {
      if (size) this.size = size;
      // Create a buffer to put positions in
      this.bufferLine = this.gl.createBuffer();
      // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = positionBuffer)
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.bufferLine);
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        new Float32Array(vertices),
        this.gl.STATIC_DRAW
      );
      if (!color) {
        if (this.color) color = this.color;
        else {
          color = [255, 0, 0];
          this.color = color;
        }
      } else {
        this.color = color;
      }
      const colors = [];
      for (let i = 0; i < vertices.length / 3; i++) {
        for (let j = 0; j < 3; j++) {
          colors.push(color[j]);
        }
      }
      // console.log({ colors });
      // Create a buffer to put colors in
      this.bufferColorLine = this.gl.createBuffer();
      // Bind it to ARRAY_BUFFER (think of it as ARRAY_BUFFER = colorBuffer)
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.bufferColorLine);
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        new Uint8Array(colors),
        this.gl.STATIC_DRAW
      );
    }
    // this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  };
  draw(viewProjectionMatrix) {
    if (!this.bufferLine) return;
    const gl = this.gl;
    const program = this.program;
    const positionLocation = this.positionLine;
    const positionBuffer = this.bufferLine;
    const matrixLocation = this.matrixLine;

    const bufferColorLine = this.bufferColorLine;
    const colorLine = this.colorLine;
    // starting with the view projection matrix
    // compute a matrix for the F
    var matrix = m4.translate(viewProjectionMatrix, 0, 0, 0);
    // Tell it to use our program (pair of shaders)
    gl.useProgram(program);

    // Bind the position buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    // Turn on the position attribute
    gl.enableVertexAttribArray(positionLocation);
    // Tell the position attribute how to get data out of positionBuffer (ARRAY_BUFFER)
    var size = 3; // 3 components per iteration
    var type = gl.FLOAT; // the data is 32bit floats
    var normalize = false; // don't normalize the data
    var stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    var offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(
      positionLocation,
      size,
      type,
      normalize,
      stride,
      offset
    );

    // Turn on the color attribute
    gl.enableVertexAttribArray(colorLine);

    // Bind the color buffer.
    gl.bindBuffer(gl.ARRAY_BUFFER, bufferColorLine);

    // Tell the attribute how to get data out of colorBuffer (ARRAY_BUFFER)
    size = 3; // 3 components per iteration
    type = gl.UNSIGNED_BYTE; // the data is 8bit unsigned values
    normalize = true; // normalize the data (convert from 0-255 to 0-1)
    stride = 0; // 0 = move forward size * sizeof(type) each iteration to get the next position
    offset = 0; // start at the beginning of the buffer
    gl.vertexAttribPointer(colorLine, size, type, normalize, stride, offset);

    // Set the matrix.
    gl.uniformMatrix4fv(matrixLocation, false, matrix);

    gl.lineWidth(1);
    gl.drawArrays(gl.LINES, 0, this.size);
    // gl.lineWidth(0.5);
  }
}

export default LineWebGL;
