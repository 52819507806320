export const useStyles = theme => ({
  root: {
    marginTop: 0,
    paddingTop: 0,
    maxHeight: 500,
  },
  actions: {
    margin: theme.spacing(2),
  },
  reload: {
    margin: theme.spacing(0),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
  card_content: { padding: theme.spacing(2, 0.5, 0, 0.5) },
});
