import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DialogUnitName = ({ open, handleClose, name, handleReName }) => {
  const [value, setValue] = React.useState(name);
  const handleChange = event => {
    setValue(event.target.value);
  };
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      open={open}>
      <DialogTitle id="form-dialog-title">Unit Name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please type the new name of the unit.
        </DialogContentText>
        <TextField
          autoFocus
          defaultValue={name}
          fullWidth
          id="name"
          label="Unit name"
          margin="dense"
          onChange={handleChange}
          value={value}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleReName(value)}>
          Re-Name
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DialogUnitName.defaultProps = {
  name: '',
};
export default DialogUnitName;
