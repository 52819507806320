/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  MobileStepper,
  Paper,
  Typography,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Fab,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';
import { createSurvey } from 'helpers/Surveys';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import { MutationCreateSurvey } from 'graphql/Surveys';
import { GetData } from 'helpers';

const ColorButton = styled(Button)(() => ({
  color: 'white',
  textTransform: 'none',
  backgroundColor: '#14B495',
  '&:hover': {
    backgroundColor: '#14A695',
  },
}));

class SurveyStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false,
    };
  }
  handleSave = () => {
    const { create } = this.state;
    const { buttonSave } = this.props;
    console.log('SurveyStepper - handleSave', { buttonSave, create });
    if (buttonSave) {
      buttonSave.action();
      if (!create) this.props.handleSaved();
    } else if (create) {
      /** empty */
    }
  };
  handleSaveCreate = (sender, complete) => {
    console.log({ sender, complete });
    const { saving } = this.state;
    const { currentPatient } = this.props;
    if (saving) return;
    this.setState({ saving: true });
    (async () => {
      MutationCreateSurvey(sender, complete)
        .then(res => {
          const data = GetData(res);
          const { ok, survey, errors } = data.createSurvey;
          if (ok) {
            const reply = createSurvey(
              survey,
              survey.id,
              currentPatient.id,
              this.props.handleSave
            );
            this.props.handleCreatedSurvey({
              updatedAt: survey.updatedAt,
              survey_json: reply.survey_json,
              currentSurvey: reply.survey,
              buttonSave: reply.buttonSave,
              buttonCancel: reply.buttonCancel,
              value_changed: [],
            });
            this.props.handleAddNewSurvey(survey);
            this.setState({ saving: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ saving: false });
        });
    })();
  };
  handleComplete = () => {
    console.log('handleComplete');
    const { currentSurvey } = this.props;
    if (currentSurvey) currentSurvey.completeLastPage();
  };
  handleCreate = () => {
    console.log('handleCreate');
    const { currentPatient, surveyType } = this.props;
    const reply = createSurvey(
      { surveyType, survey: null },
      undefined,
      currentPatient.id,
      this.handleSaveCreate,
      this.handleBackToOverview
    );
    this.setState({ create: true });
    this.props.handleCreatedSurvey({
      updatedAt: moment().valueOf(),
      survey_json: reply.survey_json,
      currentSurvey: reply.survey,
      buttonSave: reply.buttonSave,
      buttonCancel: reply.buttonCancel,
      value_changed: [{ name: '' }],
    });
  };
  render() {
    const {
      children,
      handleNext,
      handleBack,
      pages,
      theme,
      isLastPage,
      isChanged,
      currentSurvey,
      mode,
    } = this.props;
    let { activeStep, maxSteps, loading } = this.props;
    // console.log({ activeStep, maxSteps });
    let complete = false;
    if (currentSurvey) complete = currentSurvey.complete;
    let words = '';
    if (pages.length) {
      words = pages[activeStep].title.split('_');
    } else {
      activeStep = 0;
      maxSteps = 1;
    }
    let title = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word && word !== '') {
        const uppder = word.charAt(0).toUpperCase() + word.slice(1);
        title += uppder + ' ';
      }
    }
    if (loading !== undefined && !loading && !currentSurvey) {
      loading = undefined;
    }
    let disable_button = false;
    if (loading !== undefined) {
      disable_button = loading;
    }
    let small_buttons = false;
    if (window.innerWidth < 620) {
      small_buttons = true;
    }
    let small_complete = null;
    if (window.innerWidth < 544) {
      small_complete = true;
    }

    let ButtonSave = (
      <Grid item>
        {small_buttons ? (
          <Fab
            aria-label="save"
            disabled={!isChanged || disable_button}
            onClick={this.handleSave}
            size="small"
            sx={{ backgroundColor: '#14B495', color: 'white' }}>
            <SaveIcon />
          </Fab>
        ) : (
          <ColorButton
            disabled={!isChanged || disable_button}
            onClick={this.handleSave}
            variant="contained">
            Save
          </ColorButton>
        )}
      </Grid>
    );
    let ButtonComplete = null;
    if (isLastPage) {
      ButtonComplete = (
        <Grid item>
          {small_complete ? (
            <Fab
              aria-label="complete"
              disabled={disable_button}
              onClick={this.handleComplete}
              size="small"
              sx={{ backgroundColor: '#14B495', color: 'white' }}>
              <DoneIcon />
            </Fab>
          ) : (
            <ColorButton
              disabled={disable_button || complete}
              onClick={this.handleComplete}
              variant="contained">
              Complete
            </ColorButton>
          )}
        </Grid>
      );
    }
    let value = Math.floor(((activeStep + 1) * 100) / maxSteps);
    let FirstComponent = null;
    if (currentSurvey) {
      FirstComponent = (
        <Typography noWrap>{`${title} (${activeStep +
          1}/${maxSteps})`}</Typography>
      );
    } else if (loading === undefined && mode === 'edit') {
      ButtonSave = null;
      FirstComponent = (
        <Grid item>
          <ColorButton
            disabled={disable_button}
            onClick={this.handleCreate}
            variant="contained">
            Create Survey
          </ColorButton>
        </Grid>
      );
    } else if (loading) {
      FirstComponent = <Typography>Loading...</Typography>;
    } else if (mode === 'patient') {
      FirstComponent = <Typography>The link is invalid or expired</Typography>;
      activeStep = 0;
      maxSteps = 1;
      ButtonSave = null;
    }
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Paper
          elevation={0}
          square
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 50,
            pl: 2,
            bgcolor: 'background.default',
          }}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between">
            <Grid item sx={{ padding: 1 }} xs={small_complete ? 4 : 8}>
              {FirstComponent}
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-end"
              spacing={1}
              sx={{ paddingRight: 2, minWidth: 115 }}
              xs={4}>
              {ButtonSave}
              {ButtonComplete}
            </Grid>
          </Grid>
        </Paper>
        <Divider />
        <LinearProgress
          sx={{
            backgroundColor: '#ddd',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#14B495',
            },
          }}
          value={value}
          variant="determinate"
        />
        {children}
        <Divider />
        <MobileStepper
          activeStep={activeStep}
          backButton={
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              size="small">
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
          nextButton={
            <Button
              disabled={activeStep === maxSteps - 1}
              onClick={handleNext}
              size="small">
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          position="static"
          steps={maxSteps}
        />
      </Box>
    );
  }
}
SurveyStepper.propTypes = {
  isChanged: PropTypes.bool,
};
SurveyStepper.defaultProps = {
  isChanged: false,
  handleSaved: () => '',
  loading: undefined,
  mode: 'edit',
};
export default props => {
  const theme = useTheme();
  return <SurveyStepper theme={theme} {...props} />;
};
