import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, IconButton, Checkbox } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/ChevronRight';
import { v1 as uuid } from 'uuid';
import { CircularLoading } from 'components';
import TableGridRowCell from './TableGridRowCell';
class TableGridFirstRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cells_active: null,
    };
  }
  componentDidUpdate() {
    const { isRowClicked } = this.props;
    const { cells_active } = this.state;
    if (!isRowClicked && cells_active) {
      this.setState({ cells_active: null });
    }
  }
  handleCellDoubleClick = cell => {
    this.setState({ cells_active: cell });
  };
  handleSingleClick = cell => {
    let { cells_active } = this.state;
    if (!cells_active || cells_active === cell) return;
    this.setState({ cells_active: null });
  };
  handlelKeyEnter = () => {
    this.setState({ cells_active: null });
  };
  handleTextChanged = (cell, value) => {
    const { headers } = this.props;
    let { row, row_original } = this.props;
    let internal_row = {
      ...row,
    };
    internal_row[cell] = value;
    let edited = 0;
    for (let i = 0; i < headers.length; i++) {
      let E = this.props.getCellValue(headers[i], row);
      if (row_original) {
        E = this.props.getCellValue(headers[i], row_original);
      }
      let I = this.props.getCellValue(headers[i], internal_row);
      if (E !== I) {
        edited++;
      }
    }
    this.props.onRowEdited(edited > 0 ? true : false, internal_row);
  };
  render() {
    const {
      open,
      onClickExpandRow,
      row,
      headers,
      checkboxSelection,
      onCheckBoxItemClicked,
      isItemChecked,
      isRowSelected,
      loading,
    } = this.props;
    let TableCellCheckbox = null;
    const { cells_active } = this.state;
    if (checkboxSelection) {
      TableCellCheckbox = (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemChecked}
            color="default"
            inputProps={{ 'aria-labelledby': uuid() }}
            onClick={() => onCheckBoxItemClicked(row)}
          />
        </TableCell>
      );
    }
    return (
      <TableRow
        hover
        onClick={() => this.props.handleRowClicked(row)}
        selected={isRowSelected}>
        <TableCell style={{ paddingLeft: 5, paddingRight: 5 }}>
          {loading ? (
            <CircularLoading simple small />
          ) : (
            <IconButton
              aria-label="expand row"
              onClick={() => onClickExpandRow(!open)}
              size="small">
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          )}
        </TableCell>
        {TableCellCheckbox}
        {headers.map((header, index) => {
          const { field } = header;
          const value = this.props.getCellValue(header, row);
          // console.log({ value });
          return (
            <TableGridRowCell
              header={header}
              isActive={cells_active === field ? true : false}
              key={`table-grid-row-cell-${index}`}
              onCellClick={this.handleSingleClick}
              onCellDoubleClick={this.handleCellDoubleClick}
              onCellKeyEnter={this.handlelKeyEnter}
              onTextChanged={this.handleTextChanged}
              row={row}
              value={value}
            />
          );
        })}
      </TableRow>
    );
  }
}

TableGridFirstRow.propTypes = {
  getCellValue: PropTypes.func,
  headers: PropTypes.array,
};
TableGridFirstRow.defaultProps = {
  getCellValue: () => '',
  headers: [],
  cells: [],
  checkboxSelection: false,
  onCheckBoxItemClicked: () => '',
  onCellDoubleClick: () => '',
  isItemChecked: false,
  isRowSelected: false,
  isRowClicked: false,
  handleRowClicked: () => '',
  onRowEdited: () => '',
};

export default TableGridFirstRow;
