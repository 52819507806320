import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { FormatBytes, FormatTime } from 'helpers';

class TabDockerImageLayers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { image, height } = this.props;
    if (!image || image === undefined) return null;
    const { Layers } = image;
    let layers = [];
    if (Layers) {
      // const { Layers } = RootFS;
      // if (Layers) layers = Layers;
      layers = Layers;
    }
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
        overflowY: 'auto',
      };
    }
    return (
      <TableContainer component={Paper} style={style}>
        <Table aria-label="caption table" size="small">
          <caption>A basic about layers of the selected image</caption>
          <TableBody>
            {layers.map((layer, index) => {
              let id = layer.Id;
              let CreatedBy = layer.CreatedBy;
              if (id.length > 10) id = id.substring(0, 10) + '...';
              if (CreatedBy.length > 70)
                CreatedBy = CreatedBy.substring(0, 70) + '...';
              return (
                <TableRow key={`layer-row-${index}`}>
                  <TableCell>{index}</TableCell>
                  <TableCell>{id}</TableCell>
                  <TableCell>{CreatedBy}</TableCell>
                  <TableCell>{FormatBytes(layer.Size)}</TableCell>
                  <TableCell>{FormatTime(layer.Created, 'calendar')}</TableCell>
                  <TableCell>{layer.Comment}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabDockerImageLayers.propTypes = {
  classes: PropTypes.object,
};

export default TabDockerImageLayers;
