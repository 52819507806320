import React, { Component } from 'react';
import { QueryAnatomicalRegions } from 'graphql/Series';
import { GetData, ServerErrorsString } from 'helpers';

class QuerySeriesRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QueryAnatomicalRegions(null, 0, 10000)
        .then(res => {
          const data = GetData(res);
          const rows = data.AnatomicalRegions;
          if (rows) {
            this.props.setState({ loading: false });
            this.setState({
              rows,
              loading: false,
            });
          } else {
            throw Error('Invalid Anatomical Regions');
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { rows, loading } = this.state;
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        rows,
        loading,
      });
    });
  }
}
QuerySeriesRegion.defaultProps = {
  holder: {
    reload: null,
    setLoading: () => '',
  },
};
export default QuerySeriesRegion;
