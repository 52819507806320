import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL, HTTP_ADDRESS_WEB } from 'config';
import { GetData } from 'helpers';

const MUTATION_SEND_EMAIL = gql`
  mutation($subject: String!, $email: String!, $message: String!) {
    sendEmail(subject: $subject, email: $email, message: $message) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationSendEmail = (subject, email, message) => {
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SEND_EMAIL),
    variables: {
      subject,
      email,
      message,
    },
  });
};
export const SendEmail = (props, state, setState) => {
  const { user, folder_content } = props;
  const { emailing } = state;
  if (emailing) return;
  setState({ emailing: true });
  (async () => {
    const url = `${HTTP_ADDRESS_WEB}/dashboard/view/${folder_content.idFolderContent}`;
    const subject = `Request to download high-resolution image from ${user.FullName}`;
    // const email = 'waldovalenzuela@yahoo.com';
    const email = 'waldovalenzuela@yahoo.com, webneuro@insel.ch';
    const message = `
    <p>Dear Administrator,</p>
    <p></p>
    <p>The user (${user.FullName}) is requesting to download a high-resultion file.</p>
    <p style="margin-left: 80px;"><a href="${url}" rel="noopener noreferrer" target="_blank">Link to Image selected by the user</a></p>
    <p></p>
    <p>Best regards,</p>
    <p></p>
    <p>Medical-Blocks User</p>
    <p>${user.FullName}</p>
    <p></p>
    <p></p>
    </div>`;
    MutationSendEmail(subject, email, message)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.sendEmail;
        if (ok) {
          setState({ emailing: false, open_message: true });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        console.log({ error });
        setState({ emailing: false });
      });
  })();
};
