/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FolderOpen } from 'icons';

import { QuerySubFolders } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';
import { setExplorerSubFolders, setExplorerFolders } from 'redux-store/actions';
import {
  IsProjectFolder,
  IsUploadFolder,
} from 'views/Explorer/components/ExplorerViews/components/ExplorerViewGallery/components/ExplorerViewGalleryItem/Style';
const useStyles = theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    color: 'blue',
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  icon_folder_shared: {
    color: 'green',
  },
  icon_folder_project: {
    color: '#90caf9',
  },
  icon_folder_upload: {
    color: '#ffcc80',
  },
});

class ExplorerFolderTreeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LocalSubFolders: [],
      loading: false,
      empty: false,
    };
  }

  loadSubFolders = () => {
    // console.log({ sub_folder: this.props.sub_folder });
    const { loading, empty } = this.state;
    const {
      sub_folder: { total_sub_folders, idFolder, folders_paths },
    } = this.props;
    this.props.setExplorerFolders(folders_paths);
    if (empty || !total_sub_folders) {
      if (loading) {
        this.setState({ loading: false });
      }
      return;
    }
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySubFolders(idFolder)
        .then(res => {
          const data = GetData(res);
          const { subfolders } = data;
          const total = subfolders.length;
          if (!total && this.props.sub_folder.total_sub_folders) {
            this.props.sub_folder.total_sub_folders = total;
            throw Error('Error occurred loading subfolders');
          }
          this.props.setExplorerSubFolders(subfolders);
          this.setState({
            loading: false,
            LocalSubFolders: subfolders,
            empty: total ? false : true,
          });
        })
        .catch(error => {
          console.log({ error });
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false, LocalSubFolders: [] });
        });
    })();
  };
  getChildren = () => {
    const { sub_folder, ...rest } = this.props;
    const { total_sub_folders, SubFolders, idFolder: idParent } = sub_folder;
    let { LocalSubFolders, loading } = this.state;
    if (typeof SubFolders !== 'undefined' && SubFolders.length) {
      LocalSubFolders = SubFolders;
    }
    if (!loading && LocalSubFolders.length) {
      let indices = [];
      return LocalSubFolders.map(local_sub_folder => {
        //
        const { idFolder } = local_sub_folder;
        if (indices.indexOf(idFolder) !== -1) {
          return null;
        }
        indices.push(idFolder);
        return (
          <ExplorerFolderTreeItem
            {...rest}
            color="#1a73e8"
            itemId={idFolder.toString()}
            key={`folder-${idParent}-sub-item-${idFolder}`}
            nodeId={idFolder.toString()}
            sub_folder={local_sub_folder}
          />
        );
      });
    } else if (total_sub_folders) {
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="center"
          xs={12}>
          <CircularProgress size={25} />
        </Grid>
      );
    } else {
      return null;
    }
  };
  handleLabelClick = () => {
    const {
      disable_click,
      base,
      sub_folder: { idFolder, folders_paths },
    } = this.props;
    if (!disable_click) {
      if (base === 'Home') {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      } else {
        this.props.history.push(`/explorer/shared/folder/${idFolder}`);
      }
    }
    this.props.handleItemClick(this.props.sub_folder);
    const { LocalSubFolders } = this.state;
    if (LocalSubFolders.length) {
      console.log({ folders_paths });
      this.props.setExplorerFolders(folders_paths);
      return;
    }
    this.loadSubFolders();
  };

  render() {
    const {
      classes,
      color,
      bgColor,
      sub_folder,
      labelText,
      setExplorerSubFolders,
      setExplorerFolders,
      handleItemClick,
      disable_click,
      handleAddError,
      idHomeFolder,
      user,
      staticContext,
      nodeIds,
      selectedId,
      ...res
    } = this.props;
    let { labelIcon: LabelIcon, ...other } = res;
    const { name, total_files, total_sub_folders, idUser } = sub_folder;
    // console.log({ other });
    let total = 0;
    if (total_sub_folders) total += total_sub_folders;
    if (total_files) total += total_files;
    let folder_ownership = true;
    if (user && user.id !== idUser) folder_ownership = false;
    let is_project_folder = IsProjectFolder(sub_folder, idHomeFolder);
    let is_upload_folder = IsUploadFolder(sub_folder, idHomeFolder);
    const isExpanded = nodeIds.indexOf(this.props.nodeId) !== -1;
    let color_icon = 'primary';
    if (isExpanded) {
      LabelIcon = FolderOpen;
      if (!folder_ownership) color_icon = 'green';
      else if (is_project_folder) color_icon = '#90caf9';
      else if (is_upload_folder) color_icon = '#ffcc80';
      else color_icon = 'blue';
    }
    return (
      <TreeItem
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        label={
          <div className={classes.labelRoot}>
            <LabelIcon
              className={clsx({
                [classes.labelIcon]: classes.labelIcon,
                [classes.icon_folder_shared]: !folder_ownership,
                [classes.icon_folder_project]: is_project_folder,
                [classes.icon_folder_upload]: is_upload_folder,
              })}
              color={color_icon}
            />
            <Typography className={classes.labelText} variant="body2">
              {name}
            </Typography>
            <Typography color="inherit" variant="caption">
              {total}
            </Typography>
          </div>
        }
        onClick={this.handleLabelClick}
        style={{
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        {...other}>
        {this.getChildren()}
      </TreeItem>
    );
  }
}

ExplorerFolderTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

ExplorerFolderTreeItem.defaultProps = {
  labelIcon: FolderIcon,
  sub_folder: { name: '', total_files: 0, total_sub_folders: 0 },
  labelText: '',
  disable_click: false,
  handleItemClick: () => '',
};
const mapStateToProps = state => {
  return {
    view_type: state.page_explorer.view_type,
  };
};
export default connect(mapStateToProps, {
  setExplorerSubFolders,
  setExplorerFolders,
})(withStyles(withRouter(ExplorerFolderTreeItem), useStyles));
