export const columns = [
  {
    field: 'table_map_name',
    headerName: 'Join Table',
    align: 'left',
    width: 150,
  },
  {
    field: 'table_name_input',
    headerName: 'Name Input Table',
    align: 'left',
    width: 150,
  },
  {
    field: 'id_table_input',
    headerName: 'ID Input Table',
    align: 'left',
    width: 110,
  },
  {
    field: 'table_name_output',
    headerName: 'Name Output Table',
    align: 'left',
    width: 150,
  },
  {
    field: 'id_table_output',
    headerName: 'ID Output Table',
    align: 'left',
    width: 110,
  },
];
