import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';

import { CellSortMenu } from 'components/Tables/TableGrid/TableGridHeaderCell/components';

const useStyles = () => ({
  root_left: {
    borderLeft: '3px outset #cecccc',
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root_right: {
    borderRight: '3px outset #e0e0e029',
    paddingRight: 5,
  },
});
class TableGridHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
    };
  }
  getObject = () => {
    const { header } = this.props;
    if (header === undefined) return '';
    const { headerName } = header;
    return headerName;
  };
  render() {
    const {
      classes,
      header,
      last,
      onSortHeaderClicked,
      orderBy,
      order,
    } = this.props;
    let style = {
      backgroundColor: 'transparent',
      paddingLeft: 2,
      paddingRight: 2,
    };
    // if (header.width) {
    //   style = {
    //     minWidth: header.width,
    //     backgroundColor: 'transparent',
    //     paddingRight: last ? 10 : 0,
    //     paddingLeft: 6,
    //   };
    // }
    if (header.style) {
      style = {
        ...style,
        ...header.style,
      };
    }
    let text_cell = this.getObject();
    return (
      <TableCell style={style}>
        <div
          className={clsx({
            [classes.root_left]: true,
            [classes.root_right]: last,
          })}
          onClick={this.handleOpenChangeAvatar}
          onFocus={() => {}} // Ensure the element can receive focus
          onMouseEnter={() => this.setState({ isMouseOver: true })}
          onMouseLeave={() => this.setState({ isMouseOver: false })}
          role="button"
          tabIndex={0} // Ensure the element is focusable
        >
          <TableSortLabel
            active={orderBy === header.field}
            direction={order}
            onClick={() => onSortHeaderClicked(header.field)}
            style={{ width: 'calc(100% - 40px)' }}>
            {text_cell}
          </TableSortLabel>
          <CellSortMenu
            header={header}
            headerName={header.headerName}
            isMouseOver={this.state.isMouseOver}
            onClickHideColumn={this.props.onClickHideColumn}
            onClickShowColumn={this.props.onClickShowColumn}
            onSearchFilterChanged={this.props.onSearchFilterChanged}
            onSortHeader={this.props.onSortHeader}
            order={order}
            orderBy={orderBy}
          />
        </div>
      </TableCell>
    );
  }
}

TableGridHeaderCell.propTypes = {
  classes: PropTypes.object,
};
TableGridHeaderCell.defaultProps = {
  // header: '',
  onSortHeaderClicked: () => '',
  onSortHeader: () => '',
  onClickHideColumn: () => '',
  onClickShowColumn: () => '',
  onSearchFilterChanged: () => '',
  order: '',
  orderBy: '',
  last: false,
};
export default withStyles(TableGridHeaderCell, useStyles);
