import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Button,
  ButtonGroup,
  Tooltip,
  IconButton,
  Badge,
} from '@mui/material';
import {
  ButtonDownloadType,
  ButtonReloadDelete,
  SearchInput,
  IconButtonLoad,
} from 'components';
import EditIcon from '@mui/icons-material/Edit';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { connect } from 'react-redux';
import {
  clean_manager_error,
  copy,
  cleanCopySeries,
  cleanCopyCut,
  copySeries,
} from 'redux-store/actions/file_folder_manager';
import { QueryFolderContents } from 'graphql/Folders';
import { GetData, ServerErrorsString } from 'helpers';

class TabUnMappedHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copying: false,
    };
  }
  handleQueryContent = () => {
    const { copying } = this.state;
    const { selected } = this.props;
    if (copying) return;
    const inputs = selected
      .map(x => {
        const { idSeries, idFileObject } = x;
        if (idSeries) return { idSeries };
        else if (idFileObject) return { idFileObject };
        else return null;
      })
      .filter(x => x);
    this.setState({ copying: true });
    QueryFolderContents(inputs)
      .then(res => {
        const data = GetData(res);
        const { ok, errors, folder_contents } = data.folderContents;
        if (ok) {
          this.handleCopy(folder_contents);
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ copying: false });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  handleCopy = folder_contents => {
    const { selected } = this.props;
    const { copy_series } = this.props;
    const ids = copy_series.map(x => x.idSeries);
    const series = [];
    for (let i = 0; i < selected.length; i++) {
      const { idSeries } = selected[i];
      if (ids.indexOf(idSeries) === -1) {
        copy_series.push({ ...selected[i] });
        series.push(idSeries);
      }
    }
    const total = series.length;
    if (total) {
      this.props.copy(folder_contents);
      this.props.copySeries(copy_series);
      this.props.handleAddSuccess(`Copy ready for explorer (${total})`);
    } else {
      this.props.handleAddError(
        'To Copy you need to select Series first or there are already copied.'
      );
    }
    this.setState({ copying: false });
  };
  handleCleanCopyCut = () => {
    this.props.cleanCopyCut();
    this.props.cleanCopySeries();
  };
  render() {
    const { selected, copy_series } = this.props;
    let disabled = selected.length ? false : true;
    const { copying } = this.state;
    const defaultProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      color: 'error',
      children: <DeleteOutlineIcon />,
    };
    let ComponentClean = null;
    if (copy_series.length) {
      ComponentClean = (
        <Grid item>
          <Tooltip title="Clean Copy objects">
            <IconButton
              aria-controls="delete-folders"
              // className={classes.root}
              onClick={this.handleCleanCopyCut}
              size="large">
              <Badge badgeContent={copy_series.length} {...defaultProps} />
            </IconButton>
          </Tooltip>
        </Grid>
      );
    }
    return (
      <Paper>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 50 }}>
          <Grid item sx={{ paddingLeft: 1 }} xs={2}>
            <ButtonGroup
              // className={classes.root}
              exclusive="false"
              size="small"
              value="center">
              <Button disabled={disabled} onClick={this.props.handleOpenEdit}>
                <EditIcon style={{ color: disabled ? 'gray' : 'red' }} />
              </Button>
              <ButtonDownloadType key="center" type="unsorted_sequences" />
            </ButtonGroup>
          </Grid>

          <Grid item sx={{ paddingLeft: 5 }}>
            <SearchInput
              onChange={this.props.handleChangeName}
              onKeyDown={event => {
                if (event && event.key === 'Enter') {
                  this.props.handleChangeName({
                    target: { value: this.props.name },
                  });
                }
              }}
              placeholder="Search series"
              value={this.props.name}
            />
          </Grid>
          <Grid container item xs={4}>
            <Grid item>
              <IconButtonLoad
                disabled={disabled}
                handleClick={this.handleQueryContent}
                icon={
                  <CopyAllIcon
                    style={{ color: disabled || copying ? 'gray' : 'green' }}
                  />
                }
                length={this.props.copy_objects.length}
                loading={this.state.copying}
                tooltip="Copy Files"
              />
            </Grid>
            {ComponentClean}
          </Grid>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={this.props.handleReloadTable}
            loading={this.props.loading}
            loading_table={this.props.loading_table}
            numSelected={selected.length}
            tooltip="Reload USer"
          />
        </Grid>
      </Paper>
    );
  }
}

TabUnMappedHeader.propTypes = {
  selected: PropTypes.array,
};
TabUnMappedHeader.defaultProps = {
  selected: [],
};

const mapStateToProps = state => {
  const { manager } = state;
  const objects = manager.cut.concat(manager.copy).concat(manager.copy_series);
  return {
    copy_objects: manager.copy,
    copy_series: manager.copy_series,
    loading_paste: manager.loading_paste,
    error_manager: manager.error_manager,
    warning_manager: manager.warning_manager,
    size: objects.length,
  };
};
export default connect(mapStateToProps, {
  copy,
  copySeries,
  clean_manager_error,
  cleanCopyCut,
  cleanCopySeries,
})(TabUnMappedHeader);
