/* eslint-disable react/react-in-jsx-scope */
import SvgIcon from '@mui/material/SvgIcon';

const SortDescByIcon = () => {
  return (
    <SvgIcon viewBox={'0 0 512 512'}>
      <path
        d="M0,5.6v44.5h267.1V5.6H0z M378.4,5.6v416l-57.7-57.7l-31.3,31.3l95.3,96l16,15.3l16-15.3l95.3-96l-31.3-31.3L423,421.6V5.6
		H378.4z M0,94.6v44.5h222.6V94.6H0z M0,183.7v44.5h178.1v-44.5H0z M0,272.7v44.5h133.6v-44.5H0z M0,361.7v44.5h89v-44.5H0z
		 M0,450.8v44.5h44.5v-44.5H0z"
      />
    </SvgIcon>
  );
};

export default SortDescByIcon;
