import { IsInvalid } from 'helpers';
import {
  LOGOUT_USER_PAGE_EXPLORER,
  PAGE_EXPLORER_BOTTOM_BAR_FOLDERS,
  PAGE_EXPLORER_OPEN,
  PAGE_EXPLORER_SET_HOME,
  PAGE_EXPLORER_SET_HOME_SUB_FOLDER,
  PAGE_EXPLORER_SET_NODE_ID,
  PAGE_EXPLORER_STATUS_SET,
  PAGE_EXPLORER_STATUS_PAGE,
  PAGE_EXPLORER_STATUS_LIMIT,
  PAGE_EXPLORER_UPLOAD_PROGRESS,
  PAGE_EXPLORER_UPLOAD_PROGRESS_FILES,
  PAGE_EXPLORER_UPLOAD_RESET,
  PAGE_EXPLORER_UPLOAD_TOTAL_FILES,
  PAGE_EXPLORER_VIEWTYPE,
  PAGE_EXPLORER_CHANGED,
  PAGE_EXPLORER_FOLDER_BAR_CLICKED,
} from '../actions/types';

const initialState = {
  folders: [],
  folder_bar_clicked: null,
  LocalHomeFolder: null,
  home_change: false,
  progress: 0,
  progress_files: 0,
  total_files: 0,
  view_type: 0, // 0 -> thumbnails, 1 -> tree
  open_explorer: true,
  nodeId: '', // tree view node selected
  status: {
    folders: 0,
    files: 0,
    page: 0,
    rowsPerPage: 500,
  },
};

export default function reducer_page_explorer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case LOGOUT_USER_PAGE_EXPLORER:
      return initialState;
    case PAGE_EXPLORER_STATUS_PAGE:
      return {
        ...state,
        status: {
          ...state.status,
          page: action.page,
        },
      };
    case PAGE_EXPLORER_STATUS_LIMIT:
      return {
        ...state,
        status: {
          ...state.status,
          page: 0,
          rowsPerPage: action.rowsPerPage,
        },
      };

    case PAGE_EXPLORER_FOLDER_BAR_CLICKED:
      return {
        ...state,
        folder_bar_clicked: action.folder_bar_clicked,
      };
    case PAGE_EXPLORER_BOTTOM_BAR_FOLDERS:
      return {
        ...state,
        folders: action.folders,
      };
    case PAGE_EXPLORER_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case PAGE_EXPLORER_UPLOAD_PROGRESS_FILES:
      return {
        ...state,
        progress_files: action.progress_files,
      };
    case PAGE_EXPLORER_UPLOAD_TOTAL_FILES:
      return {
        ...state,
        total_files: action.total_files,
      };
    case PAGE_EXPLORER_VIEWTYPE:
      return {
        ...state,
        view_type: action.view_type,
      };
    case PAGE_EXPLORER_OPEN:
      return {
        ...state,
        open_explorer: action.open_explorer,
      };
    case PAGE_EXPLORER_SET_HOME: {
      const { home_change } = state;
      const { home } = action;
      if (IsInvalid(home.SubFolders)) {
        action.home.SubFolders = [];
      }
      // for (let i = 0; i < home.SubFolders.length; i++) {
      //   const total = home.SubFolders[i];
      // }
      return {
        ...state,
        LocalHomeFolder: {
          ...home,
        },
        home_change: !home_change,
      };
    }
    case PAGE_EXPLORER_CHANGED: {
      return {
        ...state,
        home_change: action.home_change,
      };
    }
    case PAGE_EXPLORER_SET_HOME_SUB_FOLDER: {
      let { LocalHomeFolder } = state;
      const { sub_folders } = action;
      if (!sub_folders.length || !LocalHomeFolder) {
        return state;
      }
      LocalHomeFolder = SetSubFolder(LocalHomeFolder, sub_folders);
      return {
        ...state,
        LocalHomeFolder,
      };
    }
    case PAGE_EXPLORER_SET_NODE_ID: {
      return {
        ...state,
        nodeId: action.nodeId,
      };
    }
    case PAGE_EXPLORER_UPLOAD_RESET:
      return {
        ...state,
        progress: 0,
        progress_files: 0,
        total_files: 0,
      };
    case PAGE_EXPLORER_STATUS_SET:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.status,
        },
      };
    default:
      return state;
  }
}
const SetSubFolder = (Folder, sub_folders) => {
  const { idParent } = sub_folders[0];
  const { idFolder, SubFolders } = Folder;
  //
  if (idFolder === idParent) {
    //
    //   `Found folder: ${name}, idFolder: ${idFolder} -> idParent: ${idParent}`
    // );
    if (typeof SubFolders === 'undefined' || !SubFolders.length) {
      Folder.SubFolders = sub_folders;
      return Folder;
    }
    for (let i = 0; i < sub_folders.length; i++) {
      const idSubFolder = sub_folders[i].idFolder;
      const index = SubFolders.map(x => x.idFolder).indexOf(idSubFolder);
      if (index === -1) {
        SubFolders.push({ ...sub_folders[i] });
      } else {
        SubFolders[index] = { ...sub_folders[i] };
      }
    }
    let indices = [];
    Folder.SubFolders = SubFolders.map(x => {
      if (indices.indexOf(x.idFolder) === -1) {
        indices.push(x.idFolder);
        return x;
      } else {
        return null;
      }
    }).filter(x => x);
    return Folder;
  }
  if (typeof SubFolders === 'undefined' || !SubFolders.length) {
    return Folder;
  }
  for (let i = 0; i < SubFolders.length; i++) {
    SubFolders[i] = SetSubFolder(SubFolders[i], sub_folders);
  }
  Folder.SubFolders = SubFolders;
  return Folder;
};
