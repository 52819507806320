import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getParams } from 'helpers';
import { ChannelRight, DirectChatRight } from './components';
import { QueryChatMessages } from 'graphql/Communications';

class CommunicationMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      unsubcribeChat: null,
    };
  }

  componentDidUpdate() {
    const params = getParams(this.props);
    if (!params) return;
    const { type } = params;
    const { selected } = this.props;
    if ((typeof type === 'undefined' || !type) && selected) {
      this.props.history.push('/communications');
    }
    if (!this.IsChannel() && !this.IsChat()) {
      this.props.history.push('/communications');
    }
  }

  IsChannel = () => {
    const { selected } = this.props;
    if (!selected) return false;
    const { channel } = selected;
    if (channel) return true;
    return false;
  };
  IsChat = () => {
    const { selected } = this.props;
    if (!selected) return false;
    const { direct } = selected;
    if (direct) return true;
    return false;
  };
  getChannel = () => {
    const { selected } = this.props;
    if (!selected) return null;
    return selected.channel;
  };
  getReceiver = () => {
    const { selected } = this.props;
    if (!selected) return null;
    return selected.direct.User;
  };
  getID = () => {
    const params = getParams(this.props);
    if (!params) return 0;
    const { id } = params;
    return parseInt(id, 10);
  };

  render() {
    const { height, isMobile, user, handleDrawerToggle } = this.props;
    const channelId = this.getID();
    if (this.IsChannel())
      return (
        <ChannelRight
          channel={this.getChannel()}
          channelId={this.getID()}
          // handleChannelSelection={this.handleChannelSelection}
          handleDrawerToggle={handleDrawerToggle}
          height={height}
          isMobile={isMobile}
        />
      );
    if (this.IsChat())
      return (
        <QueryChatMessages
          handleAddLastDirect={this.props.handleAddLastDirect}
          Receiver={this.getReceiver()}
          user={user}>
          <DirectChatRight
            handleDrawerToggle={handleDrawerToggle}
            handleOnlineUser={this.props.handleOnlineUser}
            height={height}
            holder={this.holder}
            idReceiver={channelId}
            isMobile={isMobile}
          />
        </QueryChatMessages>
      );
    return null;
  }
}

CommunicationMessages.propTypes = {
  classes: PropTypes.object,
};
CommunicationMessages.defaultProps = {
  handleOnlineUser: () => '',
};
export default withRouter(CommunicationMessages);
