import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import {
  GroupBox,
  IconButtonLoad,
  SearchCentersInputs,
  Sequence,
} from 'components';
import { Replay as ReloadIcon, Save as SaveIcon } from '@mui/icons-material';
import { MutationAddSequencesMapping } from 'graphql/Series/sequences';
import { GetData, ServerErrorsString } from 'helpers';
import { getFileID } from '../utils';

class GroupBoxEditSelectedRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_selected: null,
      updating: false,
      sequence: null,
      center: null,
    };
    this.holder = {
      resetSequence: null,
      resetCenter: null,
      reset: () => {
        if (this.holder.resetSequence) {
          this.holder.resetSequence();
        }
        if (this.holder.resetCenter) {
          this.holder.resetCenter();
        }
      },
    };
  }

  componentDidUpdate() {
    const { row_selected: original } = this.props;
    if (!original) return;
    const { row_selected: current } = this.state;
    //
    if (!current || getFileID(current) !== getFileID(original)) {
      this.handleResetRowSequence();
    }
  }

  handleResetRowSequence = () => {
    this.setState({
      row_selected: this.props.row_selected,
      sequence: null,
      center: null,
    });
    this.holder.reset();
    console.log('handleResetRowSequence');
  };
  isChanged = () => {
    const { sequence } = this.state;
    if (sequence === undefined) return false;
    return this.state.sequence ? true : false;
  };
  getName = row => {
    let { name, ProtocolName, SeriesDescription, SequenceName } = row;
    if (name === undefined || !name) {
      if (ProtocolName && ProtocolName !== '' && ProtocolName !== 'Empty')
        name = ProtocolName;
      else if (
        SeriesDescription &&
        SeriesDescription !== '' &&
        SeriesDescription !== 'Empty'
      )
        name = SeriesDescription;
      else if (SequenceName && SequenceName !== '' && SequenceName !== 'Empty')
        name = SequenceName;
      else name = null;
    }
    return name;
  };
  getSequenceMapping = () => {
    const { sequence, center, row_selected } = this.state;
    if (!sequence || sequence.idSequence === undefined) {
      return [];
    }
    const { show_mapping, selected } = this.props;
    console.log({ show_mapping, selected });
    if (show_mapping) {
      if (row_selected) {
        let { idSeries } = row_selected;
        return [
          {
            id: 1,
            idSequence: sequence.idSequence,
            idCenter: center ? center.id : null,
            name: this.getName(row_selected),
            idSeries,
          },
        ];
      }
    } else {
      return selected
        .map((x, id) => {
          let { idSeries } = x;
          return {
            id,
            idSeries,
            idSequence: sequence.idSequence,
            idCenter: center ? center.id : null,
            name: this.getName(x),
          };
        })
        .filter(x => x.name);
    }
    return [];
  };
  updateSequenceMapping = () => {
    const { updating, row_selected } = this.state;
    if (updating) return;
    const in_mappings = this.getSequenceMapping();
    console.log({ in_mappings, row_selected });
    if (!in_mappings.length) {
      this.props.handleAddError(
        'There are not mapping selected or the sequence is missing'
      );
      return;
    }

    this.setState({ updating: true });
    (async () => {
      MutationAddSequencesMapping(in_mappings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addSequencesMapping;
          if (ok) {
            this.handleSaveButton();
            this.setState({ updating: false, sequence: null, center: null });
            this.holder.reset();
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveButton = () => {
    const { show_mapping, selected } = this.props;
    const { sequence, row_selected } = this.state;

    if (show_mapping) {
      if (row_selected) {
        const idMap = row_selected.series.idSeries;
        this.props.handleSave([idMap], sequence.idSequence);
      }
    } else {
      const ids = selected.map(x => x.series.idSeries);
      this.props.handleSave(ids, sequence.idSequence);
    }
  };
  handleSequenceChanged = (label, sequence) => {
    if (sequence === undefined) {
      sequence = null;
    }
    this.setState({ sequence });
  };
  handleSelectCenter = center => {
    if (center === undefined) {
      center = null;
    }
    this.setState({ center });
  };

  render() {
    let { row_selected, updating, single, show_mapping } = this.props;
    if (this.state.row_selected) {
      row_selected = this.state.row_selected;
    } else if (!row_selected) {
      row_selected = { sequence: null, name: '', center: null };
    }
    if (single === undefined) single = true;
    let disable_all = false;
    if (
      single &&
      (!this.props.row_selected || this.props.row_selected.id < 0)
    ) {
      disable_all = true;
    }
    let ComponentMapping = null;
    if (show_mapping) {
      ComponentMapping = (
        <Grid item style={{ paddingLeft: 40 }} xs={3}>
          <TextField
            disabled
            fullWidth
            id="sequence-mapping-value"
            InputLabelProps={{
              shrink: true,
            }}
            label="Mapping Value"
            sx={{ m: 1, minWidth: '25ch' }}
            value={row_selected.name}
            variant="standard"
          />
        </Grid>
      );
    }
    //
    return (
      <GroupBox title_box={this.props.title_box}>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}>
          <Grid item style={{ paddingRight: 50 }} xs={4}>
            <Sequence
              disabled={disable_all}
              handleSelectedChanged={this.handleSequenceChanged}
              holder={this.holder}
              small
            />
          </Grid>
          <Grid item style={{ paddingRight: 10, marginTop: 0 }} xs={4}>
            <SearchCentersInputs
              disabled={disable_all}
              handleAddCenter={this.handleSelectCenter}
              handleSetCenter={this.handleSelectCenter}
              no_icon
              placeholder="Search Center..."
            />
          </Grid>
          {ComponentMapping}
          <Grid
            container
            direction="row"
            item
            style={{ paddingRight: 10, marginTop: 0 }}
            xs={3}>
            <IconButtonLoad
              disabled={updating || disable_all}
              handleClick={this.handleResetRowSequence}
              icon={<ReloadIcon />}
              // loading={this.props.loading}
              tooltip="Reset Sequence"
            />
            <IconButtonLoad
              disabled={!this.isChanged() || updating || disable_all}
              handleClick={this.updateSequenceMapping}
              icon={
                <SaveIcon color={this.isChanged() ? 'primary' : 'disabled'} />
              }
              loading={updating}
              tooltip="Update Sequence"
            />
          </Grid>
        </Grid>
      </GroupBox>
    );
  }
}

GroupBoxEditSelectedRow.propTypes = {
  classes: PropTypes.object,
  handleAddError: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  row_selected: PropTypes.object,
  selected: PropTypes.array,
  show_mapping: PropTypes.bool.isRequired,
  single: PropTypes.bool.isRequired,
  title_box: PropTypes.string.isRequired,
};
GroupBoxEditSelectedRow.defaultProps = {
  handleUpdateSequenceMappings: () => '',
  title_box: '',
  row_selected: null,
  single: true,
  selected: [],
  show_mapping: true,
  handleSave: () => '',
  handleAddError: () => '',
};

export default GroupBoxEditSelectedRow;
