import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SSR_THROMBOLYSISPROCEDURE = gql`
  query($name: String, $page: Int, $limit: Int) {
    ssrThromboProcedures(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      procedures {
        id
        idPatient
        idCase
        bridging_regime
        bridging_regime_oth
        rtpa_score
        conc_medi
        antithrombotic
        antithrombotic_oth
        recanalization
        antithrombotic_start
        ct_denseartery
        ct_leukoaraiosis
        ct_oldinfarct
        ct_earlyinfarctsings
        ct_aspects
        occ_none
        occ_ica_extra
        occ_ica_intra
        occ_ica_t
        occ_mca_m1
        occ_mca_m2
        occ_mca_m3_m4
        occ_ba_proximal
        occ_ba_middle
        occ_ba_distal
        occ_aca_a1
        occ_aca_a2
        occ_va
        occ_pca_p1
        occ_pca_p2
        occ_pica
        occ_suca
        occ_aica
        occ_other
        occ_other_spec
        occ_determined_by
        occ_determined_oth
        occ_core
        occ_penumbra
        lab_inr
        lab_aptt
        lab_tt
        lab_riv
        lab_dab
        lab_afxa
        lab_platelets
        lab_haem
        lab_crp
        lab_calcium
        lab_albumin
        lab_uric_acid
        lab_triglycerides
        lab_hdl
        lab_hba1c
        lab_tropinin_t_hs
        lab_d_dimer
        reperfusion_time
        stroke_reper_time
        door_reper_time
        ivt_reper_time
        iat_reper_time
        ed_none
        ed_solitaire
        ed_trevo
        ed_3d_separator
        ed_preset
        ed_capture
        ed_merci
        ed_other
        ed_other_spec
        alo_acc
        alo_aci_prox
        alo_aci_dist
        alo_m1
        alo_m2
        alo_m3
        alo_a1
        alo_av_prox
        alo_av_dist
        alo_ba_prox
        alo_ba_dist
        alo_p1
        alo_p2
        alo_other
        alo_other_spec
        recanal_before
        recanal_after
        pat_alone_before
        pat_independ_before
        pat_lift_arms_adm
        pat_walk_adm
        pat_gcs_normal
        dragon_prestroke
        dragon_age
        dragon_glucose
        dragon_ott
        dragon_nihss
        dragon_score
        sedan_glucose
        sedan_age
        sedan_nihss
        sedan_score
        fu_timi
        fu_any_ich
        fu_deterioration
        fu_sympt_ich
        sympt_ecass
        sympt_ninds
        sympt_sits
        ich_proact2
        bleeding_other
        fu_barthel_90d
        fu_nihss_90d
        fu_epi_90d
        fu_mrs_1y
        thrombo_comments
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const QueryStrokeThrombolysisProcedure = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SSR_THROMBOLYSISPROCEDURE),
    variables: {
      name,
      page,
      limit,
    },
  });
};

const MUTATION_ADD_SSR_THROMBOPROCEDURE = gql`
  mutation($inputs: [InputSsrThromboProcedure!]!) {
    addSsrThromboProcedure(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      procedures {
        id
        idPatient
        idCase
        bridging_regime
        bridging_regime_oth
        rtpa_score
        conc_medi
        antithrombotic
        antithrombotic_oth
        recanalization
        antithrombotic_start
        ct_denseartery
        ct_leukoaraiosis
        ct_oldinfarct
        ct_earlyinfarctsings
        ct_aspects
        occ_none
        occ_ica_extra
        occ_ica_intra
        occ_ica_t
        occ_mca_m1
        occ_mca_m2
        occ_mca_m3_m4
        occ_ba_proximal
        occ_ba_middle
        occ_ba_distal
        occ_aca_a1
        occ_aca_a2
        occ_va
        occ_pca_p1
        occ_pca_p2
        occ_pica
        occ_suca
        occ_aica
        occ_other
        occ_other_spec
        occ_determined_by
        occ_determined_oth
        occ_core
        occ_penumbra
        lab_inr
        lab_aptt
        lab_tt
        lab_riv
        lab_dab
        lab_afxa
        lab_platelets
        lab_haem
        lab_crp
        lab_calcium
        lab_albumin
        lab_uric_acid
        lab_triglycerides
        lab_hdl
        lab_hba1c
        lab_tropinin_t_hs
        lab_d_dimer
        reperfusion_time
        stroke_reper_time
        door_reper_time
        ivt_reper_time
        iat_reper_time
        ed_none
        ed_solitaire
        ed_trevo
        ed_3d_separator
        ed_preset
        ed_capture
        ed_merci
        ed_other
        ed_other_spec
        alo_acc
        alo_aci_prox
        alo_aci_dist
        alo_m1
        alo_m2
        alo_m3
        alo_a1
        alo_av_prox
        alo_av_dist
        alo_ba_prox
        alo_ba_dist
        alo_p1
        alo_p2
        alo_other
        alo_other_spec
        recanal_before
        recanal_after
        pat_alone_before
        pat_independ_before
        pat_lift_arms_adm
        pat_walk_adm
        pat_gcs_normal
        dragon_prestroke
        dragon_age
        dragon_glucose
        dragon_ott
        dragon_nihss
        dragon_score
        sedan_glucose
        sedan_age
        sedan_nihss
        sedan_score
        fu_timi
        fu_any_ich
        fu_deterioration
        fu_sympt_ich
        sympt_ecass
        sympt_ninds
        sympt_sits
        ich_proact2
        bleeding_other
        fu_barthel_90d
        fu_nihss_90d
        fu_epi_90d
        fu_mrs_1y
        thrombo_comments
        createdAt
        updatedAt

        patient {
          id
          PatientID
        }
      }
    }
  }
`;

export const MutationAddSsrThromboProcedure = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SSR_THROMBOPROCEDURE),
    variables: {
      inputs,
    },
  });
};

const SEARCH_SSR_THROMBOPROCEDURE = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchSsrThromboProcedures(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      procedures {
        id
        idPatient
        idCase
        bridging_regime
        bridging_regime_oth
        rtpa_score
        conc_medi
        antithrombotic
        antithrombotic_oth
        recanalization
        antithrombotic_start
        ct_denseartery
        ct_leukoaraiosis
        ct_oldinfarct
        ct_earlyinfarctsings
        ct_aspects
        occ_none
        occ_ica_extra
        occ_ica_intra
        occ_ica_t
        occ_mca_m1
        occ_mca_m2
        occ_mca_m3_m4
        occ_ba_proximal
        occ_ba_middle
        occ_ba_distal
        occ_aca_a1
        occ_aca_a2
        occ_va
        occ_pca_p1
        occ_pca_p2
        occ_pica
        occ_suca
        occ_aica
        occ_other
        occ_other_spec
        occ_determined_by
        occ_determined_oth
        occ_core
        occ_penumbra
        lab_inr
        lab_aptt
        lab_tt
        lab_riv
        lab_dab
        lab_afxa
        lab_platelets
        lab_haem
        lab_crp
        lab_calcium
        lab_albumin
        lab_uric_acid
        lab_triglycerides
        lab_hdl
        lab_hba1c
        lab_tropinin_t_hs
        lab_d_dimer
        reperfusion_time
        stroke_reper_time
        door_reper_time
        ivt_reper_time
        iat_reper_time
        ed_none
        ed_solitaire
        ed_trevo
        ed_3d_separator
        ed_preset
        ed_capture
        ed_merci
        ed_other
        ed_other_spec
        alo_acc
        alo_aci_prox
        alo_aci_dist
        alo_m1
        alo_m2
        alo_m3
        alo_a1
        alo_av_prox
        alo_av_dist
        alo_ba_prox
        alo_ba_dist
        alo_p1
        alo_p2
        alo_other
        alo_other_spec
        recanal_before
        recanal_after
        pat_alone_before
        pat_independ_before
        pat_lift_arms_adm
        pat_walk_adm
        pat_gcs_normal
        dragon_prestroke
        dragon_age
        dragon_glucose
        dragon_ott
        dragon_nihss
        dragon_score
        sedan_glucose
        sedan_age
        sedan_nihss
        sedan_score
        fu_timi
        fu_any_ich
        fu_deterioration
        fu_sympt_ich
        sympt_ecass
        sympt_ninds
        sympt_sits
        ich_proact2
        bleeding_other
        fu_barthel_90d
        fu_nihss_90d
        fu_epi_90d
        fu_mrs_1y
        thrombo_comments
        createdAt
        updatedAt
        patient {
          id
          PatientID
        }
      }
    }
  }
`;
export const QuerySearchStrokeThromboProcedure = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SEARCH_SSR_THROMBOPROCEDURE),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
