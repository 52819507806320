import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERT_SITE_PROJECT_ACTIVITIES = gql`
  query($username: String, $projectname: String, $page: Int, $limit: Int) {
    SiteProjectActivity(
      username: $username
      projectname: $projectname
      page: $page
      limit: $limit
    ) {
      ok
      total
      activities {
        id
        idProject
        idUser

        activity
        icon

        Project {
          name
        }
        User {
          FullName
        }
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QuerySiteProjectActivity = (
  username,
  projectname,
  page,
  limit
) => {
  const variables = {};
  if (username && typeof username !== 'undefined' && username !== '') {
    variables.username = username;
  }
  if (projectname && typeof projectname !== 'undefined' && projectname !== '') {
    variables.projectname = projectname;
  }
  if (typeof page !== 'undefined') {
    variables.page = page;
  }
  if (typeof limit !== 'undefined') {
    variables.limit = limit;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERT_SITE_PROJECT_ACTIVITIES),
    variables,
  });
};
