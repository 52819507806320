import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  GroupAutors,
  GroupClinics,
  GroupCopyright,
  GroupCreators,
  GroupImageTypes,
  GroupKeywords,
  GroupProjects,
  GroupPublications,
} from './components';
import { DialogCreateSeriesDetail, DialogDeleteWarning } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import {
  MutationAddGroupMapping,
  MutationRemoveGroupMapping,
} from 'graphql/Mapping/utils_group_mapping';
class TabDesignerSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleting: false,
      open: false,
      open_delete: false,
      title: '',
      idGroup: -1,
      ids: [],
    };
    this.holder = {
      addAuthor: null,
      removeAuthors: null,
      addProject: null,
      removeProjects: null,
      addCopyright: null,
      removeCopyright: null,
      addCreators: null,
      removeCreators: null,
      addImageType: null,
      removeImageType: null,
      addKeyword: null,
      removeKeyword: null,
      addPublication: null,
      removePublication: null,
      addClinic: null,
      removeClinic: null,
    };
  }
  handleOpenAddDialog = (title, idGroup, open) => {
    this.setState({ title, idGroup, open });
  };
  handleOpenRemoveDialog = (title, idGroup, ids) => {
    let open_delete = true;
    if (ids && !ids.length) {
      open_delete = false;
    }
    this.setState({ title, idGroup, ids, open_delete });
  };
  handleCreateGroup = name => {
    const { loading, idGroup, title } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    const inputs = [
      {
        idGroup,
        groupname: title,
        name,
      },
    ];
    (async () => {
      MutationAddGroupMapping(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.addGroupMappings;
          if (ok) {
            if (mappings.length) {
              this.handleAddGroupMapping(title, mappings[0]);
            }
            this.setState({ loading: false, open: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddGroupMapping = (title, map) => {
    if (title === 'Authors Designer') {
      this.holder.addAuthor(map);
    } else if (title === 'Project Designer') {
      this.holder.addProject(map);
    } else if (title === 'Creators Designer') {
      this.holder.addCreators(map);
    } else if (title === 'Keyword Designer') {
      this.holder.addKeyword(map);
    } else if (title === 'Image Type Designer') {
      this.holder.addImageType(map);
    } else if (title === 'Publication Designer') {
      this.holder.addPublication(map);
    } else if (title === 'Copyright') {
      this.holder.addCopyright(map);
    } else if (title === 'Clinics Designer') {
      this.holder.addClinic(map);
    }
  };
  handleDeleteSelected = () => {
    const { deleting, idGroup, ids, title } = this.state;
    // console.log({ idGroup });
    if (deleting || !ids.length) return;
    this.setState({ deleting: true });
    (async () => {
      MutationRemoveGroupMapping(idGroup, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeGroupMapping;
          if (ok) {
            this.handleRemoveGroupMapping(title);
            this.setState({ deleting: false, ids: [], open_delete: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRemoveGroupMapping = title => {
    if (title === 'Authors Designer') {
      this.holder.removeAuthors();
    } else if (title === 'Project Designer') {
      this.holder.removeProjects();
    } else if (title === 'Creators Designer') {
      this.holder.removeCreators();
    } else if (title === 'Keyword Designer') {
      this.holder.removeKeyword();
    } else if (title === 'Image Type Designer') {
      this.holder.removeImageType();
    } else if (title === 'Publication Designer') {
      this.holder.removePublication();
    } else if (title === 'Copyright') {
      this.holder.removeCopyright();
    } else if (title === 'Clinics Designer') {
      this.holder.removeClinic();
    }
  };
  render() {
    const { height } = this.props;
    let style = null;
    if (height) {
      const final_height = height - 110;
      style = { height: final_height, maxHeight: final_height };
    }
    return (
      <React.Fragment>
        <Grid
          columns={{ xs: 4, sm: 8, md: 12 }}
          container
          spacing={{ xs: 2, md: 3 }}
          sx={style}>
          <Grid item md={4} sm={4} xs={2}>
            <GroupAutors
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupProjects
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>

          <Grid item md={4} sm={4} xs={2}>
            <GroupCreators
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupClinics
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupCopyright
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupKeywords
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupPublications
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
          <Grid item md={4} sm={4} xs={2}>
            <GroupImageTypes
              handleOpenAddDialog={this.handleOpenAddDialog}
              handleOpenRemoveDialog={this.handleOpenRemoveDialog}
              height={350}
              holder={this.holder}
            />
          </Grid>
        </Grid>
        <DialogCreateSeriesDetail
          handleClose={() => this.setState({ open: false })}
          handleCreateDetail={this.handleCreateGroup}
          loading={this.state.loading}
          open={this.state.open}
          title={this.state.title}
        />
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={
            'You are about to delete the selected mapping, this action cannot be undone'
          }
          title_text="Delete selected mappings"
        />
      </React.Fragment>
    );
  }
}

TabDesignerSettings.propTypes = {
  classes: PropTypes.object,
};

export default TabDesignerSettings;
