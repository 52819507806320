import React from 'react';
import PropTypes from 'prop-types';
import { Paper, ButtonGroup, Button, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { ButtonReload, SearchInput } from 'components';
import { styleToolbar } from 'common';
class ToolBarSignalPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  render() {
    const { classes, selected } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between">
          <ButtonGroup
            // className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            <Button
              disabled={!selected.length}
              onClick={this.props.handleOpenEdit}>
              <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
            </Button>
          </ButtonGroup>
          <Grid item sx={{ paddingLeft: 5 }} xs={6}>
            <SearchInput
              onChange={this.props.handleChangeName}
              placeholder="Search patient"
              value={this.props.name}
            />
          </Grid>
          <ButtonReload
            className={classes.margin}
            handleReload={this.props.handleReload}
            loading={this.props.loading}
            small
            tooltip="Reload details"
            wrapper={{ margin: 0 }}
          />
        </Grid>
      </Paper>
    );
  }
}

ToolBarSignalPatient.propTypes = {
  classes: PropTypes.object,
  handelAddVariableTypes: PropTypes.func,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
};
ToolBarSignalPatient.defaultProps = {
  loading: false,
  selected: [],
  name: '',
  handleChangeName: () => '',
  handleReload: () => '',
};
export default withStyles(ToolBarSignalPatient, styleToolbar);
