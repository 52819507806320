export const shader_2D = {
  vertShaderSource: `
attribute vec2 a_position;

uniform mat3 u_matrix;

varying vec4 v_color;

void main() {
  // Multiply the position by the matrix.
  gl_Position = vec4((u_matrix * vec3(a_position, 1)).xy, 0, 1);

  // Convert from clipspace to colorspace.
  // Clipspace goes -1.0 to +1.0
  // Colorspace goes from 0.0 to 1.0
  v_color = gl_Position * 0.5 + 0.5;
}
        `,
  fragShaderSource: `
precision mediump float;

varying vec4 v_color;

void main() {
  gl_FragColor = v_color;
}
        `,
};
// webgl-how-it-works.html
export const shader_2D_1 = {
  vertShaderSource: `
attribute vec2 a_position;
attribute vec4 a_color;

uniform mat3 u_matrix;

varying vec4 v_color;

void main() {
  // Multiply the position by the matrix.
  gl_Position = vec4((u_matrix * vec3(a_position, 1)).xy, 0, 1);

  // Copy the color from the attribute to the varying.
  v_color = a_color;
}
        `,
  fragShaderSource: `
precision mediump float;

varying vec4 v_color;

void main() {
  gl_FragColor = v_color;
}
        `,
};
export const shader_2D_TEXT = {
  vertShaderSource: `
attribute vec4 a_position;
attribute vec2 a_texcoord;

uniform mat4 u_matrix;

varying vec2 v_texcoord;

void main() {
   gl_Position = u_matrix * a_position;
   v_texcoord = a_texcoord;
}
        `,
  fragShaderSource: `
precision mediump float;

varying vec2 v_texcoord;
uniform sampler2D u_texture;

void main() {
   gl_FragColor = texture2D(u_texture, v_texcoord);
}
        `,
};
export const shader_3D_CUBE = {
  vertShaderSource: `
  attribute vec4 a_position;
attribute vec2 a_texcoord;

uniform mat4 u_matrix;

varying vec2 v_texcoord;

void main() {
  // Multiply the position by the matrix.
  gl_Position = u_matrix * a_position;

  // Pass the texcoord to the fragment shader.
  v_texcoord = a_texcoord;
}
  `,
  fragShaderSource: `
  precision mediump float;

// Passed in from the vertex shader.
varying vec2 v_texcoord;

// The texture.
uniform sampler2D u_texture;

void main() {
   gl_FragColor = texture2D(u_texture, v_texcoord);
}
  `,
};
export const shader_2D_IMAGE = {
  vertShaderSource: `
attribute vec4 a_position;
attribute vec2 a_texcoord;

uniform mat4 u_matrix;

varying vec2 v_texcoord;

void main() {
  // Multiply the position by the matrix.
  gl_Position = u_matrix * a_position;

  // Pass the texcoord to the fragment shader.
  v_texcoord = a_texcoord;
}
        `,
  fragShaderSource: `
precision mediump float;

// Passed in from the vertex shader.
varying vec2 v_texcoord;

// The texture.
uniform sampler2D u_texture;

void main() {
   gl_FragColor = texture2D(u_texture, v_texcoord);
}
        `,
};

export const shader_3D_cubes = {
  vertShaderSource: `
attribute vec4 a_position;
attribute vec4 a_color;
attribute vec2 a_texcoord;


uniform mat4 u_matrix;

varying vec4 v_color;
varying vec2 v_texcoord;

void main() {
  // Multiply the position by the matrix.
  gl_Position = u_matrix * a_position;

  // Pass the color to the fragment shader.
  v_color = a_color;
  v_texcoord = a_texcoord;

}
        `,
  fragShaderSource: `
precision mediump float;

// Passed in from the vertex shader.
varying vec4 v_color;
varying vec2 v_texcoord;
uniform sampler2D u_texture;

void main() {
  // gl_FragColor = v_color;
  gl_FragColor = texture2D(u_texture, v_texcoord);

}
        `,
};

export const shader_3D_lines = {
  vertShaderSource: `
    attribute vec4 a_position;
    attribute vec4 a_color;

    uniform mat4 u_matrix;
		varying vec4 v_color;

    void main(void)
		{
      gl_Position = u_matrix * a_position;
      v_color = a_color;
		}
  `,
  fragShaderSource: `
    precision highp float;
    precision highp int;
    varying vec4 v_color;
  	void main(void)
		{
			gl_FragColor = v_color;
		}
  `,
};
