import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@mui/material';

class ShortTerm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const style_title = {
      paddingTop: 10,
      textTransform: 'none',
    };
    return (
      <React.Fragment>
        <Typography gutterBottom style={style_title} variant="h6">
          Abstract
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The SWEZ Inselspital <strong>Short-term</strong> iEEG Dataset
          comprises of 100 seizures of varying length collected from a total of
          16 patients suffering from pharmacoresistent epilepsy undergoing
          pre-surgical evaluation at the Sleep-Wake-Epilepsy-Center (SWEZ) of
          the University Department of Neurology at the Inselspital in Bern,
          Switzerland.
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          License
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The database is provided under the{' '}
          <Link
            href="https://opendatacommons.org/licenses/odbl/1-0/)"
            rel="noopener noreferrer"
            target="_blank">
            Attribution License Open Data Commons v1.0
          </Link>
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Description
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The iEEG signals were recorded intracranially by strip, grid, and
          depth electrodes. After 16-bit analog-to-digital conversion, the data
          were digitally band-pass filtered between 0.5 and 150 Hz using a
          fourth-order Butterworth filter prior to analysis and written onto
          disk at a rate of 512 Hz. Forward and backward filtering was applied
          to minimize phase distortions. All the iEEG recordings were visually
          inspected by an EEG board-certified experienced epileptologist (K.S.)
          for identification of seizure onsets and endings and exclusion of
          channels continuously corrupted by artifacts. Each recording consists
          of 3 minutes of preictal segments (i.e., immediately before the
          seizure onset), the ictal segment (ranging from 10 s to 1002 s), and 3
          minutes of postictal time (i.e., immediately after seizure ending).
        </Typography>
        <Typography gutterBottom style={style_title} variant="h6">
          Data format
        </Typography>
        <Typography color="text" sx={{ fontSize: 17 }} variant="body2">
          The dataset is provided as a set of 16 folders, one for each patient.
          Each folder contains a certain number of HDF5 files named{' '}
          <strong>ID&lt;nn&gt;_part&lt;xy&gt;.h5</strong>, one for each seizure
          recorded. Each file contains a single group <strong>data</strong> with
          a single dataset <strong>ieeg</strong> containing the corresponding
          iEEG recording with dimensions channels x samples. <br />
          <br />
          Each file contains also the following attributes:
          <br />- <strong>channels</strong> is the number of channels of the
          patient <br />- <strong>patient</strong> is the patient ID <br />-{' '}
          <strong>sampling_rate</strong> is the patient sampling rate
          <br />
          <br />
          Each folder contains an HDF5 VDS file named{' '}
          <strong>ID&lt;nn&gt;_total.h5</strong> containing the groups{' '}
          <strong>data</strong> and <strong>info</strong>.<br />
          <br /> <strong>data</strong> is structured thus: <br />-{' '}
          <strong>ieeg_&lt;xy&gt;</strong> is a virtual dataset containing the
          iEEG recording for seizure &lt;xy&gt;, contained in file{' '}
          <strong>ID&lt;nn&gt;_total.h5</strong>
          <br />- <strong>seizures</strong> is a dataset containing the
          timepoints (in seconds) of each seizure onset and offset. <br />
          <br /> <strong>info</strong> is structured thus: <br />-{' '}
          <strong>files</strong> contains the filenames of the individual
          dataset composing the VDS. <br />- <strong>checksums</strong> is a
          dataset containing the Blake2b checksums of each file described in{' '}
          <strong>files</strong> to ensure data integrity after download
        </Typography>
      </React.Fragment>
    );
  }
}

ShortTerm.propTypes = {
  classes: PropTypes.object,
};

export default ShortTerm;
