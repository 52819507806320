import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Typography } from '@mui/material';
import { DivGrow } from 'components';
import { getUserFullName } from 'helpers';
import { ButtonProject } from './components';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    height: '42px',
    // marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  activeButton: {
    height: '20px',
    // margin: theme.spacing(1),
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  textTop: {
    marginBottom: theme.spacing(2),
  },
}));

const OverviewToolBar = props => {
  const { className, project, history, loading } = props;
  const { id, name } = project;
  const { classes } = useStyles();
  let administrator = '';
  if (project.admin !== undefined) {
    administrator = getUserFullName(project.admin);
  }
  let title = name ? name : 'Select a Project';
  let head = `BROWSE PROJECT (${id})`;
  if (loading) {
    title = 'Loading...';
    administrator = title;
    head = 'BROWSE PROJECT';
  }
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography className={classes.textTop} component="div">
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            m={1}
            variant="button">
            {head}
          </Box>
          <Box fontSize={22} fontWeight="fontWeightBold" m={1} spacing={3}>
            {title}
          </Box>
          <Box m={1}> </Box>
        </Typography>
        <DivGrow />
        <Typography className={classes.textTop} component="div">
          <Box m={1}> </Box>
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            m={1}
            variant="button">
            Administrator
          </Box>
          <Box fontSize={20} fontWeight="fontWeightBold" m={1} spacing={1}>
            {administrator}
          </Box>
        </Typography>
      </div>
      <div className={classes.row}>
        {!loading && (
          <ButtonProject
            classes={classes}
            history={history}
            project={project}
          />
        )}
      </div>
    </div>
  );
};

OverviewToolBar.propTypes = {
  className: PropTypes.string,
};

export default withRouter(OverviewToolBar);
