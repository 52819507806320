import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  ImageSize,
  ImageSpacing,
  ImageNIfTI,
} from 'views/QueryRetreive/components/TabQueryImages/components/TopTabQuery/components/TabImage/components';
import { CheckBoxText, DivGrow } from 'components';

class ContentImageType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { imagesize, spacing, nifti } = this.props;
    return (
      <Grid alignItems="flex-start" container direction="column" spacing={0}>
        <CheckBoxText
          checked={nifti}
          label="NIfTI"
          name="nifti"
          onChange={this.props.handleChange}
        />
        {nifti && <ImageNIfTI holder={this.props.holder} />}
        <CheckBoxText
          checked={imagesize}
          label="ImageSize"
          name="imagesize"
          onChange={this.props.handleChange}
        />
        {imagesize && <ImageSize holder={this.props.holder} />}
        <CheckBoxText
          checked={spacing}
          label="Spacing"
          name="spacing"
          onChange={this.props.handleChange}
        />
        {spacing && <ImageSpacing holder={this.props.holder} />}
        <DivGrow />
      </Grid>
    );
  }
}

ContentImageType.propTypes = {
  imagesize: PropTypes.bool,
  nifti: PropTypes.bool,
  spacing: PropTypes.bool,
};

export default ContentImageType;
