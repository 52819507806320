const GlAndCanvas = (idViewer, width, height, version) => {
  let gl = null;
  const canvas = document.getElementById(idViewer);
  if (canvas === undefined || !canvas) return null;
  console.log({ width, height });
  if (width && height) {
    canvas.width = width;
    canvas.height = height;
  }
  // canvas.width = window.innerWidth * 0.99;
  // canvas.height = window.innerHeight * 0.85;
  gl = canvas.getContext(version ? 'webgl' : 'webgl2');
  const ext = gl.getExtension('EXT_color_buffer_float');
  if (!ext) {
    // eslint-disable-next-line quotes
    console.log("sorry, can't render to floating point textures");
  }
  gl.clearColor(0, 0, 0, 1.0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  return { gl, canvas };
};
const createShader = (gl, sourceCode, type) => {
  /*
        This function acts as a global funtion to create shaders for the WebGl components. In some browsers the default function is not valid, so this function replaces it.
        */
  // console.log({ sourceCode });
  var shader = gl.createShader(type);
  gl.shaderSource(shader, sourceCode);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    var info = gl.getShaderInfoLog(shader);
    console.log('could not compile web gl shader. \n\n' + info);
  }

  return shader;
};
const createProgramFromString = (gl, shaderSources) => {
  let vertShader = [];
  let fragShader = [];

  if (/Firefox/i.test(navigator.userAgent)) {
    vertShader = createShader(
      gl,
      shaderSources.vertShaderSource,
      gl.VERTEX_SHADER
    );
    fragShader = createShader(
      gl,
      shaderSources.fragShaderSource,
      gl.FRAGMENT_SHADER
    );
  } else {
    vertShader = gl.createShader(gl.VERTEX_SHADER);
    fragShader = gl.createShader(gl.FRAGMENT_SHADER);

    gl.shaderSource(vertShader, shaderSources.vertShaderSource);
    gl.shaderSource(fragShader, shaderSources.fragShaderSource);

    gl.compileShader(vertShader);
    gl.compileShader(fragShader);
  }

  const program = gl.createProgram();
  gl.attachShader(program, vertShader);
  gl.attachShader(program, fragShader);

  gl.linkProgram(program);

  gl.useProgram(program);
  return program;
};
const updatePosition = (index, renderer) => {
  return function(event, ui) {
    renderer.translation[index] = ui.value;
    renderer.drawScene();
  };
};

const updateAngle = (event, ui, renderer) => {
  var angleInDegrees = 360 - ui.value;
  renderer.angleInRadians = (angleInDegrees * Math.PI) / 180;
  renderer.drawScene();
};

const updateScale = (index, renderer) => {
  return function(event, ui) {
    renderer.scale[index] = ui.value;
    renderer.drawScene();
  };
};
export {
  GlAndCanvas,
  createShader,
  createProgramFromString,
  updatePosition,
  updateAngle,
  updateScale,
};
