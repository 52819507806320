import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
/* -------------- USER LOGS ------------------ */
const QUERY_LOGS_DOCKER = gql`
  query($name: String, $page: Int, $limit: Int) {
    logDockers(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      logs {
        id
        idUser
        user {
          id
          FullName
        }
        name
        idUser
        count
        status
        containerID
        error
        createdAt
        updatedAt
      }
      total
    }
  }
`;
export const QueryLogDockers = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_LOGS_DOCKER),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const DELETE_LOGS_DOCKER = gql`
  mutation($ids: [LongLong!]!) {
    deleteLogDockers(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteLogDockers = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(DELETE_LOGS_DOCKER),
    variables: {
      ids,
    },
  });
};
