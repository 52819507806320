import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSleepCSV } from './utils';

const QUERY_SLEEP_TEST_TYPE = gql`
  query($name: String, $type: String, $page: Int, $limit: Int) {
    sleepTestTypes(name: $name, type: $type, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      test_types {
        id
        idPatient
        idSleepCase
        type
        instruction
        date
        diagnose1
        diagnose2
        diagnose3
        nr_repetitions
        sleep_latency
        sorem_n

        sleep_case {
          id
          record_id
        }
        repetitions {
          id
          start_datetime
          sleep_latency
          rem_latency
          kss_pre
          kss_post
        }
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySleepTestTypes = (name, type, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SLEEP_TEST_TYPE),
    variables: {
      name,
      type,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_SLEEP_TEST_TYPES = gql`
  mutation($ids: [LongLong!]!) {
    deleteSleepTestTypes(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveSleepTestTypes = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_SLEEP_TEST_TYPES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_SLEEP_TEST_TYPES = gql`
  mutation($inputs: [InputSleepTestType!]!) {
    addSleepTestTypes(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      test_types {
        id
        idPatient
        idSleepCase
        type
        instruction
        date
        diagnose1
        diagnose2
        diagnose3
        nr_repetitions
        sleep_latency
        sorem_n

        sleep_case {
          id
          record_id
        }
        repetitions {
          id
          start_datetime
          sleep_latency
          rem_latency
          kss_pre
          kss_post
        }
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddSleepTestTypes = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_SLEEP_TEST_TYPES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_SLEEP_TEST_TYPES = gql`
  mutation($file: Upload!) {
    uploadSleepTestTypes(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      test_types {
        id
        idPatient
        idSleepCase
        type
        instruction
        date
        diagnose1
        diagnose2
        diagnose3
        nr_repetitions
        sleep_latency
        sorem_n

        sleep_case {
          id
          record_id
        }
        repetitions {
          id
          start_datetime
          sleep_latency
          rem_latency
          kss_pre
          kss_post
        }
        patient {
          id
          PatientID
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadSleepTestTypes = (file, handleProgress = null) => {
  return MutationUploadSleepCSV(
    file,
    MUTATION_UPLOAD_SLEEP_TEST_TYPES,
    handleProgress
  );
};
