import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ButtonGroup } from '@mui/material';
import {
  ButtonFileUpload,
  ButtonDownloadType,
  ButtonGroupLoading,
  ButtonReloadDelete,
} from 'components';
import { withStyles } from 'tss-react/mui';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ServerErrorsString, GetData, IsAdministrator } from 'helpers';
import { MutationUploadProjectCenterCSV } from 'graphql/Projects/utils_mutation_center';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});
class ProjectSettingCenterToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      total_download: 0,
    };
  }
  handleProgress = event => {
    const { loading, total_download } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = total_download;
    }
    if (this.props.handleProgress) {
      this.props.handleProgress({ loaded, total }, loading);
    }
  };
  handleSelectFile = event => {
    const { uploading } = this.state;
    console.log({ uploading });
    if (uploading) return;
    const { files } = event.target;
    console.log({ files });
    if (!files.length) {
      return;
    }
    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadProjectCenterCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, project_centers } = data.uploadNewProjectCenters;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddNewProjectCenters(project_centers);
            if (errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, selected, user } = this.props;
    const isAdmin = IsAdministrator(user);
    let width = 150;
    if (selected.length && isAdmin) {
      width = 220;
    }
    let ComponentUpload = null;
    let ComponentSave = null;
    if (isAdmin) {
      ComponentUpload = (
        <ButtonFileUpload
          handleSelectFile={this.handleSelectFile}
          uploading={this.state.uploading}
        />
      );
      ComponentSave = (
        <ButtonGroupLoading
          disabled={this.props.disabled_save}
          loading={this.props.saving_center}
          onClick={this.props.handleUpdateProjectCenter}
        />
      );
    }
    let disabled_edit = !selected.length;
    let disabled_folder = true;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between">
        <Grid item md={3} sm={4.5} sx={{ minWidth: width }} xs={5}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            {/* <ButtonGroupLoading
            icon={<ReplayIcon />}
            loading={this.props.loading_centers}
            onClick={this.props.handleReLoadCenter}
          /> */}
            {ComponentSave}
            <ButtonDownloadType
              idProject={this.props.idProject}
              type="project_center"
            />
            {ComponentUpload}
            <ButtonGroupLoading
              badge
              disabled={disabled_folder}
              icon={
                <CreateNewFolderIcon
                  style={{ color: disabled_folder ? 'gray' : 'blue' }}
                />
              }
              loading={this.props.deleting_centers}
              onClick={this.props.handleDeleteSelectedCenters}
              // total={selected.length}
            />
            <ButtonGroupLoading
              badge
              batch_style={{ batch_color: '#00e676' }}
              color_batch="primary"
              disabled={disabled_edit}
              icon={
                <BorderColorIcon
                  style={{ color: disabled_edit ? 'gray' : 'green' }}
                />
              }
              onClick={this.props.handleOpenEdit}
              total={selected.length}
            />
          </ButtonGroup>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={1}>
          <ButtonReloadDelete
            handleClearSelected={this.props.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelectedCenters}
            handleReloadTable={this.props.handleReLoadCenter}
            loading={this.props.deleting_centers}
            loading_table={this.props.loading_centers}
            numSelected={selected.length}
            small
          />
        </Grid>
      </Grid>
    );
  }
}

ProjectSettingCenterToolBar.propTypes = {
  selected: PropTypes.array,
};
ProjectSettingCenterToolBar.defaultProps = {
  selected: [],
  handleReLoadCenter: () => {},
  handleUpdateProjectCenter: () => {},
  handleDeleteSelectedCenters: () => {},
  deleting_centers: false,
  disabled_save: false,
  saving_center: false,
  downloading: false,
  loading_centers: false,
};
export default withStyles(ProjectSettingCenterToolBar, useStyles);
