import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

import { AccountDetails, AccountProfile } from './components';
import { calc_image_size } from 'helpers/utils_images';

const useStyles = theme => ({
  root: {
    padding: theme.spacing(4),
  },
});

class Profile extends React.Component {
  render() {
    if (!this.props.isActive) return null;
    const { classes, user, size_avatar } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <AccountProfile size_avatar={size_avatar} user={user} />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <AccountDetails size_avatar={size_avatar} user={user} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let size_avatar = 0;
  const { user } = state.info_user;
  if (user) {
    size_avatar = calc_image_size(user.avatar);
  }
  //
  return {
    size_avatar,
    user,
  };
};

export default connect(mapStateToProps)(withStyles(Profile, useStyles));
