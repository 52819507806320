import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, CircularProgress, Autocomplete } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData } from 'helpers';
import { MembersAtProject } from 'graphql/Projects/utils_project';

import { useStyles } from '../Styles';
import { inputProps } from 'common';

class InputProjectMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      name: '',
      loading: false,
      members: [],
      open: false,
      length: 0,
      empty: false,
    };
  }
  componentDidMount() {
    this.runSearchUser('');
  }
  componentDidUpdate() {
    const { length, name } = this.state;
    if (length !== name.length) {
      this.runSearchUser(name);
    }
  }
  handleUserChanged = selected => {
    if (!selected || typeof selected === 'undefined') return;
    this.props.onChange(selected);
  };
  handleUserNameChanged = name => {
    this.setState({ name });
    this.runSearchUser(name);
  };
  runSearchUser = name => {
    const { loading } = this.state;
    const { idProject } = this.props;
    if (loading) return;
    this.setState({
      loading: true,
      length: name.length,
      members: [],
      empty: false,
    });
    (async () => {
      MembersAtProject(idProject, name, 0, 5)
        .then(res => {
          const data = GetData(res);
          let { ok, errors, members, total } = data.projectsMembers;
          if (ok) {
            let empty = false;
            if (!members.length) {
              members.push({ FullName: 'Not found' });
              total = 1;
              empty = true;
            }
            this.setState({
              members: members.map(x => {
                return { id: x.user.id, FullName: x.user.FullName };
              }),
              loading: false,
              total,
              empty,
            });
          } else {
            throw errors;
          }
        })
        .catch(err => {
          console.log('runSearchUser-catch-error', err);
          this.setState({ members: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddUser = () => {
    this.setState({ name: '', members: [], length: 0 });
  };
  render() {
    // const { classes, style } = this.props;
    const { members, open, loading, name } = this.state;
    const { textfield, enable_input_props, value } = this.props;
    const height = '40px';
    let style_input_props = null;
    if (enable_input_props) {
      style_input_props = {
        height,
        margin: 0,
        padding: 0,
        borderWidth: 0,
        border: 0,
      };
    }
    let inputValue = name;
    if (value && inputValue === '') {
      const { FullName } = value;
      inputValue = FullName;
    }
    // console.log({ inputValue, value });
    return (
      <Autocomplete
        disabled={this.props.disabled}
        getOptionLabel={option => {
          if (typeof option === 'undefined') return '';
          const { FullName } = option;
          if (typeof FullName === 'undefined') return '';
          return FullName;
        }}
        id="search-members-input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) =>
          option.FullName === value.FullName
        }
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          this.handleUserChanged(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleUserNameChanged(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={members}
        renderInput={params => (
          <TextField
            {...params}
            {...textfield}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { style_input_props },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="user"
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.props.handleAddMembers();
              }
            }}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
      />
    );
  }
}

InputProjectMembers.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enable_input_props: PropTypes.bool,
  filter: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  textfield: PropTypes.object,
  value: PropTypes.object,
  variant: PropTypes.string,
};
InputProjectMembers.defaultProps = {
  handleAddMembers: () => '',
  onChange: () => console.log('onChange'),
  textfield: {},
  placeholder: 'Search Members...',
  variant: 'standard',
  enable_input_props: true,
  multiple: true,
  value: null,
  filter: null,
  disabled: false,
  idProject: -1,
};

export default withStyles(InputProjectMembers, useStyles);
