import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
// import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import { cleanCopySeries } from 'redux-store/actions/file_folder_manager';

const useStyles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
});

class ButtonCopySeries extends Component {
  handleCleanCopyCut = () => {
    this.props.cleanCopySeries();
  };

  render() {
    const { copy_series, classes, size } = this.props;
    if (!size) {
      return null;
    }
    const defaultProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      color: 'error',
      children: <NotInterestedIcon />,
    };
    return (
      <Tooltip title="Clean Selected Series">
        <IconButton
          aria-controls="clean-selected-series"
          className={classes.root}
          onClick={this.handleCleanCopyCut}
          size="large">
          <Badge badgeContent={copy_series.length} {...defaultProps} />
        </IconButton>
      </Tooltip>
    );
  }
}

ButtonCopySeries.propTypes = {
  classes: PropTypes.object,
  copy_series: PropTypes.array,
};
const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { cleanCopySeries })(
  withStyles(ButtonCopySeries, useStyles)
);
