import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { TableRowDoubleCell } from 'components/Tables/components';

class TabContainerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { container } = this.props;
    if (!container || container === undefined) return null;
    return (
      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <caption>
            A basic information of the image of this docker container
          </caption>
          <TableBody>
            <TableRowDoubleCell name="Image" value={container.idImage} />
            <TableRowDoubleCell
              name="Port configuration"
              value={container.Ports.map(
                x => `${x.PrivatePort}/${x.Type} - ${x.PublicPort}`
              ).join('\n')}
            />
            <TableRowDoubleCell
              name="ENTRYPOINT"
              value={container.Names.join(',')}
            />
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TabContainerDetails.propTypes = {
  classes: PropTypes.object,
};

export default TabContainerDetails;
