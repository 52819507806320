const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 16,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 2,
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 10,
    },
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true,
  },
  country: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  unit: {
    presence: { allowEmpty: false, message: ' id is required' },
  },
  'unit.name': {
    presence: { allowEmpty: false, message: ' name is required' },
  },
};

export default schema;
