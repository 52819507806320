import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDeleteIcon,
  DivGrow,
  IconButtonLoad,
  SearchText,
} from 'components';
import { MutationDeleteUserSessions, QueryUserSessions } from 'graphql/Users';
import { ServerErrorsString, GetData } from 'helpers';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Replay as ReloadIcon } from '@mui/icons-material';
import { TabSessionsMap, TabSessionsTable } from './components';
import { TabContext, TabPanel } from '@mui/lab';

class TabSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 0,
      limit: 100,
      sessions: [],
      selected: [],
      idUser: -1,
      empty: false,
      deleting: false,
      view: 'table',
      name: '',
      init: false,
    };
    this.holder = {
      removeMarker: null,
    };
  }
  componentDidUpdate() {
    if (!this.state.init) this.loadSessions();
  }
  loadSessions = (newName, newPage, newLimit) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    let { page, limit, name } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    let country = null;
    let city = null;
    if (name) {
      if (name.startsWith('country:'))
        country = name.replace('country:', '').trim();
      if (name.startsWith('city:')) city = name.replace('city:', '').trim();
    }
    this.setState({ loading: true });
    (async () => {
      QueryUserSessions({ name, city, country }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, sessions, errors, total } = data.userSessions;
          if (ok) {
            this.setState({
              page,
              limit,
              loading: false,
              sessions,
              total,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSessions = () => {
    const { deleting, selected, sessions, total } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteUserSessions(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSessions;
          if (ok) {
            if (this.holder.removeMarker) {
              this.holder.removeMarker(ids);
            }
            this.setState({
              deleting: false,
              sessions: sessions.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
              total: total - ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    const { sessions, loading, deleting, selected, view } = this.state;
    const { height } = this.props;
    let ComponetDelete = null;
    if (selected.length) {
      ComponetDelete = (
        <Grid item>
          <ButtonDeleteIcon
            loading={deleting}
            onClick={this.handleDeleteSessions}
            selected={selected}
          />
        </Grid>
      );
    }
    let internal_height = height - 275;
    let ComponentSearch = null;
    if (view === 'table') {
      ComponentSearch = (
        <Grid item xs>
          <SearchText
            handleSearchText={code => this.loadSessions(code)}
            id="id-search-session-name"
            loading={loading}
            onChange={name => this.setState({ name })}
            placeholder="Search by name or country..."
          />
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          p={1}
          spacing={1}
          style={{ height: 75 }}>
          <Grid item>
            <ToggleButtonGroup
              aria-label="Platform"
              color="primary"
              exclusive
              onChange={(e, view) => this.setState({ view })}
              value={view}>
              <ToggleButton value="table">User Sessions</ToggleButton>
              <ToggleButton value="map">Google Map</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {ComponentSearch}
          <DivGrow />
          {ComponetDelete}
          <Grid item>
            <IconButtonLoad
              handleClick={() => this.loadSessions()}
              icon={<ReloadIcon />}
              loading={loading}
              small
              tooltip="Reload Table"
            />
          </Grid>
        </Grid>
        <TabContext value={view}>
          <TabPanel style={{ padding: 0 }} value="table">
            <TabSessionsTable
              handleSelected={selected => this.setState({ selected })}
              height={internal_height}
              limit={this.state.limit}
              loading={loading}
              loadSessions={this.loadSessions}
              page={this.state.page}
              selected={selected}
              sessions={sessions}
              total={this.state.total}
            />
          </TabPanel>
          <TabPanel style={{ padding: 0 }} value="map">
            <TabSessionsMap
              height={internal_height}
              holder={this.holder}
              sessions={sessions}
            />
          </TabPanel>
        </TabContext>
      </React.Fragment>
    );
  }
}

TabSessions.propTypes = {
  classes: PropTypes.object,
};

export default TabSessions;
