import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { inputProps } from 'common';

class InputText extends Component {
  render() {
    const { loading, text, options, open } = this.props;
    const height = '40px';
    return (
      <Autocomplete
        getOptionLabel={option => {
          if (option) return option.name;
          return '';
        }}
        id="search-text-input"
        inputValue={text}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          this.props.handleSelectOptionChanged(newValue);
        }}
        onClose={() => {
          this.props.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.props.handleTextChanged(newInputValue);
        }}
        onOpen={() => {
          this.props.handleOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                height,
                margin: 0,
                padding: 0,
                borderWidth: 0,
                border: 0,
              },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="user"
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleEnterPressed();
              }
            }}
            placeholder={this.props.placeholder}
            variant="standard"
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
      />
    );
  }
}

InputText.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
};
InputText.defaultProps = {
  loading: false,
  text: '',
  options: [],
  open: false,
  multiple: false,
  placeholder: '',
};
export default InputText;
