import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const SendDirectMessageQuery = gql`
  mutation($text: String!, $receiverId: Int!) {
    createDirectMessage(text: $text, receiverId: $receiverId)
  }
`;

export const SendDirectMessage = (text, receiverId) => {
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SendDirectMessageQuery),
    variables: {
      text,
      receiverId,
    },
  });
};

export const SUBSCRIPTION_NEW_CHAT_MESSAGE = gql`
  subscription {
    newChatMessage {
      id
      text
      read
      receiverId
      sender {
        id
        FullName
        username
        avatar
      }
      createdAt
    }
  }
`;
export const SUBSCRIPTION_NEW_CHATS = gql`
  subscription {
    newChatAdded {
      User {
        id
        FullName
        username
        avatar
      }
      NewMessages
      LastMessage {
        id
        text
        # sender
        read
        createdAt
      }
      createdAt
    }
  }
`;
export const SUBSCRIPTION_NEW_DIRECT_MESSAGES = gql`
  subscription($receiverId: Int!) {
    newDirectMessage(receiverId: $receiverId) {
      id
      text
      read
      receiverId
      sender {
        id
        FullName
        username
        avatar
      }
      createdAt
    }
  }
`;

export const QUERY_DIRECT_MESSAGES = gql`
  query($receiverId: Int!, $page: Int, $limit: Int) {
    directMessages(receiverId: $receiverId, page: $page, limit: $limit) {
      id
      text
      read
      receiverId
      sender {
        id
        FullName
        username
        avatar
      }
      createdAt
    }
  }
`;

export const QueryDirectMessages = (receiverId, page, limit) => {
  console.log('QueryDirectMessages', { receiverId });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DIRECT_MESSAGES),
    variables: {
      receiverId,
      page,
      limit,
    },
  });
};

export const MessageReadQuery = gql`
  mutation($receiverId: Int!) {
    chatRead(receiverId: $receiverId) {
      ok
      receiverId
      errors {
        path
        message
      }
    }
  }
`;

export const MessageRead = receiverId => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MessageReadQuery),
    variables: {
      receiverId,
    },
  });
};
