import * as m4 from '../utils/m4';
import { Vector3D } from 'helpers/Utils3D';

const Orthographic = c => {
  const width = c.initWidth / c.zoom;
  const height = c.initHeight / c.zoom;
  var projectionMatrix = m4.orthographic(
    -width / 2,
    width / 2,
    -height / 2,
    height / 2,
    -10000,
    10000
  );
  return projectionMatrix;
};
const Perspective = c => {
  // Compute the projection matrix
  var aspect = c.initWidth / c.initHeight;
  var zNear = 1;
  var zFar = 2000;
  return m4.perspective(c.fieldOfViewRadians, aspect, zNear, zFar);
};
const radToDeg = r => {
  return (r * 180) / Math.PI;
};

const degToRad = d => {
  return (d * Math.PI) / 180;
};
class CameraWebGL {
  gl = null;
  initWidth = null;
  initHeight = null;
  camPosition = [0, 0, 0];
  camOrigin = [0, 1, 0];
  up = [0, 1, 0];
  upOrigin = [0, 1, 0];
  fieldOfViewRadians = degToRad(60);
  lookAt = [0, 0, 0];
  lookAtOrigin = [0, 0, 0];
  zoom = 1;
  vectorU = new Vector3D(1, 0, 0);
  vectorV = new Vector3D(0, 1, 0);
  constructor(type, gl) {
    this.gl = gl;
    this.type = type;
    this.initWidth = gl.canvas.clientWidth;
    this.initHeight = gl.canvas.clientHeight;
    this.reset();
    if (type === 'orthographic') {
      /* empty */
    } else if (type === 'perspective') {
      this.fieldOfViewRadians = degToRad(60);
    }
  }
  projectionMatrix() {
    this.initWidth = this.gl.canvas.clientWidth;
    this.initHeight = this.gl.canvas.clientHeight;
    // m4.orthographic(left, right, bottom, top, near, far)$
    if (this.type === 'orthographic') {
      return Orthographic(this);
    } else if (this.type === 'perspective') {
      return Perspective(this);
    }
    return null;
  }
  position() {
    return new Vector3D(
      this.camPosition[0],
      this.camPosition[1],
      this.camPosition[2]
    );
  }
  cameraMatrix(center = null, lookAt = null, vectorUp = null) {
    // Compute the position of the first F
    if (center && lookAt && vectorUp) {
      this.camOrigin[0] = center[0];
      this.camOrigin[1] = center[1];
      this.camOrigin[2] = center[2];
      this.camPosition[0] = center[0];
      this.camPosition[1] = center[1];
      this.camPosition[2] = center[2];
      this.lookAt[0] = lookAt[0];
      this.lookAt[1] = lookAt[1];
      this.lookAt[2] = lookAt[2];
      this.lookAtOrigin[0] = lookAt[0];
      this.lookAtOrigin[1] = lookAt[1];
      this.lookAtOrigin[2] = lookAt[2];
      this.up[0] = vectorUp[0];
      this.up[1] = vectorUp[1];
      this.up[2] = vectorUp[2];
      this.upOrigin[0] = vectorUp[0];
      this.upOrigin[1] = vectorUp[1];
      this.upOrigin[2] = vectorUp[2];
      // console.log('set', {
      //   camPosition: this.camPosition,
      //   lookAt: this.lookAt,
      //   up: this.up,
      // });
    } else {
      // console.log('ren', {
      //   camPosition: this.camPosition,
      //   lookAt: this.lookAt,
      //   up: this.up,
      // });
      // this.lookAt = [this.camPosition[0], this.camPosition[1], -200];
    }
    // Get the camera's position from the matrix we computed
    // const cameraPosition = [
    //   cameraMatrix[12],
    //   cameraMatrix[13],
    //   cameraMatrix[14],
    // ];
    // Compute the camera's matrix using look at.
    const cameraMatrix = m4.lookAt(this.camPosition, this.lookAt, this.up);
    return cameraMatrix;
  }
  viewMatrix() {
    const cameraMatrix = this.cameraMatrix();
    // Make a view matrix from the camera matrix
    var viewMatrix = m4.inverse(cameraMatrix);
    return viewMatrix;
  }
  setOrigin(index, position) {
    this.camPosition[index] = position;
  }
  getOrigin(index) {
    if (index === undefined) return this.camPosition;
    if (index < 0 || index > 2) return this.camPosition;
    return this.camPosition[index];
  }
  setFieldOfView(value) {
    this.fieldOfViewRadians = degToRad(value);
  }
  getFieldOfView() {
    return radToDeg(this.fieldOfViewRadians);
  }
  zoomIn() {
    this.zoom *= 1.08;
    console.log('zoomIn: ', this.zoom);
  }
  zoomOut() {
    this.zoom /= 1.08;
    console.log('zoomOut: ', this.zoom);
  }
  pan(deltaX, deltaY) {
    let position = new Vector3D(
      this.camPosition[0],
      this.camPosition[1],
      this.camPosition[2]
    );
    const U = this.vectorU.multiply(deltaX / this.zoom);
    const V = this.vectorV.multiply(deltaY / this.zoom);
    let newPosition = position.add(U.add(V));
    this.camPosition[0] = newPosition.x;
    this.camPosition[1] = newPosition.y;
    this.camPosition[2] = newPosition.z;

    position = new Vector3D(this.lookAt[0], this.lookAt[1], this.lookAt[2]);
    newPosition = position.add(U.add(V));
    this.lookAt[0] = newPosition.x;
    this.lookAt[1] = newPosition.y;
    this.lookAt[2] = newPosition.z;

    this.deltaX = deltaX;
    this.deltaY = deltaY;
    // this.camPosition[0] = this.camPosition[0] + deltaX / this.zoom;
    // this.camPosition[1] = this.camPosition[1] + deltaY / this.zoom;
  }
  setVectorU(U) {
    this.vectorU = U;
  }
  setVectorV(V) {
    this.vectorV = V;
  }
  getLookAt = () => {
    return this.lookAt;
  };
  reset() {
    this.deltaX = 0;
    this.deltaY = 0;
    this.camPosition = [
      this.camOrigin[0],
      this.camOrigin[1],
      this.camOrigin[2],
    ];
    this.lookAt[0] = this.lookAtOrigin[0];
    this.lookAt[1] = this.lookAtOrigin[1];
    this.lookAt[2] = this.lookAtOrigin[2];
    this.up = [this.upOrigin[0], this.upOrigin[1], this.upOrigin[2]];
    this.fieldOfViewRadians = degToRad(60);
    this.zoom = 1;
  }
  update() {
    // Compute the projection matrix
    var projectionMatrix = this.projectionMatrix();

    // Use matrix math to compute a position on a circle where
    // the camera is
    // var cameraMatrix = this.cameraMatrix();
    var viewMatrix = this.viewMatrix();
    // Compute a view projection matrix
    var viewProjectionMatrix = m4.multiply(projectionMatrix, viewMatrix);
    // var viewProjectionMatrix = m4.multiply(projectionMatrix, cameraMatrix);

    return viewProjectionMatrix;
  }
}

export default CameraWebGL;
