import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ImageList, ImageListItem, Skeleton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { getInitials, GetThumbnailFile } from 'helpers';
import { CircularLoading } from 'components';

const useStyles = theme => ({
  gridList: {
    width: '100%',
    height: '140',
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    padingBottom: 0,
    padingRight: 0,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0,
    marginRight: 0,
  },
});

class StudyThumbnails extends Component {
  render() {
    const { Series, loading } = this.props;
    let height = '75px';
    // let width = '50%';
    let cols = 2;
    let rowHeight = 70;
    let padding = '1px';
    if (Series === undefined) {
      let ComponentLoad = null;
      if (loading) {
        ComponentLoad = (
          <Grid item>
            <CircularLoading />
          </Grid>
        );
      } else {
        ComponentLoad = (
          <Box sx={{ width: '100%', padding: 0, margin: 0 }}>
            <Skeleton sx={{ height: '200px' }} />
          </Box>
        );
      }
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          sx={{ height: '200px', padding }}>
          {ComponentLoad}
        </Grid>
      );
    } else if (!Series.length) {
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          sx={{ height: '150px' }}>
          <Grid item sx={{ fontWeight: 1000 }}>
            Empty or not allow
          </Grid>
        </Grid>
      );
    }
    const TotalSeries = Series.length;
    if (TotalSeries === 1) {
      // width = '100%';
      height = '150px';
      cols = 1;
      rowHeight = 144;
      padding = 0;
    } else if (TotalSeries === 2) {
      // width = '50%';
      height = '150px';
      rowHeight = 144;
      padding = 0;
    }
    // console.log({ TotalSeries, width, cols, rowHeight })
    return (
      <ImageList
        cols={cols}
        rowHeight={rowHeight}
        style={{ padding: 10, height: '174px' }}>
        {Series.map((serie, i) => {
          if (i > 3) return null;
          const { idSeries, SeriesDescription } = serie;
          if (Series.length === 3 && i === 2) {
            // width = '100%';
          }
          let thumbnail = GetThumbnailFile('file.dcm');
          if (serie.Thumbnail && serie.Thumbnail !== '') {
            thumbnail = `data:image/jpeg;base64,${serie.Thumbnail}`;
          } else if (serie && serie.Modality === 'SR') {
            thumbnail = '/images/icons/icon_sr.png';
          } else if (serie && serie.Modality === 'PR') {
            thumbnail = '/images/icons/icon_pr.png';
          }
          return (
            <ImageListItem
              // cols={cols}
              key={idSeries}
              style={{ height, padding: 0, margin: 0 }}>
              <img
                alt={getInitials(SeriesDescription)}
                src={thumbnail}
                style={{ height, margin: 0, pading: 0 }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    );
  }
}

StudyThumbnails.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(StudyThumbnails, useStyles);
