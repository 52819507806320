import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import { format } from 'date-fns';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import clsx from 'clsx';
import { FormatTime, getInitials, IsToday } from 'helpers';
import useStyles from './Styles';

class Message extends React.Component {
  render() {
    const {
      left,
      classes,
      message: { sender, createdAt, text },
    } = this.props;
    let { avatar, username } = sender;
    if (!avatar) {
      avatar = '/images/avatars/avatar_0.png';
    }
    let date = '';
    if (IsToday(createdAt)) {
      date = FormatTime(createdAt, 'HH:mm');
    } else {
      date = FormatTime(createdAt, 'MMM Do, YYYY - h:mm a');
    }
    return (
      <div
        className={clsx({
          [classes.root_right]: true,
          [classes.root_left]: left,
        })}>
        <Grid className={classes.root} container>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={getInitials(username)} src={avatar} />
            </ListItemAvatar>
            <Grid container direction="column" justifyContent="space-between">
              <ListItemText
                classes={{
                  primary: clsx({
                    [classes.header_black]: left,
                    [classes.header_white]: !left,
                  }),
                  secondary: clsx({
                    [classes.message_black]: left,
                    [classes.message_white]: !left,
                  }),
                }}
                className={clsx({
                  [classes.text_content_right]: !left,
                  [classes.text_content_left]: left,
                })}
                primary={username ? username : 'Unkown'}
                secondary={text}
              />
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-end">
                <Typography
                  color="textPrimary"
                  component="span"
                  variant="body2">
                  <div>{date}</div>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
      </div>
    );
  }
}

Message.defaultProps = {
  left: false,
};
Message.propTypes = {
  classes: PropTypes.object.isRequired,
  left: PropTypes.bool,
};
export default withStyles(Message, useStyles);
