import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DialogGetDownloadLink,
  DialogGetSharedLink,
  FileDetails,
  FolderDetails,
  SeriesDetails,
} from './components';
import { DeleteFoldersMutation, RemoveSharedMutation } from 'graphql/Folders';
import { GetData } from 'helpers';
import { DialogDeleteWarning, SnackMessage } from 'components';
import { removeSharedFoldersIds } from 'redux-store/actions/notifications';
import { IsValid } from 'helpers';

class ExplorerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      delete_object: [],
      deleting: false,
      loading: false,
      error: '',
      open_link: false,
      open_download_link: false,
      link: null,
      download_link: null,
      total: 0,
    };
  }

  handleError = error => {
    this.setState({ error });
  };
  handleCloseSnack = () => {
    this.setState({ error: '' });
  };
  handleOpenDelete = delete_object => {
    console.log({ delete_object });
    this.setState({ delete_object: [delete_object], open_delete: true });
  };
  handleDeleteFolder = (idFolder, shared) => {
    if (shared) {
      this.handleShared([idFolder]);
    } else {
      this.handleDelete([idFolder]);
    }
  };
  handleDelete = (folders_ids, content_ids, delete_series = false) => {
    const { deleting } = this.state;
    if (deleting) {
      return;
    }
    this.setState({ deleting: true });

    (async () => {
      DeleteFoldersMutation(folders_ids, content_ids, delete_series)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, warnings } = data.deleteFolders;
          if (ok) {
            this.props.handleDrawerClose();
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings,
              folders_ids,
              content_ids,
            });
            this.setState({ deleting: false, open_delete: false });
            this.props.removeSharedFoldersIds(folders_ids);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ deleting: false });
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };
  handleShared = folders_ids => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });

    (async () => {
      RemoveSharedMutation(folders_ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeSharedFolders;
          if (ok) {
            this.props.handleDrawerClose();
            this.props.handleDeleteFolders({
              ok,
              errors: null,
              warnings: null,
              folders_ids,
              content_ids: null,
            });
            this.setState({ loading: false, open_delete: false });
            this.props.removeSharedFoldersIds(folders_ids);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ loading: false });
          // this.state.loading = false;
          this.props.handleDeleteFolders({
            ok: false,
            errors,
            warnings: null,
            folders_ids: null,
            content_ids: null,
          });
        });
    })();
  };
  handleGetSharedLink = link => {
    const { open_link } = this.state;
    if (link && !open_link) {
      this.setState({ open_link: true, link });
    } else {
      this.setState({ open_link: false, link: null });
    }
  };
  handleGetDownloadLink = download_link => {
    const { open_download_link } = this.state;
    console.log({ open_download_link, download_link });
    if (download_link && !open_download_link) {
      this.setState({ open_download_link: true, download_link });
    } else {
      this.setState({ open_download_link: false, download_link: null });
    }
  };
  handleProgress = (event, loading, total_size) => {
    if (typeof event === 'undefined' || !event) {
      return;
    }
    let { loaded, total } = event;
    // console.log({ loaded, total, total_size });
    if (total_size !== undefined && total_size > this.state.total) {
      this.setState({ total: total_size });
      total = total_size;
    }
    if (total === 0 || isNaN(total) || total === undefined) {
      if (loaded > this.state.total) {
        total = loaded * 4;
        this.setState({ total });
      } else {
        total = this.state.total;
      }
    }
    let progress = Math.floor((loaded * 100) / total);
    if (loading !== undefined) {
      if (!loading) {
        progress = 0;
      }
    }
    this.props.handleProgress(progress);
  };
  handleRunDeleteContentOrFolder = (shared, open_delete, delete_series) => {
    const { delete_object } = this.state;
    if (!delete_object.length) {
      return;
    }
    const { idFolderContent, idFolder, Series, File } = delete_object[0];
    if (IsValid(idFolder)) {
      this.handleDeleteFolder(idFolder, shared);
    } else if (File || Series) {
      this.handleDelete([], [idFolderContent], delete_series);
    }
  };
  render() {
    const {
      open,
      handleDrawerClose,
      item,
      holder,
      user,
      isMobile,
    } = this.props;
    const { error, loading, open_link, open_download_link } = this.state;
    let is_folder = false;
    let is_series = false;
    let is_file = false;
    let link = null;
    let download_link = null;
    let owner = false;
    if (item) {
      const { idFolder, idFolderContent, Owner } = item;
      link = item.link;
      download_link = item.download_link;
      if (idFolder && (idFolderContent === undefined || !idFolderContent)) {
        is_folder = true;
      } else if (idFolderContent) {
        const { Series, File } = item;
        is_file = File ? true : false;
        is_series = Series ? true : false;
      } else if (item.Series) {
        is_file = false;
        is_series = true;
      }
      if (Owner && Owner.FullName) {
        owner = user.id === Owner.id;
      }
    }
    return (
      <React.Fragment>
        {is_folder ? (
          <FolderDetails
            base={this.props.base}
            deleting={this.state.deleting}
            folder={item}
            handleAddError={this.props.handleAddError}
            handleDeleteFolder={this.handleDeleteFolder}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleDrawerClose={handleDrawerClose}
            handleError={this.handleError}
            handleGetDownloadLink={this.handleGetDownloadLink}
            handleGetSharedLink={this.handleGetSharedLink}
            handleOpenDelete={this.handleOpenDelete}
            handleProgress={this.handleProgress}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            handleSubFolderNameChanged={this.props.handleSubFolderNameChanged}
            height={this.props.height}
            holder={holder}
            isMobile={isMobile}
            loading={loading}
            open={open}
            owner={owner}
            user={user}
          />
        ) : is_series ? (
          <SeriesDetails
            base={this.props.base}
            folder_content={item}
            handleAddError={this.props.handleAddError}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleDrawerClose={handleDrawerClose}
            handleError={this.handleError}
            handleGetDownloadLink={this.handleGetDownloadLink}
            handleGetSharedLink={this.handleGetSharedLink}
            handleItemChanged={this.props.handleItemChanged}
            handleOpenDelete={this.handleOpenDelete}
            handleProgress={this.handleProgress}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            height={this.props.height}
            isMobile={isMobile}
            open={open}
            owner={owner}
            Series={item.Series}
            user={user}
          />
        ) : is_file ? (
          <FileDetails
            base={this.props.base}
            deleting={this.state.deleting}
            folder_content={item}
            handleAddError={error => {
              console.log(error);
              this.props.handleAddError(error);
            }}
            handleDelete={this.handleDelete}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleDrawerClose={handleDrawerClose}
            handleError={this.handleError}
            handleGetDownloadLink={this.handleGetDownloadLink}
            handleGetSharedLink={this.handleGetSharedLink}
            handleItemChanged={this.props.handleItemChanged}
            handleOpenDelete={this.handleOpenDelete}
            handleProgress={this.handleProgress}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            handleUpdateFolderContent={this.props.handleUpdateFolderContent}
            height={this.props.height}
            isMobile={isMobile}
            loading={loading}
            open={open}
            owner={owner}
            user={user}
          />
        ) : null}
        <SnackMessage
          handleClose={this.handleCloseSnack}
          message_text={error !== '' ? error : 'Unknown warning'}
          open={error && error !== '' ? true : false}
          type="error"
        />
        <DialogGetSharedLink
          handleClose={this.handleGetSharedLink}
          handleSharedLinkChanged={this.props.handleSharedLinkChanged}
          item={item}
          link={link}
          open={open_link}
        />
        <DialogGetDownloadLink
          download_link={download_link}
          handleClose={this.handleGetDownloadLink}
          handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
          item={item}
          open={open_download_link}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleRunDeleteContentOrFolder}
          loading={this.state.deleting}
          open={this.state.open_delete}
          selected={this.state.delete_object}
          shared={!owner}
        />
      </React.Fragment>
    );
  }
}

ExplorerDetails.defaultProps = {
  item: { idFolder: -1, name: 'unknown', Rights: [] },
  handleProgress: () => '',
  handleUpdateFolderContent: () => '',
};

ExplorerDetails.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(mapStateToProps, { removeSharedFoldersIds })(
  ExplorerDetails
);
