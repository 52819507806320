import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper } from '@mui/material';
import moment from 'moment';
import { QuerySurvey } from 'graphql/Surveys';
import {
  createSurvey,
  GetSteps,
  handleValueChanged,
  handleSave,
} from 'helpers/Surveys';
import { CardHeaderCreate } from '../CreateNewSurvey/components';
import { SurveyStepper, ViewerSurvey,defaultSurvey } from 'components';
import { FormatTime, ServerErrorsString, GetData } from 'helpers';

class EditSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      empty: false,
      ...defaultSurvey,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  componentDidUpdate() {
    this.handleQuery();
  }
  handleQuery = () => {
    const { edit_survey, currentPatient } = this.props;
    const { loading, currentSurvey, empty } = this.state;
    if (loading || currentSurvey || empty) return;
    if (currentSurvey && edit_survey.id === currentSurvey.id) return;
    this.setState({ loading: true });
    (async () => {
      QuerySurvey(edit_survey.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, survey } = data.surveyRecorded;
          if (ok) {
            const reply = createSurvey(
              survey,
              edit_survey.id,
              currentPatient.id,
              this.handleSave,
              this.handleBackToOverview,
              this.handleValueChanged
            );
            this.setState({
              loading: false,
              empty: false,
              updatedAt: survey.updatedAt,
              survey_json: reply.survey_json,
              currentSurvey: reply.survey,
              buttonSave: reply.buttonSave,
              buttonCancel: reply.buttonCancel,
              value_changed: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSave = (sender, complete) => {
    handleSave(sender, complete, this);
  };
  handleBackToOverview = () => {
    this.setState({
      ...defaultSurvey,
    });
    this.props.handleClose();
  };
  handlePageChanged = survey => {
    const { currentPageNo, isFirstPage, isLastPage } = survey;
    this.setState({ currentPageNo, isFirstPage, isLastPage });
  };
  handleValueChanged = (sender, options) => {
    handleValueChanged(sender, options, this);
  };
  render() {
    const { currentSurvey, currentPageNo, value_changed } = this.state;
    let updatedAt = this.state.updatedAt;
    if (updatedAt === undefined || !updatedAt) updatedAt = moment().valueOf();
    if (currentSurvey) {
      currentSurvey.currentPageNo = currentPageNo;
    }
    const json = GetSteps('surveyNeuro');
    let steps = [];
    let pages = [];
    if (json && json.pages) {
      pages = json.pages;
      steps = json.pages.map((x, index) => index);
    }
    return (
      <Paper>
        <CardHeaderCreate
          description={
            'Updated: ' + FormatTime(updatedAt, 'MMM Do, YYYY (h:mm a)')
          }
          handleClose={this.handleBackToOverview}
          title="Edit Survey"
        />
        <Divider />
        <SurveyStepper
          activeStep={this.state.currentPageNo}
          buttonCancel={this.state.buttonCancel}
          buttonSave={this.state.buttonSave}
          currentSurvey={currentSurvey}
          handleBack={() => this.setState({ currentPageNo: currentPageNo - 1 })}
          handleNext={() => this.setState({ currentPageNo: currentPageNo + 1 })}
          handleSaved={() => this.setState({ value_changed: [] })}
          isChanged={Boolean(value_changed.length)}
          isFirstPage={this.state.isFirstPage}
          isLastPage={this.state.isLastPage}
          loading={this.state.loading}
          maxSteps={steps.length}
          pages={pages}>
          <ViewerSurvey
            currentSurvey={currentSurvey}
            delta={175}
            handlePageChanged={this.handlePageChanged}
            height={this.props.height}
            loading={this.state.loading}
          />
        </SurveyStepper>
      </Paper>
    );
  }
}

EditSurvey.propTypes = {
  handleUpdateSurvey: PropTypes.func,
};
EditSurvey.defaultProps = {
  handleUpdateSurvey: () => '',
};

export default EditSurvey;
