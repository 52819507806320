import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { useStylesShow } from '../../../TopMenu';

import { MetaRow, FileMetaHeader, FileMetaSearch } from './components';
import { GenerateDictionary } from 'helpers/json';
import { IsInvalid } from 'helpers';

class FileMetaData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  getMetadata = () => {
    const { File } = this.props;
    let metadata = [];
    if (File && File.MetaData) {
      let meta = null;
      try {
        meta = File.MetaData.split('\n').join('');
        if (meta.startsWith('"[')) {
          meta = '{"tags":' + meta.slice(1, -1).replace(/\\"/g, '"') + '}';
          // const lastFive = meta.slice(-15);
          // console.log({ lastFive });
          // meta = innerString.replace()
          // console.log({ innerString });
          // const parsedArray = eval('(' + innerString + ')');
          // console.log({ parsedArray });
        }
        // console.log({ meta });
        const json_tags = JSON.parse(meta);
        // console.log({ json_tags });
        if (Array.isArray(json_tags)) {
          metadata = json_tags;
        } else if (Object.keys(json_tags).length) {
          metadata = json_tags.tags;
        }
        // console.log('invalid:', {
        //   idFile: File.idFile,
        //   Meta: File.MetaData,
        //   metadata,
        // });
      } catch (error) {
        console.log('getMetadata', { error });
        metadata = GenerateDictionary(meta);
      }
    }
    // console.log({ metadata });
    let { search } = this.state;
    search = search.toLowerCase();
    if (IsInvalid(metadata)) {
      console.log('invalid:', { idFile: File.idFile, Meta: File.MetaData });
      return [];
    }
    if (metadata.length === 0 || search === '') return metadata;
    return metadata.filter(meta => {
      const { tag } = meta;
      let Name = '';
      let Value = '';
      let Tag = '';
      if (IsInvalid(tag)) {
        Name = meta.Name;
        Value = meta.Value;
        if (Array.isArray(meta.Value)) {
          Value = meta.Value.join('\\\\');
        }
        Value = `${Value}`;
        Tag = meta.Tag;
      } else {
        Name = tag.Name;
        Value = tag.Value;
        Tag = tag.Tag;
      }
      let index = Name.toLowerCase().indexOf(search);
      if (index !== -1) return true;
      if (typeof Value !== 'undefined') {
        // console.log({ Value });
        index = Value.toLowerCase().indexOf(search);
        if (index !== -1) return true;
      }
      if (typeof Tag !== 'undefined' && Tag) {
        index = Tag.toLowerCase().indexOf(search);
      }
      if (index !== -1) return true;
      return false;
    });
  };
  handleTextChange = event => {
    this.setState({ search: event.target.value });
  };

  render() {
    const { classes, theme, handleDrawerClose, open } = this.props;
    const metadata = this.getMetadata();
    return (
      <Drawer
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        open={open}
        variant="persistent">
        <Divider />
        <FileMetaHeader
          classes={classes}
          File={this.props.File}
          handleDrawerClose={handleDrawerClose}
          theme={theme}
        />
        <Divider />
        <FileMetaSearch onChange={this.handleTextChange} />
        <Divider />
        <TableContainer component={Paper}>
          <Table
            aria-label="a dense table"
            className={classes.table}
            size="small"
            stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Value</TableCell>
                <TableCell align="center">Hexadecimal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metadata.map((meta, index) => {
                let tag = meta.tag;
                if (IsInvalid(tag)) {
                  tag = {
                    ...meta,
                  };
                }
                return (
                  <MetaRow key={`meta-row-${index}-${tag.Tag}`} {...tag} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Drawer>
    );
  }
}

FileMetaData.propTypes = {
  classes: PropTypes.object,
};
const ContainerFileMetaData = props => {
  const theme = useTheme();
  return <FileMetaData theme={theme} {...props} />;
};
export default withStyles(ContainerFileMetaData, useStylesShow);
