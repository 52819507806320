import React, { Component } from 'react';
import { CardHeader } from '@mui/material';

class CardHeaderCustom extends Component {
  render() {
    const { ...res } = this.props;
    return (
      <CardHeader
        {...res}
        subheaderTypographyProps={{
          style: {
            color: '#263238',
            fontSize: '14px',
            // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            fontWeight: '400',
            lineHeight: '21px',
            letterSpacing: '-0.05px',
          },
        }}
        titleTypographyProps={{
          style: {
            color: '#263238',
            fontSize: '16px',
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.05px',
          },
        }}
        //   title={title ? title : 'Notifica/tions'}
      />
    );
  }
}

export default CardHeaderCustom;
