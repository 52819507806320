import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, InputAdornment, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import AccountCircle from '@mui/icons-material/AccountCircle';

import UnitDescription from '../UnitDescription';
import { UnitDateTimes } from './components';
import { DialogContent, DialogTitle } from 'components';
import { inputProps } from 'common';

const useStyles = theme => ({
  root: {
    // display: 'flex',
    // // overflow: 'auto',
    // width: 'auto',
    // minWidth: '100% !important',
  },
  container: {
    // display: 'flex',
    // // margin: theme.spacing(3),
    // flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(-1, 0, 2, 0),
  },
  datetime: {
    padding: 0,
    margin: theme.spacing(0, 0, 4, 0),
  },
});

class DialogUnit extends React.Component {
  render() {
    const { classes, open, handleCloseDialog, unit } = this.props;
    if (!unit) return null;
    const { description, institution, createdAt, updatedAt } = unit;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        className={classes.root}
        onClose={handleCloseDialog}
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          {unit.name}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                className={classes.margin}
                fullWidth
                id="administrator"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                  ...inputProps,
                }}
                label="Administrator"
                readOnly
                value={unit.Admin.FullName}
              />
            </Grid>
            <Grid item>
              <UnitDateTimes
                classes={classes}
                createdAt={createdAt}
                updatedAt={updatedAt}
              />
            </Grid>
            <Grid item>
              <UnitDescription
                description={description}
                institution={institution}
                unit={unit}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

DialogUnit.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(DialogUnit, useStyles);
