import { grey } from '@mui/material/colors';

const useStyles = theme => ({
  table: {
    height: '100%',
    // width: '100%',
    padding: theme.spacing(2),
  },
  tableWrapper: {
    width: '100%',
    maxHeight: 405,
    overflow: 'auto',
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'yellow',
      },
    },
  },
  empty: {},
  download: {
    padding: 0,
    margin: 0,
  },
});

export default useStyles;
