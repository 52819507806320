import { IsInvalid } from 'helpers';
import path from 'common/path';
export const list_to_open = [
  'text/plain',
  'application/json',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'text/markdown',
  'text/x-markdown',
  'application/pdf',
  'text/csv',
];

export const FileExtension = (filepath, to_lower = true) => {
  if (filepath === undefined || !filepath) return '';
  if (filepath.toLowerCase().endsWith('nii.gz')) {
    return 'nii.gz';
  }
  let ext = path.extname(filepath);
  if (to_lower) {
    ext = ext.toLowerCase();
  }
  if (ext.startsWith('.')) {
    ext = ext.substring(1);
  }
  if (ext === undefined || !ext) return '';
  //   var ext = path.extname(filename || '').split('.');
  // console.log('ext: ', ext);
  // return ext[ext.length - 1];
  return ext;
};
const text_file = [
  'JS',
  'C',
  'CPP',
  'TXT',
  'YML',
  'H',
  'PY',
  'CMAKE',
  'RB',
  'M',
];
export const IsTextFile = filename => {
  const ext = FileExtension(filename).toUpperCase();
  if (ext === undefined || !ext || ext === '') return false;
  if (text_file.indexOf(ext) === -1) return false;
  return true;
};

export const GetLanguage = filename => {
  let exp = FileExtension(filename).toUpperCase();
  if (filename === 'CMakeLists.txt') exp = 'CMAKE';
  // console.log({ exp, filename });
  switch (exp) {
    case 'CPP':
    case 'C':
    case 'H':
      return 'cpp';
    case 'PY':
      return 'python';
    case 'JAVA':
      return 'java';
    case 'RB':
      return 'ruby';
    case 'CMAKE':
      return 'cmake';
    case 'M':
      return 'matlab';
    case 'YML':
      return 'yaml';
    case 'JSON':
      return 'json';
    default:
      return 'javascript';
  }
};
export const IsFileReadable = file_object => {
  // console.log({ file_object });
  if (IsInvalid(file_object)) return false;
  const { mimetype, original_name } = file_object;
  if (IsInvalid(original_name) || original_name === '') return false;
  if (mimetype.startsWith('image/')) return true;
  if (list_to_open.indexOf(mimetype) !== -1) return true;
  if (IsTextFile(original_name)) return true;
  return false;
};
export const IsExcelFile = file => {
  if (IsInvalid(file)) return false;
  const { mimetype } = file;
  if (IsInvalid(mimetype)) return false;
  return (
    mimetype ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    mimetype === 'application/vnd.ms-excel'
  );
};
export const IsMarkDownFile = file => {
  if (IsInvalid(file)) return false;
  const { mimetype } = file;
  if (IsInvalid(mimetype)) return false;
  return mimetype === 'text/markdown' || mimetype === 'text/x-markdown';
};
