import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import {
  GetData,
  ServerErrorsString,
  ValidateEmail,
  ValidatePassword,
} from 'helpers';
import { ButtonLoading, SnackMessage } from 'components';
import { getStepContent, getSteps } from './components';
import {
  CheckCodeResetPassword,
  FinishResetPassword,
  InitResetPassword,
} from 'graphql/Users';

const useStyles = theme => ({
  root: {
    maxWidth: 500,
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  margin_double: {
    margin: theme.spacing(3, 1, 3, 1),
  },
  margin_tripple: {
    margin: theme.spacing(6, 1, 1, 1),
  },
});

class CardForgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      error: '',
      email: '',
      code: '',
      password: '',
      confirm: '',
      loading: false,
    };
    this.holder = {
      getEmail: null,
      getCode: null,
      getPassword: null,
      getConfirm: null,
      IsSamePassword: null,
    };
  }

  setActiveStep = activeStep => {
    this.setState({ activeStep });
  };
  handleNext = () => {
    const { activeStep } = this.state;
    switch (activeStep + 1) {
      case 1: {
        const email = this.holder.getEmail();
        const error = ValidateEmail(email);
        if (error) {
          this.setState({ error });
          return;
        }
        this.CheckEmail(email);
        return;
      }
      case 2: {
        const code = this.holder.getCode();
        if (code === '') {
          this.setState({ error: 'The code is empty' });
          return;
        }
        this.CheckCode(code);
        return;
      }
      case 3: {
        const password = this.holder.getPassword();
        const confirm = this.holder.getConfirm();
        const error_passwords = ValidatePassword(password, confirm);
        if (error_passwords) {
          this.setState({ error: error_passwords });
          return;
        } else {
          this.FinishPassword(password, confirm);
        }
        break;
      }
      default:
        break;
    }
  };
  CheckEmail = email => {
    const { loading, activeStep } = this.state;
    if (loading) return;
    this.setState({ loading: true, email });
    (async () => {
      InitResetPassword(email)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.InitResetPassword;
          if (ok) {
            this.setState({ loading: false });
            this.setActiveStep(activeStep + 1);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  CheckCode = code => {
    const { loading, activeStep, email } = this.state;
    if (loading) return;
    this.setState({ loading: true, code });
    (async () => {
      CheckCodeResetPassword(email, code)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.CheckCodeResetPassword;
          if (ok) {
            this.setState({ loading: false });
            this.setActiveStep(activeStep + 1);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  FinishPassword = (password, confirm) => {
    const { loading, activeStep, email, code } = this.state;
    if (loading) return;
    this.setState({ loading: true, password, confirm });
    (async () => {
      FinishResetPassword(email, password, code)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.FinishResetPassword;
          if (ok) {
            this.setState({ loading: false });
            this.setActiveStep(activeStep + 1);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleBack = () => {
    const { activeStep } = this.state;
    this.setActiveStep(activeStep - 1);
  };

  handleReset = () => {
    this.setActiveStep(0);
  };
  handleError = error => {
    this.setState({ error });
  };
  handleCloseSnak = () => {
    this.setState({ error: '' });
  };

  render() {
    const { classes, handleLoginForgot } = this.props;
    const { activeStep, error, loading } = this.state;
    const steps = getSteps();
    return (
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center">
        <Grid className={classes.root} container>
          <div className={classes.root}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ backgroundColor: 'transparent' }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {getStepContent(
                      index,
                      classes,
                      this.handleError,
                      this.holder,
                      this.state
                    )}
                    <div className={classes.actionsContainer}>
                      <Grid container>
                        <Button
                          className={classes.button}
                          disabled={activeStep === 0}
                          onClick={this.handleBack}>
                          Back
                        </Button>
                        <ButtonLoading
                          color="primary"
                          handleClick={this.handleNext}
                          loading={loading}
                          name={
                            activeStep === steps.length - 1
                              ? 'Reset Password'
                              : 'Next'
                          }
                          variant="contained"
                        />
                      </Grid>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <div className={classes.resetContainer}>
                <Typography>
                  Password reset is completed - you can login again.
                </Typography>
                <Button color="primary" onClick={this.handleReset}>
                  Reset Again
                </Button>
              </div>
            )}
          </div>
          <Grid className={classes.margin_tripple} container>
            <Typography variant="body1">
              {'Do you want to login again to the platform?'}
            </Typography>
            <div style={{ flexGrow: 1, marginLeft: 20 }} />
            <Link
              component="button"
              onClick={() => {
                handleLoginForgot(true);
              }}
              variant="body2">
              SingIn
            </Link>
          </Grid>
        </Grid>
        <SnackMessage
          handleClose={this.handleCloseSnak}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Grid>
    );
  }
}

CardForgot.propTypes = {
  classes: PropTypes.object,
  handleView: PropTypes.func,
};

export default withStyles(CardForgot, useStyles);
