import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_DIRECT_CHATS = gql`
  query($page: Int, $limit: Int) {
    directChats(page: $page, limit: $limit) {
      chats {
        User {
          id
          FullName
          username
          avatar
        }
        NewMessages
        LastMessage {
          id
          text
          read
          createdAt
        }
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryRunChatsList = (page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_DIRECT_CHATS),
    variables: {
      page,
      limit,
    },
  });
};
