import React from 'react';
import PropTypes from 'prop-types';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { CircularProgress, Tooltip } from '@mui/material';
import { green } from '@mui/material/colors';
import {
  DownloadItemsAtKey,
  GenerateDownload,
  MutationDownloadFinished,
} from 'graphql/Download';
import {
  ServerErrorsString,
  GetData,
  GetNameFromHeader,
  GetResponseError,
} from 'helpers';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 8,
    left: 6,
    zIndex: 1,
  },
});
class ButtonDownloadToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }
  handleContentDownload = () => {
    const { downloading } = this.state;
    const { folder_content } = this.props;
    if (downloading || folder_content === undefined || !folder_content) return;
    const { idFolderContent } = folder_content;
    if (idFolderContent === undefined || !idFolderContent) return;
    this.setState({ downloading: true });
    this.props.handleDownloadTriggered(true);
    const input = [{ idFolderContent }];
    const { download_type } = this.props;
    if (download_type) {
      input[0].type = download_type;
    }
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(input);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          this.props.handleDownloadTriggered(false);
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        this.props.handleDownloadTriggered(false);
        return;
      }
      DownloadItemsAtKey(download_key)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.props.handleDownloadTriggered(false);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ downloading: false });
          this.props.handleDownloadTriggered(false);
        });
    })();
  };

  render() {
    const { classes, IconDownload, tooltip, onClick } = this.props;
    let { downloading } = this.state;
    let handleClick = this.handleContentDownload;
    if (onClick) {
      handleClick = onClick;
    }
    if (this.props.downloading) {
      downloading = this.props.downloading;
    }
    return (
      <Tooltip title={tooltip}>
        <div
          onClick={handleClick}
          role="button"
          style={{ margin: 0, padding: 0 }}>
          {IconDownload}
          {downloading && (
            <CircularProgress className={classes.fabProgress} size={34} />
          )}
        </div>
      </Tooltip>
    );
  }
}

ButtonDownloadToggle.propTypes = {
  classes: PropTypes.object,
};
ButtonDownloadToggle.defaultProps = {
  IconDownload: <QuestionMarkIcon />,
  tooltip: '',
  handleDownloadTriggered: () => '',
  onClick: null,
};

export default withStyles(ButtonDownloadToggle, useStyles);
