import React from 'react';
import PropTypes from 'prop-types';
import GroupBasics from '../GroupBasics';

class GroupKeywords extends React.Component {
  constructor(props) {
    super(props);
    this.holder = {
      addMap: null,
      removeMaps: null,
    };
    if (props.holder) {
      props.holder.addKeyword = keyword => {
        if (this.holder.addMap) this.holder.addMap(keyword);
      };
      props.holder.removeKeyword = () => {
        if (this.holder.removeMaps) this.holder.removeMaps();
      };
    }
  }
  render() {
    return (
      <GroupBasics
        handleOpenAddDialog={this.props.handleOpenAddDialog}
        handleOpenRemoveDialog={this.props.handleOpenRemoveDialog}
        holder={this.holder}
        map_name="Keyword Designer"
        title="Keywords"
      />
    );
  }
}

GroupKeywords.propTypes = {
  classes: PropTypes.object,
};

export default GroupKeywords;
