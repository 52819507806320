import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { grey } from '@mui/material/colors';

function FilesViewTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disable_checkbox,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  let ComponentCheckBox = null;
  let padding = null;
  if (!disable_checkbox) {
    padding = 'checkbox';
    ComponentCheckBox = (
      <Checkbox
        checked={numSelected > 0 && numSelected === rowCount}
        indeterminate={numSelected > 0 && numSelected < rowCount}
        inputProps={{ 'aria-label': 'select all desserts' }}
        onChange={onSelectAllClick}
      />
    );
  }
  return (
    <TableHead>
      <TableRow style={{ backgroundColor: grey[200] }}>
        <TableCell
          padding={padding}
          style={{ margin: 0, padding: 0, width: 15 }}>
          {ComponentCheckBox}
        </TableCell>
        {headCells.map(headCell => {
          const { align } = headCell;
          let align_type = headCell.numeric ? 'right' : 'center';
          if (align) align_type = align;
          return (
            <TableCell
              align={align_type}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {/* {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null} */}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

FilesViewTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default FilesViewTableHead;
