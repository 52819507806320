import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_CLINICALS = gql`
  query($idUser: LongLong!) {
    userClinicalDataType(idUser: $idUser) {
      ok
      total
      errors {
        path
        message
      }
      clinical_types {
        idUser
        idClinicalDataType
        hasAdminAccess
        updatedAt
        createdAt
      }

      clinical_type {
        id
        code
        description
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryUsersClinicals = idUser => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_CLINICALS),
    variables: {
      idUser,
    },
  });
};
const MUTATION_ADD_USER_CLINICAL = gql`
  mutation($inputs: [InputClinicalDataUser!]!) {
    addUserClinicalData(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAddUsersClinicals = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_USER_CLINICAL),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_USER_CLINICAL = gql`
  mutation($inputs: [InputClinicalDataUser!]!) {
    removeUserClinicalData(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveUsersClinicals = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_USER_CLINICAL),
    variables: {
      inputs,
    },
  });
};

const MUTATION_ADMIN_ACCESS_TO_STUDY = gql`
  mutation($inputs: [InputStudyAdmin!]!) {
    updateAdminAccessToStudy(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAdminAccessToStudy = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADMIN_ACCESS_TO_STUDY),
    variables: {
      inputs,
    },
  });
};
