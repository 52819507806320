import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
// import clsx from 'clsx';
import { Card, CardContent, Divider } from '@mui/material';

import {
  TableTeamMembers,
  DialogChangeTeamAvatar,
  CardEditHeader,
} from 'components';
import { useStyles } from './Styles';
import { TeamViewToolBar } from './components';
import { FormatTime, ServerErrors, GetData } from 'helpers';
import { MutationUpdateTeam } from 'graphql/Teams/utils_team_muations';
import { ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { QueryAllTeamsAndMembers } from 'graphql/Teams';
const default_state = {
  loading: false,
  open_avatar: false,
  saved: true,
  members: [],
  team: null,
  teams: [],
};
class TeamView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...default_state,
    };
    this.holder = {
      reloadTable: null,
    };
  }
  componentDidMount() {
    const { teamId, selected } = this.props;
    const { teams } = this.state;
    if (IsInvalid(teamId)) return;
    if (teamId && !selected.length && !teams.length) {
      this.handleLoadTeam(teamId);
    }
  }
  componentDidUpdate() {
    const { teamId, selected } = this.props;
    const { teams } = this.state;
    if (IsInvalid(teamId)) return;
    if (teamId && !selected.length && !teams.length) {
      this.handleLoadTeam(teamId);
    }
  }
  handleLoadTeam = teamId => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true, error: '' });
    (async () => {
      QueryAllTeamsAndMembers(null, [teamId])
        .then(res => {
          const data = GetData(res);
          const { ok, teams, errors } = data.allTeams;
          if (ok) {
            this.setState({ teams, loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddMembers = added => {
    let { members, team } = this.state;
    const errors = [];
    for (let i = 0; i < added.length; i++) {
      const { id, FullName } = added[i];
      const index = members.map(x => x.id).indexOf(id);
      if (index === -1) {
        members.push(added[i]);
      } else {
        errors.push(FullName);
      }
    }
    let error = '';
    if (errors.length) {
      error = `- ${errors.join('\n- ')}`;
      console.log({ error });
    }

    team.members = members;
    this.setState({ members, team });
    this.props.handleTeamModified(team);
  };
  handleAddTeamMembers = new_members => {
    const { team } = this.state;
    if (!team) {
      return;
    }
    let { members } = this.state;
    for (let i = 0; i < new_members.length; i++) {
      const { id } = new_members[i];
      const index = members.map(x => x.id).indexOf(id);
      if (index === -1) {
        members.push(new_members[i]);
      }
    }
    team.members = members;
    this.setState({ members, team });
    this.props.handleTeamModified(team);
  };
  handleDeleteTeamMembers = ListToDelete => {
    const { team } = this.state;
    const members = [];
    for (let i = 0; i < this.state.members.length; i++) {
      const { id } = this.state.members[i];
      const index = ListToDelete.indexOf(id);
      if (index === -1) {
        members.push(this.state.members[i]);
      }
    }
    team.members = members;
    this.setState({ members, team });
    this.props.handleTeamModified(team);
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  handleOpenCloseAvatar = status => {
    this.setState({ open_avatar: status ? status : false });
  };
  handleSelectAvatar = index => {
    const { updating, team } = this.state;
    if (updating) return;
    // console.log('handleSelectAvatar', { index });
    const avatar = '/images/teams/team_' + index.toString() + '.png';
    team.avatar = avatar;
    this.setState({ updating: true, open_avatar: false, saved: false });
    (async () => {
      MutationUpdateTeam(team)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateTeam;
          if (ok) {
            this.setState({ updating: false, team });
            this.props.handleTeamModified(team);
            this.props.updateCurrentTeamAvatar(avatar);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log('MutationCreateTeam-catch-error', errors);
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrors(errors));
        });
    })();
  };
  handleSaveTeam = () => {};
  handleTeamChannel = () => {
    this.props.history.push('/team_chats');
  };
  handleUpdatedTeam = update_team => {
    const { team } = this.state;
    this.setState({ team: { ...team, ...update_team } });
    this.props.handleTeamModified(update_team);
  };
  handleClose = () => {
    this.setState({ ...default_state });
    this.props.handleOpenTeamView();
  };
  render() {
    const { classes, height } = this.props;
    let { idProject } = this.props;
    let selected = this.props.selected;
    const { open_avatar, members, team, teams } = this.state;
    let { loading } = this.state;
    if (this.props.loading) loading = this.props.loading;
    let createdAt = '';
    if (team) {
      createdAt = team.createdAt;
    }
    if (!selected.length) selected = teams;
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardEditHeader
            description={'Created: ' + FormatTime(createdAt, 'MMM Do, YYYY')}
            handleClose={this.handleClose}
            title="Edit Seleted Teams"
          />
          <Divider />
          <CardContent>
            <TeamViewToolBar
              classes={classes}
              enable_edit={this.props.enable_edit}
              handleAddError={this.props.handleAddError}
              handleAddMembers={this.handleAddTeamMembers}
              handleOpenCloseAvatar={this.handleOpenCloseAvatar}
              handleTeamMembers={(team, members) =>
                this.setState({ team, members })
              }
              handleUpdatedTeam={this.handleUpdatedTeam}
              holder={this.holder}
              idProject={idProject}
              selected={selected}
              team={this.state.team}
            />
            <TableTeamMembers
              handleAddTeamMembers={this.handleAddTeamMembers}
              handleDeleteSelected={this.handleDeleteTeamMembers}
              handleReloadTable={() => this.holder.reloadTable()}
              height={height ? height - 270 : undefined}
              loading={loading}
              members={members}
              team={this.state.team}
            />
          </CardContent>
          <DialogChangeTeamAvatar
            handleCancel={this.handleOpenCloseAvatar}
            handleCloseDialog={this.handleOpenCloseAvatar}
            handleSelectAvatar={this.handleSelectAvatar}
            key="call-change-avatar-modal"
            open={open_avatar}
            team={team}
          />
        </Card>
      </React.Fragment>
    );
  }
}

TeamView.propTypes = {
  history: PropTypes.object,
  team: PropTypes.object,
};
TeamView.defaultProps = {
  backto: '/management/patients',
  handleTeamModified: () => console.log('handleTeamModified - TeamView'),
  enable_edit: false,
  handleAddError: () => '',
  updateCurrentTeamAvatar: () => '',
};
export default withStyles(withRouter(TeamView), useStyles);
