import React, { Component } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import { Grid, IconButton, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { DivGrow, ButtonSettingMenu } from 'components';
import {
  ButtonSwithView,
  ToolBarImages,
  useStyles,
  defaultFilter,
  DatabaseSearchFilter,
} from './components';
import { getParams, IsInvalid } from 'helpers';
import { setSeriesSequence } from 'redux-store/actions';

class DatabaseToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Study: null,
      description: '',
      search_type: null,
      filters: {
        ...defaultFilter,
      },
    };
    const { holder } = props;
    if (holder) {
      holder.setStudy = Study => {
        this.setState({ Study });
      };
      holder.getDescription = () => {
        return this.state.description;
      };
    }
    this.holder = {
      resetFilters: null,
    };
  }

  getIdStudy = () => {
    const params = getParams(this.props);
    if (!params) return null;
    const { level_1, level_2 } = params;
    const idStudy = parseInt(level_2, 10);
    if (IsInvalid(level_1) || isNaN(idStudy) || level_1 !== 'study') {
      return null;
    }
    return idStudy;
  };
  IsPatientView = () => {
    const { view_database } = this.props;
    if (view_database === 'patients') return true;
    return false;
  };
  IsStudySeriesView = () => {
    const idStudy = this.getIdStudy();
    if (idStudy) return true;
    return false;
  };
  handleBack = () => {
    this.props.history.goBack();
  };
  handleTextChange = event => {
    this.setState({ description: event.target.value });
  };
  handleSequenceChanged = (type, sequence) => {
    this.props.setSeriesSequence(sequence);
  };
  handleUpdateFilters = filters => {
    this.setState({ filters });
    this.props.handleUpdateFilters(filters);
  };
  handleSearchTypeChanged = search_type => {
    this.setState({ search_type });
    this.props.handleUpdateSearchTypes(search_type);
  };
  handleDatabaseViewChanged = type => {
    this.setState({ search_type: null });
    this.props.handleUpdateSearchTypes(null);
    this.props.handleChange(type);
    if (this.holder.resetFilters) {
      this.holder.resetFilters();
    }
  };
  getPatientIDs = () => {
    const { ids_patients } = this.props;
    const params = getParams(this.props);
    if (params) {
      const { level_2 } = params;
      if (level_2 === 'group') {
        return ids_patients;
      } else if (/^\d+$/.test(level_2)) {
        const idPatient = parseInt(level_2, 10);
        return [idPatient];
      }
    }
    return ids_patients;
  };
  render() {
    const { className, classes, view_database, index_level } = this.props;
    const { search_type } = this.state;
    const isStudySeriesView = this.IsStudySeriesView();
    const isPatientView = this.IsPatientView();
    let { Study } = this.props;
    if (!Study) {
      Study = this.state.Study;
    }
    let type = 'series';
    if (view_database === 'study' && !isStudySeriesView) {
      type = 'study';
    } else if (isPatientView) {
      type = 'patients';
    }
    let placeholder = `Search by ${type} description...`;
    if (search_type) {
      placeholder = `Search by ${type} ${search_type.name}`;
    }
    let ComponentSearchSetting = null;
    if (!isStudySeriesView && !isPatientView) {
      ComponentSearchSetting = (
        <Grid item sx={{ minWidth: 30 }}>
          <ButtonSettingMenu
            handleSearchTypeChanged={this.handleSearchTypeChanged}
            search_type={search_type}
            view={view_database}
          />
        </Grid>
      );
    } else if (isPatientView) {
      ComponentSearchSetting = (
        <IconButton onClick={this.handleBack} size="large">
          <ArrowBackIcon className={classes.button} />
        </IconButton>
      );
    }
    let secondary_text = null;
    if (isStudySeriesView && Study) {
      const { StudyDescription } = Study;
      secondary_text = (
        <Typography
          style={{
            marginLeft: 15,
            fontWeight: 500,
          }}>{`Series at Study (${StudyDescription})`}</Typography>
      );
    } else if (isPatientView) {
      let text = 'Studies at patient selection';
      const numb_patients = this.getPatientIDs();
      if (numb_patients.length > 1) {
        text = `Studies of ${numb_patients.length} selected patients`;
      }
      secondary_text = (
        <Typography
          style={{
            marginLeft: 15,
            fontWeight: 500,
          }}>
          {text}
        </Typography>
      );
    }
    return (
      <div className={clsx(classes.root, className)}>
        <Grid container>
          <ToolBarImages
            handleReload={this.props.handleReload}
            loading={this.props.loading}
            user={this.props.user}
          />
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            style={{ marginTop: 0 }}>
            {ComponentSearchSetting}
            <DatabaseSearchFilter
              classes={classes}
              description={this.state.description}
              filters={this.state.filters}
              handleAddError={this.props.handleAddError}
              handleRunAtEnter={this.props.handleRunAtEnter}
              handleSearch={this.props.handleReload}
              handleTextChange={this.handleTextChange}
              holder={this.holder}
              index_level={index_level}
              isPatientView={isPatientView}
              isStudySeriesView={isStudySeriesView}
              placeholder={placeholder}
              setState={this.handleUpdateFilters}
              view_database={view_database}
            />
            <DivGrow />
            <ButtonSwithView
              classes={classes}
              handleBack={this.handleBack}
              handleChange={this.handleDatabaseViewChanged}
              idStudy={this.getIdStudy()}
              isStudySeriesView={isStudySeriesView}
              Study={Study}
              view={view_database}
            />
          </Grid>
        </Grid>
        {secondary_text}
      </div>
    );
  }
}

DatabaseToolBar.propTypes = {
  className: PropTypes.string,
  handleReload: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
};
DatabaseToolBar.defaultProps = {
  handleRunAtEnter: () => '',
  loading: false,
};

const mapStateToProps = state => {
  const { sequence, setting, ids_patients } = state.page_database;
  let index_level = 2;
  if (setting && setting.level) {
    index_level = setting.level.index_level;
  }
  return {
    sequence,
    index_level,
    ids_patients: ids_patients ? ids_patients : [],
  };
};

export default connect(mapStateToProps, { setSeriesSequence })(
  withStyles(withRouter(DatabaseToolBar), useStyles)
);
