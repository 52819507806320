import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import {
  ButtonLoad,
  DialogAnonymizationKeys,
  SearchAnonymizationRules,
  TextFieldCustom,
} from 'components';
import { IsInvalid } from 'helpers';
import { MutationAddAnonymizationTags } from 'graphql/Anonymization';
import { GetData, ServerErrorsString } from 'helpers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
class DialogCreateRuleTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null,
      creating: false,
      open_info: false,
    };
  }
  handleTextChanged = event => {
    let { tag } = this.state;
    const name = event.target.name;
    if (IsInvalid(tag)) {
      tag = {
        [name]: event.target.value,
      };
    } else {
      tag = {
        ...tag,
        [name]: event.target.value,
      };
    }
    this.setState({ tag });
  };
  handleAddAnonymizationRule = () => {
    const { creating, tag } = this.state;
    const { idProject, idUser } = this.props;
    if (creating || IsInvalid(tag)) return;
    this.setState({ creating: false });
    (async () => {
      MutationAddAnonymizationTags([{ idProject, idUser, ...tag }])
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules } = data.addAnonymizationTags;
          if (ok) {
            this.setState({ creating: false });
            this.props.handleCreatedTagRule(rules);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddTagRule = tag_rule => {
    console.log({ tag_rule });
    let { tag } = this.state;
    if (tag_rule) {
      tag = {
        tag: tag_rule.tag,
        tag_rule: tag_rule.tag_rule,
        tag_value: '',
      };
    } else {
      tag = null;
    }
    this.setState({ tag });
  };
  render() {
    const { tag } = this.state;
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          open={this.props.open}
          PaperComponent={PaperComponent}>
          <DialogTitle>Create DICOM Tag Rule</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter all the necessary information of the rule.
            </DialogContentText>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              pt={1}
              spacing={1}>
              <Grid item xs={6}>
                <SearchAnonymizationRules
                  handleAddTagRule={this.handleAddTagRule}
                  text_field
                />
              </Grid>
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="space-between"
                xs={6}>
                <Grid item xs={10}>
                  <TextFieldCustom
                    label="Tag Rule"
                    name="tag_rule"
                    onChange={this.handleTextChanged}
                    placeholder="Options D/Z/X/K/C/U"
                    value={tag ? tag.tag_rule : undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() => this.setState({ open_info: true })}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextFieldCustom
                  label="Tag Value"
                  name="tag_value"
                  onChange={this.handleTextChanged}
                  value={tag ? tag.tag_value : undefined}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              sx={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <ButtonLoad
              color="primary"
              disable_button={this.state.tag ? false : true}
              handleClick={this.handleAddAnonymizationRule}
              loading={this.state.creating}
              name="Create Rule"
              variant="text"
            />
          </DialogActions>
          <DialogAnonymizationKeys
            handleClose={() => this.setState({ open_info: false })}
            open={this.state.open_info}
          />
        </Dialog>
      </div>
    );
  }
}

DialogCreateRuleTag.propTypes = {
  size: PropTypes.string,
};

export default DialogCreateRuleTag;
