import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { FormatTime, getInitials } from 'helpers';

const useStyles = theme => ({
  root: {
    height: '60.5px',
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
    margin: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  expand: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(2),
    marginRight: 0,
  },
});

class ListProjectActivities extends Component {
  render() {
    const { data, classes } = this.props;
    let emptyRows = 6 - data.length;
    if (emptyRows < 0) {
      emptyRows = 0;
    }
    return (
      <List>
        {data.map((activity, i) => {
          let { icon, Project, User } = activity;
          //
          let FullName = 'Unknown';
          let project_name = 'Unknown';
          if (!icon) {
            icon = '/images/activity/unknown.png';
          }
          if (User) {
            FullName = User.FullName;
          }
          if (Project) {
            project_name = Project.name;
          }
          const date = `Updated ${FormatTime(activity.updatedAt, 'FromNow')}`;
          return (
            <ListItem
              className={classes.root}
              divider={i < data.length - 1}
              key={activity.id}>
              <Tooltip title={FullName}>
                <ListItemAvatar>
                  <img
                    alt={getInitials(FullName)}
                    className={classes.image}
                    src={`${icon}`}
                  />
                </ListItemAvatar>
              </Tooltip>
              <ListItemText
                primary={
                  <Grid
                    alignItems="flex-start"
                    container
                    direction="row"
                    justifyContent="space-between">
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {`Name: ${project_name}`}
                    </div>
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {activity.activity}
                    </div>
                  </Grid>
                }
                secondary={date}
                style={{ padding: 0, margin: 0 }}
              />
              <IconButton className={classes.button} edge="end" size="small">
                <MoreVertIcon />
              </IconButton>
            </ListItem>
          );
        })}
        {emptyRows > 0 && (
          <React.Fragment>
            <Divider />
            <ListItem
              style={{
                height: 60.5 * emptyRows,
                border: 0,
                width: '100%',
                margin: 0,
                padding: 0,
              }}
            />
          </React.Fragment>
        )}
      </List>
    );
  }
}

ListProjectActivities.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
};

export default withStyles(ListProjectActivities, useStyles);
