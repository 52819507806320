import { Vector3D, Line3D } from 'helpers/Utils3D';

const GetBuffer = pixelData => {
  const height = pixelData.length;
  let width = -1;
  if (height) {
    width = pixelData[0].length;
  }
  let i = 0;
  let buffer = new Int32Array(width * height);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      buffer[i] = pixelData[y][x];
      i++;
    }
  }
  return buffer;
};
class DicomSlice {
  WW = 255;
  WL = 123;
  orig_min = 0;
  orig_max = 0;
  maximum = 0;
  minimum = 0;
  height = 0;
  width = 0;
  buffer = null;
  filepath = '';
  InstanceNumber = 0;
  idSeries = 0;
  ImagePositionPatient = [0, 0, 0];
  ImageOrientationPatient = [1, 0, 0, 0, 1, 0];
  constructor(width, height, buffer) {
    this.width = width;
    this.height = height;
    this.buffer = buffer;
  }
  static fromPixelData(pixelData) {
    const height = pixelData.length;
    let width = -1;
    if (height) {
      width = pixelData[0].length;
    }
    let buffer = GetBuffer(pixelData);
    return new DicomSlice(width, height, buffer);
  }
  static fromData(data, idSeries = 0) {
    const { pixelData, width, height, minimum, maximum } = data;
    let buffer = GetBuffer(pixelData);
    let dicom_slice = new DicomSlice(width, height, buffer);
    dicom_slice.initMinMax(minimum, maximum);
    dicom_slice.filepath = data.filepath;
    dicom_slice.InstanceNumber = data.InstanceNumber;
    dicom_slice.idSeries = idSeries;
    dicom_slice.Modality = data.Modality;
    dicom_slice.SeriesDescription = data.SeriesDescription;
    dicom_slice.PatientName = data.PatientName;
    dicom_slice.StudyDate = data.StudyDate;
    dicom_slice.StudyTime = data.StudyTime;
    dicom_slice.SliceThickness = data.SliceThickness;
    dicom_slice.SpacingBetweenSlices = data.SpacingBetweenSlices;
    dicom_slice.ProtocolName = data.ProtocolName;
    dicom_slice.RepetitionTime = data.RepetitionTime;
    dicom_slice.EchoTime = data.EchoTime;
    dicom_slice.SliceLocation = data.SliceLocation;
    dicom_slice.MagneticFieldStrength = data.MagneticFieldStrength;
    dicom_slice.SeriesNumber = data.SeriesNumber;
    dicom_slice.NumberOfFrames = data.NumberOfFrames;
    dicom_slice.PixelSpacing = data.PixelSpacing;
    dicom_slice.ImageType = data.ImageType;
    dicom_slice.ImageOrientationPatient = data.ImageOrientationPatient;
    dicom_slice.ImagePositionPatient = data.ImagePositionPatient;
    dicom_slice.StudyDescription = data.StudyDescription;
    return dicom_slice;
  }
  reset() {
    this.setMinMax(this.orig_min, this.orig_max);
  }
  getContrast() {
    return {
      minimum: this.minimum,
      maximum: this.maximum,
      WL: this.WL,
      WW: this.WW,
    };
  }
  setContrast(contrast) {
    if (contrast === undefined || !contrast) return;
    this.WL = contrast.WL;
    this.WW = contrast.WW;
    this.minimum = contrast.minimum;
    this.maximum = contrast.maximum;
  }
  getSeriesID() {
    return this.idSeries;
  }
  getMinimum() {
    return this.minimum;
  }
  getMaximum() {
    return this.maximum;
  }
  setMinimun(minimum) {
    this.minimum = minimum;
    this.updateContrast();
  }
  setMaximum(maximum) {
    this.maximum = maximum;
    this.updateContrast();
  }
  initMinMax(minimum, maximum) {
    this.orig_min = minimum;
    this.orig_max = maximum;
    this.setMinMax(minimum, maximum);
  }
  setMinMax(minimum, maximum) {
    this.minimum = minimum;
    this.maximum = maximum;
    this.updateContrast();
  }
  updateContrast() {
    this.WW = this.maximum - this.minimum;
    this.WL = this.minimum + this.WW / 2;
  }
  getWorldWidth() {
    return this.width * this.PixelSpacing[0];
  }
  getWorldHeight() {
    return this.height * this.PixelSpacing[1];
  }
  getSliceRGBA() {
    const imageData = new Uint8Array(4 * this.width * this.height);
    const a = 255 / this.WW;
    const b = 255 / 2 - this.WL * a;
    console.log({ a, b });
    let width = this.width;
    let height = this.height;
    let i = 0;
    // for (let y = height - 1; y >= 0; y--) {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; ++x) {
        const value = this.buffer[y * width + x];
        let gray = value * a + b;
        gray = gray > 255 ? 255 : gray < 0 ? 0 : gray | 0;
        imageData[i * 4] = gray;
        imageData[i * 4 + 1] = gray;
        imageData[i * 4 + 2] = gray;
        imageData[i * 4 + 3] = 255;
        i++;
      }
    }
    return imageData;
  }
  IsSame(dicom_slice) {
    if (dicom_slice === undefined || !dicom_slice) return false;
    const { idSeries, width, height, InstanceNumber } = dicom_slice;
    if (this.idSeries !== idSeries) return false;
    if (this.height !== height) return false;
    if (this.width !== width) return false;
    if (this.InstanceNumber !== InstanceNumber) return false;
    return true;
  }
  getProjectPoint(dst) {
    const normal = this.getPlaneNormal();
    const center = this.getPlaneCenter();
    return center.add(normal.multiplyScalar(dst));
  }
  getVectorU() {
    const UV = this.ImageOrientationPatient;
    return new Vector3D(UV[0], UV[1], UV[2]).normalize();
  }
  getVectorV() {
    const UV = this.ImageOrientationPatient;
    return new Vector3D(UV[3], UV[4], UV[5]).normalize();
  }
  getPlaneNormal() {
    const UV = this.ImageOrientationPatient;
    const U = new Vector3D(UV[0], UV[1], UV[2]).normalize();
    const V = new Vector3D(UV[3], UV[4], UV[5]).normalize();
    return U.crossVectors(V).normalize();
  }
  getPlaneCenter() {
    const PS = this.getVertices();
    const diagonal = new Line3D(PS[0], PS[2]);
    return diagonal.middlePoint();
  }
  getVertices(delta = null) {
    const P = this.ImagePositionPatient;
    const UV = this.ImageOrientationPatient;
    const imgW = this.getWorldWidth();
    const imgH = this.getWorldHeight();
    const O = new Vector3D(P[0], P[1], P[2]);
    const U = new Vector3D(UV[0], UV[1], UV[2]).normalize();
    const V = new Vector3D(UV[3], UV[4], UV[5]).normalize();
    const A = O.add(V.clone().multiplyScalar(imgH));
    const D = O.add(U.clone().multiplyScalar(imgW));
    const B = D.add(V.clone().multiplyScalar(imgH));
    if (delta) {
      const n = this.getPlaneNormal().multiplyScalar(-delta);
      return [O.add(n), A.add(n), B.add(n), D.add(n)];
    } else {
      return [O, A, B, D];
    }
  }
  getPlaneVertices(delta = null) {
    const vertices = this.getVertices(delta);
    const T1 = vertices[0]
      .toArray()
      .concat(vertices[1].toArray())
      .concat(vertices[2].toArray());
    const T2 = vertices[0]
      .toArray()
      .concat(vertices[2].toArray())
      .concat(vertices[3].toArray());
    /*
      A --------- B
      |         / |
      |  T1   /   |
      |     /     |
      |   /   T2  |
      | /         |
      O --------- D      
      */
    return T1.concat(T2);
  }
  getPlaneLines() {
    const P = this.getVertices();
    // prettier-ignore
    const vertices = [
      P[0].x ,P[0].y, P[0].z,// O
      P[1].x ,P[1].y, P[1].z,//A
      P[1].x ,P[1].y, P[1].z,//A
      P[2].x ,P[2].y, P[2].z,//B
      P[2].x ,P[2].y, P[2].z,//B
      P[3].x ,P[3].y, P[3].z,//D
      P[3].x ,P[3].y, P[3].z,//D
      P[0].x ,P[0].y, P[0].z,//O
    ];
    return vertices;
  }
}
export { DicomSlice };
