import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ToolBarInputActions, TabsInputData } from './components';

class TabInputData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      process: null,
      tabValue: 0,
    };
    this.holder = {
      handleAction: null,
      handleDeleteSelected: null,
    };
  }
  handleProcessChanged = process => {
    this.setState({ process });
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ToolBarInputActions
            handleAction={(e, action) => this.holder.handleAction(action)}
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleAddWarning={this.props.handleAddWarning}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={() => this.holder.handleDeleteSelected()}
            onObjectChanged={this.handleProcessChanged}
            process={this.state.process}
            selected={this.state.selected}
            tabValue={this.state.tabValue}
            user={this.props.user}
          />
        </Grid>
        <Grid item sx={{ paddingTop: 1 }} xs={12}>
          <TabsInputData
            cleanSelected={() => this.setState({ selected: [] })}
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleAddWarning={this.props.handleAddWarning}
            handleChangedTabValue={tabValue => this.setState({ tabValue })}
            handleTabChanged={this.props.handleTabChanged}
            height={height}
            history={this.props.history}
            holder={this.holder}
            isDesktop={this.props.isDesktop}
            process={this.state.process}
            setSelected={selected => this.setState({ selected })}
            user={this.props.user}
          />
        </Grid>
      </Grid>
    );
  }
}

TabInputData.propTypes = {
  classes: PropTypes.object,
};
export default TabInputData;
