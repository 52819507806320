import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, Button, Box, Card, Link, Grid } from '@mui/material';
import { IsInvalid } from 'helpers';

class DatabaseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClickButton = route => {
    if (IsInvalid(route) || route === '') return;
    this.props.history.push(`/public_access/databases/${route}`);
  };
  render() {
    const { image, title, description, action, height } = this.props;
    let { buttons } = this.props;
    // console.log({ height, title });
    let ListButtons = [];
    if (IsInvalid(buttons)) {
      ListButtons = [''];
      if (action.type === 'external') {
        ListButtons.push(
          <Button
            color={action.color ? action.color : 'dark'}
            component={Link}
            href={action.route}
            rel="noreferrer"
            size="small"
            target="_blank"
            variant="gradient">
            {action.label}
          </Button>
        );
      } else {
        ListButtons.push(
          <Button
            color={action.color ? action.color : 'dark'}
            component={Link}
            size="small"
            to={action.route}
            variant="gradient">
            {action.label}
          </Button>
        );
      }
    } else {
      if (buttons.length === 1) ListButtons.push('');
      for (let i = 0; i < buttons.length; i++) {
        const { name, route } = buttons[i];
        ListButtons.push(
          <Button
            color={action.color ? action.color : 'dark'}
            onClick={() => this.handleClickButton(route)}
            size="small"
            variant="outlined">
            {name}
          </Button>
        );
      }
    }
    if (ListButtons.length === 2) ListButtons.push('');
    return (
      <Card>
        <Box mt={-1} p={3} textAlign="center">
          <Typography
            display="inline"
            fontWeight="regular"
            textTransform="capitalize"
            variant="h5">
            {title}
          </Typography>
        </Box>
        <Box
          borderRadius="lg"
          mt={1}
          mx={2}
          position="relative"
          textAlign="center">
          <Box
            alt={title}
            borderRadius="lg"
            component="img"
            height={height}
            position="relative"
            src={image}
            width={height}
            zIndex={1}
          />
          {/* <Box
            borderRadius="lg"
            left={0}
            position="absolute"
            shadow="md"
            style={{ height }}
            sx={{
              backgroundImage: `url(${image})`,
              transform: 'scale(0.94)',
              filter: 'blur(12px)',
              backgroundSize: 'cover',
            }}
            top={0}
            width="100%"
          /> */}
        </Box>
        <Box mt={-1} p={3} textAlign="center">
          <Box mb={3} mt={1}>
            <Typography color="text" component="p" variant="body2">
              {description}
            </Typography>
          </Box>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between">
            {ListButtons.map((button, index) => {
              return (
                <Grid item key={`grid-button-${index}`}>
                  {button}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Card>
    );
  }
}

DatabaseCard.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withRouter(DatabaseCard);
