import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import FolderIcon from '@mui/icons-material/Folder';
import { useStyles } from './Styles';

class SharedGalleryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
    };
  }

  handleMouseHover = () => {
    this.setState(prev_state => ({ isMouseOver: !prev_state.isMouseOver }));
  };

  render() {
    const {
      classes,
      name,
      Thumbnail,
      isSelected,
      isFolder,
      handleSelected,
      handleDoubleClick,
      handleClickItem,
      item,
    } = this.props;
    const { isMouseOver } = this.state;
    const selected = isMouseOver || isSelected;
    let title_short = name;
    if (name.length > 15) {
      title_short = name.substring(0, 12) + '...';
    }
    return (
      <Grid
        className={classes.root}
        container
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        wrap="nowrap">
        {selected ? (
          <Checkbox
            checked={isSelected}
            className={classes.checkbox}
            color="primary"
            onChange={() => handleSelected(item)}
            style={{ backgroundColor: 'white' }}
          />
        ) : null}
        <Tooltip title={name.length > 15 ? name : ''}>
          <Box
            className={clsx({
              [classes.box]: !selected,
              [classes.box_selected]: selected,
            })}
            onClick={() => handleClickItem(item)}>
            {isFolder ? (
              <FolderIcon
                className={classes.icon}
                onDoubleClick={() => handleDoubleClick(item)}
              />
            ) : (
              <div className={classes.icon}>
                <img
                  alt={title_short}
                  src={Thumbnail}
                  style={{ width: 100, height: 80 }}
                />
              </div>
            )}
            <Box className={classes.folder_name}>
              <Typography
                align="center"
                gutterBottom
                onDoubleClick={() => handleDoubleClick(item)}
                variant="body2">
                {title_short}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Grid>
    );
  }
}

SharedGalleryItem.propTypes = {
  classes: PropTypes.object,
};
SharedGalleryItem.defaultProps = {
  item: null,
  name: '',
  Thumbnail: '',
  isSelected: false,
  isFolder: false,
  handleSelected: () => '',
  handleDoubleClick: () => '',
  handleClickItem: () => '',
};
export default withStyles(SharedGalleryItem, useStyles);
