import path from 'common/path';

const extensions = {
  bin: '/images/icons/bin.png',
  bmp: '/images/icons/bmp.png',
  csv: '/images/icons/csv.png',
  dcm: '/images/icons/dcm.png',
  doc: '/images/icons/doc.png',
  docx: '/images/icons/doc.png',
  json: '/images/icons/json.png',
  log: '/images/icons/log.png',
  mha: '/images/icons/mha.png',
  mhd: '/images/icons/mhd.png',
  raw: '/images/icons/raw.png',
  sql: '/images/icons/sql.png',
  xls: '/images/icons/xls.png',
  xlsx: '/images/icons/xlsx.png',
  ppt: '/images/icons/ppt.png',
  pptx: '/images/icons/pptx.png',
  pdf: '/images/icons/pdf.png',
  zip: '/images/icons/zip.png',
  txt: '/images/icons/txt.png',
  mp4: '/images/icons/mp4.png',
  eeg: '/images/icons/eeg.png',
  edf: '/images/icons/eeg.png',
  nii: '/images/icons/nii.png',
};
export const GetThumbnailFile = filename => {
  if (typeof filename === 'undefined' || !filename) {
    return '/images/icons/bin.png';
  }
  var ext = filename.split('.').pop();
  if (ext === undefined || !ext) {
    return '/images/icons/bin.png';
  }
  const index = Object.keys(extensions).indexOf(ext.toLowerCase());
  if (index === -1) {
    if (filename.indexOf('nii.gz') === -1) return '/images/icons/bin.png';
    else return extensions['nii'];
  }
  return extensions[ext];
};

export const GetExtension = filename => {
  var ext = path.extname(filename || '').split('.');
  //
  ext = ext[ext.length - 1];
  if (!ext || ext === 'undefined') {
    return '';
  }
  return ext;
};
export const GetNameFromHeader = response => {
  if (!response.data) {
    throw Error('Unknown error data is null');
  }
  let disposition = response.headers['Content-Disposition'];
  if (typeof disposition === 'undefined') {
    disposition = response.headers['content-disposition'];
  }
  // console.log({ disposition });
  const { type } = response.data;
  const names = disposition.split('filename=');
  const types = type.split('/');
  let name = 'download_all';
  if (names.length === 2) {
    name = names[1];
  } else if (types[1] !== '') {
    name = name + '.' + types[1];
  }
  const ext = GetExtension(name);
  console.log({ disposition, types, name, ext });
  if (ext === '' && types[1] !== '') {
    name = name + '.' + types[1];
  }
  return name.replaceAll('"', '');
};
