import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

class TableSiteUsersHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      headCells,
    } = this.props;
    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={numSelected > 0 && numSelected === rowCount}
              color="default"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headCells.map(headCell => {
            let cell_style = null;
            if (headCell.minWidth) {
              cell_style = { minWidth: headCell.minWidth };
            }
            return (
              <TableCell
                align={headCell.numeric ? 'right' : 'center'}
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={cell_style}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={order}
                  onClick={this.createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

TableSiteUsersHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

export default TableSiteUsersHeader;
