import { IsInvalid, IsInvalidParam } from 'helpers';
import { getUserFullName, FormatTime } from 'helpers';

export const columns_account_dockers = [
  {
    field: 'id',
    headerName: 'ID',
    align: 'left',
  },
  {
    field: 'account_type',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'account_type');
      if (IsInvalid(value)) return '';
      return value.description;
    },
  },
  {
    field: 'docker_image',
    headerName: 'Docker Operation',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'docker_image');
      if (IsInvalid(value)) return '';
      return value.operation;
    },
  },
  {
    field: 'admin',
    headerName: 'Docker Admin',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue(params.id, 'docker_image');
      if (IsInvalid(value)) return '';
      const { user } = value;
      if (IsInvalid(user)) return '';
      return getUserFullName(user);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
