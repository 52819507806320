import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 50,
  },
  {
    id: 'teams',
    label: 'Teams',
    minWidth: 100,
    align: 'right',
    format: value => value.length,
  },
  {
    id: 'projects',
    label: 'Projects',
    minWidth: 100,
    align: 'right',
    format: value => value.length,
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 100,
    align: 'right',
    format: value => value.toLocaleString(),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 100,
    align: 'right',
    format: value => value.toLocaleString(),
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
