import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';

class ExplorerFloatingMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      name,
      ContentIcon,
      shortcut,
      minWidth,
      handleMenuItemClicked,
      isEnabled,
      type,
    } = this.props;
    let disabled = false;
    if (type && type === 'selected') {
      disabled = true;
    }
    return (
      <MenuItem
        disabled={!isEnabled || disabled}
        id={name}
        onClick={handleMenuItemClicked}
        style={{ minWidth }}>
        <ListItemIcon>
          {ContentIcon && <ContentIcon fontSize="small" />}
        </ListItemIcon>
        <ListItemText style={{ paddingRight: 4 }}>{name}</ListItemText>
        <Typography color="text.secondary" variant="body2">
          {shortcut}
        </Typography>
      </MenuItem>
    );
  }
}

ExplorerFloatingMenuItem.propTypes = {
  ContentIcon: PropTypes.object.isRequired,
  minWidth: PropTypes.number,
  name: PropTypes.string,
  shortcut: PropTypes.string,
};
ExplorerFloatingMenuItem.defaultProps = {
  name: '',
  shortcut: '',
  minWidth: 180,
  handleMenuItemClicked: () => '',
};
export default ExplorerFloatingMenuItem;
