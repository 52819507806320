import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_DOCKERS = gql`
  query($idUser: LongLong!, $page: Int, $limit: Int) {
    userDockers(idUser: $idUser, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      dockers {
        id
        idUser
        idImage

        name
        operation
        filepath

        user {
          id
          FullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUsersDockers = (idUser, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_DOCKERS),
    variables: {
      idUser,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_USER_DOCKER = gql`
  mutation($inputs: [InputDockerUser!]!) {
    addUserDockers(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationAddUsersDockers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_USER_DOCKER),
    variables: {
      inputs,
    },
  });
};
const MUTATION_REMOVE_USER_DOCKER = gql`
  mutation($inputs: [InputDockerUser!]!) {
    removeUserDockers(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveUsersDockers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_USER_DOCKER),
    variables: {
      inputs,
    },
  });
};
