import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SITE_MAPPING = gql`
  query($name: String, $page: Int, $limit: Int) {
    siteMappings(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        projects {
          id
          name
        }
        map_file {
          id
          name

          file {
            idFileObject
            original_name
          }
          type {
            id
            name
          }
          updatedAt
          createdAt
        }
        map_series {
          id
          name
          series {
            idSeries
            SeriesDescription
            NumberOfSeriesRelatedInstances
          }
          type {
            id
            name
          }
          updatedAt
          createdAt
        }
        map_sequence {
          id
          Series {
            idSeries
            SeriesDescription
            NumberOfSeriesRelatedInstances
          }
          Sequence {
            idSequence
            name
          }
          updatedAt
          createdAt
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QuerySiteMappings = (name, page, limit) => {
  const variables = {};
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (name !== undefined && name) variables.name = name;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SITE_MAPPING),
    variables,
  });
};
