import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import DropFiles from '../../../DropFiles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
const useStyles = theme => ({
  paper: {
    margin: theme.spacing(5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});
class UploadDropArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, holder, handleChange, className } = this.props;
    return (
      <Paper
        className={clsx({
          [classes.paper]: true,
          [className]: className,
        })}>
        <DropFiles
          filesLimit={100}
          holder={holder}
          maxFileSize={5000000000}
          onChange={handleChange.bind(this)}
          showPreviewsInDropzone={false}
        />
      </Paper>
    );
  }
}

UploadDropArea.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(UploadDropArea, useStyles);
