import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTable, MainTableToolbar } from 'components/Tables/components';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { SelectedArray, rowsPerPageBig } from 'helpers';
import { columns } from './utils';

class TableCenters extends React.Component {
  isSelected = file => {
    if (file === undefined) return;
    const selected = this.props.holder.getSelected();
    const index = selected.map(x => x.id).indexOf(file.id);
    return index !== -1;
  };
  handleSelectedClick = (event, index, file) => {
    event.preventDefault();
    if (file === undefined) return;
    const selected = this.props.holder.getSelected();
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(file.id)
      : 0;
    const newSelected = SelectedArray(selected, file, selectedIndex);
    this.props.handleSetSelected(newSelected);
    return newSelected.length;
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.props.handleSetSelected(rows);
    } else {
      this.props.handleSetSelected([]);
    }
  };
  handleClearSelected = () => {
    this.props.handleSetSelected([]);
  };

  render() {
    const {
      classes,
      rows,
      height,
      loading,
      total,
      selected,
      code,
    } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    // let disabled = false;
    // if (loading || total < this.props.limit) {
    //   disabled = true;
    // }
    // console.log({ rows });
    if (code && columns.map(x => x.id).indexOf('code') === -1) {
      columns.splice(2, 0, {
        id: 'code',
        label: 'Code',
        align: 'center',
        // minWidth: 170,
        type: 'string',
      });
    }
    let ComponentToolbar = null;
    if (!this.props.hide_toolbar) {
      ComponentToolbar = (
        <MainTableToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={this.props.deleting}
          loading_table={loading}
          numSelected={selected.length}
          title="Centers"
        />
      );
    }
    return (
      <Paper className={classes.root}>
        {ComponentToolbar}
        <div className={classes.tableWrapper} style={style}>
          <MainTable
            columns={columns}
            dense={this.props.dense}
            disable_checkbox={this.props.disable_checkbox}
            disable_splice={this.props.disable_splice}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.props.handleRowClicked}
            isHighLighted={this.props.isHighLighted}
            isSelected={this.isSelected}
            limit={this.props.limit}
            loading={loading}
            nowrap={this.props.nowrap}
            page={this.props.page}
            rows={rows}
            selected={selected}
          />
        </div>
        <Divider />
        <TablePagination
          component="div"
          count={total}
          onPageChange={this.props.handleChangePage}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          page={this.props.page}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableCenters.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  dense: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  handleSetSelected: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  selected: PropTypes.array,
};
TableCenters.defaultProps = {
  code: false,
  dense: false,
  nowrap: false,
  disable_splice: true,
  loading: false,
  deleting: false,
  disable_checkbox: false,
  page: 0,
  limit: 100,
  rows: [],
  selected: [],
  total: 0,
  hide_toolbar: false,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: () => '',
  handleRowClicked: () => '',
  isHighLighted: () => false,
};
export default withStyles(TableCenters, useStyles);
