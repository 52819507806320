import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { InputProjectMembers, ButtonLoad, SnackMessage } from 'components';
import { PaperComponent, ServerErrorsString, GetData } from 'helpers';
import { useStyles } from '../Styles';
import { MutationCreateProjectMemberSettings } from 'graphql/Projects/utils_project';
class DialogAddProjectSettingMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
    };
  }
  handleSelected = selected => {
    this.setState({ selected });
  };
  handleAddMembers = () => {
    const { loading, selected } = this.state;
    if (loading) return;
    const { project } = this.props;
    if (project === undefined || !project || project.id < 1) {
      this.setState({ error: 'Project is missing' });
      return;
    }
    const members = selected.map(x => {
      return {
        idUser: x.id,
        idProject: project.id,
      };
    });
    this.setState({ loading: true });
    (async () => {
      MutationCreateProjectMemberSettings(members)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            errors,
            member_settings,
          } = data.createProjectMemberSetting;
          if (ok) {
            this.setState({ loading: false, selected: [], error: '' });
            this.props.handleAddMembers(member_settings);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          console.log(
            'MutationCreateProjectMemberSettings-catch-error',
            errors
          );
          this.setState({
            loading: false,
            error: ServerErrorsString(errors),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };
  render() {
    const { loading, error, selected } = this.state;
    const { classes, open, handleClose, project } = this.props;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Add Member Setting To Project
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new member to the project, please fill all the necessary
            information.
          </DialogContentText>
          <InputProjectMembers
            handleAddMembers={this.handleAddMembers}
            idProject={project.id}
            onChange={this.handleSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            className={classes.button}
            disable_button={selected.length ? false : true}
            handleClick={this.handleAddMembers}
            loading={loading}
            name="Add"
            variant="text"
          />
        </DialogActions>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
      </Dialog>
    );
  }
}

DialogAddProjectSettingMembers.propTypes = {
  classes: PropTypes.object,
};
DialogAddProjectSettingMembers.defaultProps = {
  open: false,
  handleClose: () => console.log('DialogAddProjectSettingMembers - close'),
  handleAddMembers: () => console.log('DialogAddProjectSettingMembers - close'),
  project: { id: -1 },
};
export default withStyles(DialogAddProjectSettingMembers, useStyles);
