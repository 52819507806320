import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
} from '@mui/material';
import { IsInvalid, ServerErrorsString, GetData } from 'helpers';
import {
  CaseForm,
  OutcomeForm,
  PatientForm,
  ResectionForm,
  StudyResearchForm,
} from './components';
import { header_patients } from 'views/DataClinics/components/PatientClinics/components';
import {
  cases,
  research_study,
  resection,
  outcome,
} from 'views/DataClinics/components/ClinicEpilepsy/components/utils_columns';
import { MutationCreateEpilepsy } from 'graphql/DataClinics';
import { ButtonLoad } from 'components/Buttons';
import SnackMessage from 'components/SnackMessage';
import { formatInput, initFields } from 'helpers/DataClinics';

const steps = [
  'Patient',
  'Epilepsy Case',
  'Epilepsy Research Study',
  'Epilepsy Resection',
  'Epilepsy Outcome',
  'Create!',
];

class AddEpilepsyStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: '',
      activeStep: 0,
      patient_form: [],
      case_form: [],
      research_study_form: [],
      resection_form: [],
      outcome_form: [],
    };
  }
  componentDidMount() {
    const patient_form = initFields(header_patients);
    const case_form = initFields(cases);
    const research_study_form = initFields(research_study);
    const resection_form = initFields(resection);
    const outcome_form = initFields(outcome);

    this.setState({
      patient_form,
      case_form,
      research_study_form,
      resection_form,
      outcome_form,
    });
  }

  MutationCreateEpilepsy = inputs => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationCreateEpilepsy(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, cases } = data.createEpilepsy;
          if (ok) {
            const success_str = `Case ${cases[0].case_identifier} successfully created`;
            this.setState({ success: success_str });
            this.handleReset();
          } else {
            this.setState({ activeStep: 0 });
            throw errors;
          }
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  missingRequired = form => {
    if (IsInvalid(form)) return true;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      if (field.required && field.value.length === 0) {
        return true;
      }
    }
    return false;
  };
  handleNext = () => {
    const { activeStep } = this.state;
    if (!this._renderStepActions(activeStep)) return;
    this.setState({ activeStep: activeStep + 1 });
  };
  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };
  handleReset = () => {
    this.resetForms();
    this.setState({
      activeStep: 0,
    });
  };
  handleUpdatePatientForm = form => {
    let { patient_form } = this.state;
    for (let i = 0; i < patient_form.length; i++) {
      const index = form.findIndex(
        item => item.field === patient_form[i].field
      );
      patient_form[i].value = form[index].value;
    }
    this.setState({ patient_form });
  };
  handleUpdateCaseForm = form => {
    let { case_form } = this.state;
    for (let i = 0; i < case_form.length; i++) {
      const index = form.findIndex(item => item.field === case_form[i].field);
      case_form[i].value = form[index].value;
    }
    this.setState({ case_form });
  };
  handleUpdateStudyResearchForm = form => {
    let { research_study_form } = this.state;
    for (let i = 0; i < research_study_form.length; i++) {
      const index = form.findIndex(
        item => item.field === research_study_form[i].field
      );
      research_study_form[i].value = form[index].value;
    }
    this.setState({ research_study_form });
  };
  handleUpdateResectionForm = form => {
    let { resection_form } = this.state;
    for (let i = 0; i < resection_form.length; i++) {
      const index = form.findIndex(
        item => item.field === resection_form[i].field
      );
      resection_form[i].value = form[index].value;
    }
    this.setState({ resection_form });
  };
  handleUpdateOutcomeForm = form => {
    let { outcome_form } = this.state;
    for (let i = 0; i < outcome_form.length; i++) {
      const index = form.findIndex(
        item => item.field === outcome_form[i].field
      );
      outcome_form[i].value = form[index].value;
    }
    this.setState({ outcome_form });
  };
  resetForms = () => {
    const patient_form = initFields(header_patients);
    const case_form = initFields(cases);
    const research_study_form = initFields(research_study);
    const resection_form = initFields(resection);
    const outcome_form = initFields(outcome);

    this.setState({
      patient_form,
      case_form,
      research_study_form,
      resection_form,
      outcome_form,
    });
  };
  handleSaveSettings = () => {
    const {
      patient_form,
      case_form,
      research_study_form,
      resection_form,
      outcome_form,
    } = this.state;
    let inputs = {};
    inputs = formatInput(patient_form, inputs, 'PAT_');
    inputs = formatInput(case_form, inputs, 'CAS_');
    inputs = formatInput(research_study_form, inputs, 'R_STUD_');
    inputs = formatInput(resection_form, inputs, 'RES_');
    inputs = formatInput(outcome_form, inputs, 'OUT_');
    this.MutationCreateEpilepsy(inputs);
  };
  _renderStepContent = step => {
    const {
      patient_form,
      case_form,
      research_study_form,
      resection_form,
      outcome_form,
    } = this.state;
    switch (step) {
      case 0:
        return (
          <PatientForm
            handleAddError={this.props.handleAddError}
            handleUpdatePatientForm={patient_form =>
              this.handleUpdatePatientForm(patient_form)
            }
            patient_form={patient_form}
          />
        );
      case 1:
        return (
          <CaseForm
            case_form={case_form}
            handleAddError={this.props.handleAddError}
            handleUpdateCaseForm={case_form =>
              this.handleUpdateCaseForm(case_form)
            }
          />
        );
      case 2:
        return (
          <StudyResearchForm
            handleAddError={this.props.handleAddError}
            handleUpdateStudyResearchForm={research_studyForm =>
              this.handleUpdateStudyResearchForm(research_studyForm)
            }
            research_study_form={research_study_form}
          />
        );
      case 3:
        return (
          <ResectionForm
            handleAddError={this.props.handleAddError}
            handleUpdateResectionForm={resection_form =>
              this.handleUpdateResectionForm(resection_form)
            }
            resection_form={resection_form}
          />
        );
      case 4:
        return (
          <OutcomeForm
            handleAddError={this.props.handleAddError}
            handleUpdateOutcomeForm={outcome_form =>
              this.handleUpdateOutcomeForm(outcome_form)
            }
            outcome_form={outcome_form}
          />
        );
      case 5:
        return (
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Typography>
              All steps completed - you&apos;re ready to create the epilepsy
              case
            </Typography>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };
  _renderStepActions = step => {
    const {
      patient_form,
      case_form,
      research_study_form,
      resection_form,
      outcome_form,
    } = this.state;
    switch (step) {
      case 0:
        if (this.missingRequired(patient_form)) return false;
        return true;
      case 1:
        if (this.missingRequired(case_form)) return false;
        return true;
      case 2:
        if (this.missingRequired(research_study_form)) return false;
        return true;
      case 3:
        if (this.missingRequired(resection_form)) return false;
        return true;
      case 4:
        if (this.missingRequired(outcome_form)) return false;
        return true;
      case 5:
        this.handleSaveSettings();
        return false;
      default:
        return false;
    }
  };
  render() {
    const { activeStep, success, loading } = this.state;
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={`${label}-${index}`} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment>
          {this._renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={this.handleBack}
              sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={this.handleReset}>Reset</Button>
            <ButtonLoad
              color="primary"
              handleClick={this.handleNext}
              loading={loading}
              name={activeStep >= steps.length - 1 ? 'CREATE' : 'NEXT'}
              variant="contained"
            />
            <SnackMessage
              handleClose={() => this.setState({ success: '' })}
              message_text={success !== '' ? success : 'Unknown warning'}
              open={success && success !== '' ? true : false}
              type="success"
            />
          </Box>
        </React.Fragment>
      </Box>
    );
  }
}

AddEpilepsyStepper.propTypes = {
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
};
AddEpilepsyStepper.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
};

export default AddEpilepsyStepper;
