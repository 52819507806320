import { Badge } from '@mui/material';

import { styled } from '@mui/material/styles';
const BadgeSettings = styled(Badge)(({ theme, ...props }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    right: 7,
    top: props.top ? props.top : -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'lightGreen',
  },
}));
export const BadgeSmall = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: 'lightGreen',
  },
}));

export default BadgeSettings;
