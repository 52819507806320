import { FileExtension } from 'common';
import { IsValid } from 'helpers';
import { IsInvalid } from 'helpers';

export const useStyles = () => ({
  root: {},
});
export const columns = [
  { field: 'id', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'center',
    headerName: 'Center',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'center');
      if (IsInvalid(value)) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  {
    field: 'team',
    headerName: 'Team',
    width: 150,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'team');
      if (IsInvalid(value)) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  {
    field: 'total_maps',
    headerName: '#Maps',
    align: 'left',
    width: 90,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    //  width: 90,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      let value = params.getValue(params.id, 'folder_content');
      if (IsValid(value)) {
        const { Series, File } = value;
        if (Series) {
          const { sequence } = Series;
          if (IsInvalid(sequence)) return '';
          return sequence.name ? sequence.name : '';
        } else if (File) {
          const ext = FileExtension(File.original_name);
          if (IsInvalid(ext)) return '';
          return ext;
        } else {
          return '';
        }
      } else {
        value = params.getValue(params.id, 'folder');
        if (IsInvalid(value)) return '';
        return 'Folder';
      }
    },
  },
  {
    field: 'folder_content',
    headerName: 'Folder Content',
    align: 'left',
    width: 170,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'folder_content');
      if (IsInvalid(value)) return '';
      const { Series, File } = value;
      if (Series) {
        return Series.SeriesDescription ? Series.SeriesDescription : '';
      } else if (File) {
        return File.original_name ? File.original_name : '';
      } else {
        return '';
      }
    },
  },
  {
    field: 'file_object',
    headerName: 'File Object',
    width: 170,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'file_object');
      if (IsInvalid(value)) return '';
      const { Series, File } = value;
      if (Series) {
        return Series.SeriesDescription ? Series.SeriesDescription : '';
      } else if (File) {
        return File.original_name ? File.original_name : '';
      } else {
        return '';
      }
    },
  },
  {
    field: 'folder',
    headerName: 'Folder',
    width: 150,
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue(params.id, 'folder');
      if (IsInvalid(value)) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  {
    field: 'folderStructure',
    headerName: 'Folder Structure',
    align: 'left',
    width: 870,
  },
];
