import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import { AutoComboBox } from 'components';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryAccountTypes } from 'graphql/Users/utils_users';
import { IsInvalid } from 'helpers';
import { QueryClinicalType } from 'graphql/DataClinics/utils_clinical_type';

class DialogAccountClinical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clinical_data: [],
      account_types: [],
      loading_clinical: false,
      loading_types: false,
      selected_type: null,
      selected_clinical: null,
      init: false,
    };
  }
  componentDidUpdate() {
    if (this.props.open && !this.state.init) {
      this.handleUpdateClinicalDataType();
      this.updateAccounts();
    }
  }
  handleUpdateClinicalDataType = () => {
    if (!this.props.isActive) return;
    const { loading_clinical } = this.state;
    if (loading_clinical) return;
    this.setState({ loading_clinical: true });
    (async () => {
      QueryClinicalType()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, clinical_types, total } = data.clinicalDataTypes;
          if (ok) {
            clinical_types.unshift({ description: 'None', id: 0 });
            this.setState({
              loading_clinical: false,
              clinical_data: clinical_types,
              total,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_clinical: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  updateAccounts = () => {
    if (!this.props.isActive) return;
    const { loading_types } = this.state;
    if (loading_types) return;
    this.setState({ loading_types: true, empty: false });
    (async () => {
      QueryAccountTypes()
        .then(res => {
          const data = GetData(res);
          const { ok, accounts, errors } = data.accountTypes;
          if (ok) {
            accounts.unshift({ description: 'None', id: 0 });
            this.setState({
              account_types: accounts,
              loading_types: false,
              empty: !accounts.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_types: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddClinicalType = () => {
    const { selected_clinical, selected_type } = this.state;
    if (IsInvalid(selected_clinical) || IsInvalid(selected_type)) {
      this.props.handleAddError(
        'You need to select a Type and a Clinical type'
      );
      return;
    }
    this.props.handleAddClinicalType(selected_type, selected_clinical);
  };
  render() {
    const { open, handleClose } = this.props;
    if (!open) return null;
    const { clinical_data, account_types } = this.state;
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add Clinical type to account type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select an Account Type and clinical type and press ADD.
          </DialogContentText>
          <Grid container pt={2} spacing={2}>
            <Grid item xs={12}>
              <AutoComboBox
                defaultValue={account_types[0]}
                disabled={this.state.loading_types}
                getOptionLabel={option => {
                  if (option === undefined || option.description === undefined)
                    return '';
                  return option.description;
                }}
                label="Account Type"
                onObjectChanged={selected_type =>
                  this.setState({ selected_type })
                }
                options={account_types}
                small
              />
            </Grid>
            <Grid item xs={12}>
              <AutoComboBox
                defaultValue={clinical_data[0]}
                disabled={this.state.loading_clinical}
                fullWidth
                getOptionLabel={option => {
                  if (option === undefined || option.description === undefined)
                    return '';
                  return option.description;
                }}
                label="Clinical type"
                onObjectChanged={selected_clinical =>
                  this.setState({ selected_clinical })
                }
                options={clinical_data}
                small
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAddClinicalType}>Add</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogAccountClinical.propTypes = {
  open: PropTypes.bool,
};

export default DialogAccountClinical;
