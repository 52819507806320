import React from 'react';
import PropTypes from 'prop-types';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';

import { List, ListItem } from '@mui/material';
import { ButtonCircularIconLoad, DivGrow } from 'components';
import { withRouter } from 'react-router-dom';

class ButtonBoxSeriesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // open_edit: true,
    };
  }

  handleShowSeries = () => {
    const { Series, history } = this.props;
    const { idSeries, idStudy } = Series;
    if (!idSeries) return;

    history.push(`/image_analysis/study/${idStudy}/series/${idSeries}/view`);
  };
  render() {
    const { classes, owner, downloading } = this.props;
    return (
      <List>
        <ListItem style={{ margin: 0, padding: 0 }}>
          <ButtonCircularIconLoad
            className={classes.button_blue}
            disabled={!owner}
            handleButtonClick={this.props.handleButtonShare}
            icon={<ShareIcon />}
            small
          />
          <ButtonCircularIconLoad
            disabled={!owner}
            handleButtonClick={this.props.handleClickDownload}
            icon={<GetAppIcon />}
            loading={downloading}
            small
          />
          <ButtonCircularIconLoad
            className={classes.button_blue}
            handleButtonClick={this.handleShowSeries}
            icon={<OpenWithIcon />}
            small
            tooltip="Show Series"
          />
          <DivGrow />
          <ButtonCircularIconLoad
            disabled={!owner}
            handleButtonClick={this.props.handleEditSeries}
            icon={<EditIcon />}
            small
            tooltip="Edit Series"
          />
          <ButtonCircularIconLoad
            className={classes.button_red}
            disabled={!owner}
            handleButtonClick={this.props.handleOpenDelete}
            icon={<DeleteIcon />}
            small
            tooltip="Remove from Explorer"
          />
        </ListItem>
      </List>
    );
  }
}

ButtonBoxSeriesDetails.propTypes = {
  classes: PropTypes.object,
};

ButtonBoxSeriesDetails.defaultProp = {
  loading: false,
  downloading: false,
  owner: false,
};

export default withRouter(ButtonBoxSeriesDetails);
