import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const GREATE_SIDE = gql`
  mutation createInvestigativedSide($name: String!) {
    createInvestigativedSide(name: $name) {
      ok
      Side {
        idSide
        name
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const CreateInvestigativedSide = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_SIDE),
    variables: { name },
  });
};

const GREATE_SIDE_DELETE = gql`
  mutation deleteInvestigativedSide($ids: [Int!]!) {
    deleteInvestigativedSide(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteInvestigativedSide = selected => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_SIDE_DELETE),
    variables: { ids: selected.map(x => x.idSide) },
  });
};

const GREATE_PATHOLOGY = gql`
  mutation createPathology($name: String!) {
    createPathology(name: $name) {
      ok
      Pathology {
        idPathology
        name
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const CreatePathology = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_PATHOLOGY),
    variables: { name },
  });
};

const GREATE_PATHOLOGY_DELETE = gql`
  mutation deletePathology($ids: [Int!]!) {
    deletePathology(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeletePathology = selected => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_PATHOLOGY_DELETE),
    variables: { ids: selected.map(x => x.idPathology) },
  });
};

const GREATE_IMAGE_FOCUS = gql`
  mutation createImageFocus($name: String!) {
    createImageFocus(name: $name) {
      ok
      ImageFocus {
        idFocus
        name
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const CreateImageFocus = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_IMAGE_FOCUS),
    variables: { name },
  });
};

const GREATE_IMAGE_FOCUS_DELETE = gql`
  mutation deleteImageFocus($ids: [Int!]!) {
    deleteImageFocus(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteImageFocus = selected => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_IMAGE_FOCUS_DELETE),
    variables: { ids: selected.map(x => x.idFocus) },
  });
};

const GREATE_SEQUENCE = gql`
  mutation createSequence($name: String!) {
    createSequence(name: $name) {
      ok
      Sequence {
        idSequence
        name
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const CreateSequence = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_SEQUENCE),
    variables: { name },
  });
};

const MUTATION_SEQUENCE_DELETE = gql`
  mutation deleteSequence($ids: [Int!]!) {
    deleteSequence(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteSequence = selected => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_SEQUENCE_DELETE),
    variables: { ids: selected.map(x => x.idSequence) },
  });
};

const GREATE_REGION = gql`
  mutation createAnatomicalRegion($name: String!) {
    createAnatomicalRegion(name: $name) {
      ok
      AnatomicalRegion {
        idRegion
        name
        updatedAt
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const CreateAnatomicalRegion = name => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_REGION),
    variables: { name },
  });
};

const GREATE_REGION_DELETE = gql`
  mutation deleteAnatomicalRegion($ids: [Int!]!) {
    deleteAnatomicalRegion(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const DeleteAnatomicalRegion = selected => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GREATE_REGION_DELETE),
    variables: { ids: selected.map(x => x.idRegion) },
  });
};

const SAVE_DETAILS = gql`
  mutation SaveDetails($DetailsSeriesIds: DetailsSeriesIds!) {
    SaveDetails(DetailsSeriesIds: $DetailsSeriesIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const SaveDetails = DetailsSeriesIds => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SAVE_DETAILS),
    variables: { DetailsSeriesIds },
  });
};

const SAVE_MULTI_DETAILS = gql`
  mutation($ids: [Int!]!, $DetailsSeriesIds: DetailsSeriesIds!) {
    SaveMultiDetails(ids: $ids, DetailsSeriesIds: $DetailsSeriesIds) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const SaveMultiDetails = (ids, DetailsSeriesIds) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(SAVE_MULTI_DETAILS),
    variables: { ids, DetailsSeriesIds },
  });
};

const GET_SERIES_DETAILS = gql`
  query($idSeries: LongLong!) {
    SeriesDetails(idSeries: $idSeries) {
      ok
      Details {
        SeriesRegion {
          AnatomicalRegion {
            idRegion
            name
          }
          updatedAt
        }
        SeriesFocus {
          ImageFocus {
            idFocus
            name
          }
          updatedAt
        }
        SeriesPathology {
          Pathology {
            idPathology
            name
          }
          updatedAt
        }
        SeriesSequence {
          Sequence {
            idSequence
            name
          }
          updatedAt
        }
        SeriesSide {
          InvestigatedSide {
            idSide
            name
          }
          updatedAt
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

export const QueryRunSeriesDetails = idSeries => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GET_SERIES_DETAILS),
    variables: { idSeries },
  });
};
