import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadSingleFile } from 'common';

const QUERY_ANONYMIZATION_RULES = gql`
  query($tag_name: String, $page: Int, $limit: Int) {
    anonymizationRules(tag_name: $tag_name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      rules {
        id
        tag
        tag_options
        tag_name
        tag_rule
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryAnonymizationRules = (tag_name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ANONYMIZATION_RULES),
    variables: { tag_name, page, limit },
  });
};
const MUTATIOM_ANONYMIZATION_RULES = gql`
  mutation($file: Upload!) {
    uploadAnonymizationRules(file: $file) {
      ok
      errors {
        path
        message
      }
      rules {
        tag
        tag_name
        tag_rule
        tag_options
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadRulesCSV = (file, handleProgress = null) => {
  return MutationUploadSingleFile(
    file,
    MUTATIOM_ANONYMIZATION_RULES,
    handleProgress
  );
};
const MUTATIOM_ADD_ANONYMIZATION_RULES = gql`
  mutation($inputs: [InputRule!]!) {
    addAnonymizationRules(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      rules {
        tag
        tag_name
        tag_rule
        tag_options
        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddAnonymizationRules = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATIOM_ADD_ANONYMIZATION_RULES),
    variables: { inputs },
  });
};
const MUTATIOM_REMOVE_ANONYMIZATION_RULES = gql`
  mutation($ids: [LongLong!]!) {
    removeAnonymizationRules(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveAnonymizationRules = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATIOM_REMOVE_ANONYMIZATION_RULES),
    variables: { ids },
  });
};
