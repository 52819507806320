import React from 'react';
import PropTypes from 'prop-types';
import { ExplorerDetails } from '../../../index';
import { Drawer } from '@mui/material';
import clsx from 'clsx';

class DivDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, children } = this.props;
    const {
      handleDeleteFolders,
      handleDrawerClose,
      handleItemChanged,
      handleSubFolderNameChanged,
      holder,
      item_clicked,
      open_drawer,
      total,
      isMobile,
      height,
      base,
    } = this.props;
    let { CurrentFolder } = this.props;
    if (CurrentFolder === undefined || !CurrentFolder) {
      CurrentFolder = {
        idFolder: -1,
        idParent: -1,
        name: base,
        Rights: [],
        SubFolders: [],
      };
    }
    let variant = 'persistent';
    if (isMobile) {
      variant = 'temporary';
    }
    let Header = <div className={classes.drawerHeader} />;
    if (isMobile) {
      Header = null;
    }
    // console.log({ variant });
    return (
      <div className={classes.content}>
        {children}
        <Drawer
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          className={clsx({
            [classes.drawer]: true,
            [classes.drawerTemporal]: variant === 'temporary',
          })}
          onClose={handleDrawerClose}
          open={open_drawer}
          variant={variant}>
          {Header}
          <ExplorerDetails
            base={this.props.base}
            handleAddError={this.props.handleAddError}
            handleDeleteFolders={handleDeleteFolders}
            handleDownloadLinkChanged={this.props.handleDownloadLinkChanged}
            handleDrawerClose={handleDrawerClose}
            handleItemChanged={handleItemChanged}
            handleProgress={this.props.handleProgress}
            handleSharedLinkChanged={this.props.handleSharedLinkChanged}
            handleSubFolderNameChanged={handleSubFolderNameChanged}
            handleUpdateFolderContent={this.props.handleUpdateFolderContent}
            height={height}
            holder={holder}
            idFolder={CurrentFolder.idFolder}
            isMobile={isMobile}
            item={item_clicked}
            open={open_drawer}
            total={total}
          />
        </Drawer>
      </div>
    );
  }
}

DivDrawer.propTypes = {
  isDesktop: PropTypes.bool,
};
DivDrawer.defaultProps = {
  handleProgress: () => '',
  base: 'Home',
  handleUpdateFolderContent: () => '',
};
export default DivDrawer;
