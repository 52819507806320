import React from 'react';
import PropTypes from 'prop-types';
import MuiMenu from '@mui/material/Menu';
class MenuFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      children,
      anchorEl,
      open,
      handleClose,
      anchorOrigin,
      transformOrigin,
    } = this.props;
    let res = {};
    if (anchorOrigin) {
      res = { anchorOrigin };
    }
    if (transformOrigin) {
      res = { ...res, transformOrigin };
    }
    let arrow_style = {};
    if (this.props.left) {
      arrow_style = { left: this.props.left };
      // right: arrow_position,
    } else {
      arrow_style = { right: this.props.right };
    }
    return (
      <MuiMenu
        {...res}
        anchorEl={anchorEl}
        autoFocus={false}
        id="account-menu"
        onClose={handleClose}
        onKeyDown={e => e.stopPropagation()}
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              ...arrow_style,
            },
          },
        }}>
        {children}
      </MuiMenu>
    );
  }
}

MenuFilters.propTypes = {
  anchorOrigin: PropTypes.object,
};
MenuFilters.defaultProps = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  right: 7,
};

export default MenuFilters;
