import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Typography, DialogContent, Divider } from '@mui/material';
import { CardEditHeader } from 'components/Cards';
import AddIcon from '@mui/icons-material/Add';
import { AddEpilepsyStepper } from './components';
import { withStyles } from 'tss-react/mui';

const useStyles = () => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

class DialogAddViewEpilepsyCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };
  render() {
    const { open } = this.props;

    let ComponentHeaderView = null;
    ComponentHeaderView = (
      <React.Fragment>
        <CardEditHeader
          description={
            'Follow the guided procedure to create a new epilepsy case'
          }
          handleClose={this.handleClose}
          icon={<AddIcon style={{ color: 'green' }} />}
          title={
            <Typography
              display="inline"
              gutterBottom
              style={{
                marginRight: '15px',
                textTransform: 'none',
                fontWeight: 600,
              }}
              variant="h5">
              Create new Epilepsy
            </Typography>
          }
        />
      </React.Fragment>
    );
    return (
      <div>
        <Dialog fullWidth maxWidth="lg" onClose={this.handleClose} open={open}>
          {ComponentHeaderView}
          <Divider />
          <DialogContent dividers={scroll === 'paper'}>
            <AddEpilepsyStepper
              handleAddError={this.props.handleAddError}
              handleClose={this.handleClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

DialogAddViewEpilepsyCase.propTypes = {
  handleAddError: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
DialogAddViewEpilepsyCase.defaultProps = {
  handleClose: () => '',
  handleAddError: () => '',
  open: false,
};

export default withStyles(DialogAddViewEpilepsyCase, useStyles);
