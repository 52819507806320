import React from 'react';
import { FormatBytes, FormatTime } from 'helpers';

export const useStyles = theme => ({
  root: {
    width: 'calc(100% - 16px)',
    height: '100%',
    margin: theme.spacing(1),
    padding: 0,
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
});
export const columns = [
  {
    id: 'idImage',
    label: 'ID',
    minWidth: 100,
    type: 'link',
  },
  {
    id: 'Status',
    label: 'Status',
    minWidth: 100,
    format: value => {
      let color = '#ff1744';
      let font = 'white';
      if (value === 'unused') {
        color = '#ff9800';
        font = 'black';
      }
      return (
        <div
          style={{
            backgroundColor: color,
            color: font,
            fontWeight: 1000,
            borderRadius: 5,
            marginLeft: 10,
            marginRight: 10,
            padding: 2,
            paddingRight: 5,
            paddingLeft: 5,
            textAlign: 'center',
          }}>
          {value}
        </div>
      );
    },
  },
  {
    id: 'RepoTags',
    label: 'Name',
    align: 'left',
    minWidth: 10,
    format: value => {
      //
      if (value === undefined || !value.length) return 'Name missing';
      const { name } = value[0];
      if (name === undefined || name === '') return 'Name missing';
      return name;
    },
  },
  {
    id: 'Size',
    label: 'Size',
    minWidth: 100,
    format: value => FormatBytes(value),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 100,
    format: value => (value ? FormatTime(value, 'calendar') : ''),
  },
];
