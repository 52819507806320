import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Toolbar, Grid } from '@mui/material';
import { useToolbarStyles, handleDeleteSelected, GetComponent } from './utils';
import {
  ButtonReloadDelete,
  IconButtonLoad,
  DialogDeleteWarning,
} from 'components';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { connect } from 'react-redux';
import { copySeries, copyFiles } from 'redux-store/actions/file_folder_manager';
class ToolbarOrphans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClearSelected = () => {
    this.props.handleState({ selected: [] });
    if (this.props.holder) {
      this.props.holder.clean();
    }
  };
  copySeriesToExplorer = () => {
    const { state } = this.props;
    const { selected } = state;
    const { copy_series, copy_files } = this.props;

    const total = copy_series.length;
    const total_files = copy_files.length;
    for (let i = 0; i < selected.length; i++) {
      const {
        idFileObject,
        idSeries,
        original_name,
        SeriesDescription,
        createdAt,
        updatedAt,
      } = selected[i];
      if (idSeries) {
        const index = copy_series.map(x => x.idSeries).indexOf(idSeries);
        if (index === -1) {
          copy_series.push({
            idSeries,
            SeriesDescription,
            createdAt,
            updatedAt,
          });
        }
      } else if (idFileObject) {
        const index = copy_files.map(x => x.idFileObject).indexOf(idFileObject);
        if (index === -1) {
          copy_files.push({
            idFileObject,
            original_name,
            createdAt,
            updatedAt,
          });
        }
      }
    }
    const success = total < copy_series.length;
    const success_files = total_files < copy_files.length;
    // console.log({ total, copy_series, l: copy_series.length });
    // console.log({ total_files, copy_files, l: copy_files.length });
    if (success) {
      this.props.copySeries(copy_series);
      this.props.handleAddSuccess(
        `${copy_series.length} series copied for explorer`
      );
    } else if (total !== copy_series.length) {
      this.props.handleAddError('The series are already copied');
    }
    if (success_files) {
      this.props.copyFiles(copy_files);
      this.props.handleAddSuccess(
        `${copy_files.length} files copied for explorer`
      );
    } else if (total_files !== copy_files.length) {
      this.props.handleAddError('The files are already copied');
    }
  };
  render() {
    const {
      classes,
      handleReloadTable,
      state,
      title,
      handleState,
      size,
    } = this.props;
    const { loading, selected, deleting, open_delete, copying } = state;
    const numSelected = selected.length;
    const SelectedText = GetComponent(numSelected, title, classes);
    let CopyComponent = null;
    if (numSelected > 0 || size) {
      CopyComponent = (
        <Grid item>
          <IconButtonLoad
            handleClick={this.copySeriesToExplorer}
            icon={<CopyAllIcon style={{ color: 'green' }} />}
            length={size}
            loading={copying}
            tooltip="Copy Files"
          />
        </Grid>
      );
    }
    return (
      <React.Fragment>
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between">
            <Grid item>{SelectedText}</Grid>
            <Grid
              alignItems="center"
              container
              direction="row"
              item
              justifyContent="flex-end"
              xs={5}>
              {CopyComponent}
              <Grid item>
                <ButtonReloadDelete
                  deleting={deleting}
                  handleClearSelected={this.handleClearSelected}
                  handleDeleteSelected={() =>
                    handleState({ open_delete: true })
                  }
                  handleReloadTable={handleReloadTable}
                  loading_table={loading}
                  numSelected={numSelected}
                  // tooltip={tooltip}
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: !open_delete })}
          handleDeleteFolders={() =>
            handleDeleteSelected(state, handleState, this)
          }
          loading={deleting}
          open={open_delete}
          question_text="You are about to delete the selected orphans, this action cannot be undone"
          title_text="Delete Orphans"
        />
      </React.Fragment>
    );
  }
}

ToolbarOrphans.propTypes = {
  selected: PropTypes.array,
};
ToolbarOrphans.defaultProps = {
  selected: [],
};

const mapStateToProps = state => {
  const { copy_series, copy_files } = state.manager;
  let size = copy_series ? copy_series.length : 0;
  if (copy_files) size += copy_files.length;
  return {
    copy_series: copy_series ? copy_series : [],
    copy_files: copy_files ? copy_files : [],
    size,
  };
};
export default connect(mapStateToProps, { copySeries, copyFiles })(
  withStyles(ToolbarOrphans, useToolbarStyles)
);
