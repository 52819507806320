import React from 'react';

import { lighten } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { MutationDeleteOrphans } from 'graphql/Folders';
import { ServerErrorsString, GetData } from 'helpers';
export const useToolbarStyles = (theme, props) => ({
  root: {
    width: '100%',
    minWidth: 250,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#bdbdbd',
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: props.transform ? '20px' : '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: props.transform ? 'none' : null,
  },
});
export const handleDeleteSelected = (state, handleState, THIS) => {
  const { loading, deleting, orphans, selected } = state;
  if (loading || deleting) return;
  console.log({ selected });
  handleState({ deleting: true });
  (async () => {
    const ids = selected.map(x => x.idFileObject);
    MutationDeleteOrphans(ids)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.DeleteOrphans;
        if (ok) {
          handleState({
            orphans: orphans.filter(x => ids.indexOf(x.idFileObject) === -1),
            deleting: false,
            open_delete: false,
            selected: [],
          });
          THIS.props.holder.clean();
        } else {
          throw errors;
        }
      })
      .catch(error => {
        THIS.props.handleAddError(ServerErrorsString(error));
        handleState({
          deleting: false,
        });
      });
  })();
};
export const GetComponent = (numSelected, title, classes) => {
  let SelectedText = null;
  if (numSelected > 0) {
    SelectedText = (
      <Typography className={classes.title} color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    );
  } else {
    SelectedText = (
      <Typography className={classes.title} id="tableTitle" variant="h6">
        {title}
      </Typography>
    );
  }
  return SelectedText;
};
