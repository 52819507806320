import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_FILE_LINKS = gql`
  query($name: String, $page: Int, $limit: Int) {
    fileObjectLinks(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      file_links {
        id
        idFileObject
        idStudy
        idSeries
        file_object {
          original_name
        }
        study {
          StudyDescription
        }
        series {
          SeriesDescription
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryFileObjectLinks = (name, page, limit) => {
  console.log('QueryFileObjectLinks', { name, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_LINKS),
    variables: { name, page, limit },
  });
};
