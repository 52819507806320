export const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'permission',
    numeric: false,
    disablePadding: false,
    label: 'Permission (W/R/Do/De/F)',
  },
  {
    id: 'data_modified',
    numeric: true,
    disablePadding: false,
    label: 'Data Modified',
  },
  { id: 'size', numeric: true, disablePadding: false, label: 'Size' },
  {
    id: 'kind',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'Kind',
  },
];
