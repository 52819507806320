import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadEpilepsyCSV } from './utils';

const QUERY_EPILEPSY_OUTCOMES = gql`
  query($name: String, $page: Int, $limit: Int) {
    epilepsyOutcomes(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      outcomes {
        id
        idPatient
        idEpilepsyCase
        engel_3m
        engel_1y
        engel_2y
        percentage_aed_1y
        percentage_aed_2y

        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryEpilepsyOutcomes = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_EPILEPSY_OUTCOMES),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_REMOVE_EPILEPSY_OUTCOMES = gql`
  mutation($ids: [LongLong!]!) {
    deleteEpilepsyOutcomes(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationRemoveEpilepsyOutcomes = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_EPILEPSY_OUTCOMES),
    variables: {
      ids,
    },
  });
};
const MUTATION_ADD_EPILEPSY_OUTCOMES = gql`
  mutation($inputs: [InputEpilepsyOutcome!]!) {
    addEpilepsyOutcomes(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      outcomes {
        id
        idPatient
        idEpilepsyCase
        engel_3m
        engel_1y
        engel_2y
        percentage_aed_1y
        percentage_aed_2y

        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationAddEpilepsyOutcomes = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_EPILEPSY_OUTCOMES),
    variables: {
      inputs,
    },
  });
};
const MUTATION_UPLOAD_EPILEPSY_OUTCOMES = gql`
  mutation($file: Upload!) {
    uploadEpilepsyOutcomes(file: $file) {
      ok
      errors {
        path
        message
      }
      total
      outcomes {
        id
        idPatient
        idEpilepsyCase
        engel_3m
        engel_1y
        engel_2y
        percentage_aed_1y
        percentage_aed_2y

        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationUploadEpilepsyOutcomes = (file, handleProgress = null) => {
  return MutationUploadEpilepsyCSV(
    file,
    MUTATION_UPLOAD_EPILEPSY_OUTCOMES,
    handleProgress
  );
};

const QUERY_SEARCH_EPILEPSY_OUTCOMES = gql`
  query($phrase: String, $page: Int, $limit: Int) {
    searchEpilepsyOutcomes(phrase: $phrase, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      outcomes {
        id
        idPatient
        idEpilepsyCase
        engel_3m
        engel_1y
        engel_2y
        percentage_aed_1y
        percentage_aed_2y

        patient {
          id
          PatientID
        }

        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySearchEpilepsyOutcomes = (phrase, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SEARCH_EPILEPSY_OUTCOMES),
    variables: {
      phrase,
      page,
      limit,
    },
  });
};
