import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { SearchProjectsAtUser } from 'components';

class UploadStepOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      getFolderCenterTeam: null,
      cleanSelected: null,
    };
  }
  handleSelectProject = project => {
    console.log({ project });
    let folder = null;
    if (project) {
      const { ProjectFolder } = project;
      folder = ProjectFolder;
    }
    if (this.holder.cleanSelected) {
      this.holder.cleanSelected();
    }
    this.props.handleProjectSelection({ project, folder });
  };
  render() {
    const { project } = this.props;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        p={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>Project Selection</Typography>
        </Grid>
        <Grid item p={1} xs={12}>
          <Typography>
            In this section you can find all the project that you have access in
            Medical-Blocks. Please, select a project to go to the next step.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <SearchProjectsAtUser
            external_selected={project}
            handleAddProject={this.handleSelectProject}
            handleSetProject={this.handleSelectProject}
            label="Select Project to upload"
            no_icon
            placeholder="Search My Projects..."
          />
        </Grid>
      </Grid>
    );
  }
}

UploadStepOne.propTypes = {
  project: PropTypes.object,
};

export default UploadStepOne;
