import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function isImage(file) {
  const fileName = file.name || file.path;
  const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
  if (
    suffix === 'jpg' ||
    suffix === 'jpeg' ||
    suffix === 'bmp' ||
    suffix === 'png'
  ) {
    return true;
  }
}

export function convertBytesToMbsOrKbs(filesize) {
  var size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = filesize / 1000000 + ' megabytes';
  } else if (filesize >= 1000) {
    size = filesize / 1000 + ' kilobytes';
  } else {
    size = filesize + ' bytes';
  }
  return size;
}

export async function createFileFromUrl(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url
    .replace(/\?.+/, '')
    .split('/')
    .pop();
  const ext = data.type.split('/').pop();
  return new File([data], `${filename}.${ext}`, metadata);
}

export function arrayRemove(arr, value) {
  return arr.filter(function(ele) {
    return ele !== value;
  });
}

export function arrayRemoveFile(arr, fileObject) {
  //
  //
  return arr.filter(function(ele) {
    const { file } = ele;
    // if (file) {
    //
    //   if (fileObject === file) {
    //
    //   }
    // }
    return file !== fileObject;
  });
}

export function findFileIndex(fileObjects, fileObject) {
  // let fileIndex = -1;
  return fileObjects.findIndex(object => object.file === fileObject);
  // fileObjects.filter((fileObject, i) => {
  //   if (fileObject === fileObjectToIdentify && fileObject !== -1) {
  //     fileIndex = i;
  //   }
  //   return true;
  // });
  // return fileIndex;
}

export function ReadyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.354 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        fillRule="evenodd"
      />
      <path d="M6.25 8.043l-.896-.897a.5.5 0 1 0-.708.708l.897.896.707-.707zm1 2.414l.896.897a.5.5 0 0 0 .708 0l7-7a.5.5 0 0 0-.708-.708L8.5 10.293l-.543-.543-.707.707z" />
    </SvgIcon>
  );
}
