import { MutationBigFiles } from './utils_chunk_upload';
import { MutationUploadInputs } from './utils_single_upload';
import { max_file_size, MUTATION_FILE_UPLOAD } from './utls_gql_upload';

export const MutationUploadFile = (
  file,
  handleProgress,
  idFolder,
  settings
) => {
  const { size } = file;
  const query = { file };
  console.log({ file });
  if (idFolder) {
    query.settings = { folderIds: [idFolder] };
  }
  if (settings) {
    query.settings = settings;
  }
  if (size < max_file_size) {
    return MutationUploadInputs(query, MUTATION_FILE_UPLOAD, handleProgress);
  } else {
    return MutationBigFiles(file, query, handleProgress);
  }
};
