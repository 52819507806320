/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { lighten } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { TextField, Toolbar, Grid, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import { ButtonReloadDelete, DivGrow, IconButtonLoad } from 'components';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
const useToolbarStyles = (theme, props) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(blue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: props.transform ? '20px' : '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: props.transform ? 'none' : null,
  },
});

class TableToolbarMemberSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }
  handleSearchByName = event => {
    this.setState({ name: event.target.value });
    this.props.handleSearchByName(event.target.value);
  };
  render() {
    const {
      numSelected,
      classes,
      title,
      handleDeleteSelected,
      handleReloadTable,
      handleClearSelected,
      tooltip,
      loading_table,
    } = this.props;
    let ButtonLeft = null;
    if (numSelected > 0) {
      ButtonLeft = (
        <Grid item>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1">
            {numSelected} selected
          </Typography>
        </Grid>
      );
    } else {
      ButtonLeft = (
        <React.Fragment>
          <Grid item>
            <Typography className={classes.title} id="tableTitle" variant="h6">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-search"
              label="Search Member"
              onChange={this.handleSearchByName}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  this.props.handelAddMember();
                }
              }}
              size="small"
              type="search"
              value={this.state.name}
              variant="outlined"
            />
          </Grid>
        </React.Fragment>
      );
    }
    let ComponentEditSetting = null;
    let AddMemberButton = null;
    if (numSelected < 1) {
      AddMemberButton = (
        <Grid item>
          <IconButtonLoad
            // disabled={!changed}
            handleClick={this.props.handelAddMember}
            icon={<PersonAddIcon />}
            // loading={loading}
            tooltip="Add Member Setting"
          />
        </Grid>
      );
    } else {
      ComponentEditSetting = (
        <Grid item>
          <IconButtonLoad
            bottom_top
            handleClick={this.props.handleEditSelected}
            icon={<EditIcon style={{ color: 'green' }} />}
            left_right
            length={numSelected}
            small
            tooltip="Delete Members"
          />
        </Grid>
      );
    }
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          spacing={3}>
          {ButtonLeft}
          <DivGrow />
          {AddMemberButton}
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            xs={3}>
            {ComponentEditSetting}
            <ButtonReloadDelete
              handleClearSelected={handleClearSelected}
              handleDeleteSelected={handleDeleteSelected}
              handleReloadTable={handleReloadTable}
              loading={this.props.deleting_member}
              loading_table={loading_table}
              numSelected={numSelected}
              show_delete
              tooltip={tooltip}
            />
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

TableToolbarMemberSetting.propTypes = {
  handleClearSelected: PropTypes.func.isRequired,
  handleReloadTable: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  transform: PropTypes.bool,
};
TableToolbarMemberSetting.defaultProps = {
  loading_table: false,
  loading: false,
  handleReloadTable: () => console.log('handleReloadTable'),
  tooltip: 'Delete',
  handleClearSelected: () => '',
  transform: false,
  handleSearchByName: () => '',
  handelAddMember: () => '',
};
export default withStyles(TableToolbarMemberSetting, useToolbarStyles);
