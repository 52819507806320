import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components';
import {
  TabSequenceMapping,
  TabSequences,
  TabUnsortedSequences,
} from './components';
import { withStyles } from 'tss-react/mui';
import { getTabLabel } from 'helpers';
const useStyles = () => ({
  root: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
});
class TabDicoms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        { name: 'Sequences', TabComponent: TabSequences },
        { name: 'Sequence Mapping', TabComponent: TabSequenceMapping },
        { name: 'Unsorted Sequence', TabComponent: TabUnsortedSequences },
      ],
    };
  }
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    newValue = parseInt(newValue, 10);
    if (newValue < 0 || newValue > this.state.tabs.length) {
      newValue = 0;
    }
    const tab = this.state.tabs[newValue].name;
    this.setState({ tabValue: newValue });
    this.props.history.push(
      `/administrator/general/dicoms/${getTabLabel(tab)}`
    );
  };
  render() {
    const { tabs, tabValue } = this.state;
    const { classes, user, height } = this.props;
    const internal_height = height - 50;
    return (
      <div>
        <Paper square>
          <Tabs
            $scrollButtons="auto"
            className={classes.root}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {tabs.map((tab, index) => {
              return (
                <Tab
                  className={classes.tab}
                  key={`tab-dicoms-${index}`}
                  label={tab.name}
                />
              );
            })}
          </Tabs>
        </Paper>
        <Divider />
        <div style={{ width: '100%', height: internal_height }}>
          <div>
            {tabs.map((tab, index) => {
              const { TabComponent } = tab;
              return (
                <TabPanel
                  // className={classes.tab_panel}
                  index={index}
                  key={`tab-dicoms-panel-${index}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    height={internal_height}
                    history={this.props.history}
                    isActive={this.props.isActive}
                    match={this.props.match}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

TabDicoms.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabDicoms, useStyles);
