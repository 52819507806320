import React from 'react';
import PropTypes from 'prop-types';
import { FileDefault, FileDesigner } from './components';

class FileDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    if (user && user.idCategory === 2) {
      return <FileDesigner {...this.props} />;
    } else {
      return <FileDefault {...this.props} />;
    }
  }
}

FileDetails.propTypes = {
  classes: PropTypes.object,
};

export default FileDetails;
