import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { MutationUploadInputs } from 'common';

const QUERY_FILE_CONTENT = gql`
  query($idFileObject: LongLong!) {
    getFileContent(idFileObject: $idFileObject) {
      ok
      content
      errors {
        path
        message
      }
    }
  }
`;
export const QueryFileContent = idFileObject => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_CONTENT),
    variables: { idFileObject },
  });
};

const UPDATE_EXCEL_FILE = gql`
  mutation($idFileObject: LongLong!, $file: Upload!) {
    UpdateExcelFile(idFileObject: $idFileObject, file: $file) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const UpdateExcelFile = (idFileObject, file, handleProgress = null) => {
  return MutationUploadInputs(
    { idFileObject, file },
    UPDATE_EXCEL_FILE,
    handleProgress
  );
};
