export const setFolderSharedLink = (idFolder, link, self) => {
  const { CurrentFolder, HomeFolder } = self.state;
  let { item_clicked } = self.state;
  const { SubFolders } = CurrentFolder;
  const index = SubFolders.map(x => x.idFolder).indexOf(idFolder);
  if (index !== -1 && item_clicked.idFolder === SubFolders[index].idFolder) {
    item_clicked = {
      ...item_clicked,
      link,
    };
  }
  let state = {
    CurrentFolder: {
      ...CurrentFolder,
      link,
    },
    item_clicked,
  };
  if (HomeFolder && idFolder === HomeFolder.idFolder) {
    state = {
      ...state,
      HomeFolder: {
        ...HomeFolder,
        link,
      },
      item_clicked,
    };
  } else if (HomeFolder) {
    const sub_folders = self.updateLinkAtFolder(
      idFolder,
      link,
      HomeFolder.SubFolders
    );
    if (sub_folders) {
      state = {
        ...state,
        HomeFolder: {
          ...HomeFolder,
          SubFolders: sub_folders,
        },
        item_clicked,
      };
    }
  } else {
    self.handleLoadHome();
  }
  if (!self.props.LocalHomeFolder || self.props.LocalHomeFolder === undefined) {
    self.props.setExplorerHome(HomeFolder);
  }
  self.setState(state);
};

export const setContentSharedLink = (idFolderContent, link, self) => {
  const { CurrentFolder, HomeFolder } = self.state;
  let { item_clicked } = self.state;
  const { Files } = CurrentFolder;
  const index = Files.map(x => x.idFolderContent).indexOf(idFolderContent);
  if (index === -1) return;
  if (item_clicked.idFolderContent === Files[index].idFolderContent) {
    item_clicked = {
      ...item_clicked,
      link,
    };
  }
  Files[index].link = link;
  if (HomeFolder && HomeFolder.idFolder === CurrentFolder.idFolder) {
    HomeFolder.Files[index].link = link;
    self.setState({ CurrentFolder, HomeFolder, item_clicked });
  } else {
    self.setState({ CurrentFolder, item_clicked });
  }
};
