import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

class ProjectName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      set: false,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getName = () => this.state.name;
    }
  }

  setFromProps = () => {
    const {
      project: { name },
    } = this.props;
    if (typeof name === 'undefined') return;
    this.setState({ name, set: true });
  };

  componentDidMount() {
    this.setFromProps();
  }

  componentDidUpdate() {
    if (!this.state.set) {
      this.setFromProps();
    }
  }

  handleChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
    const {
      project: { name },
    } = this.props;
    if (typeof name === 'undefined') return;
    this.props.handleChange('name', name !== event.target.value);
  };

  render() {
    const { name } = this.state;
    const { admin, project } = this.props;
    let label = 'Project Name';
    if (project) {
      label = `${label} (Project ID: ${project.id})`;
    }
    return (
      <TextField
        disabled={!admin}
        id="name"
        InputLabelProps={{
          shrink: true,
        }}
        label={label}
        margin="normal"
        name="name"
        onChange={this.handleChange}
        // style={{ margin: 8 }}
        placeholder="Add a project name"
        // required
        value={name}
        variant="outlined"
      />
    );
  }
}

ProjectName.propTypes = {
  classes: PropTypes.object,
};
ProjectName.defaultProps = {
  admin: false,
};

export default ProjectName;
