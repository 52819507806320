export const IsProjectFolder = (value, idHomeFolder) => {
  const title = value.name;
  const { idParent } = value;
  if (
    idParent === idHomeFolder &&
    (title === 'Projects' || title === 'Thema')
  ) {
    return true;
  }
  return false;
};
export const IsUploadFolder = (value, idHomeFolder) => {
  const title = value.name;
  const { idParent } = value;
  if (idParent === idHomeFolder && title === 'MissingUploadFolder') {
    return true;
  }
  return false;
};
export const useStyles = () => ({
  root: {
    height: 110,
    width: 100,
    backgroundColor: 'white',
    color: 'blue',
  },
  context_menu: {
    width: 105,
    height: 115,
    border: '2px solid #d3d4d5',
  },
  checkbox: {
    width: '10px',
    height: '10px',
    marginTop: '-5px',
    marginLeft: '-10px',
    marginRight: 0,
  },
  box: {
    backgroundColor: 'white',
    // '&:hover': {
    //   color: '#ed1212',
    //   cursor: 'pointer',
    //   backgroundColor: 'white',
    // },
    width: '100px',
    padding: 0,
  },
  box_selected: {
    backgroundColor: 'white',
    // '&:hover': {
    //   color: '#ed1212',
    //   cursor: 'pointer',
    //   backgroundColor: 'white',
    // },
    width: '100px',
    padding: 0,
    marginTop: '0px',
    marginLeft: '-8px',
  },
  folder_name: {
    width: '100%',
    marginTop: '-15px',
  },
  icon: {
    width: 100,
    height: 100,
  },
  icon_folder_shared: {
    color: 'green',
    width: 100,
    height: 100,
  },
  icon_folder_project: {
    color: '#90caf9',
  },
  icon_folder_upload: {
    color: '#ffcc80',
  },
});
