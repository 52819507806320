import React from 'react';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TeamView } from 'components';
import { ProjectTeamsView, useStyles } from './components';
import { QueryProjectTeams } from 'graphql/Projects/utils_project';
import { GetData, ServerErrorsString } from 'helpers';

class TabProjectTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      loading: false,
      open_edit_teams: false,
      length: 0,
      project_teams: [],
      total: 0,
      page: 0,
      limit: 100,
      idProject: -1,
      selected: [],
    };
  }

  componentDidMount() {
    this.updateTeams();
  }

  componentDidUpdate() {
    const { idProject } = this.state;
    if (idProject !== this.props.project.id) {
      this.updateTeams();
    }
  }

  updateTeams = (newName = null, newPage = null, newLimit = null) => {
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (newName) {
      name = newName;
    }
    const { project } = this.props;
    if (project.id <= 0) return;
    this.setState({ loading: true });
    (async () => {
      QueryProjectTeams(project.id, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, total, pc_teams } = data.projectTeams;
          //
          if (ok) {
            this.setState({
              loading: false,
              project_teams: pc_teams.map(x => ({
                ...x,
                center: x.center_team.center,
              })),
              idProject: project.id,
              total,
              page,
              limit,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject: project.id });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddNewProjectTeams = new_project_teams => {
    let { project_teams, total } = this.state;
    for (let i = 0; i < new_project_teams.length; i++) {
      const { id } = new_project_teams[i];
      const index = project_teams.map(x => x.id).indexOf(id);
      if (index === -1) {
        project_teams.unshift(new_project_teams[i]);
        total++;
      }
    }
    this.setState({ project_teams, total });
  };
  handleTeamSearch = event => {
    const name = event.target.value;
    this.updateTeams(name);
  };
  handleOpenEditSelected = () => {
    const { open_edit_teams } = this.state;
    const { history, project } = this.props;
    // this.state.open_edit_teams = !open_edit_teams;
    this.setState({ open_edit_teams: !open_edit_teams });
    if (!open_edit_teams)
      history.push(`/project_management/${project.id}/teams/edit`);
    else {
      this.setState({ selected: [] });
      history.push(`/project_management/${project.id}/teams`);
    }
  };
  IsEdit = () => {
    const {
      match: { params },
    } = this.props;
    if (typeof params === 'undefined') return false;
    const { level_2 } = params;
    if (level_2 === 'edit') return true;
    return false;
  };
  handleTeamModified = team => {
    let { selected, project_teams } = this.state;
    let index = selected.map(x => x.center_team.team.id).indexOf(team.id);
    if (index !== -1) {
      selected[index].team = team;
      if (team.members) {
        selected[index].members = team.members.length + 1;
      }
    }
    index = project_teams.map(x => x.center_team.team.id).indexOf(team.id);
    if (index !== -1) {
      project_teams[index].team = team;
      if (team.members) {
        project_teams[index].members = team.members.length + 1;
      }
    }
    this.setState({ selected, project_teams });
  };
  handleDeletedTeams = ids => {
    let { project_teams, total } = this.state;
    total = total - ids.length;
    project_teams = project_teams.filter(
      x => ids.map(x => x.id).indexOf(x.id) === -1
    );

    this.setState({ project_teams, total, selected: [] });
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleChangePage = (event, page) => {
    event.preventDefault();
    this.updateTeams(null, page, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.updateTeams(null, 0, +event.target.value);
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, history, project, isAdmin } = this.props;
    const { selected, loading } = this.state;
    if (!loading && this.IsEdit() && !selected.length) {
      history.push(`/project_management/${project.id}/teams`);
    }
    if (!loading && this.IsEdit() && selected.length) {
      return (
        <div className={classes.main_container}>
          <TeamView
            enable_edit={isAdmin}
            handleAddError={this.props.handleAddError}
            handleOpenTeamView={this.handleOpenEditSelected}
            handleTeamModified={this.handleTeamModified}
            height={this.props.height}
            idProject={project ? project.id : undefined}
            loading={loading}
            selected={selected}
          />
        </div>
      );
    }
    return (
      <div className={classes.main_container}>
        <ProjectTeamsView
          admin={isAdmin}
          deleting={this.state.deleting}
          handleAddError={this.props.handleAddError}
          handleAddNewProjectTeams={this.handleAddNewProjectTeams}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleDeletedTeams={this.handleDeletedTeams}
          handleOpenEditSelected={this.handleOpenEditSelected}
          handleSetSelected={this.handleSetSelected}
          handleTeamSearch={this.handleTeamSearch}
          height={this.props.height}
          limit={this.state.limit}
          loading={loading}
          page={this.state.page}
          project={project}
          project_teams={this.state.project_teams}
          selected={selected}
          total={this.state.total}
          updateTeams={() => this.updateTeams()}
        />
      </div>
    );
  }
}

TabProjectTeams.propTypes = {
  history: PropTypes.object,
  project: PropTypes.object,
};

export default withStyles(withRouter(TabProjectTeams), useStyles);
