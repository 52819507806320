import { IsInvalid } from 'helpers';
export { default as withRouter } from './withRouter';
export * from './tables';
export * from './styles';
export * from './file_information';
export * from './inputs';
export * from './utils_user';
export * from './projects';
export * from './google';
export * from './utils_main';
export * from './utils_router';
export * from './upload';
export * from './utils_summaries';
// prettier-ignore
export const alphabet = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
export const numberToColumnLabel = number => {
  let label = '';
  while (number > 0) {
    const remainder = (number - 1) % 26;
    label = String.fromCharCode(65 + remainder) + label;
    number = Math.floor((number - 1) / 26);
  }
  return label;
};

export const generateColumnLabels = numColumns => {
  const columnLabels = [];
  for (let i = 1; i <= numColumns; i++) {
    columnLabels.push(numberToColumnLabel(i));
  }
  return columnLabels;
};
export const codesAlpha = () => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map(x => String.fromCharCode(x));
  console.log(alphabet);
  return alphabet;
};

export const getColumns = (external = null, total = 26) => {
  if (IsInvalid(external)) external = generateColumnLabels(total);
  const columns = [];
  for (let i = 0; i < external.length; i++) {
    columns.push({
      field: `${external[i]}`,
      headerName: `${external[i]}`,
      width: 85,
      align: 'left',
      edit: true,
      style: {
        width: 50,
        minWidth: 70,
        // maxWidth: 100,
      },
    });
  }
  return columns;
};

export const alphabet_columns = [...getColumns()];
export const getRows = (external = null) => {
  if (IsInvalid(external)) external = alphabet;
  let row = {};
  external.forEach(letter => {
    row = {
      ...row,
      [letter]: '',
    };
  });
  const rows = Array.from(Array(2).keys()).map((x, index) => {
    return {
      id: index,
      ...x,
      ...row,
    };
  });
  return rows;
};
export const alphabet_rows = getRows();

export { default as path } from './path';
