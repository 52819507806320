import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import PersonAddIcon from '@mui/icons-material/PersonAdd';

class EditSequenceBottom extends Component {
  render() {
    // const { classes, saved } = this.props;
    return (
      <React.Fragment>
        {/* <Tooltip title={'Save Changes'}>
          <span>
            <Fab
              aria-label="save-team"
              disabled={saved}
              onClick={this.props.handleSaveTeam}
              size="small">
              <SaveIcon />
            </Fab>
          </span>
        </Tooltip>
        <Tooltip title={'Team Chat'}>
          <Fab
            aria-label="channel-team"
            className={classes.expand}
            onClick={this.props.handleTeamChannel}
            size="small">
            <ChatIcon />
          </Fab>
        </Tooltip> */}
        {/* <Tooltip title={'Add Team Member'}>
          <Fab
            aria-label="add"
            className={classes.expand}
            color="primary"
            onClick={() => this.props.handleAddMember(true)}
            size="small">
            <PersonAddIcon />
          </Fab>
        </Tooltip> */}
      </React.Fragment>
    );
  }
}

EditSequenceBottom.propTypes = {
  classes: PropTypes.object,
};
EditSequenceBottom.defaultProps = {};

export default EditSequenceBottom;
