import React from 'react';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import useStyles from '../components/Styles';

import { MainTable } from '../components';
import { SelectedArray } from 'helpers';

export const columns = [
  {
    id: 'name',
    label: 'Owner',
    minWidth: 100,
  },
  {
    id: 'type',
    label: 'Type',
    align: 'left',
    minWidth: 170,
  },
];

class TableToShareSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.cleanSelected = () => {
        this.setState({ selected: [] });
      };
    }
  }

  handleSelectedClick = (event, index, row) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.id).indexOf(row.id)
      : 0;
    const newSelected = SelectedArray(selected, row, selectedIndex);
    this.setState({ selected: newSelected });
    this.props.handleSelectionChanged(newSelected);
  };
  isSelected = row => {
    const { selected } = this.state;
    const i = selected.map(x => x.id).indexOf(row.id);
    return i === -1 ? false : true;
  };
  handleSelectedRow = item => {
    this.props.handleSelectedRow(item);
  };
  handleAcceptReject = () => {};
  handleButtonView = () => {};
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selected: rows });
    } else {
      this.setState({ selected: [] });
    }
  };

  render() {
    const { classes, rows, loading } = this.props;
    const { selected } = this.state;
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <MainTable
            columns={columns}
            dense={this.props.dense}
            handleAcceptReject={this.handleAcceptReject}
            handleButtonView={this.handleButtonView}
            handleSelectAllClick={this.handleSelectAllClick}
            handleSelectedClick={this.handleSelectedClick}
            handleSelectedRow={this.handleSelectedRow}
            isSelected={this.isSelected}
            loading={loading}
            rows={rows}
            selected={selected}
          />
        </div>
      </Paper>
    );
  }
}

TableToShareSeries.defaultProps = {
  dense: false,
  rows: [],
  deleting: false,
  loading: false,
};
TableToShareSeries.propTypes = {
  rows: PropTypes.array,
};
export default withStyles(TableToShareSeries, useStyles);
