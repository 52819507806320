import {
  ApolloClient,
  ApolloLink,
  split,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';

import { HTTP_ADDRESS_GRAPHQL, WS_ADDRESS_GRAPHQL } from '../config';
import { store } from 'redux-store/store';
import { logout } from 'redux-store/actions/loginActions';

const httpLink = new HttpLink({
  uri: HTTP_ADDRESS_GRAPHQL,
});

const AuthLink = (operation, next) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (token) {
    operation.setContext(context => ({
      ...context,
      headers: {
        ...context.headers,
        Authorization: `Bearer ${token}`,
        'x-token': token,
        'x-refresh-token': refreshToken,
      },
    }));
  }
  return next(operation);
};

const httpLinkWithMiddleware = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    let need_logout = false;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        if (extensions.code === 'UNAUTHENTICATED') {
          console.log('UNAUTHENTICATED');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          client.resetStore();
          need_logout = true;
        }
        if (message === 'You need to be logged in.') {
          need_logout = true;
        }
      });
      if (networkError) {
        /* empty */
      }
    }
    if (need_logout) {
      store.dispatch(logout());
    }
  }),
  AuthLink,
  httpLink,
]);

const wsLink = new GraphQLWsLink(
  createClient({
    // lazy: true,
    url: WS_ADDRESS_GRAPHQL,
    connectionParams: () => {
      return {
        reconnect: true,
        reconnectInterval: 1000, // try to reconnect every 1 second
        reconnectTries: 10, // try to reconnect a maximum of 10 times
        token: localStorage.getItem('token'),
        refreshToken: localStorage.getItem('refreshToken'),
        ua: window.navigator.userAgent,
      };
    },
    credentials: 'omit', //include add cokies
  })
);
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLinkWithMiddleware
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
