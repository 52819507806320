import * as m4 from './m4';
export const radToDeg = r => {
  return (r * 180) / Math.PI;
};

export const degToRad = d => {
  return (d * Math.PI) / 180;
};
export const loadImageAndCreateTextureInfo = (This, dicom_slice) => {
  const gl = This.gl;
  let textureInfo = {
    width: 1, // we don't know the size until it loads
    height: 1,
    texture: 0,
  };
  let texture_id = 0;
  if (!This.textureInfo) {
    texture_id = gl.createTexture();
    // console.log('Creating: ', { texture_id });
    gl.bindTexture(gl.TEXTURE_2D, texture_id);
    // Fill the texture with a 1x1 blue pixel.
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      1,
      1,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      new Uint8Array([0, 0, 255, 255])
    );

    // let's assume all images are not a power of 2
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    textureInfo.texture = texture_id;
  } else {
    texture_id = This.textureInfo.texture;
    textureInfo = This.textureInfo;
    // console.log('Loading: ', { texture_id });
  }

  if (dicom_slice) {
    const width = dicom_slice.width;
    const height = dicom_slice.height;
    const buffer = dicom_slice.getSliceRGBA();
    gl.bindTexture(gl.TEXTURE_2D, texture_id);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      width,
      height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      buffer
    );
    gl.generateMipmap(gl.TEXTURE_2D);
    textureInfo.width = width;
    textureInfo.height = height;
  }
  return textureInfo;
};

export const drawImageOrthografic = (
  This,
  tex,
  texWidth,
  texHeight,
  dstX = 0,
  dstY = 0
) => {
  const gl = This.gl;
  const program = This.program;
  const positionLocation = This.positionLocation;
  const positionBuffer = This.positionBuffer;
  const texcoordBuffer = This.texcoordBuffer;
  const texcoordLocation = This.texcoordLocation;
  const matrixLocation = This.matrixLocation;
  const textureLocation = This.textureLocation;
  texWidth *= This.actions.xZoom;
  texHeight *= This.actions.yZoom;

  gl.bindTexture(gl.TEXTURE_2D, tex);

  // Tell WebGL to use our shader program pair
  gl.useProgram(program);

  // Setup the attributes to pull data from our buffers
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
  gl.enableVertexAttribArray(texcoordLocation);
  gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 0, 0);

  // this matrix will convert from pixels to clip space
  // m4.orthographic(left, right, bottom, top, near, far)
  var matrix = m4.orthographic(0, gl.canvas.width, gl.canvas.height, 0, -1, 1);

  // this matrix will translate our quad to dstX, dstY
  matrix = m4.translate(matrix, dstX, dstY, 0);

  // this matrix will scale our 1 unit quad
  // from 1 unit to texWidth, texHeight units
  matrix = m4.scale(matrix, texWidth, texHeight, 1);

  // Set the matrix.
  gl.uniformMatrix4fv(matrixLocation, false, matrix);

  // Tell the shader to get the texture from texture unit 0
  gl.uniform1i(textureLocation, 0);

  // draw the quad (2 triangles, 6 vertices)
  gl.drawArrays(gl.TRIANGLES, 0, 6);
};

export const drawImagePerpestive = (
  This,
  tex,
  texWidth,
  texHeight
  // dstX = 0,
  // dstY = 0
) => {
  const gl = This.gl;
  const program = This.program;
  const positionLocation = This.positionLocation;
  const positionBuffer = This.positionBuffer;
  const texcoordBuffer = This.texcoordBuffer;
  const texcoordLocation = This.texcoordLocation;
  const matrixLocation = This.matrixLocation;
  const textureLocation = This.textureLocation;
  const fieldOfViewRadians = This.fieldOfViewRadians;
  const translation = This.translation;
  const rotation = This.rotation;
  const scale = This.scale;

  // texWidth *= This.actions.xZoom;
  // texHeight *= This.actions.yZoom;

  // Enable the depth buffer
  gl.enable(gl.DEPTH_TEST);

  gl.bindTexture(gl.TEXTURE_2D, tex);

  // Tell WebGL to use our shader program pair
  gl.useProgram(program);

  // Setup the attributes to pull data from our buffers
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
  gl.enableVertexAttribArray(texcoordLocation);
  gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 0, 0);

  // Compute the matrix
  var aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  var zNear = 1;
  var zFar = 2000;
  var matrix = m4.perspective(fieldOfViewRadians, aspect, zNear, zFar);
  matrix = m4.translate(matrix, translation[0], translation[1], translation[2]);
  matrix = m4.xRotate(matrix, rotation[0]);
  matrix = m4.yRotate(matrix, rotation[1]);
  matrix = m4.zRotate(matrix, rotation[2]);
  matrix = m4.scale(
    matrix,
    texWidth * scale[0],
    texHeight * scale[1],
    scale[2]
  );

  // Set the matrix.
  gl.uniformMatrix4fv(matrixLocation, false, matrix);

  // Tell the shader to get the texture from texture unit 0
  gl.uniform1i(textureLocation, 0);

  // draw the quad (2 triangles, 6 vertices)
  gl.drawArrays(gl.TRIANGLES, 0, 6);
};
export const drawImageOrthograpic = (
  This,
  tex,
  texWidth,
  texHeight
  // dstX = 0,
  // dstY = 0
) => {
  const gl = This.gl;
  const program = This.program;
  const positionLocation = This.positionLocation;
  const positionBuffer = This.positionBuffer;
  const texcoordBuffer = This.texcoordBuffer;
  const texcoordLocation = This.texcoordLocation;
  const matrixLocation = This.matrixLocation;
  const textureLocation = This.textureLocation;
  const fieldOfViewRadians = This.fieldOfViewRadians;
  const translation = This.translation;
  const rotation = This.rotation;
  const scale = This.scale;

  // texWidth *= This.actions.xZoom;
  // texHeight *= This.actions.yZoom;

  // Enable the depth buffer
  gl.enable(gl.DEPTH_TEST);

  gl.bindTexture(gl.TEXTURE_2D, tex);

  // Tell WebGL to use our shader program pair
  gl.useProgram(program);

  // Setup the attributes to pull data from our buffers
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
  gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
  gl.enableVertexAttribArray(texcoordLocation);
  gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 0, 0);

  // Compute the matrix
  var aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  var zNear = 1;
  var zFar = 2000;
  var matrix = m4.perspective(fieldOfViewRadians, aspect, zNear, zFar);
  matrix = m4.translate(matrix, translation[0], translation[1], translation[2]);
  matrix = m4.xRotate(matrix, rotation[0]);
  matrix = m4.yRotate(matrix, rotation[1]);
  matrix = m4.zRotate(matrix, rotation[2]);
  matrix = m4.scale(
    matrix,
    texWidth * scale[0],
    texHeight * scale[1],
    scale[2]
  );

  // Set the matrix.
  gl.uniformMatrix4fv(matrixLocation, false, matrix);

  // Tell the shader to get the texture from texture unit 0
  gl.uniform1i(textureLocation, 0);

  // draw the quad (2 triangles, 6 vertices)
  gl.drawArrays(gl.TRIANGLES, 0, 6);
};
