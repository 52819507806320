import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useStyles from './Style';
import { ButtonDeleteSeries, ButtonDownloadSeries } from './components';
import { IconButtonLoad, RowText } from 'components';
import { FormatBytes, GetData, GetThumbnailFile } from 'helpers';
import OpenIcon from '@mui/icons-material/ZoomOutMap';
import EditIcon from '@mui/icons-material/BorderColor';
import { MutationUpdateSeriesCounts } from 'graphql/Series/utils_series';
import { IsNormalUser } from 'common';

class SeriesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  updateCounts = () => {
    const { series } = this.props;
    if (series === undefined || !series) return null;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationUpdateSeriesCounts([series.idSeries])
        .then(res => {
          const data = GetData(res);
          const { ok, Series, errors } = data.updateSeriesCounts;
          if (ok) {
            for (let i = 0; i < Series.length; i++) {
              const { idSeries, NumberOfSeriesRelatedInstances } = Series[i];
              if (series.idSeries === idSeries) {
                this.props.series.NumberOfSeriesRelatedInstances = NumberOfSeriesRelatedInstances;
              }
            }
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };

  handleViewSeries = () => {
    const { series, series_view } = this.props;
    this.props.history.push(`${series_view}/${series.idSeries}/view`);
  };
  handleEditSeries = () => {
    const { series, series_view } = this.props;
    this.props.history.push(`${series_view}/${series.idSeries}/edit`);
  };

  render() {
    const { series, classes, handleClickSelected, isSelected } = this.props;
    if (series === undefined || !series) return null;
    let ImageSize = '0x0';
    let Spacing = '0x0x0';
    let Number = '0';
    let title = 'Undefined';
    let size = '0 Bytes';
    let thumbnail = GetThumbnailFile('file.dcm');
    let HealtyWarning = null;
    let sequence_name = 'Not defined';
    if (Object.keys(series).length) {
      const { sequence } = series;
      if (sequence) sequence_name = sequence.name;
      Spacing = series.ImageResolution.Spacing;
      ImageSize = series.ImageSize.Size;
      Number = series.NumberOfSeriesRelatedInstances;
      if (series.SeriesDescription) {
        title = `${series.SeriesDescription} (${series.idSeries})`;
      }
      if (typeof series.Size !== 'undefined') {
        size = FormatBytes(series.Size);
      }
      if (series.Thumbnail && series.Thumbnail !== '') {
        thumbnail = `data:image/jpeg;base64,${series.Thumbnail}`;
      } else if (series && series.Modality === 'SR') {
        thumbnail = '/images/icons/icon_sr.png';
      } else if (series && series.Modality === 'PR') {
        thumbnail = '/images/icons/icon_pr.png';
      }
      if (
        series.NumberOfSeriesRelatedInstances !== series.CurrentNumberOfFiles
      ) {
        Number = `DICOMs: ${series.CurrentNumberOfFiles}, DB: ${series.NumberOfSeriesRelatedInstances}`;
        HealtyWarning = (
          <Grid item md={2} sm={2} xs={2}>
            <IconButtonLoad
              disabled={this.state.loading}
              handleClick={this.updateCounts}
              icon={<ErrorOutlineIcon style={{ color: 'orange' }} />}
              loading={this.state.loading}
              tooltip="Warning this series has problems"
            />
          </Grid>
        );
      }
    }
    let md = 6;
    let sm = 5;
    if (HealtyWarning) {
      md = 5;
      sm = 4;
    }
    let ButtonsDownloadEditDelete = null;
    if (IsNormalUser(this.props.user)) {
      ButtonsDownloadEditDelete = (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          md={md}
          sm={md}
          xs={md}>
          <Grid item>
            <ButtonDownloadSeries Series={series} />
          </Grid>
          <Grid item>
            <ButtonDeleteSeries
              handleSeriesDeleted={this.props.handleSeriesDeleted}
              question_text={
                'This action will delete the seleted Series and its files. This action can not be undone.'
              }
              series={series}
              title_text={'Are you sure that you want to delete this Series?'}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item md={2} sm={4} style={{ minWidth: 290 }} xs={12}>
        <Card
          className={clsx(classes.card, {
            [classes.card_selected]: isSelected,
          })}>
          <CardActionArea onClick={() => handleClickSelected(series)}>
            <CardMedia
              className={classes.cardMedia}
              image={thumbnail}
              title="Image title"
            />
            <CardContent className={classes.cardContent}>
              <Typography component="h2" gutterBottom variant="h6">
                {title}
              </Typography>
              <Divider className={classes.divider} variant="middle" />
              <RowText first="Image Size:" second={ImageSize} />
              <RowText first="Spacing:" second={Spacing} />
              <RowText first="Number of Files:" second={Number} />
              <RowText first="Series Size:" second={size} />
              <RowText first="Sequence:" second={sequence_name} />
            </CardContent>
          </CardActionArea>
          <Divider />
          <CardActions className={classes.card_actions}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              style={{ width: '100%' }}>
              <Grid container item md={md} sm={sm} xs={md}>
                <Grid item>
                  <IconButton onClick={this.handleViewSeries} size="large">
                    <OpenIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.handleEditSeries} size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {HealtyWarning}
              {ButtonsDownloadEditDelete}
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

SeriesCard.propTypes = {
  classes: PropTypes.object.isRequired,
};
SeriesCard.defaultProps = {
  series_view: '/image_analysis/series',
};
export default withStyles(withRouter(SeriesCard), useStyles);
