import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LinearProgress, Toolbar, ToggleButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import DownloadIcon from '@mui/icons-material/GetApp';

import { ButtonViewType, useStyles, menu } from './components';
import { IconButtonLoad, ButtonReload, DivGrow, SortBy } from 'components';

class ToolBarSharedBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
  }

  handleProgress = (event, loading) => {
    let total = 0;
    const { selected } = this.props;
    for (let i = 0; i < selected.length; i++) {
      const { folder, content } = selected[i];
      if (folder) total += folder.Size;
      if (content) {
        const { File, Series } = content;
        if (File) total += File.Size;
        if (Series) total += Series.Size;
      }
    }
    let { loaded } = event;
    if (typeof loading !== 'undefined' && !loading) {
      loaded = total;
    }
    this.updateProgress(loaded, total);
  };
  updateProgress = (loaded, total) => {
    const progress = Math.floor((loaded * 100) / total);
    this.setState({ progress });
  };

  render() {
    const {
      classes,
      handleViewType,
      handleSort,
      orderBy,
      order,
      selected,
      handleReload,
      code,
      downloading,
    } = this.props;
    const { progress } = this.state;
    let ComponentDownloadSelected = null;
    if (selected.length) {
      ComponentDownloadSelected = (
        <IconButtonLoad
          bottom_top
          color="red"
          disabled={downloading}
          handleClick={this.props.handleDownloadSelected}
          icon={<DownloadIcon style={{ color: 'green' }} />}
          left_right
          length={selected.length}
          loading={downloading}
          tooltip="Download Selected"
        />
      );
    }
    return (
      <React.Fragment>
        <Toolbar className={classes.root}>
          <ButtonViewType handleViewType={handleViewType} />
          {ComponentDownloadSelected}
          <DivGrow />
          {/* <ButtonDownloadAll
            handleActionSecondary={this.props.handleDownloadOneAtaTime}
            isMobile={isMobile}
            link={link}
            updateProgress={this.updateProgress}
          /> */}
          <ToggleButton
            aria-label="justified"
            className={classes.sort}
            value="sortby">
            <SortBy
              handleSeleted={handleSort}
              menu={menu}
              order={order}
              orderBy={orderBy}
            />
          </ToggleButton>
          <ButtonReload
            handleReload={() => {
              if (!code) return;
              handleReload(code);
            }}
            small
            // loading={loading}
            tooltip="Reload Link"
            wrapper={{ padding: 0, margin: 0 }}
          />
        </Toolbar>
        {progress > 0 && progress < 100 ? (
          <div className={classes.progress}>
            <LinearProgress value={progress} variant="determinate" />
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

ToolBarSharedBottom.propTypes = {
  classes: PropTypes.object,
};
ToolBarSharedBottom.defaultProps = {
  handleDownloadOneAtaTime: () => '',
  onDownloadFinished: () => '',
};

export default withStyles(withRouter(ToolBarSharedBottom), useStyles);
