import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { ProcessToolBar, columns_process } from './components';
import { QueryProcessFolder } from 'graphql/Process';
import { GetData } from 'helpers';
import { Grid } from '@mui/material';
import { ServerErrorsString } from 'helpers';

class TabProcessFolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      process: [],
      selected: [],
      total: 0,
      clicked: null,
      loading: false,
      page: 0,
      limit: 50,
      init: false,
    };
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoad();
  }
  handleLoad = (new_page = null, new_limit = null) => {
    if (!this.props.isActive) return;
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProcessFolder(page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, process, total, errors } = data.processFolders;
          if (ok) {
            this.setState({
              process,
              total,
              loading: false,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRowClicked = clicked => {
    this.setState({ clicked });
  };
  handleRowDoubleClick = clicked => {
    console.log({ clicked });
  };
  handleSelectedChanged = selected => {
    this.setState({ selected });
  };
  removeSelected = () => {
    const ids = this.state.selected.map(x => x.id);
    let { process } = this.state;
    process = process.filter(x => ids.indexOf(x.id) === -1);
    this.setState({ process, selected: [] });
  };
  addProcess = new_process => {
    const { process } = this.state;
    for (let i = 0; i < new_process.length; i++) {
      const { id } = new_process[i];
      const index = process.map(x => x.id).indexOf(id);
      if (index === -1) {
        process.push(new_process[i]);
      }
    }
    this.setState({ process });
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    const { process, loading, selected } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ProcessToolBar
            addProcess={this.addProcess}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleReload={this.handleLoad}
            isActive={this.props.isActive}
            loading={loading}
            removeSelected={this.removeSelected}
            selected={selected}
          />
        </Grid>
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_selected={selected}
          headers={columns_process}
          height={height - 262}
          loading={loading}
          onHighlightChange={this.handleRowClicked}
          onPageChange={page => this.handleLoad(page, this.state.limit)}
          onRowClicked={this.handleRowClicked}
          onRowDoubleClick={this.handleRowDoubleClick}
          onRowsPerPageChange={limit => this.handleLoad(0, limit)}
          onSelectedChanged={this.handleSelectedChanged}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={process}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Grid>
    );
  }
}

TabProcessFolders.propTypes = {
  classes: PropTypes.object,
};

export default TabProcessFolders;
