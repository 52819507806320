import axios from 'axios';
import { persistor } from 'redux-store/store';
import { jwtDecode } from 'jwt-decode';
import { print } from 'graphql';
import gql from 'graphql-tag';
import {
  deviceType,
  getUA,
  isBrowser,
  isConsole,
  isMobileOnly,
  isSmartTV,
  isTablet,
  osName,
  osVersion,
} from 'react-device-detect';

import {
  GetCurrentUser,
  GetData,
  ServerErrors,
  setAuthorizationToken,
} from 'helpers';
import {
  LOGIN_ERRORS,
  LOGIN_PREV_USER_ID,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_CREATE_PROJECT,
  LOGOUT_USER_LAYAOUT,
  LOGOUT_USER_MANAGER,
  LOGOUT_USER_NOTIFICATION,
  LOGOUT_USER_PAGE_DATABASE,
  LOGOUT_USER_PAGE_EXPLORER,
  LOGOUT_USER_PAGE_PROJECT,
  LOGOUT_USER_PAGE_PROJECTS,
  LOGOUT_USER_PAGE_TEAMS,
  LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS,
  LOGOUT_USER_REGISTER,
  LOGOUT_USER_PAGE_GLOBAL,
  LOGOUT_DICOM_VIEWER,
  LOGOUT_USER_PAGE_SETTING,
  UPDATE_USER_SETTING,
  PAGE_SETTING_DASHBOARD_UPDATE,
} from './types';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { CreateDevice } from 'graphql/Users/utils_request';
import {
  MutationLogOut,
  QueryMe,
  QueryUserSetting,
} from 'graphql/Users/utils_users';

import { store } from '../store';
import { QueryUserDashboard } from 'graphql/Users/utils_dashboard';
import { IsInvalid } from 'helpers';
let ReactGA4 = null;
export const setReactGA4 = react_ga4 => {
  ReactGA4 = react_ga4;
};
const actions_logout = [
  LOGOUT_USER_MANAGER,
  LOGOUT_USER_LAYAOUT,
  LOGOUT_USER_NOTIFICATION,
  LOGOUT_USER_PAGE_DATABASE,
  LOGOUT_USER_PAGE_EXPLORER,
  LOGOUT_USER_PAGE_PROJECT,
  LOGOUT_USER_PAGE_PROJECTS,
  LOGOUT_USER_PAGE_TEAMS,
  LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS,
  LOGOUT_USER_CREATE_PROJECT,
  LOGOUT_USER_REGISTER,
  LOGOUT_USER_PAGE_GLOBAL,
  LOGOUT_DICOM_VIEWER,
  LOGOUT_USER_PAGE_SETTING,
];
axios.interceptors.response.use(response => {
  const token = localStorage.getItem('token');
  // console.log({ token });
  if (!token) {
    const { data } = response;
    if (data && data.data) {
      const { linkContent } = data.data;
      if (linkContent) {
        return response;
      }
    }
    store.dispatch(logout());
    return response;
  }
  const { status } = response;
  if (status === 200) {
    const { data } = response;
    if (data && !data.data) {
      const { errors } = data;
      if (errors) {
        // console.log({ errors });
        const array_errors = ServerErrors(errors);
        if (array_errors) {
          const index = array_errors.indexOf(
            'UNAUTHENTICATED: You need to be logged in.'
          );
          if (index !== -1) {
            store.dispatch(
              setLoginErrors('Session Expired please login again')
            );
            store.dispatch(logout());
            return response;
          }
        }
      }
    }
  }
  const token_header = response.headers['x-token'];
  const re_token_header = response.headers['x-refresh-token'];
  // console.log({ token_header, re_token_header });
  if (token_header !== undefined) {
    if (token_header !== token) {
      const dec_token = jwtDecode(token);
      if (IsInvalid(dec_token)) throw Error('Invalid Token');
      const { user: user_internal } = dec_token;
      const dec_header_token = jwtDecode(token_header);
      if (IsInvalid(dec_header_token)) throw Error('Invalid Header Token');
      const { user: user_response } = dec_header_token;
      const { idSession } = user_internal;
      if (!idSession && idSession === undefined) {
        store.dispatch(logout());
        return;
      }
      const state = store.getState();
      if (
        state &&
        user_internal.id === user_response.id &&
        state.info_user.user.id === user_response.id
      ) {
        if (re_token_header !== undefined) {
          const user_token = setAuthorizationToken(
            token_header,
            re_token_header
          );
          // console.log('user_token', { user_token });
          store.dispatch(setCurrentUser(LOGIN_USER, user_token));
        } else {
          store.dispatch(logout());
        }
      } else {
        store.dispatch(logout());
      }
    }
  } else {
    // console.log('{ localStorage.removeItem(token); }');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
  return response;
});

const MUATITION_LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

export const setCurrentUser = (type, user, dispatch) => {
  // console.log('setCurrentUser', { user });
  if (user) {
    if (user.address && user.address.city) {
      user.city = user.address.city;
    }
    if (user.address && user.address.country) {
      user.country = user.address.country;
    }
  }
  if (typeof dispatch !== 'undefined') {
    dispatch({
      type: type,
      user: user,
      errors: null,
    });
  } else {
    return {
      type: type,
      user: user,
      errors: null,
    };
  }
};
export const setLoginErrors = (errors, dispatch) => {
  const value = {
    type: LOGIN_ERRORS,
    user: null,
    errors: errors,
  };
  if (typeof dispatch !== 'undefined') {
    dispatch(value);
  } else {
    return value;
  }
};
export const clearLoginError = () => dispatch => {
  //
  setLoginErrors(null, dispatch);
};

export const login = (email, password, ReactGA4) => dispatch => {
  axios
    .post(HTTP_ADDRESS_GRAPHQL, {
      query: print(MUATITION_LOGIN),
      variables: {
        email: email,
        password: password,
      },
    })
    .then(async res => {
      const data = GetData(res);
      const { token, refreshToken, ok, errors } = data.login;
      if (!ok) {
        console.log('first level login:', { errors });
        throw errors;
      }
      const user_token = setAuthorizationToken(token, refreshToken);
      if (user_token) {
        await CreateDevice({
          deviceType,
          osVersion,
          osName,
          UA: getUA,
          isBrowser,
          isTablet,
          isSmartTV,
          isConsole,
          isMobileOnly,
        });
      }
      return user_token;
    })
    .then(async final_user => {
      if (IsInvalid(final_user)) {
        throw Error('Unknown error during login');
      }
      ReactGA4.ga('event', 'login', {
        method: 'Google',
      });
      const me_res = await QueryMe();
      const me_data = GetData(me_res);
      const { ok } = me_data.me;
      if (ok) {
        final_user = {
          ...final_user,
          ...me_data.me.user,
        };
      } else {
        console.log('error - ok: ', { me_data });
      }
      setCurrentUser(LOGIN_USER, final_user, dispatch);
      const setting_res = await QueryUserSetting(final_user.id);
      const setting_data = GetData(setting_res);
      const { UserSetting } = setting_data;
      if (UserSetting.ok) {
        const { setting } = UserSetting;
        dispatch({
          type: UPDATE_USER_SETTING,
          setting,
        });
      }
      const dashboard_res = await QueryUserDashboard(final_user.id);
      const dashboard_data = GetData(dashboard_res);
      const { userDashboard } = dashboard_data;
      if (userDashboard.ok) {
        const { setting_dashboard } = userDashboard;
        if (setting_dashboard) {
          const { datestart, dateend, selected_project } = setting_dashboard;
          let { visualization_mode } = setting_dashboard;
          if (IsInvalid(visualization_mode)) visualization_mode = 4;
          let update = { visualization_mode };
          if (datestart) update.datestart = datestart;
          if (dateend) update.dateend = dateend;
          if (selected_project) update.selected_project = selected_project;
          dispatch({
            type: PAGE_SETTING_DASHBOARD_UPDATE,
            update,
          });
        }
      }
    })
    .catch(error => {
      console.log({ error });
      setLoginErrors(ServerErrors(error), dispatch);
    });
};
export const logout = () => async dispatch => {
  const user = GetCurrentUser();
  //
  let prevUserId = null;
  if (user) {
    prevUserId = user.id;
    (async () => {
      MutationLogOut(prevUserId)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.logout;
          if (ok)
            ReactGA4.event({
              action: 'logout',
              method: 'Google',
            });
          else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
        });
    })();
    dispatch({
      type: LOGIN_PREV_USER_ID,
      prevUserId,
    });
  }
  setAuthorizationToken(null);
  await persistor.flush();
  setCurrentUser(LOGOUT_USER, null, dispatch);
  for (const type of actions_logout) {
    dispatch({ type });
  }
};

export const setUpdateLogin = (token, refreshToken) => dispatch => {
  const user = setAuthorizationToken(token, refreshToken);
  setCurrentUser(LOGIN_USER, user, dispatch);
};
export const setUpdateSetting = setting => dispatch => {
  dispatch({
    type: UPDATE_USER_SETTING,
    setting,
  });
};
