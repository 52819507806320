import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getLevels } from 'helpers';

const useStyles = theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingRight: 0,
    marginRight: 0,
  },

  toolbarTitle: {
    flex: 1,
  },
});

class TopBar extends Component {
  render() {
    const { classes, theme, handleDrawerClose, open_drawer } = this.props;
    let title = 'Explorer';
    const { level_1 } = getLevels(this.props);
    if (level_1 === 'shared') {
      title = 'Shared';
    }
    return (
      <Toolbar className={classes.toolbar}>
        {/* <Button size="small">Subscribe</Button> */}
        <Typography
          align="center"
          className={classes.toolbarTitle}
          color="inherit"
          component="h2"
          noWrap
          variant="h5">
          {title}
        </Typography>
        {open_drawer && (
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        )}
      </Toolbar>
    );
  }
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerClose: PropTypes.func,
  open_drawer: PropTypes.bool.isRequired,
};
TopBar.defaultProps = {
  open_drawer: false,
};
const ContainerTopBar = props => {
  const theme = useTheme();
  return <TopBar theme={theme} {...props} />;
};
export default withStyles(withRouter(ContainerTopBar), useStyles);
