/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
  Paper,
  Divider,
  Grid,
} from '@mui/material';
import { UploadStepOne, UploadStepThree, UploadStepTwo } from './components';
import { IsInvalid } from 'helpers';
const steps = ['Project Selection', 'Patient Number', 'Upload'];
class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      completed: {},
      project: null,
      folder: null,
      center: null,
      center_team: null,
      patient_number: '',
    };
    this.holder = {
      getFolderCenterTeam: null,
      cleanSelected: null,
    };
  }

  totalSteps = () => {
    return steps.length;
  };

  completedSteps = () => {
    const { completed } = this.state;
    return Object.keys(completed).length;
  };

  isLastStep = () => {
    const { activeStep } = this.state;
    return activeStep === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };

  handleNext = () => {
    const { activeStep } = this.state;
    // const newActiveStep =
    //   this.isLastStep() && !this.allStepsCompleted()
    //     ? // It's the last step, but not all steps have been completed,
    //       // find the first step that has been completed
    //       steps.findIndex((step, i) => !(i in completed))
    //     : activeStep + 1;
    console.log('handleNext', { activeStep });
    this.handleStep(activeStep + 1);
  };

  handleBack = () => {
    this.setState(prev => ({ activeStep: prev.activeStep - 1 }));
  };

  handleStep = activeStep => {
    console.log('handleStep', { activeStep });
    const { project, patient_number } = this.state;
    if (!project) {
      this.props.handleAddError('You need to select a project first');
    } else {
      if (activeStep === 2 && IsInvalid(patient_number, true)) {
        this.props.handleAddError('You need to select a patient number');
      } else {
        this.setState({ activeStep });
      }
    }
  };

  handleComplete = () => {
    const { activeStep } = this.state;
    const completed = this.state.completed;
    completed[activeStep] = true;
    this.setState({ completed });
    this.handleNext();
  };

  handleReset = () => {
    this.setState({ activeStep: 0, completed: {} });
  };
  handleAddCenterTeam = center_team => {
    if (IsInvalid(center_team)) {
      this.setState({ center: null });
    } else {
      this.setState({ center: center_team.center, center_team });
    }
    console.log({ center_team });
  };
  handlePatientName = event => {
    event.preventDefault();
    // console.log({ patient_number: event.target.value });
    this.setState({ patient_number: event.target.value });
  };
  getStepComponent = () => {
    const {
      activeStep,
      project,
      center,
      patient_number,
      folder,
      center_team,
    } = this.state;
    const { height } = this.props;
    // console.log({ activeStep, project, center, patient_number });
    switch (activeStep) {
      case 0:
        return (
          <UploadStepOne
            handleAddError={this.props.handleAddError}
            handleProjectSelection={selection =>
              this.setState({ ...selection })
            }
            height={height}
            project={project}
          />
        );
      case 1:
        return (
          <UploadStepTwo
            center={center}
            handleAddCenterTeam={this.handleAddCenterTeam}
            handleAddError={this.props.handleAddError}
            handlePatientName={this.handlePatientName}
            height={height}
            holder={this.holder}
            patient_number={patient_number}
            project={project}
          />
        );
      case 2:
        return (
          <UploadStepThree
            center={center}
            center_team={center_team}
            folder={folder}
            handleAddError={this.props.handleAddError}
            height={height - 70}
            patient_number={patient_number}
            project={project}
          />
        );
      default:
        return null;
    }
  };
  render() {
    const { height } = this.props;
    const { activeStep, completed } = this.state;
    let delta = 165;
    if (activeStep === 1) delta = 149;
    if (activeStep === 2) delta = 170;
    return (
      <Paper sx={{ width: '100%' }}>
        <div
          style={{
            minHeight: 60,
            padding: 10,
            paddingTop: 15,
            paddingBottom: 15,
          }}>
          <Stepper activeStep={activeStep} nonLinear>
            {steps.map((label, index) => (
              <Step completed={completed[index]} key={label}>
                <StepButton
                  color="inherit"
                  onClick={() => this.handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <Divider />
          <div style={{ height: height - delta, minHeight: height - delta }}>
            {this.getStepComponent()}
          </div>
          <Divider />
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            style={{ backgroundColor: 'white', zIndex: 20000 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={this.handleBack}
              sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              disabled={!(activeStep + 1 !== steps.length)}
              onClick={this.handleNext}
              sx={{ mr: 1 }}>
              {activeStep + 1 !== steps.length ? 'Next' : 'Upload'}
            </Button>
          </Grid>
        </div>
      </Paper>
    );
  }
}

Upload.propTypes = {
  classes: PropTypes.object,
};

export default Upload;
