import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { getInitials, GetThumbnailFile } from 'helpers';

import { Drawer, DrawerHeader, useStyles } from './useStyles';
import { useTheme } from '@mui/material';

class SideStudySeriesBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, open, handleDrawerClose, height } = this.props;
    let { series, study } = this.props;
    let currentID = 0;
    let text = '';
    if (study && open) {
      text = study.StudyDescription;
    }
    if (study && study.Series && study.Series.length) {
      if (series) {
        currentID = series.idSeries;
      }
    } else if (series) {
      study = { Series: [series] };
      if (series) {
        currentID = series.idSeries;
      }
    } else {
      study = { Series: [] };
    }
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
        overflowY: 'auto',
        overflowX: 'hidden',
      };
      if (!open) {
        style.minWidth = 50;
      }
    }
    return (
      <Drawer
        classes={{
          paper: clsx(classes.drawer_paper, {
            // [classes.drawer_position]: !open,
            // [classes.drawer_mobile]: !isMobileSmall && !open,
          }),
        }}
        // className={classes.root}
        open={open}
        variant="permanent" //persistent,permanent
        //
      >
        <DrawerHeader>
          {text}
          <IconButton onClick={handleDrawerClose}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div style={style}>
          <List>
            {study.Series.map((Series, index) => {
              let {
                idSeries,
                SeriesDescription,
                Thumbnail,
                NumberOfSeriesRelatedInstances,
              } = Series;
              if (SeriesDescription === '' || !SeriesDescription)
                SeriesDescription = 'Empty';
              let thumbnail = GetThumbnailFile('file.dcm');
              if (Thumbnail && Thumbnail !== '') {
                thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
              } else if (Series && Series.Modality === 'SR') {
                thumbnail = '/images/icons/icon_sr.png';
              } else if (Series && Series.Modality === 'PR') {
                thumbnail = '/images/icons/icon_pr.png';
              }
              let selected = currentID === idSeries;
              return (
                <ListItem
                  className={clsx({
                    [classes.item]: true,
                    [classes.selected]: selected,
                  })}
                  disablePadding
                  key={`thumbnail-series-${idSeries}-${index}`}
                  onClick={() => this.props.onSeriesClick(Series)}
                  selected={selected}
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <img
                        alt={getInitials(SeriesDescription)}
                        src={thumbnail}
                        style={{ height: 50, margin: 0, pading: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.text_center}
                          component="div"
                          gutterBottom
                          variant="h6">
                          {SeriesDescription}
                        </Typography>
                      }
                      secondary={`Files: ${NumberOfSeriesRelatedInstances}`}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    );
  }
}

SideStudySeriesBar.propTypes = {
  classes: PropTypes.object,
};

SideStudySeriesBar.defaultProps = {
  open: false,
  handleDrawerClose: () => '',
  isMobileSmall: false,
  study: [],
  series: null,
  onSeriesClick: () => '',
};
const ContainerSideStudySeriesBar = props => {
  const theme = useTheme();
  return <SideStudySeriesBar theme={theme} {...props} />;
};
export default withStyles(ContainerSideStudySeriesBar, useStyles);
