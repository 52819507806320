import React, { Component } from 'react';
import { Subscription } from '@apollo/client/react/components';
import {
  QueryDirectMessages,
  SUBSCRIPTION_NEW_DIRECT_MESSAGES,
} from 'graphql/Communications';
import { GetData, IsInvalid } from 'helpers';

class QueryChatMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idReceiver: -1,
      loading: false,
      messages: [],
    };
  }

  componentDidMount() {
    const { Receiver } = this.props;
    if (Receiver && Receiver.id !== -1) {
      this.handleLoadDirectMessages();
    }
  }
  componentDidUpdate() {
    const { Receiver } = this.props;
    if (Receiver && Receiver.id !== -1) {
      this.handleLoadDirectMessages();
    }
  }
  handleLoadDirectMessages = () => {
    let { loading, idReceiver } = this.state;
    const { Receiver } = this.props;
    if (loading || Receiver === undefined || Receiver.id <= 0) return;
    if (idReceiver === Receiver.id) return;
    idReceiver = Receiver.id;
    this.setState({ loading: true });
    (async () => {
      QueryDirectMessages(idReceiver)
        .then(res => {
          const data = GetData(res);
          this.setState({
            messages: data.directMessages,
            loading: false,
            idReceiver,
          });
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, idReceiver });
        });
    })();
  };
  onSubscriptionDataMessages = ondata => {
    console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { messages } = this.state;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { newDirectMessage } = data;
      if (newDirectMessage) {
        const index = messages.map(x => x.id).indexOf(newDirectMessage.id);
        if (index === -1) {
          messages.unshift(newDirectMessage);
          this.setState({ messages });
          this.props.handleAddLastDirect(newDirectMessage);
        }
      }
    }
  };
  render() {
    const { children, user, Receiver } = this.props;
    const variables = {
      receiverId: -1,
    };
    if (Receiver && Receiver.id) {
      variables.receiverId = Receiver.id;
    }
    const { messages } = this.state;
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataMessages}
        shouldResubscribe
        subscription={SUBSCRIPTION_NEW_DIRECT_MESSAGES}
        variables={variables}>
        {({ loading, error }) => {
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              loading,
              error,
              Receiver,
              user,
              messages,
            });
          });
        }}
      </Subscription>
    );
  }
}

export default QueryChatMessages;
