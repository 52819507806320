import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, CircularProgress, Box } from '@mui/material';
import { getParams } from 'helpers';
import { QueryFolderContentWithMetadata } from 'graphql/Folders';
import { ServerErrorsString, GetData } from 'helpers';
import { ExplorerImageToolbar, DrawerImageDetails } from './components';
import { useStyles } from './components/useStyles';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { GetContentThumbnail, GetContentName } from 'helpers';
class ExplorerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_content: null,
      open: true,
      empty: false,
      hide: false,
    };
    this.holder = {
      openEdit: null,
    };
  }
  getFolderContent = () => {
    const params = getParams(this.props);
    let idFolderContent = null;
    if (params) {
      const { level_1, level_2 } = params;
      if (level_1 && level_2) {
        idFolderContent = parseInt(level_2, 10);
      }
    }
    return idFolderContent;
  };
  handleUpdateMetadata = metadata => {
    const { folder_content } = this.state;
    if (!folder_content || metadata === undefined || !metadata) return;
    // console.log('handleUpdateMetadata', { metadata });
    let { File } = folder_content;
    File = { ...File, metadata };
    this.setState({ folder_content: { ...folder_content, File } });
  };
  updateContent = () => {
    const { folder_content, empty } = this.state;
    const { idType } = this.props;
    const idFolderContent = this.getFolderContent();
    if (!idFolderContent || empty) {
      if (empty) {
        this.props.handleAddError(
          // eslint-disable-next-line quotes
          "The file was deleted or you don't have access"
        );
      }
      this.props.history.push('/dashboard');
    } else if (idFolderContent !== idType || !folder_content) {
      this.loadContent(idFolderContent);
    }
  };
  componentDidMount() {
    this.updateContent();
  }
  componentDidUpdate() {
    this.updateContent();
  }
  loadContent = idNewFolderContent => {
    let { loading, idFolderContent } = this.state;
    if (idNewFolderContent) {
      idFolderContent = idNewFolderContent;
    }
    if (loading || idFolderContent === undefined || !idFolderContent) return;
    this.setState({ loading: true });
    (async () => {
      QueryFolderContentWithMetadata(idFolderContent)
        .then(res => {
          const data = GetData(res);
          const { ok, folder_content, errors } = data.folderContent;
          if (ok) {
            let empty = false;
            if (!folder_content || folder_content === undefined) {
              empty = true;
            }
            this.setState({
              loading: false,
              folder_content,
              idFolderContent,
              empty,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idFolderContent, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleEdit = () => {
    if (this.holder.openEdit) {
      this.setState({ hide: true });
      this.holder.openEdit();
    }
  };
  handleSaveImageName = name => {
    const { folder_content } = this.state;
    if (folder_content.File && folder_content.File.metadata) {
      const { metadata } = folder_content.File;
      folder_content.File.metadata = {
        ...metadata,
        name,
      };
    }
    this.setState({ folder_content });
  };
  render() {
    const { loading, folder_content } = this.state;
    // console.log('ExplorerImage', { folder_content });
    if (loading) {
      return (
        <Grid
          alignItems="center"
          container
          item
          justifyContent="center"
          spacing={3}
          style={{ minHeight: '50%' }}
          xs={12}>
          <CircularProgress />
        </Grid>
      );
    }
    let total_groups = 0;
    if (folder_content && folder_content.metadata) {
      total_groups = folder_content.metadata.group_mappings
        ? folder_content.metadata.group_mappings.length
        : 0;
    }
    const { classes, height } = this.props;
    const { open } = this.state;
    const img = GetContentThumbnail(folder_content);
    const title = GetContentName(folder_content);
    return (
      <div style={{ height: height - 70, overflow: 'auto' }}>
        <div className={classes.container}>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}>
            <ExplorerImageToolbar
              folder_content={folder_content}
              handleAddError={this.props.handleAddError}
              handleDrawerClose={() => this.setState({ open: true })}
              handleUpdateMetadata={this.handleUpdateMetadata}
              hide={this.state.hide}
              holder={this.holder}
              open_meta={open}
            />
            <div style={{ height: height - 135 }}>
              <div
                style={{
                  height: height - 135,
                  width: '100%',
                  overflow: 'auto',
                }}>
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{ height: '100%' }}>
                  <Grid item xs={12}>
                    <Box
                      alt={title}
                      component="img"
                      src={img}
                      sx={{
                        // height: '100%',
                        display: 'block',
                        // maxHeight: height - 200,
                        overflow: 'hidden',
                        width: '100%',
                        padding: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        zIndex: -1,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <DrawerImageDetails
            folder_content={folder_content}
            handleAddError={this.props.handleAddError}
            handleDrawerClose={() => this.setState({ open: false })}
            handleEdit={this.handleEdit}
            handleSaveImageName={this.handleSaveImageName}
            height={height}
            open={open}
            total_groups={total_groups}
          />
        </div>
      </div>
    );
  }
}

ExplorerImage.propTypes = {
  handleAddError: PropTypes.func,
};
ExplorerImage.defaultProps = {
  handleAddError: () => '',
};

export default withStyles(withRouter(ExplorerImage), useStyles);
