import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { SearchProjectsAtUser, SearchUsersInput } from 'components';
import { Grid } from '@mui/material';
import { IsInvalid, getParams } from 'helpers';

class TabUserToolBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'users',
    };
    const { holder } = props;
    if (holder) {
      holder.getType = () => this.state.type;
    }
  }
  componentDidMount() {
    const { level_1, level_2, level_3 } = getParams(this.props);
    let type = 'users';
    if (level_1 && level_2) {
      if (level_3 && level_3 === 'projects') type = 'projects';
    }
    this.setState({ type });
  }
  handleChange = (event, type) => {
    if (IsInvalid(type)) return;
    this.setState({ type });
    this.props.handleReset(type);
  };
  handleSelected = selected => {
    const { type } = this.state;
    if (type === 'users') this.props.handleSetUser(selected);
    else {
      this.props.handleSetProject(selected);
    }
  };
  render() {
    const { type } = this.state;
    return (
      //style={{ margin: 0, marginBottom: 10 }}
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        sx={{ margin: 0, marginBottom: 1 }}>
        <Grid item sx={{ minWidth: 180 }} xs={2}>
          <ToggleButtonGroup
            aria-label="Platform"
            color="primary"
            exclusive
            onChange={this.handleChange}
            size="small"
            value={this.state.type}>
            <ToggleButton value="users">Users</ToggleButton>
            <ToggleButton value="projects">Projects</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs>
          {type === 'users' ? (
            <SearchUsersInput
              filter={false}
              handleAddUser={this.handleSelected}
            />
          ) : (
            <SearchProjectsAtUser
              handleAddProject={this.handleSelected}
              handleSetProject={this.handleSelected}
              placeholder="Search Projects..."
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

TabUserToolBar.propTypes = {
  classes: PropTypes.object,
};

export default TabUserToolBar;
