/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { IsValid, IsInvalid, FormatTime } from 'helpers';
import {
  ClinicalSingleChildObjectViewer,
  ClinicalMultiChildObjectViewer,
} from './components';

class ClinicalChildObjectViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  formatDate = value => {
    if (IsInvalid(value)) return '';
    if (value === undefined || !value) return '';
    return value ? FormatTime(value, 'MMM Do, YYYY (h:mm a)') : '';
  };

  getValue = (value, type) => {
    if (type === 'boolean') {
      /** empty */
    }
    if (IsValid(value)) {
      return value.toString();
    }
    return '-';
  };

  render() {
    const { object, title, columns } = this.props;

    const isArray = Array.isArray(object);
    return (
      <Box>
        <Typography
          display="inline"
          gutterBottom
          style={{
            marginRight: '5px',
            marginBottom: '20px',
            textTransform: 'none',
          }}
          sx={{ p: 1 }}
          variant="h6">
          {title}
        </Typography>
        {isArray ? (
          <ClinicalMultiChildObjectViewer
            clinicalObject={object}
            columns={columns}
          />
        ) : (
          <ClinicalSingleChildObjectViewer
            clinicalObject={object}
            columns={columns}
          />
        )}
      </Box>
    );
  }
}

ClinicalChildObjectViewer.propTypes = {
  classes: PropTypes.object,
};

export default ClinicalChildObjectViewer;
