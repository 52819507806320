import * as XLSX from 'xlsx';

let minNumberOfRows = 40;
let minNumberOfColumns = 20;
let lastCell = XLSX.utils.encode_cell({
  c: minNumberOfColumns - 1,
  r: minNumberOfRows - 1,
});
const operators = ['*', '+', '-', '/'];

const configureMinValues = matrix => {
  if (minNumberOfRows <= matrix.length) {
    minNumberOfRows = matrix.length + 10;
  }
  if (minNumberOfColumns <= matrix[0].length) {
    minNumberOfColumns = matrix[0].length + 5;
  }
  lastCell = XLSX.utils.encode_cell({
    c: minNumberOfColumns - 1,
    r: minNumberOfRows - 1,
  });
};

export const getWorksheetContent = (props, activeSheet) => {
  const { SheetNames, Sheets } = props;
  const worksheet = Sheets[SheetNames[activeSheet]];
  const matrix = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    defval: '',
    raw: true,
    cellFormula: true,
  });
  if (
    minNumberOfRows <= matrix.length ||
    minNumberOfColumns <= matrix[0].length
  ) {
    configureMinValues(matrix);
  }
  const worksheetWithFunctions = XLSX.utils.sheet_to_formulae(worksheet);
  let mat = new Array(minNumberOfRows);
  for (let i = 0; i < mat.length; i++) {
    mat[i] = new Array(minNumberOfColumns).fill({ value: '', result: '' });
  }
  const newMatrix = matrix.map(rows => {
    return rows.map(elem => {
      if (worksheetWithFunctions.length > 0) {
        return {
          value: elem,
          result: worksheetWithFunctions.shift().split('=')[1],
        };
      } else {
        return { value: elem, result: elem };
      }
    });
  });
  newMatrix.map((row, i) => {
    return row.map((value, j) => {
      return (mat[i][j] = value);
    });
  });
  return mat;
};

export const arrayToWorksheet = (
  sheet,
  workbook,
  activeCell,
  coordinates,
  cellValueType,
  activeSheet
) => {
  if (
    typeof workbook.Sheets[workbook.SheetNames[activeSheet]][activeCell] ===
      'undefined' ||
    typeof workbook.Sheets[workbook.SheetNames[activeSheet]] === 'undefined'
  ) {
    workbook.Sheets[workbook.SheetNames[activeSheet]][
      '!ref'
    ] = `A1:${lastCell}`;
    const newValue = {
      t: cellValueType,
      v: sheet[coordinates.r][coordinates.c].value,
      w: sheet[coordinates.r][coordinates.c].result,
    };
    workbook.Sheets[workbook.SheetNames[activeSheet]][activeCell] = newValue;
  }
  const worksheetReferences = workbook.Sheets[workbook.SheetNames[activeSheet]];
  let excelworksheet = {
    '!margins': worksheetReferences['!margins'],
    '!ref': worksheetReferences['!ref'],
  };
  // const transformedSheet = sheet.map((row, i) => {
  //   return row.map((col, j) => {
  //     const cellAddress = XLSX.utils.encode_cell({ c: j, r: i });
  //     let cellObj = worksheetReferences[cellAddress];
  //     if (typeof cellObj !== 'undefined') {
  //       cellObj['v'] = col.value;
  //       excelworksheet[cellAddress] = cellObj;
  //     }
  //   });
  // });
  workbook.Sheets[workbook.SheetNames[activeSheet]] = excelworksheet;
  return workbook;
};

const findReferences = (substring, arr) => {
  const flatArr = arr.flatMap((innerArr, i) =>
    innerArr.map((elem, j) => [i, j, elem])
  );
  const indices = flatArr
    // eslint-disable-next-line no-unused-vars
    .filter(([i, j, elem]) => elem.result.includes(substring))
    .map(([i, j]) => [i, j]);
  return indices;
};

const getCellValue = (cell, sheet) => {
  let value;
  if (isNaN(cell)) {
    const cellInfo = XLSX.utils.decode_cell(cell);
    value = sheet[cellInfo.r][cellInfo.c].value;
  } else {
    value = cell;
  }
  return value;
};

const resolveCellFx = (op, operands) => {
  let newCellValue = 0;
  switch (op) {
    case '+':
      newCellValue = operands.reduce(
        (accumulator, currentValue) => accumulator + parseFloat(currentValue)
      );
      break;
    case '-':
      newCellValue = operands.reduce(
        (accumulator, currentValue) => accumulator - parseFloat(currentValue)
      );
      break;
    case '*':
      newCellValue = operands.reduce(
        (accumulator, currentValue) => accumulator * parseFloat(currentValue)
      );
      break;
    case '/':
      newCellValue = operands.reduce(
        (accumulator, currentValue) => accumulator / parseFloat(currentValue)
      );
      break;
    default:
      console.log('Invalid operator');
  }
  return newCellValue;
};

export const cellContainsFunction = fx => {
  const escapedArr = operators.map(item =>
    item.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  );
  const regex = new RegExp(escapedArr.join('|'));
  if (regex.test(fx)) {
    return true;
  }
  return false;
};

export const getOperands = (sheet, cellValue) => {
  let result;
  for (const op of operators) {
    if (cellValue.includes(op)) {
      const variables = cellValue.split(op);
      const operands = variables.map(variable => getCellValue(variable, sheet));
      result = resolveCellFx(op, operands);
    }
  }
  return result;
};

const solveEquation = (sheet, fx, indices) => {
  let newCellValue = 0;
  if (cellContainsFunction(fx)) {
    operators.map(op => {
      // const variables = fx.split(op);
      if (fx.includes(op)) {
        const variables = fx.split(op);
        const operands = variables.map(variable =>
          getCellValue(variable, sheet)
        );
        newCellValue = resolveCellFx(op, operands);
      }
    });
  } else {
    const cellAddress = XLSX.utils.decode_cell(fx);
    if (typeof sheet[cellAddress.r][cellAddress.c] !== 'undefined') {
      newCellValue = sheet[cellAddress.r][cellAddress.c].value;
    }
  }
  return sheet.map((arr, i) => {
    if (indices[0] != i) {
      return [...arr];
    } else {
      return arr.map((cell, j) => {
        if (j != indices[1]) {
          return { ...cell };
        } else {
          return {
            ...cell,
            value: newCellValue,
          };
        }
      });
    }
  });
};

export const updateAllCellsRecursively = params => {
  const { sheet, cellAddress } = params;
  let result = [];
  const indices = findReferences(cellAddress, sheet);
  if (indices.length > 0) {
    let newCellAddress = XLSX.utils.encode_cell({
      c: indices[0][1],
      r: indices[0][0],
    });
    const allIndices = [...params.references, ...indices];
    return updateAllCellsRecursively({
      ...params,
      cellAddress: newCellAddress,
      references: allIndices,
    });
  } else {
    if (params.references.length > 0) {
      return params.references.map(cell => {
        let fx = sheet[cell[0]][cell[1]].result;
        result = result.length === 0 ? sheet : result;
        result = solveEquation(result, fx, cell);
        return result;
      });
    }
  }
};
