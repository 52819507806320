export { default as LatestMessages } from './LatestMessages';
export { default as LatestNotifications } from './LatestNotifications';
export const useStyles = theme => ({
  root: {
    boxShadow: 'none',
    background: '#095275',
    // position: 'relative',
    // zIndex: theme.zIndex.drawer - 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  sign_out: {
    marginLeft: theme.spacing(1),
    palette: {
      primary: 'white',
      secondary: 'white',
    },
    color: 'white',
  },
  colorBudget: {
    span: { color: 'green' },
    palette: {
      primary: 'red',
    },
    color: 'white',
  },
});
