import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCellThumbnail from '../TableCellThumbnail';
import { ButtonDownloadExplorerContent } from 'components';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { ReadyIcon } from 'views/DownloadUpload/components/helpers';
import { FormatBytes, FormatTime, GetContentThumbnail } from 'helpers';
class TableRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
    };
  }
  GetThumbnail = row => {
    const { folder, content } = row;
    if (folder) return '';
    let Thumbnail = GetContentThumbnail(content);
    if (
      Thumbnail &&
      !Thumbnail.startsWith('/images/') &&
      !Thumbnail.startsWith('data:image/')
    ) {
      Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
    }
    return Thumbnail;
  };
  handleProgress = ({ loaded, total }, loading) => {
    const { item } = this.props;
    const t = this.state.total;
    if (total < loaded) {
      if (t === 0 || loaded > t) {
        total = loaded + loaded * 0.4;
        this.setState({ total });
      }
    }
    this.props.handleProgress({ loaded, total }, loading, item);
  };
  handleDownloadFinished = error => {
    const { item } = this.props;
    this.props.handleDownloadFinished(item, error);
  };
  handleDowloadClicked = () => {
    const { item } = this.props;
    this.props.handleDowloadClicked(item);
  };
  render() {
    const {
      classes,
      row,
      index,
      handleDoubleClick,
      handleSelected,
      isSelected,
      download_item,
    } = this.props;
    const { downloaded, loading, error, loaded } = download_item;
    const { content, folder, name, Owner } = row;
    const Thumbnail = this.GetThumbnail(row);
    let selected_content = [];
    let selected_folder = [];
    let tool_tip = '';
    if (content) {
      selected_content.push(content);
      tool_tip = `Download file: ${name}`;
    }
    if (folder) {
      selected_folder.push(folder);
      tool_tip = `Download folder: ${name}`;
    }
    let expand = false;
    let ComponentDownloaded = null;
    if (downloaded && !loading) {
      expand = true;
      ComponentDownloaded = (
        <Tooltip title={error ? error : ''}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton aria-label="ready" disabled edge="end" size="large">
              {error ? (
                <ErrorOutlineIcon
                  style={{
                    fontSize: 40,
                    color: 'orange',
                  }}
                />
              ) : (
                <ReadyIcon
                  color="primary"
                  style={{
                    fontSize: 40,
                    color: '#1b5e20',
                  }}
                  viewBox={'0 0 16 16'}
                />
              )}
            </IconButton>
          </span>
        </Tooltip>
      );
    } else if (loading && loaded) {
      expand = true;
      const total = FormatBytes(loaded);
      ComponentDownloaded = (
        <Grid item>
          <Typography variant="body2">{total}</Typography>
        </Grid>
      );
    }
    return (
      <TableRow key={`row-table-shared-${index}`}>
        <TableCell className={classes.tableCell} padding="checkbox">
          <Checkbox
            checked={isSelected(row)}
            inputProps={{ 'aria-labelledby': index }}
            onClick={() => {
              handleSelected(row);
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <TableCellThumbnail
            content={row}
            handleDoubleClick={handleDoubleClick}
            name={name}
            Thumbnail={Thumbnail}
          />
        </TableCell>
        <TableCell>{Owner}</TableCell>
        <TableCell align="right">{FormatBytes(row.Size)}</TableCell>
        <TableCell align="right">
          {FormatTime(row.createdAt, 'MMM Do - YYYY, h:mm a')}
        </TableCell>
        <TableCell align="right">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ width: expand ? 100 : 50 }}>
            {ComponentDownloaded}
            <ButtonDownloadExplorerContent
              circle_size={39}
              circle_style={classes.fabProgress}
              enable_badge={false}
              handleProgress={this.handleProgress}
              onDownloadClicked={this.handleDowloadClicked}
              onDownloadFinished={this.handleDownloadFinished}
              selected_content={selected_content}
              selected_folder={selected_folder}
              tool_tip={tool_tip}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
}

TableRows.propTypes = {
  classes: PropTypes.object,
};

export default TableRows;
