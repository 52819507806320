import { webglViewer, MouseWebGL, ActionViewer } from './utils';
import CameraWebGL from './CameraWebGL';
import {
  ImageWebGL,
  LineWebGL,
  DrawWebGlLines,
  DrawWebGLAngles,
} from './WebGLObjects';
class SceneWebGL {
  canvas = null;
  gl = null;
  IMG_W = 300;
  IMG_H = 150;
  action = 'None';
  constructor(params) {
    const { idViewer, version, width, height } = params;
    const reply = webglViewer.GlAndCanvas(idViewer, width, height, version);
    if (!reply) return;
    this.canvas = reply.canvas;
    this.gl = reply.gl;
    this.gl.lineWidth(0.5);
    this.camera = new CameraWebGL('orthographic', this.gl);
    this.image_frame = new LineWebGL(this.gl);
    this.lines = new DrawWebGlLines(this.gl);
    this.angles = new DrawWebGLAngles(this.gl);
    this.image = new ImageWebGL(this.gl);
    this.mouse = new MouseWebGL(this.gl);
    this.actions = new ActionViewer();
  }
  setAction = action => {
    console.log({ action });
    if (action === 'contrast') {
      this.actions.setContrast(true);
    } else if (action === 'zoom') {
      this.actions.setPan(false);
      this.actions.setZoom(true);
      this.actions.setContrast(false);
      this.actions.press = false;
    } else if (action === 'pan') {
      this.actions.setPan(true);
      this.actions.setZoom(false);
      this.actions.setContrast(false);
    } else {
      this.actions.setPan(false);
      this.actions.setZoom(false);
      this.actions.setContrast(false);
    }
  };
  keyEvent = (key, event) => {
    if (key === 'Shift') {
      if (event === 'down' || event === 'up') {
        this.actions.setPan(event === 'down' ? true : false);
      }
    } else if (key === 'r') {
      this.actions.reset();
      this.camera.reset();
      this.drawScene();
    }
  };
  mouseEvent = (mouseEvent, event) => {
    if (!mouseEvent) return;
    this.mouse.updateMouse(mouseEvent, event);
    let point3d = null;
    if (this.image.IsReady()) {
      point3d = this.mouse.getWorldCordinates(this.camera, this.image);
    }
    // console.log({ b: mouseEvent.buttons });
    if (event === 'down') this.actions.press = true;
    if (event === 'up') this.actions.press = false;
    if (!mouseEvent.buttons && this.actions.press) this.actions.press = false;
    switch (mouseEvent.which) {
      case 1:
        if (event === 'down' || event === 'up') {
          console.log(`click - left - ${event}`);
          if (this.action === 'Length') this.lines.moveClick(point3d, event);
          else if (this.action === 'Angle')
            this.angles.moveClick(point3d, event);
        }
        break;
      case 2:
        break;
      case 3:
        if (event === 'down' || event === 'up') {
          console.log(`click - right - ${event}`);
          this.actions.press = true;
          this.actions.setZoom(event === 'down' ? true : false);
        }
        break;
      default:
        break;
    }
    if (event === 'move') {
      this.zoomingFunction();
      this.panningFunction();
      this.updateContrast();
      if (this.action === 'Length') this.lines.moveMove(point3d);
      else if (this.action === 'Angle') this.angles.moveMove(point3d, event);
    }

    this.mouse.updateLast();
  };
  IsZoom() {
    return this.actions.flagZoom;
  }
  IsContrast() {
    return this.actions.flagContrast;
  }
  getContrast() {
    return this.image.getContrast();
  }
  zoomingFunction = () => {
    if (!this.actions.flagZoom || !this.actions.press) return;
    if (this.mouse.backward()) {
      this.camera.zoomIn();
      this.drawScene();
    } else if (this.mouse.fordward()) {
      this.camera.zoomOut();
      this.drawScene();
    }
  };
  panningFunction = () => {
    if (!this.actions.flagPan || !this.actions.press) return;
    const deltaX = this.mouse.deltaX();
    const deltaY = this.mouse.deltaY() * -1;
    this.actions.pan(deltaX, deltaY);
    this.camera.pan(this.actions.xPan, this.actions.yPan);
    this.drawScene();
  };
  updateContrast = () => {
    if (!this.actions.flagContrast || !this.actions.press) return;
    const deltaX = this.mouse.deltaX();
    const deltaY = this.mouse.deltaY();
    const min = this.image.getMinimum() + deltaX;
    const max = this.image.getMaximum() + deltaY;
    console.log({ min, max });
    this.image.setMinMax(min, max);
    this.drawScene();
    // this.image.updateSlice(dicom_slice);
    // this.drawScene();
  };
  updateSlice = async dicom_slice => {
    this.clean();
    this.image.updateSlice(dicom_slice);
    // prettier-ignore
    const vertices = dicom_slice.getPlaneLines();
    this.image_frame.setVertices(vertices, [255, 255, 0]);
    // console.log('loaded image ');
    const LOOKAT = dicom_slice.getPlaneCenter();
    const NORMAL = dicom_slice.getPlaneNormal();
    const CAM_POS = LOOKAT.add(NORMAL.multiply(20));
    const U = dicom_slice.getVectorU();
    const V = dicom_slice.getVectorV();
    // console.log({ CAM_POS, LOOKAT });
    this.camera.setVectorU(U);
    this.camera.setVectorV(V);
    // const PS = dicom_slice.getVertices();
    // const l1 = new Line3D(PS[0], PS[1]);
    // const l2 = new Line3D(PS[3], PS[2]);
    // const POINTS = l2
    //   .middlePoint()
    //   .toArray()
    //   .concat(l1.middlePoint().toArray());
    // this.active_line.setVertices(POINTS, [0, 255, 0], 2);
    // console.log({ POINTS });
    // const vectorUp = dicom_slice.getVectorV();
    // const center = new Vector3D(10.86184175, -33.12710429, -132.49517047);
    // const lookAt = new Vector3D(-111.15619455, 151.10691373, -127.63269054);
    // const vectorUp = new Vector3D(0.00289518, 0.02829975, -0.99959529);
    this.camera.cameraMatrix(
      LOOKAT.toArray(),
      CAM_POS.toArray(),
      V.multiply(-1).toArray()
    );
    this.drawScene();
  };
  getMouse() {
    return this.mouse;
  }
  getZoom() {
    let zoom = this.camera.zoom;
    return parseFloat(zoom * 100).toFixed(2);
  }
  clean() {
    this.angles.clean();
    this.lines.clean();
  }
  setMouseAction = action => {
    this.action = action;
  };
  // Draw the scene.
  drawScene() {
    const gl = this.gl;
    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // Clear the canvas AND the depth buffer.
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    // Turn on culling. By default backfacing triangles
    // will be culled.
    gl.enable(gl.CULL_FACE);

    // Enable the depth buffer
    gl.enable(gl.DEPTH_TEST);
    // Compute a view projection matrix
    var viewProjectionMatrix = this.camera.update();
    var projectionMatrix = this.camera.projectionMatrix();
    this.image.draw(viewProjectionMatrix);
    this.image_frame.draw(viewProjectionMatrix);
    this.lines.draw(viewProjectionMatrix);
    this.angles.draw(viewProjectionMatrix, projectionMatrix);
  }
}
export default SceneWebGL;
