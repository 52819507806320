import {
  LOGOUT_USER_PAGE_SETTING,
  PAGE_SETTING_DASHBOARD_SET,
  PAGE_SETTING_DASHBOARD_MODE,
  PAGE_SETTING_DASHBOARD_UPDATE,
  PAGE_SETTING_DASHBOARD_PAGE_DESIGNER,
} from '../actions/types';

// { value: 1, label: 'Global' },
// { value: 2, label: 'Collaborators' }, // Mine and my collaborator projects
// { value: 3, label: 'Project' }, // current project related
// { value: 4, label: 'Mine' }, // filter only my data

export const initialState = {
  dashboard_view: {
    latest_series_sequences: true,
    anatomical_regions: true,
    latest_project_activity: true,
    latest_activity: true,
    pathologies: true,
    investigative_side: true,
    user_by_device: true,
    latest_series_modalities: true,
    image_focus: true,
    visualization_mode: 1, // 1 global, 2: Collaborator, 3: Project, 4: Mine
    selected_project: null,
    fullHistory: true,
    datestart: null,
    dateend: null,
    page_designer: 0,
    limit_designer: 10,
  },
};

export default function reducer_page_settings(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case LOGOUT_USER_PAGE_SETTING:
      return initialState;
    case PAGE_SETTING_DASHBOARD_SET:
      return {
        ...state,
        dashboard_view: action.dashboard_view,
      };
    case PAGE_SETTING_DASHBOARD_MODE:
      return {
        ...state,
        dashboard_view: {
          ...state.dashboard_view,
          visualization_mode: action.visualization_mode,
        },
      };
    case PAGE_SETTING_DASHBOARD_UPDATE:
      return {
        ...state,
        dashboard_view: {
          ...state.dashboard_view,
          ...action.update,
        },
      };
    case PAGE_SETTING_DASHBOARD_PAGE_DESIGNER:
      return {
        ...state,
        dashboard_view: {
          ...state.dashboard_view,
          page_designer: action.page_designer,
          limit_designer: action.limit_designer,
        },
      };
    default:
      return state;
  }
}
