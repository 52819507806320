import isEmpty from 'lodash/isEmpty';
import {
  LOGOUT_USER_REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
} from '../actions/types';

const initialState = {
  isRegistered: false,
  registration: {},
};

export default function reducer_page_register(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_USER_REGISTER:
      return initialState;
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegistered: !isEmpty(action.user),
        registration: action.payload,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        isRegistered: false,
        registration: action.payload,
      };
    default:
      return state;
  }
}
