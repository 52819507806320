import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const CREATE_LANDING_OBJECTS = gql`
  mutation($inputs: [InputCreateLanding!]!) {
    createLandingObjects(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      total
      landings {
        idLanding

        idFileObject
        idUser
        idSequence
        idCenterTeam
        pid

        file {
          idFileObject
          idLanding
          original_name
          Size
          Thumbnail
        }
        user {
          FullName
        }
        sequence {
          name
        }
        center_team {
          id
          center {
            id
            idAdmin
            name
          }
          team {
            id
            name
          }
        }

        isSeries
        processing
        projectIds
        folderIds
        folderStructure
        types
        function_name
        status
        inputs
        num_inputs
        errors

        createdAt
        updatedAt
      }
    }
  }
`;
export const MutationCreateLandingObjects = inputs => {
  const variables = { inputs };
  console.log('MutationCreateLandingObjects', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(CREATE_LANDING_OBJECTS),
    variables,
  });
};
