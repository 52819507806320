import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_ano_tags } from '../Styles';
import { rowsPerPageBig } from 'helpers';
import TabRulesTagsToolbar from './TabRulesTagsToolbar';
import { Grid } from '@mui/material';
import { QueryAnonymizationTags } from 'graphql/Anonymization';
import { ServerErrorsString, GetData } from 'helpers';

class TabRulesTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      loading: false,
      rules: [],
      selected: [],
      page: 0,
      limit: 100,
      init: false,
    };
  }
  componentDidMount() {
    this.handleLoadRulesUser();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoadRulesUser();
  }
  handleLoadRulesUser = () => {
    if (!this.props.isActive) return;
    const { loading, name } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryAnonymizationTags({ name })
        .then(res => {
          const data = GetData(res);
          const { ok, errors, rules, total } = data.anonymizationTags;
          if (ok) {
            this.setState({ loading: false, rules, total, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreatedTagRule = (new_rules, clean_edit = false) => {
    console.log({ new_rules, clean_edit });
    let { rules, total, rows_edited } = this.state;
    for (let i = 0; i < new_rules.length; i++) {
      const { id } = new_rules[i];
      const index = rules.map(x => x.id).indexOf(id);
      if (index === -1) {
        rules.unshift(new_rules[i]);
        total++;
      } else {
        rules[index] = {
          ...rules[index],
          ...new_rules[i],
        };
      }
    }
    if (clean_edit) rows_edited = [];
    this.setState({ rules, total, rows_edited });
  };
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TabRulesTagsToolbar
            classes={this.props.classes}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleCreatedTagRule={this.handleCreatedTagRule}
            handleOpenHelp={this.props.handleOpenHelp}
            handleReload={this.handleLoadRulesUser}
            idProject={this.props.idProject}
            loading={this.state.loading}
            rules={this.state.rules}
            selected={this.state.selected}
            setState={state => this.setState(state)}
          />
        </Grid>
        <Grid item sx={{ width: '100%', paddingRight: 2 }} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_ano_tags}
            height={this.props.height}
            loading={this.state.loading}
            onPageChange={page => this.setState({ page })}
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowsPerPageChange={limit => this.setState({ limit })}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            rowCount={this.state.total}
            rows={this.state.rules}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabRulesTags.propTypes = {
  classes: PropTypes.object,
};

export default TabRulesTags;
