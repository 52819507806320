import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { StudyCard } from 'views/DataImages/components/ViewDatabaseStudy/components';

class PatientGridView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { studies } = this.props;
    return (
      <div style={{ padding: 30 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          spacing={5}>
          {studies.map((Study, i) => {
            const { idStudy } = Study;
            return (
              <Grid
                item
                key={`card-study-${idStudy}-${i}`}
                md={2}
                sm={4}
                style={{ minWidth: 290 }}
                xs={12}>
                <StudyCard
                  // handleStudyDeleted={handleStudyDeleted}
                  // handleUpdateStudies={this.handleUpdateStudies}
                  healthy
                  Study={Study}
                  user={this.props.user}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

PatientGridView.propTypes = {
  Study: PropTypes.object,
};

export default PatientGridView;
