import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const drawerWidth = 240;
export const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  section1: {
    margin: theme.spacing(3, 2),
  },
  button: {
    // margin: theme.spacing(1)
    width: '100px',
  },
  button_red: {
    width: '100px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
});

export const theme_style = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h5: {
          color: '#263238',
          fontSize: '20px',
          // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '-0.05px',
        },
        body1: {
          color: '#263238',
          fontSize: '14px',
          // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          fontWeight: '400',
          lineHeight: '21px',
          letterSpacing: '-0.05px',
        },
        h4: {
          fontSize: '7px',
          fontWeight: 1500,
        },
        h6: {
          fontSize: '15px',
          fontWeight: 500,
          textTransform: 'none',
        },
        h1: {
          fontSize: '7px',
        },
      },
    },
  },
});
