import React from 'react';
import PropTypes from 'prop-types';
import { TableRights, CardEditHeader, TableGrid } from 'components';
import { Divider, Grid, Paper } from '@mui/material';
import { columns, columns_selected } from './Styles';
class FileInfoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_highlight: null,
    };
  }

  render() {
    const { selected } = this.props;
    const { row_highlight } = this.state;
    let rights = [];
    let list_projects = [];
    if (row_highlight) {
      const { projects, folder_contents } = row_highlight;
      for (let j = 0; j < folder_contents.length; j++) {
        const { Rights } = folder_contents[j];
        if (Rights === undefined) continue;
        for (let i = 0; i < Rights.length; i++) {
          const { idRight } = Rights[i];
          const index = rights.map(x => x.idRight).indexOf(idRight);
          if (index === -1) rights.push(Rights[i]);
        }
      }
      for (let j = 0; j < projects.length; j++) {
        const index = list_projects.map(x => x.id).indexOf(projects[j].id);
        if (index === -1) list_projects.push(projects[j]);
      }
    }
    // selected.forEach(x => {

    // });
    // console.log({ s: this.state.selected });
    return (
      <Grid container>
        <Grid item xs={12}>
          <CardEditHeader
            description={'Edit the selected data'}
            handleClose={this.props.handleClose}
            loading={this.state.loading}
            title={'Edit Selected File information'}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ margin: 1 }}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns_selected}
              idRow="idObject"
              onHighlightChange={row_highlight =>
                this.setState({ row_highlight })
              }
              onRowClicked={row_highlight => this.setState({ row_highlight })}
              onSelectedChanged={selected => this.setState({ selected })}
              rows={selected}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ margin: 1 }}>
            <TableRights add_id rights={rights} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ margin: 1 }}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              // height={height - 70}
              // idRow="idObject"
              // loading={loading}
              onPageChange={page => this.setState({ page })}
              onRowsPerPageChange={limit => this.setState({ limit })}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              rows={list_projects}
              rowsPerPage={this.state.limit}
              // rowsPerPageOptions={rowsPerPageBig}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

FileInfoDetails.propTypes = {
  classes: PropTypes.object,
};

export default FileInfoDetails;
