import React from 'react';
import PropTypes from 'prop-types';
import {
  GetSteps,
  handleValueChanged,
  handleSave,
  handleQuery,
} from 'helpers/Surveys';
import { Grid } from '@mui/material';
import { SurveyStepper, ViewerSurvey,defaultSurvey } from 'components';

class TabMedicalCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultSurvey,
    };
  }
  componentDidMount() {
    this.handleQuery();
  }
  componentDidUpdate() {
    this.handleQuery();
  }
  handleQuery = () => {
    handleQuery('surveyMC', this);
  };
  handleSave = (sender, complete) => {
    handleSave(sender, complete, this);
  };
  handleValueChanged = (sender, options) => {
    handleValueChanged(sender, options, this);
  };
  render() {
    const { currentSurvey, currentPageNo, value_changed } = this.state;
    const json = GetSteps('surveyMC');
    let steps = [];
    let pages = [];
    if (json && json.pages) {
      pages = json.pages;
      steps = json.pages.map((x, index) => index);
    }
    if (currentSurvey) {
      currentSurvey.currentPageNo = currentPageNo;
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SurveyStepper
            activeStep={this.state.currentPageNo}
            buttonCancel={this.state.buttonCancel}
            buttonSave={this.state.buttonSave}
            currentPatient={this.props.currentPatient}
            currentSurvey={currentSurvey}
            handleAddNewSurvey={this.props.handleAddNewSurvey}
            handleBack={() =>
              this.setState({ currentPageNo: currentPageNo - 1 })
            }
            handleCreatedSurvey={reply => this.setState({ ...reply })}
            handleNext={() =>
              this.setState({ currentPageNo: currentPageNo + 1 })
            }
            handleSave={this.handleSave}
            handleSaved={() => this.setState({ value_changed: [] })}
            isChanged={Boolean(value_changed.length)}
            isFirstPage={this.state.isFirstPage}
            loading={this.state.loading}
            maxSteps={steps.length}
            pages={pages}
            surveyType="surveyMC">
            <ViewerSurvey
              currentSurvey={currentSurvey}
              handlePageChanged={this.handlePageChanged}
              height={this.props.height}
              loading={this.state.loading}
            />
          </SurveyStepper>
        </Grid>
      </Grid>
    );
  }
}

TabMedicalCenter.propTypes = {
  classes: PropTypes.object,
};

export default TabMedicalCenter;
