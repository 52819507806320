import React from 'react';
import PropTypes from 'prop-types';
import { DialogDeleteWarning, TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { columns } from './utils_mapping';
import {
  MutationDeleteProjectMapping,
  QueryProjectMapping,
} from 'graphql/Projects/utils_project_mapping';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import ToolBarProjectMapping from './ToolBarProjectMapping';
import { Grid } from '@mui/material';
import DialogCreateMapping from './DialogCreateMapping';
import EditProjectMapping from './EditProjectMapping';

class ProjectSettingMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_edit: false,
      open_delete: false,
      open_create: false,
      loading: false,
      selected: [],
      mappings: [],
      page: 0,
      total: 0,
      limit: 100,
      init: false,
    };
    this.holder = {
      cleanCreateState: null,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    this.updateProjectsMapping();
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (!this.state.init) this.updateProjectsMapping();
  }
  updateProjectsMapping = (newPage = null, newLimit = null) => {
    const { idProject } = this.props;
    let { loading, page, limit } = this.state;
    if (loading) {
      return;
    }
    if (newLimit !== undefined && newLimit !== null) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectMapping(idProject, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, total, errors, mappings } = data.projectMappings;
          if (ok) {
            this.setState({ loading: false, total, mappings, init: true });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddNewMapping = new_mappings => {
    const { mappings, total } = this.state;
    let count = 0;
    for (let i = 0; i < new_mappings.length; i++) {
      const { id } = new_mappings[i];
      const index = mappings.map(x => x.id).indexOf(id);
      if (index === -1) {
        mappings.push(new_mappings[i]);
        count++;
      }
    }
    if (this.holder.cleanCreateState) {
      this.holder.cleanCreateState();
    }
    this.setState({ mappings, total: total + count, open_create: false });
  };
  handleDeleteSelected = () => {
    const { deleting, selected, mappings, total } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteProjectMapping(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteProjectMapping;
          if (ok) {
            this.setState({
              selected: [],
              open_delete: false,
              deleting: false,
              mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handlEditMapping = new_mappings => {
    const { mappings } = this.state;
    for (let i = 0; i < new_mappings.length; i++) {
      const { id } = new_mappings[i];
      const index = mappings.map(x => x.id).indexOf(id);
      if (index !== -1) {
        mappings[index] = { ...mappings[index], ...new_mappings[i] };
      }
    }
    this.setState({ mappings, open_edit: false });
  };
  handelAddNewProjectMapping = new_maps => {
    const { mappings } = this.state;
    console.log({ new_maps });
    for (let i = 0; i < new_maps.length; i++) {
      const { id } = new_maps[i];
      const index = mappings.map(x => x.id).indexOf(id);
      if (index === -1) {
        mappings.push(new_maps[i]);
      }
    }
    this.setState({ mappings });
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    const { loading, page, total, limit } = this.state;
    return (
      <Grid
        container
        spacing={2}
        sx={{ width: '100%', paddingLeft: 1, paddingRight: 5 }}>
        <Grid item sx={{ width: '100%' }} xs={12}>
          <ToolBarProjectMapping
            handelAddNewProjectMapping={this.handelAddNewProjectMapping}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelected={() => this.setState({ open_delete: true })}
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReloadTable={() => this.updateProjectsMapping()}
            idProject={this.props.idProject}
            loading_table={loading}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            // external_edited={this.state.rows_edited}
            external_selected={this.state.selected}
            // filter={this.state.filter}
            headers={columns}
            // headers_to_hide={this.state.headers_to_hide}
            height={height - 100}
            loading={loading}
            // onFilterChanded={filter => this.setState({ filter })}
            onPageChange={newPage => this.updateProjectsMapping(newPage, limit)}
            // onRowEditedChanged={rows_edited => this.setState({ rows_edited })}
            onRowsPerPageChange={newLimit =>
              this.updateProjectsMapping(0, newLimit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={page}
            paginationMode="server"
            rowCount={total}
            rows={this.state.mappings}
            rowsPerPage={limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
        <DialogCreateMapping
          handleAddError={this.props.handleAddError}
          handleAddNewMapping={this.handleAddNewMapping}
          handleClose={() => {
            if (this.holder.cleanCreateState) {
              this.holder.cleanCreateState();
            }
            this.setState({ open_create: false });
          }}
          holder={this.holder}
          idProject={this.props.idProject}
          open={this.state.open_create}
        />
        <EditProjectMapping
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handlEditMapping={this.handlEditMapping}
          idProject={this.props.idProject}
          open={this.state.open_edit}
          selected={this.state.selected}
        />
        <DialogDeleteWarning
          handleClose={() => {
            this.setState({ open_delete: !this.state.open_delete });
          }}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={
            'You are about to delete the selected maps, this action cannot be undone'
          }
          title_text={'Delete Project Mappings'}
        />
      </Grid>
    );
  }
}

ProjectSettingMapping.propTypes = {
  classes: PropTypes.object,
};

export default ProjectSettingMapping;
