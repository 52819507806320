import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, InputAdornment } from '@mui/material';
import { SearchVariableTypes } from 'components';
import { inputProps } from 'common';
class EditSelectedType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to_edit: null,
    };
  }
  componentDidUpdate() {
    const { selected } = this.props;
    if (!selected || selected === undefined) return;
    const { to_edit } = this.state;
    if (!to_edit) {
      this.setState({ to_edit: selected });
      this.props.handleUpdateToEdit(selected);
    } else if (to_edit.id !== selected.id) {
      this.setState({ to_edit: selected });
      this.props.handleUpdateToEdit(selected);
    }
  }
  handleAddType = type => {
    let { to_edit } = this.state;
    console.log({ type });
    to_edit = { ...to_edit, type };
    this.setState({ to_edit });
    this.props.handleUpdateToEdit(to_edit);
  };
  render() {
    const { to_edit } = this.state;
    let disable_all = true;
    let type = null;
    if (to_edit) {
      disable_all = false;
      type = to_edit.type;
    }
    return (
      <Grid container spacing={3}>
        <Grid item md={6} style={{ width: '100%' }} xs={12}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">ID:</InputAdornment>
              ),
              ...inputProps,
            }}
            label="Mapping ID Selected"
            // className={clsx(classes.margin, classes.textField)}
            style={{ marginLeft: 8, paddingRight: 8 }}
            value={to_edit ? to_edit.id : ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            disabled={disable_all}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="SeriesDescription"
            margin="dense"
            name="seriesdescription"
            placeholder="Please specify the description of this variable"
            value={to_edit ? to_edit.series.SeriesDescription : ''}
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SearchVariableTypes
            handleAddType={this.handleAddType}
            no_icon
            selected_type={type}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            disabled={disable_all}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label="Variable Type Description"
            margin="dense"
            name="description"
            placeholder="The description of this variable type is defined here"
            value={to_edit && to_edit.type ? to_edit.type.description : ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

EditSelectedType.propTypes = {
  classes: PropTypes.object,
};

export default EditSelectedType;
