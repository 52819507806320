import React from 'react';
import PropTypes from 'prop-types';
import { Link, List, ListItem, Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import {
  HowToReg as PersonAddIcon,
  Lock as LockIcon,
} from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { withRouter } from 'react-router-dom';

import CustomButton from '../../../CustomButton';
import useStyles from './Styles';
import { ButtonPublicAccess } from './components';

const list_public = [
  { buttom: 'Projects', link: '/public_access/projects' },
  { buttom: 'Databases', link: '/public_access/databases' },
  { buttom: 'Software', link: '/public_access/software' },
];
class RightButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleButtonLogIn = () => {
    console.log('handleButtonLogIn');
    const { isAuthenticated, history } = this.props;
    if (isAuthenticated) {
      this.props.logout();
      history.push('/');
    } else {
      history.push('/sign-in');
    }
  };
  handleButtonRegister = () => {
    const { history } = this.props;
    history.push('/sign-up');
  };

  render() {
    const { classes, isAuthenticated } = this.props;

    let Register = null;
    if (!isAuthenticated) {
      Register = (
        <ListItem className={classes.listItem}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            style={{ padding: 6 }}>
            <CustomButton
              className={classes.navLink}
              color="transparent"
              onClick={this.handleButtonRegister}>
              <PersonAddIcon className={classes.icons} /> Register
            </CustomButton>
          </Grid>
        </ListItem>
      );
    }
    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ButtonPublicAccess
            changeColorOnScroll={this.props.changeColorOnScroll}
            dropdownList={list_public.map(({ buttom, link }, index) => {
              return (
                <Link
                  className={classes.dropdownLink}
                  key={`public-access-${buttom}-${index}`}
                  onClick={() => {
                    this.props.history.push(link);
                  }}>
                  {buttom}
                </Link>
              );
            })}
            inDrawer={this.props.inDrawer}
          />
        </ListItem>

        {isAuthenticated ? (
          <ListItem className={classes.listItem}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="center"
              style={{ padding: 6 }}>
              <CustomButton
                className={classes.navLink}
                color="transparent"
                onClick={() => {
                  this.props.history.push('/dashboard');
                }}>
                <DashboardIcon /> Dashboard
              </CustomButton>
            </Grid>
          </ListItem>
        ) : null}
        <ListItem className={classes.listItem}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            style={{ padding: 6 }}>
            <CustomButton
              className={classes.navLink}
              color="transparent"
              onClick={this.handleButtonLogIn}>
              {isAuthenticated ? (
                <React.Fragment>
                  <ExitToAppIcon /> LogOut
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <LockIcon className={classes.icons} /> LogIn
                </React.Fragment>
              )}
            </CustomButton>
          </Grid>
        </ListItem>
        {Register}
      </List>
    );
  }
}

RightButtons.propTypes = {
  classes: PropTypes.object,
  inDrawer: PropTypes.bool,
};
RightButtons.defaultProps = {
  inDrawer: false,
};

export default withStyles(withRouter(RightButtons), useStyles);
