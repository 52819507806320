import React from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { Avatar, Tooltip, Typography } from '@mui/material';
import {
  GetContentName,
  GetContentThumbnail,
  IsFolder,
} from 'helpers/utils_explorer';
import {
  IsProjectFolder,
  IsUploadFolder,
} from 'views/Explorer/components/ExplorerViews/components/ExplorerViewGallery/components/ExplorerViewGalleryItem/Style';
import { IsInvalid } from 'helpers';

const theme_active_button = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      // fontSize: '12',
      // fontFamily: 'Arial'
    },
  },
  // palette: {
  //   primary: green
  // }
});

const useStyles = theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  folder_icon: {
    margin: 0,
    padding: 0,
    marginTop: 5,
    marginLeft: 0,
    color: 'blue',
  },
  icon_folder_shared: {
    margin: 0,
    padding: 0,
    marginTop: 5,
    marginLeft: 0,
    color: 'green',
  },
  move_div: {
    marginLeft: -30,
  },
  icon_folder_project: {
    color: '#90caf9',
  },
  icon_folder_upload: {
    color: '#ffcc80',
  },
});

class TableObjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      title: false,
    };
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.observeTextDimensions();
  }

  componentDidUpdate() {
    this.observeTextDimensions();
  }
  observeTextDimensions() {
    if (IsInvalid(this.textRef.current)) return;
    const name = GetContentName(this.props.row);
    if (this.state.name !== name) {
      const size = name.length;
      let title = false;
      if (size > 20 && this.textRef.current.scrollWidth > 200) {
        title = true;
      }
      // console.log({
      //   t: this.textRef.current.scrollWidth,
      //   l: name.length,
      //   name,
      // });
      this.setState({ name, title });
    }
  }
  handleClick = event => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      isDense,
      row,
      isMobileSmall,
      open_explorer,
      user,
      idHomeFolder,
    } = this.props;
    const name = GetContentName(row);
    let Thumbnail = GetContentThumbnail(row);
    if (Thumbnail) {
      if (
        !Thumbnail.startsWith('/images/') &&
        !Thumbnail.startsWith('data:image/')
      ) {
        Thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
      } else if (Thumbnail === '') {
        Thumbnail = '/images/icons/bin.png';
      }
    }
    // console.log({ row, Thumbnail });
    const isFolder = IsFolder(row);
    let folder_ownership = true;
    let idUser = -1;
    if (isFolder) {
      if (row.Owner) idUser = row.Owner.id;
      if (idUser !== user.id) {
        folder_ownership = false;
      }
    }
    const is_project_folder = IsProjectFolder(row, idHomeFolder);
    const is_upload_folder = IsUploadFolder(row, idHomeFolder);
    // console.log({ idHomeFolder, is_project_folder, is_upload_folder, row });
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme_active_button}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            spacing={2}>
            <Grid item>
              {isFolder ? (
                <FolderIcon
                  className={clsx({
                    [classes.folder_icon]: folder_ownership,
                    [classes.move_div]: isMobileSmall && !open_explorer,
                    [classes.icon_folder_shared]: !folder_ownership,
                    [classes.icon_folder_project]: is_project_folder,
                    [classes.icon_folder_upload]: is_upload_folder,
                  })}
                  fontSize="large"
                  onDoubleClick={() => this.props.handleOpenFolder(row)} //{isDense ? 'medium' : 'large'}
                />
              ) : Thumbnail ? (
                <Avatar
                  alt={name}
                  src={Thumbnail}
                  style={{
                    marginLeft: isMobileSmall && !open_explorer ? -30 : 0,
                  }}
                />
              ) : (
                <DescriptionIcon fontSize={isDense ? 'medium' : 'large'} />
              )}
            </Grid>
            <Grid item xs zeroMinWidth>
              <Tooltip title={this.state.title ? name : ''}>
                <Typography
                  noWrap
                  ref={this.textRef}
                  style={{
                    marginLeft: isMobileSmall && !open_explorer ? -5 : 0,
                    minWidth: 200,
                    paddingRight: 10,
                    marginRight: 20,
                  }}>
                  {name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          {/* </RouterLink> */}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.info_user.user,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(TableObjectItem, useStyles));
