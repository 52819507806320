import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import {
  HomeFooter,
  HomeFooterInformation,
  HomeHeader,
  HomeLogos,
} from 'views/Home/components';
import { HomeMain } from 'components';
import { Typography } from '@mui/material';
import { DatabasesMain } from './components';
import 'views/Home/Home.css';
import useStyles from 'views/Home/Styles';
import { getParams } from 'helpers';

class HomeDatabases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user, isDesktop, isAuthenticated } = this.props;
    let className = undefined;
    const reply = getParams(this.props);
    let variant = 'h2';
    if (reply && reply.level_2) {
      className = classes.tiny;
      variant = 'h4';
    }
    return (
      <React.Fragment>
        <HomeHeader
          changeColorOnScroll={{
            height: 200,
            color: 'white',
          }}
          color="transparent"
          fixed
          isAuthenticated={isAuthenticated}
          isDesktop={isDesktop}
          user={user}
        />
        <HomeMain
          className={className}
          image={'/images/public_access.jpg'}
          small>
          <div className={classes.container}>
            <Typography mb={2} sx={{ color: 'white' }} variant={variant}>
              Public Access
            </Typography>
          </div>
        </HomeMain>
        <div className={clsx(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <DatabasesMain
              handleAddError={this.props.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
            />
          </div>
        </div>
        <HomeLogos />
        <HomeFooterInformation />
        <HomeFooter />
      </React.Fragment>
    );
  }
}

HomeDatabases.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(HomeDatabases), useStyles);
