import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns_docker } from './utils_docker';
import { rowsPerPageBig } from 'helpers';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { MutationDeleteLogDockers, QueryLogDockers } from 'graphql/Logs';
import { Grid } from '@mui/material';
import { GridDockerToolBar } from './components';
import { style_paper } from '../LogsSequenceErrors/components';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  left: {
    paddingLeft: 0,
  },
});
class DockerLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row_highlight: null,
      loading: false,
      selected: [],
      page: 0,
      limit: 100,
      nowrap: false,
      init: false,
    };
    this.holder = {
      getSearchText: null,
    };
  }
  componentDidMount() {
    this.handleLoad();
  }
  componentDidUpdate() {
    if (!this.state.init) this.handleLoad();
  }
  handleLoad = (new_page, new_limit) => {
    if (!this.props.isActive) return;
    const { loading } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    if (new_page !== undefined) {
      page = new_page;
    }
    if (new_limit !== undefined) {
      limit = new_limit;
    }
    let name = null;
    if (this.holder.getSearchText) {
      name = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QueryLogDockers(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, logs, total } = data.logDockers;
          if (ok) {
            this.setState({
              loading: false,
              logs,
              total,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangeCheck = event => {
    this.setState({ nowrap: event.target.checked });
  };
  handleDelete = () => {
    const { deleting, selected, logs } = this.state;
    if (deleting) return;
    if (!selected.length) {
      this.props.handleAddError('You need to select first');
      return;
    }
    const ids = selected.map(x => x.id);
    this.setState({ deleting: true });
    (async () => {
      MutationDeleteLogDockers(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteLogDockers;
          if (ok) {
            this.setState({
              deleting: false,
              logs: logs.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GridDockerToolBar
            className={classes.left}
            deleting={this.state.deleting}
            handleChangeCheck={this.handleChangeCheck}
            handleDeleteSelected={this.handleDelete}
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenCreateUpload={() => this.setState({ open_upload: true })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReload={() => this.handleLoad()}
            holder={this.holder}
            loading={this.state.loading}
            nowrap={this.state.nowrap}
            selected={this.state.selected}
          />
        </Grid>
        <Grid item ml={1} mt={1} pr={2} style={style_paper} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_docker}
            height={this.props.height - 280}
            loading={this.state.loading}
            nowrap={this.state.nowrap}
            onHighlightChange={row_highlight =>
              this.setState({ row_highlight })
            }
            onPageChange={page => this.handleLoad(page, this.state.limit)}
            onRowClicked={row_highlight => this.setState({ row_highlight })}
            onRowsPerPageChange={limit => this.handleLoad(0, limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.logs}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

DockerLogs.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(DockerLogs, useStyles);
