import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { Fab, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = theme => ({
  root: {
    paddingTop: 150,
    width: '100%',
    height: '90vh',
    textAlign: 'center',
    // backgroundColor: 'green'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  mobile: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    left: 0,
    margin: theme.spacing(1),
  },
});

class NoSelection extends React.Component {
  render() {
    const { classes, isMobile, handleDrawerToggle } = this.props;
    let Mobile = null;
    if (isMobile) {
      Mobile = (
        <Fab
          aria-label="add"
          className={classes.mobile}
          // color="primary"
          onClick={handleDrawerToggle}
          variant="extended">
          <MenuIcon />
        </Fab>
      );
    }
    return [
      <div key="mobile-button">{Mobile}</div>,
      <div className={classes.root} key="root-container">
        <img
          alt="Under development"
          className={classes.image}
          src="/images/svg/undraw_work_chat_erdt.svg"
        />
        <Typography variant="h1">Select conversation to display</Typography>
        <Typography style={{ textTransform: 'none' }} variant="subtitle2">
          To start a conversation just click the message button from a person or
          team profile
        </Typography>
      </div>,
    ];
  }
}

NoSelection.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(NoSelection, useStyles);
