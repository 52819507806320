import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

import { TableTopFive } from '../index';
import { QuerySummaryImageFocus } from 'graphql/Series';
import { ButtonReload } from 'components';

import { useStyles } from '../Styles';

class ImageFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.holder = {
      reload: null,
      setLoading: loading => {
        if (loading === this.state.loading) {
          return;
        }
        this.setState({ loading });
      },
    };
  }

  reload = () => {
    this.setState({ loading: true });
    this.holder.reload();
  };

  render() {
    const { classes, className } = this.props;
    const { loading } = this.state;
    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <ButtonReload
              handleReload={this.reload}
              loading={loading}
              small
              tooltip="Reload Image Focus"
            />
          }
          className={classes.reload}
          title="Image Focus"
        />
        <Divider />
        <CardContent className={classes.card_content}>
          <QuerySummaryImageFocus
            handleAddError={this.props.handleAddError}
            holder={this.holder}
            setState={state => this.setState(state)}>
            <TableTopFive type="Focus" />
          </QuerySummaryImageFocus>
        </CardContent>
        <CardActions className={classes.actions} />
      </Card>
    );
  }
}

ImageFocus.propTypes = {
  className: PropTypes.string,
};

export default withStyles(ImageFocus, useStyles);
