import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ImageList,
  ImageListItem,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
// import { v1 as uuid } from 'uuid';
import { PaperComponent } from 'helpers';

import { projectCreateTeams } from 'redux-store/actions/projectActions';

const tileData = Array.from(Array(10).keys());

const useStyles = theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // width: 200
  },
  gridList: {
    width: 500,
    height: 250,
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  avatar_selected: {
    width: '100%',
    height: '100%',
    border: '10px solid #ddd',
  },
});

class DialogChangeTeamAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indexSelected: 1,
      team: null,
    };
  }
  componentDidMount() {
    this.updateIndex();
  }
  componentDidUpdate() {
    this.updateIndex();
  }
  updateIndex = () => {
    let { team } = this.props;
    if (!team) return;
    if (this.state.team && this.state.team.id === team.id) return;
    let default_avatar = team.avatar;
    if (default_avatar && default_avatar.startsWith('/images/teams/team_')) {
      const alt = default_avatar.split('.');
      if (alt.length === 2) {
        const parts = alt[0].split('_');
        if (parts.length === 2) {
          const index = parseInt(parts[1], 10);
          this.setState({ indexSelected: index, team });
        }
      }
    }
  };
  handleClose = () => {
    this.props.handleCloseDialog(false);
  };
  handleClick = event => {
    const { alt } = event.target;
    if (alt) {
      if (alt.startsWith('avatar')) {
        const parts = alt.split('_');
        if (parts.length === 2) {
          const index = parseInt(parts[1], 10);
          this.setState({ indexSelected: index });
        }
      } else {
        /** empty */
      }
    }
  };

  render() {
    const { classes, open, handleSelectAvatar } = this.props;
    const { indexSelected } = this.state;
    return (
      <div>
        <Dialog
          aria-labelledby="draggable-dialog-title"
          onClose={this.handleClose}
          open={open}
          PaperComponent={PaperComponent}>
          <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
            Change Team Avatar
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              To change the avatar of the team, please click the picture.
            </DialogContentText>

            <ImageList className={classes.gridList} cols={5} rowHeight={100}>
              {tileData.map(index => (
                <ImageListItem
                  key={`avatar_${index}`}
                  onClick={this.handleClick}>
                  <Avatar
                    alt={`avatar_${index}`}
                    className={clsx({
                      [classes.avatar]: true,
                      [classes.avatar_selected]: index === indexSelected,
                    })}
                    src={'/images/teams/team_' + index.toString() + '.png'}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (handleSelectAvatar) {
                  handleSelectAvatar(indexSelected);
                } else {
                  this.handleClose();
                }
              }}>
              Select
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogChangeTeamAvatar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  handleCloseDialog: PropTypes.func,
  handleSelectAvatar: PropTypes.func,
  open: PropTypes.bool,
  projectCreateTeams: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
};
DialogChangeTeamAvatar.defaultProps = {
  team: null,
};
const mapStateToProps = state => ({
  teams: state.projects.teams,
});

export default connect(mapStateToProps, {
  projectCreateTeams,
})(withStyles(DialogChangeTeamAvatar, useStyles));
