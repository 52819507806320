import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ButtonInputCircular, ButtonCircularIconLoad } from 'components';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { MutationDownloadSoftware } from 'graphql/Software';
import {
  GetNameFromHeader,
  GetResponseError,
  ServerErrorsString,
} from 'helpers';
const default_software = {
  name: '',
  os: '',
  architecture: '',
  version: '',
  downloads: 0,
};
class ContentSoftware extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      filename: '',
      file: null,
      software: {
        ...default_software,
      },
    };
    const { holder } = props;
    if (holder) {
      holder.getSoftware = () => this.state.software;
      holder.resetSoftware = () => this.setState({ ...default_software });
      holder.getFile = () => this.state.file;
    }
  }
  updateInput = () => {
    const { software } = this.props;
    const in_software = this.state.software;
    if (!software) return;
    // console.log({ software, in_software });
    if (in_software.id === undefined || in_software.id !== software.id) {
      this.setState({
        software: { ...software },
        file: null,
        filename: software.filename ? software.filename : '',
      });
    }
  };
  componentDidMount() {
    this.updateInput();
  }
  componentDidUpdate() {
    this.updateInput();
  }
  handleTextChanged = event => {
    let { software } = this.state;
    software = {
      ...software,
      [event.target.id]: event.target.value,
    };
    this.setState({ software });
    this.props.onContentChanged(this.IsChange(software));
  };
  IsChange = software => {
    if (software.name !== '') return true;
    if (software.os !== '') return true;
    if (software.architecture !== '') return true;
    if (software.version !== '') return true;
    return false;
  };
  handleSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      return;
    }
    const { software } = this.state;
    const { name } = files[0];
    this.setState({ file: files[0], filename: name });
    this.props.handleSelectFile(software, files[0]);
  };
  downloadSoftware = () => {
    const { downloading } = this.state;
    if (downloading) return;
    this.setState({ downloading: true });
    (async () => {
      MutationDownloadSoftware(this.state.software)
        .then(response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          this.props.handleDownloadTriggered(false);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          this.setState({ downloading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { saving, uploading } = this.props;
    const { software, filename, downloading } = this.state;
    return (
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item>
          <TextField
            fullWidth
            id="name"
            InputLabelProps={{
              shrink: true,
            }}
            label="Name"
            onChange={this.handleTextChanged}
            value={software.name}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="os"
            InputLabelProps={{
              shrink: true,
            }}
            label="O.S"
            onChange={this.handleTextChanged}
            value={software.os}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="architecture"
            InputLabelProps={{
              shrink: true,
            }}
            label="Architecture"
            onChange={this.handleTextChanged}
            value={software.architecture}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="version"
            InputLabelProps={{
              shrink: true,
            }}
            label="Version"
            onChange={this.handleTextChanged}
            value={software.version}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="filename"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            label="FileName"
            value={filename}
            variant="standard"
          />
        </Grid>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          item
          justifyContent="flex-start"
          xs={4}>
          <ButtonInputCircular
            accept="*"
            disabled={saving || uploading || downloading}
            handleSelectFile={this.handleSelectFile}
            icon={<UploadFileIcon />}
            loading={uploading}
          />
          <ButtonCircularIconLoad
            color="secondary"
            disabled={software.filename ? false : true || downloading}
            handleButtonClick={() => this.downloadSoftware()}
            icon={<CloudDownloadIcon />}
            loading={saving || downloading}
            name="Download"
            transform={false}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

ContentSoftware.propTypes = {
  onContentChanged: PropTypes.func,
  saving: PropTypes.bool,
  uploading: PropTypes.bool,
};
ContentSoftware.defaultProps = {
  onContentChanged: () => '',
  saving: false,
  uploading: false,
  software: null,
  handleSelectFile: () => '',
  handleDownloadTriggered: () => '',
};

export default ContentSoftware;
