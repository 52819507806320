/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Breadcrumbs, Toolbar } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  setExplorerFolders,
  setExplorerNodeID,
  setExplorerFolderBar,
} from 'redux-store/actions';
import { getInt, getLevels, getParams } from 'helpers';

import { useStyles } from './components';
import { StyledBreadcrumb } from 'components';

class BottomBar extends Component {
  constructor(props) {
    super(props);
    const { folders } = props;
    this.state = {
      folders: folders ? folders : [],
      current_path: folders ? folders.map(x => x.name).join('/') : '',
    };
  }

  getFolderID = () => {
    const { level_1, level_2, level_3 } = getLevels(this.props);
    if (level_1 === 'folder') {
      return parseInt(level_2, 10);
    } else if (level_1 === 'shared' && level_2 === 'folder') {
      return parseInt(level_3, 10);
    } else {
      return null;
    }
  };

  componentDidMount() {
    const { folders } = this.props;
    let current_path = folders.map(x => x.name).join('/');
    this.setState({
      folders,
      current_path,
    });
  }

  componentDidUpdate() {
    this.UpdateList();
  }

  UpdateList = () => {
    let { loading, LocalHomeFolder, folders } = this.props;
    // console.log('UpdateList', { folders, f: this.state.folders });
    if (loading || !LocalHomeFolder) {
      return;
    }
    const params = getParams(this.props);
    const { level_1, level_2 } = params;
    let idFolder = -1;
    if (level_1 === 'folder') {
      idFolder = getInt(level_2);
    } else if (level_1 === 'shared') {
      if (level_2 === 'folder') {
        idFolder = getInt(params.level_3);
      } else {
        //
      }
    }
    if (folders) {
      const { current_path } = this.state;
      let new_path = folders.map(x => x.name).join('/');
      // console.log({ current_path, new_path });
      if (
        current_path !== new_path ||
        this.state.folders.length !== folders.length
      ) {
        this.setState({ folders, current_path: new_path });
        this.props.setExplorerNodeID(idFolder.toString());
        this.props.setExplorerFolders(folders);
      }
    }
  };
  handleBackRootFolder = event => {
    event.preventDefault();
    const { base } = this.props;
    if (base === 'Shared') {
      this.props.history.push('/explorer/shared');
    } else {
      this.props.history.push('/explorer');
    }
    this.props.setExplorerFolders([]);
    // this.setState({ folders: [] });
    this.props.setExplorerNodeID('');
    this.props.changeParentFolder({ idFolder: -1 });
  };
  handleClickFolder = folder => {
    // Here I handle folder navigation with bottom bar...
    const { base, folder_bar_clicked } = this.props;
    const { folders } = this.state;
    const { idFolder } = folder;
    const length = folders.length;
    const index = folders.map(x => x.idFolder).indexOf(folder.idFolder);
    if (index === -1 || index + 1 >= length) {
      if (
        folder_bar_clicked &&
        folder_bar_clicked.idFolder === folder.idFolder
      ) {
        this.props.setExplorerFolderBar(null);
      } else {
        this.props.setExplorerFolderBar(folder);
      }
      return;
    }
    folders.splice(index + 1, length);
    this.state.folders = folders;
    this.props.setExplorerFolders(folders);
    if (folders.length) {
      const new_folder = folders[folders.length - 1];
      this.props.setExplorerNodeID(new_folder.idFolder.toString());
      this.props.changeParentFolder(new_folder);
    } else {
      this.props.setExplorerNodeID('');
      this.props.changeParentFolder({ idFolder: -1 });
    }
    if (base === 'Shared') {
      this.props.history.push(`/explorer/shared/folder/${idFolder}`);
    } else {
      this.props.history.push(`/explorer/folder/${idFolder}`);
    }
  };

  render() {
    const { classes, base } = this.props;
    const { folders } = this.state;
    if (folders.length && folders[0].name === 'Home') {
      folders.splice(0, 1);
    }
    return (
      <Toolbar
        className={classes.toolbarSecondary}
        component="nav"
        variant="dense">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            icon={<HomeIcon fontSize="small" />}
            label={base}
            onClick={this.handleBackRootFolder}
          />
          {folders.map(folder => {
            return (
              <StyledBreadcrumb
                component="a"
                key={`folder_${folder.idFolder}`}
                label={folder.name}
                onClick={() => this.handleClickFolder(folder)}
              />
            );
          })}
        </Breadcrumbs>
      </Toolbar>
    );
  }
}

BottomBar.defaultProps = {
  folders: [],
  loading: false,
  base: 'Home',
};
BottomBar.propTypes = {
  base: PropTypes.string,
  classes: PropTypes.object,
  folders: PropTypes.array.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  const { page_explorer } = state;
  let total_folders = 0;
  let fullpath = '';
  if (page_explorer.folders) {
    total_folders = page_explorer.folders.length;
    fullpath = page_explorer.folders.map(x => x.name).join('/');
  }
  // console.log({ fullpath });
  return {
    fullpath,
    total_folders,
    view_type: page_explorer.view_type,
    folders: page_explorer.folders,
    LocalHomeFolder: page_explorer.LocalHomeFolder,
    folder_bar_clicked: page_explorer.folder_bar_clicked,
  };
};

export default connect(mapStateToProps, {
  setExplorerFolders,
  setExplorerNodeID,
  setExplorerFolderBar,
})(withStyles(withRouter(BottomBar), useStyles));
