import React from 'react';
import PropTypes from 'prop-types';

// react-copy-to-clipboard components
import { CopyToClipboard } from 'react-copy-to-clipboard';
// react-syntax-highlighter components
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Button, Slide, Alert, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { withStyles } from 'tss-react/mui';
import { GetLanguage } from 'common';
const useStyles = () => ({
  text: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    backgroundColor: '#212121',
    color: 'white',
    '&:hover,&:focus': {
      color: 'white',
      backgroundColor: '#424242',
    },
  },
});
class ViewerCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }
  handleSuccess = () => {
    this.setState({ success: true });
    setTimeout(() => this.setState({ success: false }), 3000);
  };
  render() {
    const { success } = this.state;
    const { classes, height, code, filename } = this.props;
    const language = GetLanguage(filename);
    return (
      <Box
        borderRadius="xl"
        position="relative"
        sx={{ overflow: 'hidden' }}
        width="100%">
        <CopyToClipboard text={code}>
          <Button
            className={classes.text}
            onClick={() => this.handleSuccess()}
            size="small"
            startIcon={<ContentCopyIcon />}
            variant="gradient">
            <Box className="fas fa-copy" color="white" mr={0.5} /> Copy
          </Button>
        </CopyToClipboard>
        <Slide direction="down" in={success} unmountOnExit>
          <Box left={0} position="absolute" top="0.5rem" width="100%">
            <Alert
              color="success"
              mx="auto"
              sx={{
                minHeight: '2.5rem !important',
                py: 1,
                justifyContent: 'center',
              }}
              width="25%">
              <Typography fontWeight="bold" variant="body2">
                Code successfully copied!
              </Typography>
            </Alert>
          </Box>
        </Slide>
        <SyntaxHighlighter
          customStyle={{
            height,
            maxHeight: '40rem',
            fontSize: '1rem',
            backgroundColor: grey[100],
            padding: '1rem 1rem 1rem 0.25rem',
            overflowY: 'scroll',
            margin: 0,
          }}
          language={language} //jsx
          showLineNumbers
          style={prism}>
          {code}
        </SyntaxHighlighter>
      </Box>
    );
  }
}

ViewerCode.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number,
};
ViewerCode.defaultProp = {
  height: 300,
  code: '',
};

export default withStyles(ViewerCode, useStyles);
