export const IsChanged = (settings, self) => {
  const { defaultSetting } = self.props;
  if (defaultSetting === undefined || !defaultSetting) return false;
  // if (!defaultSetting.input_type) defaultSetting.input_type = '';
  // if (!defaultSetting.output_type) defaultSetting.output_type = '';
  if (!defaultSetting.ports) defaultSetting.ports = '';
  if (!defaultSetting.volume) defaultSetting.volume = '';
  if (!defaultSetting.access) defaultSetting.access = '';
  if (!defaultSetting.input_commands) defaultSetting.input_commands = '';
  if (!defaultSetting.docker_variables) defaultSetting.docker_variables = '';
  if (!defaultSetting.edited_element) defaultSetting.edited_element = {};
  if (!settings.ports) settings.ports = '';
  if (!settings.volume) settings.volume = '';
  if (!settings.access) settings.access = '';
  if (!settings.input_commands) settings.input_commands = '';
  if (!settings.docker_variables) settings.docker_variables = '';
  if (!settings.edited_element) defaultSetting.edited_element = {};

  // console.log({ defaultSetting, settings });
  if (defaultSetting.volume !== settings.volume) return true;
  if (defaultSetting.ports !== settings.ports) return true;
  if (defaultSetting.execute !== settings.execute) return true;
  if (defaultSetting.access !== settings.access) return true;
  if (defaultSetting.input_commands !== settings.input_commands) return true;
  if (defaultSetting.docker_variables !== settings.docker_variables)
    return true;
  if (defaultSetting.edited_element !== settings.edited_element) return true;
  for (let i = 0; i < defaultSetting.inputs_outputs.length; i++) {
    const element = defaultSetting.inputs_outputs[i];
    if (element.id === settings.edited_element.id) {
      if (settings.edited_element.in_out_type !== element.in_out_type)
        return true;
      if (settings.edited_element.type !== element.type) return true;
      if (settings.edited_element.volume !== element.volume) return true;
      break;
    }
  }
  return false;
};
