import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';

import { selectCurrentProject } from 'redux-store/actions/pageProjectsActions';
import { FormatTime, getUserFullName } from 'helpers';
import { RowText, IconButtonLoad } from 'components';

const useStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    maxHeight: 300,
  },
  imageContainer: {
    height: 64,
    width: 64,
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center',
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
  progress: {
    // color: green[800],
    position: 'absolute',
    top: 4,
    left: 3,
    zIndex: 1,
  },
}));

const ProjectCard = props => {
  const { className, project, user, deleting, handleDeleteProject } = props; // ...rest
  const [loading, setLoading] = React.useState(false);
  const { classes } = useStyles();
  const handleOpenProject = () => {
    props.selectCurrentProject(project);
    setLoading(true);
    props.history.push(`/project_management/${project.id}/overview`);
  };
  let name = '';
  if (project.admin !== undefined) {
    name = getUserFullName(project.admin);
  }
  let description = project.description;
  if (description.length > 170) {
    description = project.description.substring(0, 150) + '...';
  }
  let admin = null;
  if (project && project.admin) admin = project.admin;
  const num_studies = project.num_studies;
  let ComponentButtonDelete = null;
  if (user && admin && (user.type === 1 || user.id === admin.id)) {
    let color = 'red';
    if (user.id !== admin.id) {
      color = 'blue';
    }
    ComponentButtonDelete = (
      <Grid item xs={6}>
        <IconButtonLoad
          handleClick={() => handleDeleteProject(project)}
          icon={<DeleteIcon sx={{ color }} />}
          loading={deleting}
          tooltip="Delete Project"
        />
      </Grid>
    );
  }
  let idProject = 'None';
  if (project) idProject = project.id;
  let total_studies = null;
  if (num_studies) {
    total_studies = `, Studies: ${num_studies}`;
  }
  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent style={{ minHeight: 230 }}>
        <div className={classes.imageContainer}>
          <img alt="Product" className={classes.image} src={project.avatar} />
        </div>
        <Typography align="center" gutterBottom variant="h4">
          {project.name}
        </Typography>
        <RowText first="Administrator:" second={name} />
        <div style={{ display: 'flex', maxHeight: 80 }}>
          <div>
            <Typography
              align="justify"
              style={{ marginTop: 10 }}
              variant="body1">
              {description}
            </Typography>
          </div>
        </div>
      </CardContent>
      <Divider />
      <CardActions
        style={{
          height: 50,
          color: 'white',
          backgroundColor: 'white',
          zIndex: 1000,
        }}>
        <Grid container justifyContent="space-between">
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              {FormatTime(project.updatedAt, 'calendar') + ` (${idProject})`}
              {total_studies}
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="flex-end"
            xs={3}>
            <Grid item xs={6}>
              <IconButtonLoad
                disabled={deleting}
                handleClick={handleOpenProject}
                icon={<OpenWithIcon sx={{ color: 'gray' }} />}
                loading={loading}
                tooltip="Open Project"
              />
            </Grid>
            {ComponentButtonDelete}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  project: PropTypes.object.isRequired,
  selectCurrentProject: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  project_selected: state.page_projects.project,
  // update: state.page_projects.update,
  // errors: state.page_projects.errors
});

export default connect(mapStateToProps, {
  // updateCurrentProjects,
  // loadProjects,
  selectCurrentProject,
})(withRouter(ProjectCard));
