import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { inputProps } from 'common';
import { v1 as uuid } from 'uuid';
class SearchAutocomplete extends React.Component {
  render() {
    const {
      setObject,
      name,
      loading,
      setObjectName,
      setOptions,
      setOpen,
      handleAddResultObject,
      open,
      options,
      handelSearchNameChanged,
      placeholder,
      disabled,
      selected,
    } = this.props;
    const height = '25px';
    let style_textfield_param = {
      style: {
        height,
        margin: 0,
        padding: 0,
        borderWidth: 0,
        border: 0,
      },
    };
    let style_textfield_global = {
      marginLeft: '6px',
      margin: 0,
      height,
      borderWidth: 0,
      border: 0,
    };
    let sytle_autocomplete = {
      width: '100%',
      height,
      borderWidth: 0,
      border: 0,
    };
    let style_label = {
      borderWidth: 0,
      border: 0,
    };
    let variant = undefined;
    let label = null;
    const { no_icon } = this.props;
    if (no_icon) {
      style_textfield_param = { height: '41px', paddingTop: 5 };
      style_textfield_global = null; //{ height: '10px', margin: 0, padding: 0 };
      sytle_autocomplete = { paddingTop: 8, width: '100%' }; //{ height: '10px', margin: 0, padding: 0 };
      style_label = null; //{ height: '10px', margin: 0, padding: 0 };
      variant = this.props.variant;
      label = this.props.label;
    } else {
      variant = 'standard';
    }
    if (this.props.sytle_autocomplete) {
      sytle_autocomplete = {
        ...sytle_autocomplete,
        ...this.props.sytle_autocomplete,
      };
    }
    return (
      <Autocomplete
        disabled={disabled}
        getOptionLabel={this.props.getOptionLabel}
        id={`auto-${this.props.label}-${uuid()}`}
        inputValue={name}
        isOptionEqualToValue={this.props.isOptionEqualToValue}
        loading={loading}
        onChange={(event, newValue) => {
          setObject(newValue);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          setObjectName(newInputValue);
        }}
        onOpen={() => {
          setOptions([]);
          setOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            InputLabelProps={{
              shrink: true,
              style: { ...style_label },
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { ...style_textfield_param },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            label={label}
            name={this.props.name}
            onChange={handelSearchNameChanged}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                handleAddResultObject(selected);
                if (this.props.clean_output) {
                  setObjectName('');
                  setObject(null);
                  setOptions([]);
                }
              }
            }}
            placeholder={placeholder}
            style={style_textfield_global}
            variant={variant}
          />
        )}
        size={this.props.small ? 'small' : 'medium'}
        style={sytle_autocomplete}
        value={selected}
      />
    );
  }
}

SearchAutocomplete.propTypes = {
  clean_output: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handelSearchNameChanged: PropTypes.func.isRequired,
  handleAddResultObject: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  no_icon: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  setObject: PropTypes.func.isRequired,
  setObjectName: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOptions: PropTypes.func.isRequired,
};
SearchAutocomplete.defaultProps = {
  no_icon: false,
  label: 'Unknown Label',
  name: 'unknown_name',
  variant: 'outlined',
  placeholder: 'Search...',
  disabled: false,
  selected: null,
  setOptions: () => '',
  clean_output: true,
  loading: false,
  setObjectName: () => '',
  setOpen: () => '',
  handelSearchNameChanged: () => '',
  isOptionEqualToValue: (option, value) => {
    if (value) return option.name === value.name;
    if (option.name === '') return true;
    return false;
  },
  getOptionLabel: option => {
    if (option) {
      const { name } = option;
      if (name) return name;
    }
    return '';
  },
};
export default SearchAutocomplete;
