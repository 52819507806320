import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { CreateCenterBottom } from './components';
import { useStyles } from './components/utils';
import {
  Countries,
  InputUsers,
  CreateCardHeader,
  TextFieldCustom,
} from 'components';
import { withStyles } from 'tss-react/mui';

const default_center = {
  id: -1,
  idAdmin: -1,
  idAddress: -1,

  admin: {
    FullName: '',
  },
  address: {
    city: '',
    country: '',
    line_1: '',
  },
  name: '',
};

class CreateCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        ...default_center,
      },
      disable_all: false,
    };
    this.holder = {
      getCenter: () => this.state.center,
      setDisableAll: disable_all => {
        this.setState({ disable_all });
      },
      resetCenter: () => {
        this.setState({
          center: {
            ...default_center,
          },
        });
      },
    };
  }

  handleChange = event => {
    const { center } = this.state;
    const key = event.target.name;
    if (key === 'name') {
      center.name = event.target.value;
    } else if (key === 'FullName') {
      center.admin.FullName = event.target.value;
    } else if (key === 'city') {
      center.address.city = event.target.value;
    } else if (key === 'line_1') {
      center.address.line_1 = event.target.value;
    }
    this.setState({ center });
  };
  handleCountryChanged = selected => {
    if (!selected) {
      return;
    }
    const { center } = this.state;
    center.address.country = selected.name;
    this.setState({ center });
  };
  handleAddUser = user => {
    const { center } = this.state;
    center.idAdmin = user.id;
    center.admin = user;
    this.setState({ center });
  };

  render() {
    const { classes } = this.props;
    const { center, disable_all } = this.state;
    const textfield = {
      fullWidth: true,
      helperText: 'Search specify the name of user administrator',
      label: 'Administrator',
      margin: 'dense',
      required: true,
    };
    return (
      <Card className={classes.card}>
        <CreateCardHeader
          classes={classes}
          description={'Insert the necessary information for the center'}
          handleClose={this.props.handleClose}
          title="Create Center"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={disable_all}
                fullWidth
                helperText="Please specify the name of the center"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Center name"
                margin="dense"
                name="name"
                onChange={this.handleChange}
                required
                value={center.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputUsers
                disabled={disable_all}
                filter={false}
                multiple={false}
                onChange={this.handleAddUser}
                textfield={textfield}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Countries
                country={center.address.country}
                disabled={disable_all}
                handleCountryChanged={this.handleCountryChanged}
              />
            </Grid>
            <Grid item md={6} mt={1} xs={12}>
              <TextFieldCustom
                disabled={disable_all}
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={this.handleChange}
                placeholder="Select your city"
                small
                value={center.address.city}
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <TextFieldCustom
                dense
                disabled={disable_all}
                fullWidth
                label="Street"
                name="line_1"
                onChange={this.handleChange}
                placeholder="Select center street"
                small
                value={center.address.line_1}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <CardActions disableSpacing>
          <CreateCenterBottom
            handleAddError={this.props.handleAddError}
            handleAddNewCenter={this.props.handleAddNewCenter}
            holder={this.holder}
          />
        </CardActions>
      </Card>
    );
  }
}

CreateCenter.propTypes = {
  classes: PropTypes.object,
  handleAddNewCenter: PropTypes.func,
};
CreateCenter.propTypes = {
  handleAddNewCenter: () => '',
};
export default withStyles(CreateCenter, useStyles);
