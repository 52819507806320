import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { GetData } from 'helpers';
import { QuerySequences } from 'graphql/Series/sequences';
import { inputProps } from 'common';

class InputSequences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sequences: [],
      loading: false,
      open: false,
      selected: null,
      touched: false,
      name: '',
    };
    const { holder } = props;
    if (holder) {
      holder.resetSequence = (new_sequence = null) => {
        const { sequence } = this.props;
        let name = '';
        if (!new_sequence) {
          new_sequence = sequence;
          if (sequence) {
            name = sequence.name;
          }
        } else {
          name = new_sequence.name;
        }

        if (name === this.state.name) {
          return;
        }
        this.setState({
          touched: true,
          selected: new_sequence,
          name,
        });
      };
    }
  }

  componentDidMount() {
    const { sequence } = this.props;
    if (!this.state.touched && sequence) {
      this.setState({ touched: true, selected: sequence, name: sequence.name });
    }
  }

  componentDidUpdate() {
    const { sequence } = this.props;
    if (!this.state.touched && sequence) {
      this.setState({ touched: true, selected: sequence, name: sequence.name });
    }
  }

  handleSelectSequence = (event, selected) => {
    event.preventDefault();

    let { name } = this.state;
    if (selected) {
      name = selected.name;
    } else {
      name = '';
    }
    this.setState({ selected, name });
    this.props.handleSequenceChanged(selected);
  };
  handleChange = event => {
    const name = event.target.value;
    this.runSearh(name);
  };
  runSearh = name => {
    const { loading } = this.state;
    if (loading) {
      this.setState({ name });
      return;
    }
    // const { name } = this.state;
    this.setState({ loading: false, sequences: [], name });
    (async () => {
      QuerySequences(name, 0, 5)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, sequences } = data.Sequences;
          if (ok) {
            this.setState({ sequences, loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  setOpen = open => {
    this.setState({ open });
  };

  render() {
    const { loading, sequences, selected, open, name } = this.state;
    const { disabled } = this.props;
    return (
      <Autocomplete
        disabled={disabled}
        getOptionLabel={option => {
          if (option) return option.name;
          return '';
        }}
        id="input-autocomplete-sequences"
        inputValue={name}
        // multiple
        loading={loading}
        onChange={this.handleSelectSequence}
        onClose={() => {
          this.setOpen(false);
        }}
        onOpen={() => {
          this.setOpen(true);
        }}
        open={open}
        options={sequences}
        renderInput={params => (
          <TextField
            {...params}
            disabled={disabled}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            label="Sequences"
            onChange={this.handleChange}
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.props.handleAddSequence(selected);
              }
            }}
            variant="standard"
          />
        )}
      />
    );
  }
}

InputSequences.propTypes = {
  classes: PropTypes.object,
  handleAddSequence: PropTypes.func,
  handleSequenceChanged: PropTypes.func,
  holder: PropTypes.object,
  sequence: PropTypes.object,
};
InputSequences.defaultProps = {
  sequence: null,
  holder: null,
  handleSequenceChanged: () => '',
  handleAddSequence: () => '',
};

export default InputSequences;
