import { FormatTime } from 'helpers';

export const columns = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    id: 'User',
    label: 'User',
    align: 'left',
    minWidth: 170,
    format: value => {
      let { FullName } = value;
      if (typeof FullName === 'undefined') return 'Name Missing';
      return FullName;
    },
  },
  {
    id: 'Project',
    label: 'Project',
    align: 'left',
    minWidth: 170,
    format: value => {
      let { name } = value;
      if (typeof name === 'undefined') return 'Name Missing';
      return name;
    },
  },
  {
    id: 'activity',
    label: 'Activity',
    minWidth: 100,
  },
  {
    id: 'updatedAt',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];

export const useStyles = theme => ({
  root: {
    width: '100%',
    // height: 'auto',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  max_height: {
    maxHeight: '100%',
  },
  nameContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    // paddingBottom: -theme.spacing(1)
  },
});
