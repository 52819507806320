import { short_patients } from '../../PatientClinics/components';

export const cases = [
  {
    field: 'record_id',
    headerName: 'Record ID',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'redcap_event_name',
    headerName: 'REDCap event name',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'ess_score',
    headerName: 'ESS Score',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ess_score'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'fss_score',
    headerName: 'FSS Score',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['fss_score'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'anamnesy',
    headerName: 'Anamnesy',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'medication',
    headerName: 'Medication',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'tot_files',
    headerName: 'Total files',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_files'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_series',
    headerName: 'Total series',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_series'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'tot_edfs',
    headerName: 'Total edfs',
    width: 75,
    edit: false,
    align: 'left',
    editerGetter: (row, value) => {
      row['tot_edfs'] = parseInt(value);
      return row;
    },
  },
];
export const test_types = [
  {
    field: 'idSleepCase',
    headerName: 'ID Sleep Case',
    width: 75,
    align: 'left',
    edit: true,
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idSleepCase'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'instruction',
    headerName: 'Instruction',
    align: 'left',
    edit: true,
    type: 'categories',
    options: ['Diagnostisch', 'Fahreignung', 'Therapeutisch'],
  },
  {
    field: 'date',
    headerName: 'Date',
    align: 'left',
    width: 157,
    edit: true,
    type: 'date',
  },
  {
    field: 'diagnose1',
    headerName: 'Diagnose 1',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'diagnose2',
    headerName: 'Diagnose 2',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'diagnose3',
    headerName: 'Diagnose 3',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'nr_repetitions',
    headerName: 'Amount repetitions',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'sleep_latency',
    headerName: 'Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'sorem_n',
    headerName: 'Sorem',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep1_sleep_latency',
    headerName: 'Rep1 - Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep1_rem_latency',
    headerName: 'Rep1 - REM latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep1_kss_pre',
    headerName: 'Rep1 - KSS pre',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep1_kss_post',
    headerName: 'Rep1 - KSS post',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep1_start_datetime',
    headerName: 'Rep1 - Start date',
    align: 'left',
    width: 222,
    edit: true,
    type: 'datetime',
  },
  {
    field: 'rep2_sleep_latency',
    headerName: 'Rep2 - Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep2_rem_latency',
    headerName: 'Rep2 - REM latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep2_kss_pre',
    headerName: 'Rep2 - KSS pre',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep2_kss_post',
    headerName: 'Rep2 - KSS post',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep2_start_datetime',
    headerName: 'Rep2 - Start date',
    align: 'left',
    width: 222,
    edit: true,
    type: 'datetime',
  },
  {
    field: 'rep3_sleep_latency',
    headerName: 'Rep3 - Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep3_rem_latency',
    headerName: 'Rep3 - REM latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep3_kss_pre',
    headerName: 'Rep3 - KSS pre',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep3_kss_post',
    headerName: 'Rep3 - KSS post',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep3_start_datetime',
    headerName: 'Rep3 - Start date',
    align: 'left',
    width: 222,
    edit: true,
    type: 'datetime',
  },
  {
    field: 'rep4_sleep_latency',
    headerName: 'Rep4 - Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep4_rem_latency',
    headerName: 'Rep4 - REM latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep4_kss_pre',
    headerName: 'Rep4 - KSS pre',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep4_kss_post',
    headerName: 'Rep4 - KSS post',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep4_start_datetime',
    headerName: 'Rep4 - Start date',
    align: 'left',
    width: 222,
    edit: true,
    type: 'datetime',
  },
  {
    field: 'rep5_sleep_latency',
    headerName: 'Rep5 - Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep5_rem_latency',
    headerName: 'Rep5 - REM latency',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep5_kss_pre',
    headerName: 'Rep5 - KSS pre',
    edit: true,
    width: 75,
    align: 'left',
  },
  {
    field: 'rep5_kss_post',
    headerName: 'Rep5 - KSS post',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'rep5_start_datetime',
    headerName: 'Rep5 - Start date',
    align: 'left',
    edit: true,
    width: 222,
    type: 'datetime',
  },
];
export const polysomnography = [
  {
    field: 'idSleepCase',
    headerName: 'ID Sleep Case',
    width: 75,
    edit: true,
    align: 'left',
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idSleepCase'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'start_datetime',
    headerName: 'Start date',
    align: 'left',
    width: 157,
    edit: true,
    type: 'date',
  },
  {
    field: 'cpap_device',
    headerName: 'CPAP device',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'sleep_duration',
    headerName: 'Sleep duration',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['sleep_duration'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'sleep_latency',
    headerName: 'Sleep latency',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['sleep_latency'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'sleep_efficiency',
    headerName: 'Sleep efficiency',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['sleep_efficiency'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'rem_latency',
    headerName: 'REM latency',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['rem_latency'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'rem_percentage',
    headerName: 'REM percentage',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['rem_percentage'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'stage1_percentage',
    headerName: 'Stage 1 (%)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['stage1_percentage'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'stage2_percentage',
    headerName: 'Stage 2 (%)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['stage2_percentage'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'stage3_percentage',
    headerName: 'Stage 3 (%)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['stage3_percentage'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'ahi_sleep',
    headerName: 'AHI',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ahi_sleep'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'ahi_non_supine',
    headerName: 'AHI non-supine',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ahi_non_supine'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'ahi_supine',
    headerName: 'AHI supine',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ahi_supine'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'ahi_rem',
    headerName: 'AHI REM',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ahi_rem'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'ahi_nrem',
    headerName: 'AHI NREM',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['ahi_nrem'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'co2_average_sleep',
    headerName: 'Mean tCO2 (mmHg)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['co2_average_sleep'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'co2_max_sleep',
    headerName: 'Maximal tCO2 (mmHg)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['co2_max_sleep'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'co2_50_more_duration',
    headerName: 'CO2 > 50 mmHg',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['co2_50_more_duration'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'supine_sleep_index',
    headerName: 'Supine sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['supine_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'apnoea_sleep_index',
    headerName: 'Apnoea sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['apnoea_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'hypopnoea_sleep_index',
    headerName: 'Hypopnoea sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['hypopnoea_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'centrale_sleep_index',
    headerName: 'Centrale sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['centrale_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'obstructive_sleep_index',
    headerName: 'Obstructive sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['obstructive_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'mixed_sleep_index',
    headerName: 'Mixed sleep index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['mixed_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'arousal_index',
    headerName: 'Arousal index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['arousal_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'plm_sleep_index',
    headerName: 'PLMS index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['plm_sleep_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'average_spo2',
    headerName: 'Mean SpO2 (%)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['average_spo2'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'min_spo2',
    headerName: 'Minimal SpO2 (%)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['min_spo2'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'time_90_spo2',
    headerName: 'Duration saturation < 90%',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['time_90_spo2'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'time_88_spo2',
    headerName: 'Duration saturation < 88%',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['time_88_spo2'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'desat_index',
    headerName: '(ODI) Desaturation index',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['desat_index'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'average_hf',
    headerName: 'Mean heart frequency (bpm)',
    width: 75,
    edit: true,
    align: 'left',
    editerGetter: (row, value) => {
      row['average_hf'] = parseFloat(value);
      return row;
    },
  },
  {
    field: 'diagnose1',
    headerName: 'Diagnose 1',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'diagnose2',
    headerName: 'Diagnose 2',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'diagnose3',
    headerName: 'Diagnose 3',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'subject_sleep_quality_pre',
    headerName: 'Subjective sleep quality (night before test)',
    edit: true,
    align: 'left',
    multiline: true,
    type: 'categories',
    options: [
      'Gleich geschlafen wie sonst',
      'Besser geschlafen als sonst',
      'Schlechter geschlafen als sonst',
    ],
  },
  {
    field: 'subject_sleep_quality',
    headerName: 'Subjective sleep quality (PSG night)',
    edit: true,
    align: 'left',
    multiline: true,
    type: 'categories',
    options: [
      'Gleich geschlafen wie zu Hause',
      'Besser geschlafen als zu Hause',
      'Schlechter geschlafen als zu Hause',
    ],
  },
  {
    field: 'subject_sleep_duration',
    headerName: 'Subjective sleep duration (PSG night)',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
  {
    field: 'subject_sleep_latency',
    headerName: 'Subjective sleep latency (PSG night)',
    width: 75,
    edit: true,
    align: 'left',
    multiline: true,
  },
];
export const vigilance = [
  {
    field: 'idSleepCase',
    headerName: 'ID Sleep Case',
    width: 75,
    edit: true,
    align: 'left',
    hideViewer: true,
    editerGetter: (row, value) => {
      row['idSleepCase'] = parseInt(value);
      return row;
    },
  },
  {
    field: 'test_types',
    headerName: 'Viglance test type',
    edit: true,
    align: 'left',
    type: 'categories',
    options: ['MSLT', 'MWT', 'MSLT/MWT'],
  },
  {
    field: 'pvt_mean',
    headerName: 'PVT (Mean/ms)',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'pvt_lapses',
    headerName: 'PVT (Lapses)',
    width: 75,
    align: 'left',
    edit: true,
  },
  {
    field: 'sart_total_errors',
    headerName: 'SART (Total error)',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'stear_clear',
    headerName: 'Steer clear test (%errors)',
    width: 75,
    edit: true,
    align: 'left',
  },
  {
    field: 'pupillography_var',
    headerName: 'Pupillography variation (mean)',
    width: 75,
    edit: true,
    align: 'left',
  },
];

export const getColumnList = () => {
  let columns = null;

  const new_patients = short_patients.map(x => {
    return { key: 'PAT_' + x.field, value: x.headerName, parent: 'Patient' };
  });
  const new_cases = cases.map(x => {
    return { key: 'CAS_' + x.field, value: x.headerName, parent: 'Case' };
  });
  let new_mslt = test_types.map(x => {
    return {
      key: ('MSLT_' + x.field).replace('MSLT_rep', 'MSLT_REP'),
      value: x.headerName,
      parent: 'MSLT',
    };
  });
  let new_mwt = test_types.map(x => {
    return {
      key: ('MWT_' + x.field).replace('MWT_rep', 'MWT_REP'),
      value: x.headerName,
      parent: 'MWT',
    };
  });
  const new_polysomnography = polysomnography.map(x => {
    return {
      key: 'PSG_' + x.field,
      value: x.headerName,
      parent: 'Polysomnography',
    };
  });
  const new_vigilance = vigilance.map(x => {
    return { key: 'VIG_' + x.field, value: x.headerName, parent: 'Vigilance' };
  });

  columns = new_patients.concat(
    new_cases,
    new_mslt,
    new_mwt,
    new_polysomnography,
    new_vigilance
  );
  columns = columns.filter(item => item.value !== 'ID Sleep Case');
  return columns;
};
