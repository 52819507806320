import React from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

export const getSteps = () => {
  return ['Recovery email', 'Code Verifycation', 'Reset Password'];
};

export const getStepContent = (step, classes, handleError, holder, state) => {
  const { email, code, loading, error, password, confirm } = state;
  switch (step) {
    case 0:
      return (
        <StepOne
          classes={classes}
          email={email}
          error={error}
          handleError={handleError}
          holder={holder}
          loading={loading}
        />
      );
    case 1:
      return (
        <StepTwo
          classes={classes}
          code={code}
          email={email}
          error={error}
          handleError={handleError}
          holder={holder}
          loading={loading}
        />
      );
    case 2:
      return (
        <StepThree
          classes={classes}
          confirm={confirm}
          error={error}
          handleError={handleError}
          holder={holder}
          loading={loading}
          password={password}
        />
      );
    default:
      return 'Unknown step';
  }
};
