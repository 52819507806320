import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';

import { SendMessageToTeam, TeamChats, TeamChatsUpperBar } from './components';
import { QueryChannelMessages } from 'graphql/Communications';
import { SnackMessage } from 'components';

const useStyles = () => ({
  main_container: {
    width: '99.7%',
    height: '100%',
    borderLeft: '1px solid #eaeaeb',
  },
  root: {
    width: '100%',
    height: '79vh',
    // backgroundColor: 'red'
  },
  root_messages: {
    height: '100%',
    display: 'flex',
    overflowY: 'scroll',
    transform: 'scaleY(-1)',
  },
});

class ChannelRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
    this.holder = {
      IsSubscribed: null,
      Subscribe: null,
      UnSubscribe: null,
    };
  }

  getError = () => {
    return this.state.error;
  };
  handleError = error => {
    this.setState({ error });
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const {
      classes,
      channelId,
      channel,
      handleChannelSelection,
      height,
      isMobile,
      handleDrawerToggle,
    } = this.props;
    return (
      <div>
        <TeamChatsUpperBar
          channel={channel}
          handleChannelSelection={handleChannelSelection}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={isMobile}
        />
        <div className={classes.main_container}>
          <div style={{ height: height - 125, width: '100%' }}>
            <div className={classes.root_messages}>
              <QueryChannelMessages
                channelId={parseInt(channelId, 10)}
                getError={this.getError}
                handleError={this.handleError}>
                <TeamChats height={height} />
              </QueryChannelMessages>
            </div>
          </div>
        </div>
        <SendMessageToTeam channelId={channelId} />
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={this.state.error}
          open={this.state.error !== '' ? true : false}
          type="error"
        />
      </div>
    );
  }
}

ChannelRight.propTypes = {
  classes: PropTypes.object.isRequired,
  team: PropTypes.object,
};
ChannelRight.defaultProps = {
  height: 200,
};
export default withStyles(ChannelRight, useStyles);
