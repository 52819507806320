import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';

class JoinSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_join: null,
    };
  }
  componentDidMount() {
    const { selected_join } = this.props;
    this.setState({ selected_join });
  }
  handleListItemClick = (event, selected_join) => {
    this.setState({ selected_join });
    this.props.handleUpdateJoinSelection(selected_join);
  };
  render() {
    const { selected_join, join_list } = this.props;
    let options = [];
    if (join_list.length > 0 && selected_join !== null) {
      join_list.forEach(element => {
        options.push(
          <ListItemButton
            key={element.id}
            name={element.table_map_name}
            onClick={event => this.handleListItemClick(event, element)}
            selected={element.id === selected_join.id}>
            <ListItemIcon>
              {element.id === selected_join.id ? (
                <RadioButtonCheckedOutlinedIcon color="primary" />
              ) : (
                <RadioButtonUncheckedOutlinedIcon color="primary" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={element.table_map_name}
              secondary={
                <Grid container style={{ width: '100%' }}>
                  <Grid item style={{ width: '100%' }} xs={6}>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Table Input:&nbsp;
                      </Typography>
                      {element.table_name_input}
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Table Output:&nbsp;
                      </Typography>
                      {element.table_name_output}
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: '100%' }} xs={6}>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Column:&nbsp;
                      </Typography>
                      {element.id_table_input}
                    </Grid>
                    <Grid item style={{ width: '100%' }} xs={6}>
                      <Typography
                        color="text.primary"
                        component="span"
                        sx={{ display: 'inline' }}
                        variant="body2">
                        Column:&nbsp;
                      </Typography>
                      {element.id_table_output}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </ListItemButton>
        );
      });
    } else {
      options = (
        <p>
          No joins found, try selecting different tables at the previous step
        </p>
      );
    }
    return (
      <Box sx={{ width: '100%', maxWidth: '75%', bgcolor: 'background.paper' }}>
        <h3>Join Selection</h3>
        <List component="nav">{options}</List>
      </Box>
    );
  }
}

JoinSelection.propTypes = {
  handleClose: PropTypes.func,
  handleUpdateJoinSelection: PropTypes.func,
  join_list: PropTypes.array,
  selected_join: PropTypes.object,
};
JoinSelection.defaultProps = {
  join_list: [],
  selected_join: null,
  handleClose: () => '',
  handleUpdateJoinSelection: () => '',
};

export default JoinSelection;
