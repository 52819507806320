import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

class TabsSurveySummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tab_value, handleChange, tabs, disabled } = this.props;
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          centered
          disabled={disabled}
          onChange={(event, tab_value) => handleChange(tab_value)}
          value={tab_value}>
          {tabs.map((item, index) => {
            const { name } = item;
            return <Tab key={`tab-survery-${index}`} label={name} />;
          })}
        </Tabs>
      </Box>
    );
  }
}

TabsSurveySummary.propTypes = {
  handleChange: PropTypes.func,
  tab_value: PropTypes.number,
};
TabsSurveySummary.defaultProps = {
  tabs: [{ name: 'None' }],
  tab_value: 0,
  handleChange: () => '',
  disabled: false,
};

export default TabsSurveySummary;
