import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
class CardHeaderCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { small } = this.props;
    let style = null;
    if (small) {
      style = {
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
      };
    }
    // console.log({ small, style });
    return (
      <CardHeader
        action={
          <Grid container sx={{ paddingTop: small ? 1 : null }}>
            <IconButton
              aria-label="settings"
              onClick={() => this.props.handleClose()}
              size={small ? 'small' : 'large'}>
              <CancelIcon />
            </IconButton>
          </Grid>
        }
        avatar={this.props.icon}
        style={style}
        subheader={this.props.description}
        title={this.props.title}
      />
    );
  }
}

CardHeaderCreate.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  handleReload: PropTypes.func,
  loading: PropTypes.bool,
  show_reload: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip_clear: PropTypes.string,
};
CardHeaderCreate.defaultProps = {
  handleReload: () => '',
  handleClose: () => '',
  description: 'Modify all the necessary field and press save to update',
  icon: <EditIcon style={{ color: 'green' }} />,
  loading: false,
  show_reload: false,
  tooltip_clear: 'Reload',
  small: true,
};

export default CardHeaderCreate;
