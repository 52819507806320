import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/lab/Skeleton';
import { Box } from '@mui/material';

class SeriesFileSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { width } = this.props;
    return (
      <Box width={width + 4}>
        <Skeleton height={90} variant="rectangular" width={width} />
        <Box pt={0.2}>
          <Skeleton width={width} />
          <Skeleton width={width} />
        </Box>
      </Box>
    );
  }
}

SeriesFileSkeleton.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
};
SeriesFileSkeleton.defaultProps = {
  width: 90,
};
export default SeriesFileSkeleton;
