import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const QUERY_FILE_TYPE_MAPPINGS = gql`
  query($name: String, $page: Int, $limit: Int) {
    fileObjectTypeMaps(name: $name, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      mappings {
        id

        idFileObject
        idType

        name

        file {
          original_name
          mimetype
          Size
          Thumbnail
          filepath
          updatedAt
        }
        type {
          name
          description
          updatedAt
        }

        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryFileObjectTypeMap = (name, page, limit) => {
  const variables = {};
  if (name !== undefined && name !== '') {
    variables.name = name;
  }
  if (limit !== undefined && limit) {
    variables.limit = limit;
    variables.page = page;
  }
  console.log('QueryFileObjectTypeMap: ', { variables });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILE_TYPE_MAPPINGS),
    variables,
  });
};
const MUTATION_DELETE_FILE_VAR_MAP = gql`
  mutation($ids: [LongLong!]!) {
    deleteFileObjectTypes(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteFileMapTypes = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_FILE_VAR_MAP),
    variables: {
      ids,
    },
  });
};
const MUTATION_UPDATE_FILE_VAR_MAPS = gql`
  mutation($maps: [InputFileType!]!) {
    mapFileObjectTypes(maps: $maps) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idFileObject
        idType
        name
      }
    }
  }
`;
export const MutationUpdateFileMapTypes = maps => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_FILE_VAR_MAPS),
    variables: {
      maps,
    },
  });
};

const QUERY_TYPES_AT_FILE = gql`
  query($idFileObject: LongLong!, $page: Int, $limit: Int) {
    typesAtFile(idFileObject: $idFileObject, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      total
      mappings {
        id
        idFileObject
        idType
        name
        type {
          id
          name
          description
        }
        file {
          idFileObject
          original_name
          mimetype
          Size
          # sequence {
          #   idSequence
          #   name
          # }
          metadata {
            id
            idUser
            idFileObject

            notes
            comments
            information
            creationTime
            name

            group_mappings {
              id
              idGroup
              group {
                name
              }
              name
            }
            updatedAt
            createdAt
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QueryTypesAtFile = (idFileObject, page, limit) => {
  console.log('QueryTypesAtFile', { idFileObject, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_TYPES_AT_FILE),
    variables: {
      idFileObject,
      page,
      limit,
    },
  });
};
