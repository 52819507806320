/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import { BottomBar, MiddleBar } from './components';
import { DialgoEditMetadata } from 'components';
import { IsInvalid } from 'helpers';

const useStyles = () => ({
  progress: {
    width: '100%',
  },
});

class ExplorerToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      total: 0,
      open_edit: false,
    };
  }

  handleProgress = (event, loading) => {
    if (IsInvalid(event)) {
      this.props.handleProgress(0);
      return;
    }
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      if (loaded > this.state.total) {
        total = loaded * 4;
        this.setState({ total });
      } else {
        total = this.state.total;
      }
    }
    let progress = Math.floor((loaded * 100) / total);
    if (loading !== undefined) {
      if (!loading) {
        progress = 0;
      }
    }
    this.props.handleProgress(progress);
  };
  handleEditSelected = () => {};
  render() {
    const {
      selected_content,
      selected_folder,
      ParentFolder,
      loading,
    } = this.props;
    const { open_edit } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <MiddleBar
            base={this.props.base}
            handleClickMenuItems={this.props.handleClickMenuItems}
            handleCurrentFolderChanged={this.props.handleCurrentFolderChanged}
            handleDeleteFolders={this.props.handleDeleteFolders}
            handleDrawerClose={this.props.handleDrawerClose}
            handleEditSelected={() => this.setState({ open_edit: true })}
            handleExplorerSortBy={this.props.handleExplorerSortBy}
            handleProgress={this.handleProgress}
            handleRefresh={this.props.handleRefresh}
            handleShareFolder={this.props.handleShareFolder}
            menu_items={this.props.menu_items}
            order={this.props.order}
            orderBy={this.props.orderBy}
            ParentFolder={ParentFolder}
            selected_content={selected_content}
            selected_folder={selected_folder}
            user={this.props.user}
          />
          <BottomBar
            base={this.props.base}
            changeParentFolder={this.props.changeParentFolder}
            IsHome={this.props.IsHome}
            loading={loading}
            ParentFolder={ParentFolder}
            user={this.props.user}
          />
        </Paper>
        <DialgoEditMetadata
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleUpdateMetadata={() => {
            this.setState({ open_edit: false });
          }}
          open_edit={open_edit}
          selected_content={selected_content}
        />
      </React.Fragment>
    );
  }
}

ExplorerToolBar.defaultProps = {
  menu_items: [],
  base: 'Home',
  selected_content: [],
  selected_folder: [],
  ParentFolder: { idFolder: -1, name: 'Home' },
};
ExplorerToolBar.propTypes = {
  base: PropTypes.string,
  handleClickMenuItems: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  handleShareFolder: PropTypes.func,
  menu_items: PropTypes.array,
  sections: PropTypes.array,
  title: PropTypes.string,
};
const mapStateToProps = state => ({
  user: state.info_user.user,
});
export default connect(mapStateToProps)(withStyles(ExplorerToolBar, useStyles));
