import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useMakeStyles } from '../Styles';
import { GetData } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';
import { QuerySummaryTeams } from 'graphql/Teams';

const SearchTeamInputs = props => {
  const { style } = props;

  const classes = useMakeStyles();

  const [name, setTeamName] = React.useState('');
  const [team, setTeam] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      QuerySummaryTeams(name)
        .then(res => {
          const data = GetData(res);
          const {
            allTeams: { ok, errors },
          } = data;

          let teams = [];
          if (ok) {
            teams = data.allTeams.teams;
            if (teams.length === 0) {
              teams.push({ name: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(teams);
        })
        .catch(error => {
          console.log({ error });
          setOptions([]);
        });
    })();
  }, [loading, name]);
  const handelSiteNameChanged = event => {
    setTeamName(event.target.value);
  };
  const handleAddTeam = team => {
    setTeam(team);
    props.handleAddTeam(team);
  };

  if (props.no_icon) {
    return (
      <SearchAutocomplete
        // disabled={disabled}
        handelSearchNameChanged={handelSiteNameChanged}
        handleAddResultObject={props.handleAddTeam}
        label="Teams"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={team}
        setObject={props.handleAddTeam}
        setObjectName={setTeamName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper className={classes.root} style={{ ...style, padding: 10 }}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        // disabled={disabled}
        handelSearchNameChanged={handelSiteNameChanged}
        handleAddResultObject={handleAddTeam}
        label="Teams"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={team}
        setObject={handleAddTeam}
        setObjectName={setTeamName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchTeamInputs.propTypes = {
  className: PropTypes.string,
  no_icon: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object,
};
SearchTeamInputs.defaultProps = {
  no_icon: false,
};

export default SearchTeamInputs;
