import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { withRouter } from 'react-router-dom';
import {
  ButtonReloadDelete,
  ButtonDownloadType,
  SearchProjectCentersInputs,
} from 'components';
import { useStyles } from './components';

class ToolBarProjectStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      access_level_index: props.type,
    };
  }
  handleAccessLevelChanged = access_level_index => {
    if (access_level_index !== -1) {
      this.setState({ access_level_index });
      this.props.handleSelectionType(access_level_index);
    }
  };
  handleOpenStudy = () => {
    const { row_clicked } = this.props;
    const { idStudy } = row_clicked;
    if (idStudy) {
      this.props.history.push(`/image_analysis/study/${idStudy}`);
    }
  };
  render() {
    const {
      classes,
      handleOpenEdit,
      handleOpenCreate,
      selected,
      handleClearSelected,
      handleOpenVarMappping,
      handleDeleteSelected,
      handleReloadTable,
      loading_table,
      loading,
      isAdmin,
      row_clicked,
      idProject,
    } = this.props;
    let ComponentAdministrator = null;
    let ComponentAdminProjectFilter = null;
    if (isAdmin) {
      ComponentAdministrator = (
        <React.Fragment>
          <Tooltip title="Study add mapping">
            <Button onClick={handleOpenCreate}>
              <AddIcon style={{ color: 'blue' }} />
            </Button>
          </Tooltip>

          <Tooltip title="Study series details">
            <span>
              <Button disabled={!selected.length} onClick={handleOpenEdit}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
            </span>
          </Tooltip>
        </React.Fragment>
      );
      ComponentAdminProjectFilter = (
        <Grid item sm={5} xs={5}>
          <SearchProjectCentersInputs
            handleAddCenter={this.props.handleAddCenter}
            handleAddError={this.props.handleAddError}
            idProject={idProject}
            no_icon
          />
        </Grid>
      );
    }
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        item
        justifyContent="space-between"
        spacing={2}
        sx={{ width: '100%' }}
        xs={12}>
        <Grid item md={4} sm={4} xs={12}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            {ComponentAdministrator}
            <Tooltip title="Study mapping">
              <span>
                <Button
                  disabled={!selected.length}
                  onClick={handleOpenVarMappping}>
                  <AccountTreeIcon
                    style={{ color: selected.length ? 'green' : 'gray' }}
                  />
                </Button>
              </span>
            </Tooltip>
            <ButtonDownloadType
              idProject={this.props.idProject}
              type="project_studies"
            />
            <Button disabled={!row_clicked} onClick={this.handleOpenStudy}>
              <LaunchIcon style={{ color: row_clicked ? 'green' : 'gray' }} />
            </Button>
          </ButtonGroup>
        </Grid>
        {ComponentAdminProjectFilter}
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          md={3}
          sm={3}
          style={{ paddingRight: 0, marginRight: 0, maxHeight: 20 }}
          xs={12}>
          <ButtonReloadDelete
            handleClearSelected={handleClearSelected}
            handleDeleteSelected={handleDeleteSelected}
            handleReloadTable={handleReloadTable}
            loading={loading}
            loading_table={loading_table}
            numSelected={selected.length}
            show_delete={isAdmin}
            // tooltip={tooltip}
          />
        </Grid>
      </Grid>
    );
  }
}

ToolBarProjectStudies.propTypes = {
  handleOpenCreate: PropTypes.func,
};
ToolBarProjectStudies.defaultProps = {
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  selected: [],
  handleOpenVarMappping: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSelectionType: () => '',
  loading_table: false,
  loading: false,
  type: 0,
  isAdmin: false,
  access_level: 0,
};

export default withStyles(withRouter(ToolBarProjectStudies), useStyles);
