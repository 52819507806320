import React from 'react';
import PropTypes from 'prop-types';

class TitleSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          paddingLeft: 5,
          fontWeight: 1000,
          pointerEvents: 'none',
        }}>
        {this.props.title}
      </div>
    );
  }
}

TitleSort.propTypes = {
  classes: PropTypes.object,
};

export default TitleSort;
