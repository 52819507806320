import React from 'react';
import PropTypes from 'prop-types';
import { DialogShareFolders, DialogShareFiles } from 'components';
const getKeyObject = object => {
  const { idFolder, idFolderContent } = object;
  if (idFolderContent) return `Content_${idFolderContent}`;
  if (idFolder) return `Folder_${idFolder}`;
  return null;
};
class ExplorerMenuShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_share: false,
    };
    if (props.holder) {
      props.holder.openShare = () => this.setState({ open_share: true });
    }
  }
  handleShareFolder = rights => {
    const { selected_objects } = this.props;
    console.log({ selected_objects, rights });
    if (selected_objects && selected_objects.length) {
      for (let j = 0; j < selected_objects.length; j++) {
        const object_rights = [...selected_objects[j].Rights];
        for (let i = 0; i < rights.length; i++) {
          if (getKeyObject(selected_objects[j]) !== getKeyObject(rights[i]))
            continue;
          const index = object_rights
            .map(x => x.idRight)
            .indexOf(rights[i].idRight);
          if (index === -1) {
            object_rights.push({ ...rights[i] });
          }
        }
        const { idFolder, idFolderContent } = selected_objects[j];
        if (idFolderContent)
          console.log({ idFolderContent, object: [...object_rights] });
        else console.log({ idFolder, object: [...object_rights] });
        this.props.selected_objects[j].Rights = [...object_rights];
        this.props.handleItemChanged(this.props.selected_objects[j]);
      }
      console.log({ selected_objects: this.props.selected_objects });
    } else {
      this.props.itemOver.Rights = rights;
      this.props.handleItemChanged(this.props.itemOver);
    }
    this.setState({ open_share_folder: false });
  };
  updateRights = object => {
    const { selected_objects } = this.props;
    if (selected_objects && selected_objects.lenth) {
      for (let j = 0; j < selected_objects.length; j++) {
        const { idFolder, idFolderContent } = selected_objects[j];
        let here = false;
        if (object.idFolderContent) {
          if (idFolderContent && idFolderContent === object.idFolderContent) {
            here = true;
          }
        } else if (object.idFolder) {
          if (idFolder && idFolder === object.idFolder) {
            here = true;
          }
        }
        if (here) {
          this.props.selected_objects[j].Rights = [...object.Rights];
          this.props.handleItemChanged(this.props.selected_objects[j]);
        }
      }
    } else {
      this.props.itemOver.Rights = [...object.Rights];
      this.props.handleItemChanged(this.props.itemOver);
    }
  };
  render() {
    const { isFolder, itemOver, selected_objects } = this.props;
    if (!itemOver) return null;
    let folders = [];
    let files_contents = [];
    let open_share_file = false;
    let open_share_folder = false;
    // console.log({ selected_objects });
    if (selected_objects && selected_objects.length) {
      const selected_folders = selected_objects
        .filter(x => x.idFolderContent === undefined && x.idFolder)
        .filter(x => x);
      const selected_contents = selected_objects
        .filter(x => x.idFolderContent)
        .filter(x => x);
      if (selected_folders.length > selected_contents.length) {
        folders = folders.concat(selected_folders);
        open_share_folder = this.state.open_share;
      } else {
        files_contents = files_contents.concat(selected_contents);
        open_share_file = this.state.open_share;
      }
    } else if (isFolder) {
      folders.push(itemOver);
      open_share_folder = this.state.open_share;
    } else {
      files_contents.push(itemOver);
      open_share_file = this.state.open_share;
    }
    return (
      <React.Fragment>
        <DialogShareFiles
          files_contents={files_contents}
          handleClose={() => this.setState({ open_share: false })}
          handleShareFolder={this.handleShareFolder}
          open={open_share_file}
          updateRights={this.updateRights}
        />
        <DialogShareFolders
          folders={folders}
          handleClose={() => this.setState({ open_share: false })}
          handleShareFolder={this.handleShareFolder}
          open={open_share_folder}
          updateRights={this.updateRights}
        />
      </React.Fragment>
    );
  }
}

ExplorerMenuShare.propTypes = {
  classes: PropTypes.object,
};

export default ExplorerMenuShare;
