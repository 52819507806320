import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_SUMMARY_MODALITIES = gql`
  query($mode: Int, $idProject: Int) {
    SummaryModalities(mode: $mode, idProject: $idProject) {
      ok
      errors {
        path
        message
      }
      History {
        Modality
        Total
      }
      ThisYear {
        Modality
        Total
      }
      LastYear {
        Modality
        Total
      }
      LastMonth {
        Modality
        Total
      }
    }
  }
`;
export const QueryRunSummaryModalities = variables => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUMMARY_MODALITIES),
    variables,
  });
};
