/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { FormatTime, rowsPerPageMedium } from 'helpers';

import { MainTable } from 'components/Tables/components';
import { Divider } from '@mui/material';

const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    type: 'string',
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  table_container: {
    minHeight: 200,
  },
}));

const TableSeriesDetails = props => {
  const { classes } = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const {
    rows,
    handleSelectClick,
    handleSelectAllClick,
    isSelected,
    selected,
    height,
    maxHeight,
    minHeight,
  } = props;
  if (props.limit !== null && props.limit !== rowsPerPage) {
    setRowsPerPage(props.limit);
  }
  if (props.page !== null && props.page !== page) {
    setPage(props.page);
  }

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(event, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    props.handleChangeRowsPerPage(event);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // let emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  // if (props.disable_splice) {
  //   emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);
  // }
  let style = null;
  if (height) {
    style = { height, maxHeight: height };
  }
  if (maxHeight) {
    if (style) style.maxHeight = maxHeight;
    else style = { maxHeight };
  }
  if (minHeight) {
    if (style) style.minHeight = minHeight;
    else style = { minHeight };
  }
  // const Component = <div />; // Paper;

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapper} style={style}>
        <MainTable
          columns={columns}
          dense
          disable_checkbox={props.disable_checkbox}
          disable_splice={props.disable_splice}
          handleSelectAllClick={handleSelectAllClick}
          handleSelectedClick={handleSelectClick}
          handleSelectedRow={props.handleRowClicked}
          isHighLighted={props.isHighLighted}
          isSelected={isSelected}
          limit={props.limit}
          loading={props.loading}
          nowrap
          page={props.page}
          rows={rows}
          selected={selected}
        />
      </div>
      <Divider />
      <TablePagination
        component="div"
        count={props.total ? props.total : rows.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageMedium}
      />
    </Paper>
  );
};
TableSeriesDetails.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  handleSetSelected: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  selected: PropTypes.array,
};
TableSeriesDetails.defaultProps = {
  handleSelectAllClick: () => console.log('handleSelectAllClick'),
  maxHeight: null,
  disable_splice: true,
  loading: false,
  deleting: false,
  disable_checkbox: false,
  page: 0,
  limit: 100,
  rows: [],
  selected: [],
  total: 0,
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  handleSetSelected: () => '',
  handleRowClicked: () => '',
  isHighLighted: () => false,
};
export default TableSeriesDetails;
