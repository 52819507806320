import React from 'react'; //, { useState }
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ButtonReload, TableActivity } from 'components';
import { QueryNotifications } from 'graphql/Communications';
import { GetData } from 'helpers';
import { setNotifications } from 'redux-store/actions';
import { initialState } from 'redux-store/reducers/page_settings';

const useStyles = theme => ({
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0),
  },
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
});

class LatestActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    this.handleReload();
  };

  handleReload = () => {
    const { loading } = this.state;
    const { dashboard_view } = this.props;

    const { visualization_mode, fullHistory } = dashboard_view;

    let idProject = 0;
    if (dashboard_view.idProject) {
      idProject = dashboard_view.idProject;
    }

    let datestart,
      dateend = '';
    if (!fullHistory) {
      datestart = dashboard_view.datestart;
      dateend = dashboard_view.dateend;
    }
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryNotifications(
        0,
        10,
        visualization_mode,
        idProject,
        dateend,
        datestart,
        fullHistory
      )
        .then(res => {
          const data = GetData(res);
          const {
            notifications: { ok, errors, notifications },
          } = data;
          if (ok) {
            this.props.setNotifications(notifications);
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.props.setNotifications([]);
          this.setState({ loading: false });
        });
    })();
  };

  render() {
    const { classes, className, limit, page } = this.props;
    const { loading } = this.state;
    let { notifications } = this.props;
    if (limit) {
      notifications = notifications.slice(page, limit);
    }

    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <ButtonReload
              handleReload={this.handleReload}
              loading={loading}
              small
              tooltip="Reload Project Activity"
            />
          }
          className={classes.header}
          title="Latest Activity"
        />
        <Divider />
        <CardContent className={classes.content}>
          <TableActivity
            dense
            height={this.props.height}
            notifications={notifications}
            nowrap
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            onClick={() => this.props.history.push('/account/activity')}
            size="small"
            variant="text">
            View all <ArrowRightIcon />
          </Button>
        </CardActions>
      </Card>
    );
  }
}

LatestActivity.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
};
LatestActivity.defaultProps = {
  limit: 0,
  page: 0,
  height: 0,
};

const mapStateToProps = state => {
  let { notifications } = state.page_activity;
  let size = 0;
  if (notifications === undefined) {
    size = 0;
    notifications = [];
  } else {
    size = notifications.lenght;
  }
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return {
    notifications,
    size,
    dashboard_view,
  };
};
export default connect(mapStateToProps, { setNotifications })(
  withStyles(withRouter(LatestActivity), useStyles)
);
