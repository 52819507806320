import React, { Component } from 'react';
import { ServerErrorsString, GetData, IsInvalid } from 'helpers';
import { connect } from 'react-redux';
import { logout } from 'redux-store/actions';
import { green, grey, lime, orange } from '@mui/material/colors';
import { getVariables } from 'common';
import { QuerySummaryBodyParts } from './utils_series_body_parts';
import { initialState } from 'redux-store/reducers/page_settings';
class QuerySeriesBodyParts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoadBodyParts();
    }
  }
  componentDidMount() {
    this.handleLoadBodyParts();
  }
  handleLoadBodyParts = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QuerySummaryBodyParts(getVariables(this.props))
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.SummaryBodyParts;
          if (ok) {
            this.props.setState({ loading: false });
            this.setState({
              data: this.getData(data.SummaryBodyParts),
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getEmpty = () => {
    const data = {
      datasets: [
        {
          data: [0],
          backgroundColor: [orange[400], lime[500], green[800]],
          borderWidth: 8,
          borderColor: grey[50],
          hoverBorderColor: grey[50],
        },
      ],
      labels: ['Empty'],
    };
    return data;
  };
  getData = data_in => {
    const { BodyParts, Total } = data_in;
    //
    const data = this.getEmpty();
    let total = BodyParts.length;
    if (total >= 4) {
      total = 4;
    }
    if (IsInvalid(data.datasets)) {
      console.log('SeriesBody');
    }
    data.labels = BodyParts.map(x => x.BodyPartExamined);
    data.datasets[0].data = Array(total).fill(0, 0);
    for (let i = 0; i < total; i++) {
      const objc = BodyParts[i];
      const value = (objc.Total / Total) * 100;
      data.datasets[0].data[i] = parseInt(value, 10);
    }
    return data;
  };
  getSubTitles = data => {
    const elements = [];
    for (let i = 0; i < data.datasets[0].data.length; i++) {
      elements.push({
        title: data.labels[i],
        value: `${data.datasets[0].data[i]}`,
        color: data.datasets[0].backgroundColor[i],
      });
    }
    return elements;
  };

  render() {
    const { children } = this.props;
    let { data, loading } = this.state;
    if (IsInvalid(data)) data = this.getEmpty();
    const titles = this.getSubTitles(data);
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        data,
        titles,
        loading,
      });
    });
  }
}
QuerySeriesBodyParts.defaultProps = {
  holder: {
    reload: null,
    setLoading: () => '',
  },
};

const mapStateToProps = state => {
  let { dashboard_view } = state.page_settings;
  if (dashboard_view === undefined) {
    dashboard_view = {
      ...initialState.dashboard_view,
    };
  }
  return { dashboard_view };
};

export default connect(mapStateToProps, { logout })(QuerySeriesBodyParts);
