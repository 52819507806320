import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IOSSwitch, StyleText } from 'components';

class ButtonSwithView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      isStudySeriesView,
      handleBack,
      handleChange,
      view,
    } = this.props;
    let checked = true;
    let text = 'Series';
    if (view === 'study' && !isStudySeriesView) {
      text = 'Study';
      checked = false;
    } else if (view === 'patients') {
      text = 'Patients';
    }
    return (
      <React.Fragment>
        {isStudySeriesView ? (
          <IconButton onClick={handleBack} size="large">
            <ArrowBackIcon className={classes.button} />
          </IconButton>
        ) : (
          <IOSSwitch checked={checked} onChange={handleChange} />
        )}
        <StyleText
          fontSize={22}
          fontWeight="fontWeightBold"
          spacing={3}
          text={text}
          variant="button"
        />
      </React.Fragment>
    );
  }
}

ButtonSwithView.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  handleBack: PropTypes.func,
  handleChange: PropTypes.func,
  study_view: PropTypes.bool,
};
ButtonSwithView.defaultProps = {
  study_view: true,
  Study: null,
};

export default ButtonSwithView;
