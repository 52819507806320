import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_UPDATE_TEAM = gql`
  mutation($id: LongLong, $name: String, $avatar: String) {
    updateTeam(id: $id, name: $name, avatar: $avatar) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateTeam = ({ id, name, avatar }) => {
  console.log('MutationUpdateTeam', { id, name, avatar });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_TEAM),
    variables: {
      id,
      name,
      avatar,
    },
  });
};

const MUTATION_REMOVE_TEAMS = gql`
  mutation($teamsIds: [Int!], $idProject: LongLong) {
    removeTeams(teamsIds: $teamsIds, idProject: $idProject) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveTeams = (teamsIds, idProject) => {
  console.log('MutationRemoveTeams', { teamsIds });
  const variables = { teamsIds };
  if (typeof idProject !== 'undefined' && idProject > 0) {
    variables.idProject = idProject;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_TEAMS),
    variables,
  });
};

const MUTATION_REMOVE_TEAM_MEMBERS = gql`
  mutation($ids: [Int!]!, $teamId: Int!) {
    removeTeamMembers(ids: $ids, teamId: $teamId) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveTeamMembers = (ids, teamId) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_TEAM_MEMBERS),
    variables: {
      ids,
      teamId: teamId,
    },
  });
};
const MUTATION_CREATE_TEAM = gql`
  mutation($name: String!, $idProject: LongLong, $idCenter: LongLong) {
    createTeam(name: $name, idProject: $idProject, idCenter: $idCenter) {
      ok
      team {
        id
        idCenterTeam
        name
        avatar
        admin {
          id
          firstName
          lastName
          FullName
        }
        center_team {
          idCenter
        }
        createdAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const MutationCreateTeam = (name, idProject, idCenter) => {
  const variables = { name };
  if (idProject) {
    variables.idProject = idProject;
  }
  if (idCenter) {
    variables.idCenter = idCenter;
  }
  console.log('createTeam', variables);
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_CREATE_TEAM),
    variables,
  });
};
