import path from 'common/path';
import {
  MutationDownloadFinished,
  DownloadItemsAtKey,
  GenerateDownload,
} from 'graphql/Download';

import {
  GetData,
  GetNameFromHeader,
  GetResponseError,
  IsInvalid,
} from 'helpers';

export const handleDownload = (index, THIS, download_all = null) => {
  const { downloading, total_downloaded, current_index, ready } = THIS.state;
  let selected = [];
  let folder = null;
  let content = null;
  try {
    if (!download_all) {
      download_all = THIS.state.download_all;
    }
    if (downloading && current_index === index) {
      return;
    }
    if (ready.indexOf(index) !== -1) {
      console.log('read  - here');
      THIS.handleDownloadItem(++index);
      return;
    }
    const { downloads } = THIS.state;
    if (index >= downloads.length) {
      THIS.setState({
        download_all: false,
        downloading: false,
        current_index: -1,
      });
      return;
    }
    const item = downloads[index];
    folder = item.folder;
    content = item.content;
    if (content) {
      selected.push({ idFolderContent: content.idFolderContent });
    } else if (folder) {
      selected.push({ idFolder: folder.idFolder });
    } else {
      THIS.handleDownloadItem(++index, 'Empty item at selected download');
      return;
    }
    // console.log('setDownload', { selected, total_downloaded });
    THIS.holder.setDownload(selected, 'func');
    THIS.setState({
      downloading: true,
      current_index: index,
      total_downloaded: total_downloaded + 1,
    });
  } catch (error) {
    if (!selected.length) {
      THIS.handleDownloadItem(++index, error);
      return;
    }
  }
  (async () => {
    let download_key = null;
    try {
      const res = await GenerateDownload(selected);
      const data = GetData(res);
      const {
        downloadGenerate: { ok, key, errors },
      } = data;
      if (ok) {
        download_key = key;
      } else {
        throw errors;
      }
    } catch (error) {
      console.log({ error });
      THIS.handleDownloadItem(++index, error);
      return;
    }
    DownloadItemsAtKey(download_key, event =>
      THIS.handleProgress(event, selected)
    )
      .then(async response => {
        let disposition = response.headers['Content-Disposition'];
        if (IsInvalid(disposition)) {
          disposition = response.headers['content-disposition'];
        }
        if (!response.data) {
          throw Error('Unknown error data is null');
        }
        let name = 'download_all';
        let extension = 'zip';
        if (content) {
          const { Series, File } = content;
          if (Series) {
            name = Series.SeriesDescription;
          } else if (File) {
            let ext = path.extname(File.original_name);
            name = path.basename(File.original_name, ext);
            if (ext) {
              if (ext.startsWith('.')) {
                ext = ext.replace('.', '');
              }
              extension = ext;
            }
          }
        } else if (folder) {
          name = folder.name;
        } else if (disposition && disposition.indexOf('filename=') !== -1) {
          name = GetNameFromHeader(response);
          extension = null;
        }
        let file_name = `${name}.${extension}`;
        if (!extension) file_name = name;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name);
        link.style.display = 'none';
        link.target = '_blank';
        link.hidden = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await MutationDownloadFinished(download_key);
        ready.push(index);
        const state = { ready };
        console.log('download  finished', { download_all });
        if (download_all) {
          THIS.setState(state);
          THIS.handleDownloadItem(++index, null, selected);
        } else {
          state.downloading = false;
          THIS.setState(state);
        }
      })
      .catch(async error => {
        console.log({ error });
        error = await GetResponseError(error);
        THIS.handleDownloadItem(++index, error, selected);
      });
  })();
};
