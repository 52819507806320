import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
export { default as ToolBarProjectPatients } from './ToolBarProjectPatients';
export const useStyles = () => ({
  root: {},
});
export const columns_project_patient = [
  {
    field: 'idPatient',
    headerName: 'ID',
    width: 75,
    style: {
      width: 75,
      maxWidth: 90,
    },
    align: 'left',
  },
  {
    field: 'patient',
    headerName: 'Patient',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'patient');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.PatientName;
      }
    },
  },
  {
    field: 'patient',
    headerName: 'PatientID',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'patient');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.PatientID;
      }
    },
  },
  {
    field: 'PatientBirthDate',
    headerName: 'BirthDate',
    align: 'left',
    // edit: true,
    // style: {
    //   width: 190,
    //   minWidth: 190,
    // },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) {
        return '';
      } else {
        return value.PatientBirthDate;
      }
    },
  },
  {
    field: 'PatientSex',
    headerName: 'Sex',
    align: 'left',
    // edit: true,
    // style: {
    //   width: 190,
    //   minWidth: 190,
    // },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'patient');
      if (IsInvalid(value)) {
        return '';
      } else {
        return value.PatientSex;
      }
    },
  },
  {
    field: 'patient_code',
    headerName: 'CODE',
    align: 'left',
    edit: true,
    style: {
      // width: 190,
      minWidth: 50,
    },
  },
  {
    field: 'center',
    headerName: 'Center',
    align: 'left',
    edit: true,
    style: {
      width: 90,
      minWidth: 90,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue(params.id, 'center');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.name;
      }
    },
  },
  {
    field: 'total_studies',
    headerName: '#Studies',
    align: 'left',
    style: {
      width: 90,
      minWidth: 90,
      maxWidth: 190,
    },
  },
  {
    field: 'total_series',
    headerName: '#Series',
    align: 'left',
    style: {
      width: 80,
      minWidth: 80,
      maxWidth: 190,
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue(params.id, 'createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do, YYYY (h:mm a)');
    },
  },
];
