import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { GetThumbnailFile } from 'helpers';

class SeriesFileReady extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      item,
      index,
      defaultProps,
      classes,
      handleOnClick,
      width,
      border,
      Modality,
    } = this.props;
    let thumbnail = GetThumbnailFile('file.dcm');
    //
    if (item.Thumbnail && item.Thumbnail !== '') {
      thumbnail = `data:image/jpeg;base64,${item.Thumbnail}`;
    } else if (Modality && Modality === 'SR') {
      thumbnail = '/images/icons/icon_sr.png';
    } else if (Modality && Modality === 'PR') {
      thumbnail = '/images/icons/icon_pr.png';
    }
    return (
      <Box
        {...defaultProps}
        onClick={() => handleOnClick(index, item)}
        width={width + 4}>
        <img
          alt={item.title}
          className={clsx({
            [classes.unselected]: !border,
            [classes.selected]: border,
          })}
          src={thumbnail}
        />
        <Box pr={2} width={width}>
          <Typography
            align="center"
            color="textSecondary"
            display="block"
            variant="caption">
            {`${item.InstanceNumber} Image`}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            display="block"
            variant="caption">
            {`${item.SliceLocation}`}
          </Typography>
        </Box>
      </Box>
    );
  }
}

SeriesFileReady.propTypes = {
  border: PropTypes.bool,
  classes: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
  width: PropTypes.number,
};
SeriesFileReady.defaultProps = {
  width: 90,
  defaultProps: {},
  handleOnClick: () => '',
  border: false,
};
export default SeriesFileReady;
