import React from 'react';
// @mui/material components
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// @mui/icons-material
import ShareIcon from '@mui/icons-material/Share';
import SaveIcon from '@mui/icons-material/Save';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
// core components
import { InfoArea } from './components';

import styles from './Styles';

const useStyles = makeStyles()(styles);

export default function ProductSection() {
  const { classes } = useStyles();
  return (
    <div className={classes.section}>
      <Grid container justifyContent="center">
        <Grid item md={8} sm={12} xs={12}>
          <h2 className={classes.title}>How we can help you?</h2>
          <h5 className={classes.description}>
            Combining diverse medical data has emerged, in the clinical
            environment, as an important pre- and post-processing method to help
            with the diagnosis and the analysis of disease progress. To bring
            about this analysis through medical images (MRI, X-ray, CT, etc...),
            clinical information and signal data, researchers need to use a
            variety of pre-classified data from different sources to be able to
            identify the disease. Therefore, our system provides an easy way to
            share and classify anonymized data between users and institutions.
          </h5>
        </Grid>
      </Grid>
      <div>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center">
          <Grid item md={4} sm={12} xs={12}>
            <InfoArea
              description="Our cloud, application and hardware solutions allows to share studies between users and projects as easy as possible."
              icon={ShareIcon}
              iconColor="info"
              title="Share"
              vertical
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <InfoArea
              description="If you use our standalone solution any computer in the hospital can syncronize anonymized data with our cloud solution, and the data can no longer be associated with an individual in any manner."
              icon={SaveIcon}
              iconColor="success"
              title="Anonymization"
              vertical
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <InfoArea
              description="Our hardware solution enables a fast and user friendly way of deploy our system in your hospital facilities, which enables secure synchronization with our cloud solution using our integrated anonymization system."
              icon={DesktopMacIcon}
              iconColor="warning"
              title="Institution Device"
              vertical
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <InfoArea
              description={
                <React.Fragment>
                  Our system follows the Swiss regulations defined in the Human
                  Research Act article 5 (
                  <a href="https://www.fedlex.admin.ch/eli/cc/2013/642/de#a5">
                    HFV Art. 5
                  </a>
                  ) and in the article 18 of the Clinical Trials Regulation (
                  <a href="https://www.fedlex.admin.ch/eli/cc/2013/643/de#art_18">
                    KlinV Art. 18
                  </a>
                  ). Fundamentally the system{' '}
                  <a style={{ fontWeight: 1000, color: 'red' }}>Restrict</a> the
                  handling of the health-related personal data to those persons
                  who need this data to fulfill their duties,{' '}
                  <a style={{ fontWeight: 1000, color: 'red' }}>prevent</a>{' '}
                  unauthorized or accidental disclosure, alteration, erasure and
                  copying of health-related personal information, and{' '}
                  <a style={{ fontWeight: 1000, color: 'red' }}>Document</a> all
                  relevant machining operations to ensure traceability
                </React.Fragment>
              }
              icon={EnhancedEncryptionIcon}
              iconColor="danger"
              styleBox={{ maxWidth: '100%' }}
              title="System Regulations"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
