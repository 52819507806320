import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Menu } from '@mui/material';

const useStyles = () => ({
  paper: {
    background: 'transparent',
    border: '0px solid transparent',
  },
});

class StyledMenu extends Component {
  render() {
    const { position, transform, ...res } = this.props;
    return (
      <Menu
        anchorOrigin={position}
        elevation={0}
        // getContentAnchorEl={null}
        transformOrigin={transform}
        {...res}
      />
    );
  }
}

StyledMenu.defaultProps = {
  position: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transform: {
    vertical: 'top',
    horizontal: 'center',
  },
};
StyledMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  transform: PropTypes.object.isRequired,
};

export default withStyles(StyledMenu, useStyles);
