export const IsProjectFullAccess = (admin, access_level, user) => {
  if (user && user.type === 1) {
    return true;
  }
  return admin || access_level === 1 || access_level === 3;
};
export const IsProjectAdmin = (admin, access_level, user) => {
  if (user && user.type === 1) {
    return true;
  }
  return admin || access_level === 3;
};
export const ListAccessLevel = [
  'Blocked', // 0
  'Full Access', // 1
  'Only your teams or centers', // 2
  'Project Administrator Level', // 3
];
