import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const drawerMaxWidth = 240;
export const drawerMinWidth = 240;
export const useStyles = (theme, props) => ({
  root: {
    margin: 0,
    padding: 0,
    border: 0,
    position: 'relative',
    marginLeft: 'auto',
    width: '100%',
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      width: '100%',
      position: 'absolute',
      height: props ? props.height - 240 : null,
      transition: 'none !important',
    },
  },
  drawer_root: {
    width: 200,
    margin: 0,
    padding: 0,
    flexShrink: 0,
    position: 'relative',
  },
  drawer_paper: {
    width: 200,
    margin: 0,
    padding: 0,
    position: 'relative',
    height: props ? props.height - 56 : null,
    // marginLeft: 9,
  },
  drawer_position: {
    // position: 'absolute',
  },
  drawer_mobile: {
    display: 'none',
  },
  text_center: {
    fontSize: '12px',
  },
  item: {},
  selected: {
    backgroundColor: '#c8c4c4',
    color: 'white',
    fontWeight: 600,
    borderLeft: '4px solid #949494',
  },
});
const openedMixin = theme => ({
  width: drawerMaxWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerMaxWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
