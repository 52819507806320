import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  SearchText,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DialogCreateEncryptedMap from './DialogCreateEncryptedMap';
import { IsInvalid, ServerErrorsString, GetData } from 'helpers';
import {
  MutationAddEncryptionMaps,
  MutationRemoveEncryptionMaps,
  MutationUploadEncryptionMapsCSV,
} from 'graphql/Anonymization';
class TabEncryptionMapToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_delete: false,
      uploading: false,
      open_create: false,
      deleting: false,
      text: '',
    };
  }
  handleDeleteSelected = () => {
    console.log('handleDeleteSelected');
    const { selected, mappings, total } = this.props;
    const { deleting } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true });
    const ids = selected.map(x => x.id).filter(x => x);
    (async () => {
      MutationRemoveEncryptionMaps(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeEncryptionMaps;
          if (ok) {
            this.setState({ deleting: false, open_delete: false });
            this.props.setState({
              mappings: mappings.filter(x => ids.indexOf(x.id) === -1),
              total: total - ids.length,
              selected: [],
            });
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveChanges = () => {
    const { rows_edited } = this.props;
    if (IsInvalid(rows_edited) || !rows_edited.length) {
      this.props.handleAddError('Nothing to save');
      return;
    }
    const { saving } = this.state;
    if (saving) return;
    const inputs = rows_edited.map(x => {
      // eslint-disable-next-line no-unused-vars
      const { createdAt, updatedAt, user, project, patient, ...res } = x;
      return {
        ...res,
      };
    });
    console.log({ inputs });
    // return;
    this.setState({ saving: true });
    (async () => {
      MutationAddEncryptionMaps(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.addEncryptionMaps;
          if (ok) {
            this.setState({ creating: false });
            this.props.handleCreatedMappings(mappings, true);
            if (errors && errors.length) {
              this.props.handleAddError(ServerErrorsString(errors));
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ creating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleUploadSelectFile = event => {
    const { files } = event.target;
    if (!files.length) {
      this.props.handleAddError('Select file first');
      return;
    }
    this.setState({ uploading: true });
    (async () => {
      MutationUploadEncryptionMapsCSV(files[0], this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings } = data.uploadEncryptionMaps;
          if (ok) {
            this.setState({
              uploading: false,
            });
            this.props.handleCreatedMappings(mappings);
            if (errors && errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleEncryptedMapCreated = mappings => {
    this.setState({ open_create: false });
    this.props.handleCreatedMappings(mappings);
  };
  handleSearch = text => {
    const query = this.getQuery(text);
    this.props.handleReload(query);
    this.setState({ text });
  };
  getQuery = text => {
    if (IsInvalid(text)) text = '';
    let query = null;
    // key: String;
    // value: String;
    // owner: String;
    // if (text !== '')
    {
      query = {};
      const parts = text.split(';').map(x => x.trim());
      let notfound = 0;
      for (let i = 0; i < parts.length; i++) {
        const field = parts[i].toLowerCase();
        if (field.startsWith('key:')) {
          query.key = parts[i].replace('key:', '').trim();
        } else if (field.startsWith('value:')) {
          query.value = parts[i].replace('value:', '').trim();
        } else if (field.startsWith('owner:')) {
          query.owner = parts[i].replace('owner:', '').trim();
        } else if (field.startsWith('project:')) {
          query.project = parts[i].replace('project:', '').trim();
        } else if (field.startsWith('patient:')) {
          query.patient = parts[i].replace('patient:', '').trim();
        } else if (field.startsWith('idProject:')) {
          query.idProject = parts[i].replace('idProject:', '').trim();
        } else {
          notfound++;
        }
      }
      if (notfound === parts.length) {
        query.key = text;
        query.value = text;
        query.owner = text;
        query.project = text;
        query.patient = text;
        // query.idProject = text;
      }
    }
    return query;
  };
  render() {
    const { classes, selected, rows_edited } = this.props;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          pb={1}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.button_group}
              exclusive="false"
              size="small"
              value="save">
              <Button
                disabled={!rows_edited.length}
                onClick={this.handleSaveChanges}>
                <SaveIcon
                  style={{ color: rows_edited.length ? 'green' : 'gray' }}
                />
              </Button>
              <Button onClick={() => this.setState({ open_create: true })}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
              {/* <Button
                disabled={!selected.length}
                onClick={this.props.handleOpenEditView}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button> */}
              <ButtonDownloadType
                idProject={this.props.idProject}
                inputs={this.getQuery(this.state.text)}
                key="encryptions"
                type="encrypted_maps"
              />
              <ButtonFileUpload
                handleSelectFile={this.handleUploadSelectFile}
                uploading={this.state.uploading}
              />
            </ButtonGroup>
          </Grid>
          <Grid item xs>
            <SearchText
              handleSearchText={text => this.handleSearch(text)}
              id="id-search-encrypted-maps"
              loading={this.props.loading}
              placeholder="Search encrypted maps"
            />
          </Grid>
          <Grid item>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={() => this.props.handleReload(null)}
              loading={this.state.deleting}
              loading_table={this.props.loading}
              numSelected={selected.length}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogCreateEncryptedMap
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_create: false })}
          handleEncryptedMapCreated={this.handleEncryptedMapCreated}
          idProject={this.props.idProject}
          open={this.state.open_create}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_delete: false })}
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="You are about to delete the encrypted maps, this action cannot be undone"
          title_text="Delete Selected Encrypted Maps"
        />
      </React.Fragment>
    );
  }
}

TabEncryptionMapToolbar.propTypes = {
  classes: PropTypes.object,
};

export default TabEncryptionMapToolbar;
