import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  DatabaseToolBar,
  SeriesViewEdit,
  StatusDashboard,
  useStyles,
  ViewDatabasePatient,
  ViewDatabaseSeries,
  ViewDatabaseStudy,
  ViewDetails,
} from './components';
import { getInt, getParams, getParamsUrl } from 'helpers';
import { setDatabaseView } from 'redux-store/actions';
import { withStyles } from 'tss-react/mui';
import { IsInvalid } from 'helpers';

class DataImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 25,
      Total: 0,
      Study: null,
      loading: false,
      filters: null,
      search_type: null,
    };
    this.holder = {
      getSeries: null,
      getStudy: null,
      setStudy: null,
      getDescription: null,
      runQuerySeries: null,
      runQueryStudy: null,
      runQueryPatients: null,
      setTotal: Total => {
        this.setState({ Total });
      },
      setPageLimit: (page, limit) => {
        this.setState({ page, limit });
      },
      setLoading: loading => {
        this.setState({ loading });
      },
      getPatientIDs: null,
    };
    const { holder } = props;
    if (typeof holder !== 'undefined') {
      holder.getSeries = idSeries => {
        if (this.holder.getSeries) {
          return this.holder.getSeries(idSeries);
        } else {
          return null;
        }
      };
    }
  }
  checkView = () => {
    const { view_database } = this.props;
    const url = getParamsUrl(this.props);
    const view = this.getLevel();
    if (view && view_database !== view) {
      this.props.setDatabaseView(view);
      this.props.history.push(url);
    } else if (IsInvalid(view) && view_database !== 'study') {
      this.props.setDatabaseView('study');
    }
  };
  componentDidMount() {
    this.checkView();
  }

  componentDidUpdate() {
    this.checkView();
  }
  handleRunAtEnter = (description, index_level, reload = undefined) => {
    const { page, limit } = this.state;
    const query = {
      description,
      current_page: page,
      current_limit: limit,
      level: index_level,
      reload,
    };
    this.handleRun(query);
  };
  handleRun = query => {
    let { description, index_level } = query;
    const { view_database } = this.props;
    if (description && description !== '' && this.holder.getDescription) {
      query.description = this.holder.getDescription();
    }
    if (index_level === undefined && !index_level) {
      query.level = this.props.index_level;
    }
    if (view_database === 'series') {
      this.holder.runQuerySeries(query);
    } else if (view_database === 'study') {
      this.holder.runQueryStudy(query);
    } else if (view_database === 'patients') {
      this.holder.runQueryPatients(query);
    }
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    console.log({ newPage });
    const { limit } = this.state;
    this.setState({ page: newPage });
    this.handleRun({ current_page: newPage, current_limit: limit });
  };

  handleChangeRowsPerPage = event => {
    event.preventDefault();
    const limit = parseInt(event.target.value, 10);
    this.setState({ page: 0, limit });
    this.handleRun({ current_page: 0, current_limit: limit });
  };
  handleChangeView = () => {
    const { view_database } = this.props;
    console.log({ view_database });
    if (view_database === 'patients') {
      this.props.setDatabaseView('study');
      this.props.history.push('/image_analysis');
    } else {
      const view = view_database === 'study' ? 'series' : 'study';
      this.props.setDatabaseView(view);
      this.props.history.push(`/image_analysis/${view}`);
    }
  };
  getLevel = () => {
    const params = getParams(this.props);
    if (!params) return null;
    const { level_1 } = params;
    if (typeof level_1 === 'undefined') return null;
    return level_1;
  };
  getIdStudy = () => {
    const params = getParams(this.props);
    if (!params) return null;
    const { level_1, level_2 } = params;
    if (typeof level_2 === 'undefined') return null;
    if (level_1 === 'series') return null;
    const idStudy = parseInt(level_2, 10);
    if (typeof idStudy === 'undefined' || isNaN(idStudy)) return null;
    return idStudy;
  };
  getSeriesParams = () => {
    const url = getParamsUrl(this.props);
    if (url === '/image_analysis/series/edit/multi') {
      return {
        idSeries: null,
        view: 'multi',
      };
    }
    const params = getParams(this.props);
    const params_series = { idSeries: null, view: null };
    if (!params) return params_series;
    const { level_1, level_2, level_3, level_4, level_5 } = params;
    if (level_1 && level_2 && level_3) {
      const idSeries = getInt(level_2);
      if (level_1 === 'series' && idSeries) {
        return {
          idSeries,
          view: level_3,
        };
      }
    }
    if (IsInvalid(level_4) || IsInvalid(level_5)) return params_series;
    const idSeries = getInt(level_4);
    if (level_5 !== 'view' && level_5 !== 'edit') return params_series;
    return {
      idSeries,
      view: level_5,
    };
  };
  handleSetStudy = Study => {
    this.setState({ Study });
  };
  getViewer = () => {
    const { view_database, height, display_database } = this.props;
    if (view_database === 'series') {
      return (
        <ViewDatabaseSeries
          display_database={display_database}
          filters={this.state.filters}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          height={height}
          holder={this.holder}
          limit={this.state.limit}
          page={this.state.page}
          search_type={this.state.search_type}
          user={this.props.user}
        />
      );
    } else if (view_database === 'patients') {
      return (
        <ViewDatabasePatient
          display_database={display_database}
          handleAddError={this.props.handleAddError}
          height={height}
          holder={this.holder}
          limit={this.state.limit}
          loading={this.state.loading}
          page={this.state.page}
          setState={state => this.setState(state)}
          user={this.props.user}
        />
      );
    } else {
      return (
        <ViewDatabaseStudy
          display_database={display_database}
          filters={this.state.filters}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleSetStudy={this.handleSetStudy}
          height={height}
          holder={this.holder}
          limit={this.state.limit}
          page={this.state.page}
          search_type={this.state.search_type}
          user={this.props.user}
        />
      );
    }
  };
  render() {
    // console.log({ s: this.getLevel(), d: this.props.view_database });
    if (this.getLevel() === 'details') {
      return <ViewDetails handleAddError={this.props.handleAddError} />;
    }
    const { idSeries, view } = this.getSeriesParams();
    const idStudy = this.getIdStudy();
    if ((view && idSeries) || view === 'multi') {
      let Series = null;
      if (idStudy && this.holder.getStudy) {
        const Study = this.holder.getStudy(idStudy);
        if (Study) {
          const index = Study.Series.map(x => x.idSeries).indexOf(idSeries);
          if (index !== -1) {
            Series = Study.Series[index];
          }
        }
      }
      return (
        <SeriesViewEdit
          handleAddError={this.props.handleAddError}
          idSeries={idSeries}
          Series={Series}
          user={this.props.user}
          view={view}
        />
      );
    }
    const {
      classes,
      isDesktop,
      isMobile,
      view_database,
      display_database,
    } = this.props;

    const { page, limit, Total } = this.state;
    let Study = null;
    if (this.holder.getStudy && idStudy) {
      Study = this.holder.getStudy(idStudy);
    }
    let numb_patients = 0;
    if (this.holder.getPatientIDs) {
      numb_patients = this.holder.getPatientIDs();
    }
    if (!Study) {
      Study = this.state.Study;
    }
    return (
      <React.Fragment>
        <DatabaseToolBar
          handleAddError={this.props.handleAddError}
          handleChange={this.handleChangeView}
          handleReload={() => this.handleRunAtEnter('', undefined, true)}
          handleRunAtEnter={this.handleRunAtEnter}
          handleUpdateFilters={filters => this.setState({ filters })}
          handleUpdateSearchTypes={search_type =>
            this.setState({ search_type })
          }
          holder={this.holder}
          loading={this.state.loading}
          numb_patients={numb_patients}
          Study={Study}
          user={this.props.user}
          view_database={view_database}
        />
        <div
          className={clsx({
            [classes.main_container]: true,
            [classes.main_container_list]: display_database === 'list',
          })}>
          <div className={classes.root_channels}>
            <div className={classes.paper}>{this.getViewer()}</div>
          </div>
          <StatusDashboard
            classes={classes}
            display_database={display_database}
            filters={this.state.filters}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            isDesktop={isDesktop}
            isMobile={isMobile}
            limit={limit}
            page={page}
            Total={Total}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { view_database, display_database, setting } = state.page_database;
  let index_level = 2;
  if (setting && setting.level) {
    index_level = setting.level.index_level;
  }
  return {
    index_level,
    view_database,
    display_database,
  };
};

export default connect(mapStateToProps, { setDatabaseView })(
  withStyles(withRouter(DataImages), useStyles)
);
