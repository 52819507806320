import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Browser, Overview } from './components';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { GetData, ServerErrorsString } from 'helpers';
import { QueryMyProjectsFull } from 'graphql/Projects';

const useStyles = theme => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      empty: false,
      error: '',
      loading: false,
      total: 0,
      page: 0,
      limit: 25,
    };
    this.holder = {
      getProjectName: null,
    };
  }

  componentDidMount() {
    this.loadProjects();
  }

  componentDidUpdate() {
    const { empty, projects } = this.state;
    if (empty || projects.length) return;
    //
    this.loadProjects();
  }

  loadProjects = (page = null, limit = null) => {
    const { loading } = this.state;
    if (loading) return;
    if (!limit) {
      page = this.state.page;
      limit = this.state.limit;
    }
    const projectname = this.holder.getProjectName();
    //
    this.setState({ loading: true });
    QueryMyProjectsFull(projectname, page, limit)
      .then(res => {
        const data = GetData(res);
        //
        const { ok, projects, total, errors } = data.projects;
        if (ok) {
          this.setState({
            loading: false,
            empty: !projects.length,
            projects,
            total,
            page,
            limit,
          });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        const text_error = ServerErrorsString(error);
        this.setState({ loading: false, empty: true });
        this.props.handleAddError(text_error);
        if (text_error.indexOf('You are not in this project') !== -1) {
          this.props.history.push('/project_management/browser');
        }
      });
  };
  getProject = () => {
    const {
      match: { params },
    } = this.props;
    if (typeof params === 'undefined') return null;
    const { item } = params;
    return parseInt(item, 10);
  };
  IsBrowser = () => {
    return isNaN(this.getProject()) ? true : false;
  };
  projectChanged = project => {
    let { projects } = this.state;
    const index = projects.map(x => x.id).indexOf(project.id);
    if (index !== -1) {
      projects[index] = project;
      this.setState({ projects });
    }
  };
  handleDeletedProjects = ids => {
    let { projects } = this.state;
    projects = projects.filter(x => ids.indexOf(x.id));
    this.setState({ projects });
  };
  render() {
    const { projects, loading, total } = this.state;
    const { user, height } = this.props;
    const idProject = this.getProject();
    const index = projects.map(x => x.id).indexOf(idProject);
    let project = null;
    if (index !== -1) {
      project = projects[index];
    }
    //
    return (
      <React.Fragment>
        {this.IsBrowser() ? (
          <Browser
            handleAddError={this.props.handleAddError}
            handleDeletedProjects={this.handleDeletedProjects}
            height={height}
            holder={this.holder}
            limit={this.state.limit}
            loading={loading}
            page={this.state.page}
            projects={projects}
            reload={this.loadProjects}
            total={total}
            user={user}
          />
        ) : (
          <Overview
            handleAddError={this.props.handleAddError}
            height={height}
            holder={this.holder}
            idProject={idProject}
            project={project}
            projectChanged={this.projectChanged}
            user={user}
          />
        )}
      </React.Fragment>
    );
  }
}

Projects.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(withRouter(Projects), useStyles);
