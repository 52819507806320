export const drawerWidth = 300;

export const useStyles = theme => ({
  root: {
    margin: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main_container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
    height: '68vh',
  },
  folder_container: {
    width: '100%',
    height: '100%',
    paddingBottom: 2,
    overflowY: 'scroll',
    minHeight: '25vh',
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    marginLeft: '-23px',
  },
  pagination: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  spacer: {
    flexGrow: 1,
  },
  content: {
    height: '100%',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  drawerTemporal: {
    zIndex: 1250,
  },
});

export const UpdateToThema = (Folder, user) => {
  if (user === undefined || !user) {
    return;
  }
  if (user.idCategory !== 2) return;
  if (Folder === undefined || !Folder) {
    return;
  }
  if (Folder.name === 'Home' && !Folder.idParent) {
    const index = Folder.SubFolders.map(x => x.name).indexOf('Projects');
    console.log({ index });
    if (index !== -1) {
      Folder.SubFolders[index].name = 'Thema';
      if (Folder.SubFolders[index].folders_paths.length > 0) {
        const i = Folder.SubFolders[index].folders_paths
          .map(x => x.name)
          .indexOf('Thema');
        if (i !== -1) {
          Folder.SubFolders[index].folders_paths[i].name = 'Thema';
        }
      }
    }
  }
  if (
    Folder.folders_paths.length > 1 &&
    Folder.folders_paths[0].name === 'Home' &&
    Folder.folders_paths[1].name === 'Projects'
  ) {
    Folder.folders_paths[1].name = 'Thema';
  }
};
