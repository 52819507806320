import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, IconButton } from '@mui/material';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { options_types } from 'helpers/Docker';
import { IsChanged } from '../../../utils';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
class InputDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      input_type_object: null,
      vol_input: '',
      set: false,
    };
    const { holder } = props;
    if (holder) {
      holder.setInputVolume = (id, input_type_object, vol_input) =>
        this.setState({ id, input_type_object, vol_input, set: true });
    }
  }
  componentDidMount() {
    const { input } = this.props;
    if (input === null || input === undefined) return;
    let input_type_object = null;
    const index = options_types.map(x => x.name).indexOf(input.type);
    if (index !== -1) input_type_object = options_types[index];
    this.setState({
      id: input.id,
      input_type_object,
      vol_input: input.volume,
      set: true,
    });
  }
  componentDidUpdate() {
    if (this.state.set) return;
    const { input } = this.props;
    if (input === null || input === undefined) return;
    let input_type_object = null;
    const index = options_types.map(x => x.name).indexOf(input.type);
    if (index !== -1) input_type_object = options_types[index];
    this.setState({
      id: input.id,
      input_type_object,
      vol_input: input.volume,
      set: true,
    });
  }
  handleSelectedInputType = input_type_object => {
    const { settings } = this.props;
    const { id } = this.state;
    let input_type = '';
    if (input_type_object) input_type = input_type_object.name;
    let edited_input = settings.inputs_outputs.find(item => item.id === id);
    if (edited_input) {
      edited_input.type = input_type;
    } else {
      let edited_input = {
        id,
        idDockerSetting: settings.id,
        in_out_type: 'input',
        type: input_type,
        volume: '',
      };
      settings.inputs_outputs.push(edited_input);
    }
    const changed = IsChanged(
      { ...settings, edited_element: edited_input },
      this
    );
    this.setState({ input_type_object });
    this.props.handleUpdateSetting(settings);
    this.props.handleChanged(changed);
  };
  handleInputDockerVolume = event => {
    const { settings } = this.props;
    const { id } = this.state;
    const vol_input = event.target.value;
    let edited_input = settings.inputs_outputs.find(item => item.id === id);
    if (edited_input) {
      edited_input.volume = vol_input;
    } else {
      edited_input = {
        id,
        idDockerSetting: settings.id,
        in_out_type: 'input',
        type: '',
        volume: vol_input,
      };
      settings.inputs_outputs.push(edited_input);
    }
    const changed = IsChanged(
      { ...settings, edited_element: edited_input },
      this
    );
    this.setState({ vol_input });
    this.props.handleUpdateSetting(settings);
    this.props.handleChanged(changed);
  };
  handleRemoveInput = () => {
    this.props.handleRemoveInput(this.state.id);
  };
  render() {
    const { disabled } = this.props;
    const { input_type_object, vol_input } = this.state;
    let input_type = '';
    if (input_type_object) {
      input_type = input_type_object.name;
    }
    return (
      <Grid container item spacing={2} xs={12}>
        <Grid item sm={6} style={{ width: '100%' }} xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            id="input-docker-volume"
            InputLabelProps={{
              shrink: true,
            }}
            label="Input Docker Volume"
            onChange={this.handleInputDockerVolume}
            placeholder="Type the input volume name (e.g. /temp)..."
            value={vol_input}
            variant="filled"
          />
        </Grid>
        <Grid item sm={4} style={{ width: '100%' }} xs={8}>
          <SearchAutocomplete
            disabled={disabled}
            handleAddResultObject={this.handleSelectedInputType}
            label="Input Data Type"
            name={input_type}
            no_icon
            options={options_types}
            placeholder="Select input type of your docker..."
            selected={input_type_object}
            setObject={this.handleSelectedInputType}
          />
        </Grid>
        <Grid
          alignItems="center"
          container
          item
          style={{ width: '100%' }}
          xs={2}>
          <IconButton
            color="error"
            onClick={this.handleRemoveInput}
            size="small">
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

InputDocker.propTypes = {
  handleUpdateSetting: PropTypes.func,
};
InputDocker.defaultProps = {
  handleUpdateSetting: () => '',
};

export default InputDocker;
