import React from 'react';
import PropTypes from 'prop-types';
import TableGridFirstRow from './TableGridFirstRow';
import TableGridSecondRow from './TableGridSecondRow';
import { getCellValue } from 'components/Tables/TableGrid/TableGrid/utils_table_grid';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';
import { GetDataTypes, QueryVariableType } from './utils_var_types';
class TableGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      internals: [],
      empty: false,
      page: 0,
      limit: 5,
    };
  }
  getSeriesID = () => {
    const { row } = this.props;
    if (IsInvalid(row)) return -1;
    const { folder_content } = row;
    if (IsInvalid(folder_content)) return -1;
    const { Series } = folder_content;
    if (Series) return Series.idSeries;
    return -1;
  };
  getFileID = () => {
    const { row } = this.props;
    if (IsInvalid(row)) return -1;
    const { folder_content } = row;
    if (IsInvalid(folder_content)) return -1;
    const { File } = folder_content;
    if (File) return File.idFileObject;
    return -1;
  };
  handleOnClickExpandRow = new_open => {
    const { loading, empty, internals } = this.state;
    if (loading) return;
    const idSeries = this.getSeriesID();
    if (!new_open || internals.length || idSeries < -1 || empty) {
      this.setState({ open: new_open });
      return;
    }
    this.updateMapping(new_open);
  };
  updateMapping = (new_open, new_page, new_limit) => {
    let { loading, open, page, limit } = this.state;
    const idSeries = this.getSeriesID();
    let idFileObject = -1;
    if (idSeries < 0) idFileObject = this.getFileID();
    if (loading || (idSeries < 0 && idFileObject < 0)) return;
    if (new_open) {
      open = new_open;
    }
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryVariableType(idSeries, idFileObject, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, mappings, total } = GetDataTypes(data);
          if (ok) {
            this.setState({
              loading: false,
              internals: mappings,
              total,
              open,
              empty: !mappings.length,
              page,
              limit,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  updateTableMappings = (internals, removed) => {
    let { total } = this.state;
    total -= removed;
    if (total < 0) total = 0;
    this.setState({ internals, total });
  };
  render() {
    const { open, loading, internals } = this.state;
    const { headers, row } = this.props;
    const idSeries = this.getSeriesID();
    let isSeries = true;
    if (idSeries < 0) isSeries = false;
    return (
      <React.Fragment>
        <TableGridFirstRow
          checkboxSelection={this.props.checkboxSelection}
          getCellValue={getCellValue}
          handleAddError={this.props.handleAddError}
          handleRowClicked={this.props.handleRowClicked}
          headers={headers}
          isItemChecked={this.props.isItemChecked}
          isRowClicked={() => this.props.IsRowClicked(row)}
          isRowSelected={this.props.isRowSelected}
          loading={loading}
          onCheckBoxItemClicked={this.props.onCheckBoxItemClicked}
          onClickExpandRow={this.handleOnClickExpandRow}
          onRowEdited={this.props.handleRowEdited}
          open={open}
          row={row}
          row_original={this.props.row_original}
        />
        <TableGridSecondRow
          handleChangePage={page =>
            this.updateMapping(null, page, this.state.limit)
          }
          handleChangeRowsPerPage={limit => this.updateMapping(null, 0, limit)}
          handleReloadTable={() => this.updateMapping()}
          headers={headers}
          isSeries={isSeries}
          limit={this.state.limit}
          mappings={internals}
          open={open}
          page={this.state.page}
          total={this.state.total}
          updateMappings={this.updateTableMappings}
        />
      </React.Fragment>
    );
  }
}

TableGridRow.propTypes = {
  headers: PropTypes.array,
};
TableGridRow.defaultProps = {
  headers: [],
  checkboxSelection: false,
  cells: [],
  onCheckBoxItemClicked: () => '',
  onCellDoubleClick: () => '',
  isItemChecked: false,
  isRowSelected: false,
  isRowClicked: false,
  handleRowClicked: () => '',
  onRowEdited: () => '',
  handleAddError: error => console.log({ error }),
};

export default TableGridRow;
