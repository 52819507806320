const styles = theme => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    minHeight: '110px',
    backgroundColor: '#F0F0F0',
    border: 'dashed',
    borderColor: '#C8C8C8',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  stripes: {
    border: 'solid',
    backgroundImage:
      'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  rejectStripes: {
    border: 'solid',
    backgroundImage:
      'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  dropzoneTextStyle: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  uploadIconSize: {
    width: 51,
    height: 51,
    color: '#909090',
  },
  dropzoneParagraph: {
    fontSize: 24,
    color: '#909090',
  },
});
export default styles;
