import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from '../EditSystemDockerImage/Style';
import {
  DockerInputs,
  ButtonCircularIconLoad,
  CardCreateHeader,
} from 'components';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';
import { MutationCreateDockerImage } from 'graphql/Dockers/utils_docker_system';
import { GetData, ServerErrorsString } from 'helpers';
import { IsInvalid } from 'helpers';

class CreateSystemDockerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operation_name: '',
      docker_images: [],
      selected_image: null,
      uploading: false,
    };
    this.holder = {
      setSetting: null,
      getSetting: null,
    };
  }
  getFilterDockerImages = () => {
    const { docker_images } = this.props;
    let list = [];
    return docker_images.map((x, index) => {
      const { RepoTags, idImage } = x;
      let name = '';
      if (IsInvalid(RepoTags) || !RepoTags.length) {
        name = idImage.substring(0, 10) + '...';
      } else {
        console.log({ RepoTags });
        name = RepoTags[0].name;
      }
      if (list.indexOf(name) !== -1) {
        name = `${name} (${index})`;
      }
      list.push(name);
      return {
        index,
        ...x,
        name,
      };
    });
  };
  componentDidMount() {
    const docker_images = this.getFilterDockerImages();
    this.setState({
      docker_images,
    });
  }
  componentDidUpdate() {
    const docker_images = this.getFilterDockerImages();
    if (docker_images === undefined || !docker_images) return;
    if (docker_images.length !== this.state.docker_images.length) {
      this.setState({ docker_images });
    }
  }
  handleChangeOperationName = event => {
    this.setState({ operation_name: event.target.value });
  };
  handleSelectedDocker = selected_image => {
    console.log({ selected_image });
    this.setState({ selected_image });
  };
  handleSaveClicked = () => {
    const { uploading, selected_image, operation_name } = this.state;
    if (uploading || !selected_image) return;
    if (operation_name === '') {
      this.props.handleAddError('Operation name is missing');
      return;
    }
    const { idImage } = selected_image;
    const setting = this.holder.getSetting();
    for (let i = setting.inputs_outputs.length - 1; i >= 0; i--) {
      const element = setting.inputs_outputs[i];
      delete element.id;
      if (element.volume === '' || element.type === '') {
        setting.inputs_outputs.splice(i, 1);
      }
    }
    setting.operation_name = operation_name;
    this.setState({ uploading: true });
    (async () => {
      MutationCreateDockerImage(idImage, setting)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.createSystemDockerImage;
          if (ok) {
            this.setState({ uploading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, height } = this.props;
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
      };
    }
    const {
      operation_name,
      docker_images,
      selected_image,
      uploading,
    } = this.state;
    return (
      <Card className={classes.card} style={style}>
        <CardCreateHeader
          classes={classes}
          handleClose={this.props.handleOpenCreateDocker}
          icon={<AddIcon style={{ color: 'blue' }} />}
          loading={this.state.loading}
          subheader="Fill the necessary field and save it"
          title="Create System MB-Docker Image"
        />
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SearchAutocomplete
              disabled={this.state.uploading}
              handleAddResultObject={this.handleSelectedDocker}
              label="System Docker Images"
              name={selected_image ? selected_image.name : ''}
              no_icon
              options={docker_images}
              placeholder="Select a docker image for your container..."
              selected={selected_image}
              setObject={this.handleSelectedDocker}
              sytle_autocomplete={{ minWidth: 200, paddingLeft: 15 }}
            />
          </Grid>
          <Grid item>
            <ButtonCircularIconLoad
              disabled={selected_image ? false : true}
              handleButtonClick={this.handleSaveClicked}
              loading={uploading}
              small
            />
          </Grid>
        </Grid>
        <Divider sx={{ paddingTop: 1 }} variant="middle" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="operation-name"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Operation Name"
                onChange={this.handleChangeOperationName}
                placeholder="Name of the docker operation (e.g. tar.gz)..."
                required
                sx={{ paddingBottom: 1 }}
                value={operation_name}
              />
            </Grid>
          </Grid>
          <DockerInputs
            handleChanged={changed => this.setState({ changed })}
            holder={this.holder}
          />
        </CardContent>
      </Card>
    );
  }
}

CreateSystemDockerImage.propTypes = {
  classes: PropTypes.object,
};
CreateSystemDockerImage.defaultProps = {
  docker_images: [],
};

export default withStyles(CreateSystemDockerImage, useStyles);
