import React, { Component } from 'react';
import { QueryRunInvestigatedSide } from './utils_investigated_side';
import { ServerErrorsString, GetData, IsValid } from 'helpers';
class QueryInvestigatedSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      loading: false,
      name: null,
      page: 0,
      limit: 1000,
    };
    const { holder } = props;
    if (holder) {
      holder.reload = () => this.handleLoad();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }

  handleLoad = () => {
    const { loading, name, page, limit } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QueryRunInvestigatedSide(name, page, limit)
        .then(res => {
          const data = GetData(res);
          const rows = data.InvestigatedSides;
          if (IsValid(rows)) {
            this.props.setState({ loading: false });
            this.setState({
              rows,
              loading: false,
            });
          } else {
            throw Error('Invalid Rows');
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { children } = this.props;
    const { loading, rows } = this.state;
    return React.Children.map(children, function(child) {
      return React.cloneElement(child, {
        rows,
        loading,
      });
    });
  }
}
QueryInvestigatedSide.defaultProps = {
  holder: {
    reload: null,
  },
};
export default QueryInvestigatedSide;
